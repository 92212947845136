import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';
import { AttachmentType } from '../../fields/attachmentType';
import { NXTRailClampOption } from '../../fields/railClampOption';

export default () => {
  const { userPreferences: { preferred_span, rafter_spacing_inches } ,} = state();
  const NxtHorizonConfig = {
    zipcode: '',
    building_code: 2,
    wind_speed: 110,
    snow_load: 5,
    seismic_ss: 1.20,
    seismic_sds: 0.01,
    tornado_speed : 0,
    elevation: 5499,
    wind_exposure: 'B',
    rail_finish: 'AL',
    roof_type: 1,    
    mean_recurrence_interval: 25,
    preferred_span: preferred_span || 72,
    client_name: '',
    rail_direction: 'EW',
    rafter_spacing_inches: rafter_spacing_inches || 24,
    building_type: gable.value,
    topographical_factor_kzt: 1,
    mid_clamps_choices:4,
    end_clamps_choices:4,
    rail_type:6,
    attachment_type : AttachmentType.STRONGHOLD_ATT_KIT_COMP,
    rail_clamp_option: NXTRailClampOption.RAIL_CLAMP_ONLY,
    stagger_attachments: false
  };
  return NxtHorizonConfig;
};
