import { getAngle } from "./backgroundRoofEdgesRotation";

export const getRoofAreaAzimuth = (leadEdgeCords: pixelPoint[], roofEdges: google.maps.LatLngLiteral[], roofCenter: cordPoint, zoom: number): number  => {
  // angle measured in anticlockwise direction going from +ve X Axis to South Edge line going anticlockwise along the roof area polygon
  let southEdgeAngleWithXAxis = getAngle(leadEdgeCords, roofEdges, roofCenter, zoom); 
  if (southEdgeAngleWithXAxis < 0) southEdgeAngleWithXAxis += 360;
  // angle measured in anticlockwise direction going from +ve Y Axis to Line perpendicular to South Edge directed out of the polygon
  let azimuth = southEdgeAngleWithXAxis < 180? southEdgeAngleWithXAxis + 180: southEdgeAngleWithXAxis - 180;
  // change to clockwise direction
  azimuth =  (360 - azimuth)%360;
  return Math.round(azimuth * 100) / 100;
};
