export const apiField = 'thermal_factor';

const config = {
  label: 'thermalFactor',
  type: 'Number',
  apiField,
  missing: undefined,
  min: 1,
  max: 1.2,
  step: 0.2
};

export default config;