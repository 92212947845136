import nbccCategoryTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/nbccCategoryTooltip';
export const apiField = 'nbcc_category';

const options = [
  {
    value: '1',
    name: 'Low',
  },
  {
    value: '2',
    name: 'Normal',
  },
  {
    value: '3',
    name: 'High',
  },
  {
    value: '4',
    name: 'Post-Disaster',
  }
]

const config = {
  label: 'nbccCategory',
  type: 'Select',
  apiField,
  tooltip: nbccCategoryTooltip,
  missing: undefined,
  data: options,
};


export default config;