import { createReducer } from '__common/utils/helpers';
import { StageActionTypes } from 'actionsConstants';


export const stageState = {
  rehydrated: false,
  stageScale: { x: 1, y: 1 },
  cursor: 'inherit',
};

export default {
  stage: createReducer(stageState, {
    [StageActionTypes.SET_STAGE_SCALE](state, action) {
      const { stageScale } = action.payload;
      return { ...state, stageScale };
    },
  }),
};
