import React from 'react';
import ModuleSelectorComponent from '__common/components/moduleSelector/moduleSelectorComponent';
import { getModuleSelectorTooltipContent } from '__common/components/Drawer/utils/getDrawerTooltipsContent';
import { products, getProductName } from '__common/constants/products';

export const ModuleSelectorPage = (productId: number) => {
  return {
    content: <ModuleSelectorComponent productId={getProductName(productId)} />,
    tooltip: getModuleSelectorTooltipContent(products[productId]),
  };
};
