import RainLoadTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/rainLoadTooltip';
export const apiField = 'rain_load';

const config = {
  type: 'Number',
  label: 'rainLoad',
  apiField,
  tooltip: RainLoadTooltip,
  min: 0,
  max: 1,
  missing: undefined,
};

export default config;