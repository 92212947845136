import * as PIXI from 'pixi.js';
import { getTexture } from './utils/textureManager';
import { dispatch, state } from '__common/store';
import { isRMDT, isGFT, isULA, isAscender } from '__common/constants/products';
import { removePanelsOnMovement } from '../components/panels/panels';
import { CHANGE_PANELS_SIZES, FLIP_CURSOR, SHOW_CURSOR } from '../actions';
import { is1PStructureType } from 'projectDesign/components/projectConfiguration/fields/types/structureType';

type CursorXY = {
  x: number,
  y: number,
};

function cursorModel({ x, y }: CursorXY) {
  const { settings: { panelWidth, panelHeight }, editorCursor: { colour } } = state();
  const texture = getTexture('cursor', panelWidth, panelHeight, colour);
  const cursor = new PIXI.Sprite(texture);
  cursor.id = 'cursor';
  cursor.hitArea = new PIXI.Rectangle(x, y, panelWidth, panelHeight);
  cursor.width = panelWidth;
  cursor.height = panelHeight;
  cursor.zIndex = 9999;
  cursor.x = x;
  cursor.y = y;
  cursor.anchor.x = 0.5;
  cursor.anchor.y = 0.5;
  cursor.interactive = false;

  return cursor;
}

function getSingleCursor({ x, y }: CursorXY) {
  return cursorModel({ x, y });
}

function getDoubleCursorLongerEdge({ x, y }: CursorXY): PIXI.Container {
  const { editorCursor: { landscape } } = state();
  const cursorsContainer = new PIXI.Container();
  cursorsContainer.x = x;
  cursorsContainer.y = y;
  let firstCursor: PIXI.Sprite;
  let secondCursor: PIXI.Sprite;
  if (landscape) {
    firstCursor = cursorModel({ x: 0, y: 0 });
    secondCursor = cursorModel({ x: 0, y: firstCursor.height });
  } else {
    firstCursor = cursorModel({ x: 0, y: 0 });
    secondCursor = cursorModel({ x: firstCursor.width, y: 0 });
  }
  cursorsContainer.addChild(firstCursor);
  cursorsContainer.addChild(secondCursor);
  cursorsContainer.pivot.x = firstCursor.width / 2;

  cursorsContainer.zIndex = 9999;
  cursorsContainer.id = 'cursor';
  return cursorsContainer;
}

const getDesiredTableLengthCursorShorterEdge = ({ x, y }: CursorXY): PIXI.Container => {
  const cursorsContainer = new PIXI.Container();
  cursorsContainer.x = x;
  cursorsContainer.y = y;
  const { 
    panels: { 
      desiredTableLength: desiredLength,
    },
    settings: { 
      panelWidth, 
      panelHeight,
      columnSpacing,
    },
    background: {
      metersPerPixel,
    },
  } = state();
  const columnSpacingPx = columnSpacing/metersPerPixel;
  for (let i=0; i<desiredLength; i++){
    const firstCursor = cursorModel({ x: (panelWidth+columnSpacingPx)*i, y: 0 });
    const secondCursor = cursorModel({ x: (panelWidth+columnSpacingPx)*i, y: panelHeight });
    cursorsContainer.addChild(firstCursor);
    cursorsContainer.addChild(secondCursor); 
  }
  cursorsContainer.pivot.y = panelHeight / 2;

  cursorsContainer.zIndex = 9999;
  cursorsContainer.id = 'cursor';
  return cursorsContainer;
};

const getDesiredTableLengthAndWidthCursorShorterEdge = ({ x, y }: CursorXY): PIXI.Container => {
  const cursorsContainer = new PIXI.Container();
  cursorsContainer.x = x;
  cursorsContainer.y = y;
  const { 
    panels: { 
      desiredTableLength: desiredLength,
      desiredTableWidth: desiredWidth,
    },
    settings: { 
      panelWidth, 
      panelHeight,
      columnSpacing,
      rowSpacing,
    },
    background: {
      metersPerPixel,
    },
  } = state();

  const columnSpacingPx = columnSpacing/metersPerPixel;
  const rowSpacingPx = rowSpacing/metersPerPixel;
  
  for (let i=0; i<desiredLength; i++){
    for(let j=0;j < desiredWidth; j++){
      cursorsContainer.addChild(cursorModel({ x: (panelWidth+columnSpacingPx)*i, y: (panelHeight+rowSpacingPx)*j }));
    }
  }
  cursorsContainer.pivot.y = panelHeight / 2;

  cursorsContainer.zIndex = 9999;
  cursorsContainer.id = 'cursor';
  return cursorsContainer;
};


const getCursorForAscender = ({ x, y }: CursorXY): PIXI.Container => {
  const cursorsContainer = new PIXI.Container();
  cursorsContainer.x = x;
  cursorsContainer.y = y;
  const {
    settings: { 
      panelWidth, 
      panelHeight,
      columnSpacing,
    },
    background: {
      metersPerPixel,
    },
    projectConfiguration: {
      projectEnvConfig: {
        structure_type,
      }
    },
  } = state();

  if(is1PStructureType(structure_type)) {
    return cursorModel({ x, y });
  }
  else {
    const columnSpacingPx = columnSpacing/metersPerPixel;
    for (let i=0; i<1; i++){
      const firstCursor = cursorModel({ x: (panelWidth+columnSpacingPx)*i, y: 0 });
      const secondCursor = cursorModel({ x: (panelWidth+columnSpacingPx)*i, y: panelHeight });
     cursorsContainer.addChild(secondCursor);
     cursorsContainer.addChild(firstCursor);
    }
    cursorsContainer.pivot.y = 0;
  
    cursorsContainer.zIndex = 9999;
    cursorsContainer.id = 'cursor';
    return cursorsContainer;
  }
};


const getCursorForPanelsArrayMovement = ({ x, y }: CursorXY): PIXI.Container => {
  const cursorsContainer = new PIXI.Container();

  const { 
    editorCursor: {landscape},
    panels: { 
      panelsToBeMoved,
    },
  } = state()

  if(panelsToBeMoved[0].landscape != landscape)  {
    dispatch(FLIP_CURSOR());
    dispatch(CHANGE_PANELS_SIZES());
  }


  cursorsContainer.x = x;
  cursorsContainer.y = y;
  const { 
    projectConfiguration: {productId},
    panels: { 
      panelsArrangementToBeMoved,
    },
    settings: { 
      panelWidth, 
      panelHeight,
      columnSpacing,
      rowSpacing,
    },
    background: {
      metersPerPixel,
    },
  } = state();

  const columnSpacingPx = columnSpacing/metersPerPixel;
  const rowSpacingPx = rowSpacing/metersPerPixel;
  let firstModuleIndexOnFirstRow = -1;
  for(let i=0;i < panelsArrangementToBeMoved.panelsToBeMovedGrid.length; i++){
  for (let j=0; j<panelsArrangementToBeMoved.panelsToBeMovedGrid[0].length; j++){
      if(panelsArrangementToBeMoved.panelsToBeMovedGrid[i][j] == 1) {

        if(i==0 && firstModuleIndexOnFirstRow == -1 && panelsArrangementToBeMoved.panelsToBeMovedGrid[0][j] == 1)  {
          firstModuleIndexOnFirstRow = j;
        }

        if(isRMDT(productId)) {
          let firstCursor: PIXI.Sprite;
          let secondCursor: PIXI.Sprite;
          if (landscape) {
            firstCursor = cursorModel({ x:  (panelWidth+columnSpacingPx)*j, y: (panelHeight*2+rowSpacingPx)*i });
            secondCursor = cursorModel({ x: (panelWidth+columnSpacingPx)*j, y: firstCursor.height + (panelHeight*2+rowSpacingPx)*i  });
          } else {
            firstCursor = cursorModel({ x: (panelWidth*2+columnSpacingPx)*j, y: (panelHeight+rowSpacingPx)*i });
            secondCursor = cursorModel({ x: panelWidth + (panelWidth*2+columnSpacingPx)*j, y: (panelHeight+rowSpacingPx)*i });
          }
          cursorsContainer.addChild(firstCursor);
          cursorsContainer.addChild(secondCursor);
        }
        else if (isGFT(productId)) {
          cursorsContainer.addChild(cursorModel({ x: (panelWidth+columnSpacingPx)*j, y: (panelHeight)*i }));          
        }
        else {
          cursorsContainer.addChild(cursorModel({ x: (panelWidth+columnSpacingPx)*j, y: (panelHeight+rowSpacingPx)*i }));
        }
      }
    }
  }
  let multiple = isRMDT(productId) ? 2 : 1;
  cursorsContainer.pivot.x = (panelWidth * multiple +columnSpacingPx)* firstModuleIndexOnFirstRow;
  cursorsContainer.pivot.y = panelHeight / 2;

  cursorsContainer.zIndex = 9999;
  cursorsContainer.id = 'cursor';
  return cursorsContainer;
};



export function Cursor({ x, y }: CursorXY, productId: number) {
  const {panels: {panelsToBeMoved, panelsArrangementToBeMoved,}, background: {moveArrayMode}} = state();
  
  if(moveArrayMode && panelsToBeMoved.length >0 && panelsArrangementToBeMoved.panelsToBeMovedGrid.length > 0) {
    removePanelsOnMovement(panelsToBeMoved[0].groupId);
    dispatch(SHOW_CURSOR());
    return getCursorForPanelsArrayMovement({x,y});
  }

  if (isRMDT(productId)) {
    return getDoubleCursorLongerEdge({ x , y });
  }
  if (isGFT(productId)) {
    return getDesiredTableLengthCursorShorterEdge({ x, y });
  }
  if (isULA(productId)) {
    return getDesiredTableLengthAndWidthCursorShorterEdge({ x, y });
  }
  if(isAscender(productId)) {
    return getCursorForAscender({x,y});
  }

  return getSingleCursor({ x, y });
}

export default Cursor;
