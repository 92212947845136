export const apiField = 'wind_exposure';

export const windExposureOption = {
  inTown: {
    value: 'B',
    name: 'B - IN TOWN',
  },
  openTerrain: {
    value: 'C',
    name: 'C - OPEN TERRAIN',
  },
  openWater: {
    value: 'D',
    name: 'D - OPEN WATER',
  },
};

export interface WindExposureField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  data: WindExposureOption[];
}

export interface WindExposureOption {
  value: string;
  name: string;
}
