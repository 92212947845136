export const apiField = 'total_weight_on_roof_limit';

const config = {
  min: 0,
  max: 10000000,
  type: 'Number',
  label: 'totalWeightonRoof',
  apiField,
  tooltip: () => 'Total Weight on Roof',
  missing: undefined,
};

export default config;
