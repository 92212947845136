import { createReducer } from '__common/utils/helpers';
import { CursorActionTypes } from 'actionsConstants';
import { ASCE_716_ROOF_ZONES } from '../roofZones/roofZoneNumbers';

export const editorCursorState: editorCursor = {
  position: { x: 0, y: 0 },
  // TODO - this is not really a color, but rather a number 
  // that is used to determine a color in 'getColorByRoofZone' 
  colour: 0,
  width: 15,
  height: 10,
  visible: true,
  landscape: false,
  locked: false,
  overPanel: false,
  overLapPanel: false,
  systemCursor: 'inherit',
};

export default {
  editorCursor: createReducer(editorCursorState, {
    [CursorActionTypes.MOVE_CURSOR](state, action): editorCursor {
      const editorCursor = { ...state.editorCursor, position: action.payload.position };

      return { ...state, ...editorCursor };
    },
    [CursorActionTypes.SHOW_CURSOR](state: editorCursor): editorCursor {
      const editorCursor = { ...state, visible: true };

      return { ...state, ...editorCursor };
    },
    [CursorActionTypes.HIDE_CURSOR](state: editorCursor): editorCursor {
      const editorCursor = { ...state, visible: false };

      return { ...state, ...editorCursor };
    },
    [CursorActionTypes.SET_PORTRAIT_CURSOR](state: editorCursor): editorCursor {
      return { ...state, landscape: false };
    },
    [CursorActionTypes.SET_LANDSCAPE_CURSOR](state: editorCursor): editorCursor {
      return { ...state, landscape: true };
    },
    [CursorActionTypes.FLIP_CURSOR](state: editorCursor): editorCursor {
      return { ...state, landscape: !state.landscape };
    },
    [CursorActionTypes.LOCK_CURSOR](state: editorCursor): editorCursor {
      return { ...state, locked: true };
    },
    [CursorActionTypes.UNLOCK_CURSOR](state: editorCursor): editorCursor {
      return { ...state, locked: false };
    },
    [CursorActionTypes.SET_CURSOR_OVERLAP_PANEL](state: editorCursor): editorCursor {
      return { ...state, overLapPanel: true };
    },
    [CursorActionTypes.UNSET_CURSOR_OVERLAP_PANEL](state: editorCursor): editorCursor {
      return { ...state, overLapPanel: false };
    },
    [CursorActionTypes.SET_CURSOR_OVER_PANEL](state: editorCursor): editorCursor {
      return { ...state, overPanel: true };
    },
    [CursorActionTypes.UNSET_CURSOR_OVER_PANEL](state: editorCursor): editorCursor {
      return { ...state, overPanel: false };
    },
    [CursorActionTypes.RESET_CURSOR_STATE](state: editorCursor): editorCursor {
      return { ...state, locked: false, systemCursor: 'inherit' };
    },
    [CursorActionTypes.CHANGE_SYSTEM_CURSOR](state: editorCursor, action): editorCursor {
      return { ...state, systemCursor: action.payload.systemCursor };
    },
    [CursorActionTypes.RESET_SYSTEM_CURSOR](state: editorCursor): editorCursor {
      return { ...state, systemCursor: 'inherit' };
    },
    [CursorActionTypes.SET_BLACK_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 3 };
    },
    [CursorActionTypes.SET_ORANGE_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 2 };
    },
    [CursorActionTypes.SET_YELLOW_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 1 };
    },
    [CursorActionTypes.SET_GREEN_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 4 };
    },
    [CursorActionTypes.SET_BLUE_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 0 };
    },
    [CursorActionTypes.SET_ASCE_7_16_GREEN_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: ASCE_716_ROOF_ZONES.NORTG_LEADING };
    },
    [CursorActionTypes.SET_ASCE_7_16_YELLOW_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: ASCE_716_ROOF_ZONES.MIDDLE };
    },
    [CursorActionTypes.SET_ASCE_7_16_BLACK_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 3 };
    },
    [CursorActionTypes.SET_ASCE_7_16_BROWN_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: ASCE_716_ROOF_ZONES.SOUTH_CORNER };
    },
    [CursorActionTypes.SET_ASCE_7_16_VIOLET_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: ASCE_716_ROOF_ZONES.NORTH_CORNER };
    },
    [CursorActionTypes.SET_ASCE_7_16_ORANGE_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: ASCE_716_ROOF_ZONES.EAST_WEST_EDGES };
    },
    [CursorActionTypes.SET_ASCE_7_16_SALMON_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: ASCE_716_ROOF_ZONES.SOUTH_LEADING };
    },
    [CursorActionTypes.SET_OBSTRUCTION_ZONE_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 8 };
    },
    [CursorActionTypes.SET_ASCE_7_16_SNAPPING_CURSOR](state: editorCursor): editorCursor {
      return { ...state, colour: 9 };
    },
  }),
};
