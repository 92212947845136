import * as React from 'react';
import { connect } from 'react-redux';

type Props = {
  moduleSelector: moduleSelectorState,
  panels: any,
  rubberBand: any,
};

class ModulesInfo extends React.Component<Props, {}> {
  render() {
    const output = this.props.moduleSelector.modelData.watts;
    const modules = this.props.panels.panels.length + this.props.rubberBand.panelsShadow.length - this.props.rubberBand.panelsToRemove.length;
    const Kw = parseFloat((modules * output / 1000).toFixed(2))

    return (
      <div className="total-modules-kw">
        <div className="value modules-quantity">
          {modules || 0}
        </div>
        <div className="label">
          Modules,
        </div>
        <div className="value kw-quantity">
          {Kw || 0}
        </div>
        <div className="label">
          KW
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    moduleSelector: state.moduleSelector,
    panels: state.panels,
    rubberBand: state.rubberBand,
  };
}

export default connect(mapStateToProps)(ModulesInfo);
