export const apiField = 'material_thickness';

function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'roof_material_thickness_sm_flush',
    apiField,
    data: options,
  };
}

export default makeConfig([
  {
    value: 8,
    name: '22',
  },
  {
    value: 9,
    name: '24',
  },
  
]);

export const rpanelRoofMaterialThicknessForPM9000s = makeConfig([
  {
    value: 9,
    name: '24 ga',
  },
  
]);

export const rpanelRoofMaterialThicknessForPMAdj = makeConfig([
  {
    value: 8,
    name: '22 ga',
  },
  {
    value: 9,
    name: '24 ga',
  },
  {
    value: 10,
    name: '26 ga',
  },
]);
