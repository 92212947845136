import { deployAdvanceSelecting } from '__common/components/advancedSelecting/advanceSelecting';
import { clearAdvanceSelecting } from '../advanceSelecting/drawAdvanceSelectingOnStage';
import { state, dispatch } from '__common/store';
import { ADD_OBSTRUCTION, CHANGE_OBSTRUCTION_HEIGHT } from './ObstructionsActions';
import { getStage } from '../stage/stage';
import { isRM5, isRM10, isRMDT, isRMIFIProduct, isRMGridFlex, isRM10Evolution, isGroundProduct, isResidentialProduct, isRmGridflex10 } from '__common/constants/products';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';
import { metersToInches } from '__common/calculations/metersToInches';
import { getRoofZonesEdges } from '../roofZones/roofZonesHelper';
import { checkObstruction } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { isASCE716BuildingCode, isASCE716or722BuildingCode } from 'projectDesign/components/projectConfiguration/projectConfiguration';

const obstructionDefaultName = 'Obstruction ';

let obstructionContainer: PIXI.Container;
const obstructionContainerName = 'Obstruction container';

export const createObstructionContainer = () => {
  const stage = getStage();
  obstructionContainer = new PIXI.Container();
  obstructionContainer.id = obstructionContainerName;
  removeObstructionContainer();
  stage.addChild(obstructionContainer);
};

export const getObstructionContainer = () => {
  if (obstructionContainer) {
    return obstructionContainer;
  }
};

export const removeObstructionContainer = () => {
  const stage = getStage();

  if (stage && obstructionContainer) {
    stage.children = stage.children.filter((child: PIXI.Container) => child.id !== obstructionContainerName);
  }
};

export const getRectangleCoordsFrom3Points = (points: {x: number, y:number}[]) => {
  const [pointA, pointB, pointC] = points;
  const dx = pointA.x - pointB.x;
  const dy = pointA.y - pointB.y;
  return points.concat({ x: dx + pointC.x, y: dy + pointC.y });
};

export const deployObstructionDrawing = (dblClick = true) => {
  deployAdvanceSelecting({
    dblClick,
    selfIntersectionWarning: 'Cannot self intersect',
    overlapingWarning: 'Cannot overlap',
    onClear: () => clearAdvanceSelecting(),
    onDeploy: (newRoofPoints: { x: number, y: number }[]) => {
      const { background: { selectedRoofId, metersPerPixel }, projectConfiguration: { productId } } = state();
      const newObstructionId = getObstructionId();
      const obstructionName = `${obstructionDefaultName}${newObstructionId}`;
      let points = newRoofPointsByCenter(newRoofPoints);
      let [obstructionLength, obstructionWidth] = [undefined, undefined];

      if (isRMIFIProduct(productId)) {
        if (points.length > 3){
          points = points.splice(0, 3);
        }
        if (points.length === 3) {
          points = getRectangleCoordsFrom3Points(points);
          obstructionLength = metersToInches(calcDistanceOfTwoPoints(points[0], points[1]) * metersPerPixel);
          obstructionWidth = metersToInches(calcDistanceOfTwoPoints(points[1], points[2]) * metersPerPixel);
        }
      }
      const isLargeEnough = isObstructionEdgesLargeEnough(points, metersPerPixel);
      if (!isRMIFIProduct(productId) || points.length === 4) {
        dispatch(ADD_OBSTRUCTION(selectedRoofId, newObstructionId, obstructionName, obstructionLength, obstructionWidth, points, isLargeEnough, metersPerPixel));
      }
    },
  });
};

export const roofHasObstructions = (roofId: number) => {
  const { obstructions: { obstructions } } = state();
  return obstructions && obstructions[roofId] && Object.keys(obstructions[roofId]).length;
};

export const getRoofObstructions = (roofId: number) => {
  const { obstructions: { obstructions } } = state();
  if (roofHasObstructions(roofId)) {
    return obstructions[roofId];
  }
};

export const getObstructionLargestEdgeMinimumLength = (muliplayer: number) => {
  // Obstruction longest wall and obstruction height must be greater than:
  // RM10 --> 5*(module_length * sin(10[deg]) + 4.81)
  // RM5 --> 5*(module_length * sin(5[deg]) + 4.19)
  // RMDT --> 5*(module_width * sin(8[deg]) + 4.25)
  // RMGRIDFLEX --> 5*(module_width * sin(5[deg]) + 3.28)
  
  // if angle need to be set in rad 
  // sin(X[deg]) -> sin(X * pi/180)

  const { projectConfiguration: { productId }, moduleSelector: { modelData: { height, width } }, saveLoadProject: { dataToLoad } } = state();

  if (isRM5(productId)) {
    const angleRadians = degreesToRadians(5);
    const moduleDim = height;
    const offset = 4.19;

    return muliplayer * (moduleDim * Math.sin(angleRadians) + offset);
  } 
  
  if (isRM10(productId) || isRM10Evolution(productId) || isRmGridflex10(productId)) {
    const angleRadians = degreesToRadians(10);
    const moduleDim = height;
    const offset = 4.81;

    return muliplayer * (moduleDim * Math.sin(angleRadians) + offset);
  } 
  
  if (isRMDT(productId)) {
    const angleRadians = degreesToRadians(8);
    const moduleDim = width;
    const offset = 4.25;

    return muliplayer * (moduleDim * Math.sin(angleRadians) + offset);
  }

  if (isRMGridFlex(productId)) {
    const angleRadians = degreesToRadians(5);
    const module_width = dataToLoad?.project_configuration?.module_width;
    const moduleDim = width? width: module_width;
    const offset = 3.28; // the length from bottom edge of module to bottom of roof pad for GridFlex is 3.278"

    return muliplayer * (moduleDim * Math.sin(angleRadians) + offset);
  }

  return 1;
};

export const hasRoofObstructionHeightSet = () => {
  const { background: { selectedRoofId }, projectConfiguration: { projectEnvConfig: { building_code } } } = state();
  const obstructions = getRoofObstructions(selectedRoofId) || {};
  const nonObstructions = Object.keys(obstructions).length === 0;
  const valid = Object.keys(obstructions).some(obstructionId => {
    return checkObstruction(obstructions[obstructionId].height, true);
  });

  return (valid && isASCE716or722BuildingCode(building_code)) ||
  (nonObstructions && isASCE716or722BuildingCode(building_code)) ||
  !isASCE716or722BuildingCode(building_code);
};


function getObstructionId () {
  const { obstructions: { obstructions }, background: { selectedRoofId } } = state();

  if (obstructions[selectedRoofId]) {
    const ids = Object.keys(obstructions[selectedRoofId]);
    const id = Number(ids[ids.length - 1]) + 1;
    return id;
  }

  return 1;
}

function newRoofPointsByCenter(newRoofPoints: { x: number, y: number }[]) {
  const { background: { bgXY: { x, y } } } = state();

  const centered = newRoofPoints.map(point => {
    point.x -= x;
    point.y -= y;
    return point;
  });

  return centered;
}

export const isObstructionEdgesLargeEnough = (obstructionCords: { x: number, y: number}[], metersPerPixel: number) => {
  const obstructionEdgesPx = getRoofZonesEdges(obstructionCords);
  const minDistance = getObstructionLargestEdgeMinimumLength(5);
  return obstructionEdgesPx.some(edge => {
    const startPoint = edge[0];
    const endPoint = edge[1];
    const distance = calcDistanceOfTwoPoints(startPoint, endPoint);
    const distanceInches = metersToInches(distance * metersPerPixel);
    return distanceInches >= minDistance;
  });
};


export const loadObstructions = () => {
  const {
    background: { selectedRoofId, metersPerPixel },
    obstructions : {obstructions}
  } = state();

  if(obstructions && Object.keys(obstructions).length && obstructions.hasOwnProperty(Number(selectedRoofId)) && Object.keys(obstructions[selectedRoofId]).length) {
    Object.keys(obstructions[selectedRoofId]).map(obs => {
      const obsInfo = obstructions[selectedRoofId][obs];
      let newObsInfo = obsInfo;
      let multiplier = 1;
      if (metersPerPixel != obsInfo.metersPerPixel) {
        multiplier = obsInfo.metersPerPixel/metersPerPixel;
        multiplier = parseFloat(multiplier.toFixed(1));
      }

      const validMultiplierVals = [0.5, 1, 2];
      if (!validMultiplierVals.includes(multiplier)) multiplier = 1;
      
      if (multiplier !== 1) {
        newObsInfo.coords = newObsInfo.coords.map(coord => {
          return {
            x: multiplier * coord.x,
            y: multiplier * coord.y
          }
        })
        newObsInfo.length  = multiplier * newObsInfo.length;
        newObsInfo.width = multiplier * newObsInfo.width;
      }
      const {length, width, coords, name} = newObsInfo;
      const isLargeEnough = isObstructionEdgesLargeEnough(coords, metersPerPixel);
      dispatch(ADD_OBSTRUCTION(selectedRoofId, Number(obs), name, length, width, coords, isLargeEnough, metersPerPixel));
      dispatch(CHANGE_OBSTRUCTION_HEIGHT(selectedRoofId, Number(obs), newObsInfo.height));  
    })
  }
};


export const hideObstructions = () => {
  const {projectConfiguration: {productId, projectEnvConfig: {building_code}},} = state();
  return isResidentialProduct(productId) || (!isASCE716BuildingCode(building_code) && isGroundProduct(productId))
}