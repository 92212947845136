import { createReducer } from '__common/utils/helpers';
import { InfoModalActionTypes } from './InfoModalActionsConstants';

export let productDisabledInfoState: productDisabledInfoState = {
  isOpen: false,
  title: '',
  content: '',
  onConfirmAction: null,
  onConfirmCallback: null,
};

export default {
  productDisabledInfo: createReducer(productDisabledInfoState, {
    [InfoModalActionTypes.OPEN_INFO_MODAL](state, action) {
      const { title, content, onConfirmCallback, onConfirmAction } = action.payload;
      return { ...state, title, content, onConfirmCallback, onConfirmAction, isOpen: true };
    },
    [InfoModalActionTypes.HIDE_INFO_MODAL](state) {
      return {
        ...state,
        isOpen: false,
        title: '',
        content: '',
        onConfirmCallback: null,
        onConfirmAction: null,
      };
    },
  }),
};
