import React from 'react';
import { connect } from 'react-redux';

type Props = {
  lastProjectSave: number,
};

type State = {
  currentTime: number,
};

class LastEditorSave extends React.Component<Props, State> {
  interval;

  state = {
    currentTime: 0,
  };

  UNSAFE_componentWillMount() {
    this.setState({ currentTime: new Date().getTime() });

    this.interval = setInterval(() => this.setState({ currentTime: new Date().getTime() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { lastProjectSave } = this.props;
    const { currentTime } = this.state;
    const time = Number((currentTime - lastProjectSave) / 1000);
    if (lastProjectSave) {
      return (
        <div className="last-project-save">
         Last editor save {time < 0 ? 0 : time.toFixed(0)} sec ago
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(appState: appState) {
  return {
    lastProjectSave: appState.saveLoadProject.lastProjectSave,
  };
}

export default connect(mapStateToProps)(LastEditorSave);
