import { DimensionsActionTypes } from 'actionsConstants';

export function SET_DIMENSIONS(ns, ew) {
  return {
    type: DimensionsActionTypes.SET_DIMENSIONS,
    payload: {
      dimensions: {
        ns,
        ew,
      },
    },
  };
}

export function RESET() {
  return {
    type: DimensionsActionTypes.RESET,
  };
}
