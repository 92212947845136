import { createLegend, LegendZone } from './legends';
import { ROOF_PITCH } from '../../tiltedRoof/roofPitch';

export const gableRoofLegend = (roofPitch: string) => {
  if (!roofPitch || roofPitch === '---') {
    return null;
  }
  
  const degressAngle = ROOF_PITCH[roofPitch];
  
  let legend: LegendZone[];

  if (degressAngle <= 45) {
    legend = [{
      zone: 3,
      name: '3e',
    }, {
      zone: 2,
      name: '2n, 3r',
    }, {
      zone: 1,
      name: '1, 2e, 2r',
    }];
  }

  if (degressAngle <= 27) {
    legend = [{
      zone: 3,
      name: '3r',
    }, {
      zone: 2,
      name: '2n, 2r, 3e',
    }, {
      zone: 1,
      name: '1, 2e',
    }];
  }
  
  if (degressAngle <= 20) {
    legend = [{
      zone: 3,
      name: '3r',
    }, {
      zone: 2,
      name: '2n, 2r, 3e',
    }, {
      zone: 1,
      name: '1, 2e',
    }];
  }




  return createLegend(legend);
};

