import _ from 'lodash';
import { getRoofZonesEdges, getZoneDirection } from '../roofZonesHelper';
import { findPerpendicularLines, line } from '__common/calculations/prependicularLines';
import { RoofZonesAreas, RoofZonesCorner } from '__editor/panelsEditor/components/roofZones/roofZones';
import { edge, edgePoint, adjacentEdge, getEdgePoints } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { isResidentialProduct, isCommercialProduct, isSF } from '__common/constants/products';
import { state } from '__common/store';
import { EDGES_TYPE } from './edgesType';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints'; 
import { needNewZoneClassification } from '__common/utils/versionCompare/versionCompare';
import { _isSFM } from '../../cursor/utils/snapToGridHelper';


// If we're talking abour roof edges, we want to have edgesAndCorners insideOfPolygon,
// because this usually means wind zones or roof zones.
// But if we're talking about obstructions they actually have the area around them, 
// so the insideOfPolygon flag is false
// 
// ╔══════════════════════════╗
// ║                          ║         ═  outside of polygon
// ║   ┌------------------┐   ║         -- polygon
// ║   |   obstruction    |   ║
// ║   |                  |   ║
// ║   └------------------┘   ║
// ║                          ║
// ╚══════════════════════════╝

export const getEdgesAndCorners = (
  roofId: string|number, 
  objectPolygon: PolygonInterface, 
  distance: number, 
  obstructionsEdgesPolygon: PolygonInterface, 
  insideOfPolygon: boolean,
  obstructionId?: string,
): RoofZonesAreas => {
  const { projectConfiguration: { productId } } = state();

  let corners: RoofZonesCorner[] = [];
  const edges: edge[] = [];

  let objectPolygonPxPoints: [edgePoint, edgePoint][];

  if (objectPolygon) {
    objectPolygonPxPoints = getRoofZonesEdges(objectPolygon.points);
  }

  const { leadEdgeRoofSelector: { leadEdges } } = state();
  const southEdgeIndex = roofId !== null ?  leadEdges[roofId]:Object.values(leadEdges)[0];
  const { projectConfiguration: { projectVersion } } = state();
  let useNewZoneClassification = needNewZoneClassification(projectVersion);
  const southEdgeNeighbors = (objectPolygonPxPoints.length > 0 && (!!southEdgeIndex ||  (southEdgeIndex===0 && useNewZoneClassification && !isSF(productId))) && insideOfPolygon) ? 
    findEdgesAdjacentToSouthEdge(objectPolygonPxPoints, southEdgeIndex, distance) : 
    // blank design doesn't have any edges.
    [];
    
  if (objectPolygonPxPoints) {
    objectPolygonPxPoints.map((points, edgeIndex, allEdges) => {
      const lines: line[] = findPerpendicularLines(points[0], points[1], distance, obstructionsEdgesPolygon, insideOfPolygon);
      const edgeType = getEdgeType(edgeIndex, southEdgeIndex, southEdgeNeighbors);

      lines && lines.map((line) => {

        const firstPoint: edgePoint =  JSON.parse(JSON.stringify(points[0]));
        const secondPoint: edgePoint = JSON.parse(JSON.stringify(points[1]));
        const thirdPoint: edgePoint = JSON.parse(JSON.stringify(line[0]));
        const fourthPoint: edgePoint = JSON.parse(JSON.stringify(line[1]));

        firstPoint.index = edgeIndex;
        secondPoint.index = edgeIndex;
        thirdPoint.index = edgeIndex;
        fourthPoint.index = edgeIndex;
        
        const edge: edge = [secondPoint, firstPoint, thirdPoint, fourthPoint, edgeType];
        if (!insideOfPolygon) edge.push(obstructionId);
        edges.push(edge);

        const zone = getZoneDirection(line);

        if (isCommercialProduct(productId)) {
          // We're creating two corners from the same edge. 
          // First is the starting point, and the second
          // is the ending point.
          // Therefore, we can't use the same edges for adjacentEdges.
          // For point 0, next edge is the same as previous edge for point 1.
          //
          //           ┌-------┐
          //           │       │
          //           │       │
          // point 1 > └-------┘ < point 0 of edge 0
          // of edge 0     ^ edge 0.
          // 
          // --------------------------------------
          // E.g. For point 0:
          // │       │ <- this is the previous edge
          // └-------○
          //     ^ this is the next edge
          // 
          // For point 1:
          // this -> │       │ 
          // is the  ○-------┘
          // next edge   ^ this is the previous edge

          const nextEdgeIndex = allEdges[edgeIndex + 1] ? edgeIndex + 1 : 0;
          const previousEdgeIndex = edgeIndex === 0 ? allEdges.length - 1 : edgeIndex - 1;

          corners.push({
            pos: points[0],
            distance,
            zone,
            index: edgeIndex,
            adjacentEdges: [
              createAdjacentEdge(previousEdgeIndex, getEdgeType(previousEdgeIndex, southEdgeIndex, southEdgeNeighbors)),
              createAdjacentEdge(edgeIndex, getEdgeType(edgeIndex, southEdgeIndex, southEdgeNeighbors)), 
            ],
            ...(!insideOfPolygon? { obstructionId }: {}),
          });
  
          corners.push({
            pos: points[1],
            distance,
            zone,
            index: edgeIndex,
            adjacentEdges: [
              createAdjacentEdge(edgeIndex, getEdgeType(edgeIndex, southEdgeIndex, southEdgeNeighbors)),
              createAdjacentEdge(nextEdgeIndex, getEdgeType(nextEdgeIndex, southEdgeIndex, southEdgeNeighbors)), 
            ],
            ...(!insideOfPolygon? { obstructionId }: {}),
          });
        }

        if (isResidentialProduct(productId)) {
          const adjacentEdgeIndex = allEdges[edgeIndex + 1] ? edgeIndex + 1 : 0;

          corners.push({
            pos: points[1],
            distance,
            zone,
            index: edgeIndex,
            edge: edgeType,
            adjacentEdges: [
              createAdjacentEdge(edgeIndex, getEdgeType(edgeIndex, southEdgeIndex, southEdgeNeighbors)),
              createAdjacentEdge(adjacentEdgeIndex, getEdgeType(adjacentEdgeIndex, southEdgeIndex, southEdgeNeighbors)), 
            ],
            ...(!insideOfPolygon? { obstructionId }: {}),
          });
        }
      });
    });
  }
  

  
  if (corners.length && isCommercialProduct(productId)) {
    // this is to combine zones for corners. I.e, before that 
    // there were two corners in the same position. One with zone N, and one with W.
    // After that the zones are combined, like NW.
    //
    //  NW ----- N ----- NE
    //  |                 |
    //  W                 E
    //  |                 |
    //  SW ----- S ----- SE
    //
    const groupedCorners = _.groupBy(corners, 'pos');
    corners = Object.entries(groupedCorners).map(([groupId, corners]) => {
      const corner = corners[0];
      if (corners[0]?.zone === corners[1]?.zone) {
        corner.zone = corners[0]?.zone;
      } else {
        corner.zone = `${corners[0]?.zone}${corners[1]?.zone}`;
      }
      
      return corner;
    });
  }

  // we have to assign adjacent edges after the double corners have been grouped (for commercial only).
  // otherwise the indexes won't match.
  corners = corners.map((corner, index, all): RoofZonesCorner => {
    // e.g. Corner 1 should have edges 0 and 1. 
    // Therefore the next edge index corresponds to the corner's index.
    // If the corner index is 0, then the previous edge should be the next edge index from the last corner.
    const [previousEdge, nextEdge] = corner.adjacentEdges;
    const previousEdgeIndex = previousEdge.index;
    const nextEdgeIndex = nextEdge.index;
    const previousCornerIndex = index === 0 ? all.length - 1 : index - 1;
    const nextCornerIndex = index === all.length - 1 ? 0 : index + 1;

    return {
      ...corner,
      adjacentEdges: [
        {
          ...previousEdge,
          points: getEdgePoints(edges[previousEdgeIndex]),
        },
        {
          ...nextEdge,
          points: getEdgePoints(edges[nextEdgeIndex]),
        },
      ],
      siblingCorners: [all[previousCornerIndex], all[nextCornerIndex]],
    };
  });

  return {
    corners,
    edges,
  };
};


function createAdjacentEdge(index: number, edgeType: EDGES_TYPE): adjacentEdge {
  return {
    index,
    edgeType,
  };
}

function getEdgeType(edgeIndex: number, southEdgeIndex: number, southEdgeNeighbors: number[]): EDGES_TYPE {
  const isSouth = edgeIndex === southEdgeIndex;
  const isAdjacentToSouthEdge = southEdgeNeighbors.includes(edgeIndex);

  let edgeType = isSouth ? EDGES_TYPE.EAVE : EDGES_TYPE.RIDGE;
  edgeType = isAdjacentToSouthEdge ? EDGES_TYPE.RAKEGABLE : edgeType;

  return edgeType;
}

const findEdgesAdjacentToSouthEdge = (
  edges: [edgePoint, edgePoint][],  
  southEdgeIndex: number, 
  windZoneDistance: number,
): number[] => {
  // when there is a short edge that is adjacent to the south edge
  // this edge and its neighbour and its neighbours neighbour should be treated as the same edge type.
  //
  //  B
  //  ┌───
  //  │
  // ┌┘ <-- these all (from point A to point B) should be treated as an one edge
  // │
  // └───
  // A
  const iterator = getIterator(edges.length);

  const southEdgePrevNeighbourIndex = iterator.previous(southEdgeIndex);
  const southEdgeNextNeighbourIndex = iterator.next(southEdgeIndex);

  const adjacentIndexes = [
    southEdgePrevNeighbourIndex,
    southEdgeNextNeighbourIndex,
  ];

  let i = southEdgePrevNeighbourIndex;
  while (iterator.previous(i) !== southEdgePrevNeighbourIndex) {
    i = iterator.previous(i);
    const [start, end] = edges[i]; 
    const edgeDistance = calcDistanceOfTwoPoints(start, end);
    if (edgeDistance > windZoneDistance) {
      break;
    }

    adjacentIndexes.push(i);
    adjacentIndexes.push(iterator.previous(i));
  }

  i = southEdgeNextNeighbourIndex;
  while (iterator.next(i) !== southEdgeNextNeighbourIndex) {
    i = iterator.next(i);
    const [start, end] = edges[i]; 
    const edgeDistance = calcDistanceOfTwoPoints(start, end);
    if (edgeDistance > windZoneDistance) {
      break;
    }

    adjacentIndexes.push(i);
    adjacentIndexes.push(iterator.next(i));
  }

  return adjacentIndexes;
};

const getIterator = (setLength: number) => ({
  next: (currentIndex: number): number => (currentIndex === setLength - 1 ? 0 : currentIndex + 1),
  previous: (currentIndex: number): number => (currentIndex === 0 ? setLength - 1 : currentIndex - 1),
});
