import * as React from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { saveBom } from '../utils/saveBom';


type Props = {
  dispatch: Function,
  bom: bomState,
  railsSelector: railsSelectorState,
  productId: string,
  projectId: string,
};

class SaveBom extends React.Component<Props, {}> {
  onClick = () => {
    saveBom(false);
  }

  render() {
    return (
      <div role="button" onClick={this.onClick} className="continue-button">
      continue
      <SVG src={require('assets/media/icons/forward_arrow_icon.svg')} />
    </div>);
  }
}

function mapPropTypes(state: appState) {
  return {
    bom: state.bom,
    railsSelector: state.railsSelector,
  };
}

export default connect(mapPropTypes)(SaveBom);
