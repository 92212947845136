import { windExposureOption } from '../fields/types/windExposure';

interface WindExposureTooltipConfig {
  inTownEnabled?: boolean;
  openTerrainEnabled?: boolean;
  openWaterEnabled?: boolean;
}

export default (config?: WindExposureTooltipConfig) => {
  const defaultConfig: WindExposureTooltipConfig = { 
    inTownEnabled: true,
    openTerrainEnabled: true,
    openWaterEnabled: true,
  };

  const tooltipConfig = {
    ...defaultConfig,
    ...config,
  };

  return `
  <div>
    ${tooltipConfig.inTownEnabled ? getInTownOption() : ''}
    ${tooltipConfig.openTerrainEnabled ? getOpenTerrainOption() : ''}
    ${tooltipConfig.openWaterEnabled ? getOpenWaterOption() : ''}
  `;
};

const getInTownOption = () => `
  <div class="sub-header">
    <strong>In Town</strong> (Exposure ${windExposureOption.inTown.value}):
  </div>
  <div class="content">
    Urban and suburban areas, wooded areas, or other terrain with numerous
    closely spaced obstructions having the size of single family dwellings.
  </div>
`;

const getOpenTerrainOption = () => `
  <div class="sub-header">
    <strong>Open Terrain</strong> (Exposure ${windExposureOption.openTerrain.value}):
  </div>
  <div class="content">
    Has open terrain with scattered obstructions having heights generally less
    than 30 feet. This category includes flat open country, grasslands and all
    water surfaces in hurricane prone regions.</div>
  </div>
`;

const getOpenWaterOption = () => `
  <div class="sub-header">
    <strong>Open Water</strong> (Exposure ${windExposureOption.openWater.value}):
  </div>
  <div class="content">
    Flat, unobstructed areas and water surfaces. This category includes smooth
    mud flats, salt flats, and unbroken ice."
  </div>
`;
