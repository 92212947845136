
import { DrawPanelFunction } from './draw_on_area';
import { getDoublePanelsWithGapsPopulatingDataAscender } from './populating';
import nextUid from '__common/calculations/nextUid';
import { state } from '__common/store';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { PANEL_EW_POSITION } from '../../cursor/utils/snapToGridStickyPoints';
import { is2PStructureType, StructureType } from 'projectDesign/components/projectConfiguration/fields/types/structureType';

export const gapLengthMeters = inchesToMeters(12);
export const ascenderTableLength = 12;


export const isElevatedStructureType = (structureType: number) =>  [StructureType.Two_P_Elevated,  StructureType.One_P_Elevated].includes(Number(structureType));

export const drawAscenderPanelsInTables = (
  direction: string, 
  points: { x: number, y: number }[], 
  drawFn: DrawPanelFunction, 
  groupId: number | null,
): panelInState[] => {

  switch (direction) {
    case 'SE':
      return draw_SE(points, drawFn);
    case 'NW':
      return draw_NW(points, drawFn);
    case 'NE':
      return draw_NE(points, drawFn);
    case 'SW':
      return draw_SW(points, drawFn);
    default:
      return draw_SE(points, drawFn);
  }
};

const draw_SE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    projectConfiguration: {projectEnvConfig: {structure_type}}, 
    settings: { 
      columnSpacing,
      rowSpacing,
      tableRowSpacing
    }, 
    background: { 
      metersPerPixel,
    }, 
  } = state();

  let panels_in_column = 1
  if (is2PStructureType(structure_type)) {
    panels_in_column = 2;
  }
  const { 
    topEdge, 
    leftEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopulatingDataAscender(points, ascenderTableLength, panels_in_column);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = panelsInRow;
  const panelPairColumns = Math.ceil(panelsInColumn / panels_in_column);
  const initialX = leftEdge;
  let x: number;
  let y: number;
  let panels: panelInState[] = []; 
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = topEdge + (panelHeight * panels_in_column * i) + (tableRowSpacingPx * i) + (gapPx * i);
    for (let j = 0; j < readjustedPanelsinRow; j++) {
      let pairId = nextUid();
      x = initialX + (panelWidth * j) + (columnSpacingPx * j) + (gapPx * gapIndex) ;
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === ascenderTableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, rowSpacingPx, groupId, panelEWPos, drawFn, pairId, structure_type));
      tableIndex += 1;

      if (tableIndex === ascenderTableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_NW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    projectConfiguration: {projectEnvConfig: {structure_type}},
    settings: { 
      columnSpacing,
      rowSpacing,
      tableRowSpacing
    }, 
    background: { 
      metersPerPixel,
    }, 
  } = state();
  let panels_in_column = 1
  if (is2PStructureType(structure_type)) {
    panels_in_column = 2;
  }
  const { 
    bottomEdge, 
    rightEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopulatingDataAscender(points, ascenderTableLength, panels_in_column);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = panelsInRow;
  const panelPairColumns = Math.ceil(panelsInColumn / panels_in_column);
  const initialX = rightEdge;
  let x: number;
  let y: number;

  let panels: panelInState[] = [];
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = bottomEdge - (panelHeight * panels_in_column * i) - (tableRowSpacingPx * i) - (gapPx * i);
    for (let j = 0; j < readjustedPanelsinRow; j++) {
      let pairId = nextUid();
      x = initialX - (panelWidth * j) - (columnSpacingPx * j)  - (gapPx * gapIndex);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === ascenderTableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, rowSpacingPx, groupId, panelEWPos, drawFn, pairId, structure_type));
      tableIndex += 1;

      if (tableIndex === ascenderTableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_NE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    projectConfiguration: {projectEnvConfig: {structure_type}},
    settings: { 
      columnSpacing,
      rowSpacing,
      tableRowSpacing
    }, 
    background: { 
      metersPerPixel,
    },
  } = state();

  let panels_in_column = 1
  if (is2PStructureType(structure_type)) {
    panels_in_column = 2;
  }
  const { 
    bottomEdge, 
    leftEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopulatingDataAscender(points, ascenderTableLength, panels_in_column);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = panelsInRow;
  const panelPairColumns = Math.ceil(panelsInColumn / panels_in_column);
  const initialX = leftEdge;
  let x: number;
  let y: number;
  let panels: panelInState[] = [];
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = bottomEdge - (panelHeight * panels_in_column * i) - (tableRowSpacingPx * i) - (gapPx * i);
    for (let j = 0; j < readjustedPanelsinRow; j++) {
      let pairId = nextUid();
      x = initialX + (panelWidth * j) + (columnSpacingPx * j)  + (gapPx * gapIndex);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === ascenderTableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, rowSpacingPx, groupId, panelEWPos, drawFn, pairId, structure_type));
      tableIndex += 1;

      if (tableIndex === ascenderTableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_SW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    projectConfiguration: {projectEnvConfig: {structure_type}},
    settings: { 
      columnSpacing,
      rowSpacing,
      tableRowSpacing
    }, 
    background: { 
      metersPerPixel,
    }, 
  } = state();
  let panels_in_column = 1
  if (is2PStructureType(structure_type)) {
    panels_in_column = 2;
  }
  const { 
    topEdge, 
    rightEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopulatingDataAscender(points, ascenderTableLength, panels_in_column);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = panelsInRow;
  const panelPairColumns = Math.ceil(panelsInColumn / panels_in_column);
  const initialX = rightEdge;
  let x: number;
  let y: number;
  let panels: panelInState[] = []; 
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = topEdge + (panelHeight * panels_in_column * i) + (tableRowSpacingPx * i) + (gapPx * i);
    for (let j = 0; j < readjustedPanelsinRow; j++) {
      let pairId = nextUid();
      x = initialX - (panelWidth * j) - (columnSpacingPx * j)  - (gapPx * gapIndex);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === ascenderTableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, rowSpacingPx, groupId, panelEWPos, drawFn, pairId,structure_type));
      tableIndex += 1;

      if (tableIndex === ascenderTableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

function drawPanels(x: number, y: number, panelWidth: number, panelHeight: number, rowSpacingPx: number, groupId: number, panelEWPos: number, drawFn: DrawPanelFunction, pairId: number, panelConfig: number): panelInState[] {
  const { 
    projectConfiguration: {projectEnvConfig: {structure_type}},  
  } = state();

  let panels_in_column = 1
  if (is2PStructureType(structure_type)) {
    panels_in_column = 2;
  }
  const panels: panelInState[] = [];
  const siblingId = nextUid();

  panels.push(drawFn(x, y, groupId, siblingId, 0, panelEWPos, 0, 0, false, pairId, panelConfig, true));
  if (panels_in_column === 2){
    panels.push(drawFn(x, y + panelHeight + rowSpacingPx, groupId, siblingId, 1, panelEWPos, 0, 0, false, pairId, panelConfig, true));
  }
  return panels;
}
