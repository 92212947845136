import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';

export const apiField = 'parapet_height_num';

export default {
  type: 'Number',
  label: 'parapetHeightMetric',
  apiField,
  tooltip: parapetHeightTooltip,
  min: 0,
  max: 0.9,
  missing: undefined,
};