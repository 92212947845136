import React from 'react';
import { missingLocationAndLoadsPageValid, projectConfigurationWarningConditionValid } from '../utils/validation';
import { missingPageWarning } from './components/missingPageWarning';
import { projectConfigurationWarning } from './components/projectConfigurationWarning';
import { renderInputs } from './renderInputs';
import { dispatch } from '__common/store';
import { SET_PROJECT_OPTION } from 'actions';
import velocityPressureExposureCoefficientKz from '../fields/velocityPressureExposureCoefficientKz';
import { calculateKe, calculateKz, get_numerical_coefficient, get_design_life_factor, getFrictionFactor } from '../utils/coefficients';
import groundElevationFactorKe from '../fields/groundElevationFactorKe';
import windDirectionalityFactorKd from '../fields/windDirectionalityFactorKd';
import { isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import designLifeFactorFc from '../fields/designLifeFactorFc';
import numericalCoefficient from '../fields/numericalCoefficient';
import { apiField as roofExposureApiField, RoofExposureOption } from '../fields/types/roofExposure';
import { apiField as soilClassApiField } from '../fields/soilClass';
import { apiField as thermalFactorApiField } from '../fields/thermalFactor';
import { apiField as frictionFactorApiField } from '../fields/frictionFactor';
import { apiField as loadSharingTypeApiField, loadSharingOptions } from '../fields/loadSharingType';


import ResetChangesButton from '__common/components/Drawer/components/ResetChangesButton';
import { isRMGridFlex, isRmGridflex10 } from '__common/constants/products';

export const additionalUserInputsPage = (
  currentConfig: Function,
  productId: number,
  initData: projectEnvConfig,
  mapType: string,
  location: string,
  envFactorsLoading: boolean,
  area: string,
  getErrorMessageIfInvalid: (fieldName: string) => string | null,
  inputUnit: number,
) => {
  const buildingCode = initData['building_code'];
  const config = currentConfig(buildingCode);
  const resetInputData = () => {
    const { wind_exposure, building_height, elevation, mean_recurrence_interval, zipcode, roof_type } = initData;
    dispatch(SET_PROJECT_OPTION(velocityPressureExposureCoefficientKz.apiField, calculateKz(wind_exposure, building_height, zipcode, '', inputUnit)));
    dispatch(SET_PROJECT_OPTION(groundElevationFactorKe.apiField, calculateKe(elevation, isASCE716or722(buildingCode), inputUnit)));
    dispatch(SET_PROJECT_OPTION(windDirectionalityFactorKd.apiField, 0.85));
    dispatch(SET_PROJECT_OPTION(topographicalFactorKzt.apiField, 1));
    dispatch(SET_PROJECT_OPTION(designLifeFactorFc.apiField, get_design_life_factor(Number(mean_recurrence_interval))));
    dispatch(SET_PROJECT_OPTION(numericalCoefficient.apiField, get_numerical_coefficient(buildingCode, elevation, inputUnit) ));
    dispatch(SET_PROJECT_OPTION(roofExposureApiField, RoofExposureOption.partiallyExposed.value));
    dispatch(SET_PROJECT_OPTION(soilClassApiField, 4));
    if (isRmGridflex10(productId), isRMGridFlex(productId)){
      dispatch(SET_PROJECT_OPTION(thermalFactorApiField, isASCE722(buildingCode)?1.2:1));
      dispatch(SET_PROJECT_OPTION(frictionFactorApiField, getFrictionFactor(Number(roof_type), productId)));
      dispatch(SET_PROJECT_OPTION(loadSharingTypeApiField, loadSharingOptions.default.value));
    }
  }
  return {
    content: (
      <>
        <div className="drawer-section-title">Additional User Inputs</div>
        {missingLocationAndLoadsPageValid(productId)
          ? null
          : missingPageWarning()}
        <div className="drawer-fields">
          <div className="drawer-section-subtitle">
            Wind Pressure Inputs
          </div>
          <div>{renderInputs(config.WindPressureInputs, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
        </div>
        <br></br>
        <div className="drawer-fields">
          <div className="drawer-section-subtitle">
            Snow Load Inputs
          </div>
          <div>{renderInputs(config.SnowLoadInputs, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
        </div>
        <br></br>
        {
          isRmGridflex10(productId) || isRMGridFlex(productId)?
          <>
          <div className="drawer-fields">
              <div className="drawer-section-subtitle">
                Load Sharing
              </div>
              <div>{renderInputs(config.LoadSharing, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
            </div><br></br>
            </>
        :<></>
        }
        {config.SeismicInputs && <div className="drawer-fields">
          <div className="drawer-section-subtitle">
            Seismic Inputs
          </div>
          <div>{renderInputs(config.SeismicInputs, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
        </div>}
        <ResetChangesButton label='RESET' onClick={resetInputData} />
        {projectConfigurationWarningConditionValid(productId) ? projectConfigurationWarning() : null}
      </>
    ),
  };
};
