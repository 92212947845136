import config from 'local_config';
import EngineeringProductDocuments from 'engineering/components/engineeringProductDocuments';
import EngineeringProjectDocumentsComponent from 'engineering/components/engineeringProjectDocuments/engineeringProjectDocumentsComponent';
import Loader from '__common/components/LoaderComponent';
import React from 'react';
import { products, isGFT, isRMGridFlex, isSolarMount, isNxtTilt, isSMTilt } from '__common/constants/products';
import { state } from '__common/store';
import { ReportTypes, page } from 'engineering/components/engineeringProjectReportsMenu';
import { SharedRail, _4Rail } from './gftRailInstallationGuide';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

enum ReportsMenuTitles {
  bom = 'BILL OF MATERIALS',
  engineeringReport = 'ENGINEERING REPORT',
  layout = 'LAYOUT',
  internalEngReport = 'INTERNAL ENGINEERING REPORT',
  detailedEngReport = 'DETAILED ENGINEERING REPORT',
  projectOverview = 'PROJECT OVERVIEW',
  peStamp = 'PE STAMP REQUEST'
}
function shouldDisplayInternalEngineeringReport(isStaff: boolean, productId: string) {
  return isStaff && isGFT(products[productId]);
}


function shouldNotDisplayEngineeringReport(isStaff: boolean, productId: string , projectVersion: string) {
  return (isNxtTilt(products[productId]) || (adjustableTiltSystem(projectVersion) && isSMTilt(products[productId])));
}

function shouldDisplayDetailedEngineeringReport(isStaff: boolean, productId: string) {
  return (isStaff && (isGFT(products[productId]) || isSolarMount(products[productId])));
}

function shouldDisplayPeStampReport(isStaff: boolean, productId: string) {
  return isStaff;
}

export function getProductsDocuments(productId: string, projectId: string, isStaff: boolean, railArrangementType: number, ascender_structure_types: number[], projectConfiguration: projectConfigurationState) {
  const { apiUrl } = config;
  let docs = EngineeringProductDocuments.getDocuments(products[productId], ascender_structure_types, projectConfiguration);
  if (isGFT(products[productId]) && railArrangementType) {
    docs = docs.filter(([docName]) =>
      docName === _4Rail.value ?
        _4Rail.options.includes(railArrangementType) :
        docName === SharedRail.value ?
          SharedRail.options.includes(railArrangementType) :
          true,
    );
  }
  const staticDocuments: page[] = docs.map((document: [string, string], index) => {
    const name = document[0];
    const pdfPlace = document[1];

    if (name === 'CODE COMPLIANT ENGINEERING LETTER - NM') {
      const { engineering: { compilantLetter } } = state();
      return {
        name: compilantLetter.name,
        active: index === 0,
        type: ReportTypes.productDocuments,
        content: (<><Loader /><embed id="pdfs-content" key={index} width="100%" height="100%" src={`${location.protocol}//${apiUrl}${compilantLetter.relative_url}`} /></>),
      };
    }

    if (name.toLowerCase().includes('L-Foot Install'.toLocaleLowerCase())) {
      return {
        name,
        active: index === 0,
        type: ReportTypes.productDocuments,
        content: (<><Loader /><img id="pdfs-content" key={index} src={`${location.protocol}//${apiUrl}static/pdfs/${pdfPlace}`} /></>),
      };
    }

    return {
      name,
      active: index === 0,
      type: ReportTypes.productDocuments,
      content: (<><Loader /><embed id="pdfs-content" key={index} width="100%" height="100%" src={`${location.protocol}//${apiUrl}static/pdfs/${pdfPlace}`} /></>),
    };
  });

  let generatedDocuments: page[] = [
    {
      name: ReportsMenuTitles.bom,
      content: <EngineeringProjectDocumentsComponent type="bom" projectId={projectId} />,
      active: true,
      type: ReportTypes.projectReports,
    },
    // {
    //   name: ReportsMenuTitles.engineeringReport,
    //   content: <EngineeringProjectDocumentsComponent type="engineering" projectId={projectId} />,
    //   type: ReportTypes.projectReports,
    // },
    {
      name: ReportsMenuTitles.layout,
      content: <EngineeringProjectDocumentsComponent type="layout" projectId={projectId} />,
      type: ReportTypes.projectReports,
    },
  ];

  if (!shouldNotDisplayEngineeringReport(isStaff, productId, projectConfiguration.projectVersion)) {
    generatedDocuments.splice(1, 0, {
      name: ReportsMenuTitles.engineeringReport,
      content: <EngineeringProjectDocumentsComponent type="engineering" projectId={projectId} projectVersion={projectConfiguration.projectVersion}/>,
      type: ReportTypes.projectReports,
    });
  }

  if (shouldDisplayInternalEngineeringReport(isStaff, productId)) {
    generatedDocuments.splice(2, 0, {
      name: ReportsMenuTitles.internalEngReport,
      content: <EngineeringProjectDocumentsComponent type="internal engineering" projectId={projectId} />,
      type: ReportTypes.projectReports,
    });
  }

  if (shouldDisplayPeStampReport(isStaff, productId)) {
    generatedDocuments.splice(3, 0, {
      name: ReportsMenuTitles.peStamp,
      content: <EngineeringProjectDocumentsComponent type="pe_stamp" projectId={projectId} />,
      type: ReportTypes.projectReports,
    });
  }

  if (shouldDisplayDetailedEngineeringReport(isStaff, productId)) {
    generatedDocuments.splice(2, 0, {
      name: ReportsMenuTitles.detailedEngReport,
      content: <EngineeringProjectDocumentsComponent type="detailed_engineering" projectId={projectId} />,
      type: ReportTypes.projectReports,
    });
  }

  if (isRMGridFlex(products[productId])) {
    generatedDocuments.splice(3, 0, {
      name: ReportsMenuTitles.projectOverview,
      content: <EngineeringProjectDocumentsComponent type="engineering_summary" projectId={projectId} />,
      type: ReportTypes.projectReports,
    });
  } 
  generatedDocuments = generatedDocuments.concat(staticDocuments);

  return generatedDocuments;
}
