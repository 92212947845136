import { AdvanceRoofSelectingActionTypes } from 'actionsConstants';

export function ADD_ROOF_POINT(coords: pixelPoint) {
  return {
    type: AdvanceRoofSelectingActionTypes.ADD_ROOF_POINT,
    payload: {
      x: coords.x,
      y: coords.y,
    },
  };
}

export function CLEAR_ROOF_POINTS() {
  return {
    type: AdvanceRoofSelectingActionTypes.CLEAR_ROOF_POINTS,
  };
}

export function TERMINATE_ADVANCE_ROOF_SELECTING() {
  return {
    type: AdvanceRoofSelectingActionTypes.TERMINATE_ADVANCE_ROOF_SELECTING,
  };
}

export function TOGGLE_ADVANCE_ROOF_SELECTING() {
  return {
    type: AdvanceRoofSelectingActionTypes.TOGGLE_ADVANCE_ROOF_SELECTING,
  };
}

export function ADD_ROOF_LAST_POINT(coords: pixelPoint) {
  return {
    type: AdvanceRoofSelectingActionTypes.ADD_ROOF_LAST_POINT,
    payload: {
      x: coords.x,
      y: coords.y,
    },
  };
}

export function ADD_TEMP_POINT(coords: pixelPoint) {
  return {
    type: AdvanceRoofSelectingActionTypes.ADD_TEMP_POINT,
    payload: {
      x: coords.x,
      y: coords.y,
    },
  };
}

export function CLEAR_TEMP_POINT() {
  return {
    type: AdvanceRoofSelectingActionTypes.CLEAR_TEMP_POINT,
  };
}

export function TURN_ON_ROOF_SELECTING() {
  return {
    type: AdvanceRoofSelectingActionTypes.TURN_ON_ROOF_SELECTING,
  };
}

export function TURN_OFF_ROOF_SELECTING() {
  return {
    type: AdvanceRoofSelectingActionTypes.TURN_OFF_ROOF_SELECTING,
  };
}

export function TURN_ON_BANNERS() {
  return {
    type: AdvanceRoofSelectingActionTypes.TURN_ON_BANNERS,
  };
}

export function TURN_OFF_BANNERS() {
  return {
    type: AdvanceRoofSelectingActionTypes.TURN_OFF_BANNERS,
  };
}
