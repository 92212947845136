import { createReducer } from '__common/utils/helpers';
import { RubberBandActionTypes } from 'actionsConstants';


export const rubberBandState: rubberBandState = {
  points: [],
  start: { x: 0, y: 0 },
  inited: false,
  panelsShadow: [],
  panelsToRemove: [],
  toolTipData: {
    rows: 0,
    columns: 0,
  },
  mode: 'draw',
};

export default {
  rubberBand: createReducer(rubberBandState, {
    [RubberBandActionTypes.START](state: rubberBandState, action): rubberBandState {
      const start = action.payload.start;
      return { ...state, start, inited: true };
    },
    [RubberBandActionTypes.RESIZE](state: rubberBandState, action): rubberBandState {
      const points = action.payload.points;
      return { ...state, points };
    },
    [RubberBandActionTypes.RESET_RUBBERBAND_STATE](state: rubberBandState): rubberBandState {
      return { ...rubberBandState, mode: state.mode };
    },
    [RubberBandActionTypes.ADD_SHADOW_PANELS](state: rubberBandState, action): rubberBandState {
      return { ...state, panelsShadow: action.payload.panelsShadow };
    },
    [RubberBandActionTypes.SET_DRAW_RUBBER_BAND](state: rubberBandState): rubberBandState {
      return { ...state, mode: 'draw' };
    },
    [RubberBandActionTypes.SET_REMOVE_RUBBER_BAND](state: rubberBandState): rubberBandState {
      return { ...state, mode: 'remove' };
    },
    [RubberBandActionTypes.SET_PANELS_TO_HIDE_ON_REMOVE](state: rubberBandState, action): rubberBandState {
      return { ...state, panelsToRemove: action.payload.panelsToRemove };
    },
    [RubberBandActionTypes.DISABLE_RUBBER_BAND](state: rubberBandState): rubberBandState {
      return { ...state, mode: 'disabled' };
    },
  }),
};
