import * as React from 'react';
import Attachments from 'bom/components/attachmentsSelector/components/attachments';
import AttachmentTypes from 'bom/components/attachmentsSelector/components/attachmentType';
import LagBolts from 'bom/components/attachmentsSelector/components/lagBolts';
import { connect } from 'react-redux';
import { isSfmFamilyName } from '__common/constants/products';


class AttachmentSelector extends React.Component<attachmentsSelectorComponentState, {}> {
  renderNonSFMAttachments() {
    const { bom: { attachmentsList, selectedAttachmentType }, dispatch } = this.props;
    const selectedGroup = Object.keys(attachmentsList).filter(type => attachmentsList[type].selected)[0];
    return (
      <div className="attachments_selector">
        <div className="control-panel-label bom-sidebar-main-header roof-attachments-header">
            Select roof attachments
        </div>
        <AttachmentTypes dispatch={dispatch} attachmentTypes={Object.keys(attachmentsList)} selectedGroup={selectedGroup} />
        {Object.keys(selectedAttachmentType).length &&
          Object.keys(selectedAttachmentType).map((attachments, index) => {
            if (Array.isArray(selectedAttachmentType[attachments]) && selectedAttachmentType[attachments].length) {
              if (attachments === 'parts') {
                return null;
              } 
              
              if (attachments === 'lag_bolts') {
                return <LagBolts key={attachments} dispatch={dispatch} lagBolts={selectedAttachmentType[attachments]} attachmentGroup={Object.keys(selectedAttachmentType)[index]} />;
              }
              return <Attachments key={attachments} dispatch={dispatch} attachments={selectedAttachmentType[attachments]} attachmentGroup={Object.keys(selectedAttachmentType)[index]} />;
            }
          })
        }        
      </div>
    );
  }

  renderSFMAttachments() {
    const { bom: { attachmentsList }, dispatch } = this.props;
    const selectedGroup = Object.keys(attachmentsList).filter(type => attachmentsList[type].selected)[0];

    return (
      <div className="attachments_selector">
        <AttachmentTypes dispatch={dispatch} attachmentTypes={Object.keys(attachmentsList)} selectedGroup={selectedGroup} />
      </div>
    );
  }
  

  render() {
    const { productId } = this.props;
    if (isSfmFamilyName(productId)) {
      return this.renderSFMAttachments();
    }
    return this.renderNonSFMAttachments();
  }
}

function mapStateToProps(state) {
  return {
    bom: state.bom,
  };
}

export default connect(mapStateToProps)(AttachmentSelector);
