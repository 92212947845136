import residentalBuildingTypes from './residentalBuildingTypes';
import buildingLength from './shortestBuildingLength';
import { isASCE716, isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';

export const getASCE716722BuildingDescriptionResidentalAdditionalInputs = (buildingCode: number, missing = undefined, longestBuildingLengthField={}) => 
{
  buildingLength.missing = missing;
  return (isASCE716or722(buildingCode) ? [residentalBuildingTypes, ...(isASCE722(buildingCode) ? [longestBuildingLengthField] : []), buildingLength] : [buildingLength]);
};

/** for sunframe only */
export const getASCE716BuildingDescriptionAdditionalInputsForSF = (buildingCode: number) => {
  return (isASCE716(buildingCode) ? [residentalBuildingTypes, buildingLength] : [buildingLength]);
};