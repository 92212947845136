export enum zoneClassification {
  northCorner = 33,
  southCorner = 32,
  eastWestEdge = 31,
  northEdge = 13,
  southEdge = 12,
  field = 11,
  unknown = 0,
  northSouthCornerOverlap = 34,
  northSouthEdgeOverlap = 14,
}
