import * as React from 'react';
import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import Tooltip from '__common/components/Tooltip';
import { connect } from 'react-redux';
import { SET_ROOF_ROW_SPACING, SET_ROW_SPACING } from 'actions';
import { RESET_PANELS_STATE, SET_TILTED_ROOF_STRUCTURE_TILT } from 'actions';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import { inchesToMeters } from 'maths';
import { setPanelsDimensions } from '__editor/panelsEditor/components/panels/panels';
type Props = {
  productId: number,
  dispatch: Function;
  panels: panelsState;
  tiltedRoof: tiltedRoofState;
  background: backgroundState,
  projectEnvConfig: projectEnvConfig;
  seismicDesignCategory: string;
  location: string;
  projectConfiguration : projectConfigurationState;
  selectedRoofId: number
};
class StructureTiltSelector extends React.Component<Props, {}> {
  structureTiltValues = [
    '---',
    '5 - Normal',
    '5 - Raised',
    '10',
  ];
  getStructureTiltValues = () => {
    return this.structureTiltValues;
  }
  onStructureTiltChange = (structureTilt: string) => {
    const { dispatch } = this.props;
    dispatch(RESET_PANELS_STATE());
    structureTilt = structureTilt === '5 - Normal' ? '5' : structureTilt === '5 - Raised' ? '15' : '10';
    dispatch(SET_TILTED_ROOF_STRUCTURE_TILT(structureTilt));
    if (structureTilt === '5' || structureTilt === '15' || structureTilt === '---') {
      // assuming the default row spacing when '---' is selected
      dispatch(SET_ROW_SPACING(inchesToMeters(8)));
    }else if (structureTilt === '10') {
      dispatch(SET_ROW_SPACING(inchesToMeters(12)));
    }
    setPanelsDimensions()
  }
  warningMessageForInput = (structureTilt) => {
    if (structureTilt === '---') {
      return (
        <div className="input-warning lack-of-roof-pitch">
          You should set structure tilt
        </div>
      );
    }
  }
  getLabel = () => {
    return <div className="input-label">Select Structure Tilt <Tooltip id="roof-pitch-selector" content={'Select Structure Tilt Angle'} position="bottom" /></div>;
  }
  structureTiltSelect(structureTiltValues: string[], label) {
    const {
      panels: { panels },
      tiltedRoof: { structureTilt },
      background: { bgLoaded },
    } = this.props;
    return (
      <>
        {this.warningMessageForInput(structureTilt)}
        <ConfirmationSelectField
          value={structureTilt==='5' ? '5 - Normal' : structureTilt==='15' ? '5 - Raised' : structureTilt}
          items={structureTiltValues}
          onConfirm={this.onStructureTiltChange}
          title="Are you sure you want to change the structure tilt?"
          content="Current layout will be lost."
          shouldConfirm={panels.length > 0}
          label={label}
          selectFieldProps={{
            fullWidth: true,
            id: 'roofPitch',
            name: 'select-field',
            position: SelectField.Positions.BELOW,
            listZDepth: 0,
            minBottom: 100,
            disabled: !bgLoaded,
          }}
        />
      </>
    );
  }
  render() {
    const structureTiltValues = this.getStructureTiltValues();
    const label = this.getLabel();
    return this.structureTiltSelect(structureTiltValues, label);
  }
}
function mapStateToProps(state: appState) {
  return {
    panels: state.panels,
    tiltedRoof: state.tiltedRoof,
    background: state.background,
    projectEnvConfig: state.projectConfiguration.projectEnvConfig,
    seismicDesignCategory: state.projectConfiguration.seismicDesignCategory,
    location: state.projectConfiguration.area,
    projectConfiguration : state.projectConfiguration,
    selectedRoofId: state.background.selectedRoofId,
  };
}
export default connect(mapStateToProps)(StructureTiltSelector);