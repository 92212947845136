import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import IsSimpleOp from 'jsts/org/locationtech/jts/operation/IsSimpleOp';
import GeometryGraph from 'jsts/org/locationtech/jts/geomgraph/GeometryGraph';
import ConsistentAreaTester from 'jsts/org/locationtech/jts/operation/valid/ConsistentAreaTester';

const googleMaps2JTS = function (boundaries) {
  const coordinates = [];
  const boundriesPaths = boundaries.getPath();
  for (let i = 0; i < boundriesPaths.getLength(); i++) {
    coordinates.push(new Coordinate(
      boundriesPaths.getAt(i).lat(), boundriesPaths.getAt(i).lng()));
  }
  coordinates.push(coordinates[0]);
  console.log(coordinates);
  return coordinates;
};

/**
 * findSelfIntersects
 *
 * Detect self-intersections in a polygon.
 *
 * @param {object} google.maps.Polygon path co-ordinates.
 * @return {array} array of points of intersections.
 */
export const findSelfIntersects = function (googlePolygonPath) {
  const coordinates = googleMaps2JTS(googlePolygonPath);
  const geometryFactory = new GeometryFactory();
  const shell = geometryFactory.createLinearRing(coordinates);
  const jstsPolygon = geometryFactory.createPolygon(shell);

  // if the geometry is aleady a simple linear ring, do not
  // try to find self intersection points.
  const validator = new IsSimpleOp(jstsPolygon);
  if (validator.isSimpleLinearGeometry(jstsPolygon)) {
    return;
  }

  const res = [];
  const graph = new GeometryGraph(0, jstsPolygon);
  const cat = new ConsistentAreaTester(graph);
  const r = cat.isNodeConsistentArea();
  if (!r) {
    const pt = cat.getInvalidPoint();
    res.push([pt.x, pt.y]);
  }
  return res;
};
