import * as React from 'react';
import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import { clearRoofsFromMap } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import {
  SET_BING_MAPS_ROOFS_SELECTOR,
  SET_GOOGLE_MAPS_ROOFS_SELECTOR,
  UNSELECT_ALL_SELECTED_ROOF_AND_MARKER,
} from 'actions';

type Props = {
  mapType: string,
  roofsOnMap: Object | null,
  dispatch: Function,
};

const MAP_TYPES = [{
  name: 'Google maps',
  type: 'google',
}, {
  name: 'Bing maps',
  type: 'bing',
}];

export default class RoofsSelectorMapTypeChanger extends React.Component<Props> {
  changeMapType = (newMapType: string) => {
    const { dispatch } = this.props;
    let onConfirm;

    dispatch(UNSELECT_ALL_SELECTED_ROOF_AND_MARKER());

    if (newMapType === 'google') {
      onConfirm = SET_GOOGLE_MAPS_ROOFS_SELECTOR();
    }

    if (newMapType === 'bing') {
      onConfirm = SET_BING_MAPS_ROOFS_SELECTOR();
    }

    clearRoofsFromMap();
    
    return dispatch(onConfirm);
  }

  render() {
    const { mapType, roofsOnMap } = this.props;
    
    return (
      <ConfirmationSelectField
        items={MAP_TYPES}
        value={mapType}
        onConfirm={this.changeMapType}
        title="Are you sure you want to change the background?"
        content="All array layouts will be lost."
        shouldConfirm={roofsOnMap !== null && Object.keys(roofsOnMap).length > 0}
        selectFieldProps={{
          itemLabel: 'name',
          itemValue: 'type',
          name: 'select-field',
          fullWidth: true,
          position: SelectField.Positions.BELOW,
          listZDepth: 0,
          minBottom: 100,
        }}
      />
    );
  }
}

