import Loader from '__common/components/LoaderComponent';
import React from 'react';
import throttle from 'lodash/throttle';
import YourProjectsCombineSelected from 'yourProjects/components/YourProjectsCombineSelected';
import { apiUrlUb1 } from 'local_config';
import { connect } from 'react-redux';
import { parseDate } from '__common/calculations/dateFormat';
import {
  GET_USER_PROJECTS,
  SET_YOUR_PROJECTS_PAGINATION_START,
  SET_YOUR_PROJECTS_SORTING,
  SET_YOUR_PROJECTS_TABLE_PAGE,
  SEARCH_PROJECT,
  DELETE_PROJECT,
  COPY_PROJECT,
  SET_SELECTED_PROJECTS,
} from 'actions';
import Button from 'react-md/lib/Buttons/Button';
import DataTable from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHeader from 'react-md/lib/DataTables/TableHeader';
import TablePagination from 'react-md/lib/DataTables/TablePagination';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL } from 'actions';
import TableRow from 'react-md/lib/DataTables/TableRow';
import { dispatch } from '__common/store';
import { showSuccessAlert } from '__common/modules/alerts';


type Props = {
  sortedField: string;
  sortMethod: string;
  rowsPerPage: number;
  data: any[];
  dispatch: Function;
  paginationStart: number;
  totalProjects: number;
  filterString: string;
  paginationPage: number;
  paginationTotal: number;
  selectedProjects: { [projectId: string]: boolean };
  showOldProjects: boolean;
  areLoading: boolean;
  appliedFilters: {},
};

class YourProjectsTable extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.deleteProject = throttle(this.deleteProject, 1000);
    this.copyProject = throttle(this.copyProject, 1000);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(SET_SELECTED_PROJECTS({}));
  }

  sort = (field: string) => {
    const {
      dispatch,
      rowsPerPage,
      paginationStart,
      sortedField,
      sortMethod,
      filterString,
      showOldProjects,
      appliedFilters,
    } = this.props;
    if (field === sortedField) {
      const sort = sortMethod === 'asc' ? 'desc' : 'asc';
      dispatch(SET_YOUR_PROJECTS_SORTING(sortedField, sort));
      dispatch(
        GET_USER_PROJECTS(
          rowsPerPage,
          paginationStart,
          field,
          sort,
          filterString,
          showOldProjects,
          appliedFilters
        ),
      );
    } else {
      dispatch(SET_YOUR_PROJECTS_SORTING(field, 'asc'));
      dispatch(
        GET_USER_PROJECTS(
          rowsPerPage,
          paginationStart,
          field,
          'asc',
          filterString,
          showOldProjects,
          appliedFilters
        ),
      );
    }
  }

  handlePagination = (
    paginationStart: number,
    rowsPerPage: number,
    currentPage: number,
  ) => {
    const {
      dispatch,
      sortedField,
      sortMethod,
      filterString,
      showOldProjects,
      appliedFilters,
    } = this.props;
    dispatch(SET_YOUR_PROJECTS_PAGINATION_START(paginationStart));
    dispatch(SET_YOUR_PROJECTS_TABLE_PAGE(currentPage));
    dispatch(
      GET_USER_PROJECTS(
        rowsPerPage,
        paginationStart,
        sortedField,
        sortMethod,
        filterString,
        showOldProjects,
        appliedFilters,
      ),
    );
  }

  goToProject = (projectId: string, absolute_url: number) => {
    const { dispatch, showOldProjects } = this.props;

    if (showOldProjects) {
      window.open(`https://${apiUrlUb1.slice(0, -1)}${absolute_url}`, '_blank');
    } else {
      dispatch(SEARCH_PROJECT(projectId));
    }
  }

  copyProject = (projectId: string) => {
    const { dispatch } = this.props;
    dispatch(COPY_PROJECT(projectId));
  }

  deleteProject = (projectId: string) => {
    const {
      dispatch,
      rowsPerPage,
      paginationStart,
      sortedField,
      sortMethod,
      filterString,
      showOldProjects,
      appliedFilters,
    } = this.props;

    const postDeleteAction = GET_USER_PROJECTS.bind(
      null,
      rowsPerPage,
      paginationStart,
      sortedField,
      sortMethod,
      filterString,
      showOldProjects,
      appliedFilters,
    );

    dispatch (
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        `Are you sure you want to delete the project?`,
        `All project details will be lost once deleted.
        `,
        () => {
                dispatch(DELETE_PROJECT(postDeleteAction, projectId));
                showSuccessAlert('Project deleted successfully')
        },
      ),
  )}
  

  copyAndDeleteButtons(projectId: string) {
    if (this.props.showOldProjects) {
      return null;
    }

    return (
      <TableColumn className="utils">
        <Button
          icon={true}
          className="material-icons"
          tooltipLabel="Copy project"
          onClick={this.copyProject.bind(null, projectId)}
        >
          content_copy
        </Button>
        <Button
          icon={true}
          className="material-icons delete-button"
          tooltipLabel="Delete project"
          onClick={this.deleteProject.bind(null, projectId)}
        >
          delete
        </Button>
      </TableColumn>
    );
  }

  totalPrice = (projectId: string, totalPrice: number) => {
    return (
      <div>
        <div className="total-price">{totalPrice || 'bom not calculated'}</div>
      </div>
    );
  }

  combineSelected = () => {
    const {
      dispatch,
      selectedProjects,
    } = this.props;

    return (
    <YourProjectsCombineSelected
      dispatch={dispatch}
      selectedProjects={selectedProjects}
    />
    );
  }

  tableBottom = () => {
    const {
      rowsPerPage,
      paginationTotal,
      paginationPage,
    } = this.props;
    return (
      <>
        {this.combineSelected()}
        <TablePagination
          id="your-projects-pagination"
          rowsPerPage={rowsPerPage}
          rows={paginationTotal}
          onPagination={this.handlePagination}
          page={paginationPage}
        />
      </>
    );
  }

  setSelected = (rowId: number, selected: boolean) => {
    const { data, dispatch, selectedProjects } = this.props;

    let selectedRows = selectedProjects;

    if (rowId === 0) {
      selectedRows = Object.keys(data).reduce((acc, index) => {
        const project = data[index];

        if (project.total_price === 0) {
          return acc;
        }

        if (selected) {
          acc[project.project_id] = true;
        } else {
          acc[project.project_id] = false;
        }

        return acc;
      }, {});
    } else {
      const projectId = data[rowId - 1].project_id;
      selectedRows[projectId] = selected;
    }

    dispatch(SET_SELECTED_PROJECTS(selectedRows));
  }

  render() {
    const {
      data,
      sortedField,
      sortMethod,
      selectedProjects,
      areLoading,
    } = this.props;

    if (areLoading) {
      return <Loader />;
    }

    if (!data || data.length === 0) {
      return (<div className="main-title">No projects found</div>);
    }

    return (
      <>
        <DataTable
          className="your-projects-table"
          onRowToggle={this.setSelected}
          baseId="your-projects-table"
          fixedHeight={600}
          fixedFooter={true}
          fixedHeader={true}
        >
          <TableHeader>
            <TableRow>
              <TableColumn
                sorted={sortedField === 'project_name'}
                onClick={this.sort.bind(null, 'project_name')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                PROJECT NAME
              </TableColumn>
              <TableColumn
                sorted={sortedField === 'client_name'}
                onClick={this.sort.bind(null, 'client_name')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                CLIENT NAME
              </TableColumn>
              <TableColumn
                sorted={sortedField === 'created'}
                onClick={this.sort.bind(null, 'created')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                CREATED
              </TableColumn>
              <TableColumn
                sorted={sortedField === 'panel_mfg'}
                onClick={this.sort.bind(null, 'panel_mfg')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                MFG
              </TableColumn>
              <TableColumn
                sorted={sortedField === 'panel_model'}
                onClick={this.sort.bind(null, 'panel_model')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                MODEL
              </TableColumn>
              <TableColumn
                sorted={sortedField === 'zipcode'}
                onClick={this.sort.bind(null, 'zipcode')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                ZIP
              </TableColumn>
              <TableColumn
                sorted={sortedField === 'total_price'}
                onClick={this.sort.bind(null, 'total_price')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                LIST
              </TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((project, index) => (
              <TableRow
                key={index}
                className={project.total_price > 0 ? '' : 'bom-not-calculated'}
                selected={(selectedProjects[project.project_id] && project.total_price > 0) || false}
              >
                <TableColumn
                  onClick={this.goToProject.bind(
                    null,
                    project.project_id,
                    project.absolute_url,
                  )}
                  className="project-name"
                >
                  {project.project_name} - <span className="project-id">#{project.project_id.toUpperCase()}</span>
                </TableColumn>
                <TableColumn>{project.client_name}</TableColumn>
                <TableColumn>{parseDate(project.created)}</TableColumn>
                <TableColumn>{project.panel_mfg}</TableColumn>
                <TableColumn>{project.panel_model}</TableColumn>
                <TableColumn>{project.zipcode}</TableColumn>
                <TableColumn className="last-column">
                  {this.totalPrice(project.project_id, project.total_price)}
                </TableColumn>
                {this.copyAndDeleteButtons(project.project_id)}
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
        {this.tableBottom()}
      </>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    yourProjects: appState.yourProjects,
  };
}

export default connect(mapStateToProps)(YourProjectsTable);
