import keyMirror from 'keymirror';


export const ModuleSelectorActionTypes = keyMirror({
  SET_MFG: undefined,
  SET_MODEL: undefined,
  FETCH_MFGS_AND_MODELS_REQUEST: undefined,
  FETCH_MFG_MODELS_REQUEST_ERROR: undefined,
  FETCH_MFG_MODELS_REQUEST: undefined,
  FETCH_MFGS_SUCCESS: undefined,
  FETCH_MFGS_MODELS_SUCCESS: undefined,
  RESET_MODULE_SELECTOR: undefined,
  SET_CUSTOM_VALUE: undefined,
  SET_MODEL_DATA_HAS_ERROR: undefined,
  POPULATE_MODELS_BY_MODULE: undefined,
  SET_SAME_PANEL_DIMS: undefined,
});

