import { getStage } from '__editor/panelsEditor/components/stage/stage';
import { state } from '__common/store';
import { DEBUG } from 'debug';

export interface ExposureExtentsArea {
  Ny: number;
  Sy: number;
  Ex: number;
  Wx: number;
}

enum boxSide {
  north,
  south, 
  east, 
  west,
}

export const getNorthExposureExtents = (
  panelCenter: { x: number, y: number },
  panelWidth: number,
  panelHeight: number,
  xSpacingPx: number,
  allowedDistance: number,
): ExposureExtentsArea => {
  // 
  // ○_____○  Ny ----/
  // |     |         |
  // |Wx   |Ex       | <-- allowed distance (exposure extent)
  // |     |         |
  // ○ - - ○  Sy ----/
  // |     |         |
  // |panel|         | <-- panel height
  // |     |         |
  // ○ - - ○    -----/
  //
  // we also include spacing to cover the case when the panel is fully covered, but 
  // the covering panels are not found due to the spacing. Example below:
  // 1 - panel we check exposure for.
  // 2 - panel that would be ignored when looking for "panels from box" because its max X is the same as 1's minX.
  // This creates the gap between 1's minX and 3's minX. Including spacing is going to find also panel 2.
  //
  // ┌----┐┌----┐┌----┐
  // |  2 ||  3 ||  4 |
  // |    ||    ||    |
  // └----┘└----┘└----┘
  //      ┌--------┐
  //      |   1    |
  //      └--------┘
  const Ny = panelCenter.y - (0.5 * panelHeight) - allowedDistance;
  const Sy = panelCenter.y - (0.5 * panelHeight);
  const Ex = panelCenter.x + (0.5 * panelWidth) + xSpacingPx;
  const Wx = panelCenter.x - (0.5 * panelWidth) - xSpacingPx;

  drawBox({ Ny, Sy, Ex, Wx }, boxSide.north);

  return { Ny, Sy, Ex, Wx };
};

export const getSouthExposureExtents = (
  panelCenter: { x: number, y: number },
  panelWidth: number,
  panelHeight: number,
  xSpacingPx: number,
  allowedDistance: number,
): ExposureExtentsArea => {
  // 
  // ○ - - ○  
  // |     |         
  // |panel|   
  // |     |    
  // ○ - - ○  Ny  /
  // |     |      |
  // |     |      | <-- allowed distance (exposure extent)
  // |     |      |
  // ○_____○ Sy   /
  // 
  // we also include spacing to cover the case when the panel is fully covered, but 
  // the covering panels are not found due to the spacing. Example below:
  // 1 - panel we check exposure for.
  // 2 - panel that is ignored when looking for "panels from box" because its max X is the same as 1's minX.
  // This creates the gap between 1's minX and 3's minX. Including spacing is going to find also panel 2.
  //
  //      ┌--------┐
  //      |   1    |
  //      └--------┘
  // ┌----┐┌----┐┌----┐
  // |  2 ||  3 ||  4 |
  // |    ||    ||    |
  // └----┘└----┘└----┘
  const Ny = panelCenter.y + (0.5 * panelHeight);
  const Sy = panelCenter.y + (0.5 * panelHeight) + allowedDistance;
  const Ex = panelCenter.x + (0.5 * panelWidth) + xSpacingPx;
  const Wx = panelCenter.x - (0.5 * panelWidth) - xSpacingPx;

  drawBox({ Ny, Sy, Ex, Wx }, boxSide.south);

  return { Ny, Sy, Ex, Wx };
};

export const getEastExposureExtents = (
  center: { x: number, y: number },
  panelWidth: number,
  panelHeight: number,
  ySpacingPx: number,
  allowedDistance: number,
): ExposureExtentsArea => {  
  //       allowed distance (exposure extent)
  //       /-----/
  //   
  // ○ - - ○_____○  Ny
  // |     |     |   
  // |panel|Wx   |Ex
  // |     |     |
  // ○ - - ○_____○  Sy
  //
  // we also include spacing to cover the case when the panel is fully covered, but 
  // the covering panels are not found due to the spacing. Example below:
  // 1 - panel we check exposure for.
  // 2 - panel that is ignored when looking for "panels from box" because its max Y is the same as 1's min Y.
  // This creates the gap between 1's minY and 3's minY. Including spacing is going to find also panel 2.
  // 
  //         ┌--------┐
  //         |   2    |
  //  ┌----┐ └--------┘ 
  //  | 1  | ┌--------┐ 
  //  |    | |   3    |
  //  └----┘ └--------┘
  const Ny = center.y - (0.5 * panelHeight) - ySpacingPx;
  const Sy = center.y + (0.5 * panelHeight) + ySpacingPx;
  const Ex = center.x + (0.5 * panelWidth) + allowedDistance;
  const Wx = center.x + (0.5 * panelWidth);

  drawBox({ Ny, Sy, Ex, Wx }, boxSide.east);

  return { Ny, Sy, Ex, Wx };
};

export const getWestExposureExtents = (
  panelCenter: { x: number, y: number },
  panelWidth: number,
  panelHeight: number,
  ySpacingPx: number,
  allowedDistance: number,
): ExposureExtentsArea => {
  //    allowed distance (exposure extent)
  //   /-----/ 
  //
  //   ○_____○ - - ○ Ny
  //   |     |<Ex  |
  //   |<Wx  |panel|
  //   |     |     |
  //   ○_____○ - - ○ Sy
  //
  // we also include spacing to cover the case when the panel is fully covered, but 
  // the covering panels are not found due to the spacing. Example below:
  // 1 - panel we check exposure for.
  // 2 - panel that is ignored when looking for "panels from box" because its max Y is the same as 1's min Y.
  // This creates the gap between 1's minY and 3's minY. Including spacing is going to find also panel 2.
  // 
  //  ┌--------┐
  //  |   2    |
  //  └--------┘ ┌----┐ 
  //  ┌--------┐ | 1  | 
  //  |   3    | |    | 
  //  └--------┘ └----┘ 
  const Ny = panelCenter.y - (0.5 * panelHeight) - ySpacingPx;
  const Sy = panelCenter.y + (0.5 * panelHeight) + ySpacingPx;
  const Ex = panelCenter.x - (0.5 * panelWidth);
  const Wx = panelCenter.x - (0.5 * panelWidth) - allowedDistance;

  drawBox({ Ny, Sy, Ex, Wx }, boxSide.west);

  return { Ny, Sy, Ex, Wx };
};

const getArea = ({ Ny ,Sy, Ex, Wx }: ExposureExtentsArea) => {
  return {
    minX: Wx,
    minY: Ny,
    maxX: Ex,
    maxY: Sy,
  };
};

const drawBox = ({ Ny, Sy, Ex, Wx }: ExposureExtentsArea, side: boxSide) => {
  if (!DEBUG.showExposureCoverageAreas) {
    return;
  }

  const { background: { bgXY: { x, y } } } = state();

  const stage = getStage();
  const NW = new PIXI.Point(Wx + x, Ny + y);
  const NE = new PIXI.Point(Ex + x, Ny + y);
  const SE = new PIXI.Point(Ex + x, Sy + y);
  const SW = new PIXI.Point(Wx + x, Sy + y);

  const box = new PIXI.Graphics;
  let color: number;
  switch (side) {
    case boxSide.north:
      color = 0xF26522; // #F26522
      break;
    case boxSide.south:
      color = 0x4287f5; // #4287f5
      break;
    case boxSide.east:
      color = 0x40f790; // #40f790
      break;
    case boxSide.west:
      color = 0xf4f72f; // #f4f72f
      break;
  }
  
  box.beginFill(color, 0.28);
  box.drawPolygon([NW, NE, SE, SW]);
  box.endFill();

  stage.addChild(box);
};

export const getPanelsInExposureExtentsArea = (
  extentsArea: ExposureExtentsArea, 
  currentPanelId: number,
  exposureRTree: rbush.RBush<rbush.BBox>,
): rbush.BBox[] => {
  const { Ny ,Sy, Ex, Wx } = extentsArea;
  const collisionArea = getArea({ Ny ,Sy, Ex, Wx });
  return exposureRTree.search(collisionArea).filter(panel => panel.id !== currentPanelId);
};
