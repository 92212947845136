import { createBingLeadEdgesOnMap, removeBingLeadEdgeObject } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/bingMapsRoofsSelectorLeadEdge';
import { reselectLeadRoofEdge } from '__editor/components/roofsSelector/components/roofsSelectorLeadEdge/utils/roofsSelectorSelectEdge';
import { setBingMapAzimuthInfo } from '../../bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { state } from '__common/store';
import { getLeadEdge } from '__editor/panelsEditor/components/leadEdge/leadEdge';

export function selectBingRoofLeadEdge(selectedRoofData: { edgeNumber: number, roofId: number }): void {
  const { drawingManager: { roofs }, roofsSelector: { mapType } } = state();
  const { roofId } = selectedRoofData;
  removeBingLeadEdgeObject(roofId);
  reselectLeadRoofEdge(selectedRoofData);
  const leadEdgePath = getLeadEdge(roofId);
  createBingLeadEdgesOnMap(roofId, leadEdgePath);
  if (mapType !== 'white') setBingMapAzimuthInfo(roofId, roofs[roofId].coords, leadEdgePath);
}
