import React from 'react';
import SVG from 'react-inlinesvg';
import { isAscender, products } from '__common/constants/products';

const attachments = () => {
  return  {
    page: 'attachments',
    icon: <SVG src={require('assets/media/icons/sidebar_base_system.svg')} />,
    content: undefined,
    postRender: undefined,
    active: true,
    tooltip: 'Rail and Attachments',
  };
};

const accessories = () => {
  return {
    page: 'accessories',
    icon: <SVG src={require('assets/media/icons/sidebar_accessory.svg')} />,
    content: undefined,
    postRender: undefined,
    tooltip: 'Accessories',
    active: false,
  };
};

const bomWarnings = () => {
  return {
    page: 'warnings',
    icon: <SVG src={require('assets/media/icons/bom_warning.svg')} />,
    content: undefined,
    postRender: undefined,
    tooltip: 'Warnings',
    active: false,
  };
};

export function getBomBasicPages(productId, warnings=[]) {
  let pages = [attachments(), accessories()];
  if(isAscender(products[productId]) && warnings.length) {
    pages.push(bomWarnings());
  }
  return pages;
}
