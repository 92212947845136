import FogOfWar from '__editor/panelsEditor/models/fogOfWar';
import { state } from '__common/store';
import { getStage } from '../stage/stage';
let fogOfWar;

export const drawFogOfWarOnStage = (width: number, height: number, screenshots: boolean, resizedY?: number) => {
  const stage = getStage();
  const { roofsSelector: { mapType } } = state();
    
  if (mapType === 'white' || screenshots) {
    return;
  }

  if (fogOfWar) {
    stage.removeChild(fogOfWar);
  }

  fogOfWar = FogOfWar(width, height, resizedY);
  stage.addChild(fogOfWar);

  return fogOfWar;
};




