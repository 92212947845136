export const apiField = 'additional_load_combination';


export interface AdditionalLoadCombinationField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  data: AdditionalLoadCombinationOption[];
}

export interface AdditionalLoadCombinationOption {
  value: number;
  name: string;
}
