import { flatRoofDistances, getLZonesAreasMemoized as getLZonesAreas, getRoofZonesAreasMemoized as getRoofZonesAreas } from '../roofZones';
import { createEdgeZonePolygon } from '../roofZonesCollisionsHelper';
import { findLinesCollisions } from './windZonesCollisionsDetection';

interface flatRoofZonesCollisionConfig {
  panelPoly: PolygonInterface;
  roofEdges: google.maps.LatLngLiteral[];
  roofId: number;
  roofCenter: cordPoint;
  zoom: number;
  buildingHeightFt: number;
  metersPerPixel: number;
  bgRotationDegrees: number;
  bgXOffset: number;
  bgYOffset: number;
  insideOfPolygon: boolean;
  roofPitch: string;
  roofEdgesPixiCords,
  productId: number,
  tilt: number,
  mapType: string,
}

export const getFlatRoofZoneCollision = ({
  panelPoly,
  roofEdges,
  roofCenter,
  roofId,
  zoom,
  buildingHeightFt,
  metersPerPixel,
  bgRotationDegrees,
  bgXOffset,
  bgYOffset,
  insideOfPolygon,
  roofPitch,
  roofEdgesPixiCords,
  productId,
  tilt,
  mapType,
}: flatRoofZonesCollisionConfig): { zone: roofZoneNumber } => {
  const { distanceFromEdgeCorner, distanceFromEdge } = flatRoofDistances(buildingHeightFt, metersPerPixel);

  const lZone = getLZonesAreas({roofEdges, roofCenter, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, distanceFromEdgeCorner, distanceFromEdge, roofPitch});
  const restrictedAreaPolygonsLZone = lZone.map(createEdgeZonePolygon);
  const collisionslZone = restrictedAreaPolygonsLZone.reduce((acc, area) => findLinesCollisions(acc, area, panelPoly), []);
  
  if (collisionslZone.length) {
    return { zone: 3 };
  }
  
  const zone1 = getRoofZonesAreas(
    {roofEdges, roofCenter,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance: distanceFromEdgeCorner, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});

  const restrictedAreaPolygonsZone1 = zone1.edges.map(createEdgeZonePolygon);
  const collisionsZone1 = restrictedAreaPolygonsZone1.reduce((acc, area) => findLinesCollisions(acc, area, panelPoly), []);
  
  if (collisionsZone1.length) {
    return { zone: 2 };
  }
  
  const zone2 =  getRoofZonesAreas(
    {roofEdges, roofCenter,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance: distanceFromEdgeCorner * 2, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  const restrictedAreaPolygonsZone2 = zone2.edges.map(createEdgeZonePolygon);
  
  const collisionsZone2 = restrictedAreaPolygonsZone2.reduce((acc, area) => findLinesCollisions(acc, area, panelPoly), []);
  
  if (collisionsZone2.length) {
    return { zone: 1 };
  }
  
  return { zone: 4 };
};

