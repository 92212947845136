import { Store } from 'redux';
import { getZoneInformation } from '__editor/panelsEditor/components/panels/utils/getZoneInformation';
import { ASCE_716_ROOF_ZONES } from '__editor/panelsEditor/components/roofZones/roofZoneNumbers';

const hierarchy = {
  [ASCE_716_ROOF_ZONES.NORTH_CORNER]: 1,
  [ASCE_716_ROOF_ZONES.SOUTH_CORNER]: 2,
  [ASCE_716_ROOF_ZONES.EAST_WEST_EDGES]: 3,
  [ASCE_716_ROOF_ZONES.NORTG_LEADING]: 4,
  [ASCE_716_ROOF_ZONES.SOUTH_LEADING]: 5,
  [ASCE_716_ROOF_ZONES.MIDDLE]: 6,
};

export const overrideZone = (store: Store<appState>, roofId: number) => 
(panel: panelInState, index: number, allPanels: panelInState[]): panelInState => {
  // by default zones have 3 panels (or pairs in RMDT) depth. But if the panels
  // lay within the wind zone it can be bigger, therefore we override them.  
  const { zone } = getZoneInformation(store, roofId, panel);
  const newHierarchyValue = hierarchy[zone];
  const currentHierarchyValue = hierarchy[panel.roofZone];
  // This is hierarchy in which the panels may be overwritten. The lower the better.
  // 1) 33XX - North Corner
  // 2) 32XX - South Corner
  // 3) 31XX - East and West Edge
  // 4) 13XX - North Edge
  // 5) 12XX - South Edge
  // 6) 11XX - Field
  if (newHierarchyValue < currentHierarchyValue) {
    return {
      ...panel,
      roofZone: zone,
    };
  }
  
  return panel;
};
