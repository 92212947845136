import React from 'react';
import { CHANGE_DISCOUNT, SET_DISCOUNT } from '../engineeringActions';
import { connect } from 'react-redux';
import { dispatch } from '__common/store';
import TextField from 'react-md/lib/TextFields/TextField';

type Props = {
  dispatch: Function,
  discount: number | null,
  projectId: string,
};

class EngineeringDiscount extends React.Component<Props> {
  changeDiscount = (discount: number) => {
    const { dispatch } = this.props;
    dispatch(CHANGE_DISCOUNT(discount));
  }

  applyDiscountHandler = () => {
    const { discount, projectId } = this.props;

    dispatch(SET_DISCOUNT(projectId, discount));
  }

  render() {
    const { discount } = this.props;

    return (
      <div className="engineering-discount pb-3">
        <div className="discount-input">
          <div className="input-label">discount</div>
          <div className="discount-container">
            <TextField
              id="discount_input"
              type="text"
              onChange={this.changeDiscount}
              max={100}
              value={discount === null ? 0 : discount}
            />
            <div className="percentage-sign">%</div>
          </div>
        </div>
        <div className="save-changes-button" onClick={this.applyDiscountHandler}>
          APPLY DISCOUNT
        </div>
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    discount: appState.engineering.projectInfo.discount,
  };
}

export default connect(mapStateToProps)(EngineeringDiscount);
