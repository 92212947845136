import PolygonsIntersect from 'polygons-intersect';
import { asce716RestrictedZoneDistance, getRoofZonesAreasMemoized as getRoofZonesAreas, RoofZonesCorner } from '../roofZones';
import { singlePanelPolygon, panelPolygon } from '../../roofEdges/roofEdgesCollisions';
import { RectCircleColliding, createEdgeZonePolygon } from '../roofZonesCollisionsHelper';

export const checkPanelCollisionWithRestrictedZones = (
  panel: panelInState,
  relativeToCenter: boolean,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  metersPerPixel: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  productId: number,
  insideOfPolygon: boolean,
  roofPitch: string,
  roofEdgesPixiCords,
  mapType: string,
  tilt: number,
): boolean => {
  const distance = asce716RestrictedZoneDistance(metersPerPixel, productId);
  // note that we pass distance here which is a restricted zone distance, not the wind zone dimension.
  const rotatedRoofZone =  getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  // When we check panels we check them one by one so the center should be the center of the single panel.
  // But when we check cursor, if the products requires double panels
  // then the center is in the middle so we have to take that into account. 
  const panelPoly = singlePanelPolygon(panel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);
  let collisionDetected = false;

  const minX = Math.min(...panelPoly.points.map(point => point.x));
  const minY = Math.min(...panelPoly.points.map(point => point.y));

  if (Object.keys(rotatedRoofZone).length) {
    const restrictedAreaPolygons = rotatedRoofZone.edges.map<PolygonInterface>(createEdgeZonePolygon);
    collisionDetected = restrictedAreaPolygons.some(collidingWithEdge(panelPoly));
  }

  if (!collisionDetected) {
    collisionDetected = rotatedRoofZone.corners.some((corner: RoofZonesCorner) => {
      const circle = { x: corner.pos.x, y: corner.pos.y, r: distance };
      const rect = { x: minX, y: minY, w: panel.width, h: panel.height };
      return RectCircleColliding(circle, rect);
    });
  }

  return collisionDetected;
};

export const checkCursorCollisionWithRestrictedZones = (
  cursor: cursor,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  metersPerPixel: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  productId: number,
  insideOfPolygon: boolean,
  roofPitch: string,
  roofEdgesPixiCords,
  mapType: string,
  tilt: number,
): boolean => {
  // cursor coordinates are relative to the canvas origin.
  const relativeToCenter = false;
  const distance = asce716RestrictedZoneDistance(metersPerPixel, productId);
  // note that we pass distance here which is a restricted zone distance, not the wind zone dimension.
  const rotatedRoofZone = getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  // When we check panels we check them one by one so the center should be the center of the single panel.
  // But when we check cursor, if the products requires double panels
  // then the center is in the middle so we have to take that into account.
  const panelPoly = panelPolygon(cursor, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);
  let collisionDetected = false;

  const minX = Math.min(...panelPoly.points.map(point => point.x));
  const minY = Math.min(...panelPoly.points.map(point => point.y));

  if (Object.keys(rotatedRoofZone).length) {
    const restrictedAreaPolygons = rotatedRoofZone.edges.map<PolygonInterface>(createEdgeZonePolygon);
    collisionDetected = restrictedAreaPolygons.some(collidingWithEdge(panelPoly));
  }

  if (!collisionDetected) {
    collisionDetected = rotatedRoofZone.corners.some((corner: RoofZonesCorner) => {
      const circle = { x: corner.pos.x, y: corner.pos.y, r: distance };
      const rect = { x: minX, y: minY, w: cursor.width, h: cursor.height };
      return RectCircleColliding(circle, rect);
    });
  }

  return collisionDetected;
};

const collidingWithEdge = (panelPolygon: PolygonInterface) => (edge: PolygonInterface): boolean => {
  const intersect = PolygonsIntersect(edge.points, panelPolygon.points);
  return intersect.length > 0;
};
