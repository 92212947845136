import { SettingsActionTypes } from 'actionsConstants';


export function DRAW_ORIENTATION_CHANGE() {
  return {
    type: SettingsActionTypes.DRAW_ORIENTATION_CHANGE,
  };
}

export function CHANGE_PANELS_SIZES() {
  return {
    type: SettingsActionTypes.CHANGE_PANELS_SIZES,
  };
}

export function SET_PANELS_WIDTH_AND_HEIGHT(width: any, height: any) {
  return {
    type: SettingsActionTypes.SET_PANELS_WIDTH_AND_HEIGHT,
    payload: {
      panelWidth: width,
      panelHeight: height,
    },
  };
}

export function SET_CANVAS_CENTER(x: number, y: number) {
  return {
    type: SettingsActionTypes.SET_CANVAS_CENTER,
    payload: {
      x,
      y,
    },
  };
}

export function SET_ROW_SPACING(rowSpacing: number) {
  return {
    type: SettingsActionTypes.SET_ROW_SPACING,
    payload: {
      rowSpacing,
    },
  };
}

export function SET_COLUMN_SPACING(columnSpacing: number) {
  return {
    type: SettingsActionTypes.SET_COLUMN_SPACING,
    payload: {
      columnSpacing,
    },
  };
}

export function SET_TABLE_ROW_SPACING(tableRowSpacing: number) {
  return {
    type: SettingsActionTypes.SET_TABLE_ROW_SPACING,
    payload: {
      tableRowSpacing,
    },
  };
}

export function SET_RELOCATION_ACTIVE(mapDisplacedFromRoof?: boolean) {
  return {
    type: SettingsActionTypes.SET_RELOCATION_ACTIVE,
    payload: {
    mapDisplacedFromRoof,
    },
  };
}

export function CALCULATE_ZONE_CLASSIFICATION(calculateZoneClassication: boolean) {
  return {
    type: SettingsActionTypes.CALCULATE_ZONE_CLASSIFICATION,
    payload: {
      calculateZoneClassication,
    },
  };
}
