import Button from 'react-md/lib/Buttons/Button';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import SaveChangesButton from '__common/components/Drawer/components/SaveChangesButton';
import { connect } from 'react-redux';
import { dispatch } from '../../store';
import { footerHeight } from '__common/constants/layout_css_dims';
import { isRMFamily } from '__common/constants/products';
import { OPEN_DRAWER_PAGE } from 'actions';
import { isModuleSelectorValid } from '../moduleSelector/moduleSelectorHelper';

type Props = {
  dispatch: Function,
  pages: PAGE[];
  page: string | null;
  mainClass?: string;
  listClass?: string;
  drawerHeight?: number;
  saveButton?: boolean;
  saveButtonLabel?: string,
  keepOpen?: boolean;
  inactive?: boolean;
  productId: number,
  shouldConfigureFirst?: boolean,
  onSave?: Function,
  projectConfigurated: boolean,
  moduleSelector: moduleSelectorState,
  verifiedFlyouts?: verifiedFlyouts,
};

type PAGE = {
  page: string;
  icon: any;
  content: JSX.Element;
  active?: boolean;
  keepOpen?: boolean;
  postRender?: Function;
  disabled?: boolean,
  tooltip: any;
  invalid: boolean;
  bottomIcon?: boolean;
  onClick?: () => void;
};

type State = {
  configuredPages: string[],
  pageInConfiguration: boolean,
};

class Drawer extends React.Component<Props, State> {
  state = {
    configuredPages: [],
    pageInConfiguration: false,
  };

  drawerList: any;
  drawerContent: any;

  contentHeight = this.props.drawerHeight
    ? this.props.drawerHeight
    : `calc(100% - ${footerHeight}`;

  componentDidMount() {
    const { page } = this.props;

    this.openActive();

    if (page === null) {
      this.setPageOpenIfShouldBe();
    }

    document.addEventListener('mousedown', this.handleClickOutside);
    
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Props) {
    const { page, shouldConfigureFirst } = this.props;
    
    this.setPageOpenIfShouldBe();

    if (shouldConfigureFirst) {
      this.forceToConfigurePages();
    }

    if (!page) {
      return;
    }

    if (prevProps.drawerHeight !== this.props.drawerHeight) {
      this.contentHeight = this.props.drawerHeight
      ? this.props.drawerHeight
      : `calc(100% - ${footerHeight}`;
    }

    const postRenderPage = this.props.pages.find(p => p.page === page);
    let postRender: Function | undefined;

    if (postRenderPage) {
      postRender = postRenderPage.postRender;
    }

    if (postRender) {
      postRender();
    }

    if (prevProps.productId !== this.props.productId) {
      this.openActive();
    }

  }

  forceToConfigurePages() {
    const { configuredPages, pageInConfiguration } = this.state;
    const { pages, projectConfigurated, shouldConfigureFirst } = this.props;

    if (pageInConfiguration || projectConfigurated && shouldConfigureFirst) {
      return;
    }

    pages.some(p => {
      const pa = p.page;
      if (!configuredPages.includes(pa)) {
        dispatch(OPEN_DRAWER_PAGE(pa));
        this.setState({ pageInConfiguration: true });
        return true;
      }
    });
  }

  getPage = (page: string) => {
    return this.props.pages.find(p => p.page === page);
  }

  setPageOpenIfShouldBe = () => {
    const { dispatch, page } = this.props;
    this.props.pages.some(p => {
      if (p.invalid && p.page !== page) {
        return true;
      }

      if (p.invalid && p.page !== page) {
        dispatch(OPEN_DRAWER_PAGE(p.page));
        return true;
      }

      if (p.keepOpen && p.page === page) {
        return true;
      }
      
      if (p.keepOpen && p.page !== page) {
        dispatch(OPEN_DRAWER_PAGE(p.page));
        return true;
      }
    });
  }

  openActive = () => {
    const { dispatch, page, projectConfigurated, shouldConfigureFirst } = this.props;

    if (!projectConfigurated && shouldConfigureFirst) {
      return;
    }

    this.props.pages.some(p => {
      
      const currentPageData = this.getPage(page);

      if (currentPageData && currentPageData.invalid && currentPageData.page === page) {
        return true;
      }

      if (p.active && page !== p.page) {
        dispatch(OPEN_DRAWER_PAGE(p.page));
        return true;
      }
    });
  }

  _clickedOnMapDesigningButton = (e) => {
    if (e && 
        e.target && 
        e.target.parentElement && 
        e.target.parentElement.parentElement && 
        e.target.parentElement.parentElement.parentElement &&
        e.target.parentElement.parentElement.parentElement.classList && 
        e.target.parentElement.parentElement.parentElement.classList.length) {
      return e.target.parentElement.parentElement.parentElement.classList[1] === 'design-on-map-button';
    }
  }

  handleClickOutside = (event: Event) => {
    const { dispatch } = this.props;
    if (
      this.drawerContent &&
      !this.drawerContent.contains(event.target) &&
      this.drawerList &&
      !this.drawerList.contains(event.target) &&
      this.props.page &&
      !this.props.keepOpen &&
      !this._clickedOnMapDesigningButton(event) &&
      !this.shouldKeepOpen()
    ) {
      dispatch(OPEN_DRAWER_PAGE(null));
    }
  }

  shouldKeepOpen = () => {
    const { page } = this.props;
    const currentPage = this.props.pages.find(p => p.page === page);

    // Custom module selector has a lot of validation rules
    // therefore, we should keep the drawer open until all the requirements are met.
    if ((currentPage && currentPage.keepOpen) || !isModuleSelectorValid()) {
      return true;
    }

    return false;
  }

  setOpen = (page: PAGE) => {
    const { dispatch } = this.props;
    
    if (this.shouldKeepOpen()) {
      return ;
    }

    if (page.page === this.props.page && !this.props.keepOpen) {
      dispatch(OPEN_DRAWER_PAGE(null));
    } else {
      dispatch(OPEN_DRAWER_PAGE(page.page));
    }
  }

  list = () => {
    const { pages } = this.props;
    let bottomIconIndex = -1;
    return (
      <>
        {pages
          .filter(page => !page.disabled)
          .map((page) => {
            if(page.bottomIcon) bottomIconIndex++;
            return (
            <Button
              flat={true}
              data-for="drawerTooltip"
              data-multiline="true"
              data-tip={page.tooltip}
              data-tip-disable={this.props.page === page.page}
              data-offset="{'top': 0, 'left': 5}"
              className={`drawer-icon ${page.page} ${
                page.page === this.props.page ? 'active' : ''
              } ${page.page}`}
              onClick={page.onClick ? page.onClick : this.setOpen.bind(this, page)}
              key={page.page}
              style={page.bottomIcon? { bottom: `${10 + bottomIconIndex*50}px` }: {}}
            >
              {page.icon}
            </Button>
            );
          })}
      </>
    );
  }

  addPageToConfigured = () => {
    const { page, pages, dispatch } = this.props;
    const { configuredPages } = this.state;
    const newConfigured = configuredPages;

    if (!newConfigured.includes(page)) {
      newConfigured.push(page);
      this.setState({ pageInConfiguration: false, configuredPages: newConfigured });
    }
  }

  onSave = () => {
    const { dispatch, shouldConfigureFirst } = this.props;
    
    const { onSave } = this.props;
    if (onSave) {
      onSave();
    }

    if (shouldConfigureFirst) {
      this.addPageToConfigured();
    }

    if (this.shouldKeepOpen()) {
      return;
    }

    dispatch(OPEN_DRAWER_PAGE(null));
  }

  content = () => {
    const { listClass, saveButtonLabel, page, productId } = this.props;
    const rm5Class = isRMFamily(productId) ? 'rm-family' : '';
    if (page === null) {
      return null;
    }

    const foundPage = this.props.pages.find(p => p.page === page);

    if (!foundPage || !foundPage.content) {
      return null;
    }

    return (
      <div
        ref={ref => (this.drawerContent = ref)}
        className={`drawer-list ${page}-content ${listClass} ${rm5Class}`}
        {...(this.props.page!="accessories" ? {style:{height: this.contentHeight}} : {style:{height: "100%"}})}
      >
        {foundPage.content}
        {this.props.saveButton && (
          <SaveChangesButton
            label={saveButtonLabel}
            onClick={this.onSave}
            page={foundPage.page}
            verifiedFlyouts={this.props.verifiedFlyouts}
          />
        )}
      </div>
    );
  }

  render() {
    const { inactive } = this.props;
    return (
      <>
      <ReactTooltip className="drawer-and-header-tooltip" effect="solid" id="drawerTooltip" />
        <div
          {...(this.props.page!="accessories" ? {style:{height: this.contentHeight}} : {style:{height: "auto"}})}
          ref={ref => (this.drawerList = ref)} // tslint:disable-next-line
          className={`drawer-component ${ this.props.page === "accessories" ? this.props.page : '' } ${this.props.mainClass ? this.props.mainClass : '' } ${inactive == true ? 'inactive' : ''}`}
        >
          {this.list()}
        </div>
        {this.content()}
      </>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    page: appState.projectConfiguration.openedDrawerPage,
    projectConfigurated: appState.projectConfiguration.projectConfigurated,
    moduleSelector: appState.moduleSelector,
    verifiedFlyouts: appState.projectConfiguration.verifiedFlyouts
  };
}

export default connect(mapStateToProps)(Drawer);
