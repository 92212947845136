import { isSolarMount, products, isNxtHorizon} from '__common/constants/products';
import { ROOF_PITCH } from '__editor/panelsEditor/components/tiltedRoof/roofPitch';
import { InputUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

export function parseDataForAuroraImport(product: string|number, initData, moduleData: modelData) {
  
  const pId = product;

  const data = {
    aurora_default_product_id: initData.aurora_default_product_id ,
    aurora_design_id: initData.aurora_design_id,
    aurora_project_id: initData.aurora_project_id,
    aurora_racking_type:  initData.aurora_racking_type,
    aurora_session_token: initData.aurora_session_token,
    aurora_tenant_id: initData.aurora_tenant_id,
    aurora_user_id: initData.aurora_user_id,
    product: pId,
    module: Number(moduleData.module) || Number(initData.module),
    model: Number(moduleData.id) || Number(initData.model),
    custom_title: '',
    building_height: initData.building_height,
    module_watts: initData.aurora_module_wattage,
    module_length: initData.aurora_module_length,
    module_width: initData.aurora_module_width,
    module_thickness: initData.aurora_module_thickness,
    module_weight: initData.aurora_module_weight,
    zipcode: initData.zipcode,
    snow_load: initData.snow_load,
    wind_speed: initData.wind_speed,
    seismic_ss: Number(Number(initData.seismic_ss).toFixed(2)),
    elevation: initData.elevation,
    wind_exposure: initData.wind_exposure,
    building_code: initData.building_code,
    roof_type: initData.roof_type,
    row_spacing: initData.row_spacing,
    rafter_spacing_inches: initData.rafter_spacing_inches,
  };
  if (isSolarMount(pId)) {
    data.mid_clamps_choices = initData.mid_clamps_choices || 1;
    data.end_clamps_choices = initData.end_clamps_choices || 1;
    data.roof_pitch = ROOF_PITCH[initData.roof_pitch] || 5;
    data.input_unit = InputUnit.CUSTOMARY;
  }

  if (isNxtHorizon(pId)) {
    data.mid_clamps_choices = initData.mid_clamps_choices || 4;
    data.end_clamps_choices = initData.end_clamps_choices || 4;
    data.roof_pitch = ROOF_PITCH[initData.roof_pitch] || 5;
  }

  return data;
}

export function validateDataForImport(data) {
  if (data.wind_speed === undefined) {
    alert('Set wind speed before import');
    return false;
  }

  if (data.snow_load === undefined) {
    alert('Set snow load before import');
    return false;
  }

  if (data.seismic_ss === undefined) {
    alert('Set seismic_ss before import');
    return false;
  }

  if (!data.model || !data.module) {
    return false;
  }

  if (!data.module || !data.model) {
    return false;
  }

  return true;
}
