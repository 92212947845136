import React from 'react';
import { FormattedMessage } from 'react-intl';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';

type Props = {
    fieldBlock: checkboxBlock,
    isSelected: number,
    updateProjectOption: (field: string, value: any) => void,
  };

export default class CheckboxField extends React.PureComponent<Props> {
  render() {
    const { fieldBlock, isSelected, updateProjectOption } = this.props;
    return (
    <div style={{ display: 'flex', marginTop:fieldBlock.margin_}}>
      <div className='input-label' style={{ marginTop: '15px' }}>
        <FormattedMessage id={fieldBlock.label} />:
      </div>
      <SelectionControl
        id={fieldBlock.id}
        name={fieldBlock.label}
        type="checkbox"
        aria-label={fieldBlock.aria_label}
        value={fieldBlock.label}
        checked={Boolean(isSelected)}
        onChange={(value => updateProjectOption(fieldBlock.apiField, value))}
        disabled={fieldBlock.is_disabled}
      />
    </div>
    );
  }
}