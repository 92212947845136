import { DISABLE_RUBBER_BAND, RESTORE_PANELS_FOR_NO_MOVEMENT, TERMINATE_ADVANCE_ROOF_SELECTING } from 'actions';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { ENABLE_BAYS_CONTAINER, MOVE_ARRAY_MODE_DISABLE, MOVE_MAP_MODE_DISABLE, TOGGLE_ATTACHMENTS_ENABLE } from '../../background/backgroundActions';
import { isRM10Evolution, isRM5 } from '__common/constants/products';

class ToggleAttachments extends React.Component<toggleAttachmentsState, {}> {
  action = () => {
    const { dispatch, productId } = this.props;
    dispatch(DISABLE_RUBBER_BAND());
    dispatch(MOVE_MAP_MODE_DISABLE());
    if(this.props.moveArrayEnabled) {
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
    }
    dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    dispatch(TOGGLE_ATTACHMENTS_ENABLE());
    (isRM10Evolution(productId) || isRM5(productId)) && dispatch(ENABLE_BAYS_CONTAINER())
  }

  render() {
    const { toggleAttachments } = this.props;
    return (
      <Button flat={true} onClick={this.action} className="roofs-selector-button" tooltipLabel="Toggle Attachments">
        <SVG className={ toggleAttachments? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/sidebar_accessory.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    toggleAttachments: state.background.toggleAttachments,
    productId: state.projectConfiguration.productId,
    moveArrayEnabled: state.background.moveArrayMode,
  };
}

export default connect(mapStateToProps)(ToggleAttachments);
