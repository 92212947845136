import config from 'local_config';
import Footer from 'app/components/FooterComponent';
import React from 'react';
import { DialogContainer, Button } from 'react-md';
import { connect } from 'react-redux';
import { REMOVE_APP_ERROR } from 'actionsConstants';
import { DEBUG } from 'debug';

type State = {
  showGears: boolean,
};

type Props = {
  error: { info: string, stack: string, isIE: boolean } | null,
  dispatch: Function,
};

class ErrorPageModal extends React.Component<Props, State> {
  state = {
    showGears: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showGears: true });
    },         1000);
  }

  renderGears = (isIE: boolean) => {
    const { showGears } = this.state;
    return (
      <div className={showGears ? '' : 'loading'}>
        <h1>Oops</h1>
        <h2 className="info">
          {isIE ? this.renderIEMessage() : this.renderStandardMessage()}
        </h2>
        <div className="gears">
          <div className="gear one">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="gear two">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="gear three">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
        </div>
      </div>
    );
  }

  renderStack = () => {
    const { error } = this.props;

    if (error === null) {
      return null;
    }

    return (
      <div className="error-stack">
        {error.info && <div className="component-stack-trace">
          <pre>{error.info.trim()}</pre>
        </div>}
        {error.stack && <div className="stack-trace">
          <div>
            <pre>{error.stack.trim()}</pre>
          </div>
        </div>}
      </div>
    );
  }

  renderStandardMessage = () => {
    return (
      <>
        <h2>
          Unexpected Error <b> :( </b>
        </h2>
        <h2 className="info">
          We apologize for the inconvenience.  Please use the Report Issues button below to report this error.
        </h2>
      </>
    );
  }

  renderIEMessage = () => {
    return (
      <h2 className="info">
        This application does not support Internet Explorer. For best results please use Chrome, Edge, Opera, or Safari
      </h2>
    );
  }

  isIE = () => {
    return this.props.error && this.props.error.isIE;
  }

  render() {
    const { error, dispatch } = this.props;
    const isProd = config.isProd;
    const isIE = this.isIE();
    
    if (!DEBUG.showPageErrorModal) {
      return null;
    }

    return (
      <DialogContainer
        id="error-page-modal"
        visible={error !== null}
        title={'Oooppsss...'}
        fullPage={true}
        focusOnMount={false}
      >
        <div className={`error-page ${isProd || isIE ? 'error-page-production' : 'error-page-development'}`}>
          <Button icon={true} primary={true} onClick={() => dispatch(REMOVE_APP_ERROR())}>close</Button>
          {this.renderGears(isIE)}
          {!isProd && !isIE && this.renderStack()}
          <Footer />
        </div>
      </DialogContainer>

    );
  }
}

function mapStateToProps(state: appState) {
  return {
    error: state.router.error,
  };
}

export default connect(mapStateToProps)(ErrorPageModal);

