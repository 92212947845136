export const apiField = 'attachment_type';

function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'solarhooks_attachments',
    apiField,
    data: options,
  };
}

export default makeConfig([
  {
    value: 12,
    name: 'AT1-Flat Tile',
  },
  {
    value: 13,
    name: 'CT2-Spanish 9" Base',
  },
  {
    value: 14,
    name: 'CT1-Spanish 7" Base',
  },
  {
    value: 15,
    name: 'CT5-Universal',
  },
  
]);
