import PolygonsIntersect from 'polygons-intersect';
import { getRoofArea, getRoofZonesAreasMemoized as getRoofZonesAreas, RoofZonesCorner } from '../roofZones';
import { singlePanelPolygon, panelPolygon } from '../../roofEdges/roofEdgesCollisions';
import { RectCircleColliding, createEdgeZonePolygon } from '../roofZonesCollisionsHelper';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { state } from '__common/store';
import { isASCE716or722 } from '__common/constants/buildingCodes';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { isRMGridFlex, isRM10Ultra, isRM10, isRM10orRM10Evo, isEcoFoot2Plus, isRM5, isRmGridflex10, isRMFamily } from '__common/constants/products';
import { getSeismicDesignCategory } from '__common/calculations/seismic_design_category';
import { minDistanceToSegment } from '__common/calculations/distanceBetweenPointAndLineSegment';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { round } from 'lodash';
import { filterOutPanelsNotInsideRoofEdges } from 'projectDesign/rmGridflexBlankMapUtils';
import { inchesToFeet } from '__common/calculations/inchesToFeet';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { applyMinSetbackValidationBasedOnSeismicDesignCategory, applyRMGF5SetbackChanges } from '__common/utils/versionCompare/versionCompare';
import { getSetBackDistance } from '../drawRoofZonesOnStage';
import { Fade } from 'react-bootstrap';

export const checkPanelCollisionWithSetBackDistance = (
  panel: panelInState,
  relativeToCenter: boolean,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  metersPerPixel: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  productId: number,
  insideOfPolygon: boolean,
  roofPitch: string,
  roofEdgesPixiCords,
  mapType: string,
  tilt: number,
  setbackInMeters: number = 0,
): boolean => {
  const distance = setbackInMeters ? setbackInMeters / metersPerPixel : getSetBackDistance(metersPerPixel);
  // note that we pass distance here which is a restricted zone distance, not the wind zone dimension.
  const rotatedRoofZone = getRoofZonesAreas({roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  // When we check panels we check them one by one so the center should be the center of the single panel.
  // But when we check cursor, if the products requires double panels
  // then the center is in the middle so we have to take that into account. 
  const panelPoly = singlePanelPolygon(panel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);
  let collisionDetected = false;

  const minX = Math.min(...panelPoly.points.map(point => point.x));
  const minY = Math.min(...panelPoly.points.map(point => point.y));

  if (Object.keys(rotatedRoofZone).length) {
    const SetBackDistanceAreaPolygons = rotatedRoofZone.edges.map<PolygonInterface>(createEdgeZonePolygon);
    collisionDetected = SetBackDistanceAreaPolygons.some(collidingWithEdge(panelPoly));
  }

  if (!collisionDetected) {
    collisionDetected = rotatedRoofZone.corners.some((corner: RoofZonesCorner) => {
      const circle = { x: corner.pos.x, y: corner.pos.y, r: distance };
      const rect = { x: minX, y: minY, w: panel.width, h: panel.height };
      return RectCircleColliding(circle, rect);
    });
  }

  return collisionDetected;
};

export const checkBlankMapPanelsInsideSetBackDistance = (
  blank_map_building_length, 
  blank_map_building_width, 
  setbackDistance, 
  metersPerPixel, 
  bgScale, 
  panelWidth, 
  panelHeight, 
  panels,
) : boolean => {
  if (!bgScale) return false;
  const filteredPanels = filterOutPanelsNotInsideRoofEdges({ 
    length : blank_map_building_length, 
    width : blank_map_building_width,
    setbackDistance, 
    metersPerPixel, 
    bgScale, 
    panelWidth, 
    panelHeight, 
    panels 
  });
  return filteredPanels.length !== panels.length;
}

export const getPanelSetBackDistance = (
  panel: panelInState,
  relativeToCenter: boolean,
  roofEdges: google.maps.LatLngLiteral[],
  cords: cordPoint,
  zoom: number,
  metersPerPixel: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  roofPitch: string,
): number => {
  
  const panelPoly = singlePanelPolygon(panel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);
  const roofPolygon = getRoofArea(
    roofEdges,
    cords,
    zoom,
    bgRotationDegrees,
    bgXOffset,
    bgYOffset,
    roofPitch
  );
  const minDistToRoofEdges = Math.min(...panelPoly.points.map(point => Math.min(...roofPolygon.points.map((roofPoint, index) => minDistanceToSegment(point, roofPoint, roofPolygon.points[(index + 1) % roofPolygon.points.length])))))
  const minDistToPanelEdges = Math.min(...roofPolygon.points.map(point => Math.min(...panelPoly.points.map((panelPoint, index) => minDistanceToSegment(point, panelPoint, panelPoly.points[(index + 1) % panelPoly.points.length])))))
  const setBackDistanceInFt = metersToFeets(Math.min(minDistToPanelEdges, minDistToRoofEdges) * metersPerPixel);

  return round(setBackDistanceInFt, 2);
};


export const checkCursorCollisionWithSetBackDistance = (
  cursor: cursor,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  metersPerPixel: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  productId: number,
  insideOfPolygon: boolean,
  roofPitch: string,
  roofEdgesPixiCords,
  mapType: string,
  tilt: number,
): boolean => {
  // cursor coordinates are relative to the canvas origin.
  const relativeToCenter = false;
  const distance = getSetBackDistance(metersPerPixel);
  // note that we pass distance here which is a restricted zone distance, not the wind zone dimension.
  const rotatedRoofZone = getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  // When we check panels we check them one by one so the center should be the center of the single panel.
  // But when we check cursor, if the products requires double panels
  // then the center is in the middle so we have to take that into account.
  const panelPoly = panelPolygon(cursor, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);
  let collisionDetected = false;

  const minX = Math.min(...panelPoly.points.map(point => point.x));
  const minY = Math.min(...panelPoly.points.map(point => point.y));

  if (Object.keys(rotatedRoofZone).length) {
    const SetBackDistanceAreaPolygons = rotatedRoofZone.edges.map<PolygonInterface>(createEdgeZonePolygon);
    collisionDetected = SetBackDistanceAreaPolygons.some(collidingWithEdge(panelPoly));
  }

  if (!collisionDetected) {
    collisionDetected = rotatedRoofZone.corners.some((corner: RoofZonesCorner) => {
      const circle = { x: corner.pos.x, y: corner.pos.y, r: distance };
      const rect = { x: minX, y: minY, w: cursor.width, h: cursor.height };
      return RectCircleColliding(circle, rect);
    });
  }

  return collisionDetected;
};

const collidingWithEdge = (panelPolygon: PolygonInterface) => (edge: PolygonInterface): boolean => {
  const intersect = PolygonsIntersect(edge.points, panelPolygon.points);
  return intersect.length > 0;
};


export const getIFISetBack = (productId, parapet_height) => {

  let SYSTEM_HEIGHT = 0;
  if (isRMGridFlex(productId)) {
    SYSTEM_HEIGHT = 0.207;  // meters
  } else if (isRM10Ultra(productId)) {
    SYSTEM_HEIGHT = 0.283;  // meters
  }

  let data = 0;
  if (parapet_height > SYSTEM_HEIGHT) {
    data = 0;
  } else {
    data = (2 * (SYSTEM_HEIGHT - parapet_height));
  }
  return data;
};


export const getSeismicSetback = (projectEnvConfig: projectEnvConfig, productId: number) => {

  let seismicSetback = 0;
  let seismic_design_displacement = 0;
  let to_roof_edge_with_parapet: boolean;

  const PARAPET_HEIGHT_VALUES = {
    '<=6': 1,
    '<=12': 2,
    '>12': 3,
  };
  const { risk_category, building_code, parapet_height, parapet_height_input, dead_load_factor_modification } = projectEnvConfig;
  const { Sds: sds, seismicDesignCategory } = getSeismicDesignCategory(projectEnvConfig);

  const seismic_importance_factor = { 1: 1, 2: 1, 3: 1.25, 4: 1.5 }[risk_category];
  const { projectConfiguration: { projectVersion } } = state();

  // If SDC A or B && DL factor == 0.6 with VC comparision retuun 12 inch setback
  if(isASCE716or722(building_code) && applyMinSetbackValidationBasedOnSeismicDesignCategory(projectVersion) &&
   (isRMFamily(productId) || isEcoFoot2Plus(productId))) {
    if(['A', 'B'].includes(seismicDesignCategory) && dead_load_factor_modification === 0.6) {
      seismicSetback = 12;
      return inchesToFeet(seismicSetback);
    }
    else {
      if (isRM10(productId) || isEcoFoot2Plus(productId) || isRM5(productId)) {
        to_roof_edge_with_parapet = parapet_height == PARAPET_HEIGHT_VALUES['>12']
      }
      else {
        // # 12 inch comparison because as per ASCE 7-16/7-22 | Section 13.6.12 point no-6
        to_roof_edge_with_parapet = parapet_height_input > 12
      }      
      seismic_design_displacement = Math.max(60 * seismic_importance_factor * ((sds - 0.4) ** 2), 24);

      if (to_roof_edge_with_parapet) {
          seismicSetback = seismic_design_displacement; 
      }
      else {
        seismicSetback = 2 * seismic_design_displacement; 
      }
      return inchesToFeet(seismicSetback); // in ft
    }
  }
 
  if (isRMGridFlex(productId)) {
    if(applyRMGF5SetbackChanges(projectVersion)){
      to_roof_edge_with_parapet = parapet_height_input > 0
    } else {
      to_roof_edge_with_parapet = parapet_height_input === 0
    }
  } else if (isRM10(productId) || isEcoFoot2Plus(productId)) {
    to_roof_edge_with_parapet = parapet_height == PARAPET_HEIGHT_VALUES['>12']
  } else {
    to_roof_edge_with_parapet = parapet_height_input > 12
  }
  

  //  Seismic Design Displacement, Delta MPV
  if (isASCE716or722(building_code)) {
    if (['A', 'B', 'C'].includes(seismicDesignCategory)) {
      seismic_design_displacement = 24;
    } else {
      seismic_design_displacement = Math.max(60 * seismic_importance_factor * ((sds - 0.4) ** 2), 24);
    }
  } else {
    if (['A', 'B', 'C'].includes(seismicDesignCategory)) {
      seismic_design_displacement = 6;
    } else {
      seismic_design_displacement = Math.max(60 * ((sds - 0.4) ** 2), 6);
    }
  }

  if (to_roof_edge_with_parapet) {

    if (isASCE716or722(building_code)) {
      seismicSetback = seismic_design_displacement;
    } else {
      seismicSetback = seismic_importance_factor * seismic_design_displacement;
    }

  } else {

    if (isASCE716or722(projectEnvConfig.building_code)) {
      seismicSetback = 2 * seismic_design_displacement;
    } else {
      seismicSetback = 1.5 * seismic_importance_factor * seismic_design_displacement;
    }

  }

  return inchesToFeet(seismicSetback); // in ft
};


export const getMaxSetBack = (projectEnvConfig, inputUnit: number, productId: number, projectVersion?: string) => {
  const setbackDistance = isMetricUnit(inputUnit) ? cmsToMeters(projectEnvConfig.setback_distance) : feetsToMeters(projectEnvConfig.setback_distance);
  if (isRM10orRM10Evo(productId) || isRmGridflex10(productId) || isEcoFoot2Plus(productId) || isRM5(productId)){
    return setbackDistance;
  }
  const parapetHeight = inchesToMeters(projectEnvConfig.parapet_height_input);
  const ifiSetBack = getIFISetBack(projectEnvConfig.productId, parapetHeight);
  if(isRMGridFlex(productId) && !applyRMGF5SetbackChanges(projectVersion)) {
    const seismicSetback = feetsToMeters(getSeismicSetback(projectEnvConfig, productId));
    return Math.max(ifiSetBack, seismicSetback, setbackDistance);

  }
  return Math.max(ifiSetBack, setbackDistance);
};

