import * as React from 'react';
import AppHistory from '__common/modules/history';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HelioscopeDesignsList from 'helioscopeImport/components/helioscopeDesignsList/components/HelioscopeDesignsListComponent';
import SVG from 'react-inlinesvg';
import { clearHelioscopeDesignsList, fetchHelioscopeDesignsList } from 'actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

type Props = {
  dispatch: Function,
  helioscopeDesignsList: any,
  match: any,
  isPrivilegedUser: boolean,
};

export class HelioscopeDesigns extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch, match } = this.props;

    dispatch(fetchHelioscopeDesignsList(match.params.projectId));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearHelioscopeDesignsList());
  }

  goBack = () => {
    AppHistory.push('/hs/list');
  }

  pageHeader = () => {
    const { helioscopeDesignsList: { name }  } = this.props;

    return (
      <div className="page-header">
        <div className="page-title" onClick={this.goBack}>
        <SVG className="svg-back-icon" src={require('assets/media/icons/backward_arrow_icon.svg')} />
          <FormattedMessage id="my.helioscope.projects" />
        </div>
        <div className="page-subtitle">/ {name || '...'}</div>
      </div>
    );
  }

  render() {
    const { helioscopeDesignsList: { isReady, designs }, dispatch, match: { params: { productId, projectId } } } = this.props;

    if (isReady && designs.length === 0) {
      return (
        <div className="app-content">
        <Header productId={productId} projectId={projectId} />
        <div className="helioscope_import helioscope-import-designs">
          {this.pageHeader()}
          <div className="main-title">Could not find any projects</div>
        </div>
        <Footer />
      </div>
      );
    }
    return (
      <div className="app-content">
        <Header productId={productId} projectId={projectId} />
        <div className="helioscope_import helioscope-import-designs">
          {this.pageHeader()}
          <div className="body">
            <HelioscopeDesignsList  dispatch={dispatch} projectID={projectId} helioscopeDesignsList={this.props.helioscopeDesignsList} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/* istanbul ignore next */
// function mapStateToProps(state) {
// }

function mapStateToProps(state) {
  return {
    helioscopeDesignsList: state.helioscopeDesignsList,
    isPrivilegedUser: state.user.isPrivilegedUser,
  };
}

export default connect(mapStateToProps)(HelioscopeDesigns);
