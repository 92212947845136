import * as PIXI from 'pixi.js';
import { addNewRoofArea, getRoofOnMap, roofAreaMarkerLatLngs } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { advanceRoofSelectingAlert, NEW_ROOF_INTERSECTION_ERROR_MSG } from '__editor/components/roofsSelector/components/roofsSelectorAdvanceRoofSelecting/roofsSelectorAdvanceRoofSelecting';
import { checkRoofSize } from '../drawingManager/utils/roofSizeAlert';
import { dispatch } from '__common/store';
import { getPixiLayer } from '__editor/googleMapsRoofsSelector/components/stage/stage';
import { newRoofOverlaping, clearRoof } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { selectRoofLeadEdge } from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/utils/selectEdge';
import { TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from 'actions';
import { turnGoogleMapsOnMapMoveControls } from '__editor/googleMapsRoofsSelector/components/map/map';
import { deployAdvanceSelecting, getAdvanceRoofSelectingModel } from '__common/components/advancedSelecting/advanceSelecting';

let shiftPressed = false;

export const renderAdanvaceRoofSelecting = () => {
  const pixiLayer: any = getPixiLayer();
  pixiLayer.addLayer((layer: PIXI.Container) => {
    const scale = getPixiLayer().stage.scale.x;
    const model = getAdvanceRoofSelectingModel(scale);
    layer.id = 'adanvceRoofSelector';
    pixiLayer.stage.children = pixiLayer.stage.children.filter(child => child.id !== 'adanvceRoofSelector');
    layer.addChild(model);
  });
};

export const shiftDown = () => {
  shiftPressed = true;
};

export const shiftUp = () => {
  shiftPressed = false;
};

export const isShiftPressed = () => shiftPressed;

export function deployAdvanceRoofSelecting(dblClick = false) {
  const pixiLayer = getPixiLayer();

  deployAdvanceSelecting({
    dblClick,
    selfIntersectionWarning: NEW_ROOF_INTERSECTION_ERROR_MSG,
    overlapingWarning: 'Roof cannot overlap another roof',
    onClear: () => clearGoogleAdvanceRoofSelecting(pixiLayer),
    overLapCheck: (roofPoints) => {
      const newRoofPoints = roofPoints.slice(0);

      const coords = newRoofPoints.map(coord => pixiLayer.fromPixelToLatLng(coord).toJSON());

      if (newRoofOverlaping(coords)) {
        advanceRoofSelectingAlert();
        clearGoogleAdvanceRoofSelecting(pixiLayer);
        return true;
      }
    },
    onDeploy: (newRoofPoints) => {
      if (newRoofPoints.length > 1) {
        const coords = newRoofPoints.map(coord => pixiLayer.fromPixelToLatLng(coord).toJSON());

        const { checkingMarker } = roofAreaMarkerLatLngs(coords);
          const roofAreaDeploy = () => {
          const roofId = addNewRoofArea(coords, newRoofPoints);
          setFirstDrawEdgeAsLeadEdge(roofId);
          const roof = getRoofOnMap(roofId);
          const leadEdgeCoords = [coords[0], coords[1]];
          const { lat, lng } = roof.roof.overlay.getRoofApproximateCenter();
          checkRoofSize(roofId, coords, { lat: lat(), lng: lng() }, leadEdgeCoords, 18);
          clearGoogleAdvanceRoofSelecting(pixiLayer);
      };
      clearRoof(checkingMarker, roofAreaDeploy);
    };
    },
  });
}

function setFirstDrawEdgeAsLeadEdge(roofId: number) {
  selectRoofLeadEdge({ edgeNumber: 0, roofId });
}

export function clearGoogleAdvanceRoofSelecting(pixiLayer) {
  setTimeout(() => {
    dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    dispatch(TURN_OFF_MEASUREMENT_TOOL());
    turnGoogleMapsOnMapMoveControls();

    pixiLayer.stage.children = pixiLayer.stage.children.filter(child => child.id !== 'adanvceRoofSelector' && child.id !== 'measurementTool');
  }, 30);
}
