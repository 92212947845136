import { getEditorDims } from '../../panelsEditorHelper';
import { getStage } from '../stage/stage';
import { isEdge } from '__common/utils/browserDetector';
import { mousePosition } from '../cursor/cursor';

let moveMapStart: any = {};

export function startMovingMap() {
  if (Object.keys(moveMapStart).length === 0) {
    moveMapStart = {
      x: mousePosition().x,
      y: mousePosition().y,
    };
  }
}

export function moveMap() {
  document.body.style.cursor = '-webkit-grabbing';
  const currPosition = {
    x: mousePosition().x,
    y: mousePosition().y,
  };

  const stage = getStage();

  const modi = isEdge() ? 0.15 : 1;
  const xDiff = (currPosition.x - moveMapStart.x) * modi;
  const yDiff = (currPosition.y - moveMapStart.y) * modi;

  const editorDims = getEditorDims();

  if (
    stage.x + xDiff < 0 &&
    stage.x - editorDims.width > -(editorDims.width * stage.scale.x) - xDiff
  ) {
    stage.x += xDiff;
  }

  if (
    stage.y + yDiff < 0
     &&
    stage.y - editorDims.height > -(editorDims.height * stage.scale.y) - yDiff
  ) {
    stage.y += yDiff;
  }

  return;
}

export function endMovingMap() {
  document.body.style.cursor = '-webkit-grab';
  moveMapStart = {};
  return;
}

export function isMovingMapInitied() {
  return (
    moveMapStart.x &&
    moveMapStart.y &&
    moveMapStart.x !== 0 &&
    moveMapStart.y !== 0
  );
}
