import { state, dispatch } from '__common/store';
import { isCursorInForbidenArea, isCursorSnapping, isCursorInZone } from '../cursor';
import { SET_ASCE_7_16_SNAPPING_CURSOR } from 'actions';
import { SET_ASCE_7_16_ORANGE_CURSOR, SET_ASCE_7_16_GREEN_CURSOR } from '../cursorActions';

export const asce716ResidentialsBlankColors = (cursor: PIXI.Graphics, cursorColide: boolean, mapType: string, productId: string, roofZone: number) => {
  const { settings: { canvasCenter, panelWidth, panelHeight }, editorCursor: { colour }, background: { selectedRoofId, moveArrayMode }, panels: {panelsToBeMoved} } = state();
  const x = cursor.x - canvasCenter.x;
  const y = cursor.y - canvasCenter.y;

  const snappingId = 9;

  const isInForbbidenZone = isCursorInForbidenArea(cursor, cursorColide, colour, x, y, panelWidth, panelHeight, selectedRoofId, mapType);

  if (isInForbbidenZone && colour !== 3) {
    return dispatch(SET_ASCE_7_16_ORANGE_CURSOR());
  }

  if (moveArrayMode && panelsToBeMoved.length > 0) {
    return dispatch(SET_ASCE_7_16_GREEN_CURSOR());
  }

  if (isCursorSnapping(cursorColide, isInForbbidenZone, colour, productId, snappingId) && colour !== snappingId) {
    return dispatch(SET_ASCE_7_16_SNAPPING_CURSOR());
  }

  if (isCursorInZone(cursorColide, isInForbbidenZone, colour, roofZone, 1, snappingId)) {
    return dispatch(SET_ASCE_7_16_GREEN_CURSOR());
  }
};

