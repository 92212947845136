export const apiField = 'tornado_speed';

interface TornadoSpeedField {
  type: string;
  apiField: string;
  tooltip: () => string;
  label: string;
  min: number;
  max: number;
  missing?: any;
}

function makeConfig(min: number = 0, max: number = 138, label:string= 'tornado_speed'): TornadoSpeedField {
  return {
    type: 'Number',
    label,
    apiField,
    tooltip: () => "Buildings and other structures classified as risk category III or IV and located in the tornado prone regions including the MWFRS and all components and cladding is designed to resist the greater of the tornado loads or wind loads.",
    min,
    max,
    missing: undefined,
  };
}

const config = makeConfig();

export default config;

export const metricTornadoSpeedField = makeConfig(0, 222, 'tornado_speed.kmph');
