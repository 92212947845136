import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isResidentialProduct, isCommercialProduct } from '__common/constants/products';
import { windZonesDistanceForGableAndHip } from './asce716ResidentialZonesDistances';
import { state } from '__common/store';
import { getBuildingHeight } from 'projectDesign/components/projectConfiguration/projectConfiguration';

export const getWindZoneDistance = (metersPerPixel: number, productId: number) => {
  if (isResidentialProduct(productId)) {
    const distance = windZonesDistanceForGableAndHip();
    const distanceMeters = feetsToMeters(distance);
    return distanceMeters / metersPerPixel;
  }

  if (isCommercialProduct(productId)) {
    return getWindZoneDistanceForCommercialInPixels(metersPerPixel);
  }

  return 0;
};

const getWindZoneDistanceForCommercialInPixels = (metersPerPixel: number) => {
  const distanceFt = getWindZoneDistanceForCommercial();
  const distanceMeters = feetsToMeters(distanceFt);
  const distancePx = distanceMeters / metersPerPixel;
  return distancePx;
};

export const getWindZoneDistanceForCommercial = () => {
  const { projectConfiguration: { projectEnvConfig: { building_length } } } = state();
  const buildingHeightFt = getBuildingHeight();
  return Math.min( 0.6 * buildingHeightFt, 0.1 * building_length );
};

export const COMMERCIAL_WIND_ZONE_FORMULA = 'min(0.6h, 0.1WL)';
