import * as PIXI from 'pixi.js';
import Polygon from 'polygon';
import rubberBandModel from '__editor/panelsEditor/models/rubberBand';
import shadowPanelModel from '__editor/panelsEditor/models/shadowPanel';
import { cursorPosition } from '__editor/panelsEditor/components/cursor/cursor';
import { dispatch, state } from '__common/store';
import { getNearestGroup } from '../cursor/utils/snapToGridHelper';
import { getPolyMinMaxCoords } from '__common/calculations/polygon';
import { removeChild } from '__editor/panelsEditor/components/stage/stage';
import { searchCollisionItems } from '__editor/panelsEditor/components/panels/panelsCollisions';
import {
  START,
  RESIZE,
  RESET_RUBBERBAND_STATE,
  REQUEST_ADD_PANELS,
  ADD_SHADOW_PANELS,
  SET_TOOLTIP_CONTENT,
  HIDE_CURSOR, SHOW_CURSOR,
  CHANGE_SYSTEM_CURSOR,
  RESET_CURSOR_STATE,
  SET_PANELS_TO_HIDE_ON_REMOVE,
  REMOVE_PANELS,
} from 'actions';
import { get_drawing_direction } from '../panels/utils/populating';
import { draw_double_panels_on_area } from '../panels/utils/draw_double_panels_on_area';
import { drawGftPanelsInTables } from '../panels/utils/drawGftPanelsInTables';
import { drawUlaPanelsInTables } from '../panels/utils/drawUlaPanelsInTables';
import { draw_on_area, DrawPanelFunction } from '../panels/utils/draw_on_area';
import { pairWithSiblings, createPanel } from '../panels/utils/panelsManagment';
import { isRMDT, isGFT, isULA, isAscender } from '__common/constants/products';
import nextUid from '__common/calculations/nextUid';
import { drawAscenderPanelsInTables } from '../panels/utils/drawAscenderPanelsInTables';

let panelsShadowContainer: PIXI.Container;

export const drawShadowPanel = shadowPanel => shadowPanelModel(shadowPanel);

export function startRubberBand(x, y) {
  dispatch(HIDE_CURSOR());
  dispatch(START(x, y));
  dispatch(CHANGE_SYSTEM_CURSOR('crosshair'));
}

export function resizeRubberBand(startX, startY, x, y) {
  const { rubberBand } = state();
  const points = _calculatePoints(startX, startY, x, y);
  dispatch(RESIZE(points));
  if (rubberBand.mode === 'draw') {
    populateWithPanelsShadow();
  } else {
    hidePanelsToRemove(points);
  }
}

export function deployRubberBand() {
  const { rubberBand } = state();
  if (rubberBand.mode === 'draw') {
    populateWithPanels();
    dispatch(SHOW_CURSOR());
  }

  if (rubberBand.mode === 'remove') {
    removePanels();
  }

  dispatch(RESET_RUBBERBAND_STATE());
  dispatch(RESET_CURSOR_STATE());
  dispatch(SET_TOOLTIP_CONTENT(null));
}

export const drawRubberBand = rubberBand => rubberBandModel(rubberBand);

export const removeRubberBand = children => removeChild(children, 'rubberBand');

export const getPanelsShadowContainer = () => {
  if (panelsShadowContainer) {
    return panelsShadowContainer;
  }
  panelsShadowContainer = new PIXI.Container();
  panelsShadowContainer.id = 'panelsShadowContainer';
  panelsShadowContainer.zIndex = 2;
  panelsShadowContainer.interactiveChildren = false;
  return panelsShadowContainer;
};

function getDrawingArea() {
  const { start, points } = state().rubberBand;
  const { x, y } = cursorPosition();
  const mouseMoveCords = {
    startX: start.x,
    startY: start.y,
    endX: x,
    endY: y,
  };
  const direction = get_drawing_direction(mouseMoveCords);
  return {
    direction,
    points,
  };
}

function hidePanelsToRemove(points: {x:number, y:number}[]) {
  const { settings: { canvasCenter }, rubberBand: { panelsToRemove: oldPanelsToRemove } } = state();
  const rubberBandAreaPolygon = new Polygon(points);
  const areaMinMax = getPolyMinMaxCoords(rubberBandAreaPolygon);
  areaMinMax.minX -= canvasCenter.x;
  areaMinMax.minY -= canvasCenter.y;
  areaMinMax.maxX -= canvasCenter.x;
  areaMinMax.maxY -= canvasCenter.y;
  const newPanelsToRemove = searchCollisionItems(areaMinMax);
  const panels = newPanelsToRemove.map(value => { return { x: value.maxX, y: value.maxY }; });

  if (newPanelsToRemove.length > 0) {
    const panelIdsToRemove = pairWithSiblings(newPanelsToRemove);
    updateTooltipData(panels);
    dispatch(SET_PANELS_TO_HIDE_ON_REMOVE(panelIdsToRemove));
  } else if (oldPanelsToRemove.length !== 0) {
    // We should also check for empty result of collision 
    // in case when you had selected at least one panel but then you wanted it back.
    // However, we should do it only once, otherwise we would dispatch an action
    // wich each mouse move. That's why we check length of panelsToRemove from the previous state.
    dispatch(SET_PANELS_TO_HIDE_ON_REMOVE([]));
  }
}

function populateWithPanelsShadow() {
  const { direction, points } = getDrawingArea();
  if (!points || points.length === 0) { // skip below if rubberband has no panels
    return;
  }
  const panels = draw_panels_on_area(direction, points, createPanel, null);
  if (panels && panels.length>0) {
    updateTooltipData(panels);
    dispatch(ADD_SHADOW_PANELS(panels));
  }
}

function populateWithPanels() {
  const { direction, points } = getDrawingArea();
  if (!points || points.length === 0) { // skip below if rubberband has no panels
    return;
  }
  const groupId = getNearestGroup() || nextUid();
  const panels = draw_panels_on_area(direction, points, createPanel, groupId);
  dispatch(REQUEST_ADD_PANELS(panels));
}

function removePanels() {
  const { panelsToRemove } = state().rubberBand;
  dispatch(REMOVE_PANELS(panelsToRemove));
}

function updateTooltipData(panels: panel[]) {
  const rowsAndColumns = panels.reduce((acc, panel) => {
    acc.rows.add(Math.floor(panel.y * 100) / 100);
    acc.columns.add(Math.floor(panel.x * 100) / 100);
    return acc;
  },{
    rows: new Set([]),
    columns: new Set([]),
  });
  dispatch(SET_TOOLTIP_CONTENT(`${rowsAndColumns.rows.size}x${rowsAndColumns.columns.size}`));
}

function _calculatePoints(starX, startY, diagonalX, diagonalY) {
  const initialX = starX;
  const initialY = startY;
  const x2 = initialX;
  const y2 = diagonalY;

  const x4 = diagonalX;
  const y4 = initialY;

  return [
    new PIXI.Point(initialX, initialY),
    new PIXI.Point(x2, y2),
    new PIXI.Point(diagonalX, diagonalY),
    new PIXI.Point(x4, y4),
  ];
}

function draw_panels_on_area(direction: string, points: { x: number, y: number }[], createPanel: DrawPanelFunction, groupId: number | null) {
  const { projectConfiguration: { productId } } = state();

  if (isRMDT(productId)) {
    return draw_double_panels_on_area(direction, points, createPanel, groupId);
  }

  if (isGFT(productId)) {
    return drawGftPanelsInTables(direction, points, createPanel, groupId);
  }

  if (isAscender(productId)) {
    return drawAscenderPanelsInTables(direction, points, createPanel, groupId);
  }

  if(isULA(productId) ) {
    return drawUlaPanelsInTables(direction, points, createPanel, groupId);
  }
  
  return draw_on_area(direction, points, createPanel, groupId);
}
