import { apiField, WindDeflectorOption, WindDeflectorField } from 'projectDesign/components/projectConfiguration/fields/types/windDeflectors';

function config(showNoDeflector=true): WindDeflectorField {
  const options = [
    WindDeflectorOption.everywhere,
    WindDeflectorOption.edgeDeflector,
    WindDeflectorOption.ul2703,
    ...(showNoDeflector ? [WindDeflectorOption.noDeflector] : [])  
  ]
  
  return {
    label: 'windDeflectors',
    type: 'Select',
    apiField,
    missing: undefined,
    data: options
  }
};

export const options = {
  allDeflectors: {
    value: 1,
    name: 'YES',
  }, noDeflectors: {
    value: 0,
    name: 'NO',
  }, 
  
};

export const windDeflectorGF1=() => {
  return{type: 'Select',
  apiField,
  label: 'windDeflectors',
  missing: undefined,
  data: [    
    options.allDeflectors,
    options.noDeflectors,
  ]}
}

export  const windDeflectorGF10=windDeflectorGF1();



export default config;
