import history from '__common/modules/history';
import { SaveLoadProjectActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';

export function SAVE_PROJECT(data: any, productId: string, projectId?: string, withBom?: boolean) {
  return {
    type: SaveLoadProjectActionTypes.SAVE_PROJECT,
    payload: {
      data,
      productId,
      projectId,
      withBom,
    },
  };
}

export function AUTO_SAVE_PROJECT(data: any, productId: string, projectId?: string) {
  return {
    type: SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT,
    payload: {
      data,
      productId,
      projectId,
    },
  };
}

export function REDIRECT_TO_BOM(data: any) {
  return {
    type: SaveLoadProjectActionTypes.REDIRECT_TO_BOM,
    payload: {
      data,
    },
  };
}

export function REDIRECT_TO_ENGINEERING(projectId: string, data: { pk: string }) {
  history.push(`/project/engineering/${projectId}/${data.pk}`);
  return {
    type: SaveLoadProjectActionTypes.REDIRECT_TO_ENGINEERING,
  };
}

export function CLEAR_PK() {
  return {
    type: SaveLoadProjectActionTypes.CLEAR_PK,
  };
}

export function FETCH_PROJECT(projectId: string) {
  return {
    type: SaveLoadProjectActionTypes.FETCH_PROJECT,
    payload: {
      projectId,
    },
  };
}

export function LOAD_DATA_FROM_API(dataToLoad: any) {
  return {
    type: SaveLoadProjectActionTypes.LOAD_DATA_FROM_API,
    payload: {
      dataToLoad,
    },
  };
}

export function CLEAR_DATA_TO_LOAD() {
  return {
    type: SaveLoadProjectActionTypes.CLEAR_DATA_TO_LOAD,
  };
}

export function AUTO_SAVE_PROJECT_FINISHED() {
  return {
    type: SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FINISHED,
  };
}

export interface SAVE_PROJECT_FAILED_ACTION {
  error_msg: string;
  clamp_check_failed?: boolean;
  project_id?: string;
}

export function SAVE_PROJECT_FAILED(payload: SAVE_PROJECT_FAILED_ACTION | null): Action<SAVE_PROJECT_FAILED_ACTION> {
  return {
    type: SaveLoadProjectActionTypes.SAVE_PROJECT_FAILED,
    payload,
  };
}

export interface AUTO_SAVE_PROJECT_FAILED_ACTION {
  error_msg: string;
  clamp_check_failed?: boolean;
  project_id?: string;
}

export function AUTO_SAVE_PROJECT_FAILED(payload : AUTO_SAVE_PROJECT_FAILED_ACTION | null): Action<AUTO_SAVE_PROJECT_FAILED_ACTION> {
  return {
    type: SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FAILED,
    payload,
  };
}

export function PREVENT_AUTO_SAVE_START() {
  return {
    type: SaveLoadProjectActionTypes.PREVENT_AUTO_SAVE_START,
  };
}

export function PREVENT_AUTO_SAVE_FINISHED() {
  return {
    type: SaveLoadProjectActionTypes.PREVENT_AUTO_SAVE_FINISHED,
  };
}


export function SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH({ isSaveProjectPending } : SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH_ACTION): Action<SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH_ACTION>  {
  return {
    type: SaveLoadProjectActionTypes.SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH,
    payload: {
      isSaveProjectPending,
    },
  };
}

export interface SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH_ACTION {
  isSaveProjectPending: boolean;
}
