import Events from '__common/modules/Events';
import { CLEAR_TEMP_POINT, TURN_OFF_BANNERS, TURN_ON_BANNERS } from 'actions';
import { clearEvents } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorHelper';
import { deployAdvanceRoofSelecting, shiftDown, shiftUp } from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelecting';
import { deployBingAdvanceRoofSelecting } from '__editor/bingMapsRoofsSelector/components/bingMapsAdvanceRoofSelecting/bingMapsAdvanceRoofSelecting';
import { dispatch, state } from '__common/store';

export function initStageEvents() {
  const { roofsSelector: { mapType } } = state();
  
  clearEvents();
  
  const deployRubberFn = mapType === 'google' ? deployAdvanceRoofSelecting : deployBingAdvanceRoofSelecting;

  Events.addEventListener('keydown.roofsSelectorStage', (e) => {
    if (e.key === 'Shift') {
      shiftDown();
    }

    if (e.key === 'Escape') {
      deployRubberFn();
    }
  });

  Events.addEventListener('keyup.roofsSelectorStage', () => {
    const { advanceRoofSelecting: { tempPoint } } = state();
    if (tempPoint !== null) {
      dispatch(CLEAR_TEMP_POINT());
    }

    shiftUp();
  });

  Events.addEventListener('dblclick.roofsSelectorStage', () => {
    const { advanceRoofSelecting: { roofPoints } } = state();
    const dblClick = true;
    if (roofPoints.length > 1) {
      deployRubberFn(dblClick);
    }
  });

  Events.addEventListener('mousedown.roofsSelectorStage', (e) => {
    if (e.button === 2 || e.which === 3) {
      const { advanceRoofSelecting: { enabled, banners } } = state();
      if (!enabled) {
        if (banners) {
          dispatch(TURN_OFF_BANNERS());
        } else {
          dispatch(TURN_ON_BANNERS());
        }
      }
    }
  });
}


