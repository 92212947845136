export const apiField = 'anchor_type';

export const options = {
  mechanicalAttachment: {
    value: 1,
    name: 'MECHANICAL ATTACHMENT',
  }, 
  heatWelded: {
    value: 2,
    name: 'HEAT WELDED',
  }
};

const config = {
  type: 'Select',
  apiField,
  label: 'anchorType',
  data: [
    options.mechanicalAttachment,
    options.heatWelded,
  ],
};

export default config;
