import React from 'react';
import SVG from 'react-inlinesvg';
import { dispatch } from '__common/store';
import { TOGGLE_INPUT_UNIT } from '../projectConfigurationActions';

export const MODULE_SELECTION_PAGE = 'module-selection';
export const LOCATION_AND_LOADS_PAGE = 'location-and-loads';
export const RAIL_SYSTEM_PAGE = 'rail-system';
export const ADDITIONAL_USER_INPUTS_PAGE = 'additional-user-inputs';

const helioscopePage = () => {
  return {
    page: 'helioscope-selection',
    icon: <SVG src={require('assets/media/icons/helioscope_logo.svg')} />,
    content: undefined,
    postRender: undefined,
    active: true,
    tooltip: undefined, 
    keepOpen: undefined,
    invalid: false,
  };
};

const auroraPage = () => {
  return {
    page: 'aurora',
    icon: <SVG width= {200} height={200} src={require('assets/media/icons/aurora_logo.svg')} />,
    content: undefined,
    postRender: undefined,
    active: true,
    tooltip: undefined, 
    keepOpen: undefined,
    invalid: false,
  };
};

const moduleSelectionPage = () => {
  return  {
    page: 'module-selection',
    icon: <SVG src={require('assets/media/icons/sidebar_module.svg')} />,
    content: undefined,
    postRender: undefined,
    active: false,
    tooltip: undefined,
    keepOpen: undefined,
    invalid: false,
  };
};

const locationAndLoads = () => {
  return {
    page: 'location-and-loads',
    icon: <SVG src={require('assets/media/icons/sidebar_location.svg')} />,
    content: undefined,
    postRender: undefined,
    tooltip: undefined,
    active: false,
    keepOpen: undefined,
    invalid: false,
  };
};

const railSystem = () => {
  return {
    page: 'rail-system',
    icon: <SVG src={require('assets/media/icons/sidebar_racking.svg')} />,
    content: undefined,
    postRender: undefined,
    tooltip: undefined,
    active: false,
    keepOpen: undefined,
    invalid: false,
  };
};

export function copyProject(onClick: Function) {
  return {
    page: 'copy-project',
    icon: <div className="material-icons" style={{ color: 'white' }}>content_copy</div>,
    content: null,
    postRender: undefined,
    active: false,
    tooltip: 'COPY PROJECT',
    onClick,
    invalid: false,
    bottomIcon: true,
  };
}

export function getAdditionalUserInputsPage() {
  return {
    page: 'additional-user-inputs',
    icon: <SVG src={require('assets/media/icons/additional_user_inputs.svg')} />,
    content: undefined,
    postRender: undefined,
    tooltip: 'Additional User Inputs',
    active: false,
    keepOpen: undefined,
    invalid: false,
  };
}

const toggleUnits = () => {
return {
    page: 'toggle-units',
    icon: <SVG src={require('assets/media/icons/icon_metrics.svg')} />,
    content: null,
    postRender: undefined,
    active: false,
    tooltip: 'Set Toggle Units',
    onClick: () => {
      dispatch(TOGGLE_INPUT_UNIT());
    },
    bottomIcon: true,
  };
};

export function getProjectDesignPages(helioscope: boolean, aurora: boolean,  showToggleUnits: boolean = false, shouldShowAdditionalUserInputs: boolean = false, shouldAllowCopyProj: boolean, onClickCopy = null) {
  if (helioscope) {
    return [
      helioscopePage(),
    ];
  }

  if (aurora) {
    return [
      auroraPage(),
    ]
  }
  
 const pages  =  [
    moduleSelectionPage(),
    locationAndLoads(),
    railSystem(),
    ...(shouldShowAdditionalUserInputs ? [getAdditionalUserInputsPage()] : []),
    ...(showToggleUnits ? [toggleUnits()] : []),
    ...(shouldAllowCopyProj? [copyProject(onClickCopy)]: []),
  ];
  
  return pages;
}
