import allRms from 'projectDesign/components/projectConfiguration/utils/defaultProps/allRms';
import Sf from 'projectDesign/components/projectConfiguration/utils/defaultProps/sf';
import SfmEnhanced from 'projectDesign/components/projectConfiguration/utils/defaultProps/SfmEnhanced';
import SM from 'projectDesign/components/projectConfiguration/utils/defaultProps/sm';
import MetalX from './defaultProps/metalX';
import SmTilt from 'projectDesign/components/projectConfiguration/utils/defaultProps/smTilt';
import Gft from 'projectDesign/components/projectConfiguration/utils/defaultProps/gft';
import Ula from './defaultProps/ula';
import ecoFoot2Plus from 'projectDesign/components/projectConfiguration/utils/defaultProps/ecoFoot2Plus';
import { products } from '__common/constants/products';
import NxtHorizon from './defaultProps/NxtHorizon';
import Ascender from './defaultProps/ascender';
import SMTiltPR from './defaultProps/smTiltPR'

export const getDefaultProjectConfiguration = (product: number) => {
  switch (product) {
    case products.solarmount_hd:
    case products.solarmount_2:
    case products.solarmount_lt:
    case products.solarmount_ascender_flush:
      return SM();
    case products.solarmount_2_tilt:
    case products.nxt_tilt:
    // case products.adjustable_tilt_system:
      return SmTilt();
    case products.sunframe:
      return Sf();
    case products.sfmEnhanced:
    case products.sfmInfinity:
      return SfmEnhanced(product);
    case products.metal_x:
      return MetalX();
    case products.rm:
    case products.rm_5:
    case products.rm_dt:
    case products.rm_10_ultra:
    case products.rm_gridflex:
    case products.rm_gridflex_10:
    case products.rm_10_evolution:
      return allRms(product);
    case products.gft:
      return Gft();
    case products.ula:
      return Ula();
    case products.ecofoot2_plus:
      return ecoFoot2Plus();
    case products.ascender:
      return Ascender();
    case products.nxt_umount:
    case products.nxt_horizon_lt:
    case products.nxt_horizon_hd:
      return NxtHorizon();
    case products.sm_tilt_pr:
    case products.sm_tilt_pr_lt:
      return SMTiltPR()
    default:
      return SM();
  }
};
