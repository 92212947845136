import * as React from 'react';
import { Route } from 'react-router-dom';
import AppHistory from '__common/modules/history';

const RedirectPublic: React.StatelessComponent<redirectPublicComponentState> = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated) {
        return (AppHistory.push(`/`));
      }
      return (<Component {...props} {...rest} />);
    }}
  />
);

export default RedirectPublic;
