import * as React from 'react';
import { connect } from 'react-redux';
import MetalXAttachmentType from './metalXAttachmentType';

class MetalxAttachmentSelector extends React.Component<{dispatch: Function, bom: bomState, productId: string}, {}> {

  renderMetalXAttachments(){
    const { bom: { attachmentsList }, dispatch } = this.props;
    return (
      <div className="attachments_selector">
        <div className="control-panel-label bom-sidebar-main-header roof-attachments-header">
            Select Roof Attachments
        </div>
        { Object.keys(attachmentsList).map(type => {
          const groupTypes = Object.keys(attachmentsList[type]);
          const selectedGroupItem = groupTypes.find(groupItem => attachmentsList[type][groupItem].selected);
          if (groupTypes?.length) {
            return <MetalXAttachmentType dispatch={dispatch} group={type} groupItemTypes={groupTypes} selectedGroupItem={selectedGroupItem? selectedGroupItem: ''}/>;
          }
          return null;
        })}             
      </div>
    );
  }

  render() {
    return this.renderMetalXAttachments();
  }
}

function mapStateToProps(state) {
  return {
    bom: state.bom,
  };
}

export default connect(mapStateToProps)(MetalxAttachmentSelector);
