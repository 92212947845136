import { ActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import {
  IMPORT_HELIOSCOPE_PROJECT_SUCCESS,
  LOAD_HELIOSCOPE_PROJECT_CONFIGURATION,
  IMPORT_HELIOSCOPE_PROJECT_FAILED,
  SET_LOADER_MSG,
} from 'actions';


export function helioscopeDesignConfigInitDataRequest(action$) {
  return action$.ofType(ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_REQUEST)
    .switchMap((action) => {
      const projectId = action.payload.projectId;
      const designId = action.payload.designId;
      const fieldSegmentId = action.payload.fieldSegmentId;
      const productId = action.payload.productId;

      return ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_SUCCESS),
        onSuccess: LOAD_HELIOSCOPE_PROJECT_CONFIGURATION,
        onError: IMPORT_HELIOSCOPE_PROJECT_FAILED,
        onErrorMessage: 'Cannot fetch project data',
        socketName: 'helioscope',
        link: hitApi('get', `api/v1/helioscope/${projectId}/${designId}/${fieldSegmentId}/${productId}/`),
      });
    });
}

export function helioscopeDesignFullDesignConfigInitDataRequest(action$) {
  return action$.ofType(ActionTypes.HELIOSCOPE_DESIGN_FULL_DESIGN_CONFIG_INIT_DATA_REQUEST)
    .switchMap((action) => {
      const projectId = action.payload.projectId;
      const designId = action.payload.designId;
      const productId = action.payload.productId;

      return ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_SUCCESS),
        onSuccess: LOAD_HELIOSCOPE_PROJECT_CONFIGURATION,
        onError: IMPORT_HELIOSCOPE_PROJECT_FAILED,
        onErrorMessage: 'Cannot fetch project data',
        socketName: 'helioscope',
        link: hitApi('get', `api/v1/helioscope/${projectId}/${designId}/${productId}/`),
      });
    });
}

export function importHelioscopeProject(action$) {
  return action$.ofType(ActionTypes.IMPORT_HELIOSCOPE_PROJECT)
    .switchMap(action =>
      ObservableAjax({
        onSuccess: IMPORT_HELIOSCOPE_PROJECT_SUCCESS,
        onErrorMessage: 'Cannot import project',
        socketName: 'helioscope',
        onSocketFailure: 'HELIOSCOPE IMPORT FAILURE',
        showBackendErrorMsgOnFailure: true,
        link: hitApi('post', 'api/v1/helioscope/import/', action.payload.data),
      }),
    );
}

export function importHelioscopeFullDesignProject(action$, store) {
  return action$.ofType(ActionTypes.IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT)
    .switchMap((action) => {
      store.dispatch(SET_LOADER_MSG('Importing Helioscope Full Design Project'));
      return ObservableAjax({
        onSuccess: IMPORT_HELIOSCOPE_PROJECT_SUCCESS,
        onErrorMessage: 'Cannot import project',
        socketName: 'helioscope',
        onSocketFailure: 'HELIOSCOPE FULL DESIGN IMPORT FAILURE',
        showBackendErrorMsgOnFailure: true,
        link: hitApi('post', 'api/v1/helioscope/full_design_import/', action.payload.data),
      });
    });
}
