import { CHECK_UB1_PROJECTS_FAILED, SET_UB1_PROJECTS } from './yourProjectsActions';
import { dispatch } from '__common/store';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { YourProjectsActionTypes } from 'actionsConstants';
import utils from "react-day-picker/moment";
import {
  LOAD_YOUR_PROJECTS_DATA,
  SET_INFO_DATA,
  SET_YOUR_PROJECTS_PAGINATION_TOTAL,
  REDIRECT_TO_COMBINED_PROJECT,
  POPULATE_COMBINED_PROJECTS,
  CANNOT_GET_PROJECTS,
  } from 'actions';
import { getCurrentTime } from '__common/calculations/currentTime';

export function getUserProjects(action$) {
  return action$.ofType(YourProjectsActionTypes.GET_USER_PROJECTS)
    .switchMap((action) => {
      const { limit, offset, ordering, sortMethod, filterString, oldUb, appliedFilters } = action.payload;
      const sort = sortMethod === 'asc' ? '-' : '';
      let url = `${oldUb ? 'tool/' : 'api/v1/'}user_projects/?limit=${limit}&offset=${offset}&ordering=${sort}${ordering}&search=${filterString}`; 
      if (appliedFilters?.project_wattage_range?.from || appliedFilters?.project_wattage_range?.from === 0) url += `&project_wattage_lower_range=${appliedFilters?.project_wattage_range?.from}`;
      if (appliedFilters?.project_wattage_range?.to || appliedFilters?.project_wattage_range?.to === 0) url += `&project_wattage_upper_range=${appliedFilters?.project_wattage_range?.to}`;
      if (appliedFilters?.project_created_range?.from) url += `&project_created_lower_range=${utils.formatDate(appliedFilters?.project_created_range?.from)}`;
      if (appliedFilters?.project_created_range?.to) url += `&project_created_upper_range=${utils.formatDate(appliedFilters?.project_created_range?.to)}`;
      if (appliedFilters?.project_price_range?.from || appliedFilters?.project_price_range?.from === 0) url += `&project_price_lower_range=${appliedFilters?.project_price_range?.from}`;
      if (appliedFilters?.project_price_range?.to || appliedFilters?.project_price_range?.to === 0) url += `&project_price_upper_range=${appliedFilters?.project_price_range?.to}`;
      if (appliedFilters?.project_state) url += `&project_state=${appliedFilters?.project_state}`;
      if (appliedFilters?.product) url += `&product=${appliedFilters?.product}`;
      if (appliedFilters?.module) url += `&module=${appliedFilters?.module}`;
      return ObservableAjax({
        takeUntil: action$.ofType(YourProjectsActionTypes.GET_USER_PROJECTS),
        onSuccess: [LOAD_YOUR_PROJECTS_DATA, SET_YOUR_PROJECTS_PAGINATION_TOTAL],
        onErrorAction: () => {
          dispatch(CANNOT_GET_PROJECTS());
        },
        onErrorMessage: 'Cannot get user project',
        link: hitApi('get', url, {}, {}, oldUb), 
      });
    });
}

export function checkUB1Projects(action$) {
  return action$.ofType(YourProjectsActionTypes.CHECK_UB1_PROJECTS)
    .switchMap((action) => {
      return ObservableAjax({
        takeUntil: action$.ofType(YourProjectsActionTypes.CHECK_UB1_PROJECTS),
        onSuccess: SET_UB1_PROJECTS,
        onErrorAction: () => {
          dispatch(CHECK_UB1_PROJECTS_FAILED());
        },
        link: hitApi('get', 'tool/user_projects/?limit=10&offset=0&ordering=-created', {}, {}, true),
      });
    });
}

export function initUserProjects(action$) {
  return action$.ofType(YourProjectsActionTypes.INIT_USER_PROJECTS)
    .switchMap((action) => {
      return ObservableAjax({
        takeUntil: action$.ofType(YourProjectsActionTypes.INIT_USER_PROJECTS),
        onSuccess: [LOAD_YOUR_PROJECTS_DATA, SET_INFO_DATA, SET_YOUR_PROJECTS_PAGINATION_TOTAL],
        onErrorMessage: 'Cannot init project data',
        link: hitApi('get', `${action.payload.oldUb ? 'tool/' : 'api/v1/'}user_projects/?limit=${action.payload.rowsPerPage}&offset=${0}`, {}, {}, action.payload.oldUb),
      });
    });
}

export function deleteProject(action$) {
  return action$.ofType(YourProjectsActionTypes.DELETE_PROJECT)
    .switchMap((action) => {
      const { postDeleteAction, projectId } = action.payload;
      return ObservableAjax({
        takeUntil: action$.ofType(YourProjectsActionTypes.DELETE_PROJECT),
        onSuccess: postDeleteAction,
        onErrorMessage: 'Cannot delete projects data',
        link: hitApi('delete', `api/v1/project/${projectId}/`),
      });
    });
}

export function combineProjects(action$) {
  return action$.ofType(YourProjectsActionTypes.COMBINE_PROJECT)
    .switchMap((action) => {
      const { projects } = action.payload;
      return ObservableAjax({
        takeUntil: action$.ofType(YourProjectsActionTypes.COMBINE_PROJECT),
        onSuccess: REDIRECT_TO_COMBINED_PROJECT,
        onErrorMessage: 'Cannot combine projects',
        link: hitApi('post', `api/v1/combined_projects/`, { projects, created_user_time: getCurrentTime() }),
      });
    });
}

export function getCombinedproject(action$) {
  return action$.ofType(YourProjectsActionTypes.GET_COMBINED_PROJECT)
  .switchMap((action) => {
    const { combinedProjectId } = action.payload;
    return ObservableAjax({
      takeUntil: action$.ofType(YourProjectsActionTypes.GET_COMBINED_PROJECT),
      onSuccess: POPULATE_COMBINED_PROJECTS,
      onErrorMessage: 'Cannot get combined project',
      link: hitApi('get', `api/v1/combined_projects/${combinedProjectId}/`),
    });
  });
}

