import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import { Link } from 'react-router-dom';
import { REGISTRATION_REQUEST, USER_CLEAR_REGISTRATION_ERROR } from 'actions';
import config from 'config';

type State = {
  email: string,
  password: string,
  repeatedPassword: string,
  errors: {},
  registered: boolean,
};

type Props = {
  dispatch: Function,
};

export default class Registration extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    if (config.domainName !== 'localhost') {
      window.location = config.ssoRegistrationUrl;
    }
  }
  state = { email: '', password: '', repeatedPassword: '', errors: {}, registered: false };

  checkAndClearErrors() {
    const { errors } = this.state;
    const { dispatch } = this.props;
    if (errors && Object.keys(errors).length > 0) {
      dispatch(USER_CLEAR_REGISTRATION_ERROR());
    }
  }

  setEmail = (email) => {
    this.setState(() => ({ email }));
    this.checkAndClearErrors();
  }

  setPassword = (password) => {
    this.setState(() => ({ password }));
    this.checkAndClearErrors();
  }

  setRepeatedPassword = (repeatedPassword) => {
    this.setState(() => ({ repeatedPassword }));
    this.checkAndClearErrors();
  }

  handleKeyPressOnFormInput = (e) => {
    if (e.key === 'Enter') {
      this.submitRegistrationFrom();
    }
  }

  submitRegistrationFrom = () => {
    const { email, password, repeatedPassword } = this.state;
    const { dispatch } = this.props;

    if (!this.isFormValidate()) {
      return;
    }

    dispatch(REGISTRATION_REQUEST(
      email,
      password,
      repeatedPassword,
      this.postRegistrationAction,
      this.onErrorAction,
    ));
  }

  clearRegistrationFrom = () => {
    this.setState(() => ({
      email: '',
      password: '',
      repeatedPassword: '',
      errors: {},
    }));
  }

  postRegistrationAction = (data) => {
    this.setState(() => ({
      email: '',
      password: '',
      repeatedPassword: '',
      errors: {},
      activationLink: '',
      registered: true,
    }));
  }

  onErrorAction = (response: { [fieldName: string]: string[] }) => {
    this.setState(() => ({ errors: response }));
  }

  printErrors = (fieldName: string) => {
    const { errors } = this.state;

    if (!errors || Object.keys(errors).length === 0) {
      return null;
    }

    return Object.keys(errors).map((field) => {
      if (fieldName === field) {
        return errors[field].map((error, index) => {
          return (
            <div key={index} className={`error ${field}`}>
              {error}
            </div>);
        });
      }
    });
  }

  printNonFieldsError = () => {
    return (
      <div className="non-field-errors">
        {this.printErrors('non_field_errors')}
      </div>
    );

  }

  isFormValidate = () => {
    const { password, repeatedPassword } = this.state;

    if (password !== repeatedPassword) {
      this.setState(() => ({
        errors: {
          password: ['Password and repeated password not match'],
          repeatedPassword: ['Password and repeated password not match'],
        },
      }));

      return false;
    }

    return true;
  }

  registrationForm = () => {
    const { email, password, repeatedPassword } = this.state;
    return (
      <form id="registration-form">
        <div className="input-label">
          Email:
        </div>
        {this.printErrors('email')}
        <TextField
          id="email"
          type="email"
          value={email}
          onKeyDown={this.handleKeyPressOnFormInput}
          onChange={this.setEmail}
        />
        <div className="input-label">
          Password:
        </div>
        {this.printErrors('password')}
        <TextField
          id="password"
          type="password"
          value={password}
          onKeyDown={this.handleKeyPressOnFormInput}
          onChange={this.setPassword}
        />
        <div className="input-label">
          Repeat password:
        </div>
        {this.printErrors('repeatedPassword')}
        <TextField
          id="repeatedPassword"
          type="password"
          value={repeatedPassword}
          onKeyDown={this.handleKeyPressOnFormInput}
          onChange={this.setRepeatedPassword}
        />
        <Button
          flat={true}
          className="form_button"
          type="button"
          id="registration_button"
          onClick={this.submitRegistrationFrom}
        >
          Register
        </Button>
        <Button
          flat={true}
          className="form_button"
          type="button"
          id="clear_registration_button"
          onClick={this.clearRegistrationFrom}
        >
          Clear
        </Button>
      </form>
    );
  }

  registeredSuccessfuly = () => {
    return (<div className="registration-succcess">Registered successfully. You can log in <Link to="/login">here</Link></div>);
  }

  content = () => {
    const { registered } = this.state;
    const form = registered ? this.registeredSuccessfuly() : this.registrationForm();
    return (
      <>
        <div className="maintitle">
          REGISTER YOUR ACCOUNT
          {this.printNonFieldsError()}
        </div>
        {form}
      </>
    );
  }

  render() {
    return (
      <div className="app-content registration-page">
        <Header />
        <HeaderImage content={this.content()} />
        <Footer />
      </div>
    );
  }
}

