import React from 'react';
import { ASCE_7_16_YELLOW, NEAR_OBSTRUCTION_COLOR, ASCE_7_16_SALMON, ASCE_7_16_GREEN, ASCE_7_16_BROWN, ASCE_7_16_VIOLET, ASCE_7_16_ORANGE, SKEW_AFFECTED_COLOR } from '__editor/panelsEditor/models/utils/panelColor';
import { 
  renderLegendOptionName, 
  renderLegendHeader, 
  renderLegendSubHeader, 
  renderZoneInLegend,
  renderNonExposedZoneinLegend,
  renderZoneInLegendASCE710,
} from './legends';

export const renderASCE716CommercialLegned = () => {
  return (
    <div className="roof-zones-legend">
      {renderLegendHeader('Panels: ')}
      {renderLegendSubHeader('Exposed / Non-exposed')}
      <div className="legend">
        {renderZoneInLegend(ASCE_7_16_SALMON)}
        {renderLegendOptionName('South Leading Edge')}
      </div>
      <div className="legend">
        {renderZoneInLegend(ASCE_7_16_GREEN)}
        {renderLegendOptionName('North Leading Edge')}
      </div>
      <div className="legend">
        {renderZoneInLegend(ASCE_7_16_YELLOW)}
        {renderLegendOptionName('Field')}
      </div>
      <div className="legend">
        {renderZoneInLegend(ASCE_7_16_BROWN)}
        {renderLegendOptionName('South Corner')}
      </div>
      <div className="legend">
        {renderZoneInLegend(ASCE_7_16_VIOLET)}
        {renderLegendOptionName('North Corner')}
      </div>
      <div className="legend">
        {renderZoneInLegend(ASCE_7_16_ORANGE)}
        {renderLegendOptionName('East & West Edges')}
      </div>
      <div className="legend">
        {renderZoneInLegend(NEAR_OBSTRUCTION_COLOR)}
        {renderLegendOptionName('Near obstruction')}
      </div>
      <div className="legend">
        {renderZoneInLegend(SKEW_AFFECTED_COLOR)}
        {renderLegendOptionName('Skew Affected')}
      </div>
    </div>
  );
};
export const renderASCE710CommercialLegnedPerimeterZone = () => {
  return (
    <div className="roof-zones-legend">
      {renderLegendHeader('Panels: ')}
      <div className="legend">
        {renderZoneInLegendASCE710(ASCE_7_16_SALMON)}
        {renderLegendOptionName('South Leading Edge')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(ASCE_7_16_GREEN)}
        {renderLegendOptionName('North Leading Edge')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(ASCE_7_16_YELLOW)}
        {renderLegendOptionName('Field')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(ASCE_7_16_BROWN)}
        {renderLegendOptionName('South Corner')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(ASCE_7_16_VIOLET)}
        {renderLegendOptionName('North Corner')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(ASCE_7_16_ORANGE)}
        {renderLegendOptionName('East & West Edges')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(NEAR_OBSTRUCTION_COLOR)}
        {renderLegendOptionName('Near obstruction')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE710(SKEW_AFFECTED_COLOR)}
        {renderLegendOptionName('Skew Affected')}
      </div>
    </div>
  );
};


export const renderRMIFILegend = () => {
  return (
    <div className="roof-zones-legend">
      {renderLegendHeader('Panels: ')}
      <div className="legend">
        {renderNonExposedZoneinLegend(NEAR_OBSTRUCTION_COLOR)}
        {renderLegendOptionName('Near obstruction')}
      </div>
      <div className="legend">
        {renderZoneInLegend(SKEW_AFFECTED_COLOR)}
        {renderLegendOptionName('Skew Affected')}
      </div>
    </div>
  );
};
