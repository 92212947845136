import { dispatch, state } from '__common/store';
import { resetKdTree } from '../cursor/utils/kdTreeStore';
import { terminateKeyboard } from '../../keyboardConfg';
import {
  CLEAR_TILTED_ROOF,
  RESET_CURSOR_STATE,
  RESET_PANELS_STATE,
  RESET_RUBBERBAND_STATE,
  BG_CLEAR_LOADED,
  CLEAR_BACKGROUND_REDUCER,
  } from 'actions';
import { resetPanelCenterTree } from '../panels/utils/panelsCenterStore';

export function resetPanelEditorState() {
  const { projectConfiguration: { productId, projectEnvConfig: { building_code, building_type } } } = state();
  
  dispatch(RESET_PANELS_STATE());
  dispatch(RESET_CURSOR_STATE());
  dispatch(RESET_RUBBERBAND_STATE());
  dispatch(CLEAR_TILTED_ROOF(productId, building_code, building_type));
  dispatch(CLEAR_BACKGROUND_REDUCER());
  dispatch(BG_CLEAR_LOADED());
  terminateKeyboard();
  resetKdTree();
  resetPanelCenterTree();
}
