import Loader from '__common/components/LoaderComponent';
import React from 'react';
import { BOM_GENERATE_START, ENGINEERING_GENERATE_START, LAYOUT_GENERATE_START, INTERNAL_ENGINEERING_GENERATE_START, DETAILED_ENGINEERING_GENERATE_START, ENGINEERING_SUMMARY_GENERATE_START, PE_STAMP_GENERATE_START } from './engineeringProjectDocumentsActions';
import { connect } from 'react-redux';
import {
  getBomReportUrl,
  getEngineeringReportUrl,
  getLayoutReportUrl,
  createLink,
  getInternalEngineeringReportUrl,
  getDetailedEngineeringReportUrl,
  getEngineeringSummaryReportUrl,
  getPeStampReportUrl,
} from 'engineering/components/engineeringProjectDocuments/utils/reportsApi';

type Props = {
  dispatch: Function,
  type: 'bom' | 'engineering' | 'engineering_summary' | 'layout' | 'internal engineering' | 'detailed_engineering' | 'pe_stamp',
  projectId: string,
  isBomGenerated: boolean,
  isEngineeringGenerated: boolean,
  isEngineeringSummaryGenerated: boolean,
  isLayoutGenerated: boolean,
  isInternalEngineeringGenerated: boolean,
  isDetailedEngineeringGenerated: boolean,
  unitsType: unitsType,
  isPeStampGenerated: boolean
};

class EngineeringProjectDocuments extends React.Component<Props> {
  componentDidMount() {
    this.generatePdf();
  }

  generatePdf = () => {
    const { dispatch, type, projectId, unitsType } = this.props;
    switch (type) {
      case 'bom':
        // commenting: Start generation of other reports as well, once on the engineering tab to save some client waiting.
        // dispatch(LAYOUT_GENERATE_START(projectId, unitsType));
        // dispatch(ENGINEERING_SUMMARY_GENERATE_START(projectId, unitsType));
        // dispatch(DETAILED_ENGINEERING_GENERATE_START(projectId, unitsType));
        // dispatch(ENGINEERING_GENERATE_START(projectId, unitsType));
        // dispatch(BOM_GENERATE_START(projectId, unitsType));
        return dispatch(BOM_GENERATE_START(projectId, unitsType));
      case 'engineering':
        return dispatch(ENGINEERING_GENERATE_START(projectId, unitsType));
      case 'engineering_summary':
        return dispatch(ENGINEERING_SUMMARY_GENERATE_START(projectId, unitsType));
      case 'layout':
        return dispatch(LAYOUT_GENERATE_START(projectId, unitsType));
      case 'internal engineering':
        return dispatch(INTERNAL_ENGINEERING_GENERATE_START(projectId, unitsType));
      case 'detailed_engineering':
        return dispatch(DETAILED_ENGINEERING_GENERATE_START(projectId, unitsType));
      case 'pe_stamp':
        return dispatch(PE_STAMP_GENERATE_START(projectId, unitsType));
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.type !== this.props.type || this.hasBeenRegenerated(prevProps)) {
      this.generatePdf();
    }
  }

  hasBeenRegenerated(prevProps: Props) {
    const { type } = this.props;

    switch (type) {
      case 'bom':
        return prevProps.isBomGenerated && !this.props.isBomGenerated;
      case 'engineering':
        return prevProps.isEngineeringGenerated && !this.props.isEngineeringGenerated;
      case 'engineering_summary':
        return prevProps.isEngineeringSummaryGenerated && !this.props.isEngineeringSummaryGenerated;
      case 'layout':
        return prevProps.isLayoutGenerated && !this.props.isLayoutGenerated;
      case 'internal engineering':
        return prevProps.isInternalEngineeringGenerated && !this.props.isInternalEngineeringGenerated;
      case 'detailed_engineering':
        return prevProps.isDetailedEngineeringGenerated && !this.props.isDetailedEngineeringGenerated;
      case 'pe_stamp':
        return prevProps.isPeStampGenerated && !this.props.isPeStampGenerated;
    }
  }

  getLink(): string {
    const { type, projectId, unitsType } = this.props;

    switch (type) {
      case 'bom':
        return createLink(getBomReportUrl(projectId, unitsType));
      case 'engineering':
        return createLink(getEngineeringReportUrl(projectId, unitsType));
      case 'engineering_summary':
        return createLink(getEngineeringSummaryReportUrl(projectId, unitsType));
      case 'layout':
        return createLink(getLayoutReportUrl(projectId, unitsType));
      case 'internal engineering':
        return createLink(getInternalEngineeringReportUrl(projectId, unitsType));
      case 'detailed_engineering':
        return createLink(getDetailedEngineeringReportUrl(projectId, unitsType));
      case 'pe_stamp':
      return createLink(getPeStampReportUrl(projectId, unitsType));
    }
  }

  isGenerated = () => {
    const { type } = this.props;

    switch (type) {
      case 'bom':
        return this.props.isBomGenerated;
      case 'engineering':
        return this.props.isEngineeringGenerated;
      case 'engineering_summary':
        return this.props.isEngineeringSummaryGenerated;
      case 'layout':
        return this.props.isLayoutGenerated;
      case 'internal engineering':
        return this.props.isInternalEngineeringGenerated;
      case 'detailed_engineering':
        return this.props.isDetailedEngineeringGenerated;
      case 'pe_stamp':
        return this.props.isPeStampGenerated;
    }
  }

  renderLoader() {
    return <Loader />;
  }

  renderDocument() {
    const { type } = this.props;

    return <embed id="pdfs-content" key={type} width="100%" height="100%" src={this.getLink()} />;
  }

  render() {
    if (this.isGenerated()) {
      return this.renderDocument();
    }

    return this.renderLoader();
  }
}

function mapStateToProps(appState: appState) {
  return {
    isBomGenerated: appState.engineeringProjectDocuments.bom,
    isEngineeringGenerated: appState.engineeringProjectDocuments.engineering,
    isLayoutGenerated: appState.engineeringProjectDocuments.layout,
    isInternalEngineeringGenerated: appState.engineeringProjectDocuments.internalEngineering,
    isDetailedEngineeringGenerated: appState.engineeringProjectDocuments.detailedEngineering,
    isEngineeringSummaryGenerated: appState.engineeringProjectDocuments.engineeringSummary,
    unitsType: appState.engineeringProjectDocuments.units,
    isPeStampGenerated: appState.engineeringProjectDocuments.peStamp,
  };
}

export default connect(mapStateToProps)(EngineeringProjectDocuments);
