import { isCursorInForbidenArea, isCursorInBlueZone, isCursorSnapping } from '../cursor';
import { state, dispatch } from '__common/store';
import { SET_BLACK_CURSOR, SET_BLUE_CURSOR, SET_GREEN_CURSOR } from 'actions';

export const asce705and710BlankColors = (
  cursor: PIXI.Graphics, 
  cursorColide: boolean, 
  mapType: string, 
  productId: string, 
  isInYellowWindZone: boolean, 
  isInRedWindZone: boolean,
) => {
  const { settings: { canvasCenter, panelWidth, panelHeight }, editorCursor: { colour }, background: { selectedRoofId, moveArrayMode }, panels: {panelsToBeMoved} } = state();
  const x = cursor.x - canvasCenter.x;
  const y = cursor.y - canvasCenter.y;

  if (isCursorInForbidenArea(cursor, cursorColide, colour, x, y, panelWidth, panelHeight, selectedRoofId, mapType)) {
    return dispatch(SET_BLACK_CURSOR());
  }

  if ((isCursorInBlueZone(cursorColide, isInYellowWindZone, isInRedWindZone, colour, productId)) || (moveArrayMode && panelsToBeMoved.length > 0)) {
    return dispatch(SET_BLUE_CURSOR());
  }

  if (isCursorSnapping(cursorColide, false, colour, productId, 4)) {
    return dispatch(SET_GREEN_CURSOR());
  }
};
