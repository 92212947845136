export const getErrorMessageIfInvalidFactory = (state: appState) => 
  (fieldName: string) => {
    const field = state.projectConfiguration.formState.invalidFields.find(f => f.fieldName === fieldName);

    if (field) {
      return field.errorMessage;
    }

    return null;
  };
