import * as PIXI from 'pixi.js';
import { state } from '__common/store';
import { getRoofEdgesPointsInPx } from '../components/background/background';
import Polygon from 'polygon';
import { degreesToRadians } from 'maths';
import { getScaleFromPitchRoof } from '../components/tiltedRoof/tiltedRoof';

function fogOfWar(width: number, height: number, resizedY: number) {
  const fogOfWar = createFogOfWarGraphicObject(width, height);
  const holePoint = getRotatedPolyPixelPoints();
  createHoleInPolygon(fogOfWar, holePoint);
  rescaleFogOfWarIfRoofPitch(fogOfWar, resizedY);

  return fogOfWar;
}

function createFogOfWarGraphicObject(width: number, height: number) {
  const startX = 0;
  const startY = 0;

  const fogOfWar = new PIXI.Graphics()
    .beginFill(0x000000)
    .moveTo(startX, startY)
    .lineTo(width, startY)
    .lineTo(width, height)
    .lineTo(startX, height);

  fogOfWar.id = 'fogOfWar';
  fogOfWar.zIndex = 9999;
  fogOfWar.alpha = 0.70;

  return fogOfWar;
}

function rescaleFogOfWarIfRoofPitch(fogOfWar: PIXI.Graphics, resizedY: number) {
  const {
    background: { bgXY },
    tiltedRoof: { roofPitch },
    projectConfiguration: {productId}
  } = state();
  const scale = getScaleFromPitchRoof(roofPitch, productId);
  fogOfWar.y -= (bgXY.y - resizedY) * scale || 0;
  fogOfWar.scale.y = scale;
}

function createHoleInPolygon(fogOfWar: PIXI.Graphics, holePoint: {x: number, y: number}[]) {
  holePoint.map((point, index) => {
    const x = point.x;
    const y = point.y;
    if (index === 0) {
      fogOfWar.moveTo(x, y);
    } else {
      fogOfWar.lineTo(x, y);
    }
  });
  
  fogOfWar.closePath().addHole();
}

export function getRotatedPolyPixelPoints() {
  const {
    background: { roofEdges, cords, zoom, rotationDegrees },
    settings: { canvasCenter },
  } = state();

  const cordsInPx = getRoofEdgesPointsInPx(roofEdges, cords, zoom);
  const movedCordsInPx = cordsInPx.map((point) => {
    return {
      x: point.x + canvasCenter.x,
      y: point.y + canvasCenter.y,
    };
  });
  const polygon = new Polygon(movedCordsInPx);
  polygon.scale(2);
  polygon.rotate(degreesToRadians(rotationDegrees));

  return polygon.points;
}

export default fogOfWar;
