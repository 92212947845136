import keyMirror from 'keymirror';

export const UserPreferencesActionTypes = keyMirror({
  SET_USER_PRODUCT: undefined,
  SET_USER_ZIPCODE: undefined,
  SET_USER_PROJECTS_PER_PAGE: undefined,
  SET_USER_PROJECTS_SORTING: undefined,
  SET_USER_SORT_METHOD: undefined,
  SET_USER_HELIOSCOPE: undefined,
  SET_USER_HELIOSCOPE_LOGIN: undefined,
  SET_USER_HELIOSCOPE_PASSWORD: undefined,
  SET_USER_SPANS: undefined,
  SEND_NEW_USER_PREFERENCES: undefined,
  UPDATE_USER_PREFERENCES: undefined,
  SET_STAGGER_ATTACHMENTS: undefined,
  CLEAR_USER_PREFERENCES: undefined,
  USER_PREFERENCES_SAVE_ERROR: undefined,
  SET_AREA: undefined,
  SET_USER_RAFTER_SPACING: undefined,
});

