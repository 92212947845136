export default () => `
  <div class="sub-header">
    <strong>Low:</strong>
  </div>
  <div class="content">
    Buildings that represent a low direct or indirect hazard to human life in the event of failure, including:
    low human-occupancy buildings, where it can be shown that collapse is not likely to cause injury or
    other serious consequences, minor storage buildings.
  </div>
  <div class="sub-header">
    <strong>Normal:</strong>
  </div>
  <div class="content">
    All buildings except those listed in Importance Categories Low, High and Post-disaster.
  </div>
  <div class="sub-header">
    <strong>High:</strong>
  </div>
  <div class="content">
    Buildings that are likely to be used as post-disaster shelters, including buildings whose primary use is as an elementary, middle or secondary school, as a community center. Manufacturing and storage facilities containing toxic, explosive or other hazardous substances in sufficient
    quantities to be dangerous to the public if released.
  </div>
  <div class="sub-header">
    <strong>Post-Disaster:</strong>
  </div>
  <div class="content">
    Post-disaster buildings are buildings that are essential to the provision of services in the event of a disaster.
  </div>
`;











