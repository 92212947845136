import { state } from '__common/store';
import { isSMFamily, isSF, isSFMFamily, isRMFamily } from '__common/constants/products';
import { showErrorAlert } from '__common/modules/alerts';

const limitation = {
  Sm: 6000,
  SFM: 1000,
  RMFamily: 15000,
};

export function showOverMaxAllowedPanelsAlert(overNumber: number, limit: number) {
  const {  } = state();
  
  const message = `Please generate your calculations in smaller segments through multiple U-builder projects.`;
  
  showErrorAlert(message, 10);
}

export function validateMaxAllowedPanels(newPanels: number) {
  const { drawingManager: { roofs }, background: { selectedRoofId }, projectConfiguration: { productId }, panels: { panels } } = state();
  const limit = getProductLimitation(productId);
  const roofsIds = Object.keys(roofs).map(id => Number(id));
  const roofsIdsWithoutCurrentRoof = roofsIds.filter(id => id !== selectedRoofId);
  const panelsNumber = roofsIdsWithoutCurrentRoof.map(roofId => roofs[roofId].panels ? roofs[roofId].panels.length : 0).reduce((a, b) => (a + b), 0);
  const currentPanelsNumber = panels ? panels.length : 0;
  const total = newPanels + panelsNumber + currentPanelsNumber;
  const isOver = total > limit;
  const overNumber = total - limit;

  if (isOver) {
    showOverMaxAllowedPanelsAlert(overNumber, limit);
  }

  return isOver;
}

function getProductLimitation(productId: number) {
  if (isSMFamily(productId) || isSF(productId)) {
    return limitation.Sm;
  }

  if (isSFMFamily(productId)) {
    return limitation.SFM;
  }

  if (isRMFamily(productId)) {
    return limitation.RMFamily;
  }
}
