interface range {
  minX: number; 
  minY: number;
  maxX: number;
  maxY: number;
}

export function getPanelsRange(panels: panelInState[]): range {
  let minX: number;
  let minY: number;
  let maxX: number;
  let maxY: number;

  panels.forEach((panel) => {
    // if panels is landscape then the width is bigger than height. We don't have to check for it explicitly.
    const panelTopLeftX = panel.x - (panel.width / 2);
    const panelBottomRightX = panel.x + (panel.width / 2);
    const panelTopLeftY = panel.y - (panel.height / 2);
    const panelBottomRightY = panel.y + (panel.height / 2);

    if (minX === undefined || panelTopLeftX < minX) {
      minX = panelTopLeftX;
    }

    if (minY === undefined || panelTopLeftY < minY) {
      minY = panelTopLeftY;
    }

    if (maxX === undefined || panelBottomRightX > maxX) {
      maxX = panelBottomRightX;
    }

    if (maxY === undefined || panelBottomRightY > maxY) {
      maxY = panelBottomRightY;
    }
  });

  return {
    minX,
    maxX,
    minY,
    maxY,
  };
}

export function arePanelsWithinRoofEdges(roofEdges: pixelPoint[], panels: panelInState[], offsetPx = 0): boolean {
  let minX: number;
  let minY: number;
  let maxX: number;
  let maxY: number;

  roofEdges.forEach((edgeCorner) => {
    if (minX === undefined || edgeCorner.x < minX) {
      minX = edgeCorner.x;
    }

    if (minY === undefined || edgeCorner.y < minY) {
      minY = edgeCorner.y;
    }

    if (maxX === undefined || edgeCorner.x > maxX) {
      maxX = edgeCorner.x;
    }

    if (maxY === undefined || edgeCorner.y > maxY) {
      maxY = edgeCorner.y;
    }
  });

  return panels.every((panel) => {
    return (
      (panel.x - panel.width / 2 - offsetPx) >= minX && 
      (panel.x + panel.width / 2 + offsetPx) <= maxX &&
      (panel.y - panel.height / 2 - offsetPx) >= minY &&
      (panel.y + panel.height / 2 + offsetPx) <= maxY 
    );
  });
}
