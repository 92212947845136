import React from 'react';
import { COMBINE_PROJECT } from 'actions';

type Props = {
  dispatch: Function,
  selectedProjects: {[projectId: string]: boolean},
};

export default class YourProjectsCombineSelected extends React.Component<Props> {
  getProjectsIds = () => {
    const { selectedProjects } = this.props;
    return Object.keys(selectedProjects || []).map(projectId => {
      if (selectedProjects[projectId]) {
        return projectId;
      }
    }).filter(projectId => projectId);
  }

  combineProject = () => {
    const { dispatch } = this.props;

    const projects = this.getProjectsIds();

    if (projects.length > 1) {
      dispatch(COMBINE_PROJECT(projects));
    }
  }

  content = () => {
    return (
      <div>
        <a onClick={this.combineProject} className={this.getProjectsIds().length > 1 ? '' : 'not-available'}>
          <i className="material-icons">merge_type</i>
          <div className="text">
            COMBINE SELECTED
        </div>
        </a>
      </div>
    );
  }

  render() {
    return (
      <>
          <tbody className="your-projects-combine-selected">
            <tr>
              <td>
              {this.content()}
            </td>
          </tr>
        </tbody>
      </>
    );
  }
}
