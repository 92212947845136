export const ROOF_PITCH: { [key: string]: number } = {
  ['1/12']: 5,
  ['2/12']: 9,
  ['3/12']: 14,
  ['4/12']: 18,
  ['5/12']: 23,
  ['6/12']: 27,
  ['7/12']: 30,
  ['8/12']: 34,
  ['9/12']: 37,
  ['10/12']: 40,
  ['11/12']: 43,
  ['12/12']: 45,
};
