import * as React from 'react';
import Drawer from '__common/components/Drawer/Drawer';
import rm5RmDtConfig from 'projectDesign/components/projectConfiguration/configurations/Rm5RmDtConfig';
import sfConfig from 'projectDesign/components/projectConfiguration/configurations/SfConfig';
import SfmEnhancedConfig from './configurations/SfmEnhancedConfig';
import metalXConfig from './configurations/MetalXConfig';
import smConfig from 'projectDesign/components/projectConfiguration/configurations/SmConfig';
import smTiltConfig from 'projectDesign/components/projectConfiguration/configurations/SmTiltConfig';
import ascenderConfig from 'projectDesign/components/projectConfiguration/configurations/AscenderConfig';
import gftConfig from 'projectDesign/components/projectConfiguration/configurations/GftConfig';
import ulaConfig from 'projectDesign/components/projectConfiguration/configurations/UlaConfig';
import { connect } from 'react-redux';
import { getProjectDesignPages } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import { isRMFamily, isSFMFamily, products, isGFT, isAdditionalUserInputEnabledProduct, isSolarMount } from '__common/constants/products';
import {
  missingLocationAndLoadsPageValid,
  missingProductConfigurationPageValid,
  invalidRailArrangementType,
} from 'projectDesign/components/projectConfiguration/utils/validation';
import { SelectRadios } from './utils/updateProjectOption';
import { helioscopePage } from './components/helioscopePage';
import { ModuleSelectorPage } from './components/moduleSelectorPage';
import { locationAndLoadsPage } from './components/locationAndLoadsPage';
import { checkModelData } from '__common/components/Drawer/utils/checkModelData';
import { ProductConfigurationPage } from './components/productConfigurationPage';
import { roofsSelectorState } from '__editor/components/roofsSelector/roofsSelectorReducer';
import { getErrorMessageIfInvalidFactory } from 'projectDesign/components/projectConfiguration/components/getErrorMessageForField';
import ecoFoot2PlusConfig from './configurations/ecoFoot2PlusConfig';

import NxtHorizonConfig from './configurations/NxtHorizonConfig';
import { getWarningMessageFactory } from './components/getWarningMessageForField';
import { additionalUserInputsPage } from './components/additionalUserInputsPage';
import SMTiltPRConfig from './configurations/SmTiltPRConfig'
import { UBUILDER_SOFTWARE_GROUP } from 'user/userGroups';
import { COPY_PROJECT } from 'app/appActions';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL } from '../confirmClearArraysModal/confirmClearArraysModalActions';
import { auroraPage } from './components/auroraPage';

interface Props {
  centerFields?: boolean;
  initData: any;
  onChange: Function;
  productId: string;
  isHelioscope?: boolean;
  isAurora?:boolean
  projectConfiguration: projectConfigurationState;
  getErrorMessageIfInvalid: (fieldName: string) => string | null;
  getWarningMessage: (fieldName: string) => string | null;
  roofsSelector: roofsSelectorState;
  dispatch: Function;
  userPreferences: userPreferencesState;
  moduleSelector: moduleSelectorState;
  user: userState;
  onSave?: Function;
  location: string;
  isProjectError: boolean;
}

class ProjectConfigurationComponent extends React.Component<Props> {
  componentDidMount() {
    SelectRadios();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.productId !== prevProps.productId) {
      this.render();
      SelectRadios();
    }
  }

  getPageConfig = (productId: number) => {
    switch (productId) {
      case products.solarmount_2:
      case products.solarmount_lt:
      case products.solarmount_hd:
      case products.solarmount_ascender_flush:
        return smConfig;
      case products.solarmount_2_tilt:
      case products.nxt_tilt:
      // case products.adjustable_tilt_system:
        return smTiltConfig;
      case products.sunframe:
        return sfConfig;
      case products.rm:
      case products.rm_5:
      case products.rm_dt:
      case products.rm_10_ultra:
      case products.rm_gridflex:
      case products.rm_10_evolution:
      case products.rm_gridflex_10:
        return rm5RmDtConfig;
      case products.gft:
        return gftConfig;
      case products.ula:
        return ulaConfig;
      case products.metal_x:
        return metalXConfig;
      case products.ecofoot2_plus:
        return ecoFoot2PlusConfig;
      case products.ascender:
        return ascenderConfig;
      case products.nxt_umount:
      case products.nxt_horizon_lt:
      case products.nxt_horizon_hd:
        return NxtHorizonConfig;
      case products.sm_tilt_pr:
      case products.sm_tilt_pr_lt:
        return SMTiltPRConfig;
    }

    if (isSFMFamily(productId)) {
      return SfmEnhancedConfig;
    }
  }

  setInactiveDrawer = (mapTypeSelected: boolean) => !mapTypeSelected;

  setCustomSaveButtonText = (isHelioscope: boolean, isAurora: boolean) => (isHelioscope || isAurora) ? 'SAVE AND IMPORT' : '';

  helioscopePageConfig = (
    currentConfig,
    productId: string,
    initData: projectEnvConfig,
    mapType: string,
    location: string,
    onChange: Function) => {
    const {
      content,
      tooltip,
    } = helioscopePage(
      currentConfig,
      productId,
      initData,
      mapType,
      location,
      onChange,
      this.props.getErrorMessageIfInvalid,
    );
    const keepOpen = true;

    return {
      tooltip,
      content,
      keepOpen,
    };
  }

  auroraPageConfig = (
    currentConfig,
    productId: string,
    initData: projectEnvConfig,
    mapType: string,
    location: string,
    onChange: Function) => {
    const {
      content,
      tooltip,
    } = auroraPage(
      currentConfig,
      productId,
      initData,
      mapType,
      location,
      onChange,
      this.props.getErrorMessageIfInvalid,
    );
    const keepOpen = true;

    return {
      tooltip,
      content,
      keepOpen,
    };
  }

  moduleSelectorPageConfig = (productId: number, selectedMfgId: number) => {
    const { content, tooltip } = ModuleSelectorPage(productId);
    const keepOpen = !selectedMfgId || !checkModelData();
    const active = true;

    return {
      tooltip,
      content,
      active,
      keepOpen,
    };
  }

  locationAndLoadsPageConfig = (
    currentConfig,
    productId: number,
    initData,
    mapType: string,
    location: string,
    envFactorsLoading: boolean,
    area: string,
  ) => {
    const {
      tooltip,
      content,
    } = locationAndLoadsPage(
      currentConfig,
      productId,
      initData,
      mapType,
      location,
      envFactorsLoading,
      area,
      this.props.getErrorMessageIfInvalid,
      this.props.getWarningMessage,
    );


    if (isRMFamily(products[productId])) {
      const invalid = !missingLocationAndLoadsPageValid(productId);

      return {
        tooltip,
        content,
        invalid,
      };
    }

    return {
      tooltip,
      content,
    };
  }

  additionalUserInputsPageConfig = (
    currentConfig,
    productId: number,
    initData,
    mapType: string,
    location: string,
    envFactorsLoading: boolean,
    area: string,
    inputUnit: number,
  ) => {
    const {
      content,
    } = additionalUserInputsPage(
      currentConfig,
      productId,
      initData,
      mapType,
      location,
      envFactorsLoading,
      area,
      this.props.getErrorMessageIfInvalid,
      inputUnit
    );

    return {
      content,
    };
  }

  rackingPageConfig = (currentConfig, productId: number, projectVersion: string, initData, mapType: string, location: string) => {
    const {
      tooltip,
      content,
      postRender,
    } = ProductConfigurationPage(
      currentConfig,
      productId,
      initData,
      mapType,
      location,
      projectVersion,
      this.props.getErrorMessageIfInvalid,
      this.props.getWarningMessage,
    );

    if (isRMFamily(products[productId])) {
      const invalid = !missingProductConfigurationPageValid(productId);

      return {
        tooltip,
        content,
        invalid,
        postRender,
      };
    }

    if (isGFT(products[productId])) {
      const invalid = invalidRailArrangementType();
      return {
        tooltip,
        content,
        invalid,
        postRender,
      };
    }

    return {
      tooltip,
      content,
      postRender,
    };
  }

  showToggleUnits = () => isSolarMount(products[this.props.productId]) && this.props.user.permissions?.indexOf(UBUILDER_SOFTWARE_GROUP.solarmount_ascender_flush_permission) > -1;

  getPages = (currentConfig: Function) => {
    const {
      initData,
      isHelioscope,
      isAurora,
      moduleSelector: { selectedMfgId },
      productId,
      roofsSelector: { mapType },
      location,
      projectConfiguration: {projectVersion, envFactorsLoading, area, projectId, projectConfigurated, projectEnvConfig:{aurora_project_id, is_aurora_project}, productId:product_id },
      user,
      onChange
    } = this.props;
    
    const shouldShowAdditionalUserInputs = (isAdditionalUserInputEnabledProduct(products[productId])) && (projectConfigurated ? user.isStaff : user.isStaff);
    const shouldAllowCopyProj = user?.isStaff && !!projectId && !is_aurora_project;

    const PAGES = getProjectDesignPages(isHelioscope, isAurora,  this.showToggleUnits(), shouldShowAdditionalUserInputs, shouldAllowCopyProj, this.copyProject);

    if (isHelioscope) {
      PAGES[0] = { ...PAGES[0], ...this.helioscopePageConfig(currentConfig, productId, initData, mapType, location, onChange) };

      return PAGES;
    }

    if (isAurora && product_id) {
      PAGES[0] = { ...PAGES[0], ...this.auroraPageConfig(currentConfig, productId, initData, mapType, location, onChange) };

      return PAGES;
    }
    
    let idx = 0;
    PAGES[idx] = { ...PAGES[idx++], ...this.moduleSelectorPageConfig(products[productId], selectedMfgId) };

    PAGES[idx] = { ...PAGES[idx++], ...this.locationAndLoadsPageConfig(currentConfig, products[productId], initData, mapType, location, envFactorsLoading, area) };

    PAGES[idx] = { ...PAGES[idx++], ...this.rackingPageConfig(currentConfig, products[productId], projectVersion,initData, mapType, location) };

    if (shouldShowAdditionalUserInputs) {
      PAGES[idx] = { ...PAGES[idx++], ...this.additionalUserInputsPageConfig(currentConfig, products[productId], initData, mapType, location, envFactorsLoading, area, this.props.projectConfiguration.inputUnit) };
    }
    
    return PAGES;
  }

  copyProject = () => {
    const { projectConfiguration: { projectId, projectEnvConfig:{ helioscope_id } }, dispatch } = this.props;
    if(helioscope_id){
      dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          `Confirmation for copy project`,
          `Are you sure that you have gone through the panel editor?`,
          () => {
            dispatch(COPY_PROJECT(projectId));
          },
          null,
          null,
          null,
          'YES',
          'NO',
          {dialogStyle: { opacity: 1 }},
          true
        ),
      );
    }
    else{
      dispatch(COPY_PROJECT(projectId));
    }
  }

  render() {
    const {
      productId,
      roofsSelector: { mapTypeSelected },
      isHelioscope,
      isAurora,
      onSave,
      projectConfiguration
    } = this.props;

    const drawerInactive = this.setInactiveDrawer(mapTypeSelected);
    const saveButtonTitle = this.setCustomSaveButtonText(isHelioscope, isAurora);
    const pageConfig = this.getPageConfig(isAurora && projectConfiguration.productId? projectConfiguration.productId : products[productId]);

    const pages = this.getPages(pageConfig);

    return (
      <Drawer
        productId={products[productId]}
        inactive={drawerInactive}
        onSave={onSave}
        saveButton={true}
        saveButtonLabel={saveButtonTitle}
        pages={pages}
        keepOpen={isHelioscope || isAurora}
        mainClass="project-description-component"
        shouldConfigureFirst={true}
      />
    );
  }
}

function mapStateToProp(state: appState) {
  return {
    moduleSelector: state.moduleSelector,
    projectConfiguration: state.projectConfiguration,
    roofsSelector: state.roofsSelector,
    location: state.roofsSelectorMap.location,
    userPreferences: state.userPreferences,
    user: state.user,
    getErrorMessageIfInvalid: getErrorMessageIfInvalidFactory(state),
    getWarningMessage: getWarningMessageFactory(state),
    isProjectError: state.app.isProjectError,
  };
}

export default connect(mapStateToProp)(ProjectConfigurationComponent);
