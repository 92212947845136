// https://en.wikipedia.org/wiki/United_States_customary_units
export const CUSTOMARY_UNIT = 'customary';
export const METRIC_UNIT = 'metric';

export const isCustomaryUnit = (unit: number) =>  unit === InputUnit.CUSTOMARY;

export const isMetricUnit = (unit: number) =>  unit === InputUnit.METRIC;

export enum InputUnit {
        CUSTOMARY = 1,
        METRIC = 2,
}

export const toggleInputUnit = (input: number) => input && input === InputUnit.METRIC ? InputUnit.CUSTOMARY : InputUnit.METRIC
