export function getRandomInterestingPos(): { lat: number, lng: number, name: string } {
  const location = locations[Math.floor(Math.random() * locations.length)];
  return randomGeo(location);
}

const distanceLimit = 1; // in meters

function randomGeo(center: { latitude: number, longitude: number, name: string }): { lat: number, lng: number, name: string } {
  const y0 = center.latitude;
  const x0 = center.longitude;
  const rd = distanceLimit / 111300; // about 111300 meters in one degree

  const u = Math.random();
  const v = Math.random();

  const w = rd * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  const newlat = y + y0;
  const newlon = x + x0;

  return {
    lat: Number(newlat.toFixed(5)),
    lng: Number(newlon.toFixed(5)),
    name: center.name,
  };
}

const locations = [
    { name: 'Statue of Liberty, NY, USA', latitude: 40.689249, longitude: -74.0445 },
    { name: 'Central Park, NY, USA', latitude: 40.781273, longitude: -73.966613 },
    { name: 'Casino Royale & Hotel, NV, USA', latitude: 36.116375, longitude: -115.169731 },
    { name: 'Empire State Building, NY, USA', latitude: 40.748441, longitude: -73.985664 },
    { name: 'Alcatraz, SF, USA', latitude: 37.826273, longitude:-122.422601 },
    { name: 'Pentagon, VA, USA', latitude: 38.870911, longitude: -77.055934 },
    { name: 'Miami Beach, FL, USA', latitude: 25.790256, longitude: -80.127267 },
    { name: 'Times Square, NY, USA', latitude: 40.75796382847943, longitude: -73.98595937642368 },
];
