import { AdditionalLoadCombinationField, apiField } from './types/additionalLoadCombination';

const config : AdditionalLoadCombinationField = ({
    label: 'additionalLoadCombination',
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data: [
      {
        value: 0.6,
        name: '0.6D + 0.6W',
      },
      {
        value: 1.0,
        name: 'None',
      },
    ],
  });


export default config;
