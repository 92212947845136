import { state } from '__common/store';
import { getPixiLayer } from '../../../../googleMapsRoofsSelector/components/stage/stage';
import { clearGoogleAdvanceRoofSelecting } from '../../../../googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelecting';
import {getBingLatLngAdvanceRoofSelectingCoords, clearBingAdvanceRoofSelecting } from '../../../../bingMapsRoofsSelector/components/bingMapsAdvanceRoofSelecting/bingMapsAdvanceRoofSelecting';
import Polygon from 'polygon';
import { showErrorAlert } from '__common/modules/alerts';
import {lat_lng_distance_to_feet} from '__editor/panelsEditor/components/background/utils/backgroundCordinatesConverter';

export const NEW_ROOF_INTERSECTION_ERROR_MSG = 'Shape may not be closed if boundary lines are crossing. Please correct your roof boundary.';


export function advanceRoofSelectingAlert(overlapingWarning?: string) {
  showErrorAlert(overlapingWarning || 'Roof cannot overlap another roof');
}

export function clearAdvanceRoofSelecting() {
  const { roofsSelector: { mapType } } = state();

  if (mapType === 'google') {
    const pixiLayer = getPixiLayer();
    if (pixiLayer) {
      clearGoogleAdvanceRoofSelecting(pixiLayer);
    }
  } 
  clearBingAdvanceRoofSelecting(); 
}

export function checkSelfIntersection(coords: {x: number, y: number}[]) {
  const poly = new Polygon([...coords]);
  return poly.selfIntersections().length;
}

export function newRoofIntersectionWarning(warning?: string) {
  showErrorAlert(warning || NEW_ROOF_INTERSECTION_ERROR_MSG);
}


export function checkCppConditions(coords: {x: number, y: number}[]) : boolean {
  const pixiLayer = getPixiLayer();
  const { roofsSelector: { mapType } } = state();
  let latlongs = undefined;
  if (mapType === 'google') {
    latlongs =  coords.map(coord => pixiLayer.fromPixelToLatLng(coord).toJSON());
  }
  else {
    latlongs = getBingLatLngAdvanceRoofSelectingCoords(coords);
  }
  return !cppThresholdDistanceCheck(latlongs) && coords.length % 2 == 0
}

export function checkCppConditionsLatLong(latlongs: {lat: number, lng: number}[]) : boolean {
  return !cppThresholdDistanceCheck(latlongs) && latlongs.length % 2 == 0
}

const isDistanceLessThanTwo = dist => dist < 2;

function cppThresholdDistanceCheck(latlongs: {lat: number, lng: number}[]
  ) : boolean {
  for(let i=0;i<latlongs.length;i++) {
      if(isDistanceLessThanTwo(lat_lng_distance_to_feet(latlongs[i],latlongs[(i+1)%latlongs.length]))) {
        return true;
      }
  }
  return false;
}

export function getLatLongFromPixiCoords(coords: {x: number, y: number}){
  const pixiLayer = getPixiLayer();
  const { roofsSelector: { mapType } } = state();
  let latlong;
  if (mapType === 'google') {
    latlong =  pixiLayer.fromPixelToLatLng(coords).toJSON();
  }
  else {
    latlong = getBingLatLngAdvanceRoofSelectingCoords([coords])[0]
  }
  return latlong;
}

