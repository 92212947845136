import { getStage } from '../stage/stage';
import { getRenderer } from '../renderer/renderer';
import { getObstructionContainer } from './obstructions';
import { state } from '__common/store';
import { obstructionModel } from '__editor/panelsEditor/models/obstruction';
import { ColorReplaceFilter } from 'pixi-filters';

export const drawObstructionsOnStage = () => {
  const stage = getStage();
  const renderer = getRenderer();
  const obstructionContainer = getObstructionContainer();
  const { obstructions: { obstructions, editingObstructionId }, background: { selectedRoofId } } = state();

  if (stage && renderer) {

    if (obstructionContainer) {
      obstructionContainer.children = [];
    }
    
    if (obstructions && obstructions[selectedRoofId] && Object.keys(obstructions[selectedRoofId]).length) {
      Object.keys(obstructions[selectedRoofId]).map(obstructionId => {
        const { coords } = obstructions[selectedRoofId][obstructionId];
        const obstruction = obstructionModel(coords, Number(obstructionId));
        obstructionContainer.addChild(obstruction);
      });
    }
  }

  stopHighlightObstructions();

  if (editingObstructionId) {
    highlightObstruction(editingObstructionId);
  }
  
  renderer.render(stage);
};

export const highlightObstruction = (obstructionId: number) => {
  const obstruction = findObstructionGraphicObject(obstructionId);

  if (obstruction) {
    obstruction.filters = [new ColorReplaceFilter(
      0xFF0000,
      0xFFFF00,
      1,
      )];
  }
};

export const stopHighlightObstructions = () => {
  const obstructionContainer = getObstructionContainer();

  if (obstructionContainer && obstructionContainer.children && obstructionContainer.children.length) {
    obstructionContainer.children.map(obstruction => {
      obstruction.filters = [];
    });
  }
};

const findObstructionGraphicObject = (obstructionId: number) => {
  const obstructionContainer = getObstructionContainer();

  if (obstructionContainer && obstructionContainer.children && obstructionContainer.children.length) {
    return obstructionContainer.children.find((obstruction: PIXI.Graphics) => obstruction.id === obstructionId);
  }
};
