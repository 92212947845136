import React from 'react';
import ReactTooltip from 'react-tooltip';
import { SelectionControl, SelectionControlProps } from 'react-md';
import uniqueId from 'lodash/uniqueId';

interface props extends SelectionControlProps {
  labelText: string;
}

export default (props: props) => {
  const id = uniqueId('tooltipedSelectionControl');
  const { labelText, label, ...otherProps } = props;
  const labelWithButton = (
    <>
      {props.label} {renderTooltipButton(id, props.labelText)}
    </>
  );
  return (
    <>
      <SelectionControl {...otherProps} label={labelWithButton}  />
      {renderTooltip(id)}
    </>
  );
};

const renderTooltip = (id: string) => {
  return (
    <ReactTooltip 
      className="selection-control-tooltip" 
      effect="solid" 
      place="right"
      type="info" 
      id={id} 
    />
  );
};

const renderTooltipButton = (id: string, text: string) => {
  return (
    <a 
      data-for={id} 
      data-tip={text} 
      data-html={true} 
      className="tooltip-info-button"
    >
      <img src={require('assets/media/icons/questionmark.png')} />
    </a>
  );
};
