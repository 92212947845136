import * as PIXI from 'pixi.js';
import store from '__common/store';
import { getTexture } from './utils/textureManager';

function shadowPanelModel({ x, y, id }) {
  const { settings: { panelWidth, panelHeight, canvasCenter } } = store.getState();
  const shadowPanel = new PIXI.Sprite(getTexture('panel', panelWidth, panelHeight, 5));
  shadowPanel.x = x + canvasCenter.x;
  shadowPanel.y = y + canvasCenter.y;
  shadowPanel.interactiveChildren = false;
  shadowPanel.zIndex = 9998;
  shadowPanel.anchor.set(0.5, 0.5);
  shadowPanel.width = panelWidth;
  shadowPanel.height = panelHeight;
  return shadowPanel;
}

export default shadowPanelModel;

