import React from 'react';
import Select from 'react-select';
import autobind from 'autobind-decorator';

type Props = {
  options: {
    value: string | number,
    label: string | number,
  }[],
  valArray: string[] | number[],
  onChange: (value: any) => void,
};

export default class MultiSelect extends React.PureComponent<Props> {

  @autobind
  handleChange(valueObjArray: any) {
    const { onChange } = this.props;
    const val = valueObjArray.map(valObj => {return (valObj.value)});
    return onChange(val);
  }

  renderSelectField = () => {
    const { options, valArray } = this.props;
    const values = options.filter(({
      value
    }) => valArray.includes(value));
    return (
        <Select
        isMulti
        value={values}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={this.handleChange}
      />
    );
  }

  render() {
    return (
      <div className="field">
        {this.renderSelectField()}
      </div>
    );
  }
}