import * as React from 'react';
import autobind from 'autobind-decorator';
import Icon from '__common/components/Icon';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import { selectLagBolts, unSelectLagBolts } from '../../utils/partsSelectionFunctions';

type Props = {
  dispatch: Function,
  lagBolts: [bomPart, bomPart],
  attachmentGroup: string,
};

export default class LagBoltsPart extends React.Component<Props, {}> {
  @autobind
  toggleLagBolts(checked: boolean) {
    const { lagBolts } = this.props;
    if (checked) {
      selectLagBolts(lagBolts);
    } else {
      unSelectLagBolts(lagBolts);
    }
  }

  render() {
    const { lagBolts } = this.props;
    return (
      <div className="lagBolts">
        <div className="control-panel-sublabel">
            Lag bolts
        </div>
        <div>
          <SelectionControl
            id={`lagBolts_${lagBolts[0].number}`}
            name="lagBolts_checkboxes[]"
            label="I require Lag Bolts"
            type="checkbox"
            value={lagBolts[0].number}
            checked={lagBolts[0].selected || false}
            onChange={this.toggleLagBolts}
            disabled={lagBolts[0].disabled}
            checkedCheckboxIcon={(<Icon>check</Icon>)}
            uncheckedCheckboxIcon={(<Icon>check</Icon>)}
          />
        </div>
      </div>
    );
  }
}

