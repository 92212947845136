import { autoSaveProject } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/saveProject';
import { BackgroundActionTypes } from '__editor/panelsEditor/components/background/backgroundActionsConstants';
import { DrawingManagerActionTypes } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerActionsConstants';
import { getProductName } from '__common/constants/products';
import { shouldAutoSave } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/autosave';
import { ProjectConfigurationActionTypes } from 'projectDesign/components/projectConfiguration/projectConfigurationActionsConstants';
import { state } from '__common/store';


export const saveProjectMiddleware = store => next => action => {
  next(action);
  
  const { projectConfiguration: { productId, projectId } } = state();

  if (
    shouldSaveOnActions(action.type) && shouldAutoSave()
  ) {
    autoSaveProject(getProductName(productId) , projectId);
  }
};

function shouldSaveOnActions(actionType) {
  const actions = [
    ProjectConfigurationActionTypes.SET_PROJECT_OPTION,
    ProjectConfigurationActionTypes.SET_PRODUCT_TITLE,
    ProjectConfigurationActionTypes.SET_ZIP_AND_ADDRESS_CODE,
    ProjectConfigurationActionTypes.SET_ENVIRONMENTAL_FACTORS,
    ProjectConfigurationActionTypes.SET_PROJECT_NAME,
    ProjectConfigurationActionTypes.SET_RAILS_PRODUCT_ID,
    ProjectConfigurationActionTypes.SET_SPAN,
    DrawingManagerActionTypes.DELETE_ROOF,
    DrawingManagerActionTypes.ADD_ROOF,
    DrawingManagerActionTypes.SET_ARRAY_NAME,
    BackgroundActionTypes.DEACTIVE_PANELS_EDITOR,
  ];

  return actions.includes(actionType);
}
