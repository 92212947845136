export const apiField = 'deflectors';

export const options = {
  noDeflectors: {
    value: 0,
    name: 'No deflectors',
  }, 
  allDeflectors: {
    value: 1,
    name: 'All deflectors',
  },  
  edgeDeflectors: {
    value: 2,
    name: 'Edge deflectors',
  },
};

const config = {
  type: 'Select',
  apiField,
  label: 'deflectors',
  data: [
    options.noDeflectors,
    options.allDeflectors,
    options.edgeDeflectors,
  ],
};

export default config;
