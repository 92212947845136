import * as React from 'react';
import { connect } from 'react-redux';
import TileReplacementAttachmentTypes from './tileReplacementAttachmentType';

class TileReplacementSelector extends React.Component<{dispatch: Function, bom: bomState, productId: string},{}> {
  renderTileReplacement() {
    const { bom: { tileReplacementList }, dispatch } = this.props;

    return (
      <div className="attachments_selector">
        {
          Object.keys(tileReplacementList).includes('tiles') ? (<div className="control-panel-label bom-sidebar-main-header roof-attachments-header">
          Select tile replacement
         </div>) : null 
        }
        { Object.keys(tileReplacementList).map(type => {
          if (type === 'trim' || type === 'slider'){
            return null;
          }
          const groupTypes = Object.keys(tileReplacementList[type]);
          const selectedGroupItem = groupTypes.find(groupItem => tileReplacementList[type][groupItem].selected);
          if (groupTypes?.length) {
            return <TileReplacementAttachmentTypes dispatch={dispatch} group={type} groupItemTypes={groupTypes} selectedGroupItem={selectedGroupItem? selectedGroupItem: ''}/>;
          }
          return null;
        })}             
      </div>
    );
  }

  render() {
    return this.renderTileReplacement();
  }
}

function mapStateToProps(state) {
  return {
    bom: state.bom,
  };
}

export default connect(mapStateToProps)(TileReplacementSelector);
