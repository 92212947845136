import { asce716WindSpeedMetric as windSpeed } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { onlyAsce716BuildingCode as buildingCode } from 'projectDesign/components/projectConfiguration/fields/buildingCode';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import {elevationMetric} from 'projectDesign/components/projectConfiguration/fields/elevation';
import {ascenderRailFinish as railFinish} from 'projectDesign/components/projectConfiguration/fields/railFinish';
import {ascenderRoofType} from 'projectDesign/components/projectConfiguration/fields/roofType';
import {snowLoadMetric} from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/ascenderWindExposure';
import { checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { checkBuildingHeight, checkLongestBuildingLength, checkParapetHeightNumeric,  checkSeismic, checkSeismicS1, checkShortestBuildingLength, } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { getASCE716722LocationsResidentalAdditionalInputs } from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { ascenderMidAndEndClamps } from 'projectDesign/components/projectConfiguration/fields/smClamps';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import seismicS1 from 'projectDesign/components/projectConfiguration/fields/seismicS1';
import getSoilClassField from 'projectDesign/components/projectConfiguration/fields/soilClass';
import { state } from '__common/store';
import parapetHeightNumeric from '../fields/parapetHeightNumeric';
import {longestBuildingLengthMetric as longestBuildingLength} from '../fields/longestBuildingLength';
import {buildingHeightMetric as buildingHeight} from '../fields/buildingHeight';
import { shortestBuildingLengthMetric  as shortestBuildingLength } from '../fields/shortestBuildingLength';
import railLengthForThermalGap from '../fields/railLengthForThermalGap';
import allowThermalGap from '../fields/allowThermalGap';

export default (buildingCodeValue: number) => {
  
    const {
      projectConfiguration: {
        projectEnvConfig: {
          seismic_ss, building_code, seismic_s1, allow_thermal_gap, shortest_building_length, building_length, building_height, parapet_height_num,
        },
        productId,
      },
    } = state();

    const isThermalGapAllowed = (allow_thermal_gap) => {
      return allow_thermal_gap ===  1;
    };

    const snowLoad = snowLoadMetric;

  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, null, elevationMetric);

  let soilClass = getSoilClassField(building_code, productId);
  soilClass['label']='site_class';
  seismicSs.missing = !checkSeismic(seismic_ss);
  seismicS1.missing = !checkSeismicS1(seismic_s1);
  buildingHeight.missing = !checkBuildingHeight(building_height * 1);
  shortestBuildingLength.missing = !checkShortestBuildingLength(shortest_building_length * 1);
  longestBuildingLength.missing = !checkLongestBuildingLength(building_length * 1);
  parapetHeightNumeric.missing = !checkParapetHeightNumeric(parapet_height_num * 1);
  
  const cfg = {
    
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCode,
        WindExposure,
        windSpeed,
        snowLoad,
        elevationMetric,
        seismicSs,
        soilClass,
        seismicS1,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        ascenderMidAndEndClamps('mid_clamps_choices'),
        ascenderMidAndEndClamps('end_clamps_choices'),
        ascenderRoofType,
        longestBuildingLength,
        parapetHeightNumeric,
        shortestBuildingLength,
      ],
    },
    Spans: {
      label: '',
      fields: [
        allowThermalGap,
        ...(isThermalGapAllowed(allow_thermal_gap) ? [railLengthForThermalGap] : []),
      ],
    },
    railFinish,
    railTypes: true,
  };

  return cfg;
};

