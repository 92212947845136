import { state } from '__common/store';

export function obstructionModel(coords: { x: number, y: number}[], obstructionId: number): any {
  const { settings: { canvasCenter } } = state();
  const pixiPoints = coords.map(coord => new PIXI.Point(coord.x + canvasCenter.x, coord.y + canvasCenter.y));
  const obstruction = new PIXI.Graphics();
  obstruction.id = obstructionId;
  obstruction.beginFill(0x000000, 0.80);
  obstruction.drawPolygon(pixiPoints);
  obstruction.endFill();
  return obstruction;
}
