import clone from 'lodash/clone';
import { sortBomTableByUniracRequest } from 'bom/components/bomTable/utils/bomTableSorting';
import { state } from '__common/store';
import { isSfmFamilyName, isULA, isMetalX, products, isNxtHorizon, isSolarMount } from '__common/constants/products';

const MID_PRO_CLAMPS_MILL_PART_NUMBER = '302030M';
const MID_PRO_CLAMPS_DARK_PART_NUMBER = '302030D';
const END_PRO_CLAMPS_MILL_PART_NUMBER = '302035M';

export function calculatePrices(partsList) {
  return partsList.reduce((total, part) => {
    if (part.disabled) {
      return total;
    }
    if (part.category === 'Accessory') {
      total.accessoriesPrice += part.price * part.quantity;
    }

    if (!part.category || part.category === 'Base') {
      total.baseSystemPrice += part.price * part.quantity;
    }
    return total;
  },                      {
    baseSystemPrice: 0,
    accessoriesPrice: 0,
  });
}

export function addSelectedFlashingToPartsList(selectedAttachmentType: {flashings: bomPartsList, standoffs?: bomPartsList, lag_bolts: bomPartsList }, partsList: bomPart[], extraParameters: extraParameters): bomPart[] {
  if (selectedAttachmentType.flashings && selectedAttachmentType.flashings.length) {
    const selectedFlashings = selectedAttachmentType.flashings.filter(flashing => flashing.selected)[0];
    if (selectedFlashings.number && selectedFlashings.number.length) {
      const { productId, total_feet,  is_sm_tilt} = extraParameters;
      if (((isSolarMount(productId) && !is_sm_tilt) || isNxtHorizon(productId)) && total_feet) {
        selectedFlashings['quantity'] = total_feet;
        selectedFlashings['suggested_quantity'] = total_feet;
      }
      partsList.push(selectedFlashings);
    }
  }

  return partsList;
}

export function addSelectedStandoffsToPartsList(
  selectedAttachmentType: { flashings: bomPartsList, standoffs?: bomPartsList, lag_bolts: bomPartsList }, partsList: bomPartsList, extraParameters: extraParameters): bomPart[] {
  if (selectedAttachmentType.standoffs && selectedAttachmentType.standoffs.length) {
    const selectedStandoff = selectedAttachmentType.standoffs.filter(standoff => standoff.selected)[0];
    if (selectedStandoff.number && selectedStandoff.number.length) {
      const { productId, total_feet,  is_sm_tilt} = extraParameters;
      if (((isSolarMount(productId) && !is_sm_tilt)|| isNxtHorizon(productId)) && total_feet) {
        selectedStandoff['quantity'] = total_feet;
        selectedStandoff['suggested_quantity'] = total_feet;
      }
      partsList.push(selectedStandoff);
    }
  }
  return partsList;
}

export function enableAllParts(partsList) {
  return partsList.map((part) => {
    part.disabled = false;
    return part;
  });
}

export function selectAttachment(attachment, attachmentNumber) {
  const selected = attachment.number.includes(attachmentNumber.slice(0, -1));
  return {
    ...attachment,
    selected,
  };
}



export function parseAttachmentsList(productId: string, roofAttachments) {
  if (roofAttachments && roofAttachments.roof_attachments && Object.keys(roofAttachments.roof_attachments).length) {
    if (isSfmFamilyName(productId)) {
      return parseSFMAttachmentsList(roofAttachments);
    }
    if (isMetalX(products[productId])){
      return roofAttachments.roof_attachments;
    }
    return parseNonSFMAttachmentsList(roofAttachments);
  }
}

export function preparePartListToSave(partList): partsListForApi[] {
  return partList.map((part) => {
    delete part.variants;
    delete part.description;
    delete part.disabled;
    delete part.full_description;
    delete part.image;
    delete part.type;
    return part;
  });
}

export function getSFMAttachmentsPartsNumbers(roofAttachments: SFMattachmentsFromApi) {
  const numbers = Object.keys(roofAttachments).map((type) => {
    return roofAttachments[type].parts.map((part) => {
      return part.number;
    });
  });
  const flatten = list => list.reduce(
    (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [],
  );
  return flatten(numbers);
}

export function addSFMAttachmentsToPartsList(roofAttachments: sfmAttachmentsPart[], partsList: bomPartsList) {
  return [...roofAttachments, ...partsList];
}

function parseSFMAttachmentsList(roofAttachments: SFMattachmentsFromApi) {
  const attachments = Object.keys(roofAttachments).reduce((acc, type) => {
    const parts = roofAttachments[type].parts.map((part) => {
      part.subType = 'SFM Attachements';
      return part;
    });
    roofAttachments[type].parts = parts;
    acc[type] = roofAttachments[type];
    return acc;
  },                                                      {});
  return attachments;
}

function parseNonSFMAttachmentsList(roofAttachments: nonSFMattachmentsFromApi) {
  if (roofAttachments === null) {
    return [];
  }
  const attachments = roofAttachments.roof_attachments;
  
  if (attachments['Flashkit Pro']) {
    attachments['Flashkit Pro'] = { ...attachments['Flashkit Pro'], lag_bolts: roofAttachments.lag_bolts };
    delete attachments['Flashkit Pro'].flashings;
  }

  if (attachments['FLASHLOC COMP KIT']) {
    attachments['FLASHLOC COMP KIT'] = { ...attachments['FLASHLOC COMP KIT'], lag_bolts: roofAttachments.lag_bolts };
    delete attachments['FLASHLOC COMP KIT'].flashings;
  }

  if (attachments['L Foot Only']) {
    attachments['L Foot Only'] = { ...attachments['L Foot Only'], lag_bolts: roofAttachments.lag_bolts };

    if (attachments['L Foot Only'].flashings[0]) {
      attachments['L Foot Only'].flashings[0].number = '';
      attachments['L Foot Only'].flashings[0].type = 'Flashing';
    }

  }

  if (attachments['STRONGHOLD ATT COMP - NON SEALANT']) {
    attachments['STRONGHOLD ATT COMP - NON SEALANT'] = { ...attachments['STRONGHOLD ATT COMP - NON SEALANT']};
    delete attachments['STRONGHOLD ATT COMP - NON SEALANT'].flashings;
  }

  if (attachments['STRONGHOLD ATT KIT COMP']) {
    attachments['STRONGHOLD ATT KIT COMP'] = { ...attachments['STRONGHOLD ATT KIT COMP']};
    delete attachments['STRONGHOLD ATT KIT COMP'].flashings;
  }
 
  return attachments;
}

export function parseDataForBomSave(partsList: bomPartsList, railsList: railsList, extraParameters: extraParameters, productId: number, selectOnlySMHDRails: boolean): bomSaveRequestBody {
  const partsListForApi = preparePartListToSave(sortBomTableByUniracRequest(productId, partsList).map(clone));
  const selectedRails = railsList
                          .map(rail => rail.selected ? rail.length : 0); 
  const selections = getBomSelections();
  let newPartListForApi;

  if(isULA(productId)){
    extraParameters.select_only_smhd_rails = selectOnlySMHDRails;
    extraParameters.clamps_changed = 0;
  }

  extraParameters.selections = selections;
  extraParameters.quantities = partsList.reduce((acc, part) => { 
    acc[`${part.number}`] = { 
      qty: part.quantity,
      suggestedQty: part.suggested_quantity,
    };
    return acc;
  },                                            {});

  if (selectedRails) {
    extraParameters.selections.selected_rails = railsList;
  }

  if(partsListForApi && partsListForApi.length>0) {
      newPartListForApi = partsListForApi.filter((elem, index, self) => self.findIndex(
        ele => ele.number === elem.number) === index);
    }
  return  {
    parts_list: newPartListForApi,
    rails_lengths: selectedRails,
    extra_parameters: extraParameters,
  };

}

export function areStandardClampsSelected(partsList: bomPartsList) {
  const { bom: { stdClampsFetching } } = state();
  return stdClampsFetching || partsList.filter(part => 
    (part.number === MID_PRO_CLAMPS_MILL_PART_NUMBER ||
    part.number === MID_PRO_CLAMPS_DARK_PART_NUMBER) ||
    part.number === END_PRO_CLAMPS_MILL_PART_NUMBER).length === 0;
}

export function stdClampsInSelections() {
  const { bom: { extraParameters: { selections } } } = state();
  return selections && selections.accessories && selections.accessories.length && selections.accessories.includes('STDCLAMPS');
}

export function getBomSelections() {
  const { projectConfiguration: { productId } ,bom: { accessoriesList, selectedAttachmentType, attachmentsList, tileReplacementList, extraParameters: { selections: oldSelections } } } = state();
  const selections: bomSelections =  { ...oldSelections };
  selections.accessories = accessoriesList.map(accessory => accessory.selected ? accessory.number : null).filter(accessory => accessory !== null);

  const isTileSelected = tileReplacementList && Object.keys(tileReplacementList).length;

  if(isMetalX(productId)){
    const isAttachmentsSelected = attachmentsList && Object.keys(attachmentsList).length;

    if(isAttachmentsSelected){
      selections.selected_tile_replacement = {};

      const sMetal = "standing seam metal attachments";
      const sNonMetal = "standing seam not verified";
      
      if(attachmentsList && (attachmentsList[sMetal] || attachmentsList[sNonMetal]) && (Object.keys(attachmentsList[sMetal]) || Object.keys(attachmentsList[sNonMetal]))){
        selections.selected_tile_replacement.tile = Object.keys(attachmentsList[sMetal]).find(tileType => attachmentsList[sMetal][tileType].selected);
        if(!selections.selected_tile_replacement.tile){
          selections.selected_tile_replacement.tile = Object.keys(attachmentsList[sNonMetal]).find(tileType => attachmentsList[sNonMetal][tileType].selected);
        }
      }

      if (attachmentsList && attachmentsList['Corrugated roof'] && Object.keys(attachmentsList['Corrugated roof'])) {
        selections.selected_tile_replacement.tile = Object.keys(attachmentsList['Corrugated roof']).find(tileType => attachmentsList['Corrugated roof'][tileType].selected);
      }
    }
  }

  if (isTileSelected) {
    selections.selected_tile_replacement = {};

    if (tileReplacementList?.tiles && Object.keys(tileReplacementList.tiles)) {
      selections.selected_tile_replacement.tile = Object.keys(tileReplacementList.tiles).find(tileType => tileReplacementList.tiles[tileType].selected);
    }

    if (tileReplacementList && tileReplacementList['sub flashing'] && Object.keys(tileReplacementList['sub flashing'])) {
      selections.selected_tile_replacement['sub flashing'] = Object.keys(tileReplacementList['sub flashing']).find(tileType => tileReplacementList['sub flashing'][tileType].selected);
    }

    if (tileReplacementList && tileReplacementList['tile flashing'] && Object.keys(tileReplacementList['tile flashing'])){
      selections.selected_tile_replacement['tile flashing'] = Object.keys(tileReplacementList['tile flashing']).find(tileType => tileReplacementList['tile flashing'][tileType].selected);
    }

  } else {

    if (selectedAttachmentType.parts && selectedAttachmentType.parts[0] && selectedAttachmentType.parts[0].number) {
      selections.attachmentType = Object.keys(attachmentsList).find(name => attachmentsList[name].selected);
    }

    if (selectedAttachmentType.flashings && selectedAttachmentType.flashings.length) {
      selections.flashings = selectedAttachmentType.flashings.find((flashing) => {
        return flashing.selected;
      }).number;
    }

    if (selectedAttachmentType.standoffs && selectedAttachmentType.standoffs.length) {
      selections.standoffs = selectedAttachmentType.standoffs.find(standoff => standoff.selected).number;
    }

    if (selectedAttachmentType.lag_bolts && selectedAttachmentType.lag_bolts.length) {
      const selectedBolts = selectedAttachmentType.lag_bolts.find(bolt => bolt.selected);
      if (selectedBolts) {
        selections.lag_bolts = selectedAttachmentType.lag_bolts.find(bolt => bolt.selected).number;
      }
    }

    if (selectedAttachmentType['tile hooks'] && selectedAttachmentType['tile hooks'].length) {
      const selectedHook = selectedAttachmentType['tile hooks'].find(hook => hook.selected);
      if (selectedHook) {
        selections['tile hooks'] = selectedAttachmentType['tile hooks'].find(hook => hook.selected).number;
      }
    }
  }
  
  return selections;
}
