import buildingCodeField, {onlyAsce716BuildingCode} from 'projectDesign/components/projectConfiguration/fields/buildingCode';
import rm10ParapetHeight from 'projectDesign/components/projectConfiguration/fields/rm10ParapetHeight';
import rm5ParapetHeight from 'projectDesign/components/projectConfiguration/fields/rm5ParapetHeight';
import rmDTParapetHeight from 'projectDesign/components/projectConfiguration/fields/rmDTparapetHeight';
import rmIFIParapetHeight from 'projectDesign/components/projectConfiguration/fields/rmIFIParapetHeight';
import ecoFoot2PlusParapetHeight from 'projectDesign/components/projectConfiguration/fields/parapetHeight';
import { state } from '__common/store';

import {
  isRMFamily,
  isSF,
  isSFMFamily,
  isMetalX,
  isSMFamily,
  isRM5,
  isRM10,
  isRMDT,
  isRMIFIProduct,
  isEcoFoot2Plus,
  isRM10Evolution,
  isNxtHorizon,
  isAscender,
  isSMTiltPR,
  isGroundProduct,
  isRMGridFlex,
  isSMAscenderFlush,
  isRmGridflex10,
} from '../../../constants/products';

export const getModuleSelectorTooltipContent = (productId: number) => {
  const { moduleSelector: { selectedMfgId, selectedModelId, mfgs, models } } = state();

  if (selectedMfgId === null || selectedModelId === null) {
    return 'Module <br /> Selection';
  }
  const mfgName = mfgs.find((mfg) => mfg.id === selectedMfgId);
  const modelName = models ? models.find((model) => model.id === selectedModelId) : { name: 'not defined' };

  if (mfgName && modelName) {
    return `${mfgName.name} <br /> ${modelName.name}`;
  }

  return 'Module <br /> Selection';
};

export const getLocationAndLoadsTooltipContent = () => {
  const { projectConfiguration: {area, projectEnvConfig: { zipcode, building_code }, productId, railsProductId}} = state();

  let code;
  if(isAscender(productId) || isSMAscenderFlush(railsProductId)) {
    code = onlyAsce716BuildingCode.data.find(code => code.value === building_code);
  }
  else {
    code = buildingCodeField.data.find(code => code.value === building_code);
  }
  
  if (code && code.name) {
    return `${area} ${zipcode} <br /> ${code.name}`;
  }
  return 'Location And Loads';
};


export const getBuildingRackingOptionsTooltipContent = (productId: number) => {
  if (isSMFamily(productId)) {
    return SMRackingTooltip();
  }

  if  (isNxtHorizon(productId)) {
    return NxtHorizonRackingTooltip();
  }
  if (isAscender(productId)) {
    return AscenderRackingTooltip();
  }
  
  if (isSF(productId)) {
    return SFRackingTooltip();
  }

  if (isSFMFamily(productId) || isMetalX(productId)) {
    return SFMAndMetalXRackingTooltip();
  }
  
  if (isRMFamily(productId) || isEcoFoot2Plus(productId)) {
    return RMAndEcoFootRackingTooltip(productId);
  }

  if (isGroundProduct(productId)){
    return GFTAndULARackingTooltip();
  }

  if (isSMTiltPR(productId)) {
    return SMTiltPRRackingTooltip();
  }
};

const SMRackingTooltip = () => {
  const { projectConfiguration: { projectEnvConfig: { preferred_span }, railsProductId } } = state();
  let SMType;

  if (railsProductId === 11) {
    SMType = 'SM Light';
  }else if (railsProductId === 30){
    SMType = 'SM Tilt PR';
  } else if (railsProductId === 9) {
    SMType = 'SM Standard';
  } else if (railsProductId === 2) {
    SMType = 'SM Heavy';
  }

  return `${SMType} <br /> Selected Span: ${preferred_span}"`;
};


const AscenderRackingTooltip = () => `Building and Racking Options`;

const SMTiltPRRackingTooltip = () => {
  const { projectConfiguration: { projectEnvConfig: { preferred_span }, railsProductId } } = state();
  let SMType;

  if (railsProductId === 30) {
    SMType = 'SM Tilt PR Standard';
  } else if (railsProductId === 31) {
    SMType = 'SM Tilt PR Light';
  }

  return `${SMType} <br /> Selected SPAN: ${preferred_span}"`;
};

const NxtHorizonRackingTooltip = () => {
  const { projectConfiguration: { projectEnvConfig: { preferred_span }, railsProductId } } = state();
  let SMType;

  if (railsProductId === 26) {
    SMType = 'NXT Light';
  } else if (railsProductId === 25) {
    SMType = 'NXT Standard';
  } else if (railsProductId === 27) {
    SMType = 'NXT Heavy';
  }

  return `${SMType} <br /> Selected SPAN: ${preferred_span}"`;
};

const SFMAndMetalXRackingTooltip = () => {
  const { projectConfiguration: { projectEnvConfig: { preferred_span, stagger_attachments } } } = state();
  const isStaggered = stagger_attachments ? 'Staggered' : 'Non Staggered';

  return `${isStaggered} <br /> Selected Span: ${preferred_span}"`;
};

const SFRackingTooltip = () => {
  const { projectConfiguration: { projectEnvConfig: { preferred_span } } } = state();

  return `Selected Span: ${preferred_span}"`;
};

const RMAndEcoFootRackingTooltip = (productId: number) => {
  const { projectConfiguration: { projectEnvConfig: { building_height, parapet_height, parapet_height_input }, inputUnit } } = state();
  const parapetHeight = getParapetHeightForProduct(inputUnit);
  let height: any = parapetHeight.data.find(field => field.value === parapet_height);

  height = (height && height.name) ? height.name : parapet_height_input;
  height = `${height} ${isRM10Evolution(productId) || isRMGridFlex(productId) ? ' in' : ''}`

  return `Building Height: ${building_height || 'not defined'} ft <br /> Parapet Height: ${height || 'not defined'}`;
};

const GFTAndULARackingTooltip = () => {
  return 'Racking Options';
}

function getParapetHeightForProduct(inputUnit) {
  const {
    projectConfiguration: { productId },
  } = state();

  if (isRM5(productId) ||isRmGridflex10(productId)) {
    return rm5ParapetHeight(inputUnit);
  }

  if (isRM10(productId) ) {
    return rm10ParapetHeight(inputUnit);
  }

  if (isRMDT(productId)) {
    return rmDTParapetHeight;
  }

  if (isRMIFIProduct(productId)) {
    return rmIFIParapetHeight();
  }

  if (isRM10Evolution(productId)) {
    return rmIFIParapetHeight(inputUnit);
  }
  if(isEcoFoot2Plus(productId)) {
    return ecoFoot2PlusParapetHeight(inputUnit);
  }

}
