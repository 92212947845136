import azimuth from 'projectDesign/components/projectConfiguration/fields/helioscope_azimuth.json';
import { ballastBlockWeightField } from 'projectDesign/components/projectConfiguration/fields/ballastBlockWeight';
import buildingLength, { longestBuildingLengthMetric } from 'projectDesign/components/projectConfiguration/fields/longestBuildingLength';
import shortestBuildingLength, { shortestBuildingLengthMetric } from 'projectDesign/components/projectConfiguration/fields/shortestBuildingLength';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import frameSizeUp from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_up.json';
import frameSizeWide from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_wide.json';
import oldBuildingCodeMeanRecurrenceInterval from 'projectDesign/components/projectConfiguration/fields/meanRecurrenceInterval';
import asce716meanRecurrenceInterval from 'projectDesign/components/projectConfiguration/fields/asce716meanRecurrenceInterval';
import orientation from 'projectDesign/components/projectConfiguration/fields/helioscope_orientation.json';
import racking from 'projectDesign/components/projectConfiguration/fields/helioscope_racking.json';
import { ecoFoot2PlusSetbackDistance, ecoFootSetbackDistanceMetric } from 'projectDesign/components/projectConfiguration/fields/setBackDistance';
import ecoFoot2PlusSurface from 'projectDesign/components/projectConfiguration/fields/surface_type';
import ecoFoot2PlusSlipSheet from 'projectDesign/components/projectConfiguration/fields/slipSheet';
import getSoilClassField from 'projectDesign/components/projectConfiguration/fields/soilClass';
import { buildingHeightMetric, rmDTbuildingHeight } from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import { centralSupportForEF2 } from 'projectDesign/components/projectConfiguration/fields/centralSupport';
import { ecoFoot2PlusRoofType } from 'projectDesign/components/projectConfiguration/fields/roofType';
import rmWindExposure from 'projectDesign/components/projectConfiguration/fields/rmWindExposure';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import seismicS1 from 'projectDesign/components/projectConfiguration/fields/seismicS1';
import setSpacing from 'projectDesign/components/projectConfiguration/fields/helioscope_setback.json';
import { getSnowLoadField } from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import windSpeed from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { state } from '__common/store';
import {
  isCanadianChangesProduct,
  isEcoFoot2Plus,
} from '__common/constants/products';
import {
  checkLongestBuildingLength,
  checkEcoFoot2PlusRoofType,
  checkWindExposure,
  checkSeismicSs,
  checkSeismicS1,
  checkEcoFoot2PlusSurface,
  checkEcoFoot2PlusSlipSheet,
  checkMaxDownPointLoad,
  checkParapetHeight,
  checkFieldNotEmpty,
  checkBuildingHeight,
  checkShortestBuildingLength,
  checkSeismicSds,
  checkSeismicSd1,
  checkTornadoSpeed,
} from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { isASCE716or722BuildingCode } from '../projectConfiguration';
import { getBuildingCode710716 } from '../utils/getBuildingCode';
import ecoFoot2PlusParapetHeight from '../fields/parapetHeight';
import limitDownPointLoads from '../fields/limitDownPointLoads';
import maxDownPointLoadToBeAllowed from '../fields/maxDownPointLoadToBeAllowed';
import { riskRMIToIIICategory } from '../fields/riskCategory';
import velocityPressureExposureCoefficientKz from '../fields/velocityPressureExposureCoefficientKz';
import groundElevationFactorKe from '../fields/groundElevationFactorKe';
import windDirectionalityFactorKd from '../fields/windDirectionalityFactorKd';
import rmTopographicalFactorKzt from '../fields/rmTopographicalFactorKzt';
import designLifeFactorFc from '../fields/designLifeFactorFc';
import numericalCoefficient from '../fields/numericalCoefficient';
import rmRoofExposure from '../fields/rmRoofExposure';
import { applyEcoFoot2PlusRM10andEvoSetbackChanges, applyRemoveMRI25ForEF } from '__common/utils/versionCompare/versionCompare';
import { isCanadianZipcode } from '../utils/validation';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import nbccCategory from '../fields/nbccCategory';
import nbccWindPressure from '../fields/nbccWindPressure';
import nbccRoughness from '../fields/nbccRoughness';
import rainLoad from '../fields/rainLoad';
import { buildingHeightCheck, riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import buildingStorey from '../fields/buildingStorey';
import { isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import { asce710716722BuildingCode } from '../fields/buildingCode';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import seismicSd1 from 'projectDesign/components/projectConfiguration/fields/seismicSd1';
import tornadoSpeed, { metricTornadoSpeedField } from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';

export default isHelioscope => {
  const {
    projectConfiguration: {
      projectEnvConfig: {
        wind_exposure,
        building_height,
        roof_type,
        parapet_height,
        building_length,
        seismic_ss,
        seismic_s1,
        seismic_sds,
        seismic_sd1,
        building_code,
        ecofoot_surface,
        slip_sheet,
        shortest_building_length,
        limit_down_point_loads,
        max_down_point_load_to_be_allowed,
        zipcode,
        rain_load,
        tornado_speed,
        risk_category,
        elevation: current_elevation,
        snow_load,
        wind_speed
      },
      inputUnit,
      productId,
      projectVersion,
    },
    user: { isStaff }
  } = state();

  let Helioscope = undefined;
  let parapetHeight;
  let meanRecurrenceInterval;
  let setBackDistance = [];

  if (isASCE716or722BuildingCode(building_code) || applyRemoveMRI25ForEF(projectVersion) || isCanadianZipcode(zipcode)) {
    meanRecurrenceInterval = asce716meanRecurrenceInterval;
  } else {
    meanRecurrenceInterval = oldBuildingCodeMeanRecurrenceInterval;
  }

  if (isEcoFoot2Plus(productId)) {
    parapetHeight = ecoFoot2PlusParapetHeight(inputUnit);
  }

  if (applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion)) {
    setBackDistance = isMetricUnit(inputUnit) ? [ecoFootSetbackDistanceMetric] : [ecoFoot2PlusSetbackDistance];
  }

  if (isHelioscope && isEcoFoot2Plus(productId)) {
    Helioscope = {
      fields: [
        azimuth,
        frameSizeUp,
        frameSizeWide,
        orientation,
        racking,
        setSpacing,
      ],
    };
  }

  if (isEcoFoot2Plus(productId)) {
    rmWindExposure.missing = !checkWindExposure(wind_exposure);
    rmDTbuildingHeight.missing = !checkBuildingHeight(building_height * 1);
    ecoFoot2PlusRoofType.missing = !checkEcoFoot2PlusRoofType(roof_type * 1);
    seismicS1.missing = !checkSeismicS1(seismic_s1);
    parapetHeight.missing = !checkParapetHeight(parapet_height * 1);
    buildingLength.missing = !checkLongestBuildingLength(building_length * 1);
    ecoFoot2PlusSurface.missing = !checkEcoFoot2PlusSurface(ecofoot_surface * 1);
    ecoFoot2PlusSlipSheet.missing = !checkEcoFoot2PlusSlipSheet(slip_sheet * 1);
    shortestBuildingLength.missing = !checkLongestBuildingLength(shortest_building_length * 1);
    maxDownPointLoadToBeAllowed.missing =  limitDownPointLoads && !checkMaxDownPointLoad(max_down_point_load_to_be_allowed)
  }

  if(isCanadianChangesProduct(productId)) {
    rainLoad.missing = !checkFieldNotEmpty(rain_load);
    buildingHeightMetric.missing = !checkBuildingHeight(building_height * 1);
    longestBuildingLengthMetric.missing = !checkLongestBuildingLength(building_length * 1);
    shortestBuildingLengthMetric.missing = !checkShortestBuildingLength(shortest_building_length * 1);
  }

  seismicSs.missing = !checkSeismicSs(seismic_ss);

  const buildingCode = asce710716722BuildingCode;
  const snowLoad  = getSnowLoadField(true, inputUnit);
  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, null, elevation);

  const soilClass = getSoilClassField(building_code);

  const tornadoSpeedField = isASCE722(building_code) && riskCategoryCheckForTornadoSpeed(risk_category) ? isMetricUnit(inputUnit) ? metricTornadoSpeedField : tornadoSpeed : [];
  const seismicSdsField = isASCE722(building_code) ? seismicSds : [];
  const seismicSd1Field = isASCE722(building_code) ? seismicSd1 : [];
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  seismicSd1.missing = !checkSeismicSd1(seismic_sd1);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);
  snowLoad.missing = !checkFieldNotEmpty(snow_load);
  elevation.missing = !checkFieldNotEmpty(current_elevation);
  riskRMIToIIICategory.missing = !checkFieldNotEmpty(risk_category);
  windSpeed.missing = !checkFieldNotEmpty(wind_speed);

  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Helioscope,
    HelioscopeRoofPitchSelector: true,
    Client: {
      label: '',
      fields: [clientName, clientAddress],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        ...([buildingCode]),
        ...(isCanadianZipcode(zipcode) ? [nbccRoughness, nbccWindPressure, rainLoad] : [rmWindExposure, windSpeed]),
        snowLoad,
        seismicSs,
        ...(applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) ? [seismicS1] : []),
        seismicSdsField,
        seismicSd1Field,
        elevation,
        ...(!isASCE716or722BuildingCode(building_code)?[meanRecurrenceInterval]:[]),
        tornadoSpeedField,
        ...(isCanadianZipcode(zipcode) ? [nbccCategory] : [riskRMIToIIICategory]),
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        ...(isMetricUnit(inputUnit) ? [buildingHeightMetric]  : [rmDTbuildingHeight]),
        ...(buildingHeightCheck(building_height, inputUnit) && isASCE716or722(building_code) ? [buildingStorey] : []),
        ecoFoot2PlusRoofType,
        parapetHeight,
        ...setBackDistance,
        ...(isMetricUnit(inputUnit) ? [longestBuildingLengthMetric, shortestBuildingLengthMetric] : [buildingLength, shortestBuildingLength]),
        ecoFoot2PlusSurface,
        ecoFoot2PlusSlipSheet,

      ],
    },
    BlockWeight: {
      label: '',
      fields: [
        ballastBlockWeightField(inputUnit),
        centralSupportForEF2,
      ],
    },
    DownPointLoad: {
      label: 'Down Point Load',
      fields: [
        limitDownPointLoads,
        ...(limit_down_point_loads ? [maxDownPointLoadToBeAllowed] : [])
      ]
    },
    WindPressureInputs: {
      label: 'additionalUserInputsPage',
      fields: [
        velocityPressureExposureCoefficientKz,
        groundElevationFactorKe,
        windDirectionalityFactorKd,
        rmTopographicalFactorKzt,
        designLifeFactorFc,
        numericalCoefficient
      ],
    },
    SnowLoadInputs: {
      label: 'additionalUserInputsPage',
      fields: [
        rmRoofExposure,
      ]
    },
    SeismicInputs:{
      label: 'additionalUserInputsPage',
      fields: [
        soilClass,
      ]
    },
  };

  return cfg;
};
