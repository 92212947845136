
export const apiField = 'psf_control';

export enum PsfControl {
  OVERALL_ROOF = 1,
  LIMIT_EACH_SUBARRAY = 2,
}

const IFI_PRODUCTS = [
  {
    value: PsfControl.OVERALL_ROOF,
    name: 'OVERALL ROOF',
  },
  {
    value: PsfControl.LIMIT_EACH_SUBARRAY,
    name: 'LIMIT EACH SUBARRAY',
  },
]
const RM10_PRODUCT = [

  {
    value: PsfControl.LIMIT_EACH_SUBARRAY,
    name: 'LIMIT EACH SUBARRAY',
  },
]

function makeConfig(data) {
  return {
    label: 'psfControl',
    type: 'Select',
    apiField,
    missing: undefined,
    data
  };
}

export const rmPsfControl = makeConfig(RM10_PRODUCT);


export default makeConfig(IFI_PRODUCTS);
