import en from '_translations/en-us.json';
import pl from '_translations/pl-pl.json';
import { IntlProvider } from 'react-intl';
import store from '__common/store';

export const translations = { en, pl };

export const getTranslation = (message) => {
  const { app: { locales: { locale, messages } } } = store.getState();

  const { intl } = new IntlProvider({ locale, messages }, {}).getChildContext();
  return intl.formatMessage({
    id: message,
  });
};
