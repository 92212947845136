export default () => 
  `<div class="content"> 
    Wind speed in case of ice condition 
  </div>
`;

export const note = () => 
`
  Ice values are not in Unirac's database, please enter correct values from <a target="_blank" href="https://asce7hazardtool.online/">ASCE 7</a>, your local AHJ, or contact Unirac for assistance with
  values for your area.
`;
