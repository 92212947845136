import { SET_PROJECT_CREATED_LOWER_RANGE, SET_PROJECT_CREATED_UPPER_RANGE } from "actions";
import React from "react";
import { dispatch } from "__common/store";
import { connect } from 'react-redux';
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from 'moment';
import autobind from "autobind-decorator";
import utils from "react-day-picker/moment";

type DateRangeFieldProps = {
  to: Date;
  from: Date;
  onFromChange: Function;
  onToChange: Function;
};

type State = {
  fromMonth: Date,
  toMonth: Date,
}

export default class DateRangeField extends React.Component<DateRangeFieldProps, State> {

  lowerLimitRef = null;
  upperLimitRef = null;
  constructor(props) {
    super(props);
    this.state = {
      fromMonth: props.from,
      toMonth: props.to,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    const { from, to } = this.props;
    const { from: prevFrom, to: prevTo } = prevProps;

    if (prevTo !== to) {
      this.showFromMonth();
      this.showFromMonth(); 
      this.showFromMonth();
      this.handleYearMonthChangeTo(to);
      this.handleYearMonthChangeTo(to);
      this.handleYearMonthChangeTo(to);
    }
    if (prevFrom !== from) {
      this.handleYearMonthChangeFrom(from);
      this.handleYearMonthChangeFrom(from);
      this.handleYearMonthChangeFrom(from);
    }
  }


  @autobind
  lowerLimitChange(val) {
    this.props.onFromChange(val);
  }

  @autobind
  upperLimitChange(val) {
    this.props.onToChange(val);
  }

  @autobind
  showFromMonth() {
    const { from, to } = this.props;
    if (!from || !to) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.upperLimitRef.getDayPicker().showMonth(from);
    }
  }

  @autobind
  handleYearMonthChangeTo(date) {
    this.setState({toMonth: date});
  }

  @autobind
  handleYearMonthChangeFrom(date) {
    this.setState({fromMonth: date});
  }

  @autobind
  yearMonthForm(date, localeUtils, onChange, fromMonth, toMonth) {
    const today = new Date();
    const months = localeUtils.getMonths();

    const years = [];
    for (let i = today.getFullYear() - 10; i <= today.getFullYear(); i += 1) {
      years.push(i);
    }

    const handleChange = function handleChange(e) {
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value));
    };

    return (
      <form className="DayPicker-Caption">
        <select name="month" onChange={handleChange} value={date.getMonth()}>
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select name="year" onChange={handleChange} value={date.getFullYear()}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }


  render() {
    const today = new Date();
    const { from, to } = this.props;
    const modifiers = { start: from, end: to };
    return (
      <div className="InputFromTo">
        <DayPickerInput
          value={from ?? ''}
          placeholder="From"
          format="LL"
          formatDate={utils.formatDate}
          parseDate={utils.parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to ? to : today },
            toMonth: today,
            modifiers,
            onDayClick: () => this.upperLimitRef.getInput().focus(),
            month: this.state.fromMonth??this.state.toMonth??today,
            captionElement: ({ date, localeUtils }) => this.yearMonthForm(date, localeUtils, this.handleYearMonthChangeFrom, from, today),
          }}
          onDayChange={this.lowerLimitChange}
        /><span className="text"> — </span>
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.upperLimitRef = el)}
            value={to ?? ''}
            placeholder="To"
            format="LL"
            formatDate={utils.formatDate}
            parseDate={utils.parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from, after: today },
              modifiers,
              month: this.state.toMonth??this.state.fromMonth??today,
              fromMonth: from,
              toMonth: today,
              captionElement: ({ date, localeUtils }) => this.yearMonthForm(date, localeUtils, this.handleYearMonthChangeTo, from, today),
            }}
            onDayChange={this.upperLimitChange}
          />
        </span>
      </div>
    )
  }
}
