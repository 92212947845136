import { CursorActionTypes } from 'actionsConstants';


export function MOVE_CURSOR(position: pixelPoint) {
  return {
    type: CursorActionTypes.MOVE_CURSOR,
    payload: {
      position,
    },
  };
}

export function SHOW_CURSOR() {
  return {
    type: CursorActionTypes.SHOW_CURSOR,
  };
}

export function HIDE_CURSOR() {
  return {
    type: CursorActionTypes.HIDE_CURSOR,
  };
}

export function SET_PORTRAIT_CURSOR() {
  return {
    type: CursorActionTypes.SET_PORTRAIT_CURSOR,
  };
}

export function SET_LANDSCAPE_CURSOR() {
  return {
    type: CursorActionTypes.SET_LANDSCAPE_CURSOR,
  };
}

export function FLIP_CURSOR() {
  return {
    type: CursorActionTypes.FLIP_CURSOR,
  };
}

export function LOCK_CURSOR() {
  return {
    type: CursorActionTypes.LOCK_CURSOR,
  };
}

export function UNLOCK_CURSOR() {
  return {
    type: CursorActionTypes.UNLOCK_CURSOR,
  };
}

export function SET_CURSOR_OVERLAP_PANEL() {
  return {
    type: CursorActionTypes.SET_CURSOR_OVERLAP_PANEL,
  };
}

export function UNSET_CURSOR_OVERLAP_PANEL() {
  return {
    type: CursorActionTypes.UNSET_CURSOR_OVERLAP_PANEL,
  };
}

export function SET_CURSOR_OVER_PANEL() {
  return {
    type: CursorActionTypes.SET_CURSOR_OVER_PANEL,
  };
}

export function UNSET_CURSOR_OVER_PANEL() {
  return {
    type: CursorActionTypes.UNSET_CURSOR_OVER_PANEL,
  };
}

export function RESET_CURSOR_STATE() {
  return {
    type: CursorActionTypes.RESET_CURSOR_STATE,
  };
}

export function CHANGE_SYSTEM_CURSOR(systemCursor: string) {
  return {
    type: CursorActionTypes.CHANGE_SYSTEM_CURSOR,
    payload: {
      systemCursor,
    },
  };
}
export function RESET_SYSTEM_CURSOR() {
  return {
    type: CursorActionTypes.RESET_SYSTEM_CURSOR,
  };
}

export function SET_BLACK_CURSOR() {
  return {
    type: CursorActionTypes.SET_BLACK_CURSOR,
  };
}

export function SET_YELLOW_CURSOR() {
  return {
    type: CursorActionTypes.SET_YELLOW_CURSOR,
  };
}

export function SET_ORANGE_CURSOR() {
  return {
    type: CursorActionTypes.SET_ORANGE_CURSOR,
  };
}

export function SET_GREEN_CURSOR() {
  return {
    type: CursorActionTypes.SET_GREEN_CURSOR,
  };
}

export function SET_BLUE_CURSOR() {
  return {
    type: CursorActionTypes.SET_BLUE_CURSOR,
  };
}

export function SET_ASCE_7_16_SALMON_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_SALMON_CURSOR,
  };
}

export function SET_ASCE_7_16_GREEN_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_GREEN_CURSOR,
  };
}

export function SET_ASCE_7_16_YELLOW_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_YELLOW_CURSOR,
  };
}

export function SET_ASCE_7_16_BLACK_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_BLACK_CURSOR,
  };
}

export function SET_ASCE_7_16_BROWN_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_BROWN_CURSOR,
  };
}

export function SET_ASCE_7_16_VIOLET_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_VIOLET_CURSOR,
  };
}

export function SET_ASCE_7_16_ORANGE_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_ORANGE_CURSOR,
  };
}

export function SET_ASCE_7_16_SNAPPING_CURSOR() {
  return {
    type: CursorActionTypes.SET_ASCE_7_16_SNAPPING_CURSOR,
  };
}

export function SET_OBSTRUCTION_ZONE_CURSOR() {
  return {
    type: CursorActionTypes.SET_OBSTRUCTION_ZONE_CURSOR,
  };
}
