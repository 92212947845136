import { getYesOrNo } from '../projectConfiguration';
export const apiField = 'allow_north_bays';

const config = {
  label: 'useSpecialNorthBays',
  type: 'Select',
  apiField,
  data: getYesOrNo(),
};

export default config;