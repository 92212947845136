import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { CHANGE_PANELS_SIZES, FLIP_CURSOR, MOVE_ARRAY_MODE_DISABLE, MOVE_MAP_MODE_DISABLE, RESTORE_PANELS_FOR_NO_MOVEMENT, SET_DRAW_RUBBER_BAND, SHOW_CURSOR, TOGGLE_ATTACHMENTS_DISABLE, UNBLOCK_CREATING_PANEL } from '__editor/panelsEditor/actions';
import { connect } from 'react-redux';
import { _isULA } from '../../cursor/utils/snapToGridHelper';
import { TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from 'actions';
import { dispatch } from '__common/store';
class ChangeOrientationButton extends React.Component< changeOrientationComponentState, {}> {

  @autobind
  changeRotation() {
    const { background: {moveArrayMode}, enableDraw } = this.props;
    if(moveArrayMode) enableDraw();
    dispatch(FLIP_CURSOR());
    dispatch(CHANGE_PANELS_SIZES());
  }

  renderIcon() {
    const { editorCursor: { landscape } } = this.props;
    if (landscape) {
      return <SVG className={'roofs-selector-control-panel-active'} src={require('assets/media/icons/icon_orientation2_hori.svg')} />;

    }

    return <SVG className={'roofs-selector-control-panel-active'} src={require('assets/media/icons/icon_orientation2_vert.svg')} />;
  }

  render() {
    return (
      <Button flat={true} id='orientationButton' onClick={this.changeRotation} className="roofs-selector-button module-orientation-button" tooltipLabel = 'Module orientation'  >
        {this.renderIcon()}
      </Button>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    enableDraw: () => {
      dispatch(UNBLOCK_CREATING_PANEL());
      dispatch(SET_DRAW_RUBBER_BAND());
      dispatch(SHOW_CURSOR());
      dispatch(MOVE_MAP_MODE_DISABLE());
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
      dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
      dispatch(TOGGLE_ATTACHMENTS_DISABLE());
      dispatch(TURN_OFF_MEASUREMENT_TOOL());
    },
  }
}


/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    editorCursor: state.editorCursor,
    projectConfiguration: state.projectConfiguration,
    moduleSelector: state.moduleSelector,
    background: state.background,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOrientationButton);
