import { createReducer } from '__common/utils/helpers';
import { SaveLoadProjectActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';
import { SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH_ACTION } from './saveLoadProjectActions';

export const saveLoadProjectState: saveLoadProjectState = {
  pk: null,
  isLoading: false,
  dataToLoad: {},
  lastProjectSave: 0,
  autoSaving: false,
  isSaveProjectPending: false,
};

export default {
  saveLoadProject: createReducer(saveLoadProjectState, {
    [SaveLoadProjectActionTypes.SAVE_PROJECT](state) {
      return { ...state, isLoading: true };
    },
    [SaveLoadProjectActionTypes.SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH](state: saveLoadProjectState, action: Action<SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH_ACTION>) {
      const { payload } = action;
      return { ...state, isSaveProjectPending: payload.isSaveProjectPending };
    },
    [SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT](state) {
      return { ...state, autoSaving: true };
    },
    [SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FINISHED](state) {
      return { ...state, autoSaving: false, lastProjectSave: new Date().getTime() };
    },
    [SaveLoadProjectActionTypes.SAVE_PROJECT_FAILED](state: saveLoadProjectState, action: action & { payload: { clamp_check_failed: boolean } }) {
      const { payload: { clamp_check_failed } } = action;
      if (clamp_check_failed) {
        return { ...state, isLoading: false };
      }
      return state;
    },
    [SaveLoadProjectActionTypes.AUTO_SAVE_PROJECT_FAILED](state: saveLoadProjectState) {
      return { ...state, autoSaving: false };
    },
    [SaveLoadProjectActionTypes.PREVENT_AUTO_SAVE_START](state) {
      return { ...state, autoSaving: true };
    },
    [SaveLoadProjectActionTypes.PREVENT_AUTO_SAVE_FINISHED](state) {
      return { ...state, autoSaving: false };
    },
    [SaveLoadProjectActionTypes.REDIRECT_TO_BOM](state: saveLoadProjectState, action: action & { payload: { data: { pk: null | number } } }) {
      const { data: { pk } } = action.payload;
      return { ...state, pk, isLoading: false };
    },
    [SaveLoadProjectActionTypes.CLEAR_PK](state) {
      return { ...state, pk: null, isLoading: false };
    },
    [SaveLoadProjectActionTypes.LOAD_DATA_FROM_API](state: saveLoadProjectState, action: { payload: { dataToLoad: any } }) {
      const { dataToLoad } = action.payload;
      return { ...state, dataToLoad, isLoading: false };
    },
    [SaveLoadProjectActionTypes.FETCH_PROJECT](state: saveLoadProjectState) {
      return { ...state, isLoading: true };
    },
    [SaveLoadProjectActionTypes.CLEAR_DATA_TO_LOAD](state: saveLoadProjectState) {
      return { ...state, dataToLoad: [], isLoading: false, lastProjectSave: 0, autoSaving: false };
    },
  }),
};
