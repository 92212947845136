import store from '__common/store';
import differenceBy from 'lodash/differenceBy';
import debounce from 'lodash/debounce';
import {
  drawRubberBand,
  removeRubberBand,
  getPanelsShadowContainer,
  drawShadowPanel,
} from '__editor/panelsEditor/components/rubberBand/rubberBand';
import { PanelsContainer } from '__editor/panelsEditor/models/panelsContainer';

const addShadowBoxes = debounce((stage, prevRubberBandState, panelsShadow) => {
  const panelsShadowContainer = getPanelsShadowContainer();

  const toRemove = differenceBy(panelsShadowContainer.children, panelsShadow, 'id').map((panel: any) => panel.id);

  const toAdd = differenceBy(panelsShadow, panelsShadowContainer.children, 'id');

  panelsShadowContainer.children = panelsShadowContainer.children.filter((panel: PIXI.Graphics) => {
    const remove = !toRemove.includes(panel.id);

    if (remove) {
      panel.destroy();
    }

    return remove;
  });

  toAdd.map((panel) => {
    panelsShadowContainer.addChild(drawShadowPanel(panel));
  });
  stage.addChild(panelsShadowContainer);
},                              100);

export const drawRubberBandOnStage = (stage: PIXI.Container, panelsContainer: PanelsContainer, prevRubberBandState) => {
  const { points, panelsShadow, panelsToRemove, mode, } = store.getState().rubberBand;
  stage.children = removeRubberBand(stage.children);
  panelsContainer.children.map((panel) => { panel.visible = true; });

  if (points.length) {
    const rubberBand = drawRubberBand(points);
    stage.addChild(rubberBand);

    if (mode === 'remove') {
      panelsContainer.children.map((panel) => {
        if (panelsToRemove.includes(panel.id)) {
          panel.visible = false;
        }
      });
    }
  }

  if (prevRubberBandState.panelsShadow !== panelsShadow) {
    addShadowBoxes(stage, prevRubberBandState, panelsShadow);
  }
};
