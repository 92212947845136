import { createReducer } from '__common/utils/helpers';
import { ResetPasswordActionTypes } from 'actionsConstants';

export let resetPasswordState: resetPasswordState = {
  sending: false,
  success: false,
  error: false,
};

export default {
  resetPassword: createReducer(resetPasswordState, {
    [ResetPasswordActionTypes.RESET_PASSWORD](state) {
      return { ...state, sending: true };
    },
    [ResetPasswordActionTypes.RESET_PASSWORD_SUCCESS](state) {
      return { ...state, sending: false, success: true };
    },
    [ResetPasswordActionTypes.RESET_PASSWORD_ERROR](state) {
      return { ...state, sending: false, error: true };
    },
    [ResetPasswordActionTypes.RESET_PASSWORD_CLEAR](state) {
      return { sending: false, success: false, error: false };
    },
  }),
};
