export interface BuildingCode {
  value: number;
  name: string;
}

export const asce_705: BuildingCode = {
  value: 1,
  name: 'ASCE 7-05',
};

export const asce_710: BuildingCode = {
  value: 2,
  name: 'ASCE 7-10',
};

export const asce_716: BuildingCode = {
  value: 3,
  name: 'ASCE 7-16',
};

export const asce_722: BuildingCode = {
  value: 4,
  name: 'ASCE 7-22'
}

export function isASCE716(buildingCode: number) {
  return buildingCode === asce_716.value;
}

export function isASCE705(buildingCode: number) {
  return buildingCode === asce_705.value;
}

export function isASCE710(buildingCode: number) {
  return buildingCode === asce_710.value;
}

export function isASCE722(buildingCode: number) {
  return buildingCode === asce_722.value;
}

export function isASCE716or722(buildingCode: number) {
  return buildingCode === asce_716.value || buildingCode === asce_722.value;
}
