import { removeSMHDrailoptionULA } from "__common/utils/versionCompare/versionCompare";
import { ULARailType } from "../utils/constants";
export const apiField = 'rail_type';

export const railType = (projectVersion, isPrivilegedUser) => {
  return {
    type: 'Select',
    apiField,
    label: 'rail_type',
    data: [
      { 
        value: ULARailType.SM,
        name: 'SM',
      },
      ...((removeSMHDrailoptionULA(projectVersion) && !isPrivilegedUser)?[]:[{value: ULARailType.SM_HD,name: 'SMHD',}]),
      { 
          value: ULARailType.GFT,
          name: 'GFT',
      },
    ],
  };
};
