import * as React from 'react';
import ReactDOM from 'react-dom';
import Root from '_containers/Root';
import store from '__common/store';
import { AppContainer } from 'react-hot-loader';
import '../styles/main.scss';
import 'classlist-polyfill';
import '__common/vendor/rxjs';
import '__common/vendor/polyfills';
import 'core-js/shim';
import appConfig from 'local_config';
import { getRaven } from '__common/utils/ravenConfig';



if (!appConfig.disableRaven) {
  getRaven();
}

export function renderApp(RootComponent) {
  const target = document.getElementById('react');
  
  /* istanbul ignore next */
  if (target) {
    ReactDOM.render(
      <AppContainer>
        <RootComponent store={store} />
      </AppContainer>,
      target,
    );
  }
}

window.onload = () => {
  return renderApp(Root);
};

/* istanbul ignore next  */
if (module.hot) {
  module.hot.accept('_containers/Root', () => renderApp(Root));
}
