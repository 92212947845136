import { state } from "__common/store";
import { applyPanelRotationFactorChanges } from "__common/utils/versionCompare/versionCompare";
import { degreesToRadians } from "./degreesToRadians";

type PosXY = {
  x: number,
  y: number,
};

export type line = linePoint[];

export interface linePoint {
  x: number;
  y: number;
  zone: string;
}

const CHECK_POINT_PX_DISTANCE = 1;

export const ZONES_CLASSIFICATION = {
  N: 'N',
  S: 'S',
  E: 'E',
  W: 'W',
};

export const findPerpendicularLines = (
  startPos: PosXY, 
  endPos: PosXY, 
  distance: number, 
  polygon: PolygonInterface,
  // this is to distinguish between roof edges and obstruction edges. 
  // because when we calculate windzones we want the parallel edges to go to the inside
  // and for obstruction they go around the polygon.
  insideOfPolygon: boolean,
): line[] => {
  if (lineIsParallel(startPos, endPos)) {
    return getParallelLines(startPos, endPos, distance, polygon, insideOfPolygon);
  }

  if (lineIsPrepandicular(startPos, endPos)) {
    return getPerpendicularLines(startPos, endPos, distance, polygon, insideOfPolygon);
  }

  return getLines(startPos, endPos, distance, polygon, insideOfPolygon);
};

const getLines = (
  startPos: PosXY, 
  endPos: PosXY, 
  distance: number, 
  polygon: PolygonInterface, 
  insideOfPolygon: boolean,
): line[] => {
  const {background: {panelsRotationDegrees,  bgXY}, projectConfiguration: {projectVersion}} = state();

  const avgPos = getMiddlePointOnLine(startPos, endPos);

  let { A, B, Bprim, Bbis, Bsmallprim, Bsmallbis } = findB(startPos, endPos, distance, avgPos, CHECK_POINT_PX_DISTANCE);

  const { A_perp, B_perp_bis, B_perp_prim, B_perp_small } = findPrependicular(startPos, endPos, avgPos);

  const { point1, point2, point3, point4, pointA, pointB } = getPerpendicularityDataPoints(A, Bprim, Bbis, A_perp, B_perp_prim, B_perp_bis, B_perp_small, Bsmallprim, Bsmallbis);

  const pointsAContains = polygon.containsPoint({ x: pointA.x, y: pointA.y });
  
  // A, B,  Bprim, Bbis are being used for deciding zones. Hence, calculating the values prior to rotation in order to get some zones even after rotation

  if(applyPanelRotationFactorChanges(projectVersion)) {
    const {x: x1, y: y1} = getRotatedPoint(endPos, -panelsRotationDegrees, bgXY);
    const {x: x0, y: y0} = getRotatedPoint(startPos, -panelsRotationDegrees, bgXY);  
    A = (y1-y0)/(x1-x0);
    B = y1 - A * x1;
    let distance_multiplied = distance * Math.sqrt(A ** 2+ 1);
    Bprim = distance_multiplied + B;
    Bbis = -(distance_multiplied - B);      
  }

  if (pointsAContains && insideOfPolygon) {
    const zone = calcZone(A, Bprim, B);
    return [
      [
        { x: point1.x, y: point1.y, zone },
        { x: point2.x, y: point2.y, zone },
      ],
    ];
  }

  if (pointsAContains && !insideOfPolygon) { 
    const zone = calcZone(A, Bprim, B);
    return [
      [
        { x: point3.x, y: point3.y, zone },
        { x: point4.x, y: point4.y, zone },
      ],
    ];
  }

  const pointsBContains = polygon.containsPoint({ x: pointB.x, y: pointB.y });
  
  if (pointsBContains && insideOfPolygon) {
    const zone = calcZone(A, Bbis, B);
    return [
      [
        { x: point3.x, y: point3.y, zone },
        { x: point4.x, y: point4.y, zone },
      ],
    ];
  }

  if (pointsBContains && !insideOfPolygon) {
    const zone = calcZone(A, Bbis, B);
    return [
      [
        { x: point1.x, y: point1.y, zone },
        { x: point2.x, y: point2.y, zone },
      ],
    ];
  }

  if (!pointsAContains && !pointsBContains) {
    const bPrimZone = calcZone(A, Bprim, B);
    const bBisZone = calcZone(A, Bbis, B);
    return [
      [
        { x: point1.x, y: point1.y, zone: bPrimZone },
        { x: point2.x, y: point2.y, zone: bPrimZone },
      ],
      [
        { x: point3.x, y: point3.y, zone: bPrimZone },
        { x: point4.x, y: point4.y, zone: bPrimZone },
      ],
      [
        { x: point3.x, y: point3.y, zone: bBisZone },
        { x: point4.x, y: point4.y, zone: bBisZone },
      ],
      [
        { x: point1.x, y: point1.y, zone: bBisZone },
        { x: point2.x, y: point2.y, zone: bBisZone },
      ],
    ];
  }
};

const getParallelLines = (
  startPos: PosXY, 
  endPos: PosXY, 
  distance: number, 
  polygon: PolygonInterface, 
  insideOfPolygon: boolean,
): line[] => {
  const avgPoint_x = getMiddlePointBetweenCoords(startPos.x, endPos.x);

  const shouldTakeHigherLine = polygon.containsPoint({ x: avgPoint_x, y: startPos.y + CHECK_POINT_PX_DISTANCE });
  const shouldTakeLowerLine = polygon.containsPoint({ x: avgPoint_x, y: startPos.y - CHECK_POINT_PX_DISTANCE });


  if (
    (shouldTakeLowerLine && insideOfPolygon) ||
    (!shouldTakeLowerLine && !insideOfPolygon)
  ) {
    return [
      [
        { x: startPos.x, y: startPos.y - distance, zone: ZONES_CLASSIFICATION.S },
        { x: endPos.x, y: endPos.y - distance, zone: ZONES_CLASSIFICATION.S },
      ],
    ];
  }

  if (
    (shouldTakeHigherLine && insideOfPolygon) ||
    (!shouldTakeHigherLine && !insideOfPolygon)
  ) {
    return [
      [
        { x: startPos.x, y: startPos.y + distance, zone: ZONES_CLASSIFICATION.N },
        { x: endPos.x, y: endPos.y + distance, zone: ZONES_CLASSIFICATION.N },
      ],
    ];
  }
};

const getPerpendicularLines = (
  startPos: PosXY, 
  endPos: PosXY, 
  distance: number, 
  polygon: PolygonInterface, 
  insideOfPolygon: boolean,
): line[] => {
  const avgPoint_y = getMiddlePointBetweenCoords(startPos.y, endPos.y);
  const shouldTakeWestLine = polygon.containsPoint({ x: startPos.x - CHECK_POINT_PX_DISTANCE, y: avgPoint_y });
  const shouldTakeEastLine = polygon.containsPoint({ x: startPos.x + CHECK_POINT_PX_DISTANCE, y: avgPoint_y });
  //    
  //   WEST EDGE
  //    v
  //    ┌------
  //    |
  //  o | x <== x is inside of polygon. So you should
  //    |       take the east line (east to the actual edge)
  //    |
  //    ⌊

  if (
    (shouldTakeWestLine && insideOfPolygon) ||
    (!shouldTakeWestLine && !insideOfPolygon)
  ) {
    return [
      [
        { x: startPos.x - distance, y: startPos.y, zone: ZONES_CLASSIFICATION.E  },
        { x: endPos.x - distance, y: endPos.y, zone: ZONES_CLASSIFICATION.E  },
      ],
    ];
  }

  if (
    (shouldTakeEastLine && insideOfPolygon) || 
    (!shouldTakeEastLine && !insideOfPolygon)
  ) {
    return [
      [
        { x: startPos.x + distance, y: startPos.y, zone: ZONES_CLASSIFICATION.W },
        { x: endPos.x + distance, y: endPos.y, zone: ZONES_CLASSIFICATION.W },
      ],
    ];
  }
};

const getPerpendicularityDataPoints = (
  A: number,
  Bprim: number,
  Bbis: number,
  A_perp: number,
  B_perp_prim: number,
  B_perp_bis: number,
  B_perp_small: number,
  Bsmallprim: number,
  Bsmallbis: number,
) => {

  const point1 = {
    x: (Bprim - B_perp_prim) / (A_perp - A),
    y: (Bprim * A_perp - B_perp_prim * A) / (A_perp - A),
  };

  const point2 = {
    x: (Bprim - B_perp_bis) / (A_perp - A),
    y: (Bprim * A_perp - B_perp_bis * A) / (A_perp - A),
  };

  const point3 = {
    x: (Bbis - B_perp_prim) / (A_perp - A),
    y: (Bbis * A_perp - B_perp_prim * A) / (A_perp - A),
  };

  const point4 = {
    x: (Bbis - B_perp_bis) / (A_perp - A),
    y: (Bbis * A_perp - B_perp_bis * A) / (A_perp - A),
  };

  const pointA = {
    x: (Bsmallprim - B_perp_small) / (A_perp - A),
    y: (Bsmallprim * A_perp - B_perp_small * A) / (A_perp - A),
  };

  const pointB = {
    x: (Bsmallbis - B_perp_small) / (A_perp - A),
    y: (Bsmallbis * A_perp - B_perp_small * A) / (A_perp - A),
  };

  return {
    point1,
    point2,
    point3,
    point4,
    pointA,
    pointB,
  };
};

const lineIsPrepandicular = (startPos: PosXY, endPos: PosXY) => {
  return startPos.x === endPos.x;
};

const lineIsParallel = (startPos: PosXY, endPos: PosXY) => {
  return startPos.y === endPos.y;
};

const getMiddlePointBetweenCoords = (coordinate1: number, coordinate2: number) => (coordinate1 + coordinate2) / 2;

const getMiddlePointOnLine = (startPos: PosXY, endPos: PosXY) => ({
  x: (startPos.x + endPos.x) / 2,
  y: (startPos.y + endPos.y) / 2,
});

const calcZone = (A: number, BprimOrBis: number, B: number) => {
  if (-1 / A >= 0 && -1 / A <= 1) {
    if (BprimOrBis > B) {
      return ZONES_CLASSIFICATION.W;
    }

    if (BprimOrBis < B) {
      return ZONES_CLASSIFICATION.E;
    }
  }

  if (-1 / A >= -1 && -1 / A <= 0) {
    if (BprimOrBis > B) {
      return ZONES_CLASSIFICATION.E;
    }

    if (BprimOrBis < B) {
      return ZONES_CLASSIFICATION.W;
    }
  }

  return calcZoneNS(A, BprimOrBis, B);
};

const calcZoneNS = (A: number, BprimOrBis: number, B: number) => {
  if (-1 / A < -1 || -1 / A > 1) {
    if (BprimOrBis > B) {
      return ZONES_CLASSIFICATION.N;
    }

    if (BprimOrBis < B) {
      return ZONES_CLASSIFICATION.S;
    }
  }
};

const findB = (startPos: PosXY, endPos: PosXY, distance: number, avgPos: PosXY, smallDistance: number) => {
  const { x1, y1, x2, y2, x3, y3 } = getCoords(startPos, endPos, avgPos);

  // "A" is from linear function "y = Ax + B"
  // y1 = A*x1 + B; y2 = A*x2 + B;
  // y1 - A*x1 = B; y2 - A*x2 = B; <==== formula for B here
  // y1 - A*x1 = y2 - A*x2;
  // -A*x1 + A*x2 = y2 - y1
  // A(x2 - x1) = y2 - y1
  // A = (y2 - y1) / (x2 - x1) <==== formula for A here
  const A = (y2 - y1) / (x2 - x1);
  const B = y1 - A * x1;

  // Bsmall is going to be very close to B because it is calculated from a mid point.
  const BSmall = y3 - A * x3;

  // B' and B" set parallel lines to the X1Y1-X2Y2 line - i.e. they have the same "A".
  // Ax+B, Ax+B', Ax+B";
  const distance_multiplied = distance * Math.sqrt(A ** 2 + 1);
  const Bprim = distance_multiplied + B;
  const Bbis = -(distance_multiplied - B); 
  const smallDistance_multiplied = smallDistance * Math.sqrt(A ** 2 + 1);
  const Bsmallprim = smallDistance_multiplied + BSmall;
  const Bsmallbis = -(smallDistance_multiplied - BSmall);

  return {
    A,
    B,
    Bprim,
    Bbis,
    Bsmallprim,
    Bsmallbis,
  };
};

const findPrependicular = (startPos: PosXY, endPos: PosXY, avgPos: PosXY) => {
  const { x1, y1, x2, y2, x3, y3 } = getCoords(startPos, endPos, avgPos);

  const A_perp = -1 / ((y2 - y1) / (x2 - x1));
  const B_perp_prim = y1 - A_perp * x1;
  const B_perp_bis = y2 - A_perp * x2;
  const B_perp_small = y3 - A_perp * x3;
  return {
    A_perp,
    B_perp_bis,
    B_perp_prim,
    B_perp_small,
  };
};


const getCoords = (startPos: PosXY, endPos: PosXY, avgPos: PosXY) => {
  const x1 = startPos.x;
  const y1 = startPos.y;
  const x2 = endPos.x;
  const y2 = endPos.y;
  const x3 = avgPos.x;
  const y3 = avgPos.y;

  return { x1, y1, x2, y2, x3, y3 };
};

export const getRotatedPoint = (point: pixelPoint, degrees: number, pivot: pixelPoint) : pixelPoint => {
  // degrees - clockwise
  const cosTheta = Math.cos(degreesToRadians(degrees));
  const sinTheta = Math.sin(degreesToRadians(degrees));
  
  return {
    x: (point.x- pivot.x)* cosTheta  - (point.y-pivot.y) * sinTheta + pivot.x, 
    y: (point.x- pivot.x) *sinTheta +  (point.y-pivot.y)* cosTheta + pivot.y
  };
}