export const apiField = 'rail_finish';

export const railFinish = {
  "label": "preferred_system_finish",
  "id": "railFinish",
  "type": "Radio",
  "apiField": apiField,
  "fields": [
    {
      "label": "mill",
      "value": "AL",
      "icon": "icon_mill.svg"
    },
    {
      "label": "clear",
      "value": "CLR",
      "icon": "icon_clear.svg"
    },
    {
      "label": "dark",
      "value": "DRK",
      "icon": "icon_dark.svg"
    }
  ]
}

export const adjustableTiltSystemRailFinish = {
  "label": "preferred_system_finish",
  "id": "railFinish",
  "type": "Radio",
  "apiField": apiField,
  "fields": [
    {
      "label": "mill",
      "value": "AL",
      "icon": "icon_mill.svg"
    },
    {
      "label": "dark",
      "value": "DRK",
      "icon": "icon_dark.svg"
    }
  ]
}

export const ascenderRailFinish = {
  "label": "preferred_system_finish",
  "id": "railFinish",
  "type": "Radio",
  "apiField": apiField,
  "fields": [
    {
      "label": "mill",
      "value": "AL",
      "icon": "icon_mill.svg"
    },
  ]
}

export default railFinish;