export const apiField = 'ground_screw_manufacturer';

export const options = {
  krinner: {
    value: 1,
    name: 'Krinner',
  },
};

const config = {
  type: 'Select',
  apiField,
  label: 'ground_screw_manufacturer',
  data: [
    options.krinner,
  ],
};

export default config;
