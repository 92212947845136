import React from 'react';
import { isAscender } from '__common/constants/products';
import { state } from '__common/store';
import { ON_THE_CORNER_COLOR, NEAR_ONE_EDGE_COLOR, MIDDLE_ROOF_COLOR, ASCE_7_16_BEIGE } from '__editor/panelsEditor/models/utils/panelColor';
import { asce_705, asce_710, asce_722 } from '__common/constants/buildingCodes';

const COLORS = {
  1: MIDDLE_ROOF_COLOR,
  2: NEAR_ONE_EDGE_COLOR,
  3: ON_THE_CORNER_COLOR,
  5: ASCE_7_16_BEIGE,
};

export interface LegendZone {
  zone: number;
  name: string;
}

const renderExposedZone = (color: string, productId?: number) => (
  <div 
    style={{ 
      backgroundColor: color, 
      border: '1.5px solid white', 
      outline: '1px solid black', 
    }} 
    className= {isAscender(productId) ? "zone-in-legend-portrait" : "zone-in-legend"  }
  />
);

const renderExposedZoneElevated = (color: string) => (
  <div 
    style={{ 
      backgroundColor: color, 
      border: '1.5px solid white', 
      outline: '1px solid black', 
    }} 
    className="zone-in-legend-portrait elevated-legend" 
  />
);


const renderNonExposedZone = (color: string) => (
  <div 
    style={{ 
      backgroundColor: color, 
      border: '2px solid black',
    }} 
    className="zone-in-legend" 
  />
);

const renderZoneInLegendSeparator = () => (
  <span style={{ display: 'inline-block', marginRight: '10px' }}>/</span>
);

export const renderNonExposedZoneinLegend = (color: number) => {
  let col = color.toString(16);

  if (col.length === 4) {
    col = `00${col}`;
  }

  return (
    <React.Fragment>
      {renderNonExposedZone(col)}
    </React.Fragment>
  );
};

export const renderZoneInLegend = (color: number) => {
  let col = color.toString(16);
  const { projectConfiguration:{projectEnvConfig:{building_code}} } = state();

  if (col.length === 4) {
    col = `00${col}`;
  }

  if (building_code == 1 || building_code == 2) {
    return (
      <React.Fragment>
        {renderExposedZone(col)}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {renderExposedZone(col)}
      {renderZoneInLegendSeparator()}
      {renderNonExposedZone(col)}
    </React.Fragment>
  );
};


export const renderZoneInLegendASCE710 = (color: number) => {
  let col = color.toString(16);

  if (col.length === 4) {
    col = `00${col}`;
  }
  return (
    <React.Fragment>
      {renderNonExposedZone(col)}
    </React.Fragment>
  );
};

export const renderZoneInLegendASCE716 = (color: number) => {
  let col = color.toString(16);
  const {projectConfiguration: {productId}} =state();

  if (col.length === 4) {
    col = `00${col}`;
  }
  return (
    <React.Fragment>
      {renderExposedZone(col, productId)}
      {renderZoneInLegendSeparator()}
      {renderExposedZoneElevated(col)}
    </React.Fragment>
  );
};


export const renderLegendOptionName = (name: string) => {
  return (
    <div className="roof-zone-element-name">{name}</div>
  );
};

export const renderLegendHeader = (text: string) => {
  return (
    <div className="drawer-section-title">{text}</div>
  );
};

export const renderLegendSubHeader = (text: string) => {
  return (
    <div className="drawer-section-subtitle">{text}</div>
  );
};

export const createLegend = (zones: LegendZone[]) => {
  
  const { projectConfiguration:{projectEnvConfig:{building_code}} } = state();

  if (building_code == asce_705.value || building_code == asce_710.value || building_code == asce_722.value) {
    zones = [{
      zone: 3,
      name: '3',
    }, {
      zone: 2,
      name: '2',
    }, {
      zone: 1,
      name: '1',
    }];
    return (
      <div className="roof-zones-legend">
        {renderLegendHeader('Zones: ')}
        {zones && zones.map(zone => (
          <div className="legend">
            {renderZoneInLegend(COLORS[zone.zone])}
            {renderLegendOptionName(zone.name)}
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className="roof-zones-legend">
      {renderLegendHeader('Zones: ')}
      {renderLegendSubHeader('Exposed / Non-exposed')}
      {zones && zones.map(zone => (
        <div className="legend">
          {renderZoneInLegend(COLORS[zone.zone])}
          {renderLegendOptionName(zone.name)}
        </div>
      ))}
    </div>
  );
};
