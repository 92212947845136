import { lbsToKgs } from '__common/calculations/unitConversions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { round } from 'lodash';
import balastBlockWeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/balastBlockWeightTooltip';
export const apiField = 'block_weight'

function makeConfig(max: number = 100, min: number = 1, label:string= 'ballastBlockWeightLbs') {
  return {
    type: 'Number',
    min,
    max,
    apiField,
    label,
    tooltip: balastBlockWeightTooltip,
  };
}

const config  = makeConfig();

export default config;

export const ballastBlockWeightField = (inputUnit: number = 1) => isMetricUnit(inputUnit) ?  makeConfig(round(lbsToKgs(100), 1), 1, 'ballastBlockWeightKg') : config;


