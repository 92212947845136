import RoofTypeTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/roofTypeTooltip';
import RmRoofTypeTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/rmRoofTypeTooltip';
export const apiField = 'roof_type';

const RoofTypes = {
  SHINGLE: {value: 1, name: 'Shingle'},
  TILE: {value: 3, name: 'Tile'},
}

function makeConfig(options: object[], params: object) {
  return {
    type: 'Select',
    label: 'roofType',
    missing: undefined,
    tooltip: params['toolTip'],
    apiField,
    data: options,
  };
}

export default makeConfig(
  [
    {
      value: 1,
      name: 'SHINGLE',
    },
    {
      value: 2,
      name: 'STANDING SEAM METAL',
    },
    {
      value: 3,
      name: 'TILE',
    },
    {
      value: 4,
      name: 'OTHER',
    },
  ], 
  { toolTip: RoofTypeTooltip });

export const ecoFoot2PlusRoofType = makeConfig(
  [  
    {
      value: '5',
      name: 'MINERAL CAP',
    },
    {
      value: '6',
      name: 'EPDM',
    },
    {
      value: '7',
      name: 'TPO',
    },
    {
      value: '8',
      name: 'PVC',
    },
    {
      value: '12',
      name: 'TAR & GRAVEL',
    },
    {
      value: '9',
      name: 'OTHER',
    },
  ], 
  { toolTip: RmRoofTypeTooltip });

export const smFlushRoofType = makeConfig(
  [  
    {
      value: 1,
      name: 'SHINGLE',
    },
    {
      value: 2,
      name: 'STANDING SEAM METAL',
    },
    {
      value: 5,
      name: 'R-PANEL',
    },
    {
      value: 3,
      name: 'TILE',
    },
    {
      value: 4,
      name: 'OTHER',
    },
  ],
  { toolTip: RmRoofTypeTooltip });

  export const smAscenderFlushRoofType = makeConfig(
    [  
      {
        value: 1,
        name: 'Shingle',
      },
    ],
    { toolTip: RmRoofTypeTooltip });
    

  export const ascenderRoofType = makeConfig(
    [  
      {
        value: 1,
        name: 'CONCRETE',
      },
    ],
    { });
    
  export const nhRoofType = makeConfig(
    [  
      {
        value: 1,
        name: 'SHINGLE',
      },
      {
        value: 2,
        name: 'STANDING SEAM METAL',
      },
      {
        value: 5,
        name: 'R-PANEL',
      },
      {
        value: 3,
        name: 'TILE',
      },
    ],
    { toolTip: RmRoofTypeTooltip });
  
export const smTiltPRRoofType = makeConfig(
  [
    {
      value: 6,
      name: 'GABLE',
    },
  ],
  { toolTip: null });

  export const SFMInfinityRoofType = makeConfig([RoofTypes.SHINGLE, RoofTypes.TILE],{ toolTip: RoofTypeTooltip });
