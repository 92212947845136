import { EngineeringBallastMapsActionsConstants } from 'actionsConstants';

export function BALLAST_GENERATING_START(generatedArrayId: number, generatedLayoutType: number, projectId: string, unitsType: string) {
  return {
    type: EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_START,
    payload: {
      generatedArrayId,
      generatedLayoutType,
      projectId,
      unitsType,
    },
  };
}

export function BALLAST_GENERATING_SUCCESS() {
  return {
    type: EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_SUCCESS,
  };
}

export function BALLAST_GENERATING_FAILED() {
  return {
    type: EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_FAILED,
  };
}

export function BALLAST_GENERATING_RESET() {
  return {
    type: EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_RESET,
  };
}
