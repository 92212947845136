import setBackDistanceTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/setbackDistanceTooltip';
import { areAllPanelsInSetbackDistance } from '__editor/panelsEditor/components/panels/utils/panelsManagment';

export const apiField = 'setback_distance';

function makeConfig(options: number[], label: string = 'setbackDistance') {
  return {
    type: 'Number',
    label,
    apiField,
    tooltip: setBackDistanceTooltip,
    data: [],
    min: options[0],
    max: options[1],
    shouldConfirm: true,
    shouldConfirmCb: (val) => areAllPanelsInSetbackDistance(Number(val)),
    title: 'Are you sure you want change setback distance?',
    content: 'Array layouts in the setback region will be lost!',
  
  };
}

export default makeConfig(
  [0, 10],
);

export const ecoFoot2PlusSetbackDistance = makeConfig(
  [1, 100],
);

export const RMSetbackDistance = makeConfig(
  [1, 100],
);

export const SetbackDistanceMetric = makeConfig([0,999], 'setbackDistanceMetric')

export const ecoFootSetbackDistanceMetric = makeConfig([0,100], 'setbackDistanceMetric')