export const apiField = 'attachment_type';

function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'standing_seam_metal_attachments',
    apiField,
    data: options,
  };
}

export default makeConfig([
  {
    value: 16,
    name: 'Mini 1ss',
  },
  {
    value: 17,
    name: 'Stand 2ss',
  },
  {
    value: 18,
    name: 'Wide 2ss',
  },
  
]);
