export const apiField = 'ns_diagnol_brace'
export const YES_OPTION = {
  value: 'true',
  name: 'YES',
};

export const NO_OPTION = {
  value: 'false',
  name: 'NO',
};

export const nsDiagnolBrace = {
  apiField,
  type: 'Select',
  label: 'ns_diagnol_brace',
  data: [
    {
      value: YES_OPTION.value,
      name: YES_OPTION.name,
    },
    {
      value: NO_OPTION.value,
      name: NO_OPTION.name,
    },
  ],
};

