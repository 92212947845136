function findClosesNumberInArray(arr, closestTo) {
  let closestNumber = Math.max.apply(null, arr);

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] >= closestTo && arr[i] < closestNumber) closestNumber = arr[i];
  }

  return closestNumber;
}

function getCorrectWindSpeed(windSpeed) {
  const windSpeeds = [85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170];
  return findClosesNumberInArray(windSpeeds, windSpeed);
}

export function parseDataFromImport(data) {
  data.wind_speed = getCorrectWindSpeed(data.wind_speed);
  data.snow_load = data.snow_load || 0;
  data.seismic_ss = data.seismic_ss || 0;

  if (data.helioscope_rack_type === 'dual') {
    data.helioscope_rack_type = 'rack';
  }
  return data;
}

