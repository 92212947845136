
import { selectAccessory, changeAttachmentType, changeAttachment, selectLagBolts, changeMetalXRoofAttachmentType, changeTileReplacementType } from './partsSelectionFunctions';
import { state, dispatch } from '__common/store';
import { BOM_SELECTION_LOADING_START, BOM_SELECTION_LOADING_STOP, SAVE_BOM, SET_BOM_PK } from '../../bomActions';
import { FETCH_RAILS } from '../railsSelector/railsSelectorActions';
import { isMetalX, products } from '__common/constants/products';
import { parseDataForBomSave } from 'bom/bomHelpers';

export const loadBomSelections = (selections: bomSelections, productId: string, projectId: string ) => {
  const { bom: { accessoriesList, attachmentsList, goToEngineeringFromBom, partsList, 
          extraParameters: { continuous_trims_sections, select_only_smhd_rails, rm_roof_pad },},
          railsSelector: { railsList, selectOnlySMHDRails }, } = state();
  const { bom: { extraParameters, tileReplacementList } } = state()

  if (!selections) {
    return;
  }

  dispatch(BOM_SELECTION_LOADING_START());

  selections.accessories.map((acc: string) => {
    const accessory: bomPart = accessoriesList.find((ac: bomPart) => {
      return ac.number === acc && !ac.selected;
    }); 
    if (accessory) {
      selectAccessory(accessory, partsList, accessoriesList, continuous_trims_sections, productId, projectId, rm_roof_pad);
    }
  });

  if (attachmentsList && attachmentsList[selections.attachmentType] && selections.attachmentType) {
    changeAttachmentType(selections.attachmentType);
  }

  if (selections['tile hooks'] && isTileHookOnList(attachmentsList, selections.attachmentType, selections['tile hooks'])) {
    changeAttachment(selections['tile hooks'], 'tile hooks', attachmentsList[selections.attachmentType]['tile hooks']);
  }

  if (selections.flashings && isFlashingOnList(attachmentsList, selections.attachmentType, selections.flashings)) {
    changeAttachment(selections.flashings, 'flashings', attachmentsList[selections.attachmentType].flashings);
  }

  if (selections.standoffs && isStandoffOnList(attachmentsList, selections.attachmentType, selections.standoffs)) {
    changeAttachment(selections.standoffs, 'standoffs', attachmentsList[selections.attachmentType].standoffs);
  }

  
  if (isMetalX(products[productId]) && selections?.selected_tile_replacement && attachmentsList && Object.keys(attachmentsList).length) {
    attachmentsList['standing seam metal attachments'] && !attachmentsList['standing seam not verified'].hasOwnProperty(selections.selected_tile_replacement.tile) && 
    changeMetalXRoofAttachmentType('standing seam metal attachments', selections.selected_tile_replacement.tile, Object.keys(attachmentsList['standing seam metal attachments']));
    
    attachmentsList['Corrugated roof'] && attachmentsList['Corrugated roof'].hasOwnProperty(selections.selected_tile_replacement.tile) && 
    changeMetalXRoofAttachmentType('Corrugated roof', selections.selected_tile_replacement.tile, Object.keys(attachmentsList['Corrugated roof']));
    
    attachmentsList['standing seam not verified'] && !attachmentsList['standing seam metal attachments'].hasOwnProperty(selections.selected_tile_replacement.tile) && 
    changeMetalXRoofAttachmentType('standing seam not verified', selections.selected_tile_replacement.tile, Object.keys(attachmentsList['standing seam not verified']));
  }

  if (selections?.selected_tile_replacement && Object.keys(tileReplacementList).length) {
    selections?.selected_tile_replacement.hasOwnProperty('tiles') && changeTileReplacementType('tiles', selections.selected_tile_replacement.tile, Object.keys(tileReplacementList.tiles));
    selections?.selected_tile_replacement.hasOwnProperty('sub flashing') && changeTileReplacementType('sub flashing', selections.selected_tile_replacement['sub flashing'], Object.keys(tileReplacementList['sub flashing']));
    selections?.selected_tile_replacement.hasOwnProperty('tile flashing') && changeTileReplacementType('tile flashing', selections.selected_tile_replacement['tile flashing'], Object.keys(tileReplacementList['tile flashing']))
  }

  if (selections.lag_bolts) {
    const { bom: { selectedAttachmentType } } = state();
    selectLagBolts(selectedAttachmentType['lag_bolts']);
  }

  if(select_only_smhd_rails) {
    dispatch(FETCH_RAILS(selections.selected_rails, projectId, false, true, true));
  }

  if (selections.selected_rails && selections.selected_rails.length) {
    if (goToEngineeringFromBom) {
       const bomSaveRequestBody = parseDataForBomSave(partsList, railsList, extraParameters, products[productId], selectOnlySMHDRails);
       if (bomSaveRequestBody && Object.keys(bomSaveRequestBody)?.length > 0) {
        dispatch(SAVE_BOM(productId, projectId, bomSaveRequestBody, false));
       }
    }
  }

  dispatch(BOM_SELECTION_LOADING_STOP());
  
};

function isFlashingOnList(attachmentsList: bomPart[], attachmentType: string, flashing: string) {
  return attachmentsList && attachmentsList[attachmentType] && attachmentsList[attachmentType].flashings && attachmentsList[attachmentType].flashings.find(part => part.number.includes(flashing.slice(0, -1)));
}

function isStandoffOnList(attachmentsList: bomPart[], attachmentType: string, standoff: string) {
  return attachmentsList && attachmentsList[attachmentType] && attachmentsList[attachmentType].standoffs && attachmentsList[attachmentType].standoffs.find(part => part.number.includes(standoff.slice(0, -1)));
}

function isTileHookOnList(attachmentsList: bomPart[], attachmentType: string, tileHook: string) {
  return attachmentsList && attachmentsList[attachmentType] && attachmentsList[attachmentType]['tile hooks'] && attachmentsList[attachmentType]['tile hooks'].find(part => part.number.includes(tileHook.slice(0, -1)));
}
