export const apiField = 'slip_sheet';

function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'Slip Sheet',
    apiField,
    missing: undefined,
    data: options,
  };
}

export default makeConfig([
  {
    value: 1,
    name: 'YES',
  },
  {
    value: 2,
    name: 'NO',
  },
],
);
