import { TypeOfSupport } from 'app/components/ReportProblem/constants';
import { getFormattedWord, getFormattedWordForProductLabel } from 'app/components/ReportProblem/ReportProblemForm';
import { getProductName, isAuroraProduct, isSM2Standard, productsInfo } from '__common/constants/products';
import { getProductTitle } from '__common/constants/products_titles';
import { state } from '__common/store';

export const accessCheckForProduct = (user: userState, productId: number) => {
  const { products } = user;
  return products.includes(productId);
}

export const getProductsInfoForUser = (user: userState) => productsInfo.map(({ productType, productItems }) => ({
  productType,
  nestedItems: productItems.filter(productId => accessCheckForProduct(user, productId)).map(productId => createProduct(productId))
})).filter(product => product.nestedItems.length);

export const getAllProductsInfo = (user: userState) => productsInfo.map(({ productType, productItems }) => ({
  productType,
  nestedItems: productItems.map(productId => createProduct(productId))
})).filter(product => product.nestedItems.length);

export const getUnaccessedProductsInfoForUser = (user: userState) => productsInfo.map(({ productType, productItems }) => ({
  productType,
  nestedItems: productItems.filter(productId => !accessCheckForProduct(user, productId)).map(productId => createProduct(productId))
})).filter(product => product.nestedItems.length);

function createProduct(productId: number) {
  return {
    title: getProductTitle(productId, true),
    productId,
  };
}

export function getAllowedProductsInfo(user: userState, contact_support_category: TypeOfSupport=TypeOfSupport.ISSUE_REPORT) {
  const {projectConfiguration:{projectEnvConfig}} = state();
  const listOfAccessedProducts = getProductsInfoForUser(user);
  const listOfUnaccessedProducts = getUnaccessedProductsInfoForUser(user);
  const allowedProductsInfo = contact_support_category === TypeOfSupport.NEED_ACCESS ? listOfUnaccessedProducts : listOfAccessedProducts;    
  const nestedProductsInfoList = allowedProductsInfo.map(e=>e.nestedItems);
  const productinfoList = [].concat.apply([], nestedProductsInfoList.map(subArray => subArray)).map(product=>({
    productValue:getFormattedWord(getProductName(product.productId)), 
    productLabel:getFormattedWordForProductLabel(product.title.name),  
    productId: product.productId}));
  let uniqueProductList = (projectEnvConfig?.aurora_project_id || projectEnvConfig?.is_aurora_project) ? 
  productinfoList.filter((obj, index) => {
    return isAuroraProduct(obj.productId) && index === productinfoList.findIndex(o => obj.productId === o.productId);
  }) : 
  productinfoList.filter((obj, index) => {
    return index === productinfoList.findIndex(o => obj.productId === o.productId);
  });    
  if(projectEnvConfig?.aurora_project_id || projectEnvConfig?.is_aurora_project){
    uniqueProductList.push({
      productValue: 'AURORA',
      productLabel: 'AURORA',
      productId: 100
    })
  }
  return uniqueProductList;
}

export function isUniracOrNuevoSolUser(emailChecking) {
  const domainList = ['unirac.com','rivalschoolsonline.com', 'nuevo-sol.com']
  const emailCheck = emailChecking?.split('@')
  if (emailCheck?.length === 2){
    return domainList.includes(emailCheck[1]);
  }

  return false;
}

export function isUniracUser(emailChecking:string) {
  const userDomain = 'unirac.com';
  const emailParts = emailChecking.split('@'); 
  if (emailParts.length === 2 && emailParts[1] === userDomain) {
    return true;
  }

  return false;
}
