import { isRMFamily } from '__common/constants/products';

export function sortBomTableByUniracRequest(productId: number, partsList: bomPart[]): bomPart[] {
  if (isRMFamily(productId)) {
    return sortByUniracRMRequest(partsList);
  }

  return sortByUniracSMRequest(partsList);
}

function sortByUniracRMRequest(partsList: bomPart[]): bomPart[] {
  const sortObject = {
    'ballast block': [],
    'ballast bay': [],
    'roof attachment': [],
    rail: [],
    clamp: [],
    'ballast rail': [],
    'rm roof pad': [],
    'module clip': [],
    'wind dam': [],
    'wind dam clip': [],
    'mid clamp': [],
    'end clamp': [],
    'rm hex bolt': [],
    nut: [],
    'clip on nut': [],
    others: [],
  };

  const sorted = partsList.reduce((acc, part: bomPart) => {
    if (acc[part.type.toLocaleLowerCase()]) {
      acc[part.type.toLocaleLowerCase()].push(part);
    } else {
      acc['others'].push(part);
    }

    return acc;
  },                              sortObject);
  const sortedToArray = Object.values(sorted);
  // Base Category ID = 1, Accessory Category ID = 2
  // move Accessory to below Base Parts
  // As we want base before accessories means B > A
  // COMPARE FN=`(a,b)=>...` => return -1 if we want a before b & return 1 if we want b before a
  const flattened = [].concat(...sortedToArray).sort((x,y) => x.category > y.category ? -1 : 1);
  return flattened;
}

function sortByUniracSMRequest(partsList: bomPart[]): bomPart[] {
  const sortObject = {
    base: { 
      rails: [],
      splices: [],
      midClamps: [],
      endClamps: [],
      other: [],
    },
    attachments: [],
    accessories: [],
  };

  const sorted = partsList.reduce((acc, part: bomPart) => {
    if (part.category === 'Base') {
      if (part.type === 'Rail') {
        acc.base.rails.push(part);
      } else if (part.type === 'Splice') {
        acc.base.splices.push(part);
      } else if (part.type === 'Mid Clamp') {
        acc.base.midClamps.push(part);
      } else if (part.type === 'End Clamp') {
        acc.base.endClamps.push(part);
      } else {
        acc.base.other.push(part);
      }
    }

    if (part.category === 'Accessory') {
      if (part.type === 'Roof Attachment') {
        acc.attachments.push(part);
      } else {
        acc.accessories.push(part);
      }
    }

    return acc;
  },                              sortObject);

  const flattenedBase = Object.keys(sorted.base).reduce((acc, type) => {
    const concated = acc.concat(sorted.base[type]);
    return concated;
  },                                                    []);

  const flattened = flattenedBase.concat(sorted.attachments).concat(sorted.accessories);
  return flattened;
}
