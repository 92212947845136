import { BackgroundActionTypes as backgroundType } from '__editor/panelsEditor/components/background/backgroundActionsConstants';
import { CursorActionTypes as cursorTypes } from '__editor/panelsEditor/components/cursor/cursorActionsConstants';
import { DimensionsActionTypes as dimensionsType } from '__editor/panelsEditor/components/dimensions/dimensionsActionsConstants';
import { HistoryActionTypes as historyType } from '__editor/panelsEditor/components/history/historyActionsConstants';
import { PanelsActionTypes as panelsTypes } from '__editor/panelsEditor/components/panels/panelsActionsConstants';
import { TooltipCursorActionTypes as tooltipCursor } from '__common/components/tooltipCursor/tooltipCursorActionsConstants';
import { RubberBandActionTypes as rubberBandTypes } from '__editor/panelsEditor/components/rubberBand/rubberBandActionsConstants';
import { ScaleActionTypes as scale } from '__editor/panelsEditor/components/scale/scaleActionsConstants';
import { SettingsActionTypes as settingsType } from '__editor/panelsEditor/components/settings/settingsActionsConstants';
import { StageActionTypes as stageTypes } from '__editor/panelsEditor/components/stage/stageActionsConstants';
import { TiltedRoofActionTypes as tiltedRoofType } from '__editor/panelsEditor/components/tiltedRoof/tiltedRoofActionsConstants';
import { ObstructionsActionTypes as Obstructions } from '__editor/panelsEditor/components/obstructions/ObstructionsActionsConstants';
import { RoofZonesActionTypes as RoofZonesType } from '__editor/panelsEditor/components/roofZones/roofZonesActionsConstants';

export const HistoryActionTypes = historyType;
export const CursorActionTypes = cursorTypes;
export const PanelsActionTypes = panelsTypes;
export const TooltipCursorActionTypes = tooltipCursor;
export const RubberBandActionTypes = rubberBandTypes;
export const StageActionTypes = stageTypes;
export const SettingsActionTypes = settingsType;
export const DimensionsActionTypes = dimensionsType;
export const BackgroundActionTypes = backgroundType;
export const TiltedRoofActionTypes = tiltedRoofType;
export const ScaleActionTypes = scale;
export const ObstructionsActionTypes = Obstructions;
export const RoofZonesActionTypes = RoofZonesType;
