import { ObstructionsActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';

export interface ADD_OBSTRUCTION_ACTION {
  roofId: number;
  obstructionId: number;
  name: string;
  coords: pixelPoint[];
  length: number;
  width: number;
  isLargeEnough: boolean;
  metersPerPixel: number;
}

export function ADD_OBSTRUCTION(
  roofId: number, 
  obstructionId: number, 
  name: string, 
  length: number,
  width: number,
  coords: { x: number, y: number }[], 
  isLargeEnough: boolean,
  metersPerPixel: number
): Action<ADD_OBSTRUCTION_ACTION> {
  return {
    type: ObstructionsActionTypes.ADD_OBSTRUCTION,
    payload: {
      roofId,
      obstructionId,
      name,
      length,
      width,
      coords,
      isLargeEnough,
      metersPerPixel
    },
  };
}

export function ADD_OBSTRUCTIONS(roofId: number, obstructions: roofObstructions) {
  return {
    type: ObstructionsActionTypes.ADD_OBSTRUCTIONS,
    payload: {
      roofId,
      obstructions,
    },
  };
}

export function REMOVE_OBSTRUCTION(roofId: number, obstructionId: number) {
  return {
    type: ObstructionsActionTypes.REMOVE_OBSTRUCTION,
    payload: {
      roofId,
      obstructionId,
    },
  };
}

export function REMOVE_ROOF_OBSTRUCTIONS(roofId: number) {
  return {
    type: ObstructionsActionTypes.REMOVE_ROOF_OBSTRUCTIONS,
    payload: {
      roofId,
    },
  };
}

export function CHANGE_OBSTRUCTION_NAME(roofId: number, obstructionId: number, name: string) {
  return {
    type: ObstructionsActionTypes.CHANGE_OBSTRUCTION_NAME,
    payload: {
      roofId,
      obstructionId,
      name,
    },
  };
}

export function CHANGE_OBSTRUCTION_HEIGHT(roofId: number, obstructionId: number, height: string) {
  return {
    type: ObstructionsActionTypes.CHANGE_OBSTRUCTION_HEIGHT,
    payload: {
      roofId,
      obstructionId,
      height,
    },
  };
}

export function SET_EDITING_OBSTRUCTION_ID(obstructionId: number | null) {
  return {
    type: ObstructionsActionTypes.SET_EDITING_OBSTRUCTION_ID,
    payload: {
      obstructionId,
    },
  };
}

export function CLEAR_OBSTRUCTIONS() {
  return {
    type: ObstructionsActionTypes.CLEAR_OBSTRUCTIONS,
  };
}

export function CENTER_OBSTRUCTIONS_ON_ROOF(xOffset: number, yOffset: number, roofId: number) {
  return {
    type: ObstructionsActionTypes.CENTER_OBSTRUCTIONS_ON_ROOF,
    payload: {
      xOffset,
      yOffset,
      roofId,
    },
  };
}
