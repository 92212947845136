import { LeadEdgeRoofSelectorActionTypes } from 'actionsConstants';


export function SELECT_ROOF_LEAD_EDGE(roofEdgeNumber: number, roofId: number) {
  return {
    type: LeadEdgeRoofSelectorActionTypes.SELECT_ROOF_LEAD_EDGE,
    payload: {
      roofEdgeNumber,
      roofId,
    },
  };
}

export function CLEAR_ROOF_LEAD_EDGE(selectedRoofId: number | string) {
  return {
    type: LeadEdgeRoofSelectorActionTypes.CLEAR_ROOF_LEAD_EDGE,
    payload: {
      selectedRoofId,
    },
  };
}

export function DELETE_ALL_LEAD_EDGES() {
  return {
    type: LeadEdgeRoofSelectorActionTypes.DELETE_ALL_LEAD_EDGES,
  };
}
