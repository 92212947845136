import { hitApi, ObservableAjax } from '__common/utils/api';
import { UPDATE_USER_PREFERENCES, USER_PREFERENCES_SAVE_ERROR } from 'actions';
import { UserPreferencesActionTypes } from 'actionsConstants';

export function sendNewUserPreferences(action$) {
  return action$.ofType(UserPreferencesActionTypes.SEND_NEW_USER_PREFERENCES)
    .switchMap(action => {
      const userPreferences: userPreferences = action.payload.userPreferences;
      return ObservableAjax({
        takeUntil: action$.ofType(UserPreferencesActionTypes.SEND_NEW_USER_PREFERENCES),
        onSuccess: UPDATE_USER_PREFERENCES,
        onError: USER_PREFERENCES_SAVE_ERROR,
        onErrorMessage: 'Cannot save user preferences',
        link: hitApi('put', `api/v1/user_preferences/`, { ...userPreferences }),
      });
    });
}
