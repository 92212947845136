import { createReducer } from '__common/utils/helpers';
import { UserPreferencesActionTypes } from 'actionsConstants';

export const userPreferencesState: userPreferencesState = {
  product: 9,
  area: '',
  zipcode: null,
  projects_per_page: null,
  preferred_span: null,
  project_sorting: null,
  sort_method: null,
  helioscope_user: false,
  helioscope_username: null,
  helioscope_password: null,
  stagger_attachments: null,
  rafter_spacing_inches: null,
  map_choice: '',
  model: null,
  module: null,
  error: null,
  saveSuccess: false,
};

export default {
  userPreferences: createReducer(userPreferencesState, {
    [UserPreferencesActionTypes.SET_USER_PRODUCT](state, action) {
      const { product } = action.payload;
      return { ...state, product };
    },
    [UserPreferencesActionTypes.SET_USER_ZIPCODE](state, action) {
      const { zipcode } = action.payload;
      return { ...state, zipcode };
    },
    [UserPreferencesActionTypes.SET_USER_SPANS](state, action) {
      const { preferred_span } = action.payload;
      return { ...state, preferred_span };
    },
    [UserPreferencesActionTypes.SET_USER_PROJECTS_PER_PAGE](state, action) {
      const { projects_per_page } = action.payload;
      return { ...state, projects_per_page };
    },
    [UserPreferencesActionTypes.SET_USER_PROJECTS_SORTING](state, action) {
      const { project_sorting } = action.payload;
      return { ...state, project_sorting };
    },
    [UserPreferencesActionTypes.SET_USER_SORT_METHOD](state, action) {
      const { sort_method } = action.payload;
      return { ...state, sort_method };
    },
    [UserPreferencesActionTypes.SET_USER_RAFTER_SPACING](state, action) {
      const { rafterSpacing } = action.payload;

      return { ...state, rafter_spacing_inches: rafterSpacing };
    },
    [UserPreferencesActionTypes.SET_USER_HELIOSCOPE](state, action) {
      const { helioscope_user } = action.payload;
      return { ...state, helioscope_user };
    },
    [UserPreferencesActionTypes.SET_USER_HELIOSCOPE_LOGIN](state, action) {
      const { helioscope_username } = action.payload;
      return { ...state, helioscope_username };
    },
    [UserPreferencesActionTypes.SET_USER_HELIOSCOPE_PASSWORD](state, action: { payload: userPreferences }) {
      const { helioscope_password } = action.payload;
      return { ...state, helioscope_password };
    },
    [UserPreferencesActionTypes.UPDATE_USER_PREFERENCES](state, action: { payload: { userPreferences: userPreferences, notAfterSave?: boolean } }) {
      return { ...state, ...action.payload.userPreferences, saveSuccess: action.payload.notAfterSave ? false : true, error: null };
    },
    [UserPreferencesActionTypes.SET_STAGGER_ATTACHMENTS](state, action) {
      const { stagger_attachments } = action.payload;
      return { ...state, stagger_attachments };
    },
    [UserPreferencesActionTypes.CLEAR_USER_PREFERENCES](state, action) {
      return {
        product: null,
        zipcode: null,
        projects_per_page: null,
        preferred_span: null,
        project_sorting: null,
        sort_method: null,
        rafter_spacing_inches: null,
        helioscope_user: false,
        helioscope_username: null,
        helioscope_password: null,
        stagger_attachments: null,
        model: null,
        module: null,
        error: null,
      };
    },
    [UserPreferencesActionTypes.USER_PREFERENCES_SAVE_ERROR](state, action) {
      const { error } = action.payload;
      return { ...state, error, saveSuccess: false };
    },
    [UserPreferencesActionTypes.SET_AREA](state, action) {
      const { area } = action.payload;
      return { ...state, area };
    },
  }),
};
