import * as PIXI from 'pixi.js';
import { state } from '__common/store';

function leadEdge(cordInPx: pixelPoint[], rotated: boolean = false) {
  let y1 = cordInPx[0].y;
  let y2 = cordInPx[1].y;
  let x1 = cordInPx[0].x;
  let x2 = cordInPx[1].x;
  const xOffset = 2;
  const yOffset = 2;
  if (cordInPx[0].y < 0) {
    y1 -= yOffset;
    y2 -= yOffset;
    x1 += xOffset;
    x2 += xOffset;
  } else {
    y1 += yOffset;
    y2 += yOffset;
    x1 -= xOffset;
    x2 -= xOffset;
  }

  const id = rotated ? 'rotatedLeadEdge' : 'leadEdge';
  return drawdash(x1, y1, x2, y2, 1, 0xFFFFFF, id);

}

export default leadEdge;

export function drawdash(x0, y0, x1, y1, lineWidth, color, id: string = 'leadEdge') {
  const { roofsSelector: { mapType } } = state();
  const lineColor = mapType === 'white' ? 0x00000 : 0xFFFFFF;
  const dashed = new PIXI.Graphics();
  dashed.lineStyle(1, lineColor, 1); // linewidth,color,alpha
  dashed.moveTo(0, 0);
  dashed.lineTo(lineWidth, 0);
  dashed.moveTo(lineWidth * 1.5, 0);
  dashed.lineTo(lineWidth * 2, 0);
  dashed.id = id;
  const dashedtexture = dashed.generateCanvasTexture(2, 4);
  const linelength = Math.pow(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2), 0.5);
  const tilingSprite = new PIXI.extras.TilingSprite(dashedtexture, linelength, lineWidth);
  tilingSprite.x = x0;
  tilingSprite.y = y0;
  tilingSprite.rotation = angle(x0, y0, x1, y1) * Math.PI / 180;
  tilingSprite.zIndex = 1000000000;
  return tilingSprite;

  function angle(x0, y0, x1, y1) {
    const diff_x = Math.abs(x1 - x0);
    const diff_y = Math.abs(y1 - y0);
    let cita;
    if (x1 > x0) {
      if (y1 > y0) {
        cita = 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
      } else {
        if (y1 < y0) {
          cita = -360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
        } else {
          cita = 0;
        }
      }
    } else {
      if (x1 < x0) {
        if (y1 > y0) {
          cita = 180 - 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
        } else {
          if (y1 < y0) {
            cita = 180 + 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
          } else {
            cita = 180;
          }
        }
      } else {
        if (y1 > y0) {
          cita = 90;
        } else {
          if (y1 < y0) {
            cita = -90;
          } else {
            cita = 0;
          }
        }
      }
    }
    return cita;
  }
}
