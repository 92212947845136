import React from 'react';
import { apiUrl } from 'local_config';
import EngineeringBalastActionButton from './engineeringBalastActionButton';

type Props = {
  projectId: string,
  notation: string,
  arrayId?: number,
};

class OpenMapSourceCodeInNewTabButton extends React.Component<Props> {
  openInNewTab = () => {
    const {projectId, notation, arrayId} = this.props;
    window.open(`${location.protocol}//${apiUrl}api/v1/layout_external/${projectId}/${arrayId}/${notation === 'old' ? '?notation=old': ''}`);
  }

  render() {
    const { notation, arrayId } = this.props;

    if (arrayId) {
      return (
        <EngineeringBalastActionButton 
          tooltipLabel={`Open source code (${notation})`} 
          onClick={this.openInNewTab} 
          svgPath={require('assets/media/icons/icon_copycode.svg')}
        />
      );
    }

    return null;
  }
}

export default OpenMapSourceCodeInNewTabButton;
