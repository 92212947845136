import meanTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/meanTooltip';
export const apiField = 'mean_recurrence_interval'
export default {
  type: 'Select',
  label: 'meanRecurrenceInterval',
  apiField: apiField,
  tooltip: meanTooltip,
  data: [
    {
      value: '50',
      name: '50',
    },
  ],
};
