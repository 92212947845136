import { StaggerAttachmentsField, apiField } from 'projectDesign/components/projectConfiguration/fields/types/staggerAttachments';
import metalXStaggerAttachmentsTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/metalXStaggerAttachmentsTooltip';

export const YES_OPTION = {
  value: 'true',
  name: 'YES',
};

export const NO_OPTION = {
  value: 'false',
  name: 'NO',
};

const config: StaggerAttachmentsField = {
  apiField,
  type: 'Select',
  label: 'staggerAttachments',
  // tooltip: metalXStaggerAttachmentsTooltip,
  data: [
    // {
    //   value: YES_OPTION.value,
    //   name: YES_OPTION.name,
    // },
    {
      value: NO_OPTION.value,
      name: NO_OPTION.name,
    },

  ],
};

export default config;
