import * as React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { getBaysContainer } from '../../panels/utils/panelsContainer';
import { deleteAllPanels } from '../../panels/utils/panelsManagment';

class ClearArrayButton extends React.Component<onlyDispatchComponentState, {}> {
  clearAll = () => {
    deleteAllPanels();
    getBaysContainer().removeChildren()
  }

  render() {
    return (
      <Button flat={true} onClick={this.clearAll} className="roofs-selector-button" tooltipLabel="Clear array">
        <SVG src={require('assets/media/icons/icon_delete.svg')} />
      </Button>
    );
  }
}

export default connect()(ClearArrayButton);
