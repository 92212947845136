import { ActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';
import { parseDataFromImport } from 'helioscopeImport/components/helioscopeDesignsConfig/utils/parseDataFromImport';


export const helioscopeDesignConfigState: helioscopeDesignConfigState = {
  initData: [],
  isLoading: false,
  isReady: false,
  isImportingNow: false,
  error: false,
  successfullyImportedProjectId: '',
};

export default {
  helioscopeDesignConfig: createReducer(helioscopeDesignConfigState, {
    [ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_REQUEST](state) {
      const helioscopeDesignConfig = { ...state.helioscopeDesignConfig, isLoading: true };

      return { ...state, ...helioscopeDesignConfig };
    },
    [ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_SUCCESS](state, action) {
      const helioscopeDesignConfig = {
        ...state.helioscopeDesignConfig,
        initData: parseDataFromImport(action.payload.data),
      };

      return { ...state, ...helioscopeDesignConfig, isLoading: false, isReady: true };
    },
    [ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_FAILURE](state, action) {
      const helioscopeDesignConfig = {
        ...state.helioscopeDesignConfig,
        ...action.payload,
        isLoading: false,
        isReady: false,
        error: true,
        isImportingNow: false,
      };

      return { ...state, ...helioscopeDesignConfig };
    },
    [ActionTypes.CLEAR_HELIOSCOPE_DESIGN_CONFIG_INIT_DATA]() {
      return {
        initData: [],
        isLoading: false,
        isReady: false,
        isImportingNow: false,
        error: false,
        successfullyImportedProjectId: '',
      };
    },
    [ActionTypes.UPDATE_HELIOSCOPE_DESIGN_CONFIG_INIT_DATA](state, action) {
      const { field, value } = action.payload;
      return { ...state, initData: {...state.initData, [field]: value} };
    },
    [ActionTypes.IMPORT_HELIOSCOPE_PROJECT](state) {
      return { ...state, isImportingNow: true };
    },
    [ActionTypes.IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT](state) {
      return { ...state, isImportingNow: true };
    },
    [ActionTypes.IMPORT_HELIOSCOPE_PROJECT_FAILED](state) {
      return { ...state, isImportingNow: false };
    },
    [ActionTypes.IMPORT_HELIOSCOPE_PROJECT_SUCCESS](state, action) {
      const projectId = action.payload.projectData.pk;
      return { ...state, successfullyImportedProjectId: projectId };
    },
  }),
};
