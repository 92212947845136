import { ActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';
import natsort from 'natsort';

export const projectsListState: projectsListState = {
  projects: [],
  projectsToShow: [],
  filter: '',
  isLoading: false,
  isReady: true,
  errorType: null,
  currentPage: 1,
  sortMethod: 'asc',
  sortedField: 'last_modified',
};

export default {
  projectsList: createReducer(projectsListState, {
    [ActionTypes.FETCH_PROJECTS_LIST_REQUEST](state) {
      const projectsList = { ...state.projectsList, isLoading: true };

      return { ...state, ...projectsList };
    },
    [ActionTypes.FETCH_PROJECTS_LIST_SUCCESS](state, action) {
      const projects = sortAsc(action.payload.data, state.sortedField);

      return { ...state, projects, projectsToShow:  projects.slice(0, 10), isLoading: false, isReady: true };
    },
    [ActionTypes.FETCH_PROJECTS_LIST_FAILURE](state, action) {
      const projectsList = {
        ...state.projectsList,
        ...action.payload,
        isLoading: false,
        isReady: false,
        errorType: action.payload.errorType,
      };

      return { ...state, ...projectsList };
    },
    [ActionTypes.FETCH_PROJECTS_LIST_FAILURE](state, action) {
      const projectsList = {
        ...state.projectsList,
        isLoading: false,
        isReady: false,
        errorType: action.payload.errorType,
      };

      return { ...state, ...projectsList };
    },
    [ActionTypes.PROJECTS_LIST_SET_PROJECTS_TO_SHOW](state, action) {
      const projectsList = {
        ...state.projectsList,
        ...action.payload,
      };

      return { ...state, ...projectsList };
    },
    [ActionTypes.PROJECTS_LIST_FILTER_PROJECTS](state, action) {
      const projectsList = {
        ...state.projectsList,
        filter: action.payload.filter,
      };

      return { ...state, ...projectsList };
    },
    [ActionTypes.CLEAR_HELIOSCOPE_PROJECT_LIST]() {
      return {
        projects: [],
        projectsToShow: [],
        filter: '',
        isLoading: false,
        isReady: true,
        errorType: null,
        sortMethod: 'asc',
        sortedField: 'project_name',
      };
    },
    [ActionTypes.SET_HELIOSCOPE_PROJECT_LIST_SORTING](
      state: projectsListState,
      action,
    ) {
      const { sortMethod, projects } = state;
      const { sortedField } = action.payload;

      let sortedProject;

      const sortBy = sortMethod === 'asc' && sortedField === state.sortedField ? 'desc' : 'asc';

      if (sortBy === 'asc') {
        sortedProject = sortAsc(projects, sortedField);
      } else {
        sortedProject = sortDesc(projects, sortedField);
      }

      return {
        ...state,
        sortMethod: sortBy,
        sortedField,
        projects: sortedProject,
      };
    },
  }),
};

function sortAsc(projects, sortField: string) {
  if (sortField === 'last_modified') {
    return sortDateAsc(projects);
  }

  return sortFieldAsc(projects, sortField);
}

function sortDesc(projects, sortField) {
  if (sortField === 'last_modified') {
    return sortDateDesc(projects);
  }

  return sortFieldDesc(projects, sortField);
}

function sortDateAsc(projects) {
  return projects.sort((a, b) =>
    (`${a['last_modified']}`).localeCompare(b['last_modified']),
  );
}

function sortDateDesc(projects) {
  return projects.sort((a, b) =>
    (`${b['last_modified']}`).localeCompare(a['last_modified']),
  );
}

function sortFieldAsc(projects, sortedField) {
  const sorter = natsort();
  return projects.sort((a, b) => (sorter(a[sortedField], b[sortedField])));
}

function sortFieldDesc(projects, sortedField) {
  const sorter = natsort();
  return projects.sort((a, b) => (sorter(b[sortedField], a[sortedField])));
}
