import * as PIXI from 'pixi.js';
import { state } from '__common/store';
import { isBlankMap } from '__common/constants/map';

function roofEdges(coordsInPx: pixelPoint[]): PIXI.Graphics {
  const { roofsSelector: { mapType } } = state();
  const COLOR_BLACK = 0x00000;
  const COLOR_WHITE = 0xFFFFFF;
  const lineColor = isBlankMap(mapType) ? COLOR_BLACK : COLOR_WHITE;
  const edges: PIXI.Graphics = new PIXI.Graphics();
  const dots: PIXI.Graphics[] = [];
  const newCoords = coordsInPx.reduce<number[]>((acc, coord) => {
    acc.push(coord.x);
    acc.push(coord.y);
    dots.push(drawEndLineDot(coord.x, coord.y));
    return acc;
  },                                            []);
  const poly = new PIXI.Polygon(newCoords);
  edges.interactive = true;
  edges.hitArea = poly;
  edges.lineStyle(0.2, lineColor);
  edges.beginFill(COLOR_WHITE, 0.25);
  edges.drawPolygon(newCoords.concat(newCoords[0]).concat(newCoords[1]));
  edges.id = 'roofEdges';
  dots.map(dot => edges.addChild(dot));
  edges.zIndex = 99999;
  return edges;
}

function drawEndLineDot(x, y) {
  const dot: PIXI.Graphics = new PIXI.Graphics();
  dot.beginFill(0xFFFFFF, 1);
  dot.drawCircle(x, y, 0.1);
  dot.id = 'endLineRoofEdgeDot';
  dot.endFill();
  return dot;
}

export default roofEdges;
