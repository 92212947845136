import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { drawResidentialZonesHelper } from './windZonesCollisionsDetection';
import { isEdgeLargerThanWindZoneDim } from '../roofZones';

const ANGLE_DEGRESS_LIMIT = 135;

// x0, y0 first point
// x1, y1 shared point
// x2, y2 second point

export const shouldUpgradeResidentalASCE716Zone = (
  x0: number, 
  y0: number, 
  x1: number, 
  y1: number, 
  x2: number, 
  y2: number, 
  checkPointDistance: number, 
  roofEdges: PolygonInterface, 
  roofZoneDistance: number,
): boolean => {
  // [p] - panel, x - middle point
  // . . . - line between p0 and p1
  //
  //          x1,y1     x2,y2
  //           ○ --------○        . p1 
  //          /  [p]     |    . 
  //         /           |.
  //        /        x - |middle point
  // x0,y0 ○-----.-------○ 
  //      /  .
  //  p0 .
  const { p0, p1 } = findNeighbouringPoints(x0, y0, x1, y1, x2, y2, checkPointDistance);
  const isLongEnough = isEdgeLargerThanWindZoneDim({ x: x0, y: y0 }, { x: x1, y: y1 }, { x: x2, y: y2 }, roofZoneDistance);
  return checkAngleBetweenTwoNeightbouringPoints(p0, p1, checkPointDistance, roofEdges) && isLongEnough;
}; 

export const checkAngleBetweenTwoNeightbouringPoints = (
  p0: pixelPoint, 
  p1: pixelPoint, 
  checkPointDistance: number, 
  roofEdges: PolygonInterface,
): boolean => {
  const middlePoint = getMiddlePoint(p0.x, p0.y, p1.x, p1.y);  
  
  const ANGLE_RADIANS_LIMIT = degreesToRadians(ANGLE_DEGRESS_LIMIT);

  const isMiddlePointOnRoof = roofEdges.containsPoint({ x: middlePoint.x, y: middlePoint.y });

  const GREEN = 0x00FF00; // #00FF00
  const YELLOW = 0xFFFF00; // #FFFF00
  const PINK = 0xf11efc; // #f11efc;
  const PURPLE = 0x4d16ff; // #4d16ff;
  roofEdges.points.map(edge => drawResidentialZonesHelper(edge, GREEN, 'roof'));
  drawResidentialZonesHelper(middlePoint, YELLOW, 'middlePoint');
  drawResidentialZonesHelper(p0, PINK, 'firstNeighborPoint');
  drawResidentialZonesHelper(p1, PURPLE, 'secondNeighborPoint');

  if (!isMiddlePointOnRoof) {
    return false;
  }

  const lengthBetweenPoints = getLengthBetweenPoints(p0.x, p0.y, p1.x, p1.y);
  //  d - is an arbitrary constant
  //  Z - is length between points.
  //           ○A
  //      d   / \  d
  //         /   \
  //       B○ --- ○C
  //           Z 
  // knowing the angle ∠CAB (135deg) using the cosine law we check what is the maximum Z to be below 135deg limit.
  // https://www.mathsisfun.com/algebra/trig-cosine-law.html
  // Z² = d² + d² - 2*d*d*cos(∠CAB)
  // Z = sqrt(2*d² - 2*d²*cos(∠CAB))
  // Z = sqrt(2*d² * (1 - cos(∠CAB)))
  const minDistance = Math.sqrt(2 * (checkPointDistance ** 2) * (1 - Math.cos(ANGLE_RADIANS_LIMIT)));

  return lengthBetweenPoints < minDistance;
};

const getMiddlePoint = (x1: number, y1: number, x2: number, y2: number) => { 
  return ({ x: (x1 + x2) / 2, y: (y1 + y2) / 2 });
};

const getLengthBetweenPoints = (x1: number, y1: number, x2: number, y2: number) => { 
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
};

const unitVector = (x1: number, y1: number, x2: number, y2: number) => { 
  return ({ x: (x2 - x1) / getLengthBetweenPoints(x1, y1, x2, y2), y: (y2 - y1) / getLengthBetweenPoints(x1, y1, x2, y2) });
};

const newPointInNeighbourhood = (x1: number, y1: number, x2: number, y2: number, distance: number) => {
  const x = x1 + unitVector(x1, y1, x2, y2).x * distance;
  const y = y1 + unitVector(x1, y1, x2, y2).y * distance;

  return {
    x,
    y,
  };
};

export const findNeighbouringPoints = (x0: number, y0: number, x1: number, y1: number, x2: number, y2: number, distance: number) => {
  const p0 = newPointInNeighbourhood(x1, y1, x0, y0, distance);
  const p1 = newPointInNeighbourhood(x1, y1, x2, y2, distance);
  return {
    p0,
    p1,
  };
};
