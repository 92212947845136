import { START_EXPOSURE_RECALCULATION, RECHECK_PANELS_EXPO } from '../panelsActions';
import { dispatch, state } from '__common/store';
import { reCheckPanelsExposure } from '__common/components/exposure/exposureCheckInsideOfEditor';
import hash from 'object-hash';
import { canUseExposure } from '__common/components/exposure/exposureHelper';
import { isAscender } from '__common/constants/products';

export const launchRecheckPanelExposure = (
  panelsToRecheck: number[],
  roofId: number,
  cb?: Function,
) => {
  const {
    projectConfiguration: {
      projectEnvConfig: { building_code },
      productId,
    },
    panels: { panels, exposureRecaluclationHash },
  } = state();


  if (!canUseExposure(building_code, productId) || isAscender(productId)) {
    return cb();
  }

  const panelsHash = hash(panels);

  if (panelsHash !== exposureRecaluclationHash) {
    dispatch(START_EXPOSURE_RECALCULATION());
    setTimeout(() => {
      dispatch(RECHECK_PANELS_EXPO(panelsToRecheck, roofId, productId));
      if (cb) {
        cb();
      }
    },         1000);
  } else if (cb) {
    cb();
  }
};

export const checkPanelsExposure = (
  panelsOnRoof: panelInState[],
  panelsToRecheck: number[],
  roofId: number,
) => {
  const { panels, forceExposureChanged } = reCheckPanelsExposure(
    panelsOnRoof,
    panelsToRecheck,
    roofId,
  );

  return panels.map(panel => {
    if (forceExposureChanged[panel.id] !== undefined) {
      panel.exposedNeighbour = forceExposureChanged[panel.id];
    }
    return panel;
  });
};
