import { MeasurementToolActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';

export const measurementToolState: measurementToolState = {
  startingPoint: null,
  endingPoint: null,
  enabled: false,
};

export default {
  measurementTool: createReducer(measurementToolState, {
    [MeasurementToolActionTypes.TURN_ON_MEASUREMENT_TOOL](state: measurementToolState) {
      return { ...state, enabled: true };
    },
    [MeasurementToolActionTypes.TURN_OFF_MEASUREMENT_TOOL](state: measurementToolState) {
      return { ...state, startingPoint: null, endingPoint: null, enabled: false };
    },
    [MeasurementToolActionTypes.ADD_STARTING_POINT](state: measurementToolState, action) {
      const startingPoint = action.payload;
      return { ...state, startingPoint };
    },
    [MeasurementToolActionTypes.ADD_ENDING_POINT](state: measurementToolState, action: action & { payload: pixelPoint }) {
      const endingPoint = action.payload;
      return { ...state, endingPoint };
    },
    [MeasurementToolActionTypes.START_NEW_MEASUREMENT](state: measurementToolState) {
      return { ...state, startingPoint: null, endingPoint: null };
    },
  }),
}

