import { ScaleActionTypes } from 'actionsConstants';


export function START_SCALE() {
  return {
    type: ScaleActionTypes.START_SCALE,
  };
}

export function SET_START_POS(startPos: { x: number, y: number }) {
  return {
    type: ScaleActionTypes.SET_START_POS,
    payload: {
      startPos,
    },
  };
}

export function SET_END_POS(endPos: { x: number, y: number }) {
  return {
    type: ScaleActionTypes.SET_END_POS,
    payload: {
      endPos,
    },
  };
}

export function TURN_OFF_SCALE() {
  return {
    type: ScaleActionTypes.TURN_OFF_SCALE,
  };
}
