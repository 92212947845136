import keyMirror from 'keymirror';

export const CursorActionTypes = keyMirror({
  MOVE_CURSOR: undefined,
  SHOW_CURSOR: undefined,
  HIDE_CURSOR: undefined,
  SET_PORTRAIT_CURSOR: undefined,
  SET_LANDSCAPE_CURSOR: undefined,
  FLIP_CURSOR: undefined,
  UNLOCK_CURSOR: undefined,
  LOCK_CURSOR: undefined,
  SET_CURSOR_OVERLAP_PANEL: undefined,
  UNSET_CURSOR_OVERLAP_PANEL: undefined,
  RESET_CURSOR_STATE: undefined,
  CHANGE_SYSTEM_CURSOR: undefined,
  RESET_SYSTEM_CURSOR: undefined,
  SET_CURSOR_OVER_PANEL: undefined,
  UNSET_CURSOR_OVER_PANEL: undefined,
  SET_BLACK_CURSOR: undefined,
  SET_YELLOW_CURSOR: undefined,
  SET_ORANGE_CURSOR: undefined,
  SET_BLUE_CURSOR: undefined,
  SET_GREEN_CURSOR: undefined,
  SET_ASCE_7_16_SALMON_CURSOR: undefined,
  SET_ASCE_7_16_GREEN_CURSOR: undefined,
  SET_ASCE_7_16_YELLOW_CURSOR: undefined,
  SET_ASCE_7_16_BLACK_CURSOR: undefined,
  SET_ASCE_7_16_BROWN_CURSOR: undefined,
  SET_ASCE_7_16_VIOLET_CURSOR: undefined,
  SET_ASCE_7_16_ORANGE_CURSOR: undefined,
  SET_ASCE_7_16_SNAPPING_CURSOR: undefined,
  SET_OBSTRUCTION_ZONE_CURSOR: undefined,
});
