import { FaqActionTypes } from 'actionsConstants';

export function SHOW_FAQ_WINDOW(faqData) {
  return {
    type: FaqActionTypes.SHOW_FAQ_WINDOW,
    payload: {
      faqData
    }
  };
}

export function HIDE_FAQ_WINDOW() {
  return {
    type: FaqActionTypes.HIDE_FAQ_WINDOW,
  };
}

export function RESET_FAQ_WINDOW() {
  return {
    type: FaqActionTypes.RESET_FAQ_WINDOW,
  };
}

export function POPULATE_FAQ_DATA() {
  return {
    type: FaqActionTypes.POPULATE_FAQ_DATA,
  };
};