import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import Loader from '__common/components/LoaderComponent';
import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_CLEAR } from 'actions';
import { connect } from 'react-redux';

type Props = {
  dispatch: Function;
  changePassword: changePasswordState;
};

type State = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  passwordNotMatch: boolean;
};

class ChangePassword extends React.Component<Props, State> {
  state = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    passwordNotMatch: false,
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CHANGE_PASSWORD_CLEAR());
  }

  setOldPassword = (oldPassword: string) => {
    this.setState({ oldPassword });
  }

  setNewPassword = (newPassword: string) => {
    this.setState({ newPassword });
  }

  setNewPasswordConfirmation = (newPasswordConfirmation: string) => {
    this.setState({ newPasswordConfirmation });
  }

  restoreNotMatch = () => {
    const { passwordNotMatch } = this.state;

    if (passwordNotMatch) {
      this.setState({ passwordNotMatch: false });
    }
  }

  changePassword = () => {
    const { dispatch } = this.props;
    const { oldPassword, newPassword, newPasswordConfirmation } = this.state;

    if (newPassword !== newPasswordConfirmation) {
      this.setState({ passwordNotMatch: true });
    } else {
      dispatch(
        CHANGE_PASSWORD(oldPassword, newPassword, newPasswordConfirmation),
      );
    }
  }

  passwordNotMatch = () => {
    const { passwordNotMatch } = this.state;
    if (passwordNotMatch) {
      return (
        <>
          <div className="input-warning">Password does not match</div>
        </>
      );
    }

    return null;
  }

  content = () => {
    const {
      changePassword: { error, sending, success },
    } = this.props;
    if (error) {
      return (
        <>
          <div className="maintitle">CHANGE PASSWORD</div>
          <div>Cannot change password</div>
        </>
      );
    }

    if (sending) {
      return (
        <>
          <div className="maintitle">CHANGE PASSWORD</div>
          <Loader />
        </>
      );
    }

    if (success) {
      return (
        <>
          <div className="maintitle">CHANGE PASSWORD</div>
          <div>Your password has been changed</div>
        </>
      );
    }

    return this.changePasswordForm();
  }

  changePasswordForm = () => {
    const { oldPassword, newPassword, newPasswordConfirmation } = this.state;

    return (
      <>
        <div className="maintitle">CHANGE PASSWORD</div>
        {this.passwordNotMatch()}
        <form>
          <div className="input-label">Old password:</div>
          <TextField
            id="oldpassword"
            type="password"
            placeholder="Old password"
            onChange={this.setOldPassword}
            value={oldPassword}
          />
          <div className="input-label">Password:</div>
          <TextField
            id="newpassword"
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={this.setNewPassword}
          />
          <div className="input-label">Confirm password:</div>
          <TextField
            id="newpasswordconfirmation"
            type="password"
            placeholder="New password confirmation"
            value={newPasswordConfirmation}
            onChange={this.setNewPasswordConfirmation}
          />
          <Button
            flat={true}
            type="button"
            className="form_button"
            id="change_password_button"
            onClick={this.changePassword}
          >
            CHANGE PASSWORD
          </Button>
        </form>
      </>
    );
  }

  render() {
    return (
      <div className="app-content change-password">
        <Header />
        <HeaderImage content={this.content()} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    changePassword: appState.changePassword,
  };
}

export default connect(mapStateToProps)(ChangePassword);
