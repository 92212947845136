import { metersToInches } from '__common/calculations/metersToInches';
import { getRoofZoneNumberFromClassification } from '__editor/panelsEditor/components/roofZones/roofZoneNumbers';
import { calculateRmZonesMessage, CALCULATE_RM_ZONES, calculateRmZonesMessageResult } from '../../../../../../workers/shared/message';
import { overrideZone } from '__editor/panelsEditor/components/panels/utils/overrideRmZones';
import { getWorker } from '__common/worker/getWorker';
import { isRMDT } from '__common/constants/products';
import { captureException } from '__common/utils/sentry';
import flatten from 'lodash/flatten';
import { Store } from 'redux';
import groupBy from 'lodash/groupBy';
import { setPanelRoofZone } from '../panels';
import { getBuildingHeight } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import _ from 'lodash';
import store from '__common/store';

export const recalculatePanelZones = (store: Store<appState>): Promise<panelInState[]> => {
  return new Promise((resolve,reject) => {
    const {
      panels: {panels},
      background: {
        roofEdges,
        cords,
        zoom,
        metersPerPixel,
        selectedRoofId,
        rotationDegrees,
        bgXY,
      },
      projectConfiguration: {
        productId,
        projectEnvConfig: {
          building_type,
        },
      },
      settings: {
        panelWidth,
        panelHeight,
      },
      tiltedRoof: {
        roofPitch,
      },
    } = store.getState();
    if(!panels ||  panels.length === 0) {
      return resolve([]);
    }
    const buildingHeightFt = getBuildingHeight();

    let panels_ = _.cloneDeep(panels);

    panels_ = panels_.map(panel => {
      setPanelRoofZone(
        panel, 
        roofEdges, 
        cords, 
        zoom, 
        buildingHeightFt, 
        metersPerPixel, 
        rotationDegrees, 
        bgXY.x, 
        bgXY.y, 
        panelWidth, 
        panelHeight, 
        productId, 
        building_type, 
        roofPitch,
        selectedRoofId,
      );
      return panel;
    });
    setTimeout(() => resolve(panels_), 0)
  });
};


export const recalculateRmZones = (
  productId: number, 
  rowSpacing: number, 
  columnSpacing: number, 
  metersPerPixel: number, 
  panels: panelInState[], 
  roofId: number
  ): Promise<panelInState[]> => {
  return new Promise((resolve,reject) => {
    if(!panels ||  panels.length === 0) {
      return resolve([]);
    } 
    // this can be called from map view, before entering the panels editor.
    // If so, then the panelWidth in settings has a default value.
    const panelWidthPx = panels[0].width;
    const panelHeightPx = panels[0].height;
  
    const createGridSettings = {
      columnSpacingMeters: columnSpacing,
      rowSpacingMeters: rowSpacing,
      metersPerPixel,
      productId,
      // the logic is based on the backend. 
      // This is why for zone classification width is swapped with height
      // and for grid creation is not.
      moduleLengthMeters: panelHeightPx * metersPerPixel,
      moduleWidthMeters: panelWidthPx * metersPerPixel,
    };

    const eastWestEdgeDepth = getEastWestEdgeDepth(productId);
    const northSouthEdgeDepth = getNorthSouthEdgeDepth(productId);
    const classificationSettings = {
      eastWestEdgeDepth,
      northSouthEdgeDepth,
      // RM5 and 10 panels are landscape, 
      // therefore we swap the length with width
      // (panel width used in the app is not the panel's real width, but rather
      // its dimension in east-west direction).
      // RMDT are in portrait position.
      moduleLength: isRMDT(productId) ? 
        metersToInches(panelHeightPx * metersPerPixel) :
        metersToInches(panelWidthPx * metersPerPixel),
      moduleWidth: isRMDT(productId) ?
        metersToInches(panelWidthPx * metersPerPixel) :
        metersToInches(panelHeightPx * metersPerPixel),
      rowSpacing: metersToInches(rowSpacing),
    };

    const panelsByGroupId = Object.entries(groupBy(panels, 'groupId'));
    const promise = new Promise<panelInState[]>((resolve, reject) => {
      const message: calculateRmZonesMessage = {
        type: CALCULATE_RM_ZONES,
        payload: {
          classificationSettings,
          gridSettings: createGridSettings,
          panelsByGroupId,
        },
      };
  
      
  
      const worker = getWorker();

      worker.onmessage = getWorkerMessageHandler(
        panels,
        productId,
        resolve,
        reject,
        worker,
        store,
        roofId,
      );
      worker.postMessage(message);
    });

    promise.then( ( values ) => {
      resolve(flatten(values));
    }).catch(() => resolve([]));
  });
};

const getWorkerMessageHandler = (
  panels: panelInState[], 
  productId: number,
  resolve: (panels: panelInState[]) => void,
  reject: (error: any) => void,
  worker: Worker,
  store: Store<appState>,
  roofId: number,
) => (result: any) => {
  const data = <calculateRmZonesMessageResult>result.data;
  if (data?.error?.length > 0) {
    console.warn('rm zones calculate returned error', data.error);
    captureException(new Error(data.error));
    worker.terminate();
    return reject(data.error);
  }

  let updatedPanels: panelInState[];

  if (isRMDT(productId)) {
    // we have to also update siblings
    updatedPanels = panels.map((panel, index, allPanels) => {
      const classification = data.classification[panel.id];

      if (classification) {
        return {
          ...panel,
          roofZone: getRoofZoneNumberFromClassification(classification),
        };
      } 

      const sibling = allPanels.find(p => p.siblingId === panel.siblingId && p.id !== panel.id);

      if (sibling) {
        const siblingClassification = data.classification[sibling.id];
        return {
          ...panel,
          roofZone: getRoofZoneNumberFromClassification(siblingClassification),
        };
      }

      return panel;
    }).map(overrideZone(store, roofId));
  } else {
    updatedPanels = panels.map((panel) => {
      return {
        ...panel,
        roofZone: getRoofZoneNumberFromClassification(data.classification[panel.id]),
      };
    }).map(overrideZone(store, roofId));
  }

  resolve(updatedPanels);
  worker.terminate();
};

export const getNorthSouthEdgeDepth = (productId: number): number => {
  const MIN_DEPTH = isRMDT(productId) ? 4:3;

  return MIN_DEPTH;
};

export const getEastWestEdgeDepth = (productId: number): number => {
  const MIN_DEPTH = isRMDT(productId) ? 6:3;
  return  MIN_DEPTH;
};
