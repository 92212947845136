import * as React from 'react';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import { clearLoginError, USER_LOGIN_REQUEST } from 'actions';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import config from 'config';

const userDetails = {
  username: '',
  password: '',
};

type Props = {
  user: any,
  dispatch: Function,
  history: any,
};

export default class LoginPage extends React.Component<Props> {

  constructor(props) {
    super(props);
    if (config.loginPageRedirection) {
      window.location = config.loginPageRedirection;
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearLoginError());
  }

  handleKeyPressOnFormInput = (e, dispatch) => {
    if (e.key === 'Enter') {
      dispatch(USER_LOGIN_REQUEST(userDetails.username, userDetails.password));
    }
  }

  loginError = () => {
    const { user: { loginError } } = this.props;

    if (loginError) {
      return (
        <div className="input-warning">
          {loginError}
        </div>
      );
    }

    return null;
  }

  loginInfo = () => {
    return (
      <div className="input-warning" style={{ lineHeight: 1.2 }}>
        Our login has changed. <br /> Please use the email address associated with your account <br /> instead of the username you were previously using
      </div>
    );
  }

  loginForm() {
    const { dispatch } = this.props;

    return (
      <form id="login-form">
        {this.loginError()}
        <div className="input-label">
          Email:
        </div>
        <TextField
          id="username"
          type="text"
          onKeyDown={e => this.handleKeyPressOnFormInput(e, dispatch)}
          onChange={(userName: any) => {
            userDetails.username = userName;
          }}
        />
        <div className="input-label">
          Password:
        </div>
        <TextField
          id="password"
          type="password"
          onKeyDown={e => this.handleKeyPressOnFormInput(e, dispatch)}
          onChange={(pass: any) => {
            userDetails.password = pass;
          }}
        />
        <Button
          flat={true}
          type="button"
          className="form_button"
          id="login_button"
          onClick={() => {
            dispatch(USER_LOGIN_REQUEST(userDetails.username, userDetails.password));
          }
          }
        >
          {<FormattedMessage id="login" />}
        </Button>
      </form>
    );
  }

  content = () => {
    return (
      <>
        <div className="maintitle">LOGIN TO YOUR ACCOUNT</div>
        {this.loginInfo()}
        {this.loginForm()}
        {this.links()}
      </>
    );
  }

  links = () => {
    return (
      <div className="links">
        <div className="link forgot-password"><Link to="/forgotpassword">Forgot password?</Link></div>
        <div className="link register"><Link to="/registration">Not a member? Register!</Link></div>
      </div>
    );
  }

  render() {
    return (
      <div className="app-content login-page">
        <Header />
        <HeaderImage content={this.content()} />
        <Footer />
      </div>
    );
  }
}
