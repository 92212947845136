import { hitApi, ObservableAjax } from '__common/utils/api';
import { ModuleSelectorActionTypes } from 'actionsConstants';

import {
  FETCH_MFGS_SUCCESS,
  FETCH_MFG_MODELS_REQUEST,
  FETCH_MFGS_MODELS_SUCCESS,
  SET_MFG,
  SET_MODEL,
} from 'actions';
import { state } from '__common/store';
import { isSfmFamilyName, products } from '__common/constants/products';
import { Store } from 'redux';

export function fetchMfgAndModelRequest(action$) {
  return action$.ofType(ModuleSelectorActionTypes.FETCH_MFGS_AND_MODELS_REQUEST)
    .switchMap(action => ObservableAjax({
      takeUntil: action$.ofType(ModuleSelectorActionTypes.FETCH_MFGS_SUCCESS),
      onSuccess: [
        FETCH_MFGS_SUCCESS,
        SET_MFG.bind(null, action.payload.module),
        FETCH_MFG_MODELS_REQUEST.bind(null, getCorrectProduct(action.payload.productId), action.payload.module, action.payload.selectedModelId, action.payload.customModel,action.payload.version, action.payload.inputUnit),
      ],
      onErrorMessage: 'Cannot fetch modules and models',
      link: hitApi('get', `api/v1/tool/modules/?product=${getCorrectProduct(action.payload.productId)}${setSameDims()}`),
    }),
    );
}
export function fetchMfgModelsRequest(action$, store: Store<appState>) {
  return action$.ofType(ModuleSelectorActionTypes.FETCH_MFG_MODELS_REQUEST)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(ModuleSelectorActionTypes.FETCH_MFG_MODELS_REQUEST),
        onSuccess: [FETCH_MFGS_MODELS_SUCCESS, SET_MODEL.bind(null, action.payload.selectedModelId, action.payload.customModel, products[action.payload.productId], action.payload.inputUnit, false)],
        onErrorMessage: 'Cannot fetch models',
        link: hitApi('get', `api/v1/tool/models/?product=${getCorrectProduct(action.payload.productId)}&module=${action.payload.module}&version=${action.payload.version}`),
      }),
    );
}

export function populateModelsByModule(action$) {
  return action$.ofType(ModuleSelectorActionTypes.POPULATE_MODELS_BY_MODULE)
    .switchMap(action => {
      return ObservableAjax({
        takeUntil: ModuleSelectorActionTypes.POPULATE_MODELS_BY_MODULE,
        onSuccess: FETCH_MFGS_MODELS_SUCCESS,
        onErrorMessage: 'Cannot get models',
        link: hitApi('get', `api/v1/tool/models/?product=${getCorrectProduct(action.payload.productId)}&module=${action.payload.mfg}&version=${action.payload.version}`),
      });
    });
}

function getCorrectProduct(productId: string) {
  if (!productId) return '';
  if (isSfmFamilyName(productId)) {
    return 'sfm_enhanced';
  }

  if (productId === 'solarmount_2_tilt' || productId === 'adjustable_tilt_system' || productId === 'nxt_tilt') {
    return 'solarmount_2';
  }

  return productId;
}

function setSameDims() {
  const { moduleSelector: { sameDims: { width, length } } } = state();

  if (width && length) {
    return `&model_width=${width}&model_length=${length}`;
  }

  return '';
}
