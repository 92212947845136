import * as React from 'react';
import AppHistory from '__common/modules/history';
import SVG from 'react-inlinesvg';

type Props = {
  action?: Function,
  dispatch?: Function,
  url?: string,
  onClick?: Function,
};

export default class BackButton extends React.Component<Props, {}> {
  onClick = () => {
    const { dispatch, url, action, onClick } = this.props;

    if (action) {
      dispatch(action());
    }

    if (onClick) {
      onClick();
    }

    if (url) {
      AppHistory.push(`${url}`);
    }
  }

  render() {
    return (
      <div role="button" onClick={this.onClick} className="back-button">
      <SVG src={require('assets/media/icons/backward_arrow_icon.svg')} />
      BACK
    </div>);
  }
}
