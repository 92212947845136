import { state } from '__common/store';
import {
  getMapScale,
  getStage,
  } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorStage/bingMapsRoofsSelectorStage';
import { measurementToolModel } from '__editor/googleMapsRoofsSelector/models/measurementTool';

let measurementTool;

export const renderBingMapsMeasurementTool = () => {
  const { measurementTool: { startingPoint, endingPoint }, projectConfiguration : { productId, inputUnit } } = state();
  const stage = getStage();
  measurementTool = measurementToolModel(startingPoint, endingPoint, (getMapScale()), productId, inputUnit);
  stage.removeChildren();
  stage.addChild(measurementTool);
};