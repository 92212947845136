import * as PIXI from 'pixi.js';
import { asce716RestrictedZoneDistance, getRoofZonesAreasMemoized as getRoofZonesAreas, RoofZonesAreas, getObstructionsZonesAreas, getLZonesAreasMemoized as getLZonesAreas, flatRoofDistances, exposedDistance, isEdgeLargerThanWindZoneDim, setBackRestrictedZoneDistance } from './roofZones';
import {getWindZoneDistance} from '__editor/panelsEditor/components/roofZones/utils/windZoneDistance';
import { roofZoneLine, roofZoneCorner, getResidentialZoneColor } from '__editor/panelsEditor/models/roofZone';
import { state } from '__common/store';
import { getStage, updateLayersOrder } from '../stage/stage';
import { NEAR_OBSTRUCTION_COLOR, ON_THE_CORNER_COLOR, NEAR_ONE_EDGE_COLOR, MIDDLE_ROOF_COLOR } from '__editor/panelsEditor/models/utils/panelColor';
import { isASCE716FlatRoof } from '../roofTypes/roofTypes';
import { checkZoneAngles, IAREA_WITH_INDEX } from './utils/windZonesCollisionsDetection';
import { createEdgeZonePolygon } from './roofZonesCollisionsHelper';
import { getRealRoofEdgesBoundsMemoized as  getRealRoofEdgesBounds } from '../background/background';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { detectGableAndHipZone } from './utils/gableAndHipRoofZonesCollisionsDetection';
import { isSMFamily, isSFMFamily, isRMIFIProduct, isMetalX, isNxtHorizon, isAscender, isRM10orRM10Evo, isSMTiltPR, isEcoFoot2Plus, isRM5, isRmGridflex10, isRMDT } from '__common/constants/products';
import { shouldUseVirtualRoofEdges, shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { isBlankMap } from '__common/constants/map';
import { getEdgeCollisionsFromCorner, getCollision } from '__editor/panelsEditor/components/roofZones/utils/collisions';
import { canShowRestrictedZone, canUseExposure, canShowSkewZone, shouldUseExposureZone } from '__common/components/exposure/exposureHelper';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { checkLineIntersection, drawdash } from './utils/setBackDistanceImplementationHelper';
import { getMaxSetBack } from './utils/setBackDistanceCollisionDetections';
import { isValidForRoofZone } from './isValidForRoofZone';
import { _isAscenderFamily } from '../cursor/utils/snapToGridHelper';
import { getRoofObstructions } from '../obstructions/obstructions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { getBuildingHeight } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { applyEcoFoot2PlusRM10andEvoSetbackChanges } from '__common/utils/versionCompare/versionCompare';
import { drawSkewZonesOnStage } from '../skewZones/drawSkewZonesOnStage';

const WIND_ZONE_NAME = 'windZone';
const RESTRICTED_ZONE_NAME = 'restrictedZone';
const SETBACK_DISTANCE = 'setbackDistance';
const OBSTRUCTION_ZONE_NAME = 'obstructionZone';
const EXP_ZONE_NAME = 'exp';
const L_ZONE = 'l zone';

const FLAT_ROOF_ZONE = 'flat roof zone';

export const drawRoofZonesOnStage = () => {
  const stage = getStage();
  const {
    roofZones: {
      windZone,
      restrictedZone,
      expZone,
      skewZone,
      obstructionsZone,
    },
    roofsSelector: {
      mapType,
    },
    projectConfiguration: {
      productId,
      projectVersion,
      projectEnvConfig: {
        building_type,
        building_code,
        tilt,
      },
    },
    background: {
      metersPerPixel,
      selectedRoofId
    },
    drawingManager: { roofs },
    user: { isStaff },
  } = state();
  const buildingHeightFt = getBuildingHeight();

  if (isValidForRoofZone( productId, tilt ) && isASCE716FlatRoof(building_type)){
      stage.children = stage.children.filter((child: any) => child.id !== FLAT_ROOF_ZONE);

      stage.children = stage.children.filter((child: any) => child.id !== L_ZONE);

      stage.children = stage.children.filter((child: any) => child.id !== EXP_ZONE_NAME);

      stage.children = stage.children.filter((child: any) => child.id !== RESTRICTED_ZONE_NAME);

      const { distanceFromEdgeCorner, distanceFromEdge } = flatRoofDistances(buildingHeightFt, metersPerPixel);

      if (windZone) {
        drawLZone(stage, distanceFromEdgeCorner, distanceFromEdge);
        drawFlatRoofZone(stage, distanceFromEdgeCorner * 2, MIDDLE_ROOF_COLOR);
        drawFlatRoofZone(stage, distanceFromEdgeCorner, NEAR_ONE_EDGE_COLOR);
      }

      if (expZone && canUseExposure(building_code, productId) && shouldUseExposureZone(mapType)) {
        drawExpZonesOnStage(stage);
      }

      if (restrictedZone && canShowRestrictedZone(building_code)) {
        drawRestrictedZoneOnStage(stage);
      }
      return;
    }

    if (!isBlankMap(mapType) || shouldUseVirtualRoofEdges(mapType, productId, projectVersion)) {
      if (stage) {
        stage.children = stage.children.filter((child: any) => child.id !== WIND_ZONE_NAME);

        stage.children = stage.children.filter((child: any) => child.id !== EXP_ZONE_NAME);

        stage.children = stage.children.filter((child: any) => child.id !== RESTRICTED_ZONE_NAME);
      }

      if (windZone) {
        drawWindZoneOnStage(stage);
      }

      if (expZone && canUseExposure(building_code, productId) && shouldUseExposureZone(mapType)) {
        drawExpZonesOnStage(stage);
      }

      if (restrictedZone && canShowRestrictedZone(building_code)) {
        drawRestrictedZoneOnStage(stage);
      }
  }

  if (shouldUseSetBackDistance(mapType, productId, projectVersion)) {

    if (isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRmGridflex10(productId)) {
      if (stage) {
        stage.children = stage.children.filter((child: any) => child.id !== RESTRICTED_ZONE_NAME);
      }
  
      if (restrictedZone) {
        drawRestrictedZoneOnStage(stage);
      }
    }

    stage.children = stage.children.filter((child: any) => child.id !== SETBACK_DISTANCE);

    drawSetBackDistanceOnStage(stage);
  }

  if (stage) {
    stage.children = stage.children.filter((child: any) => child.id !== OBSTRUCTION_ZONE_NAME);
  }

  if (obstructionsZone) {
    drawObstructionZoneOnStage(stage);
  }

  stage.children = stage.children.filter(child => child?.id !== "skew zone")
  if (skewZone && isStaff && canShowSkewZone(productId, mapType, projectVersion))
    drawSkewZonesOnStage(roofs[selectedRoofId].skewEdgesPixiCords);
};

const drawFlatRoofZone = (stage: PIXI.Container, distance: number, color: number) => {
  if (!stage) {
    return;
  }

  const {
    background: {
      selectedRoofId,
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      bgXY: { x, y },
      roofEdgesPixiCords,
    },
    tiltedRoof: { roofPitch },
    projectConfiguration: {productId, projectEnvConfig: {tilt}},
    roofsSelector: {mapType},
  } = state();

  const roofZonesContainer = flatRoofZoneContainer();

  const insideOfPolygon = true;

  const areas = getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: selectedRoofId,distance, zoom, rotationDegrees, bgOffSet: {x, y}, insideOfPolygon, productId, tilt, mapType,});

  drawZones(roofZonesContainer, areas, color);

  roofZonesContainer.children = updateLayersOrder(roofZonesContainer.children);

  stage.addChild(roofZonesContainer);
};

const drawWindZoneOnStage = (stage: PIXI.Container) => {
  if (!stage) {
    return;
  }

  const {
    background: {
      selectedRoofId,
      metersPerPixel,
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      bgXY: { x, y },
      roofEdgesPixiCords,
    },
    tiltedRoof: {
      roofPitch,
    },
    projectConfiguration: {
      productId, projectEnvConfig: {tilt},
    },
    roofsSelector: {mapType},
  } = state();
  const distance = getWindZoneDistance(metersPerPixel, productId);

  const roofZonesContainer = getWindZoneContainer();

  const insideOfPolygon = true;

  const areas = getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: selectedRoofId,distance, zoom, rotationDegrees, bgOffSet: {x, y}, insideOfPolygon, productId, tilt, mapType,});

  if (isSMFamily(productId) || isSFMFamily(productId) || isMetalX(productId) || isNxtHorizon(productId) || isAscender(productId) || isSMTiltPR(productId)) {
    drawWindZones(roofZonesContainer, areas, distance);
  } else {
    drawZones(roofZonesContainer, areas);
  }

  roofZonesContainer.children = updateLayersOrder(roofZonesContainer.children);

  stage.addChild(roofZonesContainer);
};

const drawRestrictedZoneOnStage = (stage: PIXI.Container) => {
  if (!stage) {
    return;
  }

  const {
    background: {
      selectedRoofId,
      metersPerPixel,
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      bgXY: { x, y },
      roofEdgesPixiCords,
    },
    projectConfiguration: {
      productId,
      projectVersion,
      inputUnit,
      projectEnvConfig : {
        setback_distance,
        tilt,
      }
    },
    tiltedRoof: {
      roofPitch,
    },
    roofsSelector: {mapType}
  } = state();

  const RESTRICTED_AREA_COLOR = 0xFF0000;

  const distance = applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) || isRmGridflex10(productId) ? setBackRestrictedZoneDistance(setback_distance, metersPerPixel, inputUnit) : asce716RestrictedZoneDistance(metersPerPixel, productId);

  const expZonesContainer = getRestrictedZoneContainer();

  const insideOfPolygon = true;

  const areas = getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: selectedRoofId,distance, zoom, rotationDegrees, bgOffSet: {x, y}, insideOfPolygon, productId, tilt, mapType,});

  drawZones(expZonesContainer, areas, RESTRICTED_AREA_COLOR);

  stage.addChild(expZonesContainer);
};

const drawObstructionZoneOnStage = (stage: PIXI.Container) => {
  if (!stage) {
    return;
  }

  const { background: { selectedRoofId, metersPerPixel, bgXY: { x, y } }, } = state();

  const obstructionContainer = getObstructionZoneContainer();

  const obstructionsForSelectedRoof = getRoofObstructions(selectedRoofId);
  const areas = getObstructionsZonesAreas({selectedRoofId, metersPerPixel, bgOffSet: {x, y}, obstructions: obstructionsForSelectedRoof});

  drawZones(obstructionContainer, areas, NEAR_OBSTRUCTION_COLOR);

  stage.addChild(obstructionContainer);
};

const drawLZone = (stage: PIXI.Container, distanceFromEdgeCorner: number, distanceFromEdge: number) => {
  const { background: { roofEdges, cords, zoom, rotationDegrees, bgXY: { x, y } }, tiltedRoof: { roofPitch } } = state();
  const zones = getLZonesAreas({roofEdges, roofCenter: cords, zoom, rotationDegrees, bgOffSet: {x, y}, distanceFromEdgeCorner, distanceFromEdge, roofPitch});

  zones.map((zone) => {
    const zonePoints = zone.map(points => {
      return new PIXI.Point(points.x, points.y);
    });
    const z = new PIXI.Graphics();
    z.beginFill(ON_THE_CORNER_COLOR, 1);
    z.id = L_ZONE;
    z.zIndex = 3;
    z.drawPolygon(zonePoints);
    z.endFill();

    stage.addChild(z);
  });
};

const drawZones = (container: PIXI.Container, areas: RoofZonesAreas, color?: number) => {
  const { corners, edges } = areas;
  const { projectConfiguration: { productId } } = state();
  corners.map(corner => {
    const roofZoneCornerModel = roofZoneCorner(corner, productId, corner.distance, corner.zone, color);
    container.addChild(roofZoneCornerModel);
  });

  edges.map((edge, index) => {
    const lineModel = roofZoneLine(edge, productId, color, index);
    container.addChild(lineModel);
  });
};

const drawWindZones = (container: PIXI.Container, areas: RoofZonesAreas, distance: number) => {
  const {
    background: {
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      bgXY: {
        x,
        y,
      },
    },
    tiltedRoof: {
      roofPitch,
    },
    projectConfiguration: {
      projectEnvConfig: {
        building_type,
        tilt,
      },
      productId,
    },
  } = state();
  const rotationRadians = degreesToRadians(rotationDegrees);

  const { corners, edges } = areas;

  const areaPolygon: IAREA_WITH_INDEX[] = edges.map(createEdgeZonePolygon);
  const roofEdgesPolygon = matchIndexes(getRealRoofEdgesBounds({roofEdges, roofCenter: cords, bgOffSet: {x, y}, zoom, rotationRadians, roofPitch, productId, tilt}));
  

  corners.map((corner) => {
    let firstPoint: pixelPoint;
    let middlePoint: pixelPoint;
    let thirdPoint: pixelPoint;

    if (corner.index === 0) {
      firstPoint = roofEdgesPolygon.points[roofEdges.length - 1];
    } else {
      firstPoint = roofEdgesPolygon.points[corner.index - 1];
    }

    middlePoint = roofEdgesPolygon.points[corner.index];

    if (corner.index === roofEdgesPolygon.points.length - 1) {
      thirdPoint = roofEdgesPolygon.points[0];
    } else {
      thirdPoint = roofEdgesPolygon.points[corner.index + 1];
    }

    const isLongEnough = isEdgeLargerThanWindZoneDim(firstPoint, middlePoint, thirdPoint, distance);

    const shouldUpgradeZone = checkZoneAngles(areaPolygon, [getCollision(corner.index)], roofEdgesPolygon, distance) && isLongEnough;

    const detectedZone =  isAscender(productId) && shouldUpgradeZone ? 
    {zone:3 as roofZoneNumber, edgeType: undefined}: 
    detectGableAndHipZone(
      getEdgeCollisionsFromCorner(corner),
      [getCollision(corner.index, corner.edge)],
      building_type,
      roofPitch,
      shouldUpgradeZone,
    );

    const color = getResidentialZoneColor(detectedZone.zone);
    const roofZoneCornerModel = roofZoneCorner(corner, productId, corner.distance, corner.zone, color);

    roofZoneCornerModel.zIndex = getRoofZoneZIndex(detectedZone.zone, true);
    container.addChild(roofZoneCornerModel);
  });

  edges.map((edge, index) => {
    const shouldUpgradeZone = checkZoneAngles(areaPolygon, [getCollision(edge[2].index)], roofEdgesPolygon, distance);

    const detectedZone = isAscender(productId) && shouldUpgradeZone ? 
    {zone:2 as roofZoneNumber, edgeType: undefined} :
    detectGableAndHipZone([getCollision(edge[2].index, edge[4])], [], building_type, roofPitch, shouldUpgradeZone);
    
    const color = getResidentialZoneColor(detectedZone.zone);
    const lineModel = roofZoneLine(edge, productId, color, index);

    lineModel.zIndex = getRoofZoneZIndex(detectedZone.zone, false);
    container.addChild(lineModel);
  });
};

const getRoofZoneZIndex = (zone: roofZoneNumber, isCorner: boolean): number => {
  // We want to display higher zone on top, because the panel is going to fall into the higher anyway.
  // And also the corner should cover overlapping edges.

  // note that we use 5 for beige zones for hip roofs, so I add 10 here to override it.
  return isCorner ? zone + 10 : zone;
};

// for some reason points in roof edges polygon are shifted by one position, e.g.
// corner with index 1 is a roof edges polygon point with index 2.
const matchIndexes = (roofEdgesPolygon: PolygonInterface): PolygonInterface => {
  const firstElement = roofEdgesPolygon.points.slice(0, 1);

  roofEdgesPolygon.points = roofEdgesPolygon.points.slice(1).concat([...firstElement]);
  return roofEdgesPolygon;
};

const flatRoofZoneContainer = () => {
  const windZoneContainer = new PIXI.Container();
  windZoneContainer.id = FLAT_ROOF_ZONE;
  windZoneContainer.zIndex = 1;
  return windZoneContainer;
};

const getWindZoneContainer = () => {
  const windZoneContainer = new PIXI.Container();
  windZoneContainer.id = WIND_ZONE_NAME;
  windZoneContainer.zIndex = 2;
  return windZoneContainer;
};

const getExpZonesContainer = () => {
  const expContainer = new PIXI.Container();
  expContainer.id = EXP_ZONE_NAME;
  expContainer.zIndex = 1.5;
  return expContainer;
};

const getRestrictedZoneContainer = () => {
  const resrictedZoneContainer = new PIXI.Container();
  resrictedZoneContainer.id = RESTRICTED_ZONE_NAME;
  resrictedZoneContainer.zIndex = 3;
  return resrictedZoneContainer;
};

const getSetbackDistanceContainer = () => {
  const setbackDistanceContainer = new PIXI.Container();
  setbackDistanceContainer.id = SETBACK_DISTANCE;
  setbackDistanceContainer.zIndex = 3;
  return setbackDistanceContainer;
};

const getObstructionZoneContainer = () => {
  const obstructionZoneContainer = new PIXI.Container();
  obstructionZoneContainer.id = OBSTRUCTION_ZONE_NAME;

  return obstructionZoneContainer;
};

const drawExpZonesOnStage = (stage: PIXI.Container) => {
  if (!stage) {
    return;
  }

  const {
    background: {
      selectedRoofId,
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      bgXY: { x, y },
      metersPerPixel,
      roofEdgesPixiCords,
    },
    tiltedRoof: {
      roofPitch,
    },
    projectConfiguration: {productId, projectEnvConfig: {tilt}},
   roofsSelector: {mapType,}
  } = state();

  const EXPOSURE_ZONE_COLOR = 0x0000ff;

  const distance = exposedDistance(metersPerPixel);

  const expZonesContainer = getExpZonesContainer();

  const insideOfPolygon = true;

  const areas = getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: selectedRoofId,distance, zoom, rotationDegrees, bgOffSet: {x, y}, insideOfPolygon, productId, tilt, mapType,});

  drawZones(expZonesContainer, areas, EXPOSURE_ZONE_COLOR);

  stage.addChild(expZonesContainer);
};

export const getSetbackPoints = (roofId?) => {
  const {
    background,
    tiltedRoof: { roofPitch },
    roofsSelector: { mapType },
    projectConfiguration: { productId, projectEnvConfig: { tilt } },
    drawingManager: { roofs },
  } = state();

  const insideOfPolygon = true;
  let input = { insideOfPolygon, productId, tilt, mapType, roofEdges: [], roofCenter: { lat: 0, lng: 0 }, rotationDegrees: 0, roofEdgesPixiCords: {}, roofPitch: '0', roofId: 1, distance: 0, zoom: 0, bgOffSet: { x: 0, y: 0 } };

  if(!background?.selectedRoofId && roofId) {
    const { bgRotation, roofPitch, zoom, metersPerPixel, marker, coords, bgOffset, roofEdgesPixiCords } = roofs[roofId];
    const distance = getSetBackDistance(metersPerPixel);

    input = { ...input, roofEdges: coords, roofCenter: marker, rotationDegrees: bgRotation, roofEdgesPixiCords, roofPitch, roofId, distance, zoom, bgOffSet: bgOffset };
  } else {
    const { selectedRoofId, roofEdges, zoom, rotationDegrees, bgXY: { x, y }, metersPerPixel, roofEdgesPixiCords } = background;
    const distance = getSetBackDistance(metersPerPixel);

    input = { ...input, roofCenter: roofs[selectedRoofId].marker, roofId: selectedRoofId, roofEdges, roofEdgesPixiCords, distance, roofPitch, zoom, rotationDegrees, bgOffSet: {x, y} };
  }

  const areas = getRoofZonesAreas(input);

  const edges = areas.edges;

  const intersectionPoints = [];

  const noOfEdges = edges.length;

  for (let i = 0; i < noOfEdges; i++) {

    let line1 = [];
    let line2 = [];

    const initialEdge = edges[0];

    line1 = [edges[i][2].x, edges[i][2].y, edges[i][3].x, edges[i][3].y];

    if (i === noOfEdges - 1) {
      line2 = [initialEdge[2].x, initialEdge[2].y, initialEdge[3].x, initialEdge[3].y];
    } else {
      line2 = [edges[i + 1][2].x, edges[i + 1][2].y, edges[i + 1][3].x, edges[i + 1][3].y];
    }

    const intersectionPoint = checkLineIntersection(line1, line2);

    intersectionPoints[i] = intersectionPoint;
  }
  return intersectionPoints;
}

const drawSetBackDistanceOnStage = (stage: PIXI.Container) => {
  if (!stage) {
    return;
  }

  const expZonesContainer = getSetbackDistanceContainer();
  const intersectionPoints = getSetbackPoints();
  
  const intersectionPointsLength = intersectionPoints.length;

  for (let i = 0; i < intersectionPointsLength; i++) {

    const initialPoint = intersectionPoints[0];

    const x0 = intersectionPoints[i].x;
    const y0 = intersectionPoints[i].y;
    let x1 = 0;
    let y1 = 0;

    if (i === intersectionPointsLength - 1) {
      x1 = initialPoint.x;
      y1 = initialPoint.y;
    } else {
      x1 = intersectionPoints[i + 1].x;
      y1 = intersectionPoints[i + 1].y;
    }
    const lineWidth = 0.80;
    const lineModel = drawdash(x0, y0, x1, y1, lineWidth);
    expZonesContainer.addChild(lineModel);
  }

  stage.addChild(expZonesContainer);
};


export const getSetBackDistance = (metersPerPixel: number) => {
  const { projectConfiguration: { inputUnit, projectEnvConfig, productId, projectVersion } } = state();
  let setback = 0;
  if (isRMIFIProduct(productId)) {
    setback = getMaxSetBack(projectEnvConfig, inputUnit, productId, projectVersion);
  } else {
    setback = isMetricUnit(inputUnit)? cmsToMeters(projectEnvConfig.setback_distance) : feetsToMeters(projectEnvConfig.setback_distance) ;
  }
  return setback / metersPerPixel;
};
