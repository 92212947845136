import riskCategoryTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/riskCategoryTooltip';
export const apiField = 'risk_category';

interface option {
  value: number;
  name: string;
}

function makeConfig(options: option[]) {
  return {
    type: 'Select',
    apiField,
    label: 'riskCategory',
    tooltip: riskCategoryTooltip,
    data: options,
    missing: undefined
  };
}

export enum RiskCategory {
  I = 1,
  II = 2,
  III = 3,
  IV = 4,
}

export default makeConfig([
  {
    value: RiskCategory.I,
    name: 'I',
  },
  {
    value: RiskCategory.II,
    name: 'II',
  },
  {
    value: RiskCategory.III,
    name: 'III',
  },
  {
    value: RiskCategory.IV,
    name: 'IV',
  },
]);


export const riskRMIToIIICategory = makeConfig([
  {
    value: 1,
    name: 'I',
  },
  {
    value: 2,
    name: 'II',
  },
  {
    value: 3,
    name: 'III',
  },
]);