import * as React from 'react';
import AccessoryPart from 'bom/components/accessoriesSelector/accessoryPart/accessoryPart';
import { areStandardClampsSelected } from 'bom/bomHelpers';
import { connect } from 'react-redux';
import { NxtAccessorySelector } from './NxtAccessorySelector';
import { mlpePartNumber, NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1, RM_ROOF_PAD } from '__common/constants/parts';

const STANDARD_CLAMPS_PART_NUMBER = 'STDCLAMPS';
const default_accessory = new Set(["008009P", "ES10201", "ES11108", "ES10260"]);
class AccessoriesSelector extends React.Component<accessoriesSelectorComponentState, {}> {
  isSelected(accessoryNumber: string) {
    const { bom: { partsList, accessoriesList, extraParameters: { rm_roof_pad, is_rm_roof_pad_checkbox_enable } } } = this.props;
    
    if (accessoryNumber === STANDARD_CLAMPS_PART_NUMBER) {
      return areStandardClampsSelected(partsList);
    }

    if (accessoryNumber === RM_ROOF_PAD && is_rm_roof_pad_checkbox_enable){
      return is_rm_roof_pad_checkbox_enable
    }

    if (accessoryNumber === '310760' && rm_roof_pad?.is_roof_pad_required){
      return rm_roof_pad.is_selected
    }

    if(accessoryNumber === 'LUGMLPE1M' || accessoryNumber === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 || accessoryNumber === 'LUGMLPE1R') {
      const is_accessory_selected = accessoriesList.find(acc => acc.number === accessoryNumber).selected;
      return is_accessory_selected;
    }

    return partsList.filter(part => part.number === accessoryNumber).length > 0;
  }

  render() {
    const { bom: { accessoriesList, partsList, extraParameters: { continuous_trims_sections, rm_roof_pad, row_to_row_bonding_qty, is_rm_roof_pad_checkbox_enable } }, dispatch, productId, projectId,} = this.props;
    const proSeriesClampsSelected = accessoriesList.map((accessory) => {
      if (accessory.number === 'PRO_SERIES') {
        return accessory.selected;
      }
    }).filter(accessory => accessory !== undefined)[0];
    return (
      <div>
        <>
        {
          (productId === 'nxt_umount' || productId === 'nxt_tilt') ? (
            <div className="control-panel-label bom-sidebar-main-header-nxt">
              SELECT ACCESSORIES
            </div>
          ) : (
            <div className="control-panel-label bom-sidebar-main-header">
              Select accessories
            </div>
          )
        }
        </>
        {
          (productId === 'nxt_umount' || productId === 'nxt_tilt') ?
          (<NxtAccessorySelector
            accessoriesList={accessoriesList}
            dispatch={dispatch}
            productId={productId}
            projectId={projectId}
            proSeriesClampsSelected={proSeriesClampsSelected}
            partsList={partsList}
            continuous_trims_sections={continuous_trims_sections}
            rm_roof_pad={rm_roof_pad}
            row_to_row_bonding_qty = {row_to_row_bonding_qty}
            is_rm_roof_pad_checkbox_enable={is_rm_roof_pad_checkbox_enable}
           />) : 
           (
             productId === 'metalx' ? (
              accessoriesList.map((accessory, index) => {
                if(!default_accessory.has(accessory.number)) {
                  const isSelected = this.isSelected(accessory.number);
                  return (<AccessoryPart
                    key={`accessories-${index}`}
                    accessory={accessory}
                    accessoriesList={accessoriesList}
                    dispatch={dispatch}
                    isSelected={isSelected}
                    productId={productId}
                    projectId={projectId}
                    partsList={partsList}
                    continuous_trims_sections={continuous_trims_sections}
                    rm_roof_pad={rm_roof_pad}
                    is_rm_roof_pad_checkbox_enable={is_rm_roof_pad_checkbox_enable}
                  />);
                } 
              })
             ) : (
              accessoriesList.map((accessory, index) => {
                const isSelected = this.isSelected(accessory.number);
                return (<AccessoryPart
                  key={`accessories-${index}`}
                  accessory={accessory}
                  accessoriesList={accessoriesList}
                  dispatch={dispatch}
                  isSelected={isSelected}
                  productId={productId}
                  projectId={projectId}
                  proSeriesClampsSelected={proSeriesClampsSelected}
                  partsList={partsList}
                  continuous_trims_sections={continuous_trims_sections}
                  rm_roof_pad={rm_roof_pad}
                  is_rm_roof_pad_checkbox_enable={is_rm_roof_pad_checkbox_enable}
                />);
              })
             )
           )         
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bom: state.bom,
  };
}

export default connect(mapStateToProps)(AccessoriesSelector);
