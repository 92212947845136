export const apiField = 'roof_psf_limit';

const config = {
  min: 1.00,
  max: 100.00, 
  type: 'Number',
  label: 'roofPsfLimit',
  apiField,
  missing: undefined,
};

export default config;
