import RoofsSelector from '__editor/components/roofsSelector/roofsSelector';
import { clearEvents } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorHelper';
import { clearPixiLayer, initPixi } from '__editor/googleMapsRoofsSelector/components/stage/stage';
import { connect } from 'react-redux';
import { getGoogleMarkerIcon } from '__editor/googleMapsRoofsSelector/models/centerMarker';
import { getRoofColor } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import { initStageEvents } from '__editor/components/roofsSelector/components/roofsSelectorStage/roofsSelectorStage';
import { renderAdanvaceRoofSelecting } from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelecting';
import {
  centerMapOnRoofs,
  initializeMap,
  removeMap,
  setMapEvents,
  } from '__editor/googleMapsRoofsSelector/components/map/map';
import {
  clearAllGoogleLeadEdgeObject,
  createLeadEdgesOnMap,
  getAllLeadEdges,
  } from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/leadEdgeRoofSelector';
import {
  clearGoogleRoofsOnMaps,
  createRoofArea,
  getAllGoogleRoofObject,
  initializeDrawingManager,
  removeRoofsListeners,
  setGoogleMapInfoWindow,
  } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { renderMeasurementTool } from './components/measurementTool/measurementTool';

class GoogleMapsRoofsSelector extends RoofsSelector<googleMapsRoofsSelectorComponentState> {

  map: google.maps.Map;
  drawingManager: google.maps.drawing.DrawingManager;
  outerDiv: HTMLDivElement;
  fpsmeter: HTMLDivElement;
  canvas: HTMLDivElement;

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.resetCanvasLayer);
  }

  componentDidUpdate(prevProps: googleMapsRoofsSelectorComponentState) {
    const { previewMode, drawingManager: { roofs } } = this.props;
    if (previewMode) {
      this.updateRoofsColor();
    }

    if (roofs && Object.keys(roofs).length){
      if(prevProps.background.selectedRoofId !== null && this.props.background.selectedRoofId !== null){
        if(prevProps.background.selectedRoofId !== this.props.background.selectedRoofId && previewMode){
          this.loadGoogleMapInfoWindow(roofs, previewMode);
        }
      }
    }
  
    this.renderAdanvaceRoofSelecting();
    this.renderMeasurementTool();
  }

  componentWillUnmount() {
    const { previewMode } = this.props;
    this.map = null;
    removeMap();
    clearPixiLayer();
    clearGoogleRoofsOnMaps();
    clearAllGoogleLeadEdgeObject();
    this.destroyListeners();
    google.maps.event.clearInstanceListeners(window);
    google.maps.event.clearInstanceListeners(document);
    if (previewMode) {
      Array.from(document.getElementsByClassName('pac-logo')).map(el => el.remove());
    }

    window.removeEventListener('resize', this.resetCanvasLayer);
  }

  initMap() {
    const { drawingManager: { roofs }, previewMode } = this.props;
    this.map = initializeMap(this.canvas, previewMode, {});
    google.maps.event.addListenerOnce(this.map, 'idle', () => {

      if (!previewMode) {
        this.setRoofSelectorMapEvents();
        clearPixiLayer();
        initPixi();
        initStageEvents();
      }

      if (previewMode) {
        setMapEvents(this.canvas, true);
      }

      if (roofs && Object.keys(roofs).length) {
        this.loadRoofSelectorShapes(roofs, previewMode);
      }
    });
  }

  resetCanvasLayer = () => {
    clearPixiLayer();
    initPixi();
  }

  setRoofSelectorMapEvents() {
    const { previewMode } = this.props;
    this.drawingManager = initializeDrawingManager();
    setMapEvents(this.canvas, previewMode);
  }

  loadLeadEdges() {
    const { leadEdgeRoofSelector: { leadEdges } } = this.props;
    if (Object.keys(leadEdges).length) {
      Object.keys(leadEdges).map((roofId) => {
        createLeadEdgesOnMap(parseInt(roofId, 10));   
      });
    }
  }

  loadRoofSelectorShapes(roofs, previewMode: boolean) {
    Object.keys(roofs).map((roofId) => {
      createRoofArea(roofs[roofId].coords, parseInt(roofId, 10), previewMode);
    });

    this.loadLeadEdges();

    centerMapOnRoofs(getAllGoogleRoofObject());
  }

  loadGoogleMapInfoWindow(roofs, previewMode){
    Object.keys(roofs).map((roofId) =>{
    setGoogleMapInfoWindow(Number(roofId), previewMode);
    });
  }

  destroyListeners() {
    removeRoofsListeners();
    clearEvents();
  }

  updateRoofsColor() {
    const roofs = getAllGoogleRoofObject();
    const leadEdges = getAllLeadEdges();
    if (roofs && Object.keys(roofs).length) {
      Object.keys(roofs).map((roofId) => {
        const roof = roofs[roofId].roof.overlay;
        const marker = roofs[roofId].marker;
        const leadEdge = leadEdges[roofId];
        const color = getRoofColor(parseInt(roofId, 10));
        marker.setIcon(getGoogleMarkerIcon(parseInt(roofId, 10)));
        roof.setOptions({ fillColor: color, strokeColor: color });
        leadEdge.setOptions({ strokeColor: color });
      });
    }
  }

  renderAdanvaceRoofSelecting() {
    const { advanceRoofSelecting: { enabled, roofPoints, lastPoint }, previewMode } = this.props;
    if (enabled && roofPoints.length && lastPoint && !previewMode) {
      renderAdanvaceRoofSelecting();
    }
  }

  renderMeasurementTool() {
    const { measurementTool : { enabled, startingPoint, endingPoint }, previewMode } = this.props;
    if (enabled && startingPoint && endingPoint && !previewMode) {
      renderMeasurementTool();
    }
  }
}

/* istanbul ignore next */
function mapStateToProps(state: appState): any {
  return {
    advanceRoofSelecting: state.advanceRoofSelecting,
    drawingManager: state.drawingManager,
    background: state.background,
    moduleSelector: state.moduleSelector,
    measurementTool: state.measurementTool,
    leadEdgeRoofSelector: state.leadEdgeRoofSelector,
    history: state.history,
    projectConfiguration: state.projectConfiguration,
    router: state.router,
    roofsSelector: state.roofsSelector,
    roofsSelectorMap: state.roofsSelectorMap,
  };
}

export default connect(mapStateToProps)(GoogleMapsRoofsSelector);
