import Polygon from 'polygon';
import Polyk, { RaycastResult } from 'polyk';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { getObstructionLargestEdgeMinimumLength } from '../obstructions/obstructions';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { isRMFamily, isResidentialProduct, isCommercialProduct, isAscender } from '__common/constants/products';
import { getEdgesAndCorners } from './utils/getRoofZonesEdgesAndCorners';
import { edge, adjacentEdge, edgeZone } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { state } from '__common/store';
import { getRealRoofEdgesBoundsMemoized as  getRealRoofEdgesBounds, getVirtualRoofEdgesPolygon } from '../background/background';
import { findNeighbouringPoints, checkAngleBetweenTwoNeightbouringPoints } from './utils/residentialsZoneExceptions';
import { findPerpendicularLines } from '__common/calculations/prependicularLines';
import { CHECK_POINT_DISTANCE } from './utils/windZonesCollisionsDetection';
import { isFlatRoof } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { exposureZoneDistance, restrictedZoneDistance, windZonesDistanceForGableAndHip } from './utils/asce716ResidentialZonesDistances';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';
import { shouldUseVirtualRoofEdges } from '__editor/panelsEditor/panelsEditorHelper';
import moize from 'moize';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { cmsToMeters } from '__common/calculations/unitConversions';

export interface RoofZonesCorner {
  pos: { x: number, y: number };
  distance: number;
  zone?: edgeZone;
  index?: number;
  edge?: number;
  siblingCorners?: [RoofZonesCorner, RoofZonesCorner];
  adjacentEdges?: [adjacentEdge, adjacentEdge];
  obstructionId?: string;
}

export interface RoofZonesAreas {
  corners: RoofZonesCorner[];
  edges: edge[];
}

const SM_FAMILY_STANDARD_ROOF_EDGES_ZONE_DISTANCE: roofZoneNumber = 3;

const RM_FAMILY_STANDARD_ROOF_EDGES_ZONE_DISTANCE: roofZoneNumber = 0;

export const OBSTRUCTION_ZONE: roofZoneNumber = 8;

export const getRoofArea = (
  roofEdges: google.maps.LatLngLiteral[], 
  roofCenter: cordPoint, 
  zoom: number, 
  rotationDegrees: number, 
  bgXOffset: number,
  bgYOffset: number,
  roofPitch: string,
  ) => {
  const rotationRadians = degreesToRadians(rotationDegrees);
  const {
    background: {
      roofEdgesPixiCords,
    },
    projectConfiguration: {
      productId,
      projectVersion,
      projectEnvConfig: {tilt}
    },
    roofsSelector: {
      mapType,
    },
  } = state();
  const roofEdgesPolygon = shouldUseVirtualRoofEdges(mapType, productId, projectVersion) ?
    getVirtualRoofEdgesPolygon(roofEdgesPixiCords) :
    getRealRoofEdgesBounds({roofEdges, roofCenter, zoom, rotationRadians, bgOffSet:{x: bgXOffset, y: bgYOffset}, roofPitch, productId, tilt});
  return roofEdgesPolygon;
}

export const getVirtualRoofZonesAreas = (
  distance: number, 
  roofEdges: pixelPoint[],
  insideOfPolygon: boolean,
): RoofZonesAreas => {
  let corners: RoofZonesCorner[] = [];
  let edges: edge[] = [];
  const roofEdgesPolygon = getVirtualRoofEdgesPolygon(roofEdges);
  const roofEdgesAndCorners = getEdgesAndCorners(null, roofEdgesPolygon, distance, roofEdgesPolygon, insideOfPolygon);

  corners = [...roofEdgesAndCorners.corners, ...corners];
  edges = [...roofEdgesAndCorners.edges, ...edges];
  
  return {
    corners,
    edges,
  };
};


export const getRoofZonesAreas = (
  input:  {
    roofEdges: google.maps.LatLngLiteral[], 
    roofCenter: cordPoint,
    roofEdgesPixiCords,
    roofPitch: string,
    roofId: number,
    distance: number,      
    zoom: number, 
    rotationDegrees: number,
    bgOffSet:{x: number, y: number},
    insideOfPolygon: boolean,
    productId: number, 
    tilt: number,
    mapType: string,
  }
  ): RoofZonesAreas => {
  let corners: RoofZonesCorner[] = [];
  let edges: edge[] = [];
  const {roofEdges, roofCenter, roofEdgesPixiCords, roofPitch, roofId, distance, zoom, rotationDegrees, bgOffSet, insideOfPolygon, mapType, productId, tilt} = input;

  const rotationRadians = degreesToRadians(rotationDegrees);
  const {
    projectConfiguration: {
      projectVersion,
    },
  } = state();

  // need to be rotated and rescale bacouse was drawn before bg loaded. We need to draw it first to find rotation ;p
  const roofEdgesPolygon = shouldUseVirtualRoofEdges(mapType, productId, projectVersion) ?
    getVirtualRoofEdgesPolygon(roofEdgesPixiCords) :
    getRealRoofEdgesBounds({roofEdges, roofCenter, bgOffSet: {x: bgOffSet.x, y:bgOffSet.y}, zoom, rotationRadians, roofPitch, productId, tilt})

  const roofEdgesAndCorners = getEdgesAndCorners(roofId, roofEdgesPolygon, distance, roofEdgesPolygon, insideOfPolygon);
  corners = [...roofEdgesAndCorners.corners, ...corners];
  edges = [...roofEdgesAndCorners.edges, ...edges];
  
  return {
    corners,
    edges,
  };
};

export const getRoofZonesAreasMemoized = moize(getRoofZonesAreas,  { isSerialized: true })

export const getObstructionsZonesAreas = (input : {selectedRoofId: number, metersPerPixel: number, bgOffSet: {x: number, y: number}, obstructions}): RoofZonesAreas => {
  const {selectedRoofId, metersPerPixel, bgOffSet, obstructions} = input;

  let corners: RoofZonesCorner[] = [];
  let edges: edge[] = [];

  if (obstructions) {
    Object.keys(obstructions).map(obstructionId => {
      
      const obstruction: obstruction = obstructions[obstructionId];
      const obstructionEdges = obstruction.coords;
      const obstructionSetback = inchesToMeters(obstruction.setback) / metersPerPixel;
      const minimumHeightInches = getObstructionLargestEdgeMinimumLength(5);
      const isHighEnough = obstruction.height > minimumHeightInches;

      if (!isHighEnough || !obstruction.isLargeEnough) {
        return;
      }

      const obstructionsEdgesCentered = obstructionEdges.map(point => ({ x: point.x + bgOffSet.x, y: point.y + bgOffSet.y }));

      // don't need to be rotated bacouse it was already drawn on rotated screen
      const obstructionsEdgesPolygon = new Polygon(obstructionsEdgesCentered); 
      const insideOfPolygon = false;

      const obstructionEdgesAndCorners = getEdgesAndCorners(selectedRoofId, obstructionsEdgesPolygon, obstructionSetback, obstructionsEdgesPolygon, insideOfPolygon, obstructionId);
      corners = [...obstructionEdgesAndCorners.corners, ...corners];
      edges = [...obstructionEdgesAndCorners.edges, ...edges];
    });
  }

  return {
    corners,
    edges,
  };
};

export const getLZonesAreas = (
  input : {
  roofEdges: google.maps.LatLngLiteral[], 
  roofCenter: cordPoint, 
  zoom: number, 
  rotationDegrees: number, 
  bgOffSet: {x: number, y: number},
  distanceFromEdgeCorner: number,
  distanceFromEdge: number,
  roofPitch: string,
  }
): { x: number, y: number }[][] => {
  const {roofEdges, roofCenter, zoom, rotationDegrees, bgOffSet, distanceFromEdgeCorner, distanceFromEdge, roofPitch} = input;
  const rotationRadians = degreesToRadians(rotationDegrees);
  const {projectConfiguration: {productId, projectEnvConfig: {tilt}}} = state();

  const roofEdgesPolygon = getRealRoofEdgesBounds({roofEdges, roofCenter, bgOffSet, zoom, rotationRadians, roofPitch, productId, tilt});

  return roofEdgesPolygon.points.reduce((acc, edge, index) => {
    let firstPoint: pixelPoint;
    let middlePoint: pixelPoint;
    let thirdPoint: pixelPoint;

    if (index === 0) {  
      firstPoint = roofEdgesPolygon.points[roofEdges.length - 1];
    } else {
      firstPoint = roofEdgesPolygon.points[index - 1];
    }

    middlePoint = edge;

    if (index === roofEdgesPolygon.points.length - 1) {
      thirdPoint = roofEdgesPolygon.points[0];
    } else {
      thirdPoint = roofEdgesPolygon.points[index + 1];
    }

    const { p0, p1 } = findNeighbouringPoints(firstPoint.x, firstPoint.y, middlePoint.x, middlePoint.y, thirdPoint.x, thirdPoint.y, CHECK_POINT_DISTANCE);
    
    const isLongEnough = isEdgeLargerThanWindZoneDim(firstPoint, middlePoint, thirdPoint, distanceFromEdgeCorner);

    if (isLongEnough && checkAngleBetweenTwoNeightbouringPoints(p0, p1, CHECK_POINT_DISTANCE, roofEdgesPolygon)) {
      const { p0, p1 } = findNeighbouringPoints(firstPoint.x, firstPoint.y, middlePoint.x, middlePoint.y, thirdPoint.x, thirdPoint.y, distanceFromEdgeCorner);

      const lines1 = findPerpendicularLines(p0, middlePoint, distanceFromEdge, roofEdgesPolygon, true);

      const lines2 = findPerpendicularLines(p1, middlePoint, distanceFromEdge, roofEdgesPolygon, true);
  
      acc.push([middlePoint, p0, lines1[0][0], lines1[0][1]]);
      acc.push([middlePoint, p1, lines2[0][0], lines2[0][1]]);
    }
 
    return acc;
  },                                    []);
};

export const getLZonesAreasMemoized = moize(getLZonesAreas, {isSerialized: true});

export const exposedDistance = (metersPerPixel: number) => {
  const distanceFt =  exposureZoneDistance();
  const distanceMeters = feetsToMeters(distanceFt);
  return distanceMeters / metersPerPixel;
};

export const flatRoofDistances = (buildingHeightFt: number, metersPerPixel: number) => {
  const buildingHeightMeters = feetsToMeters(buildingHeightFt);
  const buildingHeightPixels = buildingHeightMeters / metersPerPixel;

  return {
    distanceFromEdgeCorner: buildingHeightPixels * 0.6,
    distanceFromEdge: buildingHeightPixels * 0.2,
  };
};

export const asce716RestrictedZoneDistance = (metersPerPixel: number, productId: number) => {
  if (isCommercialProduct(productId)) {
    const RESTRICTED_ZONE_FT = 3;
    return feetsToMeters(RESTRICTED_ZONE_FT) / metersPerPixel;
  }

  if (isResidentialProduct(productId)) {
    const distanceFt = restrictedZoneDistance(productId);
    const distanceMeters = feetsToMeters(distanceFt);
    return distanceMeters / metersPerPixel;
  }
};

export const setBackRestrictedZoneDistance = (setback_distance: number, metersPerPixel: number, inputUnit: number) => {
  const distanceMeters =   isMetricUnit(inputUnit) ? cmsToMeters(setback_distance) : feetsToMeters(setback_distance)
  return distanceMeters / metersPerPixel;
};

export const isPanelOnCorner = (panelClosestDistances: RaycastResult[], productId: number, metersPerPixel: number, useNewZoneClassification: boolean) => {
  const distancesClosersThen3Feets = panelClosestDistances.filter((distance) => {
    return isCloserFromEdgeThen(distance.dist, productId, metersPerPixel, useNewZoneClassification);
  });
  const numberOfClosestEdges = distancesClosersThen3Feets.map((distance) => {
    return distance.edge;
  });

  return new Set(numberOfClosestEdges).size > 1;
};

export const isCloserFromEdgeThen = (distance: number, productId: number, metersPerPixel: number, useNewZoneClassification: boolean): boolean => {
  // 3 feets === 0.9144m
  const distanceFromEdgeFt = isRMFamily(productId) ? 
    RM_FAMILY_STANDARD_ROOF_EDGES_ZONE_DISTANCE : 
    useNewZoneClassification ? windZonesDistanceForGableAndHip(): SM_FAMILY_STANDARD_ROOF_EDGES_ZONE_DISTANCE;;


  const distanceInMeters = distance * metersPerPixel;
  const distanceInFeets = metersToFeets(distanceInMeters);
  return distanceInFeets <= distanceFromEdgeFt;
};

export const detectDistancesFromEdge = (roofEdgesPolygon, panelPoly) => {
  const roofEdgesPoints = roofEdgesPolygon.points.reduce((acc, points) => {
    acc.push(points.x);
    acc.push(points.y);
    return acc;
  },                                                     []);
  const firstPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[0].x, panelPoly.points[0].y);
  const secondPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[1].x, panelPoly.points[1].y);
  const thirdPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[2].x, panelPoly.points[2].y);
  const fourthPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[3].x, panelPoly.points[3].y);

  return [firstPanelEdgeDist, secondPanelEdgeDist, thirdPanelEdgeDist, fourthPanelEdgeDist];
};

export const getEdgeTypeName = (roofZone: number, edgeType: number) => {
  const { projectConfiguration: {  productId, projectEnvConfig: { building_type } } } = state();

  if (isFlatRoof(building_type) || isAscender(productId)) {
    return false;
  }

  if (roofZone === 1) {
    return undefined;
  }

  if (edgeType === 0) {
    return 'E';
  } 
  
  if (edgeType === 1) {
    return 'R';
  }

  if (edgeType === 2) {
    return 'N';
  }
  if (roofZone == 4 && edgeType==3) {
    return "1'"
  }
};

export const isEdgeLargerThanWindZoneDim = (firstPoint: pixelPoint, middlePoint: pixelPoint, thirdPoint: pixelPoint, distanceFromEdgeCorner: number) => {
  const firstEdgeLongEnough = Math.abs(calcDistanceOfTwoPoints(firstPoint, middlePoint)) >= distanceFromEdgeCorner;
  const secondEdgeLongEnough = Math.abs(calcDistanceOfTwoPoints(middlePoint, thirdPoint)) >= distanceFromEdgeCorner;
  const isLongEnough = firstEdgeLongEnough && secondEdgeLongEnough;
  return isLongEnough;
};


