import Leaflet from 'leaflet';
import { roofHasPanels } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';

export const fullRoofMarkerIcon = require('assets/media/icons/edit_panels_icon.svg');
export const emptyRoofMarkerIcon = require('assets/media/icons/add_panels_icon.svg');

export default (coords: { lat: number, lng: number }, roofId?: number) => {
  
  const icon = Leaflet.icon({
    iconUrl: getBingMarkerIcon(roofId),
    iconSize: [50, 50],
    iconAnchor: [25, 25],
  });

  return Leaflet.marker(coords, { icon });
};

export function getBingMarkerIcon(roofId: number) { 
  const hasPanels = roofHasPanels(roofId);
  return hasPanels ? fullRoofMarkerIcon : emptyRoofMarkerIcon;
}
