import { ConfirmClearArraysModalActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';

export const confirmClearArraysModalState: confirmClearArraysModalState = {
  isOpen: false,
  title: '',
  content: '',
  onConfirmAction: null,
  onCancelCallback: null,
  onCancelAction: null,
  onConfirmCallback: null,
  confirmTitle: '',
  cancelTitle: '',
  dialogStyleProps: null,
};

export default {
  confirmClearArraysModal: createReducer(confirmClearArraysModalState, {
    [ConfirmClearArraysModalActionTypes.OPEN_CONFIRM_CLEAR_ARRAYS_MODAL](state, action) {
      const { title, content, onConfirmCallback, onCancelCallback, onConfirmAction, onCancelAction, confirmTitle, cancelTitle, dialogStyleProps, modal } = action.payload;
      return { ...state, title, content, onConfirmCallback, onCancelCallback, onConfirmAction, onCancelAction, confirmTitle, cancelTitle, dialogStyleProps, isOpen: true, modal };
    },
    [ConfirmClearArraysModalActionTypes.HIDE_CONFIRM_CLEAR_ARRAYS_MODAL](state) {
      return {
        ...state,
        isOpen: false,
        title: '',
        content: '',
        onConfirmCallback: null,
        onCancelCallback: null,
        onConfirmAction: null,
        onCancelAction: null,
        confirmTitle: '',
        cancelTitle: '',
        dialogStyleProps: null,
      };
    },
  }),
};
