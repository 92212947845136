import React from 'react';
import ModuleSelectorComponent from '__common/components/moduleSelector/moduleSelectorComponent';
import { renderInputsForHelioscope } from './renderInputs';
import { TextField } from 'react-md';
import { products } from '__common/constants/products';
import { getProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import { getFormattedProductName } from '__common/constants/products_titles';
import { RoofPitchSelectorStripped } from 'projectDesign/components/RoofPitchSelectorStripped';

export const helioscopePage = (
  currentConfig: Function,
  productId: string,
  initData: projectEnvConfig,
  mapType: string,
  location: string,
  onChange: Function,
  getErrorMessageIfInvalid: (fieldName: string) => string | null,
) => {
  const buildingCode = initData['building_code'];
  const config = currentConfig(buildingCode);
  


  return {
    content: (
      <>
        <div className="drawer-section-title">Helioscope Configuration</div>
        {helioscopeImportWarnings(initData, productId)}
        <div className="input-soft-warning">
          Before importing the project check parameters below:
        </div>
        {helioscopeModelInfo(initData, true)}
        <div className="drawer-fields helioscope-drawer-field">
          <ModuleSelectorComponent
            noCustomModule={true}
            noTitle={true}
            productId={productId}
            hideSameDimsButton={true}
            hideRefreshButton={true}
          />
          <div>{renderInputsForHelioscope(config.Helioscope, mapType, location, initData, products[productId], onChange, getErrorMessageIfInvalid)}</div>
          {config.HelioscopeRoofPitchSelector && (<RoofPitchSelectorStripped
            productId={products[productId]}
            initData={initData}
          />)}
        </div>
      </>
    ),
    tooltip: 'Helioscope Configuration',
  };
};

const helioscopeImportWarnings = (initData, productId: string) => {
  const { helioscope_module_spacing, helioscope_row_spacing, zipcode } = initData;
  const productsSettings = getProductSettings(products[productId]);
  const { rowSpacing, columnSpacing } = productsSettings;
  const rowSpacingChanged = rowSpacing !== helioscope_row_spacing;
  const columnSpacingChanged = columnSpacing !== helioscope_module_spacing;
  return (
    <>
      { (rowSpacingChanged || columnSpacingChanged) && 
      (<div className="input-warning">
        Layout fit on roof may be affected. Please update your Helioscope model to match {getFormattedProductName(products[productId])} specifications.
      </div>)}
      { rowSpacingChanged && 
      (<div className="input-warning">
        Project row spacing will be updated: {helioscope_row_spacing} inches to {rowSpacing} inches.
      </div>)}
      { columnSpacingChanged && 
      (<div className="input-warning">
        Project column spacing will be updated: {helioscope_module_spacing} inches to {columnSpacing} inches.
      </div>)}
      { !zipcode && 
      (<div className="input-soft-warning">
        Zipcode for the project site could not be determined. Please verify the environmental loads for the imported project.
      </div>)}
    </>
  );
}

const helioscopeModelInfo = (initData: projectEnvConfig, isHelioscope: boolean) => {
  const { model, helioscope_module_manufacturer, helioscope_module_name } = initData;
  if (!model && !module && isHelioscope) {
    return (
      <div className="heliscope-model-non-found-warning">
        <div className="input-warning">
          We were not able to select module automatically. <br />
          Please select the appropriate one.
        </div>
        <div>
          <div className="input-label">
            Helioscope model:
          </div>
          <TextField
            active={false}
            disabled={true}
            value={`${helioscope_module_manufacturer} ${helioscope_module_name}`}
          />
        </div>
      </div>
    );
  }

  return null;
};
