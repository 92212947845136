export const apiField = 'ice_thickness';

const config = {
  type: 'Number',
  label: 'ice_thickness',
  apiField,
  tooltip: () => `Ice Thickness`,
  min : 0,
};

export default config;
