import store from '__common/store';
import { getSinglePanelsPopulatingData } from './populating';

export type DrawPanelFunction = (x: number, y: number, groupId: number, siblingId?: number, siblingSide?: number, panelEWPosition?: number, panelNSPosition?:number, tableHeight?:number, attached?:boolean, pairId?: number, panelConfig?: number, exposed?: boolean) => panelInState;

const draw_SE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = store.getState();
  const { topEdge, leftEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn } = getSinglePanelsPopulatingData(points);
  let x = leftEdge;
  let y = topEdge;
  const panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = leftEdge + (panelWidth * i) + ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = topEdge + (panelHeight * j) + ((rowSpacing / metersPerPixel) * j);
      panels.push(drawFn(x, y, groupId));
    }
  }
  return panels;
};

const draw_NW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = store.getState();
  const { bottomEdge, rightEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn } = getSinglePanelsPopulatingData(points);
  let x = rightEdge;
  let y = bottomEdge;
  const panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = rightEdge - (panelWidth * i) - ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = bottomEdge - (panelHeight * j) - ((rowSpacing / metersPerPixel) * j);
      panels.push(drawFn(x, y, groupId));
    }
  }
  return panels;
};

const draw_NE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = store.getState();
  const { bottomEdge, leftEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn } = getSinglePanelsPopulatingData(points);
  let x = leftEdge;
  let y = bottomEdge;
  const panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = leftEdge + (panelWidth * i) + ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = bottomEdge - (panelHeight * j) - ((rowSpacing / metersPerPixel) * j);
      panels.push(drawFn(x, y, groupId));
    }
  }
  return panels;
};

const draw_SW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = store.getState();
  const { topEdge, rightEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn } = getSinglePanelsPopulatingData(points);
  let x = rightEdge;
  let y = topEdge;
  const panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = rightEdge - (panelWidth * i) - ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = topEdge + (panelHeight * j) + ((rowSpacing / metersPerPixel) * j);
      panels.push(drawFn(x, y, groupId));
    }
  }
  return panels;
};


export const draw_on_area = (direction: string, points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  switch (direction) {
    case 'SE':
      return draw_SE(points, drawFn, groupId);
    case 'NW':
      return draw_NW(points, drawFn, groupId);
    case 'NE':
      return draw_NE(points, drawFn, groupId);
    case 'SW':
      return draw_SW(points, drawFn, groupId);
    default:
      return draw_SE(points, drawFn, groupId);
  }
};

