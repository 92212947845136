import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS } from 'actions';
import { ChangePasswordActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';

export function changePassword(action$) {
  return action$.ofType(ChangePasswordActionTypes.CHANGE_PASSWORD)
    .switchMap(action => {
      const { old_password, new_password, password_repeat } = action.payload;
      return ObservableAjax({
        takeUntil: action$.ofType(ChangePasswordActionTypes.CHANGE_PASSWORD),
        onSuccess: CHANGE_PASSWORD_SUCCESS,
        onError: CHANGE_PASSWORD_ERROR,
        onErrorMessage: 'Cannot change password',
        link: hitApi('put', 'api/auth/password-change/', { old_password, new_password, password_repeat }),
      });
    },
    );
}
