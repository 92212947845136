import Polygon from 'polygon';

export function isInPolygonArea(firstPolygonMinMax, secondPolygonMinMax) {
  return firstPolygonMinMax.minX < secondPolygonMinMax.maxX &&
    firstPolygonMinMax.maxX > secondPolygonMinMax.minX &&
    firstPolygonMinMax.minY < secondPolygonMinMax.maxY &&
    firstPolygonMinMax.maxY > secondPolygonMinMax.minY;
}

export function getPolyMinMaxCoords(polygon: Polygon): { minX: number, minY: number, maxX: number, maxY: number } {
  return polygon.points.reduce((values, value) => {
    if (value.x < values.minX) {
      values.minX = value.x;
    }

    if (value.y < values.minY) {
      values.minY = value.y;
    }

    if (value.x > values.maxX) {
      values.maxX = value.x;
    }

    if (value.y > values.maxY) {
      values.maxY = value.y;
    }

    return values;
  },                           {
    minX: polygon.points[0].x,
    minY: polygon.points[0].y,
    maxX: polygon.points[0].x,
    maxY: polygon.points[0].y,
  });
}
