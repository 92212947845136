import { createReducer } from '__common/utils/helpers';
import { TiltedRoofActionTypes } from 'actionsConstants';
import { getDefaultRoofPitchValue } from './tiltedRoof';

export const tiltedRoofState = {
  rehydrated: false,
  roofScaling: false,
  backgroundScaling: true,
  enabled: true,
  roofPitch: null,
  structureTilt: null,
};

export default {
  tiltedRoof: createReducer(tiltedRoofState, {
    [TiltedRoofActionTypes.SET_TILTED_ROOF](state, action) {
      const { roofPitch } = action.payload;
      return { ...state, roofPitch, enabled: true };
    },
    [TiltedRoofActionTypes.SET_TILTED_ROOF_STRUCTURE_TILT](state, action) {
      const { structureTilt } = action.payload;
      return { ...state, structureTilt, enabled: true };
    },
    [TiltedRoofActionTypes.CLEAR_TILTED_ROOF](state, action) {
      const { productId, buildingCode, buildingType } = action.payload;

      const roofPitch = getDefaultRoofPitchValue(productId, buildingCode, buildingType);

      return { ...state, roofPitch, enabled: true };
    },
    [TiltedRoofActionTypes.TOOGLE_ROOF_SCALING](state) {
      return { ...state, roofScaling: !state.roofScaling };
    },
    [TiltedRoofActionTypes.TOOGLE_BACKGROUND_SCALING](state) {
      return { ...state, backgroundScaling: !state.backgroundScaling };
    },
  }),
};
