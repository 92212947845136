import { state } from '__common/store';
import { checkSelfIntersection, newRoofIntersectionWarning, advanceRoofSelectingAlert } from '__editor/components/roofsSelector/components/roofsSelectorAdvanceRoofSelecting/roofsSelectorAdvanceRoofSelecting';
import { advanceRoofSelectingLineModel, advanceRoofSelectingModel } from '__editor/googleMapsRoofsSelector/models/advanceRoofSelecting';

export function deployAdvanceSelecting({ dblClick, onClear, selfIntersectionWarning, overlapingWarning, onDeploy, overLapCheck }: {
  dblClick?: boolean,
  onClear: Function,
  selfIntersectionWarning?: string,
  overlapingWarning?: string,
  onDeploy: (cords: any[]) => void,
  overLapCheck?: (coords: any[]) => boolean,
}) {
  const { advanceRoofSelecting: { roofPoints }} = state();
  onClear();

  if (dblClick) {
    roofPoints.splice(-1, 1);
  }

  // filter out duplicate pts
  const newRoofPoints = [];
  roofPoints.forEach(pt => {
    if (!newRoofPoints.find(p => p.x === pt.x && p.y === pt.y)) {
      newRoofPoints.push(pt);
    }
  });

  if (selfIntersectionWarning && selfIntersectionWarning.length && checkSelfIntersection(newRoofPoints)) {
    newRoofIntersectionWarning(selfIntersectionWarning);
    return;
  }

  if (overLapCheck && overlapingWarning && overlapingWarning.length && overLapCheck(newRoofPoints)) {
    advanceRoofSelectingAlert(overlapingWarning);
    return;
  }

  if (newRoofPoints.length > 1) {
    onDeploy(newRoofPoints);
  }
}

export function getAdvanceRoofSelectingModel(scale: number) {
  const { advanceRoofSelecting: { roofPoints, lastPoint } } = state();
  const latLngPoints = roofPoints.slice(0);
  const lastRoofPointsIndex = roofPoints.length - 1;

  if (roofPoints.length === 1 || roofPoints[lastRoofPointsIndex].x !== lastPoint.x &&
    roofPoints[lastRoofPointsIndex].y !== lastPoint.y) {
    latLngPoints.push(lastPoint);
  }
  
  if (latLngPoints.length === 2) {
    return advanceRoofSelectingLineModel(latLngPoints, scale);
  }
  if (latLngPoints.length > 2) {
    return advanceRoofSelectingModel(latLngPoints, scale);
  }
}
