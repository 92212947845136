import SeismicTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/seismicTooltip';

export const apiField = 'seismic_s1';

const config = {
  type: 'Number',
  label: 'seismic_s1',
  apiField,
  tooltip: SeismicTooltip,
  missing: undefined,
  min: 0,
};
  
export default config;
