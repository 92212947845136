import * as PIXI from 'pixi.js';
import config from 'local_config';
import { degreesToRadians } from 'maths';
import { state } from '__common/store';

let googleMapsBgTexture;
let bingMapsBgTexture;
let cordsStore: cordPoint;
let zoomStore: number;

function cordsOrZoomChanged() {
  const { background: { cords, zoom } } = state();
  return cordsStore !== cords || zoomStore !== zoom;
}

export function getGoogleMapImageLink() {
  const { background: { cords, zoom } } = state();
  return `${location.protocol}//maps.googleapis.com/maps/api/staticmap?center=${cords.lat},${cords.lng}&zoom=${zoom}&size=1280x1280&key=${config.googleMapsKey}&scale=2&maptype=satellite`;
}

export function getBingMapsImageLink() {
  const { background: { cords, zoom } } = state();
  return `${location.protocol}//dev.virtualearth.net/REST/V1/Imagery/Map/Aerial/${cords.lat},${cords.lng}/${Number(zoom) + 1}?mapSize=1280,1280&format=png&key=${config.bingMapsKey}`;
}

function getLoadedTexture(link: string) {
  if (PIXI.loader.resources[link] && PIXI.loader.resources[link].name === link) {
    PIXI.loader.resources[link].texture;
  }

  return null;
}

function getMapTexture(type: string) {
  if (cordsOrZoomChanged()) {
    googleMapsBgTexture = null;
    bingMapsBgTexture = null;
  }

  if (!googleMapsBgTexture && type === 'google') {
    const googleMapLink = getGoogleMapImageLink();
    googleMapsBgTexture = getLoadedTexture(googleMapLink) || PIXI.Texture.fromImage(googleMapLink);
  }

  if (!bingMapsBgTexture && type === 'bing') {
    const bingMapLink = getBingMapsImageLink();
    bingMapsBgTexture = getLoadedTexture(bingMapLink) || PIXI.Texture.fromImage(bingMapLink);
  }

  if (type === 'google') {
    return googleMapsBgTexture;
  }

  if (type === 'bing') {
    return bingMapsBgTexture;
  }
  
}

function updateCordsAndZoom() {
  const { background: { cords, zoom } } = state();
  cordsStore = cords;
  zoomStore = zoom;
}

function resizeTwiceBg(bg) {
  bg.width = 1280 * 2;
  bg.height = 1280 * 2;
}

function setupMapBackground(bg: PIXI.Sprite, x: number, y: number, rotation: number) {
  const { background: { bgResized } } = state();

  if (bgResized) {
    resizeTwiceBg(bg);
  }

  bg.x = x;
  bg.y = y;
  bg.anchor.set(0.5, 0.5);
  bg.rotation = degreesToRadians(rotation);
  bg.id = 'background';
  bg.zIndex = -1;
  updateCordsAndZoom();
  return bg;
}

export const googleMapsBackgroundModel = (x: number, y: number, rotation: number) => {
  const bg = new PIXI.Sprite(getMapTexture('google'));
  return setupMapBackground(bg, x, y, rotation);
};

export const bingMapsBackgroundModel = (x: number, y: number, rotation: number) => {
  const bg = new PIXI.Sprite(getMapTexture('bing'));
  return setupMapBackground(bg, x, y, rotation);
};

export const whiteBackgroundModel = (x: number, y: number, rotation: number) => {
  const { background: { bgResized } } = state();
  const bg: PIXI.Graphics = new PIXI.Graphics();
  const width = bgResized ? 1280 * 2 : 1280;
  const height = bgResized ? 1280 * 2 : 1280;
  bg.lineStyle(2, 0xFFFFFF, 1);
  bg.beginFill(0xFFFFFF, 1);
  bg.drawRect(0, 0, 1280, 1280);
  const bgSprite = new PIXI.Sprite(bg.generateCanvasTexture());
  bgSprite.id = 'background';
  bgSprite.anchor.set(0.5, 0.5);
  bgSprite.rotation = degreesToRadians(rotation);
  bgSprite.zIndex = -1;
  bgSprite.x = x;
  bgSprite.y = y;
  bgSprite.width = width;
  bgSprite.height = height;
  updateCordsAndZoom();
  return bgSprite;
};
