import keyMirror from 'keymirror';

/**
 * @namespace Constants
 * @desc Panel constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const RubberBandActionTypes = keyMirror({
  START: undefined,
  RESIZE: undefined,
  REMOVE: undefined,
  RESET_RUBBERBAND_STATE: undefined,
  ADD_SHADOW_PANELS: undefined,
  SET_DRAW_RUBBER_BAND: undefined,
  SET_REMOVE_RUBBER_BAND: undefined,
  SET_PANELS_TO_HIDE_ON_REMOVE: undefined,
  DISABLE_RUBBER_BAND: undefined,
});
