export const apiField = 'stagger_attachments';

export interface StaggerAttachmentsField {
  type: string;
  apiField: string;
  label: string;
  tooltip?: () => string;
  data: StaggerAttachmentsOption[];
}

export interface StaggerAttachmentsOption {
  value: string;
  name: string;
}
