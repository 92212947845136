import keyMirror from 'keymirror';

export const RoofsSelectorActionTypes = keyMirror({
  SET_DEFAULT_MAP_ROOFS_SELECTOR: undefined,
  SET_GOOGLE_MAPS_ROOFS_SELECTOR: undefined,
  SET_BING_MAPS_ROOFS_SELECTOR: undefined,
  SET_BLANK_MAPS_ROOFS_SELECTOR: undefined,
  SET_IMAGE_ROOFS_SELECTOR: undefined,
  SET_MAP_ROOFS_SELECTOR: undefined,
  SET_MAP_STATE: undefined,
  CLEAR_ROOFS_SELECTOR: undefined,
});
