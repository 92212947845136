import windOnIceTooltip, { note as windOnIceNote } from 'projectDesign/components/projectConfiguration/fieldsTooltips/windOnIceTooltip';
export const apiField = 'wind_on_ice';

const config = {
  type: 'Number',
  label: 'wind_on_ice',
  apiField,
  tooltip: windOnIceTooltip,
  missing: undefined,
  note: { 
    message: windOnIceNote(),
    shouldShow: true,
  },
};

export default config;
