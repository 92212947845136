import { addNewBingMapRoofArea, getBingRoofOnMap, bingRoofAreaMarkerLatLngs } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { advanceRoofSelectingAlert, NEW_ROOF_INTERSECTION_ERROR_MSG } from '__editor/components/roofsSelector/components/roofsSelectorAdvanceRoofSelecting/roofsSelectorAdvanceRoofSelecting';
import { checkRoofSize } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/roofSizeAlert';
import { dispatch } from '__common/store';
import { clearRoof, newRoofOverlaping } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { selectBingRoofLeadEdge } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/utils/selectBingLeadEdge';
import { TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from 'actions';
import { turnBingMapsOnMapMoveControls } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import {
  getMapScale,
  getStage,
  layerPointToLatLng,
  tempMovePixiContainer,
  tempRestorePixiContainer,
  } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorStage/bingMapsRoofsSelectorStage';
import { deployAdvanceSelecting, getAdvanceRoofSelectingModel } from '__common/components/advancedSelecting/advanceSelecting';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';

let advanceRoofSelectingModel;

export const renderBingMapsAdanvaceRoofSelecting = () => {
  const stage = getStage();
  advanceRoofSelectingModel = getAdvanceRoofSelectingModel(getMapScale());
  stage.removeChildren();
  stage.addChild(advanceRoofSelectingModel);
};

export function deployBingAdvanceRoofSelecting(dblClick = false) {
  deployAdvanceSelecting({
    dblClick,
    selfIntersectionWarning: NEW_ROOF_INTERSECTION_ERROR_MSG,
    overlapingWarning: 'Roof cannot overlap another roof',
    onClear: () => clearBingAdvanceRoofSelecting(),
    overLapCheck: (newRoofPoints) => {

      const stage = getStage();

      const point1: any = newRoofPoints[newRoofPoints.length - 1];
      const point2: any = newRoofPoints[newRoofPoints.length - 2];
    
      const distance = calcDistanceOfTwoPoints(stage.toGlobal(point1), stage.toGlobal(point2));

      if (distance < 4) {
        newRoofPoints.splice(-1, 1);
      }
    
      const coords = getBingLatLngAdvanceRoofSelectingCoords(newRoofPoints);
    
      if (newRoofOverlaping(coords)) {
        advanceRoofSelectingAlert();
        clearBingAdvanceRoofSelecting();
        return true;
      }
    },
    onDeploy: (newRoofPoints) => {
      const coords = getBingLatLngAdvanceRoofSelectingCoords(newRoofPoints);
      if (newRoofPoints.length > 1) {
        const { checkingMarker } = bingRoofAreaMarkerLatLngs(coords);
        const roofAreaDeploy = () => {
        const roofId = addNewBingMapRoofArea(coords, newRoofPoints);
        selectBingRoofLeadEdge({ edgeNumber: 0, roofId });
    
        const roof = getBingRoofOnMap(roofId);
        const leadEdgeCoords = [coords[0], coords[1]];
        const { lat, lng } = roof.roof.overlay.getBounds().getCenter();
    
        checkRoofSize(roofId, coords, { lat, lng } , leadEdgeCoords, 18);
      };

      clearRoof(checkingMarker, roofAreaDeploy);
    };
    },
  });
}

export function getBingLatLngAdvanceRoofSelectingCoords(newRoofPoints: pixelPoint[]) {
  const stage = getStage();
  let points = newRoofPoints.map(coord => stage.toGlobal(new PIXI.Point(coord.x, coord.y)));
  tempMovePixiContainer();
  points = points.map(coord => stage.toLocal(new PIXI.Point(coord.x, coord.y)));
  const coords = points.map(coord => layerPointToLatLng(coord));
  tempRestorePixiContainer();
  return coords;
}

export function clearBingAdvanceRoofSelecting() {
  const stage = getStage();
  if (stage) {
    stage.removeChildren();
    dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    dispatch(TURN_OFF_MEASUREMENT_TOOL());
    turnBingMapsOnMapMoveControls();
  }
}
