import $ from 'jquery';
import {
  deleteRoof,
  makeRoofEmpty,
  selectRoof,
} from './roofsSelectorDrawingManager';
import { dispatch, state } from '__common/store';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL } from 'actions';
import { roofHasPanels } from './roofsSelectorDrawingManagerHelper';
import { getRoofName } from '../../../../googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { isGoogleMap, isBingMap } from '__common/constants/map';

export const editArray = (roofId: number) => {
  const { roofsSelector: { mapType } } = state();
  
  if (mapType === 'white') {
    return '';
  }

  return `
  <div class="edit-array" data-roofId=${roofId}>
    <?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
    <path d="M37.1,10.5l-3.3,3.7l-7.3-7.3L30,3.4c0.8-0.8,1.9-0.8,2.7,0l4.4,4.4C37.9,8.6,37.9,9.8,37.1,10.5z M10.3,37.5H3v-7.3
      L24.2,8.8l7.3,7.3L10.3,37.5z"/>
    </svg>
  </div>
  `;
};

export const deleteArray = (roofId: number) => {
  return `
    <div class="delete-array" data-roofId=${roofId}>
      <?xml version="1.0" encoding="utf-8"?>
      <!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
      <path d="M7.8,34.8c0,2.3,1.9,4.2,4.1,4.2h16.5c2.3,0,4.1-1.9,4.1-4.2v-25H7.8V34.8z M34.6,3.5h-7.2l-2.1-2.1H15l-2.1,2.1H5.7v4.2
        h28.8V3.5z"/>
      </svg>
    </div>
  `;
};


export const arrayDetails = (
  name: string,
  nrOfModules: number,
  hasPanels: boolean,
) => {
  if (hasPanels) {
    return `
      <div class="array-title">${name || 'Array'}</div>
      <div class="number-of-modules">
        <span class="nrOfModules">${nrOfModules}</span> 
        <span>modules</span>
      </div>
      `;
  }

  return `
  <div class="array-title">${name || getRoofName()}</div>
  <div class="number-of-modules">
    <span class="nrOfModules">0</span> 
    <span>modules</span>
  </div>
  `;
};

export const popupEvents = () => {
  $('body').off('click', '.edit-array');
  $('body').on('click', '.edit-array', function (evt) {
    const event: any = evt;
    const roofId = event.currentTarget.attributes[1].value;
    const target = $(this);
    target.toggleClass('active');

    if (roofHasPanels(roofId)) {
      dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          'Array shape change',
          'Changing roof shape will remove all panels in this installation. Are you sure?',
          () => {
            makeRoofEmpty(roofId);
            selectRoof(roofId);
            target
              .closest('.edit-and-delete-array')
              .find('.nrOfModules')
              .html(0);
          },
        ),
      );
    } else {
      selectRoof(roofId);
    }
  });

  $('body').off('click', '.delete-array');
  $('body').on('click', '.delete-array', evt => {
    const event: any = evt;
    const roofId = event.currentTarget.attributes[1].value;
    if (roofHasPanels(roofId)) {
      dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          'Delete array',
          'It will remove array permanently, Are you sure?',
          () => {
            deleteRoof(roofId);
          },
        ),
      );
    } else {
      deleteRoof(roofId);
    }
  });
};

export const markBingArrayEditrButtonSelected = (roofId: number) => {
  $(`.edit-array[data-roofid="${roofId}"]`)
  .toggleClass('active');
};


export const deactivateRoofAreaTextPopup = (mapType: string) => {

  if(isBingMap(mapType)){
    const elements = Array.from(document.querySelectorAll('.bing-maps-popup'));
    elements.forEach((el)=>{
      el.classList.add('disable-pointer');
    });
  }

  if(isGoogleMap(mapType)){
    const elements = Array.from(document.querySelectorAll('.google-maps-popup'));
    elements.forEach((el)=>{
      el.parentElement.classList.add('disable-pointer');
    });
  }
};

export const activateRoofAreaTextPopup = (mapType: string) => {

  if(isBingMap(mapType)){
    const elements = Array.from(document.querySelectorAll('.bing-maps-popup'));
    elements.forEach((el)=>{
      if(el.classList.contains('disable-pointer')){
        el.classList.remove('disable-pointer');
      }
    });
  }

  if(isGoogleMap(mapType)){
    const elements = Array.from(document.querySelectorAll('.google-maps-popup'));
    elements.forEach((el)=>{
      if(el.parentElement.classList.contains('disable-pointer')){
        el.parentElement.classList.remove('disable-pointer');
      }
    });
  }
};


export function shouldShowBannersInMiniMap(roofId, previewMode){
  if(!previewMode){ 
    return [editArray(roofId), deleteArray(roofId)]
  }
  return deleteArray(roofId)
}
