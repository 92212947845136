import spansTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/spansTooltip';
import { PreferredSpanField, apiField } from 'projectDesign/components/projectConfiguration/fields/span';

const config: PreferredSpanField = {
  type: 'Number',
  label: 'spansInput',
  apiField,
  min: 12,
  max: 72,
  tooltip: spansTooltip,
};

export default config;
