import { PanelsActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';

export interface ADD_PANELS_ACTION {
  panels: panelInState[];
}

export function ADD_PANELS(panels: panelInState[]): Action<ADD_PANELS_ACTION> {
  return {
    type: PanelsActionTypes.ADD_PANELS,
    payload: {
      panels,
    },
  };
}

export interface REQUEST_ADD_PANELS_ACTION {
  panels: panelInState[];
}

export interface ADD_HASHMAP_ACTION {
  panelBayMapping: panelBayMapping;
}

/** 
 * This action should not add panels to the state. It should only cause calculations to run
 * that will determine which panels can be added and which are in collision with roof edges for example. 
 * It is handled in Epics.
 */
export function REQUEST_ADD_PANELS(panels: panelInState[]): Action<REQUEST_ADD_PANELS_ACTION> {
  return {
    type: PanelsActionTypes.REQUEST_ADD_PANELS,
    payload: {
      panels,
    },
  };
}
export function ADD_PANEL_BAY_MAPPING(panelBayMapping: panelBayMapping): Action<ADD_HASHMAP_ACTION> {
  return {
    type: PanelsActionTypes.ADD_PANEL_BAY_MAPPING,
    payload: {
      panelBayMapping,
    },
  };
}
export function ADD_VISITED_BAYS(bays:any) {
  return {
    type: PanelsActionTypes.ADD_VISITED_BAYS,
    payload: {
      bays,
    },
  };
}
export function START_EXPOSURE_RECALCULATION() {
  return {
    type: PanelsActionTypes.START_EXPOSURE_RECALCULATION,
  };
}

export interface RECHECK_PANELS_EXPO_ACTION {
  panelsToRecheck: number[];
  roofId: number;
  productId: number;
}

export function RECHECK_PANELS_EXPO(
  panelsToRecheck: number[], 
  roofId: number, 
  productId: number,
): Action<RECHECK_PANELS_EXPO_ACTION> {
  return {
    type: PanelsActionTypes.RECHECK_PANELS_EXPO,
    payload: {
      panelsToRecheck,
      roofId,
      productId,
    },
  };
}

export interface REMOVE_PANELS_ACTION {
  panelsIds: number[];
}

export function REMOVE_PANELS(panelsIds: number[]): Action<REMOVE_PANELS_ACTION> {
  return {
    type: PanelsActionTypes.REMOVE_PANELS,
    payload: {
      panelsIds, 
    },
  };
}
export interface REMOVE_PANELS_ACTION {
  panelsIds: number[];
}

export function REMOVE_BAYS(panelsIds: number[]): Action<REMOVE_PANELS_ACTION> {
  return {
    type: PanelsActionTypes.REMOVE_BAYS,
    payload: {
      panelsIds, 
    },
  };
}
export function BLOCK_CREATING_PANEL() {
  return {
    type: PanelsActionTypes.BLOCK_CREATING_PANEL,
  };
}

export function UNBLOCK_CREATING_PANEL() {
  return {
    type: PanelsActionTypes.UNBLOCK_CREATING_PANEL,
  };
}

export function START_DRAG_PANEL() {
  return {
    type: PanelsActionTypes.START_DRAG_PANEL,
  };
}

export function MOVE_DRAGED_PANEL() {
  return {
    type: PanelsActionTypes.MOVE_DRAGED_PANEL,
  };
}

export function END_DRAG_PANEL() {
  return {
    type: PanelsActionTypes.END_DRAG_PANEL,
  };
}

export function RESET_PANELS_STATE() {
  return {
    type: PanelsActionTypes.RESET_PANELS_STATE,
  };
}


export function LOAD_PANELS_FROM_HISTORY(panels: any) {
  return {
    type: PanelsActionTypes.LOAD_PANELS_FROM_HISTORY,
    payload: {
      panels,
    },
  };
}

export interface LOAD_PANELS_ACTION {
  panels: panelInState[];
  shouldCenter: boolean;
  rowSpacing: number;
  columnSpacing: number;
  metersPerPixel: number;
  exposureRecaluclationHash: string;
}

export function LOAD_PANELS(panels: panelInState[], shouldCenter: boolean, rowSpacing: number, columnSpacing: number, metersPerPixel: number, exposureRecaluclationHash: string): Action<LOAD_PANELS_ACTION> {
  return {
    type: PanelsActionTypes.LOAD_PANELS,
    payload: {
      panels,
      shouldCenter,
      rowSpacing,
      columnSpacing,
      metersPerPixel,
      exposureRecaluclationHash: exposureRecaluclationHash || null,
    },
  };
}

export function UPDATE_PANEL_SPACINGS(rowSpacingInches: number, columnSpacingInches: number): Action<any> {
  return {
    type: PanelsActionTypes.UPDATE_PANEL_SPACINGS,
    payload: {
      rowSpacingInches,
      columnSpacingInches,
    },
  };
}

export function SET_ROOF_ZONES_AUTOFILL(status: boolean) {
  return {
    type: PanelsActionTypes.SET_ROOF_ZONES_AUTOFILL,
    payload: {
      status,
    },
  };
}

export function RESTORE_PANELS_COLLISIONS_BOUNDS(rowSpacing: number, columnSpacing: number, metersPerPixel: number) {
  return {
    type: PanelsActionTypes.RESTORE_PANELS_COLLISIONS_BOUNDS,
    payload: {
      rowSpacing,
      columnSpacing,
      metersPerPixel,
    },
  };
}

export function CHANGE_SINGLE_PANEL_ROOF_ZONE(panelId: number, newRoofZone: number) {
  return {
    type: PanelsActionTypes.CHANGE_SINGLE_PANEL_ROOF_ZONE,
    payload: {
      panelId,
      newRoofZone,
    },
  };
}

export function SET_CENTER_OFFSET(offset: { x: number, y: number}) {
  return {
    type: PanelsActionTypes.SET_CENTER_OFFSET,
    payload: {
      offset,
    },
  };
}

export function RECHECK_PANELS_OBSTRUCTION_ZONE() {
  return {
    type: PanelsActionTypes.RECHECK_PANELS_OBSTRUCTION_ZONE,
  };
}

export interface UPDATE_PANELS_ACTION {
  panels: panelInState[];
  type: {type?: string};
}

export function UPDATE_PANELS(panels: panelInState[], type={}): Action<UPDATE_PANELS_ACTION> {
  return {
    type: PanelsActionTypes.UPDATE_PANELS,
    payload: {
      panels,
      type,
    },
  };
}

export interface SET_PANELS_ARRAY_FOR_MOVEMENT {
  panels: panelInState[];
}

export function SET_PANELS_ARRAY_FOR_MOVEMENT(panels: panelInState[]): Action<SET_PANELS_ARRAY_FOR_MOVEMENT> {
  return {
    type: PanelsActionTypes.SET_PANELS_ARRAY_FOR_MOVEMENT,
    payload: {
      panels,
    },
  };
}

export interface SET_PANELS_ARRAY_GRID_FOR_MOVEMENT {
  grid: number[][];
  toggleAttachmentsGrid: number[][],
  panelConfig?: null | number,
}

export function SET_PANELS_ARRAY_GRID_FOR_MOVEMENT(grid: number[][], toggleAttachmentsGrid: number[][], panelConfig: null | number): Action<SET_PANELS_ARRAY_GRID_FOR_MOVEMENT> {
  return {
    type: PanelsActionTypes.SET_PANELS_ARRAY_GRID_FOR_MOVEMENT,
    payload: {
      grid,
      toggleAttachmentsGrid,
      panelConfig,
    },
  };
}


export function RESET_PANELS_ARRAY_FOR_MOVEMENT(){
  return {
    type: PanelsActionTypes.RESET_PANELS_ARRAY_FOR_MOVEMENT,
  };
}


export function RESTORE_PANELS_FOR_NO_MOVEMENT(){
  return {
    type: PanelsActionTypes.RESTORE_PANELS_FOR_NO_MOVEMENT,
  };
}


export interface CHANGE_DESIRED_TABLE_LENGTH_ACTION {
  length: number;
}

export function CHANGE_DESIRED_TABLE_LENGTH(length: number): Action<CHANGE_DESIRED_TABLE_LENGTH_ACTION> {
  return {
    type: PanelsActionTypes.CHANGE_DESIRED_TABLE_LENGTH,
    payload: {
      length,
    },
  };
}

export interface CHANGE_DESIRED_TABLE_WIDTH_ACTION {
  width: number;
}

export function CHANGE_DESIRED_TABLE_WIDTH(width: number): Action<CHANGE_DESIRED_TABLE_WIDTH_ACTION> {
  return {
    type: PanelsActionTypes.CHANGE_DESIRED_TABLE_WIDTH,
    payload: {
      width,
    },
  };
}

export function RESET_TABLE_LENGTH_AND_WIDTH_SIZE() {
  return {
    type: PanelsActionTypes.RESET_TABLE_LENGTH_AND_WIDTH_SIZE,
  };
}

export function TOGGLE_PANEL_ATTACHED(id: string | number) {
  return {
    type: PanelsActionTypes.TOGGLE_PANEL_ATTACHED,
    payload: {
      panelId: id,
    }
  };
}

export function TOGGLE_BAY_ATTACHED(panelId: string | number, bayId: string | number) {
  return {
    type: PanelsActionTypes.TOGGLE_BAY_ATTACHED,
    payload: {
      panelId: panelId,
      bayId: bayId,
    }
  };
}