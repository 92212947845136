import { createReducer } from '__common/utils/helpers';
import { TooltipCursorActionTypes } from 'actionsConstants';

export const tooltipCursorState = {
  content: null,
  position: null,
};

export default {
  tooltipCursor: createReducer(tooltipCursorState, {
    [TooltipCursorActionTypes.SET_TOOLTIP_CONTENT](state, action: {  payload: { content: null | string } }) {
      const { content } = action.payload;
      return { ...state, content };
    },
  }),
};
