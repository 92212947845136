import { createReducer } from '__common/utils/helpers';
import { EngineeringActionTypes } from 'actionsConstants';
import { updateCompliantLetterGroundProduct } from './utils/compliantLetter';

export let engineeringState: engineeringState = null;

export default {
  engineering: createReducer(engineeringState, {
    [EngineeringActionTypes.SET_CODE_COMPILANT_LETTER](state, action) {
      let { compilantLetter } = action.payload;
      compilantLetter = updateCompliantLetterGroundProduct(compilantLetter);
      if (compilantLetter.relative_url && compilantLetter.relative_url[0] === '/') {
        compilantLetter.relative_url = compilantLetter.relative_url.substring(1);
      }

      return { ...state, compilantLetter };
    },
    [EngineeringActionTypes.POPULATE_ENGINEERING_RAPORT](state: engineeringState, action: action & { payload: engineeringState }) {
      return { ...state, ...action.payload };
    },
    [EngineeringActionTypes.UPDATE_ENGINEERING_CLIENT_DETAILS](state: engineeringState, action: action & { payload: { fieldType: string, value: string } }) {
      const { fieldType, value } = action.payload;
      state[fieldType] = value;
      return { ...state };
    },
    [EngineeringActionTypes.CLEAR_ENGINEERING_RAPORT](state: engineeringState) {
      return null;
    },
    [EngineeringActionTypes.CHANGE_DISCOUNT](state: engineeringProject, action) {
      const { discount } = action.payload;
      state.projectInfo.discount = discount;

      return { ...state };
    },
    [EngineeringActionTypes.SET_ENGINEERING_LAYOUT_FLAGS](state, action) {
      return { ...state, engineering_layout_flags: action.payload };
    },
  }),
};
