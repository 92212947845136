import { SOIL_CLASS } from "projectDesign/components/projectConfiguration/fields/soilClass";
import { RoofExposureOption } from "projectDesign/components/projectConfiguration/fields/types/roofExposure";
import { calculateKe, calculateKz, getFrictionFactor, get_design_life_factor, get_numerical_coefficient } from "projectDesign/components/projectConfiguration/utils/coefficients";
import { isASCE710, isASCE716or722, isASCE722 } from "__common/constants/buildingCodes";
import { isRM10, isRMIFIProduct, isRM10Evolution, isRMGridFlex, isRMDT, isRM5, isRmGridflex10 } from "__common/constants/products";
import store from "__common/store";
import { inRangeProjectVersion } from "__common/utils/versionCompare/versionCompare";
import { VERSION_MAP } from "__common/utils/versionCompare/version_info";
export function getRMProjectConfigurationData(projectEnvConfig: projectEnvConfig) {
  const {
    product,
    product_id,
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    seismic_s1,
    elevation,
    risk_category,
    dead_load_factor_modification,
    mean_recurrence_interval,
    building_height,
    roof_type,
    sub_category,
    manufacturer,
    colour,
    choice_of_fastend,
    parapet_height,
    parapet_height_input,
    setback_distance,
    is_half_block_allowed,
    block_weight,
    half_block_weight,
    wind_exposure,
    building_length,
    env_factors_for,
    client_address,
    client_name,
    created,
    updated,
    created_user_time,
    imported_helioscope_layout,
    deflectors,
    allow_mechanical_attachments,
    limit_down_point_loads,
    max_down_point_load_to_be_allowed,
    allow_manual_attachments,
    attachment_optimization_criteria,
    roof_psf_limit,
    total_weight_on_roof_limit,
    attachment_type,
    soil_class,
    psf_control,
    uplift_allowable,
    shear_allowable,
    topographical_factor_kzt,
    velocity_pressure_exposure_coefficient_kz,
    numberical_coefficient,
    design_life_factor_fc,
    wind_directionality_factor_kd,
    roof_exposure,
    ground_elevation_factor_ke,
    shortest_building_length,
    tilt,
    central_rails_count,
    allow_north_bays,
    central_support,
    add_roof_pads_everywhere,
    use_friction,
    allow_secondary_ballast_tray,
    allow_dual_attachments,
    anchor_type,
    created_user_is_staff,
    module_type,
    building_height_greater_than_6_storey,
    nbcc_category,
    nbcc_roughness,
    nbcc_wind_pressure,
    rain_load,
    mandatory_mid_support,
    additional_rail, 
    blocks_orientation,
    seismic_sds,
    seismic_sd1,
    tornado_speed,
    helioscope_id,
    thermal_factor,
    friction_factor,
    load_sharing_type,
  } = projectEnvConfig;
  const {
    projectConfiguration,
  } = store.getState();
  return {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    elevation,
    risk_category,
    mean_recurrence_interval,
    dead_load_factor_modification,
    building_height,
    roof_type,
    parapet_height,
    parapet_height_input,
    setback_distance,
    block_weight,
    wind_exposure,
    building_length,
    env_factors_for,
    client_address,
    client_name,
    created,
    updated,
    created_user_time,
    imported_helioscope_layout,
    helioscope_id,
    deflectors,
    allow_mechanical_attachments,
    ...(isRMIFIProduct(product) ?
      {
        product_id,
        tilt: tilt || 5,
        soil_class: soil_class || SOIL_CLASS.D_DEFAULT,
        topographical_factor_kzt,
        central_rails_count,
        allow_manual_attachments,
        allow_secondary_ballast_tray,
        allow_dual_attachments,
      } : {}),
    ...((isRMIFIProduct(product) || isRM10(product) || isRM10Evolution(product) || isRM5(product)) || isRmGridflex10(product) ?
      {
        seismic_s1,
        attachment_optimization_criteria,
        roof_psf_limit,
        total_weight_on_roof_limit,
        attachment_type,
        psf_control,
        uplift_allowable,
        shear_allowable,
        topographical_factor_kzt,
        is_half_block_allowed,
        half_block_weight,
        anchor_type,
        building_height_greater_than_6_storey
      } : {}),
    ...(isRM10(product) || isRM10Evolution(product) || isRM5(product) ? {
        nbcc_category,
        nbcc_roughness,
        nbcc_wind_pressure,
        rain_load
      } : {}),
    ...(isRM10(product) || isRM10Evolution(product) || isRmGridflex10(product) || isRMGridFlex(product)? {
      ...isRM10(product) ? {    
        nbcc_category,
        nbcc_roughness,
        nbcc_wind_pressure
      }: {},
      central_support,
      add_roof_pads_everywhere,
      use_friction,
      shortest_building_length,
      topographical_factor_kzt: topographical_factor_kzt || 1,
      velocity_pressure_exposure_coefficient_kz : velocity_pressure_exposure_coefficient_kz || calculateKz(wind_exposure, building_height, zipcode, projectConfiguration?.projectVersion, projectConfiguration?.inputUnit),
      wind_directionality_factor_kd: wind_directionality_factor_kd || 0.85,
      roof_exposure: roof_exposure || RoofExposureOption.partiallyExposed.value,
      ground_elevation_factor_ke: ground_elevation_factor_ke || calculateKe(elevation, isASCE716or722(building_code), projectConfiguration?.inputUnit),
      numberical_coefficient: numberical_coefficient || get_numerical_coefficient(building_code, elevation, projectConfiguration?.inputUnit),
      design_life_factor_fc: design_life_factor_fc || get_design_life_factor(mean_recurrence_interval),
      soil_class: soil_class || ((inRangeProjectVersion(projectConfiguration?.projectVersion, VERSION_MAP['shortest_building_length_changes'],
      VERSION_MAP['soil_class_D_to_D_Default_changes']) || isASCE710((building_code))) ? SOIL_CLASS.D : SOIL_CLASS.D_DEFAULT),
      created_user_is_staff,
      allow_manual_attachments,
      } : {}),
    ...(isRM10Evolution(product) ? {
      allow_north_bays,
      } : {}),
    ...(isRM5(product) ? {
      central_support, module_type,
      allow_manual_attachments,
      } : {}),
    ...(isRMGridFlex(product) || isRM10(product) || isRM10Evolution(product) || isRMDT(product) || isRM5(product) || isRmGridflex10(product) ? {
      limit_down_point_loads,
      max_down_point_load_to_be_allowed,
      } : {}),
    ...(isRM5(product) || isRMDT(product) ? {
      shortest_building_length,
      } : {}),
    ...(isRM5(product) ? {
      module_type,
      } : {}),
    ...(isRM10Evolution(product) || isRM10(product) || isRM5(product) ? {
        use_friction
      } : {}),
    ...(isRM10Evolution(product) || isRM10(product) ? {
        add_roof_pads_everywhere
      } : {}),
    ...(isRM10Evolution(product) || isRM5(product) || isRMGridFlex(product) || isRmGridflex10(product) ? {
      sub_category,
      manufacturer,
      colour,
      choice_of_fastend,
       } : {}),
      ...(isRMDT(product) ? {
        building_height_greater_than_6_storey,
      } : {}),
    ...(isRMGridFlex(product) || isRmGridflex10(product) ? {
      mandatory_mid_support,
      thermal_factor: thermal_factor || isASCE722(building_code) ? 1.2 : 1,
      friction_factor : friction_factor || getFrictionFactor(roof_type, product),
      load_sharing_type: load_sharing_type || 'default',
      } : {}),
    ...(isRmGridflex10(product) ? {
      allow_manual_attachments,
      additional_rail,
      blocks_orientation,
      tilt: tilt || 10}: {}),
    seismic_sds,
    tornado_speed,
    seismic_sd1,
  };
}
