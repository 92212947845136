import { RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS } from 'actions';
import { ResetPasswordActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';

export function resetPassword(action$) {
  return action$.ofType(ResetPasswordActionTypes.RESET_PASSWORD)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(ResetPasswordActionTypes.RESET_PASSWORD),
        onSuccess: RESET_PASSWORD_SUCCESS,
        onError: RESET_PASSWORD_ERROR,
        onErrorMessage: 'Cannot reset password',
        link: hitApi('put', 'api/auth/deactivate/'),
      }),
    );
}
