export const DeadLoadFactorTooltip = () => `
<div class="content">
  The dead load factor is used in ASCE load combination checks. A 0.6 factor assumes that only 60% of the
  system and ballast weight resist uplift and drag due to wind. This built in safety factor often controls a
  design. This safety factor allows for the possibility of 40% of the deadload of a “system” to be removed;
  the factor assumes that conditions in a building structure may change over time. The nature of a solar
  system is such that the system weight does not change over time while still seeing the same uplift and
  drag. Therefore, modifying the dead load factor to 1.0 uses 100% of the system and ballast weight to be
  assumed when calculating the requirements to resist uplift and lateral loads. The 1.0 option will only
  apply to projects and arrays meeting the following criteria: 1) Wind Exposure B or C, 2) Risk Category I
  and II, 3) Building Height of 100 feet or less, 4) Arrays at least 2X3 or 3X2 (rowsXcolumns)
</div>
`;
