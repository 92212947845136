import React from 'react';
import { Modal } from 'react-bootstrap';
import 'app/components/FaqComponent/faq.scss';
import { connect } from 'react-redux';
import { RESET_FAQ_WINDOW, POPULATE_FAQ_DATA} from './FaqActions';
import { Tab, Tabs, TabsContainer } from 'react-md';
import { openReportProblem } from '../ReportProblem/ReportProblemButton';
import { state } from '__common/store';
import { replaceURLs } from '__common/utils/helpers';

type Props = {
  user: userState,
  dispatch: Function;
  faq: faqState,
};

class FrequentlyAskedQuestions extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      openToggleIndex: 0,
      upvoteClicked: false,
      downvoteClicked: false,
      reportProblemOpen: false,
    };
  }

  handleToggle = (index) => {
    this.setState((prevState) => ({
      openToggleIndex: prevState.openToggleIndex === index ? null : index,
    }));
  };

  cancel = () => {
    const { dispatch } = this.props;
    this.setState({
      openToggleIndex: 0,
      upvoteClicked: false,
      downvoteClicked: false,
      reportProblemOpen: false,
    });
    dispatch(RESET_FAQ_WINDOW());
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleUpvoteClick = () => {
    this.setState({
      upvoteClicked: true,
      downvoteClicked: false, 
    });
  };

  handleDownvoteClick = () => {
    this.setState({
      upvoteClicked: false,
      downvoteClicked: true,
    });
  };

  addNewlineAfterPeriod(text) {
    return text.replace(/ \d\) /g, '.\n');
  }
  

  render() {
    const { faq: { open, faqData } } = this.props;
    const { openToggleIndex, upvoteClicked, downvoteClicked } = this.state;

    // const upvoteClass = upvoteClicked ? 'green' : 'btn';
    // const downvoteClass = downvoteClicked ? 'red' : 'btn';
    
    return faqData? (
      <Modal
        show={open}
        onHide={this.cancel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-90w custom-modal"
      
      >
        <Modal.Title className='titlefont'>
          <div className="page-title">
            FREQUENTLY ASKED QUESTIONS
          </div>
        </Modal.Title>
        <Modal.Body style={{ padding: '0px', paddingLeft: '10px' }}>
          <TabsContainer style={{ height: '80vh', overflow: 'auto' }}>
          <Tabs className='tab-header1 tab-indicator' tabId="simple-tab">

          {Object.keys(faqData)?.map((category, tabIndex) => (
              <Tab className='tab-font' label={category} key={tabIndex}>
              {faqData[category].map((entry, tabIndex) => <div>
                <div className="accordion">
                  <div className="accordion-item">
                    <button
                      id={`accordion-button-${tabIndex}`}
                      aria-expanded={openToggleIndex === tabIndex}
                      onClick={() => this.handleToggle(tabIndex)}
                    >
                      <span className="accordion-title">{entry?.Question}</span>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                    <div className={`accordion-content ${openToggleIndex === tabIndex ? 'open' : ''}`}>
                    <p>
                        {entry?.Answer.split(/\. (?=(?:www|https?|http):\/\/|\S+)/).map((sentence, sentenceIndex) => (
                          <div key={sentenceIndex} dangerouslySetInnerHTML={{ __html: replaceURLs(sentence) }} />
                        ))}
                      </p>
                      {/* <div className="button-container">
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
                        <span className={upvoteClass} onClick={this.handleUpvoteClick}>
                          <i className="fa fa-thumbs-up fa-lg" aria-hidden="true"></i>
                        </span>
                        <span className={downvoteClass} onClick={this.handleDownvoteClick}>
                          <i className="fa fa-thumbs-down fa-lg" aria-hidden="true"></i>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              )}
              </Tab>
            ))}

          </Tabs>
          </TabsContainer>
        </Modal.Body>
        <Modal.Footer className='footer'>
          <button className='footer querybutton' onClick={
            () => {openReportProblem()
             this.cancel()}
            }>Got Something To Ask ?</button>
         </Modal.Footer>
      </Modal>
    ): null;
  }
}

function mapStateToProps(appState) {
  return {
    faq: appState.faq,
    user: appState.user,
  };
}

export default connect(mapStateToProps)(FrequentlyAskedQuestions);
