import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';

export default () => {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const sfConfig = {
    zipcode: '',
    wind_speed: 110,
    building_code: 2,
    building_height: 30,
    preferred_span: preferred_span || 72,
    snow_load: 5,
    elevation: 5499,
    wind_exposure: 'B',
    rail_finish: 'CLR',
    roof_type: 1,
    rafter_spacing_inches: rafter_spacing_inches || 24,
    client_name: '',
    building_type: gable.value,
    shortest_building_length: 20,
  };
  return sfConfig;
};
