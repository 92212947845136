import * as React from 'react';
import ApplicationRevision from '_containers/ApplicationRevision';
import Bom from '_containers/Bom';
import ChangePassword from '_containers/ChangePassword';
import CombinedProjects from '_containers/CombinedProjects';
import ConfirmClearArraysModal from 'projectDesign/components/confirmClearArraysModal/ConfirmClearArraysModal';
import DesignAssumptions from '_containers/DesignAssumptions';
import DisableAccount from '_containers/DisableAccount';
import Engineering from '_containers/Engineering';
import ForgotPassword from '_containers/ForgotPassword';
import HelioscopeDesignConfig from '_containers/HelioscopeDesignConfig';
import HelioscopeDesigns from '_containers/HelioscopeDesigns';
import HelioscopeImport from '_containers/HelioscopeImport';
import Home from '_containers/Home';
import Loader from '__common/components/LoaderComponent';
import LoginPage from '_containers/LoginPage';
import NotFound from '_containers/NotFound';
import ProjectDesign from '_containers/ProjectDesign';
import RedirectProtected from '__common/modules/RedirectProtected';
import RedirectPublic from '__common/modules/RedirectPublic';
import Registration from '_containers/Registration';
import ReportProblemForm from 'app/components/ReportProblem/ReportProblemForm';
import FrequentlyAskedQuestions from 'app/components/FaqComponent/Faq';
import ResetPassword from '_containers/ResetPassword';
import Router from '__common/modules/ReduxRouter';
import ScreenshotContainer from './ScreenshotContainer';
import TermsofService from '_containers/TermsOfService';
import UserPreferences from '_containers/UserPreferences';
import YourProjects from '_containers/YourProjects';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';
import { Route, Switch } from 'react-router-dom';
import { SET_APP_ERROR } from 'actionsConstants';
import { tryToLogIn } from 'user/user';
import ErrorPageModal from '__common/components/ErrorPageModal';
import { GET_APP_STATUS } from 'app/appActions';
import ExposureRecalculationModal from 'projectDesign/components/ExposureRecalculationModal';
import CalculateZoneClassificationModal from 'projectDesign/components/CalculateZoneClassification'
import InfoModal from 'app/components/InfoModal/InfoModal';
import {googleAnalyticsActions} from '__common/utils/googleAnalytics/google_analytics_init';
import AuroraImport from './AuroraImport';
import AuroraDesignConfig from './AuroraDesignConfig';
const googleAnalyticsTrackingId = require('local_config').googleAnalyticsTrackingId;
import AppHistory from '__common/modules/history';

googleAnalyticsActions.initiateGA(googleAnalyticsTrackingId);

type Props = {
  app: any;
  dispatch: Function;
  user: any;
  location: string;
};

export class App extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    console.log("redirecting!!",window.location.href);
    //console.log(window.location.ancestorOrigins.length>0 && (window.location.ancestorOrigins.item(0).includes('aurorasolar') && window.location.href.includes('/project/design/aurora/')));    

    tryToLogIn();

    dispatch(GET_APP_STATUS());

    const params = new URL(location.href).searchParams;
    const aurora_tenant_id = params.get('aurora_tenant_id');
    const aurora_project_id = params.get('aurora_project_id')
    const aurora_design_id = params.get('aurora_design_id')
    const aurora_user_id = params.get('aurora_user_id')
    const aurora_session_token = params.get('aurora_session_token')
    
    if (aurora_tenant_id && aurora_design_id && aurora_project_id && aurora_user_id && aurora_session_token){
      AppHistory.push(`/project/design/aurora/${aurora_tenant_id}/${aurora_project_id}/${aurora_design_id}/${aurora_user_id}/${aurora_session_token}`)
    }
  }

  componentDidMount() {
    const browser = detect();
    const { dispatch } = this.props;

    switch (browser && browser.name) {
      case 'ie':
        const isIe = true;
        dispatch(SET_APP_ERROR('', '', isIe));
    }
  }

  renderApp() {
    const { dispatch, user } = this.props;
    //const acceptableParentWebSite = 'aurorasolar.com';
    // const isAuroraAccessible =  window.location.ancestorOrigins.length>0 ? (window.location.ancestorOrigins.item(0).includes(acceptableParentWebSite) && window.location.href.includes('/project/design/aurora/')) : (window.location.href.includes("localhost")) ? true : false
    const isAuroraAccessible =  true;

    return (
      <Router dispatch={dispatch}>
        <div key="app" className="app">
          <main className="app__main">
            <CalculateZoneClassificationModal />
            <ConfirmClearArraysModal />
            <ReportProblemForm />
            <FrequentlyAskedQuestions />
            <InfoModal/>
            <ErrorPageModal />
            <ExposureRecalculationModal />
            <Switch>
              <Route exact={true} path="/" component={Home} />
              <RedirectPublic
                component={LoginPage}
                path="/login"
                user={user}
                exact={true}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={Registration}
                path="/registration"
                user={user}
                exact={true}
                dispatch={dispatch}
              />
              <RedirectProtected
                component={HelioscopeImport}
                isAuthenticated={user.isAuthenticated}
                path="/hs/list"
                dispatch={dispatch}
                exact={true}
                user={user}
              />
              <RedirectProtected
                component={HelioscopeDesigns}
                isAuthenticated={user.isAuthenticated}
                path="/hs/project/:projectId"
                dispatch={dispatch}
                exact={true}
                user={user}
              />
              <RedirectProtected
                component={HelioscopeDesignConfig}
                isAuthenticated={user.isAuthenticated}
                path="/hs/design/config/:projectId/:designId/:fieldSegmentId/:productId"
                exact={true}
                dispatch={dispatch}
                user={user}
              />
              <RedirectProtected
                component={HelioscopeDesignConfig}
                isAuthenticated={user.isAuthenticated}
                path="/hs/design/config/:projectId/:designId/:productId"
                exact={true}
                dispatch={dispatch}
                user={user}
              />
              <RedirectProtected
                component={UserPreferences}
                isAuthenticated={user.isAuthenticated}
                path="/userpreferences"
                exact={true}
                dispatch={dispatch}
                user={user}
              />
              <RedirectProtected
                component={YourProjects}
                isAuthenticated={user.isAuthenticated}
                path="/yourprojects"
                exact={true}
                dispatch={dispatch}
                user={user}
              />
              <RedirectProtected
                component={DisableAccount}
                path="/DisableAccount"
                isAuthenticated={user.isAuthenticated}
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectProtected
                component={ChangePassword}
                path="/ChangePassword"
                isAuthenticated={user.isAuthenticated}
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectProtected 
                exact={true} 
                user={user} 
                dispatch={dispatch} 
                isAuthenticated={user.isAuthenticated} 
                path="/project/design/:productId" 
                component={ProjectDesign}
                restrictedUrl={true} 
              />
              <RedirectProtected 
                exact={true} 
                user={user} 
                dispatch={dispatch} 
                isAuthenticated={user.isAuthenticated} 
                path="/project/design/:productId/:projectId" 
                component={ProjectDesign}
                restrictedUrl={true}
              />
              <RedirectProtected 
                exact={true} 
                user={user} 
                dispatch={dispatch} 
                isAuthenticated={true} 
                path="/project/design/aurora/:aurora_tenant_id/:aurora_project_id/:aurora_design_id/:aurora_user_id/:aurora_session_token" 
                component={AuroraImport}
                restrictedUrl={false}
                accessibleUrl={isAuroraAccessible}
              />
              <RedirectPublic
                component={ScreenshotContainer}
                path="/project/screenshot/:productId/:projectId/:arrayId"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectProtected
                component={Bom}
                path="/project/bom/:productId/:projectId"
                exact={true}
                user={user}
                dispatch={dispatch}
                isAuthenticated={user.isAuthenticated}
                restrictedUrl={true} 
              />
              <RedirectProtected
                component={Engineering}
                path="/project/engineering/:productId/:projectId"
                exact={true}
                user={user}
                dispatch={dispatch}
                isAuthenticated={user.isAuthenticated} 
                restrictedUrl={true}
              />
              <RedirectProtected
                component={AuroraDesignConfig}
                isAuthenticated= {user.isAuthenticated}
                path="/aurora/design/config/:projectId/:designId/"
                exact={true}
                dispatch={dispatch}
                user={user}
              />
              <RedirectPublic
                component={CombinedProjects}
                path="/combinedprojects/:combinedProjectId"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={NotFound}
                path="/projectNotFound"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={DesignAssumptions}
                path="/designassumptions"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={ResetPassword}
                path="/resetpassword"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={ApplicationRevision}
                path="/applicationrevision"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={TermsofService}
                path="/termsofservice"
                exact={true}
                user={user}
                dispatch={dispatch}
              />
              <RedirectPublic
                component={ForgotPassword}
                path="/forgotpassword/:uid?/:token?"
                exact={false}
                user={user}
                dispatch={dispatch}
              />
              <Route component={NotFound} />
            </Switch>
          </main>
        </div>
      </Router>
    );
  }

  renderLoader() {
    return (
      <>
        <Loader />
      </>
    );
  }

  render() {
    const {
      user: { isRunning },
      app: { searchProject, copyProject },
    } = this.props;

    if (isRunning || searchProject || copyProject) {
      return this.renderLoader();
    }

    return this.renderApp();
  }
}

/* istanbul ignore next */
function mapStateToProps(state: appState) {
  return {
    app: state.app,
    user: state.user,
    location: state.router.location.pathname,
  };
}

export default connect(mapStateToProps)(App);
