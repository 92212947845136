import React from 'react';
import { dispatch, state } from '__common/store';
import { HIDE_FAQ_WINDOW, POPULATE_FAQ_DATA, SHOW_FAQ_WINDOW } from './FaqActions';
import { Button } from 'react-md';

export const openFaq = () => {
  const { faq: { open }, user } = state();
  dispatch(POPULATE_FAQ_DATA());

  if (open) {
    dispatch(HIDE_FAQ_WINDOW());
  } else {
    dispatch(SHOW_FAQ_WINDOW());
  };
}