
import { RubberBandActionTypes } from 'actionsConstants';

export function START(x: number, y: number) {
  return {
    type: RubberBandActionTypes.START,
    payload: {
      start: {
        x,
        y,
      },
    },
  };
}

export function RESIZE(points: any) {
  return {
    type: RubberBandActionTypes.RESIZE,
    payload: {
      points,
    },
  };
}

export function RESET_RUBBERBAND_STATE() {
  return {
    type: RubberBandActionTypes.RESET_RUBBERBAND_STATE,
  };
}

export function ADD_SHADOW_PANELS(panelsShadow: any) {
  return {
    type: RubberBandActionTypes.ADD_SHADOW_PANELS,
    payload: {
      panelsShadow,
    },
  };
}

export function SET_DRAW_RUBBER_BAND() {
  return {
    type: RubberBandActionTypes.SET_DRAW_RUBBER_BAND,
  };
}

export function DISABLE_RUBBER_BAND() {
  return {
    type: RubberBandActionTypes.DISABLE_RUBBER_BAND,
  };
}

export function SET_REMOVE_RUBBER_BAND() {
  return {
    type: RubberBandActionTypes.SET_REMOVE_RUBBER_BAND,
  };
}

export function SET_PANELS_TO_HIDE_ON_REMOVE(panelIdsToRemove: number[]) {
  return {
    type: RubberBandActionTypes.SET_PANELS_TO_HIDE_ON_REMOVE,
    payload: {
      panelsToRemove: panelIdsToRemove,
    },
  };
}

