import { isRMFamily, isSFMSandSMF, isGroundProduct, isNxtHorizon } from "__common/constants/products";

export const drawRoofButtonToolTipText = (productId) => {
  const roofAreaName = `${isGroundProduct(productId)? 'site': 'roof'} area`;
  return `
    ${isSFMSandSMF(productId) || isRMFamily(productId)? additionalTooltipTextRM_SFM_SM: ''}
    <p><b>Tip&#8594;</b> The first line drawn defines the array alignment. All modules will align to face this edge.</p>
    <p><b>Tip&#8594;</b> To change the array alignment edge, click a new edge of the ${roofAreaName}.</p>
  `;
};


export const nxtButylToolTipText = (productId) => {
 if (isNxtHorizon(productId)) {
  return  `<p><b>Tip&#8594;</b> Added new attachment type STRONGHOLD ATT W/BUTYL </p>`
 }
}

const additionalTooltipTextRM_SFM_SM = 
`<p><b>Tip&#8594;</b> For most accurate result outline entire building roof.</p>`;
