import React from 'react';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import NumberField from 'projectDesign/components/projectConfiguration/components/components/numberField';
import SelectField from 'projectDesign/components/projectConfiguration/components/components/selectField';
import { validateProjectOptions } from '../utils/validation';
import { updateProjectOption } from '../utils/updateProjectOption';
import RadioField from './components/radioField';
import CheckboxField from './components/checkboxField';

interface FieldBlock {
  label: string;
  fields: (selectBlock | numberBlock)[];
}

function isRadioBlock(fieldBlock: radioBlock | FieldBlock | checkboxBlock): fieldBlock is radioBlock {
  return (fieldBlock as radioBlock).type === 'Radio';
}

function isSelectBlock(field: numberBlock | selectBlock): field is selectBlock {
  return field.type === 'Select';
}

function isCheckboxBlock(fieldBlock: checkboxBlock | FieldBlock): fieldBlock is checkboxBlock{
  return (fieldBlock as checkboxBlock).type === 'Checkbox';
}

export const renderInputs = (
  fieldBlock: radioBlock | FieldBlock | checkboxBlock,
  mapType: string,
  location: string,
  initData: projectEnvConfig,
  productId: number,
  isFieldInvalidFunction: (fieldName: string) => string | null,
  warningMessageFunction?: any,
) => {
  
  if (isRadioBlock(fieldBlock)) {
    return (
      <RadioField
        fieldBlock={fieldBlock}
        value={String(initData[fieldBlock.apiField])}
        updateProjectOption={(field: string, value: any) => updateProjectOption(field, value, initData.zipcode, productId,  initData)}
      />
    );
  }

  if (isCheckboxBlock(fieldBlock)) {
    return(
      <CheckboxField 
        fieldBlock={fieldBlock}
        isSelected={initData[fieldBlock.apiField]}
        updateProjectOption={(field: string, value: any) => updateProjectOption(field, value, initData.zipcode, productId,  initData)}
      />
    );
  }

  return (
    <div className="project-configuration-basic-info fields">
      {fieldBlock.fields.map((field, index: number): JSX.Element => {
        let value = initData[field.apiField];
        let tooltip;
        const errorMessage = isFieldInvalidFunction(field.apiField);
        const warningMessage = warningMessageFunction ? warningMessageFunction(field.apiField) : null;

        if (field.label === clientAddress.label && mapType !== 'white') {
          value = initData[field.apiField] || location;
          tooltip = value;
        }

        if (isSelectBlock(field)) {
          return (
              <SelectField
                key={field.apiField}
                updateProjectOption={(field: string, newValue: any) => updateProjectOption(field, newValue, initData.zipcode, productId,  initData)}
                field={field}
                index={index}
                value={String(value)}
                error={errorMessage}
                warn={warningMessage}
                disable={field?.disable ? field?.disable : false}
              />);
        }

        if (field.type === 'Number') {
          return (
              <NumberField
                key={field.apiField}
                updateProjectOption={(field: string, newValue: any) => updateProjectOption(field, Number(newValue), initData.zipcode, productId,  initData)}
                field={field}
                index={index}
                value={value}
                error={errorMessage}
                warn={warningMessage}
                step={field.step}
                validationOnBlur={validateProjectOptions.bind(null, field.apiField)}
                disable={field?.disable ? field?.disable : false}
              />
          );
        }

        if (field.type === 'Text') {
          
          return (
            <NumberField
              key={`${field.apiField}`}
              updateProjectOption={(field: string, newValue: any) => updateProjectOption(field, newValue, initData.zipcode, productId,  initData)}
              field={field}
              index={index}
              value={value}
              error={errorMessage}
              warn={warningMessage}
              tooltip={tooltip}
              type="text"
              validationOnBlur={validateProjectOptions.bind(null, field.apiField)}
              disable={field?.disable ? field?.disable : false}
            />
          );
        }
      })}
    </div>
  );
};

export const renderInputsForHelioscope = (
  fieldBlock: radioBlock | FieldBlock | checkboxBlock,
  mapType: string,
  location: string,
  initData: projectEnvConfig,
  productId: number,
  helioInputOnChange: Function = null,
  isFieldInvalidFunction: (fieldName: string) => string | null,
  warningMessageFunction?: any,
) => {
  if (isRadioBlock(fieldBlock)) {
    return (
      <RadioField
        fieldBlock={fieldBlock}
        value={String(initData[fieldBlock.apiField])}
        updateProjectOption={(field: string, value: any) => helioInputOnChange(field, value)}
      />
    );
  }

  if (isCheckboxBlock(fieldBlock)) {
    return(
      <CheckboxField 
        fieldBlock={fieldBlock}
        isSelected={initData[fieldBlock.apiField]}
        updateProjectOption={(field: string, value: any) => helioInputOnChange(field, value)}
      />
    );
  }

  return (
    <div className="project-configuration-basic-info fields">
      {fieldBlock.fields.map((field, index: number): JSX.Element => {
        let value = initData[field.apiField];
        let tooltip;
        const errorMessage = isFieldInvalidFunction?.(field.apiField) ?? '';
        const warningMessage = warningMessageFunction ? warningMessageFunction(field.apiField) : null;

        if (field.label === clientAddress.label && mapType !== 'white') {
          value = initData[field.apiField] || location;
          tooltip = value;
        }

        if (isSelectBlock(field)) {
          return (
              <SelectField
                key={field.apiField}
                updateProjectOption={(field: string, newValue: any) => helioInputOnChange(field, newValue)}
                field={field}
                index={index}
                value={String(value)}
                error={errorMessage}
                warn={warningMessage}
              />);
        }

        if (field.type === 'Number') {
          return (
              <NumberField
                key={field.apiField}
                updateProjectOption={(field: string, newValue: any) => helioInputOnChange(field, Number(newValue))}
                field={field}
                index={index}
                value={value}
                error={errorMessage}
                warn={warningMessage}
                step={field.step}
                validationOnBlur={validateProjectOptions.bind(null, field.apiField)}
              />
          );
        }

        if (field.type === 'Text') {
          return (
            <NumberField
              key={`${field.apiField}`}
              updateProjectOption={(field: string, newValue: any) => helioInputOnChange(field, newValue)}
              field={field}
              index={index}
              value={value}
              error={errorMessage}
              warn={warningMessage}
              tooltip={tooltip}
              type="text"
              validationOnBlur={validateProjectOptions.bind(null, field.apiField)}
            />
          );
        }
      })}
    </div>
  );
};


export const renderInputsForAurora = (
  fieldBlock: radioBlock | FieldBlock | checkboxBlock,
  mapType: string,
  location: string,
  initData: projectEnvConfig,
  productId: number,
  helioInputOnChange: Function = null,
  isFieldInvalidFunction: (fieldName: string) => string | null,
  warningMessageFunction?: any,
) => {
  if (isRadioBlock(fieldBlock)) {
    return (
      <RadioField
        fieldBlock={fieldBlock}
        value={String(initData[fieldBlock.apiField])}
        updateProjectOption={(field: string, value: any) => helioInputOnChange(field, value)}
      />
    );
  }

  if (isCheckboxBlock(fieldBlock)) {
    return(
      <CheckboxField 
        fieldBlock={fieldBlock}
        isSelected={initData[fieldBlock.apiField]}
        updateProjectOption={(field: string, value: any) => helioInputOnChange(field, value)}
      />
    );
  }

  return (
    <div className="project-configuration-basic-info fields">
      {fieldBlock.fields.map((field, index: number): JSX.Element => {
        let value = initData[field.apiField];
        let tooltip;
        const errorMessage = isFieldInvalidFunction?.(field.apiField) ?? '';
        const warningMessage = warningMessageFunction ? warningMessageFunction(field.apiField) : null;

        // if (field.label === clientAddress.label && mapType !== 'white') {
        //   value = initData[field.apiField] || location;
        //   tooltip = value;
        // }

        if (isSelectBlock(field)) {
          return (
              <SelectField
                key={field.apiField}
                updateProjectOption={(field: string, newValue: any) => helioInputOnChange(field, newValue)}
                field={field}
                index={index}
                value={String(value)}
                error={errorMessage}
                warn={warningMessage}
              />);
        }

        if (field.type === 'Number') {
          return (
              <NumberField
                key={field.apiField}
                updateProjectOption={(field: string, newValue: any) => helioInputOnChange(field, Number(newValue))}
                field={field}
                index={index}
                value={value}
                error={errorMessage}
                warn={warningMessage}
                step={field.step}
                validationOnBlur={validateProjectOptions.bind(null, field.apiField)}
              />
          );
        }

        if (field.type === 'Text') {
          return (
            <NumberField
              key={`${field.apiField}`}
              updateProjectOption={(field: string, newValue: any) => helioInputOnChange(field, newValue)}
              field={field}
              index={index}
              value={value}
              error={errorMessage}
              warn={warningMessage}
              tooltip={tooltip}
              type="text"
              validationOnBlur={validateProjectOptions.bind(null, field.apiField)}
            />
          );
        }
      })}
    </div>
  );
};