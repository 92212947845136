import { exposablePanelTexture, panelTexture } from  '__editor/panelsEditor/models/utils/panelTexture';
import { canUseExposure } from '__common/components/exposure/exposureHelper';
import { state } from '__common/store';
import { isRMGridFlex } from '__common/constants/products';
import { isASCE722 } from '__common/constants/buildingCodes';

export function getPanelTexture(
  width: number, 
  height: number, 
  roofZone: roofZoneNumber, 
  nearObstruction: boolean, 
  exposed: boolean, 
  exposedNeighbour: boolean, 
  id: number | string,
  attached: boolean,
  panelConfig: number = undefined,
  skewAffected?: number,
) {
  const {
    projectConfiguration: {
      productId,
      projectEnvConfig: {
        building_code,
      },
    },
  } = state();
  if (canUseExposure(building_code, productId)) {
    return exposablePanelTexture(
      width,
      height,
      roofZone,
      nearObstruction,
      exposed,
      exposedNeighbour,
      id = isASCE722(building_code) ? null: id,
      panelConfig,
      skewAffected,
    );
  }
  
  return panelTexture(
    width,
    height,
    roofZone,
    nearObstruction,
    id = isASCE722(building_code) ? null: id,
    attached,
    isRMGridFlex(productId),
    panelConfig,
    skewAffected,
  );
}
