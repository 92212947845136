import {SMTiltPRBuildingHeight } from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import { smTiltPRRoofType } from 'projectDesign/components/projectConfiguration/fields/roofType';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import snowLoad from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import smTiltPrSpans from 'projectDesign/components/projectConfiguration/fields/span';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import { asce716WindSpeedForSfmAndSm } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkEnvironmentalFactors } from 'projectDesign/components/projectConfiguration/utils/checkEnvironmentalFactors';
import { checkLongestBuildingLength, checkSeismic, checkShortestBuildingLength, checkBuildingHeight, checkSeismicSds, checkTornadoSpeed } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { state } from '__common/store';
import { getASCE716722LocationsResidentalAdditionalInputs } from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { smMidAndEndClamps } from 'projectDesign/components/projectConfiguration/fields/smClamps';
import { asce716and722BuildingCode } from '../fields/buildingCode';
import getSoilClassField from 'projectDesign/components/projectConfiguration/fields/soilClass';
import parapetHeight from '../fields/rmIFIParapetHeight';
import longestBuildingLength from '../fields/longestBuildingLength';
import shortestBuildingLength from '../fields/shortestBuildingLength';
import railFinish from 'projectDesign/components/projectConfiguration/fields/railFinish';
import { isASCE722 } from '__common/constants/buildingCodes';
import seismicSds from '../fields/seismicSds';
import { riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import tornadoSpeed from '../fields/tornadoSpeed';


function getWindSpeedField(buildingCodeValue: number) {
  return asce716WindSpeedForSfmAndSm;
}
export default (buildingCodeValue: number) => {
  const {
    projectConfiguration: {
      projectEnvConfig: {
        seismic_ss, building_height, building_length, shortest_building_length, seismic_sds, risk_category, tornado_speed
      }, productId
    },
  } = state();

  const tornadoSpeedField = isASCE722(buildingCodeValue) && riskCategoryCheckForTornadoSpeed(risk_category) ? tornadoSpeed : [];
  const buildingCodeField = asce716and722BuildingCode();
  const windSpeed = getWindSpeedField(buildingCodeValue);
  const buildingHeight = SMTiltPRBuildingHeight
  checkEnvironmentalFactors(buildingCodeField, windSpeed, snowLoad, seismicSs, elevation);
  let soilClass = getSoilClassField(buildingCodeValue, productId);
  soilClass['label']='site_class';
  seismicSs.missing = !checkSeismic(seismic_ss);
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  buildingHeight.missing = !checkBuildingHeight(building_height * 1);
  longestBuildingLength.missing = !checkLongestBuildingLength(building_length * 1);
  shortestBuildingLength.missing = !checkShortestBuildingLength(shortest_building_length * 1);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);const seismicSdsField = isASCE722(buildingCodeValue) ? seismicSds : [];
  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCodeField,
        WindExposure,
        windSpeed,
        snowLoad,
        elevation,
        seismicSs,
        seismicSdsField,
        soilClass,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
        tornadoSpeedField,
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        smMidAndEndClamps('mid_clamps_choices','mid_clamps'),
        smMidAndEndClamps('end_clamps_choices','end_clamps'),
        smTiltPRRoofType,
        parapetHeight(),
        shortestBuildingLength,
        longestBuildingLength
      ],
    },
  Spans: {
    label: '',
    fields: [
      smTiltPrSpans,
    ],
  },
  railFinish,
  railTypes: true,
};
  return cfg;
};