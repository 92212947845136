
import * as PIXI from 'pixi.js';
import store from '__common/store';

export const rubberBandModel = (points: pixelPoint[]) => {
  const settings = store.getState().settings;
  const rubberBand: PIXI.Graphics = new PIXI.Graphics();
  rubberBand.id = 'rubberBand';
  rubberBand.lineStyle(1, settings.rubberBandColor);
  const preparedPoints = points.reduce((acc, point) => {
    acc.push(point.x);
    acc.push(point.y);
    return acc;
  },                                   []);
  preparedPoints.push(points[0].x, points[0].y);
  rubberBand.drawPolygon(preparedPoints);
  rubberBand.endFill();
  rubberBand.zIndex = 9999;
  return rubberBand;
};

export default rubberBandModel;
