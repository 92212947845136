import { dispatch, state } from '__common/store';
import { RESET_DRAWING_MANAGER } from 'actions';
import { resetBingMapsEditor } from '__editor/bingMapsRoofsSelector/bingMapsRoofsSelectorHelper';
import { resetGoogleMapsEditor } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorHelper';
export const emptyRoofColor = '#CF5232';

const fullRoofColor = '#0A3C5D';

export const getRoofColor = (roofId: number) => {
  return roofHasPanels(roofId) ? fullRoofColor : emptyRoofColor;
};

export const roofHasPanels = (roofId: number) => {
  const { drawingManager: { roofs } } = state();
  return roofId && roofs && roofs[roofId] && roofs[roofId].panels && roofs[roofId].panels.length;
};

export function resetEditor() {
  resetBingMapsEditor();
  resetGoogleMapsEditor();
  dispatch(RESET_DRAWING_MANAGER());
}
