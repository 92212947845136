import keyMirror from 'keymirror';


export const YourProjectsActionTypes = keyMirror({
  LOAD_YOUR_PROJECTS_DATA: undefined,
  SET_ROWS_PER_PAGE: undefined,
  SET_INFO_DATA: undefined,
  FILTER_BY_STRING: undefined,
  GET_USER_PROJECTS: undefined,
  INIT_USER_PROJECTS: undefined,
  SET_YOUR_PROJECTS_PAGINATION_START: undefined,
  SET_YOUR_PROJECTS_PAGINATION_TOTAL: undefined,
  SET_YOUR_PROJECTS_TABLE_PAGE: undefined,
  SET_YOUR_PROJECTS_SORTING: undefined,
  DELETE_PROJECT: undefined,
  SET_SELECTED_PROJECTS: undefined,
  COMBINE_PROJECT: undefined,
  REDIRECT_TO_COMBINED_PROJECT: undefined,
  GET_COMBINED_PROJECT: undefined,
  POPULATE_COMBINED_PROJECTS: undefined,
  YOUR_PROJECTS_LOAD_PREFERENCES: undefined,
  SHOW_NEW_PROJECTS: undefined,
  SHOW_OLD_PROJECTS: undefined,
  CANNOT_GET_PROJECTS: undefined,
  CHECK_UB1_PROJECTS: undefined,
  CLEAR_YOUR_PROJECT: undefined,
  CHECK_UB1_PROJECTS_FAILED: undefined,
  SET_UB1_PROJECTS: undefined,
  SET_PROJECT_CREATED_LOWER_RANGE: undefined,
  SET_PROJECT_CREATED_UPPER_RANGE: undefined,
  SET_PROJECT_WATTAGE_LOWER_RANGE: undefined,
  SET_PROJECT_WATTAGE_UPPER_RANGE: undefined,
  SET_PROJECT_PRICE_LOWER_RANGE: undefined,
  SET_PROJECT_PRICE_UPPER_RANGE: undefined,
  SET_PROJECT_STATE: undefined,
  SET_MODULE: undefined,
  SET_PROJECT_PRODUCT: undefined,
  CLEAR_YOUR_PROJECTS_FILTER_DATA: undefined,
  SET_FILTER_DATA: undefined,
  APPLY_FILTERS: undefined
});
