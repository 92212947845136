import { dispatch, state } from '__common/store';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { isRMIFIProduct, isSMTiltPR, isAscender, isRmGridflex10 } from '__common/constants/products';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { SET_ROW_SPACING } from '../settings/settingsActions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

export const getTiltedModuleSizes = () => {
  const { background: { metersPerPixel },
          moduleSelector: { modelData: { height, width } }, 
          projectConfiguration: { projectEnvConfig: { tilt }, inputUnit, productId },
          drawingManager : { roofs },
          tiltedRoof : { structureTilt } } = state();
  let moduleWidth = width;
  if (tilt && (isRMIFIProduct(productId) || isRmGridflex10(productId))) {
    const radiansTilt = degreesToRadians(tilt);
    moduleWidth = width * Math.cos(radiansTilt);
  }

  let module_height, module_width;

  if (isSMTiltPR(productId) && Object.keys(roofs).length > 0) {
    let newStructureTilt = structureTilt === "---" ? 0 : Number(structureTilt)
    const radiansTilt = degreesToRadians(newStructureTilt);
    moduleWidth = width * Math.cos(radiansTilt);
  }
  
  if(isAscender(productId) || isMetricUnit(inputUnit)) {
    module_width = cmsToMeters(Number(moduleWidth))/ metersPerPixel;
    module_height = cmsToMeters(Number(height)) / metersPerPixel;
  }
  else {
     module_width = inchesToMeters(Number(moduleWidth)) / metersPerPixel;
    module_height = inchesToMeters(Number(height)) / metersPerPixel;
  }
  
  return {
    width: module_width,
    height: module_height,
  };
};


export const getTiltedModuleRowSpacings = (tilt) => {
  const { background: { cords }, moduleSelector: { modelData: { width } } } = state();
  const lat = Math.abs(cords.lat);
  const sunAngle = degreesToRadians(90 - (lat + 23.5));
  const radiansTilt = degreesToRadians(tilt);
  const backEdgeHeight = width * Math.sin(radiansTilt);
  return backEdgeHeight / Math.tan(sunAngle);
};

export const getTiltedModuleTableRowSpacings = (tilt, tableHeight) => {
  const { settings: { rowSpacing }, background: { cords }, moduleSelector: { modelData: { width } } } = state();
  const lat = Math.abs(cords.lat);
  const sunAngle = degreesToRadians(90 - (lat + 23.5));
  const radiansTilt = degreesToRadians(tilt);
  const backEdgeHeight = (((width * tableHeight) + (rowSpacing * (tableHeight - 1))) * Math.sin(radiansTilt));
  let tableRowSpacing = backEdgeHeight / Math.tan(sunAngle);
  tableRowSpacing = tableRowSpacing >=12 ? tableRowSpacing : 12;
  return inchesToMeters(tableRowSpacing);
};


export const getTiltedModuleTableRowSpacingsGFT = (tilt) => {
  const { background: { cords }, moduleSelector: { modelData: { height } }, projectConfiguration: { projectEnvConfig: { clamp_type } } } = state();
  const tableHeight = 2;
  const lat = Math.abs(cords.lat);
  const sunAngle = degreesToRadians(90 - (lat + 30));
  const radiansTilt = degreesToRadians(tilt);
  let ns_module_gap;
  if (clamp_type === '11') {
     ns_module_gap = 1;
  } else if (clamp_type === '04') {
     ns_module_gap = 0.5;
  } else {
     ns_module_gap = 0.25;
  }
  const backEdgeHeight = (((height * 2) + (ns_module_gap * (tableHeight - 1))) * Math.sin(radiansTilt));
  let tableRowSpacing = backEdgeHeight / Math.tan(sunAngle);
  tableRowSpacing = tableRowSpacing >=12 ? tableRowSpacing : 12;
  return inchesToMeters(tableRowSpacing);
};

export const getSmTiltPRRowSpacing = (structureTilt) => {
  if (structureTilt == 0 || structureTilt === 5 || structureTilt === 15) {
    dispatch(SET_ROW_SPACING(inchesToMeters(8)))
  } else {
    dispatch(SET_ROW_SPACING(inchesToMeters(12)))
  }
}
