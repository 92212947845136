import centerMarker from '__editor/googleMapsRoofsSelector/models/centerMarker';
import selectedArea from '__editor/googleMapsRoofsSelector/models/selectedArea';
import { clickedRoofEdgeData, SOUTH_EDGE_CHANGE_WARNING } from '__editor/components/roofsSelector/components/roofsSelectorLeadEdge/utils/roofsSelectorSelectEdge';
import { dispatch, state } from '__common/store';
import { drawingManagerSettings } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerConfigs';
import { emptyRoofMarkerIcon } from '__editor/bingMapsRoofsSelector/models/centerMarker';
import { getMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { panelsEditorEnabled } from '__editor/panelsEditor/panelsEditorHelper';
import { savePanelsEditor } from '__editor/panelsEditor/components/panels/panels';
import { setDraggableCorners, unsetPolygons } from './utils/roofDraggableCorners';
import {
  clearAllGoogleLeadEdgeObject,
  clearGoogleLeadEdgeObject,
  makeGoogleLeadEdgeEmpty,
} from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/leadEdgeRoofSelector';
import {
  createGoogleMapsInfoWindow,
  getSelectedAreaPoints,
  unselectSelectingRoofTool,
  createGoogleMapsRoofAreaAzimuthInfoWindow,
} from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import {
  emptyRoofColor,
  roofHasPanels,
} from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import {
  isClickOnRoofEdge,
  selectRoofLeadEdge,
} from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/utils/selectEdge';
// import { editAndDeleteArray } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/editAndDeleteArrayComponent';
import {
  ACTIVE_PANELS_EDITOR,
  ADD_ROOF,
  CLEAR_ROOF_LEAD_EDGE,
  CLEAR_SELECTION,
  DELETE_ALL_LEAD_EDGES,
  DELETE_ALL_ROOFS,
  DELETE_ROOF,
  SET_SELECTED_ROOF_AND_MARKER,
  UNSELECT_ALL_SELECTED_ROOF_AND_MARKER,
  UNSET_SELECTED_ROOF_AND_MARKER,
  OPEN_CONFIRM_CLEAR_ARRAYS_MODAL,
  DELETE_PANELS_INSIDE_OF_ROOF,
  SELECT_ROOF_LEAD_EDGE,
} from 'actions';
import { goToPanelsEditor } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { roofHasObstructions } from '__editor/panelsEditor/components/obstructions/obstructions';
import { launchRecheckPanelExposure } from '__editor/panelsEditor/components/panels/utils/exposureRecheck';
import { get } from 'lodash';
import { convertToPixelPoints, getLeadEdge } from '__editor/panelsEditor/components/leadEdge/leadEdge';
import { getRoofAreaAzimuth } from '__editor/panelsEditor/components/leadEdge/utils/roofAreaAzimuth';
import $ from 'jquery';
import { isModuleSelectorValid } from '__common/components/moduleSelector/moduleSelectorHelper';
import roofDimension from '__editor/googleMapsRoofsSelector/models/roofDimension';

let drawingManager: google.maps.drawing.DrawingManager;

let roofsOnMap: { [roofId: string]: googleRoofsObject } | null = null;

export function getRoofOnMap(roofId: number) {
  if (roofsOnMap && Object.keys(roofsOnMap).length) {
    return roofsOnMap[roofId];
  }
}

export function clearGoogleMapsSelection() {
  if (roofsOnMap) {
    Object.keys(roofsOnMap).map((roofId: string) => {
      roofsOnMap[roofId].roof.overlay.editMode = false;
      unsetPolygons(roofsOnMap[roofId].roof);
    });
  }
  dispatch(UNSELECT_ALL_SELECTED_ROOF_AND_MARKER());
}

export function goToGooglePanelsEditor(roof: google.maps.Polygon, roofId: number) {
  if (panelsEditorEnabled()) {
    const { panels: { panels } } = state();
    const panelsIdsToRecheck = panels.map(panel => panel.id);

    return launchRecheckPanelExposure(panelsIdsToRecheck, roofId, () => {
      savePanelsEditor();
      activeEditor(roof, roofId);
    });
  }

  activeEditor(roof, roofId);
}

let roofOver;

export function getGoogleRoofOver() {
  return roofOver;
}

export function setGoogleMapInfoWindow(roofId: number, previewMode: boolean = false) {
  const map = getMap();
  if (!roofsOnMap || !roofsOnMap[roofId]) return; // skip update
  if (roofsOnMap[roofId].infoWindow) {
    roofsOnMap[roofId].infoWindow.close();
  }
  const {
    drawingManager: { roofs },
    background : { selectedRoofId },
  } = state();

  const hasPanels =
    roofs &&
    roofs[roofId] &&
    roofs[roofId].panels &&
    roofs[roofId].panels.length &&
    !previewMode;

  const infoWindow = createGoogleMapsInfoWindow(
    roofId,
    get(roofs, [roofId, 'roofName'], ''),
    get(roofs, [roofId, 'panels', 'length'], 0),
    roofsOnMap[roofId].roof.overlay.getRoofApproximateCenter(),
    hasPanels,
    map,
    previewMode,
  );
  if (infoWindow) {
    infoWindow.open(map);
    roofsOnMap[roofId].infoWindow = infoWindow;
  }
  if (infoWindow && roofId === selectedRoofId){
    roofsOnMap[selectedRoofId].infoWindow.close();
  }
  if (infoWindow && roofId !== selectedRoofId && selectedRoofId){
    if (roofsOnMap && Object.keys(roofsOnMap).length>0){
      const roofsArray = Object.keys(roofsOnMap)
      if ((roofsArray.indexOf(selectedRoofId.toString()) != -1)){
        roofsOnMap[selectedRoofId].infoWindow.close();
      }
    }
  }
}

export function getLeadEdgeMiddlePoint(leadEdgeCoords: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral {
  const bounds = new google.maps.LatLngBounds();
  leadEdgeCoords.map(edge => bounds.extend(edge));
  return bounds.getCenter().toJSON();
}

export function setGoogleMapAzimuthInfo(roofId: number, leadEdgeCoords: google.maps.LatLngLiteral[], previewMode: boolean = false) {
  if (!roofsOnMap || !roofsOnMap[roofId] || previewMode) return; // skip update
  if (roofsOnMap[roofId].azimuth && roofsOnMap[roofId].azimuth.infoWindow) {
    roofsOnMap[roofId].azimuth.infoWindow.close();
  }
  const map = getMap();

  const roof = roofsOnMap[roofId].roof;
  const roofCenter = roof.overlay.getRoofApproximateCenter().toJSON();
  const { roofsSelectorMap: { zoom } } = state();
  const roofEdges = getSelectedAreaPoints(roof.overlay);
  const middlePoint = getLeadEdgeMiddlePoint(leadEdgeCoords); 
  const leadEdgeCords = convertToPixelPoints(leadEdgeCoords, roofCenter, zoom);
  const azimuth = getRoofAreaAzimuth(leadEdgeCords, roofEdges, roofCenter, zoom);

  roofsOnMap[roofId].azimuth = {};

  const azimuthWindow = createGoogleMapsRoofAreaAzimuthInfoWindow(roofId, azimuth, middlePoint);

  if(azimuthWindow) {
    const domReadyListener = google.maps.event.addListener(azimuthWindow, 'domready', () => {
      const container = $(`.azimuth-info-roof-${roofId}`).closest('.gm-style-iw').parent();
      container.addClass('google-maps-azimuth-info-window');
    });
    azimuthWindow.open(map);
    roofsOnMap[roofId].azimuth.infoWindow = azimuthWindow;
    roofsOnMap[roofId].azimuth.domReadyListener = domReadyListener;
  }
}

export function setRoofDimensions(roofDimensions: any[], map: google.maps.Map|null) {
  if (roofDimensions && roofDimensions.length) {
    roofDimensions.forEach((dimension) => {
      dimension.setMap(map);
    });
  }
}

export function roofAreaMarkerLatLngs(coords: { lat: number; lng: number }[],
  roofId?: number){
  const roof = selectedArea(coords, roofId);
  const { lat, lng } = roof.overlay.getRoofApproximateCenter();
  return {checkingMarker: { lat:lat(), lng: lng() }}
  }

export function createRoofArea(
  coords: { lat: number; lng: number }[],
  roofId?: number,
  previewMode?: boolean,
) {
  const map = getMap();
  unselectSelectingRoofTool(drawingManager);

  const roof = selectedArea(coords, roofId);

  const marker = centerMarker(roof, map);

  roof.clickListener = google.maps.event.addListener(
    roof.overlay,
    'click',
    event => roofClickEvent(event, roof.id, previewMode),
  );
  roof.clickListener = google.maps.event.addListener(
    roof.overlay,
    'mouseover',
    event => {
      roofOver = roof.id;
    },
  );
  marker.clickListener = google.maps.event.addListener(marker, 'click', () =>{
  if (isModuleSelectorValid()) {
    goToPanelsEditor(roof.overlay, roof.id);
  }
  });

  if (roofsOnMap === null) roofsOnMap = {};
  roofsOnMap[roof.id] = { 
    roof,
    marker,
  };

  setGoogleMapInfoWindow(roof.id, previewMode);

  const leadEdgePath = getLeadEdge(roof.id, coords);

  if(!previewMode) {
    const dimensions = roofDimension(coords, roofHasPanels(roof.id));
    setRoofDimensions(dimensions, map);
    roofsOnMap[roof.id].dimension = dimensions;
  }

  setGoogleMapAzimuthInfo(roof.id, leadEdgePath, previewMode);

  roof.overlay.setMap(map);
  return {
    coords,
    marker: marker.getPosition().toJSON(),
    id: roof.id,
    roof,
    markerOverlay: marker,
  };
}

export function addNewRoofArea(
  roofCoords: { lat: number; lng: number }[],
  roofCoordsPixi: { x: number; y: number }[],
  roofId?: number,
) {
  const { coords, marker, id } = createRoofArea(roofCoords, roofId);
  dispatch(ADD_ROOF(coords, roofCoordsPixi, marker, id));

  return id;
}

export function redrawGoogleRoof(roofId: number) {
  const { drawingManager: { roofs }, leadEdgeRoofSelector: { leadEdges } } = state();
  const coords = roofs[roofId].coords;
  const mapPixiCoords = roofs[roofId].mapPixiCoords;
  const leadEdge = leadEdges[roofId];
  deleteGoogleRoof(roofId);

  addNewRoofArea(coords, mapPixiCoords, roofId);

  dispatch(SELECT_ROOF_LEAD_EDGE(leadEdge, roofId));
}

export function redrawGoogleRoofs() {
  const {
    drawingManager: { roofs },
  } = state();
  if (roofsOnMap && Object.keys(roofsOnMap).length && roofs && Object.keys(roofs).length) {
    Object.keys(roofs).map(roofId => {
      redrawGoogleRoof(Number(roofId));
    });
  }
}
export function updateRoofDimensions(roofId: number, enableEdit: boolean) {
  const map = getMap();
  const { drawingManager: { roofs } } = state();
  if(roofsOnMap[roofId].dimension){
    setRoofDimensions(roofsOnMap[roofId].dimension, null);
  }
  if(!enableEdit) {
    const dimensions = roofDimension(roofs[roofId].coords, roofHasPanels(roofId));
    roofsOnMap[roofId].dimension = dimensions;
    setRoofDimensions(roofsOnMap[roofId].dimension, map);
  }
}

export function roofClickEvent(event, roofId: number, preview: boolean) {
  const {
    drawingManager: { editEnabled },
  } = state();
  const clickRoofEdgePath = clickedRoofEdgeData(
    event.latLng.toJSON(),
    isClickOnRoofEdge,
  );
  if (editEnabled) {
    selectGoogleRoof(roofId);
  } else if (clickRoofEdgePath && !preview) {
    roofHasPanels(roofId) || roofHasObstructions(roofId)
      ? confirmChangeSouthEdge(clickRoofEdgePath, roofId)
      : selectRoofLeadEdge(clickRoofEdgePath);
  } else if (panelsEditorEnabled()) {
    savePanelsEditor();
  }
}

export const initializeDrawingManager = () => {
  const map = getMap();
  drawingManager = new google.maps.drawing.DrawingManager({
    ...drawingManagerSettings,
    map,
  });

  return drawingManager;
};

export const getDrawingManager = () => {
  if (drawingManager) {
    return drawingManager;
  }

  return initializeDrawingManager();
};

export const deleteGoogleRoof = (selectedId: number) => {
  roofsOnMap[selectedId].roof.overlay.setEditable(false);
  roofsOnMap[selectedId].roof.overlay.setMap(null);
  roofsOnMap[selectedId].marker.setMap(null);

  if(roofsOnMap[selectedId].dimension){
    setRoofDimensions(roofsOnMap[selectedId].dimension, null);
  }

  if (roofsOnMap[selectedId].infoWindow) {
    roofsOnMap[selectedId].infoWindow.close();
  }

  if (roofsOnMap[selectedId].azimuth && roofsOnMap[selectedId].azimuth.infoWindow) {
    roofsOnMap[selectedId].azimuth.infoWindow.close();
  }

  if (roofsOnMap[selectedId].roof.dragMarkers) {
    unsetPolygons(roofsOnMap[selectedId].roof);
    dispatch(UNSET_SELECTED_ROOF_AND_MARKER(selectedId));
  }

  clearGoogleLeadEdgeObject(selectedId);
  delete roofsOnMap[selectedId];
  dispatch(DELETE_ROOF(selectedId));
  dispatch(CLEAR_ROOF_LEAD_EDGE(selectedId));
  dispatch(CLEAR_SELECTION());
};

export const deleteAllGoogleRoofs = () => {
  const {
    drawingManager: { roofs },
  } = state();
  if (roofsOnMap && Object.keys(roofsOnMap).length && roofs && Object.keys(roofs).length) {
    Object.keys(roofs).map(roofId => {
      roofsOnMap[roofId].roof.overlay.setMap(null);
      roofsOnMap[roofId].marker.setMap(null);

      if(roofsOnMap[roofId].dimension){
        setRoofDimensions(roofsOnMap[roofId].dimension, null);
      }

      if (roofsOnMap[roofId].infoWindow) {
        roofsOnMap[roofId].infoWindow.close();
      }
      if (roofsOnMap[roofId].azimuth.infoWindow) {
        roofsOnMap[roofId].azimuth.infoWindow.close();
      }
    });
    clearAllGoogleLeadEdgeObject();
    dispatch(DELETE_ALL_ROOFS());
    dispatch(CLEAR_SELECTION());
    dispatch(DELETE_ALL_LEAD_EDGES());
    roofsOnMap = {};
  }
};

export const getGoogleRoofObject = (roofId: number) => roofsOnMap[roofId];

export const getAllGoogleRoofObject = () => roofsOnMap;

export function removeRoofsListeners() {
  if (roofsOnMap !== null) {
    Object.keys(roofsOnMap).map(roofId => {
      google.maps.event.removeListener(roofsOnMap[roofId].roof.clickListener);
      google.maps.event.removeListener(roofsOnMap[roofId].roof.shapeEditEvent);
      google.maps.event.removeListener(roofsOnMap[roofId].marker.clickListener);
      google.maps.event.removeListener(roofsOnMap[roofId].azimuth.domReadyListener);
      roofsOnMap[roofId].roof.clickListener = null;
      roofsOnMap[roofId].roof.shapeEditEvent = null;
      roofsOnMap[roofId].marker.clickListener = null;
      roofsOnMap[roofId].azimuth.domReadyListener = null;
    });
  }
}

export function clearGoogleRoofsOnMaps() {
  if (roofsOnMap && Object.keys(roofsOnMap).length) {
    Object.keys(roofsOnMap).map(roofId => {
      unsetPolygons(roofsOnMap[roofId].roof);
    });
    roofsOnMap = null;
  }
}

export function makeGoogleRoofEmpty(roofId: number) {
  roofsOnMap[roofId].roof.overlay.setOptions({
    fillColor: emptyRoofColor,
    strokeColor: emptyRoofColor,
  });

  roofsOnMap[roofId].marker.setIcon(emptyRoofMarkerIcon);

  makeGoogleLeadEdgeEmpty(roofId);

  dispatch(DELETE_PANELS_INSIDE_OF_ROOF(roofId));
  updateRoofDimensions(roofId, false);
  setGoogleMapInfoWindow(roofId);
}

export function selectGoogleRoof(roofId: number, skipZoom?: boolean) {
  if (roofsOnMap[roofId].roof.overlay.editMode) {
    roofsOnMap[roofId].roof.overlay.editMode = false;
    unsetPolygons(roofsOnMap[roofId].roof);
    roofsOnMap[roofId].roof.dragMarkers = undefined;
    dispatch(UNSET_SELECTED_ROOF_AND_MARKER(roofId));
    updateRoofDimensions(roofId, false);
  } else {
    const markers = setDraggableCorners(
      getMap(),
      roofsOnMap[roofId].roof.overlay,
      roofsOnMap[roofId].marker,
      roofsOnMap[roofId].infoWindow,
      roofId,
    );
    roofsOnMap[roofId].roof.dragMarkers = markers;
    roofsOnMap[roofId].roof.overlay.editMode = true;
    dispatch(SET_SELECTED_ROOF_AND_MARKER(roofId));
    updateRoofDimensions(roofId, true);
  }

  if (!skipZoom) {
    const bounds = new google.maps.LatLngBounds();
    const roofBounds = roofsOnMap[roofId].roof.overlay.getBounds();
    const map = getMap();
    bounds.extend(roofBounds[0]);
    bounds.extend(roofBounds[1]);
    map.fitBounds(bounds);
  }
}

function confirmChangeSouthEdge(
  clickRoofEdgePath: { edgeNumber: number; roofId: number },
  roofId: number,
) {
  dispatch(
    OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
      'South edge selection',
      SOUTH_EDGE_CHANGE_WARNING,
      () => {
        selectRoofLeadEdge(clickRoofEdgePath);
        makeGoogleRoofEmpty(roofId);
      },
    ),
  );
}

const activeEditor = (roof: google.maps.Polygon, roofId: number) => {
  const roofEdges = getSelectedAreaPoints(roof);
  const centerPoint = roof.getRoofApproximateCenter().toJSON();  
  dispatch(ACTIVE_PANELS_EDITOR(centerPoint, roofEdges, roofId));
};