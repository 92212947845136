import { DEACTIVE_PANELS_EDITOR } from 'actions';
import { dispatch, state } from '__common/store';
import { savePanelsEditor } from '__editor/panelsEditor/components/panels/panels';
import { SET_USER_CLICKED_YES_OR_NO, TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { setShouldSkipFetZipCode } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import { hasRoofObstructionHeightSet } from './components/obstructions/obstructions';
import { launchRecheckPanelExposure } from './components/panels/utils/exposureRecheck';
import { isBlankMap } from '__common/constants/map';
import { isRMFamily, isRMIFIProduct, isEcoFoot2Plus, isRmGridflex10 } from '__common/constants/products';
import { isASCE716or722Selected } from 'projectDesign/components/projectConfiguration/projectConfiguration'
import { applyEcoFoot2PlusRM10andEvoSetbackChanges } from '__common/utils/versionCompare/versionCompare';

let editorDims = null;

export function panelsEditorEnabled() {
  const { background: { cords }, moduleSelector: { modelData } } = state();
  const panelsEditorEnabled = cords;
  return panelsEditorEnabled && Object.keys(modelData).length;
}

export function deactivePanelsEditor(cb?: Function) {
  const { panels: { panels }, background: { selectedRoofId }, projectConfiguration } = state();
  
  if(projectConfiguration?.projectEnvConfig?.helioscope_id){
    dispatch(SET_USER_CLICKED_YES_OR_NO(selectedRoofId, true));
  }

  if (hasRoofObstructionHeightSet()) {
    const panelsIdsToRecheck = panels.map(panel => panel.id);
    launchRecheckPanelExposure(panelsIdsToRecheck, selectedRoofId, () => {
      savePanelsEditor();
      dispatch(DEACTIVE_PANELS_EDITOR());
      dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
      dispatch(TURN_OFF_MEASUREMENT_TOOL());
      setShouldSkipFetZipCode(true);

      if (cb) {
        cb();
      }
    });
  }
}

export function setEditorDims(width, height) {
  editorDims = {
    width,
    height,
  };
}

export function getEditorDims() {
  return editorDims;
}

export function shouldUseVirtualRoofEdges(mapType: string, productId: number, projectVersion: string): boolean {
  return isBlankMap(mapType) && (isRMFamily(productId) || isEcoFoot2Plus(productId)) && (isASCE716or722Selected() || (shouldUseSetBackDistance(mapType, productId, projectVersion)));
}

export function shouldUpdateRoofEdgesOnPanelChange(mapType: string, productId: number, projectVersion: string): boolean {
  return shouldUseVirtualRoofEdges(mapType, productId, projectVersion) && !shouldUseSetBackDistance(mapType, productId, projectVersion);
}

export function shouldUseSetBackDistance(mapType: string, productId: number, projectVersion: string): boolean {
  return (isRMIFIProduct(productId) || applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion)) || isRmGridflex10(productId);
}

// need to update shouldUseDesiredBuildingLengthWidth function if product have setback but not desired building length and width
export function shouldUseDesiredBuildingLengthWidth(mapType: string, productId: number, projectVersion: string): boolean{
  return isBlankMap(mapType) && shouldUseSetBackDistance(mapType, productId, projectVersion);

}
