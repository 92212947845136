import React from 'react';
import { getTitleForOnlyRoofArea } from './engineeringProjectReportsMenu';
import { getProductName, isNxtHorizon, isOneClickCDProduct, isSolarMount } from '__common/constants/products';
import { getFileNamePrefix } from './engineeringProjectDocuments/utils/reportsApi';
import { userPlansetPermission } from 'user/userGroups';
import { DXF_GENERATE_START, CONSOLIDATED_DXF_GENERATE_START, DXF_ZIP_ALL_GENERATE_START, CONSTRUCTION_DXF_GENERATE_START, PLANSET_DXF_GENERATE_START } from './engineeringProjectDocuments/engineeringProjectDocumentsActions';
import { isUniracUser } from 'app/components/HeaderComponent/utils/getProducts';

type Props = {
  dispatch: Function,
  projectArrays: engineeringProjectArray[],
  projectRoofs: engineeringProjectRoof[],
  projectId: string,
  productId: number,
  isStaff: boolean,
  is_imported_project: boolean,
  email: string
};

export default class EngineeringCadLayouts extends React.Component<Props> {
  downloadDxf = (projectId: string, arrayName: string, arrayId: number, productId: number) => {
    const { dispatch } = this.props;
    const fileNamePrefix = getFileNamePrefix(getProductName(productId), projectId);
    dispatch(
      DXF_GENERATE_START(projectId, arrayId, `${fileNamePrefix.toUpperCase()}${arrayName}.dxf.zip`, 'application/zip', `reports/${projectId}/dxf/`, { arrayId })
    )
  }
  
  downloadLayoutTypeDxf = (projectId: string, arrayName: string, arrayId: number, productId: number, layoutType:number) => {
    const { dispatch } = this.props;
    const fileNamePrefix = getFileNamePrefix(getProductName(productId), projectId);
    dispatch(
      DXF_GENERATE_START(projectId, arrayId, `${fileNamePrefix.toUpperCase()}${arrayName}.dxf.zip`, 'application/zip', `reports/${projectId}/dxf/`, { arrayId })
    )
  }

  downloadConsolidatedDxf = (projectId: string, roofName: string, roofId: number, productId: number) => {
    const { dispatch } = this.props;
    const fileNamePrefix = getFileNamePrefix(getProductName(productId), projectId);
      dispatch(CONSOLIDATED_DXF_GENERATE_START(projectId, roofId, `${fileNamePrefix.toUpperCase()}${roofName}.dxf`, 'image/vnd.dxf', `reports/${projectId}/consolidatedDxf/`, { roofId }),)
  }

  downloadConstructionDxf = (projectId: string, roofName: string, roofId: number, productId: number) => {
    const { dispatch } = this.props;
    const fileNamePrefix = getFileNamePrefix(getProductName(productId), projectId);
      dispatch(CONSTRUCTION_DXF_GENERATE_START(projectId, roofId, `${fileNamePrefix.toUpperCase()}${roofName}.zip`, 'application/zip', `reports/${projectId}/constructionDxf/`, { roofId }),)
  }

  downloadPlansetDxf = (projectId: string, productId: number) => {
    const { dispatch } = this.props;
    const fileNamePrefix = getFileNamePrefix(getProductName(productId), projectId);
      dispatch(PLANSET_DXF_GENERATE_START(projectId, `${fileNamePrefix.toUpperCase()}planset.zip`, 'application/zip', `reports/plansetzip/${projectId}`));
  }


  downloadDxfZip = (projectId: string, productId: number) => {
    const { dispatch } = this.props;
    const fileNamePrefix = getFileNamePrefix(getProductName(productId), projectId);
    dispatch(
      DXF_ZIP_ALL_GENERATE_START(projectId, `${fileNamePrefix.toUpperCase()}layouts.zip`, 'application/zip', `reports/dxfzip/${projectId}`),
    );
  }

  notAvailable = (mixedArray: boolean, isSFM: boolean) => {

    if (isSFM) {
      return (<div className="input-warning" style={{ marginTop: '-23px', marginBottom: '10px', fontWeight: 600, fontSize: '8px' }}>currently unavailable</div>);
    }

    if (mixedArray) {
      return (<div className="input-warning" style={{ marginTop: '-23px', marginBottom: '10px', fontWeight: 600, fontSize: '8px' }}>not available for mixed orienation</div>);
    }

    return null;
  }

  layouts = (projectId: string, name: string, arrayId: number, productId: number) => {
    return (
      <React.Fragment key={arrayId}>
        <div
          className="project-reports-menu-page"
          style={{ cursor: 'pointer' }}
          onClick={() => this.downloadDxf(projectId, name, arrayId, productId)}
        >
          {name}
        </div>
      </React.Fragment>
    );
  }

  consolidatedlayouts = (projectId: string, name: string, roofId: number, productId: number) => {
    return (
      <React.Fragment key={roofId}>
        <div
          className="project-reports-menu-page"
          style={{ cursor: 'pointer' }}
          onClick={() => this.downloadConsolidatedDxf(projectId, name, roofId, productId)}
        >
          {name}
        </div>
      </React.Fragment>
    );
  }

  cdLayouts = (projectId: string, name: string, roofId: number, productId: number) => {
    return (
      <React.Fragment key={roofId}>
        <div
          className="project-reports-menu-page"
          style={{ cursor: 'pointer' }}
          onClick={() => this.downloadConstructionDxf(projectId, name, roofId, productId)}
        >
          CONSTRUCTION DRAWING - {name}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { projectArrays, projectRoofs, projectId, productId, isStaff, is_imported_project, email} = this.props;
    return (
      <>
        {/* <div className="maintitle">Project Cad Layouts</div> */}
        {isNxtHorizon(productId) ? projectRoofs.map((roof: engineeringProjectRoof) => {
          return this.consolidatedlayouts(projectId, roof.name, roof.id, productId);
        }) :
          projectArrays.map((array: engineeringProjectArray) => {
            return this.layouts(projectId, getTitleForOnlyRoofArea(array.name), array.id, productId);
          })}
        {
          (isStaff && isOneClickCDProduct(productId)) ? projectRoofs.map((roof) => {
                return this.cdLayouts(projectId, roof.name, roof.id, productId);
              }) : null
        }
        {
          ((isUniracUser(email) || userPlansetPermission()) && is_imported_project && (isNxtHorizon(productId) || isSolarMount(productId))) ?
              <div
                className="project-reports-menu-page"
                style={{ cursor: 'pointer' }}
                onClick={() => this.downloadPlansetDxf(projectId, productId)}
              >
                PLANSET DRAWING
              </div>
           : null
        }
        <div
          className="project-reports-menu-page"
          style={{ cursor: 'pointer' }}
          onClick={() => this.downloadDxfZip(projectId, productId)}
        >
          Download All
        </div>

      </>
    );
  }
}
