import nextUid from '__common/calculations/nextUid';

// Some old RMDT projects were created before the concept of siblingId was introduced.
// Therefore, we fix them on the fly to assign them siblingIds.
// Otherwise, it would be impossible to save the panels again.

export function transformFixMissingSiblingData(panels: panelInState[]): panelInState[] {
  return panels.reduce<panelInState[]>(fixIfNeeded, []);
}

const canBeSiblings = (panel: panelInState) => (otherPanel: panelInState) => (
  panel.id !== otherPanel.id && 
  otherPanel.y === panel.y && 
  Math.abs(otherPanel.x - panel.x) <= panel.width + 0.001 //  <-- this is caused by math rounding. We need to give it a small possible error.
);

const getId = (panel: panelInState) => panel.id;

const panelAlreadyFixed = (fixedPanels: panelInState[], nextPanel: panelInState) =>
  fixedPanels.map(getId).includes(nextPanel.id);

function fixIfNeeded(fixedPanels: panelInState[], nextPanel: panelInState, index: number, allPanels: panelInState[]): panelInState[] {
  // when we generate new siblingId
  // we do it for both of the siblings
  // which means we have already added it to fixedPanels
  if (panelAlreadyFixed(fixedPanels, nextPanel)) {
    return fixedPanels;
  }

  if (siblingDataMissing(nextPanel)) {
    const [siblingPanel] = allPanels.filter(canBeSiblings(nextPanel));
    const siblingId = nextUid();

    if (siblingPanel) {
      return [
        ...fixedPanels, 
        {
          ...nextPanel,
          siblingId,
          siblingSide: 0,
        },
        {
          ...siblingPanel,
          siblingId,
          siblingSide: 1,
        },
      ];
    }
  }

  return [...fixedPanels, nextPanel];
}

function siblingDataMissing(panel: panelInState): boolean {
  return panel.siblingId === undefined || panel.siblingId === null;
}
