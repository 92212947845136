import config from 'local_config';
let configStore;

/* istanbul ignore else  */
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test' || config.isProd) {
  configStore = require('./configureStore.prod').default;
} else {
  configStore = require('./configureStore.dev').default;
}

const store = configStore();

/* istanbul ignore if  */
// if (process.env.NODE_ENV !== 'test') {
//   persistStore(store, {
//     whitelist: ['app', 'github', 'user'],
//   });
// }
export default store;

export const dispatch = store.dispatch;

export const state = () => {
  const currentState: appState = store.getState();
  return {
    ...currentState,
  };
};

export const deepClonedState = (): appState => {
  const currentState: appState = store.getState();
  return JSON.parse(JSON.stringify(currentState));
};

window.state = state;
