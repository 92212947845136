export const apiField = 'adjustable_tilt_system';
export interface AdjustableTiltSystemField{
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  data: AdjustableTiltSystemOption[];
  
}
export interface AdjustableTiltSystemOption{
  value: number;
  name: string;
}