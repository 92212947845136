import { products } from '__common/constants/products';
import { RailsSelectorActionTypes } from 'actionsConstants';
import { getDefaultSelectedRails } from './railsSelector';
import { Action } from '__common/store/action';
import nprogress from 'nprogress';

export function SELECT_ALL_RAILS() {
  return {
    type: RailsSelectorActionTypes.SELECT_ALL_RAILS,
  };
}

export function SELECT_LOWEST_COST_RAILS_FOR_185() {
  return {
    type: RailsSelectorActionTypes.SELECT_LOWEST_COST_RAILS_FOR_185,
  };
}

export function SELECT_DEAFULT_RAILS(productId: number, railFinish: string) {
  return {
    type: RailsSelectorActionTypes.SELECT_DEAFULT_RAILS,
    payload: {
      productId,
      railFinish,
    },
  };
}

export function SELECT_RAIL(length: number) {
  return {
    type: RailsSelectorActionTypes.SELECT_RAIL,
    payload: {
      length,
    },
  };
}

export function UNSELECT_RAIL(length: number) {
  return {
    type: RailsSelectorActionTypes.UNSELECT_RAIL,
    payload: {
      length,
    },
  };
}

export interface FETCH_RAILS_ACTION {
  rails: railsList;
  projectId: string;
  useStandardClamps: boolean|number;
  skipBomSave?: boolean;
  selectOnlySMHDRails?: boolean;
}

export function FETCH_RAILS(rails: railsList, projectId: string, useStandardClamps: boolean|number, skipBomSave?: boolean, selectOnlySMHDRails?: boolean): Action<FETCH_RAILS_ACTION> {
  return {
    type: RailsSelectorActionTypes.FETCH_RAILS,
    payload: {
      rails,
      projectId,
      useStandardClamps,
      skipBomSave,
      selectOnlySMHDRails,
    },
  };
}

export function FETCHING_RAILS() {
  nprogress.start();
  return {
    type: RailsSelectorActionTypes.FETCHING_RAILS,
  };
}


export function LOAD_RAILS_LIST(railsList: rail[]) {
  return {
    type: RailsSelectorActionTypes.LOAD_RAILS_LIST,
    payload: {
      railsList,
    },
  };
}

export function CLEAR_RAILS_LIST() {
  return {
    type: RailsSelectorActionTypes.CLEAR_RAILS_LIST,
  };
}

export function SELECT_RAILS_FROM_API(productId: number, data: { rail_lengths: number [], extra_parameters: extraParameters }) {
  let selectedRails;
  if (data.rail_lengths.length === 0) {
    selectedRails = getDefaultSelectedRails(products[productId], data.extra_parameters.project_rail_finish);
  } else {
    selectedRails = data.rail_lengths;
  }
  return {
    type: RailsSelectorActionTypes.SELECT_RAILS_FROM_API,
    payload: {
      selectedRails,
    },
  };
}

export function RAILS_LOADED() {
  nprogress.done();
  return {
    type: RailsSelectorActionTypes.RAILS_LOADED,
  };
}


export function SELECT_ONLY_SMHD_RAILS() {
  return {
    type: RailsSelectorActionTypes.SELECT_ONLY_SMHD_RAILS,
  };
}

export function UNSELECT_ONLY_SMHD_RAILS_OPTION() {
  return {
    type: RailsSelectorActionTypes.UNSELECT_ONLY_SMHD_RAILS_OPTION,
  };
}

export function RAIL_TYPE_LOADED() {
  return {
    type: RailsSelectorActionTypes.RAIL_TYPE_LOADED,
  };
}
