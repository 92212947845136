export const MAP_TYPE = {
  BLANK: 'white',
  GOOGLE: 'google',
  BING: 'bing',
  IMAGE: 'image',
};

export function isBlankMap(mapType: string): boolean {
  return mapType === MAP_TYPE.BLANK;
}

export function isGoogleMap(mapType: string): boolean {
  return mapType === MAP_TYPE.GOOGLE;
}

export function isBingMap(mapType: string): boolean {
  return mapType === MAP_TYPE.BING;
}


export function getMapTypeInt(mapType: string) {
  switch (mapType) {
    case MAP_TYPE.GOOGLE:
      return 1;
    case MAP_TYPE.BING:
      return 2;
    case MAP_TYPE.BLANK:
      return 3;
    default:
      return 1;
  }
}