export function getAscenderProjectConfigurationData(projectEnvConfig: any) {
    const {
      zipcode,
      building_code,
      building_height,
      wind_speed,
      snow_load,
      elevation,
      wind_exposure,
      rail_finish,
      roof_type,  
      rafter_spacing_inches,
      env_factors_for,
      client_address,
      client_name,
      created_user_time,
      building_type,
      building_length,
      shortest_building_length,
      risk_category,
      structure_type,
      parapet_height_num,
      seismic_ss,
      seismic_s1,
      soil_class,
      structure_type_info,
      rail_length_for_thermal_gap,
      allow_thermal_gap,
      mid_clamps_choices,
      end_clamps_choices,
      lug_choice,
    } = projectEnvConfig;
  
    return {
      zipcode,
      building_code,
      building_height,
      wind_speed,
      snow_load,
      elevation,
      wind_exposure,
      rail_finish,
      roof_type,  
      rafter_spacing_inches,
      env_factors_for,
      client_address,
      client_name,
      created_user_time,
      building_type,
      building_length,
      shortest_building_length,
      risk_category,
      structure_type,
      parapet_height_num,
      seismic_ss,
      seismic_s1,
      soil_class,
      structure_type_info,
      rail_length_for_thermal_gap,
      allow_thermal_gap,
      mid_clamps_choices,
      end_clamps_choices,
      lug_choice,
    };
  }
  