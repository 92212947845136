import { Store } from 'redux';
import { 
  checkCollisionWithWindZones, 
  checkCollisionWithVirtualWindZones,
} from '__editor/panelsEditor/components/roofZones/utils/windZonesCollisionsDetection';
import { shouldUseVirtualRoofEdges } from '__editor/panelsEditor/panelsEditorHelper';
import { getBuildingHeight } from 'projectDesign/components/projectConfiguration/projectConfiguration';

export const getZoneInformation = (
  store: Store<appState>, 
  roofId: number, 
  panel: panelInState,
): { zone: roofZoneNumber, edgeType?: number } => {
  const {
    background: {
      cords: editorRoofCenter,
      zoom: editorZoom,
      metersPerPixel: editorMetersPerPixel,
      rotationDegrees: editorBgRotation,
      bgXY: editorBgXY,
      panelEditorActive,
      roofEdges: editorRoofEdges,
    },
    projectConfiguration: {
      projectEnvConfig: {
        building_type: buildingType,
      },
      productId,
      projectVersion,
    },
    tiltedRoof: {
      roofPitch,
    },
    settings: {
      panelHeight,
      panelWidth,
    },
    roofsSelector: {
      mapType,
    },
    drawingManager: {
      roofs,
    },
  } = store.getState();

  let metersPerPixel: number;
  let roofCenter: cordPoint;
  let bgRotationDegrees: number;
  let zoom: number;
  let bgOffset: pixelPoint;
  let roofEdges: cordPoint[];
  const buildingHeightFt = getBuildingHeight();

  if (panelEditorActive) {
    metersPerPixel = editorMetersPerPixel;
    roofCenter = editorRoofCenter;
    bgRotationDegrees = editorBgRotation;
    zoom = editorZoom;
    bgOffset = editorBgXY;
    roofEdges = editorRoofEdges;
  } else {
    const roof = roofs[roofId];
    metersPerPixel = roof.metersPerPixel;
    roofCenter = roof.marker;
    bgRotationDegrees = roof.bgRotation;
    zoom = roof.zoom;
    bgOffset = roof.bgOffset;
    roofEdges = roof.coords;
  }

  if (shouldUseVirtualRoofEdges(mapType, productId, projectVersion)) {
    return checkCollisionWithVirtualWindZones({
      panel,
      relativeToCenter :true, 
      metersPerPixel, 
      bgXOffset: bgOffset.x, 
      bgYOffset: bgOffset.y, 
      insideOfPolygon: true,
    });
  }

  return checkCollisionWithWindZones({
    panel,
    relativeToCenter :true, 
    roofEdges, 
    roofCenter, 
    zoom, 
    buildingHeightFt, 
    metersPerPixel, 
    bgRotationDegrees,
    bgXOffset: bgOffset.x, 
    bgYOffset: bgOffset.y, 
    panelWidth, 
    panelHeight,
    insideOfPolygon: true,
    buildingType,
    roofPitch,
    roofId,
  });
};
