import { EDGES_TYPE } from "./edgesType";
import { RoofZonesCorner } from "../roofZones";

export const getCollision = (index: number, edgeType?: EDGES_TYPE): string => {
  return `${index}${edgeType !== undefined ? edgeType : '-'}`;
};

export const getCornerCollision = (index: number, edgeType: EDGES_TYPE): string => {
  return `${index}${edgeType}c`;
};

export const isCornerCollision = (collision: string): boolean => {
  // e.g. 21c or 141c (edge index 14, edge type 1)
  return /^\d{1,2,3}c$/.test(collision);
};

/** return corner collision as if it was regular collision - strips 'c' */
export const ignoreCorner = (collision: string): string => {
  return collision.replace(/c$/, "");
};

export const getIndexFromCollision = (collision: string): number => {
  return Number(ignoreCorner(collision).slice(0, -1));
};

export const getEdgeTypeFromCollision = (collision: string): EDGES_TYPE | undefined => {
  if (collision.length === 0 || collision.includes('-')) {
    return undefined;
  }
  return Number(ignoreCorner(collision).slice(-1));
};

export const getEdgeCollisionsFromCorner = (corner: RoofZonesCorner): string[] => {
  const [previous, next] = corner.adjacentEdges;
  return [
    getCollision(previous.index, previous.edgeType),
    getCollision(next.index, next.edgeType),
  ];
};
