
import { findLinesCollisions, IAREA_WITH_INDEX, getEdgeType, checkZoneAngles, findCornerCollisionIds } from './windZonesCollisionsDetection';
import { getRealRoofEdgesBoundsMemoized as  getRealRoofEdgesBounds } from '../../background/background';
import { isASCE716or722Selected, isGableRoof } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { RoofZonesAreas } from '../roofZones';
import { EDGES_TYPE } from "./edgesType";
import { ROOF_PITCH } from '../../tiltedRoof/roofPitch';
import getGableRoofZone from './residentialRoofZonesDetection/gableRoofZoneDetection';
import { isCornerCollision, getCollision, ignoreCorner } from '__editor/panelsEditor/components/roofZones/utils/collisions';
import { state } from '__common/store';
import { needNewZoneClassification } from '__common/utils/versionCompare/versionCompare';

interface getGableAndHipRoofZoneCollision {
  panelPoly: PolygonInterface;
  restrictedAreaPolygons: PolygonInterface[];
  rotatedRoofZone: RoofZonesAreas;
  roofEdges: google.maps.LatLngLiteral[];
  roofCenter: cordPoint;
  zoom: number;
  bgRotationDegrees: number;
  bgXOffset: number;
  bgYOffset: number;
  buildingType: number;
  roofPitch: string;
  distance: number;
}

export const getGableAndHipRoofZoneCollision = ({
  panelPoly,
  restrictedAreaPolygons,
  rotatedRoofZone,
  roofEdges,
  roofCenter,
  zoom,
  bgRotationDegrees,
  bgXOffset,
  bgYOffset,
  buildingType,
  roofPitch,
  distance,
}: getGableAndHipRoofZoneCollision): { zone: roofZoneNumber, edgeType?: EDGES_TYPE } => {
  const rotationRadians = degreesToRadians(bgRotationDegrees);
  const {projectConfiguration: {productId, projectEnvConfig: {tilt}}} = state();

  const collisionsLines: IAREA_WITH_INDEX[] = (<PolygonInterface[]>restrictedAreaPolygons)
    .reduce((acc, area) => findLinesCollisions(acc, area, panelPoly), []);
  const collisionsLinesIndexes = collisionsLines.map(col => {
    return getCollision(col.index, col.edge);
  });

  const collisionsCornerIndexes = rotatedRoofZone.corners.reduce<string[]>(
    (acc, corner) => findCornerCollisionIds(acc, corner, panelPoly), 
    [],
  );

  const collisionIndexes: string[] = [...collisionsLinesIndexes, ...collisionsCornerIndexes];
  
  const roofEdgesPolygon = getRealRoofEdgesBounds({roofEdges, roofCenter, bgOffSet: {x: bgXOffset, y: bgYOffset}, zoom, rotationRadians, roofPitch, productId, tilt})
  
  
  let deduplicatedCollisionIndexes = collisionIndexes.map((collision) => {
    if (isCornerCollision(collision)) {
      // strips the "c" from the collision string. e.g. 12c -> 12
      return ignoreCorner(collision);
    }
    
    return collision;
  });
  
  deduplicatedCollisionIndexes = Array.from(new Set(deduplicatedCollisionIndexes)); // first character is index of edge, second index of i type
  
  const shouldUpgradeZone = checkZoneAngles(restrictedAreaPolygons, collisionsCornerIndexes, roofEdgesPolygon, distance);
  return detectGableAndHipZone(deduplicatedCollisionIndexes, collisionsCornerIndexes, buildingType, roofPitch, shouldUpgradeZone);
};

export const hipRoofThirdZoneDetection = (degressAngle: number, edgeType: EDGES_TYPE, shouldUpgradeZone: boolean): { zone: roofZoneNumber, edgeType?: EDGES_TYPE } => {
  if (degressAngle > 27 && degressAngle <= 45 && edgeType === EDGES_TYPE.EAVE && !shouldUpgradeZone) {
    return { zone: 2, edgeType };
  }

  if (degressAngle > 27 && degressAngle <= 45 && (edgeType === EDGES_TYPE.RIDGE || edgeType === EDGES_TYPE.RAKEGABLE)) {
    return { zone: 5, edgeType };
  }

  if (degressAngle > 7 && degressAngle <= 20 && (edgeType === EDGES_TYPE.RIDGE || edgeType === EDGES_TYPE.RAKEGABLE)) {
    return { zone: 2, edgeType };
  }

  if (degressAngle > 20 && degressAngle <= 27) {
    return { zone: 2, edgeType };
  }
  return { zone: 3, edgeType };
};

export const hipRoofSecondZoneDetection = (degressAngle: number, edgeType: EDGES_TYPE): { zone: roofZoneNumber, edgeType?: EDGES_TYPE } => {
  if (degressAngle <= 20 && edgeType === EDGES_TYPE.EAVE) {
    return { zone: 3, edgeType };
  }

  if (degressAngle > 27 && degressAngle <= 45 && (edgeType === EDGES_TYPE.RAKEGABLE || edgeType === EDGES_TYPE.RIDGE)) {
    return { zone: 5, edgeType };
  }

  return { zone: 2, edgeType };
};

const nonZonesCollisionDetection = (deduplicatedCollisionIndexes: string[]) => {
  return deduplicatedCollisionIndexes.length === 0;
};

const oneZoneCollisionDetection = (deduplicatedCollisionIndexes: string[]) => {
  return deduplicatedCollisionIndexes.length === 1;
};

const fewZonesCollisionDetection = (deduplicatedCollisionIndexes: string[]) => {
  return deduplicatedCollisionIndexes.length === 2;
};

export const detectGableAndHipZone = (
  deduplicatedCollisionIndexes: string[], 
  collisionsCornerIndexes: string[], 
  buildingType: number, 
  roofPitch: string, 
  shouldUpgradeZone: boolean,
): { zone: roofZoneNumber, edgeType?: EDGES_TYPE } => {
  const degressAngle = ROOF_PITCH[roofPitch];

  if (isGableRoof(buildingType)) {
    return getGableRoofZone(deduplicatedCollisionIndexes, collisionsCornerIndexes, roofPitch, shouldUpgradeZone);
  }

  // hip roof
  if (fewZonesCollisionDetection(deduplicatedCollisionIndexes) || (collisionsCornerIndexes.length && shouldUpgradeZone)) {
    // for hip roof corners don't have edge type, e.g. it's zone 3, not 3e.
    let edgeType: EDGES_TYPE | undefined;
    if (collisionsCornerIndexes.length > 0) {
      edgeType = undefined;
    } else {
      edgeType = getEdgeType(deduplicatedCollisionIndexes);
    }

    return hipRoofThirdZoneDetection(degressAngle, edgeType, shouldUpgradeZone);
  }

  if (nonZonesCollisionDetection(deduplicatedCollisionIndexes)) {
    const { projectConfiguration: { projectVersion } } = state();
    let useNewZoneClassification = needNewZoneClassification(projectVersion);
    if (!isASCE716or722Selected() && degressAngle<=7 && useNewZoneClassification) {
        return { zone: 2 };
      }
    return { zone: 1 };
  }

  if (oneZoneCollisionDetection(deduplicatedCollisionIndexes) || (collisionsCornerIndexes.length && !shouldUpgradeZone)) {
    const edgeType = getEdgeType(deduplicatedCollisionIndexes);

    return hipRoofSecondZoneDetection(degressAngle, edgeType);
  }

  if (deduplicatedCollisionIndexes.length > 1) {
    return { zone: 3 };
  }

  return { zone: 1 };
};
