import { degreesToRadians, radiansToDegrees } from "maths";
import turf from 'turf';
import { state } from "__common/store";
import { greaterThanEqualToProjectVersion } from "__common/utils/versionCompare/versionCompare";
import { VERSION_MAP } from "__common/utils/versionCompare/version_info";

const tile_size: number = 256.0;
const earth_radius: number = 6378137.0;
const initial_resolution: number = 2 * Math.PI * earth_radius / tile_size;
const origin_shift: number = 2.0 * Math.PI * earth_radius / 2.0;

function resolution(zoom: number): number {
  return initial_resolution / (2.0 ** zoom);
}

export function lat_lng_distance_to_feet(point1: { lat: number, lng: number },point2: { lat: number, lng: number }) : number {
  const p = 0.017453292519943295;    // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((point2.lat - point1.lat) * p)/2 + 
          c(point1.lat * p) * c(point2.lat * p) * 
          (1 - c((point2.lng - point1.lng) * p))/2;

  return 12742 * Math.asin(Math.sqrt(a)) * 3280.84; // 2 * R; R = 6371 km
}

export function lat_lng_to_meters(point: { lat: number, lng: number }): { my: number, mx: number } {
  // an sumption that earth is a sphere.
  const mx: number = point.lng * origin_shift / 180.0;
  let my: number = Math.log(Math.tan((90.0 + point.lat) * Math.PI / 360.0)) / (Math.PI / 180.0);
  my = my * origin_shift / 180.0;
  return {
    my,
    mx,
  };
}

export function angele_between_lat_lng_points(p1: { lat: number, lng: number }, p2: { lat: number, lng: number }): number {
  const lat1 = degreesToRadians(p1.lat);
  const lat2 = degreesToRadians(p2.lat);
  const long1 = degreesToRadians(p1.lng);
  const long2 = degreesToRadians(p2.lng);
  const dLon = (long2 - long1);

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
          * Math.cos(lat2) * Math.cos(dLon);

  const brng = Math.atan2(y, x);

  const angleBetweenPoints = ((radiansToDegrees(brng) + 360) % 360) - 90;

  return angleBetweenPoints;

}

export function midPoint_between_lat_lng_points(p1: { lat: number, lng: number }, p2: { lat: number, lng: number }) {
  const lat1 = degreesToRadians(p1.lat);
  const lat2 = degreesToRadians(p2.lat);
  const long1 = degreesToRadians(p1.lng);
  const long2 = degreesToRadians(p2.lng);
  const dLon = (long2 - long1);

  const Bx = Math.cos(lat2) * Math.cos(dLon);
  const By = Math.cos(lat2) * Math.sin(dLon);
  const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By));
  const long3 = long1 + Math.atan2(By, Math.cos(lat1) + Bx);
  const midPoint = {
    lat: radiansToDegrees(lat3),
    lng: radiansToDegrees(long3)
  }
  return midPoint;
}

export function destination_point_from_source_point(startingPoint: { lat: number, lng: number }, brng: number, dist: number) { // angleInRadians, distance in Km
  const lat1 = degreesToRadians(startingPoint.lat);
  const long1 = degreesToRadians(startingPoint.lng);
  brng = degreesToRadians(90 - brng);  


  const lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) + 
                      Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

  const lon2 = long1 + Math.atan2(Math.sin(brng) * Math.sin(dist) *
                              Math.cos(lat1), 
                              Math.cos(dist) - Math.sin(lat1) *
                              Math.sin(lat2));


  return {
    lat: radiansToDegrees(lat2),
    lng: radiansToDegrees(lon2)
  };
}

export function meters_to_pixels(mx: number, my: number, zoom: number): { px: number, py: number } {
  const res: number = resolution(zoom);
  const px: number = (mx + origin_shift) / res;
  const py: number = (my + origin_shift) / res;
  return {
    px,
    py,
  };
}

export function lat_lng_to_pixels(point: cordPoint, zoom: number, central_pixel: pixelPoint = { x: 0, y: 0 }, invertY: boolean = false, relative: boolean = false) {
  const { mx, my } = lat_lng_to_meters(point);
  const imageWidth = 1280;
  const imageHeight = 1280;
  const scale = 2;
  let { px, py } = meters_to_pixels(mx, my, zoom);
  const {projectConfiguration : { projectVersion }} =state();

  px -= central_pixel.x;
  py -= central_pixel.y;

  if (invertY) {
    py = -py;
  }

  if (relative) {
    px += imageWidth / 2;
    py += imageHeight / 2;

    px *= scale;
    py *= scale;
  }

  return greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['lat_lng_to_pixel_conversion_changes']) ? {
    x: px,
    y: py,
  } : {
    x : Math.round(px),
    y : Math.round(py),
  };
}

export function latLngToPixels(point: { lat: number, lng: number }, roofCenter: cordPoint, zoom: number) {
  const centralPoint = lat_lng_to_pixels(roofCenter, zoom);
  const pixels = lat_lng_to_pixels({ lat: point.lat, lng: point.lng }, zoom, centralPoint, true, true);

  const imageWidth = 1280;
  const imageHeight = 1280;

  return {
    x: pixels.x - imageWidth,
    y: pixels.y - imageHeight,
  };
}

export function pixel_to_lat_lng(
  centerLat: number, 
  centerLng: number, 
  zoom: number,
  mapWidth: number, 
  mapHeight: number, 
  pointX: number, 
  pointY: number,
) {

  const degreesPerPixelX = 360 / Math.pow(2, zoom + 8);
  const degreesPerPixelY = 360 / Math.pow(2, zoom + 8) * Math.cos(centerLat * Math.PI / 180);

  return {
    lat: centerLat - degreesPerPixelY * (pointY - mapWidth / 2),
    lng: centerLng + degreesPerPixelX * (pointX - mapWidth / 2),
  };
}

export function lat_lng_distance_to_miles(point1: { lat: number, lng: number },point2: { lat: number, lng: number }) : number {
  const p = 0.017453292519943295;    // Math.PI / 180
  const lat1 = point1.lat * p     // degrees to radians conversion
  const lng1 = point1.lng * p
  const lat2 = point2.lat * p
  const lng2 = point2.lng * p

  const differenceBetween2Lngs = lng2 - lng1
  const differenceBetween2Lats = lat2 - lat1
  const a = Math.pow(Math.sin(differenceBetween2Lats/2), 2)+Math.cos(lat1)*Math.cos(lat2)*Math.pow(Math.sin(differenceBetween2Lngs/2),2);

  return 2 * Math.asin(Math.sqrt(a))*3958.8     // Radius of earth in miles 3958.8
}

export function isPointWithinPolygon(point: number[], polygon: number[][]){
  const turfPoint = turf.point(point);
  const turfPolygon = turf.polygon([polygon]);
  return turf.inside(turfPoint, turfPolygon);
}
