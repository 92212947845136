import * as React from 'react';
import history from '__common/modules/history';
import Router from 'react-router-dom/Router';
import { connect } from 'react-redux';
import { dispatch } from '__common/store';
import { LOCATION_CHANGE, SET_APP_ERROR } from 'actionsConstants';
import { captureException } from '__common/utils/sentry';
import ReactGA from 'react-ga';

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class ReduxRouter extends React.Component<onlyDispatchComponentState> {
  unsubscribe: any;

  componentDidMount() {
    this.unsubscribe = history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidCatch(error, info) {
    dispatch(SET_APP_ERROR(info.componentStack, error.stack));
    captureException(error);
  }

  handleLocationChange = (location, action) => {
    const { dispatch } = this.props;

    dispatch({
      type: LOCATION_CHANGE,
      payload: {
        location,
        action,
      },
    });
  }

  render() {
    const { children } = this.props;

    return (
      <Router history={history}>
       {children}
      </Router>
    );
  }
}

export const { push, replace, go, goBack, goForward } = history;

function mapStateToProps(appState: appState) {
  return {
    error: appState.router.error,
  };
}

export default connect(mapStateToProps)(ReduxRouter);
