import { dispatch, state } from '__common/store';
import { ADD_ENDING_POINT, ADD_STARTING_POINT, START_NEW_MEASUREMENT } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';

export function measurementToolClick(renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer, stage: PIXI.Container) {
  if (renderer.plugins?.interaction.eventData.data !== null) {
    const { measurementTool : { enabled, startingPoint} } = state();
    const coords = stage.toLocal(renderer.plugins.interaction.eventData.data.global);
    if (enabled) {
      if (startingPoint){
        dispatch(START_NEW_MEASUREMENT());
      } else {
        dispatch(ADD_STARTING_POINT(coords));
      }
    }
  }
}

export function measurementToolMove(renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer, stage: PIXI.Container) {
  const { measurementTool : { enabled, startingPoint } } = state();
  if (renderer.plugins && renderer.plugins.interaction.eventData.data !== null) {
    const coords = stage.toLocal(renderer.plugins.interaction.eventData.data.global);
    if (enabled && startingPoint && coords.x && coords.y) {
      dispatch(ADD_ENDING_POINT(coords));
    }
  }
}