import * as PIXI from 'pixi.js';
import { getBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { advanceRoofSelectingClick, advanceRoofSelectingMove } from '__common/components/advancedSelecting/advancedSelectingEvents';
import { measurementToolClick, measurementToolMove } from '__common/components/measurementTool/measurementToolEvents';

declare var PixiOverlay: any;

let stage: PIXI.Container;
let renderer;
let latLngToLayerPointFn: (latLng: { lat: number, lng: number }, zoom?: number) => { x: number, y: number } | undefined;
let layerPointToLatLngFn: (point: { x: number, y: number }, zoom?: number) => { lat: number, lng: number } | undefined;
let movePixiContainer;
let restorePixiContainer;
let getScale;

export function getStage(): PIXI.Container {
  return stage;
}

export function setPixiStage(width: number, height: number) {
  new PixiOverlay((utils) => {
    latLngToLayerPointFn = utils.latLngToLayerPoint;
    layerPointToLatLngFn = utils.layerPointToLatLng;
    getScale = utils.getScale;
    movePixiContainer = utils.movePixiContainer;
    restorePixiContainer = utils.restorePixiContainer;
    stage = utils.getContainer();
    renderer = utils.getRenderer();
    renderer.render(stage);
  },              setupBingMapsStage(width, height)).addTo(getBingMap());
  stage.on('mousemove', () => {
    onMouseMove();
  });
  stage.on('click', () => {
    mapOnClickEvent();
  });
  animateStage();
}

export function mapOnClickEvent() {
  advanceRoofSelectingClick(renderer, stage);
  measurementToolClick(renderer, stage);
}

export function latLngToLayerPoint(latLng: { lat: number, lng: number }, zoom?: number): { x: number, y: number } {
  const unprojectedPoint = latLngToLayerPointFn(latLng, zoom);
  return unprojectedPoint;
}

export function layerPointToLatLng(point: pixelPoint, zoom?: number): { lat: number, lng: number } {
  const projectedPoint = layerPointToLatLngFn(point, zoom);
  return projectedPoint;
}

export function getMapScale(zoom?: number) {
  return getScale(zoom);
}

export function tempMovePixiContainer() {
  movePixiContainer();
  renderer.render(stage);
}

export function tempRestorePixiContainer() {
  restorePixiContainer();
  renderer.render(stage);
}

var bingMapsRoofsSelectorAnimateStageReqId; 
export function animateStage() {
  bingMapsRoofsSelectorAnimateStageReqId = undefined;
  startReqAnimationFrameBingMaps();
}

const startReqAnimationFrameBingMaps = () => {
  if(!bingMapsRoofsSelectorAnimateStageReqId) {
    bingMapsRoofsSelectorAnimateStageReqId = requestAnimationFrame(() => {
      renderer.render(stage);
      animateStage();
    });
  }
}

export const stopReqAnimationFrameBingMaps = () => {
    if(bingMapsRoofsSelectorAnimateStageReqId) {
      cancelAnimationFrame(bingMapsRoofsSelectorAnimateStageReqId);
    }
}

function setupBingMapsStage(width: number, height: number) {
  stage = new PIXI.Container();
  stage.hitArea = new PIXI.Rectangle(0, 0, width, height);
  stage.interactive = true;
  stage.interactiveChildren = true;
  stage.buttonMode = true;
  return stage;
}

function onMouseMove() {
  advanceRoofSelectingMove(renderer, stage);
  measurementToolMove(renderer, stage);
}
