
import { StageActionTypes } from 'actionsConstants';

export function SET_STAGE_SCALE(stageScale: { x: number, y: number}) {
  return {
    type: StageActionTypes.SET_STAGE_SCALE,
    payload: {
      stageScale,
    },
  };
}
