import React from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from 'react-md';
import SVG from 'react-inlinesvg';
import { REMOVE_OBSTRUCTION, CHANGE_OBSTRUCTION_NAME, CHANGE_OBSTRUCTION_HEIGHT, SET_EDITING_OBSTRUCTION_ID } from './ObstructionsActions';
import { OPEN_DRAWER_PAGE } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import { RECHECK_PANELS_OBSTRUCTION_ZONE } from '../panels/panelsActions';
import { getObstructionLargestEdgeMinimumLength } from './obstructions';

type Props = {
  hideDrawer: () => void,
  deleteObstruction: (roofId: number, obstructionId: number) => void,
  changeObstructionName: (roofId: number, obstructionId: number, name: string) => void,
  changeObstructionHeight: (roofId: number, obstructionId: number, height: string) =>  void,
  setEditingObstructionId: (obstructionId: number) => void,
  roofsObstructions: roofsObstructions,
  roofId: number,
};

class ObstructionsComponent extends React.Component<Props> {
  componentDidUpdate() {
    const roofObstructions = this.getRoofObstructions();
    if (!roofObstructions || roofObstructions.length === 0) {
      this.props.hideDrawer();
    }
  }

  componentWillUnmount() {
    this.props.setEditingObstructionId(null);
  }
  
  removeObstruction = (obstructionId: number) => {
    const { roofId } = this.props;

    this.props.deleteObstruction(roofId, obstructionId);
  }

  obstructionWarning = (obstruction: { name: string, id: number, height: number, isHighEnough: boolean }) => {
    if (obstruction.isHighEnough) {
      return null;
    }

    return (
      <div className="input-warning">
        This obstruction does not affect wind pressure
      </div>
    );
  }

  getRoofObstructions = (): { name: string, id: number, height: number, length: number, width: number, isHighEnough: boolean }[] => {
    const obstructionFields = [];
    const { roofsObstructions, roofId } = this.props;
    if (roofsObstructions[roofId] && Object.keys(roofsObstructions[roofId]).length) {
      const obstructions = roofsObstructions[roofId];
      Object.keys(obstructions).map((obstructionId) => {
        const obstruction: obstruction = obstructions[obstructionId];
        const minimumHeightInches = getObstructionLargestEdgeMinimumLength(5);
        const isHighEnough = obstruction.height > minimumHeightInches && obstruction.isLargeEnough;
        
        obstructionFields.push({
          name: obstruction.name,
          id: Number(obstructionId),
          height: obstruction.height,
          length: obstruction.length,
          width: obstruction.width,
          isHighEnough,
        });
      });
    }

    return obstructionFields;
  }

  render() {
    const { roofId } = this.props;
    const roofObstructions = this.getRoofObstructions();

    if (roofObstructions.length) {
      const obstructions = roofObstructions.map((obstruction) => (
        <>
        {this.obstructionWarning(obstruction)}
        <div className="obstruction">
          <div className="name">
          <TextField
            className={`obstruction-input`}
            label="Name"
            type="text"
            onFocus={() => this.props.setEditingObstructionId(obstruction.id)}
            onBlur={() => this.props.setEditingObstructionId(null)}
            lineDirection="center"
            placeholder="Set obstruction name"
            defaultValue={obstruction.name}
            onChange={(name: string) => this.props.changeObstructionName(roofId, obstruction.id, name)}
          />
          </div>
          <div className="height">
            <TextField
              className={!obstruction.height || obstruction.height === 0 ? `obstruction-warning obstruction-input` : `obstruction-input`}
              id="floating-center-title"
              label="Height (IN)"
              type="number"
              onFocus={() => this.props.setEditingObstructionId(obstruction.id)}
              onBlur={() => this.props.setEditingObstructionId(null)}
              lineDirection="center"
              placeholder="Set obstruction height"
              defaultValue={obstruction.height}
              onChange={(height: string) => this.props.changeObstructionHeight(roofId, obstruction.id, height)}
            />
          </div>
          <div className="delete">
            <Button 
              onMouseEnter={() => this.props.setEditingObstructionId(obstruction.id)} 
              onMouseLeave={() => this.props.setEditingObstructionId(null)}
              onClick={() => this.removeObstruction(obstruction.id)} 
              className="delete-button" 
              tooltipLabel="Remove"
            >
              <SVG src={require('assets/media/icons/plus_icon.svg')} />
            </Button>
          </div>
        </div>
        </>
      ));

      return (
        <div className="obstructions">
          <div className="drawer-section-title">Roof Obstructions</div>
          {obstructions}
        </div>
      );
    }

    document.body.click();
    return null;
  }
}

const mapStateToProps = (appState: appState) => ({
  obstructions: appState.obstructions, // to refresh component only
  roofsObstructions: appState.obstructions.obstructions,
  roofId: appState.background.selectedRoofId,
});

const mapDispatchToProps = (dispatch) => ({
  changeObstructionName: (roofId: number, obstructionId: number, name: string) => {
    dispatch(SET_EDITING_OBSTRUCTION_ID(obstructionId));
    dispatch(CHANGE_OBSTRUCTION_NAME(roofId, obstructionId, name));
  },
  changeObstructionHeight: (roofId: number, obstructionId: number, height: string) => {
    dispatch(SET_EDITING_OBSTRUCTION_ID(obstructionId));
    dispatch(CHANGE_OBSTRUCTION_HEIGHT(roofId, obstructionId, height));
    dispatch(RECHECK_PANELS_OBSTRUCTION_ZONE());
  },
  deleteObstruction: (roofId: number, obstructionId: number) => {
    dispatch(REMOVE_OBSTRUCTION(roofId, obstructionId));
    dispatch(RECHECK_PANELS_OBSTRUCTION_ZONE());
  },
  setEditingObstructionId: (obstructionId: number) => dispatch(SET_EDITING_OBSTRUCTION_ID(obstructionId)),
  hideDrawer: () => dispatch(OPEN_DRAWER_PAGE(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObstructionsComponent);
