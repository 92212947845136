
import React from 'react';

type statBoxProps = {
  value: number,
  icon: string,
  description: string,
  color: string,
};

export default class StatBox extends React.Component<statBoxProps> {
  render() {
    const { value, icon, description, color } = this.props;
    const splitedDesc = description.split(' ');
    return (
        <div className={`stat-box ${color}-box`}>
          <div className="value">
            {value ? value.toFixed(0) : 0}
          </div>
          <div className="description">
            <i className="md-icon material-icons md-text--inherit">{icon}</i>
              <div className="text">
                <div>
                  {splitedDesc[0]}
                </div>
                <div>
                  {splitedDesc[1]}
                </div>
              </div>
            </div>
        </div>
    );
  }
}
