export const apiField = 'dead_load_factor_modification';


export interface DeadLoadFactorField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  data: DeadLoadFactorOption[];
}

export interface DeadLoadFactorOption {
  value: number;
  name: string;
}
