import * as PIXI from 'pixi.js';

export const tooltipCursor = (stage: PIXI.DisplayObject, x: number, y: number, text: string) => {
  const tooltip: PIXI.Text = new PIXI.Text(text, {
    fontSize: '18px',
  });
  const xOffset = 10 / stage.scale.x;
  const yOffset = 5 / stage.scale.y;

  tooltip.x = x + xOffset;
  tooltip.y = y + yOffset;
  tooltip.width /= stage.scale.x;
  tooltip.height /= stage.scale.y;
  tooltip.id = 'cursorTooltip';
  tooltip.zIndex = 1000;
  return tooltip;
};
