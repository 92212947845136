import { isEcoFoot2Plus, isRM10, isRM10Evolution, isRMDT, isRMGridFlex, isSolarMount, products, isNxtHorizon} from '__common/constants/products';
import { ROOF_PITCH } from '__editor/panelsEditor/components/tiltedRoof/roofPitch';
import { InputUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

export function parseDataForImport(product: string, projectId: string, designId: string, fieldSegmentId: string, initData, moduleData: modelData) {
  
  const pId = products[product];
  
  const data = {
    helioscope_project: Number(projectId),
    helioscope_design: Number(designId),
    helioscope_field_segment: Number(fieldSegmentId),
    product: pId,
    helioscope_layout: initData.helioscope_layout,
    module: Number(moduleData.module) || Number(initData.module),
    model: Number(moduleData.id) || Number(initData.model),
    custom_title: '',
    building_height: initData.building_height,
    module_watts: moduleData.watts || initData.module_wattage,
    module_length: moduleData.height || initData.module_length,
    module_width: moduleData.width || initData.module_width,
    module_thickness: moduleData.thickness || initData.module_thickness,
    module_weight: moduleData.weight || initData.module_weight,
    zipcode: initData.zipcode,
    snow_load: initData.snow_load,
    wind_speed: initData.wind_speed,
    seismic_ss: Number(Number(initData.seismic_ss).toFixed(2)),
    elevation: initData.elevation,
    wind_exposure: initData.wind_exposure,
    building_code: initData.building_code,
    roof_type: initData.roof_type,
    row_spacing: initData.row_spacing,
    rafter_spacing_inches: initData.rafter_spacing_inches,
    helioscope_orientation: initData.helioscope_orientation === 'horizontal'? 1: 0,
    helioscope_setback: initData.helioscope_setback,
  };
  if (isSolarMount(pId)) {
    data.mid_clamps_choices = initData.mid_clamps_choices || 1;
    data.end_clamps_choices = initData.end_clamps_choices || 1;
    data.roof_pitch = ROOF_PITCH[initData.roof_pitch] || 5;
    data.input_unit = InputUnit.CUSTOMARY;
  }

  if (isNxtHorizon(pId)) {
    data.mid_clamps_choices = initData.mid_clamps_choices || 4;
    data.end_clamps_choices = initData.end_clamps_choices || 4;
    data.roof_pitch = ROOF_PITCH[initData.roof_pitch] || 5;
  }
  if (isEcoFoot2Plus(pId) || isRMGridFlex(pId)) {
    data.roof_pitch = Number(initData.roof_pitch ?? '0');
  }


  if (isRM10(pId) || isRMDT(pId) || isRM10Evolution(pId)) {
    delete data.row_spacing;
  }

  return data;
}

export function validateDataForImport(data) {
  if (data.wind_speed === undefined) {
    alert('Set wind speed before import');
    return false;
  }

  if (data.snow_load === undefined) {
    alert('Set snow load before import');
    return false;
  }

  if (data.seismic_ss === undefined) {
    alert('Set seismic_ss before import');
    return false;
  }

  if (!data.model || !data.module) {
    return false;
  }

  if (!data.module || !data.model) {
    return false;
  }

  return true;
}
