import { state } from '__common/store';
import {
  checkBuildingCode,
  checkElevation,
  checkSeismic,
  checkSnow,
  checkWindSpeed,
  checkWindExposure,
  checkFieldNotEmpty,
  } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';

export function checkEnvironmentalFactors(
  buildingCode,
  windSpeed,
  snowLoad,
  seismicSs,
  elevationInput,
) {
  const { projectConfiguration: { projectEnvConfig }  } = state();

  if (Object.keys(projectEnvConfig).length) {
    const { building_code, wind_speed, snow_load, seismic_ss, elevation } = projectEnvConfig;
    buildingCode.missing = !checkBuildingCode(building_code);
    windSpeed.missing = !checkWindSpeed(wind_speed);
    snowLoad.missing = !checkSnow(snow_load);

    if (seismicSs !== null) {
      seismicSs.missing = !checkSeismic(seismic_ss);
    }
    
    elevationInput.missing = !checkElevation(elevation);
  }
}

export const checkAdditionalGFTFactors = ( 
  railArrangementType,
  seismicS1,
  windExposure,
  riskCategory,
  windOnIce,
  iceThickness,
  frontEdgeHeight,
) => {
  const { projectConfiguration: { projectEnvConfig }  } = state();
  if (Object.keys(projectEnvConfig).length) {
    const { rail_arrangement_type, seismic_s1, wind_exposure, risk_category, wind_on_ice, ice_thickness, front_edge_height } = projectEnvConfig;

    windExposure.missing = !checkWindExposure(wind_exposure);
    seismicS1.missing = !checkFieldNotEmpty(seismic_s1);
    windOnIce.missing = !checkFieldNotEmpty(wind_on_ice);
    iceThickness.missing = !checkFieldNotEmpty(ice_thickness);
    riskCategory.missing = !checkFieldNotEmpty(risk_category);

    railArrangementType.missing = !checkFieldNotEmpty(rail_arrangement_type);
    frontEdgeHeight.missing = !checkFieldNotEmpty(front_edge_height);
  }
};
