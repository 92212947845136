import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import rafterSpacing from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import sfmRoofType, { sfmInfinityRoofType } from 'projectDesign/components/projectConfiguration/fields/sfmRoofType';
import snowLoad from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import span from 'projectDesign/components/projectConfiguration/fields/sfmSpans';
import StaggerAttachments from 'projectDesign/components/projectConfiguration/fields/staggerAttachments';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import { asce705WindSpeedForSfmAndSm, asce716WindSpeedForSfmAndSm, asce710WindSpeedForSfmAndSm } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { checkRafterSpacing, checkSeismic, checkShortestBuildingLength, checkBuildingHeight } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { state } from '__common/store';
import { getASCE716SfmBuildingDescriptionResidentalAdditionalInputs } from '../fields/getASCE716SfmBuildingDescriptionResidentalAdditionalInputs';
import { getASCE716722LocationsResidentalAdditionalInputs } from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { isASCE705, isASCE710, } from '__common/constants/buildingCodes';
import { isSFMInfinity, isSfmInfinity } from '__common/constants/products';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import { smBuildingHeight } from '../fields/buildingHeight';
import { RoofSubstrate, RoofType } from '../utils/constants';
import { AttachmentType, sfmInfinityAttachmentType } from '../fields/attachmentType';
import { SFMInfinityRoofType } from '../fields/roofType';
import { roofSubstrate } from '../fields/roofSubstrate';
import { asce716BuildingCodeSm } from '../fields/buildingCode';

function getWindSpeedField(buildingCodeValue: number) {
  if (isASCE705(buildingCodeValue)) {
    return asce705WindSpeedForSfmAndSm;
  }

  if (isASCE710(buildingCodeValue)) {
    return asce710WindSpeedForSfmAndSm;
  }

  return asce716WindSpeedForSfmAndSm;
}

function getRoofTypeField(productId: number) {
  return isSfmInfinity(productId)? sfmInfinityRoofType: sfmRoofType;
}

export default (buildingCodeValue: number) => {
  const {
    projectConfiguration: {
      projectEnvConfig: {
        seismic_ss,
        building_height,
        shortest_building_length,
        rafter_spacing_inches,
        roof_type,
        attachment_type,
        roof_substrate,
      },
      productId,
    },
  } = state();

  const buildingCode = asce716BuildingCodeSm;
  const windSpeed = getWindSpeedField(buildingCodeValue);
  const roofType = getRoofTypeField(productId);
  const buildingHeight = smBuildingHeight();

  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, seismicSs, elevation);

  rafterSpacing.missing = !checkRafterSpacing(Number(rafter_spacing_inches));
  seismicSs.missing = !checkSeismic(seismic_ss);
  buildingHeight.missing = !checkBuildingHeight(building_height * 1);
  const shortestBuildingLengthMissing = !checkShortestBuildingLength(shortest_building_length * 1);

  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      fields: [
        buildingCode,
        WindExposure,
        windSpeed,
        snowLoad,
        seismicSs,
        elevation,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
      ...(isSfmInfinity(productId) ? [topographicalFactorKzt] : []),
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        SFMInfinityRoofType,
        ...(isSFMInfinity(productId) && roof_type === RoofType.SHINGLE ? [sfmInfinityAttachmentType] : []) ,
        ...(isSFMInfinity(productId) && roof_type === RoofType.SHINGLE && attachment_type === AttachmentType.SFM_BUTYL_DTD ? [roofSubstrate(productId,attachment_type,roof_substrate,roof_type)] : []) ,
        StaggerAttachments,
        span,
        ...(isSFMInfinity(productId) && (roof_type===RoofType.TILE || attachment_type===AttachmentType.FLASHKIT || (roof_substrate !== RoofSubstrate.PLYWOOD && roof_substrate !== RoofSubstrate.OSB)) ? [rafterSpacing] : []) ,
        ...getASCE716SfmBuildingDescriptionResidentalAdditionalInputs(buildingCodeValue, shortestBuildingLengthMissing),
      ],
    },
    spans: true,
  };

  return cfg;
};

