import { createReducer } from '__common/utils/helpers';
import { getPanelStickPoints } from '../cursor/utils/snapToGridStickyPoints';
import { insertKdTreePoints } from '../cursor/utils/kdTreeStore';
import { insertPanelIntoRtree } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { PanelsActionTypes } from 'actionsConstants';
import {
  getPanelCollisionBounds,
} from '__editor/panelsEditor/components/panels/panels';
import { checkObstructionZoneForPanelsInPanelEditor } from '../obstructions/obstructionsHelper';
import { removePanelsFromState, setExposureForSibligns } from './utils/panelsManagment';
import { insertPanelCenterPoints } from './utils/panelsCenterStore';
import { checkPanelsExposure } from './utils/exposureRecheck';
import hash from 'object-hash';
import { Action } from '__common/store/action';
import { ADD_PANELS_ACTION, LOAD_PANELS_ACTION, UPDATE_PANELS_ACTION, RECHECK_PANELS_EXPO_ACTION, CHANGE_DESIRED_TABLE_LENGTH_ACTION, CHANGE_DESIRED_TABLE_WIDTH_ACTION, ADD_HASHMAP_ACTION } from './panelsActions';
import _ from 'lodash';
import store from '__common/store';

export const panelsState: panelsState = {
  panels: [],
  centerOffset: { x: 0, y: 0 },
  creatingBlocked: false,
  enabledRoofZones: true,
  exposureRecalculation: false,
  exposureRecaluclationHash: null,
  desiredTableLength: null,
  desiredTableWidth: null,
  panelsToBeMoved: [],
  panelsArrangementToBeMoved: {panelsToBeMovedGrid: [] , panelsToggleAttachmentGrid: [], panelConfig: null},
  bays: {
    panelBayMapping: new Map(),
    visitedBays:[]
  }
};

export default {
  panels: createReducer(panelsState, {
    [PanelsActionTypes.ADD_PANELS](state: panelsState, action: Action<ADD_PANELS_ACTION>): panelsState {
      return {
        ...state,
        panels: action.payload.panels,
      };
    },
    [PanelsActionTypes.ADD_PANEL_BAY_MAPPING](state: panelsState, action: Action<ADD_HASHMAP_ACTION>): panelsState {
      return {
        ...state,
        bays: {
          ...state.bays,
          panelBayMapping: action.payload.panelBayMapping
        },
      };
    },
    [PanelsActionTypes.START_EXPOSURE_RECALCULATION](state): panelsState {
      return { ...state, exposureRecalculation: true };
    },
    [PanelsActionTypes.RECHECK_PANELS_EXPO](state: panelsState, action: Action<RECHECK_PANELS_EXPO_ACTION>): panelsState {
      const { 
        panelsToRecheck,
        productId,
        roofId,
      } = action.payload;
      let panels = checkPanelsExposure(state.panels, panelsToRecheck, roofId);
      panels = setExposureForSibligns(panels, productId); 
      const exposureRecaluclationHash = hash(panels);
      return { ...state, panels, exposureRecalculation: false, exposureRecaluclationHash };
    },
    [PanelsActionTypes.RECHECK_PANELS_OBSTRUCTION_ZONE](state: panelsState) {
      const panels = checkObstructionZoneForPanelsInPanelEditor(state.panels);
      return { ...state, panels };
    },
    [PanelsActionTypes.REMOVE_PANELS](state: panelsState, action: action & { payload: { panelsIds: number[] } }): panelsState {
      const { panelsIds } = action.payload;
      const { moveArrayMode } = store.getState()
      const { bays: {panelBayMapping} } = state
      const { background: { toggleBaysContainer } } = store.getState() as appState
      let visitedBays 
      if(panelBayMapping instanceof Map && panelBayMapping.size && !moveArrayMode){
        let deletedBays: Array<number> = [];
        panelsIds.map((id:number) => {
          if (panelBayMapping?.has(id)) {
            const bays = panelBayMapping.get(id).map(bay => bay.id);
            deletedBays = deletedBays.concat(bays);
            panelBayMapping.delete(id);
          }
        });
        let visited: any = [...state.bays.visitedBays]
        visitedBays = visited.filter(id => !deletedBays?.includes(id));
      }
      const panels = removePanelsFromState(state.panels, panelsIds);
      return { 
        ...state, 
        panels, 
        exposureRecaluclationHash: null,
        bays:{
          ...state.bays,
          visitedBays
        }
      };
    },
    [PanelsActionTypes.BLOCK_CREATING_PANEL](state: panelsState) {
      return { ...state, creatingBlocked: true };
    },
    [PanelsActionTypes.UNBLOCK_CREATING_PANEL](state: panelsState) {
      return { ...state, creatingBlocked: false };
    },
    [PanelsActionTypes.RESET_PANELS_STATE](state: panelsState) {
      return { ...state, panels: [], creatingBlocked: false, exposureRecaluclationHash: null, panelsToBeMoved: [], panelsArrangementToBeMoved: {panelsToBeMovedGrid:[], panelsToggleAttachmentGrid: [], panelConfig : null}, bays:{ panelBayMapping: new Map(), visitedBays: []}};
    },
    [PanelsActionTypes.LOAD_PANELS_FROM_HISTORY](state: panelsState, action: action & { payload: { panels: panelInState[] } }): panelsState {
      const { panels } = action.payload;
      return { 
        ...state, 
        panels, 
        exposureRecaluclationHash: null,
      };
    },
    [PanelsActionTypes.LOAD_PANELS](state: panelsState, action: Action<LOAD_PANELS_ACTION>): panelsState {
      const { rowSpacing, columnSpacing, metersPerPixel, exposureRecaluclationHash } = action.payload;

      const panels = action.payload.panels.map((panel) => {

        if (action.payload.shouldCenter) {
          panel.x += state.centerOffset.x;
          panel.y += state.centerOffset.y;
        }

        panel.rTreeBounds = getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel);
        panel.stickPoints = getPanelStickPoints(panel.x, panel.y, panel.width, panel.height, panel.siblingSide, panel.panelEWPosition, panel.panelNSPosition, panel.tableHeight);
        panel.centerPoint = { x: panel.x, y: panel.y, id: panel.id };
        insertKdTreePoints(panel.stickPoints, panel.groupId, panel.id);
        insertPanelCenterPoints(panel.centerPoint);
        insertPanelIntoRtree(getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel));

        return panel;
      });

      return { 
        ...state, 
        panels, 
        centerOffset: { x: 0, y: 0 }, 
        exposureRecaluclationHash,
      };
    },
    [PanelsActionTypes.RESTORE_PANELS_COLLISIONS_BOUNDS](state: panelsState, action) {
      const { rowSpacing, columnSpacing, metersPerPixel } = action.payload;

      const panels = state.panels.map((panel) => {
        panel.rTreeBounds = getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel);
        insertPanelIntoRtree(panel.rTreeBounds);
        return panel;
      });

      return { ...state, panels };
    },
    [PanelsActionTypes.SET_ROOF_ZONES_AUTOFILL](state: panelsState, action: { payload: { status: boolean } }) {
      return { ...state, enabledRoofZones: action.payload.status };
    },
    [PanelsActionTypes.CHANGE_SINGLE_PANEL_ROOF_ZONE](state: panelsState, action: { payload: { panelId: number, newRoofZone: roofZoneNumber } }) {
      const { panels } = state;
      const { panelId, newRoofZone } = action.payload;
      const panelIndex = panels.findIndex((panel) => {
        return panel.id === panelId;
      });
      panels[panelIndex].roofZone = newRoofZone;
      return { ...state, panels };
    },
    [PanelsActionTypes.SET_CENTER_OFFSET](state: panelsState, action: { payload: { offset: { x: number, y: number } } }) {
      const { offset } = action.payload;

      return { ...state, centerOffset: offset };
    },
    [PanelsActionTypes.UPDATE_PANELS](state: panelsState, action: Action<UPDATE_PANELS_ACTION>): panelsState {
      const { payload: { panels, type } } = action;
      const idsToUpdate = panels.map(p => p.id);
      const updatedPanels = state.panels.map((oldPanel) => {
        if (idsToUpdate.includes(oldPanel.id)) {
          const foundPanel = panels.find(p => p.id === oldPanel.id);
          return {
            ...oldPanel,
            ...(type?.type ? { [type?.type]: foundPanel[type?.type] } : foundPanel),
          };
        }
        return oldPanel;
      });
      return { ...state, panels: updatedPanels };
    },
    [PanelsActionTypes.CHANGE_DESIRED_TABLE_LENGTH](state: panelsState, action: Action<CHANGE_DESIRED_TABLE_LENGTH_ACTION>): panelsState {
      return { ...state, desiredTableLength: action.payload.length };
    },
    [PanelsActionTypes.CHANGE_DESIRED_TABLE_WIDTH](state: panelsState, action: Action<CHANGE_DESIRED_TABLE_WIDTH_ACTION>): panelsState {
      return { ...state, desiredTableWidth: action.payload.width };
    },
    [PanelsActionTypes.RESET_TABLE_LENGTH_AND_WIDTH_SIZE](state: panelsState): panelsState {
      return { ...state, desiredTableLength: null, desiredTableWidth: null };
    },
    [PanelsActionTypes.SET_PANELS_ARRAY_FOR_MOVEMENT](state: panelsState, action): panelsState {
      return { ...state, panelsToBeMoved:  action.payload.panels };
    },
    [PanelsActionTypes.SET_PANELS_ARRAY_GRID_FOR_MOVEMENT](state: panelsState, action): panelsState {
      const {grid, toggleAttachmentsGrid, panelConfig} = action.payload;
      return { ...state, panelsArrangementToBeMoved:  {panelsToBeMovedGrid: grid, panelsToggleAttachmentGrid: toggleAttachmentsGrid, panelConfig: panelConfig}};
    },
    [PanelsActionTypes.RESET_PANELS_ARRAY_FOR_MOVEMENT](state: panelsState, action): panelsState {
      return { ...state, panelsToBeMoved:  [] , panelsArrangementToBeMoved:{panelsToBeMovedGrid: [], panelsToggleAttachmentGrid: [], panelConfig: null}};
    },
    [PanelsActionTypes.TOGGLE_PANEL_ATTACHED](state: panelsState, action): panelsState {
      const panelId = action.payload.panelId;
      const panels = state.panels.map(panel=>{
        if (panel.id === panelId) {
          panel.attached = !panel.attached;
        }
        return panel; 
      }) 
      return { ...state, panels };
    },
    [PanelsActionTypes.TOGGLE_BAY_ATTACHED](state: panelsState, action): panelsState {
      const bayId = action.payload.bayId;
      const panelId = action.payload.panelId;
      const mapping = new Map([...Array.from(state.bays.panelBayMapping)]);
      mapping.get(panelId)?.map(bay=> {
        if(bay.id === bayId){
          bay.attached = bay.attached ? 0 : 1
        }
      })
      return { ...state, bays: { ...state.bays, panelBayMapping: mapping } };
    },
    [PanelsActionTypes.ADD_VISITED_BAYS](state: panelsState, action): panelsState {
      const bays = action.payload.bays;
      return { ...state, bays:{...state.bays, visitedBays:bays} };
    },
  }),
};
