import { ActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';


export const helioscopeDesignsListState: helioscopeDesignsListState = {
  designs: [],
  name: '',
  isLoading: false,
  isReady: false,
};

export default {
  helioscopeDesignsList: createReducer(helioscopeDesignsListState, {
    [ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_REQUEST](state) {
      const helioscopeDesignsList = { ...state.helioscopeDesignsList, isLoading: true };

      return { ...state, ...helioscopeDesignsList };
    },
    [ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_SUCCESS](state, action) {
      const helioscopeDesignsList = {
        ...state.helioscopeDesignsList,
        designs: action.payload.data.designs,
        name: action.payload.data.name,
      };

      return { ...state, ...helioscopeDesignsList, isLoading: false, isReady: true };
    },
    [ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_FAILURE](state, action) {
      const helioscopeDesignsList = {
        ...state.helioscopeDesignsList,
        ...action.payload,
        isLoading: false,
        isReady: false,
        error: true,
      };

      return { ...state, ...helioscopeDesignsList };
    },
    [ActionTypes.CLEAR_HELIOSCOPE_DESIGNS_LIST_STATE](state) {

      return { designs: [],
        isLoading: false,
        isReady: false,
        name: '',
      };
    },
  }),
};

