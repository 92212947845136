
export const apiField = 'limit_down_point_loads';

const config = {
  label: 'limitDownPointLoads',
  type: 'Select',
  apiField,
  missing: undefined,
  data: [
    { value: 1, name: 'YES' },
    { value: 0, name: 'NO' },
  ],
};

export default config;
