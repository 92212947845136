import { addNewBingMapRoofArea, zoomOnBingAllRoofs } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { dispatch, state } from '__common/store';
import { getNewRoofEdgesForClassicDesigner } from '__editor/panelsEditor/components/background/background';
import { SELECT_ROOF_LEAD_EDGE, SORT_ROOFS_ON_MAP, UPDATE_ROOF_COORDS } from 'actions';
import { selectBingRoofLeadEdge } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/utils/selectBingLeadEdge';
import { getRoofApproximateCenter } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/getRoofApproximateCenter';

const startPoint: cordPoint = { lat: 34.052544436793006, lng: -118.24445659093726 };

export const startCoords: cordPoint[] = [
  startPoint,
  { lat: 34.052544436793006, lng: -118.24405233105153 },
  { lat: 34.05221388839965, lng: -118.24405233105153 },
  { lat: 34.05221388839965, lng: -118.24445659093726 },
];

export const roofsSelectorClassicDesignerOffset = 0.0005;
const lngRoofSpacing = 0.00009;

export function createNewArray() {
  const coords = getNewRoofCoords();
  const roofId = addNewBingMapRoofArea(coords);
  selectBingRoofLeadEdge({ edgeNumber: 2, roofId });
  zoomOnBingAllRoofs();
}

// remove export
export function getNewRoofCoords() {
  const {
    drawingManager: { roofs },
  } = state();
  if (roofs && Object.keys(roofs).length) {
    const lastCoords = getMaxLastCoordsFromAllRoofs();
    const lastCoordsDiff = getLastCoordsDiff(lastCoords, startCoords);
    return startCoords.map(coord => {
      return {
        lat: coord.lat + lastCoordsDiff.lat,
        lng: coord.lng + lastCoordsDiff.lng + lngRoofSpacing,
      };
    });
  }

  return startCoords;
}

export function updateRoofCoordsForClassicDesigner() {
  const {
    background: { selectedRoofId },
  } = state();
  const coords = getNewRoofEdgesForClassicDesigner(selectedRoofId);
  dispatch(UPDATE_ROOF_COORDS(coords, selectedRoofId));

  dispatch(SORT_ROOFS_ON_MAP());
  reselectLeadEdges();
}

export function sortRoofsOnMap(roofs: {
  [roofId: string]: drawingManagerRoof;
}) {
  const newRoofs = {};
  let lastCoords: cordPoint = null;

  Object.keys(roofs).map((roofId: string, indexOfRoof: number) => {
    let lastCoordsDiff: cordPoint;
    const roof = roofs[roofId];
    let roofCoords = roof.coords;

    const coordsDiff = getFirstCoordsDiff(startPoint, roofCoords);

    if (lastCoords) {
      lastCoordsDiff = getLastCoordsDiff(lastCoords, roofCoords);
    }

    roofCoords = roofCoords.map((coord, indexOfCoords) => {
      let newCoord = coord;

      if (indexOfCoords === 0 && indexOfRoof === 0) {
        newCoord = startPoint;
      } else if (indexOfRoof === 0 || !lastCoords) {
        newCoord = giveCoordsForFirstArray(coord, coordsDiff);
      } else {
        newCoord = giveCoordsForNonFirstArray(coord, lastCoordsDiff);
      }

      return newCoord;
    });

    lastCoords = getMaxLastCoords(roofCoords);
    const googlePoly = new google.maps.Polygon({ paths: roofCoords });
    getRoofApproximateCenter();
    const center = googlePoly.getRoofApproximateCenter();
    roof.marker = center.toJSON();
    roof.coords = roofCoords;
    newRoofs[roofId] = roof;
  });

  return newRoofs;
}

function giveCoordsForFirstArray(
  coord: cordPoint,
  coordsDiff: cordPoint,
) {
  return {
    lat: coord.lat + coordsDiff.lat,
    lng: coord.lng + coordsDiff.lng,
  };
}

function giveCoordsForNonFirstArray(
  coord: cordPoint,
  coordsDiff: cordPoint,
) {
  return {
    lat: coord.lat + coordsDiff.lat,
    lng: coord.lng + coordsDiff.lng + 0.00009,
  };
}

function getFirstCoordsDiff(
  startPoint: cordPoint,
  roofCoords: cordPoint[],
) {
  const lat = startPoint.lat - roofCoords[0].lat;
  const lng = startPoint.lng - roofCoords[0].lng;

  return {
    lat,
    lng,
  };
}

function getLastCoordsDiff(
  lastCoords: { lat: number; lng: number },
  roofCoords: { lat: number; lng: number }[],
) {
  const lat = lastCoords.lat - roofCoords[0].lat;
  const lng = lastCoords.lng - roofCoords[0].lng;

  return {
    lat,
    lng,
  };
}

function reselectLeadEdges() {
  const {
    drawingManager: { roofs },
    leadEdgeRoofSelector: { leadEdges }
  } = state();
  Object.keys(roofs).map(roofId => {
    dispatch(SELECT_ROOF_LEAD_EDGE(leadEdges[roofId], Number(roofId)));
  });
}

function getMaxLastCoords(coords: { lat: number; lng: number }[]): cordPoint {
  const lats = coords.map(coord => coord.lat);
  const lngs = coords.map(coord => coord.lng);

  return {
    lat: Math.max(...lats),
    lng: Math.max(...lngs),
  };
}

function getMaxLastCoordsFromAllRoofs(): cordPoint {
  const {
    drawingManager: { roofs },
  } = state();
  return getMaxLastCoords(
    roofs[Object.keys(roofs)[Object.keys(roofs).length - 1]].coords,
  ); // get last item of object. Order not guarantee ;/
}
