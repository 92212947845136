import { createReducer } from '__common/utils/helpers';
import { DimensionsActionTypes } from 'actionsConstants';


export const dimensionsState: dimensionsState = {
  ns: 0,
  ew: 0,
};

export default {
  dimensions: createReducer(dimensionsState, {
    [DimensionsActionTypes.SET_DIMENSIONS](state, action) {
      const { ns, ew } = action.payload.dimensions;
      return { ...state, ns, ew };
    },
    [DimensionsActionTypes.RESET]() {
      return dimensionsState;
    },
  }),
};
