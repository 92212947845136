import keyMirror from 'keymirror';

/**
 * @namespace Constants
 * @desc TiltedRoof constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const TiltedRoofActionTypes = keyMirror({
  SET_TILTED_ROOF: undefined,
  SET_TILTED_ROOF_STRUCTURE_TILT: undefined,
  CLEAR_TILTED_ROOF: undefined,
  TOOGLE_ROOF_SCALING: undefined,
  TOOGLE_BACKGROUND_SCALING: undefined,
});
