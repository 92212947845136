export const apiField = 'add_roof_pads_everywhere';

interface addRoofPadsEverywhereField {
  type: string;
  apiField: string;
  label: string;
  id?: string;
  aria_label?: string;
  
}

const config:addRoofPadsEverywhereField = {
  label: 'addRoofPadsEverywhere',
  type: 'Checkbox',
  apiField,
  id: "addroofpadseverywhere", 
  aria_label: "Add Roof Pads Everywhere Selection",
};



export default config;

