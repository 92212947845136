import { anyRoofHasManualAttachments, deleteManualAttachmentsOnPanels } from "__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper";
import { getYesOrNo } from "../projectConfiguration";
export const apiField = 'allow_manual_attachments';

const config = {
  label: 'allowManualAttachments',
  type: 'Select',
  apiField,
  onConfirm: (productId: number) => deleteManualAttachmentsOnPanels(productId),
  shouldConfirm: () => true,
  shouldConfirmCb: (val: string) => anyRoofHasManualAttachments(Number(val)),
  missing: undefined,
  title: 'Are you sure you want to disallow manual attachments?',
  content: 'All manual attachment markers will be lost!',
  data: getYesOrNo(),
};

export default config;
