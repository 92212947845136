import { isMetricUnit } from "engineering/components/engineeringProjectDocuments/utils/unitTypes";
import { RiskCategory } from "../fields/riskCategory";

export const MINIMUM_SNOW_LOAD_THAT_REQUIRES_STAGGER_ATTACHMENTS = 10; // psf - pounds per square foot
export const BUILDING_HEIGHT_LOWER_BOUND = 54;
export const BUILDING_HEIGHT_UPPER_BOUND = 73;
export const BUILDING_HEIGHT_LOWER_BOUND_MTERIC = 16.46;
export const BUILDING_HEIGHT_UPPER_BOUND_METRIC = 22.25;

export const BUILDING_HEIGHT_LIMITS = (inputUnit) => isMetricUnit(inputUnit) ? { LOWER_BOUND: BUILDING_HEIGHT_LOWER_BOUND_MTERIC, UPPER_BOUND: BUILDING_HEIGHT_UPPER_BOUND_METRIC} : { LOWER_BOUND : BUILDING_HEIGHT_LOWER_BOUND, UPPER_BOUND: BUILDING_HEIGHT_UPPER_BOUND };


export function shouldUseStaggerAttachmentsForSfm(snowLoad: number): boolean {
  return snowLoad > MINIMUM_SNOW_LOAD_THAT_REQUIRES_STAGGER_ATTACHMENTS;
}

export const buildingHeightCheck = (buildingHeight: number, inputUnit: number = 1) => {
  const { LOWER_BOUND, UPPER_BOUND } = BUILDING_HEIGHT_LIMITS(inputUnit);
  return buildingHeight >= LOWER_BOUND && buildingHeight <= UPPER_BOUND;
}

export const riskCategoryCheckForTornadoSpeed = (riskCategory: number) => {
  return riskCategory === RiskCategory.III || riskCategory === RiskCategory.IV;
}

export const ABOVE_GROUND_HT_AT_2p5FT_LEADING_HT= 74.875
export const ABOVE_GROUND_HT_AT_2FT_LEADING_HT= 54.5
