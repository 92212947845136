import { BackgroundActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';

export function BACKGROUND_SET_ROTATION(degrees: number) {
  return {
    type: BackgroundActionTypes.BACKGROUND_SET_ROTATION,
    payload: {
      degrees,
    },
  };
}

export function PANELS_SET_ROTATION(degrees: number) {
  return {
    type: BackgroundActionTypes.PANELS_SET_ROTATION,
    payload: {
      degrees,
    },
  };
}

export function BACKGROUND_CHANGE(background: string) {
  return {
    type: BackgroundActionTypes.BACKGROUND_CHANGE,
    payload: {
      background,
    },
  };
}

export function RESIZE_BACKGROUND(shouldResize: boolean) {
  return {
    type: BackgroundActionTypes.RESIZE_BACKGROUND,
    payload: {
      shouldResize,
    },
  };
}

export function BACKGROUND_ZOOM_CHANGE(zoom: number) {
  return {
    type: BackgroundActionTypes.BACKGROUND_ZOOM_CHANGE,
    payload: {
      zoom,
    },
  };
}
export function TURN_ON_BLURED_BACKGROUND(shouldTurnOn: boolean) {
  return {
    type: BackgroundActionTypes.TURN_ON_BLURED_BACKGROUND,
    payload: {
      bluredBackground: shouldTurnOn,
    },
  };
}

export function TURN_ON_BLURED_BACKGROUND_ROTATION(shouldTurnOn: boolean) {
  return {
    type: BackgroundActionTypes.TURN_ON_BLURED_BACKGROUND_ROTATION,
    payload: {
      bluredBackgroundRotation: shouldTurnOn,
    },
  };
}

export function SET_METERS_PER_PIXEL(metersPerPixel: number) {
  return {
    type: BackgroundActionTypes.SET_METERS_PER_PIXEL,
    payload: {
      metersPerPixel,
    },
  };
}

export interface ACTIVE_PANELS_EDITOR_ACTION {
  cords: cordPoint;
  roofEdges: cordPoint[];
  selectedRoofId: number;
  roofEdgesPixiCords?: {x: number, y: number}[];
  blank_map_building_length?: number;
  blank_map_building_width?: number;
  panelsRotationDegrees?: number;
  lowEdgeToRoof?: number;
}

export function ACTIVE_PANELS_EDITOR(cords: { lat: number, lng: number }, roofEdges: { lat: number, lng: number }[], selectedRoofId: number, roofEdgesPixiCords?: {x: number, y: number}[], blank_map_building_length?: number, blank_map_building_width?: number, panelsRotationDegrees?: number, lowEdgeToRoof?: number): Action<ACTIVE_PANELS_EDITOR_ACTION> {
  return {
    type: BackgroundActionTypes.ACTIVE_PANELS_EDITOR,
    payload: {
      cords,
      roofEdges,
      selectedRoofId,
      roofEdgesPixiCords,
      blank_map_building_length,
      blank_map_building_width,
      panelsRotationDegrees,
      lowEdgeToRoof,
    },
  };
}

export function DEACTIVE_PANELS_EDITOR() {
  return {
    type: BackgroundActionTypes.DEACTIVE_PANELS_EDITOR,
  };
}

export interface SET_BACKGROUND_XY_POS_ACTION {
  bgXY: pixelPoint;
  bgScale: pixelPoint;
}

export function SET_BACKGROUND_XY_POS(x: number, y: number, bgScale: pixelPoint): Action<SET_BACKGROUND_XY_POS_ACTION> {
  return {
    type: BackgroundActionTypes.SET_BACKGROUND_XY_POS,
    payload: {
      bgXY: { x, y },
      bgScale,
    },
  };
}

export function SAVE_ROOF_EDGES_POINTS(roofEdgesPixiCords: pixelPoint[]) {
  return {
    type: BackgroundActionTypes.SAVE_ROOF_EDGES_POINTS,
    payload: {
      roofEdgesPixiCords,
    },
  };
}

export interface UPDATE_ROOF_EDGES_ACTION {
  roofEdges: cordPoint[];
}

export function UPDATE_ROOF_EDGES(roofEdgesCoordinates: cordPoint[]): Action<UPDATE_ROOF_EDGES_ACTION> {
  return {
    type: BackgroundActionTypes.UPDATE_ROOF_EDGES,
    payload: {
      roofEdges: roofEdgesCoordinates,
    },
  };
}

export function CLEAR_BACKGROUND_REDUCER() {
  return {
    type: BackgroundActionTypes.CLEAR_BACKGROUND_REDUCER,
  };
}

export function BG_LOADED() {
  return {
    type: BackgroundActionTypes.BG_LOADED,
  };
}

export function BG_CLEAR_LOADED() {
  return {
    type: BackgroundActionTypes.BG_CLEAR_LOADED,
  };
}

export function MOVE_MAP_MODE_ENABLE() {
  document.body.style.cursor = '-webkit-grab';
  return {
    type: BackgroundActionTypes.MOVE_MAP_MODE_ENABLE,
  };
}

export function MOVE_ARRAY_MODE_DISABLE() {
  document.body.style.cursor = 'default';
  return {
    type: BackgroundActionTypes.MOVE_ARRAY_MODE_DISABLE,
  };
}

export function MOVE_ARRAY_MODE_ENABLE() {
  document.body.style.cursor = '-webkit-grab';
  return {
    type: BackgroundActionTypes.MOVE_ARRAY_MODE_ENABLE,
  };
}

export function MOVE_MAP_MODE_DISABLE() {
  document.body.style.cursor = 'default';
  return {
    type: BackgroundActionTypes.MOVE_MAP_MODE_DISABLE,
  };
}

export function TOGGLE_ATTACHMENTS_ENABLE() {
  return {
    type: BackgroundActionTypes.TOGGLE_ATTACHMENTS_ENABLE
  }
}

export function ENABLE_BAYS_CONTAINER() {
  return {
    type: BackgroundActionTypes.ENABLE_BAYS_CONTAINER
  }
}

export function DISABLE_BAYS_CONTAINER() {
  return {
    type: BackgroundActionTypes.DISABLE_BAYS_CONTAINER
  }
}

export function TOGGLE_ATTACHMENTS_DISABLE() {
  return {
    type: BackgroundActionTypes.TOGGLE_ATTACHMENTS_DISABLE
  }
}

export function TOGGLE_ROOF_DIMENSIONS() {
  return {
    type: BackgroundActionTypes.TOGGLE_ROOF_DIMENSIONS
  }
}

export function SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH(length: number, width: number) {
  return {
    type: BackgroundActionTypes.BLANK_MAP_BUILDING_LENGTH_WIDTH,
    payload: {
      blank_map_building_width: width,
      blank_map_building_length: length,
    },
  };
}

export function BACKGROUND_SET_LOW_EDGE_TO_ROOF(lowEdgeToRoof: number) {
  return {
    type: BackgroundActionTypes.BACKGROUND_SET_LOW_EDGE_TO_ROOF,
    payload: {
      lowEdgeToRoof,
    },
  };
}