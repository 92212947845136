import overlaps from 'turf-overlaps';
import intersect from 'turf-intersect';
const polygon = require('turf-helpers').polygon;
import { createNewArray } from '../roofsSelectorClassicDesigner/roofsSelectorClassicDesigner';
import { dispatch, state } from '__common/store';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL, SET_LOCATION, HIDE_CURSOR, SHOW_CURSOR, OPEN_DRAWER_PAGE } from 'actions';

import {
  deleteAllBingShapes,
  getBingRoofOver,
  makeBingRoofEmpty,
  removeBingShape,
  toogleBingRoofSelection,
  redrawBingRoofs,
  goToBingPanelsEditor,
  turnOffBingEditing,
  } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import {
  deleteAllGoogleRoofs,
  deleteGoogleRoof,
  getGoogleRoofOver,
  makeGoogleRoofEmpty,
  selectGoogleRoof,
  redrawGoogleRoofs,
  goToGooglePanelsEditor,
  } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { clearAutoSaveProjectTimeout } from '../roofsSelectorSaveLoadProject/saveProject';
import { REMOVE_ROOF_OBSTRUCTIONS, SET_RELOCATION_ACTIVE } from '__editor/panelsEditor/actions';
import { isBlankMap, isGoogleMap } from '__common/constants/map';
import { isCommercialProduct, isEcoFoot2Plus, isRMGridFlex, isSMTiltPR, isAscender, isResidentialProduct, isRMIFIProduct } from '__common/constants/products';
import { RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS } from 'projectDesign/components/projectConfiguration/utils/updateProjectOption';
import { checkFieldNotEmpty } from '../roofsSelectorSaveLoadProject/utils/validateProject';
import { isASCE716or722 } from '__common/constants/buildingCodes';
import { apiField as buildingHeightApiField } from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import { distanceCheckFromFirstRoofCenter, distanceErrorMessage } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/roofSizeAlert';
import { apiField as longestBuildingLengthApiField } from 'projectDesign/components/projectConfiguration/fields/longestBuildingLength';
import { apiField as shortestBuildingLengthApiField } from 'projectDesign/components/projectConfiguration/fields/shortestBuildingLength';
import { LOCATION_AND_LOADS_PAGE, RAIL_SYSTEM_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import { apiField as buildingCodeApiField } from 'projectDesign/components/projectConfiguration/fields/buildingCode';

let editorWidth;
let editorHeight;
let panelsInteractionBlockTimeout;
const panelsInteractionBlockTimeoutTime = 500;

export let panelsInteractionBlock = false;

export function setEditorDims(width, height) {
  editorHeight = height;
  editorWidth = width;
}

export function getEditorDims() {
  return {
    width: editorWidth,
    height: editorHeight,
  };
}

// Remove: once roof pitch 1/12 is available for 716
export function clearRoofsWhenAsce716Or722IsEnabled(currentBuildingCode) {
  const {  drawingManager: { roofs }, projectConfiguration: { projectEnvConfig : { tilt }, productId } } = state();
  
  if(isASCE716or722(currentBuildingCode)) { 
    if (roofs && Object.keys(roofs).length) {
      Object.keys(roofs).map(roofId => {
        if(roofs[roofId].roofPitch === '1/12') {
          makeRoofEmpty(Number(roofId)); 
        }
      });
    }
  }
}


export function clearRoofsFromMap() {
  const { drawingManager: { roofs }, roofsSelector: { mapType } } = state();
  if (roofs && Object.keys(roofs).length > 0) {
    if (isGoogleMap(mapType)) {
      deleteAllGoogleRoofs();
    } else {
      deleteAllBingShapes();
    }
  }
}

export function getRoofOver() {
  const { roofsSelector: { mapType } } = state();
  if (isGoogleMap(mapType)) {
    return getGoogleRoofOver();
  }

  return getBingRoofOver();
}

export function makeAllRoofsEmpty() {
  const { drawingManager: { roofs } } = state();

  if (roofs && Object.keys(roofs).length) {
    Object.keys(roofs).map(roofId => makeRoofEmpty(Number(roofId)));
  }
}

export function makeRoofEmpty(roofId: number) {
  const { roofsSelector: { mapType } } = state();
  if (isGoogleMap(mapType)) {
    return makeGoogleRoofEmpty(roofId);
  }

  return makeBingRoofEmpty(roofId);
}

export function selectRoof(roofId: number) {
  const { roofsSelector: { mapType } } = state();
  if (isGoogleMap(mapType)) {
    return selectGoogleRoof(roofId);
  }

  return toogleBingRoofSelection(roofId);
}

export function deleteRoof(roofId: number) {
  const { roofsSelector: { mapType }, drawingManager: {roofs} } = state();
  const numberOfRoofAreas = Object.values(roofs)?.length;

  dispatch(REMOVE_ROOF_OBSTRUCTIONS(roofId));

  if (isGoogleMap(mapType)) {
    return deleteGoogleRoof(roofId);
  }

  removeBingShape(roofId);

  if (isBlankMap(mapType) && Number(roofId) === 1 && numberOfRoofAreas < 2) {
    createNewArray();
  }
}

export function clearRoof(marker: {lat:number, lng:number}, cb: () => void) {
  const { drawingManager: { roofs }, roofsSelectorMap: { location } } = state();
  let oldLocation = location;
  if (roofs && Object.keys(roofs).length > 0 && distanceCheckFromFirstRoofCenter(true, marker)) {
    dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        'Change roofarea location',
        'Changing roofarea location will remove all installations. Are you sure?',
        () => {
          clearRoofsFromMap();
          dispatch(SET_RELOCATION_ACTIVE(false));
          distanceErrorMessage(false);
          cb();
        },
        null,
        null,
        SET_LOCATION(oldLocation += ' '),
      ),
    );
  } else {
    cb()
  }
}

export function clearRoofWithWarning(cb: () => void) {
  const { drawingManager: { roofs }, roofsSelectorMap: { location } } = state();
  let oldLocation = location;
  if (roofs && Object.keys(roofs).length > 0) { 
    dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        'Change map location',
        'Changing map location will remove all installations. Are you sure?',
        () => {
          clearRoofsFromMap();
          cb();
          dispatch(SET_RELOCATION_ACTIVE(false));
        },
        null,
        null,
        SET_LOCATION(oldLocation += ' '),
      ),
    );
  } else {
    cb();
  }
}

export function newRoofOverlaping(newRoofsCoords: { lat: number; lng: number }[], roofId?: string) {
  const roofs = state().drawingManager.roofs;

  const newTurfPolyCords = newRoofsCoords.map(coord => {
    return [coord.lat, coord.lng];
  });

  newTurfPolyCords.push(newTurfPolyCords[0]);

  const newTurfPoly = polygon([newTurfPolyCords]);

  if (!roofs || Object.keys(roofs).length === 0) {
    return false;
  }

  return Object.keys(roofs).some(roofName => {
    const roof = roofs[roofName];

    const turfPolyCords = roof.coords.map(coord => {
      return [coord.lat, coord.lng];
    });

    if (Number(roofName) === Number(roofId)) {
      return false;
    }

    turfPolyCords.push(turfPolyCords[0]);

    const turfPoly = polygon([turfPolyCords]);
    
    return overlaps(turfPoly, newTurfPoly) || intersect(turfPoly, newTurfPoly);
  });
}

export function redrawRoof() {
  const { roofsSelector: { mapType } } = state();

  if (isGoogleMap(mapType)) {
    return redrawGoogleRoofs();
  }

  return redrawBingRoofs();
}

const openDrawerPage = (invalidFields) => {
  if (invalidFields.has(buildingCodeApiField) || invalidFields.has('zipcode')) {
    dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
  }
  else {
    dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
  }
}

export function canGotoPanelsEditor() {
  const { projectConfiguration: { productId, formState: { invalidFields: allInvalidFields }, projectEnvConfig } } = state();

  // restrict to go to panel editor if fields are empty/invalid which affect the setback/wind zone classification
  let invalidFields = new Set<string>();
  if (isRMGridFlex(productId) ) {
    if ( isRMGridFlex(productId) ) {
      RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS.map(field =>  !checkFieldNotEmpty(projectEnvConfig[field]) && invalidFields.add(field));
      allInvalidFields.map(({ fieldName }) => RM_GRIDFLEX_SETBACK_AFFECTING_FIELDS.includes(fieldName) && invalidFields.add(fieldName));
    }
  }
  
  if (isCommercialProduct(productId) || isResidentialProduct(productId)){
    let DEFAULT_EMPTY_FIELDS = [buildingHeightApiField];

    if ( (isCommercialProduct(productId)  && !isRMIFIProduct(productId)) || isAscender(productId)   || isSMTiltPR(productId) ) {
      DEFAULT_EMPTY_FIELDS.push(longestBuildingLengthApiField);
    }
    
    if (isResidentialProduct(productId) || isEcoFoot2Plus(productId)) {
        DEFAULT_EMPTY_FIELDS.push(shortestBuildingLengthApiField);
    }

    DEFAULT_EMPTY_FIELDS.map(field =>  !checkFieldNotEmpty(projectEnvConfig[field]) && invalidFields.add(field));
    allInvalidFields.map(({ fieldName }) => DEFAULT_EMPTY_FIELDS.includes(fieldName) && invalidFields.add(fieldName));

    if(invalidFields.size){
      openDrawerPage(invalidFields);
      return false;
    }
  }
  
    const DEFAULT_EMPTY_FIELDS = [ 'zipcode' ];
    DEFAULT_EMPTY_FIELDS.map(field => !checkFieldNotEmpty(projectEnvConfig[field]) && invalidFields.add(field));
    allInvalidFields.map(({ fieldName }) => DEFAULT_EMPTY_FIELDS.includes(fieldName) && invalidFields.add(fieldName));
  
  if(invalidFields.size){
    openDrawerPage(invalidFields);
    return false;
  }
  return true;
}

export function goToPanelsEditor(roofInfo/* : google.maps.Polygon | Leaflet.LatLng */, roofId: number) {
  
  if (!canGotoPanelsEditor()) return;

  const { roofsSelector: { mapType } } = state();

  clearTimeout(panelsInteractionBlockTimeout);
  clearAutoSaveProjectTimeout();
  turnOffEditing();

  panelsInteractionBlock = true;
  
  dispatch(HIDE_CURSOR());
  
  if (isGoogleMap(mapType)) {
    goToGooglePanelsEditor(roofInfo, roofId);
    panelsInteractionBlockTimeout = setTimeout(
      () => { 
        panelsInteractionBlock = false; 
        dispatch(SHOW_CURSOR());
      }, 
      panelsInteractionBlockTimeoutTime);
    return;
  }

  goToBingPanelsEditor(roofInfo, roofId);
  panelsInteractionBlockTimeout = setTimeout(
    () => { 
      panelsInteractionBlock = false; 
      dispatch(SHOW_CURSOR());
    }, 
    panelsInteractionBlockTimeoutTime);
  return;
}

export function clearPanelsInteractionBlockTimeout() {
  clearTimeout(panelsInteractionBlockTimeout);
}

function turnOffEditing() {
  const { roofsSelector: { mapType } } = state();

  if (isGoogleMap(mapType)) {
    // to be done someday;
    return;
  }

  return turnOffBingEditing();
}
