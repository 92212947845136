import rootEpics from 'epics';
import rootReducer from 'reducers';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { panelsHistoryMiddleware } from './middlewares/panelsHistoryMiddleware';
import { saveBomMiddleware } from './middlewares/saveBomMiddleware';
import { saveProjectMiddleware } from './middlewares/saveProjectMiddleware';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  } from 'redux';
import { sentryReporter } from './middlewares/sentryMiddleware';
import { panelsLimitsMiddleware } from './middlewares/panelsLimitsMiddleware';
import { addPanelsMiddleware } from './middlewares/addPanels';
const epicMiddleware = createEpicMiddleware(rootEpics);
const reducer = combineReducers({ ...rootReducer });

export default (initialState = {}) => {
  const middlewares = [
    thunk,
    epicMiddleware,
    saveBomMiddleware,
    panelsHistoryMiddleware,
    saveProjectMiddleware,
    sentryReporter,
    panelsLimitsMiddleware,
    addPanelsMiddleware,
  ];

  const createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(createStore);

  return createStoreWithMiddleware(reducer, initialState);
};
