import { ADD_RAILS_TO_BOM, RAILS_LOADED, FETCH_RAILS_ACTION, SELECT_ONLY_SMHD_RAILS, UNSELECT_ONLY_SMHD_RAILS_OPTION, RAIL_TYPE_LOADED } from 'actions';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { RailsSelectorActionTypes } from 'actionsConstants';
import { saveBom } from '../../utils/saveBom';
import { getRailsUrl } from './utils/railsApi';
import { ActionsObservable } from 'redux-observable';
import { Store, AnyAction } from 'redux';
import { Action } from '__common/store/action';
import { ADD_CLAMPS, ADD_NXT_CLAMPS } from 'bom/bomActions';
import { isNxtHorizon, isNxtTilt, isULA } from '__common/constants/products';
import { Observable } from 'rxjs';
import { showErrorAlert } from '__common/modules/alerts';
import nprogress from 'nprogress';
import { FETCHING_RAILS } from './railsSelectorActions';
import { MidEndClampChoice } from 'projectDesign/components/projectConfiguration/utils/constants';

export function fetchRails(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(RailsSelectorActionTypes.FETCH_RAILS)
    .debounceTime(2000)
    .switchMap((action: Action<FETCH_RAILS_ACTION>) => {
      const { projectConfiguration: { productId, projectEnvConfig : { end_clamps_choices } } } = store.getState();
      store.dispatch(FETCHING_RAILS());
      return ObservableAjax({
        takeUntil: action$.ofType(RailsSelectorActionTypes.FETCH_RAILS),
        onSuccess: isULA(productId) ? [ADD_RAILS_TO_BOM, RAILS_LOADED, RAIL_TYPE_LOADED] : [ADD_RAILS_TO_BOM, RAILS_LOADED, (isNxtHorizon(productId) || isNxtTilt(productId)) && end_clamps_choices === MidEndClampChoice.NXT_HORIZON_COMBO_CLAMP  ? ADD_NXT_CLAMPS : ADD_CLAMPS],
        nonReduxExtraAction: action.payload.skipBomSave ? null : () => saveBom(true),
        onErrorMessage: 'Cannot fetch rails',
        onErrorAction: () => nprogress.done(),
        link: hitApi('get', getRailsUrl(action.payload.projectId, action.payload.rails, action.payload.useStandardClamps, action.payload.selectOnlySMHDRails)),
      });
    });
}

export function selectRailTypeFromBom(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable <any>{
  return action$.ofType(RailsSelectorActionTypes.SELECT_RAILS_FROM_API)
  .filter(() => {
    const { projectConfiguration: { productId } } = store.getState();
    return isULA(productId);
  }).switchMap(()=> {
    const { bom: { extraParameters, isClampsChanged }, projectConfiguration: { projectEnvConfig: { clamp_type } } } = store.getState();
    if(extraParameters.select_only_smhd_rails) {
      store.dispatch(SELECT_ONLY_SMHD_RAILS());
    } else {
      store.dispatch(UNSELECT_ONLY_SMHD_RAILS_OPTION());
    }
    if(isClampsChanged){ // If user selected clamp fails, warning message will be displayed in the bom page
      let message = '';
      if(clamp_type === '02') {
        message = "Note: Clamps option has been switched to top clamp as bottom clamp couldn't counter the higher load conditions.";
      } else {
        message = "Note: Clamps option has been switched to bottom clamp as top clamp couldn't counter the higher load conditions.";
      }
      showErrorAlert(message);
    }
    return Observable.empty();
  });
}


