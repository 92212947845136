import { ResetPasswordActionTypes } from 'actionsConstants';

export function RESET_PASSWORD() {
  return {
    type: ResetPasswordActionTypes.RESET_PASSWORD,
  };
}

export function RESET_PASSWORD_SUCCESS() {
  return {
    type: ResetPasswordActionTypes.RESET_PASSWORD_SUCCESS,
  };
}

export function RESET_PASSWORD_ERROR() {
  return {
    type: ResetPasswordActionTypes.RESET_PASSWORD_ERROR,
  };
}
