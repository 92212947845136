import { state } from '__common/store';
import { checkCollision } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { isGroundProduct } from '__common/constants/products';
import { _isAscenderFamily } from './utils/snapToGridHelper';

export function detectCursorCollisionWithPanels(cursor: PIXI.Graphics) {
  const { settings: { canvasCenter, panelWidth, panelHeight }, projectConfiguration: { productId },  background: {moveArrayMode}, } = state();
  let x: number;
  let y: number;
  if (isGroundProduct(productId) || moveArrayMode || _isAscenderFamily()) {
    // GFT and ULA cursor anchored at mid-bottom(+panelWidth / 2, +panelHeight) of the 1st panel of the Cursor Table
    // Get the Top Left Corner Coordinates of the Cursor Table Rectangle/Polygon
    x = cursor.x - canvasCenter.x - panelWidth / 2;
    y = cursor.y - canvasCenter.y - panelHeight;
  } else {
    x = cursor.x - canvasCenter.x - cursor.width / 2;
    y = cursor.y - canvasCenter.y - cursor.height / 2;
  }
  const area = getMinMax(x, y, cursor.width, cursor.height);
  const collision = checkCollision(area);
  return collision;
}


function getMinMax(x: number, y: number, width: number, height: number): rbush.BBox {
  const offset = 0.1;
  return {
    minX: x + offset,
    minY: y + offset,
    maxX: x + width - offset,
    maxY: y + height - offset,
  };
}

