import { isResidentialProduct, isRMIFIProduct } from '__common/constants/products';
import { isASCE716or722 } from '__common/constants/buildingCodes';

export function getLatestSchemaVersion(productId: number, buildingCode: number): number {
  if (isRMIFIProduct(productId)) return 3;
  if (isResidentialProduct(productId) && isASCE716or722(buildingCode)) {
    return 2;
  }

  return 1;
}
