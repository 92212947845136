import Leaflet from 'leaflet';
import PanelsEditor from '__editor/panelsEditor/panelsEditor';
import React from 'react';
import { ACTIVE_PANELS_EDITOR, LOAD_PROJECT_CONFIGURATION, SET_PRODUCT_TITLE, SET_ROOF_ZONES_AUTOFILL } from 'actions';
import { connect } from 'react-redux';
import { getDefaultProjectConfiguration } from 'projectDesign/components/projectConfiguration/utils/defaultProps';
import { getProductTitle } from '__common/constants/products_titles';
import { loadProject, loadProjectData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/loadProject';
import { panelsEditorEnabled } from '__editor/panelsEditor/panelsEditorHelper';
import { products, isRMFamily, isGFT } from '__common/constants/products';
import { HIDE_CURSOR, SET_ROW_SPACING, SET_TABLE_ROW_SPACING } from '__editor/panelsEditor/actions';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { getProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import { state } from '__common/store';

type Props = {
  dispatch: Function,
  match: any,
  dataToLoad: any,
  roofs: { [roofId: string]: drawingManagerRoof },
  cords: any,
};

class ScreenshotContainer extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    const { dispatch, match: { params: { productId, projectId } } } = this.props;
    loadProjectData(productId, projectId);
    dispatch(SET_PRODUCT_TITLE(getProductTitle(products[productId])));
    const projectConfigurationData = getDefaultProjectConfiguration(products[productId]);
    dispatch(LOAD_PROJECT_CONFIGURATION(projectConfigurationData));
    dispatch(HIDE_CURSOR());
  }

  componentDidUpdate() {
    const { dispatch, dataToLoad, roofs, match: { params: { arrayId, productId } }, cords } = this.props;
    const { projectConfiguration }= state();
    if (Object.keys(dataToLoad).length) {
      loadProject(dataToLoad);
      const mapType = dataToLoad.project_configuration.map_type;
      
      if (mapType === 3) {
        dispatch(SET_ROOF_ZONES_AUTOFILL(false));
      } else if (isRMFamily(products[productId])) {
        dispatch(SET_ROOF_ZONES_AUTOFILL(false));
      }
    }

    if (roofs && Object.keys(roofs).length && !cords) {
      const roofCoords = roofs[arrayId].coords;
      const polygon = Leaflet.polygon(roofCoords);
      const projectVersion = projectConfiguration?.projectVersion;
      const defaultRowSpacing = inchesToMeters(getProductSettings(products[productId], projectVersion).rowSpacing);
      const rowSpacing = roofs[arrayId].rowSpacing || defaultRowSpacing;
      if (isGFT(productId)) {
        const defaultTableRowSpacing = inchesToMeters(getProductSettings(products[productId]).tableRowSpacing);
        const tableRowSpacing = roofs[arrayId].tableRowSpacing || defaultTableRowSpacing;
        dispatch(SET_TABLE_ROW_SPACING(tableRowSpacing));
      }
      dispatch(SET_ROW_SPACING(rowSpacing));
      dispatch(ACTIVE_PANELS_EDITOR(polygon.getBounds().getCenter(), roofCoords, arrayId));
    }
  }

  render() {
    const { match: { params: { productId } } } = this.props;

    if (panelsEditorEnabled()) {
      return (
        <div>
          <PanelsEditor productId={productId} screenshots={true} />
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state: appState) {
  return {
    cords: state.background.cords,
    roofs: state.drawingManager.roofs,
    modelData: state.moduleSelector.modelData,
    dataToLoad: state.saveLoadProject.dataToLoad,
    bgLoaded: state.background.bgLoaded,
  };
}

export default connect(mapStateToProps)(ScreenshotContainer);
