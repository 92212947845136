import _ from 'lodash';
import { getRoofEdgesPointsInPx } from '../background/background';
import { edgePoint } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { state, dispatch } from '__common/store';
import { TOGGLE_OBSTRUCTIONS_ZONE, TOGGLE_WIND_ZONE } from './roofZonesActions';

export const getRoofEdgesPxPoints = (roofEdges: google.maps.LatLngLiteral[], cords: cordPoint, zoom: number) => {
  cords = (!cords? roofEdges[0]: cords);
  return getRoofEdgesPointsInPx(roofEdges, cords, zoom);
};

export const getRoofZonesEdges = (roofEdges: { x: number, y: number }[]): [edgePoint, edgePoint][] => {
  return roofEdges.reduce<[edgePoint, edgePoint][]>(
    (acc, coord, index) => {
      if (roofEdges[index + 1]) {
        acc.push([coord, roofEdges[index + 1]]);
      } else {
        acc.push([coord, roofEdges[0]]);
      }

      return acc;
    },                      
    [],
  );
};

export const getZoneDirection = (edge: edgePoint[]) => {
  const edgeWithZone = _.find(edge, (coord) => coord.zone && coord.zone.length > 0);
  if (edgeWithZone && edgeWithZone.zone) {
    return edgeWithZone.zone;
  }
};

export const enableObstructionsZoneView = () => {
  const { roofZones: { obstructionsZone } } = state();

  if (!obstructionsZone) {
    dispatch(TOGGLE_OBSTRUCTIONS_ZONE());
  }
};

export const disableObstructionZoneView = () => {
  const { roofZones: { obstructionsZone } } = state();

  if (obstructionsZone) {
    dispatch(TOGGLE_OBSTRUCTIONS_ZONE());
  }
};

export const disableWindZoneView = () => {
  const { roofZones: { windZone } } = state();

  if (windZone) {
    dispatch(TOGGLE_WIND_ZONE());
  }
};