import * as React from 'react';
import { connect } from 'react-redux';
import { fetchAuroraDesignConfigInitDataRequest } from 'auroraImport/auroraConfigActions';
/* tslint:disable */ 

type Props = {
  dispatch: Function,
  isAuroraImport: boolean,
  msg: string,
  match: any;
};

class Aurora extends React.Component<Props> { 

  UNSAFE_componentWillMount() {
    const { dispatch, match } = this.props;      
    const auroraTenantId = match.params.aurora_tenant_id
    const auroraProjectId = match.params.aurora_project_id
    const auroraDesignId = match.params.aurora_design_id
    const auroraUserId = match.params.aurora_user_id
    const auroraSessionToken = match.params.aurora_session_token

    dispatch(fetchAuroraDesignConfigInitDataRequest(
      auroraTenantId,
      auroraProjectId,
      auroraDesignId,
      auroraUserId,
      auroraSessionToken
    ))

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    
  }

  render() {
    const { msg } = this.props;
    let containFailure = false;
    if (msg && msg.length) {
      containFailure = msg.toLocaleLowerCase().includes('failure') || msg.toLocaleLowerCase().includes('error');
    }
   
  
    return (
    <div className={`loader-svg-container ${containFailure ? 'stop-animation' : ''}`}>
        <svg className="loader-svg-machine" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 125 390 395">
          <defs/>
  
          <g fill="freeze" >
            <path fill="freeze" x="-136,996" y="-136,996" className="loader-svg-medium-shadow" d="M402 400v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L352 323c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L402 400zM265 463c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C338 430 305 463 265 463z"
            />
          </g>
          <g fill="freeze" >
            <path  fill="freeze" x="-100,136" y="-100,136" className="loader-svg-small-shadow" d="M210 246v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H100l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L10 225v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L210 246zM110 272c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S131 272 110 272z"
            />
          </g>
          <g fill="freeze" >
            <path  fill="freeze" x="-100,136" y="-100,136" className="loader-svg-small" d="M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z"
            />
          </g>
          <g fill="freeze" >
            <path  fill="freeze" x="-136,996" y="-136,996" className="loader-svg-medium" d="M392 390v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L342 313c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L392 390zM255 453c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C328 420 295 453 255 453z"/>
          </g>
        </svg>
        <div className="loading-text">{msg}</div>
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    isAuroraImport: appState.app.isAuroraImport,
    msg: appState.app.loaderMsg,
  };
}

export default connect(mapStateToProps)(Aurora);