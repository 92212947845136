export const apiField = 'material_thickness';

export enum MATERIAL_THICKNESS {
  GA_26 = 1,
  GA_24 = 2,
  GA_22 = 3,
  GA_20 = 4,
  UNKNOWN = 5,
  MM_047 = 6,
  MM_050 = 7,
}


export const configStandingSeam = {
  type: 'Select',
  apiField,
  label: 'materialThickness',
  data: [
    {
      value: 1,
      name: '26 ga',
    },
    {
      value: 2,
      name: '24 ga',
    },
    {
      value: 3,
      name: '22 ga',
    },
    {
      value: 4,
      name: 'Aluminium(20 ga)',
    },
    {
      value: 5,
      name: 'Unknown',
    },
  ],
};
export const configRPanel = {
  type: 'Select',
  apiField,
  label: 'materialThickness',
  data: [
    {
      value: 1,
      name: '26 ga',
    },
    {
      value: 2,
      name: '24 ga',
    },
    {
      value: 3,
      name: '22 ga',
    },
    {
      value: 5,
      name: 'Unknown',
    },
    {
      value: 6,
      name: '0.47 mm',
    },
    {
      value: 7,
      name: '0.50 mm',
    },
  ],
};

export const configCorrugated = {
  type: 'Select',
  apiField,
  label: 'materialThickness',
  data: [
    {
      value: 1,
      name: '26 ga',
    },
    {
      value: 2,
      name: '24 ga',
    },
    {
      value: 3,
      name: '22 ga',
    },
    {
      value: 4,
      name: 'Aluminium(20 ga)',
    },
    {
      value: 5,
      name: 'Unknown',
    },
  ],
};
