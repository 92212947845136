
import { getYesOrNo } from "../projectConfiguration";
export const apiField = 'allow_secondary_ballast_tray';

const config = {
  label: 'allowSecondaryBallastTray',
  type: 'Select',
  apiField,
  data: getYesOrNo(),
};

export default config;
