import sortBy from 'lodash/sortBy';
import { dispatch, state } from '__common/store';
import { getRoofOver } from '../../roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import {
  CLEAR_ROOF_LEAD_EDGE,
  CLEAR_TILTED_ROOF,
  SELECT_ROOF_LEAD_EDGE,
  } from 'actions';
import { REMOVE_ROOF_OBSTRUCTIONS } from '__editor/panelsEditor/actions';

export const SOUTH_EDGE_CHANGE_WARNING = 'Re selecting south edge will remove all panels and obstructions in this installation. Are you sure?';

export function clickedRoofEdgeData(cords: { lat: number, lng: number }, checkDistanceFn: (clickCord: { lat: number, lng: number }, path: /* google.maps.LatLng[] */ any) => number): any /* { path: google.maps.LatLng[], roofId: number } */ {
  const { roofs } = state().drawingManager;
  const data: any = Object.keys(roofs).map((roofId) => {
    let dist;
    const tempArray = [];
    for (let index = 0, len = roofs[roofId].coords.length; index < len; index++) {
      const coords = roofs[roofId].coords;
      let secondIndex;
      if (index + 1 === len) {
        secondIndex = 0;
      } else {
        secondIndex = index + 1;
      }
      const roofEdgePath = [coords[index], coords[secondIndex]];
      if (checkDistanceFn(cords, roofEdgePath)) {
        dist = { edgeNumber: index, roofId: parseInt(roofId, 10), dist: checkDistanceFn(cords, roofEdgePath) };
      }

      tempArray.push(dist);
    }

    return tempArray;
  });
  const flattened = data.reduce((acc, arr) => acc.concat(arr), []);
  const filtered = flattened.filter(loc => Number(loc.roofId) === Number(getRoofOver()));
  return sortBy(filtered, ['dist'])[0];
}

export function reselectLeadRoofEdge(selectedRoofData: { edgeNumber: number, roofId: number }) {
  const { projectConfiguration: { productId, projectEnvConfig: { building_code, building_type } } } = state();
  dispatch(REMOVE_ROOF_OBSTRUCTIONS(selectedRoofData.roofId));
  dispatch(CLEAR_ROOF_LEAD_EDGE(selectedRoofData.roofId));
  dispatch(CLEAR_TILTED_ROOF(productId, building_code, building_type));
  dispatch(SELECT_ROOF_LEAD_EDGE(selectedRoofData.edgeNumber, selectedRoofData.roofId));
}

export function getLeadEdgeCoords(roofId: number) {
  const { leadEdgeRoofSelector: { leadEdges }, drawingManager: { roofs } } = state();
  const leadEdgeNumber = leadEdges[roofId];
  const roofCoords = roofs[roofId].coords;

  if (roofCoords.length === leadEdgeNumber) {
    return [roofCoords[leadEdgeNumber], roofCoords[0]];
  } 

  return [roofCoords[leadEdgeNumber], roofCoords[leadEdgeNumber + 1]];
}
