export const apiField = 'longest_row_length';

export const makeConfig = (min: number = 1, max:number = 9999, label: string = 'longest_row_length') => {
  return {
    type: 'Number',
    apiField: apiField,
    label,
    missing: undefined,
    tooltip: () => 'Longest Row Length in array',
    min,
    max,
  }
};

const config = makeConfig();

export default config;
