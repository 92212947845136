import { 
  getNorthExposureExtents, 
  getSouthExposureExtents, 
  getWestExposureExtents, 
  getEastExposureExtents, 
  getPanelsInExposureExtentsArea,
} from './utils/exposureExtents';
import { 
  checkNorthSouthInterval, 
  checkWestEastInterval, 
  get_interval_in_E_and_W_box, 
  get_interval_in_N_and_S_box,
  intervalItem,
  intervalContains,
} from './utils/intervals';
import { checkEdgeCollisionWithExposureZone } from '__common/components/exposure/exposureCheckInsideOfEditor';
import { nearestPanelCenterPoint } from '__editor/panelsEditor/components/panels/utils/panelsCenterStore';
import { DEBUG } from 'debug';
import { isRM5, isRM10, isRM10Evolution, isEcoFoot2Plus, isRmGridflex10 } from '__common/constants/products';
import { isBlankMap } from '__common/constants/map';
import { getSearchDistance } from '__common/components/exposure/utils/exposureExtentsDistance';
import { state } from '__common/store';

const SEARCH_DISTANCE = 100;

export type direction = 'north' | 'south' | 'east' | 'west';

// exposure check algorithm:
// 1. Take the panel you want to check exposure for.
// 2. Create 4 exposure extents areas: north, south, east, west.
// 3. For each area:
//  3.1 find panels that are within the area
//  3.2 for each panel found:
//    3.2.1 create interval of the found panel. If you look for E/W area, create N-S interval,
//          if N/S area, create E-W interval.
//  3.3 create a union of all intervals of the found panels. 
//  3.4 Check if the panel you want to check exposure for
//      is fully within this interval. If not: panel is exposed.
// 4. If panel is covered from each side is NON exposed.

export const checkPanelExposure = (
  panel: panelInState,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  metersPerPixel: number,
  columnSpacing: number,
  rowSpacing: number,
  bgOffset: pixelPoint,
  roofPitch: string,
  productId: number,
  mapType: string,
  exposureRTree: rbush.RBush<rbush.BBox>,
) => {
  const panelCenter = { x: panel.x, y: panel.y };

  const panelWidthPx = panel.width;
  const panelHeightPx = panel.height;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const xSpacingPx = columnSpacingPx;
  const ySpacingPx = rowSpacingPx;

  const {background: {roofEdgesPixiCords}, projectConfiguration: {projectEnvConfig: {tilt}}} = state();
  // allowedDistance - pixels
  const allowedDistance = getSearchDistance(productId, metersPerPixel);

  const { nortCovered, northPanels } = checkNorth(
    panelCenter,
    panelWidthPx,
    panelHeightPx,
    xSpacingPx,
    allowedDistance,
    panel.id,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    true,
    roofPitch,
    metersPerPixel,
    productId,
    mapType,
    exposureRTree,
    roofEdgesPixiCords,
    tilt,
  );
 
  const { southCovered, southPanels } = checkSouth(
    panelCenter,
    panelWidthPx,
    panelHeightPx,
    xSpacingPx,
    allowedDistance,
    panel.id,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    true,
    roofPitch,
    metersPerPixel,
    productId,
    mapType,
    exposureRTree,
    roofEdgesPixiCords,
    tilt,
  );

  const { eastCovered, eastPanels, superStatusWestPanels } = checkEast(
    panelCenter,
    panelWidthPx,
    panelHeightPx,
    ySpacingPx,
    allowedDistance,
    panel.id,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    true,
    roofPitch,
    metersPerPixel,
    productId,
    mapType,
    exposureRTree,
    roofEdgesPixiCords, 
    tilt,
  );

  const { westCovered, westPanels, superStatusEastPanels } = checkWest(
    panelCenter,
    panelWidthPx,
    panelHeightPx,
    ySpacingPx,
    allowedDistance,
    panel.id,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    true,
    roofPitch,
    metersPerPixel,
    productId,
    mapType,
    exposureRTree,
    roofEdgesPixiCords, 
    tilt,
  );

  const directions: { [key: number]: direction } = {
    0: 'north',
    1: 'south',
    2: 'west',
    3: 'east',
  };

  let superPanelsIds: {[key: number] : { status: boolean, direction: direction }} = {};

  if (isRM5(productId) || isRM10(productId) || isRM10Evolution(productId) || isEcoFoot2Plus(productId) || isRmGridflex10(productId)) {
    superPanelsIds = [superStatusWestPanels, superStatusEastPanels]
    .reduce<{[key: number] : { status: boolean, direction: direction }}>((acc, panels, index) => {
      Object.keys(panels).map(panelId => {
        if (!acc[panelId]) {
          // TODO - these indexes will point to north or south although they are interating over
          // west panels and east panels. I'm not sure if this is correct.
          acc[panelId] = { status: panels[panelId], direction: directions[index] };
        }

        if (acc[panelId] && !panels[panelId]) {
          return acc[panelId] = { status: false, direction: directions[index] };
        }
      });
      return acc;
    },                                                                   {});
  }

  if (DEBUG.showExposureCoverageDebug) {
    console.log({
      northConvered: nortCovered,
      southCovered,
      eastCovered,
      westCovered,
      id: panel.id,
      superPanelsIds,
    });
  }

  const panelsIdsToRecheck = {
    /** ids of the nearest panels */
    recheck: Array.from(new Set([...northPanels, ...southPanels, ...eastPanels, ...westPanels]))
      .reduce<number[]>((acc, panel) => {
        const nearestPanels = Array.from(
          new Set(
            nearestPanelCenterPoint({ x: panel.minX, y: panel.minY }, 100, SEARCH_DISTANCE)
            .map(panel => panel[0].id),
          ),
        );
        return [...acc, ...nearestPanels];
      },                []),
    forceExposureChanged: superPanelsIds,
  };

  if (nortCovered && southCovered && eastCovered && westCovered) {
    return { isExposed: false, nearestPanels: panelsIdsToRecheck, superPanelsIds };
  }

  return { isExposed: true, nearestPanels: panelsIdsToRecheck, superPanelsIds };
};

const checkNorth = (
  panelCenter: { x: number, y: number },
  panelWidthPx: number,
  panelHeightPx: number,
  xSpacingPx: number,
  allowedDistance: number,
  currentPanelId: number,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  bgOffset: pixelPoint,
  insideOfPolygon: boolean,
  roofPitch: string,
  metersPerPixel: number,
  productId: number,
  mapType: string,
  exposureRTree: rbush.RBush<rbush.BBox>,
  roofEdgesPixiCords,
  tilt: number,
) => {
  const panelMinX = panelCenter.x - (panelWidthPx / 2);
  const panelMaxX = panelCenter.x + (panelWidthPx / 2);
  const panelMinY = panelCenter.y - (panelHeightPx / 2);

  const line = [{ x: panelMinX, y: panelMinY }, { x: panelMaxX, y: panelMinY }];
  const edgeCoveredByWall = isBlankMap(mapType) ? false : checkEdgeCollisionWithExposureZone(
    line,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    insideOfPolygon,
    roofPitch,
    metersPerPixel,
    'N',
    roofEdgesPixiCords,
    productId,
    mapType,
    tilt,
  );

  const panelClosedInterval = false;

  const boxN = getNorthExposureExtents(panelCenter, panelWidthPx, panelHeightPx, xSpacingPx, allowedDistance);

  const northPanels = getPanelsInExposureExtentsArea(boxN, currentPanelId, exposureRTree);
  const panelInterval = get_interval_in_N_and_S_box(xSpacingPx, panelMinX, panelMaxX, panelClosedInterval);

  const nortCovered = checkNorthSouthInterval(northPanels, xSpacingPx, currentPanelId, panelInterval);

  const boxS = getSouthExposureExtents(panelCenter, panelWidthPx, panelHeightPx, xSpacingPx, allowedDistance);

  const southPanels = getPanelsInExposureExtentsArea(boxS, currentPanelId, exposureRTree);

  const superStatusSouthPanels = southPanels.reduce((acc, bbox) => {
    acc[bbox.id] = !nortCovered && !edgeCoveredByWall;
    return acc;
  },                                                {});

  if (edgeCoveredByWall) {
    return { nortCovered: edgeCoveredByWall, northPanels, superStatusSouthPanels };
  }

  return { nortCovered, northPanels, superStatusSouthPanels };
};

const checkSouth = (
  panelCenter: { x: number, y: number },
  panelWidthPx: number,
  panelHeightPx: number,
  xSpacingPx: number,
  allowedDistance: number,
  currentPanelId: number,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  bgOffset: pixelPoint,
  insideOfPolygon: boolean,
  roofPitch: string,
  metersPerPixel: number,
  productId: number,
  mapType: string,
  exposureRTree: rbush.RBush<rbush.BBox>,
  roofEdgesPixiCords,
  tilt: number,
) => {
  const panelMinX = panelCenter.x - (panelWidthPx / 2);
  const panelMaxX = panelCenter.x + (panelWidthPx / 2);
  const panelMaxY = panelCenter.y + (panelHeightPx / 2);

  const panelClosedInterval = false;

  const line = [{ x: panelMinX, y: panelMaxY }, { x: panelMaxX, y: panelMaxY }];
  const edgeCoveredByWall = isBlankMap(mapType) ? false : checkEdgeCollisionWithExposureZone(
    line,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    insideOfPolygon,
    roofPitch,
    metersPerPixel,
    'S',
    roofEdgesPixiCords, 
    productId,
    mapType,
    tilt,
  );

  const boxS = getSouthExposureExtents(panelCenter, panelWidthPx, panelHeightPx, xSpacingPx, allowedDistance);
  const southPanels = getPanelsInExposureExtentsArea(boxS, currentPanelId, exposureRTree);    
  const panelInterval = get_interval_in_N_and_S_box(xSpacingPx, panelMinX, panelMaxX, panelClosedInterval);
  const southCovered = checkNorthSouthInterval(southPanels, xSpacingPx, currentPanelId, panelInterval);

  const boxN = getNorthExposureExtents(panelCenter, panelWidthPx, panelHeightPx, xSpacingPx, allowedDistance);

  const northPanels = getPanelsInExposureExtentsArea(boxN, currentPanelId, exposureRTree);

  const superStatusNothPanels = northPanels.reduce((acc, bbox) => {
    acc[bbox.id] = !southCovered && !edgeCoveredByWall;
    return acc;
  },                                               {});

  if (edgeCoveredByWall) {
    return { southCovered: edgeCoveredByWall, southPanels, superStatusNothPanels };
  }

  return { southCovered, southPanels, superStatusNothPanels };
};

const checkWest = (
  panelCenter: { x: number, y: number },
  panelWidthPx: number,
  panelHeightPx: number,
  ySpacingPx: number,
  allowedDistance: number,
  currentPanelId: number,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  bgOffset: pixelPoint,
  insideOfPolygon: boolean,
  roofPitch: string,
  metersPerPixel: number,
  productId: number,
  mapType: string,
  exposureRTree: rbush.RBush<rbush.BBox>,
  roofEdgesPixiCords,
  tilt: number,
) => {
  const panelMinX = panelCenter.x - (panelWidthPx / 2);
  const panelMinY = panelCenter.y - (panelHeightPx / 2);
  const panelMaxY = panelCenter.y + (panelHeightPx / 2);
  const panelClosedInterval = false;

  const line = [{ x: panelMinX, y: panelMinY }, { x: panelMinX, y: panelMaxY }];
  const edgeCoveredByWall = isBlankMap(mapType) ? false : checkEdgeCollisionWithExposureZone(
    line,
    roofEdges,
    roofId,
    cords,
    zoom,
    bgRotationDegrees,
    bgOffset,
    insideOfPolygon,
    roofPitch,
    metersPerPixel,
    'W',
    roofEdgesPixiCords, 
    productId,
    mapType,
    tilt,
  );
  const boxW = getWestExposureExtents(panelCenter, panelWidthPx, panelHeightPx, ySpacingPx, allowedDistance);
  const westPanels = takeOnlyAdjacentPanels(getPanelsInExposureExtentsArea(boxW, currentPanelId, exposureRTree), 'west', ySpacingPx);
  const panelInterval = get_interval_in_E_and_W_box(ySpacingPx, panelMinY, panelMaxY, panelClosedInterval);
  const westCovered = checkWestEastInterval(westPanels, ySpacingPx, currentPanelId, panelInterval);
  const boxE = getEastExposureExtents(panelCenter, panelWidthPx, panelHeightPx, ySpacingPx, allowedDistance);

  const eastPanels = takeOnlyAdjacentPanels(getPanelsInExposureExtentsArea(boxE, currentPanelId, exposureRTree), 'east', ySpacingPx);

  const superStatusEastPanels = eastPanels.reduce((acc, bbox) => {
    acc[bbox.id] = !westCovered && !edgeCoveredByWall;
    return acc;
  },                                              {});

  if (edgeCoveredByWall) {
    return { westCovered: edgeCoveredByWall, westPanels, superStatusEastPanels };
  }

  return { westCovered, westPanels, superStatusEastPanels };
};

const checkEast = (
  panelCenter: { x: number, y: number },
  panelWidthPx: number,
  panelHeightPx: number,
  ySpacingPx: number,
  allowedDistance: number,
  currentPanelId: number,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  bgOffset: pixelPoint,
  insideOfPolygon: boolean,
  roofPitch: string,
  metersPerPixel: number,
  productId: number,
  mapType: string,
  exposureRTree: rbush.RBush<rbush.BBox>,
  roofEdgesPixiCords,
  tilt: number,
) => {
  const panelMinY = panelCenter.y - (panelHeightPx / 2);
  const panelMaxY = panelCenter.y + (panelHeightPx / 2);
  const panelMaxX = panelCenter.x + (panelWidthPx / 2);

  const panelClosedInterval = false;

  const line = [{ x: panelMaxX, y: panelMinY }, { x: panelMaxX, y: panelMaxY }];
  const edgeCoveredByWall = isBlankMap(mapType) ? false : checkEdgeCollisionWithExposureZone(
    line, 
    roofEdges, 
    roofId,
    cords, 
    zoom, 
    bgRotationDegrees, 
    bgOffset,
    insideOfPolygon, 
    roofPitch,
    metersPerPixel,
    'E',
    roofEdgesPixiCords, 
    productId,
    mapType,
    tilt,
  );

  const boxE = getEastExposureExtents(panelCenter, panelWidthPx, panelHeightPx, ySpacingPx, allowedDistance);
  const eastPanels = takeOnlyAdjacentPanels(getPanelsInExposureExtentsArea(boxE, currentPanelId, exposureRTree), 'east', ySpacingPx);
  const panelInterval = get_interval_in_E_and_W_box(ySpacingPx, panelMinY, panelMaxY, panelClosedInterval);
  const eastCovered = checkWestEastInterval(eastPanels, ySpacingPx, currentPanelId, panelInterval);
  
  const boxW = getWestExposureExtents(panelCenter, panelWidthPx, panelHeightPx, ySpacingPx, allowedDistance);
  
  const westPanels = takeOnlyAdjacentPanels(getPanelsInExposureExtentsArea(boxW, currentPanelId, exposureRTree), 'west', ySpacingPx);

  const superStatusWestPanels = westPanels.reduce((acc, bbox) => {
    acc[bbox.id] = !eastCovered && !edgeCoveredByWall;
    return acc;
  },                                              {});

  if (edgeCoveredByWall) {
    return { eastCovered: edgeCoveredByWall, eastPanels, superStatusWestPanels };
  }

  return { eastCovered, eastPanels, superStatusWestPanels };
};

function takeOnlyAdjacentPanels(panels: rbush.BBox[], direction: direction, spacingY: number): rbush.BBox[] {
  // Panel is considered exposed if any edge is exposed. 
  // Edge is exposed if entire exposure zone edge does not extend beyond any roof edge
  // and entire exposure extents edge does not extend beyond an adjacent panel edge. 
  // One adjacent panel. If panels are alligned we should take only first (adjacent) panel. 
  //
  // ⋮ - exposure extent
  //   ┌-------┐┌-------┐┌-⋮-----┐
  //   |   1   ||   2   || ⋮ 3   |
  //   └-------┘└-------┘└-⋮-----┘
  // When we check if panel 1 is covered on east, panels 2 and 3 are within a range of exposure extent.
  // But we want to take only adjacent panel. 
  // So everything that is behind (looking from perspective of "1") "2" should be ignored.
  // However when panels are shifted, we should consider all of them because together they may completely cover the panel.
  // e.g.:
  // ⋮ - exposure extent
  //            ┌-------┐
  //   ┌-------┐|⋅⋅⋅2⋅⋅⋅|⋅⋅⋮
  //   |   1   |└-------┘  ⋮
  //   └-------┘⋅⋅┌-------┐⋮
  //              |   3   |
  //              └-------┘
  // In this case we consider both 2 and 3 because they 2 isn't behind 3 and otherwise.
  // Therefore I introduce a concept of "dead zone" to ignore the panels that are behind adjacent panel.
  //
  // ⋮ - exposure extent
  // xx - dead zone
  //   ┌-------┐┌-------┐xx⋮xxxxx┐
  //   |   1   ||   2   |xx⋮ 3 xx|
  //   └-------┘└-------┘xx⋮xxxxx┘
  let panelsSorted: rbush.BBox[];
  const idsToInclude: number[] = [];
  const deadZoneIntervals: [intervalItem, intervalItem][] = []; 
  switch (direction) {
    case 'west':
      panelsSorted = panels.sort(byXDescending);
      break;
    case 'east':
      panelsSorted = panels.sort(byXAscending);
      break;
  }

  panelsSorted.forEach((panel) => {
    const panelInterval = get_interval_in_E_and_W_box(spacingY, panel.minY, panel.maxY, true);
    const isInside = deadZoneIntervals.some(deadZone => intervalContains(deadZone, panelInterval.interval));
    if (!isInside) {
      deadZoneIntervals.push(panelInterval.interval);
      idsToInclude.push(panel.id);
    }
  });
  
  return panelsSorted.filter((panel) => idsToInclude.includes(panel.id));
}

function byXAscending(panel_1: rbush.BBox, panel_2: rbush.BBox) {
  return panel_1.minX - panel_2.minX;
}

function byXDescending(panel_1: rbush.BBox, panel_2: rbush.BBox) {
  return panel_2.minX - panel_1.minX;
}
