import { DeadLoadFactorTooltip } from '../fieldsTooltips/deadLoadFactorTooltip';
import { apiField } from './types/deadLoadFactor';

export const deadLoadFactorInputField= (limitedOption = false, dead_load = 0) => {
  let field = {
    type: 'Number',
    label: 'deadLoadFactorModification',
    apiField,
    tooltip: DeadLoadFactorTooltip,
    min : 0.001,
    max : limitedOption ? 0.6 : 1,
    step : 0.1,
    missing: undefined,
  }
  field.missing = !dead_load
  return field
};

