import { addGoogleLeadEdgeObject, clearGoogleLeadEdgeObject } from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/leadEdgeRoofSelector';
import { emptyRoofColor, getRoofColor } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import { getLeadEdge } from '../../../../panelsEditor/components/leadEdge/leadEdge';
import { getMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { reselectLeadRoofEdge } from '__editor/components/roofsSelector/components/roofsSelectorLeadEdge/utils/roofsSelectorSelectEdge';
import turf from 'turf';
import pointToLineDistance from '@turf/point-to-line-distance';
import { setGoogleMapAzimuthInfo } from '../../drawingManager/drawingManager';
const polyOptions = {
  strokeWeight: 8,
  zIndex: 9999,
};

export function getPolyRoofEdge(path: any, roofColor?: string) {
  const strokeColor = roofColor && roofColor.length ? roofColor :  emptyRoofColor;
  return new google.maps.Polyline({ ...polyOptions, path, strokeColor });
}

export function selectRoofLeadEdge(selectedRoofData: { edgeNumber: number, roofId: number }): void {
  removeCurrentSelection(selectedRoofData.roofId);
  reselectLeadRoofEdge(selectedRoofData);
  const roofColor = getRoofColor(selectedRoofData.roofId);
  const leadEdgePath = getLeadEdge(selectedRoofData.roofId);
  const roofEdge = getPolyRoofEdge(leadEdgePath, roofColor);
  addGoogleLeadEdgeObject(selectedRoofData.roofId, roofEdge);
  roofEdge.setMap(getMap());
  setGoogleMapAzimuthInfo(selectedRoofData.roofId, leadEdgePath);
}

export function isClickOnRoofEdge(clickCord: { lat: number, lng: number }, path: { lat: number, lng: number }[]): number {
  const clickDistanceTolerance = 20;
  const latLngs = new google.maps.LatLng(clickCord.lat, clickCord.lng);
  return isLocationOnEdge(latLngs, path, clickDistanceTolerance);
}

function removeCurrentSelection(roofId: number) {
  clearGoogleLeadEdgeObject(roofId);
}

function isLocationOnEdge(location,path,toleranceInMeters) {
  let dist = toleranceInMeters;
  const point = turf.point([location.lat(), location.lng()]);
  const line = turf.lineString([[path[0].lat, path[0].lng], [path[1].lat, path[1].lng]]);
  const distance = pointToLineDistance(point, line, { units: 'meters' });
  
  if (distance <= toleranceInMeters) {
    if (distance < dist) {
      dist = distance;
    }
  }
  return dist;
}
