
export const apiField = 'choice_of_fastend';

function makeConfig(data) {
  return{
    label: apiField,
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data
}};


const choiceOfFastendMap = {
  "2000" : {value: 20, name: 'U-2000',},
  "2400" : {value: 24, name: 'U-2400',},
  "2600" : {value: 26, name: 'U-2600',},
  "2800" : {value: 28, name: 'U-2800',},
}

export default function choiceOfFastend(keys_array){ 
  const filteredList = keys_array.map(key => choiceOfFastendMap[key]);
      return makeConfig(filteredList);
    }