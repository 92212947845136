
import { hitApi, ObservableAjax } from '__common/utils/api';
import { ActionTypes } from 'actionsConstants';
import { SHOW_FAQ_WINDOW, POPULATE_FAQ_DATA } from './FaqActions';
import { ActionsObservable } from 'redux-observable';
import { AnyAction, Store } from 'redux';
import { Observable } from 'rxjs';

export function faqList(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(ActionTypes.POPULATE_FAQ_DATA)
    .switchMap(action =>
      ObservableAjax({
        onSuccess: (data) => SHOW_FAQ_WINDOW(data),
        onErrorMessage: 'Could not fetch faq data',
        link: hitApi('get', `api/v1/faqs/`),
      }));
}