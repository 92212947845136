import * as React from 'react';
import { connect } from 'react-redux';
import {  CHANGE_STRUCTURE_TYPE, } from 'actions';
import { SelectField } from 'react-md';
import autobind from 'autobind-decorator';


interface props {
  structure_type: any;
  dispatch: Function;
  changeStructuretype: (structure_type: number, structure_type_info: structureTypeInfoState) => void;
  structure_type_info: structureTypeInfoState,
}

interface state {
  structure_type: number;
  structure_type_info: structureTypeInfoState,
}
 
export function getStructuretype(val: number){
    if (val==1){
        return '1-Portrait Elevated';
    }else if (val==2){
        return '1-Portrait Non Elevated';
    }else if (val==3){
        return '2-Portrait Elevated';
    }else if (val==4){
        return '2-Portrait Non Elevated';
    }
}

export function getStructuretypeVal(name){
    if (name=='1-Portrait Elevated') {
        return 1; 
    }else if (name=='1-Portrait Non Elevated') {
        return 2; 
    }else if (name=='2-Portrait Elevated') {
        return 3; 
    }else if (name=='2-Portrait Non Elevated') {
        return 4; 
    }
}
class StructureTypeChange extends React.Component<props, state> {
  structure_types=[
   {name: '1-Portrait Elevated', value: 1},
   {name: '1-Portrait Non Elevated', value: 2},
   {name: '2-Portrait Elevated', value: 3},
   {name: '2-Portrait Non Elevated', value: 4},
  ]
  
  constructor(props: props) {
    super(props);
    this.state = {
      structure_type: props.structure_type,
      structure_type_info: props.structure_type_info,
    };
  }

  @autobind
  onStructureTypeChange(value: any,) {
    this.props.changeStructuretype(value,this.state.structure_type_info);
  }


  render() {
    const { structure_type, } = this.state;
    return (
        <>
        <SelectField
          id='structure-type'
          label='Structure type :'
          menuItems={this.structure_types}
          defaultValue={structure_type}
          onChange={this.onStructureTypeChange}
          itemLabel="name"
          itemValue="value"
          listProps={{
            fullWidth: true,
            position: SelectField.Positions.BELOW,
            listZDepth: 0,
            minBottom: 100,
          }}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {

  return {
    changeStructuretype: (structure_type: any, structure_type_info: structureTypeInfoState) => {
      dispatch(CHANGE_STRUCTURE_TYPE(structure_type));
    },
  };
};

const mapStateToProps = (state: appState) => {
  return {
    structure_type: state.projectConfiguration.projectEnvConfig.structure_type,
    structure_type_info: state.projectConfiguration.projectEnvConfig.structure_type_info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StructureTypeChange);
