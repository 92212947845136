export default () => `
  <div class="sub-header">
    <strong>Rough:</strong>
  </div>
  <div class="content">
    Suburban, urban or wooded terrain extending upwind from the building uninterrupted for at least 1 km or 20 times the height of the building,
    whichever is greater.
  </div>
  <div class="sub-header">
    <strong>Open:</strong>
  </div>
  <div class="content">
    Terrain with only scattered buildings, trees or other obstructions, open water or shorelines.
  </div>
`;
