import * as PIXI from 'pixi.js';
import { lat_lng_distance_to_feet } from '../components/background/utils/backgroundCordinatesConverter';
import { createDimensionBG, createDimensionText, getRoofPolygon } from '__editor/googleMapsRoofsSelector/models/advanceRoofSelecting';
import { state } from '__common/store';
import { isAscender } from '__common/constants/products';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

export default function roofEdgeDimensionsModel(coordsInPx: pixelPoint[], roofLatLngPoints: google.maps.LatLngLiteral[], scale = 1): PIXI.Graphics {
  const { projectConfiguration: { productId, inputUnit } } = state();
  const roofEdgeDimensions = new PIXI.Graphics();
  const polygon = getRoofPolygon(coordsInPx);
  coordsInPx.map((coord, index) => {
    const nextPointIndex = (index + 1) % coordsInPx.length;
    const edgeDimension = calculateEdgeDimensionOnPanelEditor(coord, coordsInPx[nextPointIndex], roofLatLngPoints[index], roofLatLngPoints[nextPointIndex], isAscender(productId) || isMetricUnit(inputUnit));
    const dimensionText = createDimensionText(edgeDimension, scale, polygon, '12px');
    const dimensionBg = createDimensionBG(dimensionText);
    roofEdgeDimensions.addChild(dimensionBg, dimensionText);
  })
  roofEdgeDimensions.zIndex = 99999;
  return roofEdgeDimensions;
}

function calculateEdgeDimensionOnPanelEditor(p1Pixi: pixelPoint, p2Pixi: pixelPoint, p1LatLng: google.maps.LatLngLiteral, p2LatLng: google.maps.LatLngLiteral, metricUnits: boolean): edgeDimension{
  const distanceInFt = lat_lng_distance_to_feet(p1LatLng, p2LatLng);
  const distance = metricUnits ? feetsToMeters(distanceInFt).toFixed(2) + " m" : distanceInFt.toFixed(2) + " ft";
  const angleInRadians = Math.atan2(p2Pixi.y - p1Pixi.y, p2Pixi.x - p1Pixi.x);
  const midPoint = {
    x : (p1Pixi.x + p2Pixi.x)/2,
    y : (p1Pixi.y + p2Pixi.y)/2
  };
  const edgeDimension = {
    length: distance,
    midPoint,
    rotation : angleInRadians
  }
  return edgeDimension; 
}