export const apiField = 'seismic_sd1';

interface SeismicSd1Field {
  type: string;
  label: string;
  apiField: string;
  tooltip: () => string;
  missing?: any;
  min?: number;
  max?: number;
}

const config: SeismicSd1Field = {
  type: 'Number',
  label: 'seismic_sd1',
  apiField,
  tooltip: () => "Seismic Conditions: The changing or dynamic lateral load due to earthquake activity. For arrays located in seismic areas, the design of the support structure should resist the additional horizontal load.",
  missing: undefined,
  min: 0.01,
  max: 3.1,
};

export default config;
