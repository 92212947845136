import keyMirror from 'keymirror';

export * from '__editor/panelsEditor/actionsConstants';
export * from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActionsConstants';
export * from '__common/components/moduleSelector/moduleSelectorActionsConstants';
export * from 'bom/bomActionsConstants';
export * from 'bom/components/railsSelector/railsSelectorActionsConstants';
export * from 'projectDesign/components/projectConfiguration/projectConfigurationActionsConstants';
export * from '__editor/components/roofsSelector/roofsSelectorActionsConstants';
export * from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapActionsConstants';
export * from 'engineering/engineeringActionsConstants';
export * from 'yourProjects/yourProjectsActionsConstants';
export * from 'userPreferences/userPreferencesActionsConstants';
export * from '__common/components/FetchStatics/fetchStaticsActionsConstants';
export * from 'projectDesign/components/confirmClearArraysModal/confirmClearArraysModalActionsConstants';
export * from 'disableAccount/disableAccountActionsConstants';
export * from 'changePassword/changePasswordActionsConstants';
export * from 'resetPassword/resetPasswordActionsConstants';
export * from 'forgotPassword/forgotPasswordActionsConstants';
export * from 'app/components/ReportProblem/ReportProblemActionsConstants';
export * from 'app/components/FaqComponent/FaqActionsConstant';
export * from 'app/components/InfoModal/InfoModalActionsConstants';
export * from 'engineering/components/engineeringProjectDocuments/engineeringProjectDocumentsActionsConstants';
export * from 'engineering/components/engineeringBallastMaps/engineeringBallastMapsActionsConstants';

export const ActionTypes = keyMirror({
  REDIRECT_TO_PROJECT: undefined,
  SEARCH_PROJECT: undefined,
  COPY_PROJECT: undefined,
  PROJECT_NOT_FOUND: undefined,
  AJAX_ERROR: undefined,
  CHANGE_LANGUAGE: undefined,
  WAIT_FOR_SOCKET: undefined,
  EMPTY_APP_ACTION: undefined,
  USER_GET_TOKEN: undefined,
  USER_LOGIN_REQUEST: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  USER_CLEAR_LOGIN_ERROR: undefined,
  USER_ALREADY_LOGED_IN: undefined,
  USER_REGISTRATION_FAILURE: undefined,
  USER_CLEAR_REGISTRATION_ERROR: undefined,
  REGISTRATION_REQUEST: undefined,
  FETCH_POPULAR_REPOS_REQUEST: undefined,
  FETCH_POPULAR_REPOS_SUCCESS: undefined,
  FETCH_POPULAR_REPOS_FAILURE: undefined,
  FETCH_POPULAR_REPOS_CANCEL: undefined,
  FETCH_PROJECTS_LIST_REQUEST: undefined,
  FETCH_PROJECTS_LIST_WAIT_FOR_SOCKET: undefined,
  FETCH_PROJECTS_LIST_WAIT_FOR_SOCKET_DATA: undefined,
  FETCH_PROJECTS_LIST_WAIT: undefined,
  FETCH_PROJECTS_LIST_SUCCESS: undefined,
  FETCH_PROJECTS_LIST_FAILURE: undefined,
  FETCH_PROJECTS_LIST_CANCEL: undefined,
  SET_HELIOSCOPE_PROJECT_LIST_SORTING: undefined,
  PROJECTS_LIST_SET_PROJECTS_TO_SHOW: undefined,
  PROJECTS_LIST_FILTER_PROJECTS: undefined,
  FETCH_HELIOSCOPE_DESIGNS_SUCCESS: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_REQUEST: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_WAIT_FOR_SOCKET: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_WAIT_FOR_SOCKET_DATA: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_WAIT: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_SUCCESS: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_FAILURE: undefined,
  FETCH_HELIOSCOPE_DESIGNS_LIST_CANCEL: undefined,
  CLEAR_HELIOSCOPE_DESIGNS_LIST_STATE: undefined,
  CANCEL_FETCH: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_REQUEST: undefined,
  HELIOSCOPE_DESIGN_FULL_DESIGN_CONFIG_INIT_DATA_REQUEST: undefined,
  HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_WAIT_FOR_SOCKET: undefined,
  HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_SUCCESS: undefined,
  HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_WAIT_FOR_SOCKET_DATA: undefined,
  HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_FAILURE: undefined,
  SEARCH_FOR_MODEL_IN_MODULE: undefined,
  SEARCH_FOR_MODEL: undefined,
  IMPORT_HELIOSCOPE_PROJECT: undefined,
  IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT: undefined,
  IMPORT_HELIOSCOPE_PROJECT_SUCCESS: undefined,
  CLEAR_HELIOSCOPE_DESIGN_CONFIG_INIT_DATA: undefined,
  UPDATE_HELIOSCOPE_DESIGN_CONFIG_INIT_DATA: undefined,
  MULTI_ACTIONS_DISPATCH_DONE: undefined,
  CLEAR_HELIOSCOPE_PROJECT_LIST: undefined,
  IMPORT_HELIOSCOPE_PROJECT_FAILED: undefined,
  SET_LOADER_MSG: undefined,
  CLEAR_LOADER_MSG: undefined,
  SET_APP_ERROR: undefined,
  REMOVE_APP_ERROR: undefined,
  GET_APP_REVISION_HISTORY: undefined,
  SET_APP_REVISION_HISTORY: undefined,
  GET_APP_STATUS: undefined,
  GET_APP_STATUS_SUCCESS: undefined,
  GET_APP_STATUS_FAILURE: undefined,
  GET_APP_STATUS_CLEAR: undefined,
  SET_INVALID_PROJECT: undefined,
  SHOW_FAQ_WINDOW: undefined,
  POPULATE_FAQ_DATA: undefined,
  AURORA_IMPORT: undefined,
  CLEAR_AURORA_IMPORT: undefined,
  AURORA_DESIGN_CONFIG_INIT_DATA_REQUEST: undefined,
  AURORA_DESIGN_CONFIG_INIT_DATA_SUCCESS: undefined,
  IMPORT_AURORA_PROJECT_FAILED: undefined,
  IMPORT_AURORA_FULL_DESIGN_PROJECT: undefined,
  IMPORT_AURORA_PROJECT_SUCCESS: undefined,
  REDIRECT_AURORA_PAGE: undefined,
  AUTHENTICATE_AURORA_IMPORT_SUCCESS: undefined,
  SET_AURORA_BACKGROUND_VISIBLE: undefined,
  CLEAR_AURORA_BACKGROUND_VISIBLE: undefined,
});

export const XHR = keyMirror({
  SUCCESS: undefined,
  FAIL: undefined,
});

export const LOCATION_CHANGE: string = '@@router/LOCATION_CHANGE';

export const SET_APP_ERROR = (info: string, stack?: string, isIE?: boolean) => {
  return {
    type: ActionTypes.SET_APP_ERROR,
    payload: {
      stack,
      info,
      isIE,
    },
  };
};

export const REMOVE_APP_ERROR = () => {
  return {
    type: ActionTypes.REMOVE_APP_ERROR,
  };
};

