import { SET_ROW_SPACING, SET_TABLE_ROW_SPACING } from 'actions';
import { state, dispatch } from '__common/store';

export function loadRoofRowSpacing() {
  const { background: { selectedRoofId }, drawingManager: { roofs } } = state();
  const rowSpacing = roofs[selectedRoofId].rowSpacing;
  if (rowSpacing) dispatch(SET_ROW_SPACING(rowSpacing));
}

export function loadRoofTableRowSpacing() {
  const { background: { selectedRoofId }, drawingManager: { roofs }, panels: { panels } } = state();
  const tableRowSpacing = roofs[selectedRoofId].tableRowSpacing;
  const has_panels = panels && Array.isArray(panels) && panels.length > 0;
  if (tableRowSpacing && has_panels) dispatch(SET_TABLE_ROW_SPACING(tableRowSpacing));
}
