import React from 'react';
import { DEBUG } from 'debug';
import { connect } from 'react-redux';

type props = {
  cursorPosition: pixelPoint;
};

class CursorPosition extends React.Component<props, {}> {
  render() {
    if (!DEBUG.showCursorPosition) {
      return null;
    }

    const { cursorPosition: { x, y } } = this.props;
  
    return (
      <div>
        <span>x: {x.toFixed(2)}</span>
        &nbsp;
        <span>y: {y.toFixed(2)}</span>
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    cursorPosition: state.editorCursor.position,
  };
}

export default connect(mapStateToProps)(CursorPosition);
