import autobind from "autobind-decorator";
import React from "react";
import TextField from 'react-md/lib/TextFields/TextField';

type RangeFieldProps = {
  to: any;
  from: any;
  onFromChange: Function;
  onToChange: Function;
  prefix?: string;
  suffix?: string;
};


export default class RangeField extends React.Component<RangeFieldProps> {

  lowerLimitRef = null;
  upperLimitRef = null;
  constructor(props) {
    super(props);
  }

  @autobind
  lowerLimitChange(val) {
    const { to, onFromChange } = this.props;
    if (val) {
      if (Number(val) > to) return;
      onFromChange(Number(val));
    }
    else onFromChange(val);
  }

  @autobind
  upperLimitChange(val) {
    const { from, onToChange } = this.props;
    if (val) {
      if (Number(val) < from) return;
      onToChange(Number(val));
    }
    else onToChange(val);
  }

  render() {
    const { from, to, prefix, suffix } = this.props;
    return (
      <div className="InputFromToTextFieldInput">
        <div className="InputFromToTextField">
          {prefix}
          <TextField
            className="number-field"
            value = {from??''}
            id = 'FromInput' 
            ref={(el) => { this.lowerLimitRef = el; }}
            onChange={(val) => this.lowerLimitChange(val)}
            type='number'
            min={0}
            fullWidth={false} />
          {suffix}
        </div>
        <div className="InputFromToText"> — </div>
        <div className="InputFromToTextField">
          {prefix}
          <TextField
            value = {to??''} 
            id = 'ToInput' 
            ref={(el) => { this.upperLimitRef = el; }}
            onChange={(val) => this.upperLimitChange(val)}
            type='number'
            min={0}
            fullWidth={false} />
          {suffix}
        </div>
      </div>
        );
  }
}
