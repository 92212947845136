export const apiField = 'nbcc_wind_pressure';

function makeConfig(max: number = 0.68, min: number = 0.30, label:string= 'nbccWindPressure') {
  return {
    type: 'Number',
    label,
    apiField,
    min,
    max,
    missing: undefined,
  };
}

const config = makeConfig();

export default config;