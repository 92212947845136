import Polygon from 'polygon';
import { getRoofEdgesPointsInPx } from '../../background/background';
import { radiansToDegrees } from '__common/calculations/radiansToDegrees';

export function polygonIsClockwise(polygonPoints: pixelPoint[]) {
  return new Polygon(polygonPoints).winding();
}

export function getAngle(leadEdgeCords: pixelPoint[], roofEdges: google.maps.LatLngLiteral[], roofCenter: cordPoint, zoom: number): number {
  const points = getRoofEdgesPointsInPx(roofEdges, roofCenter, zoom);
  const clockwise: boolean = polygonIsClockwise(points);
  const leadEdgeAngle1 = Math.atan2(-(leadEdgeCords[0].y - leadEdgeCords[1].y), (leadEdgeCords[0].x - leadEdgeCords[1].x));

  let angleInDegrees = radiansToDegrees(leadEdgeAngle1);

  if (clockwise) {
    angleInDegrees -= 180;
  }

  return angleInDegrees;
}
