export const apiField = 'foundation_type';

export enum FoundationType {
  DRIVEN = 1,
  CONCRETE_PILE = 2,
  BALLAST_BLOCK = 3
  }
  
  export const options = {
  driven : {
    value: FoundationType.DRIVEN,
     name: 'DRIVEN',
    },
  castInPlace: {
    value: FoundationType.CONCRETE_PILE,
     name: 'CONCRETE PILE',
    },
  aboveGradeBallast: {
    value: FoundationType.BALLAST_BLOCK,
    name: 'BALLAST BLOCK',
  },
  }

const config = {
  type: 'Select',
  apiField,
  label: 'foundation_type',
  data: [
    options.driven,
    options.castInPlace,
    options.aboveGradeBallast
  ],
};

export default config;
