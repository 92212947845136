import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';
export const apiField = 'parapet_height';

function makeConfig(label: string, options: object[]) {
  return {
    type: 'Select',
    label: label,
    tooltip: parapetHeightTooltip,
    apiField,
    data: options,
  };
}

const ecoFoot2PlusParapetHeight = makeConfig('parapetHeight',[
  {
    value: 1,
    name: '< 1/2 ARRAY HEIGHT (< 5.5INCHES)',
  },
  {
    value: 2,
    name: '< 1 ARRAY HEIGHT (< 11INCHES)',
  },
  {
    value: 3,
    name: '>= 1 ARRAY HEIGHT (> 11INCHES)',
  },
],
);

const ecoFoot2PlusParapetHeightMetric = makeConfig('parapetHeightMetric', [
  {
    value: 1,
    name: '< 1/2 ARRAY HEIGHT (< 0.14 METERS)',
  },
  {
    value: 2,
    name: '< 1 ARRAY HEIGHT (< 0.28 METERS)',
  },
  {
    value: 3,
    name: '>= 1 ARRAY HEIGHT (> 0.28 METERS)',
  },
],
);

export default (inputUnit: number) => isMetricUnit(inputUnit) ? ecoFoot2PlusParapetHeightMetric : ecoFoot2PlusParapetHeight;

