import React from 'react';
import { connect } from 'react-redux';
import { CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN, CHANGE_STRUCTURE_TYPE_TILT, OPEN_DRAWER_PAGE } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import { getStructuretype, getStructuretypeVal } from './StructureTypeChange';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import { REMOVE_PANELS } from '__editor/panelsEditor/actions';
import { dispatch } from '__common/store';
import { ascenderTiltRange } from './projectConfiguration/fields/tilt';
import { TextField } from 'react-md';
import Button from 'react-md/lib/Buttons/Button';
import SVG from 'react-inlinesvg';
import { REMOVE_PANELS_ON_ROOF } from 'actions';
import _ from 'lodash';
import { AlertInstance, showCustomNotifyAlert } from '__common/modules/alerts';

type Props = {
  hideDrawer: () => void,
  changeStructureTypePreferredSpan:(structureType: number, preferred_span: number) => void,
  panels: panelInState[],
  structureTypeInfo: structureTypeInfoState[],
  roofs: { [roofId: string]: drawingManagerRoof },
};

interface state {
  structureTypeTiltState: {structureType: number, tilt: number, isValid: boolean}[],
  structureTypePreferredSpanState: {structureType: number, preferredSpan: number, show: boolean, isValid: boolean}[],
  highlight: boolean,
  tooltipOpen: boolean,
  tooltipInstance: AlertInstance | null,
}

const PREFERRED_SPAN_DEFAULT = 56;

class StructureTypeSpanTilt extends React.Component<Props, state> {

  constructor(props: Props) {
    super(props);
    const {structureTypeInfo, panels} = props;
    this.state = {
      structureTypeTiltState: structureTypeInfo.map(item => {
        const {min, max} = ascenderTiltRange[this.getFamily(item.structure_type)]
        return {
          structureType: item.structure_type,
          tilt: item.tilt,
          isValid: min <= item.tilt && item.tilt <= max,
        }
      }),
      structureTypePreferredSpanState: structureTypeInfo.map(item => {
        return {
          structureType: item.structure_type,
          preferredSpan: item.preferred_span,
          show: item.preferred_span !== null,
          isValid: item.preferred_span > 0,
        }
      }),
      highlight:  false, 
      tooltipOpen: false,
      tooltipInstance: null,
    }
  }

  tooltipDiv = () => `Please enter Installer span by clicking on the highlighted button`

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<state>, snapshot?: any): void {
    if(prevProps.panels.length == 0 && _.uniqBy(this.props.panels, p => p.panelConfig).length == 1 && !this.state.highlight) {
        this.setState({highlight: true});
        setTimeout(() => {
          this.setState({ highlight: false });
        }, 5000);
        const alert = showCustomNotifyAlert(this.tooltipDiv(), 5);
        alert.ondismiss = () => this.setState({
          tooltipOpen: false,
          tooltipInstance: null,
        });
        this.setState({
          tooltipOpen: true,
          tooltipInstance: alert,
        });
       }
  }

  getStructureTypesInfo = (): { name: string, tilt: number, preferred_span: number }[] => {
    const structureTypes = new Set<Number>();
    const { panels, } = this.props;
    const {structureTypePreferredSpanState, structureTypeTiltState} = this.state;
    if (panels.length) {
      panels.map(panel => {
        structureTypes.add(panel.panelConfig)
      })
    }
    
    const structureTypesInfo = Array.from(structureTypes).map((structureType: number) => {
      return {
        name: getStructuretype(structureType),
        tilt: structureTypeTiltState[structureType-1].tilt,
        preferred_span: structureTypePreferredSpanState[structureType-1].preferredSpan,
      }
    })

    return structureTypesInfo;
  }

  getFamily = (structureType: number) => {
    if(structureType == 1 || structureType == 2) return '1p';
    else return '2p';
  }

  onStructureTypeTiltChange = (structureType: number, val: number,) => {
    const {structureTypeTiltState,} = this.state;
    const {roofs, panels} = this.props;
    let isValid = false;
    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    if(val >= min && val <= max) {
      dispatch(CHANGE_STRUCTURE_TYPE_TILT(structureType, val));
      isValid = true;
      let panelIds = [];
      if(roofs && Object.keys(roofs).length > 0) {
        Object.keys(roofs).map(roofId => {
          panelIds = [];
          const roof = roofs[roofId];
          const panels = roof.panels;
          if(panels && panels.length > 0) {
            panels.map(panel => {
              if(panel.panelConfig == structureType) {
                panelIds.push(panel.id)
              }
            })
          }
          if(panelIds && panelIds.length > 0) dispatch(REMOVE_PANELS_ON_ROOF(panelIds, Number(roofId)));
        })
      }
      panelIds = []
      if(panels && panels.length > 0) {
        panels.map(panel => {
          if(panel.panelConfig == structureType) {
            panelIds.push(panel.id);
          }
        })
      }
      dispatch(REMOVE_PANELS(panelIds));
    }
    this.setState({ ...this.state, structureTypeTiltState: [...structureTypeTiltState.slice(0,structureType-1), 
      { ...structureTypeTiltState[structureType-1], isValid: isValid, tilt: val}, 
      ...structureTypeTiltState.slice(structureType)] });
  }


  onStructureTypePreferredSpanChange = (structureType: number, val: number) => {
    const {structureTypePreferredSpanState} = this.state;
    let isValid = false;
    if(val > 0) {
      dispatch(CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN(structureType, val));
      isValid = true;
    }
     this.setState({ ...this.state, structureTypePreferredSpanState: [...structureTypePreferredSpanState.slice(0,structureType-1), 
      { ...structureTypePreferredSpanState[structureType-1], preferredSpan: val, isValid: isValid,}, 
      ...structureTypePreferredSpanState.slice(structureType)] });
  }


  renderWarningMessageForStructureTypeTilt(structureType: number) {
    if (this.state.structureTypeTiltState[structureType-1].isValid) {
      return null;
    }
    
    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    const errorMessage = `Tilt must be greater than ${min-1} and less than  ${max+1}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  renderWarningMessageForPreferredSpan(structureType: number) {
    if (this.state.structureTypePreferredSpanState[structureType-1].isValid) {
      return null;
    }
  
    const errorMessage = `Preferred Span must be greater than 0`;

    return (
      <div className="input-warning">{errorMessage}</div>
    )
  }

  _hasPanels = (structureType: number) => {
    const { panels, roofs } = this.props;

    let panelIds = [];
    if(roofs && Object.keys(roofs).length > 0) {
      Object.keys(roofs).map(roofId => {
        const roof = roofs[roofId];
        const panels = roof.panels;
        if(panels && panels.length > 0) {
          panels.map(panel => {
            if(panel.panelConfig === structureType) {
              panelIds.push(panel.id)
            }
          })
        }
      })
    }
    if(panels && panels.length > 0) {
      panels.map(panel => {
        if(panel.panelConfig === structureType) {
          panelIds.push(panel.id);
        }
      })
    }

    return panelIds.length > 0;
  }

  shouldConfirm = (value: number, structureType: number) => {
    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    if (value >= min && value <= max) {
      return this._hasPanels(structureType);
    }
    else {
      return false;
    }
  }

  toggleInstallerSpanInfo = (structureType: number) => {
    const {changeStructureTypePreferredSpan} = this.props;
    const {structureTypePreferredSpanState} = this.state;
    let preferred_span = null;
    let isValid = false;
    if(structureTypePreferredSpanState[structureType-1].show) {
      changeStructureTypePreferredSpan(structureType, null);
    }
    else {
      preferred_span = PREFERRED_SPAN_DEFAULT;
      changeStructureTypePreferredSpan(structureType, preferred_span);
      isValid = true;
    }
    this.setState({ ...this.state, structureTypePreferredSpanState: [...structureTypePreferredSpanState.slice(0,structureType-1), 
      { ...structureTypePreferredSpanState[structureType-1], show: !structureTypePreferredSpanState[structureType-1].show, preferredSpan: preferred_span, isValid: isValid }, 
      ...structureTypePreferredSpanState.slice(structureType)] });
  }

  render() {
    const {structureTypePreferredSpanState} = this.state;
    const structureTypesInfo= this.getStructureTypesInfo();

    if (structureTypesInfo.length) {
      const structureTypesDiv= structureTypesInfo.map((structureType) => (
        <>
        <div className="obstruction" >
          <div className="name" style={{width:'200px'}}>
          <TextField
            className={`obstruction-input`}
            label="Name :"
            type="text"
            lineDirection="center"
            placeholder="Set structure type name"
            defaultValue={structureType.name}
            disabled
           />
          </div>
          <div className="height" style={{width:'100px'}}>
          <div className="input-label">Tilt (degree) :</div>
            <ConfirmationTextField
              value={structureType.tilt}
              onConfirm={(val: number) => this.onStructureTypeTiltChange(getStructuretypeVal(structureType.name), Number(val))}
              title="Are you sure you want to change the tilt?"
              content={`Current ${structureType.name} array(s) in this project will be lost.`}
              shouldConfirm={true}
              shouldConfirmCb={(value) => this.shouldConfirm(value, getStructuretypeVal(structureType.name))}
              textFieldProps={{
                lineDirection: 'center',
                type: 'number',
                fullWidth: false,
              }}
            />
            {this.renderWarningMessageForStructureTypeTilt(getStructuretypeVal(structureType.name))}
          </div>
          <div className="height" >
        {structureTypePreferredSpanState[getStructuretypeVal(structureType.name)-1].show ? 
        <div>
          <div style={{float:'left', padding:'10px 5px'}}> 
            <Button onClick={() =>this.toggleInstallerSpanInfo(getStructuretypeVal(structureType.name))} tooltipLabel="Remove Installer span"> <SVG src={require('assets/media/icons/minus_sign.svg')} />
            </Button>
          </div>
          <div style={{float:'right', width:'120px'}}>
            <div className="input-label">Installer Span (cm) : </div>
            <TextField
                className={`obstruction-input`}
                id="floating-center-title"
                type="number"
                lineDirection="center"
                placeholder="Set installer span"
                defaultValue={structureType.preferred_span}
                onChange={(val: number) => this.onStructureTypePreferredSpanChange(getStructuretypeVal(structureType.name), val)}
              />
                {this.renderWarningMessageForPreferredSpan(getStructuretypeVal(structureType.name))}
            </div>
          </div> : <div style={{float:'left', padding:'10px 5px'}}> <Button  className = {this.state.highlight ? `highlight` : null} onClick={() =>this.toggleInstallerSpanInfo(getStructuretypeVal(structureType.name))}  tooltipLabel="Set Installer span">
            <SVG src={require('assets/media/icons/plus_sign.svg')} />
          </Button> </div>}
           </div>
        </div>
        </>
      ));

      return (
        <div className="obstructions">
          <div className="drawer-section-title">Structure Type Info</div>
          {structureTypesDiv}
        </div>
      );
    }

    document.body.click();
    return null;
  }
}

const mapStateToProps = (appState: appState) => ({
  roofs: appState.drawingManager.roofs,
  panels: appState.panels.panels,
  structureTypeInfo: appState.projectConfiguration.projectEnvConfig.structure_type_info,
});

const mapDispatchToProps = (dispatch) => ({
  changeStructureTypePreferredSpan: (structure_type: number, preferred_span: any) => dispatch(CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN(structure_type, preferred_span)),  
  hideDrawer: () => dispatch(OPEN_DRAWER_PAGE(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StructureTypeSpanTilt);
