import * as React from 'react';
import Icon from '__common/components/Icon';
import { changeMetalXRoofAttachmentType } from 'bom/components/utils/partsSelectionFunctions';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import { SelectionControl } from 'react-md';
declare type MetalXAttachmentTypeComponentState = {
  dispatch: Function,
  group: string,
  groupItemTypes: string[],
  selectedGroupItem: string,
};
export default class MetalXAttachmentType extends React.Component<MetalXAttachmentTypeComponentState, {}> {
  changeAttachmentType(group: string, selectedGroupItem: string, groupItemTypes: string[]) {
    changeMetalXRoofAttachmentType(group, selectedGroupItem, groupItemTypes);
  }

  parseTypes = (groupItemTypes: string[]) => {
    return groupItemTypes.map(type => Object({ label: type, value: type, key: type.toLowerCase().split(' ').join('-') }));
  }  
  render() {
    const { group, groupItemTypes, selectedGroupItem } = this.props;
    const types = this.parseTypes(groupItemTypes);
    return (
      <div className="attachment-types-selector">
        <div className="control-panel-sublabel">
            {group.toUpperCase()}
        </div>
        {groupItemTypes.length > 1? 
        <SelectionControlGroup
          id={`${group}-selection-control-group-radios`}
          name="metalx-attachment-types"
          type="radio"
          value={selectedGroupItem}
          controls={types}
          onChange={(item) => this.changeAttachmentType(group, item, groupItemTypes)}
          inline={true}
          checkedRadioIcon={(<Icon>check</Icon>)}
          uncheckedRadioIcon={(<Icon>check</Icon>)}
        />
        :
        <SelectionControl
          id={`${group}-selection-control`}
          name="metalx-attachment-types"
          label={`${groupItemTypes[0]}`}
          type="checkbox"
          disabled={selectedGroupItem === groupItemTypes[0] ? true: false}
          onChange={() => this.changeAttachmentType(group, selectedGroupItem === groupItemTypes[0]? '': groupItemTypes[0], groupItemTypes)}
          checked={selectedGroupItem === groupItemTypes[0]}
          checkedCheckboxIcon={(<Icon>check</Icon>)}
          uncheckedCheckboxIcon={(<Icon>check</Icon>)}
        />
        }
      </div>
    );
  }
}

