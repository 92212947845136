export default () => `
<div class="content">
  Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. 
  If your project is in a special case study region or in an area where wind studies have been performed, 
  please verify with your jurisdiction to ensure that elevation effects have not already been factored into the wind speed.
  If elevation effects have been included in your wind speed, please select 0 ft. 
</div>
`;


export const elevationTooltipMetric =  () => `
<div class="content">
  Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. 
  If your project is in a special case study region or in an area where wind studies have been performed, 
  please verify with your jurisdiction to ensure that elevation effects have not already been factored into the wind speed.
  If elevation effects have been included in your wind speed, please select 0 m. 
</div>
`;