import { ConfirmClearArraysModalActionTypes } from 'actionsConstants';


export function OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(title: string, content: string, onConfirmCallback: Function, onCancelCallback?: Function, onConfirmAction?, onCancelAction?, confirmTitle='', cancelTitle='', dialogStyleProps=null, modal?: boolean) {
  return {
    type: ConfirmClearArraysModalActionTypes.OPEN_CONFIRM_CLEAR_ARRAYS_MODAL,
    payload: {
      title,
      content,
      onConfirmCallback,
      onCancelCallback,
      onConfirmAction,
      onCancelAction,
      confirmTitle,
      cancelTitle,
      dialogStyleProps,
      modal,
    },
  };
}

export function HIDE_CONFIRM_CLEAR_ARRAYS_MODAL() {
  return {
    type: ConfirmClearArraysModalActionTypes.HIDE_CONFIRM_CLEAR_ARRAYS_MODAL,
  };
}
