import centralRailCountTooltip from "../fieldsTooltips/centralRailCountTooltip";

export const apiField = 'central_rails_count';


export default {
    type: 'Select',
    apiField,
    label: 'centralRailsCount',
    tooltip:centralRailCountTooltip,
    data: [
        {
            value: 0,
            name: '0',
        },
        {
            value: 1,
            name: '1',
        },
        {
            value: 2,
            name: '2',
        },
    ],
};
