import { products } from '__common/constants/products';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { isSMAscenderFlush, isSolarMount, } from '__common/constants/products';
import { state } from '__common/store';

function accessibleProductForUser(paramProductName:string=undefined){
  const { user: { products: userProducts } } = state();
  return userProducts.includes(products[paramProductName]);
}
export default accessibleProductForUser;


export const isSMFlushMetric = () => {
  const {projectConfiguration: {railsProductId, inputUnit}} = state();
  return isSolarMount(railsProductId) && isMetricUnit(inputUnit);
}

export const isSMFamilyNonAscenderFlushMetric = () => {
  const {projectConfiguration: {railsProductId, inputUnit}} = state();
  return isSolarMount(railsProductId) &&  !isSMAscenderFlush(railsProductId) && isMetricUnit(inputUnit);
}

export const isSMAscenderFlushMetric = () => {
  const {projectConfiguration: {railsProductId, inputUnit}} = state();
  return isSMAscenderFlush(railsProductId) &&isMetricUnit(inputUnit);
}