import WindExposureTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/windExposureTooltip';
import { apiField, windExposureOption, WindExposureField } from 'projectDesign/components/projectConfiguration/fields/types/windExposure';

const config: WindExposureField = {
  label: 'windExposure',
  type: 'Select',
  apiField,
  missing: undefined,
  tooltip: () => WindExposureTooltip({ openWaterEnabled: false }),
  data: [
    windExposureOption.inTown,
    windExposureOption.openTerrain,
  ],
};

export default config;
