export const getWarningMessageFactory = (state: appState) => 
  (fieldName: string) => {
    const field = state.projectConfiguration.formState.warningFields.find(f => f.fieldName === fieldName);

    if (field) {
      return field.warningMessage;
    }

    return null;
  };
