import { ActionTypes } from 'actionsConstants';
import config from 'config';
import Cookies from 'js-cookie';


export function USER_LOGIN_REQUEST(username?: string, password?: string, token?: string) {
  return {
    type: ActionTypes.USER_LOGIN_REQUEST,
    payload: {
      username,
      password,
      token,
    },
  };
}

export function USER_LOGIN_SUCCESS(token: string, username: String, email: string, isStaff: boolean, isPrivilegedUser: boolean, products: [], permissions: []) {
  return {
    type: ActionTypes.USER_LOGIN_SUCCESS,
    payload: {
      token,
      username,
      email,
      isStaff,
      isPrivilegedUser,
      products,
      permissions
    },
  };
}

export function logOut() {
  return {
    type: ActionTypes.USER_LOGOUT_REQUEST,
  };
}

export function loginError(error: { response: { non_field_errors: string[] }, status: number }) {
  let err;

  if (error.response && error.response.non_field_errors && error.response.non_field_errors.length) {
    err = error.response.non_field_errors;
  }
  if (error.status === 401) {
    Cookies.remove('token');
    Cookies.remove('_globalToken', { domain: config.domainName });
    Cookies.remove('USER_DATA', { domain: config.domainName });
  }
  return {
    type: ActionTypes.USER_LOGIN_FAILURE,
    payload: { error: err },
  };
}

export function clearLoginError() {
  return {
    type: ActionTypes.USER_CLEAR_LOGIN_ERROR,
  };
}

export function USER_ALREADY_LOGED_IN() {
  return {
    type: ActionTypes.USER_ALREADY_LOGED_IN,
  };
}

export function USER_REGISTRATION_FAILURE() {
  return {
    type: ActionTypes.USER_REGISTRATION_FAILURE,
  };
}

export function USER_CLEAR_REGISTRATION_ERROR() {
  return {
    type: ActionTypes.USER_CLEAR_REGISTRATION_ERROR,
  };
}

export function REGISTRATION_REQUEST(
  email: String,
  password: String,
  repeatedPassword: String,
  postRegistrationAction: (data) => void,
  onErrorAction: (error: { [fieldName: string]: string[] }) => void) {
  return {
    type: ActionTypes.REGISTRATION_REQUEST,
    payload: {
      email,
      password,
      repeatedPassword,
      postRegistrationAction,
      onErrorAction,
    },
  };
}
