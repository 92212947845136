export default () =>
    `<div class="content"> 
      Please provide deck capacity if it is lower than given value. </br>
      <strong>Note:</strong> Please enter attachment allowable capacity NOT ultimate capacity. </br>
      Allowable capacity = Ultimate capacity / factor of safety, factor of safety = 3
    </div>
`;

export const upliftShearAllowableGridflexTooltip = () =>
    `<div class="content"> 
      Please provide deck capacity if it is lower than given value.
    </div>
`;
