import ecoFoot2PlusSurfaceTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/ecoFoot2PlusSurfaceTooltip';
export const apiField = 'ecofoot_surface'
export default {
  type: 'Select',
  label: 'Ecofoot SUrface',
  apiField,
  missing: undefined,
  tooltip: ecoFoot2PlusSurfaceTooltip,
  data: [
    {
      value: 1,
      name: 'BARE',
    },
    {
      value: 2,
      name: 'EPDM',
    },
  ],
};
