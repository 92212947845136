import { dispatch } from "__common/store";
import { drawBay } from "./panelsManagment";
import { ADD_VISITED_BAYS } from "../panelsActions";
import { PanelsContainer } from "__editor/panelsEditor/models/panelsContainer";
import _ from "lodash";

export const createBays = async (panelBaysMapping: panelBayMapping = new Map(), panelsDict, baysContainer:PanelsContainer, visitedBays: number[], rightEdgePanels: number[], leftEdgePanels: number[], northEdgePanels: number[], northRightEdgePanels: number[], northMostRow: number[])=>{
    const newArray = [...(panelBaysMapping.values())].flat();
      const difference = _.filter(newArray, (obj1:any) => !_.some(baysContainer.children, (obj2:any) => obj1.id === obj2.id && obj1.attached === obj2.attached)).map(obj=> obj.id);
      baysContainer.children.forEach((child) => {
        if (difference.includes(child.id)) {
          baysContainer.removeChild(child);
        }
      });
      const visited = [...(visitedBays ?? [])];
      if(visited.length) baysContainer.children = baysContainer.children.filter(child => visited.includes(child.id))
      const updatedBays = _.difference(visited, difference)
  
      if(panelBaysMapping && Object.keys(panelsDict).length>=1){
        for (const [panelId, baysAssociated] of panelBaysMapping.entries()) {
          baysAssociated.map((bay)=> {
            if(updatedBays.includes(bay.id)){
              return
            } else if (panelsDict[panelId]) {
              const pixiPanel: PIXI.Graphics = drawBay(panelsDict[panelId], bay, rightEdgePanels, leftEdgePanels, northEdgePanels, northRightEdgePanels, northMostRow)
              baysContainer.addChild(pixiPanel)
              updatedBays.push(bay.id)
            }
          })
        }
      if(updatedBays?.length && updatedBays?.length !== visitedBays?.length){
        dispatch(ADD_VISITED_BAYS(updatedBays))
      }
    }
    return updatedBays
  }