import { round } from "lodash";
import { isASCE705, isASCE710 } from "__common/constants/buildingCodes";
import { greaterThanEqualToProjectVersion } from "__common/utils/versionCompare/versionCompare";
import { VERSION_MAP } from "__common/utils/versionCompare/version_info";
import { isMetricUnit } from "engineering/components/engineeringProjectDocuments/utils/unitTypes";
import { metersToFeets } from "__common/calculations/metersToFeets";
import { isCanadianZipcode } from "./validation";
import { RmRoofType } from "./constants";
import { products } from "__common/constants/products";

function getAlphaZgBasedOnWindExposure(windExposure: string) {
    switch (windExposure) {
        case 'B':
            return { alpha: 7.0, zg: 1200 };
        case 'C':
            return { alpha: 9.5, zg: 900 };
        case 'D':
            return { alpha: 11.5, zg: 700 };
        default:
            return { alpha: 7.0, zg: 1200 };
    }
}
export function calculateKz(windExposure: string, buildingHeight: number, zipCode: string, version: string = VERSION_MAP['velocity_pressure_exposure_coefficient_changes'], inputUnit: number = 1) {
    const buildingHeightFt = isMetricUnit(inputUnit) ? metersToFeets(buildingHeight) : buildingHeight;

    let isCanadianZipCode = isCanadianZipcode(zipCode);

    windExposure = isCanadianZipCode ? getCanadianWindExposure(windExposure) : windExposure;

    // Minimum building height from flyouts is 15ft
    const zga = getAlphaZgBasedOnWindExposure(windExposure);

    let max_height = windExposure == 'B' ? 30 : 15

    let kz = isCanadianZipCode ? 2.01 * (Math.max(buildingHeightFt, max_height) / zga.zg) ** (2 / zga.alpha) : 2.01 * (buildingHeightFt / zga.zg) ** (2 / zga.alpha)

    if (!Boolean(kz)) {
        kz = 0.98
    }

    return greaterThanEqualToProjectVersion(version, VERSION_MAP['velocity_pressure_exposure_coefficient_changes']) ? kz : round(kz, 2);
}

function getCanadianWindExposure(windExposure: string) {

    switch (windExposure) {
        case 'A':
            return 'B';
        case 'B':
            return 'C';
    }
}

export function calculateKe(elevation: number, isASCE716or722: boolean = false, inputUnit: number = 1) {
    const elevationFt = isMetricUnit(inputUnit) ? metersToFeets(elevation) : elevation;
    let ke
    if (!isMetricUnit(inputUnit) && (isASCE716or722)) {
        ke = Math.exp(-0.0000362 * elevationFt)
    } else {
        ke = 1
    }
    return ke;
}

const NUMERICAL_COEFFICIENT_ASCE_710 = {
    0: 0.0025553,
    1000: 0.0024785,
    2000: 0.002405,
    3000: 0.0023348,
    3281: 0.0023148,
    4000: 0.0022647,
    5000: 0.0022012,
    6000: 0.0021344,
    6562: 0.002101,
    7000: 0.0020709,
    8000: 0.0020108,
    9000: 0.0019507,
    9843: 0.0019006,
    10000: 0.0018939
}
const NUMERICAL_COEFFICIENT_ELEVATIONS_ASCE_710 = [0, 1000, 2000, 3000, 3281, 4000, 5000, 6000, 6562, 7000, 8000, 9000, 9843, 10000]
const NUMERICAL_COEFFICIENT_ASCE_716 = 0.00256

function lookup_coefficient(number: number, values: number[]) {
    if (values.includes(number)) {
        return number
    } else if (number < values[values.length - 1]) {
        for (let i = 0; i < values.length; i++) {
            if (number > values[i] && number < values[i + 1]) {
                return values[i]
            }
        }
    } else {
        return values[-1]
    }

}
export function get_numerical_coefficient(building_code: number, elevation: number, inputUnit: number = 1) {
    const elevationFt = isMetricUnit(inputUnit) ? metersToFeets(elevation) : elevation;

    let numerical_coefficient = 0;
    console.log(isMetricUnit(inputUnit));
    if (!isMetricUnit(inputUnit) && (isASCE705(building_code) || isASCE710(building_code))) {
        let newElevation = elevationFt < 0 ? 0 : elevationFt
        numerical_coefficient = NUMERICAL_COEFFICIENT_ASCE_710[lookup_coefficient(
            newElevation, NUMERICAL_COEFFICIENT_ELEVATIONS_ASCE_710)]
    }
    else {
        numerical_coefficient = NUMERICAL_COEFFICIENT_ASCE_716
    }
    return numerical_coefficient
}

export function get_design_life_factor(mean_recurrence_interval: number) {
    const ratio_wind_speed_return_period = round(0.36 + 0.1 * Math.log(12 * mean_recurrence_interval), 3)
    return ratio_wind_speed_return_period ** 2

}
const RM_GRIDFLEX_FRICTION_COEFFICIENTS = {
    [RmRoofType.PVC]: 0.9,
    [RmRoofType.EPDM]: 0.57,
    [RmRoofType.TPO]: 0.49,
    [RmRoofType.MINERAL_CAP]: 0.53,
    [RmRoofType.OTHER]: 0.38,
    [RmRoofType.FINE_GRAVEL]: 0.38,
    [RmRoofType.HONED_CONCRETE]: 0.61,
    [RmRoofType.PAINTED_CONCRETE]: 0.53,
    [RmRoofType.BROOMED_CONCRETE]: 0.57,
    [RmRoofType.KEE]: 0.38,
    [RmRoofType.TPA]: 0.38,
    [RmRoofType.APP]: 0.38,
    [RmRoofType.SBS]: 0.38,
    [RmRoofType.ACRYLIC]: 0.38,
    [RmRoofType.COATED]: 0.38,
}

const RM_GRIDFLEX_10_FRICTION_COEFFICIENTS = {
    [RmRoofType.PVC]: 0.84,
    [RmRoofType.EPDM]: 0.72,
    [RmRoofType.TPO]: 0.68,
    [RmRoofType.MINERAL_CAP]: 0.72,
    [RmRoofType.OTHER]: 0.4,
    [RmRoofType.FINE_GRAVEL]: 0.58,
    [RmRoofType.HONED_CONCRETE]: 0.75,
    [RmRoofType.PAINTED_CONCRETE]: 0.52,
    [RmRoofType.BROOMED_CONCRETE]: 0.63,
    [RmRoofType.KEE]: 0.4,
    [RmRoofType.TPA]: 0.4,
    [RmRoofType.APP]: 0.4,
    [RmRoofType.SBS]: 0.4,
    [RmRoofType.ACRYLIC]: 0.4,
    [RmRoofType.COATED]: 0.4,
}
const FRICTION_COEFFICIENTS = {
    [products.rm_gridflex]: RM_GRIDFLEX_FRICTION_COEFFICIENTS,
    [products.rm_gridflex_10]: RM_GRIDFLEX_10_FRICTION_COEFFICIENTS,

}
export function getFrictionFactor(roof_type: number, productId: number) {
    return FRICTION_COEFFICIENTS[productId][roof_type]
}