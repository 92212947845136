import { InfoModalActionTypes } from "./InfoModalActionsConstants";

export function OPEN_INFO_MODAL(
  title: string,
  content: string,
  onConfirmCallback?: Function,
  onConfirmAction?) {
  return {
    type: InfoModalActionTypes.OPEN_INFO_MODAL,
    payload: {
      title,
      content,
      onConfirmCallback,
      onConfirmAction,
    },
  };
}

export function HIDE_INFO_MODAL() {
  return {
    type: InfoModalActionTypes.HIDE_INFO_MODAL,
  };
}
