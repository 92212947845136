import * as React from 'react';
import { connect } from 'react-redux';

type Props = {
  warningsInfo: warningsInfo,
};

class BomWarnings extends React.Component<Props, {}> {

  render() {
    return (
      <div>
        {
          this.props.warningsInfo?.warnings.map(warning => {
            return(
              <div className='alert-danger' style={{border: '1px solid #AAA', marginBottom: '5px', padding:'5px'}}>{warning}</div>
            )
          })
        }
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    warningsInfo: state.bom.warningsInfo,
  };
}

export default connect(mapStateToProps)(BomWarnings);
