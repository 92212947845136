import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isRMFamily, isSFMFamily, getProductName, isMetalX, isSfmInfinity, isRM5, isRMDT, isRMGridFlex, isAscender, isRM10, isRM10Evolution, isSMTiltPR, isRmGridflex10, isEcoFoot2Plus, isGFT } from '__common/constants/products';
import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import {
  OPEN_DRAWER_PAGE,
  SET_CUSTOM_VALUE,
  SET_FLYOUT_VERIFIED,
  SET_PROJECT_OPTION,
} from 'actions';
import { FETCH_MFGS_AND_MODELS_REQUEST, SET_MODEL_DATA_HAS_ERROR } from '../moduleSelectorActions';
import { REPLACE_PANELS_ON_ROOF, RESIZE_PANELS } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import _ from 'lodash';
import { isSingleOrientationInProject } from '../moduleSelectorHelper';
import { checkThickness, checkSMTiltPRThickness } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { isBlankMap } from '__common/constants/map';
import { validateModuleSize, validateWidth, validateLength, validateWeight, validateWatt, _isInRange } from '__common/components/moduleSelector/components/customModuleValidation';
import { milimetersToInches, inchesToMillimeters, cmsToMillimeters } from '__common/calculations/inchesToMillimeters';
import { inchesToFeet } from '__common/calculations/inchesToFeet';
import { CUSTOM_MANUFACTURING_ID, CUSTOM_MODEL_ID, WARNING_MODULE_THICKNESSES, _width_range } from '__common/constants/modules';
import { checkCentralSupportConditionForSnowloadAndHeight, disableMandatoryMidSupportCondition, updateProjectOptionsOnModuleDimsChange } from 'projectDesign/components/projectConfiguration/utils/updateModuleDims';
import { dispatch, state } from '__common/store';
import { applyCentralSupportForRM5, applyModuleLengthRestrictTo97InchForGFT } from '__common/utils/versionCompare/versionCompare';
import { apiField as centralSupportApiField} from 'projectDesign/components/projectConfiguration/fields/centralSupport';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { MODULE_SELECTION_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import { getEditorCenter } from '__editor/panelsEditor/components/background/background';
import { checkObstructionZoneForPanels, isPanelCollideWithObstacles } from '__editor/panelsEditor/components/obstructions/obstructionsCollisions';
import { shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { filterOutPanelsNotInsideRoofEdges } from 'projectDesign/rmGridflexBlankMapUtils';
import { apiField as railArrangementTypeApiField, options as railArrangementTypeOptions } from 'projectDesign/components/projectConfiguration/fields/railArrangementType';
import { options as topChordOptions, apiField as topChordApiField } from 'projectDesign/components/projectConfiguration/fields/topChord';
import { moduleLengthCheckForGFT } from 'projectDesign/components/projectConfiguration/utils/validation';
import { apiField as mandatorymidsupportApiField } from 'projectDesign/components/projectConfiguration/fields/mandatorymidsupport';


type Props = {
  switchToCustomModule: (productId: string) => void,
  setCustomValue: (field: fieldName, value: number) => void,
  setFlyoutVerified: (flyout: string, value: boolean, error?: boolean) => void,
  resizePanels: (oldModel: modelData, newModel: modelData, productId: number) => void;
  replacePanelsOnRoof: (panel: panelInState[], roofId: number) => void;
  setModelDataError: (hasError: boolean) => void;
  dispatch: Function,
  moduleSelector: moduleSelectorState,
  roofsOnMap: { [roofId: string]: drawingManagerRoof } | null,
  productId: number,
  projectVersion: string,
  snowLoad: number,
  mapType: string,
  editorCursor: editorCursor,
  user: userState,
  inputUnit?: number,
};

type fieldName = 'width' | 'height' | 'thickness' | 'thicknessmm' | 'watts' | 'weight';

const FIELD: { [key: string]: fieldName } = {
  WIDTH: 'width',
  HEIGHT: 'height',
  THICKNESS: 'thickness',
  THICKNESS_MM: 'thicknessmm',
  WATTS: 'watts',
  WEIGHT: 'weight',
};

type State = {
  areaError: string,
  fieldErrors: {
    [K in fieldName]?: string;
  },
  fieldWarnings: {
    [K in fieldName]?: string;
  },
  modelData: {
    watts: number,
    width: number,
    height: number,
    thickness: number,
    thicknessmm: number,
    weight: number,
    id: number,
    module: number,
  },
};

class CustomModuleSelector extends React.Component<Props, State> {

  validationTimeout: ReturnType<typeof setTimeout>;

  sfmThicknessValues = [32, 33, 35, 38, 40, 46];
  sfmInfinityThicknessValues = [30, 32, 33, 35, 38, 40];

  state: State = {
    areaError: '',
    fieldErrors: {},
    modelData: {
      watts: 0,
      width: 0,
      height: 0,
      thickness: 0,
      thicknessmm: 0,
      weight: 0,
      id: 0,
      module: 0,
    },
    fieldWarnings: {}
  };

  componentDidMount() {
    const { moduleSelector: { modelData: { thickness, width, height } } } = this.props;

    this._updateState().then(() => {
      this.props.setModelDataError(this._hasAnyError());
      // if we called validate area before updating local state
      // it would raise an error because of default values (0s)
      // and hence unabling correct values to load.
      return this.validateArea(width, height);
    }).then(() => {
      this.validateThickness(thickness, FIELD.THICKNESS)
      this.validateLength(height, FIELD.HEIGHT)
    });
  }

  componentWillUnmount() {
    // we don't want to values to update after the component has been unmounted.
    setTimeout(() => {
      clearTimeout(this.validationTimeout);
      this.validationTimeout = undefined;
      // closing with errors means reseting to the last valid state
      // which means we can assume the model data error is false.
      this.props.setModelDataError(false);
    }, 250);
  }


  componentDidUpdate(prevProps: Props) {
    if(this.props.inputUnit != prevProps.inputUnit) {
      this._removeErrors();
    }
    else if (this._hasAnyError()) {
      return;
    }
    if (this._modelDataChanged() && !this.validationTimeout) {
      this._updateState().then(() => {
        const { modelData: { height } } = this.state;
        this.validateLength(height, FIELD.HEIGHT);
      });
      updateProjectOptionsOnModuleDimsChange();
    }
  }

  _hasAnyError(): boolean {
    return this._hasAreaError() || Object.keys(this.state.fieldErrors).length > 0;
  }

  _hasError(field: fieldName): boolean {
    return this.state.fieldErrors[field] !== undefined;
  }

  _hasWarning(field: fieldName): boolean {
    return this.state.fieldWarnings[field] !== undefined;
  }

  _hasAreaError(): boolean {
    return this.state.areaError.length > 0;
  }

  _addError(field: fieldName, error: string) {
    this.setState((state) => ({
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        [field]: error,
      },
    }));
  }
  _addWarning(field: fieldName, error: string) {
    this.setState((state) => ({
      ...state,
      fieldWarnings: {
        ...state.fieldWarnings,
        [field]: error,
      },
    }));
  }
  _removeError(field: fieldName) {
    const newErrors = { ...this.state.fieldErrors };
    const { projectConfiguration : { verifiedFlyouts } } = state();
    if ([FIELD.THICKNESS, FIELD.THICKNESS_MM].includes(field)) {
      delete newErrors[FIELD.THICKNESS];
      delete newErrors[FIELD.THICKNESS_MM];
    } else {
      delete newErrors[field];
    }
    if(Object.keys(newErrors).length)
      this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, false, true);
    else
      this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, verifiedFlyouts[MODULE_SELECTION_PAGE].checked, false);
    this.setState({
      fieldErrors: newErrors,
    });
  }
  _removeErrors() {
    this.setState({
      fieldErrors: {},
    });
  }

  _removeWarning(field: fieldName) {
    const newWarnings = { ...this.state.fieldWarnings };
    if ([FIELD.THICKNESS, FIELD.THICKNESS_MM].includes(field)) {
      delete newWarnings[FIELD.THICKNESS];
      delete newWarnings[FIELD.THICKNESS_MM];
    } else {
      delete newWarnings[field];
    }
    this.setState({
      fieldWarnings: newWarnings,
    });
  }

  _updateState = () => {
    const { watts, width, height, thickness, thicknessmm, weight, id, module } = this.props.moduleSelector.modelData;

    const newModelData = {
      watts,
      width,
      height,
      thickness,
      thicknessmm,
      weight,
      id,
      module,
    };

    return new Promise<void>((resolve) => {
      this.setState({ modelData: newModelData }, () => {
        resolve();
      });
    });
  }

  _modelDataChanged = () => {
    const modelData = this.props.moduleSelector.modelData;
    const {
      id,
      module,
      thickness,
      thicknessmm,
      width,
      height,
    } = this.state.modelData;
  
    return (
      id !== modelData.id ||
      module !== modelData.module ||
      thickness !== modelData.thickness ||
      thicknessmm !== modelData.thicknessmm ||
      width !== modelData.width ||
      height !== modelData.height
    );
  }

  _checkObstructionsCollisions = () => {
    const { roofsOnMap } = this.props;

    if (!roofsOnMap || Object.keys(roofsOnMap).length === 0) {
      return;
    }

    // I am doing the same in many places in app
    // Iterating over roof should be done with
    // better manuer
    Object.keys(roofsOnMap).map(roofId => {
      const roof = roofsOnMap[roofId];
      const panels = roof.panels;
      const { blank_map_building_length: length, blank_map_building_width: width, bgScale } = roof;
      const { roofsSelector: { mapType }, projectConfiguration: { projectEnvConfig: { setback_distance: setbackDistance }, inputUnit, productId, projectVersion }, settings: { panelWidth, panelHeight } } = state();
      let filteredPanels;
      if (!panels || panels.length === 0) {
        return;
      }
      const center = getEditorCenter();
      const metersPerPixel = roof.metersPerPixel;

      const panelsNotOnObstructions = panels.filter(panel => {
        return !isPanelCollideWithObstacles(panel, Number(roofId), center.x, center.y, panel.width, panel.height, metersPerPixel);
      });

      const panelsInObstructionZone = checkObstructionZoneForPanels(panelsNotOnObstructions, Number(roofId), metersPerPixel, center.x, center.y);
      if (shouldUseSetBackDistance(mapType, productId, projectVersion)){
        const setbackInMeters = isMetricUnit(inputUnit) ? cmsToMeters(setbackDistance) : feetsToMeters(setbackDistance);
        filteredPanels = filterOutPanelsNotInsideRoofEdges({ length, width, setbackDistance: setbackInMeters, metersPerPixel, bgScale, panelWidth, panelHeight, panels: panelsInObstructionZone });
      }
      else{
        filteredPanels = panelsInObstructionZone;
      }
      this.props.replacePanelsOnRoof(filteredPanels, Number(roofId));
    });
  }

  onChange = (field: fieldName, val: number) => {
    clearTimeout(this.validationTimeout);
    const { moduleSelector: { modelData }, productId, user: { isStaff }, inputUnit } = this.props;
    const { rail_arrangement_type, top_chord } = state().projectConfiguration.projectEnvConfig;
    
    const oldModel = modelData;

    const newModel = _.cloneDeep(modelData);
    newModel[field] = val;

    if (field === FIELD.WIDTH) {
      const widthError = validateWidth(val, productId, isStaff, inputUnit);
      if (widthError.length > 0) {
        this._addError(field, widthError);
      } else {
        this._removeError(field);
      }
    }

    if (field === FIELD.HEIGHT) {
      const heightError = validateLength(val, productId, inputUnit);

      if (heightError.length > 0) {
        this._addError(field, heightError);
      } else {
        this._removeError(field);
      }
    }

    if (field === FIELD.WEIGHT) {
      const weightError = validateWeight(val);

      if (weightError.length > 0) {
        this._addError(field, weightError);
      } else {
        this._removeError(field);
      }
    }

    if (field === FIELD.WATTS) {
      const wattError = validateWatt(val);
      if (wattError.length > 0) {
        this._addError(field, wattError);
      } else {
        this._removeError(field);
      }
    }

    if ([FIELD.THICKNESS_MM, FIELD.THICKNESS].includes(field)) {
      const { productId, inputUnit } = this.props;
      let valid = false;
      const valueInMm = field === FIELD.THICKNESS ? isMetricUnit(inputUnit) ? cmsToMillimeters(val) : inchesToMillimeters(val) : val;
      const minError = 'Minimum Module thickness is 30mm / 1.18in.';
      const rm5RmDTError = 'Module thickness should be >= 30mm / 1.18in and <= 50mm / 1.97in.';
      const rm5RmDTMetricError = 'Module thickness should be >= 3.0cm and <= 5.0Cm.';
      const minAscenderError = 'Minimun Module thickness is 3.0cm'
      const rmError = 'Thickness should be greater than 0.';
      const rmGridflex10Error='Module thickness should be >= 1.18in and <= 1.80in.';
      const rmGridflex10Errormm='Module thickness should be >= 30mm and <= 46mm.';
      const errMsg = () => {
        if (isRM5(productId) || isRMDT(productId)) return isMetricUnit(inputUnit) ? rm5RmDTMetricError : rm5RmDTError;
        else if (isRmGridflex10(productId)) {if (field===FIELD.THICKNESS_MM){return rmGridflex10Errormm} else{return rmGridflex10Error} }
        else if (isRM10(productId) || isRM10Evolution(productId) || isRMGridFlex(productId)) return rmError
        else if (isAscender(productId)) return minAscenderError
        else return minError
      }
      valid = checkThickness(milimetersToInches(valueInMm), productId, true);
      if (valid) {
        this._removeError(FIELD.THICKNESS);
      } else {
        this._addError(field, errMsg());
      }
      if (WARNING_MODULE_THICKNESSES.includes(Math.round(valueInMm)) && !(isRM5(productId) || isRMDT(productId)))
        this._addWarning(field, 'please verify module thickness')
      else {
        this._removeWarning(FIELD.THICKNESS);
        this._removeWarning(FIELD.THICKNESS_MM);
      }
    }

    const areaError = validateModuleSize(newModel.width, newModel.height, productId, isStaff, inputUnit);

    this.setState({
      areaError,
      modelData: {
        ...this.state.modelData,
        [field]: val,
      },
    }, () => {
      this.validationTimeout = setTimeout(() => {
        clearTimeout(this.validationTimeout);
        this.validationTimeout = undefined;
        const { moduleSelector: { selectedMfgId, selectedModelId }, productId, mapType, snowLoad, projectVersion } = this.props;

        const value = Number(newModel[field]);

        if (field === FIELD.THICKNESS) {
          this.setState({
            modelData: {
              ...this.state.modelData,
              thicknessmm: inchesToMillimeters(value),
            },
          });
        }

        if (field === FIELD.THICKNESS_MM) {
          this.setState({
            modelData: {
              ...this.state.modelData,
              thickness: milimetersToInches(value),
            },
          });
        }

        if ((field === FIELD.WIDTH || field === FIELD.HEIGHT) && isBlankMap(mapType) && isSingleOrientationInProject()) {
          this.props.resizePanels(oldModel, newModel, productId);          
          this._checkObstructionsCollisions();
        }

        this.props.setModelDataError(this._hasAnyError());
        if (!this._hasError(field)) {
          if (Number(Number(oldModel[field]).toFixed(2)) !== Number(newModel[field])) {
            this.props.setCustomValue(field, Number(value));
            this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, false, this._hasAnyError());
            (field === FIELD.HEIGHT) && (checkCentralSupportConditionForSnowloadAndHeight(snowLoad, value)) && (isRM10(productId) || isRM10Evolution(productId) || applyCentralSupportForRM5(projectVersion)) ? dispatch(SET_PROJECT_OPTION(centralSupportApiField, 1)) : dispatch(SET_PROJECT_OPTION(centralSupportApiField, 0));
            (field === FIELD.HEIGHT) && (disableMandatoryMidSupportCondition(value, snowLoad)) && isRMGridFlex(productId) ? dispatch(SET_PROJECT_OPTION(mandatorymidsupportApiField, 1)) : dispatch(SET_PROJECT_OPTION(mandatorymidsupportApiField, 0));
            if(field === FIELD.HEIGHT && isGFT(productId) && applyModuleLengthRestrictTo97InchForGFT(projectVersion)) {
              let railArrangementType;
              if(moduleLengthCheckForGFT(val)) {
                railArrangementType = rail_arrangement_type===railArrangementTypeOptions._5rail.value;
                top_chord===topChordOptions["old_top_chord"].value && dispatch(SET_PROJECT_OPTION(topChordApiField, topChordOptions["new_top_chord"].value));
              }
              else
                railArrangementType = rail_arrangement_type===railArrangementTypeOptions._8rail.value;
              railArrangementType && dispatch(SET_PROJECT_OPTION(railArrangementTypeApiField, railArrangementTypeOptions._6rail.value));
            }
            (selectedMfgId !== CUSTOM_MANUFACTURING_ID || selectedModelId !== CUSTOM_MODEL_ID) && this.props.switchToCustomModule(getProductName(productId));
          }
        }
        if (selectedMfgId !== CUSTOM_MANUFACTURING_ID || selectedModelId !== CUSTOM_MODEL_ID) {
          if (Number(Number(oldModel[field]).toFixed(2)) !== Number(newModel[field])) {
            this.props.switchToCustomModule(getProductName(productId));
          }
        }

        if (this._shouldConfirm(field) && !(isBlankMap(mapType) && isSingleOrientationInProject())) {
          makeAllRoofsEmpty();
        }
      }, 250);
    });

  }


  saveChangeToState = (field: string, value: number) => {
    const { moduleSelector } = this.props;
    const modelData = this.state.modelData;

    if (isNaN(value)) {
      modelData[field] = moduleSelector.modelData[field];
      this.setState({ modelData });
      return;
    }
    modelData[field] = value;
    this.setState({ modelData });
  }

  _isRM = () => {
    return isRMFamily(this.props.productId);
  }

  validateThickness = (value: number, field: fieldName) => {
    const { productId, inputUnit } = this.props;
    let valid = false;
    const valueInMm = field === FIELD.THICKNESS ? isMetricUnit(inputUnit) ? cmsToMillimeters(value) : inchesToMillimeters(value) : value;
    const minError = 'Minimum Module thickness is 30mm / 1.18in.';
    const rmError = 'Thickness should be greater than 0.';
    const rm5RmDTError = 'Module thickness should be >= 30mm / 1.18in and <= 50mm / 1.97in.';
    const rm5RmDTMetricError = 'Module thickness should be >= 3.0cm and <= 5.0Cm.';
    const rmGridflex10Error='Module thickness should be >= 30mm / 1.18in and <= 46mm / 1.80in.';
    const errMsg = () => {
      if (isRM5(productId) || isRMDT(productId)) return isMetricUnit(inputUnit) ? rm5RmDTMetricError : rm5RmDTError;
      else if (isRmGridflex10(productId)) return rmGridflex10Error
      else if (isRM10(productId) || isRM10Evolution(productId) || isRMGridFlex(productId)) return rmError
      else return minError
    }
    valid = checkThickness(milimetersToInches(valueInMm), productId, true);
    if (isSMTiltPR(productId)) {
      valid = checkSMTiltPRThickness(value, productId, false);
    }
    if (valid) {
      this._removeError(FIELD.THICKNESS_MM);
      this._removeError(FIELD.THICKNESS);
    } else {
      this._addError(field, errMsg());
    }
    if (this._hasAnyError() || !valid) {
      dispatch(OPEN_DRAWER_PAGE('module-selection'));
    }
    if (WARNING_MODULE_THICKNESSES.includes(Math.round(valueInMm)) && !(isRM5(productId) || isRMDT(productId)))
      this._addWarning(field, 'please verify module thickness')
    else {
      this._removeWarning(FIELD.THICKNESS);
      this._removeWarning(FIELD.THICKNESS_MM);
    }
  }

  validateLength = (value: number, field: fieldName) => {
    const { productId, inputUnit } = this.props;
    if (isEcoFoot2Plus(productId) || isRM5(productId) || isRmGridflex10(productId)){
      const heightError = validateLength(value, productId, inputUnit);
      if (heightError){
        this._addError(field, heightError)
      } else {
        this._removeError(field)
      }
    }
  }

  validateArea = (width: number, height: number) => {
    const { productId, user: { isStaff }, inputUnit } = this.props;

    const formError = validateModuleSize(width, height, productId, isStaff, inputUnit);
    return new Promise<void>((resolve) => {
      this.setState({
        areaError: formError,
      }, () => resolve());
    });
  }

  _shouldConfirm = (field: fieldName) => {
    const { roofsOnMap, mapType } = this.props;

    if ((field === FIELD.WIDTH || field === FIELD.HEIGHT) && isBlankMap(mapType) && isSingleOrientationInProject()) {
      return false;
    }

    if (field !== FIELD.WIDTH && field !== FIELD.HEIGHT) {
      return false;
    }

    return (
      roofsOnMap !== null &&
      Object.keys(roofsOnMap).length > 0 &&
      roofsOnMap[Object.keys(roofsOnMap)[0]].panels &&
      roofsOnMap[Object.keys(roofsOnMap)[0]].panels.length > 0
    );
  }

  textInput = (label: string, field: fieldName, value: string | number) => {
    return (
      <div className="field">
        {this.renderLabel(label, field)}
        <ConfirmationTextField
          value={value}
          onConfirm={(val) => {
            if (isNaN(Number(val))) {
              return;
            }
            if (this._shouldConfirm(field)) {
              let value = Number(val.replace(',', '.'));
              value = Number(value.toFixed(2));

              this.saveChangeToState(field, value);
              this.onChange(field, value);
            }
          }}
          onBlur={(event) => {
            let value = event.target.value.replace(',', '.');

            if (isNaN(Number(event.target.value))) {
              const { moduleSelector: { modelData } } = this.props;
              this.saveChangeToState(field, modelData[field]);
            } else if (!this._shouldConfirm(field)) {
              value = Number(value).toFixed(2);
              this.saveChangeToState(field, value);
              this.onChange(field, value);
            }
          }}
          title="Are you sure you want to change the module specification?"
          content="All array layouts will be lost."
          shouldConfirm={this._shouldConfirm(field)}
          textFieldProps={{
            lineDirection: 'center',
            type: 'text',
            fullWidth: true,
          }}
        />
        {this._hasError(field) ? this.renderError(this.state.fieldErrors[field]) : null}
        {this._hasWarning(field) ? this.renderError(this.state.fieldWarnings[field], true) : null}
      </div>);
  }

  selectInput = (label: string, field: fieldName, value: string | number) => {
    const { productId } = this.props;
    const thicknessValues = isSfmInfinity(productId) ? this.sfmInfinityThicknessValues : this.sfmThicknessValues;

    return (
      <div className="field">
        {this.renderLabel(label, field)}
        <ConfirmationSelectField
          value={value || null}
          items={thicknessValues}
          onConfirm={(val) => {
            const modelData = this.state.modelData;
            modelData[field] = val;
            this.setState({ modelData });
            this.onChange(field, val);
          }}
          title="Are you sure you want to change the module specification?"
          content="All array layouts will be lost."
          shouldConfirm={this._shouldConfirm(field)}
          selectFieldProps={{
            lineDirection: 'center',
            type: 'text',
            fullWidth: true,
            className: field,
          }}
        />
        {this._hasError(field) ? this.renderError(this.state.fieldErrors[field]) : null}
        {this._hasWarning(field) ? this.renderError(this.state.fieldWarnings[field], true) : null}

      </div>);
  }

  renderError = (message: string, isWarning?: boolean, other?: { [key in keyof React.HTMLAttributes<HTMLDivElement>] }) => {
    // we need to render html string here to support <sup></sup> in error messages
    // e.g. square feet.
    !isWarning && this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, false, true);
    return (
      <div
        className={isWarning ? "input-soft-warning" : "input-warning"}
        dangerouslySetInnerHTML={{ __html: message }}
        {...other}
      />
    );
  }

  renderLabel = (label: string, field: fieldName) => {
    return (
      <div className={classNames('input-label', { 'input-warning': this._hasError(field) })}>
        {label}
      </div>
    );
  }

  renderGridflexWidthError() {
    const { modelData: { width } } = this.state;
    const { productId } = this.props;
    if (isRMGridFlex(productId) && width > _width_range.rm_gridflex['0.0.1'].maxWidth){
      this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, false, true);
      return this.renderError(`Warning: module width exceeds for Gridflex system, please contact Unirac for design.`);
    }
    else
      return false;
  }

  renderArea = () => {
    const { width, height } = this.state.modelData;
    const { inputUnit } = this.props;
    let area = inchesToFeet(width) * inchesToFeet(height);
    let units = 'ft';
    if(isAscender(this.props.productId) || isMetricUnit(inputUnit)) {
      area = cmsToMeters(width) * cmsToMeters(height);
      units = 'm'
    }
    return (
      <div id="custom-module-area" className={classNames('input-label area', { 'input-warning': this._hasAreaError() })}>
        Area: {area.toFixed(2)} ({units}<sup>2</sup>)
      </div>
    );
  }

  renderFields = () => {
    const { modelData: { watts, width, height, thickness, thicknessmm, weight } } = this.state;
    if (isSFMFamily(this.props.productId)) {
      return (
        <div className="fields">
          {this.textInput('OUTPUT (W)', FIELD.WATTS, watts || 0)}
          {this.textInput('WIDTH (IN)', FIELD.WIDTH, width || 0)}
          {this.textInput('LENGTH (IN)', FIELD.HEIGHT, height || 0)}
          {this.selectInput('THICKNESS (MM)', FIELD.THICKNESS_MM, Math.round(thicknessmm) || 0)}
          {this.textInput('WEIGHT (LBS)', FIELD.WEIGHT, weight || 0)}
        </div>
      );
    }

    if (isMetalX(this.props.productId)) {
      return (
        <div className="fields">
          {this.textInput('OUTPUT (W)', FIELD.WATTS, watts || 0)}
          {this.textInput('WIDTH (IN)', FIELD.WIDTH, width || 0)}
          {this.textInput('LENGTH (IN)', FIELD.HEIGHT, height || 0)}
          {this.textInput('THICKNESS (IN)', FIELD.THICKNESS, Number(thickness || 0) >= 1.18 ? Number(thickness || 1.18).toFixed(2) : Number(1.18).toFixed(2))}
          {this.textInput('WEIGHT (LBS)', FIELD.WEIGHT, weight || 0)}
          {this.textInput('THICKNESS (MM)', FIELD.THICKNESS_MM, Number(thicknessmm || 0) >= 30.00 ? Number(thicknessmm || 30.00).toFixed(0) : Number(30.00).toFixed(0))}
        </div>
      );
    }

    if(isAscender(this.props.productId) || isMetricUnit(this.props.inputUnit)) {
      return (
        <div className="fields">
          {this.textInput('OUTPUT (W)', FIELD.WATTS, watts || 0)}
          {this.textInput('WIDTH (CM)', FIELD.WIDTH, Number(width || 0).toFixed(1) || 0)}
          {this.textInput('LENGTH (CM)', FIELD.HEIGHT, Number(height || 0).toFixed(1) || 0)}
          {this.textInput('THICKNESS (CM)', FIELD.THICKNESS, Number(thickness || 0).toFixed(1))}
          {this.textInput('WEIGHT (KGS)', FIELD.WEIGHT, Number(weight || 0).toFixed(1))}
        </div>
      );
    }

    return (
      <div className="fields">
        {this.textInput('OUTPUT (W)', FIELD.WATTS, watts || 0)}
        {this.textInput('WIDTH (IN)', FIELD.WIDTH, width || 0)}
        {this.textInput('LENGTH (IN)', FIELD.HEIGHT, height || 0)}
        {this.textInput('THICKNESS (IN)', FIELD.THICKNESS, Number(thickness || 0).toFixed(2))}
        {this.textInput('WEIGHT (LBS)', FIELD.WEIGHT, weight || 0)}
        {this.textInput('THICKNESS (MM)', FIELD.THICKNESS_MM, Number(thicknessmm || 0).toFixed(0))}
      </div>
    );
  }

  render() {
    return (
      <div className="drawer-fields custom-module-selector">
        <div className="fields">
          {this.renderFields()}
        </div>
        {this.renderArea()}
        {this._hasAreaError() ? this.renderError(this.state.areaError, false, { id: 'custom-module-area-error' }) : null}
        {this.renderGridflexWidthError()}
      </div>
    );
  }
}


function mapDispatchToProps(dispatch: Function) {
  return {
    switchToCustomModule: (productId: string) => dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, CUSTOM_MANUFACTURING_ID, CUSTOM_MODEL_ID)),
    setCustomValue: (field: string, value: number) => dispatch(SET_CUSTOM_VALUE(field, value)),
    setFlyoutVerified: (flyout: string, value: boolean, error?: boolean) => dispatch(SET_FLYOUT_VERIFIED(flyout, value, error)),
    resizePanels: (oldModel: modelData, newModel: modelData, productId: number) => dispatch(RESIZE_PANELS(oldModel, newModel, productId)),
    replacePanelsOnRoof: (panels: panelInState[], roofId: number) => dispatch(REPLACE_PANELS_ON_ROOF(panels, roofId)),
    setModelDataError: (hasError: boolean) => dispatch(SET_MODEL_DATA_HAS_ERROR(hasError)),
  };
}


function mapStateToProps(state: appState) {
  return {
    moduleSelector: state.moduleSelector,
    roofsOnMap: state.drawingManager.roofs,
    productId: state.projectConfiguration.productId,
    projectVersion: state.projectConfiguration.projectVersion,
    snowLoad: Number(state.projectConfiguration.projectEnvConfig.snow_load),
    mapType: state.roofsSelector.mapType,
    editorCursor: state.editorCursor,
    user: state.user,
    inputUnit: state.projectConfiguration.inputUnit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomModuleSelector);
