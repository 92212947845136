import Leaflet from 'leaflet';
import { dispatch, state } from '__common/store';
import { getBingMap, getBingZipCode } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { getMap as getGoogleMap, getGoogleZipCode } from '__editor/googleMapsRoofsSelector/components/map/map';
import { removePlaceBingMarker } from '../../../../bingMapsRoofsSelector/components/bingMapsRoofsSelectorLocationSearch/bingMapsRoofsSelectorLocationSearch';
import { SET_LOCATION, SET_MAP_CENTER } from 'actions';
import { getAreaByZip } from './utils/getAreaByZip';
import { SET_CITY_NAME, SET_PROJECT_OPTION } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import { isGoogleMap, isBlankMap, isBingMap } from '__common/constants/map';

export let skipZipcodeFetch = false;

export const shouldSkipFetchZipcode = () => skipZipcodeFetch;
export const setShouldSkipFetZipCode = (val: boolean) => skipZipcodeFetch = val;

export function setMapCenterFromGoogleMaps(coords: google.maps.LatLng) {
  const lat = coords.lat();
  const lng = coords.lng();
  dispatch(SET_MAP_CENTER({ lat, lng }));
}

export function setMapCenterFromBingMaps(coords: { lat: number, lng: number }) {
  const { lat, lng } = coords;
  dispatch(SET_MAP_CENTER({ lat, lng }));
}

export function generateNewCenterForMap() {
  const { roofsSelector: { mapType }, roofsSelectorMap: { mapCenterCoords } } = state();

  if (isGoogleMap(mapType) || isBingMap(mapType)) {
    return mapCenterCoords;
  }

  if (isBlankMap(mapType)) {
    return { lat: 34.051535, lng: -118.24168 };
  }
}

export function getMapCenter() {
  const { roofsSelector: { mapType } } = state();

  if (isGoogleMap(mapType)) {
    const { lat, lng } = getGoogleMap().getCenter();
    return {
      lat: lat(),
      lng: lng(),
    };
  }

  return getBingMap().getCenter();
}

export function getCurrentMap(): any {
  const { roofsSelector: { mapType } } = state();

  if (isGoogleMap(mapType)) {
    return getGoogleMap();
  }

  return getBingMap();
}

export function setCenterForCurrentMap(lat: number, lng: number) {
  const { roofsSelector: { mapType } } = state();
  const map = getCurrentMap();

  dispatch(SET_MAP_CENTER({ lat, lng }));
  setShouldSkipFetZipCode(true);

  if (map && isGoogleMap(mapType)) {
    return map.setCenter({ lat, lng });
  }

  if (map) {
    return map.panTo(new Leaflet.LatLng(lat, lng));
  }

}

export function updateMapLocation(dispatch: Function, zipCode: string) {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: zipCode }, (result, status) => {
    if (result && result.length) {
      for (const component in result[0]['address_components']) {
        for (const i in result[0]['address_components'][component]['types']) {
          if (
            result[0]['address_components'][component]['types'][i] ===
            'administrative_area_level_1'
          ) {
            const postalCode = result[0].address_components.find((component) => component.types.includes('postal_code'));
            if (postalCode && postalCode.short_name === zipCode || postalCode.long_name === zipCode) {
              const { geometry: { location: { lat, lng } } } = result[0];
              setCenterForCurrentMap(lat(), lng());
            }
          }
        }
      }
    }
    if (result && (result[0] && result[0].formatted_address)) {
      dispatch(SET_LOCATION(result[0].formatted_address));
    }
  });
}

export function removePlaceMarker() {
  const { roofsSelector: { mapType } } = state();

  if (isGoogleMap(mapType)) {
    removePlaceMarker();
  }

  removePlaceBingMarker();
}

export const getZipCode = (preview?: boolean) => {
  if (preview) {
    return;
  }
  const { projectConfiguration: { projectEnvConfig }, userPreferences, roofsSelector: { mapType } } = state();

  const getZipCode = isGoogleMap(mapType) ? getGoogleZipCode : getBingZipCode;

  if (projectEnvConfig && projectEnvConfig.zipcode && projectEnvConfig.zipcode.length === 0 && userPreferences.zipcode && userPreferences.zipcode.length) {
    getAreaByZip(userPreferences.zipcode, (data) => {
      const { lat, lng, address, state, city } = data;

      if (!lat && !lng && address.length === 0 && state.length === 0 && city.length === 0) {
        getZipCode();
      }

      dispatch(SET_PROJECT_OPTION('zipcode', userPreferences.zipcode));
      dispatch(SET_LOCATION(address));
      setCenterForCurrentMap(lat, lng);
      dispatch(SET_CITY_NAME(`${city}, ${state}`));
    });
  } else {
    getZipCode();
  }
};
