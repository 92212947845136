import { createReducer } from '__common/utils/helpers';
import { RoofZonesActionTypes } from 'actionsConstants';

export const roofZonesState: roofZonesState = {
  windZone: false,
  expZone: false,
  restrictedZone: false,
  obstructionsZone: false,
  skewZone: false,
  skewAffectedModules: false,
};

export default {
  roofZones: createReducer(roofZonesState, {
    [RoofZonesActionTypes.TOGGLE_WIND_ZONE](state) {
      const { windZone } = state;
      return { ...state, windZone: !windZone };
    },
    [RoofZonesActionTypes.TOGGLE_EXP_ZONE](state) {
      const { expZone } = state;
      return { ...state, expZone: !expZone };
    },
    [RoofZonesActionTypes.TOGGLE_RESTRICTED_ZONE](state) {
      const { restrictedZone } = state;
      return { ...state, restrictedZone: !restrictedZone };
    },
    [RoofZonesActionTypes.TOGGLE_OBSTRUCTIONS_ZONE](state) {
      const { obstructionsZone } = state;
      return { ...state, obstructionsZone: !obstructionsZone };
    },
    [RoofZonesActionTypes.TOGGLE_SKEW_ZONE](state) {
      const { skewZone } = state;
      return { ...state, skewZone: !skewZone };
    },
    [RoofZonesActionTypes.TOGGLE_SKEW_AFFECTED_MODULES](state) {
      const { skewAffectedModules } = state;
      return { ...state, skewAffectedModules: !skewAffectedModules };
    },
  }),
};
