import nprogress from 'nprogress';
import { BomActionTypes } from 'actionsConstants';
import { parseAttachmentsList } from 'bom/bomHelpers';
import { showSuccessAlert } from '__common/modules/alerts';
import { saveAs } from 'file-saver';
import { dispatch } from '__common/store';


export function FETCH_BOM_PART_LIST_REQUEST(
  projectId: string,
  productId: string,
) {
  return {
    type: BomActionTypes.FETCH_BOM_PART_LIST_REQUEST,
    payload: {
      projectId,
      productId,
    },
  };
}
export function FETCH_SCREENSHOT_REQUEST(
  projectId: string,
  data: any
) {
  return {
    type: BomActionTypes.FETCH_SCREENSHOT_REQUEST,
    payload: {
      projectId,
    },
  };
}
export function FETCH_SCREENSHOT_SUCCESS(
  projectId: string,
) {
  return {
    type: BomActionTypes.FETCH_SCREENSHOT_SUCCESS,
    payload: {
      projectId,
    },
  };
}
export function FETCH_BOM_PART_LIST_ERROR(ajaxError: any) {
  return {
    type: BomActionTypes.FETCH_BOM_PART_LIST_ERROR,
    payload: {
      ajaxError,
    },
  };
}

export function FETCH_BOM_PART_LIST_SUCCESS(
  productId: string,
  data: {
    parts_list: bomPartsList[];
    accessories_list: bomPartsList[];
    roof_attachments: any;
    tile_replacement_parts: tileReplacementList;
    extra_parameters: extraParameters;
    total_modules: number;
    total_watts: number;
    custom_title: string;
    reload_selections: boolean;
    created: boolean;
    engineering_layout_flags: any;
    warnings_info: warningsInfo;
  },
) {
  return {
    type: BomActionTypes.FETCH_BOM_PART_LIST_SUCCESS,
    payload: {
      partsList: data.parts_list || [],
      attachmentsList: parseAttachmentsList(productId, data.roof_attachments),
      tileReplacementList: data.tile_replacement_parts || {},
      accessoriesList: data.accessories_list || [],
      extraParameters: data.extra_parameters,
      total_modules: data.total_modules,
      total_watts: data.total_watts,
      project_name: data.custom_title,
      reload_selections: data.reload_selections,
      created: data.created,
      engineering_layout_flags: data.engineering_layout_flags,
      warningsInfo: data.warnings_info,
    },
  };
}

export function BOM_RESET() {
  return {
    type: BomActionTypes.BOM_RESET,
  };
}

export function SET_NEW_PART_QUANTITY(partNumber: string, newQty: number) {
  return {
    type: BomActionTypes.SET_NEW_PART_QUANTITY,
    payload: {
      partNumber,
      newQty,
    },
  };
}

export function ADD_PART_TO_BOM(part: bomPart) {
  return {
    type: BomActionTypes.ADD_PART_TO_BOM,
    payload: {
      part,
    },
  };
}

export function ADD_RM10_MODULE_AND_HEX_BOLT(qty: number, parts: bomPart[]) {
  return {
    type: BomActionTypes.ADD_RM10_MODULE_AND_HEX_BOLT,
    payload: {
      parts,
      qty,
    },
  };
}

export function ADD_RM10_EVO_MODULE_CLAMP_KIT(qty: number, parts: bomPart[]) {
  return {
    type: BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_KIT,
    payload: {
      parts,
      qty,
    },
  };
}

export function REMOVE_PART_FROM_BOM(partNumber: string) {
  return {
    type: BomActionTypes.REMOVE_PART_FROM_BOM,
    payload: {
      partNumber,
    },
  };
}

export function SELECT_TILE_REPLACEMENT_TYPE(group: string, selectedGroupItem: string) {
  return {
    type: BomActionTypes.SELECT_TILE_REPLACEMENT_TYPE,
    payload: {
      group,
      selectedGroupItem,
    },
  };
}

export function ADD_TILE_REPLACEMENT_ITEM(group: string, selectedGroupItem: string) {
  return {
    type: BomActionTypes.ADD_TILE_REPLACEMENT_ITEM,
    payload: {
      group,
      selectedGroupItem,
    },
  };
}

export function REMOVE_TILE_REPLACEMENT_ITEM(group: string, selectedGroupItem: string) {
  return {
    type: BomActionTypes.REMOVE_TILE_REPLACEMENT_ITEM,
    payload: {
      group,
      selectedGroupItem,
    },
  };
}

export function SELECT_ROOF_ATTACHMENT_TYPE(group: string, selectedGroupItem: string) {
  return {
    type: BomActionTypes.SELECT_ROOF_ATTACHMENT_TYPE,
    payload: {
      group,
      selectedGroupItem,
    },
  };
}

export function ADD_ATTACHMENT_ITEM(group: string, selectedGroupItem: string) {
  return {
    type: BomActionTypes.ADD_ATTACHMENT_ITEM,
    payload: {
      group,
      selectedGroupItem,
    },
  };
}

export function REMOVE_ATTACHMENT_ITEM(group: string, selectedGroupItem: string) {
  return {
    type: BomActionTypes.REMOVE_ATTACHMENT_ITEM,
    payload: {
      group,
      selectedGroupItem,
    },
  };
}

export function SELECT_ATTACHMENT_TYPE(attachmentType: string) {
  return {
    type: BomActionTypes.SELECT_ATTACHMENT_TYPE,
    payload: {
      attachmentType,
    },
  };
}

export function SELECT_ATTACHMENT(
  attachmentGroup: string,
  attachmentNumber: string,
) {
  return {
    type: BomActionTypes.SELECT_ATTACHMENT,
    payload: {
      attachmentGroup,
      attachmentNumber,
    },
  };
}

export function ADD_ATTACHMENT_TO_BOM(attachment: bomPart) {
  return {
    type: BomActionTypes.ADD_ATTACHMENT_TO_BOM,
    payload: {
      attachment,
    },
  };
}

export function UNSELECT_LAG_BOLTS() {
  return {
    type: BomActionTypes.UNSELECT_LAG_BOLTS,
  };
}

export function SELECT_LAG_BOLTS() {
  return {
    type: BomActionTypes.SELECT_LAG_BOLTS,
  };
}

export function DISABLE_LAG_BOLTS() {
  return {
    type: BomActionTypes.DISABLE_LAG_BOLTS,
  };
}

export function ENABLE_LAG_BOLTS() {
  return {
    type: BomActionTypes.ENABLE_LAG_BOLTS,
  };
}
export function ENABLE_PART(partNumber: string) {
  return {
    type: BomActionTypes.ENABLE_PART,
    payload: {
      partNumber,
    },
  };
}

export function DISABLE_PART(partNumber: string) {
  return {
    type: BomActionTypes.DISABLE_PART,
    payload: {
      partNumber,
    },
  };
}

export function FETCH_STANDARD_CLAMPS(projectId: string) {
  return {
    type: BomActionTypes.FETCH_STANDARD_CLAMPS,
    payload: {
      projectId,
    },
  };
}

export function FETCH_PRO_CLAMPS(projectId: string) {
  return {
    type: BomActionTypes.FETCH_PRO_CLAMPS,
    payload: {
      projectId,
    },
  };
}

export function ADD_CLAMPS(data: {
  midclamps: bomPart[];
  endclamps: bomPart[];
}) {
  return {
    type: BomActionTypes.ADD_CLAMPS,
    payload: {
      midclamps: data.midclamps,
      endclamps: data.endclamps,
    },
  };
}

export function ADD_NXT_CLAMPS(data: {
  clamps: bomPart[];
}) {
  return {
    type: BomActionTypes.ADD_NXT_CLAMPS,
    payload: {
      clamps: data.clamps,
    },
  };
}

export function FETCH_FRONT_TRIM_END_CAP(
  productId: number,
  continuous_trims_sections: number,
  projectId: string,
  front_trim_quantity: number,
  fetch_front_trim_cap_or_not : boolean,
) {
  return {
    type: BomActionTypes.FETCH_FRONT_TRIM_END_CAP,
    payload: {
      productId,
      continuous_trims_sections,
      projectId,
      front_trim_quantity,
      fetch_front_trim_cap_or_not,
    },
  };
}

export function ADD_FRONT_TRIM_END_CAP(data: {
  clips: void[];
  midclamps: bomPart[];
  endclamps: bomPart[];
}) {
  return {
    type: BomActionTypes.ADD_FRONT_TRIM_END_CAP,
    payload: {
      clips: data.clips[0],
      midclamps : data.midclamps[0],
      endclamps : data.endclamps[0],
    },
  };
}

export function REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY(data: {
  midclamps: bomPart[];
  endclamps: bomPart[];
}) {
  return {
    type: BomActionTypes.REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY,
    payload: {
      midclamps : data.midclamps[0],
      endclamps : data.endclamps[0],
    },
  };
}

export function REMOVE_FRONT_TRIM_END_CAP(
  productId: number,
  continuous_trims_sections: number,
  projectId: string,
  front_trim_quantity: number,
) {
  return {
    type: BomActionTypes.REMOVE_FRONT_TRIM_END_CAP,
    payload: {
      productId,
      continuous_trims_sections,
      projectId,
      front_trim_quantity,
    },
  };
}

export function CHANGE_BOM_COLOR_VARIANT(
  partNrToReplace: string,
  newPart: bomPart,
) {
  return {
    type: BomActionTypes.CHANGE_BOM_COLOR_VARIANT,
    payload: {
      partNrToReplace,
      newPart,
    },
  };
}

export function SET_BOM_PK(bomPk: string) {
  return {
    type: BomActionTypes.SET_BOM_PK,
    payload: {
      bomPk,
    },
  };
}

export function SAVE_BOM(
  productId: string,
  projectId: string,
  bomSaveRequestBody: bomSaveRequestBody,
  dontRedirect?: boolean,
) {
  return {
    type: BomActionTypes.SAVE_BOM,
    payload: {
      productId,
      projectId,
      bomSaveRequestBody,
      dontRedirect,
    },
  };
}

export function AUTO_SAVE_BOM(
  productId: string,
  projectId: string,
  bomSaveRequestBody: bomSaveRequestBody,
  dontRedirect?: boolean,
) {
  return {
    type: BomActionTypes.AUTO_SAVE_BOM,
    payload: {
      productId,
      projectId,
      bomSaveRequestBody,
      dontRedirect,
    },
  };
}

export function SAVE_BOM_AND_DOWNLOAD_BOM_PDF(
  productId: string,
  projectId: string,
  bomSaveRequestBody: bomSaveRequestBody,
  dontRedirect?: boolean,
) {
  return {
    type: BomActionTypes.SAVE_BOM_AND_DOWNLOAD_BOM_PDF,
    payload: {
      productId,
      projectId,
      bomSaveRequestBody,
      dontRedirect,
    },
  };
}

export function SEND_BOM_DATA_TO_AURORA(
  projectId: string,
) {
  nprogress.start();
  return {
    type: BomActionTypes.SEND_BOM_DATA_TO_AURORA,
    payload: {
      projectId,
    },
  };
}

export function SEND_BOM_DATA_TO_AURORA_SUCCESS(data) {
  showSuccessAlert("Sucessfully sent the BOM data");
  nprogress.done();
  return {
    type: BomActionTypes.SEND_BOM_DATA_TO_AURORA_SUCCESS,
  };
}

export function CLEAR_BOM_PK() {
  return {
    type: BomActionTypes.CLEAR_BOM_PK,
  };
}

export function SELECT_ATTACHMENT_TYPE_ON_LOAD(attachmentType: string) {
  return {
    type: BomActionTypes.SELECT_ATTACHMENT_TYPE_ON_LOAD,
    payload: {
      attachmentType,
    },
  };
}

export function SET_BOM_PRODUCT(productId, projectId) {
  return {
    type: BomActionTypes.SET_BOM_PRODUCT,
    payload: {
      productId,
      projectId,
    },
  };
}

export function ADD_RAILS_TO_BOM(rails: bomPartsList) {
  return {
    type: BomActionTypes.ADD_RAILS_TO_BOM,
    payload: {
      rails,
    },
  };
}

export function FETCH_RM10_MODULE_AND_HEX_BOLT(projectId: string, qty: number) {
  return {
    type: BomActionTypes.FETCH_RM10_MODULE_AND_HEX_BOLT,
    payload: {
      projectId,
      qty,
    },
  };
}

export function FETCH_RM10_EVO_MODULE_CLAMP_KIT(projectId: string, qty: number) {
  return {
    type: BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_KIT,
    payload: {
      projectId,
      qty,
    },
  };
}

export function ADD_TILE_HOOK() {
  return {
    type: BomActionTypes.ADD_TILE_HOOK,
  };
}

export function REMOVE_TILE_HOOK() {
  return {
    type: BomActionTypes.REMOVE_TILE_HOOK,
  };
}

export function ADD_LFOOT() {
  return {
    type: BomActionTypes.ADD_LFOOT,
  };
}

export function REMOVE_LFOOT() {
  return {
    type: BomActionTypes.REMOVE_LFOOT,
  };
}

export function ADD_FLASHKIT_PRO() {
  return {
    type: BomActionTypes.ADD_FLASHKIT_PRO,
  };
}

export function REMOVE_FLASHKIT_PRO() {
  return {
    type: BomActionTypes.REMOVE_FLASHKIT_PRO,
  };
}

export function ADD_FLASHLOC_COMP_KIT() {
  return {
    type: BomActionTypes.ADD_FLASHLOC_COMP_KIT,
  };
}

export function REMOVE_FLASHLOC_COMP_KIT() {
  return {
    type: BomActionTypes.REMOVE_FLASHLOC_COMP_KIT,
  };
}

export function SELECT_ACCESSORY(partNumber: string) {
  return {
    type: BomActionTypes.SELECT_ACCESSORY,
    payload: {
      partNumber,
    },
  };
}

export function UNSELECT_ACCESSORY(partNumber: string) {
  return {
    type: BomActionTypes.UNSELECT_ACCESSORY,
    payload: {
      partNumber,
    },
  };
}

export function BOM_SELECTION_LOADING_START() {
  return {
    type: BomActionTypes.BOM_SELECTION_LOADING_START,
  };
}

export function BOM_SELECTION_LOADING_STOP() {
  return {
    type: BomActionTypes.BOM_SELECTION_LOADING_STOP,
  };
}

export function GO_TO_ENGINEERING_FROM_BOM() {
  return {
    type: BomActionTypes.GO_TO_ENGINEERING_FROM_BOM,
  };
}

export function DONT_GO_TO_ENGINEERING_FROM_BOM() {
  return {
    type: BomActionTypes.DONT_GO_TO_ENGINEERING_FROM_BOM,
  };
}

export function REMOVE_PART_FROM_REMEMBERED_QTYS(ids: string[]) {
  return {
    type: BomActionTypes.REMOVE_PART_FROM_REMEMBERED_QTYS,
    payload: {
      ids,
    },
  };
}

export function BOM_CHANGED() {
  return {
    type: BomActionTypes.BOM_CHANGED,
  };
}

export function UPDATE_QUANTITY(accessory: bomPart) {
  return {
    type: BomActionTypes.UPDATE_QUANTITY,
    payload: {
      accessory
    },
  };
}

export function CLAMPS_CHANGED(
  productId: string,
  data: {
    parts_list: bomPartsList[];
    accessories_list: bomPartsList[];
    roof_attachments: any;
    extra_parameters: extraParameters;
    total_modules: number;
    total_watts: number;
    custom_title: string;
    reload_selections: boolean;
    created: boolean;
  },
) {
  return {
    type: BomActionTypes.CLAMPS_CHANGED,
    payload: {
      clampsChanged: data.extra_parameters.clamps_changed,
    },
  };
}

export function ADD_STRONGHOLD_ATT_COMP_NON_SEALANT() {
  return {
    type: BomActionTypes.ADD_STRONGHOLD_ATT_COMP_NON_SEALANT,
  }
}

export function REMOVE_STRONGHOLD_ATT_COMP_NON_SEALANT() {
  return {
    type: BomActionTypes.REMOVE_STRONGHOLD_ATT_COMP_NON_SEALANT,
  }
}

export function ADD_STRONGHOLD_ATT_KIT_COMP() {
  return {
    type : BomActionTypes.ADD_STRONGHOLD_ATT_KIT_COMP
  }
}

export function REMOVE_STRONGHOLD_ATT_KIT_COMP() {
  return {
    type : BomActionTypes.REMOVE_STRONGHOLD_ATT_KIT_COMP
  }
}



export function ADD_FLASHKIT_PRO_SB() {
  return {
    type: BomActionTypes.ADD_FLASHKIT_PRO_SB,
  };
}

export function REMOVE_FLASHKIT_PRO_SB() {
  return {
    type: BomActionTypes.REMOVE_FLASHKIT_PRO_SB,
  };
}

export function ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK(qty: number, parts: bomPart[]) {
  return {
    type: BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK,
    payload: {
      parts,
      qty,
    },
  };
}

export function ADD_RM10_EVO_MODULE_CLAMP_BULK(qty: number, parts: bomPart[]) {
  return {
    type: BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_BULK,
    payload: {
      parts,
      qty,
    },
  };
}

export function FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK(projectId: string, qty: number) {
  return {
    type: BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK,
    payload: {
      projectId,
      qty,
    },
  };
}

export function FETCH_RM10_EVO_MODULE_CLAMP_BULK(projectId: string, qty: number) {
  return {
    type: BomActionTypes.FETCH_RM10_EVO_MODULE_CLAMP_BULK,
    payload: {
      projectId,
      qty,
    },
  };
}

export function ADD_T_BOLTS_009020S_FOR_SOLARHOOKS(qty: number, parts: bomPart[]) {
  return {
    type: BomActionTypes.ADD_T_BOLTS_009020S_FOR_SOLARHOOKS,
    payload: {
      parts,
      qty,
    },
  };
}

export function FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS(projectId: string, qty: number) {
  return {
    type: BomActionTypes.FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS,
    payload: {
      projectId,
      qty,
    },
  };
}

export function SET_AURORA_REPORTS_DATA_URLS(data) {
  const blob = new Blob([data], { type: "application/pdf" });
  const auroraReportsDataUrl= window.URL.createObjectURL(blob);
 // window.open(url, '_blank').focus();
  return {
    type: BomActionTypes.SET_AURORA_REPORTS_DATA_URLS,
    payload: {
      auroraReportsDataUrl,
    },
  };
}

export function SET_AURORA_LOADER() {
  return {
    type: BomActionTypes.SET_AURORA_LOADER,
  };
}

export function CLEAR_AURORA_LOADER() {
  return {
    type: BomActionTypes.CLEAR_AURORA_LOADER,
  };
}

export function SET_AURORA_ERROR(errorMessages: string[]) {
  return {
    type: BomActionTypes.SET_AURORA_ERROR,
    payload: {
      errorMessages,
    },
  };
}

export function CLEAR_AURORA_ERROR() {
  return {
    type: BomActionTypes.CLEAR_AURORA_ERROR,
  };
}

export function SAVE_BOM_FOR_AURORA(productId: string,
  projectId: string,
  bomSaveRequestBody: bomSaveRequestBody,
  dontRedirect?: boolean) {
  return {
    type: BomActionTypes.SAVE_BOM_FOR_AURORA,
    payload: {
      productId,
      projectId,
      bomSaveRequestBody,
      dontRedirect,
    },
  };
}

export function HIDE_MODAL_FOR_AURORA_REPORTS() {
  return {
    type: BomActionTypes.HIDE_MODAL_FOR_AURORA_REPORTS,
  };
}

export function ENABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA() {
  return {
    type: BomActionTypes.ENABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA,
  };
}

export function DISABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA() {
  return {
    type: BomActionTypes.DISABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA,
  };
}
