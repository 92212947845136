export const apiField = 'foundation_screw_length';

const config = {
  type: 'Select',
  apiField,
  label: 'foundation_screw_length',
  data: [
    { 
      value: 1600,
      name: '1600',
    },
    {
      value: 2000,
      name: '2000',
    },
  ],
};

function makeConfig(): any{
  return {
  type: 'Select',
  apiField,
  label: 'foundation_screw_length',
  data: [
    { 
      value: 1600,
      name: '1600',
    },
    {
      value: 2100,
      name: '2100',
    },
  ],
}
}
export default config;

export const foundationScrewLengthKrinner=makeConfig();