import React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { MOVE_ARRAY_MODE_DISABLE, MOVE_MAP_MODE_ENABLE, TOGGLE_ATTACHMENTS_DISABLE, TURN_OFF_MEASUREMENT_TOOL } from 'actions';
import { TERMINATE_ADVANCE_ROOF_SELECTING } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { RESTORE_PANELS_FOR_NO_MOVEMENT } from '../../panels/panelsActions';

type Props = {
  moveMapEnabled: boolean,
  moveArrayEnabled: boolean,
  enableMapMove: () => void,
  disableArrayMove: () => void,
  terminateDrawObstructions: () => void,
  disableToggleAttachment: () => void,
  disableMeasurementTool: () => void;
};

class MoveMap extends React.Component<Props> {
  onMoveMapHandler = () => {
    this.props.terminateDrawObstructions();
    if(this.props.moveArrayEnabled) {
      this.props.disableArrayMove();
    }
    this.props.disableToggleAttachment();
    this.props.disableMeasurementTool();
    return this.props.enableMapMove();
  }

  render() {
    const { moveMapEnabled} = this.props;
    return (
      <Button onClick={this.onMoveMapHandler} className={moveMapEnabled ? 'roofs-selector-control-panel-active roofs-selector-button' : 'roofs-selector-button'} flat={true} tooltipLabel="Move map">
        <SVG src={require('assets/media/icons/icon_move.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    moveMapEnabled: appState.background.moveMapMode,
    moveArrayEnabled: appState.background.moveArrayMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    enableMapMove: () => {
      dispatch(MOVE_MAP_MODE_ENABLE());
    },
    terminateDrawObstructions: () => {
      dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    },
    disableToggleAttachment: () => {
      dispatch(TOGGLE_ATTACHMENTS_DISABLE());
    },
    disableArrayMove: () => {
      dispatch(MOVE_ARRAY_MODE_DISABLE());
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
    },
    disableMeasurementTool: () => dispatch(TURN_OFF_MEASUREMENT_TOOL()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveMap);
