import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import Icon from '__common/components/Icon';
import Loader from '__common/components/LoaderComponent';
import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import { connect } from 'react-redux';
import { RESET_PASSWORD } from 'actions';

type State = {
  selection: string,
};

type Props = {
  dispatch: Function,
  resetPassword: resetPasswordState,
};

class ResetPassword extends React.Component<Props, State> {
  state = {
    selection: 'yes',
  };

  resetPasswordHandler = () => {
    const { dispatch } = this.props;
    dispatch(RESET_PASSWORD());
  }

  form() {
    return (
      <>
        <div className="maintitle">RESET PASSWORD</div>
        <SelectionControlGroup
          id="selection-control-group-radios"
          name="radio-example"
          type="radio"
          checkedRadioIcon={(<Icon>check</Icon>)}
          uncheckedRadioIcon={(<Icon>check</Icon>)}
          inline={true}
          defaultValue="yes"
          controls={[
            {
              label: 'YES',
              value: 'yes',
            },
            {
              label: 'NO',
              value: 'no',
            },
          ]}
        />
     
            <Button
              flat={true}
              type="button"
              className="form_button"
              id="login_button"
              onClick={() => {
                this.resetPasswordHandler();
              }
              }
            >
              RESET
            </Button>
          </>
    );
  }

  successfullyReset = () => {
    return (
      <>
      We send reset link on your email
    </>
    );
  }

  content = () => {
    const { resetPassword: { error, sending, success } } = this.props;

    if (error) {
      return(<div>Cannot reset password</div>);
    }

    if (sending) {
      return(<Loader />);
    }

    if (success) {
      return (
        this.successfullyReset()
      );
    }

    return this.form();
  }

  render() {
    return (
      <div className="app-content reset-password">
        <Header />
        <HeaderImage content={this.content()} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    resetPassword: state.resetPassword,
  };
}

export default connect(mapStateToProps)(ResetPassword);
