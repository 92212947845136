import * as React from 'react';
import autobind from 'autobind-decorator';
import store from '__common/store';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { LOAD_PANELS_FROM_HISTORY, UNDO_HISTORY } from '__editor/panelsEditor/actions';
import { DEBUG } from 'debug';

class Undo extends React.Component<onlyDispatchComponentState, {}> {
  @autobind
  action() {
    const { dispatch } = this.props;
    dispatch(UNDO_HISTORY());
    const { history } = store.getState();
    dispatch(LOAD_PANELS_FROM_HISTORY(history.panelsSnapshot[history.index]));
  }

  render() {

    // hidden before release, will be fixed later

    if (!DEBUG.enablePanelsUndo) {
      return null;
    }
    
    return (
      <Button flat={true} onClick={this.action} className="roofs-selector-button" tooltipLabel="undo">
        <SVG src={require('assets/media/icons/icon_undo.svg')} />
      </Button>
    );
  }
}

export default connect()(Undo);
