import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';

export default {
  type: 'Select',
  label: 'parapetHeight',
  apiField: 'parapet_height',
  tooltip: parapetHeightTooltip,
  data: [
    {
      value: 1,
      name: '<= 1/2 ARRAY HEIGHT (<=5 INCHES)',
    },
    {
      value: 2,
      name: '<= 1 ARRAY HEIGHT (<= 10 INCHES)',
    },
    {
      value: 3,
      name: '> 1 ARRAY HEIGHT (> 10 INCHES)',
    },
  ],
};
