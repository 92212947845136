import { ModuleBits, ModuleBitsReturnData } from "./baseTypes";
import { EDGES_TYPE } from "__editor/panelsEditor/components/roofZones/utils/edgesType";
interface Flags {
    isRidge: 0 | 1;
    isEave: 0 | 1;
    isDeepInterior: 0 | 1;
    isOnCorner: 0 | 1;
    isOnEdge: 0 | 1;
  }
  
  interface DecodedConfig {
    roofZone: number;
    edgeType: EDGES_TYPE;
  }

export class AscenderModuleBits extends ModuleBits {
    private static fieldsLength = 8;
  
  constructor(private buildingType: number, private roofPitchDegrees: number = 0) {
    super();
  }
  fromBits(bitData: number, roofZoneId?: number): ModuleBitsReturnData {
    const bitFlags: (0|1)[] = [];
    let shiftedBitData: number = bitData;
    for (let i = 0; i < AscenderModuleBits.fieldsLength; i++) {
      bitFlags.push(this.booleanToBit(this.bitToBoolean(shiftedBitData & 1)));
      shiftedBitData = shiftedBitData >> 1;
    }
    const [
        isLandscape,
        isOnEdge, 
        isOnCorner, 
        isEave, 
        isRidge, 
        isDeepInterior, 
        isNeighborExposed, 
        isPanelExposed, 
      ] = bitFlags;
  
      const flags: Flags = {
        isDeepInterior,
        isEave,
        isOnCorner,
        isOnEdge, 
        isRidge,
      };
  
    let config: DecodedConfig;
    config = this.decodeRoof(flags);

    return {
      exposed: this.bitToBoolean(isPanelExposed),
      exposedNeighbour: this.bitToBoolean(isNeighborExposed),
      nearObstruction: false, // it applies only to commercial
      isLandscape: this.bitToBoolean(isLandscape),
      roofZone: roofZoneId,
      edgeType: config.edgeType,
    };
  }
  toBits(panel: panelInState) {
    let flags: Flags;
    flags = this.encodeRoof(panel);

    const isLandscape = panel.landscape ? 1 : 0; // 1 - landscape
    const isPanelExposed = panel.exposed || panel.exposedNeighbour ? 1 : 0;
    const isNeighborExposed = panel.exposedNeighbour ? 1 : 0;
    
    return [
      isPanelExposed, 
      isNeighborExposed, 
      flags.isDeepInterior, 
      flags.isRidge, 
      flags.isEave, 
      flags.isOnCorner, 
      flags.isOnEdge, 
      isLandscape,
    ].reduce<number>(
      ((bit, flag) => {
        return bit << 1 | flag;
      }), 
      0,
    );
  }

  private encodeRoof(panel: panelInState): Flags {
    // for roof pitch 7° - 27°:
    //  - 3r is marked as 3, but 3e is in the same category as 2n, therefore we need to also check if it is on the correct edge.
    // --------------------------
    // for roof pitch 27° - 45°:
    //  - 3r is marked in the same category as 2n therefore we need to check if it's on the correct edge.
    const isOnCorner = (
      (panel.roofZone === 3) || 
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.EAVE) || 
       // without roof pitch it's ambiguous whether it is a 3r corner for 27° - 45° or 2r for 7° - 27°
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.RIDGE && this.roofPitchDegrees > 27)
    );
    // for roof pitch 7° - 27°:
    //  - 2n and 2r are in the same category and 2e is in the same category as 1
    // --------------------------
    // for roof pitch 27° - 45°:
    //  - 2n is in the same category as 3r, and 2e and 2r are in the same category as 1
    const isOnEdge = (
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.RAKEGABLE) ||
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.RIDGE && this.roofPitchDegrees <= 27) ||
      (panel.roofZone === 1 && panel.edgeType !== undefined)
    );

    return {
      isDeepInterior: 0, // it applies only for flat roofs
      isRidge: this.booleanToBit(panel.edgeType === EDGES_TYPE.RIDGE),
      isEave: this.booleanToBit(panel.edgeType === EDGES_TYPE.EAVE),
      isOnCorner: this.booleanToBit(isOnCorner),
      isOnEdge: this.booleanToBit(isOnEdge),
    };
  }

  

  private decodeRoof(flags: Flags): DecodedConfig {
    if (this.roofPitchDegrees < 27) {
      if (flags.isOnCorner) {
        if (flags.isEave) {
          // 3e
          return {
            roofZone: 2,
            edgeType: EDGES_TYPE.EAVE,
          };
        } 
        
        if (flags.isRidge) {
          // 3r
          return {
            roofZone: 3,
            edgeType: EDGES_TYPE.RIDGE,
          };
        }
      } 
      
      if (flags.isOnEdge) {
        if (flags.isEave) {
          // 2e
          return {
            roofZone: 1,
            edgeType: EDGES_TYPE.EAVE,
          };
        } 
        
        if (flags.isOnEdge && flags.isRidge) {
          // 2r
          return {
            roofZone: 2,
            edgeType: EDGES_TYPE.RIDGE,
          };
        }

        // 2n
        return {
          roofZone: 2,
          edgeType: EDGES_TYPE.RAKEGABLE,
        };
      }
    }

    if (flags.isOnCorner) {
      if (flags.isEave) {
        // 3e
        return {
          roofZone: 3,
          edgeType: EDGES_TYPE.EAVE,
        };
      } 
      
      if (flags.isRidge) {
        // 3r
        return {
          roofZone: 2,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }
    } 
    
    if (flags.isOnEdge) {
      if (flags.isEave) {
        // 2e
        return {
          roofZone: 1,
          edgeType: EDGES_TYPE.EAVE,
        };
      } 
      
      if (flags.isOnEdge && flags.isRidge) {
        // 2r
        return {
          roofZone: 1,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }

      // 2n
      return {
        roofZone: 2,
        edgeType: EDGES_TYPE.RAKEGABLE,
      };
    }

    return {
      roofZone: 1,
      edgeType: undefined,
    };
  }
}
