import Leaflet from 'leaflet';
import RoofsSelector from '__editor/components/roofsSelector/roofsSelector';
import { clearEvents } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorHelper';
import { connect } from 'react-redux';
import { createBingLeadEdgesOnMap, getAllBingLeadEdges } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/bingMapsRoofsSelectorLeadEdge';
import { createNewArray } from '../components/roofsSelector/components/roofsSelectorClassicDesigner/roofsSelectorClassicDesigner';
import { getBingMap, initializeMap, initializePreviewMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { getBingMarkerIcon } from '__editor/bingMapsRoofsSelector/models/centerMarker';
import { getRoofColor } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import { initStageEvents } from '__editor/components/roofsSelector/components/roofsSelectorStage/roofsSelectorStage';
import { renderBingMapsAdanvaceRoofSelecting } from '__editor/bingMapsRoofsSelector/components/bingMapsAdvanceRoofSelecting/bingMapsAdvanceRoofSelecting';
import {setPixiStage, stopReqAnimationFrameBingMaps } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorStage/bingMapsRoofsSelectorStage';
import {
  clearBingRoofsOnMaps,
  createBingMapRoofArea,
  getAllBingRoofObject,
  setBingMapInfoWindow,
  } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { getLeadEdge } from '__editor/panelsEditor/components/leadEdge/leadEdge';
import { renderBingMapsMeasurementTool } from './components/bingMapsMeasurementTool/bingMapsMeasurementTool';
import { isBlankMap } from '__common/constants/map';

class BingMapsRoofsSelector extends RoofsSelector<bingMapsRoofsSelectorComponentState> {
  constructor(props: bingMapsRoofsSelectorComponentState) {
    super(props);
  }

  shouldComponentUpdate(prevState: bingMapsRoofsSelectorComponentState) {
    super.shouldComponentUpdate(prevState);
    const { roofsSelector: { mapType } } = this.props;
    return mapType !== prevState.roofsSelector.mapType || super.shouldComponentUpdate(prevState);
  }


  componentDidUpdate(prevState: bingMapsRoofsSelectorComponentState) {
    this.renderAdanvaceRoofSelecting();
    this.renderMeasurementTool();
    const { previewMode, drawingManager: { roofs }, roofsSelector: { mapType } } = this.props;
    if (previewMode) {
      this.updateRoofsColor();
    }

    if (prevState.roofsSelector.mapType !== this.props.roofsSelector.mapType) {
      const map = getBingMap();
      map.remove();
      this.initMap();
    }

    if (prevState.moduleSelector.modelData !== this.props.moduleSelector.modelData &&
    (!roofs || Object.keys(roofs).length === 0) && isBlankMap(mapType)) {
      createNewArray();
    }

    if (roofs && Object.keys(roofs).length){
      if(prevState.background.selectedRoofId !== null && this.props.background.selectedRoofId !== null){
        if(prevState.background.selectedRoofId !== this.props.background.selectedRoofId && previewMode){
          this.loadBingMapInfoWindow(roofs, previewMode);
        }
      }
    }

    this.renderAdanvaceRoofSelecting();
    this.renderMeasurementTool();
  }

  componentWillUnmount() {
    const { drawingManager: { roofs } } = this.props;
    clearEvents();
    stopReqAnimationFrameBingMaps();
    if (roofs && Object.keys(roofs).length) {
      clearBingRoofsOnMaps();
    }
  }

  initMap() {
    const { drawingManager: { roofs }, previewMode, roofsSelector: { mapType }, moduleSelector: { modelData } } = this.props;
    if (previewMode) {
      initializePreviewMap(this.canvas, mapType);
    } else {
      initializeMap(this.canvas, this.outerDiv.offsetWidth, this.outerDiv.offsetHeight, previewMode);
      setPixiStage(this.outerDiv.offsetWidth, this.outerDiv.offsetHeight);
      initStageEvents();
    }

    if (roofs && Object.keys(roofs).length) {
      this.loadRoofSelectorShapes(roofs, previewMode);
    } else if (isBlankMap(mapType) && modelData && Object.keys(modelData).length) {
      createNewArray();
    }
  }

  updateRoofsColor() {
    const roofs = getAllBingRoofObject();
    const leadEdges = getAllBingLeadEdges();
    if (roofs && Object.keys(roofs).length) {
      Object.keys(roofs).map((roofId) => {
        const roof = roofs[roofId].roof.overlay;
        const marker = roofs[roofId].marker;
        const leadEdge = leadEdges[roofId];
        const color = getRoofColor(parseInt(roofId, 10));
        roof.setStyle({ fillColor: color, color });
        const icon = new Leaflet.Icon({
          iconUrl: getBingMarkerIcon(parseInt(roofId, 10)),
          iconSize: [50, 50],
          iconAnchor: [25, 25],
        });
        marker.setIcon(icon);
        leadEdge.setStyle({ fillColor: color, color });
      });
    }
  }

  loadRoofSelectorShapes(roofs, previewMode: boolean) {
    const map = getBingMap();
    const roofsGroup = Object.keys(roofs).map((roofId) => {
      const roof = roofs[roofId];
      return createBingMapRoofArea(roof.coords, Number(roofId), previewMode).roof.overlay;
    });
    const group = Leaflet.featureGroup(roofsGroup).addTo(map);
    map.fitBounds(group.getBounds());
    this.loadLeadEdges();
  }

  loadBingMapInfoWindow(roofs, previewMode: boolean){
    Object.keys(roofs).map((roofId) => {
    return setBingMapInfoWindow(Number(roofId), previewMode);
  });
  }

  loadLeadEdges() {
    const { leadEdgeRoofSelector: { leadEdges } } = this.props;
    if (Object.keys(leadEdges).length) {
      Object.keys(leadEdges).map((roofId) => {
        const leadEdgePath = getLeadEdge(Number(roofId));
        createBingLeadEdgesOnMap(Number(roofId), leadEdgePath);
      });
    }
  }

  renderAdanvaceRoofSelecting() {
    const { advanceRoofSelecting: { enabled, roofPoints, lastPoint }, previewMode } = this.props;
    if (enabled && roofPoints.length && lastPoint && !previewMode) {
      renderBingMapsAdanvaceRoofSelecting();
    }
  }

  renderMeasurementTool() {
    const { measurementTool : { enabled, startingPoint, endingPoint }, previewMode } = this.props;
    if (enabled && startingPoint && endingPoint && !previewMode) {
      renderBingMapsMeasurementTool();
    }
  }
}

function mapStateToProps(state: appState) {
  return {
    advanceRoofSelecting: state.advanceRoofSelecting,
    measurementTool: state.measurementTool,
    drawingManager: state.drawingManager,
    leadEdgeRoofSelector: state.leadEdgeRoofSelector,
    moduleSelector: state.moduleSelector,
    projectConfiguration: state.projectConfiguration,
    router: state.router,
    roofsSelector: state.roofsSelector,
    background : state.background
  };
}

export default connect(mapStateToProps)(BingMapsRoofsSelector);
