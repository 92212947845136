import { smBuildingHeight } from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import rafterSpacing from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import railDirection from 'projectDesign/components/projectConfiguration/fields/railDirection';
import nxtRailFinish from 'projectDesign/components/projectConfiguration/fields/nxtRailFinish.json'
import frameSizeUp from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_up.json';
import frameSizeWide from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_wide.json';
import { nhRoofType } from 'projectDesign/components/projectConfiguration/fields/roofType';
import orientation from 'projectDesign/components/projectConfiguration/fields/helioscope_orientation.json';
import racking from 'projectDesign/components/projectConfiguration/fields/helioscope_racking.json';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import tornadoSpeed from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import setSpacing from 'projectDesign/components/projectConfiguration/fields/helioscope_setback.json';
import {SmAndNxtSnowLoadConfig} from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import span from 'projectDesign/components/projectConfiguration/fields/span';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import { asce716WindSpeedForSfmAndSm, asce705WindSpeedForSfmAndSm, asce710WindSpeedForSfmAndSm } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkEnvironmentalFactors } from 'projectDesign/components/projectConfiguration/utils/checkEnvironmentalFactors';
import { checkRafterSpacing, checkSeismic, checkShortestBuildingLength, checkBuildingHeight, checkSeismicSds, checkTornadoSpeed, checkFieldNotEmpty, checkRoofTypeMissing } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { state } from '__common/store';
import { getASCE716722BuildingDescriptionResidentalAdditionalInputs } from '../fields/getASCE716BuildingDescriptionResidentalAdditionalInputs';
import { getASCE716722LocationsResidentalAdditionalInputs } from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { isASCE705, isASCE710 , isASCE722} from '__common/constants/buildingCodes';
import { nhMidAndEndClamps } from 'projectDesign/components/projectConfiguration/fields/smClamps';
import smStandardSeamMetalAttachment from '../fields/smStandardSeamMetalAttachment';
import { railClampOption } from '../fields/railClampOption';
import roofMaterialThicknessSolarMountFlush, { rpanelRoofMaterialThicknessForPM9000s, rpanelRoofMaterialThicknessForPMAdj } from '../fields/roofMaterialThicknessSolarMountFlush';
import smSolarhooksAttachments from '../fields/smSolarhooksAttachments';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import { AttachmentType, smAndNxtAttachmentTypes } from '../fields/attachmentType';
import { isOsbOrPlywood } from '../utils/validation';
import { roofSubstrate } from '../fields/roofSubstrate';
import { asce722BuildingCodeNXTUmount, asceBuildingCodeNHForAurora } from '../fields/buildingCode';
import smTileReplacementsOrSolarhooks from '../fields/smTileReplacementsOrSolarhooks';
import { RoofType,TileReplacementOrSolarhooks, removeUpslopeRail } from '../utils/constants';
import azimuth from 'projectDesign/components/projectConfiguration/fields/helioscope_azimuth.json';
import { riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import longestBuildingLength from '../fields/longestBuildingLength';
import riskCategory from '../fields/riskCategory';
import StaggerAttachments from 'projectDesign/components/projectConfiguration/fields/staggerAttachments';
import { PSEUDO_STAFF_USER_PERMISSIONS } from 'user/userGroups';
import auroraRackingType from '../fields/auroraRackingType';
import { getProjectRoofs } from 'projectDesign/components/asce716switch/asce716switch';

function getWindSpeedField(buildingCodeValue: number, isAurora: boolean) {
  if (isASCE705(buildingCodeValue)) {
    return asce705WindSpeedForSfmAndSm();
  }

  if (isASCE710(buildingCodeValue)) {
    return asce710WindSpeedForSfmAndSm();
  }

  return asce716WindSpeedForSfmAndSm;
}

export default (buildingCodeValue: number) => {  
  let railFinish = JSON.parse(JSON.stringify(nxtRailFinish))
  const {
    projectConfiguration: {
      projectEnvConfig: {
        seismic_ss, seismic_sds, tornado_speed, roof_type, 
        attachment_type, 
        roof_substrate,
        building_height,
        shortest_building_length,
        tile_replacement_or_solarhooks,
        rafter_spacing_inches, risk_category,
        building_length,
        snow_load,
        elevation: current_elevation,
        wind_speed,
        is_aurora_project
      },
      productId, projectVersion
    },
    user :{
      isStaff, permissions
    }
  } = state();

  const roofs = getProjectRoofs();
  let isOneByTwelvePitch = () =>{
    if (roofs) {
      return Object.keys(roofs).some(roofKey => {
        const roofId = Number(roofKey)
        const roof: drawingManagerRoof = roofs[roofId]
        return roof.roofPitch === '1/12'        
      })
    }
  }

  const buildingCodeField = (is_aurora_project && isOneByTwelvePitch()) ? asceBuildingCodeNHForAurora() : asce722BuildingCodeNXTUmount();
  const windSpeed = getWindSpeedField(buildingCodeValue, is_aurora_project);
  const buildingHeight = smBuildingHeight();
  const roofType = nhRoofType

  let tornadoSpeedsField:any = []
  let longestBuildingLengthField:any = []
  if (isASCE722(buildingCodeValue) && riskCategoryCheckForTornadoSpeed(risk_category)){
    tornadoSpeedsField = tornadoSpeed;
    longestBuildingLengthField = longestBuildingLength;
    longestBuildingLengthField.missing=!building_length
  }
  const seismicSdsField = isASCE722(buildingCodeValue) ? seismicSds : [];
  const snowLoad = SmAndNxtSnowLoadConfig();
  checkEnvironmentalFactors(buildingCodeField, windSpeed, snowLoad, seismicSs, elevation);
  
  rafterSpacing.missing = !checkRafterSpacing(Number(rafter_spacing_inches));
  seismicSs.missing = !checkSeismic(seismic_ss);
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);
  buildingHeight.missing = !checkBuildingHeight(building_height * 1);
  riskCategory.missing = !checkFieldNotEmpty(risk_category)
  snowLoad.missing = !checkFieldNotEmpty(snow_load);
  elevation.missing = !checkFieldNotEmpty(current_elevation);
  windSpeed.missing = !checkFieldNotEmpty(wind_speed);

  const shortestBuildingLengthMissing = !checkShortestBuildingLength(shortest_building_length * 1);
  roofType.missing = !checkRoofTypeMissing(roof_type);
  
  const Helioscope = {
      fields: [
        azimuth,
        frameSizeUp,
        frameSizeWide,
        orientation,
        racking,
        nhMidAndEndClamps('mid_clamps_choices','mid_clamps', true),
        nhMidAndEndClamps('end_clamps_choices','end_clamps', true),
      ],
  };
  const Aurora = {
    fields: [
      auroraRackingType,
      nhMidAndEndClamps('mid_clamps_choices','mid_clamps', true),
      nhMidAndEndClamps('end_clamps_choices','end_clamps', true),
    ],
};
  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    Helioscope,
    HelioscopeRoofPitchSelector:  true,
    Aurora,
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCodeField,
        WindExposure,
        windSpeed,
        snowLoad,
        elevation,
        seismicSs,
        seismicSdsField,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
        topographicalFactorKzt,
        tornadoSpeedsField,
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        nhMidAndEndClamps('mid_clamps_choices','mid_clamps', false, is_aurora_project),
        nhMidAndEndClamps('end_clamps_choices','end_clamps', false, is_aurora_project),
        nhRoofType,
        ...(roof_type === RoofType.SHINGLE ? [smAndNxtAttachmentTypes(roof_type, productId)] : []) ,
        ...(roof_type === RoofType.SHINGLE  && [AttachmentType.FLASHLOC_DUO, AttachmentType.STRONGHOLD_ATT_BUTYL].includes(attachment_type)? [roofSubstrate(productId, attachment_type, roof_substrate, roof_type)] : []),
        isStaff || permissions.includes(PSEUDO_STAFF_USER_PERMISSIONS.staggering_access)?StaggerAttachments: [],
        ...(roof_type === RoofType.STANDING_SEAM ? [roofMaterialThicknessSolarMountFlush,smStandardSeamMetalAttachment, railClampOption] : []) ,
        ...(roof_type === RoofType.R_PANEL ? [smAndNxtAttachmentTypes(roof_type, productId)] : []) ,
        ...(roof_type === RoofType.R_PANEL && attachment_type === AttachmentType.PM_9000S ? [rpanelRoofMaterialThicknessForPM9000s] : []) ,
        ...(roof_type === RoofType.R_PANEL && attachment_type === AttachmentType.PM_ADJUST ? [rpanelRoofMaterialThicknessForPMAdj] : []) ,
        ...(roof_type === RoofType.TILE ? [smTileReplacementsOrSolarhooks] : []) ,
        ...(roof_type === RoofType.TILE && tile_replacement_or_solarhooks === TileReplacementOrSolarhooks.SOLARHOOKS ? [smSolarhooksAttachments] : []) ,
        ...getASCE716722BuildingDescriptionResidentalAdditionalInputs(buildingCodeValue, shortestBuildingLengthMissing, longestBuildingLengthField),
      ],
    },
  Spans: {
    label: '',
    fields: [
      span,
      isOsbOrPlywood(roof_type,attachment_type,roof_substrate) ? [] : rafterSpacing,
    ],
  },
  railDirection: railDirection(removeUpslopeRail(attachment_type, projectVersion)),
  railFinish,
  railTypes: false,
};
  return cfg;
};
