import autobind from 'autobind-decorator';
import React from 'react';
import { dispatch } from '__common/store';
import { SEARCH_PROJECT } from 'actions';
import TextField from 'react-md/lib/TextFields/TextField';

type State = {
  searchValue: string,
};

export default class SearchProjectInput extends React.Component<{}, State> {
  input;

  constructor(props) {
    super(props);
    this.state = { searchValue: '' };
  }

  @autobind
  onKeyPressed(e) {
    const { searchValue } = this.state;
    if (e.key === 'Enter') {
      this.setState(() => ({ searchValue: '' }));
      dispatch(SEARCH_PROJECT(searchValue.trim())); 
    }
  }

  @autobind
  setSearchId(value) {
    this.setState(() => ({ searchValue: value }));
  }

  render() {
    const { searchValue } = this.state;
    return (
      <div className="search-project">
        <TextField
          ref={input => this.input = input}
          id="search-project"
          placeholder="SEARCH PROJECT"
          onKeyDown={this.onKeyPressed}
          onChange={this.setSearchId}
          value={searchValue}
        />
      </div>
    );
  }
}
