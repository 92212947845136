import * as React from 'react';
import { Route } from 'react-router-dom';
import config from 'config';
import NotFound from '_containers/NotFound';
import accessibleProductForUser from '__common/utils/products';
const RedirectProtected: React.StatelessComponent<redirectProtectedComponentState> = ({ component: Component, isAuthenticated, user, dispatch, restrictedUrl, accessibleUrl=true, ...rest }) => {
  let { productId, projectId } = rest.computedMatch?.params;
  if(productId === 'nxt_horizon')
    productId = 'nxt_umount';

  let isaccessibleUrl = accessibleUrl;
  if(restrictedUrl && !projectId) {
    isaccessibleUrl = accessibleProductForUser(productId);
  }
  return (
  <Route
    {...rest}
    render={props => (
      isAuthenticated ? isaccessibleUrl ? <Component {...props} />: <NotFound /> :
      (
        window.location = config.domainName === 'localhost' ? config.ssoLogoutUrl : config.ssoLoginUrlWithNext + config.applicationUrl + props.location.pathname
      )
  )}
/>
);
}
export default RedirectProtected;
