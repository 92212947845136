import { state } from '__common/store';

export default () => {
  const {projectConfiguration:{projectEnvConfig}} = state()
  const auroraConfig = {
    building_code: 2,
    wind_speed: 115,
    snow_load: 5,
    seismic_ss: 0.46,
    elevation: 5499,
    mean_recurrence_interval: 25,
    building_height: 15,
    roof_type: 5,
    parapet_height: 1,
    block_weight: 32,
    wind_exposure: 'B',
    building_length: 100,
    aurora_racking_type: projectEnvConfig?.aurora_racking_type || 25,
  };
  return auroraConfig;
};
