import document from 'global/document';

class EventClass {
  constructor() {
    this.functionMap = {};
  }

  functionMap: any;

  addEventListener(event, func) {
    this.functionMap[event] = func;
    document.addEventListener(event.split('.')[0], this.functionMap[event]);
  }

  removeEventListener(event) {
    document.removeEventListener(event.split('.')[0], this.functionMap[event]);
    delete this.functionMap[event];
  }
}
const events = new EventClass();

export default events;
