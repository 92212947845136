import { createReducer } from '__common/utils/helpers';
import { FaqActionTypes } from 'actionsConstants';

export let faqState: faqState = {
  open: false,
  faqData: [],
};

export default {
  faq: createReducer(faqState, {
    [FaqActionTypes.SHOW_FAQ_WINDOW](state: faqState, action): faqState {
      return { ...state, open: true, faqData: action.payload.faqData };
    },
    [FaqActionTypes.HIDE_FAQ_WINDOW](state: faqState): faqState {
      return { ...state, open: false };
    },
    [FaqActionTypes.POPULATE_FAQ_DATA](state: faqState, action): faqState {
      return { ...state, faqData: action.payload };
    },
    [FaqActionTypes.RESET_FAQ_WINDOW](state: faqState): faqState {
      return { ...state, open: false, faqData: [] };
    },
  }),
};
