import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TextField from 'react-md/lib/TextFields/TextField';
import autobind from 'autobind-decorator';
import { CHANGE_DESIRED_TABLE_LENGTH, CHANGE_DESIRED_TABLE_WIDTH } from 'actions'; 
import Tooltip from '__common/components/Tooltip';
import { _isULA } from '__editor/panelsEditor/components/cursor/utils/snapToGridHelper';

interface props {
  length: number;
  width: number;
  isLandscape: boolean;
  dispatch: Function;
  changeLength: (length: number) => void;
  changeWidth: (length: number) => void;
}

interface state {
  length: number;
  width: number;
  isLandscape: boolean;
  isLengthValid: boolean;
  isWidthValid: boolean;
  lengthBounds: { min: number, max: number };
  widthBounds: { min: number, max: number };
}

const lengthTooltipText = `Select table length. Table length is the number of module columns in the east-west direction.`;
const widthTooltipText = `Select table height. Table height is the number of module rows in the north-south direction.`;

class DesiredTableSizeUla extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      length: props.length,
      width: props.width,
      isLandscape: true,
      isLengthValid: true,
      isWidthValid: true,
      lengthBounds: { min: 1, max: 20 },
      widthBounds: { min: props.isLandscape? 3: 2, max: props.isLandscape? 5: 3 }, 
    };
  }

  @autobind
  onLengthChange(value: string) {
    const parsedValue = parseInt(value, 10);
    const { 
      lengthBounds: { min: minLength, max: maxLength },
    } = this.state;

    if (
      parsedValue >= minLength && 
      parsedValue <= maxLength
    ) {
      this.setState({
        ...this.state,
        length: parsedValue,
        isLengthValid: true,
      });
      this.props.changeLength(parsedValue);
    } else {
      this.setState({
        length: parsedValue,
        isLengthValid: false,
      });
    }
  }

  @autobind
  onWidthChange(value: string) {
    const parsedValue = parseInt(value, 10);
    const { 
      widthBounds: { min: minWidth, max: maxWidth },
    } = this.state;


    if (
      parsedValue >= minWidth && 
      parsedValue <= maxWidth
    ) {
      this.setState({
        ...this.state,
        width: parsedValue,
        isWidthValid: true,
      });
      this.props.changeWidth(parsedValue);
    } else {
      this.setState({
        ...this.state,
        width: parsedValue,
        isWidthValid: false,
      });
    }
  }

  getLengthRangeHelper() {
    const { 
      lengthBounds: { min, max },
    } = this.state;
    return `(${min}-${max})`;
  }

  getWidthRangeHelper() {
    const { 
      widthBounds: { min, max },
    } = this.state;
    return `(${min}-${max})`;
  }

  renderLengthError() {
    if (this.state.isLengthValid) {
      return null;
    }

    const { 
      lengthBounds: { min: minLength, max: maxLength },
    } = this.state;

    const lengthErrorMessage = `Desired table length must be in range ${minLength} - ${maxLength}`;

    return (
      <div className="input-warning">{lengthErrorMessage}</div>
    );
  }

  renderWidthError() {
    if (this.state.isWidthValid) {
      return null;
    }

    const { 
      widthBounds: { min: minWidth, max: maxWidth },
    } = this.state;

    const widthErrorMessage = `Desired table height must be in range ${minWidth} - ${maxWidth}`;

    return (
      <div className="input-warning">{widthErrorMessage}</div>
    );
  }

  renderWarningMessage() {
    const { length, width } = this.props;
    if (length && width) {
      return null;
    }

    let warningMessage = "";

    if(!length && !width) {
      warningMessage = "You should enter length and height of the table";
    }else if(!width) {
      warningMessage = "You should enter height of the table";
    }else if(!length) {
      warningMessage = "You should enter length of the table";
    }
    return (
    <div className="input-warning">{warningMessage} </div>
    );
  }

  render() {
    const { 
      length,
      width,
      lengthBounds: { min: minLength, max: maxLength },
      widthBounds: { min: minWidth, max: maxWidth },
    } = this.state;
    return (
      <div className="desired-table-size">
        {this.renderWarningMessage()}
        <div>
          <div className="input-label">
            Desired table height {this.getWidthRangeHelper()}:
            <Tooltip 
              id={'desired-table-width'} 
              content={widthTooltipText}
              position="right" 
            />
          </div>
          <div className="input-row">
            <TextField 
              id="desiredTableWidth"
              className={classNames('input-value')}
              active={true}
              fullWidth={false}
              value={width ?? ""}
              onChange={this.onWidthChange}
              lineDirection="center"
              min={minWidth}
              max={maxWidth}
              step={1}
              type="number"
            />
          </div>
          {this.renderWidthError()}
        </div>
        <div>
          <div className="input-label">
            Desired table length {this.getLengthRangeHelper()}:
            <Tooltip 
              id={'desired-table-length'} 
              content={lengthTooltipText}
              position="right" 
            />
          </div>
          <div className="input-row">
            <TextField 
              id="desiredTableLength"
              className={classNames('input-value')}
              active={true}
              fullWidth={false}
              value={length ?? ""}
              onChange={this.onLengthChange}
              lineDirection="center"
              min={minLength}
              max={maxLength}
              step={1}
              type="number"
            />
          </div>
          {this.renderLengthError()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changeLength: (length: number) => dispatch(CHANGE_DESIRED_TABLE_LENGTH(length)),
    changeWidth: (width: number) => dispatch(CHANGE_DESIRED_TABLE_WIDTH(width)),
  };
};

const mapStateToProps = (state: appState) => {
  return {
    length: state.panels.desiredTableLength,
    width: state.panels.desiredTableWidth,
    isLandscape: state.editorCursor.landscape,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesiredTableSizeUla);
