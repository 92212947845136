import { getRenderer } from '__editor/panelsEditor/components/renderer/renderer';
import { getStage } from '__editor/panelsEditor/components/stage/stage';
import { dispatch, state } from '__common/store';
import { measurementToolModel } from '__editor/googleMapsRoofsSelector/models/measurementTool';
import { START_NEW_MEASUREMENT } from 'actions';

let measurement;

export function drawingMeasurementToolOnStage() {
  const { measurementTool: { enabled, startingPoint, endingPoint }, background: { metersPerPixel }, projectConfiguration : { productId, inputUnit } } = state();
  const stage = getStage();
  const renderer = getRenderer();
  if (stage) {

    if (!enabled && measurement) {
      stage.removeChild(measurement);
    }

    if (enabled && startingPoint && endingPoint) {
      stage.removeChild(measurement);
      measurement = measurementToolModel(startingPoint, endingPoint, stage.scale.x, productId, inputUnit, true, metersPerPixel);
      stage.addChild(measurement);
    }
  }
  renderer.render(stage);
}

export const clearMeasurements = () => {
  const stage = getStage();

  if (stage) {
    if (measurement) {
      stage.removeChild(measurement);
      dispatch(START_NEW_MEASUREMENT());
    }
  }
};