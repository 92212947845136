import frontEdgeHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/frontEdgeHeightTooltip';

export const apiField = 'front_edge_height';
enum TILT {
  TILT_20 = 20,
  TILT_30 = 30,
}
export const MAX_FRONT_EDGE_HEIGHT_FOR_DRIVEN = 4;
const config = {
  type: 'Number',
  label: 'front_edge_height',
  apiField,
  tooltip: frontEdgeHeightTooltip,
  data: { // determine fronEdgeHeight min, max and default values based on tilt 
    [TILT.TILT_20]: { min: 2, max: 6.5, default: 2 },
    [TILT.TILT_30]: { min: 2.5, max: 5, default: 2.5 },
  },
};

export default config;
