export const apiField = 'deflectors';

export const WindDeflectorOption = {
  everywhere: {
    value: 1,
    name: 'EVERYWHERE',
    },
    edgeDeflector: {
      value: 2,
      name: 'EDGE DEFLECTOR',
    },
    ul2703: {
      value: 3,
      name: 'UL 2703',
    },
    noDeflector: {
      value: 4,
      name: 'NO DEFLECTOR',
    },
};

export interface WindDeflectorField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  data: WindDeflectorOption[];
}

export interface WindDeflectorOption {
  value: number;
  name: string;
}
