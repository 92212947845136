import * as React from 'react';
import Icon from '__common/components/Icon';
import { changeAttachmentType } from 'bom/components/utils/partsSelectionFunctions';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import {
  SELECT_ATTACHMENT_TYPE_ON_LOAD,
} from 'actions';
export default class AttachmentTypes extends React.Component<attachmentTypeComponentState, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch, selectedGroup } = this.props;
    dispatch(SELECT_ATTACHMENT_TYPE_ON_LOAD(selectedGroup));
  }

  changeAttachmentType(attachmentType: string) {
    changeAttachmentType(attachmentType);
  }

  parseTypes = (attachmentTypes: string[]) => {
    return attachmentTypes.map(type => Object({ label: type, value: type, key: type.toLowerCase().split(' ').join('-') }));
  }

  render() {
    const { attachmentTypes, selectedGroup } = this.props;
    const types = this.parseTypes(attachmentTypes);
    return (
      <div className="attachment-types-selector">
        <SelectionControlGroup
          id="attachment-selection-control-group-radios"
          name="attachment-types"
          type="radio"
          value={selectedGroup}
          controls={types}
          onChange={(attachmentType) => this.changeAttachmentType(attachmentType)}
          checkedRadioIcon={(<Icon>check</Icon>)}
          uncheckedRadioIcon={(<Icon>check</Icon>)}
          inline={true}
        />
      </div>
    );
  }
}

