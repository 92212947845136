import { generateNewCenterForMap } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';

export const mapType = google.maps.MapTypeId.SATELLITE;

export const mapConfig = (): google.maps.MapOptions => {
  const { lat, lng } = generateNewCenterForMap();
  return {
    zoom: 19,
    minZoom: 16,
    disableDefaultUI: true,
    zoomControl: false,
    streetViewControl: false,
    rotateControl: false,
    mapTypeId: mapType,
    tilt: 0,
    center: new google.maps.LatLng(lat, lng),
  };
};

export const previewMapConfig = () => {
  const { lat, lng } = generateNewCenterForMap();
  return ({
    zoom: 21,
    disableDefaultUI: true,
    zoomControl: false,
    streetViewControl: false,
    tilt: 0,
    center: new google.maps.LatLng(lat, lng),
  });
};
