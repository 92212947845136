import RoofTypeTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/roofTypeTooltip';

function makeConfig(data: {[value: string]: string}[]) {
  return {
    type: 'Select',
    label: 'roofType',
    apiField: 'roof_type',
    tooltip: RoofTypeTooltip,
    data,
  };
}

const defaultSfmRoofType = makeConfig(
  [
    {
      value: '1',
      name: 'SHINGLE',
    },
  ],
);
export default defaultSfmRoofType;

export const sfmInfinityRoofType = makeConfig(
  [
    {
      value: '1',
      name: 'SHINGLE',
    },
    {
      value: '3',
      name: 'TILE',
    },
  ],
);
