export const apiField = 'foundation_type';

export const options = {
  concrete: {
    value: 2,
    name: 'CONCRETE',
  }, 
  aboveGradeBallast: {
    value: 3,
    name: 'BALLAST BLOCK',
  },
  groundScrew: {
    value: 4,
    name: 'GROUND SCREW',
  },  
};

const config = {
  type: 'Select',
  apiField,
  label: 'foundation_type',
  data: [
    options.concrete,
    options.groundScrew,
    options.aboveGradeBallast,
  ],
};

export default config;
