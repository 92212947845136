//  Note : To avoide muiltple API calls added parts in frontend and filtering the data.
//  parts added based on  roof type.
//  keys in numDictionary are rooftype.
//  format : {"choice of fasted": value, "color":value, "manufacturer code": value, "part number":value}

        
export const  numDictionary = {
    7 : [ //01 TPO
        {"fastend":"2000",  "color":"W",    "mf":"AAA",	"part":"UA20AAA01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"CAR",	"part":"UA20CAR01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"FIR",	"part":"UA20FIR01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"FLX",	"part":"UA20FLX01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"GAF",	"part":"UA20GAF01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"GFL",	"part":"UA20GFL01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"JMA", "part":"UA20JMA01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"MLH",	"part":"UA20MLH01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"RMA",	"part":"UA20RMA01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"VER",	"part":"UA20VER01W",},
        {"fastend":"2000",	 "color":"W",	"mf":"WEA",	"part":"UA20WEA01W",},
        {"fastend":"2000",	 "color":"G",	"mf":"AAA",	"part":"UA20AAA01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"CAR",	"part":"UA20CAR01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"FIR",	"part":"UA20FIR01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"FLX",	"part":"UA20FLX01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"GAF",	"part":"UA20GAF01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"GFL",	"part":"UA20GFL01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"JMA", "part":"UA20JMA01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"MLH",	"part":"UA20MLH01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"RMA",	"part":"UA20RMA01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"VER",	"part":"UA20VER01G",},
        {"fastend":"2000",	 "color":"G",	"mf":"WEA",	"part":"UA20WEA01G",},
        {"fastend":"2000",	 "color":"C",	"mf":"AAA",	"part":"UA20AAA01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"CAR",	"part":"UA20CAR01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"FIR",	"part":"UA20FIR01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"FLX",	"part":"UA20FLX01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"GAF",	"part":"UA20GAF01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"GFL",	"part":"UA20GFL01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"JMA", "part":"UA20JMA01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"MLH",	"part":"UA20MLH01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"RMA",	"part":"UA20RMA01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"VER",	"part":"UA20VER01C",},
        {"fastend":"2000",	 "color":"C",	"mf":"WEA",	"part":"UA20WEA01C",},
        {"fastend":"2400",	 "color":"W",	"mf":"AAA",	"part":"UA24AAA01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"CAR",	"part":"UA24CAR01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"FIR",	"part":"UA24FIR01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"FLX",	"part":"UA24FLX01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"GAF",	"part":"UA24GAF01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"GFL",	"part":"UA24GFL01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"JMA", "part":"UA24JMA01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"MLH",	"part":"UA24MLH01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"RMA",	"part":"UA24RMA01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"VER",	"part":"UA24VER01W",},
        {"fastend":"2400",	 "color":"W",	"mf":"WEA",	"part":"UA24WEA01W",},
        {"fastend":"2400",	 "color":"G",	"mf":"AAA",	"part":"UA24AAA01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"CAR",	"part":"UA24CAR01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"FIR",	"part":"UA24FIR01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"FLX",	"part":"UA24FLX01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"GAF",	"part":"UA24GAF01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"GFL",	"part":"UA24GFL01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"JMA", "part":"UA24JMA01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"MLH",	"part":"UA24MLH01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"RMA",	"part":"UA24RMA01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"VER",	"part":"UA24VER01G",},
        {"fastend":"2400",	 "color":"G",	"mf":"WEA",	"part":"UA24WEA01G",},
        {"fastend":"2400",	 "color":"C",	"mf":"AAA",	"part":"UA24AAA01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"CAR",	"part":"UA24CAR01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"FIR",	"part":"UA24FIR01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"FLX",	"part":"UA24FLX01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"GAF",	"part":"UA24GAF01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"GFL",	"part":"UA24GFL01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"JMA", "part":"UA24JMA01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"MLH",	"part":"UA24MLH01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"RMA",	"part":"UA24RMA01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"VER",	"part":"UA24VER01C",},
        {"fastend":"2400",	 "color":"C",	"mf":"WEA",	"part":"UA24WEA01C",},
        ],
    8 : [ //02 PVC
        {"fastend":"2000",	"color":"W",	"mf":"AAA",	   "part":"UA20AAA02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"CAR",	   "part":"UA20CAR02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"DUR",	   "part":"UA20DUR02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"FLX",	   "part":"UA20FLX02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"GAF",	   "part":"UA20GAF02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"IBR",	   "part":"UA20IBR02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"JMA",	   "part":"UA20JMA02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"MLH",	   "part":"UA20MLH02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"RMA",	   "part":"UA20RMA02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"SIK",	   "part":"UA20SIK02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"SOP",	   "part":"UA20SOP02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"VER",	   "part":"UA20VER02W",},
	    {"fastend":"2000",	"color":"W",	"mf":"WEA",	   "part":"UA20WEA02W",},
	    {"fastend":"2000",	"color":"G",	"mf":"AAA",	   "part":"UA20AAA02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"CAR",	   "part":"UA20CAR02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"DUR",	   "part":"UA20DUR02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"FLX",	   "part":"UA20FLX02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"GAF",	   "part":"UA20GAF02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"IBR",	   "part":"UA20IBR02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"JMA",	   "part":"UA20JMA02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"MLH",	   "part":"UA20MLH02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"RMA",	   "part":"UA20RMA02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"SIK",	   "part":"UA20SIK02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"SOP",	   "part":"UA20SOP02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"VER",	   "part":"UA20VER02G",},
	    {"fastend":"2000",	"color":"G",	"mf":"WEA",	   "part":"UA20WEA02G",},
	    {"fastend":"2000",	"color":"C",	"mf":"AAA",	   "part":"UA20AAA02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"CAR",	   "part":"UA20CAR02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"DUR",	   "part":"UA20DUR02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"FLX",	   "part":"UA20FLX02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"GAF",	   "part":"UA20GAF02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"IBR",	   "part":"UA20IBR02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"JMA",	   "part":"UA20JMA02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"MLH",	   "part":"UA20MLH02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"RMA",	   "part":"UA20RMA02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"SIK",	   "part":"UA20SIK02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"SOP",	   "part":"UA20SOP02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"VER",	   "part":"UA20VER02C",},
	    {"fastend":"2000",	"color":"C",	"mf":"WEA",	   "part":"UA20WEA02C",},
	    {"fastend":"2400",	"color":"W",	"mf":"AAA",	   "part":"UA24AAA02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"CAR",	   "part":"UA24CAR02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"DUR",	   "part":"UA24DUR02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"FLX",	   "part":"UA24FLX02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"GAF",	   "part":"UA24GAF02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"IBR",	   "part":"UA24IBR02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"JMA",	   "part":"UA24JMA02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"MLH",	   "part":"UA24MLH02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"RMA",	   "part":"UA24RMA02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"SIK",	   "part":"UA24SIK02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"SOP",	   "part":"UA24SOP02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"VER",	   "part":"UA24VER02W",},
	    {"fastend":"2400",	"color":"W",	"mf":"WEA",	   "part":"UA24WEA02W",},
	    {"fastend":"2400",	"color":"G",	"mf":"AAA",	   "part":"UA24AAA02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"CAR",	   "part":"UA24CAR02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"DUR",	   "part":"UA24DUR02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"FLX",	   "part":"UA24FLX02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"GAF",	   "part":"UA24GAF02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"IBR",	   "part":"UA24IBR02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"JMA",	   "part":"UA24JMA02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"MLH",	   "part":"UA24MLH02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"RMA",	   "part":"UA24RMA02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"SIK",	   "part":"UA24SIK02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"SOP",	   "part":"UA24SOP02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"VER",	   "part":"UA24VER02G",},
	    {"fastend":"2400",	"color":"G",	"mf":"WEA",	   "part":"UA24WEA02G",},
	    {"fastend":"2400",	"color":"C",	"mf":"AAA",	   "part":"UA24AAA02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"CAR",	   "part":"UA24CAR02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"DUR",	   "part":"UA24DUR02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"FLX",	   "part":"UA24FLX02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"GAF",	   "part":"UA24GAF02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"IBR",	   "part":"UA24IBR02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"JMA",	   "part":"UA24JMA02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"MLH",	   "part":"UA24MLH02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"RMA",	   "part":"UA24RMA02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"SIK",	   "part":"UA24SIK02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"SOP",	   "part":"UA24SOP02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"VER",	   "part":"UA24VER02C",},
	    {"fastend":"2400",	"color":"C",	"mf":"WEA",	   "part":"UA24WEA02C",},
        ],
    17 :[ //03 KEE
        {"fastend":"2400",    "color":"W",    "mf":"AAA",   "part":"UA24AAA03W",},
        {"fastend":"2400",    "color":"W",    "mf":"DUR",   "part":"UA24DUR03W",},
        {"fastend":"2400",    "color":"W",    "mf":"FBT",   "part":"UA24FBT03W",},
        {"fastend":"2400",    "color":"W",    "mf":"FLX",   "part":"UA24FLX03W",},
        {"fastend":"2400",    "color":"W",    "mf":"GAF",   "part":"UA24GAF03W",},
        {"fastend":"2400",    "color":"W",    "mf":"IBR",   "part":"UA24IBR03W",},
        {"fastend":"2400",    "color":"W",    "mf":"JMA",   "part":"UA24JMA03W",},
        {"fastend":"2400",    "color":"W",    "mf":"RMA",   "part":"UA24RMA03W",},
        {"fastend":"2400",    "color":"W",    "mf":"TRE",   "part":"UA24TRE03W",},
        {"fastend":"2400",    "color":"G",    "mf":"AAA",   "part":"UA24AAA03G",},
        {"fastend":"2400",    "color":"G",    "mf":"DUR",   "part":"UA24DUR03G",},
        {"fastend":"2400",    "color":"G",    "mf":"FBT",   "part":"UA24FBT03G",},
        {"fastend":"2400",    "color":"G",    "mf":"FLX",   "part":"UA24FLX03G",},
        {"fastend":"2400",    "color":"G",    "mf":"GAF",   "part":"UA24GAF03G",},
        {"fastend":"2400",    "color":"G",    "mf":"IBR",   "part":"UA24IBR03G",},
        {"fastend":"2400",    "color":"G",    "mf":"JMA",   "part":"UA24JMA03G",},
        {"fastend":"2400",    "color":"G",    "mf":"RMA",   "part":"UA24RMA03G",},
        {"fastend":"2400",    "color":"G",    "mf":"TRE",   "part":"UA24TRE03G",},
        {"fastend":"2400",    "color":"C",    "mf":"AAA",   "part":"UA24AAA03C",},
        {"fastend":"2400",    "color":"C",    "mf":"DUR",   "part":"UA24DUR03C",},
        {"fastend":"2400",    "color":"C",    "mf":"FBT",   "part":"UA24FBT03C",},
        {"fastend":"2400",    "color":"C",    "mf":"FLX",   "part":"UA24FLX03C",},
        {"fastend":"2400",    "color":"C",    "mf":"GAF",   "part":"UA24GAF03C",},
        {"fastend":"2400",    "color":"C",    "mf":"IBR",   "part":"UA24IBR03C",},
        {"fastend":"2400",    "color":"C",    "mf":"JMA",   "part":"UA24JMA03C",},
        {"fastend":"2400",    "color":"C",    "mf":"RMA",   "part":"UA24RMA03C",},
        {"fastend":"2400",    "color":"C",    "mf":"TRE",   "part":"UA24TRE03C",},

        ],
    6 : [//04 EPDM
        {"fastend":"2400", 	"color":"W",	"mf":"AAA",	    "part":"UA24AAA04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"CAR",	    "part":"UA24CAR04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"FIR",	    "part":"UA24FIR04W",},
        {"fastend":"2400", 	"color":"W",    "mf":"GAF",	    "part":"UA24GAF04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"GFL",	    "part":"UA24GFL04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"JMA",	    "part":"UA24JMA04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"MLH",	    "part":"UA24MLH04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"VER",	    "part":"UA24VER04W",},
        {"fastend":"2400", 	"color":"W",	"mf":"WEA",	    "part":"UA24WEA04W",},
        {"fastend":"2400", 	"color":"B",	"mf":"AAA",	    "part":"UA24AAA04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"CAR",	    "part":"UA24CAR04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"FIR",	    "part":"UA24FIR04B",},
        {"fastend":"2400", 	"color":"B",    "mf":"GAF",	    "part":"UA24GAF04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"GFL",	    "part":"UA24GFL04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"JMA",	    "part":"UA24JMA04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"MLH",	    "part":"UA24MLH04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"VER",	    "part":"UA24VER04B",},
        {"fastend":"2400", 	"color":"B",	"mf":"WEA",	    "part":"UA24WEA04B",},
        ],
    18 : [ //05 TPA
        {"fastend":"2000",	"color":"W",	"mf":"AAA",	 "part":"UA20AAA05W",},
        {"fastend":"2000",	"color":"W",	"mf":"TRE",	 "part":"UA20TRE05W",},
        {"fastend":"2000",	"color":"G",	"mf":"AAA",	 "part":"UA20AAA05G",},
        {"fastend":"2000",	"color":"G",	"mf":"TRE",	 "part":"UA20TRE05G",},
        {"fastend":"2000",	"color":"C",	"mf":"AAA",	 "part":"UA20AAA05C",},
        {"fastend":"2000",	"color":"C",	"mf":"TRE",	 "part":"UA20TRE05C",},
        ],
    19 :[// 06 APP
        {"fastend":"2600",			"color":"W",	"mf":"AAA", "part":"UA26AAA06W",},
        {"fastend":"2600",			"color":"W",	"mf":"CTD", "part":"UA26CTD06W",},
        {"fastend":"2600",			"color":"W",	"mf":"DER", "part":"UA26DER06W",},
        {"fastend":"2600",			"color":"W",	"mf":"FIR", "part":"UA26FIR06W",},
        {"fastend":"2600",			"color":"W",    "mf":"GAF", "part":"UA26GAF06W",},
        {"fastend":"2600",			"color":"W",	"mf":"JMA", "part":"UA26JMA06W",},
        {"fastend":"2600",			"color":"W",	"mf":"MAL", "part":"UA26MAL06W",},
        {"fastend":"2600",			"color":"W",	"mf":"MLH", "part":"UA26MLH06W",},
        {"fastend":"2600",			"color":"W",	"mf":"POL", "part":"UA26POL06W",},
        {"fastend":"2600",			"color":"W",	"mf":"SPL", "part":"UA26SPL06W",},
        {"fastend":"2600",			"color":"W",	"mf":"TRE", "part":"UA26TRE06W",},
        {"fastend":"2600",			"color":"G",	"mf":"AAA", "part":"UA26AAA06G",},
        {"fastend":"2600",			"color":"G",	"mf":"CTD", "part":"UA26CTD06G",},
        {"fastend":"2600",			"color":"G",	"mf":"DER", "part":"UA26DER06G",},
        {"fastend":"2600",			"color":"G",	"mf":"FIR", "part":"UA26FIR06G",},
        {"fastend":"2600",			"color":"G",	"mf":"GAF", "part":"UA26GAF06G",},
        {"fastend":"2600",			"color":"G",	"mf":"JMA", "part":"UA26JMA06G",},
        {"fastend":"2600",			"color":"G",	"mf":"MAL", "part":"UA26MAL06G",},
        {"fastend":"2600",			"color":"G",	"mf":"MLH", "part":"UA26MLH06G",},
        {"fastend":"2600",			"color":"G",	"mf":"POL", "part":"UA26POL06G",},
        {"fastend":"2600",			"color":"G",	"mf":"SPL", "part":"UA26SPL06G",},
        {"fastend":"2600",			"color":"G",	"mf":"TRE", "part":"UA26TRE06G",},
        {"fastend":"2600",			"color":"O",	"mf":"AAA", "part":"UA26AAA06O",},
        {"fastend":"2600",			"color":"O",	"mf":"CTD", "part":"UA26CTD06O",},
        {"fastend":"2600",			"color":"O",	"mf":"DER", "part":"UA26DER06O",},
        {"fastend":"2600",			"color":"O",	"mf":"FIR", "part":"UA26FIR06O",},
        {"fastend":"2600",			"color":"O",	"mf":"GAF", "part":"UA26GAF06O",},
        {"fastend":"2600",			"color":"O",	"mf":"JMA", "part":"UA26JMA06O",},
        {"fastend":"2600",			"color":"O",	"mf":"MAL", "part":"UA26MAL06O",},
        {"fastend":"2600",			"color":"O",	"mf":"MLH", "part":"UA26MLH06O",},
        {"fastend":"2600",			"color":"O",	"mf":"POL", "part":"UA26POL06O",},
        {"fastend":"2600",			"color":"O",	"mf":"SPL", "part":"UA26SPL06O",},
        {"fastend":"2600",			"color":"O",	"mf":"TRE", "part":"UA26TRE06O",},
        ],
    20:{1 : [// 07 SBS //Regular
            {"fastend":"2600",	"color":"W",	"mf":"AAA",	"part":"UA26AAA07W",},
            {"fastend":"2600",	"color":"W",	"mf":"CTD",	"part":"UA26CTD07W",},
            {"fastend":"2600",	"color":"W",	"mf":"FIR",	"part":"UA26FIR07W",},
            {"fastend":"2600",	"color":"W",	"mf":"GAF",	"part":"UA26GAF07W",},
            {"fastend":"2600",	"color":"W",	"mf":"GAR",	"part":"UA26GAR07W",},
            {"fastend":"2600",	"color":"W",	"mf":"JMA",	"part":"UA26JMA07W",},
            {"fastend":"2600",	"color":"W",	"mf":"MAL",	"part":"UA26MAL07W",},
            {"fastend":"2600",	"color":"W",	"mf":"MLH",	"part":"UA26MLH07W",},
            {"fastend":"2600",	"color":"W",	"mf":"POL",	"part":"UA26POL07W",},
            {"fastend":"2600",	"color":"W",	"mf":"SPL",	"part":"UA26SPL07W",},
            {"fastend":"2600",	"color":"W",	"mf":"SOP",	"part":"UA26SOP07W",},
            {"fastend":"2600",	"color":"W",	"mf":"TRE",	"part":"UA26TRE07W",},
            {"fastend":"2600",	"color":"G",	"mf":"AAA",	"part":"UA26AAA07G",},
            {"fastend":"2600",	"color":"G",	"mf":"CTD",	"part":"UA26CTD07G",},
            {"fastend":"2600",	"color":"G",	"mf":"FIR",	"part":"UA26FIR07G",},
            {"fastend":"2600",	"color":"G",	"mf":"GAF",	"part":"UA26GAF07G",},
            {"fastend":"2600",	"color":"G",	"mf":"GAR",	"part":"UA26GAR07G",},
            {"fastend":"2600",	"color":"G",	"mf":"JMA",	"part":"UA26JMA07G",},
            {"fastend":"2600",	"color":"G",	"mf":"MAL",	"part":"UA26MAL07G",},
            {"fastend":"2600",	"color":"G",	"mf":"MLH",	"part":"UA26MLH07G",},
            {"fastend":"2600",	"color":"G",	"mf":"POL",	"part":"UA26POL07G",},
            {"fastend":"2600",	"color":"G",	"mf":"SPL",	"part":"UA26SPL07G",},
            {"fastend":"2600",	"color":"G",	"mf":"SOP",	"part":"UA26SOP07G",},
            {"fastend":"2600",	"color":"G",	"mf":"TRE",	"part":"UA26TRE07G",},
            {"fastend":"2600",	"color":"O",	"mf":"AAA",	"part":"UA26AAA07O",},
            {"fastend":"2600",	"color":"O",	"mf":"CTD",	"part":"UA26CTD07O",},
            {"fastend":"2600",	"color":"O",	"mf":"FIR",	"part":"UA26FIR07O",},
            {"fastend":"2600",	"color":"O",	"mf":"GAF",	"part":"UA26GAF07O",},
            {"fastend":"2600",	"color":"O",	"mf":"GAR",	"part":"UA26GAR07O",},
            {"fastend":"2600",	"color":"O",	"mf":"JMA",	"part":"UA26JMA07O",},
            {"fastend":"2600",	"color":"O",	"mf":"MAL",	"part":"UA26MAL07O",},
            {"fastend":"2600",	"color":"O",	"mf":"MLH",	"part":"UA26MLH07O",},
            {"fastend":"2600",	"color":"O",	"mf":"POL",	"part":"UA26POL07O",},
            {"fastend":"2600",	"color":"O",	"mf":"SPL",	"part":"UA26SPL07O",},
            {"fastend":"2600",	"color":"O",	"mf":"SOP",	"part":"UA26SOP07O",},
            {"fastend":"2600",	"color":"O",	"mf":"TRE",	"part":"UA26TRE07O",},
            ],
        2 : [//08 SBS //TORCHED
            {"fastend":"2600",	"color":"W",	"mf":"AAA",	 "part":"UA26AAA08W",},
            {"fastend":"2600",	"color":"W",	"mf":"CTD",	 "part":"UA26CTD08W",},
            {"fastend":"2600",	"color":"W",	"mf":"FIR",	 "part":"UA26FIR08W",},
            {"fastend":"2600",	"color":"W",	"mf":"GAF",	 "part":"UA26GAF08W",},
            {"fastend":"2600",	"color":"W",	"mf":"GAR",	 "part":"UA26GAR08W",},
            {"fastend":"2600",	"color":"W",	"mf":"JMA",	 "part":"UA26JMA08W",},
            {"fastend":"2600",	"color":"W",	"mf":"MAL",	 "part":"UA26MAL08W",},
            {"fastend":"2600",	"color":"W",	"mf":"POL",	 "part":"UA26POL08W",},
            {"fastend":"2600",	"color":"W",	"mf":"SPL",	 "part":"UA26SPL08W",},
            {"fastend":"2600",	"color":"W",	"mf":"SOP",	 "part":"UA26SOP08W",},
            {"fastend":"2600",	"color":"W",	"mf":"TRE",	 "part":"UA26TRE08W",},
            {"fastend":"2600",	"color":"G",	"mf":"AAA",	 "part":"UA26AAA08G",},
            {"fastend":"2600",	"color":"G",	"mf":"CTD",	 "part":"UA26CTD08G",},
            {"fastend":"2600",	"color":"G",	"mf":"FIR",	 "part":"UA26FIR08G",},
            {"fastend":"2600",	"color":"G",	"mf":"GAF",	 "part":"UA26GAF08G",},
            {"fastend":"2600",	"color":"G",	"mf":"GAR",	 "part":"UA26GAR08G",},
            {"fastend":"2600",	"color":"G",	"mf":"JMA",	 "part":"UA26JMA08G",},
            {"fastend":"2600",	"color":"G",	"mf":"MAL",	 "part":"UA26MAL08G",},
            {"fastend":"2600",	"color":"G",	"mf":"POL",	 "part":"UA26POL08G",},
            {"fastend":"2600",	"color":"G",	"mf":"SPL",	 "part":"UA26SPL08G",},
            {"fastend":"2600",	"color":"G",	"mf":"SOP",	 "part":"UA26SOP08G",},
            {"fastend":"2600",	"color":"G",	"mf":"TRE",	 "part":"UA26TRE08G",},
            {"fastend":"2600",	"color":"O",	"mf":"AAA",	 "part":"UA26AAA08O",},
            {"fastend":"2600",	"color":"O",	"mf":"CTD",	 "part":"UA26CTD08O",},
            {"fastend":"2600",	"color":"O",	"mf":"FIR",	 "part":"UA26FIR08O",},
            {"fastend":"2600",	"color":"O",	"mf":"GAF",	 "part":"UA26GAF08O",},
            {"fastend":"2600",	"color":"O",	"mf":"GAR",	 "part":"UA26GAR08O",},
            {"fastend":"2600",	"color":"O",	"mf":"JMA",	 "part":"UA26JMA08O",},
            {"fastend":"2600",	"color":"O",	"mf":"MAL",	 "part":"UA26MAL08O",},
            {"fastend":"2600",	"color":"O",	"mf":"POL",	 "part":"UA26POL08O",},
            {"fastend":"2600",	"color":"O",	"mf":"SPL",	 "part":"UA26SPL08O",},
            {"fastend":"2600",	"color":"O",	"mf":"SOP",	 "part":"UA26SOP08O",},
            {"fastend":"2600",	"color":"O",	"mf":"TRE",	 "part":"UA26TRE08O",},
            ],
        },
    21 :[//09 Acrylic
        {"fastend":"2800",	"color":"N",    "mf":"AAA",	  "part":"UA28AAA09N",},
        {"fastend":"2800",	"color":"N",    "mf":"CAR",	  "part":"UA28CAR09N",},
        {"fastend":"2800",	"color":"N",    "mf":"DER",	  "part":"UA28DER09N",},
        {"fastend":"2800",	"color":"N",    "mf":"DUR",	  "part":"UA28DUR09N",},
        {"fastend":"2800",	"color":"N",    "mf":"FIR",	  "part":"UA28FIR09N",},
        {"fastend":"2800",	"color":"N",    "mf":"GAF",	  "part":"UA28GAF09N",},
        {"fastend":"2800",	"color":"N",    "mf":"JMA",	  "part":"UA28JMA09N",},
        {"fastend":"2800",	"color":"N",    "mf":"MLH",	  "part":"UA28MLH09N",},
        {"fastend":"2800",	"color":"N",    "mf":"POL",	  "part":"UA28POL09N",},
        {"fastend":"2800",	"color":"N",    "mf":"SIK",	  "part":"UA28SIK09N",},
        {"fastend":"2800",	"color":"N",    "mf":"SPL",	  "part":"UA28SPL09N",},
        {"fastend":"2800",	"color":"N",    "mf":"SOP",	  "part":"UA28SOP09N",},
        {"fastend":"2800",	"color":"N",    "mf":"TRE",	  "part":"UA28TRE09N",},
        {"fastend":"2800",	"color":"N",    "mf":"VER",	  "part":"UA28VER09N",},
        ],
    22:{1 : [//10 COATED //SOLVENT BASED
            {"fastend":"2800",	"color":"N",	"mf":"AAA",	"part":"UA28AAA10N",},
            {"fastend":"2800",	"color":"N",	"mf":"CAR",	"part":"UA28CAR10N",},
            {"fastend":"2800",	"color":"N",	"mf":"DER",	"part":"UA28DER10N",},
            {"fastend":"2800",	"color":"N",	"mf":"DUR",	"part":"UA28DUR10N",},
            {"fastend":"2800",	"color":"N",	"mf":"FIR",	"part":"UA28FIR10N",},
            {"fastend":"2800",	"color":"N",	"mf":"GAF",	"part":"UA28GAF10N",},
            {"fastend":"2800",	"color":"N",	"mf":"JMA",	"part":"UA28JMA10N",},
            {"fastend":"2800",	"color":"N",	"mf":"MLH",	"part":"UA28MLH10N",},
            {"fastend":"2800",	"color":"N",	"mf":"POL",	"part":"UA28POL10N",},
            {"fastend":"2800",	"color":"N",	"mf":"SIK",	"part":"UA28SIK10N",},
            {"fastend":"2800",	"color":"N",	"mf":"SPL",	"part":"UA28SPL10N",},
            {"fastend":"2800",	"color":"N",	"mf":"SOP",	"part":"UA28SOP10N",},
            {"fastend":"2800",	"color":"N",	"mf":"TRE",	"part":"UA28TRE10N",},
            {"fastend":"2800",	"color":"N",	"mf":"VER",	"part":"UA28VER10N",},
            ],
        2 : [//11 COATED //SILICONE BASED
            {"fastend":"2800",	"color":"N",	"mf":"AAA",	"part":"UA28AAA11N",},
            {"fastend":"2800",	"color":"N",	"mf":"CAR",	"part":"UA28CAR11N",},
            {"fastend":"2800",	"color":"N",	"mf":"DER",	"part":"UA28DER11N",},
            {"fastend":"2800",	"color":"N",	"mf":"DUR",	"part":"UA28DUR11N",},
            {"fastend":"2800",	"color":"N",	"mf":"FIR",	"part":"UA28FIR11N",},
            {"fastend":"2800",	"color":"N",	"mf":"GAF",	"part":"UA28GAF11N",},
            {"fastend":"2800",	"color":"N",	"mf":"JMA",	"part":"UA28JMA11N",},
            {"fastend":"2800",	"color":"N",	"mf":"MLH",	"part":"UA28MLH11N",},
            {"fastend":"2800",	"color":"N",	"mf":"POL",	"part":"UA28POL11N",},
            {"fastend":"2800",	"color":"N",	"mf":"SIK",	"part":"UA28SIK11N",},
            {"fastend":"2800",	"color":"N",	"mf":"SPL",	"part":"UA28SPL11N",},
            {"fastend":"2800",	"color":"N",	"mf":"SOP",	"part":"UA28SOP11N",},
            {"fastend":"2800",	"color":"N",	"mf":"TRE",	"part":"UA28TRE11N",},
            {"fastend":"2800",	"color":"N",	"mf":"VER",	"part":"UA28VER11N",},
            ]
        },

    };

export const roof_type_mapping = (roof_type) => {
    const mapping = {
        7   : "01",
        8   : "02",
        17  : "03",
        6   : "04",
        18  : "05",
        19  : "06",
        20  : {1 : "07", 2 : "08"},
        21  : "09",
        22  : {1 : "10", 2 : "11"},
    };

    return mapping[roof_type] || null; 

};

export const u_anchor_roofs_list = [6, 7, 8, 17, 18, 19, 20, 21, 22]

export const getDataByKey = (key) => {
    return numDictionary[key] || [];
};
export const filterByManufacturer = (data, manufacturer) => data.filter(item => item.mf === manufacturer);

export const filterByColour = (data, colour) => data.filter(item => item.color === colour);

export const filterByFast = (data, choiceOfFastend) => {
    const result = data.find(item => item.fastend === choiceOfFastend);
    return result ? result.part : null;
};

export const extractUniqueMfValues = (data) => {
    const mfSet = new Set(data.map(item => item.mf));
    return Array.from(mfSet);
};

export const extractUniqueColourValues = (data) => {
    const colourSet = new Set(data.map(item => item.color));
    return Array.from(colourSet);
};

export const extractUniqueFastendValues = (data) => {
    const fastenersSet = new Set(data.map(item => item.fastend));
    return Array.from(fastenersSet);
};

export const getDataBySubCategoryField = (data,key) => {
    return data[key] || [];
};
