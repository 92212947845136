
export const apiField = 'tile_replacement_or_solarhooks';

function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'tile_replacement_or_solarhooks',
    apiField,
    data: options,
  };
}

export default makeConfig([
  {
    value: 1,
    name: 'Tile Replacement',
  },
  {
    value: 2,
    name: 'Solarhooks',
  },
  
]);

