import { getDecoder } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/bitDataConverter/getDecoder';

export function toBitwiseData(panel: panelInState, buildingCode: number, productId: number, schemaVersion: number, roofPitch: number) {
  const decoder = getDecoder(buildingCode, productId, roofPitch, schemaVersion);
  return decoder.toBits(panel);
}

export function fromBitwiseData(bitData: number, buildingCode: number, productId: number, schemaVersion: number, roofPitch: number, roofZoneId?: number) {
  const decoder = getDecoder(buildingCode, productId, roofPitch, schemaVersion);
  return decoder.fromBits(bitData, roofZoneId);
}
