import * as React from 'react';
import SVG from 'react-inlinesvg';

type Props = {
  label?: string,
  onClick: () => void,
};

export default class ResetChangesButton extends React.Component<Props> {
  render() {
    const { label } = this.props;
    return (
      <div role="button" onClick={this.props.onClick} className="save-changes-button">
      <SVG src={require('assets/media/icons/reset_inputs.svg')} />
      {label || 'RESET'}
    </div>);
  }
}
