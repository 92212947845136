import { feetsToMeters } from "__common/calculations/feetsToMeters";
import Polygon from 'polygon';

export const getRMIFIRoofDimensions = ({
  length,
  width,
  setbackDistance,
  metersPerPixel,
  bgScale,
  getInnerRoof = false}): pixelPoint[] => {  

  let lengthPixels = feetsToMeters(length) / metersPerPixel;
  let widthPixels = feetsToMeters(width) / metersPerPixel;
  let setbackPixels = setbackDistance / metersPerPixel;
  
  lengthPixels /= bgScale.y;
  widthPixels /= bgScale.x;
  setbackPixels /= bgScale.x;
  
  lengthPixels = lengthPixels/2;
  widthPixels = widthPixels/2;

  if (getInnerRoof) {
    lengthPixels = lengthPixels - setbackPixels - 0.8;
    widthPixels = widthPixels - setbackPixels - 0.8;
  }

  const newRoofEdges: pixelPoint[] = [
    { x: -widthPixels, y: -lengthPixels },
    { x: widthPixels, y: -lengthPixels },
    { x: widthPixels, y: lengthPixels },
    { x: -widthPixels, y: lengthPixels },
  ];
  return newRoofEdges;
};


export const filterOutPanelsNotInsideRoofEdges = ({
  length,
  width,
  setbackDistance,
  metersPerPixel,
  bgScale,
  panelWidth,
  panelHeight,
  panels}): panelInState[] => {
  let innerRoofEdges = getRMIFIRoofDimensions({ length, width, setbackDistance, metersPerPixel, bgScale, getInnerRoof: true });
  innerRoofEdges = innerRoofEdges.map((edge) => ({
    x: edge.x * bgScale.x,
    y: edge.y * bgScale.y,
  }));
  if (!panels || !panels.length || !innerRoofEdges) {
    return [];
  } 
  const getPanelBounds = (panel: panelInState) => (
  {
    minX: panel.x - (panelWidth / 2),
    minY: panel.y - (panelHeight / 2),
    maxX: panel.x + (panelWidth / 2),
    maxY: panel.y + (panelHeight / 2),
  });  
  const roofEdgesPolygon = new Polygon(innerRoofEdges);
  const filteredPanels = panels.filter(panel => {
    const { minX, minY, maxX, maxY } = getPanelBounds(panel);
    const panelPoly = new Polygon([{ x: minX, y: minY }, { x: maxX, y: minY }, { x: maxX, y: maxY }, { x: minX, y: maxY }]);
    return roofEdgesPolygon.contains(panelPoly);
  });
  return filteredPanels;
};
