import ClampsTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/clampsTooltip';
import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper'; 

export const apiField = 'clamp_type';

export const ulaClampsType = {
  type: 'Select',
  apiField: 'clamp_type',
  label: 'clamp_options',
  tooltip: ClampsTooltip,
  onConfirm: () => {
    makeAllRoofsEmpty();
  },
  shouldConfirm: () => anyPanelsDrawn(),
  data: [
    {
      value: '11',
      name: 'PRO SERIES',
    },
    {
      value: '01',
      name: 'STANDARD',
    },
    {
      value: '04',
      name : 'UNIVERSAL AF CLAMPS'
    }
  ],
};

export const gftClampsType = (rail_arrangement_type) => {
  return {
  type: 'Select',
  apiField: 'clamp_type',
  label: 'clamp_options',
  tooltip: ClampsTooltip,
  onConfirm: () => {
    makeAllRoofsEmpty();
  },
  shouldConfirm: () => anyPanelsDrawn(),
  data: [
      {
        value: '11',
        name: 'PRO SERIES',
      },
      {
        value: '01',
        name: 'STANDARD',
      },
      ...((rail_arrangement_type === 3 || rail_arrangement_type === 1)? []:[{ value:'04', name:'UNIVERSAL AF CLAMPS' }]),
    ],
  }
};
