import { ForgotPasswordActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import {
  CHANGE_PASSWORD_ON_DEMAND_ERROR,
  CHANGE_PASSWORD_ON_DEMAND_SUCCESS,
  RESET_PASSWORD_ON_DEMAND_ERROR,
  RESET_PASSWORD_ON_DEMAND_SUCCESS,
  } from 'actions';

export function resetPasswordOnDemand(action$) {
  return action$.ofType(ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND),
        onSuccess: RESET_PASSWORD_ON_DEMAND_SUCCESS,
        onError: RESET_PASSWORD_ON_DEMAND_ERROR,
        onErrorMessage: 'Cannot reset password',
        link: hitApi('post', 'api/auth/forgot-password/', { email: action.payload.email }),
      }),
    );
}

export function changePasswordOnDemand(action$) {
  return action$.ofType(ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND),
        onSuccess: CHANGE_PASSWORD_ON_DEMAND_SUCCESS,
        onError: CHANGE_PASSWORD_ON_DEMAND_ERROR,
        onErrorMessage: 'Cannot change password',
        link: hitApi('put', 'api/auth/forgot-password-change/', { new_password: action.payload.newPassword, password_repeat: action.payload.newPasswordRepeated, token: action.payload.token, uid: action.payload.uid }),
      }),
    );
}
