import { dispatch, state } from '__common/store';
import { getRoofEdges } from '__editor/panelsEditor/components/background/background';
import { SET_STAGE_SCALE, SET_CENTER_OFFSET, LOAD_PANELS } from 'actions';
import { resetKdTree } from '../cursor/utils/kdTreeStore';
import { clearRTree } from '../panels/panelsCollisions';
import { removeAllPanelsFromStageToRedraw } from '../panels/drawingPanelsOnStage';
import { CENTER_OBSTRUCTIONS_ON_ROOF } from '../obstructions/ObstructionsActions';
import { getBaysContainer, getPanelsContainer } from '../panels/utils/panelsContainer';
import { resetPanelCenterTree } from '../panels/utils/panelsCenterStore';
import { isGFT } from '__common/constants/products';

const classicDesignerZoom = {
  height: 99.68746373276178,
  width: 169.5780322696522,
  x: 637.2108721432896 - 50,
  y: 302.6578361877598 - 50,
};

export function zoomRoof(
  stage: PIXI.Container,
  renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer,
  editorWidth: number,
  editorHeight: number,
  classicDesigner?: boolean,
  productId?: number,
) {
  const {
    background: { bgXY, selectedRoofId },
    drawingManager: { roofs },
    projectConfiguration : { projectEnvConfig : { is_aurora_project } }
  } = state();
  const panels = roofs[selectedRoofId].panels
    ? roofs[selectedRoofId].panels
    : [];
  const previewMapWidth = 0;
  const roofScale = 1;
  let roofEdgesBounds;
  let mouse_loc;
  if (classicDesigner && panels.length) {
    const panelsContainer = getPanelsContainer();
    if (!panelsContainer) {
      return;
    }

    roofEdgesBounds = panelsContainer.getLocalBounds();
    // Hack for makng it look like the array us in the center,in order to make sure array is not breaking when we go in out of the editor multiple times
    const panelsContainerCenter = {
      x: roofEdgesBounds.x + roofEdgesBounds.width / 2.1,
      y: roofEdgesBounds.y + roofEdgesBounds.height / 2.1,
    };

    mouse_loc = bgXY;

    centerAllPanels(panelsContainerCenter, mouse_loc);

    // const dot: any = new PIXI.Graphics();
    // dot.beginFill(0x00ff00, 1);
    // dot.drawCircle(mouse_loc.x, mouse_loc.y, 5);
    // dot.id = 'endLineRoofEdgeDot';
    // dot.zIndex = 100001;
    // dot.endFill();

    // const rect: any = new PIXI.Graphics();
    // rect.lineStyle(1, 0x000000);
    // rect.drawRect(
    //   roofEdgesBounds.x,
    //   roofEdgesBounds.y,
    //   roofEdgesBounds.width,
    //   roofEdgesBounds.height,
    // );
    // rect.id = 'rect';
    // rect.zIndex = 500;
    // rect.endFill();

    // stage.addChild(dot);
    // stage.addChild(rect);
  } else if (classicDesigner && panels.length === 0) {
    roofEdgesBounds = classicDesignerZoom;
    mouse_loc = bgXY;
  } else {
    const roofEdges = getRoofEdges();
    roofEdgesBounds = roofEdges.getBounds();
    mouse_loc = bgXY;
  }

  if (roofEdgesBounds.x < 0 && roofEdgesBounds.y < 0) {
    return false;
  }

  if (panels.length) {
    if (is_aurora_project) {
      roofEdgesBounds.width /= 0.2;
      roofEdgesBounds.height /= 0.2;
    } else {
      roofEdgesBounds.width /= 0.5;
      roofEdgesBounds.height /= 0.5;
    }
  }

  let roofDimensionForScale;
  let editorDimensionForScale;
  let scale;
  const multiplicationfactor = isGFT(productId) ? 0.25 : 1;

  if (roofEdgesBounds.width > roofEdgesBounds.height) {
    roofDimensionForScale = roofEdgesBounds.width * roofScale;
    editorDimensionForScale = editorWidth - previewMapWidth;
    scale = multiplicationfactor * editorDimensionForScale / roofDimensionForScale;
  } else {
    roofDimensionForScale = roofEdgesBounds.height * roofScale;
    editorDimensionForScale = editorHeight;
    scale = multiplicationfactor * editorDimensionForScale / roofDimensionForScale;
  }
  if (scale < 1) {
    return true;
  }

  const zoom_factor = scale;

  stage.x -= (mouse_loc.x - stage.x) * (zoom_factor - 1);
  stage.y -= (mouse_loc.y - stage.y) * (zoom_factor - 1);
  stage.scale.set(scale);
  renderer.render(stage);
  return true;
}

export const initZoom = (domElement, stage: PIXI.Container, renderer) => {
  domElement.addEventListener('mousewheel', e => {
    onWheelScrool(e, stage, renderer);
  });
};

export const terminateZoom = (
  domElement,
  stage: PIXI.Container,
  renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer,
) => {
  domElement.removeEventListener('mousewheel', e => {
    onWheelScrool(e, stage, renderer);
  });
};

export const zoom = (
  e,
  stage: PIXI.Container,
  renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer,
  toPoint?: { x: number; y: number },
) => {
  const zoom_in = e.deltaY < 0;
  let zoom_factor;
  if (zoom_in) {
    zoom_factor = 1.1;
  } else {
    zoom_factor = 1 / 1.1;
  }

  // zoom
  stage.scale.x *= zoom_factor;
  stage.scale.y *= zoom_factor;

  // center on cursor
  const mouse_loc =
    toPoint || renderer.plugins.interaction.eventData.data.global;
  stage.x -= (mouse_loc.x - stage.x) * (zoom_factor - 1);
  stage.y -= (mouse_loc.y - stage.y) * (zoom_factor - 1);

  // keep in frame
  stage.x = Math.min(0, stage.x);
  stage.y = Math.min(0, stage.y);

  const renderer_view_width = renderer.view.width - 100;
  const renderer_view_height = renderer.view.height - 100;


  // keep width in bounds
  const visible_width = renderer.width * stage.scale.x + stage.x;
  if (visible_width < renderer_view_width) {
    stage.x = Math.min(0, renderer_view_width - renderer.width * stage.scale.x);
    if (stage.x === 0) {
      stage.scale.x = renderer_view_width / renderer.width;
    }
  }

  // keep height in bounds
  const visible_height = renderer.height * stage.scale.y + stage.y;
  if (visible_height < renderer_view_height) {
    stage.y = Math.min(
      0,
      renderer_view_height - renderer.height * stage.scale.y,
    );
    if (stage.y === 0) {
      stage.scale.y = renderer_view_height / renderer.height;
    }
  }

  // keep aspect ratio
  if (stage.scale.y !== stage.scale.x) {
    stage.scale.x = Math.max(stage.scale.x, stage.scale.y);
    stage.scale.y = Math.max(stage.scale.x, stage.scale.y);
  }

  dispatch(SET_STAGE_SCALE({ x: stage.scale.x, y: stage.scale.y }));
  if (e.preventDefault) {
    e.preventDefault();
  }
};

function onWheelScrool(
  e,
  stage: PIXI.Container,
  renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer,
) {
  e.preventDefault();
  zoom(e, stage, renderer);
}


function centerAllPanels(panelsContainerCenter, canvasCenter) {
  const { background: { selectedRoofId, metersPerPixel }, settings: { rowSpacing, columnSpacing } } = state();
  const panelsContainer = getPanelsContainer();
  const { panels, exposureRecaluclationHash } = state().panels;
  const offsetX = canvasCenter.x - panelsContainerCenter.x;
  const offsetY = canvasCenter.y - panelsContainerCenter.y;

  dispatch(SET_CENTER_OFFSET({ x: offsetX, y: offsetY }));
  dispatch(CENTER_OBSTRUCTIONS_ON_ROOF(offsetX, offsetY, selectedRoofId));
  resetKdTree();
  resetPanelCenterTree();
  clearRTree();
  removeAllPanelsFromStageToRedraw(panelsContainer, getBaysContainer());
  dispatch(LOAD_PANELS(panels, true, rowSpacing, columnSpacing, metersPerPixel, exposureRecaluclationHash));
}
