import * as React from 'react';
import autobind from 'autobind-decorator';
import { CHANGE_BOM_COLOR_VARIANT } from 'actions';
import { connect } from 'react-redux';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import uniq from 'lodash/uniq';

type Props = {
  dispatch: Function,
  variants: any,
  partToReplace: bomPart,
};

class ColorsVariantsSelector extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { partToReplace } = this.props;
    let { variants } = this.props;
    variants = uniq(variants);
    const clonedPartToReplace = { ...partToReplace };
    if (variants.length > 0 && !variants.includes(partToReplace)) {
      variants.unshift(clonedPartToReplace);
      delete clonedPartToReplace.variants;
    }
    this.controls = variants.reduce((acc, variant: bomPart) => {
      acc.push({
        label: variant.color,
        value: variant.number,
      });

      return acc;
    },                              []);
    this.selected = partToReplace.number;
  }

  controls: { label: string, value: string }[];
  selected: string;

  @autobind
  changeColorVariant(partNumber: string) {
    const { dispatch, variants, partToReplace } = this.props;
    const newPart = variants.filter(variant => variant.number === partNumber)[0];
    const restVariants = variants.reduce((acc, variant) => {
      if (newPart.number !== variant.number) {
        acc.push(variant);
      }
      delete variant.variants;
      return acc;
    },                                   []);
    const clonedPartToReplace = { ...partToReplace };
    delete clonedPartToReplace.variants;
    if (clonedPartToReplace.selected) {
      newPart.selected = clonedPartToReplace.selected;
      delete clonedPartToReplace.selected;
    }

    restVariants.push(clonedPartToReplace);
    newPart.variants = restVariants;
    newPart.quantity = partToReplace.quantity;
    newPart.suggested_quantity = partToReplace.quantity;
    
    this.selected = newPart.number;
    dispatch(CHANGE_BOM_COLOR_VARIANT(clonedPartToReplace.number, newPart));
  }

  render() {
    const { partToReplace } = this.props;
    return (
      <SelectField
        id={`color-variant-changer-${partToReplace.number}`}
        name={`color-variant-changer-${partToReplace.number}`}
        placeholder="Objects button"
        onChange={this.changeColorVariant}
        menuItems={this.controls}
        value={this.selected}
        className="color-variants"
      />
    );
  }
}

export default connect()(ColorsVariantsSelector);
