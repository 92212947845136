import * as React from 'react';

type Props = {
  accessoriesPrice: number,
  baseSystemPrice: number,
};

export const prices: React.StatelessComponent<Props> = ({ baseSystemPrice, accessoriesPrice }) => {
  const totalListPrice = baseSystemPrice + accessoriesPrice;

  function baseSystem() {
    return (
      <div className="info-item">
        <div className="title">base system</div>
        <div className="value">${baseSystemPrice.toFixed(2)}</div>
      </div>
    );
  }

  function accessories() {
    return (
      <div className="info-item">
        <div className="title">accessories</div>
        <div className="value">${accessoriesPrice.toFixed(2)}</div>
      </div>
    );
  }

  function totalList() {
    return (
      <div className="info-item">
        <div className="title">total list price</div>
        <div className="value">${totalListPrice.toFixed(2)}</div>
      </div>
    );
  }

  return (
    <div className="project-prices">
      {baseSystem()}
      {accessories()}
      {totalList()}
    </div>
  );
};

export default prices;
