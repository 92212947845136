import { connect } from 'react-redux';
import * as React from 'react';
import autobind from 'autobind-decorator';
import { ADD_PANELS, SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH, SAVE_ROOF_EDGES_POINTS, UPDATE_PANELS } from 'actions';
import Tooltip from '__common/components/Tooltip';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import { dispatch, state } from '__common/store';
import { getMaxSetBack, getPanelSetBackDistance } from '__editor/panelsEditor/components/roofZones/utils/setBackDistanceCollisionDetections';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { filterOutPanelsNotInsideRoofEdges, getRMIFIRoofDimensions } from 'projectDesign/rmGridflexBlankMapUtils';
import { isEcoFoot2Plus, isRM10orRM10Evo, isRM5, isRMGridFlex } from '__common/constants/products';
interface props {
  width: number;
  length: number;
  dispatch: Function;
  changeLengthWidth: (length: number, width: number) => void;
  panels: panelInState[];
  metersPerPixel: number;
  zoom: number;
  cursorPoint: any;
  canvasCenter: pixelPoint;
  bgScale: {x: number, y: number};
  panelHeight: number;
  panelWidth: number;
  projectEnvConfig: projectEnvConfig;
  productId: number;
  inputUnit: number;
  projectVersion: string;
  updatePanels: (newPanels: panelInState[])=> void;
  background: backgroundState;
  roofPitch: string;
  settings: settingsState
}

interface state {
  width: number;
  isWidthValid: boolean;
  length: number;
  isLengthValid: boolean;
  setbackDistance: number;
  minSize: number;
  initialRender: boolean;
}

const widthTooltipText = `Select roof area width along EW direction`;
const lengthTooltipText = `Select roof area length along NS direction`;

class BuildingLengthWidth extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    const { projectEnvConfig, metersPerPixel, panelWidth, panelHeight, width, length, productId, inputUnit, projectVersion} = props;
    const maxSetback =  getMaxSetBack(projectEnvConfig, inputUnit, productId, projectVersion);
    let minSize = 0;
    if (metersPerPixel) {
      const minSizeforMin1Panel = metersToFeets(((maxSetback/metersPerPixel)*2 + Math.max(panelWidth, panelHeight)) * metersPerPixel);
      minSize = Math.ceil(minSizeforMin1Panel);
    }
    this.state = {
      isLengthValid: length && length >= minSize && length <= BuildingLengthWidth.maxLength,
      isWidthValid: width && width >= minSize && length <= BuildingLengthWidth.maxWidth,
      width,
      length,
      setbackDistance: maxSetback,
      minSize,
      initialRender: true,
    };
  }
  static maxWidth = 640;
  static maxLength = 280;

  shouldComponentUpdate(nextProps: props, nextState: state) {
    const shouldUpdate = nextState.width !== this.state.width || nextState.length !== this.state.length ||
     nextProps.metersPerPixel !== this.props.metersPerPixel || nextState.minSize !== this.state.minSize || 
     (!!nextProps.width && !this.props.width) || (!!nextProps.length && !this.props.length);
    return shouldUpdate;
  }

  componentDidUpdate() {
    const { panelHeight, panelWidth, metersPerPixel, length: propLength, width: propWidth } = this.props;
    let { length, width, minSize } = this.state;
    const { initialRender } = this.state;
    if (!minSize && metersPerPixel) {
      const minSizeforMin1Panel = metersToFeets(((this.state.setbackDistance/metersPerPixel)*2 + Math.max(panelWidth, panelHeight)) * metersPerPixel);
      minSize = Math.ceil(minSizeforMin1Panel);
    }

    if(!length && propLength && initialRender) {
      length = propLength;
    }
    if (!width && propWidth && initialRender) { 
      width = propWidth;
    }
      
    this.setState({ ...this.state, initialRender: false, length, width, minSize,
      isLengthValid: length && length >= minSize && length <= BuildingLengthWidth.maxLength,
      isWidthValid: width && width >= minSize && width <= BuildingLengthWidth.maxWidth,
    });

  }

  isValidLength(value: string | number) {
    let parsedValue = Number(value);
    if (!parsedValue) parsedValue = 0;
    return parsedValue >= this.state.minSize && parsedValue <= BuildingLengthWidth.maxLength;
  }

  getUpdatedPanelSetBack(){
    const {
      background: {
        roofEdges,
        cords,
        zoom,
        metersPerPixel,
        rotationDegrees,
        bgXY,
        },
        roofPitch,
        productId,
        panels,
        settings: {
          panelWidth,
          panelHeight,
        },
      } = this.props
      if((isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRMGridFlex(productId))) {
        const updatedPanels = panels.map(panel =>{
          panel.panelSetback = getPanelSetBackDistance(
            panel,
            true,
            roofEdges,
            cords,
            zoom,
            metersPerPixel,
            rotationDegrees,
            bgXY.x,
            bgXY.y,
            panelWidth,
            panelHeight,
            roofPitch,
          )
          return panel
        })
        return updatedPanels
    }
  }

  isValidWidth(value: string | number) {
    let parsedValue = Number(value);
    if (!parsedValue) parsedValue = 0;
    return parsedValue >= this.state.minSize && parsedValue <= BuildingLengthWidth.maxWidth;
  }

  @autobind
  onWidthChange(value: string) {
    let parsedValue = parseInt(value, 10);
    if (!parsedValue) parsedValue = 0;
    if (this.isValidWidth(parsedValue)) {
      this.setState({ ...this.state, width: parsedValue, isWidthValid: true });
      this.props.changeLengthWidth(this.props.length, parsedValue);
      this.pointConversionForRoofArea(this.props.length, parsedValue);
      const setbackCalculatedPanels = this.getUpdatedPanelSetBack()
      this.props.updatePanels(setbackCalculatedPanels)
    } else {
      this.setState({ ...this.state, width: parsedValue, isWidthValid: false });
    }
  }

  @autobind
  onLengthChange(value: string) {
    let parsedValue = parseInt(value, 10);
    if (!parsedValue) parsedValue = 0;
    if (this.isValidLength(parsedValue)) {
      this.setState({ ...this.state, length: parsedValue, isLengthValid: true });
      this.props.changeLengthWidth(parsedValue, this.props.width);
      this.pointConversionForRoofArea(parsedValue, this.props.width);
      const setbackCalculatedPanels = this.getUpdatedPanelSetBack()
      this.props.updatePanels(setbackCalculatedPanels)
    } else {
      this.setState({ ...this.state, length: parsedValue, isLengthValid: false });
    }
  }

  renderWarningWidthMessage() {
    if (this.state.isWidthValid) {
      return null;
    }

    const errorMessage = `Desired table width must be in range ${this.state.minSize} - ${BuildingLengthWidth.maxWidth}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  renderWarningLengthMessage() {
    if (this.state.isLengthValid) {
      return null;
    }

    const errorMessage = `Desired table length must be in range ${this.state.minSize} - ${BuildingLengthWidth.maxLength}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  _hasPanels = () => {
    const { panels } = this.props;
    return panels && Array.isArray(panels) && panels.length > 0;
  }

  getRoofDimensions = (length: number, width: number, getInnerRoof = false): pixelPoint[] => {
    const { metersPerPixel, bgScale } = this.props;
    return getRMIFIRoofDimensions({ length, width, setbackDistance: this.state.setbackDistance, metersPerPixel, bgScale, getInnerRoof });
  }

  pointConversionForRoofArea(length: number, width: number) {
    const newRoofEdges = this.getRoofDimensions(length, width);
    const filteredPanels =  this.filteredPanelsWithNewRoof(length, width);
    dispatch(SAVE_ROOF_EDGES_POINTS(newRoofEdges));
    if (filteredPanels.length !== this.props.panels.length) dispatch(ADD_PANELS(filteredPanels));
  }

  filteredPanelsWithNewRoof(length: number, width: number): panelInState[] {
    const { panelWidth, panelHeight, panels, metersPerPixel, bgScale } = this.props;
    return filterOutPanelsNotInsideRoofEdges({ length, width, setbackDistance: this.state.setbackDistance, metersPerPixel, bgScale, panelWidth, panelHeight, panels });
  }

  shouldConfirm = (value: string, hasWidthChanged = true) => {
    const isValidDim = hasWidthChanged? this.isValidWidth(value): this.isValidLength(value);
    if (isValidDim && this._hasPanels()) {
      const val = parseInt(value, 10);
      let [length, width] = [val, this.props.width];
      let hasDimensionChanged = () => val !== this.props.length;
      if (hasWidthChanged) {
        [length, width] = [this.props.length, val];
        hasDimensionChanged = () => val !== this.props.width;
      }
      return hasDimensionChanged() && this.filteredPanelsWithNewRoof(length, width).length !== this.props.panels.length;
    }
    return false;
  }

  render() {
    const { width, length } = this.state;
    return (
      <>
        <div className="desired-table-size">
          <div className="input-label">
            Desired Roof width (ft):
            <Tooltip 
              id={'desired-table-width'}
              content={widthTooltipText}
              position="right"
            />
          </div>

          <div className="field">
            <ConfirmationTextField
              value={width ?? ""}
              onConfirm={val => this.onWidthChange(val)}
              title="Are you sure you want to change the roof width?"
              content="Some panels may be lost."
              shouldConfirm={true}
              shouldConfirmCb={(val) => this.shouldConfirm(val)}
              textFieldProps={{
                lineDirection: 'center',
                type: 'text',
                fullWidth: true,
                className: 'blank-map-building-width-input',
              }}
            />
          </div>
          {this.renderWarningWidthMessage()}
        </div>

        <div className="desired-table-size">
          <div className="input-label">
            Desired Roof length (ft):
            <Tooltip
              id={'desired-table-length'}
              content={lengthTooltipText}
              position="right"
            />
          </div>

          <div className="field">
            <ConfirmationTextField
              value={length ?? ""}
              onConfirm={val => this.onLengthChange(val)}
              title="Are you sure you want to change the roof length?"
              content="Some panels may be lost."
              shouldConfirm={true}
              shouldConfirmCb={(val) => this.shouldConfirm(val, false)}
              textFieldProps={{
                lineDirection: 'center',
                type: 'text',
                fullWidth: true,
                className: 'blank-map-building-length-input',
              }}
            />
          </div>
          {this.renderWarningLengthMessage()}
        </div>
      </>);
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changeLengthWidth: (length: number, width: number) => dispatch(SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH(length, width)),
    updatePanels: (newPanels)=> dispatch(UPDATE_PANELS(newPanels))
  };
};

const mapStateToProps = (state: appState) => {
  return {
    width: state.background.blank_map_building_width,
    length: state.background.blank_map_building_length,
    panels: state.panels.panels,
    metersPerPixel: state.background.metersPerPixel,
    zoom: state.background.zoom,
    bgScale: state.background.bgScale,
    cursorPoint: state.editorCursor.position,
    canvasCenter: state.settings.canvasCenter,
    panelHeight: state.settings.panelHeight,
    panelWidth: state.settings.panelWidth,
    projectEnvConfig: state.projectConfiguration.projectEnvConfig,
    productId: state.projectConfiguration.productId,
    inputUnit: state.projectConfiguration.inputUnit,
    projectVersion: state.projectConfiguration.projectVersion,
    background: state.background,
    roofPitch: state.tiltedRoof.roofPitch,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingLengthWidth);
