import React from 'react';

type Props = {
  content: JSX.Element,
};

export default class HeaderImage extends React.Component<Props> {
  render() {
    const { content } = this.props;

    return (
      <div
        className="header-image"
        style={{
          background: `url(${require('assets/media/images/top_background_image.jpg')})`,
        }}
      >
        <div className="content">
          {content}
        </div>
        <div className="note">
          U-Builder performs best on Google Chrome and Firefox Browsers.
        </div>
      </div>
    );
  }
}
