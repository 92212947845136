import * as React from 'react';
import { connect } from 'react-redux';
import { getTotalModules } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';

type Props = {
  watts: number,
  bom: bomState,
  drawingManager: drawingManagerState,
  engineering: engineeringState,
  projectConfiguration: projectConfigurationState,
  moduleSelector: moduleSelectorState,
};

class TotalModulesInfo extends React.Component<Props, {}> {
  getTotal(): { totalModules: number, totalKw: number } {
    const { drawingManager: { roofs }, bom: { partsList }, engineering, projectConfiguration: { projectEnvConfig }, moduleSelector: { modelData: { watts } } } = this.props;
    const totalWatts = projectEnvConfig?.aurora_project_id ? projectEnvConfig?.aurora_module_wattage : watts;
    if (roofs && Object.keys(roofs).length) {
      return this.getFromDesign(roofs);
    }

    if (partsList.length) {
      return this.getFromBom();
    }
    if (engineering !== null && engineering.projectInfo) {
      return this.getFromEngineering();
    }

    if (Object.keys(projectEnvConfig).length) {
      return {
        totalModules: projectEnvConfig.total_modules,
        totalKw: this.getTotalKw(projectEnvConfig.total_modules, totalWatts),
      };
    }

    return {
      totalModules: 0,
      totalKw: 0,
    };
  }

  getFromDesign(roofs: { [roofId: string]: drawingManagerRoof }) {
    const { watts } = this.props;
    if (roofs && Object.keys(roofs).length) {
      const totalModules = getTotalModules(roofs);

      return {
        totalModules,
        totalKw: this.getTotalKw(totalModules, watts),
      };
    }
  }

  getFromBom() {
    const { bom: { total_modules, total_watts } } = this.props;

    return {
      totalModules: total_modules,
      totalKw: Number((total_watts / 1000).toFixed(2)),
    };
  }

  getFromEngineering() {
    const { engineering: { projectInfo: { total_modules, total_watts } } } = this.props;
    return {
      totalModules: total_modules,
      totalKw: Number((total_watts / 1000).toFixed(2)),
    };
  }

  getTotalKw(totalModules: number, watts: number): number {
    return parseFloat((totalModules * watts / 1000).toFixed(2));
  }

  render() {
    const { totalModules, totalKw } = this.getTotal();
    return (
      <div className="total-modules-kw">
        <div className="value total-modules-quantity">
          {totalModules || 0}
        </div>
        <div className="label">
          Modules,
        </div>
        <div className="value total-kw-quantity">
          {totalKw || 0}
        </div>
        <div className="label">
          KW
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    watts: state.moduleSelector.modelData.watts,
    bom: state.bom,
    drawingManager: state.drawingManager,
    engineering: state.engineering,
    projectConfiguration: state.projectConfiguration,
    moduleSelector: state.moduleSelector,
  };
}

export default connect(mapStateToProps)(TotalModulesInfo);
