import AppHistory from '__common/modules/history';
import { getProductName, isNxtTilt, isSMAscenderFlush, isSMHD, isSMLT } from '__common/constants/products';
import { state } from '__common/store';
import { anyPanelsDrawn, allRoofsHasPanels, allRoofsHaveRoofPitch } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { panelsEditorEnabled } from 'projectDesign/projectDesign';

export function changeUrlOnFirstAutoSave(projectId: string) {
  const { projectConfiguration: { productId } } = state();

  let productName;

  if (isSMLT(productId) || isSMHD(productId) || isSMAscenderFlush(productId)) {
    productName = getProductName(9);
  } else {
    productName = getProductName(productId);

  }

  if (!location.href.includes(projectId)) {
    AppHistory.replace(`/project/design/${productName}/${projectId}`);
  }
}

export function isOnDesignPage() {
  return location.href.includes('project/design');
}

export function shouldAutoSave() {
  const { saveLoadProject: { autoSaving, isLoading } } = state();
  return anyPanelsDrawn() && isOnDesignPage() && !autoSaving && !isLoading && !panelsEditorEnabled() && allRoofsHasPanels() && allRoofsHaveRoofPitch();
}
