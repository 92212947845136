import React from 'react';
import { connect } from 'react-redux';
import { CHANGE_STRUCTURE_TYPE_TILT, } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import { REMOVE_PANELS } from '__editor/panelsEditor/actions';
import { dispatch } from '__common/store';
import { ascenderTiltRange } from './projectConfiguration/fields/tilt';
import { getStructuretype } from './StructureTypeChange';
import { REMOVE_PANELS_ON_ROOF } from 'actions';

type Props = {
  changeStructureTypeTilt:(structureType: number, tilt: number, roofs: any, panels: any) => void,
  panels: panelInState[],
  structureTypeInfo: structureTypeInfoState,
  roofs: { [roofId: string]: drawingManagerRoof },
  structureType: number,
};

interface state {
  tilt: number,
  isValidTilt: boolean
}

class StructureTypeTilt extends React.Component<Props, state> {

  constructor(props: Props) {
    super(props);
    const {structureType, structureTypeInfo}  = props;
    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    const tilt = structureTypeInfo[structureType-1]['tilt'];
    this.state = {
      tilt: tilt ,
      isValidTilt: min <= tilt && tilt <= max,
    }
  }

  componentDidUpdate(previousProps, prevState,) {
    if (prevState.tilt !== this.props.structureTypeInfo[this.props.structureType-1].tilt) {
      this.setState({ ...this.state, tilt: this.props.structureTypeInfo[this.props.structureType-1].tilt , isValidTilt: true });
    }
}

  onStructureTypeTiltChange = (structureType: number, val: number,) => {
    const {roofs, panels} = this.props;
    let isValid = false;
    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    if(val >= min && val <= max) {
      dispatch(CHANGE_STRUCTURE_TYPE_TILT(structureType, val));
      isValid = true;
      let panelIds = [];
      if(roofs && Object.keys(roofs).length > 0) {
        Object.keys(roofs).map(roofId => {
          panelIds = [];
          const roof = roofs[roofId];
          const panels = roof.panels;
          if(panels && panels.length > 0) {
            panels.map(panel => {
              if(panel.panelConfig == structureType) {
                panelIds.push(panel.id)
              }
            })
          }
          if(panelIds && panelIds.length > 0) dispatch(REMOVE_PANELS_ON_ROOF(panelIds, Number(roofId)));
        })
      }
      panelIds = []
      if(panels && panels.length > 0) {
        panels.map(panel => {
          if(panel.panelConfig == structureType) {
            panelIds.push(panel.id);
          }
        })
      }
      dispatch(REMOVE_PANELS(panelIds));
    }
    this.setState({ ...this.state, tilt: val, isValidTilt: isValid });
  }

  renderWarningMessage(structureType: number) {
    if (this.state.isValidTilt) {
      return null;
    }

    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    const errorMessage = `Tilt must be greater than ${min-1} and less than  ${max+1}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  getFamily = (structureType: number) => {
    if(structureType == 1 || structureType == 2) return '1p';
    else return '2p';
  }

  _hasPanels = (structureType: number) => {
    const { panels, roofs } = this.props;

    let panelIds = [];
    if(roofs && Object.keys(roofs).length > 0) {
      Object.keys(roofs).map(roofId => {
        const roof = roofs[roofId];
        const panels = roof.panels;
        if(panels && panels.length > 0) {
          panels.map(panel => {
            if(panel.panelConfig === structureType) {
              panelIds.push(panel.id)
            }
          })
        }
      })
    }
    if(panels && panels.length > 0) {
      panels.map(panel => {
        if(panel.panelConfig === structureType) {
          panelIds.push(panel.id);
        }
      })
    }

    return panelIds.length > 0;
  }

  shouldConfirm = (value: number, structureType: number) => {
    const {min, max} = ascenderTiltRange[this.getFamily(structureType)];
    if (value >= min && value <= max) {
      return this._hasPanels(structureType);
    }
    else {
      return false;
    }
  }

  render() {
    const {structureType} = this.props;
    const {tilt} = this.state;

    if (structureType) {
      return (
        <>
          <div  className="obstruction">
          <div className="input-label">Tilt (degree) :</div>
            <ConfirmationTextField
              value={tilt}
              onConfirm={(value: number) => this.onStructureTypeTiltChange(structureType, value)}
              title="Are you sure you want to change the tilt?"
              content={`Current ${getStructuretype(structureType)} array(s) in this project  will be lost.`}
              shouldConfirm={true}
              shouldConfirmCb={(value) => this.shouldConfirm(value, structureType)}
              textFieldProps={{
                lineDirection: 'center',
                type: 'number',
                fullWidth: false,
              }}
            />
          {this.renderWarningMessage(structureType)}
        </div>
        </>      
        )};

    document.body.click();
    return null;
  }
}

const mapStateToProps = (appState: appState) => ({
  roofs: appState.drawingManager.roofs,
  panels: appState.panels.panels,
  structureTypeInfo: appState.projectConfiguration.projectEnvConfig.structure_type_info,
  structureType: appState.projectConfiguration.projectEnvConfig.structure_type,
});

export default connect(mapStateToProps,)(StructureTypeTilt);

