export const apiField = 'rail_arrangement_type';

export const options = {
  shared3rail: {
    value: 1,
    name: 'SHARED 3 RAIL',
  },
  _4rail: {
    value: 2,
    name: '4 RAIL',
  },
  _5rail: {
    value: 3,
    name: '5 RAIL',
  },
  _6rail: {
    value: 4,
    name: '6 RAIL',
  },
  _8rail: {
    value: 5,
    name: '8 RAIL',
  },
};

export const railArrangementType = (clamp_type, new_top_chord = false) => {
  return {
  type: 'Select',
  apiField: 'rail_arrangement_type',
  label: 'rail_arrangement_type',
  data: [
    ...(clamp_type === "04" ? [] : [options.shared3rail]),
    options._4rail,
    ...(clamp_type === "04" || new_top_chord ? [] : [options._5rail]),
    options._6rail,
    ...(new_top_chord ? [options._8rail] : []),
  ],
  }
};
