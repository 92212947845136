import { createReducer } from '__common/utils/helpers';
import { YourProjectsActionTypes } from 'actionsConstants';
import _ from 'lodash';

export const yourProjectsState: yourProjectsState = {
  data: null,
  paginationStart: 0,
  totalProjects: 0,
  paginationTotal: 0,
  paginationPage: 1,
  rowsPerPage: 10,
  filterString: '',
  kilowatts: 0,
  miles: 0,
  trees: 0,
  sortedField: 'created',
  sortMethod: 'asc',
  selectedProjects: {},
  combinedProjectId: '',
  combinedPartsList: [],
  combinedProjects: {},
  showOldProjects: false,
  areLoading: false,
  gettingOldUbProject: false,
  hasOldUb: false,
  filterSet: {
    project_created_range: {from: undefined, to: undefined},
    project_wattage_range: {from: undefined, to: undefined},
    project_price_range: {from: undefined, to: undefined},
    project_state: [],
    module: [],
    product: [],
  },
  appliedFilters: {},
};

export default {
  yourProjects: createReducer(yourProjectsState, {
    [YourProjectsActionTypes.LOAD_YOUR_PROJECTS_DATA](state, action) {
      const { data } = action.payload;
      return { 
        ...state, 
        areLoading: false,
        data: data.results,
      };
    },
    [YourProjectsActionTypes.GET_USER_PROJECTS](state) {
      return { ...state, areLoading: true };
    },
    [YourProjectsActionTypes.SET_PROJECT_CREATED_LOWER_RANGE](state: yourProjectsState, action) {
      let newState = { ...state};
      newState.filterSet.project_created_range.from = action.payload.from;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_CREATED_UPPER_RANGE](state, action) {
      let newState = { ...state};
      newState.filterSet.project_created_range.to = action.payload.to;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_WATTAGE_LOWER_RANGE](state, action) {
      let newState = { ...state};
      newState.filterSet.project_wattage_range.from = action.payload.from;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_WATTAGE_UPPER_RANGE](state, action) {
      let newState = { ...state};
      newState.filterSet.project_wattage_range.to = action.payload.to;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_PRICE_LOWER_RANGE](state, action) {
      let newState = { ...state};
      newState.filterSet.project_price_range.from = action.payload.from;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_PRICE_UPPER_RANGE](state, action) {
      let newState = { ...state};
      newState.filterSet.project_price_range.to = action.payload.to;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_STATE](state, action) {
      
      let newState = { ...state};
      newState.filterSet.project_state = action.payload.state;
      return newState;
    },
    [YourProjectsActionTypes.SET_PROJECT_PRODUCT](state, action) {
      
      let newState = { ...state};
      newState.filterSet.product = action.payload.state;
      return newState;
    },
    [YourProjectsActionTypes.SET_MODULE](state, action) {
      let newState = { ...state};
      newState.filterSet.module = action.payload.module;
      return newState;
    },
    [YourProjectsActionTypes.APPLY_FILTERS](state, action) {
      let newState = { ...state};
      newState.appliedFilters = action.payload.applyFilters;
      return newState;
    },
    [YourProjectsActionTypes.INIT_USER_PROJECTS](state) {
      return { ...state, areLoading: true };
    },
    [YourProjectsActionTypes.CANNOT_GET_PROJECTS](state) {
      return { ...state, areLoading: false };
    },
    [YourProjectsActionTypes.SET_ROWS_PER_PAGE](state, action) {
      const { rowsPerPage } = action.payload;
      return { ...state, rowsPerPage };
    },
    [YourProjectsActionTypes.FILTER_BY_STRING](state, action) {
      const { filterString } = action.payload;
      return { ...state, filterString };
    },
    [YourProjectsActionTypes.SET_INFO_DATA](state, action) {
      const { totalProjects, kilowatts, miles, trees } = action.payload;
      return { ...state, totalProjects, kilowatts, miles, trees };
    },
    [YourProjectsActionTypes.SET_YOUR_PROJECTS_PAGINATION_START](state, action) {
      const { paginationStart } = action.payload;
      return { ...state, paginationStart };
    },
    [YourProjectsActionTypes.SET_YOUR_PROJECTS_PAGINATION_TOTAL](state, action) {
      const { paginationTotal } = action.payload;
      return { ...state, paginationTotal };
    },
    [YourProjectsActionTypes.SET_YOUR_PROJECTS_TABLE_PAGE](state, action) {
      const { paginationPage } = action.payload;
      return { ...state, paginationPage };
    },
    [YourProjectsActionTypes.SET_YOUR_PROJECTS_SORTING](state, action) {
      const { sortedField, sortMethod } = action.payload;
      return { ...state, sortedField, sortMethod };
    },
    [YourProjectsActionTypes.SET_SELECTED_PROJECTS](state, action) {
      return { ...state, selectedProjects: action.payload.selectedProjects };
    },
    [YourProjectsActionTypes.POPULATE_COMBINED_PROJECTS](state, action) {
      const { combinedProjectId, combinedPartsList, combinedProjects } = action.payload;
      return {
        ...state,
        combinedProjectId,
        combinedPartsList,
        combinedProjects,
      };
    },
    [YourProjectsActionTypes.YOUR_PROJECTS_LOAD_PREFERENCES](state, action) {
      const { sortedField, sortMethod, rowsPerPage } = action.payload;
      return { ...state, sortedField, sortMethod, rowsPerPage };
    },
    [YourProjectsActionTypes.SHOW_NEW_PROJECTS](state) {
      return { ...state, showOldProjects: false };
    },
    [YourProjectsActionTypes.SHOW_OLD_PROJECTS](state) {
      return { ...state, showOldProjects: true };
    },
    [YourProjectsActionTypes.CHECK_UB1_PROJECTS](state) {
      return { ...state, gettingOldUbProject: true };
    },
    [YourProjectsActionTypes.CHECK_UB1_PROJECTS_FAILED](state) {
      return { ...state, gettingOldUbProject: false };
    },
    [YourProjectsActionTypes.SET_UB1_PROJECTS](state, action: { payload: yourProjectsApiResponse }) {
      const hasOldUb = action.payload.results.length;
      return { ...state, hasOldUb, gettingOldUbProject: false };
    },
    [YourProjectsActionTypes.CLEAR_YOUR_PROJECTS_FILTER_DATA](state: yourProjectsState):yourProjectsState {
      return { ...state, 
        filterSet: {
          project_created_range: {from: undefined, to: undefined},
          project_wattage_range: {from: undefined, to: undefined},
          project_price_range: {from: undefined, to: undefined},
          project_state: [],
          module: [],
          product: []
        },
      };
    },
    [YourProjectsActionTypes.SET_FILTER_DATA](state, action) {
      const { filterSet } = action.payload;
      return { ...state, filterSet };
    },
    [YourProjectsActionTypes.CLEAR_YOUR_PROJECT](state) {
      return {
        data: null,
        paginationStart: 0,
        totalProjects: 0,
        paginationTotal: 0,
        paginationPage: 1,
        rowsPerPage: 10,
        filterString: '',
        kilowatts: 0,
        miles: 0,
        trees: 0,
        sortedField: 'created',
        sortMethod: 'asc',
        selectedProjects: {},
        combinedProjectId: '',
        combinedPartsList: [],
        combinedProjects: {},
        showOldProjects: false,
        areLoading: false,
        gettingOldUbProject: false,
        hasOldUb: false,
        filterSet: {
          project_created_range: {from: undefined, to: undefined},
          project_wattage_range: {from: undefined, to: undefined},
          project_price_range: {from: undefined, to: undefined},
          project_state: [],
          module: [],
          product: []
        },
        appliedFilters: {},
      };
    },
  }),
};
