import { SubCategoryField } from "./types/roofTypeSubCategoryFieldType";

export const apiField = 'sub_category';

function makeConfig(data, label) : SubCategoryField {
  return{
    label: label,
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data
}};


const coatedOptions = [
  {
    value: 1,
    name: 'SOLVENT-BASED',
  },
  {
    value: 2,
    name: 'SILICONE-BASED',
  },
]

const sbsOPtions = [
  {
    value: 1,
    name: 'REGULAR',
  },
  {
    value: 2,
    name: 'TORCHED',
  },
]

export default function subcategory(roof_type){ 
  if (roof_type == 22) {
    const label = 'SOLVENT-BASED/SILICONE-BASED'
    return makeConfig(coatedOptions, label);
    }
  else if (roof_type == 20){
    const label = 'REGULAR/TORCHED'
    return makeConfig(sbsOPtions, label)
  }
  else
  {
    return [];
  }
}
