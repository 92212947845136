import { checkPanelExposure, direction } from '__common/components/exposure/singlePanelExposureCheck';
import { getRTreeForExposure } from './utils/collision';

interface FORCE_EXPOSURE_CHANGED {
  [id: string]: boolean;
}

interface superPanel {
  status: boolean;
  direction: direction;
}
interface superPanelIds {
  [id: string]: superPanel;
  [id: number]: superPanel;
}

export const recheckExposureForNewPanelsNeighbours = (
  panelsInCurrentState: panelInState[],
  panelsToRecheck: number[],
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  metersPerPixel: number,
  columnSpacing: number,
  rowSpacing: number,
  bgOffset: pixelPoint,
  roofPitch: string,
  productId: number,
  mapType: string,
) => {
  const panelsToRecheck2: (number|number[])[] = [];
  const superPanelsIdsCollection: superPanelIds[] = [];

  // we use a separate rTree for collision detection and exposure
  // because in collision we care about row and column spacing
  // and for exposure checking we don't.
  const exposureRTree = getRTreeForExposure(panelsInCurrentState);
  
  panelsToRecheck.map(panelId => {
    const panelWithExpo = panelsInCurrentState.find((pan) => pan.id === panelId);
    if (panelWithExpo) {
      const { isExposed, nearestPanels, superPanelsIds } = checkPanelExposure(
        panelWithExpo,
        roofEdges,
        roofId,
        cords,
        zoom,
        bgRotationDegrees,
        metersPerPixel,
        columnSpacing,
        rowSpacing,
        bgOffset,
        roofPitch,
        productId,
        mapType,
        exposureRTree,
      );
      if (panelWithExpo.exposed !== isExposed) {
        panelWithExpo.exposed = isExposed;
      }

      panelsToRecheck2.push(nearestPanels.recheck);
      panelsToRecheck2.push(panelId);
      superPanelsIdsCollection.push(superPanelsIds);
      superPanelsIdsCollection.push(nearestPanels.forceExposureChanged);
    }
  });

  const forceExposureChanged = getForcedExposure(superPanelsIdsCollection);

  return {
    panels: panelsInCurrentState,
    panelsToRecheck: panelsToRecheck2,
    forceExposureChanged,
  };
};

const getForcedExposure = (superPanelsIdsCollection: superPanelIds[]): FORCE_EXPOSURE_CHANGED => {
  const forceExposureChanged = superPanelsIdsCollection
  .reduce<{ [id: string]: superPanel[] }>((acc, panels) => {

    Object.keys(panels).map((panelId) => {
      if (!acc[panelId]) {
        acc[panelId] = [panels[panelId]];
      }

      acc[panelId].push(panels[panelId]);
    });

    return acc;
  },                                      {});

  return Object.keys(forceExposureChanged)
  .reduce<FORCE_EXPOSURE_CHANGED>((acc, panelId) => {

    const status = forceExposureChanged[panelId].some((status) => status.status);

    acc[panelId] = status;

    return acc;
  },                              {});
};
