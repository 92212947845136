import React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { initializeGoogleLocationSearch } from '__editor/googleMapsRoofsSelector/components/locationSearch/locationSearch';
import { setBingLocationSearch } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';

type Props = {
  dispatch: Function,
  mapType: string,
  location: string,
};

type State = {
  currentLocation: string,
};

class LocationInput extends React.Component<Props, State> {
  input: any;

  state = {
    currentLocation: '',
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  componentDidMount() {
    const { mapType, location } = this.props;

    if (mapType === 'bing') {
      setBingLocationSearch(this.input.current);
    }

    if (mapType === 'google') {
      initializeGoogleLocationSearch(this.input.current);
    }

    this.setState({ currentLocation: location });
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (prevProps.location !== location
    ) {
      this.setState({ currentLocation: location });
    }
  }

  onChange = (e) => {
    this.setState({ currentLocation: this.input.current.value });
  }

  render() {
    return (
      <div id="search-location" className="buttons-containers search-location-bar">
        <input ref={this.input} id="pac-input" className="controls" type="text" onChange={this.onChange} value={this.state.currentLocation} placeholder="Enter a location" />
        <Button flat={true} className="roofs-selector-button"><SVG src={require('assets/media/icons/icon_search.svg')} /></Button>
      </div>
    );
  }
}

function mapStateToProps(app: appState) {
  return {
    location: app.roofsSelectorMap.location,
  };
}

export default connect(mapStateToProps)(LocationInput);
