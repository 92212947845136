import loadSharingTypeTooltip from "../fieldsTooltips/loadSharingTypeTooltip";

type LoadSharingOption = {
    value: string;
    name: string;
  };
export const loadSharingOptions = {
    default: {
      value: 'default',
      name: 'STANDARD',
    },
    fmg: {
      value: 'fmg',
      name: 'FMG',
    },
    dsa: {
      value: 'dsa',
      name: 'DSA',
    },
  };
export interface LoadSharingField {
    type: string;
    apiField: string;
    missing?: any;
    label: string;
    tooltip: () => string;
    data: LoadSharingOption[];
  }

export const apiField = 'load_sharing_type';

const config: LoadSharingField = {
    label: 'loadSharingType',
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: loadSharingTypeTooltip,
    data: [
      loadSharingOptions.default,
      loadSharingOptions.fmg,
      loadSharingOptions.dsa,
    ],
  };
  
  export default config;

