import { dispatch, state } from "__common/store";
import { PANELS_SET_ROTATION } from "../backgroundActions";

export const loadPanelsRotationDegrees = () => {
  const { background: { selectedRoofId }, drawingManager: { roofs } } = state();
  const panelsRotationDegrees = roofs[selectedRoofId].panelsRotationDegrees;
  if(!panelsRotationDegrees) {
    dispatch(PANELS_SET_ROTATION(0));
  }
  else {
    dispatch(PANELS_SET_ROTATION(panelsRotationDegrees));
    }
};