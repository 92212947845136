export const apiField = 'foundation_length';

export enum FOUNDATION_LENGTH {
  PILE_10_5 = 10.5,
  PILE_12_5 = 12.5,
  PILE_15 = 15,
}

export type foundationLengthConfig = {
  type: string;
  label: string;
  apiField: string;
  // note: {
  //   message: string;
  //   shouldShow: boolean;
  // };
  tooltip: () => string;
  data: {
    value: number;
    name: string;
  }[];
  missing?: boolean;
};

export const makeOption = (defaultLength: number, optionalLength: number[]): foundationLengthConfig['data'] => {

  const _optional = [];
  optionalLength.map((item) => {
    _optional.push({
      value: item,
      name: `${item}`,
    });
  });
  return [
    {
      value: defaultLength,
      name: `${defaultLength} ${' Default'}`,
    },
    ...(_optional),
  ];
};

// export const note = {
//   message: `Warning: A foundation length shorter than the one recommended is selected.`,
// };

const makeConfig = (defaultLength: number = 0, optionalLength: number[] = []): foundationLengthConfig => {
  return {
    type: 'Select',
    label: 'foundation_length',
    apiField,
    tooltip: () => `foundation length`,
    data: makeOption(defaultLength, optionalLength),
  };
};

export default makeConfig;
