import { state } from '__common/store';
import { inchesToFeet } from '__common/calculations/inchesToFeet';
import { isAscender, isNxtTilt, isSMTilt, products } from '__common/constants/products';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { getBuildingHeight } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

export const windZonesDistanceForGableAndHipAscender = () => {
  const { projectConfiguration: { projectEnvConfig: {  building_height} } } = state();
  return  2 * building_height;
}

export const windZonesDistanceForGableAndHip = () => {
  const { projectConfiguration: { projectEnvConfig: { shortest_building_length, building_height ,} , productId, projectVersion} } = state();
  // the width of a wind zone parallel to the building's edge.
  if(isAscender(productId)) {
    return  metersToFeets(windZonesDistanceForGableAndHipAscender());
  }else if(adjustableTiltSystem(projectVersion)&&(isSMTilt(productId) || isNxtTilt(productId))){
    return 2 * building_height;
  }
  return Math.max(0.04 * shortest_building_length, 3, Math.min(0.1 * shortest_building_length, 0.4 * building_height));
};

export const WIND_ZONE_FORMULA = 'max(0.04WS, 3ft, min(0.1WS, 0.4h))';

export const restrictedZoneDistance = (productId: number) => {
  return inchesToFeet(2 * arrayHeightAboveRoofInch(productId)) || 0;
};

export const exposureZoneDistance = () => {
  const buildingHeightFt = getBuildingHeight();
  return (0.5 * buildingHeightFt) || 0;
};

export const arrayHeightAboveRoofInch = (productId: number) => {
  const SMLT = 5.19;
  const SM = 6.06;
  const SMHD = 6.44;
  const SFM = 4;
  const KILLERWRAIL = 6.06;
  const KILLERWRAILHD = 6.44;
  const KILLERWRAILLT = 5.19;
  let moduleHeighAboveRoof: number;

  switch (productId) {
    case products.solarmount_2:
    case products.sunframe:
    case products.sm_tilt_pr:
    case products.solarmount_ascender_flush:
      moduleHeighAboveRoof = SM;
    case products.solarmount_hd:
      moduleHeighAboveRoof = SMHD;
    case products.solarmount_lt:
    case products.sm_tilt_pr_lt:
      moduleHeighAboveRoof = SMLT;
    case products.sfmEnhanced:
    case products.sfmInfinity:
    case products.metal_x:
      moduleHeighAboveRoof = SFM;
    case products.nxt_umount:
      moduleHeighAboveRoof = KILLERWRAIL;
    case products.nxt_horizon_lt:
      moduleHeighAboveRoof = KILLERWRAILLT; 
    case products.nxt_horizon_hd:
      moduleHeighAboveRoof = KILLERWRAILHD;
  }

  return Math.min(Math.max(moduleHeighAboveRoof, 5), 10);
};


