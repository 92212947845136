import { clearRoofWithWarning } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { dispatch, state } from '__common/store';
import { getMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { SET_LOCATION } from 'actions';
import { setRoofsSelectorPlaceMarker } from '../../../components/roofsSelector/components/roofsSelectorLocationSearch/roofsSelectorLocationSearch';
import { FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapActions';

let autocomplete;
let placeMarker: google.maps.Marker;

function placeChanged() {
  const map = getMap();
  if (map) {
    const place = autocomplete.getPlace();
    setTimeout(() => {
      if (place && place.geometry && place.geometry.location) {
        map.setCenter(place.geometry.location);
        setPlaceMarker(place.geometry.location);
        changeCityAndZipCode(place.geometry.location);
      } else if (place && place.geometry && place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
        setPlaceMarker(place.geometry.viewport);
        changeCityAndZipCode(place.geometry.viewport);
      } 
  
      map.setZoom(18);
  
      if (place && place.formatted_address) {
        dispatch(SET_LOCATION(place.formatted_address));
      }
    });
  }
}

export const changeCityAndZipCode = (location: google.maps.LatLng) => {
  const { projectConfiguration: { projectEnvConfig: { building_code, risk_category }, productId } } = state();
  dispatch(FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG(location.lat(), location.lng(), building_code, productId, risk_category));
};

export const initializeGoogleLocationSearch = (ref: HTMLInputElement) => {
  const map = getMap();
  const input: any = document.getElementById(ref.id);
  autocomplete = new google.maps.places.Autocomplete(input);
  autocomplete.addListener('place_changed', () => {
    clearRoofWithWarning(placeChanged);
  });

  if (map) {
    autocomplete.bindTo('bounds', map);
  }
};

export function setPlaceMarker(location: google.maps.LatLng) {
  removePlaceMarker();
  setRoofsSelectorPlaceMarker({ lat: location.lat(), lng: location.lng() });
  const map = getMap();
  
  placeMarker = new google.maps.Marker({
    position: location,
    map,
  });
}

function removePlaceMarker() {
  if (placeMarker) {
    placeMarker.setMap(null);
    placeMarker = null;
  }
}

export function hidePlaceMarker() {
  if (placeMarker) {
    placeMarker.setVisible(false);
  }
}

export function showPlaceMarker() {
  if (placeMarker) {
    placeMarker.setVisible(true);
  } 
}
