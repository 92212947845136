

import { PanelsActionTypes } from 'actionsConstants';
import { dispatch, state } from '__common/store';
import { roofHasObstructions } from '__editor/panelsEditor/components/obstructions/obstructions';
import { RECHECK_PANELS_OBSTRUCTION_ZONE } from '__editor/panelsEditor/actions';

const actions = [
  PanelsActionTypes.REMOVE_PANELS, 
  PanelsActionTypes.ADD_PANELS,
];

export const addPanelsMiddleware = store => next => action => {
  next(action);

  const { background: { selectedRoofId } } = state();

  if (actions.includes(action.type) && roofHasObstructions(selectedRoofId)) {
    dispatch(RECHECK_PANELS_OBSTRUCTION_ZONE());
  }
};
