// part mutating here
// TO REFACTOR !!!! 

import { isNxtHorizon, isRMGridFlex } from "__common/constants/products";

export function restorePartQty(state: bomState, part: bomPart) {
  const partNumber = part.number;
  const { extraParameters } = state;

  if (extraParameters && extraParameters.quantities && extraParameters.quantities[getPartNotBasedOnColor(extraParameters.quantities, partNumber, false, isRMGridFlex(extraParameters.productId))]) {
    const number = getPartNotBasedOnColor(extraParameters.quantities, partNumber, false, isRMGridFlex(extraParameters.productId));
    const { qty, suggestedQty } = extraParameters.quantities[number];

    if (suggestedQty === part.suggested_quantity) {
      part.quantity = qty;
    }

    // delete assigned qty from storage
    delete extraParameters.quantities[partNumber];

    return;
  }

  // for NXT Rail parts don't change Quantity
  // Part Number format changed from 'XYZ168M' to '168XYZM' in NXT for few rail parts
  // 084RLD1, 208RLD1, 168RLD1, 084RLM1, 168RLM1, 208RLM1, 246RLM1, 246RLD1, 185RLD1, 185RLM1, 096RLD1, 096RLM1

  if(isRMGridFlex(extraParameters.productId) && part.number == '360031'){
    return;
  }

  if(extraParameters && extraParameters?.selections?.selected_rails?.some(({length, selected}) => selected && part.number.slice(0, 3).includes(`${length}`)) && isNxtHorizon(extraParameters.productId)) {
    return;
  }

  const flattenedPartList = state.partsList.reduce((acc, part) => { acc[part.number] = part.quantity; return acc; }, {});

  const partInPartList = state.partsList.find((part) => part.number === getPartNotBasedOnColor(flattenedPartList, partNumber, true, isRMGridFlex(extraParameters.productId)));

  if (
    (part.type === 'Rail' || part.type === 'Splice') && 
    extraParameters && 
    extraParameters.quantities && 
    typeof extraParameters.quantities === 'object' && 
    !extraParameters.quantities[partNumber] && 
    Object.keys(extraParameters.quantities).length &&
    partInPartList
  ) {
    part.quantity = partInPartList.quantity;
  }
}

function getPartNotBasedOnColor(quantities: bom_quantities, partNumber, isRail: boolean, isRmGridflex: boolean) {
  const flattenedQuantities = Object.keys(quantities);
  const flattenedIndex = flattenedQuantities.findIndex(number => number.includes(partNumber.slice(isRail ? 3 : 0, -1)));
  return flattenedQuantities[flattenedIndex];
}

