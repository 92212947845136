import { createReducer } from '__common/utils/helpers';
import { DisableAccountActionTypes } from 'actionsConstants';

export let disableAccountState: disableAccountState = {
  sending: false,
  success: false,
  error: false,
};

export default {
  disableAccount: createReducer(disableAccountState, {
    [DisableAccountActionTypes.DISABLE_ACCOUNT](state) {
      return { ...state, sending: true };
    },
    [DisableAccountActionTypes.DISABLE_ACCOUNT_SUCCESS](state) {
      return { ...state, sending: false, success: true };
    },
    [DisableAccountActionTypes.DISABLE_ACCOUNT_ERROR](state) {
      return { ...state, sending: false, erorr: true };
    },
    [DisableAccountActionTypes.DISABLE_ACCOUNT_CLEAR]() {
      return { sending: false, success: false, error: false };
    },
  }),
};
