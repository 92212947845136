
export default () => {
    return `
    <div>
        <div>
            <strong>FMG - </strong>
            <strong>EWA Factors:</strong>
            <ul>
                <li>1.5 for Corner Modules</li>
                <li>2.5 for Edge Modules</li>
                <li>9 for Interior Modules</li>
            </ul>
        </div>
        <div>
            <strong>DSA - </strong>
            <strong>EWA Factors:</strong>
            <ul>
                <li>1 for Corner Modules</li>
                <li>1.5 for Edge Modules</li>
                <li>4 for Interior Modules (one panel from edge)</li>
                <li>6 for Interior Modules (more than one panel from edge)</li>
            </ul>
        </div>
    </div>
    `;
}