import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';

export default () => {
  const { userPreferences: { preferred_span, stagger_attachments, rafter_spacing_inches, material_thickness } } = state();
  let staggered;

  if (stagger_attachments === null) {
    staggered = true;
  } else {
    staggered = stagger_attachments;
  }

  const metalXConfig = {
    zipcode: '',
    building_code: 2,
    wind_speed: 115,
    client_name: '',
    snow_load: 5,
    seismic_ss: 0.46,
    elevation: 5499,
    building_height: 30,
    roof_type: 2,
    material_thickness: material_thickness || 3,
    wind_exposure: 'B',
    stagger_attachments: staggered,
    preferred_span: preferred_span || 72,
    rafter_spacing_inches: rafter_spacing_inches || 24,
    building_type: gable.value,
    topographical_factor_kzt: 1,
    attachment_type: 4,
    // shortest_building_length: 20,
  };
  return metalXConfig;
};
