import { InputUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { round } from 'lodash';
import { inchesToMeters } from 'maths';
import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';

export const apiField = 'parapet_height_input';

export const rmIFIParapetHeight = (inputUnit: number = InputUnit.CUSTOMARY) => { 
  return {
    min: 0,
    max: isMetricUnit(inputUnit) ? round(inchesToMeters(100), 1) : 100,
    type: 'Number',
    label: isMetricUnit(inputUnit) ? 'parapetHeightMetric' : 'parapetHeight',
    missing: undefined,
    apiField,
    tooltip: parapetHeightTooltip,
    data: [],
  } 
}

export default rmIFIParapetHeight;

export const rmIFIParapetHeightField = rmIFIParapetHeight();

export const rmIFIParapetHeightMetricField = rmIFIParapetHeight(InputUnit.METRIC);

