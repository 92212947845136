import * as React from 'react';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HelioscopeProjectsList from 'helioscopeImport/components/helioscopeProjectsList/components/HelioscopeProjectsListComponent';
import LoaderComponent from '__common/components/LoaderComponent';
import SVG from 'react-inlinesvg';
import TextField from 'react-md/lib/TextFields/TextField';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  CLEAR_HELIOSCOPE_PROJECT_LIST, FETCH_PROJECTS_LIST_REQUEST, PROJECTS_LIST_FILTER_PROJECTS,
} from 'helioscopeImport/components/helioscopeProjectsList/HelioscopeProjectsListActions';

type Props = {
  dispatch: Function;
  projectsList: any;
  match: any;
};

export class HelioscopeImport extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;

    dispatch(FETCH_PROJECTS_LIST_REQUEST());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(CLEAR_HELIOSCOPE_PROJECT_LIST());
  }

  handleSearch = (filter: React.ReactText) => {
    const { dispatch } = this.props;

    dispatch(PROJECTS_LIST_FILTER_PROJECTS(filter));
  }

  pageHeader = () => {
    return (
      <div className="page-header">
        <div className="page-title">
          <FormattedMessage id="my.helioscope.projects" />
        </div>
      </div>
    );
  }

  tableFilters = () => {
    return (
      <div className="helioscope-projects-filter">
        <div className="label">
          Filter
        </div>
        <div className="icon">
          <SVG src={require('assets/media/icons/icon_search.svg')} />
        </div>
        <TextField
            id="searchProject"
            className="search-project-container"
            lineDirection="right"
            onChange={this.handleSearch}
        />
      </div>
    );
  }

  renderContent = () => {
    const {
      projectsList,
      dispatch,
    } = this.props;

    return (
      
      <div className="body">
        {this.tableFilters()}
        <HelioscopeProjectsList
          dispatch={dispatch}
          projectsList={projectsList}
        />
      </div>
    );
  }

  render() {
    const {
      projectsList,
      match: { productId, projectId },
    } = this.props;
    return (
      <div className="app-content">
        <Header productId={productId} projectId={projectId} />
        <div key="Private" className="helioscope_import">
          {this.pageHeader()}
          {projectsList && projectsList.projects && projectsList.projects.length ? this.renderContent() : <LoaderComponent />}
        </div>
        <Footer />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    projectsList: state.projectsList,
  };
}

export default connect(mapStateToProps)(HelioscopeImport);
