import { isASCE716or722BuildingCode } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { isResidentialProduct, isCommercialProduct, isRMIFIProduct, isEcoFoot2Plus, isRM10Evolution, isRM10, isRMDT, isRM5,  isAscender, isRmGridflex10 } from '__common/constants/products';
import { Asce716ResidentialModuleBits } from './asce716ResidentialModuleBits';
import { Asce716CommercialModuleBits } from './asce716CommercialModuleBits';
import { BasicModuleBits } from './basicModuleBits';
import { ModuleBits } from './baseTypes';
import { RMIFIModuleBits } from './RMIFIModuleBits';
import { AscenderModuleBits } from './ascenderModuleBits';
import { Asce716ResidentialModuleBitsV2 } from './asce716ResidentialModuleBitsV2';
import { state } from '__common/store';

export function getDecoder(buildingCode: number, productId: number, roofPitch: number, schemaVersion = 1): ModuleBits {

  if (isRMIFIProduct(productId)) {
    return new RMIFIModuleBits(schemaVersion);
  }

  if (isASCE716or722BuildingCode(buildingCode) || isRM10Evolution(productId) || isEcoFoot2Plus(productId) || isRM10(productId) || isRMDT(productId) || isRM5(productId) || isRmGridflex10(productId)) {

    if (isResidentialProduct(productId)) {
      switch (schemaVersion) {
        case 1:
          return new Asce716ResidentialModuleBits();
        case 2:
          const { projectConfiguration: { projectEnvConfig: { building_type } } } = state();
          if(isAscender(productId)) {
            return new AscenderModuleBits(building_type, roofPitch);
          }
          return new Asce716ResidentialModuleBitsV2(building_type, roofPitch);
      }
    }

    if (isCommercialProduct(productId)) {
      return new Asce716CommercialModuleBits();
    }
  }

  return new BasicModuleBits();
}
