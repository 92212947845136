export function buildParam(parameters: object) {
  let qs = '';
  for (const key in parameters) {
    const value = parameters[key];
    qs += `${encodeURIComponent(value)}/`;
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); 
  }

  if (qs.length === 0) {
    return '';
  }
  
  return `${qs}`;
}
