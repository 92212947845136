import * as React from 'react';
import roofPitchTooltip from './projectConfiguration/fieldsTooltips/roofPitchTooltip';
import Tooltip from '__common/components/Tooltip';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import { isRMIFIProduct, isRMGridFlex, isEcoFoot2Plus } from '__common/constants/products';
import { SET_PROJECT_OPTION } from './projectConfiguration/projectConfigurationActions';
import { dispatch } from '__common/store';

type Props = {
  initData: projectEnvConfig,
  productId: number,
};

export class RoofPitchSelectorStripped extends React.Component<Props, {}> {
  roofPitches = [
    '---',
    '1/12',
    '2/12',
    '3/12',
    '4/12',
    '5/12',
    '6/12',
    '7/12',
    '8/12',
    '9/12',
    '10/12',
    '11/12',
    '12/12',
  ];

  roofPitchesIFI = [
    '---',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
  ];

  roofPitchesIFILimitedForUNIRAC_FLASHLOC_RM = [ // if UNIRAC_FLASHLOC_RM is selected, then only these roof pitches are available for selection in the roof pitch selector dropdown list 
    '---',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
  ];

  roofPitchesIFILimited = [
    '---',
    '0',
    '1',
    '2',
    '3',
  ];

  asce716RoofPitch = [
    '---',
    '2/12',
    '3/12',
    '4/12',
    '5/12',
    '6/12',
    '7/12',
    '8/12',
    '9/12',
    '10/12',
    '11/12',
    '12/12',
  ];

  getRoofPitches = () => {
    const { productId } = this.props;

    if (isRMIFIProduct(productId)) {
      return this.roofPitchesIFI;
    }

    if(isEcoFoot2Plus(productId)) {
      return this.roofPitchesIFILimited;
    }

    return this.roofPitches;
  }

  onRoofPitchChange = (roofPitch: string) => {
    dispatch(SET_PROJECT_OPTION('roof_pitch', roofPitch))
  }

  warningMessage = (productId) => {
    return (
      <div className="input-warning lack-of-roof-pitch">
        You should set roof {isRMGridFlex(productId) ? 'slope' : 'pitch'}
      </div>
    );
  }

  getLabel = (productId) => {

    if (isRMGridFlex(productId) || isEcoFoot2Plus(productId)) {
      return <div className="input-label">Select roof slope (degrees) </div>;
    }

    return <div className="input-label">Select roof pitch <Tooltip id="roof-pitch-selector" content={roofPitchTooltip()} position="bottom" /></div>;
  }

  roofPitchSelect(roofPitches: string[], label) {

    const roofPitch = this.props.initData.roof_pitch;
    const productId = this.props.productId;
    return (
      <>
        {roofPitch === '---' && this.warningMessage(productId)}
        <SelectField
          id='roof_pitch_trimmed'
          value={roofPitch}
          menuItems={roofPitches}
          onChange={this.onRoofPitchChange}
          label={label}
        />
      </>
    );
  }

  render() {
    const { productId } = this.props;

    const roofPitches = this.getRoofPitches();

    const label = this.getLabel(productId);

    if (roofPitches.length === 0) {
      return null;
    }

    return this.roofPitchSelect(roofPitches, label);
  }
}
