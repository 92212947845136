import { dispatch, state } from '__common/store';
import { ADD_ROOF_POINT, ADD_ROOF_LAST_POINT } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
export function advanceRoofSelectingClick(renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer, stage: PIXI.Container) {
  if (renderer.plugins && renderer.plugins.interaction.eventData.data !== null) {
    const { advanceRoofSelecting: { enabled, tempPoint } } = state();
    const coords = stage.toLocal(renderer.plugins.interaction.eventData.data.global);
    const pointToAdd = tempPoint? tempPoint: coords;
    if (enabled) {
      dispatch(ADD_ROOF_POINT(pointToAdd));
    }
  }
}

export function advanceRoofSelectingMove(renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer, stage: PIXI.Container) {
  const { advanceRoofSelecting: { enabled, roofPoints } } = state();
  if (renderer.plugins && renderer.plugins.interaction.eventData.data !== null) {
    const coords = stage.toLocal(renderer.plugins.interaction.eventData.data.global);
    if (enabled && roofPoints.length && coords.x && coords.y) {
      dispatch(ADD_ROOF_LAST_POINT(coords));
    }
  }
}

export function advanceRoofSelectingMoveOutside(clearFunction: Function) {
  clearFunction();
}
