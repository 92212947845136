import * as React from 'react';

type Props = {
  canBeApplied: boolean;
  message: string;
  onClose?: () => void;
};

type State = { 
  userClosedPromotion: boolean;
};

class EngineeringPromotionNotification extends React.Component<Props, State> {
  state: State = {
    userClosedPromotion: false,
  };

  shouldDisplay() {
    return this.props.canBeApplied && !this.state.userClosedPromotion;
  }

  onClick() {
    this.setState(
      { userClosedPromotion: true }, 
      () => {
        if (this.props.onClose) {
          this.props.onClose();
        }  
      },
    );
  }

  render() {
    if (!this.shouldDisplay()) {
      return null;
    }

    return (
      <div className={'engineering-promotion'}>
        <div>
          <button className={'close-button'} onClick={() => this.onClick()}>&#10005;</button>
          {this.props.message}  
        </div>
      </div>
    );
  }
}

export default EngineeringPromotionNotification;
