import { ModuleBits } from './baseTypes';

// (little-endian) - to make the bit data read the table right to left 
// e.g. on corner (0-1), on edge (0-1), orientation (0-1)

// ** building code 7-16, commercial **
// |----------------|-------------|-------|--------|----|-----------|---------|
// | orientation    | obstruction | north | middle | ew | exposed   | exposed |
// | (is landscape) |             |       |        |    | neighbour |         |
// |----------------|-------------|-------|--------|----|-----------|---------|
// |       1        |      0      |   0   |    1   | 0  |     0     |    1    | <-- example: landscape exposed panel not next to the obstruction with 
// |                |             |       |        |    |           |         |     non-exposed neighbour on the center zone. Gives 0b1001001 = 73
// |----------------|-------------|-------|--------|----|-----------|---------|
//
// EW / MIDDLE / NORTH - Without orientation and obstruction

// ----------------------------------------
// --       ---                ---       --
// --  101  ---       001      ---  101  --
// --       ---                ---       --
// ----------------------------------------
// --       ---                ---       --
// --       ---                ---       --
// --  110  ---       010      ---  110  --
// --       ---                ---       --
// ----------------------------------------
// --       ---                ---       --
// --  100  ---       111      ---  100  --
// --       ---                ---       --
// ----------------------------------------

export class Asce716CommercialModuleBits extends ModuleBits {
  fromBits(bitData: number) {
    const isLandscape = bitData & 1;

    const obstructionBit = bitData >> 1 & 1;
    const roofZone = (bitData >> 2 & 0b111) as roofZoneNumber;
    const exposedNeighbour = bitData >> 5 & 1;
    const exposureBit = bitData >> 6 & 1;

    return {
      isLandscape: this.bitToBoolean(isLandscape),
      exposed: exposureBit === 1,
      exposedNeighbour: exposedNeighbour === 1,
      nearObstruction: obstructionBit === 1,
      roofZone,
    };
  }
  toBits(panel: panelInState) {
    const isLandscape = this.booleanToBit(panel.landscape); // 1 - landscape
    const roofZone = panel.roofZone;
    const obstructionBit = panel.nearObstruction ? 1 : 0;
    const exposedBit = panel.exposed ? 1 : 0;
    const exposedNeighbourBit = panel.exposedNeighbour ? 1 : 0;
    const exposedAndExposedNeighbourBits = exposedBit << 1 | exposedNeighbourBit;
    const exposuresWithRoofzones = exposedAndExposedNeighbourBits << 3 | roofZone;
    const exposureRoofzonesAndObstructionBits = exposuresWithRoofzones << 1 | obstructionBit;
    return exposureRoofzonesAndObstructionBits << 1 | isLandscape;
  }
}
