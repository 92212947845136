import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { clearBingSelection } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { clearGoogleMapsSelection } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { createNewArray } from '../../roofsSelectorClassicDesigner/roofsSelectorClassicDesigner';
import { getMap, turnGoogleMapsOffMapMoveControls } from '__editor/googleMapsRoofsSelector/components/map/map';
import { TURN_OFF_BANNERS, TURN_OFF_EDIT, TURN_OFF_MEASUREMENT_TOOL, TURN_ON_ROOF_SELECTING } from 'actions';
import { turnBingMapsOffMapMoveControls } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { drawRoofButtonToolTipText, nxtButylToolTipText } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/drawRoofButtonToolTip';
import { showCustomNotifyAlert, AlertInstance, showWarningAlert } from '__common/modules/alerts';
import { isBingMap, isBlankMap, isGoogleMap } from '__common/constants/map';
import { isNxtHorizon } from '__common/constants/products';

type Props = {
  advanceRoofSelectorEnabled: boolean,
  dispatch: Function,
  mapType: string,
  productId: number,
};

type State = {
  tooltipOpen: boolean,
  tooltipInstance: AlertInstance[] | null,
};

export default class DrawRoofBoundsButton extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      tooltipInstance: null,
    };
  }

  turnOffOtherModes() {
    const { mapType, dispatch } = this.props;

    dispatch(TURN_OFF_EDIT());
    dispatch(TURN_OFF_MEASUREMENT_TOOL());

    if (isGoogleMap(mapType)) {
      turnGoogleMapsOffMapMoveControls();
      clearGoogleMapsSelection(); 
    }

    if (isBingMap(mapType)) {
      turnBingMapsOffMapMoveControls();
      clearBingSelection();
    }
  }

  changeCursor() {
    const { mapType } = this.props;

    if (isGoogleMap(mapType)) {
      const map = getMap();
      map.setOptions({ draggableCursor: 'pointer' });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { advanceRoofSelectorEnabled, productId , dispatch } = this.props;
    if(advanceRoofSelectorEnabled && !prevProps.advanceRoofSelectorEnabled){
      if (this.state.tooltipOpen && this.state.tooltipInstance) {
        this.state.tooltipInstance.map(alert => alert?.dismiss());
      } 
      const alert = showCustomNotifyAlert(drawRoofButtonToolTipText(productId), 10);
      const nxtButylToolTip = isNxtHorizon(productId) ? showWarningAlert(nxtButylToolTipText(productId),10) : null;
      alert.ondismiss = () => this.setState({
        tooltipOpen: false,
        tooltipInstance: null,
      });
      this.setState({
        tooltipOpen: true,
        tooltipInstance: [alert, nxtButylToolTip],
      });
    }
  }

  @autobind
  turnOn() {
    const { dispatch, mapType } = this.props;

    if (!isBlankMap(mapType)) {
      this.turnOffOtherModes();
      dispatch(TURN_ON_ROOF_SELECTING());
      dispatch(TURN_OFF_BANNERS());
      this.changeCursor();
      
    }
    
    if (isBlankMap(mapType)) {
      createNewArray();
    }    
  }

  render() {
    const { advanceRoofSelectorEnabled } = this.props;
    return (
      <Button tooltipLabel="Draw shape" flat={true} onClick={this.turnOn} className="roofs-selector-button">
        <SVG className={advanceRoofSelectorEnabled ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_draw.svg')} />
      </Button>
    );
  }
}
