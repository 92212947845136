import React from 'react';

export default class Preview extends React.Component {
  opts = {
    width: 500,
    height: 282,
  };

  render() {
    return (
      <div className="preview">
      <iframe src="https://www.youtube.com/embed/videoseries?list=PLl-qoTIxeEhWqbIdMHfMDRGIRFDeawXAE"/>
x
        <img src={require('assets/media/images/preview.png')} />
        <div className="content">
          <div className="preview-header">
            <strong>Experience</strong> That Makes A Difference
          </div>
          <div className="text">
            Unirac is North America’s leading manufacturer of solar PV mounting systems. Recognized for our excellence in product development, 
            we provide the best mounting solutions to our customers through competitively priced products, responsive customer support, integrated technology solutions, robust engineering services, certified quality, and supply-chain excellence.
            <br />
            With 20 years of customer service, Unirac has over 5GW of experience and nearly 750,000 installations worth of knowledge; partnering with Unirac leverages experience that makes a difference.          </div>
        </div>
      </div>
    );
  }
}
