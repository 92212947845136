import * as React from 'react';
import appConfig from 'local_config';
import autobind from 'autobind-decorator';
import Icon from '__common/components/Icon';
import { selectAccessory, unselectAccessory } from 'bom/components/utils/partsSelectionFunctions';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';


export default class AccessoryPart extends React.Component<accessoryPartComponentState, {}> {

  @autobind
  toggleAccessory(checked: boolean) {
    const { accessory, accessoriesList, productId, projectId, partsList, continuous_trims_sections , rm_roof_pad, } = this.props;
    if (checked) {
      selectAccessory(accessory, partsList, accessoriesList, continuous_trims_sections, productId, projectId, rm_roof_pad);
    } else {
      unselectAccessory(accessory, partsList, accessoriesList, productId, projectId, continuous_trims_sections, rm_roof_pad);
    }
  }

  render() {
    const { accessory, isSelected, productId,  is_rm_roof_pad_checkbox_enable } = this.props;
    const disable = (accessory.number == '310760' && is_rm_roof_pad_checkbox_enable) ? true : false
    return (
      <div className="accessory" style={{ display: 'flex', marginBottom: '10px' }}>
        <div>
          <SelectionControl
            id={`accessory_${accessory.number}`}
            name="accessory_checkboxes[]"
            type="checkbox"
            aria-label="Accessories selection"
            value={accessory.number}
            checked={isSelected}
            onChange={this.toggleAccessory}
            checkedCheckboxIcon={(<Icon>check</Icon>)}
            uncheckedCheckboxIcon={(<Icon>check</Icon>)}
            disabled = {disable}
          />
        </div>
        <div className="image">
          <img src={`${location.protocol}//${appConfig.apiUrl.slice(0, -1)}${accessory.image}`} alt="accesory" style={{ maxWidth: '80px', position: 'relative', left: '-10px' }} />
        </div>
        <div className="description">
          <div className="control-panel-label accessory-name" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{accessory.name}</div>
            <div className="accessory-price">${accessory.price || 0}</div>
          </div>
          <div className="text">
            {productId === 'metal_x' ? accessory.description : accessory.full_description}
          </div>
        </div>
      </div>
    );
  }
}

