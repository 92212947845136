import keyMirror from 'keymirror';

/**
 * @namespace Constants
 * @desc Stage constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const StageActionTypes = keyMirror({
  SET_STAGE_SCALE: undefined,
});
