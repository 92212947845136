import Interval from 'math.interval';
import { union, contains } from 'math.interval-utils';

// value properties correspond to the values of interval and limit properties correspond to if values of interval are included or not.
// - If limit is 0, it indicates that value is included.
// - If the limit of first item is 1 , it indicates that first value is not included in interval.
// - If the limit of second item is -1, it indicates that second value is not included in interval.
// For example:
// (1, 5] is represented with:
// [
//     { value: 1, limit: 1 },
//     { value: 5, limit: 0 }
// ]
// -----------------------------
// [-1, 3] is represented with:
// [ 
//     { value: -1, limit: 0 },
//     { value: 3, limit: 0 }
// ]
// -----------------------------
// (10, 12) is represented with:
// [
//     { value: 10, limit: 1 },
//     { value: 12, limit: -1 }
// ]
export interface intervalItem {
  value: number;
  limit: -1 | 0 | 1;
}
export interface interval {
  interval: [intervalItem, intervalItem];
}

// because the X, Y of panels are rounded to 4 digits after loading from the API
// and the newly added panels have more
// we need to make them the same, otherwise the can expand outside the intervals (because they're shifted)
//
//  :         :
//  :  ○------:-○
//  :  |      : |
//  :  ○------:-○
//  ○---------○
//  |         |
//  ○---------○
const allowedError = 0.001;

export const checkNorthSouthInterval = (panelInExposureExtents = <rbush.BBox[]>[], xSpacingPx: number, currentPanelId: number, panelInterval: interval) => {
  if (panelInExposureExtents.length) {

    const panelsIntervals = panelInExposureExtents.reduce<[intervalItem, intervalItem][]>(
      (acc, panel) => {
        if (panel.id === currentPanelId) {
          return acc;
        }

        const interval = get_interval_in_N_and_S_box(
          xSpacingPx, 
          // because the X, Y of panels are rounded to 4 digits after loading from the API
          // and the newly added panels have more
          // we need to make them the same, otherwise the can expand outside the intervals.
          roundToNDigits(panel.minX, 4) - allowedError, 
          roundToNDigits(panel.maxX, 4) + allowedError, 
          true,
        );
        if (interval && interval.interval) {
          acc.push(interval.interval);
        }
        

        return acc;
      },                                                                
      [],
    );
    // due to the way union function is implemented internally 
    // (https://github.com/xgbuils/math.interval-utils/blob/master/src/union.js)
    // it doesn't handle properly overlapping intervals when there are more than 2.
    // [0, 3], [2.5, 5], [4.5, 7] ideally should return [0, 7],
    // but instead it is going to return [0, 5], [4.5, 7].
    // therefore we call union on union again to merge created results.
    // Without it causes problems if panels are of mixed orientation. 
    // Something like this may happen:
    //
    //  ┌-┐┌-┐┌-┐
    //  |1||2||3|
    //  └-┘└-┘└-┘
    //   ┌----┐
    //   └----┘
    //     4^
    //
    // panels 1 & 2 are one sum, and panel 3 creates another interval.
    // so checking if "some" interval contains panel's 4 interval will return false.
    // (because neither 1&2 fully contains 4, nor the 3)
    const sum = union(union(panelsIntervals)) as [intervalItem, intervalItem][];
    return sum.some(interval => contains(interval, panelInterval.interval));
  }
};

export const checkWestEastInterval = (panelInExposureExtents = <rbush.BBox[]>[], ySpacingPx: number, currentPanelId: number, panelInterval: interval) => {
  if (panelInExposureExtents.length) {
    const panelsIntervals = panelInExposureExtents.reduce<[intervalItem, intervalItem][]>(
      (acc, panel) => {
        if (panel.id === currentPanelId) {
          return acc;
        }

        const interval = get_interval_in_E_and_W_box(
          ySpacingPx, 
          roundToNDigits(panel.minY, 4) - allowedError, 
          roundToNDigits(panel.maxY, 4) + allowedError, 
          true,
        );

        if (interval && interval.interval) {
          acc.push(interval.interval);
        }

        return acc;
      },                                                                
      [],
    );
    const sum = union(union(panelsIntervals)) as [intervalItem, intervalItem][];
    return sum.some(interval => contains(interval, panelInterval.interval));
  }
};

const roundToNDigits = (value: number, digits: number): number => {
  return parseFloat(value.toFixed(digits));
};

export const get_interval_in_N_and_S_box = (spacingX: number, minX: number, maxX: number, closed: boolean): interval => {
  const openInterval = closed ? '[' : '(';
  const closeInterval = closed ? ']' : ')';
  return new Interval(`${openInterval}${minX - 0.5 * spacingX}, ${maxX + 0.5 * spacingX}${closeInterval}`);
};

export const get_interval_in_E_and_W_box = (spacingY: number, minY: number, maxY: number, closed: boolean): interval => {
  const openInterval = closed ? '[' : '(';
  const closeInterval = closed ? ']' : ')';
  return new Interval(`${openInterval}${minY - 0.5 * spacingY}, ${maxY + 0.5 * spacingY}${closeInterval}`);
};

export const intervalContains = (parent: [intervalItem, intervalItem], child: [intervalItem, intervalItem]): boolean => {
  return contains(parent, child);
};

export const intervalUnion = (intervals: [intervalItem, intervalItem][]): [intervalItem, intervalItem][] => {
  return union(intervals);
};
