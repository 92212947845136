import _ from 'lodash';
import { ASCE_716_ROOF_ZONES } from '../roofZoneNumbers';
import { ZONES_CLASSIFICATION } from '__common/calculations/prependicularLines';

export interface collisionCircle {
  pos: {
    x: number,
    y: number,
  };
  distance: number;
  zone: string;
  index: number;
  edge: number;
}

export function roofZonesClassification(collision: string): roofZoneNumber {
  let newCollisions = collision;
  if (collision.includes('E') && collision.includes('W')) {
    newCollisions = collision.replace('W', '');
  }

  if (collision.includes('N') && collision.includes('S')) {
    newCollisions = collision.replace('S', '');
  }

  newCollisions = _.uniq(newCollisions).join('');

  if (isOnTheBottom(newCollisions)) {
    return ASCE_716_ROOF_ZONES.SOUTH_LEADING;
  }

  if (isOnTheSides(newCollisions)) {
    return ASCE_716_ROOF_ZONES.EAST_WEST_EDGES;
  }

  if (isInTopLeftOrRightCorner(newCollisions)) {
    return ASCE_716_ROOF_ZONES.NORTH_CORNER;
  }

  if (isInTheBottomLeftOrRightCorner(newCollisions)) {
    return ASCE_716_ROOF_ZONES.SOUTH_CORNER;
  }

  if (isOnTheMiddle(newCollisions)) {
    return ASCE_716_ROOF_ZONES.MIDDLE;
  }

  if (isInTop(newCollisions)) {
    return ASCE_716_ROOF_ZONES.NORTG_LEADING;
  }
}

const isInTopLeftOrRightCorner = (collisions: string) =>
  collisions.includes(ZONES_CLASSIFICATION.N) && (collisions.includes(ZONES_CLASSIFICATION.E) || collisions.includes(ZONES_CLASSIFICATION.W));

const isInTop = (collisions: string) =>
  collisions.length === 1 && collisions.includes(ZONES_CLASSIFICATION.N);

const isOnTheSides = (collisions: string) =>
  collisions.length === 1 && (collisions.includes(ZONES_CLASSIFICATION.E) || collisions.includes(ZONES_CLASSIFICATION.W));

const isOnTheMiddle = (collisions: string) =>
  collisions.length === 0;

const isInTheBottomLeftOrRightCorner = (collisions: string) =>
  collisions.includes(ZONES_CLASSIFICATION.S) && (collisions.includes(ZONES_CLASSIFICATION.E) || collisions.includes(ZONES_CLASSIFICATION.W));

const isOnTheBottom = (collisions: string) =>
  collisions.length === 1 && collisions.includes(ZONES_CLASSIFICATION.S);
