export default () => `
  <div class="content">
    This is a categorization of buildings and other structures for determination of
    loads based on the risk associated with unacceptable performance. 
  </div>
  <div class="sub-header">
    <strong>Risk Category I:</strong>
  </div>
  <div class="content">
    It should be selected for unoccupied structures (sheds, ground mounts, etc.).
  </div>
  <div class="sub-header">
    <strong>Risk Category II:</strong>
  </div>
  <div class="content">
    It should be selected for most residential and commercial buildings.
  </div>
  <div class="sub-header">
    <strong>Risk Category III:</strong>
  </div>
  <div class="content">
    It should be selected for highly populated commercial buildings, schools, and government buildings.
  </div>
  <div class="sub-header">
    <strong>Risk Category IV:</strong>
  </div>
  <div class="content">
    Essential facilities like fire, police, and hospital buildings should be sent to Unirac for evaluation as Risk Category IV buildings.
  </div>
`;
