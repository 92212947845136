export const apiField = 'foundation_diameter';
import foundationDiameterTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/foundationDiameterTooltip';


const config = {
  type: 'Select',
  apiField,
  label: 'foundation_diameter_ula',
  tooltip: foundationDiameterTooltip,
  data: [
    { 
      value: 12,
      name: '12',
    },
    {
      value: 18,
      name: '18',
    },
    { 
      value: 24,
      name: '24',
    },
    {
      value: 30,
      name: '30',
    },
    {
      value: 36,
      name: '36',
    },
  ],
};

export default config;
