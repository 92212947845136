export const getDetectedModuleSpacing = ({panels, stateRowSpacing, stateColumnSpacing, metersPerPixel}) => {

  const [stateRowSpacingPx, stateColumnSpacingPx] = [stateRowSpacing/metersPerPixel, stateColumnSpacing/metersPerPixel];
  let [detectedRowSpacingPx, detectedColumnSpacingPx] = [stateRowSpacingPx, stateColumnSpacingPx];
  let [detectedRowSpacingDetermined, detectedColumnSpacingDetermined] = [false, false];

  const panelsLandscape = panels.filter((p:panelInState) => p.landscape);      
  if (panelsLandscape.length > 1){
    const uniqueXvalues = Array.from(new Set(panelsLandscape.map(p => p.x)));
    const uniqueYvalues = Array.from(new Set(panelsLandscape.map(p => p.y)));
    for (let x of uniqueXvalues) {
      if (detectedRowSpacingDetermined) {
        break;
      }
      const columnYs = panelsLandscape.filter(p=>p.x===x).map(p=>p.y).sort((a,b)=>a-b);
      for (let i = 0; i < columnYs.length-1; i++){
        const columnYsDiff = Math.abs(columnYs[i+1] - columnYs[i]);
        if (Math.round(columnYsDiff / (panelsLandscape[0].height + stateRowSpacingPx)) === 1){
          detectedRowSpacingPx = columnYsDiff - panels[0].height;
          detectedRowSpacingDetermined = true;
          break;
        }
      }      
    }
    for (let y of uniqueYvalues) {
      if (detectedColumnSpacingDetermined) {
        break;
      }
      const rowXs = panelsLandscape.filter(p=>p.y===y).map(p=>p.x).sort((a,b)=>a-b);
      for (let i = 0; i < rowXs.length-1; i++){
        const rowXsDiff = Math.abs(rowXs[i+1] - rowXs[i]);
        if (Math.round(rowXsDiff / (panelsLandscape[0].width + stateColumnSpacingPx)) === 1){
          detectedColumnSpacingPx = rowXsDiff - panels[0].width;
          detectedColumnSpacingDetermined = true;
          break;
        }
      }      
    }
  }

  const panelsPortrait = panels.filter((p:panelInState) => !p.landscape);
  if (panelsPortrait.length > 1){
    const uniqueXvalues = Array.from(new Set(panelsPortrait.map(p => p.x)));
    const uniqueYvalues = Array.from(new Set(panelsPortrait.map(p => p.y)));
    for (let x of uniqueXvalues) {
      if (detectedRowSpacingDetermined) {
        break;
      }
      const columnYs = panelsPortrait.filter(p=>p.x===x).map(p=>p.y).sort((a,b)=>a-b);
      for (let i = 0; i < columnYs.length-1; i++){
        const columnYsDiff = Math.abs(columnYs[i+1] - columnYs[i]);
        if (Math.round(columnYsDiff / (panelsPortrait[0].height + stateRowSpacingPx)) === 1){
          detectedRowSpacingPx = columnYsDiff - panels[0].height;
          detectedRowSpacingDetermined = true;
          break;
        }
      }      
    }
    for (let y of uniqueYvalues) {
      if (detectedColumnSpacingDetermined) {
        break;
      }
      const rowXs = panels.filter(p=>p.y===y).map(p=>p.x).sort((a,b)=>a-b);
      for (let i = 0; i < rowXs.length-1; i++){
        const rowXsDiff = Math.abs(rowXs[i+1] - rowXs[i]);
        if (Math.round(rowXsDiff / (panels[0].width + stateColumnSpacingPx)) === 1){
          detectedColumnSpacingPx = rowXsDiff - panels[0].width;
          detectedColumnSpacingDetermined = true;
          break;
        }
      }      
    }
  }
  return {detectedRowSpacingPx, detectedColumnSpacingPx, detectedRowSpacingDetermined, detectedColumnSpacingDetermined};
};


export const traversePanelsGraphAndUpdateSpacings = ({panel, rowSpacingPx, columnSpacingPx, panelIdMap, visited}) => {
  
  const getNeighborCoords = (p) => {
    let x = p.x - columnSpacingPx - p.width;
    let y = p.y;
    const left =  {x, y};

    x = p.x + columnSpacingPx + p.width;
    y = p.y;
    const right =  {x, y};

    x = p.x;
    y = p.y - rowSpacingPx - p.height;
    const top =  {x, y};

    x = p.x;
    y = p.y + rowSpacingPx + p.height;
    const bottom = {x, y};

    return { left, right, top, bottom };
    
  }

  const stack = [{ p: panel, x: panelIdMap[panel.id].x, y: panelIdMap[panel.id].y }];
  const neighbors = ['left', 'right', 'top', 'bottom'];

  while (stack.length) {
      const { p, x, y } = stack.pop();
      if (visited[p.id]) {
          continue;
      }
      panelIdMap[p.id].x = x;
      panelIdMap[p.id].y = y;
      visited[p.id] = true;
      const coords = getNeighborCoords(panelIdMap[p.id]);
      neighbors.forEach(key=>{
          if (p[key] && !visited[p[key].id]) {
            stack.push({p: p[key], x: coords[key].x, y: coords[key].y});
          } 
      });
  }
};
