import React, { PureComponent } from 'react';
import { DialogContainer, List, ListItem } from 'react-md';
import { connect } from 'react-redux';
import { isRM5, isRMAndEcofootFamily, isRMDT, products } from '__common/constants/products';
import { FETCH_STATIC } from '__common/components/FetchStatics/fetchStaticsActions';
import EngineeringBalastActionButton from './engineeringBalastActionButton';

type Props = {
  dispatch: Function,
  projectId: string,
  productId: string,
  is_ASCE716or722: boolean,
  engineeringBallastMaps: engineeringBallastMapsState,
};

class EngineeringBallastMapCVS extends PureComponent<Props> {
  

  show = () => {
    this.setState({ visible: true });
  }

  downloadCSV = () => {
      const { engineeringBallastMaps: { generatedLayoutType, generatedArrayId }, projectId } = this.props;
      return this.fetchCSV(`${projectId}-${generatedArrayId}-${generatedLayoutType}`, `layout_excel/${projectId}/${generatedArrayId}/${generatedLayoutType}`)
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      this.hide();
    }
  }

  button = () => {
    return (
      <EngineeringBalastActionButton 
        onClick={this.downloadCSV} 
        tooltipLabel="Download CSV" 
        svgPath={require('assets/media/icons/icon_csv.svg')} 
      />
    );
  }

  fetchCSV = (name: string, link: string) => {
    const { dispatch } = this.props;
    dispatch(FETCH_STATIC(`${name}.csv`, 'text/csv', link, null, '', true));
  }

  render() {
    const { engineeringBallastMaps: { generated } } = this.props;
    
    if (generated) {
      return (
        <div>
          {this.button()}
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(appState: appState) {
  return {
    engineeringBallastMaps: appState.engineeringBallastMaps,
  };
}

export default connect(mapStateToProps)(EngineeringBallastMapCVS);
