import { applyRemovalOfOldTopChord } from "__common/utils/versionCompare/versionCompare";

export const apiField = 'top_chord';

export const options = {
  old_top_chord: {value: 1, name:'GFT Top Chord - Module length <= 83in'},
  new_top_chord: {value: 2, name:'GFT Top Chord - Module length <= 97in'},
};

export const makeConfig = (module_length_check = false, projectVersion, top_chord) => {
  return {
    type: 'Select',
    apiField,
    label: apiField,
    data: [
      ...(module_length_check || applyRemovalOfOldTopChord(projectVersion) || top_chord === options.new_top_chord.value ? [] : [options["old_top_chord"]]),
      options["new_top_chord"],
    ],
  }
};

export default makeConfig;
