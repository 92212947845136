import rbush from 'rbush';
import { state } from '__common/store';
import { shouldUseDoublePanels } from './panelsHelper';
import { _isAscenderFamily, _isULA } from '../cursor/utils/snapToGridHelper';

const panelsRTree = rbush();

interface rTreeBounds {
  id?: number;
  maxX: number;
  maxY: number;
  minX: number;
  minY: number;
  siblingId?: number;
  groupId?: number;
}

export const insertPanelIntoRtree = (panelRTreeBounds: rTreeBounds) => {
  panelsRTree.insert(panelRTreeBounds);
};

export const removePanelFromRtree = (panelRTreeBounds: rTreeBounds) => {
  panelsRTree.remove(panelRTreeBounds, (a, b) => {
    return a.id === b.id;
  });
};

export const resetRTree = () => {
  panelsRTree.clear();
};

export const clearRTree = () => {
  panelsRTree.clear();
};

export const checkCollision = (panel: rbush.BBox) => { 
  const { projectConfiguration: { productId } } = state();
  if (shouldUseDoublePanels(productId)) {
    return checkDoublePanelsCollision(panel);
  }

  return checkNormalCollision(panel);
};

export const searchCollisionItems = (bounds: rTreeBounds) => panelsRTree.search(bounds);

function panelCollidesOnlyWithSibling(panel: rbush.BBox, panelsInCollision: rbush.BBox[]) {

  if(_isULA){
    if (panelsInCollision.length === 1 || panelsInCollision.length === 2){
      return panelsInCollision.every((collidedPanel)=>
        panel.siblingId === collidedPanel.siblingId,
      );
    }
  }

  return panelsInCollision.length === 1 && panel.siblingId === panelsInCollision[0].siblingId;
}

function checkNormalCollision(panel: rbush.BBox) {
  return panelsRTree.collides(panel);
}

function checkDoublePanelsCollision(panel: rbush.BBox) {
  const panelsInCollision = panelsRTree.search(panel);
  if (panelCollidesOnlyWithSibling(panel, panelsInCollision)) {
    return false;
  }
  return panelsRTree.collides(panel);
}
