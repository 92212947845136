import { BomActionTypes } from 'actionsConstants';
import { autoSaveBom } from 'bom/utils/saveBom';
import { state } from '__common/store';
import { BOM_CHANGED } from 'actions';

export const saveBomMiddleware = store => next => action => {
  let dontRedirect = true;
  next(action);
  
  if (
    shouldSaveOnActions(action.type, store)
  ) {
    autoSaveBom(dontRedirect);
    dontRedirect = false;
  }
};

function shouldSaveOnActions(actionType, store) {
  const { bom: { goToEngineeringFromBom, bomSelectionsLoading }, railsSelector: { railsFetching } } = state();

  const actions = [
    BomActionTypes.SET_NEW_PART_QUANTITY,
    BomActionTypes.ADD_PART_TO_BOM,
    BomActionTypes.ADD_RM10_MODULE_AND_HEX_BOLT,
    BomActionTypes.REMOVE_PART_FROM_BOM,
    BomActionTypes.ADD_ATTACHMENT_TO_BOM,
    BomActionTypes.ADD_CLAMPS,
    BomActionTypes.RAIL_TYPE_LOADED,
    BomActionTypes.ADD_FRONT_TRIM_END_CAP,
    BomActionTypes.REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY,
    BomActionTypes.REMOVE_FRONT_TRIM_END_CAP,
    BomActionTypes.CHANGE_BOM_COLOR_VARIANT,
    BomActionTypes.ADD_RAILS_TO_BOM,
    BomActionTypes.ADD_TILE_HOOK,
    BomActionTypes.REMOVE_TILE_HOOK,
    BomActionTypes.ADD_LFOOT,
    BomActionTypes.REMOVE_LFOOT,
    BomActionTypes.ADD_FLASHKIT_PRO,
    BomActionTypes.REMOVE_FLASHKIT_PRO,
    BomActionTypes.ADD_FLASHLOC_COMP_KIT,
    BomActionTypes.REMOVE_FLASHLOC_COMP_KIT,
    BomActionTypes.ADD_TILE_REPLACEMENT_ITEM,
    BomActionTypes.REMOVE_TILE_REPLACEMENT_ITEM,
    BomActionTypes.ADD_ATTACHMENT_ITEM,
    BomActionTypes.REMOVE_ATTACHMENT_ITEM,
    BomActionTypes.UPDATE_QUANTITY,
    BomActionTypes.ADD_STRONGHOLD_ATT_KIT_COMP,
    BomActionTypes.REMOVE_STRONGHOLD_ATT_KIT_COMP,
    BomActionTypes.ADD_FLASHKIT_PRO_SB,
    BomActionTypes.REMOVE_FLASHKIT_PRO_SB,
    // BomActionTypes.ADD_STRONGHOLD_ATT_COMP_NON_SEALANT,
    // BomActionTypes.REMOVE_STRONGHOLD_ATT_COMP_NON_SEALANT,
    BomActionTypes.ADD_NXT_CLAMPS,
    BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK,
    BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_BULK,
    BomActionTypes.ADD_T_BOLTS_009020S_FOR_SOLARHOOKS,
  ];

  if (actions.includes(actionType)) {
    store.dispatch(BOM_CHANGED());
  }

  return actions.includes(actionType) && !goToEngineeringFromBom && !railsFetching && !bomSelectionsLoading;
}
