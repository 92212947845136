import { state, dispatch } from '__common/store';
import { isCursorInForbidenArea, isCursorSnapping, isCursorInRedZone, isCursorInYellowZone, isCursorInBlueZone } from '../cursor';
import { SET_BLACK_CURSOR, SET_GREEN_CURSOR, SET_ORANGE_CURSOR, SET_YELLOW_CURSOR, SET_BLUE_CURSOR, SET_ASCE_7_16_SNAPPING_CURSOR } from 'actions';
import { isRM10Evolution, isRmGridflex10, products } from '__common/constants/products';

export const asce705and710MapColors = (
  cursor: PIXI.Graphics,
  cursorColide: boolean,
  mapType: string,
  productId: string,
  isInYellowWindZone: boolean,
  isInRedWindZone: boolean,
) => {
  const { settings: { canvasCenter, panelWidth, panelHeight }, editorCursor: { colour }, background: { selectedRoofId, moveArrayMode}, panels: {panelsToBeMoved}, confirmClearArraysModal:{isOpen: isOverflowPromptOpen} } = state();
  const x = cursor.x - canvasCenter.x;
  const y = cursor.y - canvasCenter.y;
  const snappingId = isRM10Evolution(products[productId]) || isRmGridflex10(products[productId]) ? 9 : 4;

  if (isCursorInForbidenArea(cursor, cursorColide, colour, x, y, panelWidth, panelHeight, selectedRoofId, mapType)) {
    return dispatch(SET_BLACK_CURSOR());
  }

  if (moveArrayMode && panelsToBeMoved.length > 0) {
    return dispatch(SET_BLUE_CURSOR());
  }

  if (isCursorSnapping(cursorColide, false, colour, productId, snappingId) && colour !== snappingId && !isOverflowPromptOpen) {
    return isRM10Evolution(products[productId]) || isRmGridflex10(products[productId]) ? dispatch(SET_ASCE_7_16_SNAPPING_CURSOR()) : dispatch(SET_GREEN_CURSOR());
  }

  if (isCursorInRedZone(cursorColide, isInRedWindZone, colour) && colour !== 2) {
    return dispatch(SET_ORANGE_CURSOR());
  }

  if (isCursorInYellowZone(cursorColide, isInYellowWindZone, colour) && colour !== 2) {
    return dispatch(SET_YELLOW_CURSOR());
  }

  if (isCursorInBlueZone(cursorColide, isInYellowWindZone, isInRedWindZone, colour, productId) && colour !== 0) {
    return dispatch(SET_BLUE_CURSOR());
  }
};
