import { ActionTypes } from 'actionsConstants';



export function FETCH_PROJECTS_LIST_REQUEST() {
  return {
    type: ActionTypes.FETCH_PROJECTS_LIST_REQUEST,
  };
}

export function FETCH_PROJECTS_LIST_SUCCESS(projectsList: any = {}) {
  return {
    type: ActionTypes.FETCH_PROJECTS_LIST_SUCCESS,
    payload: {
      data: projectsList,
    },
  };
}

export function FETCH_PROJECTS_LIST_WAIT_FOR_SOCKET(taskId: String) {
  return {
    type: ActionTypes.FETCH_PROJECTS_LIST_WAIT_FOR_SOCKET,
    payload: {
      taskId,
    },
  };
}

export function FETCH_PROJECTS_LIST_WAIT_FOR_SOCKET_DATA() {
  return {
    type: ActionTypes.FETCH_PROJECTS_LIST_WAIT_FOR_SOCKET_DATA,
  };
}

export function PROJECTS_LIST_SET_PROJECTS_TO_SHOW(projectsToShow: any[]) {
  return {
    type: ActionTypes.PROJECTS_LIST_SET_PROJECTS_TO_SHOW,
    payload: {
      projectsToShow,
    },
  };
}

export function PROJECTS_LIST_FILTER_PROJECTS(filter: React.ReactText) {
  return {
    type: ActionTypes.PROJECTS_LIST_FILTER_PROJECTS,
    payload: {
      filter,
    },
  };
}

export function CLEAR_HELIOSCOPE_PROJECT_LIST() {
  return {
    type: ActionTypes.CLEAR_HELIOSCOPE_PROJECT_LIST,
  };
}

export function FETCH_PROJECTS_LIST_FAILURE(errorType: string) {
  return {
    type: ActionTypes.FETCH_PROJECTS_LIST_FAILURE,
    payload: {
      errorType,
    },
  };
}

export function SET_HELIOSCOPE_PROJECT_LIST_SORTING(sortedField: string) {
  return {
    type: ActionTypes.SET_HELIOSCOPE_PROJECT_LIST_SORTING,
    payload: {
      sortedField,
    },
  };
}
