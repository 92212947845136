import React from 'react';
import { hasTilt } from '__editor/panelsEditor/components/panels/panels';
import { products } from '__common/constants/products';
import AppHistory from '__common/modules/history';
import { dispatch } from '__common/store';
import { SET_PRODUCT_TITLE } from './projectConfiguration/projectConfigurationActions';
import { getProductTitle } from '__common/constants/products_titles';
import { connect } from 'react-redux';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

type Props = {
  roofs: { [roofId: string]: drawingManagerRoof };
  productId: string;
  projectId: string;
  spansProductId: number;
  onRedirect: Function;
  projectVersion?: string;
  projectConfiguration: projectConfigurationState,
};

type State = {
  redirected: boolean,
  redirectToSm: boolean,
  redirectToSmTilt: boolean,
  redirectToAdjustableTilt: boolean,
  redirectToSmTiltPR: boolean,
};

class RedirectFromSmLtAndSmHd extends React.Component<Props, State> {
  state = {
    redirected: false,
    redirectToSm: false,
    redirectToSmTilt: false,
    redirectToSmTiltPR: false,
    redirectToAdjustableTilt: false,
  };

  hasTilt() {
    return hasTilt();
  }

  _shouldRedirectToSM() {
    const { roofs, productId, projectId } = this.props;
    return ([products.solarmount_lt, products.solarmount_hd, products.solarmount_ascender_flush].includes(products[productId])) &&
    roofs &&
    !this.hasTilt() &&
    projectId;
  }

  _shouldRedirectToSMTilt() {
    const { roofs, productId, spansProductId, projectId } = this.props;
    return (spansProductId === 9 ||
      spansProductId === 2 ||
      spansProductId === 11) &&
    (products[productId] === 2 ||
      products[productId] === 9 ||
      products[productId] === 11) &&
    roofs &&
    this.hasTilt() &&
    projectId;
  }

  _shouldRedirectToAdjustableTilt() {
    const { roofs, productId, spansProductId, projectId, projectVersion } = this.props;
    return adjustableTiltSystem(projectVersion) &&
    (products[productId] === 2 ||
      products[productId] === 9 ||
      products[productId] === 11 ||
      products[productId] === 99 ||
      products[productId] === 34 ) &&
    roofs &&
    this.hasTilt() &&
    projectId;
  }

  _shouldNotRedirect() {
    const { roofs, productId } = this.props;
    return (products[productId] === 99 || products[productId] === 34) && roofs && this.hasTilt();
  }

  _shouldRedirectToSMTiltPR() {
    const { roofs, productId, projectId } = this.props;
    return ([products.sm_tilt_pr_lt].includes(products[productId])) &&
    roofs &&
    projectId;
  }

  componentDidMount() {
    if (this._shouldRedirectToSM()) {
      this.setState(state => ({ ...state, redirectToSm: true }));
    }

    if (this._shouldRedirectToSMTilt()) {
      this.setState(state => ({ ...state, redirectToSmTilt: true }));
    }

    if (this._shouldRedirectToAdjustableTilt()) {
      this.setState(state => ({ ...state, redirectToAdjustableTilt: true }));
    }

    if (this._shouldRedirectToSMTiltPR()) {
      this.setState(state => ({ ...state, redirectToSmTiltPR: true }));
    }
  }
 
  componentDidUpdate() {
    if (this._shouldRedirectToSM()) {
      this.setState(state => ({ ...state, redirectToSm: true }));
    }

    if (this._shouldRedirectToSMTilt()) {
      this.setState(state => ({ ...state, redirectToSmTilt: true }));
    }

    if (this._shouldRedirectToAdjustableTilt()) {
      this.setState(state => ({ ...state, redirectToAdjustableTilt: true }));
    }

    if (this._shouldRedirectToSMTiltPR()) {
      this.setState(state => ({ ...state, redirectToSmTiltPR: true }));
    }
  }

  componentWillUnmount() {
    if (this.state.redirected) {
      this.props.onRedirect();
    }
  }

  render() {
    const { projectId, projectConfiguration : {projectEnvConfig : {adjustable_tilt_system}} } = this.props;
    const { redirectToSm, redirectToSmTilt, redirectToSmTiltPR, redirectToAdjustableTilt, } = this.state;
    if (redirectToSm) {
      this.props.onRedirect();
      AppHistory.push(`/project/design/solarmount_2/${projectId}`);
      return null;
    }

    if (redirectToSmTilt || redirectToAdjustableTilt) {
      this.props.onRedirect();
      // AppHistory.push(`/project/design/${adjustableTiltSystem(this.props.projectVersion) ? 'adjustable_tilt_system' : 'solarmount_2_tilt'}/${projectId}`);
      AppHistory.push(`/project/design/solarmount_2_tilt/${projectId}`);
      dispatch(SET_PRODUCT_TITLE(getProductTitle(99)));
      if (adjustable_tilt_system == 34) { 
          dispatch(SET_PRODUCT_TITLE(getProductTitle(34)))
        }
      return null;
    }

    if (redirectToSmTiltPR) {
      this.props.onRedirect();
      AppHistory.push(`/project/design/sm_tilt_pr/${projectId}`);
      return null;
    }

    if (this._shouldNotRedirect()) {
      this.props.onRedirect();
      return null;
    }

    return null;
  }
}

function mapPropsToState(state: appState) {
  return {
    projectVersion: state.projectConfiguration.projectVersion,
    projectConfiguration: state.projectConfiguration,
  };
}

export default connect(mapPropsToState)(RedirectFromSmLtAndSmHd);
