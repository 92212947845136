import backgroundReducer from '__editor/panelsEditor/components/background/backgroundReducer';
import cursorReducer from '__editor/panelsEditor/components/cursor/cursorReducer';
import dimensionsReducer from '__editor/panelsEditor/components/dimensions/dimensionsReducer';
import historyReducer from '__editor/panelsEditor/components/history/historyReducer';
import panelsReducer from '__editor/panelsEditor/components/panels/panelsReducer';
import rubberBandReducer from '__editor/panelsEditor/components/rubberBand/rubberBandReducer';
import scaleReducer from '__editor/panelsEditor/components/scale/scaleReducer';
import settingsReducer from '__editor/panelsEditor/components/settings/settingsReducer';
import stageReducer from '__editor/panelsEditor/components/stage/stageReducer';
import tiltedRoofReducer from '__editor/panelsEditor/components/tiltedRoof/tiltedRoofReducer';
import obstructionsReducer from '__editor/panelsEditor/components/obstructions/ObstructionsReducer';
import tooltipCursor from '__common/components/tooltipCursor/tooltipCursorReducer';
import roofZonesReducer from '__editor/panelsEditor/components/roofZones/roofZonesReducer';

export default {
  ...cursorReducer,
  ...panelsReducer,
  ...rubberBandReducer,
  ...stageReducer,
  ...settingsReducer,
  ...historyReducer,
  ...dimensionsReducer,
  ...backgroundReducer,
  ...tiltedRoofReducer,
  ...scaleReducer,
  ...obstructionsReducer,
  ...tooltipCursor,
  ...roofZonesReducer,
};
