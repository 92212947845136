export const apiField = 'mandatory_mid_support';
interface mandatorymidsupportField {
  type: string;
  apiField: string;
  label: string;
  id?: string;
  aria_label?: string;
  is_disabled?: boolean;
  isselected_?:number;
  margin_?:string
}
function makeConfig(type: string, label: string,  margin_, isselected_): mandatorymidsupportField {
    return {
      type,
      label,
      apiField,
      margin_,
      is_disabled:isselected_==1,
      isselected_,
      id: "mandatorymidsupport", 
      aria_label: "mandatory mid support"
    };
  }


export const mandatoryMidSupportRmGridflex=(disableMandatoryMidSupportCondition)=> makeConfig('Checkbox', 'mandatorymidsupport', '30px', disableMandatoryMidSupportCondition)