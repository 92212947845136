import React from 'react';
import SelectField from 'react-md/lib/SelectFields/SelectField';

type Props = {
  rowsPerPage: number;
  rowsPerPageMenu: number[];
  label?: string;
  onChange: (value: number) => void;
};

export default class RowsPerPage extends React.Component<Props> {
  render() {
    const { rowsPerPage, rowsPerPageMenu, onChange, label } = this.props;
    return (
      <div>
        <div className="input-label">{label}</div>
        <SelectField
          id="per-page-dropdown"
          value={rowsPerPage}
          menuItems={rowsPerPageMenu}
          simplifiedMenu={true}
          onChange={onChange}
          fullWidth={true}
        />
      </div>
    );
  }
}
