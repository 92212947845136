import { state } from '__common/store';
import { getDoublePanelsPopulatingData } from './populating';
import nextUid from '__common/calculations/nextUid';
import { DrawPanelFunction } from './draw_on_area';

export const draw_double_panels_on_area = (direction: string, points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  switch (direction) {
    case 'SE':
      return draw_SE(points, drawFn, groupId);
    case 'NW':
      return draw_NW(points, drawFn, groupId);
    case 'NE':
      return draw_NE(points, drawFn, groupId);
    case 'SW':
      return draw_SW(points, drawFn, groupId);
    default:
      return draw_SE(points, drawFn, groupId);
  }
};


const draw_SE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = state();
  const { topEdge, leftEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn, widthMultiplayer, heightMultiplayer } = getDoublePanelsPopulatingData(points, 2);
  let x = leftEdge;
  let y = topEdge;
  let panels: panelInState[] = [];  
  for (let i = 0; i < panelsInRow; i++) {
    x = leftEdge + (panelWidth * widthMultiplayer * i) + ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = topEdge + (panelHeight * heightMultiplayer * j) + ((rowSpacing / metersPerPixel) * j);
      panels = panels.concat(drawPanels(x, y, panelWidth, groupId, drawFn));
    }
  }
  return panels;
};

const draw_NW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = state();
  const { bottomEdge, rightEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn, widthMultiplayer, heightMultiplayer } = getDoublePanelsPopulatingData(points, 2);
  let x = rightEdge;
  let y = bottomEdge;
  let panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = rightEdge - (panelWidth * widthMultiplayer * i) - ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = bottomEdge - (panelHeight * heightMultiplayer * j) - ((rowSpacing / metersPerPixel) * j);
      panels = panels.concat(drawPanels(x, y, panelWidth, groupId, drawFn));
    }
  }
  return panels;
};

const draw_NE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = state();
  const { bottomEdge, leftEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn, widthMultiplayer, heightMultiplayer } = getDoublePanelsPopulatingData(points, 2);
  let x = leftEdge;
  let y = bottomEdge;
  let panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = leftEdge + (panelWidth * widthMultiplayer * i) + ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = bottomEdge - (panelHeight * heightMultiplayer * j) - ((rowSpacing / metersPerPixel) * j);
      panels = panels.concat(drawPanels(x, y, panelWidth, groupId, drawFn));
    }
  }
  return panels;
};

const draw_SW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction, groupId: number | null): panelInState[] => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = state();
  const { topEdge, rightEdge, panelWidth, panelHeight, panelsInRow, panelsInColumn, widthMultiplayer, heightMultiplayer } = getDoublePanelsPopulatingData(points, 2);
  let x = rightEdge;
  let y = topEdge;
  let panels: panelInState[] = [];
  for (let i = 0; i < panelsInRow; i++) {
    x = rightEdge - (panelWidth * widthMultiplayer * i) - ((columnSpacing / metersPerPixel) * i);
    for (let j = 0; j < panelsInColumn; j++) {
      y = topEdge + (panelHeight * heightMultiplayer * j) + ((rowSpacing / metersPerPixel) * j);
      panels =  panels.concat(drawPanels(x, y, panelWidth, groupId, drawFn));
    }
  }
  return panels;
};

function drawPanels(x: number, y: number, panelWidth: number, groupId: number, drawFn: DrawPanelFunction): panelInState[] {
  const panels: panelInState[] = [];
  const siblingId = nextUid();

  panels.push(drawFn(x - panelWidth / 2, y, groupId, siblingId, 0));
  panels.push(drawFn(x + panelWidth / 2, y, groupId, siblingId, 1));

  return panels;
}
