import keyMirror from 'keymirror';

export const AdvanceRoofSelectingActionTypes: any = keyMirror({
  ADD_ROOF_POINT: undefined,
  TERMINATE_ADVANCE_ROOF_SELECTING: undefined,
  TOGGLE_ADVANCE_ROOF_SELECTING: undefined,
  ADD_ROOF_LAST_POINT: undefined,
  ADD_TEMP_POINT: undefined,
  CLEAR_TEMP_POINT: undefined,
  TURN_ON_ROOF_SELECTING: undefined,
  TURN_OFF_ROOF_SELECTING: undefined,
  CLEAR_ROOF_POINTS: undefined,
  TURN_ON_BANNERS: undefined,
  TURN_OFF_BANNERS: undefined,
});
