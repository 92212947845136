import { addBreadcrumb } from '@sentry/browser';

export const sentryReporter = store => next => action => {
  addBreadcrumb({
    message: action.type,
    category: 'redux action',
    data: {
      payload: action.payload,
    },
  });
  return next(action);
};
