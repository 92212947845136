export interface AlertInstance {
  callback?: Function;
  delay: Function;
  dismiss: Function;
  dismissOthers: Function;
  element: HTMLElement;
  ondismiss: Function;
  push: (content: string, wait?: number) => AlertInstance;
  setContent: (content: string) => void;
}

export function showErrorAlert(message: string, wait?: number): AlertInstance {
  if (window.alertify) {
    return window.alertify.error(message, wait);
  }
}

export function showNotifyAlert(message: string): AlertInstance {
  if (window.alertify) {
    return window.alertify.notify(message);
  }
}

export function showCustomNotifyAlert(message: string, wait?: number): AlertInstance {
  if (window.alertify) {
    return window.alertify.notify(message, 'custom', wait);
  }
}

export function showSuccessAlert(message: string, wait?: number, position?: string): AlertInstance {
  if (window.alertify) {
    if(position){
      window.alertify.set('notifier','position', position);
    }
    return window.alertify.success(message, wait);
  }
}

export function showWarningAlert(message: string, wait?: number): AlertInstance {
  if (window.alertify) {
    return window.alertify.warning(message, wait);
  }
}
