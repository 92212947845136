import keyMirror from 'keymirror';
/**
 * @namespace Constants
 * @desc SaveLoadProject constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const SaveLoadProjectActionTypes = keyMirror({
  SAVE_PROJECT: undefined,
  REDIRECT_TO_BOM: undefined,
  REDIRECT_TO_ENGINEERING: undefined,
  CLEAR_PK: undefined,
  LOAD_DATA_FROM_API: undefined,
  FETCH_PROJECT: undefined,
  CLEAR_DATA_TO_LOAD: undefined,
  AUTO_SAVE_PROJECT: undefined,
  AUTO_SAVE_PROJECT_FINISHED: undefined,
  AUTO_SAVE_PROJECT_FAILED: undefined,
  PREVENT_AUTO_SAVE_START: undefined,
  PREVENT_AUTO_SAVE_FINISHED: undefined,
  SAVE_PROJECT_FAILED: undefined,
  SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH: undefined,
});
