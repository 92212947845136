import { colorPalettes } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerConfigs';
import { dispatch, state } from '__common/store';
import {REPLACE_PANELS_ON_ROOF} from 'actions';
import {
  arrayDetails,
  popupEvents,
  shouldShowBannersInMiniMap,
} from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorPopup';
import { isGroundProduct, isRM10Evolution, isRM5, isRMIFIProduct, isRmGridflex10 } from '__common/constants/products';
import { PRODUCTS_WITHOUT_ROOF_PITCH, PRODUCTS_WITH_STRUCTURE_TILT } from 'projectDesign/components/projectConfiguration/utils/constants';

let colors = colorPalettes();

function resetColors() {
  colors = colorPalettes();
}

export function getSelectedAreaPoints(area): { lat: number; lng: number }[] {
  const paths = [];
  for (let i = 0; i < area.getPath().getLength(); i++) {
    paths.push(
      area
        .getPath()
        .getAt(i)
        .toJSON(),
    );
  }
  return paths;
}

export function unselectSelectingRoofTool(
  drawingManager: google.maps.drawing.DrawingManager,
) {
  drawingManager.setDrawingMode(null);
}

export function getRandomUniqueColor(): string {
  for (let i = colors.length - 1; i >= 0; i--) {
    const pickedColor = colors.splice(
      Math.floor(Math.random() * colors.length),
      1,
    );
    if (!colors.length) {
      resetColors();
    }
    return pickedColor[0];
  }

  return colors.splice(Math.floor(0 * colors.length), 1)[0];
}

export function createGoogleMapsInfoWindow(
  roofId: number,
  name: string,
  nrOfModules: number,
  position,
  hasPanels: boolean,
  map: google.maps.Map,
  previewMode: boolean,
) {
  const infoWindow = new google.maps.InfoWindow({
    position,
    pixelOffset: new google.maps.Size(70, 100),
    content: `
    <div class="edit-and-delete-array google-maps-popup">
    <div class="array-details">
    ${arrayDetails(name, nrOfModules, hasPanels)}
  </div>
    <div class="array-buttons">
    ${shouldShowBannersInMiniMap(roofId, previewMode)}
    </div>
  </div>
    `,
  });

  google.maps.event.addListener(infoWindow, 'domready', () => {
    popupEvents();
  });

  return infoWindow;
}

export function createGoogleMapsRoofAreaAzimuthInfoWindow(
  roofId: number,
  azimuth: number,
  position: google.maps.LatLngLiteral,
) {
  const infoWindow = new google.maps.InfoWindow({
    position,
    content: `
    <div class="google-maps-popup">
      <div class="array-details azimuth-info-roof-${roofId}">
        Azimuth: ${azimuth}\u00B0
      </div>
    </div>
    `,
  });

  return infoWindow;
}

export function getRoofName() {
  const { projectConfiguration: { productId } } = state();
  if (isGroundProduct(productId)) {
    return `Site Area ${getNewRoofId()}`;  
  }
  return `Roof Area ${getNewRoofId()}`;
}

export function anyPanelsDrawn() {
  const {
    drawingManager: { roofs },
    panels: { panels },
  } = state();

  return (
    roofs &&
      Object.keys(roofs).length > 0 &&
      anyRoofHasPanels(roofs)) ||
      panels.length > 0;
}

export function anyRoofHasManualAttachments(allow_manual_attachments: number) {
  const {
    drawingManager: { roofs },
    projectConfiguration: {productId}
  } = state();
  if (!(!!roofs)){
    return false
  }
  const shouldConfirm = !allow_manual_attachments && Object.keys(roofs).reduce((acc, roofKey) => {
    const panels = roofs[roofKey].panels;
    if(isRmGridflex10(productId) || isRMIFIProduct(productId)){
      return panels.some(panel => !!panel.attached) || acc;
    } else {
      return panels.some(({manualAttachments}) => manualAttachments.some(value => value === 1) || acc)
    }
  }, false);
  return shouldConfirm;
}

export function deleteManualAttachmentsOnPanels(productId) {
  const {
    drawingManager: { roofs },
  } = state();
  Object.keys(roofs ?? {}).forEach(roofKey => {
    const panels = state().drawingManager.roofs[roofKey].panels;
    if(panels) {
      const updatedPanels = panels.map(panel => {
        if (isRM10Evolution(productId) || isRM5(productId)){
          return {...panel, manualAttachments:[0,0,0,0] as (0|1)[]}
        } else {
          return {...panel, attached: false}
        }
      });
      dispatch(REPLACE_PANELS_ON_ROOF(updatedPanels, Number(roofKey)));
    }
  });
}

export function allRoofsHasPanels() {
  const { drawingManager: { roofs } } = state();

  return (
    roofs &&
    Object.keys(roofs).length > 0 &&
    Object.keys(roofs).every(id => {
      return roofs[id] && roofs[id].panels && roofs[id].panels.length > 0;
    })
  );
}
 
export function allRoofsHaveRoofPitch() {
  const { drawingManager: { roofs }, projectConfiguration: { productId, projectVersion} } = state();

  if(PRODUCTS_WITHOUT_ROOF_PITCH(productId, projectVersion).includes(productId)) return true;
  return (
    roofs &&
    Object.keys(roofs).length > 0 &&
    Object.values(roofs).every(({roofPitch, structureTilt, id}) => {
      if (PRODUCTS_WITH_STRUCTURE_TILT.includes(productId)) {
        return roofs[id] && structureTilt && structureTilt!=="---";
      } else {
        return roofs[id] && roofPitch && roofPitch!=="---";
      }
    })
  );
}

export function anyRoofHasPanels(roofs) {
  return Object.keys(roofs).some(id => {
    if (roofs[id].panels && roofs[id].panels.length) {
      return true;
    }
  });
}

export function getNewRoofId() {
  const {
    drawingManager: { roofs },
  } = state();

  if (!roofs || Object.keys(roofs).length === 0) {
    return 1;
  }

  return String(Number(Object.keys(roofs)[Object.keys(roofs).length - 1]) + 1);
}

export function isZoomDefinedForRoof(selectedRoofId: number) {
  const { drawingManager: { roofs } } = state();
  if (roofs && roofs[selectedRoofId]) {
    return roofs[selectedRoofId].zoom > 0;
  }
  
  return false;
}

export const hasNoRoofs = () => {
  const {drawingManager: {roofs}} = state();
  return (!roofs || Object.keys(roofs).length === 0)
  }

export const getTotalModules = (roofs: { [roofId: string]: drawingManagerRoof }) => {
  if (roofs && Object.keys(roofs).length) {
    const totalModules = Object.keys(roofs).reduce((totalModules, roofId: string) => {
      if (roofs[roofId].panels && Array.isArray(roofs[roofId].panels)) {
        return totalModules + roofs[roofId].panels.length;
      }

      return totalModules;
    }, 0);

    return totalModules;
  }
}
