import AppHistory from '__common/modules/history';
import { YourProjectsActionTypes } from 'actionsConstants';

export function LOAD_YOUR_PROJECTS_DATA(data: yourProjectsApiResponse) {
  return {
    type: YourProjectsActionTypes.LOAD_YOUR_PROJECTS_DATA,
    payload: {
      data,
    },
  };
}

export function CANNOT_GET_PROJECTS() {
  return {
    type: YourProjectsActionTypes.CANNOT_GET_PROJECTS,
  };
}

export function SET_INFO_DATA(data: yourProjectsApiResponse) {
  return {
    type: YourProjectsActionTypes.SET_INFO_DATA,
    payload: {
      totalProjects: data.projects_configured,
      kilowatts: data.kilowatts_configured,
      miles: data.miles_driven,
      trees: data.trees_planted,
    },
  };
}

export function SET_ROWS_PER_PAGE(rowsPerPage: number) {
  return {
    type: YourProjectsActionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  };
}

export function FILTER_BY_STRING(filterString: string) {
  return {
    type: YourProjectsActionTypes.FILTER_BY_STRING,
    payload: {
      filterString,
    },
  };
}

export function INIT_USER_PROJECTS(rowsPerPage: number, oldUb: boolean) {
  return {
    type: YourProjectsActionTypes.INIT_USER_PROJECTS,
    payload: {
      rowsPerPage,
      oldUb,
    },
  };
}

export function GET_USER_PROJECTS(limit: number, offset: number, ordering: string, sortMethod: string,
   filterString: string, oldUb: boolean, appliedFilters = {}) {
  return {
    type: YourProjectsActionTypes.GET_USER_PROJECTS,
    payload: {
      limit,
      offset,
      ordering,
      sortMethod,
      filterString,
      oldUb,
      appliedFilters,
    },
  };
}

export function SET_YOUR_PROJECTS_PAGINATION_START(paginationStart: number) {
  return {
    type: YourProjectsActionTypes.SET_YOUR_PROJECTS_PAGINATION_START,
    payload: {
      paginationStart,
    },
  };
}

export function SET_YOUR_PROJECTS_PAGINATION_TOTAL(data: yourProjectsApiResponse) {
  return {
    type: YourProjectsActionTypes.SET_YOUR_PROJECTS_PAGINATION_TOTAL,
    payload: {
      paginationTotal: data.projects_configured,
    },
  };
}

export function SET_YOUR_PROJECTS_TABLE_PAGE(paginationPage: number) {
  return {
    type: YourProjectsActionTypes.SET_YOUR_PROJECTS_TABLE_PAGE,
    payload: {
      paginationPage,
    },
  };
}

export function SET_YOUR_PROJECTS_SORTING(sortedField: string, sortMethod: 'asc' | 'desc') {
  return {
    type: YourProjectsActionTypes.SET_YOUR_PROJECTS_SORTING,
    payload: {
      sortedField,
      sortMethod,
    },
  };
}

export function DELETE_PROJECT(postDeleteAction: Function, projectId: string) {
  return {
    type: YourProjectsActionTypes.DELETE_PROJECT,
    payload: {
      postDeleteAction,
      projectId,
    },
  };
}

export function SET_SELECTED_PROJECTS(selectedProjects: { [projectId: string]: boolean }) {
  return {
    type: YourProjectsActionTypes.SET_SELECTED_PROJECTS,
    payload: {
      selectedProjects,
    },
  };
}

export function COMBINE_PROJECT(projects: string[]) {
  return {
    type: YourProjectsActionTypes.COMBINE_PROJECT,
    payload: {
      projects,
    },
  };
}

export function REDIRECT_TO_COMBINED_PROJECT(response: { combined_project: string, parts_list: bomPartsList, projects: any}) {
  AppHistory.push(`/combinedprojects/${response.combined_project}`);

  return {
    type: YourProjectsActionTypes.REDIRECT_TO_COMBINED_PROJECT,
  };
}

export function POPULATE_COMBINED_PROJECTS(response: { combined_project: string, parts_list: bomPartsList | [any], projects: any}) {
  return {
    type: YourProjectsActionTypes.POPULATE_COMBINED_PROJECTS,
    payload: {
      combinedProjectId: response.combined_project,
      combinedPartsList: response.parts_list,
      combinedProjects: response.projects,
    },
  };
}

export function GET_COMBINED_PROJECT(combinedProjectId: string) {
  return {
    type: YourProjectsActionTypes.GET_COMBINED_PROJECT,
    payload: {
      combinedProjectId,
    },
  };
}

export function YOUR_PROJECTS_LOAD_PREFERENCES(sortedField: string, sortMethod: string, rowsPerPage: number) {
  return {
    type: YourProjectsActionTypes.YOUR_PROJECTS_LOAD_PREFERENCES,
    payload: {
      sortedField,
      sortMethod,
      rowsPerPage,
    },
  };
}

export function SET_FILTER_DATA(filterSet) {
  return {
    type: YourProjectsActionTypes.SET_FILTER_DATA,
    payload: {
      filterSet,
    },
  };
}

export function CLEAR_YOUR_PROJECTS_FILTER_DATA(){
  return {
    type: YourProjectsActionTypes.CLEAR_YOUR_PROJECTS_FILTER_DATA,
  };
}

export function SHOW_NEW_PROJECTS() {
  return {
    type: YourProjectsActionTypes.SHOW_NEW_PROJECTS,
  };
}

export function SHOW_OLD_PROJECTS() {
  return {
    type: YourProjectsActionTypes.SHOW_OLD_PROJECTS,
  };
}

export function CHECK_UB1_PROJECTS() {
  return {
    type: YourProjectsActionTypes.CHECK_UB1_PROJECTS,
  };
}

export function CHECK_UB1_PROJECTS_FAILED() {
  return {
    type: YourProjectsActionTypes.CHECK_UB1_PROJECTS_FAILED,
  };
}

export function SET_UB1_PROJECTS(data: yourProjectsApiResponse) {
  return {
    type: YourProjectsActionTypes.SET_UB1_PROJECTS,
    payload: {
      ...data,
    },
  };
}

export function CLEAR_YOUR_PROJECT() {
  return {
    type: YourProjectsActionTypes.CLEAR_YOUR_PROJECT,
  };
}

export function SET_PROJECT_CREATED_LOWER_RANGE(from) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_CREATED_LOWER_RANGE,
    payload: {
      from,
    },
  };
}

export function SET_PROJECT_CREATED_UPPER_RANGE(to) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_CREATED_UPPER_RANGE,
    payload: {
      to,
    },
  };
}

export function SET_PROJECT_WATTAGE_LOWER_RANGE(from) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_WATTAGE_LOWER_RANGE,
    payload: {
      from,
    },
  };
}

export function SET_PROJECT_WATTAGE_UPPER_RANGE(to) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_WATTAGE_UPPER_RANGE,
    payload: {
      to,
    },
  };
}

export function SET_PROJECT_PRICE_LOWER_RANGE(from) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_PRICE_LOWER_RANGE,
    payload: {
      from,
    },
  };
}

export function SET_PROJECT_PRICE_UPPER_RANGE(to) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_PRICE_UPPER_RANGE,
    payload: {
      to,
    },
  };
}

export function SET_PROJECT_STATE(state) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_STATE,
    payload: {
      state,
    },
  };
}

export function SET_PROJECT_PRODUCT(state) {
  return {
    type: YourProjectsActionTypes.SET_PROJECT_PRODUCT,
    payload: {
      state,
    },
  };
}

export function SET_MODULE(module) {
  return {
    type: YourProjectsActionTypes.SET_MODULE,
    payload: {
      module,
    },
  };
}

export function APPLY_FILTERS(applyFilters) {
  return {
    type: YourProjectsActionTypes.APPLY_FILTERS,
    payload: {
      applyFilters,
    },
  };
}