import ClampsTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/clampsTooltip';
import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';


export default {
  type: 'Select',
  apiField: 'pro_clamps',
  label: 'clamps',
  tooltip: ClampsTooltip,
  onConfirm: () => {
    makeAllRoofsEmpty();
  },
  shouldConfirm: () => anyPanelsDrawn(),
  data: [
    {
      value: 0,
      name: 'STANDARD CLAMPS',
    },
    {
      value: 1,
      name: 'PRO CLAMPS',
    },
  ],
};
