import { UserPreferencesActionTypes } from 'actionsConstants';
import { showSuccessAlert } from '__common/modules/alerts';


export function SET_USER_PRODUCT(product: number) {
  return {
    type: UserPreferencesActionTypes.SET_USER_PRODUCT,
    payload: {
      product,
    },
  };
}

export function SET_USER_ZIPCODE(zipcode: string) {
  return {
    type: UserPreferencesActionTypes.SET_USER_ZIPCODE,
    payload: {
      zipcode,
    },
  };
}

export function SET_USER_PROJECTS_PER_PAGE(projects_per_page: number) {
  return {
    type: UserPreferencesActionTypes.SET_USER_PROJECTS_PER_PAGE,
    payload: {
      projects_per_page,
    },
  };
}

export function SET_USER_PROJECTS_SORTING(project_sorting: string) {
  return {
    type: UserPreferencesActionTypes.SET_USER_PROJECTS_SORTING,
    payload: {
      project_sorting,
    },
  };
}

export function SET_USER_SORT_METHOD(sort_method: string) {
  return {
    type: UserPreferencesActionTypes.SET_USER_SORT_METHOD,
    payload: {
      sort_method,
    },
  };
}

export function SET_USER_HELIOSCOPE(helioscope_user: boolean) {
  return {
    type: UserPreferencesActionTypes.SET_USER_HELIOSCOPE,
    payload: {
      helioscope_user,
    },
  };
}

export function SET_USER_HELIOSCOPE_LOGIN(helioscope_username: string) {
  return {
    type: UserPreferencesActionTypes.SET_USER_HELIOSCOPE_LOGIN,
    payload: {
      helioscope_username,
    },
  };
}

export function SET_USER_HELIOSCOPE_PASSWORD(helioscope_password: string) {
  return {
    type: UserPreferencesActionTypes.SET_USER_HELIOSCOPE_PASSWORD,
    payload: {
      helioscope_password,
    },
  };
}

export function SET_USER_SPANS(preferred_span: number) {
  return {
    type: UserPreferencesActionTypes.SET_USER_SPANS,
    payload: {
      preferred_span,
    },
  };
}

export function SEND_NEW_USER_PREFERENCES(userPreferences: userPreferences) {
  return {
    type: UserPreferencesActionTypes.SEND_NEW_USER_PREFERENCES,
    payload: {
      userPreferences,
    },
  };
}

export function UPDATE_USER_PREFERENCES(userPreferences: userPreferences, notAfterSave?: boolean) {
  if (!notAfterSave) {
    showSuccessAlert('User preferences have been saved');
  }

  return {
    type: UserPreferencesActionTypes.UPDATE_USER_PREFERENCES,
    payload: {
      userPreferences,
      notAfterSave,
    },
  };
}

export function SET_STAGGER_ATTACHMENTS(stagger_attachments: boolean) {
  return {
    type: UserPreferencesActionTypes.SET_STAGGER_ATTACHMENTS,
    payload: {
      stagger_attachments,
    },
  };
}

export function CLEAR_USER_PREFERENCES() {
  return {
    type: UserPreferencesActionTypes.CLEAR_USER_PREFERENCES,
  };
}

export function USER_PREFERENCES_SAVE_ERROR(data) {
  showSuccessAlert('User preferences error');
  return {
    type: UserPreferencesActionTypes.USER_PREFERENCES_SAVE_ERROR,
    payload: {
      error: data.response,
    },
  };
}

export function SET_AREA(area: string) {
  return {
    type: UserPreferencesActionTypes.SET_AREA,
    payload: {
      area,
    },
  };
}

export function SET_USER_RAFTER_SPACING(rafterSpacing: number) {
  return {
    type: UserPreferencesActionTypes.SET_USER_RAFTER_SPACING,
    payload: {
      rafterSpacing,
    },
  };
}
