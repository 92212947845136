export const apiField = 'attachment_optimization_criteria';

export enum AttachmentOptimization {
  SEISMIC_ONLY = 1,
  MAXIMIZE_BALLAST = 2,
  PSF_LIMIT = 3,
  TOTAL_WEIGHT_ON_THE_ROOF = 4,
  FULLY_ATTACHED = 5
}

const IFI_OPTIMIZE_ATTACHMENT_FOR = [
  {
    value: AttachmentOptimization.SEISMIC_ONLY,
    name: 'SEISMIC ONLY',
  },
  {
    value: AttachmentOptimization.MAXIMIZE_BALLAST,
    name: 'MINIMUM ATTACHMENTS',
  },
  {
    value: AttachmentOptimization.PSF_LIMIT,
    name: 'PSF LIMIT',
  },
  {
    value: AttachmentOptimization.TOTAL_WEIGHT_ON_THE_ROOF,
    name: 'TOTAL WEIGHT ON THE ROOF',
  },
  {
    value: AttachmentOptimization.FULLY_ATTACHED,
    name: 'FULLY ATTACHED',
  },
]
const RM10_OPTIMIZE_ATTACHMENT_FOR = [
  {
    value: AttachmentOptimization.SEISMIC_ONLY,
    name: 'SEISMIC ONLY',
  },
  {
    value: AttachmentOptimization.MAXIMIZE_BALLAST,
    name: 'MINIMUM ATTACHMENTS',
  },
  {
    value: AttachmentOptimization.PSF_LIMIT,
    name: 'PSF LIMIT',
  },
  {
    value: AttachmentOptimization.FULLY_ATTACHED,
    name: 'FULLY ATTACHED',
  },
]
function makeConfig(options: any[]) {
  return {
    type: 'Select',
    label: 'optimizeAttachmentsFor',
    apiField,
    missing: undefined,
    data: options,
  };
}

const config = makeConfig(IFI_OPTIMIZE_ATTACHMENT_FOR);

export const getAttachmentOptimizationCriteriaField = (productId: number) => {
  return makeConfig(RM10_OPTIMIZE_ATTACHMENT_FOR);
}
export default config;
