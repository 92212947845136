import Leaflet from 'leaflet';
import { getBingMap } from '../bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { setRoofsSelectorPlaceMarker } from '../../../components/roofsSelector/components/roofsSelectorLocationSearch/roofsSelectorLocationSearch';

let placeMarker: Leaflet.Marker;
const placeMarkerLayer = new Leaflet.FeatureGroup();

export function setPlaceBingMarker(location: [number, number]) {
  removePlaceBingMarker();
  setRoofsSelectorPlaceMarker({ lat: location[0], lng: location[1] });
  const map = getBingMap();
  placeMarker = Leaflet.marker(location);
  placeMarkerLayer.addLayer(placeMarker);
  placeMarkerLayer.addTo(map);
}

export function removePlaceBingMarker() {
  const map = getBingMap();
  if (placeMarker) {
    placeMarker.remove();
    placeMarker = null;
    map.removeLayer(placeMarkerLayer);
  }
}

export function hidePlaceBingMarker() {
  const map = getBingMap();
  if (placeMarker) {
    map.removeLayer(placeMarkerLayer);
  }
}

export function showPlaceBingMarker() {
  const map = getBingMap();
  if (placeMarker) {
    map.addLayer(placeMarkerLayer);
  } 
}
