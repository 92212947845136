import React from 'react';
import { FETCH_STATIC, OPEN_STATIC } from 'actions';
import { getDownloadFullReportDownloadConfiguration, getDownloadBomDownloadConfiguration, getEngineeringReportDownloadConfiguration, getDownloadLayoutDownloadConfiguration } from './engineeringProjectDocuments/utils/reportsApi';

type Props = {
  dispatch: Function;
  projectId: string;
  productId: string;
  unitsType: unitsType;
};

export default class EngineeringProjectReports extends React.Component<Props> {
  fetchBom = () => {
    const { dispatch, projectId, productId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters, fileNamePrefix } = getDownloadBomDownloadConfiguration(projectId, productId, unitsType);
    dispatch(
      FETCH_STATIC(fileNamePrefix + 'bom.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }

  fetchEngineeringReport = () => {
    const { dispatch, projectId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters } = getEngineeringReportDownloadConfiguration(projectId, unitsType); 
    dispatch(
      FETCH_STATIC('engineering.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }

  fetchBallastMapAndLayout = () => {
    const { dispatch, projectId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters } = getDownloadLayoutDownloadConfiguration(projectId, unitsType);
    dispatch(
      FETCH_STATIC('design_plan.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }

  fetchFullRaport = () => {
    const { dispatch, projectId, productId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters } = getDownloadFullReportDownloadConfiguration(projectId, productId, unitsType);
    dispatch(
      FETCH_STATIC(`${projectId} - full_report.pdf`, type, urlBase, pathVariables, queryParameters),
    );
  }

  openBom = () => {
    const { dispatch, projectId, productId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters, fileNamePrefix } = getDownloadBomDownloadConfiguration(projectId, productId, unitsType);

    dispatch(
      OPEN_STATIC(fileNamePrefix + 'bom.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }

  openEngineeringReport = () => {
    const { dispatch, projectId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters } = getEngineeringReportDownloadConfiguration(projectId, unitsType);
    dispatch(
      OPEN_STATIC('engineering.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }

  openBallastMapAndLayout = () => {
    const { dispatch, projectId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters } = getDownloadLayoutDownloadConfiguration(projectId, unitsType);
    dispatch(
      OPEN_STATIC('design_plan.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }

  openFullRaport = () => {
    const { dispatch, projectId, productId, unitsType } = this.props;
    const { type, urlBase, pathVariables, queryParameters, fileNamePrefix } = getDownloadFullReportDownloadConfiguration(projectId, productId, unitsType);
    dispatch(
      OPEN_STATIC(fileNamePrefix + 'full_report.pdf', type, urlBase, pathVariables, queryParameters),
    );
  }
}
