import {
  BLANK_ENVIRONMENTAL_FACTORS,
  EMPTY_APP_ACTION,
  SET_ENVIRONMENTAL_FACTORS,
  SET_MODEL,
} from 'actions';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { ProjectConfigurationActionTypes } from 'actionsConstants';
import { ENV_LOADING_FINISHED, SET_ENVIRONMENTAL_FACTORS_ACTION, SET_RAIL_ARRANGEMENT_VALID_LIST, FETCH_VALID_RAIL_ARRANGEMENT_LIST, SET_SEISMIC_SETBACK_DISTANCE } from './projectConfigurationActions';
import { Observable } from 'rxjs';
import { AnyAction, Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Action } from '__common/store/action';
import { updateProjectOption, validateSetback, setProjectOptionOnInputUnitChange } from './utils/updateProjectOption';
import { apiField as snowLoadField } from 'projectDesign/components/projectConfiguration/fields/types/snowLoad';
import { apiField as windSpeedField } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { changeRailArrangementTypeWarning, isCanadianZipcode, changeSetbackDistanceWarning } from './utils/validation';
import { isAscender, isGroundProduct, isSolarMount, isRM10, isCanadianChangesProduct, isEcoFoot2Plus, isRM5} from '__common/constants/products';
import { dispatch } from '__common/store';
import { InputUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { cmsToFeets, psfToKpa } from '__common/calculations/unitConversions';
import { round } from 'lodash';
import { metersToFeets } from '__common/calculations/metersToFeets';


let notFoundsZipCodes = [];
const ParapetHeightGreaterThan12Values = [3];

export function getEnvironmentalFactors(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<AnyAction> {
  return action$.ofType(ProjectConfigurationActionTypes.GET_ENVIRONMENTAL_FACTORS)
    .switchMap((action) => {
      const { lat, lng, zipcode, buildingCode, productId, risk_category } = action.payload;
      if (isCanadianZipcode(zipcode)) {
        store.dispatch(SET_ENVIRONMENTAL_FACTORS(zipcode, productId, {
          wind_speed: 185,
          snow_load: round(psfToKpa(50), 2),
          seismic_ss: 1,
          seismic_s1: 0.39,
          elevation: 0,
          building_code: buildingCode || 2,
          wind_special: false
        }));
        return Observable.empty();
      } else {
        const { inputUnit } = store.getState().projectConfiguration;
        return ObservableAjax({
          takeUntil: action$.ofType(ProjectConfigurationActionTypes.SET_ENVIRONMENTAL_FACTORS),
          onSuccess: (factors: { wind_speed: number, wind_special: boolean, snow_load: number, elevation: number, seismic_s1: number, seismic_ss: number }) => {
            const { projectConfiguration: { projectEnvConfig: { imported_project, wind_speed, snow_load, elevation, seismic_ss } } } = store.getState();
            if (imported_project) {
              if (wind_speed) {
                factors['wind_speed'] = wind_speed;
              }
              if (snow_load) {
                factors['snow_load'] = snow_load;
              }
              if (elevation) {
                factors['elevation'] = elevation;
              }
              if (seismic_ss >= 0) {
                factors['seismic_ss'] = seismic_ss;
              }
            }
            dispatch(SET_ENVIRONMENTAL_FACTORS(zipcode, productId, factors))
          },
          onErrorAction: () => {
            if (!notFoundsZipCodes.includes(zipcode)) {
              store.dispatch(BLANK_ENVIRONMENTAL_FACTORS(zipcode, productId));
              notFoundsZipCodes.push(zipcode);
            } else {
              store.dispatch(ENV_LOADING_FINISHED());
            }
          },
          link: hitApi('get', getLinkToEnvFactors(lat, lng, zipcode, buildingCode, risk_category, productId, inputUnit ?? (isAscender(productId) ? InputUnit.METRIC : InputUnit.CUSTOMARY))),
        });
      }
    });
}

export function updateOnEnvironmentalFactorsUpdate(action$: ActionsObservable<Action<SET_ENVIRONMENTAL_FACTORS_ACTION>>, store: Store<appState>): Observable<AnyAction> {
  return action$.ofType(ProjectConfigurationActionTypes.SET_ENVIRONMENTAL_FACTORS)
    .switchMap(({ payload: { factors, productId, zipcode } }) => {
      const { projectConfiguration: { projectEnvConfig, projectVersion, inputUnit } } = store.getState();
      // There are some checks we need to do on fields change like for instance snow load and stagger attachments.
      // Therefore, we can't go directly to the reducer with field updates.
      updateProjectOption(snowLoadField, factors.snow_load, zipcode, productId, projectEnvConfig);
      updateProjectOption(windSpeedField, factors.wind_speed, zipcode, productId, projectEnvConfig);
      validateSetback(productId, projectEnvConfig, projectVersion, inputUnit);
      return Observable.empty();
    });
}

export function saveProjectTitle(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(ProjectConfigurationActionTypes.SAVE_PROJECT_TITLE)
    .switchMap((action) => {
      const { projectId, customTitle } = action.payload;
      return ObservableAjax({
        takeUntil: action$.ofType(ProjectConfigurationActionTypes.SAVE_PROJECT_TITLE),
        onSuccess: EMPTY_APP_ACTION,
        onErrorMessage: 'Cannot save project name',
        link: hitApi('put', `api/v1/project_title/${projectId}/`, { custom_title: customTitle }),
      });
    });
}


export function updateFlyoutOptionsOnInputUnitChange(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<AnyAction>{
  return action$.ofType(ProjectConfigurationActionTypes.TOGGLE_INPUT_UNIT)
    .filter(() => isCanadianChangesProduct(store.getState().projectConfiguration.productId) || isSolarMount(store.getState().projectConfiguration.productId))
    .switchMap((action) => {
      const { projectConfiguration: { productId, inputUnit }, moduleSelector: { selectedModelId, modelData } } = store.getState();
      setProjectOptionOnInputUnitChange();
      const customModel = selectedModelId === 1000001 ? modelData : undefined;
      store.dispatch(SET_MODEL(selectedModelId, customModel, productId, inputUnit, true));
      return Observable.empty();
    });
}


interface RailArrangementApiPayload {
  railArrangementTypeValidList: number[];
}

export function fetchRailArrangementListWhenClampCheckFailed(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(ProjectConfigurationActionTypes.SET_CLAMP_CHECK_FAILED)
    .switchMap(() => {
      const {
        projectConfiguration: { projectId },
      } = store.getState();
      store.dispatch(FETCH_VALID_RAIL_ARRANGEMENT_LIST(projectId));
      return ObservableAjax({
        onSuccess: ({ railArrangementTypeValidList }: RailArrangementApiPayload) => {
          changeRailArrangementTypeWarning();
          return SET_RAIL_ARRANGEMENT_VALID_LIST(railArrangementTypeValidList);
        },
        onErrorMessage: 'Could not fetch valid rail arrangement list',
        link: hitApi('get', `api/v1/valid_rail_arrangement_list/${projectId}`),
      });
    });
}

interface SetbackDistanceApiResponse {
  seismic_setback_ft: number;
  seismic_setback_satisfied: boolean;
}

export function validateSetbackDistance(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(ProjectConfigurationActionTypes.FETCH_MINIMUM_VALID_SETBACK_DISTANCE)
    .switchMap(() => {
      const {
        projectConfiguration: { productId, inputUnit, projectEnvConfig: { seismic_ss, seismic_s1, risk_category, building_code, soil_class, setback_distance, parapet_height, parapet_height_input } },
      } = store.getState();
      const isParapetHeightGreaterThan12 = isRM10(productId) || isEcoFoot2Plus(productId) ? ParapetHeightGreaterThan12Values.includes(parapet_height) : parapet_height_input > 12;
      const setback = isMetricUnit(inputUnit) ? round(cmsToFeets(setback_distance), 1) : setback_distance
      return ObservableAjax({
        onSuccess: ({ seismic_setback_ft, seismic_setback_satisfied }: SetbackDistanceApiResponse) => {
          const seismic_setback = isMetricUnit(inputUnit) ? round(metersToFeets(seismic_setback_ft), 1) : seismic_setback_ft
          changeSetbackDistanceWarning(seismic_setback, seismic_setback_satisfied, productId);
          store.dispatch(SET_SEISMIC_SETBACK_DISTANCE(seismic_setback));
        },
        onErrorMessage: 'Could not validate setback distance',
        link: hitApi('get', `api/v1/valid_setback_distance/?seismic_ss=${seismic_ss}&seismic_s1=${seismic_s1}&risk_category=${risk_category}&building_code=${building_code}&soil_class=${soil_class}&setback_distance=${setback}&isParapetHeightGreaterThan12=${isParapetHeightGreaterThan12}`),
      });
    });
}


export function clearFoundZipCodeForEnvFactors() {
  notFoundsZipCodes = [];
}

const getLinkToEnvFactors = (lat: number, lng: number, zipcode: string, buildingCode: number, risk_category: number, productId: number, inputUnit?: number) => {
  if (risk_category > 0) {
    return `api/v1/get_environmental_factors/?lat=${lat}&lon=${lng}&zipcode=${zipcode}&building_code=${buildingCode}&risk_category=${risk_category}&product_id=${productId}&input_unit=${inputUnit}`;
  }

  if (isGroundProduct(productId)) {
    const riskCategory = 1;
    return `api/v1/get_environmental_factors/?lat=${lat}&lon=${lng}&zipcode=${zipcode}&building_code=${buildingCode}&risk_category=${riskCategory}&product_id=${productId}`;
  }


  return `api/v1/get_environmental_factors/?lat=${lat}&lon=${lng}&zipcode=${zipcode}&building_code=${buildingCode}&input_unit=${inputUnit}`;
};
