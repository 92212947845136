export const apiField = 'rail_clamp_option';

export enum NXTRailClampOption{
    L_FOOT_AND_RAIL_CLAMP = 1,
    RAIL_CLAMP_ONLY = 2,
  };
  
function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'rail_clamp_option',
    apiField,
    data: options,
  };
}

export const railClampOption = makeConfig([
  { 
    value:NXTRailClampOption.L_FOOT_AND_RAIL_CLAMP,
    name: 'L FOOT WITH RAIL CLAMP',
  },
  { 
    value:NXTRailClampOption.RAIL_CLAMP_ONLY,
    name: 'RAIL CLAMP ONLY',
  },
]);