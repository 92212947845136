import { state } from "__common/store";
import { isULA, isGroundProduct } from "__common/constants/products";


export const updateCompliantLetterGroundProduct = (compliantLetter) => {
  const { projectConfiguration: { productId } } = state();
  if (isGroundProduct(productId)) {
    const defaultCompilantLetter = {
      name: 'Certification Letter - CA',
      relative_url: isULA(productId) ? 'media/docs/cert_letters/CERT_LETTER_ULA_CA.pdf' : 'media/docs/cert_letters/GFT_CA_INNOVA_20191231.pdf',
    };
    if (compliantLetter && compliantLetter.relative_url && 
      compliantLetter.relative_url.includes('SM_UNIRAC_227.3_Certification-California')){
      return defaultCompilantLetter;
    }
  }
  return compliantLetter;
};
