export enum LayoutType {
    ballst = 1,
    gcp = 2,
    modified_gcp = 3,
    uplift = 4,
    classification = 5,
    exact_classification = 6,
    remaining_uplift = 7,
    wind_dams = 8,
    wind_dams_with_ballast = 9,
    obstruction_factors = 10,
    building_factors = 11,
    uplift_gcp_for_drag = 12,
    drag_gcp = 13,
    area_reduction_factors = 14,
    modified_uplift_gcp_for_drag = 15,
    modified_drag_gcp = 16,
    ballast_to_resist_sliding = 17,
    ballast_modified = 18,
    wind_dams_with_modified_ballast = 19,
    critical_uplift_weight_map = 20,
    critical_sliding_weight_map = 21,
    ne_wind_5_digit_classification = 22,
    nw_wind_5_digit_classification = 23,
    se_wind_5_digit_classification = 24,
    sw_wind_5_digit_classification = 25,
    uplift_load_sharing_map = 26,
    sliding_load_sharing_map = 27,
    downforce_load_per_module = 28,
    final_downforce_load_per_module = 29,
    ballast_attachment_layout = 30,
    gcp_uplift_map = 31,
    gcp_sliding_map = 32,
    ballast_capacity = 33,
    north_bays_multiplier = 34,
    south_bays_multiplier = 35,
    supplemental_ballast_map = 36,
    weight_to_blocks_map = 37,
    no_of_ballast_supplemental_bays_map = 38,
    cpp_uplift_pressure = 39,
    cpp_sliding_pressure = 40,
    cpp_companion_pressure = 41,
    large_module_factor_map = 42,
    sub_array_factor_map = 43,
    slope_adjustment_factor_map = 44,
    exposure_factors = 45,
    gcp_final_downforce_load_per_module = 46,
    critical_uplift_weight_map_with_attachment = 47,
    north_bay_factor_map = 48,
    uplift_weight_per_module = 49,
    uplift_weight_per_bay = 50,
    uplift_weight_per_bay_with_north_bay_factor = 51,
    total_uplift_weight_per_module = 52,
    dead_load_per_module = 53,
    snow_load_per_module = 54,
    wind_load_per_module_upward = 55,
    wind_load_per_module_downward = 56,
    north_south_bays_multiplier = 57,
    classification_mt = 58,
    wind_uplift_gcp_map_mt = 59,
    net_uplift_load_map_mt = 60,
    snow_load_map_mt = 61,
    wind_downforce_gcp_map_mt = 62,
    deflector_drag_load_map_mt = 63,
    downward_load_map_mt = 64,
    list_of_component_weights_mt = 65,
    wind_drag_gcp_mt = 66,
    setback_factor_map = 67,
    total_download_per_module_map = 68,
    total_download_per_primary_bay_map = 69,
    total_download_per_secondary_bay_map = 70,
    final_downforce_load_per_module_with_values = 71,
    net_uplift_pressure_map_mt = 72,
    deflector_drag_pressure_map_mt = 73,
    down_pressure_map_mt = 74,
    rotation_factor_on_uplift = 75,
    rotation_factor_on_drag_downforce = 76,
    obstruction_factor_map = 77,
    exposure_factor_map = 78,
    obs_exp_rotation_factor_uplift = 79,
    obs_exp_rotation_factor_drag_downforce = 80,
    deflector_map = 81,
    modified_ballast_map_for_sliding = 82,
    ballast_attachment_layout_with_deflectors = 83,
    primary_bay_blocks_for_uplift_with_deflectors = 84,
    modified_ballast_map_for_sliding_with_deflectors = 85,
    downpoint_load_per_roofpad = 86,
    uplift_on_attachments = 87,
    shear_on_attachments = 88,
    wind_load_per_module_upward_with_attachment = 89,
    critical_uplift_weight_map_for_report = 90,
    critical_sliding_weight_map_for_report = 91,
    critical_uplift_weight_map_with_attachment_for_report = 92,
    setback_distance_map = 93,
    ballast_array = 94,
    dead_load_factor_map = 99,
    clamps_location_and_max_module_pressures_map = 100
}


export const GRIDFLX_MAP_EXCLUDED_FOR_DXF = [
    // Gridflex maps
    LayoutType.nw_wind_5_digit_classification,
    LayoutType.ne_wind_5_digit_classification,
    LayoutType.se_wind_5_digit_classification,
    LayoutType.sw_wind_5_digit_classification
]

export const RM10_EVO_MAP_EXCLUDED_FOR_DXF = [

    LayoutType.exact_classification,
    LayoutType.gcp,
    LayoutType.building_factors,
    LayoutType.area_reduction_factors,
    LayoutType.large_module_factor_map,
    LayoutType.sub_array_factor_map,
    LayoutType.drag_gcp,
    LayoutType.uplift_weight_per_bay,
    LayoutType.ballst,
    LayoutType.ballast_attachment_layout,
    LayoutType.total_download_per_primary_bay_map,
    LayoutType.total_download_per_secondary_bay_map,
    LayoutType.final_downforce_load_per_module,
    LayoutType.ballast_array,
    LayoutType.final_downforce_load_per_module_with_values,
    LayoutType.clamps_location_and_max_module_pressures_map

]