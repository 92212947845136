import app from 'app/appReducer';
import bomReducer from 'bom/bomReducer';
import changePassword from 'changePassword/changePasswordReducer';
import confirmClearArraysModal from 'projectDesign/components/confirmClearArraysModal/confirmClearArraysModalReducer';
import disableAccount from 'disableAccount/disableAccountReducer';
import engineering from 'engineering/engineeringReducer';
import engineeringBallastMaps from 'engineering/components/engineeringBallastMaps/engineeringBallastMapsReducer';
import engineeringProjectDocuments from 'engineering/components/engineeringProjectDocuments/engineeringProjectDocumentsReducer';
import forgotPassword from 'forgotPassword/forgotPasswordReducer';
import helioscopeDesignConfig from 'helioscopeImport/components/helioscopeDesignsConfig/helioscopeDesignConfigReducer';
import helioscopeDesigns from 'helioscopeImport/components/helioscopeDesignsList/helioscopeDesignsListReducer';
import moduleSelectorReducers from '__common/components/moduleSelector/moduleSelectorReducer';
import panelsEditorReducers from '__editor/panelsEditor/reducers';
import projectConfiguration from 'projectDesign/components/projectConfiguration/projectConfigurationReducer';
import projectsList from 'helioscopeImport/components/helioscopeProjectsList/HelioscopeProjectsListReducer';
import railsSelectorReducer from 'bom/components/railsSelector/railsSelectorReducer';
import reportProblem from 'app/components/ReportProblem/ReportProblemReducer';
import faq from 'app/components/FaqComponent/FaqReducer';
import productDisabledInfo from 'app/components/InfoModal/InfoModalReducer';
import resetPassword from 'resetPassword/resetPasswordReducer';
import roofsSelector from '__editor/components/roofsSelector/roofsSelectorReducer';
import roofsSelectorMap from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapReducer';
import roofsSelectorReducer from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorReducers';
import router from '__common/router/routerReducer';
import user from 'user/userReducer';
import userPreferences from 'userPreferences/userPreferencesReducer';
import yourProject from 'yourProjects/yourProjectsReducer';
import auroraDesignConfig from 'auroraImport/auroraConfigReducer';

export default {
  ...app,
  ...router,
  ...user,
  ...projectsList,
  ...helioscopeDesigns,
  ...helioscopeDesignConfig,
  ...panelsEditorReducers,
  ...roofsSelectorReducer,
  ...moduleSelectorReducers,
  ...bomReducer,
  ...projectConfiguration,
  ...roofsSelector,
  ...roofsSelectorMap,
  ...engineering,
  ...railsSelectorReducer,
  ...userPreferences,
  ...yourProject,
  ...confirmClearArraysModal,
  ...disableAccount,
  ...changePassword,
  ...resetPassword,
  ...forgotPassword,
  ...reportProblem,
  ...faq,
  ...productDisabledInfo,
  ...engineeringProjectDocuments,
  ...engineeringBallastMaps,
  ...auroraDesignConfig
};
