import store from '__common/store';
import { polygonOptions } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerConfigs';
import Events from '__common/modules/Events';
import { deleteAllGoogleRoofs } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';

export function roofSelectorStyles(shape: google.maps.Polygon) {
  shape.setOptions({ fillColor: polygonOptions.fillColor, fillOpacity: polygonOptions.fillOpacity });
}

export function previewModeRoofStyles(shape: googleRoofObject) {
  const { background } = store.getState();
  const fillOpacity = background.selectedRoofId === shape.id ? 0.35 : 1;
  const fillColor = background.selectedRoofId === shape.id ? polygonOptions.fillColor : shape.color;
  shape.overlay.setOptions({ fillColor, fillOpacity });
}

export function clearEvents() {
  Events.removeEventListener('keydown.roofsSelectorStage');
  Events.removeEventListener('keyup.roofsSelectorStage');
  Events.removeEventListener('dblclick.roofsSelectorStage');
  Events.removeEventListener('mousedown.roofsSelectorStage');
}

export function resetGoogleMapsEditor() {
  clearEvents();
  deleteAllGoogleRoofs();
}
