import { calcDistanceOfTwoPoints } from "./distanceBetweenTwoPoints";

// Function to return the minimum distance
// between a line segment AB and a point P

export function minDistanceToSegment(P: { x: number, y: number }, A: { x: number, y: number }, B: { x: number, y: number }) {
  var l2 = calcDistanceOfTwoPoints(A, B);
  if (l2 == 0) return calcDistanceOfTwoPoints(P, A);
  var t = ((P.x - A.x) * (B.x - A.x) + (P.y - A.y) * (B.y - A.y)) / (l2 * l2);
  t = Math.max(0, Math.min(1, t));
  return calcDistanceOfTwoPoints(P, {
    x: A.x + t * (B.x - A.x),
    y: A.y + t * (B.y - A.y)
  });
}


