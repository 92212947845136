

import { ActionTypes } from 'actionsConstants';

export function fetchHelioscopeDesignsList(projectId: string) {
  return {
    type: ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_REQUEST,
    payload: {
      projectId,
    },
  };
}

export function fetchHelioscopeDesignsListSuccess(HelioscopeDesignsList: any = {}) {
  return {
    type: ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_SUCCESS,
    payload: {
      data: HelioscopeDesignsList,
    },
  };
}

export function fetchHelioscopeDesignsListWaitForSocket(taskId: string) {
  return {
    type: ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_WAIT_FOR_SOCKET,
    payload: {
      taskId,
    },
  };
}

export function fetchHelioscopeDesignsListWaitForSocketData() {
  return {
    type: ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_WAIT_FOR_SOCKET_DATA,
  };
}

export function clearHelioscopeDesignsList() {
  return {
    type: ActionTypes.CLEAR_HELIOSCOPE_DESIGNS_LIST_STATE,
  };
}
