import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EngineeringBalastActionButton from './engineeringBalastActionButton';
import { LAYOUT_TYPE_DXF_GENERATE_START } from 'engineering/components/engineeringProjectDocuments/engineeringProjectDocumentsActions';
import { getFileNamePrefix } from 'engineering/components/engineeringProjectDocuments/utils/reportsApi';

type Props = {
    dispatch: Function,
    productId: string,
    projectId: string,
    layoutType: string,
    engineeringBallastMaps: engineeringBallastMapsState,
};

class LayoutTypeDxfDownloadButton extends PureComponent<Props> {

    fetchDXF = () => {
        const { engineeringBallastMaps: { generatedArrayId, generatedLayoutType }, dispatch, productId, projectId } = this.props;

        const fileNamePrefix = getFileNamePrefix(productId, projectId);

        dispatch(
            LAYOUT_TYPE_DXF_GENERATE_START(projectId, generatedArrayId, `${fileNamePrefix.toUpperCase()}.dxf.zip`, 'application/zip', `reports/${projectId}/dxf/`, { generatedArrayId }, generatedLayoutType)
          )
    }


    render() {
        return (
            <EngineeringBalastActionButton
                tooltipLabel={`Download DXF`}
                onClick={this.fetchDXF}
                svgPath={require('assets/media/icons/dxf-file-icon.svg')}
            />
        );
    }
}

function mapStateToProps(appState: appState) {
    return {
        engineeringBallastMaps: appState.engineeringBallastMaps,
    };
}

export default connect(mapStateToProps)(LayoutTypeDxfDownloadButton);
