import { apiField, RoofExposureOption, RoofExposureField } from 'projectDesign/components/projectConfiguration/fields/types/roofExposure';
import roofExposureTooltip from '../fieldsTooltips/roofExposureTooltip';

const config: RoofExposureField = {
  label: 'RoofExposure',
  type: 'Select',
  apiField: apiField,
  missing: undefined,
  tooltip: roofExposureTooltip,
  data: [
    RoofExposureOption.fullyExposed,
    RoofExposureOption.partiallyExposed,
    RoofExposureOption.sheltered,
  ],
};

export default config;
