import { createReducer } from '__common/utils/helpers';
import { SettingsActionTypes } from 'actionsConstants';

const panelScale = 0.05;
let panelWidth = 10;
let panelHeight = 15;
const defaultScale = 1;
const gapBetweenPanels = 1;
export const settingsState: settingsState = {
  // TODO probably this is unused
  panelScale,
  canvasCenter: {
    x: 0,
    y: 0,
  },
  backgroundWidth: 1366,
  backgroundHeight: 768,
  backgroundBorder: 0x000,
  backgroundColor: 0xffffff,
  landscapeOrientation: true,
  canCreatePanel: true,
  rubberBandColor: 0x000,
  gapBetweenPanels,
  startSelectingAfterPx: 1,
  snapToGridInDistanceOf: 1000,
  scale: defaultScale,
  panelWidth,
  panelHeight,
  shadowPanelColor: 0xd3d3d3,
  shadowPanelWidth: panelWidth,
  shadowPanelHeight: panelHeight,
  columnSpacing: 0,
  rowSpacing: 0,
  productId: 0,
  tableRowSpacing: 0,
  mapDisplacedFromRoof: false,
  calculateZoneClassication : false,
};

export default {
  settings: createReducer(settingsState, {
    [SettingsActionTypes.DRAW_ORIENTATION_CHANGE](state: settingsState): settingsState {
      return { ...state, panelWidth: state.panelHeight, panelHeight: state.panelWidth };
    },
    [SettingsActionTypes.CHANGE_PANELS_SIZES](state: settingsState): settingsState {
      panelWidth = state.panelHeight;
      panelHeight = state.panelWidth;
      return { ...state, panelWidth, panelHeight };
    },
    [SettingsActionTypes.SET_PANELS_WIDTH_AND_HEIGHT](state: settingsState, action): settingsState {
      return { ...state, ...action.payload };
    },
    [SettingsActionTypes.SET_CANVAS_CENTER](state: settingsState, action): settingsState {
      const { x, y } = action.payload;
      return { ...state, canvasCenter: { x, y } };
    },
    [SettingsActionTypes.SET_ROW_SPACING](state: settingsState, action): settingsState {
      return { ...state, ...action.payload };
    },
    [SettingsActionTypes.SET_COLUMN_SPACING](state: settingsState, action): settingsState {
      return { ...state, ...action.payload };
    },
    [SettingsActionTypes.SET_TABLE_ROW_SPACING](state: settingsState, action): settingsState {
      return { ...state, ...action.payload };
    },
    [SettingsActionTypes.SET_RELOCATION_ACTIVE](state: settingsState, action): settingsState {
      const value = action.payload.mapDisplacedFromRoof
      return { ...state,mapDisplacedFromRoof:value };
    },
    [SettingsActionTypes.CALCULATE_ZONE_CLASSIFICATION](state: settingsState, action): settingsState {
      return { ...state, ...action.payload };
    },
  }),
};
