

import { ActionTypes } from 'actionsConstants';

export function fetchHelioscopeDesignsConfigInitDataRequest(projectId: string, designId: string, fieldSegmentId: string, productId: string) {
  return {
    type: ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_REQUEST,
    payload: {
      projectId,
      designId,
      fieldSegmentId,
      productId,
    },
  };
}

export function fetchHelioscopeDesignsFullDesignConfigInitDataRequest(projectId: string, designId: string, productId: string) {
  return {
    type: ActionTypes.HELIOSCOPE_DESIGN_FULL_DESIGN_CONFIG_INIT_DATA_REQUEST,
    payload: {
      projectId,
      designId,
      productId,
    },
  };
}


export function fetchHelioscopeDesignsConfigInitDataWaitForSocket(taskId: string) {
  return {
    type: ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_WAIT_FOR_SOCKET,
    payload: {
      taskId,
    },
  };
}

export function fetchHelioscopeDesignsConfigInitDataSuccess(data: any = {}) {
  return {
    type: ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchHelioscopeDesignsConfigInitDataWaitForSocketData() {
  return {
    type: ActionTypes.HELIOSCOPE_DESIGN_CONFIG_INIT_DATA_WAIT_FOR_SOCKET_DATA,
  };
}

export function clearHelioscopeDesignsConfigInitData() {
  return {
    type: ActionTypes.CLEAR_HELIOSCOPE_DESIGN_CONFIG_INIT_DATA,
  };
}

export function updateHelioscopeDesignsConfigInitData(field: string, value: any) {
  return {
    type: ActionTypes.UPDATE_HELIOSCOPE_DESIGN_CONFIG_INIT_DATA,
    payload: {
      field,
      value,
    },
  };
}

export function IMPORT_HELIOSCOPE_PROJECT(data: any = {}) {
  return {
    type: ActionTypes.IMPORT_HELIOSCOPE_PROJECT,
    payload: {
      data,
    },
  };
}

export function IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT(data: any = {}) {
  return {
    type: ActionTypes.IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT,
    payload: {
      data,
    },
  };
}

export function IMPORT_HELIOSCOPE_PROJECT_SUCCESS(projectData: any = {}) {
  return {
    type: ActionTypes.IMPORT_HELIOSCOPE_PROJECT_SUCCESS,
    payload: {
      projectData,
    },
  };
}

export function SEARCH_FOR_MODEL(data) {
  return {
    type: ActionTypes.SEARCH_FOR_MODEL,
  };
}

export function IMPORT_HELIOSCOPE_PROJECT_FAILED() {
  return {
    type: ActionTypes.IMPORT_HELIOSCOPE_PROJECT_FAILED,
  };
}
