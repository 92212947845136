import * as PIXI from 'pixi.js';
import { getStage } from '__editor/panelsEditor/components/stage/stage';

export function createPolygonHelper(panel) {
  const helperPanelPolygon = new PIXI.Graphics();
  helperPanelPolygon.beginFill(0xFFFFFF);
  helperPanelPolygon.lineStyle(1, 0x000FFF);
  helperPanelPolygon.drawRect(panel.x, panel.y, panel.width, panel.height);
  helperPanelPolygon.endFill();
  helperPanelPolygon.id = 'helperPanelPolygon';
  helperPanelPolygon.zIndex = 9999999;
  return helperPanelPolygon;
}

export function createRoofEdgesHelper(roofEdgesCoords) {
  const coords = getCoords(roofEdgesCoords);
  const helperRoofEdges = new PIXI.Graphics();
  helperRoofEdges.lineStyle(1, 0xFF00000);
  helperRoofEdges.drawPolygon(coords);
  helperRoofEdges.endFill();
  helperRoofEdges.id = 'roofEdgesHelper';
  helperRoofEdges.zIndex = 9999999;
  return helperRoofEdges;
}

export function printOnScreen(pixiObject: PIXI.Graphics) {
  const stage = getStage();
  const id = pixiObject.id;
  stage.children = stage.children.filter((child: PIXI.Graphics) => child.id !== id);
  stage.addChild(pixiObject);
}

export function getCoords(coords) {
  return coords.reduce((acc, dot) => {
    acc.push(dot.x);
    acc.push(dot.y);
    return acc;
  },                   []).filter(coord => coord);
}
