import BuildingCodeTooltip, { BuildingCodeTooltipConfig } from 'projectDesign/components/projectConfiguration/fieldsTooltips/buildingCodeTooltip';
import { asce_705, asce_710, asce_716, asce_722, BuildingCode, isASCE716, isASCE722 } from '__common/constants/buildingCodes';
import { showErrorAlert } from '__common/modules/alerts';
import {  clearRoofsWhenAsce716Or722IsEnabled, } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';

export const apiField = 'building_code';

export interface BuildingCodeField {
  type: string;
  apiField: string;
  label: string;
  tooltip: () => string;
  data: BuildingCode[];
  shouldConfirm?: Function;
  onConfirm?: (val: any) => void;
  shouldConfirmCb? : (val: string) => boolean;
  title?: string;
  content?: (val) => string;
  disable?: boolean;
}


function makeTooltipConfig(options: BuildingCode[]): BuildingCodeTooltipConfig {
  const optionValues: number[] = options.map(({ value }) => value);
  return {
    ...{ asce705enabled: optionValues.includes(asce_705.value) },
    ...{ asce710enabled: optionValues.includes(asce_710.value) },
    ...{ asce716enabled: optionValues.includes(asce_716.value) },
    ...{ asce722enabled: optionValues.includes(asce_722.value) },
  };
}

// Remove: once roof pitch 1/12 is available for 716
function makeConfig(options: BuildingCode[], smAndNxt : boolean = false, isAurora: boolean = false): BuildingCodeField {  
  return {
    type: 'Select',
    label: 'buildingCode',
    tooltip: () => BuildingCodeTooltip(makeTooltipConfig(options)),
    apiField,
    data: options,
    ...(smAndNxt ? {
      onConfirm: (val: string) => {
        clearRoofsWhenAsce716Or722IsEnabled(Number(val));
      },
      shouldConfirm: () => anyPanelsDrawn() ,
      shouldConfirmCb: (val: string) => {
        const is722 = isASCE722(Number(val));
        let is716or722 = isASCE716(Number(val)) || is722;
        is716or722 && showErrorAlert(`If ${ is722 ? 'ASCE 7-22' : 'ASCE 7-16' } is selected, panels in close proximity to roof edges/boundaries may be lost`);
        return is716or722;
      },
      title: 'Are you sure you want to change the building code ?',
      content: (val) => `If ${ isASCE716(Number(val)) ? 'ASCE 7-16' : 'ASCE 7-22' } is selected, roofs with roof pitch less than or equal to 7 will have the panels deleted`,
    }: {}),
    disable: isAurora
  };
}



const config = makeConfig([
  asce_705, asce_710,
]);

export default config;

export const asce710716BuildingCode = makeConfig([
  asce_710, asce_716,
]);

export const smTiltBuildingCode = makeConfig([
  asce_705,
]);

export const adjustableTiltBuildingCode = makeConfig([
  asce_705, asce_710, asce_716,
])

export const asce716BuildingCode = makeConfig([
  asce_705, asce_710, asce_716,
]);

// Remove: once roof pitch 1/12 is available for 716 - check its references in sm and nxt config files
export const asce716BuildingCodeSm = makeConfig([
  asce_705, asce_710, asce_716,
], true);

export const asce716BuildingCodeNXTMetalx = makeConfig([
  asce_710, asce_716,
], true);

export const onlyAsce716BuildingCode = makeConfig([asce_716]);

export const asce716and722BuildingCode = (isAurora: boolean = false) =>{
  return makeConfig([asce_716, asce_722], false, isAurora);
} 

export const asce722BuildingCodeNXTUmount =(isAurora: boolean = false) => {
  return makeConfig([
    asce_710, asce_716, asce_722
  ], true, isAurora);
} 

export const asce722BuildingCodeSM =(isAurora: boolean = false)=>{
  return makeConfig([
    asce_705, asce_710, asce_716, asce_722
  ], true, isAurora);
} 
// bulding code only for aurora, if any of the roofpitches 1/12 and the user wants to switch from 710 and 716/722 codes,then we hide them
export const asceBuildingCodeSMForAurora =(isAurora: boolean = false)=>{
  return makeConfig([
    asce_705, asce_710
  ], true, isAurora);
} 

export const asceBuildingCodeNHForAurora =(isAurora: boolean = false)=>{
  return makeConfig([
    asce_710
  ], true, isAurora);
}

export const asce710716722BuildingCode = makeConfig([ asce_710, asce_716, asce_722 ]);
export const asce722BuildingCodeRM5 = makeConfig([ asce_705, asce_710, asce_716, asce_722 ]);
