import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';
import { products } from './products';
import { state } from '__common/store';

export const getCombainedTitle = (productId: number) => {
  const { type, name } = getProductTitle(productId);
  return `${type} ${name}`;
};


export const getFormattedProductName = (productId: number) => {
  switch (productId) {
    case products.nxt_umount:
    case products.nxt_horizon_hd:
    case products.nxt_horizon_lt:
      return 'NXT UMOUNT';
    case products.sm_tilt_pr:
    case products.sm_tilt_pr_lt:
      return 'SM Tilt PR'
    case products.solarmount_2:
      return 'SOLARMOUNT Flush';
    case products.solarmount_2_tilt:
      return 'SOLARMOUNT Tilt';
    case products.sfmEnhanced:
      return 'SUNFRAME MicroRail';
    case products.sfmInfinity:
      return 'SFM Infinity';
    case products.metal_x:
      return 'METALX';
    case products.sunframe:
      return 'SUNFRAME';
    case products.rm_5:
      return 'ROOFMOUNT RM5';
    case products.rm:
      return 'ROOFMOUNT RM10';
    case products.rm_10_evolution:
      return 'ROOFMOUNT RM10 EVO';
    case products.rm_dt:
      return 'ROOFMOUNT RMDT';
    case products.rm_10_ultra:
      return 'ROOFMOUNT RM10 Ultra';
    case products.ecofoot2_plus:
      return 'ECOFOOT2+';
    case products.rm_gridflex:
      return 'ROOFMOUNT GridFlex';
    case products.rm_gridflex_10:
      return 'ROOFMOUNT Gridflex 10';
    case products.gft:
      return 'GFT';
    case products.ula:
      return 'ULA';
    case 98:
      return 'HELIOSCOPE PROJECTS';
    default:
      return 'SOLARMOUNT Flush';
  }
};



export const getProductTitle = (productId: number, checkingCondition? : boolean) => {
  const { projectConfiguration : { projectVersion } } = state()
  switch (productId) {
    case products.nxt_umount:
    case products.nxt_horizon_hd:
    case products.nxt_horizon_lt:
      return {
        type: 'Rail',
        name: '- NXT UMOUNT',

      }
    case products.sm_tilt_pr:
    case products.sm_tilt_pr_lt:
      return {
        type: 'Rail',
        name: '- SM Tilt PR',

      }
    case products.solarmount_2:
      return {
        type: 'Rail',
        name: '- SOLARMOUNT Flush',
      };
    case products.solarmount_2_tilt:
    case products.nxt_tilt:
    // case products.adjustable_tilt_system:
      return {
        type: 'Rail',
        name: checkingCondition ? '- Adjustable Tilt System' : adjustableTiltSystem(projectVersion) ? '- Adjustable Tilt System' : '- SOLARMOUNT Tilt',
        // name: '- SOLARMOUNT Tilt',
      };
    case products.ascender:
      return {
        type: 'Rail',
        name: '- SM ASCENDER',
      };
    case products.sfmEnhanced:
      return {
        type: 'Rail less',
        name: '- SUNFRAME MicroRail',
      };
    case products.sfmInfinity:
      return {
        type: 'Rail less',
        name: '- SFM Infinity',
      };
    case products.metal_x:
      return {
        type: 'Rail less',
        name: '- METALX',
      };
    case products.sunframe:
      return {
        type: 'Shared rail',
        name: '- SUNFRAME',
      };
    case products.rm_5:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT RM5',
      };
    case products.rm:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT RM10',
      };
    case products.rm_10_evolution:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT RM10 EVO',
      };
    case products.rm_dt:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT RMDT',
      };
    case products.rm_10_ultra:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT RM10 Ultra',
      };
    case products.ecofoot2_plus:
      return {
        type : 'Ballasted',
        name : '- ECOFOOT2+',
      };
    case products.rm_gridflex:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT GridFlex',
      };
    case products.rm_gridflex_10:
      return {
        type: 'Ballasted',
        name: '- ROOFMOUNT GridFlex 10',
      };
    case products.gft:
      return {
        type: 'Ground',
        name: '- GFT',
      };
    case products.ula:
      return {
        type: 'Ground',
        name: '- ULA',
      };
    case 98:
      return {
        type: '',
        name: '- HELIOSCOPE PROJECTS',
      };
    default:
      return {
        type: 'Rail',
        name: 'SOLARMOUNT - Flush',
      };
  }
};

