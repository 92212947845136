import keyMirror from 'keymirror';

/**
 * @namespace Constants
 * @desc Settings constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const SettingsActionTypes = keyMirror({
  DRAW_ORIENTATION_CHANGE: undefined,
  CHANGE_PANELS_SIZES: undefined,
  SET_PANELS_WIDTH_AND_HEIGHT: undefined,
  SET_CANVAS_CENTER: undefined,
  SET_ROW_SPACING: undefined,
  SET_COLUMN_SPACING: undefined,
  SET_TABLE_ROW_SPACING: undefined,
  SET_RELOCATION_ACTIVE: undefined,
  CALCULATE_ZONE_CLASSIFICATION: undefined,
});
