import { ActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import {
  FETCH_PROJECTS_LIST_SUCCESS, FETCH_PROJECTS_LIST_FAILURE,
} from 'actions';

export function fetchHelioscopeProjectsListRequest(action$) {
  return action$.ofType(ActionTypes.FETCH_PROJECTS_LIST_REQUEST)
    .switchMap(() =>
      ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.FETCH_PROJECTS_LIST_SUCCESS),
        onSuccess: FETCH_PROJECTS_LIST_SUCCESS,
        onError: FETCH_PROJECTS_LIST_FAILURE,
        onErrorMessage: 'Cannot fetch project list',
        socketName: 'helioscope',
        link: hitApi('get', 'api/v1/helioscope/'),
      }),
    );
}
