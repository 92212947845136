let placeMarker: { lat: number, lng: number } | undefined; 

export function setRoofsSelectorPlaceMarker(marker) {
  placeMarker = marker;
}

export function getRoofsSelectorPlaceMarker() {
  return placeMarker;
}

export function removeRoofsSelectorPlaceMarker() {
  placeMarker = undefined;
}
