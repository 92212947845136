import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import DialogContainer from 'react-md/lib//Dialogs/DialogContainer';
import { connect } from 'react-redux';
import { HIDE_CONFIRM_CLEAR_ARRAYS_MODAL } from 'actions';
import { state } from '__common/store';

type Props = {
  dispatch: Function,
  isOpen: boolean,
  onCancelAction?: any,
  onConfirmAction: any,
  onConfirmCallback: Function,
  onCancelCallback: Function,
  mapType: string,
  title: string,
  content: string,
  confirmTitle: string,
  cancelTitle: string,
  dialogStyleProps: Object,
  modal: boolean,
};

class ConfirmClearArraysModal extends React.Component<Props, {}> {
  hide = () => {
    const { dispatch } = this.props;
    dispatch(HIDE_CONFIRM_CLEAR_ARRAYS_MODAL());
  }

  cancel = () => {
    const { dispatch, onCancelAction, onCancelCallback } = this.props;
    if (onCancelAction) {
      dispatch(onCancelAction);
    }
    if (onCancelCallback) {
      onCancelCallback();
    }
    this.hide();
  }

  onContinue = () => {
    const { onConfirmAction, dispatch, onConfirmCallback } = this.props;

    this.hide();

    if (onConfirmAction) {
      dispatch(onConfirmAction);
    }
   
    if (onConfirmCallback) {
      onConfirmCallback();
    }
  }

  render() {
    const { isOpen, title, content, confirmTitle, cancelTitle, dialogStyleProps, modal } = this.props;
    const { projectConfiguration: { projectEnvConfig: { helioscope_id } }}=state();
    
    const actions = helioscope_id && !title ? 
      [(<Button flat={true} className="submit-button" onClick={this.onContinue}>{confirmTitle || 'YES, CONTINUE'}</Button>),]:
      [(<Button flat={true} onClick={this.cancel}>{cancelTitle || 'NO, CANCEL'}</Button>),
      (<Button flat={true} className="submit-button" onClick={this.onContinue}>{confirmTitle || 'YES, CONTINUE'}</Button>),
    ];

    return (
        <DialogContainer
          {...dialogStyleProps}
          id="arrays-lost-modal"
          visible={isOpen}
          actions={actions}
          title={title}
          autosizeContent={false}
          initialFocus={''}
          focusOnMount={false}
          onHide={this.cancel}
          modal={modal ? modal : false}
        >
          {content}
        </DialogContainer>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    isOpen: appState.confirmClearArraysModal.isOpen,
    onCancelAction: appState.confirmClearArraysModal.onCancelAction,
    onConfirmAction: appState.confirmClearArraysModal.onConfirmAction,
    onConfirmCallback: appState.confirmClearArraysModal.onConfirmCallback,
    onCancelCallback: appState.confirmClearArraysModal.onCancelCallback,
    title: appState.confirmClearArraysModal.title,
    confirmTitle: appState.confirmClearArraysModal.confirmTitle,
    cancelTitle: appState.confirmClearArraysModal.cancelTitle,
    dialogStyleProps: appState.confirmClearArraysModal.dialogStyleProps,
    content: appState.confirmClearArraysModal.content, 
    mapType: appState.roofsSelector.mapType,
    roofs: appState.drawingManager.roofs,
    modal: appState.confirmClearArraysModal.modal,
  };
}

export default connect(mapStateToProps)(ConfirmClearArraysModal);
