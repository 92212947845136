import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isRMGridFlex, isRM10Ultra } from '__common/constants/products';

/**
 * for RM products on blank design we want to draw virtual roof edges
 * that are updated whenever we add or remove panels. The borders don't detect collision.
 * We need them so we can infer a roof zone on blank design. 
 * The assumption is that the there is a 3 feet offset between outermost panels
 * and the virtual edges.
 */

export function getOffsetInMeters() {
  return feetsToMeters(3);
}

export function getOffsetInPixels(metersPerPixel: number, productId: number, setbackDistance?: number) {
  if(isRMGridFlex(productId) || isRM10Ultra(productId)) {
    return feetsToMeters(setbackDistance) / metersPerPixel;
  }
  return getOffsetInMeters() / metersPerPixel;
}
