import { createReducer } from '__common/utils/helpers';
import { LeadEdgeRoofSelectorActionTypes } from 'actionsConstants';


export const leadEdgeRoofSelectorState: leadEdgeRoofSelectorState = {
  leadEdges: {},
};

export default {
  leadEdgeRoofSelector: createReducer(leadEdgeRoofSelectorState, {
    [LeadEdgeRoofSelectorActionTypes.SELECT_ROOF_LEAD_EDGE](state, action) {
      const { roofEdgeNumber, roofId } = action.payload;

      state.leadEdges[roofId] = roofEdgeNumber;

      return { ...state };
    },
    [LeadEdgeRoofSelectorActionTypes.CLEAR_ROOF_LEAD_EDGE](state, action) {
      const { selectedRoofId } = action.payload;
      if (state.leadEdges.hasOwnProperty(selectedRoofId)) {
        delete state.leadEdges[selectedRoofId];
      }

      return { ...state };
    },
    [LeadEdgeRoofSelectorActionTypes.DELETE_ALL_LEAD_EDGES](state) {
      return { ...state, leadEdges: {} };
    },
  }),
};
