import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import Loader from '__common/components/LoaderComponent';
import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields/TextField';
import { connect } from 'react-redux';
import {
  CHANGE_PASSWORD_ON_DEMAND,
  RESET_PASSWORD_ON_DEMAND,
  SET_EMAIL_PAGE,
  SET_PASSWORD_PAGE,
  RESET_PASSWORD_CLEAR,
  } from 'actions';

type State = {
  email: string;
  newPassword: string;
  newPasswordRepeat: string;
  passwordNotMatch: boolean,
};

type Props = {
  dispatch: Function;
  forgotPassword: forgotPasswordState;
  match: any;
};

class ForgotPassword extends React.Component<Props, State> {
  state = {
    email: '',
    newPassword: '',
    newPasswordRepeat: '',
    passwordNotMatch: false,
  };

  componentDidMount() {
    this._setPage();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(RESET_PASSWORD_CLEAR());
  }

  _setPage() {
    const {
      dispatch,
      match: {
        params: { token, uid },
      },
    } = this.props;

    if (token && token.length && uid && uid.length) {
      dispatch(SET_PASSWORD_PAGE());
    } else {
      dispatch(SET_EMAIL_PAGE());
    }
  }

  _setEmail = (email: string) => {
    this.setState({ email });
  }

  _setNewPassword = (newPassword: string) => {
    this.setState({ newPassword });
  }

  _setNewPasswordRepeat = (newPasswordRepeat: string) => {
    this.setState({ newPasswordRepeat });
  }

  _resetPasswordHandler = () => {
    const { email } = this.state;
    const { dispatch } = this.props;

    if (email && email.length) {
      dispatch(RESET_PASSWORD_ON_DEMAND(email));
    }
  }

  _changePasswordHandler = () => {
    const { 
      match: {
        params: { token, uid },
    }, 
  } = this.props;
    const { newPassword, newPasswordRepeat } = this.state;
    const { dispatch } = this.props;

    if (newPassword.length === 0 || newPasswordRepeat.length === 0) {
      return;
    }

    if (newPassword !== newPasswordRepeat) {
      return this.setState({ passwordNotMatch: true });
    }

    this.setState({ passwordNotMatch: false });
    
    dispatch(CHANGE_PASSWORD_ON_DEMAND(newPassword, newPasswordRepeat, token, uid));
  }

  handleKeyPressOnFormInput = (e, cb) => {
    if (e.key === 'Enter') {
      cb();
    }
  }

  passwordNotMatch = () => {
    const { passwordNotMatch } = this.state;

    if (passwordNotMatch) {
      return (
        <div className="input-warning">Passwords don't match</div>
      );
    }

    return null;
  }

  changePassword = () => {
    const { newPassword, newPasswordRepeat } = this.state;

    return (
      <>
        <div className="maintitle">CHANGE YOUR PASSWORD</div>
        <form>
          {this.passwordNotMatch()}
          <div className="input-label">New password:</div>
          <TextField
            id="newpassword"
            type="password"
            placeholder="New password"
            onKeyDown={e => this.handleKeyPressOnFormInput(e, this._changePasswordHandler)}
            onChange={this._setNewPassword}
            value={newPassword}
          />
          <div className="input-label">Repeat your password:</div>
          <TextField
            id="newpasswordrepeat"
            type="password"
            placeholder="Repeat your password"
            onKeyDown={e => this.handleKeyPressOnFormInput(e, this._changePasswordHandler)}
            onChange={this._setNewPasswordRepeat}
            value={newPasswordRepeat}
          />
        </form>
        <Button
          flat={true}
          type="button"
          className="form_button"
          id="reset_password_button"
          onClick={this._changePasswordHandler}
        >
          CHANGE PASSWORD
        </Button>
      </>
    );
  }

  emailForm = () => {
    const { email } = this.state;
    return (
      <>
        <div className="maintitle">RESET PASSWORD</div>
          <div>
          <div className="input-label">YOUR EMAIL:</div>
          <TextField
            id="email"
            type="email"
            placeholder="Your email"
            onKeyDown={e => this.handleKeyPressOnFormInput(e, this._resetPasswordHandler)}
            onChange={this._setEmail}
            value={email}
          />
          </div>
        <Button
          flat={true}
          type="button"
          className="form_button"
          id="reset_password_button"
          onClick={this._resetPasswordHandler}
        >
          RESET PASSWORD
        </Button>
      </>
    );
  }

  cannotResetYourPassword = () => {
    return (
      <>
        <div className="maintitle">RESET PASSWORD</div>
        <div>Cannot reset your password</div>
      </>
    );
  }

  emailSent = () => {
    return (
      <>
        <div className="maintitle">RESET PASSWORD</div>
        <div>We sent an email to your with instructions</div>
      </>
    );
  }
  
  cannotChangeYourPassword = () => {
    return (
      <>
        <div className="maintitle">RESET PASSWORD</div>
        <div>Cannot change your password</div>
      </>
    );
  }
  
  passwordChanged = () => {
    return (
      <>
        <div className="maintitle">RESET PASSWORD</div>
        <div>Your password has been changed</div>
      </>
    );
  }
  
  content = () => {
    const {
      forgotPassword: { errorEmail, sending, successEmail, changeEmail, changePassword, errorPassword, successPassword },
    } = this.props;

    if (errorEmail) {
      return this.cannotResetYourPassword();
    }

    if (successEmail) {
      return this.emailSent();
    }

    if (errorPassword) {
      return this.cannotChangeYourPassword();
    }

    if (successPassword) {
      return this.passwordChanged();
    }

    if (changeEmail) {
      return this.emailForm();
    }

    if (changePassword) {
      return this.changePassword();
    }

    if (sending) {
      return <Loader />;
    }
  }

  render() {
    return (
      <div className="app-content forgot-password">
        <Header />
        <HeaderImage content={this.content()} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    forgotPassword: state.forgotPassword,
  };
}

export default connect(mapStateToProps)(ForgotPassword);
