import { ActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import {
  fetchHelioscopeDesignsListSuccess,
} from 'actions';

export function fetchHelioscopeDesignsRequest(action$) {
  return action$.ofType(ActionTypes.FETCH_HELIOSCOPE_DESIGNS_LIST_REQUEST)
    .switchMap(action => ObservableAjax({
      takeUntil: action$.ofType(ActionTypes.FETCH_HELIOSCOPE_DESIGNS_SUCCESS),
      onSuccess: fetchHelioscopeDesignsListSuccess,
      onErrorMessage: 'Cannot fetch designs',
      socketName: 'helioscope',
      link: hitApi('get', `api/v1/helioscope/${action.payload.projectId}/`),
    }),
    );
}
