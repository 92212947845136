import { calculateEdgeDimensionOnMap } from '__editor/bingMapsRoofsSelector/models/roofDimension';
import { RotatedLabel } from '__common/modules/googleMapsRotatedLabel';
import { state } from '__common/store';
import { isAscender } from '__common/constants/products';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

export default (coords: { lat: number, lng: number }[], roofHasPanels): any[] => {
  const { projectConfiguration : {productId, inputUnit} } = state();
  let roofDimension = [];
  coords.map((coord, index)=>{
    const edgeDimension = calculateEdgeDimensionOnMap(coord, coords[(index+1)%coords.length], isAscender(productId) || isMetricUnit(inputUnit));
    const dimensionText = (isAscender(productId) || isMetricUnit(inputUnit)) ? `${edgeDimension.length} m` : `${edgeDimension.length} ft`;
    const textOutlineClass = roofHasPanels ? 'fill-roof-outline' : 'empty-roof-outline'; 
    roofDimension.push(new RotatedLabel(edgeDimension.midPoint, dimensionText, edgeDimension.rotation ,{ 
      textClassName: `google-maps-popup gm-roof-dimension-label ${textOutlineClass}`,
      parentClassName: 'gm-parent-roof-dimension-label', 
    }))
    
})

  return roofDimension;
};




