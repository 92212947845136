import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';
import { isSFMInfinity } from '__common/constants/products';
import { AttachmentType } from '../../fields/attachmentType';

export default (product) => {
  const { userPreferences: { preferred_span, stagger_attachments, rafter_spacing_inches } } = state();
  let staggered;

  if (stagger_attachments === null) {
    staggered = true;
  } else {
    staggered = stagger_attachments;
  }

  const SfmEnhancedConfig = {
    zipcode: '',
    building_code: 2,
    wind_speed: 115,
    client_name: '',
    snow_load: 5,
    seismic_ss: 0.46,
    elevation: 5499,
    roof_type: 1,
    wind_exposure: 'B',
    stagger_attachments: staggered,
    preferred_span: preferred_span || 72,
    rafter_spacing_inches: rafter_spacing_inches || 24,
    building_type: gable.value,
    topographical_factor_kzt: 1,
    ...(!isSFMInfinity(product)) ? {
      building_height: 30,
      shortest_building_length: 20,
    } : {
      attachment_type: AttachmentType.FLASHKIT
    },
  };
  return SfmEnhancedConfig;
};
