import { getStage } from '../stage/stage';
import { state, dispatch } from '__common/store';
import { CLEAR_ROOF_POINTS } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { SET_TOOLTIP_CONTENT } from '__common/components/tooltipCursor/tooltipCursorActions';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';
import { getAdvanceRoofSelectingModel } from '__common/components/advancedSelecting/advanceSelecting';
import { metersToInches } from '__common/calculations/metersToInches';

let selection;

export const renderAdvanceSelectingOnStage = () => {
  const { advanceRoofSelecting: { roofPoints, lastPoint } } = state();

  const stage = getStage();

  if (stage) {

    if (selection) {
      stage.removeChild(selection);
    }

    if (roofPoints.length && lastPoint) {
      selection = getAdvanceRoofSelectingModel(stage.scale.x);
      showTooltip();
      stage.addChild(selection);
    }
  }
};

export const clearAdvanceSelecting = () => {
  const stage = getStage();

  if (stage) {
    if (selection) {
      stage.removeChild(selection);
      dispatch(CLEAR_ROOF_POINTS());
      dispatch(SET_TOOLTIP_CONTENT(null));
    }
  }
};

const showTooltip = () => {
  const { advanceRoofSelecting: { roofPoints, lastPoint, tempPoint }, background: { metersPerPixel } } = state();
  const lastPointOnStage: any = roofPoints[roofPoints.length - 1];
  let cursorPoint: any = lastPoint;
  if (tempPoint && tempPoint.x && tempPoint.y) cursorPoint = tempPoint;
  const distance = calcDistanceOfTwoPoints(lastPointOnStage, cursorPoint);
  const distanceInMeters = distance * metersPerPixel;
  const distanceInches = metersToInches(distanceInMeters);
  dispatch(SET_TOOLTIP_CONTENT(`${distanceInches.toFixed(2)}"`));
};

