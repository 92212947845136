import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { range } from 'lodash';
import { isAscender } from '__common/constants/products';

export const apiField = 'tilt';

export enum TILT_GFT {
  TILT_20 = 20,
  TILT_30 = 30,
}

interface TiltOption {
  name: string;
  value: number;
}

const makeConfigData = (tilt: number, isGroundProduct: boolean): TiltOption => {
  return {
    ...(tilt === 0 && !isGroundProduct && !isAscender? { name: 'FLUSH_TO_ROOF' }: { name: `${tilt}\u00B0` }),
    value: tilt,
  };
};

const makeConfig = ({ options, shouldConfirm = true, isGroundProduct = false }) => {
  return {
    type: 'Select',
    label: 'tilt',
    apiField,
    ...(shouldConfirm ? 
    { 
      onConfirm: () => {
        makeAllRoofsEmpty();
      },
      shouldConfirm: () => anyPanelsDrawn(),
    } : {}
    ),
    data: options.map((option: number) => makeConfigData(option, isGroundProduct)),
  };
};

const tilt = makeConfig({ options: range(0, 45, 5) });

export default tilt;

export const tiltForAdjustableTilt = makeConfig({ options: range(10, 31, 1) });

export const tiltForGft = makeConfig({ options: [20, 30], isGroundProduct: true });

export const tiltForUla = makeConfig({ options: range(0, 46, 1), isGroundProduct: true });

export const ascenderTiltRange = {
  '1p': {
    min: 10,
    max: 25,
  },
  '2p': {
    min: 10,
    max: 20,
  }
}