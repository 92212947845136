import React from 'react';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash/debounce';
import { FormattedMessage } from 'react-intl';
import TextField, { TextFieldTypes } from 'react-md/lib/TextFields/TextField';
import Tooltipped from 'react-md/lib/Tooltips/Tooltipped';
import ConfirmationTextField from '__common/components/ConfirmationTextField';

type Props = {
  field: numberBlock;
  index: number;
  updateProjectOption: (field: string, value: any) => void;
  value: any;
  type?: TextFieldTypes;
  tooltip?: string;
  validationOnBlur: any;
  error?: string;
  warn?: string,
  step?: number;
  disable?: boolean;
};

type State = {
  val: string | number,
};

export default class NumberField extends React.PureComponent<Props, State> {
  showTooltipAfterWordLength = 20;

  state = {
    val: this.props.type === 'text' ? '' : 0,
  };

  constructor(props: Props) {
    super(props);
    this.onChange = debounce(this.onChange, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.value !== nextProps.value) {
      this.setState({ val: nextProps.value });
    }
  }

  tooltipButton = () => {
    return this.props.field.tooltip ? <a data-for={`projectConfigurationTooltip-${this.props.field.apiField}`} data-tip={this.props.field.tooltip()} data-html={true} className="disabled"><img src={require('assets/media/icons/questionmark.png')} /></a> : null;
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props;
    this.setState({ val: value });
  }

  setVal = (apiField: string, val: string | number) => {
    this.setState({ val });
    this.onChange(val, apiField);
  }

  onChange = (val: string | number, apiField: string) => {
    this.props.updateProjectOption(apiField, val);
  }

  tooltipedTextField = () => {
    const { val } = this.state;
    const { field, type, validationOnBlur } = this.props;
    let tooltipVal = val;

    if (typeof val === 'string' && val.length > this.showTooltipAfterWordLength) {
      tooltipVal = '';
    }
    
    return (
      <Tooltipped label={tooltipVal} position="bottom">
        <div className="field-container">
          <TextField
            id={field.label}
            value={val}
            onChange={this.setVal.bind(null, field.apiField)}
            lineDirection="center"
            type={type || 'number'}
            fullWidth={true}
            min={field.min}
            max={field.max}
            onBlur={validationOnBlur}
            key={`${field.apiField}-inside`}
            disabled={this.props.disable ? this.props.disable : false}
          />
        </div>
      </Tooltipped>
    );
  }

  textField = () => {
    const { field, updateProjectOption, value, type, validationOnBlur } = this.props;
    return (
      <TextField
        id={field.label}
        value={value}
        className="number-field"
        onChange={updateProjectOption.bind(null, field.apiField)}
        lineDirection="center"
        type={type || 'number'}
        step={field.step}
        fullWidth={true}
        min={field.min}
        max={field.max}
        onBlur={validationOnBlur}
        key={`${field.apiField}-inside`}
        disabled={this.props.disable ? this.props.disable : false}
      />
    );
  }

  confirmTextField = () => {
    const { field, value, type, validationOnBlur, updateProjectOption } = this.props;
    return (
          <ConfirmationTextField
            value={value ?? ""}
            onConfirm={(value) => updateProjectOption(field.apiField, value)}
            title={field.title}
            content={field.content}
            shouldConfirm={field.shouldConfirm}
            shouldConfirmCb={field.shouldConfirmCb}
            textFieldProps={{
              id: field.label,
              value: value,
              className: "number-field",
              lineDirection: "center",
              type: type || 'number',
              step: field.step,
              fullWidth: true,
              min: field.min,
              max: field.max,
              onBlur: validationOnBlur,
              key: `${field.apiField}-inside`,
            }}
          />
    );
  }

  renderField = (shouldConfirm: boolean, tooltip) => {
    if (shouldConfirm) {
      return this.confirmTextField();
    }
    else if (tooltip) {
      return this.tooltipedTextField();
    }
    return this.textField();
}

  render() {
    const { field, index, tooltip, error, warn } = this.props;

    return (
      <div className="field" key={`text-${index}`}>
        {field.label && (
          <div className={`input-label ${field.missing || error ? 'input-warning' : ''}`}>
            <FormattedMessage id={field.label} />: {this.tooltipButton()}
          </div>
        )}
        {this.renderField(field.shouldConfirm, tooltip)}
        {error && <div className={'input-label input-warning'}>{error}</div>}
        {field.note && field.note.shouldShow && field.note.message && <div className={'note'} dangerouslySetInnerHTML={{ __html: field.note.message}}></div>}
        {field.tooltip && <ReactTooltip className="project-configuration-tooltip" effect="solid" place="right" type="info" id={`projectConfigurationTooltip-${field.apiField}`} />}
        {warn && <div className={'input-label input-soft-warning'}>{warn}</div>} 
      </div>
    );
  }
}
