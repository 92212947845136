import * as React from 'react';
import { connect } from 'react-redux';
import { BACKGROUND_SET_LOW_EDGE_TO_ROOF } from 'actions';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import Tooltip from '__common/components/Tooltip';
import { isElevatedStructureType } from '__editor/panelsEditor/components/panels/utils/drawAscenderPanelsInTables';

interface props {
  value: number;
  dispatch: Function;
  changeLowEdgeToRoof: (value: Number, roofId: number) => void;
  panels: panelInState[],
  selectedRoofId: number;
  structureType: number;
}

interface state {
  value: string | number;
  isValid: boolean;
}


class LowEdgeToRoof extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isValid: true,
      value: props.value,
    };
  }
  static minSize = 10.5;
  static maxSize = 50.8;


  onValueChange = (value: number) => {
    if(value >= LowEdgeToRoof.minSize && value <= LowEdgeToRoof.maxSize) {
      this.setState({ ...this.state, value: value, isValid: true });
      const val = parseFloat(Number(value).toFixed(1).replace(/\.?0*$/, ''));
      this.props.changeLowEdgeToRoof(val, this.props.selectedRoofId);
    }
    else {
      this.setState({ ...this.state, value: value, isValid: false });
    }
  }


  getRangeHelper() {
    return `(${LowEdgeToRoof.minSize}-${LowEdgeToRoof.maxSize})`;
  }

  renderError() {
    if (this.state.isValid) {
      return null;
    }

    const errorMessage = `Low Edge to Roof must be in range ${LowEdgeToRoof.minSize} - ${LowEdgeToRoof.maxSize}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }
  
  renderWarning() {
    const warningMessage = `Low Edge to Roof applies only to elevated structures`;

    return (
      <div className="input-soft-warning">{warningMessage}</div>
    );
  }

  render() {
    const { value } = this.state;
    const {structureType} = this.props;
    
    return isElevatedStructureType(structureType) ? (
      <div className="desired-table-size">
            <div className="input-label">
              Low Edge To Roof (cm):
              <Tooltip
                id={'low-edge-to-roof'}
                content={`Low Edge To Roof Clearance(cm)`}
                position="right"
              />
          </div>
          <ConfirmationTextField
            value={value ?? ""}
            onConfirm={value => this.onValueChange(value)}
            title="Are you sure you want to change low edge to roof clearance?"
            content="Current layout will be lost"
            shouldConfirm={false}
            textFieldProps={{
              lineDirection: 'center',
              type: 'number',
              step: 0.1,
              min: 10.5,
              max: 50.8,
              fullWidth: false,
            }}
          />
        {this.renderError()}
        {this.renderWarning()}
      </div>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changeLowEdgeToRoof: (value: number, roofId: number) => {
      dispatch(BACKGROUND_SET_LOW_EDGE_TO_ROOF(value));
    }
  };
};

const mapStateToProps = (state: appState) => {
  return {
    value: state.background.lowEdgeToRoof,
    panels: state.panels.panels,
    selectedRoofId: state.background.selectedRoofId,
    structureType: state.projectConfiguration.projectEnvConfig.structure_type,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LowEdgeToRoof);
