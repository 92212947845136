import React from 'react';
import RowsPerPage from '__common/components/RowsPerPage';
import { connect } from 'react-redux';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import {
  SET_USER_PROJECTS_PER_PAGE,
  SET_USER_PROJECTS_SORTING,
  SET_USER_SORT_METHOD,
} from 'actions';

type Props = {
  dispatch: Function;
  userPreferences: userPreferencesState;
};

class OtherUserSettings extends React.Component<Props> {
  ROWS_PER_PAGE = [10, 20, 50, 100];
  SORTINGS = [
    { name: 'ASCENDING', value: 1 },
    { name: 'DESCENDING', value: 2 },
  ];
  PROJECTS_SORTINGS = [
    { name: 'PROJECT NAME', value: 1 },
    { name: 'DATE CREATED', value: 2 },
    { name: 'MFG', value: 3 },
    { name: 'MODEL', value: 4 },
    { name: 'CLIENT NAME', value: 5 },
    { name: 'ZIP', value: 7 },
    { name: 'LIST', value: 8 },
  ];

  setRowsPerPage = (rowsPerPage: number) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_PROJECTS_PER_PAGE(rowsPerPage));
  }

  setProjectsSorting = (projectsSorting: string) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_PROJECTS_SORTING(projectsSorting));
  }

  setSortingMethod = (sortingMethod: string) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_SORT_METHOD(sortingMethod));
  }

  render() {
    const {
      userPreferences: {
        projects_per_page,
        project_sorting,
        sort_method,
      },
    } = this.props;

    return (
      <div className="other-user-settings">
        <RowsPerPage
          label="Projects per page"
          rowsPerPage={projects_per_page}
          rowsPerPageMenu={this.ROWS_PER_PAGE}
          onChange={this.setRowsPerPage}
        />
        <div className="input-label">
          PROJECT SORTING
          <SelectField
            id="project sorting"
            simplifiedMenu={true}
            menuItems={this.PROJECTS_SORTINGS}
            value={project_sorting}
            onChange={this.setProjectsSorting}
            itemLabel="name"
            itemValue="value"
            fullWidth={true}
          />
        </div>
        <div>
          <div className="input-label">
            SORT METHOD
            <SelectField
              id="sort method"
              simplifiedMenu={true}
              menuItems={this.SORTINGS}
              value={sort_method}
              onChange={this.setSortingMethod}
              itemLabel="name"
              itemValue="value"
              fullWidth={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    userPreferences: appState.userPreferences,
  };
}

export default connect(mapStateToProps)(OtherUserSettings);
