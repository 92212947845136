import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';

export default (inputUnit: number) => {
  return {
    type: 'Select',
    label: isMetricUnit(inputUnit) ? 'parapetHeightMetric' : 'parapetHeight',
    apiField: 'parapet_height',
    tooltip: parapetHeightTooltip,
    data: isMetricUnit(inputUnit) ? PARAPET_DATA_METRIC : PARAPET_DATA
  }
};

const PARAPET_DATA_METRIC = [
  {
    value: 1,
    name: '<= 1/2 Array Height (<= 0.10 meters)',
  },
  {
    value: 2,
    name: '<= 1 Array Height (<= 0.20 meters)',
  },
  {
    value: 3,
    name: '> 1 Array Height (> 0.20 meters)',
  },
]

const PARAPET_DATA = [
  {
    value: 1,
    name: '<= 1/2 Array Height (<= 4 inches)',
  },
  {
    value: 2,
    name: '<= 1 Array Height (<= 8 inches)',
  },
  {
    value: 3,
    name: '> 1 Array Height (> 8 inches)',
  },
]
