export const apiField = 'use_friction';

interface useFrictionField {
  type: string;
  apiField: string;
  label: string;
  id?: string;
  aria_label?: string;
  
}

const config:useFrictionField = {
  label: 'useFriction',
  type: 'Checkbox',
  apiField,
  id: "usefriction", 
  aria_label: "Use Friction",
};



export default config;

