import ecoFoot2PlusSlipSheetTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/ecoFoot2PlusSlipSheetTooltip';
export const apiField = 'slip_sheet'
export default {
  type: 'Select',
  label: 'Slip Sheet',
  apiField,
  missing: undefined,
  tooltip: ecoFoot2PlusSlipSheetTooltip,
  data: [
    {
      value: 1,
      name: 'YES',
    },
    {
      value: 2,
      name: 'NO',
    },
  ],
};
