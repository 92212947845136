import keyMirror from 'keymirror';


/**
 * @namespace Constants
 * @desc Dimensions constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const DimensionsActionTypes = keyMirror({
  SET_DIMENSIONS: undefined,
  RESET: undefined,
});
