import React from 'react';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { _isAscenderFamily } from '__editor/panelsEditor/components/cursor/utils/snapToGridHelper';

type Props = {
  ns: number;
  ew: number;
};

export default class ArrayDimensions extends React.Component<Props> {
  render() {
    const { ns, ew } = this.props;
    if(_isAscenderFamily()) {
      return (
        <div className="array-dimensions" style={{ textAlign: 'center' }}>
          <div className="input-label">Dimensions:</div>
          <div className="dimensions">
            <div>NS: {ns.toFixed(2)}m</div>
            <div>EW: {ew.toFixed(2)}m</div>
          </div>
        </div>
      ); 
    }
    return (
      <div className="array-dimensions" style={{ textAlign: 'center' }}>
        <div className="input-label">Dimensions:</div>
        <div className="dimensions">
          <div>NS: {metersToFeets(ns).toFixed(2)}ft</div>
          <div>EW: {metersToFeets(ew).toFixed(2)}ft</div>
        </div>
      </div>
    );
  }
}
