import * as React from 'react';
import SVG from 'react-inlinesvg';
import Modal from 'react-bootstrap/Modal';
import { dispatch, state } from '__common/store';
import { SHOW_IMAGE } from './ReportProblem/ReportProblemActions';
import Button from 'react-md/lib/Buttons/Button';

type Props = {
  imageOpen?: boolean,
  fileName?: string,
};

export default class PreviewImageComponent extends React.Component<Props, {}> { 
 render() {
    const { reportProblem : { formData: { file } } } = state();
    const { imageOpen,fileName } = this.props;
    const selectedFile = file.filter((f)=>f.name === fileName)[0];    
    return (
        <>
          <Modal show={imageOpen} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered dialogClassName="modal-90w">
            <Modal.Header>
              <p>{selectedFile.name}</p>
              <Button className='close-btn' onClick={()=>dispatch(SHOW_IMAGE(false,null))}>
                <SVG src={require('assets/media/icons/icon_cross.svg')} />
              </Button>
            </Modal.Header>
            <Modal.Body className="preview-modal-body">
              <embed src={URL.createObjectURL(selectedFile)} type={selectedFile.type} className="previewed-image"/>
            </Modal.Body>
          </Modal>
        </>
      )
    }
}