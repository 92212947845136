import { ForgotPasswordActionTypes } from 'actionsConstants';

export function RESET_PASSWORD_ON_DEMAND(email: string) {
  return {
    type: ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND,
    payload: {
      email,
    },
  };
}

export function RESET_PASSWORD_ON_DEMAND_SUCCESS() {
  return {
    type: ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND_SUCCESS,
  };
}

export function RESET_PASSWORD_ON_DEMAND_ERROR() {
  return {
    type: ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND_ERROR,
  };
}

export function SET_EMAIL_PAGE() {
  return {
    type: ForgotPasswordActionTypes.SET_EMAIL_PAGE,
  };
}

export function SET_PASSWORD_PAGE() {
  return {
    type: ForgotPasswordActionTypes.SET_PASSWORD_PAGE,
  };
}

export function CHANGE_PASSWORD_ON_DEMAND(newPassword: string, newPasswordRepeated: string, token: string, uid: string) {
  return {
    type: ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND,
    payload: {
      newPassword,
      newPasswordRepeated,
      token,
      uid,
    },
  };
}

export function CHANGE_PASSWORD_ON_DEMAND_SUCCESS() {
  return {
    type: ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND_SUCCESS,
  };
}

export function CHANGE_PASSWORD_ON_DEMAND_ERROR() {
  return {
    type: ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND_ERROR,
  };
}

export function RESET_PASSWORD_CLEAR() {
  return {
    type: ForgotPasswordActionTypes.RESET_PASSWORD_CLEAR,
  };
}
