import nbccRoughnessTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/nbccRoughnessTooltip';
export const apiField = 'nbcc_roughness';

const options = [
  {
    value: '0',
    name: 'Rough',
  },
  {
    value: '1',
    name: 'Open',
  },
]

const config = {
  label: 'nbccRoughness',
  type: 'Select',
  apiField,
  tooltip: nbccRoughnessTooltip,
  missing: undefined,
  data: options,
};


export default config;