import React from 'react';

export default class BomAssumptions extends React.Component {
  render() {
    return (
      <div className="bom-assumptions">
        This design is to be evaluated to the product appropriate Unirac Code Compliant Installation Manual which references international Building Code 2003, 2006, 2009, 2012 and ASCE 7-02, ASCE-5, ASCE 7-10 and California Building Code 2010.
        The installation of products related to this design is subject to requirements in the above mentioned installation manual.
      </div>
    );
  }
}
