import { EDGES_TYPE } from "../edgesType";
import { ROOF_PITCH } from '__editor/panelsEditor/components/tiltedRoof/roofPitch';
import { getEdgeTypeFromCollision } from '__editor/panelsEditor/components/roofZones/utils/collisions';
import { isASCE716or722Selected } from "projectDesign/components/projectConfiguration/projectConfiguration";
import { needNewZoneClassification } from "__common/utils/versionCompare/versionCompare";
import { state } from "__common/store";
import { _isAscenderFamily } from "__editor/panelsEditor/components/cursor/utils/snapToGridHelper";

// groups denote how particular zones are grouped for specific angle ranges.
// for gable roof:
// ------------------------------------------------------------------
// |  angle     |   zones     | note                                |
// ------------------------------------------------------------------
// |            |   3r        | the highest group (marked as red)   |
// |            | - - - - - - | - - - - - - - - - - - - - - - - - - |
// | 7° - 20°   | 2n, 2r, 3e  | middle group (marked as yellow)     |
// |            | - - - - - - | - - - - - - - - - - - - - - - - - - |
// |            |  1, 2e      | the lowest group (marked as blue)   |
// | - - - - - -| - - - - - - | - - - - - - - - - - - - - - - - - - |
// |            |   3r        | the highest group (marked as red)   |
// |            | - - - - - - | - - - - - - - - - - - - - - - - - - |
// | 20° - 27°  | 2n, 2r, 3e  | middle group (marked as yellow)     |
// |            | - - - - - - | - - - - - - - - - - - - - - - - - - |
// |            |  1, 2e      | the lowest group (marked as blue)   |
// | - - - - - -| - - - - - - | - - - - - - - - - - - - - - - - - - |
// |            |   3e        | the highest group (marked as red)   |
// |            | - - - - - - | - - - - - - - - - - - - - - - - - - |
// | 27° - 45°  | 2n, 3r      | middle group (marked as yellow)     |
// |            | - - - - - - | - - - - - - - - - - - - - - - - - - |
// |            |  1, 2e, 2r  | the lowest group (marked as blue)   |
// | - - - - - -| - - - - - - | - - - - - - - - - - - - - - - - - - |
//
// when the panel collides with two zones we should choose higher group
// for example when the panel is in range of both 2n and 2e (but not 3e)
// for angle 20° we should classify it as the middle group (because 2n is higher than 2e)
//
// "canBeClassifiedAsCorner" - is an additional check if the corner can be classified as corner
// i.e. - if the angle is below 135° or if the distance "x" is greater than zone size "a"
//
//                │
//      x < a ↘   │
//  this isn't  ┌-┘
//  a corner    │
//  (not 3r)    │
// 
export default (
  edgesCollisions: string[], 
  cornerCollisions: string[], 
  roofPitch: string,
  canBeClassifiedAsCorner: boolean,
): { zone: roofZoneNumber, edgeType?: EDGES_TYPE }  => {
  const degressAngle = ROOF_PITCH[roofPitch];
  if ((cornerCollisions.length > 0 && canBeClassifiedAsCorner) || (edgesCollisions.length >= 2 && canBeClassifiedAsCorner)) {
    const edgeType = getCornerEdge(edgesCollisions, cornerCollisions, degressAngle);
    const group = getCornerGroup(edgeType, degressAngle);

    return {
      zone: group,
      edgeType,
    };
  }

  if (edgesCollisions.length > 0) {
    const edgeType = getEdgeType(edgesCollisions);
    const group = getEdgeGroup(edgeType, degressAngle);

    return {
      zone: group,
      edgeType,
    };
  }

  return {
    zone: 1,
  };
};


const getCornerGroup = (edgeType: EDGES_TYPE, degressAngle: number): roofZoneNumber => {
  const { projectConfiguration: { projectVersion } } = state();
  let useNewZoneClassification = needNewZoneClassification(projectVersion);
  
  if (_isAscenderFamily()) {
    return 3;
  }
  if (!isASCE716or722Selected() && degressAngle <= 7 && useNewZoneClassification) {
    return edgeType === EDGES_TYPE.EAVE ? 3 : 2;
  }

  if (!isASCE716or722Selected() && useNewZoneClassification ) {
    return 3;
  }

  if (degressAngle <= 27) {
    return edgeType === EDGES_TYPE.EAVE ? 2 : 3;
  }

  return edgeType === EDGES_TYPE.EAVE ? 3 : 2;
};

const getCornerEdge = (edgesCollisions: string[], cornerCollisions: string[], tiltAngle: number): EDGES_TYPE => {
  // whenever there is a conflict - choose edge type that belongs to the higher zone
  const edgeTypes = edgesCollisions.map(getEdgeTypeFromCollision);

  if (tiltAngle <= 27) {
    if (edgeTypes.includes(EDGES_TYPE.RIDGE)) {
      return EDGES_TYPE.RIDGE;
    }
    return EDGES_TYPE.EAVE;
  }

  if (edgeTypes.includes(EDGES_TYPE.EAVE)) {
    return EDGES_TYPE.EAVE;
  }

  return EDGES_TYPE.RIDGE;
};

const getEdgeType = (collisions: string[]): EDGES_TYPE => {
  return Math.max(...collisions.map(getEdgeTypeFromCollision));
};

const getEdgeGroup = (edgeType: EDGES_TYPE, degressAngle: number): roofZoneNumber => {
  const { projectConfiguration: { projectVersion } } = state();
  let useNewZoneClassification = needNewZoneClassification(projectVersion); 
  
  if (_isAscenderFamily()){
    return 2;
  }
  
  if (!isASCE716or722Selected() && degressAngle<=7 && useNewZoneClassification) {
    switch (edgeType) {
      case EDGES_TYPE.EAVE:
        return 2;
      case EDGES_TYPE.RAKEGABLE:
        return 2;
      case EDGES_TYPE.RIDGE:
        return 1;
    }
  }
  if (!isASCE716or722Selected() && useNewZoneClassification) {
    return 2;
  }
  if (degressAngle <= 27) {
    switch (edgeType) {
      case EDGES_TYPE.EAVE:
        return 1;
      case EDGES_TYPE.RAKEGABLE:
        return 2;
      case EDGES_TYPE.RIDGE:
        return 2;
    }
  }

  switch (edgeType) {
    case EDGES_TYPE.EAVE:
      return 1;
    case EDGES_TYPE.RAKEGABLE:
      return 2;
    case EDGES_TYPE.RIDGE:
      return 1;
  }
};
