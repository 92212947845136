import Polygon from 'polygon';
import polygonsIntersect from 'polygons-intersect';
import { exposedDistance, getRoofZonesAreasMemoized as getRoofZonesAreas } from '../roofZones';
import { panelPolygon } from '../../roofEdges/roofEdgesCollisions';

export const checkCollisionWithExpZones = (
  cursor: { x: number, y: number, width: number, height: number },
  relativeToCenter = false,
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  insideOfPolygon: boolean,
  roofPitch: string,
  metersPerPixel: number,
  roofEdgesPixiCords: number,
  mapType: string,
  productId: number,
  tilt: number,
) => {

  const distance = exposedDistance(metersPerPixel);
  const rotatedRoofZone =  getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  const cursorPoly = panelPolygon(cursor, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);
  if (Object.keys(rotatedRoofZone).length) {
    const restrictedAreaPolygons = rotatedRoofZone.edges.map(edge => new Polygon(edge));
    return restrictedAreaPolygons.some(area => {
      const intersect = polygonsIntersect(area.points.filter(edge => edge), cursorPoly.points.filter(edge => edge));
      return intersect.length > 0;
    });
  }
};
