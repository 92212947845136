import React from 'react';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';

export default class TermsOfService extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <div className="project-assumptions">
        <div className="enigneering-product-assumptions">
          <div className="assumptions-main-header">
            TERMS AND CONDITIONS OF USE
          </div>
          <div className="assumptions-sub-header">1. Terms and Conditions</div>

          <div className="text">1.1 Definitions</div>
          <div className="text first-level">
            1.1.1 All references to “Unirac” shall mean and include its
            authorized representative(s).
          </div>
          <div className="text first-level">
            1.1.2. All references to “us”, “our” or “we” shall mean Unirac and
            its authorized representative(s).
          </div>
          <div className="text first-level">
            1.1.3 All references to “you” or “your” shall mean the user(s) of
            the Unirac application.
          </div>
          <div className="text first-level">
            1.1.4 All references to the “Unirac application” shall mean the
            software and/or service provided to the user(s) by Unirac.
          </div>

          <div className="text">1.2 General Conditions</div>
          <div className="text second-level">
            By using the Unirac application, you agree to be bound by these
            Terms and Conditions of Use and any laws and regulations applicable
            to the Unirac application. You agree that you are responsible for
            conforming to all local laws. If you do not agree to any of these
            Terms and Conditions of Use, you are not permitted to use the Unirac
            application. The information held in the Unirac application is
            protected by all pertinent copyright and trade mark law. Unirac
            reserves the right to revise the Terms and Conditions of Use at any
            time of our choosing. Any changes will be effective as soon as they
            are posted on the Unirac application or website. The Terms and
            Conditions of Use remain effective until altered by Unirac and/or
            your use of the application is concluded, whether via your action or
            by action taken by Unirac. You can conclude your use of the
            application at any time by removing the Unirac application from your
            mobile or tablet device and deleting your account. Unirac may
            terminate your use of the Unirac application if, in our opinion, you
            have breached these Terms and Conditions of Use, you have breached
            any other Unirac policy and/or if continuing to provide the Unirac
            application is no longer commercially viable.
          </div>

          <div className="text">1.3 Disclaimer/Limitation of Liability</div>
          <div className="text second-level">
            By using the Unirac application, you agree that Unirac will not be
            held responsible or liable for any loss or damage that may directly
            or indirectly result through using the Unirac application, inclusive
            of but not limited to any special, consequential or indirect
            damages, including without limitation liquidated damages,
            time-related carrying costs, any loss of profit, loss of goodwill or
            reputation, loss of data, intangible losses, and physical and
            tangible loss and/or harm.
          </div>

          <div className="text first-level">1.3.1 Quality of Material</div>
          <div className="text second-level">
            The materials on the Unirac application are provided “as is” with
            all faults. Unirac makes no warranties, expressed or implied, and
            hereby disclaims and negates all other warranties, including without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, non-infringement of intellectual
            property or other violation of rights. Further, Unirac does not
            warrant or make any representations concerning the accuracy, likely
            results, or reliability of the materials on the application or
            otherwise relating to such materials on any sites linked to this
            application. Unirac is not a licensed architectural or engineering
            firm or general contractor. You agree not to rely on the Unirac
            application to perform any service, work or calculation that is
            required to be performed hereby irrevocably waive any claim or loss
            arising from or related to Unirac’s lack of licensure.
          </div>

          <div className="text first-level">1.3.2 Third Party Content</div>
          <div className="text second-level">
            By using the Unirac application you acknowledge that you may be
            exposed to content that you or other people may find offensive,
            indecent or objectionable. In such respect, you agree to use the
            Unirac application at your own risk and discretion. Unirac accepts
            no liability for any harm or loss, including but not limited to;
            physical, psychological or financial loss, caused directly or
            indirectly, from your use of the Unirac application. Unirac takes no
            responsibility and accepts no liability for behavior, content or
            material including, but not limited to, comments/posts, links, spam,
            advertisements or information provided by other users or third
            parties through/onto the Unirac application that is fraudulent,
            inappropriate, lewd, misleading, illegal, offensive or is otherwise
            harmful.
          </div>

          <div className="text first-level">1.3.3 Third Party Links</div>
          <div className="text second-level">
            The Unirac application may contain advertisements and/or links to
            external websites or software. Such websites and/or software are not
            under the control of Unirac and different terms and conditions of
            use and privacy policies may apply. Any links or advertisements to
            third party sites or software made on or through the Unirac
            application do not imply any endorsement of the content or service
            provided by these external websites or software by Unirac. Unirac
            accepts no liability for the content or services supplied via links
            or advertisements to external websites and software and Unirac
            accepts no liability for any loss resultant from the use of external
            websites or software.
          </div>

          <div className="text first-level">
            1.3.4 Content Created, Transmitted and Displayed
          </div>
          <div className="text second-level">
            By using the Unirac application, you agree that you, and you alone,
            are responsible for any content that you create, transmit or display
            in the course of using the Unirac application, and, therefore, you
            bear responsibility (and agree to indemnify, defend and hold Unirac
            harmless) for any consequences resulting from your distribution of
            content that you create, transmit or display. We make no
            representation as to the compatibility of the Unirac application
            with your hardware or software.
          </div>

          <div className="text first-level">1.3.5 Personal Information</div>
          <div className="text second-level">
            By using the Unirac application, you agree to take all necessary
            precautions to protect your own personal details and information and
            the personal details/information of others that you may be privy to,
            including but not limited to; first name(s), middle name(s),
            surname(s), alias(es), degree(s), date of birth, email address(es),
            contact detail(s), contact phone number(s), picture(s), video(s),
            demographic information, current location(s), future location(s),
            address(es), postal code(s), bank account detail(s), credit card
            detail(s), preferences and interests and other information relevant
            to customer surveys and/or offers. By using the service, you accept
            full responsibility and liability for any and all information
            provided by you. Unirac shall not be liable or responsible for any
            loss, injury or harm caused directly or indirectly, by or from any
            materials or information made available by you, intentionally or
            unintentionally, in whatever form, onto or through your use of the
            Unirac application.
          </div>

          <div className="text">1.4 Fees</div>
          <div className="text first-level">
            The Service is provided at no charge to Customer provided that
            Customer agrees that Unirac may (a) add optional services to
            Customer or its End Users for a fee or (b) offer a premium version
            of the Service for a fee.
          </div>

          <div className="text">1.5 Acceptable Use</div>
          <div className="text first-level">
            Unirac requires some personal information via our registration
            module to facilitate your use of the Unirac application. You
            understand and agree that your registration information will be
            accurate when you enter it or update it at any time. You agree to
            use the Unirac application only for the purposes permitted by these
            Terms and Conditions of Use and only as permitted by all applicable
            laws and regulations. You also agree that you will only access the
            Unirac application by the avenues provided by Unirac and not by any
            automated avenues. You shall also comply with directions provided by
            Unirac. You agree that you will not intentionally or unintentionally
            interfere or disrupt the Unirac application including the network
            servers utilized by Unirac in providing the Unirac application. No
            license is granted to reproduce, duplicate, sell, trade or resell
            the application for any purpose. Data rates may apply on your mobile
            device.
          </div>

          <div className="text">1.6 Service Levels</div>
          <div className="text first-level">
            The Unirac application requires a reliable internet connection for
            setup and use and is dependent on the quality of the customer's
            internet connection. The quality of the Unirac application cannot be
            guaranteed in the event of unforeseen circumstances beyond the
            control Unirac. These unforeseen circumstances include, but are not
            limited to, disruptions in service caused by Unirac’s suppliers,
            disruptions of service caused by Unirac’s suppliers’ suppliers,
            blackouts, hardware failure/malfunctions, software
            failure/malfunctions and disruption in services from your
            internet/data service provider. Unirac reserves the right to suspend
            services for the purposes of alteration, maintenance or improvement
            of service. Unirac makes no guarantee that the service will be
            continuously available, uninterrupted, error-free or defined to a
            consistent level at all times. Unirac will not be liable for any
            loss, including but not limited; financial loss, physical loss,
            general loss, physical harm, liability’s incurred to third parties,
            or loss of contracts caused directly or indirectly from a loss of
            service or low quality service levels.
          </div>

          <div className="text">1.7 Emergency Use</div>
          <div className="text first-level">
            Due to the nature of the Unirac application and the affiliated
            services required to run the Unirac application, the Unirac
            application is not suitable for use in emergency situations. If the
            Unirac application is used in the event of an emergency situation,
            Unirac will not be responsible for any outcome arising from the use
            of the Unirac application for this or any other purpose.
          </div>

          <div className="text">1.8 Revisions and Errata</div>
          <div className="text first-level">
            The information contained in the Unirac application may exhibit
            errors including, but not limited, typographical errors, graphical
            errors or technical errors. Unirac does not guarantee that any of
            the information or material contained within the Unirac application
            is accurate, complete or current. Unirac may make changes to the
            information contained in its application at any time without notice.
            Unirac does not undertake to update the information.
          </div>

          <div className="text">1.9 Confidentiality</div>
          <div className="text first-level">
            The Unirac application operates over the Internet and therefore
            security of the application is not guaranteed by Unirac. Unirac will
            endeavor to use security measures appropriate to the application but
            accepts no liability for any loss of confidentiality through the use
            or access of the Unirac application.
          </div>

          <div className="text">1.10 Copyright</div>
          <div className="text first-level">
            All materials, information, data, text, images, graphics, artwork,
            software and source code on, used and exhibited by the Unirac
            application are subject to the copyright of Unirac. All rights are
            reserved. No part of the Unirac application may be reproduced in any
            form or by any means without the prior written consent of Unirac.
            Certain content created by third parties and accessible through the
            application may be protected by certain Intellectual Property rights
            of supplying third parties.
          </div>

          <div>1.11 Governing Law</div>
          <div className="text first-level">
            These Terms and Conditions and any dispute arising from or related
            to these Terms and Conditions are governed by the laws of the State
            of New Mexico. Any dispute arising from or related to these Terms
            and Conditions will be brought exclusively in the state and federal
            courts sitting in Bernalillo County, New Mexico and you hereby
            irrevocably consent to the personal jurisdiction of such courts and
            irrevocably waive any defense to such jurisdiction and venue and any
            argument that such jurisdiction and venue is not a convenient forum.
          </div>

          <div className="assumptions-sub-header">2. Privacy Policy</div>

          <div className="text">
            2.1 Content Created, Transmitted or Displayed by You
          </div>
          <div className="text first-level">
            All content that is created, transmitted or displayed by you in the
            course of using the Unirac application is your responsibility. You
            bear full responsibility and agree to indemnify, defend and hold
            Unirac harmless for any and all consequences resulting from any
            content that you create, transmit or display on the Unirac
            application.
          </div>

          <div className="text">
            2.2 Registration Information and Other Information
          </div>
          <div className="text first-level">
            Unirac requires the user to supply personal information to Unirac
            through its registration module. This information is inclusive but
            not limited to: first name, middle name, surname, degree, date of
            birth, e-mail address, demographic information such as postal code,
            preferences and interests, and other information relevant to
            customer surveys and/or offers. Reasons for requiring this
            information include, but are not limited to; internal record
            keeping, improvement of services and features, periodical dispatch
            of Unirac related e-mails, use of information for market research
            purposes, mitigation of automated spamming and for the regulation
            and control of anti-social and/or illegal behavior. Unirac will make
            every effort to store this information in a secure environment,
            however Unirac accepts no liability for the loss of this data or
            information should it occur. Information identifying individual
            users will not be provided to third parties without prior consent
            from the user. Unirac does, however, reserve the right to provide
            generic user information that does not identify individual users to
            third parties where and when Unirac deems this course of action
            appropriate.
          </div>
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}
