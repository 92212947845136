import { _isRMDT, _isRM5ORRM10, _isGFT, _isULA, _isRM5_RM10_RMGridflex_RMEvo_EF2, _isAscenderFamily } from './snapToGridHelper';
import { state } from '__common/store';
import { IPANEL_CORNER } from '../snapToGrid';
import { is1PStructureType } from 'projectDesign/components/projectConfiguration/fields/types/structureType';

export function moveCursorToCorrectSiteOfPanel(data: IPANEL_CORNER) {
  if (_isRMDT()) {
    return moveRmDTCursorToCorrectSiteOfPanel(data);
  }

  if (_isRM5_RM10_RMGridflex_RMEvo_EF2()) {
    return moveRmCursorToCorrectSiteOfPanel(data);
  }

  if (_isGFT()) {
    return moveGftCursorToCrrectSiteOfPanel(data);
  }

  if(_isULA()) {
    return moveUlaCursorToCorrectSiteOfPanel(data);
  }

  if(_isAscenderFamily()) {
    const { projectConfiguration: {projectEnvConfig: {structure_type}} } = state();
    if (is1PStructureType(structure_type)) {
      return moveRmDTCursorToCorrectSiteOfPanel(data);
    }
    return moveGftCursorToCrrectSiteOfPanel(data);
  }

  return moveSMCursorToCorrectSiteOfPanel(data);
}

function moveSMCursorToCorrectSiteOfPanel(cursorData: IPANEL_CORNER): IPANEL_CORNER {
  const {
    settings: { panelWidth, panelHeight },
  } = state();
  let { x, y } = cursorData;
  const { cursorCorner } = cursorData;

  x += panelWidth / 2;
  y += panelHeight / 2;

  switch (cursorCorner) {
    case 0:
      return {
        ...cursorData,
        x,
        y,
      };
    case 1:
      return {
        ...cursorData,
        x: x - panelWidth / 2,
        y,
      };
    case 2:
      return {
        ...cursorData,
        x: x - panelWidth,
        y,
      };
    case 3:
      return {
        ...cursorData,
        x,
        y: y - panelHeight,
      };
    case 4:
      return {
        ...cursorData,
        x: x - panelWidth / 2,
        y: y - panelHeight,
      };
    case 5:
      return {
        ...cursorData,
        x: x - panelWidth,
        y: y - panelHeight,
      };
    default:
      return cursorData;
  }
}

function moveRmCursorToCorrectSiteOfPanel(cursorData: IPANEL_CORNER): IPANEL_CORNER {
  const {
    settings: { panelWidth, panelHeight },
  } = state();

  const { cursorCorner } = cursorData;
  let { x, y } = cursorData;

  x += panelWidth / 2;
  y += panelHeight / 2;

  switch (cursorCorner) {
    case 0:
      return {
        ...cursorData,
        x,
        y,
      };
    case 1:
      return {
        ...cursorData,
        x: x - panelWidth,
        y,
      };
    case 2:
      return {
        ...cursorData,
        x,
        y: y - panelHeight,
      };
    case 3:
      return {
        ...cursorData,
        x: x - panelWidth,
        y: y - panelHeight,
      };
    default:
      return cursorData;
  }
}

function moveGftCursorToCrrectSiteOfPanel(cursorData: IPANEL_CORNER): IPANEL_CORNER {
  // |------------------------------------------------------> X
  // |  0                                               1  
  // |  ○ - - - - - ○ - - - - - ○ - - - - - ○ - - - - - ○
  // |  |           |           |           |           | 
  // |  |           |           |           |           |
  // |  |           |           |           |           |
  // |  |           |           |           |           |      
  // |  |           |           |           |           |
  // |  | - - ↖ - - | - - - - - | - - - - - | - - - - - |
  // |  |           |           |           |           |
  // |  |           |           |           |           |
  // |  |           |           |           |           |
  // |  |           |           |           |           |      
  // |  |           |           |           |           |
  // |  ○ - - - - - ○ - - - - - ○ - - - - - ○ - - - - - ○
  // |  2                                               3
  // |
  // V Y
  const {
    settings: { panelWidth, panelHeight, columnSpacing },
    panels: { 
      desiredTableLength: tableLength,
    },
    background: { 
      metersPerPixel,
    },
  } = state();

  const columnSpacingPx = columnSpacing / metersPerPixel;
  
  let { x, y } = cursorData;
  const { cursorCorner } = cursorData;

  x += panelWidth / 2;
  y += panelHeight;

  switch (cursorCorner) {
    case 0:
      return {
        ...cursorData,
        x,
        y,
      };
    case 1:
      return {
        ...cursorData,
        x: x - (panelWidth + columnSpacingPx) * (tableLength - 1) - panelWidth,
        y,
      };
    case 2:
      return {
        ...cursorData,
        x,
        y: y - panelHeight * 2,
      };
    case 3:
      return {
        ...cursorData,
        x: x - (panelWidth + columnSpacingPx) * (tableLength - 1) - panelWidth,
        y: y - panelHeight * 2,
      };
    default:
      return cursorData;
  }
}

// cursor position is center position of sprite. We have to compare edges of cursor to panel sticked point
function moveRmDTCursorToCorrectSiteOfPanel(cursorData: IPANEL_CORNER): IPANEL_CORNER {
  const {
    settings: { panelWidth, panelHeight },
  } = state();
  
  let { x, y } = cursorData;
  const { cursorCorner } = cursorData;

  x += panelWidth;
  y += panelHeight / 2;

  switch (cursorCorner) {
    case 0:
      return {
        ...cursorData,
        x,
        y,
      };
    case 1:
      return {
        ...cursorData,
        x: x - panelWidth * 2,
        y,
      };
    case 2:
      return {
        ...cursorData,
        x,
        y: y - panelHeight,
      };
    case 3:
      return {
        ...cursorData,
        x: x - panelWidth * 2,
        y: y - panelHeight,
      };
    default:
      return cursorData;
  }
}


function moveUlaCursorToCorrectSiteOfPanel(cursorData: IPANEL_CORNER): IPANEL_CORNER {
  const {
    settings: { panelWidth, panelHeight, columnSpacing, rowSpacing },
    panels: { 
      desiredTableLength: tableLength,
      desiredTableWidth: tableWidth,
    },
    background: { 
      metersPerPixel,
    },
  } = state();

  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  
  let { x, y } = cursorData;
  const { cursorCorner } = cursorData;

  x += panelWidth / 2;
  y += panelHeight;

  switch (cursorCorner) {
    case 0:
      return {
        ...cursorData,
        x,
        y,
      };
    case 1:
      return {
        ...cursorData,
        x: x - (panelWidth + columnSpacingPx) * (tableLength - 1) - panelWidth,
        y,
      };
    case 2:
      return {
        ...cursorData,
        x,
        y: y - (panelHeight * tableWidth) - (rowSpacingPx * (tableWidth-1)),
      };
    case 3:
      return {
        ...cursorData,
        x: x - (panelWidth + columnSpacingPx) * (tableLength - 1) - panelWidth,
        y: y - (panelHeight * tableWidth) - (rowSpacingPx * (tableWidth-1)),
      };
    default:
      return cursorData;
  }
}
