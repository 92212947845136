
export const apiField = 'ecofoot_surface';


function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'Ecofoot Surface',
    apiField,
    missing: undefined,
    data: options,
  };
}

export default makeConfig([
  {
    value: 1,
    name: 'BARE',
  },
  {
    value: 2,
    name: 'EPDM',
  },
],
);
