import * as React from 'react';
import AppHistory from '__common/modules/history';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';

export default class CombinedProjectsBackButton extends React.Component {

  @autobind
  onClick() {
    AppHistory.push(`/yourprojects`);
  }

  render() {
    return (
      <div role="button" onClick={this.onClick} className="back-button">
      <SVG src={require('assets/media/icons/backward_arrow_icon.svg')} />
        BACK
    </div>);
  }
}
