import { createReducer } from '__common/utils/helpers';
import { RoofsSelectorActionTypes } from 'actionsConstants';
import { state as AppState } from '__common/store';

export interface roofsSelectorState {
  mapType: string;
  mapTypeSelected: boolean;
  image: string;
}


export const roofsSelectorState: roofsSelectorState = {
  mapType: null,
  mapTypeSelected: false,
  image: '',
};

export default {
  roofsSelector: createReducer(roofsSelectorState, {
    [RoofsSelectorActionTypes.SET_DEFAULT_MAP_ROOFS_SELECTOR](state: roofsSelectorState) {
      // TODO - we shouldn't reach to the global state from within a reduce function.
      // This, and other places like this, prevent us from upgrading redux to 4.0.0
      const userMapChoice = AppState().userPreferences.map_choice;
      return { ...state, mapType: userMapChoice && userMapChoice.length > 0 ? userMapChoice : 'google' };
    },
    [RoofsSelectorActionTypes.SET_GOOGLE_MAPS_ROOFS_SELECTOR](state: roofsSelectorState) {
      return { ...state, mapType: 'google' };
    },
    [RoofsSelectorActionTypes.SET_BING_MAPS_ROOFS_SELECTOR](state: roofsSelectorState) {
      return { ...state, mapType: 'bing' };
    },
    [RoofsSelectorActionTypes.SET_BLANK_MAPS_ROOFS_SELECTOR](state: roofsSelectorState) {
      return { ...state, mapType: 'white' };
    },
    [RoofsSelectorActionTypes.SET_IMAGE_ROOFS_SELECTOR](state: roofsSelectorState, action) {
      return { ...state, mapType: 'image', image: action.payload.image };
    },
    [RoofsSelectorActionTypes.SET_MAP_ROOFS_SELECTOR](state: roofsSelectorState, action) {
      const { mapType } = action.payload;
      return { ...state, mapType };
    },
    [RoofsSelectorActionTypes.SET_MAP_STATE](state: roofsSelectorComponentState, action) {
      return {
        ...state,
        mapTypeSelected: action.payload.mapTypeSelected,
      };
    },
    [RoofsSelectorActionTypes.CLEAR_ROOFS_SELECTOR]() {
      const userMapChoice = AppState().userPreferences.map_choice;
      const mapType = userMapChoice && userMapChoice.length > 0 ? userMapChoice : 'google';
      
      return {
        mapType,
        mapTypeSelected: false,
        image: '',
      };
    },
  }),
};
