import React from 'react';
import SVG from 'react-inlinesvg';
import { Button } from 'react-md';

interface Props {
  rotation?: number;
}

export default class Compass extends React.Component<Props> {
  render() {
    const { rotation } = this.props;


    const rotationDegress = rotation ? rotation : 0;

    return (<Button tooltipLabel="Compass" style={{ transform: `rotate(${rotationDegress}deg)` }} tooltipPosition="top" flat={true} className="roofs-selector-button compass"><SVG src={require('assets/media/icons/icon_compass.svg')} /></Button>);
  }
}
  

