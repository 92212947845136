import { getRMProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/allRMs';
import { getSFMProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/SFM';
import { getMetalXProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/MX';
import { getSFProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/SF';
import { getSMProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/SM';
import { getSMTiltProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/SMTilt';
import { getAscenderProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/ASCENDER';
import { getGFTProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/GFT';
import { getULAProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/ULA';
import { getEcoFoot2PlusProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/projectConfigurationData/ecoFoot2Plus';
import { products } from '__common/constants/products';
import { getNxtHorizonProjectConfigurationData } from './projectConfigurationData/NXTHORIZON';
import { getSMTiltPRProjectConfigurationData } from './projectConfigurationData/SMTiltPR';


export function getProjectConfigurationData(projectEnvConfig: any) {
  const id = (projectEnvConfig.product === 9 || projectEnvConfig.product === 34) && !isNaN(projectEnvConfig.tilt) && projectEnvConfig.tilt !== null ? 99 : projectEnvConfig.product;

  switch (id) {
    case products.solarmount_2:
    case products.solarmount_hd:
    case products.solarmount_lt:
    case products.solarmount_ascender_flush:
      return getSMProjectConfigurationData(projectEnvConfig);
    case products.sm_tilt_pr:
    case products.sm_tilt_pr_lt:
      return getSMTiltPRProjectConfigurationData(projectEnvConfig)
    case products.solarmount_2_tilt:
    case products.nxt_tilt:
    // case products.adjustable_tilt_system:
      return getSMTiltProjectConfigurationData(projectEnvConfig);
    case products.sunframe:
      return getSFProjectConfigurationData(projectEnvConfig);
    case products.sfmEnhanced:
    case products.sfmInfinity:
      return getSFMProjectConfigurationData(projectEnvConfig);
    case products.metal_x:
      return getMetalXProjectConfigurationData(projectEnvConfig);
    case products.rm_5:
    case products.rm:
    case products.rm_dt:
    case products.rm_10_ultra:
    case products.rm_gridflex:
    case products.rm_10_evolution:
    case products.rm_gridflex_10:
      return getRMProjectConfigurationData(projectEnvConfig);
    case products.gft:
      return getGFTProjectConfigurationData(projectEnvConfig);
    case products.ula:
      return getULAProjectConfigurationData(projectEnvConfig);
    case products.ecofoot2_plus:
      return getEcoFoot2PlusProjectConfigurationData(projectEnvConfig);
    case products.ascender:
      return getAscenderProjectConfigurationData(projectEnvConfig)
    case products.nxt_umount:
    case products.nxt_horizon_lt:
    case products.nxt_horizon_hd:
      return getNxtHorizonProjectConfigurationData(projectEnvConfig);
    default:
      return getSMProjectConfigurationData(projectEnvConfig);
  }
}
