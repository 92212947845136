import roundingHalfBlockTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/roundingHalfBlockTooltip';
export const apiField = 'is_half_block_allowed'
export const options = {
  yes: {
    value: 'true',
    name: 'YES',
  },
  no: {
    value: 'false',
    name: 'NO',
  },
};
const config = {
  type: 'Select',
  apiField,
  label: 'roundingHalfBlock',
  tooltip: roundingHalfBlockTooltip,
  data: [
    options.yes,
    options.no,
  ],
  note:{
    message: `<div class='input-label input-soft-warning'> Allowing half blocks could improve design and make the system lighter </div>`,
    shouldShow: true,
  }
};

export default config;
