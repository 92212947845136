import keyMirror from 'keymirror';

export const BackgroundActionTypes = keyMirror({
  BACKGROUND_SET_ROTATION: undefined,
  PANELS_SET_ROTATION: undefined,
  BACKGROUND_CHANGE: undefined,
  RESIZE_BACKGROUND: undefined,
  BACKGROUND_ZOOM_CHANGE: undefined,
  TURN_ON_BLURED_BACKGROUND: undefined,
  TURN_ON_BLURED_BACKGROUND_ROTATION: undefined,
  SET_METERS_PER_PIXEL: undefined,
  ACTIVE_PANELS_EDITOR: undefined,
  DEACTIVE_PANELS_EDITOR: undefined,
  SET_BACKGROUND_XY_POS: undefined,
  ROOF_PITCH_CHANGE: undefined,
  SAVE_ROOF_EDGES_POINTS: undefined,
  UPDATE_ROOF_EDGES: undefined,
  CLEAR_BACKGROUND_REDUCER: undefined,
  BG_LOADED: undefined,
  BG_CLEAR_LOADED: undefined,
  MOVE_MAP_MODE_ENABLE: undefined,
  MOVE_MAP_MODE_DISABLE: undefined,
  BLANK_MAP_BUILDING_LENGTH_WIDTH: undefined,
  TOGGLE_ATTACHMENTS_ENABLE: undefined,
  TOGGLE_ATTACHMENTS_DISABLE: undefined,
  TOGGLE_ROOF_DIMENSIONS: undefined,
  MOVE_ARRAY_MODE_ENABLE: undefined,
  MOVE_ARRAY_MODE_DISABLE: undefined,
  BACKGROUND_SET_LOW_EDGE_TO_ROOF: undefined,
  ENABLE_BAYS_CONTAINER: undefined,
  DISABLE_BAYS_CONTAINER: undefined,
});
