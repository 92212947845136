import keyMirror from 'keymirror';

export const ForgotPasswordActionTypes = keyMirror({
  RESET_PASSWORD_ON_DEMAND: undefined,
  RESET_PASSWORD_ON_DEMAND_SUCCESS: undefined,
  RESET_PASSWORD_ON_DEMAND_ERROR: undefined,
  CHANGE_PASSWORD_ON_DEMAND: undefined,
  CHANGE_PASSWORD_ON_DEMAND_SUCCESS: undefined,
  CHANGE_PASSWORD_ON_DEMAND_ERROR: undefined,
  SET_EMAIL_PAGE: undefined,
  SET_PASSWORD_PAGE: undefined,
  RESET_PASSWORD_CLEAR: undefined,
});
