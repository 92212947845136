export default () => `
  <div>
    <div class="content">
      <strong>Shingle:</strong> Pitched 'Comp or 'Asphalt shingle.
    </div>
    <div class="content">
      <strong>Standing Seam Metal:</strong> Roof panels joined by vertical rib seams (excluding Corrugated and Trapezoidal metal roofing).
    </div>
    <div class="content">
      <strong>Tile:</strong> S-shaped, ceramic tile roofing.
    </div>
    <div class="content">
      <strong>Other:</strong> Pitched roof types falling outside of the above categories. 
    </div>
  </div>
`;
