export const apiField = 'module_type';

export const options = {
  mechanicalAttachment: {
    value: 1,
    name: 'I',
  }, 
  heatWelded: {
    value: 2,
    name: 'II',
  }
};

const config = {
  type: 'Select',
  apiField,
  label: 'moduleType',
  data: [
    options.mechanicalAttachment,
    options.heatWelded,
  ],
};

export default config;
