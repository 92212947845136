import SeismicTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/seismicTooltip';
import { SeismicSsField, apiField } from 'projectDesign/components/projectConfiguration/fields/types/seismicSs';

const config: SeismicSsField = {
  type: 'Number',
  label: 'seismicSs',
  apiField,
  tooltip: SeismicTooltip,
  missing: undefined,
  min: 0,
};

export default config;
