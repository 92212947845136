import * as React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { MOVE_ARRAY_MODE_DISABLE, TOGGLE_ATTACHMENTS_DISABLE } from '../../background/backgroundActions';
import { DISABLE_RUBBER_BAND } from '../../rubberBand/rubberBandActions';
import { TURN_OFF_MEASUREMENT_TOOL, TURN_ON_ROOF_SELECTING } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { MOVE_MAP_MODE_DISABLE } from '../../background/backgroundActions';
import { isASCE716BuildingCode } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { isGroundProduct, isResidentialProduct } from '__common/constants/products';
import { RESTORE_PANELS_FOR_NO_MOVEMENT } from '../../panels/panelsActions';

interface Props {
  disableRubberBand: () => void;
  enableObstructionDrawing: () => void;
  enableSelection: () => void;
  disableMoveMap: () => void;
  disableMoveArray: () => void;
  disableToggleAttachment: () => void;
  disableMeasurementTool: () => void;
  obstructionDrawingInited: boolean;
  moveArrayEnabled: boolean,
  productId: number,
  buildingCode: number,
}

class DrawObstructions extends React.Component<Props> {
  action = () => {
    this.props.disableRubberBand();
    this.props.enableSelection();
    this.props.disableMoveMap();
    if(this.props.moveArrayEnabled) {
      this.props.disableMoveArray();
    }
    this.props.disableToggleAttachment();
    this.props.disableMeasurementTool();
  }

  render() {
    const { obstructionDrawingInited, productId, buildingCode} = this.props;

    if (isResidentialProduct(productId) || (!isASCE716BuildingCode(buildingCode) && isGroundProduct(productId))) {
      return null;
    }

    return (
      <Button flat={true} onClick={this.action} className={obstructionDrawingInited ? 'roofs-selector-control-panel-active roofs-selector-button' : 'roofs-selector-button'} tooltipLabel="Draw obstructions">
        <SVG src={require('assets/media/icons/icon_draw.svg')} />
      </Button>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    disableRubberBand: () => dispatch(DISABLE_RUBBER_BAND()),
    enableSelection: () => dispatch(TURN_ON_ROOF_SELECTING()),
    disableMoveMap: () => dispatch(MOVE_MAP_MODE_DISABLE()),
    disableMoveArray: () =>{
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
    },
    disableToggleAttachment: () => dispatch(TOGGLE_ATTACHMENTS_DISABLE()),
    disableMeasurementTool: () => dispatch(TURN_OFF_MEASUREMENT_TOOL()),
  };
};

const mapStateToProps = (appState: appState) => {
  return {
    obstructionDrawingInited: appState.advanceRoofSelecting.enabled,
    moveArrayEnabled: appState.background.moveArrayMode,
    buildingCode: appState.projectConfiguration.projectEnvConfig.building_code,
    productId: appState.projectConfiguration.productId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawObstructions);

