import { ChangePasswordActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';

export let changePasswordState: changePasswordState = {
  sending: false,
  success: false,
  error: false,
};

export default {
  changePassword: createReducer(changePasswordState, {
    [ChangePasswordActionTypes.CHANGE_PASSWORD](state) {
      return { ...state, sending: true };
    },
    [ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS](state) {
      return { ...state, sending: false, success: true };
    },
    [ChangePasswordActionTypes.CHANGE_PASSWORD_ERROR](state) {
      return { ...state, sending: false, error: true };
    },
    [ChangePasswordActionTypes.CHANGE_PASSWORD_CLEAR](state) {
      return { sending: false, success: false, error: false };
    },
  }),
};
