export default () => `
<div>
  <div class="content">
      Building type determines roof zones and wind pressures.
  </div>
  <div class="sub-header"><strong>Flat:</strong> Roof slope &le; 7&#176;</div>
  <div class="sub-header"><strong>Gable:</strong> Roof slope > 7&#176; and &le; 45&#176;</div>
  <div class="sub-header"><strong>Hip:</strong> Roof slope > 7&#176; and &le; 45&#176;</div>
  <div class="content">
      <strong>Tip:</strong> For roof planes with both Gable and Hip edge types, select Gable building type.
  </div>
</div>
`;
