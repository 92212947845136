import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';

export const apiField = 'parapet_height';

export default (inputUnit: number) => {
  return {
    type: 'Select',
    label: isMetricUnit(inputUnit) ? 'parapetHeightMetric' : 'parapetHeight',
    apiField,
    tooltip: parapetHeightTooltip,
    data: isMetricUnit(inputUnit) ? PARAPET_DATA_METRIC : PARAPET_DATA
  }
};


const PARAPET_DATA_METRIC = [
  {
    value: 1,
    name: '<= 1/2 Array Height (<=0.15 meters)',
  },
  {
    value: 2,
    name: '<= 1 Array Height (<= 0.30 meters)',
  },
  {
    value: 3,
    name: '> 1 Array Height (>0.30 meters)',
  },
]

const PARAPET_DATA = [
  {
    value: 1,
    name: '<= 1/2 Array Height (<=6 inches)',
  },
  {
    value: 2,
    name: '<= 1 Array Height (<= 12 inches)',
  },
  {
    value: 3,
    name: '> 1 Array Height (>12 inches)',
  },
]

