import { restorePartQty } from 'bom/components/utils/loadBomSelectionsQtys';
import { state } from '__common/store';
import { STANDARD_CLAMPS, END_CLAMP, MID_CLAMP, NXT_CLAMP_TYPE, NXT_HIDDEN_END_CLAMP_TYPE } from '__common/constants/parts';
import { isASCE716or722 } from '__common/constants/buildingCodes';
import { isSMFamily, isSMTiltPR, isNxtHorizon, isNxtTilt } from '__common/constants/products';

export const isStandardClampsAccessorySelected = (state: appState) => {
  const { bom: { accessoriesList },projectConfiguration:{ productId, projectEnvConfig: { building_code, pro_clamps, clamps_choices } } } = state;
  if(isSMFamily(productId) || isSMTiltPR(productId)) {
    return clamps_choices;
  }
  if(isASCE716or722(building_code)) {
    return !pro_clamps;
  }
  const standardClamps = accessoriesList.find((accessory) => accessory.type === STANDARD_CLAMPS.type);
  return standardClamps ? standardClamps.selected : false;
};

export const addClamps = (partsList: bomPart[], midclamps: bomPart[], endclamps: bomPart[], productId: number) => {

  const midClampType = isNxtHorizon(productId) ? NXT_CLAMP_TYPE.type : MID_CLAMP.type
  const endClampType = (isNxtHorizon(productId) || isNxtTilt(productId)) ? NXT_HIDDEN_END_CLAMP_TYPE.type : END_CLAMP.type

  const currentMidClamp = partsList.find((part) => part.type === midClampType);
  const currentEndClamp = partsList.find((part) => part.type === endClampType);

  let newMidClamp: bomPart;
  let newEndClamp: bomPart;

  if (currentMidClamp){
    newMidClamp = getCorrectClampVariants(midclamps, currentMidClamp);
    newMidClamp.quantity = currentMidClamp.quantity;
    newMidClamp.suggested_quantity = currentMidClamp.suggested_quantity;
    newMidClamp = assingQtyToVariants(newMidClamp);
  }
  if (currentEndClamp){
    newEndClamp = getCorrectClampVariants(endclamps, currentEndClamp);
    newEndClamp.quantity = currentEndClamp.quantity;
    newEndClamp.suggested_quantity = currentEndClamp.suggested_quantity;
    newEndClamp = assingQtyToVariants(newEndClamp);
  }
  return partsList.map((part) => {
    if (part.type === MID_CLAMP.type) {
      restorePartQty(state().bom, newMidClamp);
      return newMidClamp;
    }

    if (part.type === END_CLAMP.type) {
      restorePartQty(state().bom, newEndClamp);
      return newEndClamp;
    }

    return part;
  });
};

export const addNxtClamps = (partsList: bomPart[], clamps: bomPart[]) => {

  const currentClamp = partsList.find((part) => part.type === NXT_CLAMP_TYPE.type);

  let newClamp: bomPart;

  if (currentClamp){
    newClamp = getCorrectClampVariants(clamps, currentClamp);
    newClamp.quantity = currentClamp.quantity;
    newClamp.suggested_quantity = currentClamp.suggested_quantity;
    newClamp = assingQtyToVariants(newClamp);
  }
  return partsList.map((part) => {
    if (part.type === NXT_CLAMP_TYPE.type) {
      restorePartQty(state().bom, newClamp);
      return newClamp;
    }
    return part;
  });
};

const assingQtyToVariants = (clamp: bomPart) => {
  const clampVariantsWithQty = clamp.variants.map(variant => {
    variant.quantity = clamp.quantity;
    variant.suggested_quantity = clamp.suggested_quantity;
    return variant;
  });

  clamp.variants = clampVariantsWithQty;

  return clamp;
};

const getCorrectClampVariants = (clamps: bomPart[], currentClamp: bomPart) => {
  return clamps.length === 1 ? clamps[0] : clamps.find(clamp => clamp.color === currentClamp.color || (clamp.color === 'CLR' && currentClamp.color === null));
};

