import keyMirror from 'keymirror';

export const ObstructionsActionTypes = keyMirror({
  ADD_OBSTRUCTION: undefined,
  ADD_OBSTRUCTIONS: undefined,
  REMOVE_OBSTRUCTION: undefined,
  REMOVE_ROOF_OBSTRUCTIONS: undefined,
  CHANGE_OBSTRUCTION_NAME: undefined,
  CHANGE_OBSTRUCTION_HEIGHT: undefined,
  SET_EDITING_OBSTRUCTION_ID: undefined,
  CLEAR_OBSTRUCTIONS: undefined,
  CENTER_OBSTRUCTIONS_ON_ROOF: undefined,
});
