import autobind from 'autobind-decorator';
import debounce from 'lodash/debounce';
import Icon from '__common/components/Icon';
import React from 'react';
import RowsPerPage from '__common/components/RowsPerPage';
import { CHECK_UB1_PROJECTS, SET_MODULE, SET_PROJECT_CREATED_LOWER_RANGE, SET_PROJECT_CREATED_UPPER_RANGE, SET_PROJECT_PRICE_LOWER_RANGE, SET_PROJECT_PRICE_UPPER_RANGE, SET_PROJECT_STATE, SET_PROJECT_WATTAGE_LOWER_RANGE, SET_PROJECT_WATTAGE_UPPER_RANGE } from 'yourProjects/yourProjectsActions';
import { connect } from 'react-redux';
import TextField from 'react-md/lib/TextFields/TextField';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import {
  FILTER_BY_STRING,
  GET_USER_PROJECTS,
  SET_ROWS_PER_PAGE,
  SET_YOUR_PROJECTS_PAGINATION_START,
  SET_YOUR_PROJECTS_TABLE_PAGE,
  SHOW_OLD_PROJECTS,
  SHOW_NEW_PROJECTS,
  INIT_USER_PROJECTS,
  SET_PROJECT_PRODUCT,
  CLEAR_YOUR_PROJECTS_FILTER_DATA,
  SET_FILTER_DATA,
  APPLY_FILTERS,
} from 'actions';
import InputRangeField from 'projectDesign/components/projectConfiguration/components/components/InputRangeField';
import DateRangeField from 'projectDesign/components/projectConfiguration/components/components/DateRangeField';
import { dispatch } from '__common/store';
import { Button } from 'react-md/lib/Buttons';
import MultiSelect from 'projectDesign/components/projectConfiguration/components/components/MultiSelect';
import { Dropdown } from 'react-bootstrap';
import SaveChangesButton from '__common/components/Drawer/components/SaveChangesButton';
import _ from 'lodash';
import { getProductTitle } from '__common/constants/products_titles';
import { accessCheckForProduct, getAllowedProductsInfo } from 'app/components/HeaderComponent/utils/getProducts';

type yourProjectsTableFilterProps = {
  dispatch: Function;
  yourProjects: yourProjectsState;
  userPreferences: userPreferencesState;
  mfgs: mfg[];
  user: userState;
};

function getDefaultValues() {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 6);
  const filterSet = {
    project_created_range: { to: new Date(), from: startDate },
    product: [],
    project_wattage_range: { to: 100, from: 0 },
    project_price_range: { to: 5000, from: 50 },
    project_state: [],
    module: [],
  };
  return filterSet;
}

class YourProjectsTableFilter extends React.Component<
  yourProjectsTableFilterProps
> {
  ROWS_PER_PAGE = [10, 20, 50, 100, 200];

  constructor(props: yourProjectsTableFilterProps) {
    super(props);
    this.updateFilter = debounce(this.updateFilter, 250);
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(CHECK_UB1_PROJECTS());
    dispatch(SET_FILTER_DATA(getDefaultValues()));
  }

  @autobind
  clearFilters() {
    const {
      dispatch,
      yourProjects: { rowsPerPage, sortedField, sortMethod, showOldProjects, filterString },
    } = this.props;
    dispatch(APPLY_FILTERS({}));
    dispatch(SET_FILTER_DATA(getDefaultValues()));
    dispatch(SET_YOUR_PROJECTS_PAGINATION_START(0));
    dispatch(
      GET_USER_PROJECTS(rowsPerPage, 0, sortedField, sortMethod, filterString, showOldProjects, {}),
    );
    dispatch(SET_YOUR_PROJECTS_TABLE_PAGE(1));
  }

  @autobind
  getUserProjects() {
    const {
      dispatch,
      yourProjects: { rowsPerPage, sortedField, sortMethod, showOldProjects, filterString, filterSet },
    } = this.props;
    const applyFilters = _.cloneDeep(filterSet);
    dispatch(APPLY_FILTERS(applyFilters));
    dispatch(SET_YOUR_PROJECTS_PAGINATION_START(0));
    dispatch(
      GET_USER_PROJECTS(rowsPerPage, 0, sortedField, sortMethod, filterString, showOldProjects, filterSet),
    );
    dispatch(SET_YOUR_PROJECTS_TABLE_PAGE(1));
  }

  @autobind
  updateFilter(filter: string) {
    const {
      dispatch,
      yourProjects: { rowsPerPage, sortedField, sortMethod, showOldProjects, appliedFilters },
    } = this.props;
    dispatch(FILTER_BY_STRING(filter));
    dispatch(SET_YOUR_PROJECTS_PAGINATION_START(0));
    dispatch(
      GET_USER_PROJECTS(rowsPerPage, 0, sortedField, sortMethod, filter, showOldProjects, appliedFilters),
    );
    dispatch(SET_YOUR_PROJECTS_TABLE_PAGE(1));
  }

  @autobind
  changeRowPerPage(rowsPerPage: number) {
    const {
      dispatch,
      yourProjects: { sortedField, sortMethod, filterString, showOldProjects, appliedFilters },
    } = this.props;
    dispatch(SET_ROWS_PER_PAGE(rowsPerPage));
    dispatch(
      GET_USER_PROJECTS(rowsPerPage, 0, sortedField, sortMethod, filterString, showOldProjects, appliedFilters),
    );
    dispatch(SET_YOUR_PROJECTS_TABLE_PAGE(1));
  }

  @autobind
  onToggle() {
    const {
      dispatch,
      yourProjects: { appliedFilters }
    } = this.props;
    const filterset = _.cloneDeep(appliedFilters);
    if (filterset && Object.keys(filterset).length) {
      dispatch(SET_FILTER_DATA(filterset));
    }
  }

  showOldProjects = (show: boolean) => {
    const { dispatch, userPreferences: { projects_per_page } } = this.props;

    if (show) {
      dispatch(SHOW_OLD_PROJECTS());
    } else {
      dispatch(SHOW_NEW_PROJECTS());
    }

    dispatch(INIT_USER_PROJECTS(projects_per_page, show));
  }

  renderShowOldProjects = () => {
    const { yourProjects: { gettingOldUbProject, hasOldUb } } = this.props;

    if (hasOldUb && gettingOldUbProject === false) {
      return (
        <span className="show-old-projects">
          <div className="label">SHOW OLD PROJECTS</div>
          <SelectionControl
            id="old-ub"
            type="switch"
            name="lights"
            aria-label="show_old_projects_switch"
            onChange={this.showOldProjects}
          />
        </span>
      );
    }

    if (hasOldUb === false && gettingOldUbProject) {
      return (
        <span className="show-old-projects">
          <div className="label">Checking if ubuilder 1 projects exist...</div>
        </span>
      );
    }

    if (hasOldUb === false && gettingOldUbProject === false) {
      return (
        <span className="show-old-projects">
          <div className="label">You have no project in ubuilder 1</div>
        </span>
      );
    }
  }

  clearFilterButton() {
    const {
      yourProjects: { appliedFilters },
    } = this.props;
    if (appliedFilters && Object.keys(appliedFilters).length) {
      return (
        <Button className='clearFilters' raised={true} onClick={this.clearFilters}>
          Clear Filters
        </Button>
      );
    }
  }

  renderFilters() {
    const {
      yourProjects: { filterSet },
      mfgs,
      user,
    } = this.props;
    let mfgOptions = mfgs.map(({ id, name }) => ({ value: id, label: name }));
    mfgOptions = mfgOptions.filter(mfg => mfg.label !== 'All');
    const productOptions = getAllowedProductsInfo(user).map(product => ({ label: product.productLabel, value: product.productId }));
    const states = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY',
      'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NA', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA',
      'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];
    const stateOptions = states.map(state => ({ label: state, value: state }));

    return (
      <div className='filterBy'>
        <Dropdown onToggle={this.onToggle}>
          <Dropdown.Toggle className='dropdownToggle'>
            Additional Filters
          </Dropdown.Toggle>
          <Dropdown.Menu >
            <div className="filter-input">
              <div className="label">Project Created Range:</div>
              <DateRangeField
                from={filterSet?.project_created_range?.from}
                to={filterSet?.project_created_range?.to}
                onFromChange={(v) => dispatch(SET_PROJECT_CREATED_LOWER_RANGE(v))}
                onToChange={(v) => dispatch(SET_PROJECT_CREATED_UPPER_RANGE(v))}
              />
            </div>
            <Dropdown.Divider />

            <div className="filter-input">
              <div className="label">Project Wattage Range:</div>
              <InputRangeField
                from={filterSet?.project_wattage_range?.from}
                to={filterSet?.project_wattage_range?.to}
                onFromChange={(v) => dispatch(SET_PROJECT_WATTAGE_LOWER_RANGE(v))}
                onToChange={(v) => dispatch(SET_PROJECT_WATTAGE_UPPER_RANGE(v))}
                suffix="kW"
              />
            </div>
            <Dropdown.Divider />

            <div className="filter-input">
              <div className="label">Select Project State:</div>
              <MultiSelect
                options={stateOptions}
                valArray={filterSet?.project_state}
                onChange={(value) => dispatch(SET_PROJECT_STATE(value))}
              />
            </div>
            <Dropdown.Divider />

            <div className="filter-input">
              <div className="label">Select Product:</div>
              <MultiSelect
                options={productOptions}
                valArray={filterSet?.product}
                onChange={(value) => dispatch(SET_PROJECT_PRODUCT(value))}
              />
            </div>
            <Dropdown.Divider />

            <div className="filter-input">
              <div className="label">Select Module Manufacturer:</div>
              <MultiSelect
                options={mfgOptions}
                valArray={filterSet?.module}
                onChange={(value) => dispatch(SET_MODULE(value))}
              />
            </div>
            <Dropdown.Divider />

            <div className="filter-input">
              <span className="label">BOM Price Range:</span>
              <InputRangeField
                from={filterSet?.project_price_range?.from}
                to={filterSet?.project_price_range?.to}
                onFromChange={(v) => dispatch(SET_PROJECT_PRICE_LOWER_RANGE(v))}
                onToChange={(v) => dispatch(SET_PROJECT_PRICE_UPPER_RANGE(v))}
                prefix="$"
              />
            </div>
            <div className="buttons">
              <div className="filter-input-fields">
                <Button className='filteBy-buttons resetValues' flat={true} onClick={() => { dispatch(SET_FILTER_DATA(getDefaultValues())); }}>
                  <span className="material-icons">cached</span>
                  Reset Values
                </Button>
                <Button className='filteBy-buttons clearValues' flat={true} onClick={() => { dispatch(CLEAR_YOUR_PROJECTS_FILTER_DATA()); }}>
                  <span className="material-icons">close</span>
                  Clear Values
                </Button>
              </div>
              <SaveChangesButton
                onClick={this.getUserProjects}
                label="APPLY FILTERS"
              />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  render() {
    const { yourProjects: { rowsPerPage } } = this.props;
    return (
      <div className="your-projects-table-filter">
        <div className='filter-input-fields'>
          <div className="filter-input filterString">
            <div className="label">FILTER:</div>
            <TextField
              id="filter-input"
              type="text"
              onChange={this.updateFilter}
              leftIcon={<Icon>search</Icon>}
              fullWidth={false}
              error={false}
              placeholder={'enter search text'}
            />
          </div>
          {this.renderShowOldProjects()}
          {this.renderFilters()}
          {this.clearFilterButton()}
        </div>
        <div className='filter-input-fields'>
          <div className="per-page-dropdown">
            <div className="label">SHOW:</div>
            <RowsPerPage
              rowsPerPage={rowsPerPage}
              rowsPerPageMenu={this.ROWS_PER_PAGE}
              onChange={this.changeRowPerPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    yourProjects: appState.yourProjects,
    userPreferences: appState.userPreferences,
    mfgs: appState.moduleSelector.mfgs,
    user: appState.user,
  };
}

export default connect(mapStateToProps)(YourProjectsTableFilter);
