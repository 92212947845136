import { zoneClassification } from '__editor/panelsEditor/components/roofZones/utils/commercial/zoneClassification';

export const ASCE_716_ROOF_ZONES = {
  NORTG_LEADING: <roofZoneNumber>1,
  MIDDLE: <roofZoneNumber>2,
  SOUTH_CORNER: <roofZoneNumber>4,
  NORTH_CORNER: <roofZoneNumber>5,
  EAST_WEST_EDGES: <roofZoneNumber>6,
  SOUTH_LEADING: <roofZoneNumber>7,
};

export const getRoofZoneNumberFromClassification = (classification: zoneClassification): roofZoneNumber => {
  switch (classification) {
    case zoneClassification.northCorner:
    case zoneClassification.northSouthCornerOverlap:
      return ASCE_716_ROOF_ZONES.NORTH_CORNER;
    case zoneClassification.northEdge:
    case zoneClassification.northSouthEdgeOverlap:
      return ASCE_716_ROOF_ZONES.NORTG_LEADING;
    case zoneClassification.southCorner:
      return ASCE_716_ROOF_ZONES.SOUTH_CORNER;
    case zoneClassification.southEdge:
      return ASCE_716_ROOF_ZONES.SOUTH_LEADING;
    case zoneClassification.eastWestEdge:
      return ASCE_716_ROOF_ZONES.EAST_WEST_EDGES;
    case zoneClassification.field:
    default:
      return ASCE_716_ROOF_ZONES.MIDDLE;
  }
};
