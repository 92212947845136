export function getSFProjectConfigurationData(projectEnvConfig: any) {
  const {
    zipcode,
    wind_speed,
    building_code,
    building_height,
    preferred_span,
    snow_load,
    elevation,
    wind_exposure,
    rail_finish,
    roof_type,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    shortest_building_length,
    pro_clamps,
    risk_category,
  } = projectEnvConfig;

  return {
    zipcode,
    wind_speed,
    building_code,
    building_height,
    preferred_span,
    snow_load,
    elevation,
    wind_exposure,
    rail_finish,
    roof_type,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    shortest_building_length,
    pro_clamps: pro_clamps ? 1 : 0,
    risk_category,
  };
}
