let lastSnapGroup = null;

export const setSnappedGroup = (snapGroupId) => {
  lastSnapGroup = snapGroupId;
};

export const getLastSnapGroup = () => lastSnapGroup;

export const clearLastSnapGroup = () => {
  lastSnapGroup = null;
};
