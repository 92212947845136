import ReactGA from 'react-ga';

const googleAnalyticsActions = {initiateGA: null};

googleAnalyticsActions.initiateGA = key => {
    ReactGA.initialize(key);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export {googleAnalyticsActions};
