import { deactivePanelsEditor } from '../../../panelsEditor/panelsEditorHelper';
import { dispatch, state } from '__common/store';
import { FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG, SET_MAP_CENTER, SET_ROOF_ZONES_AUTOFILL } from 'actions';
import { getProductSettings } from '../../../panelsEditor/components/productsSettings/productsSettings';
import { getRoofApproximateCenter } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/getRoofApproximateCenter';
import { getRoofsSelectorPlaceMarker } from '../../../components/roofsSelector/components/roofsSelectorLocationSearch/roofsSelectorLocationSearch';
import { hidePlaceMarker, setPlaceMarker, showPlaceMarker } from '../locationSearch/locationSearch';
import { mapConfig } from '__editor/googleMapsRoofsSelector/components/map/mapConfigs';
import { setMapCenterFromGoogleMaps, shouldSkipFetchZipcode, setShouldSkipFetZipCode, getZipCode } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import debounce from 'lodash/debounce';
import { SET_MAP_ZOOM } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapActions';

let map: google.maps.Map;

export const setMapEvents = (cavnas: HTMLElement, preview = false) => {

  if (preview && map) {
    map.addListener('click', () => {
      deactivePanelsEditor();
    });
  }

  if (map) {
    map.addListener('center_changed', () => {
      setMapCenterFromGoogleMaps(map.getCenter());
      zoomMapCloser()();

      if (!preview && !shouldSkipFetchZipcode()) {
        getZipCode();
      } else {
        setShouldSkipFetZipCode(false);
      }
    });
  }

  if (cavnas) {
    cavnas.addEventListener('DOMMouseScroll', zoomMapCloser(), true);
    cavnas.addEventListener('wheel', zoomMapCloser(), true);
  }
};

export const initializeMap = (
  canvas: HTMLElement,
  previeMode: boolean = false,
  extraCfg?: { [key in keyof google.maps.MapOptions] },
): google.maps.Map => {
  const { roofsSelectorMap: { zoom } } = state();
  const mapContainer = canvas;
  const mapCfg = {
    ...mapConfig(),
    ...extraCfg,
    zoom,
  };

  const config = mapCfg;
  map = new google.maps.Map(mapContainer, config);

  const { lat, lng } = config.center;

  dispatch(SET_MAP_CENTER({ lat: lat(), lng: lng() }));

  if (!shouldSkipFetchZipcode()) {
    getZipCode(previeMode);
  } else {
    setShouldSkipFetZipCode(false);
  }

  getRoofApproximateCenter();
  setGoogleMapsSettings();
  loadPlaceMarker();
  return map;
};


export const getMap = (): google.maps.Map => {
  if (map) {
    return map;
  }
};

export const removeMap = () => {
  map = null;
};

export const turnGoogleMapsOffMapMoveControls = () => {
  if (map) {
    map.setOptions({ draggable: false });
  }
};

export const turnGoogleMapsOnMapMoveControls = () => {
  if (map) {
    map.setOptions({ draggable: true });
  }
};

export const centerMapOnRoofs = (roofs: { [roofId: string]: googleRoofsObject }) => {
  const bounds = new google.maps.LatLngBounds();
  Object.keys(roofs).map((roofId) => {
    const shape = roofs[roofId].roof.overlay;
    shape.getBounds().map(latLng => bounds.extend(latLng));
  });
  if (map) {
    map.fitBounds(bounds);
  }
};

export const getGoogleZipCode = debounce(() => {
  const { projectConfiguration: { projectId } } = state();
  if(map && projectId?.length === 0 ){
    setTimeout(() => {
      const { projectConfiguration: { projectEnvConfig: { building_code, risk_category }, productId } } = state();
      const { lat, lng } = map.getCenter();
      dispatch(FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG(lat(), lng(), building_code, productId, risk_category));
    }, 0);
  }
}, 1000);

export const zoomInGoogleMap = () => {
  const currentZoom = map.getZoom();
  zoomMapCloser()();

  map.setZoom(currentZoom + 1);
};

export const zoomOutGoogleMap = () => {
  const currentZoom = map.getZoom();
  zoomMapCloser()();
  map.setZoom(currentZoom - 1);
};

function zoomMapCloser() {
  return () => setTimeout(() => {
    const { roofsSelectorMap: { zoom } } = state();
    const currMap: google.maps.Map = getMap();

    if (!currMap) {
      return;
    }


    const zoom_after = currMap.getZoom();
    if (zoom !== zoom_after) {
      if (zoom_after > 19) {
        hidePlaceMarker();
      } else {
        showPlaceMarker();
      }

      dispatch(SET_MAP_ZOOM(zoom_after));
    }

  });
}

function setGoogleMapsSettings() {
  const { projectConfiguration: { productId } } = state();
  const settings = getProductSettings(productId);

  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
}

function loadPlaceMarker() {
  const placeMarker = getRoofsSelectorPlaceMarker();
  if (placeMarker) {
    const pos = new google.maps.LatLng(placeMarker.lat, placeMarker.lng);
    setPlaceMarker(pos);
  }
}

export function googleMapRelocation() {
  const { drawingManager: { roofs } } = state();
  let roof_Id_Array = (Object.keys(roofs));
  const googleMap = getMap();
  if (roofs && roof_Id_Array.length>0){
    const {lat, lng} = roofs[roof_Id_Array[0]].marker;
    googleMap.setCenter(new google.maps.LatLng(lat,lng));
  }
}
