import metalXRoofTypeTooltip from "projectDesign/components/projectConfiguration/fieldsTooltips/metalXRoofTypeTooltip";
export const apiField = 'roof_type';

export enum ROOF_TYPES {
  STANDING_SEAM = 2,
  R_PANEL = 10,
  CORRUGATED_METAL = 11,
}

export const options = {
  metalSeam: {
    value: 2,
    name: 'STANDING SEAM METAL',
  },
  metalRPanel: {
    value: 10,
    name: 'R-PANEL METAL',
  },
  metalCorrugated: {
    value: 11,
    name: 'CORRUGATED METAL',
  },
};
export default {
  type: "Select",
  label: "roofType",
  apiField,
  missing: undefined,
  tooltip: metalXRoofTypeTooltip,
  data: [
    options.metalSeam,
    options.metalRPanel,
    options.metalCorrugated,
  ],
};
