import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { MOVE_MAP_MODE_DISABLE, SET_REMOVE_RUBBER_BAND } from '__editor/panelsEditor/actions';
import { TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import {  RESTORE_PANELS_FOR_NO_MOVEMENT, UNBLOCK_CREATING_PANEL } from '../../panels/panelsActions';
import { DISABLE_BAYS_CONTAINER, MOVE_ARRAY_MODE_DISABLE, TOGGLE_ATTACHMENTS_DISABLE } from '../../background/backgroundActions';

class Remove extends React.Component<removeComponentState, {}> {
  @autobind
  action() {
    const { dispatch } = this.props;
    dispatch(UNBLOCK_CREATING_PANEL());
    dispatch(SET_REMOVE_RUBBER_BAND());
    dispatch(MOVE_MAP_MODE_DISABLE());
    if(this.props.moveArrayEnabled) {
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
    }
    dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    dispatch(TOGGLE_ATTACHMENTS_DISABLE());
    dispatch(TURN_OFF_MEASUREMENT_TOOL());
    dispatch(DISABLE_BAYS_CONTAINER());
  }

  render() {
    const { mode } = this.props.rubberBand;
    const { moveMapEnabled, moveArrayEnabled} = this.props;

    return (
      <Button flat={true} onClick={this.action} className="roofs-selector-button" tooltipLabel="Remove modules">
        <SVG className={mode === 'remove' && !(moveMapEnabled || moveArrayEnabled) ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_delete_module.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(state) {
  return {
    moveMapEnabled: state.background.moveMapMode,
    moveArrayEnabled: state.background.moveArrayMode,
    rubberBand: state.rubberBand,
  };
}

export default connect(mapStateToProps)(Remove);
