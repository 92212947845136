import { isNxtHorizon,isSFMInfinity, isSolarMount } from "__common/constants/products";
import attachmentFasteningOnTooltip from "../fieldsTooltips/attachmentFasteningOnTooltip";
import nxtButylAttachmentToolTip from "../fieldsTooltips/nxtButylAttachmentToolTip";
import sfmAttachmentFasteningOnTooltip from "../fieldsTooltips/sfmAttachmentFasteningOnTooltip";
import { AttachmentType } from "./attachmentType";
import { checkRoofSubstrate } from "__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject";
export const apiField = 'roof_substrate';


const roofSubstrateOptions = [
  {
    value: 3,
    name: 'Rafters',
  },
  {
    value: 1,
    name: 'Direct to Deck (OSB)',
  },
  {
    value: 2,
    name: 'Direct to Deck (Plywood)',
  },
]

const roofSubstrateTooltip = (productId, attachment_type) => {
  if((isNxtHorizon(productId)&& attachment_type === AttachmentType.STRONGHOLD_ATT_BUTYL) || (isSolarMount(productId) && attachment_type === AttachmentType.SM_BUTYL_DTD)){
    return nxtButylAttachmentToolTip;
  }
  else if(isSFMInfinity(productId) && attachment_type === AttachmentType.SFM_BUTYL_DTD){
    return sfmAttachmentFasteningOnTooltip;
  }
  else{
    return attachmentFasteningOnTooltip;
  }
}

export const roofSubstrate = (productId, attachment_type, roof_substrate?, roof_type?)=>{
  let roof_substrate_field = {
    type: 'Select',
    label: 'attachment_fastening_on',
    apiField,
    missing: undefined,
    data: roofSubstrateOptions,
    tooltip: roofSubstrateTooltip(productId, attachment_type),
  };
  roof_substrate_field.missing = checkRoofSubstrate(attachment_type, roof_type, roof_substrate) ? true : undefined;
  return roof_substrate_field
}
