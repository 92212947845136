import BomPrices from 'bom/components/Prices';
import React from 'react';
import { calculatePrices } from 'bom/bomHelpers';
import config from 'config';
import { FETCH_STATIC } from '__common/components/FetchStatics/fetchStaticsActions';
import { getBomCSVName } from 'bom/utils/bomCSVNames';
import { dispatch } from '__common/store';
import { getPEStampPDFName } from 'peStamp/utils/pestampPDFNames';
import { showErrorAlert } from '__common/modules/alerts';
import { shouldDisplayPeOrCombinedPeStampButton } from 'combineProjects/CombineProjectsHelper';

type Props = {
  combinedPartsList: bomPartsList,
  combinedId: string,
  inputListWithUnequalValues: (string|number)[],
  isStaff: boolean,
};

export default class CombinedProjectsHeader extends React.Component<Props> {
  openPdf = () => {
    const { combinedId } = this.props;
    window.open(`${location.protocol}//${config.apiUrl}api/v1/reports/pdf/combined_projects/${combinedId}/`, 'download_window');
  }
  
  downloadBom = () => {
    const { combinedId } = this.props;
    dispatch(
      FETCH_STATIC(`${getBomCSVName(combinedId)}.csv`, 'text/csv', `download/combinedbom/`, { combinedId }),
    );
  }

  downloadPEStamp = () => {
    const { combinedId, inputListWithUnequalValues} = this.props;
    if(!inputListWithUnequalValues.length){
      dispatch(
        FETCH_STATIC(`${getPEStampPDFName(combinedId)}.pdf`, 'text/pdf', `download/combinedpestamp/`, { combinedId }),
      );
    }
    else{
      const unequalInputs = inputListWithUnequalValues.join(', ')
      showErrorAlert(`${unequalInputs} should be same`);
    }
  }

  render() {
    const { accessoriesPrice, baseSystemPrice } = calculatePrices(this.props.combinedPartsList);
    const { isStaff } = this.props;
    return (
      <div className="pages-header">
        <div className="title">
          Combined Projects
        </div>
        <div className="prices">
          <BomPrices accessoriesPrice={accessoriesPrice} baseSystemPrice={baseSystemPrice}  />
        </div>
        <div className="download-links">
          <div onClick={this.openPdf} className="download-link download-module-db">
            <i className="md-icon material-icons md-text--inherit">print</i>
            PRINT PDF
          </div>
          <div onClick={this.downloadBom} className="download-link download-module-db">
            <i className="md-icon material-icons md-text--inherit">download</i>
            COMBINED BOM
          </div>
          {shouldDisplayPeOrCombinedPeStampButton(isStaff) ? (
            <div onClick={this.downloadPEStamp} className="download-link download-module-db">
              <i className="md-icon material-icons md-text--inherit">download</i>
              COMBINED PROJECT SUMMARY
            </div>
          ) : null}
          
        </div>
      </div>
    );
  }
}
