import React from 'react';


export default class EngineeringProductAssumptions extends React.Component<
  > {
  renderSM() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header">U-Builder Assumptions</div>
        <div className="assumptions-main-header main-title">
          SOLARMOUNT Flush and SFM Infinity U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          SOLARMOUNT Flush and SFM Infinity
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
            building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Allowable spans and resulting point loads are subject to the following
          conditions:</u>
        </div>
        <div className="text first-level">
          <div>1. Building Height ≤ 60 ft unless otherwise specified.</div>
          <div>2. Roof Slope ≥ 1.2°</div>
          <div>3. Maximum rail cantilever ≤ 1/3 of selected span.</div>
          <div>4. Topographic Factor (Kzt) = 1</div>
          <div>5. Occupancy/Risk Category = II unless otherwise specified.</div>
          <div>
            6. ASCE 7-05: Basic Wind Speed: 85-170 mph (IBC 2006/ASCE 7-05).
            Wind Exposure: B, C or D.
          </div>
          <div>
            7. ASCE 7-10: Basic Wind Speed: 95-190 mph (IBC 2012/ASCE 7-10).
            Wind Exposure: B, C or D.
          </div>
          <div>
            8. ASCE 7-16: Basic Wind Speed: 85-190 mph (IBC 2018/ASCE 7-16). Wind Exposure: B, C or D.
          </div>
          <div>
            9. ASCE 7-22: Basic Wind Speed: 85-190 mph (IBC 2018/ASCE 7-22). Wind Exposure: B, C or D.
          </div>
          <div>
            10. Ground Snow Load: 0-100 psf. (Reduction can be input if this is
            acceptable with your local building authority). Results are based on
            uniform snow loading and do not consider unbalanced, drifting, and
            sliding conditions.
          </div>
          <div>11. Dead Load ≤ 5 psf (includes PV Modules and Racking).</div>
          <div>12. Maximum PV Module Length: 85 in.</div>
          <div>
            13. Seismic: Installations must be in seismic site class A, B, C, or
            D as defined in IBC 2006/ASCE 7-05.
          </div>
          <div>
            14. Intermediary Span Distances: Roof attachment point loads be
            reduced linearly if the installed distance between the attachments
            is less than the selected span. For example, if the spacing is half
            the amount shown in the results, then the point loads may also be
            reduced by half.
          </div>
          <div>
            15. Spans greater than 96 in.: While our products are
            valid for greater spans in many instances, we do not recommend this
            mounting configuration. Typical residential roofs are not designed
            for the larger point loads that might result from such excessive
            spans and we recommend final design by a licensed professional
            engineer of responsible charge.
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Pitched Roof Solutions (Flush Mounted Solutions):</u>
        </div>
        <div className="text first-level">
          <div>
            Modules are parallel to the roof surface and
            within 10 in. of it.
          </div>
          <div>Allowable roof pitch: 1.2° - 45°.</div>
          <div>
            Building has a monoslope roof with a slope ≤ 3°, a gable roof ≤ 45°,
            or a hip roof ≤ 27°.
          </div>
        </div>

        <div className="assumptions-sub-header">
          <u>Flat Roof Solutions (SolarMount Tilt and SOLARMOUNT-E Tilt):</u>
        </div>

        <div className="text first-level">
          <div>Module Tilt: 0-40 degrees (relative to horizontal)</div>
          <div>
            South facing modules on North facing roofs (“Reverse Tilt”) should
            not be designed with this tool.
          </div>
        </div>
      </div>
    );
  }

  renderSMTiltPR() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          SM Tilt PR U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          SM Tilt PR
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
            building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Allowable spans and resulting point loads are subject to the following
          conditions:</u>
        </div>
        <div className="text first-level">
          <div>1. Building Height ≤ 60 ft unless otherwise specified.</div>
          <div>2. Roof Slope ≥ 7°</div>
          <div>3. Maximum rail cantilever ≤ 1/3 of selected span.</div>
          <div>4. Topographic Factor (Kzt) = 1</div>
          <div>5. Occupancy/Risk Category = II unless otherwise specified.</div>
          <div>
            6. ASCE 7-16: Basic Wind Speed: 85-190 mph (IBC 2018/ASCE 7-16). Wind Exposure: B, C or D.
          </div>
          <div>
            7. Ground Snow Load: 0-100 psf. (Reduction can be input if this is
            acceptable with your local building authority). Results are based on
            uniform snow loading and do not consider unbalanced, drifting, and
            sliding conditions.
          </div>
          <div>8. Dead Load ≤ 5 psf (includes PV Modules and Racking).</div>
          <div>9. Maximum PV Module Length: 100".</div>
          <div>
            10. Intermediary Span Distances: Roof attachment point loads be
            reduced linearly if the installed distance between the attachments
            is less than the selected span. For example, if the spacing is half
            the amount shown in the results, then the point loads may also be
            reduced by half.
          </div>
          <div>
            11. Spans greater than 72": While our products are
            valid for greater spans in many instances, we do not recommend this
            mounting configuration. Typical residential roofs are not designed
            for the larger point loads that might result from such excessive
            spans and we recommend final design by a licensed professional
            engineer of responsible charge.
          </div>
        </div>
      </div>
    );
  }

  renderRM5() {
    return (
      <div className="enigneering-product-assumptions">
        <p className="assumptions-main-header main-title">RM 5 U-BUILDER PRODUCT ASSUMPTIONS</p>
        <div className="assumptions-sub-header">
          RM5 – Ballasted Flat Roof Systems
        </div>
        <div className="text text-block">
          Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
          Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
          building design for this project.
        </div>
        <div className="assumptions-sub-header">Building Assumptions</div>
        <div className="text first-level">
          <div>1. Risk Category II</div>
          <div>2. Building Height ≤ 50 ft</div>
          <div>
            3.
            Building Height > 50 ft: only where (longest length of building x building height)^0.5 ≤ 100 ft
          </div>
          <div>4. Roof Slope ≥ 0° (0:12) and ≤ 3° (5/8:12) for Seismic Design Category C, D, E and F. For low seismic regions
          Seismic Design Category A and B (provided Array Importance factor = 1.0), Roof Slope ≥ 0° (0:12) and ≤ 7° (1 1/2:12).</div>
          <div>5. Roofing Material Types: EDPM, PVC, TPO, or Mineral Cap</div>
          <div>6. Surrounding Building Grade: Level</div>
        </div>

        <div className="assumptions-sub-header">Ballast Blocks</div>
        <div className="text text-block">
          The installer is  responsible  for procuring  the ballast blocks
          (Concrete Masonry Units – CMU) and verifying  the required  minimum
          weight  needed  for  this  design.  CMU  should  comply  with  ASM
          standard  specification  for concrete  roof  pavers  designation
          (C1491  or  C90 with an  integral water  repellant  suitable  for
          the  climate  it  is
          placed. It is recommended that the blocks are inspected periodically for any signs of degradation. If degradation
          of the block is observed, the block should immediately be replaced. The CMU ballast block should have nominal
          dimensions of 4”x8”x16”. The actual block dimensions are 3/8” less than the nominal dimensions. Ballast blocks
          should have a weight as specified for the project in the “Inspection” section of this report.
        </div>

        <div className="assumptions-sub-header">Design Parameters</div>
        <div className="text first-level">
          <div>1. Risk Category II</div>
          <div>2. Wind Design</div>

          <div className="text second-level">
            <div>a. Basic Wind Speed: 110-150 mph (ASCE 7-10)/90-180 mph (ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure: B or C (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. 25 year Design Life/50 years Design Life for ASCE 7-16/ASCE 7-22</div>
            <div>
              d.
              Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. If your
              project is in a special case study region or in an area where wind studies have been performed, please
              verify with your jurisdiction to ensure that elevation effects have not already been factored into the
              wind speed. If elevation effects have been included in your wind speed, please select 0 ft as the project
              site elevation.
            </div>
            <div>
              e.
              Wind Tunnel Testing: Wind tunnel testing coefficients have been utilized for design of the system.
            </div>
          </div>

          <div>
            3. Snow Design
            <div className="text second-level">
              <div>a. Ground Snow Load: 0-80 psf (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
              <div>b. Exposure Factor: 0.9</div>
              <div>c. Thermal Factor: 1.2</div>
              <div>
                d. Roof Snow Load: Calculation per Section 7.3 (ASCE 7-10/ASCE 7-16/ASCE 7-22)
              </div>
              <div>
                e. Unbalanced/Drifting/Sliding:  Results  are  based  on  the
                uniform  snow  loading  and  do  not  consider
                unbalanced, drifting, and sliding conditions.
              </div>
            </div>
          </div>

          <div>
            4. Seismic Design
            <div className="text second-level">
              <div>
                a. Report  SEAOC  PV1-2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12  -  Structural  Seismic
                Requirements  and  Commentary  for  Rooftop  Solar
                Photovoltaic Arrays
              </div>
              <div>b. Seismic Site Class: A, B, C, or D (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
              <div>c. Importance Factor Array (Ip): 1.0</div>
              <div>d. Importance Factor Building (Ie): 1.0</div>
              <div>e. Site Class: D</div>
            </div>
          </div>
        </div>

        <div className="assumptions-sub-header">Properties</div>
        <div className="text first-level">
          <div>1. Bay Weight: ~7.2 lbs</div>
          <div>2. Wind Deflector Weight: ~6.4 lbs</div>
          <div>3. Module Gaps (E/W) = 0.25 in</div>
          <div>
            4.
            Wind Deflectors: Wind deflectors on the east and west edges of the array should overhang the east and west
            modules by six inches for Type 1 modules on the north rows only. Wind deflectors on the east and west edges
            of the array should overhang the east and west modules by six inches for Type II modules.
          </div>
          <div>
            5.
            Bays: North row bays overhang the module by ~6.5 inches and south row bays overhang the module by ~12.25
            inches.
          </div>
        </div>

        <div className="assumptions-sub-header">Testing</div>
        <div className="text first-level">
          <div>1. Coefficient of Friction</div>
          <div>2. Wind Tunnel</div>
          <div>3. UL 2703</div>
          <div>4. Component Testing (Bay and Clamp)</div>
        </div>

        <div className="assumptions-sub-header">Setbacks</div>
        <div className="text text-block">
          For the wind tunnel recommendations in U-Builder to apply, the following setbacks should be observed/followed
          for U-Builder wind design:
        </div>
        <div className="text first-level">
          <div>
            1.
            Modules should be placed a minimum of 3 feet from the edge of the building in any direction.
          </div>
          <div>
            2.
            If the array is located near an obstruction that is 3.5 feet wide and 3.5 feet high or larger, the nearest
            module of the array must be located a distance from the obstruction that is greater than or equal to
            the height of the obstruction.
            <br />
            Exception:  When using ASCE 7-16/ASCE 7-22 Building Code and using the obstruction feature in the module editor to accurately model the size and location of obstruction.
          </div>
          <div>
            3.
            Installations within the setbacks listed above require site specific engineering. <sup>2</sup>
          </div>
          <div>
            4. The setbacks above are  for wind. High seismic areas,
            fire access isles, mechanical equipment, etc.,
            may require larger setbacks than listed above for wind.
          </div>
        </div>
        <div className="assumptions-sub-header">Site Specific Engineering</div>
        <div className="text text-block">
          {' '}
          Conditions listed below are beyond the current capabilities of U-Builder. Site specific engineering is required.
        </div>
        <div className="text first-level">
          <div>
            1. Wind designs for a project design life exceeding 25 years <sup>1/ASCE 7-16/ASCE 7-22</sup>
          </div>
          <div>
            2.
            Building assumptions and design parameters outside of U-Builder assumptions
          </div>
          <div>3. Attachments</div>
          <div>
            4.
            Risk Category III or IV projects (U-Builder can be adjusted for the correct wind, but not the seismic or
            snow design)
          </div>
          <div>
            5.
            Wind tunnel testing reduction factors are not permitted by the Authority Having Jurisdiction (AHJ) <sup>3</sup>
          </div>
          <div>
            6.
            Seismic designs that fall outside SEAOC PV1-2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12 recommendations (>3% roof slope, or AHJ’s that
            require shake table testing or non-linear site-specific response history analysis)
          </div>
          <div>
            7.
            Signed and sealed site-specific calculations, layouts, and drawings
          </div>
          <div>8. Building that is not enclosed and categorized as open structures, carport or others</div>
        </div>
        <div className="text second-level">Notes:</div>
        <div className="text third-level">
          <div><sup>1</sup> Please contact <a href="mailto:info@unirac.com">info@unirac.com</a>.</div>
          <div><sup>2</sup> Please contact <a href="mailto:EngineeringServices@unirac.com">EngineeringServices@unirac.com</a> for more information.</div>
        </div>
      </div>
    );
  }

  renderRM10() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          RM10 U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          RM10 – Ballasted Flat Roof Systems
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
            building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category II </div>
          <div>2. Building Height ≤ 50 ft </div>
          <div>3. Building Height > 50 ft: only where (longest length of building x building height)^0.5 ≤ 100 ft</div>
          <div>4. Roof Slope ≥ 0° (0:12) and ≤ 3° (5/8:12) for Seismic Design Category C, D, E and F. For low seismic regions
          Seismic Design Category A and B (provided Array Importance factor = 1.0), Roof Slope ≥ 0° (0:12) and ≤ 7° (1 1/2:12).</div>
          <div>5. Roofing Material Types: EDPM, PVC, TPO, or Mineral Cap </div>
          <div>6. Surrounding Building Grade: Level </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Ballast Blocks</u>
        </div>
        <div className="text first-level">
          The installer is responsible for procuring the ballast blocks (Concrete Masonry Units – CMU) and verifying the
          required minimum weight needed for this design. CMU should comply with ASM standard specification for
          concrete roof pavers designation (C1491 or C90 with an integral water repellant suitable for the climate it is
          placed. It is recommended that the blocks are inspected periodically for any signs of degradation. If degradation
          of the block is observed, the block should immediately be replaced. The CMU ballast block should have nominal
          dimensions of 4”x8”x16”. The actual block dimensions are 3/8” less than the nominal dimensions. Ballast blocks
          should have a weight as specified for the project in the “Inspection” section of this report.
        </div>
        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category II</div>
          <div>
            <div>2. Wind Design</div>
            <div className="text second-level">
              <div>a. Basic Wind Speed: 85-120 mph (ASCE 7-05)/110-150 mph (ASCE 7-10)/90-180 mph (ASCE 7-16/ASCE 7-22)</div>
              <div>b. Exposure: B, C or D (ASCE 7-05/ASCE 7-10)</div>
              <div>c. 25 year Design Life/50 year Design Life for ASCE 7-16/ASCE 7-22</div>
              <div>d. Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. If your
              project is in a special case study region or in an area where wind studies have been performed, please
              verify with your jurisdiction to ensure that elevation effects have not already been factored into the
              wind speed. If elevation effects have been included in your wind speed, please select 0 ft as the project
              site elevation.
                </div>
              <div>e. Wind Tunnel Testing: Wind tunnel testing coefficients have been utilized for design of the system.</div>
            </div>
          </div>
        </div>
        <div className="text first-level">
          <div>3. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0‐80 psf (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure Factor: 0.9</div>
            <div>c. Thermal Factor: 1.2</div>
            <div>d. Roof Snow Load: Calculation per Section 7.3 (ASCE 7-05/ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>e. Unbalanced/Drifting/Sliding:  Results  are  based  on  the  uniform  snow  loading  and  do  not  consider
                   unbalanced, drifting, and sliding conditions. </div>
          </div>
        </div>
        <div className="text first-level">
          <div>4. Seismic Design</div>
          <div className="text second-level">
            <div>a. Report  SEAOC  PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12  –  Structural  Seismic  Requirements  and  Commentary  for  Rooftop  Solar
                   Photovoltaic Arrays </div>
            <div>b. Seismic Site Class: A, B, C, or D (ASCE 7-05/ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. Importance Factor Array (Ip): 1.0</div>
            <div>d. Importance Factor Building (Ie): 1.0 </div>
            <div>e. Site Class: D</div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Properties</u>
        </div>
        <div className="text first-level">
          <div>1. Bay Weight: ~3.5 lbs</div>
          <div>2. Module Gaps (E/W) = 0.25 in</div>
          <div>3. Bays: North row bays overhang the module by ~19.5 inches.</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Module Properties</u>
        </div>
        <div className="text first-level">
          <div>1. Module return flange: Minimum of 0.9in (when using 1-3/4 in. clip bolts) is required.</div>
          <div>2. Module return flange: Minimum of 0.65in (when using 2 in. clip bolts) is required.</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Testing</u>
        </div>
        <div className="text first-level">
          <div>1. Coefficient of Friction</div>
          <div>2. Wind Tunnel</div>
          <div>3. UL 2703</div>
          <div>4. Component Testing (Bay and Clamp)</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Setbacks</u>
        </div>
        <div className="text first-level">
          For the wind tunnel recommendations in U‐Builder to apply, the following setbacks should be observed/followed
          for U‐Builder wind design:
          <div className="text second-level">
            <div>1. Modules should be placed a minimum of 3 feet from the edge of the building in any direction. </div>
            <div>2. If the array is located near an obstruction that is 3.5 feet wide and 3.5 feet high or larger, the nearest
            module of the array must be located a distance from the obstruction that is greater than or equal to
            the height of the obstruction.
            <br />
              Exception:  When using ASCE 7-16/ASCE 7-22 Building Code and using the obstruction feature in the module editor to accurately model the size and location of obstruction.
            </div>
            <div>3. Installations within the setbacks listed above require site specific engineering. <sup>2</sup></div>
            <div>4. The setbacks above are  for wind. High seismic areas,  fire access isles, mechanical equipment, etc.,
            may require larger setbacks than listed above for wind.
            </div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          Conditions listed below are beyond the current capabilities of U‐Builder. Site specific engineering is required.
          <div className="text second-level">
            <div>1. Wind designs for a project design life exceeding 25 years<sup>1/ASCE 7-16/ASCE 7-22</sup></div>
            <div>2. Building assumptions and design parameters outside of U‐Builder assumptions<sup>2</sup></div>
            <div>3. Attachments<sup>2</sup></div>
            <div>4. Risk Category III or IV projects (U‐Builder can be adjusted for the correct wind, but not the seismic or
                 snow design)<sup>2</sup>
            </div>
            <div>5. Wind tunnel testing reduction factors are not permitted by the Authority Having Jurisdiction (AHJ)<sup>3</sup></div>
            <div>6. Seismic designs that fall outside SEAOC PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12 recommendations (>3% roof slope, or AHJ’s that
                require shake table testing or non‐linear site‐specific response history analysis)<sup>3</sup></div>
            <div>7. Signed and sealed site‐specific calculations, layouts, and drawings<sup>3</sup></div>
            <div>8. Building that is not enclosed and categorized as open structures, carport or others</div>
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div><sup>1</sup> Please contact <a href="mailto:info@unirac.com">info@unirac.com</a>.</div>
              <div><sup>2</sup> Please contact <a href="mailto:EngineeringServices@unirac.com">EngineeringServices@unirac.com</a> for more information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRM10EVO() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          RM10 EVO U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          RM10 EVO – Ballasted Flat Roof Systems
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
            building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Minimum allowed setback distance is 1 ft </div>
          <div>2. Building Height ≤ 50 ft </div>
          <div>3. Building Height > 50 ft: only where (longest length of building x building height)^0.5 ≤ 100 ft</div>
          <div>4. Roof Slope ≥ 0° (0:12) and ≤ 3° (5/8:12) for Seismic Design Category C, D, E and F. For low seismic regions
          Seismic Design Category A and B (provided Array Importance factor = 1.0), Roof Slope ≥ 0° (0:12) and ≤ 7° (1 1/2:12).</div>
          <div>5. Roofing Material Types: EDPM, PVC, TPO, or Mineral Cap </div>
          <div>6. Surrounding Building Grade: Level </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Ballast Blocks</u>
        </div>
        <div className="text first-level">
          The installer is responsible for procuring the ballast blocks (Concrete Masonry Units – CMU) and verifying the
          required minimum weight needed for this design. CMU should comply with ASM standard specification for
          concrete roof pavers designation (C1491 or C90 with an integral water repellant suitable for the climate it is
          placed. It is recommended that the blocks are inspected periodically for any signs of degradation. If degradation
          of the block is observed, the block should immediately be replaced. The CMU ballast block should have nominal
          dimensions of 4”x8”x16”. The actual block dimensions are 3/8” less than the nominal dimensions. Ballast blocks
          should have a weight as specified for the project in the “Inspection” section of this report.
        </div>
        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category I to III</div>
          <div>
            <div>2. Wind Design</div>
            <div className="text second-level">
              <div>a. Basic Wind Speed: 110-150 mph (ASCE 7-10)/90-180 mph (ASCE 7-16/ASCE 7-22)</div>
              <div>b. Exposure: B, C or D (ASCE 7-05/ASCE 7-10)</div>
              <div>c. 25 year Design Life/50 year Design Life for ASCE 7-16/ASCE 7-22</div>
              <div>d. Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. If your
              project is in a special case study region or in an area where wind studies have been performed, please
              verify with your jurisdiction to ensure that elevation effects have not already been factored into the
              wind speed. If elevation effects have been included in your wind speed, please select 0 ft as the project
              site elevation.
                </div>
              <div>e. Wind Tunnel Testing: Wind tunnel testing coefficients have been utilized for design of the system.</div>
            </div>
          </div>
        </div>
        <div className="text first-level">
          <div>3. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0‐100 psf (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure Factor: 0.9</div>
            <div>c. Thermal Factor: 1.2</div>
            <div>d. Roof Snow Load: Calculation per Section 7.3 (ASCE 7-05/ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>e. Unbalanced/Drifting/Sliding:  Results  are  based  on  the  uniform  snow  loading  and  do  not  consider
                   unbalanced, drifting, and sliding conditions. </div>
          </div>
        </div>
        <div className="text first-level">
          <div>4. Seismic Design</div>
          <div className="text second-level">
            <div>a. Report  SEAOC  PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12  –  Structural  Seismic  Requirements  and  Commentary  for  Rooftop  Solar
                   Photovoltaic Arrays </div>
            <div>b. Seismic Site Class: A, B, C, or D (ASCE 7-05/ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. Importance Factor Array (Ip): 1.0</div>
            <div>d. Importance Factor Building (Ie): 1.0 </div>
            <div>e. Site Class: D</div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Properties</u>
        </div>
        <div className="text first-level">
          <div>1. Bay Weight: ~2.45 lbs</div>
          <div>2. Module Gaps (E/W) = 0.25 in</div>
          <div>3. Module Gaps (N/S) = 13.5 in</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Testing</u>
        </div>
        <div className="text first-level">
          <div>1. Coefficient of Friction</div>
          <div>2. Wind Tunnel</div>
          <div>3. UL 2703</div>
          <div>4. Component Testing (Bay and Clamp)</div>
        </div>
        <div className="assumptions-sub-header">
        <u>Setbacks</u>
        </div>
        <div className="text first-level">
          For the wind tunnel recommendations in U‐Builder to apply, the following setbacks should be observed/followed
          for U‐Builder wind design:
          <div className="text second-level">
            <div>1. Modules should be placed a minimum of 3 feet from the edge of the building in any direction. </div>
            <div>2. If the array is located near an obstruction that is 3.5 feet wide and 3.5 feet high or larger, the nearest
            module of the array must be located a distance from the obstruction that is greater than or equal to
            the height of the obstruction.
            <br />
              Exception:  When using ASCE 7-16/ASCE 7-22 Building Code and using the obstruction feature in the module editor to accurately model the size and location of obstruction.
            </div>
            <div>3. Installations within the setbacks listed above require site specific engineering. <sup>2</sup></div>
            <div>4. The setbacks above are  for wind. High seismic areas,  fire access isles, mechanical equipment, etc.,
            may require larger setbacks than listed above for wind.
            </div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          Conditions listed below are beyond the current capabilities of U‐Builder. Site specific engineering is required.
          <div className="text second-level">
            <div>1. Wind designs for a project design life exceeding 25 years<sup>1/ASCE 7-16/ASCE 7-22</sup></div>
            <div>2. Building assumptions and design parameters outside of U‐Builder assumptions<sup>2</sup></div>
            <div>3. Attachments<sup>2</sup></div>
            <div>4. Risk Category III or IV projects (U‐Builder can be adjusted for the correct wind, but not the seismic or
                 snow design)<sup>2</sup>
            </div>
            <div>5. Wind tunnel testing reduction factors are not permitted by the Authority Having Jurisdiction (AHJ)<sup>3</sup></div>
            <div>6. Seismic designs that fall outside SEAOC PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12 recommendations (>3% roof slope, or AHJ’s that
                require shake table testing or non‐linear site‐specific response history analysis)<sup>3</sup></div>
            <div>7. Signed and sealed site‐specific calculations, layouts, and drawings<sup>3</sup></div>
            <div>8. Building that is not enclosed and categorized as open structures, carport or others</div>
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div><sup>1</sup> Please contact <a href="mailto:info@unirac.com">info@unirac.com</a>.</div>
              <div><sup>2</sup> Please contact <a href="mailto:EngineeringServices@unirac.com">EngineeringServices@unirac.com</a> for more information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGridflex() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          Gridflex U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          Gridflex – Ballasted Flat Roof Systems
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
            building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Building Height ≤ 100 ft </div>
          <div>2. Roof Slope ≥ 0° (0:12) and ≤ 3° (5/8:12) for Seismic Design Category C, D, E and F. For low seismic regions
          Seismic Design Category A and B (provided Array Importance factor = 1.0), Roof Slope ≥ 0° (0:12) and ≤ 7° (1 1/2:12).</div>
          <div>3. Roofing Material Types: EDPM, PVC, TPO, or Mineral Cap </div>
          <div>4. Surrounding Building Grade: Level </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Ballast Blocks</u>
        </div>
        <div className="text first-level">
          The installer is responsible for procuring the ballast blocks (Concrete Masonry Units – CMU) and verifying the
          required minimum weight needed for this design. CMU should comply with ASM standard specification for
          concrete roof pavers designation (C1491 or C90 with an integral water repellant suitable for the climate it is
          placed. It is recommended that the blocks are inspected periodically for any signs of degradation. If degradation
          of the block is observed, the block should immediately be replaced. The CMU ballast block should have nominal
          dimensions of 4”x8”x16”. The actual block dimensions are 3/8” less than the nominal dimensions. Ballast blocks
          should have a weight as specified for the project in the “Inspection” section of this report.
        </div>
        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category I to IV</div>
          <div>
            <div>2. Wind Design</div>
            <div className="text second-level">
              <div>a. Basic Wind Speed: 110-150 mph (ASCE 7-10)/90-180 mph (ASCE 7-16/ASCE 7-22)</div>
              <div>b. Exposure: B, C or D (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
              <div>c. 25 year Design Life/50 year Design Life for ASCE 7-16/ASCE 7-22</div>
              <div>d. Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. If your
              project is in a special case study region or in an area where wind studies have been performed, please
              verify with your jurisdiction to ensure that elevation effects have not already been factored into the
              wind speed. If elevation effects have been included in your wind speed, please select 0 ft as the project
              site elevation.
                </div>
              <div>e. Wind Tunnel Testing: Wind tunnel testing coefficients have been utilized for design of the system.</div>
            </div>
          </div>
        </div>
        <div className="text first-level">
          <div>3. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0‐100 psf (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure Factor: 0.9</div>
            <div>c. Thermal Factor: 1.2</div>
            <div>d. Roof Snow Load: Calculation per Section 7.3 (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>e. Unbalanced/Drifting/Sliding:  Results  are  based  on  the  uniform  snow  loading  and  do  not  consider
                   unbalanced, drifting, and sliding conditions. </div>
          </div>
        </div>
        <div className="text first-level">
          <div>4. Seismic Design</div>
          <div className="text second-level">
            <div>a. Report  SEAOC  PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12  –  Structural  Seismic  Requirements  and  Commentary  for  Rooftop  Solar
                   Photovoltaic Arrays </div>
            <div>b. Seismic Site Class: A, B, C, or D (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. Importance Factor Array (Ip): 1.0 or 1.5</div>
            <div>d. Importance Factor Building (Ie): 1.0 </div>
            <div>e. Site Class: A or B or C or D</div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Properties</u>
        </div>
        <div className="text first-level">
          <div>1. Bay Weight: ~13.36 lbs</div>
          <div>2. Module Gaps (E/W) = 0.25 in</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Testing</u>
        </div>
        <div className="text first-level">
          <div>1. Coefficient of Friction</div>
          <div>2. Wind Tunnel</div>
          <div>3. UL 2703</div>
          <div>4. Component Testing (Bay and Clamp)</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Setbacks</u>
        </div>
        <div className="text first-level">
          For the wind tunnel recommendations in U‐Builder to apply, the following setbacks should be observed/followed
          for U‐Builder wind design:
          <div className="text second-level">
            <div>1. Modules should be placed a minimum of 3 feet from the edge of the building in any direction. </div>
            <div>2. If the array is located near an obstruction that is 3.5 feet wide and 3.5 feet high or larger, the nearest
            module of the array must be located a distance from the obstruction that is greater than or equal to
            the height of the obstruction.
            <br />
              Exception:  When using ASCE 7-16/ASCE 7-22 Building Code and using the obstruction feature in the module editor to accurately model the size and location of obstruction.
            </div>
            <div>3. Installations within the setbacks listed above require site specific engineering. <sup>2</sup></div>
            <div>4. The setbacks above are  for wind. High seismic areas,  fire access isles, mechanical equipment, etc.,
            may require larger setbacks than listed above for wind.
            </div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          Conditions listed below are beyond the current capabilities of U‐Builder. Site specific engineering is required.
          <div className="text second-level">
            <div>1. Wind designs for a project design life exceeding 25 years<sup>1/ASCE 7-16/ASCE 7-22</sup></div>
            <div>2. Building assumptions and design parameters outside of U‐Builder assumptions<sup>2</sup></div>
            <div>3. Wind tunnel testing reduction factors are not permitted by the Authority Having Jurisdiction (AHJ)<sup>3</sup></div>
            <div>4. Seismic designs that fall outside SEAOC PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12 recommendations (>3% roof slope, or AHJ’s that
                require shake table testing or non‐linear site‐specific response history analysis)<sup>3</sup></div>
            <div>5. Signed and sealed site‐specific calculations, layouts, and drawings<sup>3</sup></div>
            <div>6. Building that is not enclosed and categorized as open structures, carport or others</div>
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div><sup>1</sup> Please contact <a href="mailto:info@unirac.com">info@unirac.com</a>.</div>
              <div><sup>2</sup> Please contact <a href="mailto:EngineeringServices@unirac.com">EngineeringServices@unirac.com</a> for more information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEcoFoot2plus() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          EcoFoot2+ U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          EcoFoot2+ – Ballasted Flat Roof Systems
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
            building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Roof Slope ≥ 0° (0:12) and ≤ 3° (5/8:12) for Seismic Design Category C, D, E and F. For low seismic regions
          Seismic Design Category A and B (provided Array Importance factor = 1.0), Roof Slope ≥ 0° (0:12) and ≤ 7° (1 1/2:12).</div>
          <div>2. Roofing Material Types: EDPM, PVC, TPO, or Mineral Cap </div>
          <div>3. Surrounding Building Grade: Level </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Ballast Blocks</u>
        </div>
        <div className="text first-level">
          The installer is responsible for procuring the ballast blocks (Concrete Masonry Units – CMU) and verifying the
          required minimum weight needed for this design. CMU should comply with ASM standard specification for
          concrete roof pavers designation (C1491 or C90 with an integral water repellant suitable for the climate it is
          placed. It is recommended that the blocks are inspected periodically for any signs of degradation. If degradation
          of the block is observed, the block should immediately be replaced. The CMU ballast block should have nominal
          dimensions of 4”x8”x16”. The actual block dimensions are 3/8” less than the nominal dimensions. Ballast blocks
          should have a weight as specified for the project in the “Inspection” section of this report.
        </div>
        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category II</div>
          <div>
            <div>2. Wind Design</div>
            <div className="text second-level">
              <div>a. Basic Wind Speed: 85-180 mph (ASCE 7-10)/85-180 mph (ASCE 7-16/ASCE 7-22)</div>
              <div>b. Exposure: B, C or D (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
              <div>c. 25 year Design Life/50 year Design Life for ASCE 7-10, 50year Design Life for ASCE 7-16/ASCE 7-22</div>
              <div>d. Elevation: Insertion of the project at-grade elevation can result in a reduction of wind pressure. If your
              project is in a special case study region or in an area where wind studies have been performed, please
              verify with your jurisdiction to ensure that elevation effects have not already been factored into the
              wind speed. If elevation effects have been included in your wind speed, please select 0 ft as the project
              site elevation.
                </div>
              <div>e. Wind Tunnel Testing: Wind tunnel testing coefficients have been utilized for design of the system.</div>
            </div>
          </div>
        </div>
        <div className="text first-level">
          <div>3. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0‐60 psf (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure Factor: 0.9</div>
            <div>c. Thermal Factor: 1.2</div>
            <div>d. Roof Snow Load: Calculation per Section 7.3 (ASCE 7-05/ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>e. Unbalanced/Drifting/Sliding:  Results  are  based  on  the  uniform  snow  loading  and  do  not  consider
                   unbalanced, drifting, and sliding conditions. </div>
          </div>
        </div>
        <div className="text first-level">
          <div>4. Seismic Design</div>
          <div className="text second-level">
            <div>a. Report  SEAOC  PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12  –  Structural  Seismic  Requirements  and  Commentary  for  Rooftop  Solar
                   Photovoltaic Arrays </div>
            <div>b. Seismic Site Class: A, B, C, or D (ASCE 7-05/ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. Importance Factor Array (Ip): 1.0</div>
            <div>d. Importance Factor Building (Ie): 1.0 </div>
            <div>e. Site Class: D</div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Properties</u>
        </div>
        <div className="text first-level">
          <div>1. Bay Weight: ~4.04 lbs</div>
          <div>2. Module Gaps (E/W) = 0.5 in</div>
          <div>3. Bays: North row bays overhang the module by ~22.5 inches.</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Testing</u>
        </div>
        <div className="text first-level">
          <div>1. Coefficient of Friction</div>
          <div>2. Wind Tunnel</div>
          <div>3. UL 2703</div>
          <div>4. Component Testing (Bay and Clamp)</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Setbacks</u>
        </div>
        <div className="text first-level">
          For the wind tunnel recommendations in U‐Builder to apply, the following setbacks should be observed/followed
          for U‐Builder wind design:
          <div className="text second-level">
            <div>1. Modules should be placed a minimum of 3 feet from the edge of the building in any direction. </div>
            <div>2. If the array is located near an obstruction that is 3.5 feet wide and 3.5 feet high or larger, the nearest
            module of the array must be located a distance from the obstruction that is greater than or equal to
            the height of the obstruction.
            <br />
              Exception:  When using ASCE 7-16/ASCE 7-22 Building Code and using the obstruction feature in the module editor to accurately model the size and location of obstruction.
            </div>
            <div>3. Installations within the setbacks listed above require site specific engineering. <sup>2</sup></div>
            <div>4. The setbacks above are  for wind. High seismic areas,  fire access isles, mechanical equipment, etc.,
            may require larger setbacks than listed above for wind.
            </div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          Conditions listed below are beyond the current capabilities of U‐Builder. Site specific engineering is required.
          <div className="text second-level">
            <div>1. Wind designs for a project design life exceeding 25 years<sup>1/ASCE 7-16/ASCE 7-22</sup></div>
            <div>2. Building assumptions and design parameters outside of U‐Builder assumptions<sup>2</sup></div>
            <div>3. Attachments<sup>2</sup></div>
            <div>4. Risk Category III or IV projects (U‐Builder can be adjusted for the correct wind, but not the seismic or
                 snow design)<sup>2</sup>
            </div>
            <div>5. Wind tunnel testing reduction factors are not permitted by the Authority Having Jurisdiction (AHJ)<sup>3</sup></div>
            <div>6. Seismic designs that fall outside SEAOC PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12 recommendations (>3% roof slope, or AHJ’s that
                require shake table testing or non‐linear site‐specific response history analysis)<sup>3</sup></div>
            <div>7. Signed and sealed site‐specific calculations, layouts, and drawings<sup>3</sup></div>
            <div>8. Building that is not enclosed and categorized as open structures, carport or others</div>
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div><sup>1</sup> Please contact <a href="mailto:info@unirac.com">info@unirac.com</a>.</div>
              <div><sup>2</sup> Please contact <a href="mailto:EngineeringServices@unirac.com">EngineeringServices@unirac.com</a> for more information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRMDT() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          RMDT U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          RMDT – Ballasted Flat Roof Systems
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific
            project. Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar,
            electrical, or building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category II </div>
          <div>2. Building Height ≤ 50 ft </div>
          <div>3. Building Height > 50 ft: only where (longest length of building x building height)^0.5 ≤ 100 ft</div>
          <div>4. Roof Slope ≥ 0° (0:12) and ≤ 3° (5/8:12) for Seismic Design Category C, D, E and F. For low seismic regions
          Seismic Design Category A and B (provided Array Importance factor = 1.0), Roof Slope ≥ 0° (0:12) and ≤ 7° (1 1/2:12).</div>
          <div>5. Roofing Material Types: EDPM, PVC, TPO, or Mineral Cap </div>
          <div>6. Surrounding Building Grade: Level </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Ballast Blocks</u>
        </div>
        <div className="text first-level">
          The installer is  responsible  for procuring  the ballast blocks  (Concrete Masonry Units – CMU) and verifying  the
          required  minimum  weight  needed  for  this  design.  CMU  should  comply  with  ASM  standard  specification  for
          concrete  roof  pavers  designation  (C1491  or  C90 with an  integral water  repellant  suitable  for  the  climate  it  is
          placed. It is recommended that the blocks are inspected periodically for any signs of degradation. If degradation
          of the block is observed, the block should immediately be replaced. The CMU ballast block should have nominal
          dimensions of 4”x8”x16”. The actual block dimensions are 3/8” less than the nominal dimensions. Ballast blocks
          should have a weight as specified for the project in the “Inspection” section of this report.
        </div>
        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Risk Category II</div>
          <div>
            <div>2. Wind Design</div>
            <div className="text second-level">
              <div>a. Basic Wind Speed: 110‐150 mph (ASCE 7‐10)/90-180 mph (ASCE 7-16/ASCE 7-22)</div>
              <div>b. Exposure: B or C (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
              <div>c. 25 year Design Life/50 year Design Life for ASCE 7-16/ASCE 7-22</div>
              <div>d. Elevation: Insertion of the project at‐grade elevation can result in a reduction of wind pressure. If your
              project is in a special case study region or in an area where wind studies have been performed, please
              verify with your jurisdiction to ensure that elevation effects have not already been factored into the
              wind speed. If elevation effects have been included in your wind speed, please select 0 ft as the project
              site elevation.
                </div>
              <div>e. Wind Tunnel Testing: Wind tunnel testing coefficients have been utilized for design of the system.  </div>
            </div>
          </div>
        </div>
        <div className="text first-level">
          <div>3. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0‐80 psf (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure Factor: 0.9</div>
            <div>c. Thermal Factor: 1.2</div>
            <div>d. Roof Snow Load: Calculation per Section 7.3 (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>e. Unbalanced/Drifting/Sliding:  Results  are  based  on  the  uniform  snow  loading  and  do  not  consider
                   unbalanced, drifting, and sliding conditions. </div>
          </div>
        </div>
        <div className="text first-level">
          <div>4. Seismic Design</div>
          <div className="text second-level">
            <div>a. Report  SEAOC  PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12  –  Structural  Seismic  Requirements  and  Commentary  for  Rooftop  Solar
                   Photovoltaic Arrays </div>
            <div>b. Seismic Site Class: A, B, C, or D (ASCE 7‐10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. Importance Factor Array (Ip): 1.0</div>
            <div>d. Importance Factor Building (Ie): 1.0 </div>
            <div>e. Site Class: D</div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Properties</u>
        </div>
        <div className="text first-level">
          <div>1. Ridge Bay Weight: ~7.7 lbs</div>
          <div>2. Valley Bay Weight: ~5.6 lbs</div>
          <div>3. Module Gaps (N/S): 0.25 in</div>
          <div>4. Bays: East and west column bays overhang the module by ~7.9 inches.</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Testing</u>
        </div>
        <div className="text first-level">
          <div>1. Coefficient of Friction</div>
          <div>2. Wind Tunnel</div>
          <div>3. UL 2703</div>
          <div>4. Component Testing (Bay and Clamp)</div>
        </div>
        <div className="assumptions-sub-header">
          <u>Setbacks</u>
        </div>
        <div className="text first-level">
          For the wind tunnel recommendations in U‐Builder to apply, the following setbacks should be observed/followed
          for U‐Builder wind design:
          <div className="text second-level">
            <div>1. Modules should be placed a minimum of 3 feet from the edge of the building in any direction. </div>
            <div>2. If the array is located near an obstruction that is 3.5 feet wide and 3.5 feet high or larger, the nearest
            module of the array must be located a distance from the obstruction that is greater than or equal to
            the height of the obstruction.
            <br />
              Exception:  When using ASCE 7-16/ASCE 7-22 Building Code and using the obstruction feature in the module editor to accurately model the size and location of obstruction.
            </div>
            <div>3. Installations within the setbacks listed above require site specific engineering. <sup>2</sup></div>
            <div>4. The setbacks above are  for wind. High seismic areas,  fire access isles, mechanical equipment, etc.,
            may require larger setbacks than listed above for wind.
            </div>
          </div>
        </div>
        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          Conditions listed below are beyond the current capabilities of U‐Builder. Site specific engineering is required.
          <div className="text second-level">
            <div>1. Wind designs for a project design life exceeding 25 years<sup>1/ASCE 7-16/ASCE 7-22</sup></div>
            <div>2. Building assumptions and design parameters outside of U‐Builder assumptions<sup>2</sup></div>
            <div>3. Attachments<sup>2</sup></div>
            <div>4. Risk Category III or IV projects (U‐Builder can be adjusted for the correct wind, but not the seismic or
                 snow design)<sup>2</sup>
            </div>
            <div>5. Wind tunnel testing reduction factors are not permitted by the Authority Having Jurisdiction (AHJ)<sup>3</sup></div>
            <div>6. Seismic designs that fall outside SEAOC PV1‐2012/ASCE 7-16/ASCE 7-22 SECTION 13.6.12 recommendations (>3% roof slope, or AHJ’s that
                require shake table testing or non‐linear site‐specific response history analysis)<sup>3</sup></div>
            <div>7. Signed and sealed site‐specific calculations, layouts, and drawings<sup>3</sup></div>
            <div>8. Building that is not enclosed and categorized as open structures, carport or others</div>
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div><sup>1</sup> Please contact <a href="mailto:info@unirac.com">info@unirac.com</a>.</div>
              <div><sup>2</sup> Please contact <a href="mailto:EngineeringServices@unirac.com">EngineeringServices@unirac.com</a> for more information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGFT() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          GFT U-BUILDER PRODUCT ASSUMPTIONS
        </div>

        <div className="assumptions-sub-header">
          GFT - Ground Fixed Tilt
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or building design for this
            project.
          </i>
        </div>

        <div className="assumptions-sub-header">
          <u>Default Dimensions</u>
        </div>
        <div className="text first-level">
          <div>1. For Module Length &lt;= 83, Top chord length: 138.59”</div>
          <div>1. For Module Length &lt;= 97, Top chord length: 158.59”</div>
          <div>2. Distance from top chord edge to front brace bolt and rear brace bolt: 35.878”</div>
          <div>3. Clear gap between modules in North-South &amp; East-West direction</div>
          <div className="text second-level">
            <div>a. Pro series clamps: 1”</div>
            <div>b. Standard clamps: 0.25”</div>
          </div>
          <div>4. Pile offset from centre of module gap: 20”</div>
          <div>5. Grade to bottom of diagonal brace mount: 6”</div>
        </div>

        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Wind Design</div>
          <div className="text second-level">
            <div>a. Basic Wind Speed: 85-210mph (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Exposure: B or C (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>c. 50-year Design Life</div>
            <div>d. Elevation: Insertion of the project at - grade elevation can result in a reduction of wind pressure. If your project is in a special
            case study region or in an area where wind studies have been performed, please verify with your jurisdiction to ensure that
            elevation effects have not already been factored into the wind speed. If elevation effects have been included in your wind
            speed, please select 0 ft as the project site elevation.
            </div>
          </div>
          <div>2. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0-80 psf (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Risk category: I or II</div>
          </div>
          <div>3. Seismic Design</div>
          <div className="text second-level">
            <div>a. Site class D</div>
          </div>
          <div>4. Loads &amp; combinations of loads</div>
          <div className="text second-level">
            <div>a. Self weight, wind, snow, seismic, ice, wind on ice are considered in design of structure.</div>
            <div>b. Load combinations for allowable stress design: Clause: 2.4 (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
          </div>
          <div>5. Assumed Foundation capacities</div>
          <div className="text second-level">
            <div>a. Lateral bearing capacity: 300 psf/ft</div>
            <div>b. Vertical bearing capacity: 1500 psf</div>
          </div>
        </div>

        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          <i>
            Conditions listed below are beyond the current capabilities of U-Builder. Site specific engineering is required.
          </i>
          <div>1. Any deviations from default/ assumed design parameters</div>
          <div>2. The following design criteria is excluded from the racking and Foundation design: flood loading, debris loading, dynamic analysis,
          acts of god (tornado, hurricane, water inundation loading, etc.), erosion, expansive soils, frost heave, soil liquefaction, dynamic loading
          from seismic events and conditions.
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div>*Please contact <a href="mailto:commercialservices@unirac.com">commercialservices@unirac.com</a> for additional information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderULA() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          ULA U-BUILDER PRODUCT ASSUMPTIONS
        </div>

        <div className="assumptions-sub-header">
          ULA - Unirac Large Array
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or building design for this
            project.
          </i>
        </div>

        <div className="assumptions-sub-header">
          <u>Default Dimensions</u>
        </div>
        <div className="text first-level">
          <div>1. Tilt Angle: 0- 45 deg</div>
          <div>2. Height from ground to top module on lowest edge: 1.50 ft to 5.00 ft</div>
          <div>3. Clear gap between modules in North-South &amp; East-West direction</div>
          <div className="text second-level">
            <div>a. Pro series clamps: 1.00"</div>
            <div>b. Standard clamps: 0.25"</div>
            <div>c. Universal AF clamps: 0.5"</div>
          </div>
          <div>4. Foundation diameter for concrete pile: 12" to 36"</div>
          <div>5. Foundation length for ground screw: 160 cm or 200 cm</div>
          <div>6. Ballast block foundation dimensions :
            <div className="text second-level">
              <div>Length - 3 ft</div>
              <div>Width - 3 ft</div>
              <div>Height - 2 ft</div>
            </div>
          </div>
        </div>

        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Wind Design</div>
          <div className="text second-level">
            <div>a. Basic Wind Speed: 85.00 mph - 165.00 mph (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>b. Risk category: I to IV</div>
            <div>c. Exposure: B or C (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
            <div>d. 50-year Design Life</div>
            <div>e. Elevation: Insertion of the project at - grade elevation can result in a reduction of wind pressure. If your project is in a special
            case study region or in an area where wind studies have been performed, please verify with your jurisdiction to ensure that
            elevation effects have not already been factored into the wind speed. If elevation effects have been included in your wind
            speed, please select 0 ft as the project site elevation.
            </div>
          </div>
          <div>2. Snow Design</div>
          <div className="text second-level">
            <div>a. Ground Snow Load: 0-100 psf (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
          </div>
          <div>3. Seismic Design</div>
          <div className="text second-level">
            <div>a. Site class D</div>
          </div>
          <div>4. Loads &amp; Combinations of Loads</div>
          <div className="text second-level">
            <div>a. Self weight, wind, snow, seismic, ice, wind on ice are considered in design of structure.</div>
            <div>b. Load combinations for allowable stress design: Clause: 2.4 (ASCE 7-10/ASCE 7-16/ASCE 7-22)</div>
          </div>
          <div>5. Assumed Foundation Capacities</div>
          <div className="text second-level">
            <div>For Concrete Pile:</div>
            <div className="text third-level">
              <div>a. Lateral bearing capacity: 200 psf/ft</div>
              <div>b. Vertical bearing capacity: 1500 psf</div>
            </div>
            <div>For Ground Screw:</div>
            <div className="text third-level">
              <div>a. Tension: 1520 lbs</div>
              <div>b. Compression: 2200 lbs</div>
              <div>c. Shear: 1600 lbs</div>
            </div>
            <div>For Ballast Block:</div>
            <div className="text third-level">
              <div>a. Vertical Pressure: 1500 psf</div>
            </div>
          </div>
        </div>

        <div className="assumptions-sub-header">
          <u>Site Specific Engineering</u>
        </div>
        <div className="text first-level">
          <i>
            Conditions listed below are beyond the current capabilities of U-Builder. Site specific engineering is required.
          </i>
          <div>1. Any deviations from default/ assumed design parameters</div>
          <div>2. The following design criteria is excluded from the racking and Foundation design: flood loading, debris loading, dynamic analysis,
          acts of god (tornado, hurricane, water inundation loading, etc.), erosion, expansive soils, frost heave, soil liquefaction, dynamic loading
          from seismic events and conditions.
          </div>
          <div className="notes">
            <br />
            <div>Notes:</div>
            <div className="text second-level">
              <div>*Please contact <a href="mailto:commercialservices@unirac.com">commercialservices@unirac.com</a> for additional information.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAscender() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          ASCENDER U-BUILDER PRODUCT ASSUMPTIONS
        </div>

        <div className="assumptions-sub-header">
          ASCENDER
        </div>
        <div className="text first-level">
          <i>
          Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your speciﬁc project.
          Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or building design for this project.
          </i>
        </div>
        <div className="assumptions-sub-header">
          <u>Design Parameters</u>
        </div>
        <div className="text first-level">
          <div>1. Racking dead load is considered as 150 pounds or 7.2 kPa for dead load calculation (average for a 2 x 6 structure)</div>
          <div>2. Risk Category I to IV</div>
          <div>3. Wind Design</div>
          <div className="text second-level">
            <div>a. Basic Wind Speed: 85-142 mph (IBC 2018/ASCE 7-16)</div>
            <div>b. Exposure: B, C or D</div>
            <div>c. 25 year Design Life/50 year Design Life for ASCE 7-16</div>
            <div>d. Elevation: Insertion of the project at - grade elevation can result in a reduction of wind
                        pressure. If your project is in a special case study region or in an area where wind studies
                        have been performed, please verify with your jurisdiction to ensure that elevation effects have
                        not already been factored into the wind speed. If elevation effects have been included in your
                        wind speed, please select 0 ft as the project site elevation.
            </div>
            <div>e. Minimum of 16 psf of downforce or uplift is considered in the design </div>
            <div>f. Topographic Factor (K<sub>zt</sub>) = 1</div>
            <div>g. Wind directionality factor (K<sub>d</sub>) = 0.85</div>
            <div>h. Gust effect factor (G) = 0.85</div>
            <div>i. For calculation of upward pressure, all modules on the roof are considered as exposed</div>
          </div>
          <div>4. Seismic Design</div>
          <div className="text second-level">
            <div>a. Seismic Site Class: A, B, C, or D (ASCE 7-05/ASCE 7-10/ASCE 7-16)</div>
            <div>b. Importance Factor Array (l<sub>p</sub>): 1.5</div>
            <div>c. Long period transition period, T<sub>L</sub>(assumed): 4</div>
          </div>
        </div>


        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Building Height ≤ 18 m unless otherwise speciﬁed</div>
          <div>2. Maximum length and width of a building is 60 m</div>
          <div>3. This product is applicable for roof slopes &lt; 7&#xb0;</div>
          <div>4. Maximum parapet height is 0.9 m</div>
        </div>

        <div className="assumptions-sub-header">
          <u>Module Details</u>
        </div>
        <div className="text first-level">
          <div>1. Minimum gap between modules is 0.25’’</div>
          <div>2. Module tilt is limited to 10°-20° for 2-Portrait structure and 10°-25° for 1-Portrait structure</div>
          <div>3. Module orientation is always considered in portrait mode</div>
          <div>4. Maximum PV Module Length: 85’’</div>
          <div>5. Mounting position is L/4, where L represents module length</div>
          <div>6. Minimum and Maximum length of module allowed: 195.5 to 238.5 cm</div>
          <div>7. Minimum and Maximum width of module allowed: 99 cm to 130.4 cm</div>
        </div>


        <div className="assumptions-sub-header">
          <u>Other Details</u>
        </div>
        <div className="text first-level">
          <div>1. Table row spacing = 30 cm </div>
          <div>2. Low edge to roof clearance is as follows: 10.5 to 50.8 cm</div>
          <div>3. Rail length for thermal gap is considered as per installation manual</div>
        </div>


      </div>
    );
  }

  renderNXT_UMOUNT() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          NXT UMOUNT U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          NXT UMOUNT
        </div>
        <div className="text first-level">
          <i>
            Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
            Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or building design for this
            project.
          </i>
        </div>

        <div className="assumptions-sub-header">
          <u>Building Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Building Risk Category II for ASCE 7-10, and I, II, III or IV for ASCE 7-16/ASCE 7-22.</div>
          <div>2. Mean Roof Height = 0 – 60 ft unless otherwise specified.</div>
          <div>3. Roof Pitch: 5° - 45° for ASCE 7-10 and 9° - 45° for ASCE 7-16 and ASCE 7-22</div>
        </div>

        <div className="assumptions-sub-header">
          <u>Wind Design Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Exposure Category B, C, or D.</div>
          <div>2. Basic Wind Speed 95 – 190 mph for ASCE 7-10, and 85-190 mph for ASCE 7-16 and ASCE 7-22.</div>
          <div>3. Wind speeds are LRFD values</div>
          <div>4. γE = 1.0 for ‘Interior’ spans, γE = 1.5 for ‘Exposed’ spans (ASCE 7-16 and ASCE 7-22 Sec. 29.4.3, Fig. 29.4-7)</div>
        </div>
        
        <div className="assumptions-sub-header">
          <u>Snow and Seismic Design Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Ground Snow Load = 0-100 psf</div>
          <div>2. Thermal Factor = 1.0</div>
          <div>3. Results based on uniform snow load</div>
          <div>4. 0.2-s Spectral Response Acceleration, SS ≤ 3</div>
        </div>

        <div className="assumptions-sub-header">
          <u>Array Assumptions</u>
        </div>
        <div className="text first-level">
          <div>1. Dead Load≤ 5 psf (including PV Modules and Racking)</div>
          <div>2. Module orientation and Rail direction per user selection</div>
          <div>3. Module height above roof surface 5 ” to 10 ” depending on attachment type</div>
          <div>4. Gaps between module rows and columns is 0.25”-1.0”</div>
          <div>5. Modules are parallel to the roof surface and within 10 in. of it.</div>
          <div>6. ‘Exposed’ spans shall be used when any part of an exposed module or panel as is attributed to that span.</div>
          <div>7. The most restrictive of all roof zone spans shall be used when any part of the module is attributed to that span. </div>
          <br />
          <div>Intermediary Span Distances: Roof attachment point loads be reduced linearly if the installed distance between the attachments is less
            than the selected span. For example, if the spacing is half the amount shown in the results, then the point loads may also be reduced by 
            half.
            Spans greater than 72 in.: While our products are valid for greater spans in many instances, we do not recommend this mounting
            configuration. Typical residential roofs are not designed for the larger point loads that might result from such excessive spans and we
            recommend final design by a licensed professional engineer of responsible charge.
          </div>
        </div>    
      </div>
    );
  }

  renderMETAL_X() {
    return (
      <div className="enigneering-product-assumptions">
        <div className="assumptions-main-header main-title">
          METAL X U-BUILDER PRODUCT ASSUMPTIONS
        </div>
        <div className="assumptions-sub-header">
          METAL X
        </div>
        <div className="text first-level">
          <i>
          Limitations of Responsibility: It is the user’s responsibility to ensure that inputs are correct for your specific project.
          Unirac is not the solar, electrical, or building engineer of record and is not responsible for the solar, electrical, or
          building design for this project. 
          </i>
        </div>

        <div className="assumptions-sub-header">
          <u>Allowable spans and resulting point loads are subject to the following conditions:</u>
        </div>
        <div className="text first-level">
          <div>1. Building Height ≤ 60 ft unless otherwise specified.</div>
          <div>2. Roof Slope ≥ 1.2°</div>
          <div>3. Maximum rail cantilever ≤ 1/3 of selected span.</div>
          <div>4. Occupancy/Risk Category = II unless otherwise specified.</div>
          <div>5. ASCE 7-10: Basic Wind Speed: 95-190 mph (IBC 2012/ASCE 7-10). Wind Exposure: B, C or D.</div>
          <div>6. ASCE 7-16: Basic Wind Speed: 85-190 mph (IBC 2018/ASCE 7-16). Wind Exposure: B, C or D.</div>
          <div>7. Ground Snow Load: 0-100 psf. (Reduction can be input if this is acceptable with your local building authority). Results are based on
                  uniform snow loading and do not consider unbalanced, drifting, and sliding conditions.</div>
          <div>8. Dead Load ≤ 5 psf (includes PV Modules and Racking).</div>
          <div>9. Maximum PV Module Length: 85 in.</div>
          <div>10. Seismic: Installations must be in seismic site class A, B, C, or D as defined in IBC 2015/ASCE 7-10.</div>
          <div>11. Intermediary Span Distances: Roof attachment point loads be reduced linearly if the installed distance between the attachments is less
                  than the selected span. For example, if the spacing is half the amount shown in the results, then the point loads may also be reduced by
                  half.</div>
          <div>12. Spans greater than 96 in.: While our products are valid for greater spans in many instances, we do not recommend this mounting
                   configuration. Typical residential roofs are not designed for the larger point loads that might result from such excessive spans and we
                   recommend final design by a licensed professional engineer of responsible charge.
          </div>
        </div>

        <div className="assumptions-sub-header">
          <u>Pitched Roof Solutions (Flush Mounted Solutions):</u>
        </div>
        <div className="text first-level">
          <div>Modules are parallel to the roof surface and within 10 in. of it.</div>
          <div>Allowable roof pitch: 1.2° - 45°.</div>
          <div>Building has a monoslope roof with a slope ≤ 3°, a gable roof ≤ 45°, or a hip roof ≤ 27°.</div>
        </div>
        <br />
        <br />
        <br />   
      </div>
    );
  }

  render() {

      return (
        <>
          {this.renderSM()}
          <div className="section">{this.renderSMTiltPR()}</div>
          <div className="section">{this.renderAscender()}</div>
          <div className="section">{this.renderRM10()}</div>
          <div className="section">{this.renderRM10EVO()}</div>
          <div className="section">{this.renderGridflex()}</div>
          <div className="section">{this.renderEcoFoot2plus()}</div>
          <div className="section">{this.renderRM5()}</div>
          <div className="section">{this.renderRMDT()}</div>
          <div className="section">{this.renderGFT()}</div>
          <div className="section">{this.renderULA()}</div>
          <div className="section">{this.renderNXT_UMOUNT()}</div>
          <div className="section">{this.renderMETAL_X()}</div>
        </>
      );
    }
}
