import { dispatch, state } from '__common/store';
import { FETCH_MFGS_AND_MODELS_REQUEST } from 'actions';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';

export function setDefaulPanelModel(productId: string) {
  const { userPreferences: { module, model }, projectConfiguration: {projectVersion} } = state();
  if (module && model) {
    dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, module, model, undefined, projectVersion));
  } else {
    dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, 184, 2175, undefined, projectVersion));
  }
}

export function isSingleOrientationInProject() {
  const { drawingManager: { roofs } } = state();
  return (
    roofs &&
    Object.keys(roofs).length > 0 &&
    Object.keys(roofs).every(id => {
      const firstRoofId = Object.keys(roofs)[0];
      return !roofs[id] || !roofs[id].panels || !roofs[id].panels.length || roofs[id].panels.reduce((acc: boolean, panel) => {
        const isHorizontal = roofs[firstRoofId]?.panels?.length && roofs[firstRoofId].panels[0].width > roofs[firstRoofId].panels[0].height;
        const isHoriz = panel.width > panel.height;

        if (isHorizontal === isHoriz) {
          return true;
        }

        return false;
      },                                                                                            true);
    })
  );
}

export const shouldConfirmModelChange = () => {
  const { roofsSelector: { mapType } } = state();

  if (mapType === 'white' && isSingleOrientationInProject()) {
    return false;
  }


  if (!anyPanelsDrawn()) {
    return false;
  }

  return true;
};

export const isModuleSelectorValid = () => {
  const { moduleSelector: { modelDataError } } = state();

  return !modelDataError;
};

export const isModuleLoaded = () => {
  const { moduleSelector: { mfgsReady, modelsReady } } = state();
  return mfgsReady && modelsReady;
};
