export const apiField = 'foundation_diameter';

const config = {
  type: 'Select',
  apiField,
  label: 'foundation_diameter',
  data: [
    { 
      value: 1.5,
      name: '1.5',
    },
    {
      value: 2,
      name: '2',
    },
  ],
};

export default config;
