export const apiField = 'seismic_ss';

export interface SeismicSsField {
  type: string;
  label: string;
  apiField: string;
  tooltip: () => string;
  missing?: any;
  min?: number; 
}
