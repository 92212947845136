import React from 'react';
import RailsTypeChanger from 'projectDesign/components/railsTypeChanger/railsTypeChanger';
import { buildingHeightGreaterThan72WarningCondition, buildingStoreyWarningCondition, missingProductConfigurationPageValid, projectConfigurationWarningConditionValid, attachmentWarningConditionForRiskCategoryIV, roofSlopeWarningCondition } from '../utils/validation';
import { renderInputs } from './renderInputs';
import { getBuildingRackingOptionsTooltipContent } from '__common/components/Drawer/utils/getDrawerTooltipsContent';
import { missingPageWarning } from './components/missingPageWarning';
import { projectConfigurationWarning } from './components/projectConfigurationWarning';
import { SelectRadios} from '../utils/updateProjectOption';
import { isGroundProduct, isNxtTilt, isRM10orRM10Evo, isRM5, isRMGridFlex, isRmGridflex10, isSMTilt } from '__common/constants/products';
import { buildingHeightGreaterThan72Warning, roofSlopeWarning, buildingStoreyWarning, riskCategoryWarning } from './components/buildingHeightWarning';
import { isASCE716or722 } from '__common/constants/buildingCodes';
import { adjustableTiltSystem, applyRM5Revamp } from '__common/utils/versionCompare/versionCompare';
import { dispatch, state } from '__common/store';
import { RAIL_SYSTEM_PAGE } from '../utils/projectConfigurationDrawerPages';
import { SET_FLYOUT_VERIFIED } from '../projectConfigurationActions';

export const ProductConfigurationPage = (
  currentConfig: Function,
  productId: number,
  initData: projectEnvConfig,
  mapType: string,
  location: string,
  projectVersion: string,
  getErrorMessageIfInvalid: (fieldName: string) => string | null,
  getWarningMessage: (fieldName: string) => string | null,
) => {
  const buildingCode = initData['building_code'];
  const config = currentConfig(buildingCode);
  const fields = [
    config.BuildingDescription?.fields,
    config.Spans?.fields,
    config.BlockWeight?.fields,
    config.centralSupportForRM?.fields,
    config.roofPadsEverywhere?.fields,
    config.useFriction?.fields,
    config.DownPointLoad?.fields,
    config.AttachmentInputs?.fields,
  ];
  const { projectConfiguration: { verifiedFlyouts, formState: { invalidFields }, projectEnvConfig: { is_aurora_project } } } = state();
  const missing = fields.reduce((acc,x) => [...acc, ...x ?? []], []).some(({missing}) => missing);
  if(!invalidFields.length){
    if(missing && !verifiedFlyouts[RAIL_SYSTEM_PAGE].error){
      dispatch(SET_FLYOUT_VERIFIED(RAIL_SYSTEM_PAGE, false, true));
    } else if(!missing && verifiedFlyouts[RAIL_SYSTEM_PAGE].error){
      dispatch(SET_FLYOUT_VERIFIED(RAIL_SYSTEM_PAGE, false, false));
    }
  }
  return {
    content: (
      <>
        {is_aurora_project && (<div className="warning-message-aurora" >The greyed fields are read-only and cannot be edited in the Ubuilder.</div>)}
        <div className="drawer-section-title">
          { (isSMTilt(productId) || isNxtTilt(productId)) && adjustableTiltSystem(projectVersion) ? 'Racking and Building Options' :`${isGroundProduct(productId) ? '' : 'Building and '}Racking Options`}
        </div>
        {missingProductConfigurationPageValid(productId)
          ? null
          : missingPageWarning('racking-page-input-warning')}
        <div className={`project-configuration-component drawer-fields`}>
          {(isSMTilt(productId) || isNxtTilt(productId)) && adjustableTiltSystem(projectVersion) ?
          (config.railFinish ||
            config.railDirection ||
            config.BlockWeight) && (
              <div className="drawer-section-subtitle">Racking options</div>

            ) : (isGroundProduct(productId) ||
              (<div className="drawer-section-subtitle">
                Building and roof description
              </div>
              ))}
          {(isSMTilt(productId) || isNxtTilt(productId))&& adjustableTiltSystem(projectVersion) ? (<>{
            <>
            {/* {config.railFinish && renderInputs(config.railFinish, mapType, location, initData, productId, getErrorMessageIfInvalid)} */}
            {config.railDirection && renderInputs(config.railDirection, mapType, location, initData, productId, getErrorMessageIfInvalid)}
            {config.Spans && <div>{renderInputs(config.Spans, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>}
            {config.railFinish && renderInputs(config.railFinish, mapType, location, initData, productId, getErrorMessageIfInvalid)}
            {config.railTypes && (
            <RailsTypeChanger productId={productId} />
              )}
          </>
        }</>): 
        <div>{renderInputs(config.BuildingDescription, mapType, location, initData, productId, getErrorMessageIfInvalid, getWarningMessage)}</div>
        }
         <br/>
        {(isSMTilt(productId) || isNxtTilt(productId))&& adjustableTiltSystem(projectVersion) ?
                (isGroundProduct(productId) ||
                (<div className="drawer-section-subtitle">
                  Building and roof description
                </div>
                ))
                  : (config.railFinish ||
                    config.railDirection ||
                    config.BlockWeight) && (
                      <div className="drawer-section-subtitle">Racking options</div>

                    )}
          {(isSMTilt(productId) || isNxtTilt(productId))&& adjustableTiltSystem(projectVersion) ?
            <div>{renderInputs(config.BuildingDescription, mapType, location, initData, productId, getErrorMessageIfInvalid, getWarningMessage)}</div>
            : (<>{
            <>
            {config.railFinish && renderInputs(config.railFinish, mapType, location, initData, productId, getErrorMessageIfInvalid)}
            {config.railDirection && renderInputs(config.railDirection, mapType, location, initData, productId, getErrorMessageIfInvalid)}
            {config.Spans && <div>{renderInputs(config.Spans, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>}
            {config.railTypes && (
            <RailsTypeChanger productId={productId} />
            )}
            </>
            }</>)
          }
          <br/>
          {config.BlockWeight && (
            <div>{renderInputs(config.BlockWeight, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
          )}
          {config.mandatorymidsupport && (
            <div>{renderInputs(config.mandatorymidsupport, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
          )}
          {config.centralSupportForRM && (
            <div>{renderInputs(config.centralSupportForRM, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
          )}

          {config.useFriction && (
            <div>{renderInputs(config.useFriction, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
          )} 

          {config.roofPadsEverywhere && (
            <div>{renderInputs(config.roofPadsEverywhere, mapType, location, initData, productId, getErrorMessageIfInvalid)}</div>
          )}    

          {config.DownPointLoad && (
            <div>
              <div className="drawer-section-subtitle">Down Point Load</div>
              {renderInputs(config.DownPointLoad, mapType, location, initData, productId, getErrorMessageIfInvalid)}
            </div>
          )}
          {config.AttachmentInputs && (
            <div>
              <div className="drawer-section-subtitle">Attachment Inputs</div>
              {renderInputs(config.AttachmentInputs, mapType, location, initData, productId, getErrorMessageIfInvalid, getWarningMessage)}
            </div>
          )}
        </div>
        {(isRM5(productId) || isRM10orRM10Evo(productId) || isRMGridFlex(productId)|| isRmGridflex10(productId)) && isASCE716or722(buildingCode) ?
          <>
            {buildingHeightGreaterThan72WarningCondition() ? buildingHeightGreaterThan72Warning() : null}
            {roofSlopeWarningCondition() ? roofSlopeWarning() : null}
            {buildingStoreyWarningCondition() ? buildingStoreyWarning() : null}
          </> : null}
        {(isRM10orRM10Evo(productId) || applyRM5Revamp(projectVersion) || isRMGridFlex(productId) || isRmGridflex10(productId)) && isASCE716or722(buildingCode) && attachmentWarningConditionForRiskCategoryIV() ? riskCategoryWarning : null}
        {projectConfigurationWarningConditionValid(productId) ? projectConfigurationWarning(): null}
      </>
    ),
    tooltip: getBuildingRackingOptionsTooltipContent(productId),
    postRender: SelectRadios,
  };
};
