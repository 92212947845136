export const apiField = 'long_transition_periods_tl';

interface LongTransitionPeriodsField {
  type: string;
  label: string;
  apiField: string;
  tooltip: () => string;
  missing?: any;
  min?: number;
  max?: number;
}

const config: LongTransitionPeriodsField = {
  type: 'Number',
  label: 'long_transition_periods_tl',
  apiField,
  tooltip: () => "The corner period that marks the transition from the constant velocity to the constant displacement segments of the design response spectrum.",
  missing: undefined,
  min: 4,
  max: 16,
};

export default config;
