import { isRM10, isRMFamily, isRMGridFlex, getProductName, isAscender, isRM5, isRmGridflex10 } from '__common/constants/products';
import { inchesToFeet } from '__common/calculations/inchesToFeet';
import { _length_range, _module_area_map, _module_area_map_metric, _version_new_area_map, _width_range } from '__common/constants/modules';
import { baseVersionAvailableForAProduct, greaterThanEqualToProjectVersion } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
import { cmsToInches, inchesToCms } from '__common/calculations/unitConversions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { dispatch, state } from '__common/store';import { SET_FLYOUT_VERIFIED } from 'actions';
import { MODULE_SELECTION_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';


const getWidthRange = (productId, isStaffUser = false): { minWidth: number, maxWidth: number } => {

  let version, product;

  const {projectConfiguration : {projectVersion}} = state();
  
  Object.keys(_width_range).some(v => v == getProductName(productId)) ? product = getProductName(productId) : product =  'other';

  version = baseVersionAvailableForAProduct(product, projectVersion, 'width');

  if(isStaffUser && isRM10(productId)) {
    return {
      minWidth: _width_range[product][version].minWidth,
      maxWidth: _width_range[product][version].maxWidthStaff,
    }
  }
  else {
    return {
      minWidth: _width_range[product][version].minWidth,
      maxWidth: _width_range[product][version].maxWidth,
    };
  }

};

export const validateWidth = (widthInches: number, productId: number, isStaffUser = false, inputUnit: number): string => {

  let { minWidth, maxWidth } = getWidthRange(productId, isStaffUser);

  if(isMetricUnit(inputUnit)) {
    minWidth = inchesToCms(minWidth);
    maxWidth = inchesToCms(maxWidth);
  }

  if (minWidth>=0) {
    const isModuleSizeInRange = _isInRange(minWidth, maxWidth, true);
    if (!isModuleSizeInRange(widthInches)) {
      if(maxWidth){
        return `module width should be in range of ${minWidth}-${maxWidth} (${isAscender(productId) || isMetricUnit(inputUnit) ? 'cm' : 'in'})`;
      }
      return `min module width should be ${minWidth} (${isAscender(productId) || isMetricUnit(inputUnit) ? 'cm': 'in'})`;
    }
  }
  
  return '';
};

const getLengthRange = (productId): { minLength: number, maxLength: number } => {
  let version, product;
  const {projectConfiguration : {projectVersion}} = state();

  Object.keys(_length_range).some(v => v == getProductName(productId)) ? product = getProductName(productId) : product =  'other';

  version = baseVersionAvailableForAProduct(product, projectVersion, 'length');

  return {
    minLength: _length_range[product][version].minLength,
    maxLength: _length_range[product][version].maxLength,
  };

};

export const validateLength = (lengthInches: number, productId: number, inputUnit: number): string => {

  let { minLength, maxLength } = getLengthRange(productId);

  if(isMetricUnit(inputUnit)) {
    minLength = inchesToCms(minLength);
    maxLength = inchesToCms(maxLength);
  }

  if (minLength >= 0) {
    if (isRmGridflex10(productId)){
      if ((lengthInches >= 71 && lengthInches <= 100)) {
        return '';
      } else {
        return `module length should be in range of 71-100 in`;
      }
    }
    const isLengthInRange = _isInRange(minLength, maxLength, true);
    if (!isLengthInRange(lengthInches)) {
      if(maxLength){
          return`module length should be in range of ${minLength}-${maxLength} (${isAscender(productId) || isMetricUnit(inputUnit) ? 'cm' : 'in'})`;
        }
        return `min module length should be ${minLength} (${isAscender(productId) || isMetricUnit(inputUnit) ? 'cm' : 'in'})`;
      }
    if(isMetricUnit(inputUnit)) {
      lengthInches = cmsToInches(lengthInches);
    }
    if (isRM5(productId)){
      const {projectConfiguration: {projectVersion}} = state();
      var rm5ValidationLengthLimit = greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.removed_25_yrs_mri_option_in_ef2) ? 98 : 92;
      if(lengthInches > rm5ValidationLengthLimit){
        return `module length should be <= ${rm5ValidationLengthLimit} in`;
      }
    }
  }
  return '';
};

export const validateModuleSize = (widthInches: number, heightInches: number, productId: number, isStaffUser = false, inputUnit: number): string => {

  if (isMetricUnit(inputUnit)) {
    widthInches = cmsToInches(widthInches);
    heightInches = cmsToInches(heightInches);
  }

  const { projectConfiguration: { projectVersion } } = state();

  const areaInFeets = inchesToFeet(widthInches) * inchesToFeet(heightInches);

  const product = getProductName(productId);

  let newChecktobeApplied = false;

  if (greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.module_length_width_validation) && widthInches > heightInches) {
    return 'Module length should be greater than module width';
  }
  
  if ( 
    (greaterThanEqualToProjectVersion(projectVersion, '0.0.3') && _version_new_area_map['0.0.3'].indexOf(product) >= 0) 
    || 
    (greaterThanEqualToProjectVersion(projectVersion, '0.0.2') && _version_new_area_map['0.0.2'].indexOf(product) >= 0)
  ) {
    newChecktobeApplied = true;
  }

  if (newChecktobeApplied) {

    const check1 = _isInRangeCustom(17, 23.5, true, true)(areaInFeets)
    const check2 = _isInRangeCustom(37, 42, true, false)(widthInches) && _isInRangeCustom(23.5, 32, false, true)(areaInFeets);
    const check3 = _isInRangeCustom(42, 53, true, true)(widthInches) && _isInRangeCustom(23.5, 34, false, true)(areaInFeets)

    if (!check1 && !check2 && !check3) {
      dispatch(SET_FLYOUT_VERIFIED(MODULE_SELECTION_PAGE, false, true));
      if (isRmGridflex10(productId)){
        if (isMetricUnit(inputUnit)) return `Module area should be in range of ${_module_area_map_metric['new'].minArea}-${_module_area_map_metric['new'].maxArea} (m<sup>2</sup>).`;
      return `Module area should be in range of ${_module_area_map['new'].minArea}-${_module_area_map['new'].maxArea} (ft<sup>2</sup>).`;

      }
      if (isMetricUnit(inputUnit)) return `Module area should be in range of ${_module_area_map_metric['new'].minArea}-${_module_area_map_metric['new'].maxArea} (m<sup>2</sup>). Module width should not exceed 134.6cm.`;
      return `Module area should be in range of ${_module_area_map['new'].minArea}-${_module_area_map['new'].maxArea} (ft<sup>2</sup>). Module width should not exceed 53".`;
    }
  }
  else {
    if (isRMFamily(productId) && !isRMGridFlex(productId)) {
      const isAreaInRange = _isInRange(_module_area_map['old'].minArea, _module_area_map['old'].maxArea);

      const widthInCms = inchesToCms(widthInches)
      const widthError = isMetricUnit(inputUnit) ? validateWidth(widthInCms, productId, isStaffUser, inputUnit) : validateWidth(widthInches, productId, isStaffUser, inputUnit);

      if (widthError.length > 0) {
        return widthError;
      }

      if (widthInches < 37 && areaInFeets > 23.5) {
        if (isMetricUnit(inputUnit)) return `for narrow modules (<93.98cm) area should be below 2.18 (m<sup>2</sup>)`;
        return `for narrow modules (<37in) area should be below 23.5 (ft<sup>2</sup>)`;
      }

      if (!isRM10(productId) && widthInches > 42 && areaInFeets > 23.5) {
        if (isMetricUnit(inputUnit)) return `for wide modules (>106.68cm) area should be below 2.18 (m<sup>2</sup>)`;
        return `for wide modules (>42in) area should be below 23.5 (ft<sup>2</sup>)`;
      }

      if (!isAreaInRange(areaInFeets)) {
        if (isMetricUnit(inputUnit)) return `module area should be in range of ${_module_area_map_metric['old'].minArea}-${_module_area_map_metric['old'].maxArea} (m<sup>2</sup>)`;
        return `module area should be in range of ${_module_area_map['old'].minArea}-${_module_area_map['old'].maxArea} (ft<sup>2</sup>)`;
      }
    }
  }

  return '';
};

export const validateWeight = (weight: number) => {

  if (weight <= 0) {
    return 'module weight should be greater than 0';
  }

  return '';
}


export const _isInRange = (min: number, max: number, inclusive = false) => (value: number) => {
  if (inclusive) {
    if (max){
      return value >= min && value <= max;
    }
    return value >= min;
  }
  if (max){
    return value > min && value < max;
  }
  return value > min;
};

export const _isInRangeCustom = (min: number, max: number, leftInclusive = false, rightInclusive = false) => (value: number) => {
  if (leftInclusive && rightInclusive) {
    return value >= min && value <= max;
  }
  else if (leftInclusive) {
    return value >= min && value < max;
  }
  else if (rightInclusive) {
    return value > min && value <= max;
  }
  return value > min && value < max;
};

export const _inRange = (min: number, value: number, max: number) => {
  if (value > max) {
    return max;
  }

  if (value < min) {
    return min;
  }

  return value;
};


export const validateWatt = (wattNumber: number) => {

  if (wattNumber <= 0) {
    return `Output should be greater than 0`;
  }
  return ''
}

