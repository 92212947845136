import { ChangePasswordActionTypes } from 'actionsConstants';

export function CHANGE_PASSWORD(old_password: string, new_password: string, password_repeat: string) {
  return {
    type: ChangePasswordActionTypes.CHANGE_PASSWORD,
    payload: {
      old_password, 
      new_password, 
      password_repeat,
    },
  };
}

export function CHANGE_PASSWORD_SUCCESS() {
  return {
    type: ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS,
  };
}

export function CHANGE_PASSWORD_ERROR() {
  return {
    type: ChangePasswordActionTypes.CHANGE_PASSWORD_ERROR,
  };
}

export function CHANGE_PASSWORD_CLEAR() {
  return {
    type: ChangePasswordActionTypes.CHANGE_PASSWORD_CLEAR,
  };
}
