import { ObstructionsActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';

export const ObstructionsState: obstructionsState = {
  obstructions: {},
  editingObstructionId: null,
};

export default {
  obstructions: createReducer(ObstructionsState, {
    [ObstructionsActionTypes.ADD_OBSTRUCTION](state, action: { payload: { roofId: number, obstructionId: number, name: string, length: number, width: number, coords: { x: number, y: number }[], isLargeEnough: boolean, metersPerPixel : number} }) {
      const { roofId, coords, obstructionId, name, length, width, isLargeEnough, metersPerPixel } = action.payload;

      if (!state.obstructions[roofId]) {
        state.obstructions[roofId] = {};
      }

      if (!state.obstructions[roofId][obstructionId]) {
        state.obstructions[roofId][obstructionId] = {};
      }

      state.obstructions[roofId][obstructionId] = {
        coords,
        name,
        height: 0,
        setback: 0,
        length,
        width,
        isLargeEnough,
        metersPerPixel
      };

      return { ...state };
    },
    [ObstructionsActionTypes.ADD_OBSTRUCTIONS](state, action: { payload: { roofId: number, obstructions: roofObstructions } }) {
      const { roofId, obstructions } = action.payload;

      state.obstructions[roofId] = obstructions;

      return { ...state };
    },
    [ObstructionsActionTypes.REMOVE_OBSTRUCTION](state, action: { payload: { roofId: number, obstructionId: number } }) {
      const { obstructions } = state;
      const { payload: { roofId, obstructionId } } = action;

      if (obstructions[roofId] && obstructions[roofId][obstructionId]) {
        delete state.obstructions[roofId][obstructionId];
      }

      if (obstructions[roofId] && Object.keys(obstructions[roofId]).length === 0) {
        delete state.obstructions[roofId];
      }

      return { ...state };
    },
    [ObstructionsActionTypes.REMOVE_ROOF_OBSTRUCTIONS](state, action: { payload: { roofId: number } }) {
      const { obstructions } = state;
      const { payload: { roofId } } = action;

      if (obstructions[roofId]) {
        delete obstructions[roofId];
      }

      return { ...state };
    },
    [ObstructionsActionTypes.CHANGE_OBSTRUCTION_NAME](state, action: { payload: { roofId: number, obstructionId: number, name: string } }) {
      const { obstructions } = state;
      const { payload: { roofId, obstructionId, name } } = action;

      if (obstructions[roofId] && obstructions[roofId][obstructionId]) {
        state.obstructions[roofId][obstructionId].name = name;
      }

      return { ...state };
    },
    [ObstructionsActionTypes.CHANGE_OBSTRUCTION_HEIGHT](state, action: { payload: { roofId: number, obstructionId: number, height: string } }) {
      const { obstructions } = state;
      const { payload: { roofId, obstructionId, height } } = action;
      let newHeight = Number(height); 

      if (isNaN(newHeight)) {
        newHeight = 0;
      }

      let newSetback = newHeight;
      const { width, length } = state.obstructions[roofId][obstructionId];

      if (length && width) {
        newSetback = Math.min(0.5 * Math.sqrt(Math.pow(length, 2) + Math.pow(width, 2)), Number(height));
      } 
      
      if (obstructions[roofId] && obstructions[roofId][obstructionId]) {
        state.obstructions[roofId][obstructionId].height = newHeight;
        state.obstructions[roofId][obstructionId].setback = newSetback;
      }

      return { ...state };
    },
    [ObstructionsActionTypes.SET_EDITING_OBSTRUCTION_ID](state, action: { payload: { obstructionId: number | null } }) {
      const { obstructionId } = action.payload;
      return { ...state, editingObstructionId: obstructionId };
    },
    [ObstructionsActionTypes.CLEAR_OBSTRUCTIONS]() {
      return { obstructions: {}, editingObstructionId: null };
    },
    [ObstructionsActionTypes.CENTER_OBSTRUCTIONS_ON_ROOF](state: obstructionsState, action: { payload: { xOffset: number, yOffset: number, roofId: number } }) {
      const { xOffset, yOffset, roofId } = action.payload;
      
      const obstructions = state.obstructions;

      if (obstructions && Object.keys(obstructions).length) {
        
        const obstructionsOnRoof = obstructions[roofId];

        if (obstructionsOnRoof && Object.keys(obstructionsOnRoof).length) {
          const centeredObstructionsOnRoof = Object.keys(obstructionsOnRoof).reduce((acc, obstructionId) => {
            const obstruction: obstruction = obstructionsOnRoof[obstructionId];

            const centeredCords = obstruction.coords.map(coord => {
              return {
                x: coord.x + xOffset,
                y: coord.y + yOffset,
              };
            });

            obstruction.coords = centeredCords;
            acc[obstructionId] = obstruction;
            return acc;
          },                                                                        {});

          state.obstructions[roofId] = centeredObstructionsOnRoof;
        }
      }

      return { ...state };
    },
  }),
};
