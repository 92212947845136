import { asce_716, isASCE716 } from "__common/constants/buildingCodes";
import { isAscender, isSMTiltPR } from "__common/constants/products";
import { isASCE716or722BuildingCode } from "../projectConfiguration";

export const apiField = 'soil_class';

export enum SOIL_CLASS {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  D_DEFAULT = 5,
  E = 6,
  F = 7 ,
}

interface SoilClassField {
  type: string;
  apiField: string;
  label: string;
  data: SoilClassOption[];
  missing: boolean;
  tooltip: () => string;
}

interface SoilClassOption {
  value: number;
  name: string;
}

const getSoilClassOptions = (buildingCode = asce_716.value, productId?: number): SoilClassOption[] => {
  return [
    { value: SOIL_CLASS.A, name: 'A - HARD ROCK' },
    { value: SOIL_CLASS.B, name: 'B - ROCK' },
    { value: SOIL_CLASS.C, name: 'C - VERY DENSE SOIL AND SOFT ROCK' },
    { value: SOIL_CLASS.D, name: 'D - STIFF SOIL' },
    ...(isASCE716or722BuildingCode(buildingCode)  && !isAscender(productId)  && !isSMTiltPR(productId) ? [{ value: SOIL_CLASS.D_DEFAULT, name: `D - DEFAULT(SEE SECTION 11.4.3, ${isASCE716(buildingCode) ? 'ASCE 7-16' : 'ASCE 7-22'})` }]: []),
  ];
};

const config = (buildingCode = asce_716.value, productId?: number): SoilClassField => {
  return {
    type: 'Select',
    label: 'soilClass',
    apiField,
    data: getSoilClassOptions(buildingCode, productId),
    missing: undefined,
    tooltip: () => `If soil type is not known please select ${isASCE716or722BuildingCode(buildingCode) ? 'D - DEFAULT': 'D - STIFF SOIL'} `,
  };
};

export default config;
