
import { TiltedRoofActionTypes } from 'actionsConstants';

export function SET_TILTED_ROOF(roofPitch: string) {
  return {
    type: TiltedRoofActionTypes.SET_TILTED_ROOF,
    payload: {
      roofPitch,
    },
  };
}

export function SET_TILTED_ROOF_STRUCTURE_TILT(structureTilt: string){
  return {
    type: TiltedRoofActionTypes.SET_TILTED_ROOF_STRUCTURE_TILT,
    payload: {
      structureTilt,
    },
  };
}

export function CLEAR_TILTED_ROOF(productId: number, buildingCode: number, buildingType: number) {
  return {
    type: TiltedRoofActionTypes.CLEAR_TILTED_ROOF,
    payload: {
      productId,
      buildingCode,
      buildingType,
    },
  };
}

export function TOOGLE_ROOF_SCALING() {
  return {
    type: TiltedRoofActionTypes.TOOGLE_ROOF_SCALING,
  };
}

export function TOOGLE_BACKGROUND_SCALING() {
  return {
    type: TiltedRoofActionTypes.TOOGLE_BACKGROUND_SCALING,
  };
}

