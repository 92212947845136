import RBush from 'rbush';

export function getRTreeForExposure(panels: panelInState[]): rbush.RBush<rbush.BBox> {
  const tree = RBush();

  panels.forEach((panel) => {
    tree.insert({
      minX: panel.x - (panel.width / 2),
      maxX: panel.x + (panel.width / 2),
      minY: panel.y - (panel.height /2),
      maxY: panel.y + (panel.height / 2),
      id: panel.id,
      siblingId: panel.siblingId,
    });
  });

  return tree;
}
