import { getPanelTexture } from './getPanelTexture';

type textures = {
  [textureName: string]: PIXI.Texture,
};

let textures: textures = {};

export function getTexture(
  name: string, 
  width: number, 
  height: number, 
  roofZone: roofZoneNumber, 
  nearObstruction?: boolean, 
  exposed?: boolean, 
  exposedNeighbour?: boolean, 
  id?: number | string,
  attached: boolean = false,
  panelConfig: number = undefined,
  skewAffected?: number,
  ) {
  const isPortrair = height > width ? 'P' : 'L';
  const obs = nearObstruction ? 'yes' : 'no';
  const exp = exposed ? 'yes' : 'no';
  const textureName = `${name}${roofZone}${isPortrair}${obs}${exp}${id}${exposedNeighbour}${attached? attached: ''}${panelConfig?? ''}${skewAffected ? skewAffected : ''}`;
  
  if (textures[textureName]) return textures[textureName];

  if (name.includes('panel') || name.includes('cursor')) {
    textures[textureName] = getPanelTexture(width, height, roofZone, nearObstruction, exposed, exposedNeighbour, id, attached, panelConfig, skewAffected);
    return textures[textureName];
  }
}

export function clearTextures() {
  textures = {};
}



