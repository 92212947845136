export const apiField = 'topographical_factor_kzt';
export default {
  type: 'Number',
  label: 'topographicalFactorKzt',
  missing: undefined,
  apiField,
  tooltip: undefined,
  min: 1,
  max: 5,
};

