import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import Icon from '__common/components/Icon';
import Loader from '__common/components/LoaderComponent';
import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import { connect } from 'react-redux';
import {
  CLEAR_USER_PREFERENCES,
  DISABLE_ACCOUNT,
  DISABLE_ACCOUNT_CLEAR,
  logOut,
  } from 'actions';

type State = {
  selection: string,
};

type Props = {
  dispatch: Function,
  disableAccount: disableAccountState,
};

class DisableAccount extends React.Component<Props, State> {
  state = {
    selection: 'yes',
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(DISABLE_ACCOUNT_CLEAR());
  }

  disableAccountHandler = () => {
    const { selection } = this.state;

    if (selection === 'yes') {
      const { dispatch } = this.props;
      dispatch(DISABLE_ACCOUNT());
    }

  }

  form() {
    const { selection } = this.state;
    return (
      <>
        <div className="maintitle">DISABLE ACCOUNT</div>
        <SelectionControlGroup
          id="selection-control-group-radios"
          name="radio-example"
          type="radio"
          checkedRadioIcon={(<Icon>check</Icon>)}
          uncheckedRadioIcon={(<Icon>check</Icon>)}
          inline={true}
          onChange={(selection) => this.setState({ selection })}
          value={selection}
          controls={[
            {
              label: 'YES',
              value: 'yes',
            },
            {
              label: 'NO',
              value: 'no',
            },
          ]}
        />
     
            <Button
              flat={true}
              type="button"
              className="form_button"
              id="login_button"
              onClick={() => {
                this.disableAccountHandler();
              }
              }
            >
              DISABLE
            </Button>
          </>
    );
  }

  successfullyDisabled = () => {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(CLEAR_USER_PREFERENCES());
      dispatch(logOut());
    },         2000);

    return (
      <>
      Your account has been disabled
    </>
    );
  }
  content = () => {
    const { disableAccount: { error, sending, success } } = this.props;

    if (error) {
      return(<div>Cannot disable account</div>);
    }

    if (sending) {
      return(<Loader />);
    }

    if (success) {
      return (
        this.successfullyDisabled()
      );
    }

    return this.form();
  }

  render() {
    return (
      <div className="app-content disable-account">
        <Header />
        <HeaderImage content={this.content()} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    disableAccount: state.disableAccount,
  };
}

export default connect(mapStateToProps)(DisableAccount);
