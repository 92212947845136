import * as PIXI from 'pixi.js';

export default (startX: number, startY: number, endX: number, endY: number) => {
  const scaleTool = new PIXI.Graphics();
  scaleTool.lineStyle(1, 0x00000);
  scaleTool.moveTo(startX, startY);
  scaleTool.lineTo(endX, endY);
  scaleTool.id = 'scaleTool';
  return scaleTool;
};
