
import { DrawPanelFunction } from './draw_on_area';
import { getDoublePanelsWithGapsPopularingData } from './populating';
import nextUid from '__common/calculations/nextUid';
import { state } from '__common/store';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { PANEL_EW_POSITION } from '../../cursor/utils/snapToGridStickyPoints';

export const gapLengthMeters = inchesToMeters(12);
export const maxGftTableLength = 30;

export const drawGftPanelsInTables = (
  direction: string, 
  points: { x: number, y: number }[], 
  drawFn: DrawPanelFunction, 
  groupId: number | null,
): panelInState[] => {
  switch (direction) {
    case 'SE':
      return draw_SE(points, drawFn);
    case 'NW':
      return draw_NW(points, drawFn);
    case 'NE':
      return draw_NE(points, drawFn);
    case 'SW':
      return draw_SW(points, drawFn);
    default:
      return draw_SE(points, drawFn);
  }
};

const draw_SE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      columnSpacing,
      tableRowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
    },
  } = state();
  const { 
    topEdge, 
    leftEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopularingData(points, tableLength);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelPairColumns = Math.ceil(panelsInColumn / 2);
  const initialX = leftEdge - panelWidth / 2;
  let x: number;
  let y: number;
  let panels: panelInState[] = []; 
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = topEdge + (panelHeight * 2 * i) + (tableRowSpacingPx * i);

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX + (panelWidth * j) + (columnSpacingPx * j) + (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, groupId, panelEWPos, drawFn));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_NW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      columnSpacing,
      tableRowSpacing
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
    },
  } = state();
  const { 
    bottomEdge, 
    rightEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopularingData(points, tableLength);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelPairColumns = Math.ceil(panelsInColumn / 2);
  const initialX = rightEdge - panelWidth / 2;
  let x: number;
  let y: number;

  let panels: panelInState[] = [];
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = bottomEdge - (panelHeight * 2 * i)- (tableRowSpacingPx * i);

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX - (panelWidth * j) - (columnSpacingPx * j) - (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, groupId, panelEWPos, drawFn));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_NE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      columnSpacing,
      tableRowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
    },
  } = state();
  const { 
    bottomEdge, 
    leftEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopularingData(points, tableLength);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelPairColumns = Math.ceil(panelsInColumn / 2);
  const initialX = leftEdge - panelWidth / 2;
  let x: number;
  let y: number;
  let panels: panelInState[] = [];
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = bottomEdge - (panelHeight * 2 * i) - (tableRowSpacingPx * i);

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX + (panelWidth * j) + (columnSpacingPx * j) + (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, groupId, panelEWPos, drawFn));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_SW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      columnSpacing,
      tableRowSpacing
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
    },
  } = state();
  const { 
    topEdge, 
    rightEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getDoublePanelsWithGapsPopularingData(points, tableLength);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelPairColumns = Math.ceil(panelsInColumn / 2);
  const initialX = rightEdge - panelWidth / 2;
  let x: number;
  let y: number;
  let panels: panelInState[] = []; 
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  for (let i = 0; i < panelPairColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = topEdge + (panelHeight * 2 * i) + (tableRowSpacingPx * i);

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX - (panelWidth * j) - (columnSpacingPx * j) - (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, panelHeight, groupId, panelEWPos, drawFn));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

function drawPanels(x: number, y: number, panelWidth: number, panelHeight: number, groupId: number, panelEWPos: number, drawFn: DrawPanelFunction): panelInState[] {
  const panels: panelInState[] = [];
  const siblingId = nextUid();

  panels.push(drawFn(x + panelWidth / 2, y - panelHeight / 2, groupId, siblingId, 0, panelEWPos));
  panels.push(drawFn(x + panelWidth / 2, y + panelHeight / 2, groupId, siblingId, 1, panelEWPos));

  return panels;
}
