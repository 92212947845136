import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GET_APP_STATUS_CLEAR } from 'app/appActions';
import { Button } from 'react-md';
import SVG from 'react-inlinesvg';

interface Props {
  message: string;
  clearNotification: () => void;
}

class NotificationStatusBar extends React.Component<Props> {
  timeout;

  timeoutTime = 15000;

  componentDidMount() {
    if (this.props.message.length) {
      this.setTimeout();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { message } = this.props;

    if (prevProps.message.length === 0 && message.length) {
      this.setTimeout();
    }
  }

  setTimeout = () => {
    if (!this.timeout) {
      this.timeout = setTimeout(() =>  {
        this.props.clearNotification();
        clearTimeout(this.timeout); 
      },                        this.timeoutTime);
    }
  }

  exitButton = () => {
    const { message } = this.props;

    if (message.length) {
      return (
        <Button
          onClick={() => this.props.clearNotification()}
          className="exit-button"
        >
          <SVG src={require('assets/media/icons/plus_icon.svg')} />
        </Button>
      );
    }
    
    return null;
  }

  render() {
    const { message } = this.props;
    const messageShow = message.length;

    return (
      <div className="notification-status-bar" style={{ height: messageShow ? '40px' : '0px', padding: messageShow ? '25px' : '0px' }}>
        <div className="notification-status-bar-text" dangerouslySetInnerHTML={{ __html: message }} />
        {this.exitButton()}
      </div>
    );
  }
}

const mapStateToProps = (appState: appState) => ({
  message: appState.app.message,
});

const mapDispatchToProps = (dispatch: Dispatch<appState>) => ({
  clearNotification: () => dispatch(GET_APP_STATUS_CLEAR()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationStatusBar);
