import nprogress from 'nprogress';
import { ActionTypes, ProjectConfigurationActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import {
  SET_LOADER_MSG,
  LOAD_AURORA_PROJECT_CONFIGURATION,
  REDIRECT_TO_AURORA_PROJECT,
  IMPORT_AURORA_PROJECT_SUCCESS,
} from 'actions';
import { ActionsObservable } from 'redux-observable';
import { AnyAction, Store } from 'redux';
import { Observable } from 'rxjs';


export function auroraDesignConfigInitDataRequest(action$, store) {
  return action$.ofType(ActionTypes.AURORA_DESIGN_CONFIG_INIT_DATA_REQUEST)
    .switchMap((action) => {
      const auroraTenantId = action.payload.auroraTenantId
      const auroraProjectId = action.payload.auroraProjectId
      const auroraDesignId = action.payload.auroraDesignId
      const auroraUserId = action.payload.auroraUserId
      const auroraSessionToken = action.payload.auroraSessionToken
      
      return ObservableAjax({
        takeUntil: action$.ofType(ActionTypes.AURORA_DESIGN_CONFIG_INIT_DATA_SUCCESS),
        onSuccess: LOAD_AURORA_PROJECT_CONFIGURATION,
        onErrorMessage: 'Cannot fetch project data',
        socketName: 'project',
        onSocketPending: 'Wait Syncing Aurora with U-builder.....',
        onSocketStart: 'Importing Aurora Design into U-builder',
        onSocketFailure: 'Aurora Import Failure......',
        onErrorAction: () => nprogress.done(),
        link: hitApi('get', `api/v1/aurora/${auroraTenantId}/${auroraProjectId}/${auroraDesignId}/${auroraUserId}/${auroraSessionToken}/`),
      });
    });
}


  export function importAuroraFullDesignProject(action$, store) {
    return action$.ofType(ActionTypes.IMPORT_AURORA_FULL_DESIGN_PROJECT)
      .switchMap((action) => {
        store.dispatch(SET_LOADER_MSG('Importing Aurora Full Design Project'));
        return ObservableAjax({
          onSuccess: IMPORT_AURORA_PROJECT_SUCCESS,
          onErrorMessage: 'Cannot import project',
          socketName: 'project',
          onSocketFailure: 'AURORA FULL DESIGN IMPORT FAILURE',
          showBackendErrorMsgOnFailure: true,
          onErrorAction: () => nprogress.done(),
          link: hitApi('post', 'api/v1/aurora/full_design_import/', action.payload.data),
        });
      });
  }

  
  export function redirectAuroraLandingPageAfterProjectConfig(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
    return action$.ofType(ActionTypes.REDIRECT_AURORA_PAGE)
      .switchMap(() => {
        const { projectConfiguration: { projectEnvConfig : { aurora_project_id , aurora_design_id } } } = store.getState();
                
        store.dispatch(REDIRECT_TO_AURORA_PROJECT.bind(null, aurora_project_id , aurora_design_id ));
        return Observable.empty();
      });
  }

