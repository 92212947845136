

import { PanelsActionTypes } from 'actionsConstants';
import { validateMaxAllowedPanels } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateMaxAllowedPanels';

const actions = [
  PanelsActionTypes.ADD_PANELS,
];

export const panelsLimitsMiddleware = store => next => action => {
  

  if (actions.includes(action.type)) { 
    if (validateMaxAllowedPanels(action.payload.panels.length || 0)) {
      action.payload.panels = [];
      next(action);
    }
  }

  next(action);
};
