import history from '__common/modules/history';
import { ActionTypes, LOCATION_CHANGE } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';

const { location } = history;
location.state = {};

const error = null;

// type routerState = {
//   action: {

//   },
//   location: any,
// }

export const routerState = {
  action: history.action,
  location,
  error,
};

export default {
  router: createReducer(routerState, {
    [LOCATION_CHANGE](state, action) {
      const { payload } = action;
      return {
        ...state,
        location: payload.location,
        action: payload.action,
        error: null,
      };
    },
    [ActionTypes.SET_APP_ERROR](state, action) {
      return {
        ...state,
        error: {
          stack: action.payload.stack,
          info: action.payload.info,
          isIE: action.payload.isIE,
        },
      };
    },
    [ActionTypes.REMOVE_APP_ERROR](state) {
      return {
        ...state,
        error: null,
      };
    },
  }),
};
