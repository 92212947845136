import { _drawStickyPoints } from '../../panels/panels';
import { _isRM5ORRM10, _isRMDT, _isSF, _isSMFamily, _isGFT, _isULA , _isNHFamily, _isRM5_RM10_RMGridflex_RMEvo_EF2, _isEcoFoot2Plus, _isRM10Evolution, _isRM10, _isSMTiltPRFamily, _isAscenderFamily, _isRMGridflex10, _isNxtTilt } from './snapToGridHelper';
import { getStage } from '../../stage/stage';
import { state } from '__common/store';
import { gapLengthMeters } from '../../panels/utils/drawGftPanelsInTables';
import { getTiltedModuleTableRowSpacings } from '../../tiltedModule/tiltedModule';
import { is1PStructureType } from 'projectDesign/components/projectConfiguration/fields/types/structureType';

// only for GFT/ULA
// to label if a given panel lies on the left/right edge or in the middle of the GFT/ULA Table
export enum PANEL_EW_POSITION {
  LEFT_EDGE = 0,
  RIGHT_EDGE = 1,
  MIDDLE = 2,
}

export enum PANEL_NS_POSITION {
  TOP_EDGE = 0,
  MIDDLE = 1,
  BOTTOM_EDGE = 2,
}

export interface ICURSOR_STICK_POINT {
  x: number;
  y: number;
  corner: number;
}

export function getPanelStickPoints(panelX: number, panelY: number, width: number, height: number, siblingPos?: number, panelEWPosition?: number, panelNSPosition?: number, tableHeight?: number): {x: number, y: number, corner: number}[] {
  const x = panelX;
  const y = panelY;
  let stickyPoints: ICURSOR_STICK_POINT[] = [];
  if (_isGFT() || _isAscenderFamily()) {
    stickyPoints = addColumnAndGapSpacingPoints(x, y, width, height, stickyPoints, siblingPos);
    stickyPoints = addRowAndGapSpacingPointsGFT(x, y, width, height, stickyPoints, siblingPos);
    stickyPoints = addColumnRowAndGapSpacingPointsGFT(x, y, width, height, stickyPoints, siblingPos, panelEWPosition);
  } else if(_isULA()) {
    const { projectConfiguration: { projectEnvConfig: { tilt } } } = state();
    const tableRowSpacing = getTiltedModuleTableRowSpacings(tilt, tableHeight);
    stickyPoints = addColumnAndGapSpacingPointsUla(x, y, width, height, stickyPoints, siblingPos, panelEWPosition, panelNSPosition);
    stickyPoints = addRowAndGapSpacingPointsUla(x, y, width, height, stickyPoints, siblingPos, panelEWPosition, panelNSPosition, tableRowSpacing);
    stickyPoints = addColumnRowAndGapSpacingPointsUla(x, y, width, height, stickyPoints, siblingPos, panelEWPosition, panelNSPosition, tableRowSpacing);
  } else if (_isEcoFoot2Plus() || _isRM10Evolution() || _isRMGridflex10()) {
    stickyPoints = addColumnSpacingPoints(x, y, width, height, stickyPoints, siblingPos);
    stickyPoints = addRowSpacingPoints(x, y, width, height, stickyPoints, siblingPos);
  } else {
    stickyPoints = addColumnSpacingPoints(x, y, width, height, stickyPoints, siblingPos);
    stickyPoints = addRowSpacingPoints(x, y, width, height, stickyPoints, siblingPos, _isSMFamily() || _isSF() || _isNHFamily() || _isSMTiltPRFamily() || _isNxtTilt());
    stickyPoints = addColumnAndRowSpacingPoints(x, y, width, height, stickyPoints, siblingPos);
    const topRightCornerOfPanel = { x, y, corner: 2.25 };
    stickyPoints.push(topRightCornerOfPanel);
  }
  return stickyPoints;
}

let stickyPointsContainer: PIXI.Container;

export function getCursorSnappingPoint(cursorX: number, cursorY: number): ICURSOR_STICK_POINT[] {
  let points: ICURSOR_STICK_POINT[];

  if (_isRM5_RM10_RMGridflex_RMEvo_EF2()) {
    points = getRMPortrairCursorStickyPoints(cursorX, cursorY);
  } else if (_isRMDT()) {
    points = getRMDTPortrairCursorStickyPoints(cursorX, cursorY);
  } else if (_isGFT()) {
    points = getGFTCursorStickyPoints(cursorX, cursorY);
  } else if (_isAscenderFamily()) {
    const { projectConfiguration: {projectEnvConfig: {structure_type}} } = state();
    if (is1PStructureType(structure_type)) {
      points = getRMDTPortrairCursorStickyPoints(cursorX, cursorY);
    } else {
      points = getGFTCursorStickyPoints(cursorX, cursorY);
    }
  } else if(_isULA()) {
    points = getULACursorStickyPoints(cursorX, cursorY);
  } else {
    points = getCursorStickyPoints(cursorX, cursorY);
  }

  if (stickyPointsContainer) {
    const stage = getStage();

    stage.removeChild(stickyPointsContainer);
  }

  stickyPointsContainer = _drawStickyPoints(points);

  return points;
}

function addRowSpacingPoints(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number, isSMFamily: boolean = false) {
  const { settings: { rowSpacing }, background: { metersPerPixel } } = state();
  const rowSpacingPx = rowSpacing / metersPerPixel;

  let points: ICURSOR_STICK_POINT[] = [];

  const topLeft = { x, y: y - rowSpacingPx, corner: 2 };
  const topRight = { x: x + panelWidth, y: y - rowSpacingPx, corner: 3  };
  const bottomLeft = { x, y: y + panelHeight + rowSpacingPx, corner: 10  };
  const bottomRight =  { x: x + panelWidth, y: y + panelHeight + rowSpacingPx, corner: 11  };

  const topMiddle = { x: x + panelWidth / 2, y: y - rowSpacingPx, corner: 2.5 };
  const bottomMiddle = { x: x + panelWidth / 2, y: y + panelHeight + rowSpacingPx, corner: 10.5  };

  if (siblingPos === 0) {
    // RMDT
    // ○ - - - ○ - - - ○
    // |       |       |
    // |   0   |   1   |
    // |       |       |
    // ○ - - - ○ - - - ○

    // GFT
    // ○ - - - ○
    // |       |
    // |   0   |
    // |       |
    // ○ - - - ○
    // |       |
    // |   1   |
    // |       |
    // ○ - - - ○

    if (_isGFT() || _isAscenderFamily()) {
      points = [
        topLeft,
        topRight,
      ];
    } else {
      points = [
        topLeft,
        bottomLeft,
      ];
    }
  }

  if (siblingPos === 1) {
    if (_isGFT() || _isAscenderFamily()) {
      points = [
        bottomLeft,
        bottomRight,
      ];
    } else {
      points = [
        topRight,
        bottomRight,
      ];
    }
  }

  if (siblingPos !== 0 && siblingPos !== 1) {
    points = [
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
    ];
  }

  if (isSMFamily) {
    points = points.concat([
      topMiddle,
      bottomMiddle,
    ]);
  }


  return stickyPoints.concat(points);
}

function addColumnSpacingPoints(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number) {
  const { settings: { columnSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;

  let points: ICURSOR_STICK_POINT[];

  const topLeft = { x: x - columnSpacingPx, y, corner: 5 };
  const bottomLeft = { x: x - columnSpacingPx, y: y + panelHeight, corner: 7 };
  const topRight = { x: x + panelWidth + columnSpacingPx, y, corner: 6 };
  const bottomRight = { x: x + panelWidth + columnSpacingPx, y: y + panelHeight, corner: 8 };

  if (siblingPos === 0) {
    // RMDT
    // ○ - - - ○ - - - ○
    // |       |       |
    // |   0   |   1   |
    // |       |       |
    // ○ - - - ○ - - - ○

    // GFT
    // ○ - - - ○
    // |       |
    // |   0   |
    // |       |
    // ○ - - - ○
    // |       |
    // |   1   |
    // |       |
    // ○ - - - ○
    if (_isGFT() || _isAscenderFamily()) {
      points = [
        topLeft,
        topRight,
      ];
    } else {
      points = [
        topLeft,
        bottomLeft, 
      ];
    }
  }

  if (siblingPos === 1) {
    if (_isGFT() || _isAscenderFamily()) {
      points = [
        bottomLeft,
        bottomRight,
      ];
    } else {
      points = [
        topRight,
        bottomRight,
      ];
    }
  }

  if (siblingPos !== 0 && siblingPos !== 1) {
    points = [
      topLeft,
      bottomLeft,
      topRight,
      bottomRight,
    ];
  }

  return stickyPoints.concat(points);
}

function addColumnAndRowSpacingPoints(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number) {
  const { settings: { columnSpacing, rowSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;

  let points: ICURSOR_STICK_POINT[];

  const topLeft = { x: x - columnSpacingPx, y: y - rowSpacingPx, corner: 1 };
  const bottomLeft = { x: x - columnSpacingPx, y: y + rowSpacingPx + panelHeight, corner: 9 };

  const topRight = { x: x + columnSpacingPx + panelWidth, y: y - rowSpacingPx, corner: 4 };
  const bottomRight = { x: x + columnSpacingPx + panelWidth, y: y + rowSpacingPx + panelHeight, corner: 12 };

  if (siblingPos === 0) {
    // RMDT
    // ○ - - - ○ - - - ○
    // |       |       |
    // |   0   |   1   |
    // |       |       |
    // ○ - - - ○ - - - ○

    // GFT
    // ○ - - - ○
    // |       |
    // |   0   |
    // |       |
    // ○ - - - ○
    // |       |
    // |   1   |
    // |       |
    // ○ - - - ○
    if (_isGFT() || _isAscenderFamily()) {
      points = [
        topLeft,
        topRight,
      ];
    } else {
      points = [
        topLeft,
        bottomLeft,
      ];
    }
  } 

  if (siblingPos === 1) {
    if (_isGFT() || _isAscenderFamily()) {
      points = [
        bottomLeft,
        bottomRight,
      ];
    } else {
      points = [
        topRight,
        bottomRight,
      ];
    }
  }
  
  if (siblingPos !== 0 && siblingPos !== 1) {
    points = [
      topLeft,
      bottomLeft,
      topRight,
      bottomRight,
    ];
  }

  return stickyPoints.concat(points);
}

function getCursorStickyPoints(cursorCenterX: number, cursorCenterY: number) {
  // |---------------------> X
  // |  1     2     3
  // |  ○ - - ○ - - ○
  // |  |           |
  // |  |           |
  // |  |     ↖     |
  // |  |           |
  // |  |           |
  // |  ○ - - ○ - - ○
  // |  4     5     6
  // V Y
  const { settings: { panelWidth, panelHeight } } = state();
  const x = cursorCenterX - (panelWidth / 2);
  const y = cursorCenterY - (panelHeight / 2);
  return [
    { x, y, corner: 1 },
    { x: x + panelWidth / 2, y, corner: 2 },
    { x: x + panelWidth, y, corner: 3 },
    { x, y: y + panelHeight, corner: 4 },
    { x: x + panelWidth / 2, y: y + panelHeight, corner: 5 },
    { x: x + panelWidth, y: y + panelHeight, corner: 6 },
  ];
}

function addRowAndGapSpacingPointsGFT(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number) {
  const { settings: { tableRowSpacing }, background: { metersPerPixel } } = state();
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;


  let points: ICURSOR_STICK_POINT[] = [];

  const topLeft = { x, y: y - tableRowSpacingPx, corner: 2 };
  const topRight = { x: x + panelWidth, y: y - tableRowSpacingPx, corner: 3  };
  const bottomLeft = { x, y: y + panelHeight + tableRowSpacingPx , corner: 10  };
  const bottomRight =  { x: x + panelWidth, y: y + panelHeight + tableRowSpacingPx, corner: 11 };

  if (siblingPos === 0) {
    // GFT
    // ○ - - - ○
    // |       |
    // |   0   |
    // |       |
    // ○ - - - ○
    // |       |
    // |   1   |
    // |       |
    // ○ - - - ○

    points = [
      topLeft,
      topRight,
    ];
  }

  if (siblingPos === 1) {
    points = [
      bottomLeft,
      bottomRight,
    ];
  }

  return stickyPoints.concat(points);
}

function addRowAndGapSpacingPointsUla(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number,panelEWPosition?: number, panelNSPosition?: number, tableRowSpacing?: number) {
  const { settings: { rowSpacing }, background: { metersPerPixel } } = state();
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;


  let points: ICURSOR_STICK_POINT[] = [];

  const topLeft = { x, y: y - tableRowSpacingPx - rowSpacingPx, corner: 2 };
  const topRight = { x: x + panelWidth , y: y - tableRowSpacingPx - rowSpacingPx , corner: 3  };
  const bottomLeft = { x, y: y + panelHeight + tableRowSpacingPx + rowSpacingPx , corner: 10  };
  const bottomRight =  { x: x + panelWidth, y: y + panelHeight + tableRowSpacingPx + rowSpacingPx , corner: 11 };



  if (panelNSPosition === PANEL_NS_POSITION.BOTTOM_EDGE) {

    points = [
      bottomLeft,
      bottomRight,
    ];
  }

  if (panelNSPosition === PANEL_NS_POSITION.TOP_EDGE) {
    points = [
      topLeft,
      topRight,
    ];
  }

  return stickyPoints.concat(points);
}


function addColumnAndGapSpacingPoints(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number) {
  const { settings: { columnSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;

  let points: ICURSOR_STICK_POINT[];

  const topLeft = { x: x - columnSpacingPx - gapPx , y, corner: 5 };
  const bottomLeft = { x: x - columnSpacingPx - gapPx, y: y + panelHeight, corner: 7 };

  const topRight = { x: x + columnSpacingPx + panelWidth + gapPx, y, corner: 6 };
  const bottomRight = { x: x + columnSpacingPx + panelWidth + gapPx, y: y + panelHeight, corner: 8 };

  if (siblingPos === 0) {

    // GFT
    // ○ - - - ○
    // |       |
    // |   0   |
    // |       |
    // ○ - - - ○
    // |       |
    // |   1   |
    // |       |
    // ○ - - - ○
  
    points = [
      topLeft,
      topRight,
    ];
  }

  if (siblingPos === 1) {

    points = [
      bottomLeft,
      bottomRight,
    ];
  }

  return stickyPoints.concat(points);
}

function addColumnAndGapSpacingPointsUla(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number, panelEWPos? : number, panelNSPosition?: number) {
  const { settings: { columnSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;


  let points: ICURSOR_STICK_POINT[] = [];

  const topLeft = { x: x - columnSpacingPx- gapPx, y, corner: 5 };
  const bottomLeft = { x: x - columnSpacingPx - gapPx, y: y + panelHeight, corner: 7 };

  const topRight = { x: x + panelWidth+ gapPx + columnSpacingPx, y, corner: 6 };
  const bottomRight = { x: x + panelWidth + gapPx + columnSpacingPx, y: y + panelHeight, corner: 8 };

  if(panelEWPos === PANEL_EW_POSITION.LEFT_EDGE){
    points = [topLeft, bottomLeft];
  }

  if(panelEWPos === PANEL_EW_POSITION.RIGHT_EDGE){
    points = [topRight , bottomRight];
  }

  return stickyPoints.concat(points);
}

function addColumnRowAndGapSpacingPointsGFT(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number, panelEWPos?: number) {
  const { settings: { columnSpacing, tableRowSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  let points: ICURSOR_STICK_POINT[] = [];

  const topLeft = { x: x - columnSpacingPx - gapPx , y: y - tableRowSpacingPx, corner: 1 };
  const bottomLeft = { x: x - columnSpacingPx - gapPx, y: y + panelHeight + tableRowSpacingPx, corner: 9 };

  const topRight = { x: x + columnSpacingPx + panelWidth + gapPx, y: y - tableRowSpacingPx, corner: 4 };
  const bottomRight = { x: x + columnSpacingPx + panelWidth + gapPx, y: y + panelHeight + tableRowSpacingPx, corner: 12 };

  // GFT
  // ○ - - - ○
  // |       |
  // |   0   |
  // |       |
  // ○ - - - ○
  // |       |
  // |   1   |
  // |       |
  // ○ - - - ○
  
  if (!siblingPos && panelEWPos === PANEL_EW_POSITION.LEFT_EDGE){
    points = [topLeft];
  } else if (!siblingPos && panelEWPos === PANEL_EW_POSITION.RIGHT_EDGE){
    points = [topRight];
  } else if (siblingPos && panelEWPos === PANEL_EW_POSITION.LEFT_EDGE){
    points = [bottomLeft];
  } else if (siblingPos && panelEWPos === PANEL_EW_POSITION.RIGHT_EDGE){
    points = [bottomRight];
  }
  return stickyPoints.concat(points);
}

function addColumnRowAndGapSpacingPointsUla(x: number, y: number, panelWidth: number, panelHeight: number, stickyPoints: ICURSOR_STICK_POINT[], siblingPos?: number, panelEWPos? : number, panelNSPosition?: number, tableRowSpacing?: number) {
  const { settings: { columnSpacing, rowSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  let points: ICURSOR_STICK_POINT[] = [];


  const topLeft = { x: x - gapPx - columnSpacingPx , y: y - tableRowSpacingPx - rowSpacingPx, corner: 1 };
  const bottomLeft = { x: x - gapPx - columnSpacingPx, y: y + panelHeight + rowSpacingPx + tableRowSpacingPx, corner: 9 };

  const topRight = { x: x + panelWidth + gapPx + columnSpacingPx, y: y - tableRowSpacingPx -rowSpacingPx, corner: 4 };
  const bottomRight = { x: x + panelWidth+ columnSpacingPx + gapPx, y: y + panelHeight + tableRowSpacingPx + rowSpacingPx, corner: 12 };

  if (panelNSPosition === PANEL_NS_POSITION.TOP_EDGE && panelEWPos === PANEL_EW_POSITION.LEFT_EDGE){
    points = [topLeft];
  } else if (panelNSPosition === PANEL_NS_POSITION.TOP_EDGE && panelEWPos === PANEL_EW_POSITION.RIGHT_EDGE){
    points = [topRight];
  } else if (panelNSPosition===PANEL_NS_POSITION.BOTTOM_EDGE && panelEWPos === PANEL_EW_POSITION.LEFT_EDGE){
    points = [bottomLeft];
  } else if (panelNSPosition===PANEL_NS_POSITION.BOTTOM_EDGE && panelEWPos === PANEL_EW_POSITION.RIGHT_EDGE){
    points = [bottomRight];
  }

  return stickyPoints.concat(points);
}


function getRMPortrairCursorStickyPoints(cursorCenterX: number, cursorCenterY: number) {
  const { settings: { panelWidth, panelHeight } } = state();
  const x = cursorCenterX - (panelWidth / 2);
  const y = cursorCenterY - (panelHeight / 2);
  return [
    { x, y, corner: 1 },
    { x: x + panelWidth, y, corner: 2 },
    { x, y: y + panelHeight, corner: 3 },
    { x: x + panelWidth, y: y + panelHeight, corner: 4 },
  ];
}

function getRMDTPortrairCursorStickyPoints(cursorCenterX: number, cursorCenterY: number) {
  // |---------------------------> X
  // |  1                       2
  // |  ○ - - - - - - - - - - - ○
  // |  |           |           |
  // |  |           |           |
  // |  |           ↖           |
  // |  |           |           |
  // |  |           |           |
  // |  ○ - - - - - - - - - - - ○
  // |  3                       4
  // V Y
  const { settings: { panelWidth, panelHeight } } = state();
  const x = cursorCenterX - panelWidth;
  const y = cursorCenterY - (panelHeight / 2);
  return [
    { x, y, corner: 1 },
    { x: x + panelWidth * 2, y, corner: 2 },
    { x, y: y + panelHeight, corner: 3 },
    { x: x + panelWidth * 2, y: y + panelHeight, corner: 4 },
  ];
}



function getGFTCursorStickyPoints(cursorCenterX: number, cursorCenterY: number): ICURSOR_STICK_POINT[] {
  // |---------------------------> X
  // |  1           2
  // |  ○ - - - - - ○
  // |  |           |
  // |  |           |
  // |  |           |
  // |  |           |      
  // |  |           |
  // |  | - - ↖ - - |
  // |  |           |
  // |  |           |
  // |  |           |
  // |  |           |      
  // |  |           |
  // |  ○ - - - - - ○
  // |  3           4
  // V Y
  const { 
    settings: {
      panelWidth,
      panelHeight, 
      columnSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
    },
  } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;

  const x = cursorCenterX - (panelWidth / 2);
  const y = cursorCenterY - panelHeight;
  
  return [
    { x, y, corner: 1 },
    { x: x + (panelWidth + columnSpacingPx) * (tableLength - 1) + panelWidth, y, corner: 2 },
    { x, y: y + panelHeight * 2, corner: 3 },
    { x: x + (panelWidth + columnSpacingPx) * (tableLength - 1) + panelWidth, y: y + panelHeight * 2, corner: 4 },
  ];
}


function getULACursorStickyPoints(cursorCenterX: number, cursorCenterY: number): ICURSOR_STICK_POINT[] {

  const { 
    settings: {
      panelWidth,
      panelHeight, 
      columnSpacing,
      rowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
      desiredTableWidth: tableWidth,
    },
  } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;

  const x = cursorCenterX - (panelWidth / 2);
  const y = cursorCenterY - panelHeight;
  
  return [
    { x, y, corner: 1 },
    { x: x + (panelWidth + columnSpacingPx) * (tableLength - 1) + panelWidth, y, corner: 2 },
    { x, y: y + (panelHeight+rowSpacingPx) * tableWidth, corner: 3 },
    { x: x + (panelWidth + columnSpacingPx) * (tableLength - 1) + panelWidth, y: y + (panelHeight+rowSpacingPx) * tableWidth, corner: 4 },
  ];
}
