import * as Sentry from '@sentry/browser';
import { deepClonedState } from '__common/store';

export const captureException = (error: Error) => {
  Sentry.withScope(scope => {
    const state = deepClonedState();
    delete state.moduleSelector.mfgs;
    delete state.moduleSelector.models;
    scope.setExtra('State', state);
    Object.keys(error).forEach(key => {
      scope.setExtra(key, error[key]);
    });
    Sentry.captureException(error);
  });
};
