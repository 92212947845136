import { state } from '__common/store';
import { stringify } from 'json5';
import { getPanelsContainer } from '../../panels/utils/panelsContainer';
import { showNotifyAlert } from '__common/modules/alerts';


let lastPoint: pixelPoint;
let lastSnappingData;

export function getSnappingTestData(delay?: number) {
  const { settings: 
      { panelWidth, panelHeight, rowSpacing, columnSpacing }, 
    projectConfiguration: 
      { productId }, 
      panels: 
        { panels }, 
      background: 
        { metersPerPixel },
      } = state();
  const newState = {
    settings: {
      panelWidth,
      panelHeight,
      rowSpacing,
      columnSpacing,
    },
    projectConfiguration: {
      productId,
    },
    panels: { 
      panels,
    },
    background: {
      metersPerPixel,
    },
  };

  const data = {
    state: newState,
    cursorPosition: lastPoint,
    snappingData: lastSnappingData,
  };
  newState.panels.panels.map((panel, index) => {
    const container = getPanelsContainer();
    newState.panels.panels[index].x = container.children.find((p: any) => p.id === panel.id).x;
    newState.panels.panels[index].y = container.children.find((p: any) => p.id === panel.id).y;
    return panel;
  });
  copyMessage(stringify(data, null, 2));
  showNotifyAlert('Copied to clipboard');
  console.log(stringify(data, null, 2));
}


function copyMessage(val: string) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}

export function setLastPoint(point: { x: number, y: number }) {
  lastPoint = point;
}

export function setLastSnappingData(data) {
  lastSnappingData = data;
}
