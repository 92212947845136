import * as PIXI from 'pixi.js';
import { ADD_TEMP_POINT } from 'actions';
import { dispatch, state } from '__common/store';
import { findSlope } from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/utils/findSlope';
import { isShiftPressed } from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelecting';
import { panelsEditorEnabled } from 'projectDesign/projectDesign';
import { isAscender, isRMIFIProduct } from '__common/constants/products';
import { isPointWithinPolygon, lat_lng_distance_to_feet } from '__editor/panelsEditor/components/background/utils/backgroundCordinatesConverter';
import { getLatLongFromPixiCoords } from '__editor/components/roofsSelector/components/roofsSelectorAdvanceRoofSelecting/roofsSelectorAdvanceRoofSelecting';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';


function getLineWidth(scale: number) {
  return 3 / scale;
}

function getRoofPointsArray(coords: pixelPoint[]) : number[]{
  const points = coords.reduce((acc: number[], coords: pixelPoint) => { 
    const pixiPoint = new PIXI.Point(coords.x, coords.y);
    acc.push(pixiPoint.x, pixiPoint.y); 
    return acc; 
  },                                 []);
  return points
}

export function getRoofPolygon(coords: pixelPoint[]) : number[][]{
  const polygon = coords.reduce((acc: number[][], coords: pixelPoint) => { 
    const pixiPoint = new PIXI.Point(coords.x, coords.y);
    acc.push([pixiPoint.x, pixiPoint.y]); 
    return acc; 
  },                                 []);
  polygon.push([coords[0].x, coords[0].y])
  return polygon
}

export function advanceRoofSelectingLineModel(roofPixiPoints: pixelPoint[], scale: number) {
  const { projectConfiguration: { productId, inputUnit }, background : { panelEditorActive } } = state();
  const line = new PIXI.Graphics();
  const points = getRoofPointsArray(roofPixiPoints);
  line.lineStyle(getLineWidth(scale), 0xF26522, 1);
  line.moveTo(points[0], points[1]);
  line.lineTo(points[2], points[3]);
  if (!panelEditorActive){
    const edgeDimension = calculateEdgeDimensionOnStage(roofPixiPoints[0], roofPixiPoints[1], isAscender(productId) || isMetricUnit(inputUnit));
    const dimensionText = createDimensionText(edgeDimension, scale);
    const dimensionBg = createDimensionBG(dimensionText);
    line.addChild(dimensionBg, dimensionText);
  }

  return line;
}

export function advanceRoofSelectingModel(roofPixiPoints: pixelPoint[], scale: number): PIXI.Graphics {
  const { projectConfiguration: { productId, inputUnit }, background : { panelEditorActive } } = state();
  const points = getRoofPointsArray(roofPixiPoints);
  const advanceRoofSelector = new PIXI.Graphics();
  if ((isShiftPressed() && !panelsEditorEnabled()) || (isRMIFIProduct(productId) && panelsEditorEnabled())) {
    // const b = result[1];
    const newPoints: number[] = points.slice(-6, -2);
    const cursorXY: number[] = points.slice(-2);
    let [newCursorX, newCursorY] = [0, 0];
    
    // points -> list of x, y coordinates of points of the Roof/Obstruction polygon during advance selecting
    // newPoints -> x, y coordinates of the last 2 points of the polygon + x, y coordinates of the cursor Point
    const result: number[] = findSlope(newPoints);
    const a = result[0]; // slope of the line formed by the last 2 polygon points
    if (!isFinite(a)) {
      newCursorX = cursorXY[0];
      newCursorY = newPoints[3];
    } else if (a === 0) {
      newCursorX = newPoints[2];
      newCursorY = cursorXY[1];
    } else {
      const a1 = -1 / a;
      const b1 = -a1 * newPoints[2] + newPoints[3];
      const b2 = -a * cursorXY[0] + cursorXY[1];
      newCursorX = (b2 - b1) / (a1 - a);
      newCursorY = a * newCursorX + b2;
    }
    
    points[points.length - 2] = newCursorX;
    points[points.length - 1] = newCursorY;
    roofPixiPoints[roofPixiPoints.length - 1] = new PIXI.Point(newCursorX, newCursorY);
    dispatch(ADD_TEMP_POINT({ x: newCursorX, y: newCursorY }));
    // wyznaczenie prostej przechodzace przez dwa punkty ostatniej krawedzi dachu
    // const helpLine: PIXI.Graphics = new PIXI.Graphics();
    // const x1 = 0;
    // const y1 = a * x1 + b;
    // const x2 = 100;
    // const y2 = x2 * a + b;
    // helpLine.lineStyle(0.00002, "0xFFFFFF");
    // helpLine.moveTo(x1, y1);
    // helpLine.lineTo(x2, y2);
    // layer.addChild(helpLine);

    // prosta prostopadla do ostatniej krawedzi dachu przechodzaca przez ostatni punkt uzytkownika
    // const helpSecondLine: PIXI.Graphics = new PIXI.Graphics();
    // const a1 = -1 / a;
    // const b1 = -a1 * newPoints[2] + newPoints[3];
    // const x3 = 0;
    // const y3 = a1 * x3 + b1;
    // const x4 = 100;
    // const y4 = a1 * x4 + b1;
    // helpSecondLine.lineStyle(0.00002, "0xFFF000");
    // helpSecondLine.moveTo(x3, y3);
    // helpSecondLine.lineTo(x4, y4);
    // layer.addChild(helpSecondLine);

    // wyznaczana jest prosta rownolegla do ostatniej krawedzi dachu przechodzaca przez kursor (wspolrzedne)
    // const cursorXY = points.slice(-2);
    // const helpThirdLine: PIXI.Graphics = new PIXI.Graphics();
    // const b2 = -a * cursorXY[0] + cursorXY[1];
    // const x5 = 0;
    // const y5 = a * x5 + b2;
    // const x6 = 100;
    // const y6 = a * x6 + b2;
    // helpThirdLine.lineStyle(0.00002, "0x00FF00");
    // helpThirdLine.moveTo(x5, y5);
    // helpThirdLine.lineTo(x6, y6);
    // layer.addChild(helpThirdLine);

    // znalezienie przeciecia rownoleglej przechodzacej przez kursor i ostatniej krawedzi dachu
    // const newCursorX = (b2 - b1) / (a1 - a);
    // const newCursorY = a * newCursorX + b2;

    // points[points.length - 2] = newCursorX;
    // points[points.length - 1] = newCursorY;
  }
  advanceRoofSelector.beginFill(0xF26522, 0.28);
  advanceRoofSelector.lineStyle(getLineWidth(scale), 0xF26522, 1);
  const closedPolygonPoints = points.concat(points[0]).concat(points[1]);
  advanceRoofSelector.drawPolygon(closedPolygonPoints);
  advanceRoofSelector.zIndex = 99999;
  advanceRoofSelector.endFill();
  if (!panelEditorActive) {
    const polygon = getRoofPolygon(roofPixiPoints);
    roofPixiPoints.map((coord, index) => {
      const nextPointIndex = (index + 1) % roofPixiPoints.length;
      const edgeDimension = calculateEdgeDimensionOnStage(coord, roofPixiPoints[nextPointIndex], isAscender(productId) || isMetricUnit(inputUnit));
      const dimensionText = createDimensionText(edgeDimension, scale, polygon);
      const dimensionBg = createDimensionBG(dimensionText);
      advanceRoofSelector.addChild(dimensionBg, dimensionText);
    })
  }
  return advanceRoofSelector;
}

export function createDimensionText(edgeDimension: edgeDimension, scale: number, polygon?: number[][], fontSize = '16px'): PIXI.Text{
  let {
    midPoint : {
      x,
      y
    }, 
    rotation,
    length } = edgeDimension;
  const dimensionText = new PIXI.Text(length, { fontSize: fontSize, fontWeight: "700", fill: 0xFFFFFF});
  dimensionText.width /= scale;
  dimensionText.height /= scale;
  let xOffset = 15 * Math.sin(rotation) / scale;
  let yOffset = 15 * Math.cos(rotation) / scale;
  // approximate point to check: on which side of edge, dimension text should be shown
  const approx_point = [x-(0.00001 * Math.sin(rotation)) / scale, y +(0.00001 * Math.cos(rotation)) / scale];
  
  if (polygon && polygon.length > 3 && isPointWithinPolygon(approx_point, polygon)){
    xOffset = -xOffset;
    yOffset = -yOffset;
    if (-Math.PI/2  > rotation || rotation > Math.PI/2) {
      rotation += Math.PI;
    }
  }
  else if (Math.PI/2  < rotation || rotation < -Math.PI/2){
    rotation += Math.PI
  }
  
  dimensionText.x = x - xOffset;
  dimensionText.y = y + yOffset;
  dimensionText.zIndex = 99999;
  dimensionText.rotation = rotation;
  dimensionText.anchor.set(0.5);

  return dimensionText;
}


export function createDimensionBG(dimensionTxt : PIXI.Text) {
  const txtBG = new PIXI.Sprite(PIXI.Texture.WHITE);
  txtBG.tint = 0x000000;
  txtBG.position = dimensionTxt.position;
  txtBG.rotation = dimensionTxt.rotation;
  txtBG.width = dimensionTxt.width, 
  txtBG.height = dimensionTxt.height;
  txtBG.zIndex = 99999;
  txtBG.anchor.set(0.5);
  return txtBG
};


export function calculateEdgeDimensionOnStage(startingPoint: pixelPoint, endingPoint: pixelPoint, metricUnits: boolean): edgeDimension {
  const distanceInFt = lat_lng_distance_to_feet(getLatLongFromPixiCoords(startingPoint), getLatLongFromPixiCoords(endingPoint));
  const distance = metricUnits ? feetsToMeters(distanceInFt).toFixed(2) + " m" : distanceInFt.toFixed(2) + " ft"
  const angleInRadians = Math.atan2(endingPoint.y - startingPoint.y, endingPoint.x - startingPoint.x);
  const midPoint = {
    x : (startingPoint.x + endingPoint.x)/2,
    y : (startingPoint.y + endingPoint.y)/2
  };
  const edgeDimension = {
    length: distance,
    midPoint,
    rotation : angleInRadians
  }
  return edgeDimension; 
}