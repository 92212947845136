import { ModuleBits } from './baseTypes';

// (little-endian) - to make the bit data read the table right to left 
// e.g. on corner (0-1), on edge (0-1), orientation (0-1)

// ** building code 7-16, residential **
// |----------------|---------|-----------|-----------|---------|
// | orientation    | on edge | on corner | exposed   | exposed |
// | (is landscape) |         |           | neighbour |         |
// |----------------|---------|-----------|-----------|---------|
// |       1        |    0    |     1     |     1     |    1    | <--- example: landscape expose panel on corner
// |                |         |           |           |         |      with exposed neighbour. Gives 0b11101 = 29
// |----------------|---------|-----------|-----------|---------|

export class Asce716ResidentialModuleBits extends ModuleBits {
  fromBits(bitData: number, roofZoneId ?: roofZoneNumber) {
    const isLandscape = bitData & 1;

    // this is a workaround because frontend is sending too big roofzone by 1 for residentials 7-16
    let roofZone = ((bitData >> 1 & 0b11) + 1) as roofZoneNumber;
    if(roofZoneId) {
      roofZone = roofZoneId;
    }
    const exposedNeighbour = bitData >> 3 & 1;
    const obstructionExposureBit = bitData >> 4 & 1;

    return {
      isLandscape: this.bitToBoolean(isLandscape),
      exposed: obstructionExposureBit === 1,
      exposedNeighbour: exposedNeighbour === 1,
      nearObstruction: undefined,
      roofZone: roofZone,
    };
  }
  toBits(panel: panelInState) {
    const isLandscape = this.booleanToBit(panel.landscape); // 1 - landscape
    // this is a workaround because frontend is sending too big roofzone by 1 for residentials 7-16
    const roofZone = panel.roofZone - 1;
    const exposedBit = panel.exposed || panel.exposedNeighbour ? 1 : 0;
    const exposedNeighbourBit = panel.exposedNeighbour ? 1 : 0;
    const exposedAndExposedNeighbourBits = exposedBit << 1 | exposedNeighbourBit;
    const exposuresWithRoofzones = exposedAndExposedNeighbourBits << 2 | roofZone;
    return exposuresWithRoofzones << 1 | isLandscape;
  }
}
