export const apiField = 'rail_length_for_thermal_gap';

export default {
  type: 'Select',
  label: 'railLengthForThermalGap',
  apiField,
  tooltip:  () => `
  <div class="content">
  Rail Length for each thermal gap (cm). 
  </div>`,
  data: [
    {
      value: 1188.7,
      name: '1188.7',
    },
    {
      value: 1371.6,
      name: '1371.6',
    },
  ],
};
