
interface LayoutSourceCodeMap {
  name: string;
  id: number;
  content: any;
  active: boolean;
}

export function getEngineeringArrayListMap(arrays: engineeringProjectArray[], productId: number, projectId: string) {
    const sourceCodeMap = arrays.map<LayoutSourceCodeMap>((array, index) => {
      return {
        name: array.name,
        id: array.id,
        content: array.name,
        active: index === 0, // automatically select the first page
      };
    });
    return sourceCodeMap;
  }
