import { state } from '__common/store';
import { gapLengthMeters } from './drawGftPanelsInTables';

export const getDoublePanelsWithGapsPopularingData = (points: pixelPoint[], tableLength: number) => {
  const { settings: { columnSpacing, tableRowSpacing }, background: { metersPerPixel } } = state();
  const settings = state().settings;
  const panelWidth = settings.panelWidth;
  const panelHeight = settings.panelHeight;
  const { topEdge, bottomEdge, leftEdge, rightEdge } = getEdges(points);

  const columnSpacingPx = columnSpacing / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  let panelsInRow = 1;
  let panelsInColumn = 1;
  let sum = 0; 
  let tableIndex = 0;
  const dx = rightEdge - leftEdge;
  const dy = bottomEdge - topEdge;

  while (sum <= dx) {
    tableIndex += 1;
    if (sum + panelWidth + columnSpacingPx <= dx) {
      sum += panelWidth + columnSpacingPx;
      panelsInRow += 1;
    }

    if (tableIndex === tableLength) {
      tableIndex = 0;
      sum += gapLengthMeters / metersPerPixel;
      continue;
    }    
  }

  sum = 0;
  tableIndex = 0;
  while (sum <= dy) {
    tableIndex += 1;
    if (sum + (panelHeight * 2) + tableRowSpacingPx <= dy) {
      sum += panelHeight * 2 + tableRowSpacingPx;
      panelsInColumn += 2;
    }

    if (tableIndex === 2) {
      tableIndex = 0;
      sum += tableRowSpacingPx;
      continue;
    }    
  }

  return {
    topEdge,
    bottomEdge,
    leftEdge,
    rightEdge,
    panelWidth,
    panelHeight,
    panelsInRow,
    panelsInColumn,
    tableLength,
    gapLength: gapLengthMeters,
  };
};


export const getDoublePanelsWithGapsPopulatingDataAscender = (points: pixelPoint[], tableLength: number, panels_in_column: number) => {
  const { settings: { columnSpacing, rowSpacing }, background: { metersPerPixel } } = state();
  const settings = state().settings;
  const panelWidth = settings.panelWidth;
  const panelHeight = settings.panelHeight;
  const { topEdge, bottomEdge, leftEdge, rightEdge } = getEdges(points);

  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;

  let panelsInRow = 1;
  let panelsInColumn = 1;
  let sum = 0; 
  let tableIndex = 0;
  const dx = rightEdge - leftEdge;
  const dy = bottomEdge - topEdge;

  while (sum <= dx) {
    tableIndex += 1;
    if (sum + panelWidth + columnSpacingPx <= dx) {
      sum += panelWidth + columnSpacingPx;
      panelsInRow += 1;
    }

    if (tableIndex === tableLength) {
      tableIndex = 0;
      sum += gapLengthMeters / metersPerPixel;
      continue;
    }    
  }

  sum = 0;
  tableIndex = 0;
  while (sum <= dy) {
    tableIndex += 1;
    if (sum + (panelHeight*panels_in_column) + rowSpacingPx <= dy) {
      sum += (panelHeight*panels_in_column) + rowSpacingPx;
      panelsInColumn += panels_in_column;
    }

    if (tableIndex === panels_in_column) {
      tableIndex = 0;
      sum += gapLengthMeters / metersPerPixel;
      continue;
    }    
  }

  return {
    topEdge,
    bottomEdge,
    leftEdge,
    rightEdge,
    panelWidth,
    panelHeight,
    panelsInRow,
    panelsInColumn,
    tableLength,
    gapLength: gapLengthMeters,
  };
};



export const getSelectedRowByColumnPanelsWithGapsPopularingData = (points: pixelPoint[], tableLength: number, tableWidth: number) => {
  const { settings: { columnSpacing, rowSpacing, tableRowSpacing }, background: { metersPerPixel } } = state();
  const settings = state().settings;
  const panelWidth = settings.panelWidth;
  const panelHeight = settings.panelHeight;
  const { topEdge, bottomEdge, leftEdge, rightEdge } = getEdges(points);

  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  let panelsInRow = 1;
  let panelsInColumn = 1;
  let sum = 0;
  let tableIndex = 0;
  const dx = rightEdge - leftEdge;
  const dy = bottomEdge - topEdge;

  while (sum <= dx) {
    tableIndex += 1;
    if (sum + panelWidth + columnSpacingPx <= dx) {
      sum += panelWidth + columnSpacingPx;
      panelsInRow += 1;
    }

    if (tableIndex === tableLength) {
      tableIndex = 0;
      sum += gapLengthMeters / metersPerPixel;
      continue;
    }    
  }

  sum = 0;
  tableIndex = 0;
  while (sum <= dy) {
    tableIndex += 1;
    if (sum + panelHeight + rowSpacingPx <= dy) {
      sum += panelHeight + rowSpacingPx;
      panelsInColumn += 1;
    }

    if (tableIndex === tableWidth) {
      tableIndex = 0;
      sum += (tableRowSpacingPx);
      continue;
    }    
  }

  return {
    topEdge,
    bottomEdge,
    leftEdge,
    rightEdge,
    panelWidth,
    panelHeight,
    panelsInRow,
    panelsInColumn,
    tableLength,
    gapLength: gapLengthMeters,
  };
};

export const getDoublePanelsPopulatingData = (points: pixelPoint[], nrOfPanels: number = 1) => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel }, editorCursor: { landscape } } = state();
  const settings = state().settings;
  const panelWidth = settings.panelWidth;
  const panelHeight = settings.panelHeight;
  const { topEdge, bottomEdge, leftEdge, rightEdge } = getEdges(points);
  let widthMultiplayer = 1;
  let heightMultiplayer = 1;

  if (landscape) {
    heightMultiplayer *= 2;
  } else {
    widthMultiplayer *= 2;
  }

  const panelsInRow = Math.ceil((rightEdge - leftEdge) / (panelWidth * widthMultiplayer + (columnSpacing / metersPerPixel)));
  const panelsInColumn = Math.ceil((bottomEdge - topEdge) / (panelHeight * heightMultiplayer + (rowSpacing / metersPerPixel)));
  return {
    topEdge,
    bottomEdge,
    leftEdge,
    rightEdge,
    panelWidth,
    panelHeight,
    panelsInRow,
    panelsInColumn,
    heightMultiplayer,
    widthMultiplayer,
  };
};

export function get_drawing_direction(mouseMoveCords: { startX: number, startY: number, endX: number, endY: number }) {
  const { startX, startY, endX, endY } = mouseMoveCords;
  let direction = startY > endY ? 'N' : 'S';
  direction += startX > endX ? 'W' : 'E';
  return direction;
}

function convertPointsToArray(points, axis) {
  return points.map(point => point[axis]);
}

function getLeftEdge(points) {
  const xPoints = convertPointsToArray(points, 'x');
  return Math.min(...xPoints);
}

function getRightEdge(points) {
  const xPoints = convertPointsToArray(points, 'x');
  return Math.max(...xPoints);
}

function getTopEdge(points) {
  const yPoints = convertPointsToArray(points, 'y');
  return Math.min(...yPoints);
}

function getBottomEdge(points) {
  const yPoints = convertPointsToArray(points, 'y');
  return Math.max(...yPoints);
}

function getEdges(points) {
  return {
    topEdge: getTopEdge(points),
    bottomEdge: getBottomEdge(points),
    leftEdge: getLeftEdge(points),
    rightEdge: getRightEdge(points),
  };
}

export const getSinglePanelsPopulatingData = (points) => {
  const { settings: { rowSpacing, columnSpacing }, background: { metersPerPixel } } = state();
  const settings = state().settings;
  const panelWidth = settings.panelWidth;
  const panelHeight = settings.panelHeight;
  const { topEdge, bottomEdge, leftEdge, rightEdge } = getEdges(points);
  const panelsInRow = Math.ceil((rightEdge - leftEdge) / (panelWidth + (columnSpacing / metersPerPixel)));
  const panelsInColumn = Math.ceil((bottomEdge - topEdge) / (panelHeight + (rowSpacing / metersPerPixel)));
  return {
    topEdge,
    bottomEdge,
    leftEdge,
    rightEdge,
    panelWidth,
    panelHeight,
    panelsInRow,
    panelsInColumn,
  };
};
