import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { metersToInches } from 'maths';
import * as PIXI from 'pixi.js';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';
import { inchesToCms } from '__common/calculations/unitConversions';
import { isAscender } from '__common/constants/products';
import { calculateEdgeDimensionOnStage } from './advanceRoofSelecting';

function getLineWidth(scale: number) {
  return 3 / scale;
}

export function measurementToolModel(startingPoint: pixelPoint, endingPoint: pixelPoint, scale: number, productId: number, inputUnit?: number, panelEditorActive: boolean = false, metersPerPixel?: number) {

  const line = new PIXI.Graphics();
  line.lineStyle(getLineWidth(scale), 0x0083c2, 1);
  line.moveTo(startingPoint.x, startingPoint.y);
  line.lineTo(endingPoint.x, endingPoint.y);
  line.endFill();

  const lengthDimension = panelEditorActive ? calculateEdgeDimensionOnPanelEditor(startingPoint, endingPoint, isAscender(productId) || isMetricUnit(inputUnit), metersPerPixel)
  : calculateEdgeDimensionOnStage(startingPoint, endingPoint, isAscender(productId) || isMetricUnit(inputUnit));
  const lengthDimensionObj = createLengthDimensionObj(lengthDimension, scale);
  
  line.addChild(lengthDimensionObj);

  return line;
}

function calculateEdgeDimensionOnPanelEditor(startingPoint: pixelPoint, endingPoint: pixelPoint, metricUnits: boolean, metersPerPixel: number) : edgeDimension{
  const distanceinInches = metersToInches(calcDistanceOfTwoPoints(startingPoint, endingPoint) * metersPerPixel);
  const distance = metricUnits ? inchesToCms(distanceinInches).toFixed(2) + " cm" : distanceinInches.toFixed(2) + '"';
  const angleInRadians = Math.atan2(endingPoint.y - startingPoint.y, endingPoint.x - startingPoint.x);
  const midPoint = {
    x : (startingPoint.x + endingPoint.x)/2,
    y : (startingPoint.y + endingPoint.y)/2
  };
  const edgeDimension = {
    length: distance,
    midPoint,
    rotation : angleInRadians
  }
  return edgeDimension; 

}

function createLengthDimensionObj(lengthDimension: edgeDimension, scale){
  let {
    midPoint : {
      x,
      y
    },
    length,
    rotation
  } = lengthDimension
  
  // creating text object
  const text: PIXI.Text = new PIXI.Text(length, {
    fontSize: '16px', fill: 0xFFFFFF, fontWeight: "700"
  });
  const width = (text.width / 2) / scale;
  const height = (text.height / 2) / scale;
  let xOffset = (width * Math.cos(rotation) - (height * Math.sin(rotation)));
  let yOffset = (width * Math.sin(rotation) + (height * Math.cos(rotation)));
  if (Math.PI/2  < rotation || rotation < -Math.PI/2){
    xOffset = -xOffset;
    yOffset = -yOffset;
    rotation += Math.PI;
  }
  text.x = x - xOffset;
  text.y = y - yOffset;
  text.width /= scale;
  text.height /= scale;

  // creating background obj
  const textBG = new PIXI.Graphics();
  textBG.lineStyle(getLineWidth(scale), 0x0083c2, 1);
  textBG.beginFill(0x000000)
  xOffset = 10 / scale;
  yOffset = 5 / scale;
  textBG.drawRect(
    text.position.x - xOffset/2,
    text.position.y - yOffset/2,
    text.width + xOffset,
    text.height + yOffset,
  );
  textBG.position = text.position;
  textBG.pivot = text.position;
  textBG.rotation = rotation;

  textBG.addChild(text);
  return textBG
}