import spansTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/spansTooltip';

export const apiField = 'preferred_span';
export interface PreferredSpanField {
  type: string;
  label: string;
  apiField: string;
  tooltip: () => string;
  min: number;
  max: number;
}

function makeConfig(label: string =  'spansInput', min:number = 4, max: number = 144) : PreferredSpanField {
return {
  type: 'Number',
  label,
  apiField,
  min,
  max,
  tooltip: spansTooltip,
}
};

export const preferredSpanMetric = makeConfig('spansInputMetric', 2, 366);

const config = makeConfig();

export const smTiltPrSpans: PreferredSpanField = {
  type: 'Number',
  label: 'spansInput',
  apiField,
  min: 4,
  max: 72,
  tooltip: spansTooltip,
};

export default config;
