export const apiField = 'structure_type';

export const structureTypeOption = {
  p_1_elevated: {
    value: 1,
    name: '1-Portrait Elevated',
  },
  p_1_non_elevated: {
    value: 2,
    name: '1-Portrait Non Elevated',
  },
  p_2_elevated: {
    value: 3,
    name: '2-Portrait Elevated',
  },
  p_2_non_elevated: {
    value: 4,
    name: '2-Portrait Non Elevated',
  },
};

export enum StructureType {
  One_P_Elevated = 1,
  One_P_Non_Elevated = 2,
  Two_P_Elevated = 3,
  Two_P_Non_Elevated = 4,
}

export const is1PStructureType = (structureType: number) => [StructureType.One_P_Elevated, StructureType.One_P_Non_Elevated].includes(structureType)

export const is2PStructureType = (structureType: number) => [StructureType.Two_P_Elevated,  StructureType.Two_P_Non_Elevated].includes(structureType)

export interface structureTypeField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  onConfirm: () => void;
  shouldConfirm: () => any;
  data: structureTypeOption[];
  title?: string;
  content?: string;
}

export interface structureTypeOption {
  value: any;
  name: string;
}
