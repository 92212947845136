import * as PIXI from 'pixi.js';
import { dispatch, state } from '__common/store';
import { SET_METERS_PER_PIXEL } from 'actions';
import {
  onMouseOutside,
  onStageClick,
  onStageMouseMove,
  onStageMouseUp,
  } from '__editor/panelsEditor/components/stage/stageEvents';

let stage: PIXI.Container;

export function setStagedMouseEvents(domElement: HTMLElement) {
  stage
    .on('touchendoutside', onMouseOutside)
    .on('mouseupoutside', onMouseOutside);
  
  domElement.addEventListener('mousedown', onStageClick);
  domElement.addEventListener('mousemove', onStageMouseMove); 
  domElement.addEventListener('mouseup', onStageMouseUp);
  domElement.addEventListener('touchstart', onStageMouseMove);
  domElement.addEventListener('touchmove', onStageMouseMove);
  domElement.addEventListener('touchend', onStageMouseUp);
}

export const removeChild = (children: PIXI.DisplayObject[], childId: string | number) => children.filter((child: PIXI.Graphics) => child.id !== childId);

export function setupStage(width: number, height: number) {
  stage = new PIXI.Container();
  stage.hitArea = new PIXI.Rectangle(0, 0, width, height);
  stage.interactive = true;
  stage.interactiveChildren = true;
  window.stage = stage;
  return stage;
}

export function updateLayersOrder(children: PIXI.DisplayObject[]) {
  return children.sort((a, b) => {
    a.zIndex = a.zIndex || 0;
    b.zIndex = b.zIndex || 0;
    return a.zIndex - b.zIndex;
  });
}

export function getStage(): PIXI.Container {
  return stage;
}

export function getMeterPerPixel() {

  const { background: { cords, zoom } } = state();
  const mapSize = 1280;
  const newMeter = newMeterPerPixe(cords.lat, zoom, mapSize, mapSize * 2);
  dispatch(SET_METERS_PER_PIXEL(newMeter));
}

export function destroyPanelsEditorStage() {
  if (stage) {
    stage.destroy();
  }
}

function newMeterPerPixe(lat, zoom, mapWidth, mapWidthAfterResize) {
  const earthC = 6371000 * 2 * Math.PI;
  const factor = Math.pow(2, 8 + zoom);
  const MeterPerPixel = (Math.cos(lat * Math.PI / 180) * earthC / factor) / 2;
  const MapWidthDistance = mapWidth * MeterPerPixel;
  const ActualMeterPerPixel = MapWidthDistance / mapWidthAfterResize;
  return ActualMeterPerPixel;
}
