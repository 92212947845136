import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import ElevationTooltip, {elevationTooltipMetric} from 'projectDesign/components/projectConfiguration/fieldsTooltips/elevationTooltip';
export const apiField = 'elevation'
function makeConfig(label:string='elevationFt') {
  return {
    type: 'Number',
    label,
    apiField,
    missing:undefined,
    tooltip: label == 'elevationMetric' ? elevationTooltipMetric: ElevationTooltip,
  }
};

const config = makeConfig();

export const elevationMetric = makeConfig('elevationMetric');

export const getElevationField = (inputUnit: number) => isMetricUnit(inputUnit) ? elevationMetric : config;

export default config;




