import { state } from '__common/store';
import Polygon from 'polygon';
import { panelPolygon } from '../roofEdges/roofEdgesCollisions';
import polygonsIntersect from 'polygons-intersect';
import { getObstructionsZonesAreas, RoofZonesCorner } from '../roofZones/roofZones';
import { RectCircleColliding } from '../roofZones/roofZonesCollisionsHelper';
import { isRMDT, isRMIFIProduct } from '__common/constants/products';
import _ from 'lodash';
import { metersToInches } from '__common/calculations/metersToInches';
import { getRoofObstructions } from './obstructions';

export enum OBSTRUCTION_TYPE {
  NO_OBSTRUCTION = 0, // panel doesnt fall in any obstruction zone
  SMALL_OBSTRUCTION = 1, // panel falls in one or more small obstruction zones all with As <= 3m
  LARGE_OBSTRUCTION = 2, // panel falls in atleast one large obstruction zone with As > 3m
}

export const isPanelCollideWithObstacles = (
    panel: { x: number, y: number, width: number, height: number }, 
    roofId: number, 
    bgXOffset: number, 
    bgYOffset: number, 
    panelWidth: number, 
    panelHeight: number,
    metersPerPixel : number,
  ) => {
  const { obstructions: { obstructions } } = state();

  if (obstructions && obstructions[roofId] && Object.keys(obstructions[roofId]).length) {
    return Object.keys(obstructions[roofId]).some(obstructionId => {
      const obstruction: obstruction = obstructions[roofId][obstructionId];

      if (obstruction) {
        const obstructionPolygon = new Polygon(obstruction.coords);
        const panelPoly = panelPolygon(panel, false, bgXOffset, bgYOffset, panelWidth, panelHeight, metersPerPixel);

        const intersect = polygonsIntersect(obstructionPolygon.points, panelPoly.points);
        return intersect.length > 0;
      }
    });
  }
};

export const isPanelCollideWithObstructionsZones = (
  panel: panelInState, roofId: number, metersPerPixel: number, bgXOffset: number, bgYOffset: number, productId: number, obstructions
  ): boolean => {
  return ObstructionSetPanelCollidesWith(panel, roofId, metersPerPixel, bgXOffset, bgYOffset, productId, obstructions).size > 0;
};  

export const ObstructionSetPanelCollidesWith = (
  panel: panelInState, roofId: number, metersPerPixel: number, bgXOffset: number, bgYOffset: number, productId: number, obstructions,
  ): Set<string> => {

  const obstructionSet: Set<string> = new Set();
  
  const obstructionsForSelectedRoof = getRoofObstructions(roofId);
  const obstructionAreas = getObstructionsZonesAreas({selectedRoofId: roofId, metersPerPixel, bgOffSet: {x: bgXOffset, y: bgYOffset}, obstructions: obstructionsForSelectedRoof});

  if (!obstructionAreas.edges.length) {
    return obstructionSet;
  }

  const panelPoly = panelPolygon(panel, true, bgXOffset, bgYOffset, panel.width, panel.height, metersPerPixel);

  obstructionAreas.edges.map(edge => {
    const area = new Polygon(edge.slice(0, 4));
    const pointIsDefined = (point: { x: number, y: number } | undefined) => point !== undefined;
    const intersect = polygonsIntersect(area.points.filter(pointIsDefined), panelPoly.points);
    if (intersect.length > 0 && edge) {
      obstructionSet.add(edge[5]);
    }
  });

  obstructionAreas.corners.map((corner: RoofZonesCorner) => {

    if (corner.distance === 0) {
      return;
    }
    const circle = { x: corner.pos.x, y: corner.pos.y, r: corner.distance };
    const rect = { x: panel.x + bgXOffset - (panel.width / 2), y: panel.y + bgYOffset - (panel.height / 2), w: panel.width, h: panel.height };
    if (RectCircleColliding(circle, rect)) obstructionSet.add(corner.obstructionId);
  });

  return obstructionSet;
};

export const checkObstructionZoneForPanels = (panels: panelInState[], roofId: number, metersPerPixel: number, bgXOffset: number, bgYOffset: number): panelInState[] => {
  const { projectConfiguration: { productId }, obstructions: { obstructions } } = state();

  if (isRMDT(productId)) {
    const groupedPanels = _.groupBy(panels, 'siblingId');

    return _.reduce(groupedPanels, (acc, panels) => {
      if (panels && panels.length === 2) {
        const firstSibling = panels[0];
        const secondSibling = panels[1];

        if (
          isPanelCollideWithObstructionsZones(firstSibling, roofId, metersPerPixel, bgXOffset, bgYOffset, productId, obstructions) || 
          isPanelCollideWithObstructionsZones(secondSibling, roofId, metersPerPixel, bgXOffset, bgYOffset, productId, obstructions)
        ) {
          firstSibling.nearObstruction = true;
          secondSibling.nearObstruction = true;
        } else {
          firstSibling.nearObstruction = false;
          secondSibling.nearObstruction = false;
        }

        return acc.concat(firstSibling).concat(secondSibling);
      }
      
      return acc;
    },              []);
  }

  return panels.map(panel => {
    const obstructionList = Array.from(ObstructionSetPanelCollidesWith(panel, roofId, metersPerPixel, bgXOffset, bgYOffset, productId, obstructions));
    panel.nearObstruction = obstructionList.length > 0;
    if (isRMIFIProduct(productId)) {
      if (panel.nearObstruction) {
        const maxObstructionSetback = Math.max(...(obstructionList.map<number>(obstructionId => obstructions[roofId][obstructionId].setback)));
        if (maxObstructionSetback > metersToInches(3)) {
          panel.obstructionType = OBSTRUCTION_TYPE.LARGE_OBSTRUCTION;
        } else {
          panel.obstructionType = OBSTRUCTION_TYPE.SMALL_OBSTRUCTION;
        }
      }else {
        panel.obstructionType = OBSTRUCTION_TYPE.NO_OBSTRUCTION;
      }
    }
    return panel;
  });
};
