import * as React from 'react';
import { TOGGLE_WIND_ZONE, TOGGLE_EXP_ZONE, TOGGLE_RESTRICTED_ZONE, TOGGLE_OBSTRUCTIONS_ZONE, TOGGLE_SKEW_ZONE, TOGGLE_SKEW_AFFECTED_MODULES } from './roofZonesActions';
import { WIND_ZONE_FORMULA as RESIDENTIAL_WIND_ZONE_FORMULA, exposureZoneDistance, restrictedZoneDistance, windZonesDistanceForGableAndHip, windZonesDistanceForGableAndHipAscender } from '__editor/panelsEditor/components/roofZones/utils/asce716ResidentialZonesDistances';
import { COMMERCIAL_WIND_ZONE_FORMULA, getWindZoneDistanceForCommercial } from '__editor/panelsEditor/components/roofZones/utils/windZoneDistance';
import { isASCE716or722Selected, isFlatRoof, isHipRoof, isGableRoof } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { renderASCE716CommercialLegned, renderRMIFILegend, renderASCE710CommercialLegnedPerimeterZone } from './components/commercialLegend';
import { isCommercialProduct, isEcoFoot2Plus, isNxtHorizon, isResidentialProduct, isRM10, isRM10Evolution, isRMIFIProduct, isSFMSandSMF, isRM10orRM10Evo, isSMTiltPR, isRM5, isAscender, isRmGridflex10 } from '__common/constants/products';
import { renderASCE716FlatRoofLegend } from './components/flatRoofLegend';
import { SelectionControl } from 'react-md';
import { connect } from 'react-redux';
import { hipRoofLegend } from './components/hipRoofLegend';
import { gableRoofLegend } from './components/gableRoofLegend';
import { isBlankMap } from '__common/constants/map';
import { shouldUseSetBackDistance, shouldUseVirtualRoofEdges } from '__editor/panelsEditor/panelsEditorHelper';
import TooltippedSelectionControl from '__editor/panelsEditor/components/roofZones/components/tooltippedSelectionControl';
import { isValidForRoofZone } from './isValidForRoofZone';
import { applyEcoFoot2PlusRM10andEvoSetbackChanges, applyNewSkewVersion } from '__common/utils/versionCompare/versionCompare';
import { renderAscenderLegend } from './components/ascenderLegend';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { canShowSkewZone } from '__common/components/exposure/exposureHelper';

type Props = {
  toggleWindZone: () => void,
  toggleExpZone: () => void,
  toggleSkewZone: () => void,
  toggleSkewAffectedModules: () => void,
  toggleObstructionsZone: () => void,
  toggleRestrictedZone: () => void,
  windZoneEnabled: boolean,
  expZoneEnabled: boolean,
  skewZoneEnabled: boolean,
  skewAffectedModulesEnabled: boolean,
  obstructionsZoneEnabled: boolean,
  restrictedZoneEnable: boolean,
  mapType: string,
  buildingType: number,
  productId: number,
  roofPitch: string,
  tilt: number,
  setBackDistance: number,
  projectVersion: string,
  inputUnit: number,
  isStaff: boolean,
};

const virtualEdgesInfo = `
<div>
  <div class="content">
    Roof perimeter wind zones are based on wind tunnel testing and building dimensions.
  </div>
  <div class="content">
    When designing your array on a blank page, the roof perimeter is assumed to be a rectangle
    with a 3' roof setback encompassing all modules in the roof area.
    These roof edges are updated after adding each panel.
  </div>
  <div class="content">
    For more accurate roof perimeter wind zones, consider creating a new project design on map.
  </div>
</div>`;

class RoofZonesComponent extends React.Component<Props> {

  getFormulas = () => ({
    RESIDENTIAL: {
      WIND_ZONE: {
        FLAT: '0.6h',
        OTHERS: RESIDENTIAL_WIND_ZONE_FORMULA,
      },
      RESTRICTED_ZONE: '2h2',
      EXPOSURE_ZONE: 'd1 > 0.5h',
      OBSTRUCTIONS_ZONE: '',
      SKEW_ZONE: '',
    },
    COMMERCIAL: {
      WIND_ZONE: COMMERCIAL_WIND_ZONE_FORMULA,
      RESTRICTED_ZONE: '3ft',
      OBSTRUCTIONS_ZONE: 'obstruction h',
      EXPOSURE_ZONE: '',
      SKEW_ZONE: '5*h',
    },
    RMIFI: {
      WIND_ZONE: '',
      RESTRICTED_ZONE: '',
      OBSTRUCTIONS_ZONE: 'Min(0.5*sqrt(l1^2 + l2^2), Hv)',
      EXPOSURE_ZONE: '',
      SKEW_ZONE: '5*h',
    },
  })


  getRestrictedZoneLabel(productId, setBackDistance, inputUnit, formulas) {
    let label = 'Restricted zone ';
    if (isRM10orRM10Evo(productId) || isRmGridflex10(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRmGridflex10(productId)) {
      label += `(${setBackDistance} ${isMetricUnit(inputUnit) ? 'cm' : 'ft'})`
    } 
    else {
      label += formulas.RESTRICTED_ZONE
    }
    if (isSFMSandSMF(productId) || isNxtHorizon(productId)  || isSMTiltPR(productId)) {
      label += `= ${restrictedZoneDistance(productId).toFixed(2)} [ft]`
    }
    return label
  }

  renderLegend = () => {
    const { productId, buildingType, roofPitch} = this.props;

    if (isRMIFIProduct(productId)) {
      return renderRMIFILegend();
    }

    if (isAscender(productId)) {
      return renderAscenderLegend();
    }
    
    if ((isEcoFoot2Plus(productId) || (isRM10Evolution(productId)) || isRmGridflex10(productId)  || isRM10(productId) || isRM5(productId)) && !isASCE716or722Selected()) {
      return renderASCE710CommercialLegnedPerimeterZone();
    }
    if (isCommercialProduct(productId)) {
      return renderASCE716CommercialLegned();
    }

    if (isFlatRoof(buildingType)) {
      return renderASCE716FlatRoofLegend();
    }

    if (isHipRoof(buildingType)) {
      return hipRoofLegend(roofPitch);
    }

    if (isGableRoof(buildingType)) {
      return gableRoofLegend(roofPitch);
    }
  }

  render() {
    const {
      windZoneEnabled,
      restrictedZoneEnable,
      obstructionsZoneEnabled,
      mapType,
      expZoneEnabled,
      skewZoneEnabled,
      skewAffectedModulesEnabled,
      productId,
      buildingType,
      tilt,
      setBackDistance,
      projectVersion,
      inputUnit,
      isStaff,
    } = this.props;
    const showSetbackRestrictedZone = applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion);
    
    if (!(isRMIFIProduct(productId) || isRM5(productId)) && !isValidForRoofZone(productId, tilt)) {
      return null;
    }

    if (isBlankMap(mapType) && !isCommercialProduct(productId)) {
      return null;
    }

    const FORMULAS = isResidentialProduct(productId) ? this.getFormulas().RESIDENTIAL : isRMIFIProduct(productId) ? this.getFormulas().RMIFI : this.getFormulas().COMMERCIAL;
    let WIND_ZONE_RESIDENTIAL_LABEL: string;

    if (isResidentialProduct(productId) && isFlatRoof(buildingType)) {
      WIND_ZONE_RESIDENTIAL_LABEL = this.getFormulas().RESIDENTIAL.WIND_ZONE.FLAT;
    }

    if (isResidentialProduct(productId) && !isFlatRoof(buildingType)) {
      WIND_ZONE_RESIDENTIAL_LABEL = this.getFormulas().RESIDENTIAL.WIND_ZONE.OTHERS;
    }


    if (isBlankMap(mapType)) {
      return (
        <div className="roof-zones-component">
          {this.renderLegend()}
          {isCommercialProduct(productId) && <div className="roof-zones-controls">
            {shouldUseVirtualRoofEdges(mapType, productId, projectVersion) && !shouldUseSetBackDistance(mapType, productId, projectVersion) ?
              <TooltippedSelectionControl
                labelText={virtualEdgesInfo}
                id="wind-zone-checkbox"
                name="zone-checkboxes[]"
                label={`Wind zone (${WIND_ZONE_RESIDENTIAL_LABEL || FORMULAS.WIND_ZONE}) = ${getWindZoneDistanceForCommercial()} [ft]`}
                type="checkbox"
                onChange={() => this.props.toggleWindZone()}
                checked={windZoneEnabled}
                value="wind-zone"
              /> : null}
            <SelectionControl
              id="obstructions-zone-checkbox"
              name="zone-checkboxes[]"
              label={`Obstructions zone (${FORMULAS.OBSTRUCTIONS_ZONE})`}
              type="checkbox"
              onChange={() => this.props.toggleObstructionsZone()}
              checked={obstructionsZoneEnabled}
              value="obstruction-zone"
            />
          </div>}
        </div>
      );
    }
    return (
      <div className="roof-zones-component">
        {this.renderLegend()}
        <div className="roof-zones-controls">
          {!isRMIFIProduct(productId)  && <SelectionControl
            id="wind-zone-checkbox"
            name="zone-checkboxes[]"
            label={`Wind zone ${ isAscender(productId) ? ``: `(` + (WIND_ZONE_RESIDENTIAL_LABEL || FORMULAS.WIND_ZONE) +`)`} ${(isSFMSandSMF(productId) || isNxtHorizon(productId) || isSMTiltPR(productId) || isAscender(productId)) ? `= ${isAscender(productId) ? windZonesDistanceForGableAndHipAscender(): windZonesDistanceForGableAndHip()} [${isAscender(productId) ? `m`: `ft`}]` : ''}`}
            type="checkbox"
            onChange={() => this.props.toggleWindZone()}
            checked={windZoneEnabled}
            value="wind-zone"
          />}
          {isCommercialProduct(productId) && <SelectionControl
            id="obstructions-zone-checkbox"
            name="zone-checkboxes[]"
            label={`Obstructions zone (${FORMULAS.OBSTRUCTIONS_ZONE})`}
            type="checkbox"
            onChange={() => this.props.toggleObstructionsZone()}
            checked={obstructionsZoneEnabled}
            value="obstruction-zone"
          />}
          {!(isRMIFIProduct(productId) || isAscender(productId)  || isRmGridflex10(productId)) && (showSetbackRestrictedZone || isASCE716or722Selected()) && <SelectionControl
            id="restricted-zone-checkbox"
            name="zone-checkboxes[]"
            label={this.getRestrictedZoneLabel(productId, setBackDistance, inputUnit, FORMULAS)}
            type="checkbox"
            onChange={() => this.props.toggleRestrictedZone()}
            checked={restrictedZoneEnable}
            value="restricted-zone"
          />}
          {!(isRMIFIProduct(productId) || isAscender(productId)) && isASCE716or722Selected() && <SelectionControl
            id="exposure-zone-checkbox"
            name="zone-checkboxes[]"
            label={`Exposure zone (${FORMULAS.EXPOSURE_ZONE}) ${(isSFMSandSMF(productId)|| isNxtHorizon(productId) || isSMTiltPR(productId)) ? `= ${exposureZoneDistance()} [ft]` : ''}`}
            type="checkbox"
            onChange={() => this.props.toggleExpZone()}
            checked={expZoneEnabled}
            value="exposure-zone"
          />}
          {canShowSkewZone(productId, mapType, projectVersion) &&
          <>
          {isStaff && <SelectionControl
            id="skew-zone-checkbox"
            name="zone-checkboxes[]"
            label={`Skew Boundary ${applyNewSkewVersion(projectVersion) ? (FORMULAS.SKEW_ZONE) : ""}`}
            type="checkbox"
            onChange={this.props.toggleSkewZone}
            checked={skewZoneEnabled}
            value="skew-zone"
          />}
          <SelectionControl
            id="skew-modules-checkbox"
            name="zone-checkboxes[]"
            label="Skew Affected Modules"
            type="checkbox"
            onChange={this.props.toggleSkewAffectedModules}
            checked={skewAffectedModulesEnabled}
            value="skew-affected-modules"
          />
          </>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (appState: appState) => {
  return {
    windZoneEnabled: appState.roofZones.windZone,
    expZoneEnabled: appState.roofZones.expZone,
    skewZoneEnabled: appState.roofZones.skewZone,
    skewAffectedModulesEnabled: appState.roofZones.skewAffectedModules,
    obstructionsZoneEnabled: appState.roofZones.obstructionsZone,
    restrictedZoneEnable: appState.roofZones.restrictedZone,
    mapType: appState.roofsSelector.mapType,
    buildingType: appState.projectConfiguration.projectEnvConfig.building_type,
    productId: appState.projectConfiguration.productId,
    roofPitch: appState.tiltedRoof.roofPitch,
    tilt: appState.projectConfiguration.projectEnvConfig.tilt,
    setBackDistance: appState.projectConfiguration.projectEnvConfig.setback_distance,
    projectVersion: appState.projectConfiguration.projectVersion,
    inputUnit: appState.projectConfiguration.inputUnit,
    isStaff: appState.user.isStaff,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  toggleWindZone: () => dispatch(TOGGLE_WIND_ZONE()),
  toggleExpZone: () => dispatch(TOGGLE_EXP_ZONE()),
  toggleObstructionsZone: () => dispatch(TOGGLE_OBSTRUCTIONS_ZONE()),
  toggleRestrictedZone: () => dispatch(TOGGLE_RESTRICTED_ZONE()),
  toggleSkewZone: () => dispatch(TOGGLE_SKEW_ZONE()),
  toggleSkewAffectedModules: () => dispatch(TOGGLE_SKEW_AFFECTED_MODULES()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoofZonesComponent);

// && !isAscender(productId) 
