export const apiField = 'building_height_greater_than_6_storey';

const config = {
  type: 'Select',
  label: 'Is building more than 6 stories?',
  apiField,
  data: [
    { value: 1, name: 'YES' },
    { value: 0, name: 'NO' },
  ],
  missing: undefined,
};

export default config;