import { isRmGridflex10 } from '__common/constants/products';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import longestBuildingLengthTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/longestBuildingLengthTooltip';

export const apiField = 'building_length';

export const makeConfig = (min: number = 20, max: number = 2000, label: string = 'longestBuildingLength') => {
  return  {
    type: 'Number',
    apiField: apiField,
    label,
    missing: undefined,
    tooltip: longestBuildingLengthTooltip,
    min,
    max,
  }
};

const config = makeConfig();
export const longestBuildingLengthMetric = makeConfig(6.1, 609.6, 'longestBuildingLengthMetric');
// for GF10 in metric
export const longestBuildingLengthGF10Metric = makeConfig(6.1, 914.4, 'longestBuildingLengthMetric');
// for GF10 in feet
export const longestBuildingLengthGF10 = makeConfig(20, 3000, 'longestBuildingLength');

export const longestBuildingLengthField = (inputUnit: number, productId: number) => isMetricUnit(inputUnit) ? isRmGridflex10(productId) ? longestBuildingLengthGF10Metric : longestBuildingLengthMetric : isRmGridflex10(productId) ? longestBuildingLengthGF10 : config;

export default config;
