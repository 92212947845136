import { kdTree } from '__common/vendor/kdTree';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';

let stickPoints = new kdTree([], calcDistanceOfTwoPoints, ['x', 'y', 'corner']);

export function insertKdTreePoints(points: stickyPoints[], groupId: number, panelId: number) {
  points.map((point) => {
    point.groupId = groupId;
    point.panelId = panelId;
    stickPoints.insert(point);
  });
}

export function removeKdTreePoints(points: stickyPoints[]) {
  points.map((point) => { 
    return stickPoints.remove(point);
  });
}

interface kdTreePoint {
  corner: number;
  groupId: number;
  panelId: number;
  x: number;
  y: number;
}

export function nearestKdTreePoint(point: { x: number, y: number }, count = 1, distance = 50): [kdTreePoint, number][] {
  return stickPoints.nearest(point, count, distance);
}

export function resetKdTree() {
  stickPoints = new kdTree([], calcDistanceOfTwoPoints, ['x', 'y']);
}
