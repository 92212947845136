import { createReducer } from '__common/utils/helpers';
import { ForgotPasswordActionTypes } from 'actionsConstants';

export let forgotPasswordState: forgotPasswordState = {
  errorEmail: false, 
  errorPassword: false,
  sending: false,
  successEmail: false,
  successPassword: false,
  changeEmail: false,
  changePassword: false,
};

export default {
  forgotPassword: createReducer(forgotPasswordState, {
    [ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND](state) {
      return { ...state, sending: true };
    },
    [ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND_SUCCESS](state) {
      return { ...state, sending: false, successEmail: true };
    },
    [ForgotPasswordActionTypes.RESET_PASSWORD_ON_DEMAND_ERROR](state) {
      return { ...state, sending: false, errorEmail: true };
    },
    [ForgotPasswordActionTypes.RESET_PASSWORD_CLEAR](state) {
      return { 
        errorEmail: false, 
        errorPassword: false,
        sending: false,
        successEmail: false,
        successPassword: false,
        changeEmail: false,
        changePassword: false,
      };
    },
    [ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND](state) {
      return { ...state, sending: true };
    },
    [ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND_SUCCESS](state) {
      return { ...state, sending: false, successPassword: true };
    },
    [ForgotPasswordActionTypes.CHANGE_PASSWORD_ON_DEMAND_ERROR](state) {
      return { ...state, sending: false, errorPassword: true };
    },
    [ForgotPasswordActionTypes.SET_EMAIL_PAGE](state) {
      return { ...state, changeEmail: true, changePassword: false, sending: false, success: false, error: false };
    },
    [ForgotPasswordActionTypes.SET_PASSWORD_PAGE](state) {
      return { ...state, changeEmail: false, changePassword: true, sending: false, success: false, error: false };
    },
  }),
};
