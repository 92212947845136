import * as React from 'react';
import autobind from 'autobind-decorator';
import Icon from '__common/components/Icon';
import { changeAttachment } from 'bom/components/utils/partsSelectionFunctions';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import { find } from 'lodash';
import { state } from '__common/store';

export default class Attachments extends React.Component<attachmentsComponentState, {}> {
  @autobind
  changeAttachment(attachment: string) {
    const { attachmentGroup, attachments } = this.props;
    changeAttachment(attachment, attachmentGroup, attachments);
  }

  parseAttachments(attachments: bomPartsList) {
    return attachments.map(attachment => ({ label: attachment.name, value: attachment.number }));
  }

  hideAttachments(attachments: { label: string, value: string }[]) {
    const selectedAttachments = state().bom.selectedAttachmentType;

    if (selectedAttachments && selectedAttachments['tile hooks']) {
      const tileHookSpanishCT1 = find(selectedAttachments['tile hooks'], hook => hook.number === '004CT1S');

      if (tileHookSpanishCT1 && !tileHookSpanishCT1.selected) {
        return attachments.filter(attachment => attachment.value !== '004FLCT');
      }
    }

    return attachments;
  }

  getSelectedPartNumber(attachments: bomPartsList) {
    const selected = attachments.filter(attachment => attachment.selected);
    return selected.length ? selected[0].number : '';
  }

  render() {
    const { attachments, attachmentGroup } = this.props;
    const parsedAttachments = this.parseAttachments(attachments);
    const readyAttachments = this.hideAttachments(parsedAttachments);
    const getSelectedPartNumber = this.getSelectedPartNumber(attachments);
    return (
      <div className="attachment-types-selector">
        <div className="control-panel-sublabel">
            {attachmentGroup.toUpperCase()}
        </div>
        <SelectionControlGroup
          id={`attachments-selection-control-group-radios-${attachmentGroup}`}
          name={`attachments-${attachmentGroup}`}
          type="radio"
          value={getSelectedPartNumber === 'None' ? '' : getSelectedPartNumber}
          controls={readyAttachments}
          onChange={this.changeAttachment}
          checkedRadioIcon={(<Icon>check</Icon>)}
          uncheckedRadioIcon={(<Icon>check</Icon>)}
          inline={true}
        />
      </div>
    );
  }
}

