import * as PIXI from 'pixi.js';
import LeadEdge from '__editor/panelsEditor/models/leadEdge';
import { BACKGROUND_SET_ROTATION } from 'actions';
import { dispatch, state } from '__common/store';
import { getAngle, polygonIsClockwise } from '__editor/panelsEditor/components/leadEdge/utils/backgroundRoofEdgesRotation';
import { latLngToPixels } from '__editor/panelsEditor/components/background/utils/backgroundCordinatesConverter';
import { removeChild } from '__editor/panelsEditor/components/stage/stage';
import { degreesToRadians } from 'maths';
import { getRoofEdgesPointsInPx } from '../background/background';
import { getRotatedPoint } from '__common/calculations/prependicularLines';

export function convertToPixelPoints(leadEdgePath: { lat: number, lng: number}[], roofCenter: cordPoint, zoom: number): pixelPoint[] {
  return leadEdgePath.map(cord => latLngToPixels(cord, roofCenter, zoom));
}

export function rotateBgToLeadEdge() {
  const { 
    background: { 
      selectedRoofId, 
      roofEdges, 
      cords, 
      zoom,
      panelsRotationDegrees,
    },
    drawingManager: {roofs},
  } = state();
  if(selectedRoofId) {
    const paths = getLeadEdge(selectedRoofId);
    const leadEdgeCords = convertToPixelPoints(paths, cords, zoom);
    const angle = getAngle(leadEdgeCords, roofEdges, cords, zoom);
    const rotationDegrees = roofs[selectedRoofId].panelsRotationDegrees && !panelsRotationDegrees  ? roofs[selectedRoofId].panelsRotationDegrees : panelsRotationDegrees;
    dispatch(BACKGROUND_SET_ROTATION(angle + rotationDegrees));
  }
}

export function drawLeadEdge() {
  const { background: { selectedRoofId, cords, zoom } } = state();
  const leadEdgeCords = convertToPixelPoints(getLeadEdge(selectedRoofId), cords, zoom);
  return LeadEdge(leadEdgeCords);
}

export const removeLeadEdge = (children: PIXI.DisplayObject[]): any => removeChild(children, 'leadEdge');

export const removeRotatedLeadEdge = (children: PIXI.DisplayObject[]): any => removeChild(children, 'rotatedLeadEdge');

export function getLeadEdge(roofId: number, newCoords?: cordPoint[]): cordPoint[] {
  const { leadEdgeRoofSelector: { leadEdges }, drawingManager: { roofs } } = state();
  const selectedLeadEdgeNumber = leadEdges[roofId]? leadEdges[roofId]: 0;
  const coords = newCoords? newCoords: roofs[roofId].coords;
  const path1 = coords[selectedLeadEdgeNumber];
  let path2 = coords[selectedLeadEdgeNumber + 1];

  if (path2 === undefined) {
    path2 = coords[0];
  }

  return [path1, path2];
}

export function drawRotatedLeadEdge() {
  const { background: { selectedRoofId, cords, zoom, panelsRotationDegrees, roofEdges,} } = state();
  let leadEdgeCords = convertToPixelPoints(getLeadEdge(selectedRoofId), cords, zoom);
  const points = getRoofEdgesPointsInPx(roofEdges, cords, zoom);

  const clockwise: boolean = polygonIsClockwise(points);
  let pivot = 1
  if(clockwise) pivot = 0;

  // rotating it with respect to the second lead edge coord if anti-clockwise else first lead edge if clockwise
  leadEdgeCords[ (pivot+1) % 2] = getRotatedPoint(leadEdgeCords[(pivot+1) % 2], -panelsRotationDegrees, leadEdgeCords[pivot]);

  return LeadEdge(leadEdgeCords, true);
}