import * as PIXI from 'pixi.js';
import document from 'global/document';
import Polygon from 'polygon';
import { getRoofEdges, getRealRoofEdgesBoundsMemoized as  getRealRoofEdgesBounds, getVirtualRoofEdgesPolygon } from '__editor/panelsEditor/components/background/background';
import { getStage } from '__editor/panelsEditor/components/stage/stage';
import { state } from '__common/store';
import { isRMDT, isGFT, isULA, isAscender, isRMGridFlex } from '__common/constants/products';
import { isCloserFromEdgeThen } from '../roofZones/roofZones';
import moize from 'moize';
import { shouldUpdateRoofEdgesOnPanelChange, shouldUseDesiredBuildingLengthWidth, shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { isBlankMap } from '__common/constants/map';
import Polyk from 'polyk';
import { inchesToMeters } from 'maths';
import { greaterThanEqualToProjectVersion } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';

interface panelDimension {
  x: number;
  y: number;
  maxX: number;
  maxY: number;
}

export const getPanelPositionInfo = (
    panel: panel | cursor,
    relativeToCenter: boolean = false, 
    bgXOffset: number,
    bgYOffset: number, 
    roofEdges: { lat: number, lng: number }[],
    roofCenter: { lat: number, lng: number},
    zoom: number,
    rotationRadians: number,
    roofPitch: string,
    metersPerPixel : number,
  ): { isInside: boolean, closestDistancesFromEdges?: {dist:number,edge:number}[]} => {
    const { roofsSelector: { mapType }, projectConfiguration: { productId , projectVersion, projectEnvConfig: {tilt}}, background: {roofEdgesPixiCords}} = state();

    if (isBlankMap(mapType) && !shouldUseSetBackDistance(mapType, productId, projectVersion)){
      return { isInside: true };
    }

    const panelPoly = getPanelBounds(panel, relativeToCenter, bgXOffset, bgYOffset, metersPerPixel);
    const roofEdgesPolygon = getRoofBoundsMemoized({roofEdges, roofCenter, roofEdgesPixiCords, bgOffSet: {x: bgXOffset, y: bgYOffset}, zoom, rotationRadians, roofPitch, tilt, productId, mapType});
    
    if (!roofEdgesPolygon) {
      return roofEdgesPolygon;
    }

    const distances = detectDistancesFromEdge(roofEdgesPolygon, panelPoly);
    return {
      isInside: shouldUpdateRoofEdgesOnPanelChange(mapType, productId, projectVersion) ? true : roofEdgesPolygon.contains(panelPoly),
      closestDistancesFromEdges: distances.sort((a, b) =>  { return a.dist - b.dist; }),
    };
};

export const getPanelBounds = (
  panel: panel | cursor,
  relativeToCenter: boolean = false, 
  bgXOffset: number,
  bgYOffset: number, 
  metersPerPixel : number
) => {

  const panelPoly = panelPolygon(panel, relativeToCenter, bgXOffset, bgYOffset, panel.width, panel.height, metersPerPixel);

  return panelPoly;

};


export const getRoofBounds = (
  input: {
  roofEdges: { lat: number, lng: number }[],
  roofCenter: { lat: number, lng: number}, 
  roofEdgesPixiCords,
  bgOffSet: {x: number, y: number,}
  zoom: number,
  rotationRadians: number,
  roofPitch: string,
  productId: number,
  tilt: number,
  mapType: string,
  }
) => {

  const {roofEdgesPixiCords, roofEdges, roofCenter, bgOffSet, zoom, rotationRadians, roofPitch, mapType, productId, tilt} = input;
  const {projectConfiguration: {projectVersion}} = state();

  const roofEdgesPolygon: any =  shouldUseDesiredBuildingLengthWidth(mapType, productId, projectVersion) ? getVirtualRoofEdgesPolygon(roofEdgesPixiCords): getRealRoofEdgesBounds({roofEdges, roofCenter, bgOffSet, zoom, rotationRadians, roofPitch, productId, tilt});
  
  return roofEdgesPolygon;
  
};

export const getRoofBoundsMemoized = moize(getRoofBounds, { isSerialized: true });

export function isPanelOnCorner(panelClosestDistances: {dist:number,edge:number}[], productId: number, metersPerPixel: number, useNewZoneClassification: boolean) {
  const distancesClosersThen3Feets = panelClosestDistances.filter((distance) => {
    return isCloserFromEdgeThen(distance.dist, productId, metersPerPixel, useNewZoneClassification);
  });
  const numberOfClosestEdges = distancesClosersThen3Feets.map((distance) => {
    return distance.edge;
  });

  return new Set(numberOfClosestEdges).size > 1;
}

export function getRoofEdgesCoords(): { x: number, y: number }[] | null {
  const roofEdges = getRoofEdges();
  if (!roofEdges) {
    return null;
  }
  const roofEdgesDots: any = roofEdges.children;
  const stage = getStage();

  if (isRoofEdgeOffScreen(stage, roofEdgesDots[0])) {
    return null;
  }

  return roofEdgesDots.map(dot => ({
    x: stage.toLocal(dot.getBounds()).x,
    y: stage.toLocal(dot.getBounds()).y,
  }));
}

function getSinglePanelPolygonDims(
  panel: panelInState,
  relativeToCenter: boolean,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
): panelDimension {
  let x: number;
  let y: number;
  let maxX: number;
  let maxY: number;

  x = panel.x - (panelWidth / 2);
  y = panel.y - (panelHeight / 2);

  if (relativeToCenter) {
    x += bgXOffset;
    y += bgYOffset;
  }

  maxX = x + panelWidth;
  maxY = y + panelHeight;

  return {
    x,
    y,
    maxX,
    maxY,
  };
}

function getPanelPolygonDims(
  panel: panelInState | cursor,
  relativeToCenter: boolean,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  productId: number,
):panelDimension  {
  let x: number;
  let y: number;
  let maxX: number;
  let maxY: number;


  if (isRMDT(productId)) {
    x = panel.x - panelWidth;
    y = panel.y - panelHeight / 2;
  } else if (isGFT(productId) || isULA(productId) ||  isAscender(productId)) {
    // coordinates (panel.x, panel.y) is the center of the Panel/Cursor
    // update x, y to point to the Top Left Corner
    x = panel.x - (panel.width / 2);
    y = panel.y - (panel.height / 2);
  } else {
    x = panel.x - (panelWidth / 2);
    y = panel.y - (panelHeight / 2);  
  }

  if (relativeToCenter) {
    x += bgXOffset;
    y += bgYOffset;
  }

  if (isRMDT(productId)) {
    maxX = x + panelWidth * 2;
    maxY = y + panelHeight;
  } else if (isGFT(productId) || isULA(productId) ||  isAscender(productId)) {
    maxX = x + panel.width;
    maxY = y + panel.height;
  } else {
    maxX = x + panelWidth ;
    maxY = y + panelHeight;
  }

  return {
    x,
    y,
    maxX,
    maxY,
  };
}

export function singlePanelPolygon(
  panel: panelInState,
  relativeToCenter: boolean,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  metersPerPixel: number,
  index?: number,
): PolygonInterface {
  const {projectConfiguration : {projectVersion, productId},} = state()
  const gf5SouthRailDistanceFromModuleEdge = 20 // 20 inches fixed for south ballast rail length from module edge in GF5.
  const gf5SouthRailLength = inchesToMeters(gf5SouthRailDistanceFromModuleEdge)

  const { x, y, maxX, maxY } = getSinglePanelPolygonDims(panel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight);

  return new Polygon([{
    x,
    y,
    index,
  },
  {
    x: maxX,
    y,
    index,
  },
  {
    x: maxX,
    y: (isRMGridFlex(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['gf5_south_row_panel_editor_updates'])) ? maxY+(gf5SouthRailLength/metersPerPixel) : maxY,
    index,
  },
  {
    x,
    y: (isRMGridFlex(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['gf5_south_row_panel_editor_updates'])) ? maxY+(gf5SouthRailLength/metersPerPixel) : maxY,
    index,
  },
  ]);
}

export function panelAreaPolygonBound(topLeftPanel: panelInState | cursor,  downRightPanel: panelInState | cursor, relativeToCenter: boolean, bgXOffset: number, bgYOffset: number, panelWidth: number, panelHeight: number, index?: number): PolygonInterface {
  const { projectConfiguration: { productId }} = state();
  const { x: x0, y: y0} = getPanelPolygonDims(topLeftPanel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, productId);
  const {maxX: maxX1, maxY: maxY1 } = getPanelPolygonDims(downRightPanel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, productId);
  return new Polygon([{
    x: x0,
    y: y0,
    index,
  },
  {
    x: maxX1,
    y: y0,
    index,
  },
  {
    x: maxX1,
    y: maxY1,
    index,
  },
  {
    x: x0,
    y: maxY1,
    index,
  },
  ]);
}

export function panelPolygon(panel: panelInState | cursor, relativeToCenter: boolean, bgXOffset: number, bgYOffset: number, panelWidth: number, panelHeight: number, metersPerPixel: number, index?: number): PolygonInterface {
  const { projectConfiguration: { projectVersion, productId }} = state();
  const gf5SouthRailDistanceFromModuleEdge = 20 // 20 inches fixed for south ballast rail length from module edge in GF5.metersPerPixel: number,
  const gf5SouthRailLength = inchesToMeters(gf5SouthRailDistanceFromModuleEdge)

  const { x, y, maxX, maxY } = getPanelPolygonDims(panel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight, productId);
  
  return new Polygon([{
    x,
    y,
    index,
  },
  {
    x: maxX,
    y,
    index,
  },
  {
    x: maxX,
    y: (isRMGridFlex(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['gf5_south_row_panel_editor_updates'])) ? maxY+(gf5SouthRailLength/metersPerPixel) : maxY,
    index,
  },
  {
    x,
    y: (isRMGridFlex(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['gf5_south_row_panel_editor_updates'])) ? maxY+(gf5SouthRailLength/metersPerPixel) : maxY,
    index,
  },
  ]);
}

function isRoofEdgeOffScreen(stage: PIXI.Container, roofEdgesDot: any) {
  const documentWidth = document.body.clientWidth;
  const documentHeight = document.body.clientHeight;
  const x = stage.toLocal(roofEdgesDot.getBounds()).x;
  const y = stage.toLocal(roofEdgesDot.getBounds()).y;

  return x < -documentWidth || x > documentWidth || y < -documentHeight || y > documentHeight;
}

function detectDistancesFromEdge(roofEdgesPolygon, panelPoly) {
  const roofEdgesPoints = roofEdgesPolygon.points.reduce((acc, points) => {
    acc.push(points.x);
    acc.push(points.y);
    return acc;
  },                                                     []);
  const firstPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[0].x, panelPoly.points[0].y);
  const secondPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[1].x, panelPoly.points[1].y);
  const thirdPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[2].x, panelPoly.points[2].y);
  const fourthPanelEdgeDist = Polyk.ClosestEdge(roofEdgesPoints, panelPoly.points[3].x, panelPoly.points[3].y);

  return [firstPanelEdgeDist, secondPanelEdgeDist, thirdPanelEdgeDist, fourthPanelEdgeDist];
}
