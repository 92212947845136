import { dispatch, state } from '__common/store';
import { SET_DIMENSIONS } from 'actions';

export function updatePanelsDimensions() {
  const { background: { metersPerPixel }, panels: { panels }, settings: { panelWidth, panelHeight }, projectConfiguration:{projectEnvConfig:{is_aurora_project}} } = state();

  if (panels.length === 0) {
    return dispatch(SET_DIMENSIONS(0, 0));
  }
  const minAndMaxDims = panels.reduce((total, panel) => {
    const minX = panel.x - panelWidth / 2;
    const minY = panel.y - panelHeight / 2;
    const maxX = panel.x + panelWidth / 2;
    const maxY = panel.y + panelHeight / 2;

    if (!total.minX) {
      total.minX = minX;
    } else if (total.minX > minX) {
      total.minX = minX;
    }

    if (!total.minY) {
      total.minY = minY;
    } else if (total.minY > minY) {
      total.minY = minY;
    }

    if (!total.maxX) {
      total.maxX = maxX;
    } else if (total.maxX < maxX) {
      total.maxX = maxX;
    }

    if (!total.maxY) {
      total.maxY = maxY;
    } else if (total.maxY < maxY) {
      total.maxY = maxY;
    }

    return total;
  },                                  {});

  const minY = minAndMaxDims.minY;
  const maxY = minAndMaxDims.maxY;
  const minX = minAndMaxDims.minX;
  const maxX = minAndMaxDims.maxX;

  const ns = (maxY - minY) * metersPerPixel;
  const ew = (maxX - minX) * metersPerPixel;

  if(!is_aurora_project) dispatch(SET_DIMENSIONS(ns, ew));
}

