import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { bingMapRelocation, zoomInBingMap, zoomOutBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { googleMapRelocation, zoomInGoogleMap, zoomOutGoogleMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { isBingMap, isGoogleMap } from '__common/constants/map';

type ZoomingButtonsState = {
  advanceRoofSelectorEnabled: boolean,
  mapType: string,
  relocated: boolean,
};
export default class ZoomingButtons extends React.Component<ZoomingButtonsState, {}> {

  @autobind
  zoomIn() {
    const { mapType, advanceRoofSelectorEnabled } = this.props;

    if (advanceRoofSelectorEnabled) {
      return;
    }

    if (mapType === 'google') {
      zoomInGoogleMap();
    }

    if (mapType === 'bing') {
      zoomInBingMap();
    }
  }

  @autobind
  zoomOut() {
    const { mapType, advanceRoofSelectorEnabled } = this.props;

    if (advanceRoofSelectorEnabled) {
      return;
    }

    if (mapType === 'google') {
      zoomOutGoogleMap();
    }

    if (mapType === 'bing') {
      zoomOutBingMap();
    }
  }

  @autobind
  relocation() {
  const { mapType, advanceRoofSelectorEnabled } = this.props;

    if (advanceRoofSelectorEnabled) {
      return;
    }

    if (isGoogleMap(mapType)) {
      googleMapRelocation();
    }
    if (isBingMap(mapType)) {
      bingMapRelocation();
    }
  }


  render() {
    const { relocated } = this.props
    return (
      <div className="zooming-tools buttons-containers">
       {
         relocated ? (
        <Button
           tooltipLabel="roof location" 
           tooltipPosition="top" 
           flat={true} 
           onClick={this.relocation} 
           className="roofs-selector-button roofs-selector-zoom-in"
           
        >
          <SVG src={require('assets/media/icons/relocation_icon.svg')} />
        </Button>
          ) : null
        }
        <Button 
          tooltipLabel="zoom in map" 
          tooltipPosition="top" 
          flat={true} 
          onClick={this.zoomIn} 
          className="roofs-selector-button roofs-selector-zoom-in"
        >
          <SVG src={require('assets/media/icons/plus_icon.svg')} />
        </Button>
        <Button 
          tooltipLabel="Zoom out map" 
          tooltipPosition="top" 
          flat={true} 
          onClick={this.zoomOut} 
          className="roofs-selector-button roofs-selector-zoom-out"
        >
          <SVG src={require('assets/media/icons/minus_icon.svg')} />
        </Button>
      </div>
    );
  }
}
