import { RoofsSelectorActionTypes } from 'actionsConstants';

export function SET_DEFAULT_MAP_ROOFS_SELECTOR() {
  return {
    type: RoofsSelectorActionTypes.SET_DEFAULT_MAP_ROOFS_SELECTOR,
  };
}

export function SET_GOOGLE_MAPS_ROOFS_SELECTOR() {
  return {
    type: RoofsSelectorActionTypes.SET_GOOGLE_MAPS_ROOFS_SELECTOR,
  };
}

export function SET_BING_MAPS_ROOFS_SELECTOR() {
  return {
    type: RoofsSelectorActionTypes.SET_BING_MAPS_ROOFS_SELECTOR,
  };
}

export function SET_BLANK_MAPS_ROOFS_SELECTOR() {
  return {
    type: RoofsSelectorActionTypes.SET_BLANK_MAPS_ROOFS_SELECTOR,
  };
}

export function SET_IMAGE_ROOFS_SELECTOR(image: string) {
  return {
    type: RoofsSelectorActionTypes.SET_IMAGE_ROOFS_SELECTOR,
    payload: {
      image,
    },
  };
}

export function SET_MAP_ROOFS_SELECTOR(mapType: string) {
  return {
    type: RoofsSelectorActionTypes.SET_MAP_ROOFS_SELECTOR,
    payload: {
      mapType,
    },
  };
}

export function SET_MAP_STATE(mapTypeSelected: boolean) {
  return {
    type: RoofsSelectorActionTypes.SET_MAP_STATE,
    payload: {
      mapTypeSelected,
    },
  };
}

export function CLEAR_ROOFS_SELECTOR() {
  return {
    type: RoofsSelectorActionTypes.CLEAR_ROOFS_SELECTOR,
  };
}
