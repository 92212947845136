/* tslint:disable:no-eval */
import { CLEAR_TILTED_ROOF, SET_TILTED_ROOF, SET_TILTED_ROOF_STRUCTURE_TILT } from 'actions';
import { dispatch, state } from '__common/store';
import { getNewRoofId } from '../../../googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { isASCE716BuildingCode, isASCE716or722BuildingCode, isFlatRoof } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { isCommercialProduct, products, isRMIFIProduct, isEcoFoot2Plus, isSMTiltPR, isRM5, isRM10orRM10Evo, isRMGridFlex, isRmGridflex10 } from '__common/constants/products';
const PROJECTS_WITH_ROOF_PITCH = [products.sunframe, products.solarmount_2, products.sfmEnhanced, products.sfmInfinity, products.metal_x, products.rm_gridflex,products.nxt_umount, products.solarmount_2_tilt, products.nxt_tilt];

export const ROOF_PITCH: { [key: string]: number } = {
  ['1/12']: 5,
  ['2/12']: 9,
  ['3/12']: 14,
  ['4/12']: 18,
  ['5/12']: 23,
  ['6/12']: 27,
  ['7/12']: 30,
  ['8/12']: 34,
  ['9/12']: 37,
  ['10/12']: 40,
  ['11/12']: 43,
  ['12/12']: 45,
};
export const ROOF_PITCH_GRIDFLEX: { [key: string]: string } = {
  ['---']: '0',
  ['0']: '0',
  ['1']: '1 / 57',
  ['2']: '3 / 86',
  ['3']: '1 / 19',
  ['4']: '3 / 43',
  ['5']: '7 / 80',
  ['6']: '2 / 19',
  ['7']: '7 / 57',
};

// adding toDetermineZoomLevel parameter to initialize zoom for panel editor else scaling only in y-direction
export const getScaleFromPitchRoof = (roofPitch: string, productId: number, toDetermineZoomLevel: boolean = false) => {
  let pitch;

  // roofpitch has not effect on scaling in y-direction for RM10, RM10EVO
  if (isRM10orRM10Evo(productId) || isRmGridflex10(productId) && !toDetermineZoomLevel) return 1;
  
  if (roofPitch === '---' && !isRMGridFlex(productId)) {
    pitch = eval('1/12');
  } else if (ROOF_PITCH_GRIDFLEX[roofPitch]) {
    pitch = eval(ROOF_PITCH_GRIDFLEX[roofPitch]);
  } else {
    pitch = eval(roofPitch);
  }

  const { settings: { panelHeight } } = state();
  const a = panelHeight * pitch;
  const ratio = Math.sqrt(a ** 2 + panelHeight ** 2);
  return ratio / panelHeight;
};

export const loadRoofPitch = () => {
  const { projectConfiguration: { productId, projectEnvConfig: { building_code, building_type } } } = state();
  const { background: { selectedRoofId }, drawingManager: { roofs } } = state();
  const roofPitch = roofs[selectedRoofId].roofPitch;
  if (roofPitch) {
    dispatch(SET_TILTED_ROOF(roofPitch));
  } else {
    dispatch(CLEAR_TILTED_ROOF(productId, building_code, building_type));
  }
};

export const loadStructureTilt = () => {
  const { background: { selectedRoofId }, drawingManager: { roofs } } = state();
  const structureTilt = roofs[selectedRoofId].structureTilt;
  if (structureTilt || structureTilt === 0) {
    dispatch(SET_TILTED_ROOF_STRUCTURE_TILT(String(structureTilt)));
  } 
};

export const getLastRoofPitch = () => {
  const { drawingManager: { roofs } } = state();

  if (roofs && Object.keys(roofs).length) {
    const lastRoof = roofs[Number(getNewRoofId()) - 1];
    return lastRoof.roofPitch;
  }
};

export const getDefaultRoofPitchValue = (productId: number, buildingCode: number, buildingType: number) => {
  const isProjectWithRoofPitch = PROJECTS_WITH_ROOF_PITCH.includes(productId);
  const nonRoofPitch = null;
  const defaultRoofPitch = isSMTiltPR(productId) ? null : '---' ;
  const defaultRoofPitchForGridflex = '---';
  const defaultRoofPitchForEcoFoot2Plus = '---';
  const defaultRoofPitchForRM = '0';
  if (isRMIFIProduct(productId)) {
    return defaultRoofPitchForGridflex;
  }

  if(isRM5(productId) || isRM10orRM10Evo(productId) || isRmGridflex10(productId)){
    return defaultRoofPitchForRM;
  }
  
  if(isEcoFoot2Plus(productId)) {
    return defaultRoofPitchForEcoFoot2Plus;
  }

  if (isCommercialProduct(productId) || !isProjectWithRoofPitch) {
    return nonRoofPitch;
  }

  if (!isASCE716or722BuildingCode(buildingCode) && isProjectWithRoofPitch) {
    return defaultRoofPitch;
  }

  if (isASCE716or722BuildingCode(buildingCode) && !isFlatRoof(buildingType) && isProjectWithRoofPitch) {
    return defaultRoofPitch;
  }

  if (isASCE716or722BuildingCode(buildingCode) && isFlatRoof(buildingType)) {
    return nonRoofPitch;
  }
};
