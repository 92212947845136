import EngineeringBallastMapsComponent from 'engineering/components/engineeringBallastMaps/engineeringBallastMapsComponent';
import React from 'react';
import { isRM10, isRM5, isRMDT, isRMGridFlex, isEcoFoot2Plus, isRM10Evolution, isRmGridflex10, products } from '__common/constants/products';
import _ from 'lodash';
import { state } from '__common/store';
import { showRotationBasedMaps, applyRM5Revamp, greaterThanEqualToProjectVersion, applyEcoFoot2PlusRM10andEvoSetbackChanges, applyRM5SetbackChanges, applySkewFactorChanges } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';

interface LayoutMap {
  name: string;
  layoutType: number;
}

interface GeneratedLayoutMap extends LayoutMap {
  active: boolean;
  content: any;
  arrayName?: string;
  displayName?: string;
  id?: number;
}

export function getEngineeringRMBallastMaps(arrays: engineeringProjectArray[], productId: number, projectId: string, is_ASCE716or722: boolean, allowMechanicalAttachments, engineering_layout_flags, isModuleTestMap: boolean = false) {

  const critical_uplift_attachment_map = engineering_layout_flags?.critical_uplift_map_w_attachment?.length > 0 ? engineering_layout_flags.critical_uplift_map_w_attachment : [];

  var criticalUpliftAttachmentMapFlag = false;
  const layoutsPerArray = arrays.map((array) => {
    const id = array.id;

    if (critical_uplift_attachment_map.length > 0) {
      const data = critical_uplift_attachment_map.filter((item) => item.id == id);
      if (data.length > 0) {
        criticalUpliftAttachmentMapFlag = data[0].show_cri_uplift_attachment;
      }
    }
    const lays = _.map(getGeneratedLayouts(productId, is_ASCE716or722, allowMechanicalAttachments, criticalUpliftAttachmentMapFlag, isModuleTestMap), _.clone);
    const layoutsWithId = lays.slice().map((lay, idx) => {
      if (idx === 0) {
        lay.arrayName = array.name;
      }
      lay.displayName = lay.name;
      lay.name = `${array.id} ${lay.name}`;
      lay.content = (<EngineeringBallastMapsComponent projectId={projectId} arrayId={array.id} layoutType={lay.layoutType} />);
      lay.id = id;
      return lay;
    });

    return layoutsWithId;
  });

  const flattendArrays = layoutsPerArray.reduce((acc, val) => acc.concat(val), []);

  return flattendArrays;
}

function getGeneratedLayouts(productId: number, is_ASCE716or722: boolean, allowMechanicalAttachments, criticalUpliftAttachmentMapFlag: boolean = false, isModuleTestMap: boolean = false) {
  let layouts: LayoutMap[];

  if (isRM5(productId) && is_ASCE716or722) {
    layouts = rm5EngineeringBallastMapForAsce716(allowMechanicalAttachments, is_ASCE716or722);
  } else if (isRM5(productId)) {
    layouts = rm5EngineeringBallastMap(allowMechanicalAttachments);
  } else if (isRM10(productId)) {
    layouts = rm10EngineeringBallastMapForAsce716(allowMechanicalAttachments, is_ASCE716or722, isModuleTestMap);
  } else if (isRMGridFlex(productId)) {
    layouts = rmGridFlexEngineeringBallastMap(allowMechanicalAttachments, criticalUpliftAttachmentMapFlag);
  } else if ( isRmGridflex10(productId)) {
    layouts = rmGridFlex10EngineeringBallastMap(allowMechanicalAttachments, is_ASCE716or722);
  } else if (isRMDT(productId) && is_ASCE716or722) {
    layouts = rmDTEngineeringBallastMapForAsce716();
  } else if (isRMDT(productId)) {
    layouts = rmDTEngineeringBallastMap();
  } else if (isEcoFoot2Plus(productId)) {
    layouts = ecofoot2plusEngineeringBallastMap(isModuleTestMap, is_ASCE716or722);
  } else if (isRM10Evolution(productId)) {
    layouts = rm10EvoEngineeringBallastMap(allowMechanicalAttachments, is_ASCE716or722, isModuleTestMap);
  } else {
    return [];
  }

  const generatedLayouts = layouts.map<GeneratedLayoutMap>((layout, index) => {
    return {
      name: layout.name,
      content: undefined,
      layoutType: layout.layoutType,
      active: index === 0, // automatically select the first page
    };
  });
  return generatedLayouts;
}

function rm5EngineeringBallastMap(allowMechanicalAttachments): LayoutMap[] {
  const { projectConfiguration: { projectVersion, productId } } = state();
  let layoutList
  if(applyRM5Revamp(projectVersion)){
    layoutList = [
      { name: 'Ballast array', layoutType: 94 },
      { name: '4 digit classification', layoutType: 5 },
      { name: 'Exact classification', layoutType: 6 },
      { name: 'Deflector Map', layoutType: 81 }, 
      { name: 'Uplift GCp map', layoutType: 2 },
      { name: 'Building Size Factor Map', layoutType: 11 },
      { name: 'Area Reduction Factor Map', layoutType: 14 },
      { name: 'Large Module factor map', layoutType: 42 },
      ...(applyRM5SetbackChanges(projectVersion) ? [
        { name: 'Setback Factor Map', layoutType: 67 },
        { name: 'Setback Distance Map', layoutType: 93 }
      ] : []),
      { name: 'Modified Uplift GCp map', layoutType: 3 },
      { name: 'Drag GCp Map', layoutType: 13 },
      { name: 'Modified Drag GCp Map', layoutType: 16 },
      { name: 'Uplift GCp for Drag Map', layoutType: 12 },
      { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
      { name: 'Total Uplift Weight Per Module', layoutType: 52 },
      { name: 'Net Uplift Weight Per Module', layoutType: 49 },
      { name: 'Uplift Weight Per Bay', layoutType: 50 },
      { name: 'Sliding Weight per Module', layoutType: 17 },
      { name: 'Primary Bay Blocks for Uplift', layoutType: 1 },
      { name: 'Modified Ballast Map For Sliding', layoutType: 18 },
      ...(allowMechanicalAttachments ? [{ name: 'Ballast Map with attachment', layoutType: 30 },
      { name: 'Uplift load on attachment', layoutType: 87 },
      { name: 'Shear load on attachment (sliding)', layoutType: 95 },
      { name: 'Shear load on attachment (seismic)', layoutType: 88 }  
      ] : []),
      { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
      { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
      { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
      { name: 'Factored Total Wind Uplift Map', layoutType: 55},
      { name: 'Total download per module map', layoutType: 68},
      { name: 'Down Point Load Map with values', layoutType: 71},
      // { name: 'Down Point Load Map', layoutType: 29 }
    ];
    
    showRotationBasedMaps(projectVersion) && layoutList.splice(5, 0, ...[ 
     { name: 'Obstruction Factor Map', layoutType: 77 },
     { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON UPLIFT MAP':'Rotation Factor on Uplift Map', layoutType: 75 }, 
     { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON DRAG MAP':'Rotation Factor on Drag map', layoutType: 76 }, 
     { name: 'Obstruction and Rotation Factor on Uplift map', layoutType: 79 },
     { name: 'Obstruction and Rotation Factor on Drag map', layoutType: 80 },  
     ])
  }
  else {
    layoutList =  [
      { name: 'Ballast array', layoutType: 94 },
      { name: 'Uplift Ballast Map', layoutType: 1 },
      { name: 'Uplift GCp map', layoutType: 2 },
      { name: 'Obstruction Factor Map', layoutType: 10 },
      { name: 'Large Module factor map', layoutType: 42 },
      { name: 'Sub Array Factor Map', layoutType: 43 },
      { name: 'Modified Uplift GCp map', layoutType: 3 },
      { name: 'Ballast to Resist Uplift', layoutType: 4 },
      { name: '4 digit classification', layoutType: 5 },
      { name: 'Remaining Uplift map', layoutType: 7 },
      { name: 'Wind Dams map', layoutType: 8 },
      { name: 'Ballast and Wind Dams map', layoutType: 9 },
      { name: 'Building Size Factor Map', layoutType: 11 },
    ];
    showRotationBasedMaps(projectVersion)  && layoutList.splice(2, 0, ...[ 
      { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON UPLIFT MAP':'Rotation Factor on Uplift Map', layoutType: 75 }, 
      { name: 'Rotation Factor on Drag and Downforce map', layoutType: 76 },
    ])
  }
  return layoutList;
}

function rm5EngineeringBallastMapForAsce716(allowMechanicalAttachments, is_ASCE716or722): LayoutMap[] {
  const { projectConfiguration: { projectVersion, productId } } = state();
let layoutList
  if(applyRM5Revamp(projectVersion)) {
    layoutList = [
      { name: 'Ballast array', layoutType: 94 },
      { name: '4 digit classification', layoutType: 5 },
      { name: 'Exact classification', layoutType: 6 },
      { name: 'Deflector Map', layoutType: 81 }, 
      { name: 'Uplift GCp map', layoutType: 2 },
      { name: 'Building Size Factor Map', layoutType: 11 },
      { name: 'Area Reduction Factor Map', layoutType: 14 },
      { name: 'Large Module factor map', layoutType: 42 },
      ...(applyRM5SetbackChanges(projectVersion) ? [
        { name: 'Setback Factor Map', layoutType: 67 },
        { name: 'Setback Distance Map', layoutType: 93 }
      ] : []),
      { name: 'Modified Uplift GCp map', layoutType: 3 },
      { name: 'Drag GCp Map', layoutType: 13 },
      { name: 'Modified Drag GCp Map', layoutType: 16 },
      { name: 'Uplift GCp for Drag Map', layoutType: 12 },
      { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
      { name: 'Total Uplift Weight Per Module', layoutType: 52 },
      { name: 'Net Uplift Weight Per Module', layoutType: 49 },
      { name: 'Uplift Weight Per Bay', layoutType: 50 },
      { name: 'Sliding Weight per Module', layoutType: 17 },
      { name: 'Primary Bay Blocks For Uplift', layoutType: 1 },
      { name: 'Modified Ballast Map For Sliding', layoutType: 18 },
      ...(allowMechanicalAttachments ? [{ name: 'Ballast Map with attachment', layoutType: 30 },
      { name: 'Uplift load on attachment', layoutType: 87 },
      { name: 'Shear load on attachment (sliding)', layoutType: 95 },
      { name: 'Shear load on attachment (seismic)', layoutType: 88 }  
      ] : []),
      { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
      { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
      { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
      { name: 'Factored Total Wind Uplift Map', layoutType: 55},
      { name: 'Total download per module map', layoutType: 68},
      { name: 'Down Point Load Map with values', layoutType: 71},
      // { name: 'Down Point Load Map', layoutType: 29 }
    ];
  
    showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
     { name: 'Obstruction Factor Map', layoutType: 77 },
     { name: 'Exposure Factor Map', layoutType: 78 },
     { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON UPLIFT MAP':'Rotation Factor on Uplift Map', layoutType: 75 }, 
     { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON DRAG MAP':'Rotation Factor on Drag map', layoutType: 76 },
     { name: 'Obstruction, Exposure and Rotation Factor on Uplift map', layoutType: 79 }, 
     { name: 'Obstruction, Exposure and Rotation Factor on Drag map', layoutType: 80 },])
  } else {
    layoutList =  [
      { name: 'Ballast array', layoutType: 94 },
      { name: 'Uplift Ballast Map', layoutType: 1 },
      { name: 'Modified Uplift Ballast Map', layoutType: 18 },
      { name: 'Uplift GCp map', layoutType: 2 },
      { name: 'Large Module factor map', layoutType: 42 },
      { name: 'Sub Array Factor Map', layoutType: 43 },
      { name: 'Modified Uplift GCp map', layoutType: 3 },
      { name: 'Ballast to Resist Uplift', layoutType: 4 },
      { name: '4 digit classification', layoutType: 5 },
      { name: 'Remaining Uplift map', layoutType: 7 },
      { name: 'Wind Dams map', layoutType: 8 },
      { name: 'Ballast and Wind Dams map', layoutType: 9 },
      { name: 'Modified Ballast and Wind Dams map', layoutType: 19 },
      { name: 'Building Size Factor Map', layoutType: 11 },
      { name: 'Uplift GCp for Drag Map', layoutType: 12 },
      { name: 'Drag GCp Map', layoutType: 13 },
      { name: 'Area Reduction Factor Map', layoutType: 14 },
      { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
      { name: 'Modified Drag GCp Map', layoutType: 16 },
      { name: 'Ballast to Resist Sliding', layoutType: 17 },
    ];
  
    showRotationBasedMaps(projectVersion)  && layoutList.splice(12, 0, ...[ 
      { name: 'Obstruction Factor Map', layoutType: 77 },
      { name: 'Exposure Factor Map', layoutType: 78 },
      { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON UPLIFT MAP':'Rotation Factor on Uplift Map', layoutType: 75 }, 
      { name: applySkewFactorChanges(productId, projectVersion)?'SKEW FACTOR ON DRAG MAP':'Rotation Factor on Drag map', layoutType: 76 },
      { name: 'Obstruction, Exposure and Rotation Factor on Uplift map', layoutType: 10 }, 
      { name: 'Obstruction, Exposure and Rotation Factor on Drag map', layoutType: 10 },])
   
   
     !showRotationBasedMaps(projectVersion)  && layoutList.splice(12, 0, ...[ 
       { name: `Exposure and Obstruction Factor Map`, layoutType: 10 }])
  }
  return layoutList;
}

function ecofoot2plusEngineeringBallastMap(isModuleTestMap: boolean = false, is_ASCE716or722: boolean = false): LayoutMap[] {
  const { projectConfiguration: {projectVersion} } = state();
  const ballastMapList = [
    { name: 'Ballast array', layoutType: 94 },
    { name: '4 digit classification', layoutType: 5 },
    { name: 'Uplift GCp map', layoutType: 2 },
    { name: 'Building Size Factor Map', layoutType: 11 },
    { name: 'Area Reduction Factor Map', layoutType: 14 },
    { name: 'Slope Adjustment Factor Map', layoutType: 44 },
    { name: 'Large Module factor map', layoutType: 42 },
    ...(applyEcoFoot2PlusRM10andEvoSetbackChanges(products.ecofoot2_plus, projectVersion) ? [
      { name: 'Setback Factor Map', layoutType: 67 },
      { name: 'Setback Distance Map', layoutType: 93 }
    ] : []),
    { name: 'Sub Array Factor Map', layoutType: 43 },
    { name: 'Modified Uplift GCp map', layoutType: 3 },
    { name: 'Drag GCp Map', layoutType: 13 },
    { name: 'Modified Drag GCp Map', layoutType: 16 },
    { name: 'Uplift GCp for Drag Map', layoutType: 12 },
    { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
    { name: 'Ballast to Resist Sliding', layoutType: 17 },
    { name: 'Ballast to Resist Uplift', layoutType: 4 },
    { name: 'Ballast Bay Capacity', layoutType: 33 },
    { name: 'North South Bays Multiplier', layoutType: 57 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Modified Uplift Ballast Map', layoutType: 18 },
    { name: 'Weight to blocks map', layoutType: 37 },
    { name: 'Count of supplemental bays', layoutType: 38 },
    { name: 'Down Point Load Map', layoutType: 29 },
  ];


  showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && ballastMapList.splice(5, 0, ...[ 
    { name: 'Obstruction Factor Map', layoutType: 77 },
    { name: 'Exposure Factor Map', layoutType: 78 },
    { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
    { name: 'Skew Factor on Drag map', layoutType: 76 },
    { name: 'Obstruction, Exposure and Skew Factor on Uplift map', layoutType: 79 }, 
    { name: 'Obstruction, Exposure and Skew Factor on Drag map', layoutType: 80 },])
 
   showRotationBasedMaps(projectVersion)  &&  !is_ASCE716or722  && ballastMapList.splice(5, 0, ...[ 
     { name: 'Obstruction Factor Map', layoutType: 77 },
     { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
     { name: 'Skew Factor on Drag map', layoutType: 76 },
     { name: 'Obstruction and Skew Factor on Uplift map', layoutType: 79 }, 
     { name: 'Obstruction and Skew Factor on Drag map', layoutType: 80 },])
 
   !showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && ballastMapList.splice(5, 0, ...[ 
    { name: 'Exposure Factor Map', layoutType: 78 },
    { name: `Obstruction Factor Map`, layoutType: 77 }])
 
   !showRotationBasedMaps(projectVersion)  &&  !is_ASCE716or722  && ballastMapList.splice(5, 0, ...[ 
     { name: `Obstruction Factor Map`, layoutType: 79 }])

     const moduleTestMapList = [
      { name: '4 digit classification', layoutType: 58 },
      { name: 'Wind Uplift GCp map', layoutType: 59 },
      { name: 'Wind Drag gcp map', layoutType: 66 },
      { name: 'Wind Down gcp map', layoutType: 62 },
      { name: 'Net Uplift Load map', layoutType: 60 },
      { name: 'Deflector Drag Load map', layoutType: 63 },
      { name: 'snow load map', layoutType: 61 },
      { name: 'Downward Load map', layoutType: 64 },
      { name: 'Net Uplift pressure map', layoutType: 72 },
      { name: 'Deflector Drag pressure map', layoutType: 73 },
      { name: 'Down Pressure map', layoutType: 74 },
      { name: 'list of component weights', layoutType: 65 },
    ];
  
    const layoutList = isModuleTestMap ? moduleTestMapList : ballastMapList;

  return layoutList;
}


function rmGridFlexEngineeringBallastMap(allowMechanicalAttachments, criticalUpliftAttachmentMapFlag: boolean = false): LayoutMap[] {
  const { user: { isStaff } } = state();

  const layoutList = [
    { name: 'Ballast array', layoutType: 94 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Critical Uplift Weight Map', layoutType: 20 },
    { name: 'Critical Sliding Weight Map', layoutType: 21 },
    { name: 'Dead load factor Map', layoutType: 99 },
    { name: 'Uplift Load Sharing Map', layoutType: 26 },
    { name: 'Sliding Load Sharing Map', layoutType: 27 },
    { name: 'CP Uplift Map', layoutType: 31 },
    { name: 'CP Sliding Map', layoutType: 32 },
    { name: 'NE Wind Classification', layoutType: 22 },
    { name: 'NW Wind Classification', layoutType: 23 },
    { name: 'SE Wind Classification', layoutType: 24 },
    { name: 'SW Wind Classification', layoutType: 25 },
    { name: 'Downforce Map', layoutType: 29 },
    { name: 'CP Downforce Map', layoutType: 46 },
    { name: 'Dead Load Map', layoutType: 53 },
    { name: 'Snow Load Map', layoutType: 54 },
    { name: 'Wind Load (Upward) Map', layoutType: 55 },
    ...(allowMechanicalAttachments && criticalUpliftAttachmentMapFlag ? [{ name: 'Wind Load with attachment (Upward) Map', layoutType: 89 },] : []),
    { name: 'Wind Load (Downward) Map', layoutType: 56 }
  ];
  allowMechanicalAttachments && criticalUpliftAttachmentMapFlag && layoutList.unshift(
    { name: 'Critical Uplift Weight Map with attachment', layoutType: 47 },
    { name: 'Uplift on attachment', layoutType: 87 },    
    { name: 'Shear on attachment (sliding)', layoutType: 95 },
    { name: 'Shear on attachment (seismic)', layoutType: 88 },
  );
  allowMechanicalAttachments && layoutList.unshift({ name: 'Ballast Map with attachment', layoutType: 30 });

  isStaff && layoutList.splice(allowMechanicalAttachments && criticalUpliftAttachmentMapFlag ? 9 : 5, 0, ...[
    { name: 'Skew Factor on Uplift Map', layoutType: 75 },
    { name: 'Skew Factor on Drag map', layoutType: 76 },])
 
  return layoutList;
}

function rmGridFlex10EngineeringBallastMap(allowMechanicalAttachments, is_ASCE716or722=false): LayoutMap[] {
  const { user: { isStaff } } = state();
  const layoutList = [
    { name: 'Ballast array', layoutType: 94 },
    { name: '4 digit classification', layoutType: 5 },
    { name: 'Uplift Load Sharing Map', layoutType: 26 },
    { name: 'Uplift GCp map', layoutType: 2 },
    { name: 'Building Size Factor Map', layoutType: 11 },
    { name: 'Area Reduction Factor Map', layoutType: 14 },
    { name: 'Large Module factor map', layoutType: 42 },
    { name: 'Setback Factor Map', layoutType: 67 },
    { name: 'Setback Distance Map', layoutType: 93 },
    { name: 'Modified Uplift GCp map', layoutType: 3 },
    { name: 'Drag GCp Map', layoutType: 13 },
    { name: 'Modified Drag GCp Map', layoutType: 16 },
    { name: 'Uplift GCp for Drag Map', layoutType: 12 },
    { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
    { name: 'Total Uplift Weight Per Module', layoutType: 52 },
    { name: 'Net Uplift Weight Per Module', layoutType: 49 },
    { name: 'Ballast to Resist Sliding', layoutType: 17 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Modified Uplift Ballast Map', layoutType: 18 },
    { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
    { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
    { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
    { name: 'Factored total wind uplift map', layoutType: 55},
    { name: 'Total download per module map', layoutType: 68},
  ];
  isStaff && is_ASCE716or722 && layoutList.splice(5, 0, ...[
    { name: 'Obstruction Factor Map', layoutType: 77 },
    { name: 'Exposure Factor Map', layoutType: 78 },
    { name: 'Skew Factor on Uplift Map', layoutType: 75 },
    { name: 'Skew Factor on Drag map', layoutType: 76 },
    { name: 'Obstruction, Exposure and Skew Factor on Uplift map', layoutType: 79 },
    { name: 'Obstruction, Exposure and Skew Factor on Drag map', layoutType: 80 },])
 
  isStaff && !is_ASCE716or722 && layoutList.splice(5, 0, ...[
    { name: 'Obstruction Factor Map', layoutType: 77 },
    { name: 'Skew Factor on Uplift Map', layoutType: 75 },
    { name: 'Skew Factor on Drag map', layoutType: 76 },
    { name: 'Obstruction and Skew Factor on Uplift map', layoutType: 79 },
    { name: 'Obstruction and Skew Factor on Drag map', layoutType: 80 },])

  allowMechanicalAttachments && layoutList.unshift(
    { name: 'Uplift on attachment', layoutType: 87 },    
    { name: 'Shear on attachment (sliding)', layoutType: 95 },
    { name: 'Shear on attachment (seismic)', layoutType: 88 },
  );
  allowMechanicalAttachments && layoutList.unshift({ name: 'Ballast Map with attachment', layoutType: 30 });

  return layoutList;
}
function rm10EngineeringBallastMapForAsce716(allowMechanicalAttachments, is_ASCE716or722, isModuleTestMap = false): LayoutMap[] {
  if (isModuleTestMap){
      const moduleTestMapList = [
        { name: 'Ballast array', layoutType: 94 },
        { name: '4 digit classification', layoutType: 58 },
        { name: 'Wind Uplift GCp map', layoutType: 59 },
        { name: 'Wind Drag gcp map', layoutType: 66 },
        { name: 'Wind Down gcp map', layoutType: 62 },
        { name: 'Net Uplift Load map', layoutType: 60 },
        { name: 'Deflector Drag Load map', layoutType: 63 },
        { name: 'snow load map', layoutType: 61 },
        { name: 'Downward Load map', layoutType: 64 },
        { name: 'Net Uplift pressure map', layoutType: 72 },
        { name: 'Deflector Drag pressure map', layoutType: 73 },
        { name: 'Down Pressure map', layoutType: 74 },
        { name: 'list of component weights', layoutType: 65 },
      ];
      return moduleTestMapList;
  }
  const { projectConfiguration: {projectVersion, projectEnvConfig: { allow_north_bays } } } = state();
  const layoutList = [
    { name: 'Ballast array', layoutType: 94 },
    { name: '4 digit classification', layoutType: 5 },
    { name: 'Exact classification', layoutType: 6 },
    { name: 'Uplift GCp map', layoutType: 2 },
    { name: 'Building Size Factor Map', layoutType: 11 },
    { name: 'Area Reduction Factor Map', layoutType: 14 },
    { name: 'Large Module factor map', layoutType: 42 },
    ...(applyEcoFoot2PlusRM10andEvoSetbackChanges(products.rm, projectVersion) ? [
      { name: 'Setback Factor Map', layoutType: 67 },
      { name: 'Setback Distance Map', layoutType: 93 }
    ] : []),
    ...(greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm10_rm10evo_small_array_factor_changes) ? [] : [{ name: 'Sub Array Factor Map', layoutType: 43 }]),
    { name: 'Modified Uplift GCp map', layoutType: 3 },
    { name: 'Drag GCp Map', layoutType: 13 },
    { name: 'Modified Drag GCp Map', layoutType: 16 },
    { name: 'Uplift GCp for Drag Map', layoutType: 12 },
    { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
    { name: 'Total Uplift Weight Per Module', layoutType: 52 },
    { name: 'Net Uplift Weight Per Module', layoutType: 49 },
    { name: 'Uplift Weight Per Bay', layoutType: 50 },
    { name: 'Uplift Weight Per Bay With North Bay Factor', layoutType: 51 },
    ...(!allow_north_bays ? [] : [{ name: 'North Bay Factor Map', layoutType: 48 }]),
    { name: 'Ballast to Resist Sliding', layoutType: 17 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Modified Uplift Ballast Map', layoutType: 18 },
    ...(allowMechanicalAttachments ? [{ name: 'Ballast Map with attachment', layoutType: 30 }] : []),
    { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
    { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
    { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
    { name: 'Factored total wind uplift map', layoutType: 55},
    { name: 'Total download per module map', layoutType: 68},
    // { name: 'Total download per primary bay map', layoutType: 69},
    // { name: 'Total download per secondary bay map', layoutType: 70},
    { name: 'Down Point Load Map with values', layoutType: 71},
    // { name: 'Down Point Load Map', layoutType: 29 }
  ];

  showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
   { name: 'Obstruction Factor Map', layoutType: 77 },
   { name: 'Exposure Factor Map', layoutType: 78 },
   { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
   { name: 'Skew Factor on Drag map', layoutType: 76 },
   { name: 'Obstruction, Exposure and Skew Factor on Uplift map', layoutType: 79 }, 
   { name: 'Obstruction, Exposure and Skew Factor on Drag map', layoutType: 80 },])

  showRotationBasedMaps(projectVersion)  &&  !is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
    { name: 'Obstruction Factor Map', layoutType: 77 },
    { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
    { name: 'Skew Factor on Drag map', layoutType: 76 },
    { name: 'Obstruction and Skew Factor on Uplift map', layoutType: 79 }, 
    { name: 'Obstruction and Skew Factor on Drag map', layoutType: 80 },])

  !showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
    { name: `Exposure and Obstruction Factor Map`, layoutType: 79 }])

  !showRotationBasedMaps(projectVersion)  &&  !is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
    { name: `Obstruction Factor Map`, layoutType: 79 }])

  return layoutList;

}

function rm10EvoEngineeringBallastMap(allowMechanicalAttachments, is_ASCE716or722, isModuleTestMap = false): LayoutMap[] {
  const { projectConfiguration: { projectVersion, projectEnvConfig: { allow_north_bays } }} = state();

  if (isModuleTestMap) {
    return rm10EngineeringBallastMapForAsce716(allowMechanicalAttachments, is_ASCE716or722, isModuleTestMap);
  }
  const layoutList = [
    { name: 'Ballast array', layoutType: 94 },
    { name: '4 digit classification', layoutType: 5 },
    { name: 'Exact classification', layoutType: 6 },
    { name: 'Uplift GCp map', layoutType: 2 },
    { name: 'Building Size Factor Map', layoutType: 11 },
    { name: 'Area Reduction Factor Map', layoutType: 14 },
    { name: 'Large Module factor map', layoutType: 42 },
    ...(applyEcoFoot2PlusRM10andEvoSetbackChanges(products.rm_10_evolution, projectVersion) ? [
      { name: 'Setback Factor Map', layoutType: 67 },
      { name: 'Setback Distance Map', layoutType: 93 }
    ] : []),
    ...(greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP.rm10_rm10evo_small_array_factor_changes) ? [] : [{ name: 'Sub Array Factor Map', layoutType: 43 }]),
    { name: 'Modified Uplift GCp map', layoutType: 3 },
    { name: 'Drag GCp Map', layoutType: 13 },
    { name: 'Modified Drag GCp Map', layoutType: 16 },
    { name: 'Uplift GCp for Drag Map', layoutType: 12 },
    { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
    { name: 'Total Uplift Weight Per Module', layoutType: 52 },
    { name: 'Net Uplift Weight Per Module', layoutType: 49 },
    { name: 'Uplift Weight Per Bay', layoutType: 50 },
    { name: 'Uplift Weight Per Bay With North Bay Factor', layoutType: 51 },
    ...(!allow_north_bays ? [] : [{ name: 'North Bay Factor Map', layoutType: 48 }]),
    { name: 'Ballast to Resist Sliding', layoutType: 17 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Modified Uplift Ballast Map', layoutType: 18 },
    ...(allowMechanicalAttachments ? [{ name: 'Ballast Map with attachment', layoutType: 30 },
    { name: 'Uplift load on attachment', layoutType: 87 },
    { name: 'Shear load on attachment (sliding)', layoutType: 95 },
    { name: 'Shear load on attachment (seismic)', layoutType: 88 }  
    ] : []),
    { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
    { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
    { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
    { name: 'Factored total wind uplift map', layoutType: 55},
    { name: 'Total download per module map', layoutType: 68},
    // { name: 'Total download per primary bay map', layoutType: 69},
    // { name: 'Total download per secondary bay map', layoutType: 70},
    { name: 'Down Point Load Map with values', layoutType: 71},
    // { name: 'Down Point Load Map', layoutType: 29 }
    ];


  showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
    { name: 'Obstruction Factor Map', layoutType: 77 },
    { name: 'Exposure Factor Map', layoutType: 78 },
    { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
    { name: 'Skew Factor on Drag map', layoutType: 76 },
    { name: 'Obstruction, Exposure and Skew Factor on Uplift map', layoutType: 79 }, 
    { name: 'Obstruction, Exposure and Skew Factor on Drag map', layoutType: 80 },])
 
   showRotationBasedMaps(projectVersion)  &&  !is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
     { name: 'Obstruction Factor Map', layoutType: 77 },
     { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
     { name: 'Skew Factor on Drag map', layoutType: 76 },
     { name: 'Obstruction and Skew Factor on Uplift map', layoutType: 79 }, 
     { name: 'Obstruction and Skew Factor on Drag map', layoutType: 80 },])
 
   !showRotationBasedMaps(projectVersion)  &&  is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
     { name: `Exposure and Obstruction Factor Map`, layoutType: 79 }])
 
   !showRotationBasedMaps(projectVersion)  &&  !is_ASCE716or722  && layoutList.splice(5, 0, ...[ 
     { name: `Obstruction Factor Map`, layoutType: 79 }])

  return layoutList;
}


function rmDTEngineeringBallastMap(): LayoutMap[] {
  const {projectConfiguration : {projectVersion}} = state();

  const layoutList =  [
    { name: 'Ballast array', layoutType: 94 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Uplift GCp map', layoutType: 2 },
    { name: 'Obstruction Factor Map', layoutType: 10 },
    { name: 'Large Module factor map', layoutType: 42 },
    { name: 'Sub Array Factor Map', layoutType: 43 },
    { name: 'Modified Uplift GCp map', layoutType: 3 },
    { name: 'Ballast to Resist Uplift', layoutType: 4 },
    { name: '4 digit classification', layoutType: 5 },
    { name: 'Building Size Factor Map', layoutType: 11 },
    // { name: 'Down Point Load Map', layoutType: 29 },
    { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
    { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
    { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
    { name: 'Wind Load (Downward) Map', layoutType: 56 },
    { name: 'Total downward per module map', layoutType: 68},
    { name: 'Down Point Load Map with values', layoutType: 71},
  ];

  showRotationBasedMaps(projectVersion)  && layoutList.splice(2, 0, ...[ 
    { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
    { name: 'Skew Factor on Drag map', layoutType: 76 },
  ])

  return layoutList;

}

function rmDTEngineeringBallastMapForAsce716(): LayoutMap[] {
  const {projectConfiguration: {projectVersion}} = state();

  const layoutList = [
    { name: 'Ballast array', layoutType: 94 },
    { name: 'Uplift Ballast Map', layoutType: 1 },
    { name: 'Modified Uplift Ballast Map', layoutType: 18 },
    { name: 'Uplift GCp map', layoutType: 2 },
    { name: 'Large Module factor map', layoutType: 42 },
    { name: 'Sub Array Factor Map', layoutType: 43 },
    { name: 'Modified Uplift GCp map', layoutType: 3 },
    { name: 'Ballast to Resist Uplift', layoutType: 4 },
    { name: '4 digit classification', layoutType: 5 },
    { name: 'Building Size Factor Map', layoutType: 11 },
    { name: 'Uplift GCp for Drag Map', layoutType: 12 },
    { name: 'Drag GCp Map', layoutType: 13 },
    { name: 'Area Reduction Factor Map', layoutType: 14 },
    { name: 'Modified Uplift GCp for Drag Map', layoutType: 15 },
    { name: 'Modified Drag GCp Map', layoutType: 16 },
    { name: 'Ballast to Resist Sliding', layoutType: 17 },
    { name: 'Down Point Load Map', layoutType: 29 },
    { name: 'DEAD LOAD PER MODULE(D) ', layoutType: 53},
    { name: 'SNOW LOAD PER MODULE(S)', layoutType: 54},
    { name: 'WIND LOAD (UPWARD) PER MODULE', layoutType: 52},
    { name: 'Wind Load (Downward) Map', layoutType: 56 },
    { name: 'Total downward per module map', layoutType: 68},
    { name: 'Down Point Load Map with values', layoutType: 71},
  ];


  showRotationBasedMaps(projectVersion)  && layoutList.splice(8, 0, ...[ 
    { name: 'Obstruction Factor Map', layoutType: 77 },
    { name: 'Exposure Factor Map', layoutType: 78 },
    { name: 'Skew Factor on Uplift Map', layoutType: 75 }, 
    { name: 'Skew Factor on Drag map', layoutType: 76 },
    { name: 'Obstruction, Exposure and Skew Factor on Uplift map', layoutType: 10 }, 
    { name: 'Obstruction, Exposure and Skew Factor on Drag map', layoutType: 10 },])
 
 
   !showRotationBasedMaps(projectVersion)  && layoutList.splice(8, 0, ...[ 
     { name: `Exposure and Obstruction Factor Map`, layoutType: 10 }])
    
    return layoutList;
}
