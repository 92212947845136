
export const apiField = 'blocks_orientation';


export default {
    type: 'Select',
    apiField,
    label: 'blocksOrientation',
    data: [
        {
            value: 0,
            name: 'PORTRAIT',
        },
        {
            value: 1,
            name: 'LANDSCAPE',
        }
    ],
};
