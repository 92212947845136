import * as React from 'react';
import Header from 'app/components/HeaderComponent';
import Footer from 'app/components/FooterComponent';

const NotFound = () => (
  <div className="app-content">
    <Header />
    <div key="404" className="app__not-found app__route">
      <h1>404</h1>
      <h2>Content Unavailable</h2>
    </div>
    <Footer />
  </div>
);

export default NotFound;
