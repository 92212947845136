export const apiField = 'roof_exposure';

export const RoofExposureOption = {
  fullyExposed: {
    value: 'fully_exposed',
    name: 'FULLY EXPOSED',
  },
  partiallyExposed: {
    value: 'partially_exposed',
    name: 'PARTIALLY EXPOSED',
  },
  sheltered: {
    value: 'sheltered',
    name: 'SHELTERED',
  },
};

export interface RoofExposureField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  data: RoofExposureOption[];
}

export interface RoofExposureOption {
  value: string;
  name: string;
}
