import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';

type Props = {
  projectId: string,
  engineering: engineeringState,
  dispatch: Function,
};

class OrderItButton extends React.Component<Props, {}> {
  @autobind
  onClick() {
    window.open('https://unirac.com/distributors', '_blank');
  }

  render() {
    return (
      <div role="button" onClick={this.onClick} className="continue-button">
      order it!
      <SVG src={require('assets/media/icons/forward_arrow_icon.svg')} />
    </div>);
  }
}

function mapStateToProps(appState: appState) {
  return {
    engineering: appState.engineering,
  };
}

export default connect(mapStateToProps)(OrderItButton);
