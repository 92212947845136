import nextUid from "__common/calculations/nextUid";
import store from "__common/store";

export interface gridSettings {
  rowSpacingMeters: number;
  columnSpacingMeters: number;
  moduleWidthMeters: number;
  moduleLengthMeters: number;
  productId: number;
  metersPerPixel: number;
}

export const constructGraph = (panels: panelInState[], rowSpacingPx: number, columnSpacingPx: number) => {
  const leftMap = {};
  const rightMap = {};
  const topMap = {};
  const bottomMap = {};
  const precision = 1;

  const getKey = (x, y) => `${x.toFixed(precision)}-${y.toFixed(precision)}`;

  const getNeighborCoords = p => {
    const leftXCoord = p.x - columnSpacingPx - p.width;
    const leftYCoord = p.y;
    const leftCoord =  getKey(leftXCoord, leftYCoord);

    const rightXCoord = p.x + columnSpacingPx + p.width;
    const rightYCoord = p.y;
    const rightCoord =  getKey(rightXCoord, rightYCoord);

    const topXCoord = p.x;
    const topYCoord = p.y - rowSpacingPx - p.height;
    const topCoord =  getKey(topXCoord, topYCoord);

    const bottomXCoord = p.x;
    const bottomYCoord = p.y + rowSpacingPx + p.height;
    const bottomCoord = getKey(bottomXCoord, bottomYCoord);

    return { leftCoord, rightCoord, topCoord, bottomCoord };
    
  }
  const _panels = [];
  panels.forEach(p=>{
    const pData = {id: p.id, groupId: p.groupId, left: null, right: null, top: null, bottom: null};
    const pKey = getKey(p.x, p.y);
    if (leftMap[pKey]) {
      const p1Data = leftMap[pKey];
      p1Data.left = pData;
      pData.right = p1Data;
    } 
    if (rightMap[pKey]) {
      const p1Data = rightMap[pKey];
      p1Data.right = pData;
      pData.left = p1Data;
    } 
    if (topMap[pKey]) {
      const p1Data = topMap[pKey];
      p1Data.top = pData;
      pData.bottom = p1Data;
    }
    if (bottomMap[pKey]) {
      const p1Data = bottomMap[pKey];
      p1Data.bottom = pData;
      pData.top = p1Data;
    }
    const { leftCoord, rightCoord, topCoord, bottomCoord } = getNeighborCoords(p);  
    leftMap[leftCoord] = pData;
    rightMap[rightCoord] = pData;
    topMap[topCoord] = pData;
    bottomMap[bottomCoord] = pData;
    _panels.push(pData);
  });
  return _panels;
};

export const createArrayGrid = (panels: panelInState[]) => {
  const EMPTY_CELL = 0;
  const {
    settings: {
      rowSpacing, 
      columnSpacing,
    },
    background: {
      metersPerPixel,
    },
  } = store.getState();

  const panelHeight = panels[0].height;
  const panelWidth = panels[0].width;
    
  const moduleLengthMeters = panelHeight * metersPerPixel;
  const moduleWidthMeters = panelWidth * metersPerPixel;
  const xMargin = 0.001 + columnSpacing + 0.05;
  const yMargin = 0.001 + rowSpacing + 0.05;
  const xValues = panels.map((panel) => panel.x * metersPerPixel);
  const yValues = panels.map((panel) => panel.y * metersPerPixel);

  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  const dxRaw = (xMax - xMin) / (moduleWidthMeters + columnSpacing);
  const dyRaw = (yMax - yMin) / (moduleLengthMeters + rowSpacing);

  let dx = Number(Math.round(dxRaw)) + 1;

  let dy = Number(Math.round(dyRaw)) + 1;

  const grid = [];

  for (let i = 0; i < dy; i++) {
      grid.push([]);
      for (let j = 0; j < dx; j++) {
          grid[i].push(EMPTY_CELL);
      }
  }
  panels.forEach(panel => {
      const { x, y } = panel;
      const column = Math.abs(
      Number((
          (xMin - x * metersPerPixel) / (moduleWidthMeters + columnSpacing)
      ).toFixed(4)),
      );

      const row = Math.abs(
      Number((
          (yMin - y * metersPerPixel) / (moduleLengthMeters + rowSpacing)
      ).toFixed(4)),
      );
      dx = Math.abs(column - Number(column.toFixed(1)));
      dy = Math.abs(row - Number(row.toFixed(1)));

      if (dx < xMargin && dy < yMargin) {
          const columnIndex = Number(Math.round(column));
          const rowIndex = Number(Math.round(row));
          grid[rowIndex][columnIndex] = panel;
      }
  });
  return grid;
};

const depthFirstSearch = ({panel, groupId, visited}) => {
  const stack = [panel];
  const neighbors = ['left', 'right', 'top', 'bottom'];
  while (stack.length) {
      const p = stack.pop();
      if (visited[p.id]) {
          continue;
      }
      visited[p.id] = groupId;
      neighbors.forEach(key=>{
          if (p[key] && !visited[p[key].id]) stack.push(p[key]); 
      });
  }
};

export const splitArraysIntoSubarrays = (graph) => {
  const visited = {};
  graph.forEach(p=>visited[p.id]=0);
  graph.forEach(p=> {
    if (!visited[p.id]) {
        let groupId = p.groupId;
        groupId = nextUid();
        depthFirstSearch({panel: p, groupId, visited});
    }
  });
  return visited;
};
