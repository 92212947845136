import jquery from 'jquery';
import { EngineeringActionTypes } from 'actionsConstants';

export function FETCH_ENGINEERING_RAPORT(projectId) {
  return {
    type: EngineeringActionTypes.FETCH_ENGINEERING_RAPORT,
    payload: {
      projectId,
    },
  };
}

export function POPULATE_ENGINEERING_RAPORT(data: engineeringApiLiteResponse) {
  return {
    type: EngineeringActionTypes.POPULATE_ENGINEERING_RAPORT,
    payload: {
      arrays: data.arrays,
      is_sm_tilt: data.is_sm_tilt,
      is_ASCE716or722: data.is_ASCE716or722,
      is_blank_design: data.is_blank_design,
      projectInfo: data.project_info,
      partList: data.part_list,
      roofs: data.roofs,
      product: data.product
    },
  };
}

export function SEND_ENGINEERING_CLIENT_DETAILS(data: { custom_title: string, client_name: string, client_address: string, discount: number }, projectId: string) {
  return {
    type: EngineeringActionTypes.SEND_ENGINEERING_CLIENT_DETAILS,
    payload: {
      clientDetails: {
        ...data,
      },
      projectId,
    },
  };
}

export function SEND_ENGINEERING_CLIENT_DETAILS_SUCCESS() {
  return {
    type: EngineeringActionTypes.UPDATE_ENGINEERING_CLIENT_DETAILS_SUCCESS,
  };
}

export function UPDATE_ENGINEERING_CLIENT_DETAILS(value: string, fieldType: string) {
  return {
    type: EngineeringActionTypes.UPDATE_ENGINEERING_CLIENT_DETAILS,
    payload: {
      fieldType,
      value,
    },
  };
}

export function CLEAR_ENGINEERING_RAPORT() {
  return {
    type: EngineeringActionTypes.CLEAR_ENGINEERING_RAPORT,
  };
}

export function GET_CODE_COMPILANT_LETTER(projectId) {
  return {
    type: EngineeringActionTypes.GET_CODE_COMPILANT_LETTER,
    payload: {
      projectId,
    },
  };
}

export function SET_CODE_COMPILANT_LETTER(data) {
  const compilantLetter = data.find((a) => a.name.includes('Certification Letter'));

  const defaultCompilantLetter = {
    name: 'Code Compliant Engineering Letter - CA',
    relative_url: 'media/docs/SM_UNIRAC_227.3_Certification-California.pdf',
  };

  return {
    type: EngineeringActionTypes.SET_CODE_COMPILANT_LETTER,
    payload: {
      compilantLetter: compilantLetter || defaultCompilantLetter,
    },
  };
}

export function SET_DISCOUNT(projectId: string, discount: number) {
  return {
    type: EngineeringActionTypes.SET_DISCOUNT,
    payload: {
      projectId,
      discount,
    },
  };
}

export function REFRESH_PDFS() {
  const content = jquery('#pdfs-content');

  jquery('#pdfs-content').remove();
  jquery('.project-reports-content').append(content);

  return {
    type: EngineeringActionTypes.REFRESH_PDFS,
  };
}

export function CHANGE_DISCOUNT(discount: number) {
  return {
    type: EngineeringActionTypes.CHANGE_DISCOUNT,
    payload: {
      discount,
    },
  };
}

export function SET_ENGINEERING_LAYOUT_FLAGS(data: { engineering_layout_flags: {} }) {
  const val = data.engineering_layout_flags;
  return {
    type: EngineeringActionTypes.SET_ENGINEERING_LAYOUT_FLAGS,
    payload: val
  };
}
