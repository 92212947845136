import { isASCE716or722 } from "__common/constants/buildingCodes";
import { isRM10orRM10Evo, isRMGridFlex, isRmGridflex10 } from "__common/constants/products";
import { applyRM5Revamp } from "__common/utils/versionCompare/versionCompare";
import { RiskCategory } from "./riskCategory";

export const apiField = 'allow_mechanical_attachments';

const config = (building_code: number, risk_category: number, productId?: number, projectVersion?: string) => ({
  label: 'allowMechanicalAttachments',
  type: 'Select',
  apiField,
  missing: undefined,
  data: [
    { value: 1, name: 'YES' },
    ...(isASCE716or722(building_code) && risk_category === RiskCategory.IV && !(isRM10orRM10Evo(productId) || isRmGridflex10(productId) || applyRM5Revamp(projectVersion) || isRMGridFlex(productId)) ? [] : [{ value: 0, name: 'NO' }]),
  ],
});

export default config;
