import { ActionTypes } from 'actionsConstants';

export function fetchAuroraDesignConfigInitDataRequest(
    auroraTenantId, 
    auroraProjectId, 
    auroraDesignId,
    auroraUserId,
    auroraSessionToken) {
    return {
      type: ActionTypes.AURORA_DESIGN_CONFIG_INIT_DATA_REQUEST,
      payload: {
        auroraTenantId,
        auroraProjectId,
        auroraDesignId,
        auroraUserId,
        auroraSessionToken,
      },
    }
}


export function IMPORT_AURORA_PROJECT_FAILED() {
    return {
      type: ActionTypes.IMPORT_AURORA_PROJECT_FAILED,
    };
  }

  export function IMPORT_AURORA_FULL_DESIGN_PROJECT(data: any = {}) {
    return {
      type: ActionTypes.IMPORT_AURORA_FULL_DESIGN_PROJECT,
      payload: {
        data,
      },
    };
  }
  
  export function IMPORT_AURORA_PROJECT_SUCCESS(projectData: any = {}) {
    return {
      type: ActionTypes.IMPORT_AURORA_PROJECT_SUCCESS,
      payload: {
        projectData,
      },
    };
  }

  export function REDIRECT_AURORA_PAGE() {
    return {
      type: ActionTypes.REDIRECT_AURORA_PAGE,
    };
  }

  export function SET_AURORA_BACKGROUND_VISIBLE() {
    return {
      type: ActionTypes.SET_AURORA_BACKGROUND_VISIBLE,
    };
  }

  export function CLEAR_AURORA_BACKGROUND_VISIBLE() {
    return {
      type: ActionTypes.CLEAR_AURORA_BACKGROUND_VISIBLE,
    };
  }
