import { number } from 'prop-types';
import { state } from '__common/store';

export function checkModelData() {
  const { moduleSelector: { modelData: { thickness, watts, weight, width, height, thicknessmm, id } } } = state();

  return Number(thickness) &&
    Number(watts) &&
    Number(weight) &&
    Number(thicknessmm) &&
    Number(height) &&
    Number(width) &&
    Number(id);
}
