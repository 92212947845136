export const apiField = 'central_support';
interface centralSupportField {
  type: string;
  apiField: string;
  label: string;
  data?: centralSupportOption[];
  id?: string;
  aria_label?: string;
}
interface centralSupportOption {
  value: number;
  name: string;
}

function makeConfig(type: string, label: string): centralSupportField {
  return {
    type,
    label,
    apiField,
    ...(type === 'Select' ? 
    { data: centralSupportOptionsEF } : { id: "centralSupport", aria_label: "Central Support Selection"})
  };
}

export const centralSupportOptionsEF: centralSupportOption[] = [
  {
    value: 0,
    name: 'NONE',
  },
  {
    value: 1,
    name: 'SUPPLEMENTAL BASE',
  },
  {
    value: 2,
    name: 'MID SUPPORT',
  },
]

export const centralSupportForRM10 = makeConfig('Checkbox', 'addSupplementalBays')

export const centralSupportForRM10evo = makeConfig('Checkbox', 'addCentralSupportForModule')

export const centralSupportForEF2 = makeConfig('Select', 'centralSupport')