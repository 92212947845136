import { products } from '__common/constants/products';

export function getProductLink(productId: number) {
  switch (productId) {
    case products.solarmount_2:
      return '/project/design/solarmount_2';
    case products.solarmount_2_tilt:
    case products.nxt_tilt:
    // case products.adjustable_tilt_system:
      // return '/project/design/adjustable_tilt_system';
      return '/project/design/solarmount_2_tilt';
    case products.sfmEnhanced:
      return '/project/design/sfmEnhanced';
    case products.sfmInfinity:
      return '/project/design/sfmInfinity';
    case products.metal_x:
      return '/project/design/metal_x';
    case products.sunframe:
      return '/project/design/sunframe';
    case products.nxt_umount:
      return '/project/design/nxt_umount';
    case products.ascender:
      return '/project/design/ascender'
    case products.rm_5:
      return '/project/design/rm_5';
    case products.rm:
      return '/project/design/rm';
    case products.rm_dt:
      return '/project/design/rm_dt';
    case products.rm_10_ultra:
      return '/project/design/rm_10_ultra';
    case products.rm_10_evolution:
      return '/project/design/rm_10_evolution';
    case products.rm_gridflex:
      return '/project/design/rm_gridflex';
    case products.rm_gridflex_10:
      return '/project/design/rm_gridflex_10';  
    case products.ecofoot2_plus:
      return '/project/design/ecofoot2_plus';
    case products.gft:
      return '/project/design/gft';
    case products.ula:
      return '/project/design/ula';
    case products.sm_tilt_pr:
      return '/project/design/sm_tilt_pr'
    case products.helioscope:
      return '/hs/list';
    default:
      return '/project/design/solarmount_2';
  }
}
