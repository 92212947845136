import keyMirror from 'keymirror';

export const RoofZonesActionTypes = keyMirror({
  TOGGLE_WIND_ZONE: undefined,
  TOGGLE_EXP_ZONE: undefined,
  TOGGLE_RESTRICTED_ZONE: undefined,
  TOGGLE_OBSTRUCTIONS_ZONE: undefined,
  TOGGLE_SKEW_ZONE: undefined,
  TOGGLE_SKEW_AFFECTED_MODULES: undefined,
});
