import * as React from 'react';
import AppHistory from '__common/modules/history';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';

type Props = {
  action?: Function,
  dispatch?: Function,
  onClick?: Function,
  url?: string,
};

export default class ContinueButton extends React.Component<Props, {}> {

  @autobind
  onClick() {
    const { dispatch, url, action, onClick } = this.props;
    if (action) {
      dispatch(action());
    }

    if (url) {
      AppHistory.push(`${url}`);
    }

    if (onClick) {
      onClick();
    }
  }

  render() {
    return (
      <div role="button" onClick={this.onClick} className="continue-button">
      CONTINUE
      <SVG src={require('assets/media/icons/forward_arrow_icon.svg')} />
    </div>);
  }
}
