import ScaleToolModel from '__editor/panelsEditor/models/scaleTool';
import { getRenderer } from '__editor/panelsEditor/components/renderer/renderer';
import { getStage, removeChild } from '__editor/panelsEditor/components/stage/stage';
import { state } from '__common/store';

export function drawingScaleToolOnStage() {
  const { scale: { startPos, endPos } } = state();
  const stage = getStage();
  const renderer = getRenderer();
  stage.children = removeChild(stage.children, 'scaleTool');
  stage.addChild(ScaleToolModel(startPos.x, startPos.y, endPos.x, endPos.y));

  renderer.render(stage);
}
