import React from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  id: string,
  content: string,
  position: 'top' | 'right' | 'bottom' | 'left',
};

export default class Tooltip extends React.Component<Props, {}> {
  render() {
    const { content, position, id } = this.props;
    return (
      <>
      <a
        data-for={id}
        data-tip={content}
        data-html={true}
      >
        <img src={require('assets/media/icons/questionmark.png')} />
      </a>
      <ReactTooltip
        className="project-configuration-tooltip place-bottom"
        effect="solid"
        type="info"
        id={id}
        place={position}
      />
    </>);
  }
}
