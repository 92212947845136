import { clearProjectData } from '__editor/components/roofsSelector/roofsSelectorHelper';
import { dispatch, state } from '__common/store';
import { getDefaultProjectConfiguration } from './components/projectConfiguration/utils/defaultProps';
import { getProductName, isNxtHorizon, isRM10, isSMAscenderFlush, isSMHD, isSMLT, isSMRegular, isSMTiltPR, isSolarMount, products } from '__common/constants/products';
import { CLEAR_DATA_TO_LOAD, CLEAR_ENV_FACTORS_FOR, LOAD_PROJECT_CONFIGURATION, OPEN_DRAWER_PAGE, PREVENT_AUTO_SAVE_FINISHED, PREVENT_AUTO_SAVE_START, SET_INPUT_UNIT, SET_MAP_STATE, SET_PRODUCT_ID, SET_PRODUCT_TITLE, SET_PROJECT_CONFIGURED, SET_PROJECT_NAME, SET_PROJECT_OPTION } from 'actions';
import { setProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import { clearAdvanceRoofSelecting } from '__editor/components/roofsSelector/components/roofsSelectorAdvanceRoofSelecting/roofsSelectorAdvanceRoofSelecting';
import { projectDesignProps } from '_containers/ProjectDesign';
import { getCurrentMap } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import { showErrorAlert } from '__common/modules/alerts';
import { getProductTitle } from '__common/constants/products_titles';
import { setInputUnit, setMapType, setPanelsModel, setSpansProductId } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/loadProject';
import { onZipCodeChange } from './components/projectConfiguration/utils/updateProjectOption';
import { NxtHorizonRailType, SmFlushRailType } from './components/projectConfiguration/utils/constants';
import { getMapTypeInt } from '__common/constants/map';


var rM10DepricatingWarning = `<div>
  <p style="color: white; text-align : justify"> Unirac is discontinuing RM10 from distribution channel. Use RM10 EVO for all RM10 projects.</p>
  <p style="color: white; text-align : justify">RM10 EVO offers :</p>
  <p style="color: white; text-align : justify"> -15% greater module density (13.5" row spacing)</p>
  <p style="color: white; text-align : justify"> -Reliable 25-year clamping method</p>
  <p style="color: white; text-align : justify">If you have any questions, please contact your account manager.</p>
  <div/>`

var rm10Alert;
const rm10AlertFunc = () => {
  rm10Alert = showErrorAlert(rM10DepricatingWarning, 10000);
}

export function startNewProject(productId: number, project_config?: any) {

  let helioscope;
  if (productId === 98) {
    helioscope = true;
  }
  // close all menus
  const anyElementOnPage: any = document.querySelector('.header');

  if (anyElementOnPage) {
    anyElementOnPage.click();
  }

  clearProjectData(getProductName(productId), helioscope);

  const map = getCurrentMap();

  if (map) {
    map.setZoom(18);
  }

  setMapType(getMapTypeInt(project_config?.map_type));


  let projectConfigurationData = getDefaultProjectConfiguration(productId);

  if (project_config) {
    dispatch(PREVENT_AUTO_SAVE_START());
    dispatch(SET_MAP_STATE(true));
    let zipcode = project_config.zipcode;
    project_config.zipcode = '0';
    onZipCodeChange(zipcode, productId, project_config, project_config?.map_type);
    projectConfigurationData = Object.assign({}, projectConfigurationData, project_config);
    dispatch(SET_PROJECT_OPTION('rail_type', getRailType(productId)));
    setPanelsModel(project_config);
    dispatch(SET_PRODUCT_TITLE(getProductTitle(project_config?.productId)));
    dispatch(SET_PROJECT_NAME(getProductTitle(project_config?.productId).name.substring(2)));
    setProductSettings(productId, project_config?.map_type, true);

  } else {
    setProductSettings(productId, project_config?.map_type, false);
  }
  dispatch(LOAD_PROJECT_CONFIGURATION(projectConfigurationData, productId));
  if (isSolarMount(project_config?.railsProductId)) {
    setSpansProductId(project_config?.railsProductId);
  }

  delete projectConfigurationData.model;
  delete projectConfigurationData.module;

  setInputUnit(project_config?.input_unit);

  dispatch(OPEN_DRAWER_PAGE('module-selection'));
  clearAdvanceRoofSelecting();

  if (isRM10(productId)) {
    rm10AlertFunc();
  }

  if (!isRM10(productId)) {
    if (rm10Alert) {
      rm10Alert?.dismiss();
    }
  }

  dispatch(SET_PROJECT_CONFIGURED());
  dispatch(CLEAR_DATA_TO_LOAD());
  dispatch(PREVENT_AUTO_SAVE_FINISHED());
}

export function panelsEditorEnabled() {
  const { background: { cords }, moduleSelector: { modelData } } = state();
  const panelsEditorEnabled = cords;
  return panelsEditorEnabled && Object.keys(modelData).length;
}

// by back browser button
export const userWentBackToDesignLtOrHd = (props: projectDesignProps, nextProps: projectDesignProps) => props.match.params.productId === 'solarmount_2' && nextProps.match.params.productId === 'solarmount_lt' || nextProps.match.params.productId === 'solarmount_lt';


export function getRailType(productId) {

  if (isSolarMount(productId)) {
    if (isSMRegular(productId)) {
      return SmFlushRailType.SM;
    } else if (isSMLT(productId)) {
      return SmFlushRailType.SM_LT;
    } else if (isSMHD(productId)) {
      return SmFlushRailType.SM_HD;
    } else if (isSMAscenderFlush(productId)) {
      return SmFlushRailType.SM_ASCENDER_FLUSH;
    }
  } else if (isNxtHorizon(productId)) {
    if (productId === 26) {
      return NxtHorizonRailType.NH;
    } else if (productId === 27) {
      return NxtHorizonRailType.NH_LT;
    } else if (productId === 28) {
      return NxtHorizonRailType.NH_HD;
    }
  } else if (isSMTiltPR(productId)) {
    if (productId === products.sm_tilt_pr) {
      return SmFlushRailType.SM;
    } else if (productId === products.sm_tilt_pr_lt) {
      return SmFlushRailType.SM_LT;
    }

  }
}
