export const apiField = 'snow_load';

export interface SnowLoadField {
  type: string;
  apiField: string;
  missing?: any;
  label: string;
  tooltip: () => string;
  data?: SnowLoadFieldOption[];
  min: number;
  max: number;
  disable: boolean;
}

export interface SnowLoadFieldOption {
  value: string;
  name: string;
}
