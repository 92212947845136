import { kdTree } from '__common/vendor/kdTree';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';

let stickPoints = new kdTree([], calcDistanceOfTwoPoints, ['x', 'y', 'id']);

interface kdTreePoint {
  id: number;
  x: number;
  y: number;
}

export function insertPanelCenterPoints(point: { x: number, y: number, id: number }) {
  stickPoints.insert(point);
}

export function removePanelCenterPoints(point) {
  stickPoints.remove(point);
}

export function nearestPanelCenterPoint(point: { x: number, y: number }, count: number, distance: number): [kdTreePoint, number][] {
  return stickPoints.nearest(point, count, distance);
}

export function resetPanelCenterTree() {
  stickPoints = new kdTree([], calcDistanceOfTwoPoints, ['x', 'y', 'id']);
}

