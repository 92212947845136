import * as PIXI from 'pixi.js';

let renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer;

export function setupRenderer(width: number, height: number) {
  renderer = PIXI.autoDetectRenderer(
    width,
    height,
    { antialias: true },
);

  return renderer;
}

export function getRenderer() {
  return renderer;
}

export function destroyRenderer() {
  if (renderer) {
    renderer.destroy();
  }
}
