import { getYesOrNo } from "../projectConfiguration";

export const apiField = 'allow_dual_attachments'

const config = {
    label: 'allowDualAttachments',
    type: 'Select',
    apiField,
    missing: undefined,
    data: getYesOrNo()
};

export default config;
