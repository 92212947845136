import keyMirror from 'keymirror';

export const EngineeringActionTypes = keyMirror({
  FETCH_ENGINEERING_RAPORT: undefined,
  POPULATE_ENGINEERING_RAPORT: undefined,
  SEND_ENGINEERING_CLIENT_DETAILS: undefined,
  SEND_ENGINEERING_CLIENT_DETAILS_SUCCESS: undefined,
  UPDATE_ENGINEERING_CLIENT_DETAILS_SUCCESS: undefined,
  UPDATE_ENGINEERING_CLIENT_DETAILS: undefined,
  CLEAR_ENGINEERING_RAPORT: undefined,
  GET_CODE_COMPILANT_LETTER: undefined,
  SET_CODE_COMPILANT_LETTER: undefined,
  SET_DISCOUNT: undefined,
  REFRESH_PDFS: undefined,
  CHANGE_DISCOUNT: undefined,
  SET_ENGINEERING_LAYOUT_FLAGS: undefined,
});
