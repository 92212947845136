import React from 'react';
import { renderZoneInLegend, renderLegendOptionName, renderLegendHeader } from './legends';
import { MIDDLE_ROOF_COLOR, ON_THE_CORNER_COLOR, NEAR_ONE_EDGE_COLOR, MIDDLE_ROOF_PRIM_COLOR } from '__editor/panelsEditor/models/utils/panelColor';

export const renderASCE716FlatRoofLegend = () => {

  return (
    <div className="roof-zones-legend">
      {renderLegendHeader('Zones: ')}
      <div className="legend">
        {renderZoneInLegend(MIDDLE_ROOF_PRIM_COLOR)}
        {renderLegendOptionName('1\' Zone')}
      </div>
      <div className="legend">
        {renderZoneInLegend(MIDDLE_ROOF_COLOR)}
        {renderLegendOptionName('1 Zone')}
      </div>
      <div className="legend">
        {renderZoneInLegend(NEAR_ONE_EDGE_COLOR)}
        {renderLegendOptionName('2 zone')}
      </div>
      <div className="legend">
        {renderZoneInLegend(ON_THE_CORNER_COLOR)}
        {renderLegendOptionName('3 zone')}
      </div>
    </div>
  );
};

