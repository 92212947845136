import oldBuildingCode, { asce716BuildingCode, asce710716BuildingCode } from 'projectDesign/components/projectConfiguration/fields/buildingCode';
import { isCanadianZipcode } from './validation';

export const ASCE_7_16_ALLOWED_USER = 'aaa';

export const getBuildingCodeSfm = () => {
  return asce716BuildingCode;
};

export const getBuildingCodeRm = (zipcode: string = '') => {
  return isCanadianZipcode(zipcode) ? asce710716BuildingCode : asce716BuildingCode;
};

export const getBuildingCode710716 = () => {
  return asce710716BuildingCode;
};

export const getBuildingCodeSF = () => {
  // Sunframe is going to be discontinued 
  // because it doesn't have many customers.
  // Therefore, we don't need to allow for 7-16 for SF.
  return oldBuildingCode;
};
