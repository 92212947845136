import * as React from 'react';
import DataTable from 'react-md/lib/DataTables/DataTable';
import Loader from '__common/components/LoaderComponent';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHeader from 'react-md/lib/DataTables/TableHeader';
import TableRow from 'react-md/lib/DataTables/TableRow';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

type Props = {
  dispatch: Function;
  helioscopeDesignsList: helioscopeDesignsListState;
  projectID: string;
};

export default class HelioscopeDesignsList extends React.Component<Props, {}> {
  rows() {
    const { helioscopeDesignsList, projectID } = this.props;
    const designs = helioscopeDesignsList.designs;

    return (
      <TableBody>
        {designs.map(design => {
          const is_dt = design.is_dt;

          return design.field_segments_.map((segment, index) => {
            const isFirst = index === 0;
            return (
              <TableRow
                key={`helioscope-designs-list-component-table-${segment.description +
                  index}`}
                className={isFirst ? 'border-top' : 'no-border'}
              >
                <TableColumn>{isFirst && design.description}</TableColumn>
                {isFirst? this.renderDesignImports(is_dt, projectID, design.design_id): <TableColumn/>}
                <TableColumn>{segment.description}</TableColumn>
                {this.renderImports(is_dt, projectID, design.design_id, segment.field_segment_id)}
              </TableRow>
            );
          });
        })}
      </TableBody>
    );
  }

  renderDesignImports = (is_dt: boolean, projectId: string, designId: string) => {
    return is_dt ? (
      <TableColumn>
        <NavLink
          className="rmDt-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/rm_dt`}
        >
          RM DT
        </NavLink>
      </TableColumn>
    ) : (
      <TableColumn>
        <NavLink
          className="rm5-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/rm_5`}
        >
          RM 5
        </NavLink>
        <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/rm`}
        >
          RM 10
        </NavLink>          
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/rm_gridflex`}
        >
          RM GridFlex
        </NavLink>  }
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/rm_10_evolution`}
        >
          RM 10 Evo
        </NavLink>  }
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/ecofoot2_plus`}
        >
          EcoFoot2+
        </NavLink>  }
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId}/solarmount_2`}
        >
          Solar Mount Flush
        </NavLink>  }
        { <NavLink
          className="nxt-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/nxt_umount`}
        >
          NXT UMOUNT
        </NavLink>  }
      </TableColumn>
    );
  }

  renderImports = (is_dt: boolean, projectId: string, designId: string, segmentId: string) => {
    return is_dt ? (
      <TableColumn>
        <NavLink
          className="rmDt-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/rm_dt`}
        >
          RM DT
        </NavLink>
      </TableColumn>
    ) : (
      <TableColumn>
        <NavLink
          className="rm5-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/rm_5`}
        >
          RM 5
        </NavLink>
        <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/rm`}
        >
          RM 10
        </NavLink>          
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/rm_gridflex`}
        >
          RM GridFlex
        </NavLink>  }
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/rm_10_evolution`}
        >
          RM 10 Evo
        </NavLink>  }
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/ecofoot2_plus`}
        >
          EcoFoot2+
        </NavLink>  }
        { <NavLink
          className="nxt-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId
          }/${segmentId}/nxt_umount`}
        >
          NXT UMOUNT
        </NavLink>  }
        { <NavLink
          className="rm10-project-design import-design-link"
          to={`/hs/design/config/${projectId}/${
            designId}/${segmentId}/solarmount_2`}
        >
          Solar Mount Flush
        </NavLink>  }
      </TableColumn>
    );
  }

  render() {
    const { helioscopeDesignsList } = this.props;

    return helioscopeDesignsList.designs.length ? (
      <div className="helioscope_designs_list">
        <DataTable className="designs-table" plain={true}>
          <TableHeader>
            <TableRow>
              <TableColumn>
                <FormattedMessage id="design" />
              </TableColumn>
              <TableColumn>
                <FormattedMessage id="import.design.as" />
              </TableColumn>
              <TableColumn>
                <FormattedMessage id="field.segment" />
              </TableColumn>
              <TableColumn>
                <FormattedMessage id="import.segment.as" />
              </TableColumn>
            </TableRow>
          </TableHeader>
          {this.rows()}
        </DataTable>
      </div>
    ) : (
      <Loader />
    );
  }
}
