import * as React from 'react';
import AppHistory from '__common/modules/history';
import DataTable from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHeader from 'react-md/lib/DataTables/TableHeader';
import TablePagination from 'react-md/lib/DataTables/TablePagination';
import TableRow from 'react-md/lib/DataTables/TableRow';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { parseDate } from '__common/calculations/dateFormat';
import { PROJECTS_LIST_SET_PROJECTS_TO_SHOW, SET_HELIOSCOPE_PROJECT_LIST_SORTING } from 'actions';
type Props = {
  dispatch: Function,
  projectsList: any,
};

class HelioscopeProjectsList extends React.Component<Props, {}> {
  projects: any;

  paginationStart = 0;
  
  _handlePagination = (start, rowsPerPage) => {
    const { projectsList, dispatch } = this.props;

    this.paginationStart = start;

    const projects = projectsList.projects.slice(start, start + rowsPerPage);
    dispatch(PROJECTS_LIST_SET_PROJECTS_TO_SHOW(projects));
  }

  goToProjectDesigns = (projectId: string) => {
    AppHistory.push(`/hs/project/${projectId}`);
  }

  sort = (sortField: string) => {
    const { dispatch } = this.props;

    dispatch(SET_HELIOSCOPE_PROJECT_LIST_SORTING(sortField));

    this._handlePagination(this.paginationStart, 10);
  }

  rows() {
    const filter = this.props.projectsList.filter;
    if (filter && filter.length) {
      this.projects = this.props.projectsList.projects
        .filter(project => project.name.toLowerCase()
          .includes(filter.toLowerCase()));
    } else {
      this.projects = this.props.projectsList.projectsToShow;
    }

    return this.projects
      .map(project =>
        (
          <TableRow onClick={this.goToProjectDesigns.bind(this, project.project_id)} key={project.project_id}>
            <TableColumn>
              <div
                className="import-project-link"
              >
                {project.name}
              </div>
            </TableColumn>
            <TableColumn>
              {project.address}
            </TableColumn>
            <TableColumn>
              {parseDate(project.last_modified)}
            </TableColumn>
          </TableRow>
        ),
      );
  }

  render() {
    const { projectsList: { errorType, sortedField, sortMethod } } = this.props;

    if (errorType && errorType.length) {
      return (
        <div>{errorType}</div>
      );
    }
    return (
      <div className="helioscope-projects-list">
        <DataTable baseId="pagination" plain={true}>
          <TableHeader>
            <TableRow>
              <TableColumn
                className={sortedField === 'name' ? 'active-sort' : ''}
                sorted={sortedField === 'name'}
                onClick={this.sort.bind(null, 'name')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                <FormattedMessage id="project.name" />
              </TableColumn>
              <TableColumn
                className={sortedField === 'address' ? 'active-sort' : ''}
                sorted={sortedField === 'address'}
                onClick={this.sort.bind(null, 'address')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                <FormattedMessage id="address" />
              </TableColumn>
              <TableColumn
                className={sortedField === 'last_modified' ? 'active-sort' : ''}
                sorted={sortedField === 'last_modified'}
                onClick={this.sort.bind(null, 'last_modified')}
                sortIcon={
                  <i className="md-icon material-icons md-text--inherit">
                    {sortMethod === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up'}
                  </i>
                }
              >
                <FormattedMessage id="last.edit" />
              </TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.rows()}
          </TableBody>
          <TablePagination rowsPerPageLabel="SHOW" className="table-pagination" onPagination={this._handlePagination} rows={this.props.projectsList.projects.length} />
        </DataTable>
      </div>);
  }
}

function mapStateToProps(state) {
  return {
    projectsList: state.projectsList,
    locales: state.app.locales,
  };
}

export default connect(mapStateToProps)(HelioscopeProjectsList);
