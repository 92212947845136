import autobind from 'autobind-decorator';
import React from 'react';
import SaveChangesButton from '__common/components/Drawer/components/SaveChangesButton';
import { connect } from 'react-redux';
import { SEND_NEW_USER_PREFERENCES } from 'actions';

type Props = {
  dispatch: Function,
  moduleSelector: moduleSelectorState,
  userPreferences: userPreferencesState,
};

class UpdateButton extends React.Component<Props> {
  @autobind
  updateUserPreferences() {
    const {
      dispatch, 
      moduleSelector: {
        selectedMfgId,
        selectedModelId,
      },
      userPreferences: { 
        product, preferred_span, zipcode, projects_per_page, project_sorting, sort_method, helioscope_username, helioscope_password, helioscope_user, stagger_attachments, area, rafter_spacing_inches,
      }, 
    } = this.props;

    const userPreferences: userPreferences = {
      module: selectedMfgId,
      model: selectedModelId,
      product, 
      preferred_span, 
      zipcode, 
      projects_per_page: Number(projects_per_page),
      project_sorting, 
      sort_method, 
      helioscope_user,
      helioscope_username, 
      helioscope_password,
      stagger_attachments,
      rafter_spacing_inches,
      area,
    };

    if (!helioscope_user) {
      delete userPreferences.helioscope_username;
      delete userPreferences.helioscope_password;
    }
    
    dispatch(SEND_NEW_USER_PREFERENCES(userPreferences));
  }

  render() {
    return (
      <SaveChangesButton
        onClick={this.updateUserPreferences}
        label="Save Changes"
      />
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    userPreferences: appState.userPreferences,
    moduleSelector: appState.moduleSelector,
  };
}

export default connect(mapStateToProps)(UpdateButton);
