import Loader from '__common/components/LoaderComponent';
import React from 'react';
import { connect } from 'react-redux';
import { apiUrl } from 'local_config';
import { BALLAST_GENERATING_RESET, BALLAST_GENERATING_START } from './engineeringBallastMapsActions';
import { dispatch } from '__common/store';

type Props = {
  dispatch: Function,
  engineeringBallastMaps: engineeringBallastMapsState,
  arrayId: number,
  layoutType: number,
  projectId: string,
  engineeringProjectDocuments: engineeringProjectDocumentsState,
  unitsType: string
};

class EngineeringBallastMapsComponent extends React.Component<Props> {
  componentDidMount() {
    this.generateMap();
  }

  componentDidUpdate() {
    const { engineeringBallastMaps: { generatedArrayId, generatedLayoutType }, arrayId, layoutType } = this.props;

    if (arrayId !== generatedArrayId || 
      layoutType !== generatedLayoutType) {
      this.generateMap();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(BALLAST_GENERATING_RESET());
  }

  generateMap() {
    const { projectId, layoutType, arrayId, engineeringProjectDocuments : { units} } = this.props;
    dispatch(BALLAST_GENERATING_START(arrayId, layoutType, projectId, units ));
  }

  renderLoader() {
    return (<Loader />);
  }

  renderDocument() {
    const { projectId, engineeringBallastMaps: { generatedArrayId, generatedLayoutType }, engineeringProjectDocuments: { units } } = this.props;
    return <img src={`${location.protocol}//${apiUrl}api/v1/reports/${projectId}/layout/${generatedArrayId}/${generatedLayoutType}/${units}`} />;
  }

  render() {
    const { engineeringBallastMaps: { generated, generatingFailed } } = this.props;

    if (generated && !generatingFailed) {
      return this.renderDocument();
    } 

    return this.renderLoader();
  }
}

function mapStateToProps(appState: appState) {
  return {
    engineeringBallastMaps: appState.engineeringBallastMaps,
    engineeringProjectDocuments : appState.engineeringProjectDocuments
  };
}

export default connect(mapStateToProps)(EngineeringBallastMapsComponent);
