import { inchesToMeters } from 'maths';
import { state } from '__common/store';
import {
  isRMDT,
  isSF,
  isSFMFamily,
  isRM10,
  isRM5,
  isSMFamily,
  isRMFamily,
  isGFT,
  isULA,
  isMetalX,
  isRM10Evolution,
  isNxtHorizon,
  isEcoFoot2Plus,
  isAscender,
  isRMGridFlex,
  isSMTiltPR,
  isRmGridflex10,
  isNxtTilt,
  } from '__common/constants/products';

let currentNearestGroup;

export function _isRM5ORRM10() {
  const { projectConfiguration: { productId } } = state();
  return isRM5(productId) || isRM10(productId);
}

export function _isRM5_RM10_RMGridflex_RMEvo_EF2() {
  const { projectConfiguration: { productId } } = state();
  return isRM5(productId) || isRM10(productId) || isEcoFoot2Plus(productId) || isRMGridFlex(productId) || isRM10Evolution(productId) || isRmGridflex10(productId);
}

export function _isSF() {
  const { projectConfiguration: { productId } } = state();
  return isSF(productId);
}

export function _isSFM() {
  const { projectConfiguration: { productId } } = state();
  return isSFMFamily(productId);
}

export function _isRM10Evolution() {
  const { projectConfiguration: { productId } } = state();
  return isRM10Evolution(productId);
}

export function _isRM10() {
  const { projectConfiguration: { productId } } = state();
  return isRM10(productId);
}

export function _isRMGridflex10() {
  const { projectConfiguration: { productId } } = state();
  return isRmGridflex10(productId);
}
export function _isMetalX() {
  const { projectConfiguration: { productId } } = state();
  return isMetalX(productId);
}

export function _isRMDT() {
  const { projectConfiguration: { productId } } = state();
  return isRMDT(productId);
}

export function _isRMFamily() {
  const { projectConfiguration: { productId } } = state();
  return isRMFamily(productId);
}

export function _isEcoFoot2Plus() {
  const { projectConfiguration: { productId } } = state();
  return isEcoFoot2Plus(productId);
}

export function isSFEnchanced() {
  const { projectConfiguration: { productId } } = state();
  return isSFMFamily(productId);
}

export function _isSMFamily() {
  const { projectConfiguration: { productId } } = state();
  return isSMFamily(productId);
}

export function _isSMTiltPRFamily() {
  const { projectConfiguration: { productId } } = state();
  return isSMTiltPR(productId);
}

export function _isNxtTilt() {
  const { projectConfiguration: { productId } } = state();
  return isNxtTilt(productId);
}

export function _isGFT() {
  const { projectConfiguration: { productId } } = state();
  return isGFT(productId);
}

export function _isULA() {
  const { projectConfiguration: { productId } } = state();
  return isULA(productId);
}

export function _isNHFamily() {
  const { projectConfiguration: { productId } } = state();
  return isNxtHorizon(productId);
}

export function _isAscenderFamily() {
  const { projectConfiguration: { productId } } = state();
  return isAscender(productId);
}

export function getSnapDistancesInPixels() {
  const { background: { metersPerPixel } } = state();
  const inInches = 6;
  const meters = inchesToMeters(inInches);
  return meters / metersPerPixel;
}

export function getNearestGroup() {
  return currentNearestGroup;
}

export function setNearestGroup(group: number) {
  currentNearestGroup = group;
}
