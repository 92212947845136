import { getCursorSnappingPoint } from './snapToGridStickyPoints';
import {
  _isRMFamily,
  _isSMFamily,
  getSnapDistancesInPixels,
  isSFEnchanced,
  _isSF,
  _isGFT,
  _isULA,
  _isMetalX,
  _isNHFamily,
  _isAscenderFamily,
  _isEcoFoot2Plus,
  _isSMTiltPRFamily,
  _isNxtTilt,
  } from './snapToGridHelper';
import { IPANEL_CORNER } from '../snapToGrid';
import { getPixiPanel } from '../../panels/utils/panelsManagment';

export function snapOnlyToAxies(
  point: { x: number; y: number },
  nearestCorner: IPANEL_CORNER,
  panelIds: number[],
) {
  if (_isSMFamily() || _isSF() || _isNHFamily() || _isSMTiltPRFamily() || _isNxtTilt()) {
    return smSnapping(point, nearestCorner, panelIds);
  }

  if (isSFEnchanced() || _isMetalX()) {
    return sfmSnapping(point, nearestCorner, panelIds);
  }
  
  if (_isRMFamily() || _isEcoFoot2Plus()) {
    return rmSnapping(point, nearestCorner);
  }

  if (_isGFT() || _isULA() || _isAscenderFamily()) {
    return gftandUlaSnapping(point, nearestCorner);
  }

  return {
    snapped: false,
    cursorCorner: null, 
    panelCorner: null,
    x: point.x, 
    y: point.y,
  };
}
  

function rmSnapping(
  point: { x: number; y: number },
  panelNearestCorner: IPANEL_CORNER,
) {
  let x = point.x;
  let y = point.y;

  let snapped1 = false;
  let snapped2 = false;

  if (Math.abs(point.x - panelNearestCorner.x) < getSnapDistancesInPixels()) {
    x = panelNearestCorner.x;
    snapped1 = true;
  }

  if (Math.abs(point.y - panelNearestCorner.y) < getSnapDistancesInPixels()) {
    y = panelNearestCorner.y;
    snapped2 = true;
  }

  return {
    x,
    y,
    cursorCorner: panelNearestCorner.cursorCorner,
    panelCorner: panelNearestCorner.panelCorner,
    snapped: snapped1 && snapped2,
  };
}

function gftandUlaSnapping(
  point: { x: number; y: number },
  panelNearestCorner: IPANEL_CORNER,
) {
  let x = point.x;
  let y = point.y;

  let snapped1 = false;
  let snapped2 = false;

  if (Math.abs(point.x - panelNearestCorner.x) < getSnapDistancesInPixels()) {
    x = panelNearestCorner.x;
    snapped1 = true;
  }

  if (Math.abs(point.y - panelNearestCorner.y) < getSnapDistancesInPixels()) {
    y = panelNearestCorner.y;
    snapped2 = true;
  }

  return {
    x,
    y,
    cursorCorner: panelNearestCorner.cursorCorner,
    panelCorner: panelNearestCorner.panelCorner,
    snapped: snapped1 && snapped2,
  };
}

function smSnapping(
  point: { x: number; y: number },
  panelNearestCorner: IPANEL_CORNER,
  panelIds: number[],
) {
  let x = point.x;
  let y = point.y;

  let snapped1 = false;
  let snapped2 = false;
  let snapped = false;
 
  const cursorStickyPoint = getCursorSnappingPoint(point.x, point.y);

  if (Math.abs(point.x - panelNearestCorner.x) < getSnapDistancesInPixels()) {
    x = panelNearestCorner.x;
    snapped1 = true;
  }

  if (Math.abs(point.y - panelNearestCorner.y) < getSnapDistancesInPixels()) {
    y = panelNearestCorner.y;
    snapped2 = true;
  }

  panelIds.map(panelId => {
    const panel: PIXI.Graphics = getPixiPanel(panelId);
  
    if (!panel) {
      return;
    }

    const offset = getSnapDistancesInPixels();
    
    const panelMinY = getMinStickyPoint(panel.stickPoints, 'y');
    const panelMaxY = getMaxStickyPoint(panel.stickPoints, 'y');

    cursorStickyPoint.map(stickPoint => {
      if (
        areTopCursorStickyPoints(stickPoint.corner) &&
        isInPanelAreaWithOffset(stickPoint, panel.stickPoints, offset) &&
        isYPointOnTopSide(stickPoint.y, panelMaxY, offset)
      ) {
        snapped = true;
        if (!snapped1 && !snapped2) {
          y = panelMaxY + panel.height / 2;
        }
      }
  
      if (
        areBotttomCursorStickyPoints(stickPoint.corner) &&
        isInPanelAreaWithOffset(stickPoint, panel.stickPoints, offset) &&
        isYOnBottomSide(stickPoint.y, panelMinY, offset)
      ) {
        snapped = true;
        if (!snapped1 && !snapped2) {
          y = panelMinY - panel.height / 2;
        }
      }
    });
  });

  return {
    x,
    y,
    cursorCorner: panelNearestCorner.cursorCorner,
    panelCorner: panelNearestCorner.panelCorner,
    snapped: (snapped1 && snapped2) || snapped,
    snappedToY: snapped && !(snapped1 && snapped2),
  };
}

function isYPointOnTopSide(cursorPointY: number, panelMaxY: number, offset: number) {
  return Math.round(cursorPointY) >= Math.round(panelMaxY) - offset &&
    Math.round(cursorPointY) <= Math.round(panelMaxY) + offset;
}

function isYOnBottomSide(cursorPointY: number, panelMinY: number, offset: number) {
  return  Math.round(cursorPointY) >= Math.round(panelMinY) - offset &&
      Math.round(cursorPointY) <= Math.round(panelMinY) + offset;
}

function isInPanelAreaWithOffset(cursorStickPoints: {x: number, y: number}, panelStickyPoints: { x: number, y: number }[], offset: number) {
  const panelMinX = getMinStickyPoint(panelStickyPoints, 'x');
  const panelMaxX = getMaxStickyPoint(panelStickyPoints, 'x');
  const panelMinY = getMinStickyPoint(panelStickyPoints, 'y');
  const panelMaxY = getMaxStickyPoint(panelStickyPoints, 'y');
  
  return cursorStickPoints.y > panelMinY - offset &&
      cursorStickPoints.y < panelMaxY + offset &&
      cursorStickPoints.x > panelMinX - offset &&
      cursorStickPoints.x < panelMaxX + offset;
}

function getMinStickyPoint(panelStickyPoints: { x: number, y: number }[], axis: string) {
  const points: number[] = panelStickyPoints.map(point => point[axis]);
  return Math.min(...points);
}

function getMaxStickyPoint(panelStickyPoints: { x: number, y: number }[], axis: string) {
  const points: number[] = panelStickyPoints.map(point => point[axis]);
  return Math.max(...points);
}



function areTopCursorStickyPoints(cornerPoint: number) {
  return cornerPoint >= 1 && cornerPoint <= 3;
}

function areBotttomCursorStickyPoints(cornerPoint: number) {
  return cornerPoint >= 4 && cornerPoint <= 6;
}

function sfmSnapping(
  point: { x: number; y: number },
  nearestCorner: IPANEL_CORNER,
  panelIds: number[],
) {
  let x = point.x;
  let y = point.y;

  let snapped1 = false;
  let snapped2 = false;
 
  if (Math.abs(point.x - nearestCorner.x) < getSnapDistancesInPixels()) {
    x = nearestCorner.x;
  }

  if (Math.abs(point.y - nearestCorner.y) < getSnapDistancesInPixels()) {
    y = nearestCorner.y;
  }

  const cursorStickyPoint = getCursorSnappingPoint(x, y);

  panelIds.map(panelId => {
    const panel: PIXI.Graphics = getPixiPanel(panelId);
    
    if (!panel) {
      return;
    }

    const panelMinX = getMinStickyPoint(panel.stickPoints, 'x');
    const panelMaxX = getMaxStickyPoint(panel.stickPoints, 'x');
    const panelMinY = getMinStickyPoint(panel.stickPoints, 'y');
    const panelMaxY = getMaxStickyPoint(panel.stickPoints, 'y');

    // const offset = getSnapDistancesInPixels();
    const offset = 0.001;
    cursorStickyPoint.some(stickPoint => {
      if (
        stickPoint.x >= panelMinX &&
        stickPoint.x <= panelMaxX + offset &&
        stickPoint.y >= panelMinY &&
        stickPoint.y <= panelMaxY + offset
      ) {
        snapped1 = true;
        snapped2 = true;
      }

      if (snapped1 && snapped2) {
        return true;
      }
    });
  });

  return {
    x,
    y,
    cursorCorner: nearestCorner.cursorCorner,
    panelCorner: nearestCorner.panelCorner,
    snapped: snapped1 && snapped2,
  };
}
 

