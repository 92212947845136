import { getLatestSchemaVersion } from './bitDataConverter/getLatestSchemaVersion';
import { toBitwiseData } from './bitDataConverter';
import { isRMDT, isGFT, isULA, isRMIFIProduct, isRMGridFlex, isAscender, isSolarMount, isNxtHorizon, isSMTiltPR, isRM10orRM10Evo, isEcoFoot2Plus, isRM5, isRM10, isRM10Evolution, isRmGridflex10, isNxtTilt } from '__common/constants/products';
import store from '__common/store';
import { isASCE716or722 } from '__common/constants/buildingCodes';

interface saveDataDetails {
  x: number;
  y: number;
  groupId: number;
  bitData: number;
  siblingId?: number;
  siblingSide?: number;
  panelEWPosition?: number;
  pairId?: number;
  panelConfig?: number;
  roofZoneId?: number;
  schemaVersion?: number;
  panelNSPosition?: number;
  tableHeight?: number;
  attached?: number;
  panelSetback?: number;
  manualAttachments?: (0|1)[]
  skewAffected?: number;
}

export function parseSaveData(data: panelsSaveData, productId: number): saveDataDetails {
  let siblingId: number;
  let siblingSide: number;
  let pairId: number;
  let panelConfig: number;
  let roofZoneId: number;
  const [x, y, bitData, groupId] = data;

  if (isRMDT(productId)) {
    siblingId = data[4];
    siblingSide = data[5];

    return {
      x,
      y,
      bitData,
      groupId,
      siblingId,
      siblingSide,
      schemaVersion: data[6],
    };
  }
  if (isAscender(productId)) {
    siblingId = data[4];
    siblingSide = data[5];
    pairId = data[7];
    roofZoneId = data[8];
    panelConfig = data[9]
    const panelEWPosition = data[6];
    return {
      x,
      y,
      bitData, 
      groupId,
      siblingId,
      siblingSide,
      panelEWPosition,
      pairId,
      roofZoneId,
      panelConfig,
      schemaVersion: data[10],
    };
  }

  if (isGFT(productId)) {
    siblingId = data[4];
    siblingSide = data[5];
    const panelEWPosition = data[6];
    return {
      x,
      y,
      bitData,
      groupId,
      siblingId,
      siblingSide,
      panelEWPosition,
      schemaVersion: data[7],
    };
  }

  if (isULA(productId)) {
    siblingId = data[4];
    siblingSide = data[5];
    const panelEWPosition = data[6];
    const panelNSPosition = data[8];
    const tableHeight = data[9];
    return {
      x,
      y,
      bitData,
      groupId,
      siblingId,
      siblingSide,
      panelEWPosition,
      schemaVersion: data[7],
      panelNSPosition,
      tableHeight,
    };
  }

  if (isRMGridFlex(productId)) {
    const schemaVersion = data[4];
    let panelSetback = 0
    let attached = 0, skewAffected = 0;
    if (schemaVersion === 3) attached = data[5];
    if(data.length > 6){
      panelSetback = data[6];
    }
    if(data.length > 7){
      skewAffected = data[7];
    }
    return {
      x,
      y,
      bitData,
      groupId,
      schemaVersion,
      attached, 
      panelSetback,
      skewAffected
    };
  }

  if ((isSolarMount(productId) || isNxtHorizon(productId) || isSMTiltPR(productId) || isNxtTilt(productId)) && data.length > 5) {
    return {
      x,
      y,
      bitData,
      groupId,
      roofZoneId: data[4],
      schemaVersion: data[5],
    };
  }

  if (isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRM5(productId)) {
    let panelSetback = 0, schemaVersion = data[4], skewAffected = 0;
    let manualAttachments = undefined
    if (data.length > 5) {
      panelSetback = data[4];
      manualAttachments = data[5]
      schemaVersion = data.pop();
    }
    if(data.length > 6) {
      skewAffected = data[6];
    }
    return {
      x,
      y,
      bitData,
      groupId,
      panelSetback,
      schemaVersion,
      manualAttachments,
      skewAffected,
    };
  }
  if ( isRmGridflex10(productId)) {
    let panelSetback = 0, schemaVersion = data[4];
    let attached=0, skewAffected = 0;
    if (schemaVersion === 3) attached = data[7];
    if(data.length > 5){
      panelSetback = data[4];
      schemaVersion = data[5];
    }
    if(data.length > 7){
      skewAffected = data[7];
    }
    return {
      x,
      y,
      bitData, 
      groupId,
      panelSetback,
      schemaVersion,
      attached,
      skewAffected
    };
  }
  return {
    x,
    y,
    bitData,
    groupId,
    schemaVersion: data[4],
  };
}

export function convertToSaveData(
  panel: panelInState,
  productId: number,
  buildingCode: number,
  roofPitch: number,
  baysInfo?: any,
): panelsSaveData | panelsSaveDataRM10Evo {
  const {projectConfiguration: { projectEnvConfig: { allow_manual_attachments, is_aurora_project, building_code } }} = store.getState() as appState;
  const schemaVersion = getLatestSchemaVersion(productId, buildingCode);
  const bitData = toBitwiseData(panel, buildingCode, productId, schemaVersion, roofPitch);
  const x = parseFloat(panel.x.toFixed(4));
  const y = parseFloat(panel.y.toFixed(4));
  const groupId = panel.groupId;
  const baysData = baysInfo && Object.values(baysInfo).map((bay:any) => bay.attached).map(attached=> attached?1:0) 
  if (isRMDT(productId)) {
    const siblingId = panel.siblingId;
    const siblingSide = panel.siblingSide;
    return [x, y, bitData, groupId, siblingId, siblingSide, schemaVersion];
  }
  if (isAscender(productId)) {
    const { siblingId, siblingSide, panelEWPosition, pairId, panelConfig } = panel;
    const roofZoneId = panel.roofZone;
    return [x, y, bitData, groupId, siblingId, siblingSide, panelEWPosition, pairId, roofZoneId, panelConfig, schemaVersion];
  }
  if (isGFT(productId)) {
    const { siblingId, siblingSide, panelEWPosition } = panel;
    return [x, y, bitData, groupId, siblingId, siblingSide, panelEWPosition, schemaVersion];
  }
  if (isULA(productId)) {
    const { siblingId, siblingSide, panelEWPosition, panelNSPosition, tableHeight } = panel;
    return [x, y, bitData, groupId, siblingId, siblingSide, panelEWPosition, schemaVersion, panelNSPosition, tableHeight];
  }
  if (isRMIFIProduct(productId)) {
    return [panel.x, panel.y, panel.obstructionType, groupId, schemaVersion, Number(panel.attached), panel.panelSetback, panel.skewAffected ?? 0];
  }
  if (isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRM5(productId)){
    const manualAttachmentsSelection: (0 | 1)[] = allow_manual_attachments ? baysData??panel.manualAttachments : [0,0,0,0]
    return [panel.x, panel.y, bitData, groupId, panel.panelSetback, manualAttachmentsSelection, panel.skewAffected ?? 0, schemaVersion];
  }

  if (isSolarMount(productId) || isNxtHorizon(productId) || isSMTiltPR(productId) || isNxtTilt(productId)) {
    let roofZoneId = panel.roofZone;
    if (is_aurora_project && isASCE716or722(buildingCode)){
      roofZoneId = 1
    }
    return [panel.x, panel.y, bitData, groupId, roofZoneId, schemaVersion];
  }

  if (isRmGridflex10(productId)){
    return [panel.x, panel.y, bitData, groupId, panel.panelSetback, schemaVersion, Number(panel.attached), panel.skewAffected ?? 0];
  }

  return [panel.x, panel.y, bitData, groupId, schemaVersion];
}
