export const cmsToInches = (cms: number) => cms * 0.393701;

export const cmsToMeters = (cms: number) => cms / 100;

export const inchesToCms = (inches: number) => inches * 2.54;

export const kgsToLbs = (kgs: number) => kgs * 2.20462;

export const lbsToKgs = (lbs: number) => lbs * 0.453592;

export const metersToCms = (meters: number): number => meters * 100;

export const milesToKms = (miles: number) => miles * 1.60934;

export const kmsToMiles = (kms: number) => kms / 1.60934;

export const psfToKpa = (psf: number) => psf * 	0.04788026;

export const kpaToPsf = (kpa: number) => kpa * 20.885433788371;

export const cmsToFeets = (cms: number) => cms / 30.48;

export const feetsToCms = (fts: number) => fts * 30.48;
