import { HistoryActionTypes } from 'actionsConstants';

export function UNDO_HISTORY() {
  return {
    type: HistoryActionTypes.UNDO_HISTORY,
  };
}

export function REDO_HISTORY() {
  return {
    type: HistoryActionTypes.REDO_HISTORY,
  };
}


export function PUT_PANELS_IN_HISTORY(panels) {
  return {
    type: HistoryActionTypes.PUT_PANELS_IN_HISTORY,
    payload: {
      panels,
    },
  };
}

export function RESET_HISTORY() {
  return {
    type: HistoryActionTypes.RESET_HISTORY,
  };
}

export function CLEAR_HISTORY() {
  return {
    type: HistoryActionTypes.CLEAR_HISTORY,
  };
}
