export default () => `
  <div class="content">
    <div>Roof Pitch/Angle Conversion Chart</div>
    <br />
    <div class="table">
      <div class="row">
        <div><b>Pitch</b></div>
        <div><b>Angle</b></div>
      </div>
      <div class="row">
        <div>1/12</div>
        <div>4.76</div>
      </div>
      <div class="row">
        <div>2/12</div>
        <div>9.46</div>
      </div>
      <div class="row">
        <div>3/12</div>
        <div>14.04</div>
      </div>
      <div class="row">
        <div>4/12</div>
        <div>18.43</div>
      </div>
      <div class="row">
        <div>5/12</div>
        <div>22.62</div>
      </div>
      <div class="row">
        <div>6/12</div>
        <div>26.57</div>
      </div>
      <div class="row">
        <div>7/12</div>
        <div>30.26</div>
      </div>
      <div class="row">
        <div>8/12</div>
        <div>33.69</div>
      </div>
      <div class="row">
        <div>9/12</div>
        <div>36.87</div>
      </div>
      <div class="row">
        <div>10/12</div>
        <div>39.81</div>
      </div>
      <div class="row">
        <div>11/12</div>
        <div>42.51</div>
      </div>
      <div class="row">
        <div>12/12</div>
        <div>45.00</div>
      </div>
    </div>
  </div>`;
