import { DISABLE_RUBBER_BAND, MOVE_ARRAY_MODE_DISABLE, MOVE_MAP_MODE_DISABLE, RESTORE_PANELS_FOR_NO_MOVEMENT, TERMINATE_ADVANCE_ROOF_SELECTING, TOGGLE_ATTACHMENTS_DISABLE, TURN_ON_MEASUREMENT_TOOL } from 'actions';
import autobind from 'autobind-decorator';
import React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';

type Props = {
  enableMeasurementTool: () => void;
  disableMoveMap: () => void;
  disableMoveArray: () => void;
  disableToggleAttachment: () => void;
  terminateDrawObstructions: () => void,
  disableRubberBand: () => void,
  measurementToolEnabled: boolean,
  moveArrayEnabled: boolean,
};

class MeasurementToolButton extends React.Component<Props> {
  @autobind
  toggleMeasurementTool() {
    this.props.disableMoveMap();
    if(this.props.moveArrayEnabled) {
      this.props.disableMoveArray();
    }
    this.props.disableToggleAttachment();
    this.props.terminateDrawObstructions();
    this.props.disableRubberBand();
    return this.props.enableMeasurementTool();
  }

  render() {
    const { measurementToolEnabled } = this.props;
    return (
      <Button flat={true} className="roofs-selector-button" onClick={this.toggleMeasurementTool} tooltipLabel="Measuring Tool">
        <SVG className={measurementToolEnabled ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_ruler.svg')} />
      </Button>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    disableMoveMap: () => dispatch(MOVE_MAP_MODE_DISABLE()),
    terminateDrawObstructions: () => dispatch(TERMINATE_ADVANCE_ROOF_SELECTING()),
    disableMoveArray: () => {
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
    },
    disableToggleAttachment: () => dispatch(TOGGLE_ATTACHMENTS_DISABLE()),
    enableMeasurementTool: () => dispatch(TURN_ON_MEASUREMENT_TOOL()),
    disableRubberBand: () => dispatch(DISABLE_RUBBER_BAND()),
  };
};

const mapStateToProps = (appState: appState) => {
  return {
    measurementToolEnabled: appState.measurementTool.enabled,
    moveArrayEnabled: appState.background.moveArrayMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementToolButton);

