import * as React from 'react';
import ReportProblemButton from 'app/components/ReportProblem/ReportProblemButton';
import SVG from 'react-inlinesvg';
import NotificationStatusBar from './FooterComponent/NotificationStatusBar';

const goToAskQuestion = () => {
  window.open('https://unirac.com/contact-us/', '_blank');
};

const goToFacebook = () => {
  window.open('https://www.facebook.com/uniracinc/', '_blank');
};

const goToTwitter = () => {
  window.open('https://twitter.com/Unirac', '_blank');
};

const goToLinkedin = () => {
  window.open('https://www.linkedin.com/company/unirac-inc-/', '_blank');
};

const goToYoutube = () => {
  window.open('https://www.youtube.com/user/uniracsolar1/', '_blank');
};

const goToUniracSite = () => {
  window.open('http://www.unirac.com', '_blank');
};

const Footer = ({ ContainerFooter, props, RightSideFooter }: { ContainerFooter?: React.ComponentType, props?: any, RightSideFooter?: any }) => (
  <footer className="app__footer">
    <NotificationStatusBar />
    <div className="footer_button">
    {ContainerFooter && <ContainerFooter {...props}/>} 
    </div>
    <ReportProblemButton />
  <div className="contact-field">
    {RightSideFooter && RightSideFooter()}
    <div className="share" onClick={goToUniracSite}>
      <SVG src={require('assets/media/icons/icon_unirac.svg')} />
      <span className="icon-desc">
        UNIRAC
        </span>
    </div>
    <div className="social-media-icons">
      <div onClick={goToFacebook}><SVG src={require('assets/media/icons/facebook_icon.svg')} /></div>
      <div onClick={goToYoutube}><SVG src={require('assets/media/icons/youtube_icon.svg')} /></div>
      <div onClick={goToTwitter}><SVG src={require('assets/media/icons/twitter_icon.svg')} /></div>
      <div onClick={goToLinkedin}><SVG src={require('assets/media/icons/linkedin_icon.svg')} /></div>
    </div>
  </div>
  </footer >
);

export default Footer;
