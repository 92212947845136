import localConfig from 'local_config';
import NPMPackage from '../../package.json';

const config = {
  name: NPMPackage.name,
  title: NPMPackage.title,
  description: NPMPackage.description,
  ...localConfig,
};

export default config;
