import { state } from "__common/store";
import { measurementToolModel } from "__editor/googleMapsRoofsSelector/models/measurementTool";
import { getPixiLayer } from "../stage/stage";

let measurementTool;

export const renderMeasurementTool = () => {
  
  const { measurementTool: { startingPoint, endingPoint }, projectConfiguration: { productId, inputUnit } } = state();
  const pixiLayer: any = getPixiLayer();
  pixiLayer.addLayer((layer: PIXI.Container) => {
    const scale = getPixiLayer().stage.scale.x;
    measurementTool = measurementToolModel(startingPoint, endingPoint, scale, productId, inputUnit);
    layer.id = 'measurementTool';
    pixiLayer.stage.children = pixiLayer.stage.children.filter(child => child.id !== 'measurementTool');
    layer.addChild(measurementTool);
  });
};