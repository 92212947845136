

import { PanelsActionTypes } from 'actionsConstants';
import { dispatch, state } from '__common/store';
import { PUT_PANELS_IN_HISTORY } from 'actions';

const actions = [
  PanelsActionTypes.REMOVE_PANELS, 
  PanelsActionTypes.ADD_PANELS,
  PanelsActionTypes.CHANGE_SINGLE_PANEL_ROOF_ZONE,
];

export const panelsHistoryMiddleware = store => next => action => {
  next(action);

  if (actions.includes(action.type)) {
    const { panels: { panels } } = state();  
    dispatch(PUT_PANELS_IN_HISTORY(panels));
  }
};
