import { TooltipCursorActionTypes } from 'actionsConstants';

export function SET_TOOLTIP_CONTENT(content: string | null) {
  return {
    type: TooltipCursorActionTypes.SET_TOOLTIP_CONTENT,
    payload: {
      content,
    },
  };
}
