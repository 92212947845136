import keyMirror from 'keymirror';

export const PanelsActionTypes = keyMirror({
  ADD_PANELS: undefined,
  ADD_PANEL_BAY_MAPPING: undefined,
  ADD_VISITED_BAYS: undefined,
  REMOVE_BAYS: undefined,
  REQUEST_ADD_PANELS: undefined,
  RECHECK_PANELS_EXPO: undefined,
  REMOVE_PANELS: undefined,
  START_DRAG_PANEL: undefined,
  MOVE_DRAGED_PANEL: undefined,
  END_DRAG_PANEL: undefined,
  BLOCK_CREATING_PANEL: undefined,
  UNBLOCK_CREATING_PANEL: undefined,
  RESET_PANELS_STATE: undefined,
  LOAD_PANELS_FROM_HISTORY: undefined,
  LOAD_PANELS: undefined,
  SET_ROOF_ZONES_AUTOFILL: undefined,
  RESTORE_PANELS_COLLISIONS_BOUNDS: undefined,
  CHANGE_SINGLE_PANEL_ROOF_ZONE: undefined,
  SET_CENTER_OFFSET: undefined,
  RECHECK_PANELS_OBSTRUCTION_ZONE: undefined,
  START_EXPOSURE_RECALCULATION: undefined,
  UPDATE_PANELS: undefined,
  UPDATE_PANEL_SPACINGS: undefined,
  CHANGE_DESIRED_TABLE_LENGTH: undefined,
  CHANGE_DESIRED_TABLE_WIDTH: undefined,
  RESET_TABLE_LENGTH_AND_WIDTH_SIZE: undefined,
  TOGGLE_PANEL_ATTACHED: undefined,
  SET_PANELS_ARRAY_FOR_MOVEMENT: undefined,
  SET_PANELS_ARRAY_GRID_FOR_MOVEMENT: undefined,
  RESET_PANELS_ARRAY_FOR_MOVEMENT: undefined,
  RESTORE_PANELS_FOR_NO_MOVEMENT: undefined,
  TOGGLE_BAY_ATTACHED: undefined,
});
