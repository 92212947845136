import { DISABLE_ACCOUNT_ERROR, DISABLE_ACCOUNT_SUCCESS } from 'actions';
import { DisableAccountActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';

export function disableAccount(action$) {
  return action$.ofType(DisableAccountActionTypes.DISABLE_ACCOUNT)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(DisableAccountActionTypes.DISABLE_ACCOUNT),
        onSuccess: DISABLE_ACCOUNT_SUCCESS,
        onError: DISABLE_ACCOUNT_ERROR,
        onErrorMessage: 'Cannot deactivate user',
        link: hitApi('put', 'api/auth/deactivate/'),
      }),
    );
}
