import { getLeadEdge } from '../../../panelsEditor/components/leadEdge/leadEdge';
import { getMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { getPolyRoofEdge } from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/utils/selectEdge';
import { getRoofColor, emptyRoofColor } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';

let leadEdgesOnMap: { [roofId: number]: google.maps.Polyline } | null = null;

export function createLeadEdgesOnMap(roofId: number) {
  const roofColor = getRoofColor(roofId);
  const leadEdgePath = getLeadEdge(roofId);
  const roofEdge = getPolyRoofEdge(leadEdgePath, roofColor);
  addGoogleLeadEdgeObject(roofId, roofEdge);
  roofEdge.setMap(getMap());
}

export function makeGoogleLeadEdgeEmpty(roofId: number) {
  leadEdgesOnMap[roofId].setOptions({ strokeColor: emptyRoofColor });
}

export function addGoogleLeadEdgeObject(roofId: number, leadEdge: google.maps.Polyline) {
  if (leadEdgesOnMap === null) {
    leadEdgesOnMap = {};
  }
  leadEdgesOnMap[roofId] = leadEdge;
}

export function clearGoogleLeadEdgeObject(roofId: number) {
  if (leadEdgesOnMap !== null && leadEdgesOnMap[roofId]) {
    leadEdgesOnMap[roofId].setMap(null);
    delete leadEdgesOnMap[roofId];
  }
}

export function clearAllGoogleLeadEdgeObject() {
  if (leadEdgesOnMap && Object.keys(leadEdgesOnMap).length) {
    Object.keys(leadEdgesOnMap).map(roofId => leadEdgesOnMap[roofId].setMap(null));
    leadEdgesOnMap = null;
  }
}

export function getAllLeadEdges() {
  return leadEdgesOnMap;
}
