export function getSMProjectConfigurationData(projectEnvConfig: any) {
  const {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    seismic_sds,
    tornado_speed,
    elevation,
    wind_exposure,
    building_height,
    rail_finish,
    roof_type,    
    mean_recurrence_interval,
    preferred_span,
    tilt,
    rail_direction,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    shortest_building_length,
    pro_clamps,
    risk_category,
    clamps_choices,
    tile_replacement_or_solarhooks,
    material_thickness ,
    attachment_type ,
    topographical_factor_kzt,
    roof_substrate,
    mid_clamps_choices,
    end_clamps_choices,
    rail_type,
    building_length,
    helioscope_id,
    stagger_attachments,
    is_aurora_project,
    external_mfg,
    external_model
  } = projectEnvConfig;

  return {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    seismic_sds,
    tornado_speed,
    elevation,
    wind_exposure,
    building_height,
    rail_finish,
    roof_type,    
    mean_recurrence_interval,
    preferred_span,
    tilt,
    rail_direction,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    shortest_building_length,
    pro_clamps: pro_clamps ? 1: 0,
    risk_category,
    clamps_choices: clamps_choices? clamps_choices:(pro_clamps? 1: 2),
    tile_replacement_or_solarhooks,
    material_thickness ,
    attachment_type ,
    topographical_factor_kzt,
    roof_substrate,
    mid_clamps_choices,
    end_clamps_choices,
    rail_type,
    building_length,
    helioscope_id,
    stagger_attachments,
    is_aurora_project,
    external_mfg,
    external_model
  };
}
