import { EDGES_TYPE } from "__editor/panelsEditor/components/roofZones/utils/edgesType";

export interface ModuleBitsReturnData {
  isLandscape: boolean;
  roofZone: roofZoneNumber;
  nearObstruction: boolean;
  exposedNeighbour?: boolean;
  exposed?: boolean;
  edgeType?: EDGES_TYPE;
  obstructionType?: 0 | 1 | 2;
}

export abstract class ModuleBits {
  abstract fromBits(bitData: number, roofZoneId?: number): ModuleBitsReturnData;
  abstract toBits(panel: panelInState): number;
  protected booleanToBit(value: boolean): 0 | 1 {
    return value ? 1 : 0;
  }

  protected bitToBoolean(value: number): boolean {
    return value === 1;
  }
}
