import React from 'react';

export default class Icon extends React.Component {
  render() {
    return (
      <i className="md-icon material-icons">
        {this.props.children}
      </i>
    );
  }
}

