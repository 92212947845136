import { AdvanceRoofSelectingActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';
import uniq from 'lodash/uniq';

export const advanceRoofSelectingState: advanceRoofSelectingState = {
  roofPoints: [],
  tempPoint: null,
  lastPoint: null,
  enabled: false,
  rehydrated: false,
  banners: true,
};

export default {
  advanceRoofSelecting: createReducer(advanceRoofSelectingState, {
    [AdvanceRoofSelectingActionTypes.ADD_ROOF_POINT](state: advanceRoofSelectingState, action) {
      const { x, y } = action.payload;
      const roofPoints = uniq(state.roofPoints);
      roofPoints.push({ x, y });
      return { ...state, roofPoints };
    },
    [AdvanceRoofSelectingActionTypes.CLEAR_ROOF_POINTS](state: advanceRoofSelectingState) {
      return { ...state, roofPoints: [], lastPoint: null, tempPoint: null };
    },
    [AdvanceRoofSelectingActionTypes.TERMINATE_ADVANCE_ROOF_SELECTING](state: advanceRoofSelectingState) {
      state.roofPoints = [];
      state.lastPoint = null;
      state.tempPoint = null;
      state.enabled = false;
      return { ...state };
    },
    [AdvanceRoofSelectingActionTypes.TOGGLE_ADVANCE_ROOF_SELECTING](state: advanceRoofSelectingState) {
      return { ...state, enabled: !state.enabled };
    },
    [AdvanceRoofSelectingActionTypes.TURN_ON_ROOF_SELECTING](state: advanceRoofSelectingState) {
      return { ...state, enabled: true };
    },
    [AdvanceRoofSelectingActionTypes.TURN_ON_BANNERS](state: advanceRoofSelectingState) {
      return { ...state, banners: true };
    },
    [AdvanceRoofSelectingActionTypes.TURN_OFF_BANNERS](state: advanceRoofSelectingState) {
      return { ...state, banners: false };
    },
    [AdvanceRoofSelectingActionTypes.TURN_OFF_ROOF_SELECTING](state: advanceRoofSelectingState) {
      return { ...state, enabled: false };
    },
    [AdvanceRoofSelectingActionTypes.ADD_ROOF_LAST_POINT](state: advanceRoofSelectingState, action: action & { payload: pixelPoint }) {
      const lastPoint = action.payload;
      return { ...state, lastPoint };
    },
    [AdvanceRoofSelectingActionTypes.ADD_TEMP_POINT](state: advanceRoofSelectingState, action: action & { payload: pixelPoint }) {
      const tempPoint = action.payload;
      return { ...state, tempPoint };
    },
    [AdvanceRoofSelectingActionTypes.CLEAR_TEMP_POINT](state: advanceRoofSelectingState) {
      return { ...state, tempPoint: null };
    },
  }),
};
