import Polygon from 'polygon';
import { RoofZonesAreas } from './roofZones';
import { edge, getEdgePoints, edgePoints } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { getZoneDirection } from './roofZonesHelper';

export const getMinXofPolygon = (polygon: PolygonInterface) => Math.min(...polygon.points.map(points => points.x));

export const getMinYofPolygon = (polygon: PolygonInterface) => Math.min(...polygon.points.map(points => points.y));

export const roofZonesExist = (rotatedRoofZone: RoofZonesAreas) => rotatedRoofZone && Object.keys(rotatedRoofZone).length;

export const createEdgeZonePolygon = (edge: edge|pixelPoint[]) => {
  const onlyCoords: edgePoints = getEdgePoints(edge);
  const polygon = new Polygon(onlyCoords);
  const zone = getZoneDirection(onlyCoords);
  polygon.zone = zone;
  polygon.index = edge[0].index;
  polygon.edge = edge[4];
  return polygon;
};

interface Circle {
  x: number;
  y: number;
  r: number;
}

interface Rectangle {
  x: number;
  y: number;
  w: number;
  h: number;
}

export function RectCircleColliding(circle: Circle, rect: Rectangle) {
  const distX = Math.abs(circle.x - rect.x - rect.w / 2);
  const distY = Math.abs(circle.y - rect.y - rect.h / 2);

  if (distX > (rect.w / 2 + circle.r)) { return false; }
  if (distY > (rect.h / 2 + circle.r)) { return false; }

  if (distX <= (rect.w / 2)) { return true; }
  if (distY <= (rect.h / 2)) { return true; }

  const dx = distX - rect.w / 2;
  const dy = distY - rect.h / 2;
  return (dx * dx + dy * dy <= (circle.r * circle.r));
}
