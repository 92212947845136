
export const apiField = 'manufacturer';

function makeConfig(data) {
  return{
    label: 'manufacturer',
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data
}};

const manufacturerMap = {
      "AAA":{value : "AAA",  name: "GENERIC/NON-BRAND SPECFIC" },
      "CAR":{value : "CAR",  name: "CARLISLE"},
      "CTD":{value : "CTD",  name: "CERTAINTEED"},
      "DER":{value : "DER",  name: "DERBIGUM"},
      "DUR":{value : "DUR",  name: "DUROLAST"},
      "FBT":{value : "FBT",  name: "FIBERTITE"},
      "FIR":{value : "FIR",  name: "FIRESTONE"},
      "FLX":{value : "FLX",  name: "FLEX"},
      "GAF":{value : "GAF",  name: "GAF"},
      "GAR":{value : "GAR",  name: "GARLAND"},
      "GFL":{value : "GFL",  name: "GENFLEX"},
      "IBR":{value : "IBR",  name: "IB ROOF SYSTEMS"},
      "JMA":{value : "JMA",  name: "JOHNS MANVILLE"},
      "MAL":{value : "MAL",  name: "MALARKEY"},
      "MLH":{value : "MLH",  name: "MULE HIDE"},
      "POL":{value : "POL",  name: "POLYGLASS"},
      "RMA":{value : "RMA",  name: "RMA"},
      "SIK":{value : "SIK",  name: "SIKA SARNAFIL"},
      "SPL":{value : "SPL",  name: "SIPLAST"},
      "SOP":{value : "SOP",  name: "SOPREMA"},
      "TRE":{value : "TRE",  name: "TREMCO"},
      "VER":{value : "VER",  name: "VERSICO"},
      "WEA":{value : "WEA",  name: "WEATHER BOND"},

  }


export default function manufacturer(keys_array){ 
  const filteredList = keys_array.map(key => manufacturerMap[key]);
      return makeConfig(filteredList);
    }