import { ModuleBits, ModuleBitsReturnData } from './baseTypes';
import { EDGES_TYPE } from "__editor/panelsEditor/components/roofZones/utils/edgesType";
import { isGableBuildingType, isHipBuildingType, isFlatBuildingType } from '__common/constants/buildingTypes';

// (little-endian) - to make the bit data read the table right to left 

// ** building code 7-16, residential **
// |----------------|---------|-----------|---------|---------|---------|-----------|---------|
// | orientation    | on edge | on corner | is eave | is      | deep    | exposed   | exposed |
// | (is landscape) |         |           |         | ridge   | interior| neighbour |         |
// |----------------|---------|-----------|---------|---------|---------|-----------|---------|
// |       1        |    0    |     0     |    0    |    0    |    1    |     0     |    1    | <--- example: landscape exposed panel in roof zone 1'
// |                |         |           |         |         |         |           |         |      on flat roof without exposed neighbour.
// |----------------|---------|-----------|---------|---------|---------|-----------|---------|      Gives 0b10100001 = 161

interface Flags {
  isRidge: 0 | 1;
  isEave: 0 | 1;
  isDeepInterior: 0 | 1;
  isOnCorner: 0 | 1;
  isOnEdge: 0 | 1;
}

interface DecodedConfig {
  roofZone: roofZoneNumber;
  edgeType: EDGES_TYPE;
}

// DISCLAIMER - this class is not perfect. It may be hard to understand it
// because the approach taken at the beginning phase was to assign roof zones
// base on the group they belong to, not the real roof zone number.
// e.g. For gable roofs. 7° - 27° - both 3e and 3r are corner zones, and they should have zone number 3.
// but the current approach assigns 3r to zone 3 (red) whereas 3e is the zone 2 as well as zones 2n and 2r (yellow).
// However this mapping enables us to keep backend-frontend communication aligned with the ASCE7-16 roof zones.
// so it doesn't matter that 3r is red and 3e is yellow - they are both marked as "in corner", and there are additional bits
// like "is ridge" and "is eave" to further distinguish them. 
// And having established the backend-frontend communication contract we can update frontend part without breaking it.
export class Asce716ResidentialModuleBitsV2 extends ModuleBits {
  private static fieldsLength = 8;

  constructor(private buildingType: number, private roofPitchDegrees: number = 0) {
    super();
  }

  fromBits(bitData: number, roofZoneId?: roofZoneNumber): ModuleBitsReturnData {
    const bitFlags: (0|1)[] = [];
    let shiftedBitData: number = bitData;
    for (let i = 0; i < Asce716ResidentialModuleBitsV2.fieldsLength; i++) {
      bitFlags.push(this.booleanToBit(this.bitToBoolean(shiftedBitData & 1)));
      shiftedBitData = shiftedBitData >> 1;
    }

    const [
      isLandscape,
      isOnEdge, 
      isOnCorner, 
      isEave, 
      isRidge, 
      isDeepInterior, 
      isNeighborExposed, 
      isPanelExposed, 
    ] = bitFlags;

    const flags: Flags = {
      isDeepInterior,
      isEave,
      isOnCorner,
      isOnEdge, 
      isRidge,
    };

    let config: DecodedConfig;

    if (isGableBuildingType(this.buildingType)) {
      config = this.decodeGableRoof(flags);
    } else if (isHipBuildingType(this.buildingType)) {
      config = this.decodeHipRoof(flags);
    } else if (isFlatBuildingType(this.buildingType)) {
      config = this.decodeFlatRoof(flags);
    }

    let roofZoneVal = config.roofZone
    if (roofZoneId) roofZoneVal = roofZoneId;

    return {
      exposed: this.bitToBoolean(isPanelExposed),
      exposedNeighbour: this.bitToBoolean(isNeighborExposed),
      nearObstruction: false, // it applies only to commercial
      isLandscape: this.bitToBoolean(isLandscape),
      roofZone: roofZoneVal,
      edgeType: config.edgeType,
    };
  }

  toBits(panel: panelInState) {
    let flags: Flags;

    if (isGableBuildingType(this.buildingType)) {
      flags = this.encodeGableRoof(panel);
    } else if (isHipBuildingType(this.buildingType)) {
      flags = this.encodeHipRoof(panel);
    } else if (isFlatBuildingType(this.buildingType)) {
      flags = this.encodeFlatRoof(panel);
    }

    const isLandscape = panel.landscape ? 1 : 0; // 1 - landscape
    const isPanelExposed = panel.exposed || panel.exposedNeighbour ? 1 : 0;
    const isNeighborExposed = panel.exposedNeighbour ? 1 : 0;
    
    return [
      isPanelExposed, 
      isNeighborExposed, 
      flags.isDeepInterior, 
      flags.isRidge, 
      flags.isEave, 
      flags.isOnCorner, 
      flags.isOnEdge, 
      isLandscape,
    ].reduce<number>(
      ((bit, flag) => {
        return bit << 1 | flag;
      }), 
      0,
    );
  }

  private encodeGableRoof(panel: panelInState): Flags {
    // for roof pitch 7° - 27°:
    //  - 3r is marked as 3, but 3e is in the same category as 2n, therefore we need to also check if it is on the correct edge.
    // --------------------------
    // for roof pitch 27° - 45°:
    //  - 3r is marked in the same category as 2n therefore we need to check if it's on the correct edge.
    const isOnCorner = (
      (panel.roofZone === 3) || 
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.EAVE) || 
       // without roof pitch it's ambiguous whether it is a 3r corner for 27° - 45° or 2r for 7° - 27°
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.RIDGE && this.roofPitchDegrees > 27)
    );
    // for roof pitch 7° - 27°:
    //  - 2n and 2r are in the same category and 2e is in the same category as 1
    // --------------------------
    // for roof pitch 27° - 45°:
    //  - 2n is in the same category as 3r, and 2e and 2r are in the same category as 1
    const isOnEdge = (
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.RAKEGABLE) ||
      (panel.roofZone === 2 && panel.edgeType === EDGES_TYPE.RIDGE && this.roofPitchDegrees <= 27) ||
      (panel.roofZone === 1 && panel.edgeType !== undefined)
    );

    return {
      isDeepInterior: 0, // it applies only for flat roofs
      isRidge: this.booleanToBit(panel.edgeType === EDGES_TYPE.RIDGE),
      isEave: this.booleanToBit(panel.edgeType === EDGES_TYPE.EAVE),
      isOnCorner: this.booleanToBit(isOnCorner),
      isOnEdge: this.booleanToBit(isOnEdge),
    };
  }

  private decodeGableRoof(flags: Flags): DecodedConfig {
    if (this.roofPitchDegrees < 27) {
      if (flags.isOnCorner) {
        if (flags.isEave) {
          // 3e
          return {
            roofZone: 2,
            edgeType: EDGES_TYPE.EAVE,
          };
        } 
        
        if (flags.isRidge) {
          // 3r
          return {
            roofZone: 3,
            edgeType: EDGES_TYPE.RIDGE,
          };
        }
      } 
      
      if (flags.isOnEdge) {
        if (flags.isEave) {
          // 2e
          return {
            roofZone: 1,
            edgeType: EDGES_TYPE.EAVE,
          };
        } 
        
        if (flags.isOnEdge && flags.isRidge) {
          // 2r
          return {
            roofZone: 2,
            edgeType: EDGES_TYPE.RIDGE,
          };
        }

        // 2n
        return {
          roofZone: 2,
          edgeType: EDGES_TYPE.RAKEGABLE,
        };
      }
    }

    if (flags.isOnCorner) {
      if (flags.isEave) {
        // 3e
        return {
          roofZone: 3,
          edgeType: EDGES_TYPE.EAVE,
        };
      } 
      
      if (flags.isRidge) {
        // 3r
        return {
          roofZone: 2,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }
    } 
    
    if (flags.isOnEdge) {
      if (flags.isEave) {
        // 2e
        return {
          roofZone: 1,
          edgeType: EDGES_TYPE.EAVE,
        };
      } 
      
      if (flags.isOnEdge && flags.isRidge) {
        // 2r
        return {
          roofZone: 1,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }

      // 2n
      return {
        roofZone: 2,
        edgeType: EDGES_TYPE.RAKEGABLE,
      };
    }

    return {
      roofZone: 1,
      edgeType: undefined,
    };
  }

  private decodeFlatRoof(flags: Flags): DecodedConfig {
    if (flags.isOnCorner) {
      return {
        roofZone: 3,
        edgeType: undefined,
      };
    } 
    
    if (flags.isOnEdge) {
      return {
        roofZone: 2,
        edgeType: undefined,
      };
    } 
    
    if (flags.isDeepInterior) {
      return {
        roofZone: 4,
        edgeType: undefined,
      };
    }

    return {
      roofZone: 1,
      edgeType: undefined,
    };
  }

  private decodeHipRoof(flags: Flags): DecodedConfig {
    if (this.roofPitchDegrees >= 7 && this.roofPitchDegrees < 20) {
      if (flags.isOnCorner) {
        return {
          roofZone: 3,
          edgeType: undefined,
        };
      }

      if (flags.isOnEdge) {
        if (flags.isEave) {
          return {
            roofZone: 3,
            edgeType: EDGES_TYPE.EAVE,
          };
        }

        return {
          roofZone: 2,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }

      return {
        roofZone: 1,
        edgeType: undefined,
      };
    }

    if (this.roofPitchDegrees >= 20 && this.roofPitchDegrees < 27) {
      if (flags.isOnCorner) {
        return {
          roofZone: 2,
          edgeType: undefined,
        };
      }

      if (flags.isOnEdge) {
        if (flags.isEave) {
          return {
            roofZone: 2,
            edgeType: EDGES_TYPE.EAVE,
          };
        }

        return {
          roofZone: 2,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }

      return {
        roofZone: 1,
        edgeType: undefined,
      };
    }

    if (this.roofPitchDegrees >= 27 && this.roofPitchDegrees <= 45) {
      if (flags.isOnCorner) {
        return {
          roofZone: 3,
          edgeType: undefined,
        };
      }

      if (flags.isOnEdge) {
        if (flags.isEave) {
          return {
            roofZone: 2,
            edgeType: EDGES_TYPE.EAVE,
          };
        }

        return {
          roofZone: 5,
          edgeType: EDGES_TYPE.RIDGE,
        };
      }

      return {
        roofZone: 1,
        edgeType: undefined,
      };
    }
  }

  private encodeFlatRoof(panel: panelInState): Flags {
    return {
      isDeepInterior: this.booleanToBit(panel.roofZone === 4),
      isRidge: 0,
      isEave: 0,
      isOnCorner: this.booleanToBit(panel.roofZone === 3),
      isOnEdge: this.booleanToBit(panel.roofZone === 2), 
    };
  }

  private encodeHipRoof(panel: panelInState): Flags {
    const isOnCorner = (
      (panel.roofZone === 3 && panel.edgeType === undefined) || // zone 3 for pitch roof 7° - 20°
      (panel.roofZone === 2 && panel.edgeType === undefined) // // zone 3 for pitch roof 20° - 27°
    );

    const isOnEdge = (panel.edgeType !== undefined);

    return {
      isDeepInterior: 0, // it applies only for flat roofs
      isOnCorner: this.booleanToBit(isOnCorner),
      isEave: this.booleanToBit(panel.edgeType === EDGES_TYPE.EAVE),
      isRidge: this.booleanToBit(panel.edgeType === EDGES_TYPE.RIDGE),
      isOnEdge: this.booleanToBit(isOnEdge),
    };
  }
}
