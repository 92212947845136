export default () => `
  <div>
    <div class="content">
        Select between module mid clamps. Larger gaps between modules may reduce wind loads.
    </div>
    <div class="sub-header"><strong>Pro Clamps:</strong> 1/4 in gap</div>
    <div class="sub-header"><strong>Standard Clamps:</strong> 3/2 in gap</div>
    <div class="sub-header"><strong>Universal AF Clamps:</strong> 3/4 in gap</div>
  </div>
`;
