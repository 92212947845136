import config from 'local_config';
import store, { dispatch } from '__common/store';
import { GET_ENVIRONMENTAL_FACTORS, SET_ZIP_AND_ADDRESS_CODE, TOGGLE_INPUT_UNIT, TURN_ON_BANNERS } from 'actions';
import { Observable } from 'rxjs/Observable';
import { RoofsSelectorMapActionTypes, AdvanceRoofSelectingActionTypes } from 'actionsConstants';
import { of } from 'rxjs/observable/of';
import { WAIT_FOR_SOCKET, EMPTY_APP_ACTION } from 'app/appActions';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { ICITY_STATE } from './utils/cityModel';
import { isBlankMap } from '__common/constants/map';
import { activateRoofAreaTextPopup, deactivateRoofAreaTextPopup } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorPopup';
import { distanceCheckFromFirstRoofCenter } from '__editor/googleMapsRoofsSelector/components/drawingManager/utils/roofSizeAlert';
import { asce_710, asce_716, isASCE716or722 } from '__common/constants/buildingCodes';
import { isCanadianChangesProduct, isOnlyAsce716ProductFamily } from '__common/constants/products';
import { isCanadianZipcode } from 'projectDesign/components/projectConfiguration/utils/validation';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { changeBuildingCodeByState, updateProjectOption } from 'projectDesign/components/projectConfiguration/utils/updateProjectOption';
import { apiField as meanRecurrenceApiField } from 'projectDesign/components/projectConfiguration/fields/meanRecurrenceInterval';

let lastZipCode = null;


export function fetchCurrentZipCode(action$) {
  return action$.ofType(RoofsSelectorMapActionTypes.FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG)
    .switchMap((action) => {
      return Observable.ajax({
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${action.payload.lat},${action.payload.lng}&sensor=false&key=${config.googleZipCodeKey}`,
        crossDomain: true,
      })
        .map(data => data.response)
        .map((georesponse) => {
          const { lat, lng, productId, risk_category } = action.payload;
          let { building_code } = action.payload;

          const { short_address, zipCode } = getZipCodeAndAddressFromGoogleGeoApi(georesponse);
          const short_state = short_address.split(',')[1].trim()
          if(short_state){
            building_code = changeBuildingCodeByState(short_state)
          }
          if (zipCode !== lastZipCode) {
            dispatch(
              GET_ENVIRONMENTAL_FACTORS(
                lat, 
                lng, 
                zipCode, 
                building_code || (isOnlyAsce716ProductFamily(productId) ?  asce_716.value : asce_710.value),
                productId, 
                risk_category
              )
            );
            updateFlyoutForCanadianZipCodes(zipCode)
            lastZipCode = zipCode;
          }

          return SET_ZIP_AND_ADDRESS_CODE(zipCode, short_address);
        })
        .catch(() => of(WAIT_FOR_SOCKET()));
    });
}

const updateFlyoutForCanadianZipCodes = (zipcode) => {
  const {projectConfiguration: { inputUnit, productId, projectEnvConfig } } = store.getState();
  if (isCanadianChangesProduct(productId)){
    if(!isMetricUnit(inputUnit) && isCanadianZipcode(zipcode)) {
        updateProjectOption(meanRecurrenceApiField, '50', zipcode, productId, projectEnvConfig);
        dispatch(TOGGLE_INPUT_UNIT());
    } else if (isMetricUnit(inputUnit) && !isCanadianZipcode(zipcode)){
      const mean_recurrence_interval_value = isASCE716or722(projectEnvConfig.building_code) ? '50' : '25';
      updateProjectOption(meanRecurrenceApiField, mean_recurrence_interval_value, zipcode, productId, projectEnvConfig);
      dispatch(TOGGLE_INPUT_UNIT());
    }
  }
}

export function getAddressByZip(action$) {
  return action$.ofType(RoofsSelectorMapActionTypes.GET_ADDRESS_BY_ZIP)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(RoofsSelectorMapActionTypes.GET_ADDRESS_BY_ZIP),
        onSuccess: (data: ICITY_STATE) => {
          action.payload.cb(data);
          updateFlyoutForCanadianZipCodes(action.payload.zipcode);
          lastZipCode = action.payload.zipcode
          return EMPTY_APP_ACTION;
        },
        link: hitApi('get', `api/v1/geocode_from_zipcode/${action.payload.zipcode}/`),
      }),
    );
}

export function getZipCodeAndAddressFromGoogleGeoApi(georesponse) {
  let zipCode;
  let short_address;
  let short_address_state;
  let cords: any = {
    lat: 0,
    lng: 0,
  };

  try {
    georesponse.results[0].address_components.map((address_detail, index) => {
      if (address_detail.types.includes('postal_code')) {
        zipCode = address_detail.long_name;
      }

      if (address_detail.types.includes('locality')) {
        short_address = address_detail.short_name;
      }

      if (address_detail.types.includes('administrative_area_level_1')) {
        short_address_state = address_detail.short_name;

        if (!short_address) {
          short_address = address_detail.long_name;
        }
      }
    });

    if (georesponse.results && georesponse.results[0] && georesponse.results[0].geometry && georesponse.results[0].geometry.location) {
      cords = georesponse.results[0].geometry.location;
    }
  } catch {
    zipCode = undefined;
    short_address = '';
    short_address_state = '';
  }

  if (!zipCode) {
    try {
      zipCode = georesponse.results.filter((result) => result.types.includes('postal_code'))[0].address_components[0].long_name;

      if (georesponse.results && georesponse.results[0] && georesponse.results[0].geometry && georesponse.results[0].geometry.location) {
        cords = georesponse.results[0].geometry.location;
      }

    } catch {
      console.log(georesponse);
    }
  }

  return {
    zipCode,
    short_address: `${short_address}, ${short_address_state}`,
    cords: {
      lat: cords.lat.constructor === Function ? cords.lat() : cords.lat,
      lng: cords.lng.constructor === Function ? cords.lng() : cords.lng,
    },
  };
}

export function clearLastZipCode() {
  lastZipCode = null;
}

export function activateRoofAreaTextPopUp(action$, store){
  return action$.ofType(AdvanceRoofSelectingActionTypes.TERMINATE_ADVANCE_ROOF_SELECTING)
  .filter(() => {
    const { roofsSelector: { mapType } } = store.getState();
    return !isBlankMap(mapType);
  }).switchMap(()=> {
    return of(TURN_ON_BANNERS());
  });
}

export function activateRoofAreaBanners(action$, store){
  return action$.ofType(AdvanceRoofSelectingActionTypes.TURN_ON_BANNERS)
  .filter(() => {
    const { roofsSelector: { mapType } } = store.getState();
    return !isBlankMap(mapType);
  }).switchMap(()=> {
    const { roofsSelector: { mapType } } = store.getState();
    activateRoofAreaTextPopup(mapType);
    return Observable.empty();
  });
}

export function deactivateRoofAreaBanners(action$, store){
  return action$.ofType(AdvanceRoofSelectingActionTypes.TURN_OFF_BANNERS)
  .filter(() => {
    const { roofsSelector: { mapType } } = store.getState();
    return !isBlankMap(mapType);
  }).switchMap(()=> {
    const { roofsSelector: { mapType } } = store.getState();
    deactivateRoofAreaTextPopup(mapType);
    return Observable.empty();
  });
}
export function updateOnSetMapCenter(action$, store) {
  return action$.ofType(RoofsSelectorMapActionTypes.SET_MAP_CENTER)
    .switchMap(() => {
      distanceCheckFromFirstRoofCenter(true);
      return Observable.empty();
    });
}
