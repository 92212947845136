import { addPanelsWithoutSnapingToGrid, addPanelsWithSnapingToGrid } from '__editor/panelsEditor/components/panels/panels';
import {
  BLOCK_CREATING_PANEL,
  HIDE_CURSOR,
  LOCK_CURSOR,
  SET_END_POS,
  SET_START_POS,
  SHOW_CURSOR,
  UNBLOCK_CREATING_PANEL,
  UNLOCK_CURSOR,
  UNSET_CURSOR_OVER_PANEL,
  } from 'actions';
import { cursorPosition, isCursorMoving, mousePosition } from '__editor/panelsEditor/components/cursor/cursor';
import { deployRubberBand, resizeRubberBand, startRubberBand } from '__editor/panelsEditor/components/rubberBand/rubberBand';
import { dispatch, state } from '__common/store';
import {
  endMovingMap,
  isMovingMapInitied,
  moveMap,
  startMovingMap,
  } from '__editor/panelsEditor/components/background/moveBackground';
import { getStage } from '__editor/panelsEditor/components/stage/stage';
import { scaleToolInitialized, trunOffScaleTool } from '__editor/panelsEditor/components/scale/scale';
import { panelsInteractionBlock } from '../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { getRenderer } from '../renderer/renderer';
import { advanceRoofSelectingClick, advanceRoofSelectingMove } from '__common/components/advancedSelecting/advancedSelectingEvents';
import { products } from '__common/constants/products';
import { measurementToolClick, measurementToolMove } from '__common/components/measurementTool/measurementToolEvents';

export function onStageClick(e:MouseEvent) {
  const { background: { moveMapMode, moveArrayMode, toggleAttachments}, advanceRoofSelecting, measurementTool, projectConfiguration:{projectEnvConfig:{is_aurora_project}} } = state();
  if (toggleAttachments){
    return
  }
  if (advanceRoofSelecting.enabled) {
    const renderer = getRenderer();
    const stage = getStage();
    advanceRoofSelectingClick(renderer, stage);
  }

  if(measurementTool.enabled) {
    const renderer = getRenderer();
    const stage = getStage();
    measurementToolClick(renderer, stage);
  }

  if (panelsInteractionBlock) {
    return;
  }

  if(moveArrayMode) {
    dispatch(HIDE_CURSOR());
    return;
  }

  if(!is_aurora_project) dispatch(BLOCK_CREATING_PANEL());

  if (moveMapMode || e.button === ButtonEvent.ScrollClick) {
    dispatch(HIDE_CURSOR());
    return startMovingMap();
  }

  if(!is_aurora_project) dispatch(LOCK_CURSOR());
}

export function onStageMouseMove(e:MouseEvent) {
  const { x, y } = mousePosition();
  const { inited, start, mode } = state().rubberBand;
  const { creatingBlocked } = state().panels;
  const { scale: { enabled, startPos }, advanceRoofSelecting, measurementTool, background: { toggleAttachments } } = state();
  if(toggleAttachments){
    return
  }
  
  if (advanceRoofSelecting.enabled) {
    const renderer = getRenderer();
    const stage = getStage();
    advanceRoofSelectingMove(renderer, stage);
  }

  if (measurementTool.enabled) {
    const renderer = getRenderer();
    const stage = getStage();
    measurementToolMove(renderer, stage);
  }

  if (isMovingMapInitied()) {
    return moveMap();
  }

  if (enabled && startPos.x > 0 && startPos.y > 0) {
    return dispatch(SET_END_POS({ x, y }));
  }

  if (creatingBlocked) {
    if (inited && start && isCursorMoving(start.x, start.y, x, y)) {
      resizeRubberBand(start.x, start.y, x, y);
    }

    if (!inited && !enabled && mode !== 'disabled') {
      startRubberBand(cursorPosition().x, cursorPosition().y);
    }
  }
}

export function onStageMouseUp(e:MouseEvent) {
  const { inited, mode } = state().rubberBand;
  const { creatingBlocked,panelsToBeMoved} = state().panels;
  const { overLapPanel, overPanel } = state().editorCursor;
  const { 
    scale: { enabled }, 
    background: { moveMapMode, moveArrayMode, toggleAttachments, }, 
    advanceRoofSelecting, 
    measurementTool 
  } = state();
  if (panelsInteractionBlock || advanceRoofSelecting.enabled || measurementTool.enabled) {
    return;
  }

  if(toggleAttachments){
    return
  }
  
  if (isMovingMapInitied()) {
    if (e.buttons !== ButtonsEvent.ScrollHold) {
      endMovingMap();
      // left button is still holded
      if (e.buttons === ButtonsEvent.LeftHold) {
        return;
      }
      // no button is holded
      dispatch(UNBLOCK_CREATING_PANEL());
      dispatch(SHOW_CURSOR());
      return;
    }
    // scroll is still holded
    dispatch(UNBLOCK_CREATING_PANEL());
    dispatch(SHOW_CURSOR());
  }

  if (enabled) {
    if (!scaleToolInitialized()) {
      return dispatch(SET_START_POS({ x: cursorPosition().x, y: cursorPosition().y }));
    }

    if (scaleToolInitialized()) {
      dispatch(UNBLOCK_CREATING_PANEL());
      return trunOffScaleTool();
    }

    return;
  }


  if (inited && creatingBlocked) {
    deployRubberBand();
  } else if (!toggleAttachments && !overLapPanel && !overPanel && mode !== 'remove'  && !(moveArrayMode && panelsToBeMoved.length == 0)) {
    if (shouldSnapToGrid()) {
      addPanelsWithSnapingToGrid();
    } else if (!moveMapMode) {
      addPanelsWithoutSnapingToGrid();
    }
  }


  dispatch(UNBLOCK_CREATING_PANEL());
  if (mode === 'draw') {
    dispatch(UNLOCK_CURSOR());
    dispatch(UNSET_CURSOR_OVER_PANEL());
  }
}

export function onMouseOutside() {
  if (isMovingMapInitied()) {
    dispatch(UNBLOCK_CREATING_PANEL());
    dispatch(SHOW_CURSOR());
    return endMovingMap();
  }
}

const shouldSnapToGrid = (): boolean => {
  const { 
    panels: { panels },
    projectConfiguration: { productId },
    background: { moveMapMode, moveArrayMode }, 
  } = state();
  return panels.length && !moveMapMode && shouldSnapProduct(productId) && !moveArrayMode;
};

const shouldSnapProduct = (productId: number): boolean => {
  return ![products.gft,products.ula, 12].includes(productId); 
};


export enum ButtonEvent {
  LeftClick = 0,
  ScrollClick,
  RightClick,
}

export enum ButtonsEvent {
  NoButton = 0,
  LeftHold,
  RightHold,
  ScrollHold = 4,
}
