import * as React from 'react';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderImage from 'home/components/HeaderImage';
import jquery from 'jquery';
import Preview from 'home/components/Preview';
import ubuilderBlueLogo from '../../../assets/media/logos/U-Builder_Logo_Blue_Color.png'
import ubuilderWhiteLogo from '../../../assets/media/logos/White_U-Builder_new_logo.png'
export default class Home extends React.Component {
  componentDidMount() {
    const divs = jquery('.header');
    jquery(window).scroll(() => {
      const percent = this.getScrollPercent() / 100;
      // const percent = jquery(document).scrollTop() / ((jquery('.header-image').offset().top + jquery('.header-image').height()));
      divs.css('background', `rgba(10, 60, 93, ${percent})`);
      jquery('.ubuilder-header-logo').css(
        'background',
        `rgba(255, 255, 255, ${percent}`,
      );
      jquery('.ubuilder-logo-at-header').attr('src', `${percent>0.07 ? ubuilderBlueLogo : ubuilderWhiteLogo}`);
    });
  }

  UNSAFE_componentWillMount() {
    jquery(window).scroll(() => {});
  }

  getScrollPercent() {
    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }

  goToContent = () => {
    jquery([document.documentElement, document.body]).animate(
      {
        scrollTop:
          jquery('.header-image').offset().top +
          jquery('.header-image').height(),
      },
      1000,
    );
  }

  content() {
    return (
      <>
        <img
          alt="logo"
          src={require('assets/media/logos/Unirac_logo_full_color_ white_slogan.png')}
        />
        <div className="text">
          Solar Design Software. <br />
          Point. Click. Scale.
        </div>

        <div className="learn-more-button" onClick={this.goToContent}>
          Watch Introduction Video
          <i className="material-icons">arrow_downward</i>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="app-content home-page">
        <Header isAtHomePage={true}/>
        <HeaderImage content={this.content()} />
        <div className="home-page-content">
          <Preview />
        </div>
        <Footer />
      </div>
    );
  }
}
