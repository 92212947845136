import * as React from 'react';
import ConfirmationTextField from '__common/components/ConfirmationTextField';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import { inchesToMeters, metersToInches } from 'maths';
import { SET_ROOF_TABLE_ROW_SPACING, SET_TABLE_ROW_SPACING } from 'actions';
import { deleteAllPanels } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import Tooltip from '__common/components/Tooltip';
import { getTiltedModuleTableRowSpacingsGFT } from '__editor/panelsEditor/components/tiltedModule/tiltedModule';
import {  metersToCms } from '__common/calculations/unitConversions';

type Props = {
  dispatch: Function,
  panels: panelInState[],
  tableRowSpacing: number,
  drawingManager: drawingManagerState,
  selectedRoofId: number,
  tilt: number,
  productId: number,
};

interface state {
  tableRowSpacing: number,
  isValidTableRowSpacing: boolean
}

class TableRowSpacingGFT extends React.Component<Props, state> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tableRowSpacing: null,
      isValidTableRowSpacing: true
    };
  }

  _hasPanels = () => {
    const { panels } = this.props;

    return panels && Array.isArray(panels) && panels.length > 0;
  }

  _getCurrentTableRowSpacing = () => {
    const { tableRowSpacing } = this.props;
    return Number(metersToInches(tableRowSpacing).toFixed(2));
  }

  _get_suggested_tableRowSpacing = () => {
    const { tilt } = this.props;
    const suggested_tableRowSpacing = getTiltedModuleTableRowSpacingsGFT(tilt);
    return Number(metersToCms(suggested_tableRowSpacing).toFixed(2));
  }

  renderWarningMessage() {
    if (this.state.isValidTableRowSpacing) {
      return null;
    }

    const errorMessage = `Table Row Spacing must be grater than 0`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  shouldConfirm = (value: number) => {
    if (value > 0) {
      return this._hasPanels();
    }
    else {
      return false;
    }
  }
    
  @autobind
  onTableRowSpacingChange(value: number) {
    const { dispatch, selectedRoofId } = this.props;
    if (value > 0) {
      this.setState({ ...this.state, tableRowSpacing: value, isValidTableRowSpacing: true });
      deleteAllPanels();
      dispatch(SET_TABLE_ROW_SPACING(inchesToMeters(value)));
      dispatch(SET_ROOF_TABLE_ROW_SPACING(selectedRoofId, inchesToMeters(value)));
    }
    else{
      this.setState({ ...this.state, tableRowSpacing: value, isValidTableRowSpacing: false });
    }
  }


  render() {
    const { tableRowSpacing } = this.state;
    const currentTableRowSpacing = this._getCurrentTableRowSpacing();
    const suggested_tableRowSpacing = this._get_suggested_tableRowSpacing();
    const tableRowSpacingTooltip = "the distance two arrays in NS direction. Based latitude of location suggested row spacing is " + suggested_tableRowSpacing;
    return (
      <div>
        <div className="input-label">
          Table Row Spacing (In):
          <Tooltip
            id={'desired-table-row-spacing'}
            content={tableRowSpacingTooltip}
            position="right"
          />
        </div>
        <div className="field">
          <ConfirmationTextField
            value={tableRowSpacing?? currentTableRowSpacing}
            onConfirm={value => this.onTableRowSpacingChange(value)}
            title="Are you sure you want to change table row spacing?"
            content="All array layouts will be lost."
            shouldConfirm={true}
            shouldConfirmCb={(value) => this.shouldConfirm(value)}
            textFieldProps={{
              lineDirection: 'center',
              type: 'number',
              fullWidth: true,
            }}
          />
        </div>
        {this.renderWarningMessage()}
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    panels: appState.panels.panels,
    drawingManager: appState.drawingManager,
    selectedRoofId: appState.background.selectedRoofId,
    tableRowSpacing: appState.settings.tableRowSpacing,
    tilt: appState.projectConfiguration.projectEnvConfig.tilt,
    productId: appState.projectConfiguration.productId
  };
}

export default connect(mapStateToProps)(TableRowSpacingGFT);
