import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';

export default () => {
  const { userPreferences: { preferred_span } } = state();
  const smConfig = {
    zipcode: '',
    building_code: 3,
    wind_speed: 110,
    snow_load: 5,
    seismic_ss: 1.20,
    elevation: 5499,
    wind_exposure: 'B',
    building_height: null,
    rail_finish: 'AL',
    roof_type: 6,    
    mean_recurrence_interval: 25,
    preferred_span: preferred_span || 72,
    client_name: '',
    rail_direction: 'EW',
    rafter_spacing_inches: 1,
    building_type: gable.value,
    mid_clamps_choices:1,
    end_clamps_choices:1,
    rail_type:1,
    shortest_building_length: null,
    building_length: null,
    attachment_type : 21,
    soil_class: 4,
    clamps_choices: 1,
    parapet_height_input: 5,
    risk_category: 2,
  };
  return smConfig;
};
