import React from 'react';
import { DialogContainer } from 'react-md';
import { connect } from 'react-redux';

interface Props {
    calculateZoneClassication: boolean;
}

class CalculateZoneClassificationModal extends React.Component<Props> {
  render() {
    return (
      <DialogContainer
        id="zone-recalculation"
        visible={this.props.calculateZoneClassication}
        title="Zone Classication Calculation"
        fullPage={false}
        onHide={() => {}}
        focusOnMount={false}
      >
        Recalculating Zone Classication, please be patient...
      </DialogContainer>
    );
  }
}

const mapStateToProps = (appState: appState) => ({
    calculateZoneClassication: appState.settings.calculateZoneClassication,
});

export default connect(mapStateToProps)(CalculateZoneClassificationModal);


