import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import L from 'leaflet';
import "leaflet-rotatedmarker";
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isAscender } from '__common/constants/products';
import { state } from '__common/store';
import { angele_between_lat_lng_points, lat_lng_distance_to_feet } from '__editor/panelsEditor/components/background/utils/backgroundCordinatesConverter';


export default (coords: { lat: number, lng: number }[], roofHasPanels) : L.LayerGroup=> {
  const { projectConfiguration : { productId, inputUnit } } = state();
  const roofDimension = new L.LayerGroup;
  coords.map((coord, index)=>{
    const edgeDimension = calculateEdgeDimensionOnMap(coord, coords[(index+1)%coords.length], isAscender(productId) || isMetricUnit(inputUnit));
    const iconWidth = 10 * (edgeDimension.length.length + 2);
    const dimensionText = (isAscender(productId) ||  isMetricUnit(inputUnit)) ? `${edgeDimension.length} m` : `${edgeDimension.length} ft`;
    const textOutlineClass = roofHasPanels ? 'fill-roof-outline' : 'empty-roof-outline'; 
    (L.marker(edgeDimension.midPoint, {
      icon: L.divIcon({
          className: `bing-maps-popup roof-dimension-label ${textOutlineClass}`,
          html: dimensionText,
          iconSize: [iconWidth, 23]
      }),
      rotationAngle: edgeDimension.rotation,
      rotationOrigin: "center",
      zIndexOffset: 9999,
    })).addTo(roofDimension);

  })

  return roofDimension;
};


export function calculateEdgeDimensionOnMap(startingPoint: { lat: number, lng: number }, endingPoint: { lat: number, lng: number }, metricUnits: boolean): edgeDimension{
  const distanceInFt = lat_lng_distance_to_feet(startingPoint, endingPoint);
  const distance = metricUnits ? feetsToMeters(distanceInFt) : distanceInFt
  let angleInDegree = angele_between_lat_lng_points(startingPoint, endingPoint);
  // change in angle for readibility of label text
  if (90 < angleInDegree || angleInDegree < -90){
    angleInDegree += 180
  }
  const midPoint = L.latLngBounds([startingPoint, endingPoint]).getCenter();
  const edgeDimension = {
    length: distance.toFixed(2),
    midPoint,
    rotation : angleInDegree
  }
  return edgeDimension;
}