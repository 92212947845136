import { CHANGE_ROOF_ZOOM, SET_RELOCATION_ACTIVE } from 'actions';
import { dispatch, state } from '__common/store';
import { getEditorDims } from '../../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { getLeadEdgeCoords } from '../../../../components/roofsSelector/components/roofsSelectorLeadEdge/utils/roofsSelectorSelectEdge';
import { getZoomLevel } from '__editor/panelsEditor/components/background/utils/determineZoomLevel';
import { showErrorAlert } from '__common/modules/alerts';
import debounce from 'lodash/debounce';
import { getMap } from '../../map/map';
import { getBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { lat_lng_distance_to_miles } from '__editor/panelsEditor/components/background/utils/backgroundCordinatesConverter';
import { isBingMap, isBlankMap, isGoogleMap } from '__common/constants/map';


export function checkRoofSize(roofId: number, roofEdges: google.maps.LatLngLiteral[], cords, leadEdgeCords: {lat: number, lng: number}[], zoom: number) {
  const { width, height } = getEditorDims();

  const errorMessage = `
  Installation seems too large.
  Consider splitting into smaller parts to get a better image quality of selected area.
  `;

  const newZoom = getZoomLevel(roofId, roofEdges, cords, leadEdgeCords, zoom, width, height);

  if (newZoom < 18) {
    showErrorAlert(errorMessage);
  }

  dispatch(CHANGE_ROOF_ZOOM(roofId, newZoom));
}

export function checkOnRoofSizeOnEditor(roofId: number) {
  const { drawingManager: { roofs } } = state();

  if (roofs && Object.keys(roofs).length && roofs[roofId]) {
    const roof = roofs[roofId];
    const roofEdges = roof.coords;
    const roofCenter = roof.marker;
    const leadEdgeCoords = getLeadEdgeCoords(roofId);

    checkRoofSize(roofId, roofEdges, roofCenter, leadEdgeCoords, 18);
  }
}

export function mapCenterLatLng(mapType: string){
  let cursorLatLng = {'lat':0, 'lng':0};
      if (isGoogleMap(mapType)){
          const map = getMap();
          const {lat, lng} = map.getCenter();
          return cursorLatLng = {'lat':lat() , 'lng':lng()};
      };
      if (isBingMap(mapType)){
        const map = getBingMap();
        const {lat, lng} = map.getCenter();
        return cursorLatLng = { lat, lng };
      }
      if (isBlankMap(mapType)){
        return null;
      }
      
}
export const distanceErrorMessage = debounce((errorMessageHandling?: boolean, alertMessage?: string) =>{
   (errorMessageHandling) ?
    showErrorAlert(alertMessage) : null
  }, 4000);

export function distanceCheckFromFirstRoofCenter(showingErrorMessage?: boolean, newRoofMarker?: {lat:number, lng:number}){

  const alertMessage = `
    you are far away from your roofarea click on roof location button to go back.
    `;

  const userRestructingDistanceFromFirstRoofArea = 3 ;//this distance in mile

  const { drawingManager : {roofs}, roofsSelector : {mapType}} = state();
    if (roofs && Object.keys(roofs).length>0){
      let roofsArray = Object.keys(roofs);
      let flag = false;
      const firstRoofAreaLatLng = roofs[roofsArray[0]].marker;
      const newRoofAreaMarker = newRoofMarker ? newRoofMarker : mapCenterLatLng(mapType);
      if (newRoofAreaMarker != null){
        if (lat_lng_distance_to_miles(firstRoofAreaLatLng, newRoofAreaMarker) > userRestructingDistanceFromFirstRoofArea){
          flag = true;
          dispatch(SET_RELOCATION_ACTIVE(flag));
          if (showingErrorMessage){
            distanceErrorMessage(flag, alertMessage);
          }
          return flag;
        }else{
          dispatch(SET_RELOCATION_ACTIVE(flag));
          distanceErrorMessage(flag, alertMessage);
          return flag;

        }
      }else{

        return false;
      }

    }

    }

    
