import { ModuleBits, ModuleBitsReturnData } from "./baseTypes";

export class RMIFIModuleBits extends ModuleBits {
  
  schemaVersion: number;
  constructor(schemaVersion: number) {
    super();
    this.schemaVersion = schemaVersion;
  }
  fromBits(bitData: number): ModuleBitsReturnData {
    return {
      isLandscape: true,
      roofZone: 0 as roofZoneNumber,
      nearObstruction: this.schemaVersion === 3? bitData !== 0: false,
      obstructionType: (this.schemaVersion === 3? bitData: 0) as 0 | 1 | 2,
    };
  }
  toBits(panel: panelInState) {
    return panel.obstructionType;
  }
}
