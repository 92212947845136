import { isCursorSnapping } from '../cursor';
import { state, dispatch } from '__common/store';
import { SET_BLACK_CURSOR, SET_BLUE_CURSOR, SET_GREEN_CURSOR } from 'actions';
import { getLastSnapGroup } from '../../panels/utils/panelsSnappingGroup';

// Color Green = Already Snapped
// Dont Snap if Already snapped (i.e., when cursor color is same as Snapping Id)
const SNAPPING_ID = 4;

export const asce710and716GroundColors = (
  cursor: PIXI.Graphics, 
  cursorColide: boolean, 
  mapType: string, 
  productId: string, 
) => {
  const { editorCursor: { colour }, background: {moveArrayMode}, panels: {panelsToBeMoved} } = state();

  if (cursorColide) {
    return dispatch(SET_BLACK_CURSOR());
  }

  if ((!cursorColide && getLastSnapGroup() === null && colour !== 0) || (moveArrayMode && panelsToBeMoved.length > 0)) {
    return dispatch(SET_BLUE_CURSOR());
  }

  if (isCursorSnapping(cursorColide, false, colour, productId, SNAPPING_ID)) {
    return dispatch(SET_GREEN_CURSOR());
  }
};
