import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import  { getSnowLoadField } from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import { tiltForGft, TILT_GFT } from 'projectDesign/components/projectConfiguration/fields/tilt';
import riskCategory, { riskRMIToIIICategory } from 'projectDesign/components/projectConfiguration/fields/riskCategory';
import seismicS1 from 'projectDesign/components/projectConfiguration/fields/seismicS1';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import windOnIce from 'projectDesign/components/projectConfiguration/fields/windOnIce';
import iceThickness from 'projectDesign/components/projectConfiguration/fields/iceThickness';
import frontEdgeHeight, { apiField as frontEdgeHeightApiField } from 'projectDesign/components/projectConfiguration/fields/frontEdgeHeight';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import { asce7107And716WindSpeedForGft } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import foundationDiameter from 'projectDesign/components/projectConfiguration/fields/foundationDiameter';
import makeFoundationLengthConfig, { foundationLengthConfig, FOUNDATION_LENGTH } from 'projectDesign/components/projectConfiguration/fields/foundationLength';
import foundationType, { options as foundationTypeOptions } from 'projectDesign/components/projectConfiguration/fields/foundationType';
import { getBuildingCode710716 } from '../utils/getBuildingCode';
import { state } from '__common/store';
import { checkAdditionalGFTFactors, checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { checkFieldNotEmpty, checkLongTransitionPeriods, checkLongestBuildingLength, checkLongestRowLength, checkSeismicSd1, checkSeismicSds, checkShortestBuildingLength, checkShortestRowLength, checkTornadoSpeed } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import frostDepthGft from '../fields/frostDepthGft';
import { gftClampsType } from '../fields/clampTypeChoice';
import { railArrangementType } from '../fields/railArrangementType';
import { ABOVE_GROUND_HT_AT_2FT_LEADING_HT, ABOVE_GROUND_HT_AT_2p5FT_LEADING_HT, riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import AdditionalLoadCombinationField from '../fields/gftAndUlaAdditionalLoadCombinationFactor';
import { asce710716722BuildingCode } from '../fields/buildingCode';
import { isASCE722 } from '__common/constants/buildingCodes';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import seismicSd1 from 'projectDesign/components/projectConfiguration/fields/seismicSd1';
import tornadoSpeed from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import longTransitionPeriods from 'projectDesign/components/projectConfiguration/fields/longTransitionPeriods';
import shortestRowLength from '../fields/shortestRowLength';
import longestRowLength from '../fields/longestRowLength';
import topChord, { options as topChordOptions } from '../fields/topChord';
import { moduleLengthCheckForGFT } from '../utils/validation';
import { applyModuleLengthRestrictTo97InchForGFT, applyRemovalOfOldTopChord } from '__common/utils/versionCompare/versionCompare';

function getWindSpeedField(buildingCodeValue: number) {
  return asce7107And716WindSpeedForGft;
}

export const getDefaultFoundationLength = (_foundationType: number | string, tilt: number, _frontEdgeHeight: number,_frostDepthGft : number) => {
  let _default = null;
  let _optional = [];
  const foundationType = Number(_foundationType);
  const frontEdgeHeight = Number(_frontEdgeHeight);
  const frostDepthGft = Number(_frostDepthGft);
  if (foundationType === foundationTypeOptions.castInPlace.value) { 
    if (tilt === TILT_GFT.TILT_20) {   
      if(frostDepthGft <= 3.5 && foundationType === 2){
        if(frontEdgeHeight === 2) {
          _default = FOUNDATION_LENGTH.PILE_10_5;
          _optional = [FOUNDATION_LENGTH.PILE_12_5, FOUNDATION_LENGTH.PILE_15];
        } else if (frontEdgeHeight > 2 && frontEdgeHeight <= 4) {
          _default = FOUNDATION_LENGTH.PILE_12_5;
          _optional = [FOUNDATION_LENGTH.PILE_15];
        }else if(frontEdgeHeight >4 && frontEdgeHeight <=6.5){
          _default = FOUNDATION_LENGTH.PILE_15;
        }
      }else if(frostDepthGft > 3.5 && foundationType === 2){
        if(frontEdgeHeight === 2){
          _default = FOUNDATION_LENGTH.PILE_12_5;
          _optional = [FOUNDATION_LENGTH.PILE_15];
        }else if(frontEdgeHeight > 2 && frontEdgeHeight <=4.5){
          _default = FOUNDATION_LENGTH.PILE_15;
        }
      }
      
    }else if(tilt === TILT_GFT.TILT_30){
      if(frostDepthGft > 3.5 && foundationType === 2){
        if(frontEdgeHeight >=2.5 && frontEdgeHeight <=3){
          _default = FOUNDATION_LENGTH.PILE_15;
        }
      }else if(frostDepthGft <= 3.5 && foundationType === 2){
        if (frontEdgeHeight === 2.5) {
          _default = FOUNDATION_LENGTH.PILE_12_5;
          _optional = [FOUNDATION_LENGTH.PILE_15];
        }else if(frontEdgeHeight > 2.5 && frontEdgeHeight <= 5) {
          _default = FOUNDATION_LENGTH.PILE_15;
        }
      }
    }
    
  } else if(foundationType === foundationTypeOptions.driven.value) {
    if (tilt === TILT_GFT.TILT_20) { 
      if (frontEdgeHeight >= 2 && frontEdgeHeight <= 3) {
        _default = FOUNDATION_LENGTH.PILE_12_5;
        _optional = [FOUNDATION_LENGTH.PILE_10_5, FOUNDATION_LENGTH.PILE_15];
      }else if(frontEdgeHeight > 3 && frontEdgeHeight <=6.5){
        _default = FOUNDATION_LENGTH.PILE_15;
      }
    }else if (tilt === TILT_GFT.TILT_30) {
      if(frontEdgeHeight === 2.5) {
        _default = FOUNDATION_LENGTH.PILE_15;
        _optional = [FOUNDATION_LENGTH.PILE_12_5];
      }else if(frontEdgeHeight > 2.5 && frontEdgeHeight <= 5){
        _default = FOUNDATION_LENGTH.PILE_15;
      }
    }
 
  } else if(foundationType === foundationTypeOptions.aboveGradeBallast.value) {
    if (tilt === TILT_GFT.TILT_20) { 
      const calculatedMinLength = ABOVE_GROUND_HT_AT_2FT_LEADING_HT / 12 + (frontEdgeHeight - 2) + 2
      _optional = [ 
                    FOUNDATION_LENGTH.PILE_10_5, 
                    FOUNDATION_LENGTH.PILE_12_5, 
                    FOUNDATION_LENGTH.PILE_15
                  ].filter((length)=> length >= calculatedMinLength)
      _default = _optional.shift();
    }else if (tilt === TILT_GFT.TILT_30) {
      const calculatedMinLength = ABOVE_GROUND_HT_AT_2p5FT_LEADING_HT / 12 + (frontEdgeHeight - 2.5) + 2 // above_ground_ht_at_2.5ft_leading_ht
      _optional = [
                    FOUNDATION_LENGTH.PILE_10_5,
                    FOUNDATION_LENGTH.PILE_12_5, 
                    FOUNDATION_LENGTH.PILE_15
                  ].filter((length)=> length >= calculatedMinLength)
      _default = _optional.shift();
    }
 
  }
  return { _default, _optional };
};

export const getFoundationLengthConfig = (): foundationLengthConfig => {
  const { 
    projectConfiguration: {
      projectEnvConfig: { foundation_length, foundation_type, tilt, front_edge_height,frost_depth, },
      formState: { invalidFields },
    },
  } = state();
  if (invalidFields.find(({ fieldName }) => fieldName === frontEdgeHeightApiField)){
    return makeFoundationLengthConfig();
  }
  const defaultFoundationLength = getDefaultFoundationLength(foundation_type, tilt, front_edge_height,frost_depth);
  const config = makeFoundationLengthConfig(defaultFoundationLength._default, defaultFoundationLength._optional);

  config.missing = !checkFieldNotEmpty(foundation_length);
  return config;
};

export default (buildingCodeValue: number) => {
  const { 
    projectConfiguration: {
      projectEnvConfig: { 
        foundation_type, 
        rail_arrangement_type, 
        clamp_type, 
        risk_category, 
        seismic_sds, 
        seismic_sd1, 
        tornado_speed, 
        long_transition_periods_tl, 
        longest_row_length, 
        shortest_row_length,
        snow_load,
        wind_speed,
        elevation: current_elevation,
        top_chord,
      },
      projectVersion,
      clampCheckFailed,
      railArrangementTypeValidList,
    },
    moduleSelector: { modelData: { height: module_length } },
    user :{
      isStaff, isPrivilegedUser
    }
  } = state();
  const buildingCode = asce710716722BuildingCode;
  const windSpeed = getWindSpeedField(buildingCodeValue);
  const foundationLength = getFoundationLengthConfig();
  const snowLoad  = getSnowLoadField(true);
  const gftRiskCategory = isStaff ? riskCategory: riskRMIToIIICategory;

  const gftAdditionalLoadCombinationForStaffUser = (isStaff || isPrivilegedUser) ? AdditionalLoadCombinationField : false;

  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, seismicSs, elevation);
  checkAdditionalGFTFactors(railArrangementType, seismicS1, WindExposure, gftRiskCategory, windOnIce, iceThickness, frontEdgeHeight);
  let railArrangementTypeData = railArrangementType.data;
  if (clampCheckFailed && railArrangementTypeValidList.length) {
    railArrangementTypeData = railArrangementType.data.filter(option => railArrangementTypeValidList.includes(option.value));
  }
  const castInPlaceSelected = foundation_type === foundationTypeOptions.castInPlace.value;
  const tornadoSpeedField = isASCE722(buildingCodeValue) && riskCategoryCheckForTornadoSpeed(risk_category) ? tornadoSpeed : [];
  const seismicSdsField = isASCE722(buildingCodeValue) ? seismicSds : [];
  const seismicSd1Field = isASCE722(buildingCodeValue) ? seismicSd1 : [];
  const longTransitionPeriodsField = isASCE722(buildingCodeValue) ? longTransitionPeriods : [];
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  seismicSd1.missing = !checkSeismicSd1(seismic_sd1);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);
  longTransitionPeriods.missing = !checkLongTransitionPeriods(long_transition_periods_tl);
  snowLoad.missing = !checkFieldNotEmpty(snow_load);
  elevation.missing = !checkFieldNotEmpty(current_elevation);
  windSpeed.missing = !checkFieldNotEmpty(wind_speed);
  const longestRowLengthField = longestRowLength;
  const shortestRowLengthField = shortestRowLength;

  longestRowLength.missing = isASCE722(buildingCodeValue) ? !checkLongestRowLength(longest_row_length * 1) : [];
  shortestRowLength.missing = isASCE722(buildingCodeValue) ? !checkShortestRowLength(shortest_row_length * 1) : [];

  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCode,
        WindExposure,
        windSpeed,
        snowLoad,
        elevation,
        gftRiskCategory,
        seismicSs,
        seismicS1,
        ...(isASCE722(buildingCodeValue) ? [ seismicSdsField, seismicSd1Field, longTransitionPeriodsField, ] : []),
        windOnIce,
        iceThickness,
        frostDepthGft,
        gftAdditionalLoadCombinationForStaffUser,
        ...(isASCE722(buildingCodeValue) ? [ tornadoSpeedField, longestRowLengthField, shortestRowLengthField, ] : []),
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
      ],
    },
    Spans: {
      label: '',
      fields: [
        tiltForGft,
        railArrangementType(clamp_type, applyModuleLengthRestrictTo97InchForGFT(projectVersion) && isStaff && top_chord===topChordOptions.new_top_chord.value),
        gftClampsType(rail_arrangement_type),
        foundationType,
        foundationLength,
        ...(castInPlaceSelected ? [foundationDiameter] : []),
        frontEdgeHeight,
        ...(applyModuleLengthRestrictTo97InchForGFT(projectVersion) && !applyRemovalOfOldTopChord(projectVersion) ? [topChord(moduleLengthCheckForGFT(module_length), projectVersion, top_chord)] : []),
      ],
    },
    railFinish: false,
    railTypes: false,
  };

  return cfg;
};
