import { PanelsContainer } from '__editor/panelsEditor/models/panelsContainer';

let panelsContainer: PanelsContainer;
let baysContainer: PanelsContainer;

export const createPanelsContainer = () => {
  panelsContainer = new PIXI.Container();
  panelsContainer.id = 'panelsContainer';
  panelsContainer.zIndex = 4;
  
  return panelsContainer;
};

export const getPanelsContainer = () => panelsContainer;

export const removePanelsContainer = () => panelsContainer = undefined;

export const createBaysContainer = () => {
  baysContainer = new PIXI.Container();
  baysContainer.id = 'baysContainer';
  baysContainer.zIndex = 5;
  
  return baysContainer;
};

export const getBaysContainer = () => baysContainer;

export const removeBaysContainer = () => baysContainer = undefined;