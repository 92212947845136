export class RotatedLabel extends google.maps.OverlayView {
  private position_: any;
  private text_: any;
  private rotation_: any;
  private textClassName_: any;
  private parentClassName_: any;
  private div_: HTMLElement | null;
  private textElement: HTMLElement | null;
  private overlayProjection: any;
  

	constructor(position, text, rotation, customStyles = {textClassName: '', parentClassName: ''}) {
		super();

		// Initialize all properties.
		this.position_ = position;
		this.text_ = text;
		if (rotation > 90 && rotation < 270) {
			rotation += 180;
		}
		this.rotation_ = rotation;
		if (customStyles.textClassName){
			this.textClassName_ = customStyles.textClassName;
		}
		if (customStyles.parentClassName){
			this.parentClassName_ = customStyles.parentClassName;
		}

		// Define a property to hold the image's div. We'll
		// actually create this div upon receipt of the onAdd()
		// method so we'll leave it null for now.
		this.div_ = null;

	}

	/**
	 * onAdd is called when the map's panes are ready and the overlay has been
	 * added to the map.
	 */
	onAdd() {

		const div = document.createElement('div');
		div.style.borderStyle = 'none';
		div.style.borderWidth = '0px';
		div.style.position = 'absolute';
		div.style.transform = 'translate(-50%, -50%)';
    div.className = this.parentClassName_;

		this.textElement = this.getChildElement();
		div.appendChild(this.textElement);
		this.div_ = div;

		// Add the element to the "overlayLayer" pane.
		const panes = this.getPanes();
		panes.overlayLayer.appendChild(div);
	};

	getChildElement(){

		// Create the img element and attach it to the div.
		const childElement = document.createElement('div');
		childElement.textContent = this.text_;
		childElement.className = this.textClassName_;
		childElement.style.transform = `rotate(${this.rotation_}deg)`;
		childElement.style.transformOrigin = "50% 50%";
		childElement.style.whiteSpace = 'nowrap';
		return childElement;
	}

	draw() {

		// We use the south-west and north-east
		// coordinates of the overlay to peg it to the correct position.
		// To do this, we need to retrieve the projection from the overlay.
		this.overlayProjection = this.getProjection();
		this.moveOverlayDiv();
	};

	moveOverlayDiv() {
		// Retrieve position coordinates of this overlay
		// in LatLngs and convert them to pixel coordinates.
		// We'll use these coordinates to resize the div.
		const divPixel = this.overlayProjection.fromLatLngToDivPixel(this.position_);

		const div = this.div_;
		div.style.left = divPixel.x + 'px';
		div.style.top = divPixel.y + 'px';
	}

	// The onRemove() method will be called automatically from the API if
	// we ever set the overlay's map property to 'null'.
	onRemove() {
		if (!this.div_) {
			return;
		}

		this.div_.parentNode.removeChild(this.div_);
		this.div_ = null;
	};

	getPosition() {
		return this.position_;
	}

	setPosition(position) {
		this.position_ = position;

		if (!this.overlayProjection) return;

		this.moveOverlayDiv();
	}

	getText() {
		return this.text_;
	}

	setText(text) {
		this.text_ = text;
		this.textElement.textContent = this.text_;
	}

	getRotation() {
		return this.rotation_;
	}

	setRotation(rotation) {
		this.rotation_ = rotation;
		this.textElement.style.transform = `rotate(${this.rotation_}deg)`;
	}

	getTextClassName() {
		return this.textClassName_;
	}

	setTextClassName(textClassName) {
		this.textClassName_ = textClassName;
		this.textElement.className = textClassName;
	}

	getParentClassName() {
		return this.parentClassName_;
	}

	setParentClassName(parentClassName) {
		this.parentClassName_ = parentClassName;
		const div = this.div_;
		div.className = parentClassName;
	}

}