import { getColorByRoofZone } from "__editor/panelsEditor/models/panel";
import { DEBUG } from 'debug';
import { ON_THE_CORNER_COLOR } from "./panelColor";
import { drawHorizontalLine } from "../scaleCursorModel";

export function exposablePanelTexture(
  width: number, 
  height: number, 
  roofZone: roofZoneNumber, 
  nearObstruction: boolean, 
  exposed: boolean, 
  exposedNeighbour: boolean,
  id: number | string,
  panelConfig: number = undefined,
  skewAffected?: number,
  ) {
  const borderSize = 1;
  let borderColor: number;
  if (DEBUG.highlightExposedAndExposedNeighbour) {
    if (exposed === true && exposedNeighbour === true) {
      borderColor = 0xeb4034; // #eb4034
    } else if (exposed === true && exposedNeighbour === false) {
      borderColor = 0x37eb34; // #37eb34
    } else if (exposed === false && exposedNeighbour === true) {
      borderColor = 0x16b6fa; // #16b6fa
    } else if (exposed === false && exposedNeighbour === false) {
      borderColor = 0x000000; // #000000
    }
  } else {
    borderColor = 0x000000;
  }
  const COLOR_DARK_GREY = 0xA9A9A9;
  const COLOR_BLACK = 0x000000;
  
  return _basePanelTexture({
    width,
    height,
    backgroundColor: getColorByRoofZone(roofZone, nearObstruction, skewAffected),
    border: (exposed || exposedNeighbour ? 
      {
        color: COLOR_DARK_GREY,
        width: borderSize,      
      }: {
        color: COLOR_BLACK,
        width: borderSize,      
      }
    ),
    text: id ? String(id) : null,
    panelConfig,
  });
}

export function panelTexture(
  width: number, 
  height: number, 
  roofZone: roofZoneNumber, 
  nearObstruction: boolean, 
  id: number | string,
  attached: boolean = false,
  isRMGridflex: boolean = false,
  panelConfig: number = undefined,
  skewAffected?: number,
  ) {
  const borderSize = 2;
  const COLOR_BLACK = 0x000000;
  
  return _basePanelTexture({
    width,
    height,
    backgroundColor: (isRMGridflex && !!attached? ON_THE_CORNER_COLOR: getColorByRoofZone(roofZone, nearObstruction, skewAffected)),
    border: {
      color: COLOR_BLACK,
      width: borderSize,
    },
    text: id ? String(id) : null,
    panelConfig,
  });
}

interface PanelTextureConfig {
  width: number;
  height: number;
  backgroundColor: number;
  text?: string;
  border: {
    color: number;
    width: number;
  };
  panelConfig: number;
}

function _basePanelTexture(config: PanelTextureConfig): PIXI.Texture {
  const { backgroundColor, width, height, border: { width: borderWidth, color: borderColor }, panelConfig } = config;
  const panelTexture = new PIXI.Graphics(true);

  panelTexture.beginFill(backgroundColor);
  panelTexture.lineStyle(borderWidth, borderColor);
  panelTexture.drawRect(0, 0, Math.ceil(width * 2), Math.ceil(height * 2));

  if(panelConfig == 1 || panelConfig == 3) {
    panelTexture.addChild(drawHorizontalLine(Math.ceil(width/3), Math.ceil(width/3), 6));
    panelTexture.addChild(drawHorizontalLine(Math.ceil(2*width/3), Math.ceil(2*width/3), 6));
  }
  
  if (config.text) {
    panelTexture.addChild(_panelIdText(0, 0, width, height, config.text));
  }

  panelTexture.endFill();
  return panelTexture.generateCanvasTexture();
}

function _panelIdText(x: number, y: number, width: number, height: number, text?: string) {
  const tooltip: PIXI.Text = new PIXI.Text(text, {
    fontSize: '42px',
  });

  tooltip.x = x;
  tooltip.y = y;
  tooltip.width = width * 2;
  tooltip.height = height * 2;
  tooltip.id = 'panelIdText';
  return tooltip;
}

export function _baseBayTexture(width, height, attached: 0 | 1=0): PIXI.Texture {
  const panelTexture = new PIXI.Graphics(true);
  const bayColor = attached ? 0xf2682A : 0x2A81BF
  panelTexture.beginFill(bayColor); 
  panelTexture.lineStyle(1, 0x000000);
  panelTexture.drawRect(0, 0, Math.ceil(width * 2), Math.ceil(height * 2));
  panelTexture.endFill();
  return panelTexture.generateCanvasTexture();
}
