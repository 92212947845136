import React from 'react';
import { OPEN_STATIC } from 'actions';
import EngineeringBallastMapCVS from './engineeringBallastMaps/components/engineeringBallastMapCVS';
import EngineeringCadLayouts from 'engineering/components/engineeringCadLayouts';
import EngineeringDiscount from './engineeringDiscount';
import OpenMapInNewTabButton from './engineeringBallastMaps/components/openMapInNewTabButton';
import OpenMapSourceCodeInNewTabButton from './engineeringBallastMaps/components/openMapSourceCodeInNewTabButton';
import { products, isGroundProduct, isRMGridFlex, isRM10Evolution } from '__common/constants/products';
import { state } from '__common/store';
import { Accordion, Card } from 'react-bootstrap';
import { SectionTitles } from '_containers/Engineering';
import LayoutTypeDxfDownloadButton from './engineeringBallastMaps/components/layoutTypeDxfDownloadButton';
import { GRIDFLX_MAP_EXCLUDED_FOR_DXF, RM10_EVO_MAP_EXCLUDED_FOR_DXF } from 'engineering/utils/engineeringLayoutsConstant';

type Props = {
  pages: page[];
  projectArrays: engineeringProjectArray[],
  sectionTitle: string;
  dispatch: Function;
  projectId: string,
  productId: string,
  is_ASCE716or722: boolean,
  projectRoofs: engineeringProjectRoof[],
  shouldShowSourceCodeLink?: boolean,
};

export enum ReportTypes {
  projectReports = 'Project Reports',
  projectCadLayouts = 'Project Cad Layouts',
  productDocuments = 'Product Documents'
}
export type page = {
  name: string;
  content: any;
  active?: boolean,
  arrayName?: string,
  displayName?: string,
  firstButtonHandler?: any,
  hidden?: boolean,
  id?: number,
  type?: string,
  layoutType?: number,
};

type State = {
  page: string;
  sectionTitle: string;
};

const getActivePageName = (pages: page[]): string => {
  const page = pages.find(page => page.active);

  return page ? page.name : '';
};

export const getTitleForOnlyRoofArea = (title: string) => {
  let data = title;
  const { projectConfiguration: { productId } } = state();
  if (productId === products.rm_gridflex) {
    data = title.split('-')[0];
  }
  return data;
};

export default class EngineeringProjectReportsMenu extends React.Component<
  Props,
  State
> {
  state: State = {
    page: getActivePageName(this.props.pages),
    sectionTitle: this.props.sectionTitle,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
    if (prevState.sectionTitle !== nextProps.sectionTitle) {
      // This is to update active page when changing report tab (hence checking the sectionTitle).
      // The section title is not really needed in state, for any other reason than using getDerivedStateFromProps
      // but it also allowed us to migrate off from legacy lifecycle methods.
      const activePage = getActivePageName(nextProps.pages);
      return {
        page: activePage,
        sectionTitle: nextProps.sectionTitle,
      };
    }

    return null;
  }

  openPage = (page: string) => {
    this.setState(state => ({ page }));
  }

  openBom = () => {
    const { dispatch, projectId } = this.props;
    dispatch(
      OPEN_STATIC('bom.pdf', 'application/pdf', 'reports/pdf/bom/', { projectId }),
    );
  }

  renderDiscount = (sectionTitle: string, projectId: string) => {
    if (sectionTitle === ReportTypes.projectReports) {
      return <EngineeringDiscount projectId={projectId} />;
    }

    return null;
  }

  renderProjectReportsLinks = (pages: page[], currentPage: page, index: number, projectId, dispatch, projectArrays, projectRoofs, isStaff) => {
    const projectReports = pages.filter(page=> page.type === "Project Reports")
    const productDocuments = pages.filter(page=> page.type === "Product Documents")
    const types:Array<string> = [ReportTypes.projectReports, 'Project Cad Layouts', 'Product Documents']
    let filletedPages
    return (
      <div id='project-report-links'>
      {
        types.map((type, idx)=>{
          filletedPages = type === ReportTypes.projectReports ? projectReports : productDocuments
          const { projectConfiguration: { productId }, user: {isStaff, email}, engineering:{projectInfo:{is_imported_project}}} = state();
          if (type === ReportTypes.productDocuments){
            return(
              <div id="layout-maps" key={`layout-maps-${idx}`}>
              <Accordion defaultActiveKey="0" id='engineering-layouts'>
                <Card>
                  <Accordion.Toggle as={Card.Header} className='array-name' eventKey={`${idx}`}>
                    {type} 
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${idx}`}>
                  <div className='pt-3'>
                      {
                        filletedPages.map((page, i)=>{
                          return (page?.type === type) && (
                            <div
                              key={`${page}-${i}`}
                              className={`project-reports-menu-page ${currentPage.name === page.name ? 'active' : ''
                                }`}
                              onClick={this.openPage.bind(this, page.name)}
                            >
                              {page.name}
                            </div>
                          )
                        })
                      }
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            )
          }
          else if (type === ReportTypes.projectCadLayouts){
            return !isGroundProduct(productId)&&(
              <div className='box-shadow'>
                <div className="main-header">Project Cad Layouts</div>
                <EngineeringCadLayouts projectId={projectId} dispatch={dispatch} projectArrays={projectArrays} projectRoofs={projectRoofs} productId={productId} isStaff={isStaff} is_imported_project={is_imported_project} email={email}/>
              </div>
            )
          }
          return(
            <div key={`${type}-${idx}`}>
              <div className='box-shadow'>
                <div className="main-header">Project Reports</div>
                  {
                    filletedPages.map((page, i)=>{
                      return (page?.type === type) && (
                        <div
                          key={`${page}-${i}`}
                          className={`project-reports-menu-page ${currentPage.name === page.name ? 'active' : ''
                            }`}
                          onClick={this.openPage.bind(this, page.name)}
                        >
                          {page.name}
                        </div>
                      )
                    })
                  }
              </div>
            </div>
          )
        })
      }
      </div>
    );
  }

  renderLayoutLinkWithCardsAndStaticsHeader = (page: page, currentPage: page, index: number, projectId: string, dispatch: Function, projectArrays: engineeringProjectArray[], projectRoofs: engineeringProjectRoof[]) => {
    const { projectConfiguration: { productId }, user: {isStaff, email}, engineering:{projectInfo:{is_imported_project}}} = state();
    return (
      <React.Fragment key={index}>
        {!page.hidden && <div
          className={`project-reports-menu-page ${currentPage.name === page.name ? 'active' : ''
            }`}
          onClick={this.openPage.bind(this, page.name)}
        >
          {page.name}
        </div>}
        {isGroundProduct(productId) ? null : <EngineeringCadLayouts projectId={projectId} dispatch={dispatch} projectArrays={projectArrays} projectRoofs={projectRoofs} productId={productId} isStaff={isStaff} is_imported_project={is_imported_project} email={email}/>}
        {<div className="maintitle">Product Documents</div>}
      </React.Fragment>
    );
  }

  sourceCodeButtons(arrayId?: number) {
    const { projectId } = this.props;
    return ["new", "old"].map((notation) => <OpenMapSourceCodeInNewTabButton projectId={projectId} notation={notation} arrayId={arrayId} />)
  }

  shouldDXFdownloadVisible(layoutType, productId?: string) {
    
    if ((isRMGridFlex(products[productId]) && !GRIDFLX_MAP_EXCLUDED_FOR_DXF.includes(layoutType)) ||
      (isRM10Evolution(products[productId]) && !RM10_EVO_MAP_EXCLUDED_FOR_DXF.includes(layoutType))) {

      return true;
    }
    return true
  }
  shouldShowDownloadCSV(mapType: number){
    return ![94, 29].includes(mapType)
  }
  renderBallastMapsLinks = (pages: page[], currentPage: page, projectArrays) => {
    const { projectId, productId, is_ASCE716or722, shouldShowSourceCodeLink } = this.props;
    const { engineeringBallastMaps: { generatedLayoutType } } = state();
    return (
      <React.Fragment>
        {
          projectArrays.map((array, idx)=>{
            return(
              <div id="layout-maps" key={array.id}>
                <Accordion defaultActiveKey="0" id='engineering-layouts'>
                  <Card>
                    <Accordion.Toggle as={Card.Header} className='array-name' eventKey={`${idx}`}>
                      {array.name} 
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${idx}`}>
                      <div className='p-3'>
                        {
                          pages.map((page, i)=>{
                            return (page.id === array.id) && (
                              <div
                                key={`${page}-${i}`}
                                className={`project-reports-menu-page ballast-map-links ${currentPage.name === page.name ? 'active' : ''
                                  }`}
                                onClick={this.openPage.bind(this, page.name)}
                              >
                                <div className={'ballast-map-links-display-name'}>
                                  {page.displayName || page.name}
                                </div>
                                <div className="layout-buttons">
                                  <OpenMapInNewTabButton projectId={projectId} />
                                  {shouldShowSourceCodeLink && generatedLayoutType == 94 ? this.sourceCodeButtons(page.id)  : null}
                                  {this.shouldShowDownloadCSV(generatedLayoutType) && <EngineeringBallastMapCVS productId={productId} projectId={projectId} is_ASCE716or722={is_ASCE716or722} />}
                                  {isRMGridFlex(products[productId]) && this.shouldDXFdownloadVisible(page.layoutType) ? <LayoutTypeDxfDownloadButton productId={productId} projectId={projectId} arrayId={page.id} /> : null}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            )
          })
        }
      </React.Fragment>
    );
  }

  renderModuleTestMapsLinks = (page: page, currentPage: page) => {
    const { projectId } = this.props;
    return (
      <React.Fragment key={page.name}>
        {page.arrayName && <div className="drawer-section-subtitle ballast-maps">{getTitleForOnlyRoofArea(page.arrayName)}</div>}
        <div
          key={page.name}
          className={`project-reports-menu-page ballast-map-links ${currentPage.name === page.name ? 'active' : ''
            }`}
          onClick={this.openPage.bind(this, page.name)}
        >
          <div className={'ballast-map-links-display-name'}>
            {page.displayName || page.name}
          </div>
          <div className="layout-buttons">
            <OpenMapInNewTabButton projectId={projectId} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSourceCodeLinksForSourceCodeSection = (page: page, currentPage: page) => {
    return (
      <React.Fragment key={page.name}>
        {page.name && <div className="drawer-section-subtitle ballast-maps">{getTitleForOnlyRoofArea(page.arrayName)}</div>}
        <div
          key={page.name}
          className={`project-reports-menu-page ballast-map-links ${currentPage.name === page.name ? 'active' : ''
            }`}
          onClick={this.openPage.bind(this, page.name)}
        >
          <div className={'ballast-map-links-display-name'}>
            {page.displayName || page.name}
          </div>
          <div className="layout-buttons">
            {this.sourceCodeButtons(page.id)}
          </div>
        </div>
      </React.Fragment>
    );
  }


  render() {
    const { page } = this.state;
    const { projectConfiguration: { productId }, user: { isStaff } } = state();
    const { pages, sectionTitle, projectArrays, dispatch, projectId, projectRoofs } = this.props;
    if (page !== null) {
      const currentPage = pages.find(p => p.name === page);
      if (currentPage && currentPage.content) {
        return (
          <>
            <div className="project-reports-menu-pages">
              {this.renderDiscount(sectionTitle, projectId)}
              {sectionTitle !== SectionTitles.projectReports && <div className="maintitle">{sectionTitle.toLocaleUpperCase()}</div>}
              <div className="project-reports-menu-pages-list">
                { 
                (sectionTitle === SectionTitles.engBallastMaps) ? 
                  this.renderBallastMapsLinks(pages, currentPage, projectArrays)
                :
                (sectionTitle === ReportTypes.projectReports) ? 
                  this.renderProjectReportsLinks(pages, currentPage, 0, projectId, dispatch, projectArrays, projectRoofs, isStaff) 
                : pages.map((page, index) => {
                  if (page.name === 'PROJECT OVERVIEW') {
                    return this.renderLayoutLinkWithCardsAndStaticsHeader(page, currentPage, index, projectId, dispatch, projectArrays, projectRoofs);
                  } else if (!isRMGridFlex(productId) && page.name === 'LAYOUT') {
                    return this.renderLayoutLinkWithCardsAndStaticsHeader(page, currentPage, index, projectId, dispatch, projectArrays, projectRoofs);
                  }

                  if (sectionTitle === SectionTitles.moduleTestMaps) {
                    return this.renderModuleTestMapsLinks(page, currentPage);
                  }

                  if (sectionTitle === SectionTitles.engSourceCode) {
                    return this.renderSourceCodeLinksForSourceCodeSection(page, currentPage);
                  }

                })}
              </div>
            </div>
            <div className="project-reports-content">{currentPage.content}</div>
          </>
        );
      }
    }

    return null;
  }
}
