import { getCursorSnappingPoint, ICURSOR_STICK_POINT } from './utils/snapToGridStickyPoints';
import { nearestKdTreePoint } from './utils/kdTreeStore';
import { snapOnlyToAxies } from './utils/snapToAxies';
import { state } from '__common/store';
import {
  setNearestGroup, getSnapDistancesInPixels,
} from './utils/snapToGridHelper';

import { restrictedSnapping } from './utils/snapToGridRestrictedPoints';
import { moveCursorToCorrectSiteOfPanel } from './utils/snapToGridGetStickyPointByCenter';
import { setLastPoint, setLastSnappingData } from './utils/snapToGridTestsDataRetriver';
import { setSnappedGroup } from '../panels/utils/panelsSnappingGroup';
import { DEBUG } from 'debug';

export interface IPANEL_CORNER {
  panelCorner: number;
  cursorCorner: number;
  distance: number;
  groupId: number;
  panelId: number;
  x: number;
  y: number;
}

interface snappedPoint extends pixelPoint {
  cursorCorner?: number;
  panelCorner?: number;
  snapped?: boolean;
}

export function snap(point: pixelPoint): snappedPoint {
  setLastPoint(point);

  const cursorStickyPoints = getCursorSnappingPoint(point.x, point.y);

  const distances: IPANEL_CORNER[] = getNearestCorners(cursorStickyPoints);

  if (distances.length) {

    const allPanelIds = state().panels.panels.map(p => p.id);

    const allValidDistances = distances.filter(d=>allPanelIds.includes(d.panelId));

    const nearestPoints = filterRestrictedSnappingPoints(allValidDistances);

    const sortedNearestPoint = sortFromClosestToFarest(nearestPoints);
    
    const nearestPoint = sortedNearestPoint[0];

    const panelsIds = getNearstPanelsIds(nearestPoints);
    
    if (nearestPoint) {
      if (restrictedSnapping(nearestPoint)) {
        setSnappedGroup(null);
        return point;
      }

      const nearestCorner = moveCursorToCorrectSiteOfPanel(nearestPoint);

      const snappedPoint = snapOnlyToAxies(
        point,
        nearestCorner,
        panelsIds,
      );

      if (snappedPoint.snapped) {
        const currentNearestGroup = nearestPoint.groupId;
        setNearestGroup(currentNearestGroup);
        setSnappedGroup(currentNearestGroup);
        setLastSnappingData(snappedPoint);
        
        if (DEBUG.showCursorSnappingLogs) {
          console.log('Snapping:', 'Cursor:', snappedPoint.cursorCorner, 'Panel:', snappedPoint.panelCorner);
        }

      } else {
        clearSnappingGroups();
      }

      delete snappedPoint.snapped;
      return snappedPoint;
    }
  }

  clearSnappingGroups();
  
  return point;
}

export function snapToIfClosestPanel(point: pixelPoint) {
  const { rubberBand: { inited, mode }, panels: { panels } } = state();
  if (panels.length && !inited && mode === 'draw') {
    const pointXY = snap(point);
    return pointXY;
  }

  return {
    x: point.x,
    y: point.y,
  };
}

const getNearestCorners = (cursorStickyPoints: ICURSOR_STICK_POINT[]) => {
  return cursorStickyPoints.reduce<IPANEL_CORNER[]>((total, stickPoint, index) => {
    const nearest = nearestKdTreePoint(stickPoint, 20, getSnapDistancesInPixels() * 10);

    if (nearest.length) {
      const distances: IPANEL_CORNER[] = nearest.map(corner => {
        const distance = {
          ...corner[0],
          distance: corner[1],
          cursorCorner: index,
          panelCorner: corner[0].corner,
        };
        delete distance.corner;
        return distance;
      });
      return total.concat(distances);
    }
    return total;
  },                                                []);
};

const filterRestrictedSnappingPoints = (corners: IPANEL_CORNER[]) => {
  return corners.filter(
    nearestPoint => !restrictedSnapping(nearestPoint),
  );
};

const sortFromClosestToFarest = (corners: IPANEL_CORNER[]) => {
  return corners.sort((a, b) => a.distance - b.distance);
};

const getNearstPanelsIds = (corners: IPANEL_CORNER[]) => {
  const panelsIds = corners.map(point => point.panelId);
  return Array.from(new Set<number>([...panelsIds]));
};

const clearSnappingGroups = () => {
  setLastSnappingData(undefined);
  setSnappedGroup(null);
  setNearestGroup(null);
};
