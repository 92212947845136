import Gft from 'projectDesign/components/projectConfiguration/utils/defaultProps/gft';
export function getGFTProjectConfigurationData(projectEnvConfig: projectEnvConfig) {
  const {
    zipcode,
    wind_speed,
    building_code,
    preferred_span,
    snow_load,
    elevation,
    wind_exposure,
    rail_finish,
    roof_type,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    pro_clamps,
    risk_category,
    row_spacing,
    column_spacing,
    tilt,
    seismic_ss,
    seismic_s1,
    wind_on_ice,
    ice_thickness,
    foundation_type,
    rail_arrangement_type,
    foundation_diameter,
    front_edge_height,
    foundation_length,
    frost_depth,
    clamp_type,
    additional_load_combination,
    seismic_sds,
    seismic_sd1,
    tornado_speed,
    long_transition_periods_tl,
    longest_row_length,
    shortest_row_length,
    top_chord,
  } = projectEnvConfig;

  return {
    zipcode,
    wind_speed,
    building_code,
    preferred_span,
    snow_load,
    elevation,
    wind_exposure,
    rail_finish,
    roof_type,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    pro_clamps: pro_clamps ? 1 : 0,
    risk_category,
    row_spacing,
    column_spacing,
    tilt,
    seismic_ss,
    seismic_s1,
    wind_on_ice,
    ice_thickness,
    foundation_type,
    rail_arrangement_type,
    foundation_diameter,
    front_edge_height,
    foundation_length: foundation_length ? foundation_length : Gft().foundation_length,
    frost_depth: frost_depth ? frost_depth : Gft().frost_depth,
    additional_load_combination : additional_load_combination ? additional_load_combination : 1,
    clamp_type,
    seismic_sds,
    seismic_sd1,
    tornado_speed,
    long_transition_periods_tl,
    longest_row_length,
    shortest_row_length,
    top_chord,
  };
}
