import {
  BOM_GENERATE_SUCCESSFUL, ENGINEERING_GENERATE_SUCCESSFUL, LAYOUT_GENERATE_SUCCESSFUL, BOM_GENERATE_START,
  COMMON_REPORT_GENERATE_START_ACTION, PROJECT_DOCUMENTS_RESET, INTERNAL_ENGINEERING_GENERATE_SUCCESSFUL, DETAILED_ENGINEERING_GENERATE_SUCCESSFUL,
  ENGINEERING_SUMMARY_GENERATE_SUCCESSFUL,
  COMMON_DXF_GENERATE_START_ACTION,
  DXF_GENERATE_SUCCESSFUL,
  CONSOLIDATED_DXF_GENERATE_SUCCESSFUL,
  DXF_ZIP_ALL_GENERATE_SUCCESSFUL,
  CONSTRUCTION_DXF_GENERATE_SUCCESSFUL,
  LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL,
  PLANSET_DXF_GENERATE_SUCCESSFUL,
  PE_STAMP_GENERATE_SUCCESSFUL,
  PLANSET_DXF_GENERATE_FAILURE,
} from './engineeringProjectDocumentsActions';
import { EngineeringProjectDocumentsActionsConstants } from 'actionsConstants';
import { BALLAST_GENERATING_RESET, FETCH_STATIC } from 'actions';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { OPEN_STATIC } from '__common/components/FetchStatics/fetchStaticsActions';
import { dispatch } from '__common/store';
import {
  getGenerateBomReportUrl,
  getGenerateEngineeringReportUrl,
  getGenerateLayoutReportUrl,
  getGenerateFullReportUrl,
  getDownloadFullReportDownloadConfiguration,
  getDownloadBomDownloadConfiguration,
  getGenerateInternalEngineeringReportUrl,
  getGenerateDetailedEngineeringReportUrl,
  getGenerateEngineeringSummaryReportUrl,
  getDXFUrl,
  getConsolidatedDXFUrl,
  getDXFZIPAllUrl,
  getConstructionDXFUrl,
  getPlansetDXFurl,
  getLayoutTypeDXFUrl,
  getGeneratePeStampReportUrl,
} from 'engineering/components/engineeringProjectDocuments/utils/reportsApi';
import { Action } from '__common/store/action';
import { ActionsObservable } from 'redux-observable';
import { AnyAction } from 'redux';
import { Store } from 'redux';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';

export function generateBomPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.BOM_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.BOM_GENERATE_START),
        onSuccess: BOM_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING BOM REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING BOM REPORT...',
        onSocketFailure: 'GENERATING BOM FAILURE',
        link: hitApi('get', getGenerateBomReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function resetReportsOnUnitTypeChange(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.TOGGLE_UNITS)
    .switchMap((action)=>{return of(PROJECT_DOCUMENTS_RESET(), dispatch(BALLAST_GENERATING_RESET()))});
}

export function generateEngineeringPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.ENGINEERING_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.ENGINEERING_GENERATE_START),
        onSuccess: ENGINEERING_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING ENGINEERING REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING ENGINEERING REPORT...',
        onSocketFailure: 'GENERATING ENGINEERING FAILURE',
        link: hitApi('get', getGenerateEngineeringReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function generateEngineeringSummaryPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.ENGINEERING_SUMMARY_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.ENGINEERING_SUMMARY_GENERATE_START),
        onSuccess: ENGINEERING_SUMMARY_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING ENGINEERING SUMMARY REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING ENGINEERING SUMMARY REPORT...',
        onSocketFailure: 'GENERATING ENGINEERING SUMMARY FAILURE',
        link: hitApi('get', getGenerateEngineeringSummaryReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function generateInternalEngineeringPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.INTERNAL_ENGINEERING_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.INTERNAL_ENGINEERING_GENERATE_START),
        onSuccess: INTERNAL_ENGINEERING_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING INTERNAL ENGINEERING REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING INTERNAL ENGINEERING REPORT...',
        onSocketFailure: 'GENERATING INTERNAL ENGINEERING FAILURE',
        link: hitApi('get', getGenerateInternalEngineeringReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function generateDetailedEngineeringPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.DETAILED_ENGINEERING_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.DETAILED_ENGINEERING_GENERATE_START),
        onSuccess: DETAILED_ENGINEERING_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING DETAILED ENGINEERING REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING DETAILED ENGINEERING REPORT...',
        onSocketFailure: 'GENERATING DETAILED ENGINEERING FAILURE',
        link: hitApi('get', getGenerateDetailedEngineeringReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function generatePeStampPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.PE_STAMP_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.PE_STAMP_GENERATE_START),
        onSuccess: PE_STAMP_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING PE STAMP REQUEST REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING PE STAMP REQUEST REPORT...',
        onSocketFailure: 'GENERATING PE STAMP REQUEST FAILURE',
        link: hitApi('get', getGeneratePeStampReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function generateLayoutPDF(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.LAYOUT_GENERATE_START)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.LAYOUT_GENERATE_START),
        onSuccess: LAYOUT_GENERATE_SUCCESSFUL,
        socketName: 'project',
        onSocketPending: 'GENERATING LAYOUT REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING LAYOUT REPORT...',
        onSocketFailure: 'GENERATING LAYOUT FAILURE',
        link: hitApi('get', getGenerateLayoutReportUrl(action.payload.projectId, action.payload.unitsType)),
      }),
    );
}

export function downloadBomReport(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.OPEN_BOM_REPORT)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) => {
      const { urlBase, type, queryParameters, pathVariables, fileNamePrefix } = getDownloadBomDownloadConfiguration(action.payload.projectId, action.payload.productId, action.payload.unitsType);
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.OPEN_BOM_REPORT),
        onSuccess: OPEN_STATIC.bind(null, fileNamePrefix + 'bom_report.pdf', type, urlBase, pathVariables, queryParameters),
        socketName: 'project',
        link: hitApi('get', getGenerateBomReportUrl(action.payload.projectId, action.payload.unitsType)),
      });
    });
}

export function downloadFullReport(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.DOWNLOAD_FULL_REPORT)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) => {
      const { pathVariables, queryParameters, type, urlBase, fileNamePrefix } = getDownloadFullReportDownloadConfiguration(action.payload.projectId, action.payload.productId, action.payload.unitsType);
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.DOWNLOAD_FULL_REPORT),
        onSuccess: [
          FETCH_STATIC.bind(null, fileNamePrefix + 'full_report.pdf', type, urlBase, pathVariables, queryParameters),
          BOM_GENERATE_START.bind(null, action.payload.projectId, action.payload.unitsType),
        ],
        onSocketPending: 'GENERATING FULL PDF REPORT WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING FULL PDF REPORT...',
        onSocketFailure: 'GENERATING FULL PDF REPORT FAILURE',
        socketName: 'project',
        link: hitApi('get', getGenerateFullReportUrl(action.payload.projectId, action.payload.unitsType)),
      });
    });
}

export function openFullReport(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.OPEN_FULL_REPORT)
    .switchMap((action: Action<COMMON_REPORT_GENERATE_START_ACTION>) => {
      const { pathVariables, queryParameters, type, urlBase, fileNamePrefix } = getDownloadFullReportDownloadConfiguration(action.payload.projectId, action.payload.productId, action.payload.unitsType);
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.OPEN_FULL_REPORT),
        onSuccess: [
          OPEN_STATIC.bind(null, fileNamePrefix + 'full_report.pdf', type, urlBase, pathVariables, queryParameters),
          BOM_GENERATE_START.bind(null, action.payload.projectId, action.payload.unitsType),
        ],
        onSocketPending: 'OPENING FULL PDF REPORT WAITING IN A QUEUE...',
        onSocketStart: 'OPENING FULL PDF REPORT...',
        onSocketFailure: 'OPENING FULL PDF REPORT FAILURE',
        socketName: 'project',
        link: hitApi('get', getGenerateFullReportUrl(action.payload.projectId, action.payload.unitsType)),
      });
    });
}

export function generateDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_START)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_START),
        onSuccess: [
          DXF_GENERATE_SUCCESSFUL.bind(null, action.payload ),
        ],
        socketName: 'project',
        onSocketPending: 'GENERATING DXF IN A QUEUE...',
        onSocketStart: 'GENERATING DXF REPORT...',
        onSocketFailure: 'GENERATING DXF FAILURE',
        link: hitApi('get', getDXFUrl(action.payload.projectId, action.payload.arrayId)),
      })
    }
    );
}


export function generateLayoutTypeDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_START)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_START),
        onSuccess: [
          LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL.bind(null, action.payload ),
        ],
        socketName: 'project',
        onSocketPending: 'GENERATING LAYOUT TYPE DXF IN A QUEUE...',
        onSocketStart: 'GENERATING LAYOUT TYPE DXF REPORT...',
        onSocketFailure: 'GENERATING LAYOUT TYPE DXF FAILURE',
        link: hitApi('get', getLayoutTypeDXFUrl(action.payload.projectId, action.payload.arrayId, action.payload.layoutType)),
      })
    }
    );
}

export function getDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.DXF_GENERATE_SUCCESSFUL)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      store.dispatch(FETCH_STATIC(action.payload.filename, action.payload.type, action.payload.resource , action.payload.pathVariables ));
      return Observable.empty();
    }
    );
} 

export function getLayoutTypeDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.LAYOUT_TYPE_DXF_GENERATE_SUCCESSFUL)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      store.dispatch(FETCH_STATIC(action.payload.filename, action.payload.type, action.payload.resource , action.payload.pathVariables, '',false, action.payload.layoutType ));
      return Observable.empty();
    }
    );
} 


export function generateConsolidatedDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_START)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_START),
        onSuccess: [
          CONSOLIDATED_DXF_GENERATE_SUCCESSFUL.bind(null, action.payload),
        ],
        socketName: 'project',
        onSocketPending: 'GENERATING CONSOLIDATED DXF IN A QUEUE...',
        onSocketStart: 'GENERATING CONSOLIDATED DXF REPORT...',
        onSocketFailure: 'GENERATING CONSOLIDATED DXF FAILURE',
        link: hitApi('get', getConsolidatedDXFUrl(action.payload.projectId, action.payload.roofId)),
      })
    }
    );
}

export function getConsolidatedDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.CONSOLIDATED_DXF_GENERATE_SUCCESSFUL)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      store.dispatch(FETCH_STATIC(action.payload.filename, action.payload.type, action.payload.resource , action.payload.pathVariables ));
      return Observable.empty();
    }
    );
}

export function generateConstructionDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.CONSTRUCTION_DXF_GENERATE_START)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.CONSTRUCTION_DXF_GENERATE_START),
        onSuccess: [
          CONSTRUCTION_DXF_GENERATE_SUCCESSFUL.bind(null, action.payload),
        ],
        socketName: 'project',
        onSocketPending: 'GENERATING CONSTRUCTION DRAWING DXF IN A QUEUE...',
        onSocketStart: 'GENERATING CONSTRUCTION DRAWING DXF REPORT...',
        onSocketFailure: 'GENERATING CONSTRUCTION DRAWING DXF FAILURE',
        link: hitApi('get', getConstructionDXFUrl(action.payload.projectId, action.payload.roofId)),
      })
    }
    );
}

export function getConstructionDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.CONSTRUCTION_DXF_GENERATE_SUCCESSFUL)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      store.dispatch(FETCH_STATIC(action.payload.filename, action.payload.type, action.payload.resource , action.payload.pathVariables ));
      return Observable.empty();
    }
    );
}

export function generatePlansetDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.PLANSET_DXF_GENERATE_START)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.PLANSET_DXF_GENERATE_START),
        onSuccess: [
          PLANSET_DXF_GENERATE_SUCCESSFUL.bind(null, action.payload),
        ],
        onError: PLANSET_DXF_GENERATE_FAILURE,
        socketName: 'project',
        onSocketPending: 'GENERATING PLANSET DRAWING DXF IN A QUEUE...',
        onSocketStart: 'GENERATING PLANSET DRAWING DXF REPORT...',
        onSocketFailure: 'GENERATING PLANSET DRAWING DXF FAILURE',
        onErrorMessage: 'Cannot Fetch Planset',
        link: hitApi('get', getPlansetDXFurl(action.payload.projectId)),
      })
    }
    );
}

export function getPlansetDxf(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.PLANSET_DXF_GENERATE_SUCCESSFUL, EngineeringProjectDocumentsActionsConstants.PLANSET_DXF_GENERATE_FAILURE)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      store.dispatch(FETCH_STATIC(action.payload.filename, action.payload.type, action.payload.resource ));
      return Observable.empty();
    }
    );
}

export function generateDxfZipAll(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_START)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_START),
        onSuccess: [
          DXF_ZIP_ALL_GENERATE_SUCCESSFUL.bind(null, action.payload ),
        ],
        socketName: 'project',
        onSocketPending: 'GENERATING COMBINED DXF IN A QUEUE...',
        onSocketStart: 'GENERATING COMBINED DXF REPORT...',
        onSocketFailure: 'GENERATING COMBINED DXF FAILURE',
        link: hitApi('get', getDXFZIPAllUrl(action.payload.projectId)),
      })
    }
    );
}

export function getDxfZipAll(action$: ActionsObservable<AnyAction>, store: Store<appState>) {
  return action$.ofType(EngineeringProjectDocumentsActionsConstants.DXF_ZIP_ALL_GENERATE_SUCCESSFUL)
    .switchMap((action: Action<COMMON_DXF_GENERATE_START_ACTION>) =>{
      store.dispatch(FETCH_STATIC(action.payload.filename, action.payload.type, action.payload.resource ));
      return Observable.empty();
    }
    );
} 
