type line = [pixelPoint, pixelPoint];

const getDifference = (point1: pixelPoint, point2: pixelPoint) => {
  return {
    dx: point2.x - point1.x,
    dy: point2.y - point1.y,
  };
};

const isVerticalLine = (line: line): boolean => {
  const { dx } = getDifference(line[0], line[1]);
  return dx === 0;
};

const getSlope = (line: line): number => {
  const { dx, dy } = getDifference(line[0], line[1]);
  return dy / dx;
};

const getYIntercept = (line: line): number => {
  const slope = getSlope(line);
  return line[0].y - slope * line[0].x;
};

const areParallel = (slope1: number, slope2: number) => {
  return slope1 === slope2;
};

export const getIntersection = (line1: line, line2: line): pixelPoint => {
  if (isVerticalLine(line1) && isVerticalLine(line2)) {
    throw new Error('lines are parallel and will never intersect');
  }
  if (isVerticalLine(line1)) {
    const x = line1[0].x;
    const a = getSlope(line2);
    const b = getYIntercept(line2);
    const y = a * x + b;
    return { x, y };
  } 

  if (isVerticalLine(line2)) {
    const x = line2[0].x;
    const a = getSlope(line1);
    const b = getYIntercept(line1);
    const y = a * x + b;
    return { x, y };
  }

  const a1 = getSlope(line1);
  const a2 = getSlope(line2);

  if (areParallel(a1, a2)) {
    throw new Error('lines are parallel and will never intersect');
  }

  const b1 = getYIntercept(line1);
  const b2 = getYIntercept(line2);

  const x = (b2 - b1) / (a1 - a2);
  const y = a1 * x + b1;
  return {
    x, y,
  };
};

export const makeLine = (p1: pixelPoint, p2: pixelPoint): line => {
  return [p1, p2];
};

export const makePoint = (x: number, y: number): pixelPoint => {
  return {
    x, y,
  };
};
