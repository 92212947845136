import { createReducer } from '__common/utils/helpers';
import { ScaleActionTypes } from 'actionsConstants';

export const scaleState = {
  enabled: false,
  startPos: {
    x: 0,
    y: 0,
  },
  endPos: {
    x: 0,
    y: 0,
  },
};

export default {
  scale: createReducer(scaleState, {
    [ScaleActionTypes.START_SCALE](state, action) {
      return { ...state, enabled: true };
    },
    [ScaleActionTypes.SET_START_POS](state, action) {
      const { startPos } = action.payload;
      return { ...state, startPos };
    },
    [ScaleActionTypes.SET_END_POS](state, action) {
      const { endPos } = action.payload;
      return { ...state, endPos };
    },
    [ScaleActionTypes.TURN_OFF_SCALE](state) {
      return {
        enabled: false,
        startPos: {
          x: 0,
          y: 0,
        },
        endPos: {
          x: 0,
          y: 0,
        },
      };
    },
  }),
};
