import { createLegend, LegendZone } from './legends';
import { ROOF_PITCH } from '../../tiltedRoof/roofPitch';

export const hipRoofLegend = (roofPitch: string) => {
  if (!roofPitch || roofPitch === '---') {
    return null;
  }
  const degressAngle = ROOF_PITCH[roofPitch];
  let legend: LegendZone[];

  if (degressAngle <= 45) {
    legend = [{
      zone: 3,
      name: '3',
    }, {
      zone: 5,
      name: '2r',
    }, {
      zone: 2,
      name: '2e',
    }, {
      zone: 1,
      name: '1',
    }];
  }
  
  if (degressAngle <= 27) {
    legend = [{
      zone: 2,
      name: '3, 2e, 2r',
    }, {
      zone: 1,
      name: '1',
    }];
  }

  if (degressAngle <= 20) {
    legend = [{
      zone: 3,
      name: '3, 2e',
    }, {
      zone: 2,
      name: '2r',
    }, {
      zone: 1,
      name: '1',
    }];
  }

  return createLegend(legend);
};
