export const apiField = 'attachment_type';

export enum ATTACHMENT_TYPES {
  STAND_2SS = 4,
  MINI_1SS = 5,
  WIDE_2SS = 6,
  KLOC = 7,
  KLOC_MINI = 8, 
  NOT_VERIFIED = 9,
  PM_ADJUST_SLOTTED = 10,
  CORRUBRACKET = 11,
}

export const configat = {
  'Standing Seam Metal': [
    {
      value: 4,
      name: 'STAND 2SS',
    },
    {
      value: 5,
      name: 'MINI 1SS',
    },
    {
      value: 6,
      name: 'WIDE 2SS',
    },
    {
      value: 7,
      name: 'KLOC 2SS',
    },
    {
      value: 8,
      name: 'KLOC MINI',
    },
    {
      value: 9,
      name: 'ROOF NOT VERIFIED',
    },
  ],
  'R-Panel Metal' : [
    {
      value: 10,
      name: 'PM ADJUST SLOTTED',
    },
  ],
  'Corrugated Metal' : [
    {
      value: 11,
      name: 'CORRUBRACKET 100T',
    },
  ],

};

export const getAttachmentTypeOptions = (roofTypeSelectedFlags, material_thickness) => {
  const [standingSeamSelected, rPanelSelected, corrugatedSelected] = roofTypeSelectedFlags;
  let data;
  const thicknessgroup1 = [1,2,3];
  const thicknessgroup2 = [1,2,3,5,6,7];
  const thicknessgroup3 = [1,2,3,4,5];
  
  if (standingSeamSelected) {
    if (thicknessgroup1.includes(material_thickness)) {
      data = configat['Standing Seam Metal'].slice(0,5);
    }
    else if (material_thickness === 4) {
      data = [
        {
          value: 4,
          name: 'STAND 2SS',
        },
        {
          value: 6,
          name: 'WIDE 2SS',
        },
      ];
    } else if (material_thickness === 5) {
      data = [
        {
          value: 9,
          name: 'ROOF NOT VERIFIED',
        },
      ];
    }
  } else if (rPanelSelected) {
    if (thicknessgroup2.includes(material_thickness)) {
      data = configat['R-Panel Metal'];
    }

  } else if (corrugatedSelected) {
    if (thicknessgroup3.includes(material_thickness)) {
      data = configat['Corrugated Metal'];
    }

  }
  if(!data) {
    return [];
  }
  return data;
};

const config = (roofTypeSelectedFlags, material_thickness) => {
  return {
    type: "Select",
    label: "attachmentType",
    apiField,
    data: getAttachmentTypeOptions(roofTypeSelectedFlags, material_thickness),
    tooltip: () => `Attachment Type`,
  };
};

export default config;
