import { BALLAST_GENERATING_SUCCESS, BALLAST_GENERATING_FAILED } from './engineeringBallastMapsActions';
import { EngineeringBallastMapsActionsConstants } from './engineeringBallastMapsActionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';

export function generateBallastMap(action$) {
  return action$.ofType(EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_START)
    .switchMap(action => {
      return ObservableAjax({
        takeUntil: action$.ofType(EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_START),
        onSuccess: BALLAST_GENERATING_SUCCESS,
        onError: BALLAST_GENERATING_FAILED,
        socketName: 'project',
        onSocketPending: 'GENERATING BALLAST MAP WAITING IN A QUEUE...',
        onSocketStart: 'GENERATING BALLAST MAP...',
        onSocketFailure: 'GENERATING BALLAST MAP FAILURE',
        link: hitApi('get', `api/v1/reports/generate/${action.payload.projectId}/layout/${action.payload.generatedArrayId}/${action.payload.generatedLayoutType}/${action.payload.unitsType}`),
      });
    });
}
