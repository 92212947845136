import { MeasurementToolActionTypes } from "./measurementToolActionsConstants";

export function TURN_OFF_MEASUREMENT_TOOL() {
  return {
    type: MeasurementToolActionTypes.TURN_OFF_MEASUREMENT_TOOL,
  };
}

export function TURN_ON_MEASUREMENT_TOOL() {
  return {
    type: MeasurementToolActionTypes.TURN_ON_MEASUREMENT_TOOL,
  };
}

export function ADD_STARTING_POINT(coords: pixelPoint) {
  return {
    type: MeasurementToolActionTypes.ADD_STARTING_POINT,
    payload: {
      x: coords.x,
      y: coords.y,
    },
  };
}

export function ADD_ENDING_POINT(coords: pixelPoint) {
  return {
    type: MeasurementToolActionTypes.ADD_ENDING_POINT,
    payload: {
      x: coords.x,
      y: coords.y,
    },
  };
}

export function START_NEW_MEASUREMENT() {
  return {
    type: MeasurementToolActionTypes.START_NEW_MEASUREMENT,
  };
}
