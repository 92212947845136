import keyMirror from 'keymirror';

export const ReportProblemActionTypes = keyMirror({
  SHOW_REPORT_PROBLEM_FORM: undefined,
  HIDE_REPORT_PROBLEM_FORM: undefined,
  SEND_REPORT_PROBLEM_FORM: undefined,
  SUCCESS_SEND_REPORT_PROBLEM_FORM: undefined,
  ERROR_SEND_REPORT_PROBLEM_FORM: undefined,
  CLEAR_REPORT_PROBLEM_ERRORS: undefined,
  RESET_PROBLEM_FORM: undefined,
  START_REPORT_FORM_LOADING: undefined,
  SET_PROBLEM_FORM_DATA: undefined,
  SHOW_IMAGE: undefined,
  SELECT_TYPE_OF_CONTACT_SUPPORT: undefined,
});
