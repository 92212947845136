import React from 'react';
import { MIDDLE_ROOF_COLOR, NEAR_ONE_EDGE_COLOR, ON_THE_CORNER_COLOR, MIDDLE_ROOF_PRIM_COLOR } from '__editor/panelsEditor/models/utils/panelColor';
import { 
  renderLegendOptionName, 
  renderLegendHeader, 
  renderZoneInLegendASCE716,
  renderLegendSubHeader,
} from './legends';

export const renderAscenderLegend = () => {
  return (
    <div className="roof-zones-legend">
      {renderLegendHeader('Zones: ')}
      {renderLegendSubHeader('Non-elevated / Elevated')}
      <div className="legend">
        {renderZoneInLegendASCE716(MIDDLE_ROOF_PRIM_COLOR)}
        {renderLegendOptionName('Zone 1\'')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE716(MIDDLE_ROOF_COLOR)}
        {renderLegendOptionName('Zone 1')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE716(NEAR_ONE_EDGE_COLOR)}
        {renderLegendOptionName('Zone 2')}
      </div>
      <div className="legend">
        {renderZoneInLegendASCE716(ON_THE_CORNER_COLOR)}
        {renderLegendOptionName('Zone 3')}
      </div>
    </div>
  );
};