export function checkLineIntersection(line1, line2) {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    

  const line1StartX = line1[0];
  const line1StartY = line1[1];
  const line1EndX = line1[2]; 
  const line1EndY = line1[3];
  const line2StartX = line2[0];
  const line2StartY = line2[1];
  const line2EndX = line2[2];
  const line2EndY = line2[3];

  let denominator = 0; 
  let a = 0;
  let b = 0; 
  let numerator1 = 0; 
  let numerator2 = 0;
    
  const result = {
    x: null,
    y: null,
    onLine1: false,
    onLine2: false,
  };
  denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY));
  if (denominator === 0) {
    return result;
  }
  a = line1StartY - line2StartY;
  b = line1StartX - line2StartX;
  numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
  numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);
  a = numerator1 / denominator;
  b = numerator2 / denominator;
  
    // if we cast these lines infinitely in both directions, they intersect here:
  result.x = line1StartX + (a * (line1EndX - line1StartX));
  result.y = line1StartY + (a * (line1EndY - line1StartY));
  /*
        // it is worth noting that this should be the same as:
        x = line2StartX + (b * (line2EndX - line2StartX));
        y = line2StartX + (b * (line2EndY - line2StartY));
        */
    // if line1 is a segment and line2 is infinite, they intersect if:
  if (a > 0 && a < 1) {
    result.onLine1 = true;
  }
    // if line2 is a segment and line1 is infinite, they intersect if:
  if (b > 0 && b < 1) {
    result.onLine2 = true;
  }
    // if line1 and line2 are segments, they intersect if both of the above are true
  return result;
}

export function drawdash(x0, y0, x1, y1, lineWidth) {
  const lineColor = 0x00000;
  const dashed = new PIXI.Graphics();
  dashed.lineStyle(1, lineColor, 1); // linewidth,color,alpha
  dashed.moveTo(0, 0);
  dashed.lineTo(lineWidth, 0);
  dashed.moveTo(lineWidth * 1.5, 0);
  dashed.lineTo(lineWidth * 2, 0);
  dashed.id = 'dashedLine';
  const dashedtexture = dashed.generateCanvasTexture(2, 4);
  const linelength = Math.pow(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2), 0.5);
  const tilingSprite = new PIXI.extras.TilingSprite(dashedtexture, linelength, lineWidth);
  tilingSprite.x = x0;
  tilingSprite.y = y0;
  tilingSprite.rotation = angle(x0, y0, x1, y1) * Math.PI / 180;
  tilingSprite.zIndex = 1000000000;
  return tilingSprite;
  
  function angle(x0, y0, x1, y1) {
    const diff_x = Math.abs(x1 - x0);
    const diff_y = Math.abs(y1 - y0);
    let cita;
    if (x1 > x0) {
      if (y1 > y0) {
        cita = 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
      } else {
        if (y1 < y0) {
          cita = -360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
        } else {
          cita = 0;
        }
      }
    } else {
      if (x1 < x0) {
        if (y1 > y0) {
          cita = 180 - 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
        } else {
          if (y1 < y0) {
            cita = 180 + 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
          } else {
            cita = 180;
          }
        }
      } else {
        if (y1 > y0) {
          cita = 90;
        } else {
          if (y1 < y0) {
            cita = -90;
          } else {
            cita = 0;
          }
        }
      }
    }
    return cita;
  }
}

  
