import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';
import { feetsToMeters } from '__common/calculations/feetsToMeters';

export default () => {
  const { userPreferences: { rafter_spacing_inches } } = state();
  const ascenderconfig = {
    zipcode: '',
    building_code: 3,
    wind_speed: 186, //kmph
    snow_load: 0,
    elevation: Number(Math.round(feetsToMeters(129))),
    wind_exposure: 'B',
    roof_type: 1,  
    rail_direction: 'EW',
    rafter_spacing_inches: rafter_spacing_inches || 24,
    client_name: '',
    building_type: gable.value,
    rail_type:9,
    risk_category: 2,
    seismic_s1:1,
    seismic_ss: 1,
    soil_class: 4,
    rail_finish: "AL",
    structure_type: 1,
    rail_length_for_thermal_gap: null,
    allow_thermal_gap: 0,
    structure_type_info: [
      {structure_type: 1, tilt: 10, preferred_span: null},
      {structure_type: 2, tilt: 10, preferred_span: null},
      {structure_type: 3, tilt: 10, preferred_span: null},
      {structure_type: 4, tilt: 10, preferred_span: null},
    ],
    mid_clamps_choices: 3,
    end_clamps_choices: 3,
    lug_choice: 1,
  };
  return ascenderconfig;
};
