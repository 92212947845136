import React from 'react';
import { connect } from 'react-redux';
import { FETCH_STATIC } from 'actions';

type Props = {
  dispatch: Function,
  title: string,
  isStaff: boolean,
  projects: any[],
};

class YourProjectsHeader extends React.Component<Props, {}> {
  downloadProjectList = () => {
    const { dispatch } = this.props;
    dispatch(FETCH_STATIC('projectsList.csv', 'text/csv', 'download/user_projects'));
  }

  downloadModuleDb = () => {
    const { dispatch } = this.props;
    dispatch(FETCH_STATIC('moduleDb.csv', 'text/csv', 'download/models_db'));
  }

  renderDownloadProjectListButton = () => {
    if (this.props.projects.length === 0) {
      return null;
    }

    return (
      <div className="download-link download-projects" onClick={this.downloadProjectList}>
        <i className="md-icon material-icons md-text--inherit">file_download</i>
        DOWNLOAD PROJECT LIST
      </div>
    );
  }

  renderDownloadModuleDB = () => {
    if (!this.props.isStaff) {
      return null;
    }
    
    return (
      <div className="download-link download-module-db" onClick={this.downloadModuleDb}>
        <i className="md-icon material-icons md-text--inherit">file_download</i>
        DOWNLOAD MODULE DB
      </div>
    );
  }

  render() {
    const { title } = this.props;
    return (
      <div className="pages-header">
        <div className="title">
          {title}
        </div>
          <div className="download-links">
            {this.renderDownloadProjectListButton()}
            {this.renderDownloadModuleDB()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (appState: appState) => {
  return {
    isStaff: appState.user.isStaff,
    projects: appState.yourProjects.data,
  };
};

export default connect(mapStateToProps)(YourProjectsHeader);
