export default {
  SM: {
    preferred_span: 72,
    spansProductId: 9,
    tiltedRoof: "1/12",
    rowSpacing: 1,
    columnSpacing: 1,
    autofillRoofZones: true,
  },
  SMTILTPR: {
    preferred_span: 72,
    spansProductId: 30,
    tiltedRoof: "---",
    rowSpacing: 8,
    columnSpacing: 1,
    autofillRoofZones: true,
  },
  SMTilt: {
    preferred_span: 72,
    spansProductId: 9,
    tiltedRoof: "---",
    tiltedModule: 0,
    rowSpacing: 1,
    columnSpacing: 1,
    autofillRoofZones: true,
  },
  Ascender: {
    preferred_span: 2,
    spansProductId: 29,
    tiltedModule: 0,
    rowSpacing: 1,
    columnSpacing: 0.50,  // need to be updated according to mid clamp selection 
    autofillRoofZones: true,
    tableRowSpacing: 12,
  },
  SfmEnhanced: {
    preferred_span: 72,
    rowSpacing: 1,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  MetalX: {
    preferred_span: 72,
    rowSpacing: 1,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  SF: {
    preferred_span: 72,
    rowSpacing: 1,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  RM5: {
    preferred_span: null,
    spansProductId: null,
    rowSpacing: 7.5,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  RM10: {
    preferred_span: null,
    spansProductId: null,
    rowSpacing: 19.5,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  RM10Evolution: {
    preferred_span: null,
    spansProductId: null,
    rowSpacing: 13,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  RMGridFlex: {
    preferred_span: null,
    spansProductId: null,
    rowSpacing: 8,
    columnSpacing: 0.25,
    tiltedRoof: '0',
    autofillRoofZones: true,
  },
  RMGridFlex10: {
    preferred_span: null,
    spansProductId: null,
    rowSpacing: 10,
    columnSpacing: 0.25,
    autofillRoofZones: true,
  },
  RMDT: {
    preferred_span: null,
    spansProductId: null,
    rowSpacing: 0.25,
    columnSpacing: 8.63,
    autofillRoofZones: true,
  },
  GFT: {
    preferred_span: 72,
    spansProductId: 9,
    tiltedModule: 0,
    rowSpacing: 1,
    columnSpacing: 1,
    autofillRoofZones: true,
    tableRowSpacing: 12,
  },
  ULA: {
    preferred_span: 72,
    spansProductId: 9,
    tiltedModule: 0,
    rowSpacing: 1,
    columnSpacing: 1,
    autofillRoofZones: true,
    tableRowSpacing: 12,
  },
  ecoFoot2Plus: {
    preferred_span: null,
    spansProductId: null,
    tiltedRoof: '1',
    rowSpacing: 18.9,
    columnSpacing: 0.50,
    autofillRoofZones: true,
  },
  NxtHorizon: {
    preferred_span: 72,
    spansProductId: 25,
    // tiltedRoof: "1/12",
    tiltedRoof: "---",
    rowSpacing: 0.5,
    columnSpacing: 0.5,
    autofillRoofZones: true,
  },
};
