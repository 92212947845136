import { createReducer } from '__common/utils/helpers';
import { ModuleSelectorActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';
import { SET_MODEL_DATA_HAS_ERROR_ACTION } from './moduleSelectorActions';
import { CUSTOM_MANUFACTURING_ID, CUSTOM_MODEL_ID } from '__common/constants/modules';
import _ from 'lodash';
import { isAscender, isCanadianChangesProduct, isSolarMount } from '__common/constants/products';
import { InputUnit, isCustomaryUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { convertModelData } from './moduleSelector';

export const ModuleSelectorState: moduleSelectorState = {
  selectedMfgId: null,
  selectedModelId: null,
  mfgs: [],
  mfgsLoading: false,
  mfgsError: false,
  mfgsReady: false,
  models: [],
  modelsLoading: false,
  modelsError: false,
  modelsReady: false,
  ready: false,
  modelData: {},
  modelDataError: false,
  allInLoading: false,
  sameDims: {},
};

export default {
  moduleSelector: createReducer(ModuleSelectorState, {
    [ModuleSelectorActionTypes.FETCH_MFGS_AND_MODELS_REQUEST](state) {
      return { ...state, allInLoading: true };
    },
    [ModuleSelectorActionTypes.SET_MFG](state, action) {
      return { ...state, selectedMfgId: action.payload.mfgId, selectedModelId: '' };
    },
    [ModuleSelectorActionTypes.FETCH_MFGS_SUCCESS](state: moduleSelectorState, action: action & { payload: { mfgs: mfg[] } }) {
      return { ...state, mfgsLoading: false, mfgsReady: true, mfgs: action.payload.mfgs };
    },
    [ModuleSelectorActionTypes.FETCH_MFGS_MODELS_SUCCESS](state: moduleSelectorState, action: action & { payload: { model: panelModel[], models: panelModel[] } }) {
      return { ...state, modelsLoading: false, modelsReady: true, models: action.payload.model || action.payload.models, allInLoading: false, selectedModelId: null };
    },
    [ModuleSelectorActionTypes.RESET_MODULE_SELECTOR]() {
      return {
        selectedMfgId: null,
        selectedModelId: null,
        mfgs: [],
        mfgsLoading: false,
        mfgsError: false,
        mfgsReady: false,
        models: [],
        modelsLoading: false,
        modelsError: false,
        modelsReady: false,
        ready: false,
        modelData: {},
        allInLoading: false,
        sameDims: {},
      };
    },
    [ModuleSelectorActionTypes.SET_CUSTOM_VALUE](state, action) {
      const { field, value } = action.payload;
      const modelData = state.modelData;

      if (field === 'thicknessmm') {
        modelData['thickness'] = mmToInches(value).toFixed(2);
      }

      if (field === 'thickness') {
        modelData['thicknessmm'] = inchestToMm(value).toFixed(2);
      }
      modelData[field] = value;
      return {
        ...state,
        modelData,
        selectedMfgId: CUSTOM_MANUFACTURING_ID,
      };
    },
    [ModuleSelectorActionTypes.POPULATE_MODELS_BY_MODULE](state: moduleSelectorState, action) {
      const { mfg } = action.payload;
      return { ...state, selectedMfgId: mfg, modelsLoading: true, modelsReady: false, modelsError: false, modelData: {} };
    },
    [ModuleSelectorActionTypes.FETCH_MFG_MODELS_REQUEST](state: moduleSelectorState) {
      return { ...state, modelsLoading: true, modelsReady: false, modelsError: false };
    },
    [ModuleSelectorActionTypes.SET_MODEL](state: moduleSelectorState, action: action & { payload: { selectedModelId: number | string, modelId: number, customModel: panelsModel, productId: number, inputUnit?: number, toggled?: boolean } }) {

      const { productId, inputUnit, toggled } = action.payload;

      let modelData;

      let selectedModelIdMissing = false;
      if (state.models.length > 0 && state.models.filter(model => model.id === action.payload.modelId).length === 0) {
        selectedModelIdMissing = true;
      }
      
      if (action.payload.customModel) {
        modelData = action.payload.customModel;
        let modelDataClone = _.cloneDeep(modelData);
        if ((isSolarMount(productId) || isCanadianChangesProduct(productId)) && ((isMetricUnit(inputUnit) && !toggled) || isCustomaryUnit(inputUnit) && toggled) && 'width' in modelDataClone) {
          modelDataClone = convertModelData(modelDataClone, InputUnit.CUSTOMARY);
        }
        modelData = modelDataClone;
      } else if (state.selectedMfgId === CUSTOM_MANUFACTURING_ID && action.payload.modelId === CUSTOM_MODEL_ID) {
        let modelDataClone = _.cloneDeep(state.modelData);
        modelDataClone.id = CUSTOM_MODEL_ID;
        modelDataClone.module = CUSTOM_MANUFACTURING_ID;
        if ((isAscender(productId) || (isSolarMount(productId) || isCanadianChangesProduct(productId)) && ((isMetricUnit(inputUnit) && !toggled) || isCustomaryUnit(inputUnit) && toggled)) && 'width' in modelDataClone) {
          modelDataClone = convertModelData(modelDataClone, InputUnit.CUSTOMARY);
        }
        modelData = modelDataClone;
      } else {
        if (selectedModelIdMissing) {
          modelData = state.models[0];
        }
        else {
          modelData = state.models.find(model => model.id === action.payload.modelId);
        }
      }

      if (modelData) {
        modelData.id = modelData.model ? modelData.model : modelData.id;
        if (modelData.id && !modelData.thicknessmm) {
          const thicknessmm = state.models.find(model => model.id === modelData.id)?.thicknessmm;
          if (thicknessmm) {
            modelData.thicknessmm = thicknessmm;
          } else {
            modelData.thicknessmm = inchestToMm(modelData.thickness).toFixed(2);
          }
        }
        let modelDataClone = _.cloneDeep(modelData);
        if ((isAscender(productId) || ((isSolarMount(productId) || isCanadianChangesProduct(productId)) && isMetricUnit(inputUnit))) && 'width' in modelDataClone) {
          modelDataClone = convertModelData(modelDataClone, InputUnit.METRIC);
        }        
        return { ...state, selectedModelId: selectedModelIdMissing ? modelData.id : action.payload.modelId, modelData: modelDataClone};
      }

      return { ...state };
    },
    [ModuleSelectorActionTypes.SET_SAME_PANEL_DIMS](state: moduleSelectorState, action: action & { payload: { sameDims: { width?: number, length?: number } } }) {
      const { sameDims } = action.payload;
      return { ...state, sameDims };
    },
    [ModuleSelectorActionTypes.SET_MODEL_DATA_HAS_ERROR](state: moduleSelectorState, action: Action<SET_MODEL_DATA_HAS_ERROR_ACTION>): moduleSelectorState {
      return {
        ...state,
        modelDataError: action.payload.hasError,
      };
    },
  }),
};

function inchestToMm(val: number | string) {
  return Number((Number(val) * 25.4).toFixed(0)) || 0;
}

function mmToInches(val: number | string) {
  return (Number(val) / 25.4) || 0;
}
