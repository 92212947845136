import autobind from 'autobind-decorator';
import React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { trunOffScaleTool, turnOnScaleTool } from '__editor/panelsEditor/components/scale/scale';

type Props = {
  dispatch: Function,
  isScaleToolEnable: boolean,
};

class ScaleButton extends React.Component<Props> {
  @autobind
  toggleScaleTool() {
    const { isScaleToolEnable } = this.props;
    
    if (!isScaleToolEnable) {
      return turnOnScaleTool();
    }

    return trunOffScaleTool();
  }

  render() {
    const { isScaleToolEnable } = this.props;
    return (
      <Button flat={true} className="roofs-selector-button" onClick={this.toggleScaleTool} tooltipLabel="Scale">
        <SVG className={isScaleToolEnable ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_draw.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    isScaleToolEnable: state.scale.enabled,
  };
}

export default connect(mapStateToProps)(ScaleButton);

