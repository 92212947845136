import { RoofsSelectorMapActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';

export interface SET_MAP_CENTER_ACTION {
  mapCenterCoords: cordPoint;
}

export function SET_MAP_CENTER(mapCenterCoords: { lat: number, lng: number }): Action<SET_MAP_CENTER_ACTION> {
  return {
    type: RoofsSelectorMapActionTypes.SET_MAP_CENTER,
    payload: {
      mapCenterCoords,
    },
  };
}

export interface FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG_ACTION {
  lat: number;
  lng: number;
  building_code: number;
  productId: number;
  risk_category: number;
}

export function FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG(
  lat: number, 
  lng: number, 
  building_code: number, 
  productId: number,
  risk_category: number,
): Action<FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG_ACTION> {
  return {
    type: RoofsSelectorMapActionTypes.FETCH_ZIP_CODE_AND_ADDRESS_BY_LAT_LNG,
    payload: {
      lat,
      lng,
      building_code,
      productId,
      risk_category,
    },
  };
}

export interface SET_LOCATION_ACTION {
  location: string;
}

export function SET_LOCATION(location: string): Action<SET_LOCATION_ACTION> {
  return {
    type: RoofsSelectorMapActionTypes.SET_LOCATION,
    payload: {
      location,
    },
  };
}

export interface SET_MAP_ZOOM_ACTION {
  zoom: number;
}

export function SET_MAP_ZOOM(zoom: number): Action<SET_MAP_ZOOM_ACTION> {
  return {
    type: RoofsSelectorMapActionTypes.SET_MAP_ZOOM,
    payload: {
      zoom,
    },
  };
}

export interface GET_ADDRESS_BY_ZIP_ACTION {
  zipcode: string;
  cb: Function;
}

export function GET_ADDRESS_BY_ZIP(zipcode: string, cb: Function): Action<GET_ADDRESS_BY_ZIP_ACTION> {
  return {
    type: RoofsSelectorMapActionTypes.GET_ADDRESS_BY_ZIP,
    payload: {
      zipcode,
      cb,
    },
  };
}
