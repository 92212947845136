export default () => `
  <div>
    <div class="content">
        Select between module mid clamps. Larger gaps between modules may reduce wind loads.
    </div>
    <div class="sub-header"><strong>Pro Clamps:</strong> 1 (mid) and 1/4 (end) in gap</div>
    <div class="sub-header"><strong>Standard Clamps:</strong> 1/4 (mid) and 3/2 (end) in gap</div>
    <div class="sub-header"><strong>Universal AF Clamps:</strong> 1/2 (mid) and 3/4 (end) in gap</div>
  </div>
`;
