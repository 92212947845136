import { isRMGridFlex, isRmGridflex10 } from "__common/constants/products";

export const apiField = 'friction_factor';

const RM_GRIDFLEX_FRICTION_COEFFICIENTS = {
  min: 0.38,
  max: 0.9
}
const RM_GRIDFLEX_10_FRICTION_COEFFICIENTS = {
  min: 0.4,
  max: 0.84
}

export const getLimitsOfFrictionCoefficient = (productId: number) => {
  if (isRMGridFlex(productId)) {
    return RM_GRIDFLEX_FRICTION_COEFFICIENTS
  } else if (isRmGridflex10(productId)) {
    return RM_GRIDFLEX_10_FRICTION_COEFFICIENTS
  } else {
    return {
      min:0.38,
      max:0.9
    }
  }
}
function config(productId){
  return {
    label: 'frictionFactor',
    type: 'Number',
    apiField,
    missing: undefined,
    min: getLimitsOfFrictionCoefficient(productId).min,
    max: getLimitsOfFrictionCoefficient(productId).max,
    step: 0.1
  }
};

export default config;