import * as React from 'react';
import App from '_containers/App';
import ConnectedIntlProvider from '__common/providers/ConnectedIntlProvider';
import { Provider } from 'react-redux';

type Props = {
  store: any,
};

export default class Root extends React.Component<Props, {}> {
  /* istanbul ignore next */
  render() {
    const { store } = this.props;
    return (
        <Provider store={store}>
          <ConnectedIntlProvider>
            <App />
          </ConnectedIntlProvider>
        </Provider>
    );
  }
}
