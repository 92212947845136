import { RoofExposureOption } from '../fields/types/roofExposure';

interface RoofExposureTooltipConfig {
  inTownEnabled?: boolean;
  openTerrainEnabled?: boolean;
  openWaterEnabled?: boolean;
}

export default (config?: RoofExposureTooltipConfig) => {
  const defaultConfig: RoofExposureTooltipConfig = { 
    inTownEnabled: true,
    openTerrainEnabled: true,
    openWaterEnabled: true,
  };

  const tooltipConfig = {
    ...defaultConfig,
    ...config,
  };

  return `
  <div>
    ${tooltipConfig.openTerrainEnabled ? getOpenTerrainOption() : ''}
    ${tooltipConfig.inTownEnabled ? getInTownOption() : ''}
    ${tooltipConfig.openWaterEnabled ? getOpenWaterOption() : ''}
  `;
};

const getInTownOption = () => `
  <div class="sub-header">
    <strong>Partially Exposed</strong> (${RoofExposureOption.partiallyExposed.name}):
  </div>
  <div class="content">
    All roofs except as indicated in Fully exposed & sheltered category
  </div>
`;

const getOpenTerrainOption = () => `
  <div class="sub-header">
    <strong>Fully Exposed</strong> (${RoofExposureOption.fullyExposed.name}):
  </div>
  <div class="content">
    Roofs exposed on all sides with no shelter afforded by
    terrain, higher structures, or trees. Roofs that contain several large pieces of
    mechanical equipment, parapets that extend above the height of the balanced
    snow load , or other obstructions are not in this category.
  </div>
`;

const getOpenWaterOption = () => `
  <div class="sub-header">
    <strong>Sheltered</strong> (${RoofExposureOption.sheltered.name}):
  </div>
  <div class="content">
    Roofs located tight in among conifers that qualify as obstructions
  </div>
`;
