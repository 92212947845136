import * as React from 'react';
import FogOfWar from '__common/components/FogOfWar';
import ProjectConfigurationComponent from 'projectDesign/components/projectConfiguration/projectConfigurationComponent';
import RoofsSelectorControlPanel from '__editor/components/roofsSelector/components/roofsSelectorControlPanel/roofsSelectorControlPanel';
import RoofsSelectorMap from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMapComponent';
import { footerHeight, headerHeight } from '__common/constants/layout_css_dims';
import { setEditorDims } from './components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { SET_MAP_STATE } from 'actions';
import { Button } from 'react-md';
import SVG from 'react-inlinesvg';


export default abstract class RoofsSelector<P extends roofsSelectorComponentState> extends React.Component<P, {previewStatus: boolean}> {
  fpsmeter: HTMLDivElement;
  outerDiv: HTMLDivElement;
  canvas: HTMLDivElement;

  abstract initMap(): void;
  constructor(props: any) {
    super(props);
    this.state = {
      previewStatus: true,
    }
  }
  shouldComponentUpdate(prevState: roofsSelectorComponentState) {
    const { previewMode, advanceRoofSelecting, measurementTool, moduleSelector, roofsSelector } = this.props;
    return previewMode ||
      advanceRoofSelecting.roofPoints !== prevState.advanceRoofSelecting.roofPoints ||
      advanceRoofSelecting.lastPoint !== prevState.advanceRoofSelecting.lastPoint ||
      measurementTool.startingPoint !== prevState.measurementTool.startingPoint ||
      measurementTool.endingPoint !== prevState.measurementTool.endingPoint ||
      prevState.roofsSelector.mapTypeSelected !== roofsSelector.mapTypeSelected ||
      prevState.projectConfiguration.projectEnvConfig !== this.props.projectConfiguration.projectEnvConfig ||
      this.props.router !== prevState.router ||
      moduleSelector !== prevState.moduleSelector;
  }

  componentDidMount() {
    this.mountAction();
  }

  mountAction = () => {
    const { previewMode, projectId, dispatch } = this.props;

    this.initMap();

    if (projectId && projectId.length) {
      dispatch(SET_MAP_STATE(true));
    }

    if (!previewMode) {
      setEditorDims(this.outerDiv.offsetWidth, this.outerDiv.offsetHeight);
    }

    window.addEventListener('resize', () => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    window.addEventListener('resize', null);
  }

  renderSelectedRoofPreview() {
    return (
      <div>
        <div className='rerender-selected-roof-preview' id="map" ref={(c) => { this.canvas = c; }} />
        <Button className='preview-close-btn' id='close-preview' onClick={() => this.closePreview()} tooltipLabel='Close Map Preview' tooltipPosition="left">
          <SVG src={require('assets/media/icons/minus_icon.svg')} />
        </Button>
      </div>
    );
  }

  openPreview() {
    return <Button className='preview-open-btn' tooltipLabel= 'Open Map Preview' tooltipPosition="left">
      <span onClick={() => this.reRenderPreview()}>
        <SVG src={require('assets/media/icons/plus_icon.svg')} />
      </span>
    </Button>
  }
  reRenderPreview = () => {
    this.setState({ previewStatus: true })
    setTimeout(() => { this.mountAction(); }, 100);

  }

  closePreview() {
    this.setState({ previewStatus: false });
  }

  renderRoofSelector() {
    const { dispatch, productId, projectConfiguration: { projectEnvConfig }, roofsSelector: { mapTypeSelected } } = this.props;
    const offsetHeight = document.body ? document.body.offsetHeight : 0;

    return (
      <div className="roofs-selector-content">
        <FogOfWar />
        {/* <ProjectConfigurationComponent style={{ width: '50px', height: `${offsetHeight - headerHeight - footerHeight}px`, flex: '0 0 auto', overflowY: 'auto' }} productId={productId} /> */}
        <ProjectConfigurationComponent
          productId={productId}
          initData={projectEnvConfig}
          fieldsFullWidth={true}
          centerFields={true}
        />
        <div id="roofsSelector">
          <div
            ref={(outerDiv) => {
              this.outerDiv = outerDiv;
            }}
            id="project_info"
          >
            {!mapTypeSelected && <RoofsSelectorMap dispatch={dispatch} />}
            <RoofsSelectorControlPanel productId={productId} />
            <div style={{ height: `${offsetHeight - headerHeight - footerHeight}px` }} id="map" ref={(c) => { this.canvas = c; }} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { previewMode } = this.props;

    if (previewMode) {
      return (
        <>
          {this.state.previewStatus ? this.renderSelectedRoofPreview() : this.openPreview()}
        </>
      )
    }
    return this.renderRoofSelector();
  }
}

