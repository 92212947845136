/* istanbul ignore else */
import RootProd from './Root.prod';
import RootDev from './Root.dev';

let rootToExport;

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
  rootToExport = RootProd;
} else {
  rootToExport = RootDev;
}

export default rootToExport;
