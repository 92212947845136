export const apiField = 'rafter_spacing_inches';

export const makeConfig = (label: string = 'rafterSpacing', min: number = 1, max: number = 144) => {
  return {
    type: "Number",
    label,
    apiField,
    min,
    max,
    missing: undefined, 
  }
}

const config = makeConfig();

export const rafterSpacingMetric = makeConfig("rafterSpacingMetric",  2, 366)

export default config;
