import Big from 'big.js';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { isAscender, isRMDT } from '__common/constants/products';
import { state } from '__common/store';
import { _isRMFamily } from '__editor/panelsEditor/components/cursor/utils/snapToGridHelper';

export const recalculatePanelsPosition = (
  panels: panelInState[], 
  oldModel: { width: number, height: number }, 
  newModel: { width: number, height: number }, 
  metersPerPixel: number, 
  roofRowSpacing: number,
  productId?: number
) => {
  const { settings: { rowSpacing, columnSpacing } } = state();

  if (oldModel.width === newModel.width &&
    oldModel.height === newModel.height
  ) {
    return panels;
  }

  if (!newModel.height || !newModel.width) {
    return panels;
  }

  const {
    oldWidthPx,
    oldHeightPx,
    xSpacing,
    ySpacing,
    xPanelProportion,
    yPanelProportion,
    part_of_spacing_x,
    part_of_spacing_y,
    newWidthPx,
    newHeightPx,
  } = getCalculationsData(oldModel, newModel, metersPerPixel, roofRowSpacing ? roofRowSpacing : rowSpacing, columnSpacing, panels, productId);

  let min_y = getMinPanelY(panels);

  let min_x = getMinPanelX(panels);

  min_y = new Big(min_y.toString());
  min_x = new Big(min_x.toString());

  return panels.map(panel => {
    return changePanelsPositions(
      panel,
      min_x, min_y,
      oldWidthPx,
      oldHeightPx,
      newWidthPx,
      newHeightPx,
      xSpacing,
      ySpacing,
      xPanelProportion,
      yPanelProportion,
      part_of_spacing_x,
      part_of_spacing_y,
    );
  });
};

const alignPanelsToGrid = (panel_x, panel_y, min_x, min_y, newWidthPx, newHeightPx, xSpacing, ySpacing) => {

  // To be honest idk, but it is working like that for so long
  if (1 === 1) {
    return {
      panelX: panel_x,
      panelY: panel_y,
    };
  }

  const distanceFromStartGridPoint_X = panel_x.minus(min_x);
  const distanceFromStartGridPoint_Y = panel_y.minus(min_y);

  const gridOffset_X = xSpacing.plus(newWidthPx);
  const gridOffset_Y = ySpacing.plus(newHeightPx);

  const panelOrder_X = distanceFromStartGridPoint_X.div(gridOffset_X).round();
  const panelOrder_Y = distanceFromStartGridPoint_Y.div(gridOffset_Y).round();

  const panelX = min_x.plus(panelOrder_X.times(gridOffset_X));
  const panelY = min_y.plus(panelOrder_Y.times(gridOffset_Y));

  return {
    panelX,
    panelY,
  };
};

const getMinPanelY = (panels: panelInState[]) => {
  let min_y = panels[0].y;
  for (let i = 0; i < panels.length; i++) {
    if (panels[i].y < min_y) {
      min_y = panels[i].y;
    }
  }

  return min_y;
};

const getMinPanelX = (panels: panelInState[]) => {
  let min_x = panels[0].x;

  for (let i = 0; i < panels.length; i++) {
    if (panels[i].x < min_x) {
      min_x = panels[i].x;
    }
  }

  return min_x;
};

const changePanelsPositions = (panel, min_x, min_y, oldWidthPx, oldHeightPx, newWidthPx, newHeightPx, xSpacing, ySpacing, xPanelProportion, yPanelProportion, part_of_spacing_x, part_of_spacing_y) => {
  const repositionedPanel = panel;

  let panel_x = new Big(panel.x.toString());
  let panel_y = new Big(panel.y.toString());

  const counter_x = (panel_x.minus(min_x)).div((oldWidthPx).plus(xSpacing));
  const counter_y = (panel_y.minus(min_y)).div((oldHeightPx).plus(ySpacing));

  panel_x = (panel_x.times(xPanelProportion)).minus(counter_x.times(part_of_spacing_x));
  panel_y = (panel_y.times(yPanelProportion)).minus(counter_y.times(part_of_spacing_y));

  const { panelX, panelY } = alignPanelsToGrid(panel_x, panel_y, min_x, min_y, newWidthPx, newHeightPx, xSpacing, ySpacing);

  panel_x = panelX;
  panel_y = panelY;

  repositionedPanel.width = Number(newWidthPx);
  repositionedPanel.height = Number(newHeightPx);
  repositionedPanel.x = Number(Number(panel_x).toFixed(8));
  repositionedPanel.y = Number(Number(panel_y).toFixed(8));

  return panel;
};

const getCalculationsData = (
  oldModel: { width: number, height: number }, 
  newModel: { width: number, height: number }, 
  metersPerPixel: number, 
  rowSpacing: number, 
  columnSpacing: number, 
  panels: panelInState[],
  productId: number,
) => {
  const isLandscape = panels[0].landscape;
  
  let oldPanelWidth: number;
  let oldPanelHeight: number;
  let newPanelWidth: number;
  let newPanelHeight: number;

  if (_isRMFamily() && !isRMDT(productId)) {
    oldPanelWidth = oldModel.height;
    oldPanelHeight = oldModel.width;
  
    newPanelWidth =  newModel.height;
    newPanelHeight = newModel.width;
  } else {
    oldPanelWidth = isLandscape ? oldModel.height : oldModel.width;
    oldPanelHeight = isLandscape ? oldModel.width : oldModel.height;
  
    newPanelWidth = isLandscape ? newModel.height : newModel.width;
    newPanelHeight = isLandscape ? newModel.width : newModel.height;
  }



  const mPerPixel = new Big(metersPerPixel.toString());
  const bigRowSpacing = new Big(rowSpacing.toString());
  const bigColumnSpacing = new Big(columnSpacing.toString());

  const oldWidth = new Big(oldPanelWidth.toString());
  const oldHeight = new Big(oldPanelHeight.toString());

  const newWidth = new Big(newPanelWidth.toString());
  const newHeight = new Big(newPanelHeight.toString());

  const ySpacing = bigRowSpacing.div(mPerPixel);
  const xSpacing = bigColumnSpacing.div(mPerPixel);

  const newWidthMeters = isAscender(productId) ?  cmsToMeters(newPanelWidth) : inchesToMeters(newPanelWidth);
  const newHeightMeters = isAscender(productId) ?  cmsToMeters(newPanelHeight) :  inchesToMeters(newPanelHeight);

  const newWidthPx = new Big(newWidthMeters.toString()).div(mPerPixel);
  const newHeightPx = new Big(newHeightMeters.toString()).div(mPerPixel);

  const oldWidthMeters =  isAscender(productId) ?  cmsToMeters(oldPanelWidth): inchesToMeters(oldPanelWidth);
  const oldHeightMeters =  isAscender(productId) ?  cmsToMeters(oldPanelHeight): inchesToMeters(oldPanelHeight);

  const oldWidthPx = new Big(oldWidthMeters.toString()).div(mPerPixel);
  const oldHeightPx = new Big(oldHeightMeters.toString()).div(mPerPixel);


  const xPanelProportion = (newWidth.div(oldWidth));
  const yPanelProportion = (newHeight.div(oldHeight));
  const xDifferenceProportion = (newWidth.minus(oldWidth)).div(oldWidth);
  const yDifferenceProportion = (newHeight.minus(oldHeight)).div(oldHeight);
  const part_of_spacing_x = (xSpacing.times(xDifferenceProportion));
  const part_of_spacing_y = (ySpacing.times(yDifferenceProportion));

  return {
    xSpacing,
    ySpacing,
    oldWidthPx,
    oldHeightPx,
    newWidthPx,
    newHeightPx,
    xPanelProportion,
    yPanelProportion,
    part_of_spacing_x,
    part_of_spacing_y,
  };
};
