import Polygon from 'polygon';
import { BACKGROUND_ZOOM_CHANGE} from 'actions';
import { convertToPixelPoints, getLeadEdge } from '__editor/panelsEditor/components/leadEdge/leadEdge';
import { dispatch, state } from '__common/store';
import { getAngle } from '__editor/panelsEditor/components/leadEdge/utils/backgroundRoofEdgesRotation';
import { getRoofEdgesPointsInPx } from '__editor/panelsEditor/components/background/background';
import { getScaleFromPitchRoof } from '../../tiltedRoof/tiltedRoof';
import { isRMGridFlex } from '__common/constants/products';

export function determineZoomLevel(width: number, height: number) {
  const { background: { selectedRoofId }, roofsSelector: { mapType }, drawingManager: { roofs }, projectConfiguration: { projectEnvConfig : { is_aurora_project } } } = state();
  if (!is_aurora_project && mapType === 'white') {
    return;
  }

  if (roofs && roofs[selectedRoofId]) {
    const roofEdges = roofs[selectedRoofId].coords;
    const cords = roofs[selectedRoofId].marker;

    const leadEdgeCords = getLeadEdge(selectedRoofId);
    const newZoomLevel = getZoomLevel(selectedRoofId, roofEdges, cords, leadEdgeCords, 18, width, height);
    
    dispatch(BACKGROUND_ZOOM_CHANGE(newZoomLevel));
  }
}

export function getZoomLevel(roofId: number, roofEdges, coords, leadEdgeCords: { lat: number, lng: number }[], zoom: number, width: number, height: number) {
  const { background: { cords }, projectConfiguration: { productId, projectEnvConfig : { is_aurora_project } } } = state();
  const roofCenter = coords || cords;
  const pixels = getRoofEdgesPointsInPx(roofEdges, roofCenter, zoom);
  const leadEdgeCordsPx = convertToPixelPoints(leadEdgeCords, roofCenter, zoom);
  const angle = getAngle(leadEdgeCordsPx, roofEdges, roofCenter, zoom);
  const newPoly = Polygon(pixels).rotate(angle);
  const bounds = newPoly.aabb();
  const ratio = getScaleFromPitchRoof(isRMGridFlex(productId) ? '7' : '12/12', productId, true);

  if (is_aurora_project) {
    return 18
  }

  if (bounds.h * ratio > height) {
    return 16;
  }

  if (bounds.h * ratio > height / 2.5) {
    return 17;
  }

  return 18;
}
