import { isPanelCollideWithObstructionsZones, checkObstructionZoneForPanels } from './obstructionsCollisions';
import { state } from '__common/store';

export const isPanelCollideWithObstructionsZonesInPanelEditor = (panel: panelInState) => {
  const { background: { selectedRoofId, metersPerPixel, bgXY: { x, y } }, projectConfiguration: { productId }, obstructions: {obstructions} } = state();
  return isPanelCollideWithObstructionsZones(panel, selectedRoofId, metersPerPixel, x, y, productId, obstructions);
};

export const checkObstructionZoneForPanelsInPanelEditor = (panels: panelInState[]) => {
  const { background: { selectedRoofId, metersPerPixel, bgXY: { x, y } } } = state();
  return checkObstructionZoneForPanels(panels, selectedRoofId, metersPerPixel, x, y);
};
