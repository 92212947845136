import { removeChild } from '__editor/panelsEditor/components/stage/stage';
import { tooltipCursor } from '__common/models/cursorTooltip';
import { state } from '__common/store';

export const drawTooltipCursorOnStage = (renderer: PIXI.WebGLRenderer | PIXI.CanvasRenderer, stage: PIXI.Container) => {
  const { tooltipCursor: { content, position } } = state();
  let tooltipPosition;

  stage.children = removeChild(stage.children, 'cursorTooltip');
  
  if (!content) {
    return;
  }

  if (position) {
    tooltipPosition = position;
  } else {
    tooltipPosition = getDefaultCursorPosition(renderer, stage);
  }

  const { x, y } = tooltipPosition;
  const tooltip = tooltipCursor(stage, x, y, content);
  stage.addChild(tooltip);
};

function getDefaultCursorPosition(renderer: PIXI.WebGLRenderer | PIXI.CanvasRenderer, stage: PIXI.Container) {
  const x = stage.toLocal(renderer.plugins.interaction.eventData.data.global).x;
  const y = stage.toLocal(renderer.plugins.interaction.eventData.data.global).y;

  return {
    x,
    y,
  };
}

