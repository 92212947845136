import * as React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import SVG from 'react-inlinesvg';
import autobind from 'autobind-decorator';
import { TURN_OFF_BANNERS, TURN_OFF_EDIT, TURN_OFF_ROOF_SELECTING, TURN_ON_MEASUREMENT_TOOL } from 'actions';
import { getMap, turnGoogleMapsOffMapMoveControls } from '__editor/googleMapsRoofsSelector/components/map/map';
import { clearGoogleMapsSelection } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { turnBingMapsOffMapMoveControls } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { clearBingSelection } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { isBingMap, isBlankMap, isGoogleMap } from '__common/constants/map';

type Props = {
    measurementToolEnabled: boolean,
    dispatch: Function,
    mapType: string,
    productId: number,
  };
  

export default class MeasurementToolButton extends React.Component<Props, {}> {
    turnOffOtherModes() {
      const { mapType, dispatch } = this.props;
  
      dispatch(TURN_OFF_EDIT());
      dispatch(TURN_OFF_ROOF_SELECTING());
  
      if (isGoogleMap(mapType)) {
        turnGoogleMapsOffMapMoveControls();
        clearGoogleMapsSelection(); 
      }
  
      if (isBingMap(mapType)) {
        turnBingMapsOffMapMoveControls();
        clearBingSelection();
      }
    }

    changeCursor() {
      const { mapType } = this.props;
  
      if (isGoogleMap(mapType)) {
        const map = getMap();
        map.setOptions({ draggableCursor: 'pointer' });
      }
    }

    @autobind
    turnOn() {

      const { dispatch, mapType } = this.props;

      if (!isBlankMap(mapType)) {
        this.turnOffOtherModes();
        dispatch(TURN_ON_MEASUREMENT_TOOL());
        dispatch(TURN_OFF_BANNERS());
        this.changeCursor();
      }
          
    }
    
    render() {
        const { measurementToolEnabled, mapType } = this.props;
        if (isBlankMap(mapType)) {
          return null;
        }
        return (
          <Button tooltipLabel="Measuring tool" flat={true} onClick={this.turnOn} className="roofs-selector-button">
            <SVG className={measurementToolEnabled ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_ruler.svg')} />
          </Button>
        );
      }
}