import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';

export default () => {
  const { userPreferences: { preferred_span, rafter_spacing_inches },  } = state();
  const gftConfig = {
    zipcode: '',
    building_code: 2,
    wind_speed: 110,
    snow_load: 5,
    elevation: 5499,
    wind_exposure: 'C',
    rail_finish: 'AL',
    risk_category: 1,
    foundation_type: 1,
    roof_type: 1,  
    preferred_span: preferred_span || 72,
    tilt: 20,
    rail_direction: 'EW',
    rafter_spacing_inches: rafter_spacing_inches || 24,
    client_name: '',
    building_type: gable.value,
    seismic_ss: 0,
    seismic_s1: 0,
    seismic_sds: 0.01,
    seismic_sd1: 0.01,
    long_transition_periods_tl: 4,
    tornado_speed: 0,
    longest_row_length: 1,
    shortest_row_length: 1,
    ice_thickness: 0,
    wind_on_ice: 0,
    rail_arrangement_type: 2,
    front_edge_height: 2,
    foundation_length: 12.5,
    frost_depth : 0,
    clamp_type : '04',
    additional_load_combination: 1.0,
    top_chord: 2,
  };
  return gftConfig;
};
