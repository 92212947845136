import { createReducer } from '__common/utils/helpers';
import { getRandomInterestingPos } from '__editor/components/roofsSelector/components/roofsSelectorMap/utils/get_random_interesting_pos';
import { RoofsSelectorMapActionTypes } from 'actionsConstants';
import { SET_LOCATION_ACTION, SET_MAP_ZOOM_ACTION, SET_MAP_CENTER_ACTION } from './roofsSelectorMapActions';
import { Action } from '__common/store/action';

const randomLocation = getRandomInterestingPos();
const { lat, lng } = randomLocation;
const { name } = randomLocation;

export const RoofsSelectorMapState: roofsSelectorMapState = {
  mapCenterCoords: { lat, lng },
  location: name,
  zoom: 18,
};

export default {
  roofsSelectorMap: createReducer(RoofsSelectorMapState, {
    [RoofsSelectorMapActionTypes.SET_MAP_CENTER](state: roofsSelectorMapState, action: Action<SET_MAP_CENTER_ACTION>): roofsSelectorMapState {
      return { ...state, ...action.payload };
    },
    [RoofsSelectorMapActionTypes.SET_LOCATION](state: roofsSelectorMapState, action: Action<SET_LOCATION_ACTION>): roofsSelectorMapState{
      return { ...state, ...action.payload };
    },
    [RoofsSelectorMapActionTypes.SET_MAP_ZOOM](state: roofsSelectorMapState, action: Action<SET_MAP_ZOOM_ACTION>): roofsSelectorMapState {
      const { zoom } = action.payload;
      return { ...state, zoom };
    },
  }),
};
