import document from 'global/document';
import { CHANGE_PANELS_SIZES, FLIP_CURSOR } from 'actions';
import { dispatch, state } from '__common/store';
import { trunOffScaleTool } from './components/scale/scale';
import { isRMFamily } from '__common/constants/products';
import Events from '__common/modules/Events';
import { deployObstructionDrawing } from '__editor/panelsEditor/components/obstructions/obstructions';
import { displacePanels } from './components/panels/utils/panelsManagment';
import {  REQUEST_ADD_PANELS, RESET_PANELS_ARRAY_FOR_MOVEMENT, RESTORE_PANELS_FOR_NO_MOVEMENT } from './actions';
import { clearMeasurements } from './components/measurementTool/measurementToolOnStage';
/*
16: SHIFT
27: ESC
82: R
*/

let pressedKeys = [];

export const initKeyboard = (isRm: boolean) => {
  document.addEventListener('keyup', onKeyUp);
  document.addEventListener('keydown', onKeyDown);
  Events.addEventListener('dblclick.roofsSelectorStage', () => {
    const { advanceRoofSelecting: { roofPoints } } = state();
    // const dblClick = true;
    if (roofPoints.length > 1) {
      deployObstructionDrawing();
    }
  });
};

export const terminateKeyboard = () => {
  pressedKeys = [];
  document.removeEventListener('keydown', onKeyUp);
  document.removeEventListener('keydown', onKeyDown);
};

export const isShiftPressed = () => {
  return pressedKeys[16];
};

function onKeyUp(key) {
  pressedKeys[key.keyCode] = false;
  pressedKeys = pressedKeys.map(keys => keys);
}

function onKeyDown(key) {
  const { rubberBand: { mode }, scale: { enabled }, projectConfiguration: { productId }, advanceRoofSelecting, measurementTool: { enabled : measurementToolenabled} } = state();
  pressedKeys[key.keyCode] = true;
  if (pressedKeys[82] && pressedKeys[16] && mode === 'draw' && !isRMFamily(productId)) {
    dispatch(FLIP_CURSOR());
    dispatch(CHANGE_PANELS_SIZES());
  }

  if (pressedKeys[27] && enabled) {
    trunOffScaleTool();
  }

  if (pressedKeys[27] && measurementToolenabled) {
    clearMeasurements();
  }

  if (pressedKeys[27] && advanceRoofSelecting) {
    deployObstructionDrawing(false);
  }
}

export const arrayDisplacementKeyboard = () => {

  const {panels : {panelsToBeMoved}, background: {moveArrayMode}} = state();
  
  if (moveArrayMode && panelsToBeMoved.length > 0) {
    Events.addEventListener('keyup.panelsDisplacement', (e) => {

      // keycode 13 denotes enter button in keyboard
      if (e.key === "Escape") { 
        dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      }
      else if(e.keyCode === 13){
        e.preventDefault();
        dispatch(RESET_PANELS_ARRAY_FOR_MOVEMENT());
        dispatch(REQUEST_ADD_PANELS([]));
    }

      let code = e.keyCode-37;
      const dirs = [0,1,2,3]
      if (dirs.includes(code)) {
        displacePanels(panelsToBeMoved[0].groupId, code);
      }
    });
  }
  else {
    Events.removeEventListener('keyup.panelsDisplacement');
  }
}