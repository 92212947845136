import { _isSMFamily, _isSF, _isSFM, _isRMFamily, _isGFT, _isULA, _isMetalX, _isEcoFoot2Plus, _isNHFamily, _isRM10, _isRM10Evolution, _isSMTiltPRFamily, _isAscenderFamily, _isNxtTilt } from './snapToGridHelper';
import { IPANEL_CORNER } from '../snapToGrid';

export function restrictedSnapping(nearestPoint: IPANEL_CORNER) {
  // PANEL points
  // |---------------------> X
  // | 1  2           3  4 
  // | ○  ○           ○  ○ 
  // | 
  // | ○  · - - - - - ·  ○
  // | 5  |           |  6
  // |    |           | 
  // |    |           | 
  // |    |           | 
  // | 7  |           |  8
  // | ○  · - - - - - ·  ○
  // | 
  // | ○  ○           ○  ○
  // | 9  10         11  12
  // V Y

  // CURSOR points
  // |---------------------> X
  // |    0           1
  // |    · - - - - - ·  
  // |    |           |  
  // |    |           | 
  // |    |     ↖     | 
  // |    |           | 
  // |    |           | 
  // |    · - - - - - · 
  // V    2           3
  // Y
  const { cursorCorner, panelCorner } = nearestPoint;

  if (_isSMFamily() || _isSF() || _isNHFamily() || _isSMTiltPRFamily() || _isNxtTilt() ) {
    return SMandSFSnappingPoint(cursorCorner, panelCorner);
  }

  if (_isSFM() || _isMetalX()) {
    return SMandSFSnappingPoint(cursorCorner, panelCorner);
  }

  if(_isEcoFoot2Plus() || _isRM10() || _isRM10Evolution()) {
    return EcoFoot2PlusSnappingPoint(cursorCorner, panelCorner);
  }

  if (_isRMFamily()) {
    return RMSnappingPoint(cursorCorner, panelCorner);
  }

  if (_isGFT() || _isULA() || _isAscenderFamily()) {
    return GFTandULASnappingPoint(cursorCorner, panelCorner);
  }
}

function SMInteriorRestrictedSnapping() {
  const interiorTopPoints: [number, number][] = [
    [0, 1],
    [1, 2.25],
    [2, 2.25],
    [3, 2.25],
    [4, 2.25],
    [5, 2.25],
    [0, 2],
    [0, 2.5],
    [0, 3],
    [0, 4],
    [1, 2],
    [1, 2.5],
    [1, 3],
    [1, 4],
    [2, 1],
    [2, 2],
    [2, 2.5],
    [2, 3],
    [2, 4],
  ];

  const interiorBottomPoints: [number, number][] = [
    [4, 9],
    [4, 10],
    [4, 10.5],
    [4, 11],
    [5, 9],
    [5, 10],
    [5, 10.5],
    [5, 11],
    [5, 10],
    [5, 10.5],
    [5, 11],
    [5, 12],
    [3, 7],
    [3, 9],
    [5, 8],
    [4, 8],
    [3, 10],
    [3, 10.5],
  ];

  const interiorLeftPoints: [number, number][] = [
    [0, 1],
    [0, 5],
    [0, 7],
    [1, 5],
    [1, 7],
    [3, 5],
    [4, 7],
  ];

  const interiorRightPoints: [number, number][] = [
    [1, 4],
    [1, 6],
    [1, 8],
    [2, 4],
    [2, 6],
    [2, 8],
  ];

  return <[number, number][]>[]
    .concat(interiorTopPoints)
    .concat(interiorBottomPoints)
    .concat(interiorLeftPoints)
    .concat(interiorRightPoints);
}

function SMandSFSnappingPoint(cursorCorner: number, panelCorner: number) {
  const topRestrictionPoints: [number, number][] = [
    [4, 2],
    [5, 2],
    [3, 6],
    [4, 6],
    // [5, 2.5],
    [3, 2.5],
    [3, 3],
    [5, 4],
    [3, 1],
    [5, 6],
    [5, 5],
    [4, 5],
  ];

  const bottomRestrictionPoints: [number, number][] = [
    [0, 9],
    [1, 10],
    [2, 10],
    [0, 10.5],
    [0, 11],
    [2, 12],
  ];

  const leftRestrictionPoints: [number, number][] = [
    [0, 4],
    [3, 6],
    [0, 8],
    [3, 12],
  ];
  const rightRestrictionPoints: [number, number][] = [
    [2, 1],
    [2, 7],
    [5, 9],
    [5, 5],
    [3, 11],
    [4, 12],
  ];

  const restrictedSnappingPairs = topRestrictionPoints
    .concat(topRestrictionPoints)
    .concat(bottomRestrictionPoints)
    .concat(leftRestrictionPoints)
    .concat(rightRestrictionPoints)
    .concat(SMInteriorRestrictedSnapping());

  return restrictedSnappingPairs.some(pair => pair[0] === cursorCorner && pair[1] === panelCorner);
}

function RMSnappingPoint(cursorCorner: number, panelCorner: number) {
  const topRestrictionPoints: [number, number][] = [
    [3, 2],
    [2, 3],
    [2, 1],
    [3, 4],
  ];

  const bottomRestrictionPoints: [number, number][] = [
    [1, 10],
    [0, 11],
    [1, 12],
    [0, 10],
    [0, 9],
  ];

  const leftRestrictionPoints: [number, number][] = [
    [3, 5],
    [1, 7],
    [3, 9],
  ];
  const rightRestrictionPoints: [number, number][] = [
    [0, 4],
    [2, 6],
    [2, 12],
    [0, 8],
  ];

  const restrictedSnappingPairs = topRestrictionPoints
    .concat(topRestrictionPoints)
    .concat(bottomRestrictionPoints)
    .concat(leftRestrictionPoints)
    .concat(rightRestrictionPoints)
    .concat(RMInteriorRestrictedSnapping());

  return restrictedSnappingPairs.some(pair => pair[0] === cursorCorner && pair[1] === panelCorner);
}

function RMInteriorRestrictedSnapping() {
  const interiorTopPoints: [number, number][] = [
    [1, 1],
    [1 ,2.25],
    [0, 1],
    [0, 2],
    [0, 3],
    [1, 4],
    [1, 3],
    [1, 2],
    [2, 5],
    [3, 6],
  ];

  const interiorBottomPoints: [number, number][] = [
    [2, 9],
    [2, 2.25],
    [3, 2.25],
    [0, 7],
    [2, 10],
    [2, 11],
    [1, 8],
    [3, 10],
    [3, 11],
    [3, 12],
  ];

  const interiorLeftPoints: [number, number][] = [
    [0, 1],
    [0, 5],
    [0, 7],
    [2, 5],
    [2, 7],
    [2, 9],
    [1, 5],
  ];

  const interiorRightPoints: [number, number][] = [
    [1, 4],
    [1, 6],
    [1, 8],
    [4, 6],
    [4, 8],
    [4, 12],
    [3, 8],
  ];

  return <[number, number][]>[]
    .concat(interiorTopPoints)
    .concat(interiorBottomPoints)
    .concat(interiorLeftPoints)
    .concat(interiorRightPoints);
}

function GFTandULASnappingPoint(cursorCorner: number, panelCorner: number): boolean {
  // Unlike other cases, for GFT/ULA we use the allowed snapping pairs, not resticted ones.
  const allowedSnappingPairs: [number, number][] = [
    [0, 6],
    [1, 5],
    [2, 8],
    [3, 7],

    [0, 10],
    [1, 11],
    [2, 2],
    [3, 3],

    [0, 12],
    [1, 9],
    [2, 4],
    [3, 1],
  ];

  return !allowedSnappingPairs.some(pair => pair[0] === cursorCorner && pair[1] === panelCorner);
}

function EcoFoot2PlusSnappingPoint(cursorCorner: number, panelCorner: number): boolean {
  const allowedSnappingPairs: [number, number][] = [
    [0, 6],
    [1, 5],
    [2, 8],
    [3, 7],

    [0, 10],
    [1, 11],
    [2, 2],
    [3, 3],
  ];

  return !allowedSnappingPairs.some(pair => pair[0] === cursorCorner && pair[1] === panelCorner);
}