import * as React from 'react';
import autobind from 'autobind-decorator';
import { state } from '__common/store';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { LOAD_PANELS_FROM_HISTORY, REDO_HISTORY } from '__editor/panelsEditor/actions';
import { DEBUG } from 'debug';

class Redo extends React.Component<onlyDispatchComponentState, {}> {
  @autobind
  action() {
    const { dispatch } = this.props;
   
    dispatch(REDO_HISTORY());

    const { history } = state();
    
    dispatch(LOAD_PANELS_FROM_HISTORY(history.panelsSnapshot[history.index]));
  }

  render() {
    
    // hidden before release, will be fixed later

    if (!DEBUG.enablePanelsRedo) {
      return null;
    }
    
    return (
      <Button flat={true} onClick={this.action} className="roofs-selector-button" tooltipLabel="Redo">
        <SVG src={require('assets/media/icons/icon_redo.svg')} />
      </Button>
    );
  }
}

export default connect()(Redo);
