import { isMetricUnit } from "engineering/components/engineeringProjectDocuments/utils/unitTypes";

export const apiField = 'max_down_point_load_to_be_allowed'

function makeConfig(max: number = 1000, min: number = 1, label:string= 'maxDownPointLoadToBeAllowed') {
  return {
    type: 'Number',
    label,
    apiField,
    min,
    max,
    missing: undefined,
  };
}

const config  = makeConfig();

export default config;

export const maxDownPointLoadToBeAllowedField = (inputUnit: number = 1) => isMetricUnit(inputUnit) ?  makeConfig(454, 1, 'maxDownPointLoadToBeAllowedKg') : config;
