import * as React from 'react';
import debounce from 'lodash/debounce';
import CustomName from '__common/components/CustomName';
import { connect } from 'react-redux';
import { SET_PROJECT_NAME, SAVE_PROJECT_TITLE, SET_ARRAY_NAME } from 'actions';

type HeaderMiddleBarProps = {
  selectedRoofId: number,
  drawingManager: drawingManagerState,
  projectTitle: { type: string, name: string },
  rightSiteComponent: JSX.Element,
  router: any,
  dispatch: Function,
  projectName: string,
  projectId: string,
};

class HeaderMiddleBar extends React.Component<HeaderMiddleBarProps, {}> {

  constructor(props: HeaderMiddleBarProps) {
    super(props);
    this.updateProjectName = debounce(this.updateProjectName, 5);
  }
  
  updateProjectName = (projectName: string) => {
    const { dispatch, projectId } = this.props;
    dispatch(SET_PROJECT_NAME(projectName));
    if (projectId && projectId.length > 0) {
      dispatch(SAVE_PROJECT_TITLE(projectName, projectId));
    }
  }

  updateArrayName = (arrayName: string) => {
    const { dispatch, selectedRoofId  } = this.props;
    dispatch(SET_ARRAY_NAME(arrayName, selectedRoofId));
  }

  onCustomNameUpdate = (name: string) => {
    const { selectedRoofId  } = this.props;

    if (selectedRoofId !== null) {
      this.updateArrayName(name);
    } else {
      this.updateProjectName(name);
    }
  }

  customNameValue = () => {
    const { selectedRoofId, projectName, drawingManager: { roofs } } = this.props;

    if (selectedRoofId === null) {
      return projectName;
    }

    return roofs[selectedRoofId].roofName;
  }

  render() {
    const { projectTitle: { type, name }, rightSiteComponent } = this.props;
    return (
      <div className="project-title-header">
        <div className="project-description">
          <span className="project-type">{type}</span>
          <span className="project-name">{name}</span>
          <CustomName value={this.customNameValue()} onChange={this.onCustomNameUpdate}/>
        </div>
        {rightSiteComponent}
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    selectedRoofId: state.background.selectedRoofId,
    projectTitle: state.projectConfiguration.productTitle,
    projectName: state.projectConfiguration.projectName,
    panelsEditorEnabled: state.background.cords,
    drawingManager: state.drawingManager,
  };
}

export default connect(mapStateToProps)(HeaderMiddleBar);
