import Leaflet from 'leaflet';
import { clearAllBingLeadEdges } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorLeadEdge/bingMapsRoofsSelectorLeadEdge';
import { clearAllGoogleLeadEdgeObject } from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/leadEdgeRoofSelector';
import { clearFoundZipCodeForEnvFactors } from 'projectDesign/components/projectConfiguration/projectConfigurationEpics';
import { clearLastZipCode } from './components/roofsSelectorMap/roofsSelectorMapEpic';
import { deleteAllBingShapes } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { deleteAllGoogleRoofs, } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { dispatch, state } from '__common/store';
import { generateNewCenterForMap, setShouldSkipFetZipCode } from '__editor/components/roofsSelector/components/roofsSelectorMap/roofsSelectorMap';
import { getBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { getMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { PREVENT_AUTO_SAVE_FINISHED } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { removeRoofsSelectorPlaceMarker } from './components/roofsSelectorLocationSearch/roofsSelectorLocationSearch';
import { setDefaulPanelModel } from '__common/components/moduleSelector/moduleSelectorHelper';
import {
  CLEAR_BACKGROUND_REDUCER,
  CLEAR_PROJECT_CONFIGURATION,
  DELETE_ALL_LEAD_EDGES,
  DELETE_ALL_ROOFS,
  SET_DRAW_RUBBER_BAND,
  CLEAR_ENGINEERING_RAPORT,
  SET_LOCATION,
  CLEAR_RAILS_LIST,
  CLEAR_DATA_TO_LOAD,
  SET_MAP_STATE,
  BOM_RESET,
  RESET_MODULE_SELECTOR,
  CLEAR_HISTORY,
  SET_DEFAULT_MAP_ROOFS_SELECTOR,
  RESET_TABLE_LENGTH_AND_WIDTH_SIZE,
  HIDE_CONFIRM_CLEAR_ARRAYS_MODAL,
  } from 'actions';
import { CLEAR_OBSTRUCTIONS } from '__editor/panelsEditor/actions';
import { SET_MAP_ZOOM } from './components/roofsSelectorMap/roofsSelectorMapActions';
import { clearMoizeCache } from '__common/utils/moize/moizeHelper';

export function clearRoofsSelectorData() {
  const {projectConfiguration:{projectEnvConfig:{is_aurora_project}}} = state();
  clearAllBingLeadEdges();
  clearAllGoogleLeadEdgeObject();
  deleteAllBingShapes();
deleteAllGoogleRoofs();
  dispatch(CLEAR_BACKGROUND_REDUCER());
  dispatch(DELETE_ALL_ROOFS());
  dispatch(DELETE_ALL_LEAD_EDGES());
  if(!is_aurora_project) dispatch(SET_DRAW_RUBBER_BAND());
  dispatch(SET_MAP_STATE(false));
  dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  dispatch(SET_LOCATION(''));
  dispatch(CLEAR_RAILS_LIST());
  dispatch(CLEAR_DATA_TO_LOAD());
  dispatch(CLEAR_HISTORY());
  dispatch(CLEAR_OBSTRUCTIONS());
  clearLastZipCode();
  clearFoundZipCodeForEnvFactors();
  removeRoofsSelectorPlaceMarker();
  dispatch(PREVENT_AUTO_SAVE_FINISHED());
  dispatch(SET_MAP_ZOOM(18));
  setShouldSkipFetZipCode(false);
  clearMoizeCache();
}

// should be moved to projectDesign.ts
export function clearProjectData(productId: string, helioscope?: boolean) {
  const {projectConfiguration:{projectEnvConfig:{is_aurora_project}}} = state();
  clearAllBingLeadEdges();
  clearAllGoogleLeadEdgeObject();
  deleteAllBingShapes();
  deleteAllGoogleRoofs();
  dispatch(CLEAR_ENGINEERING_RAPORT());
  dispatch(CLEAR_PROJECT_CONFIGURATION());
  dispatch(CLEAR_BACKGROUND_REDUCER());
  dispatch(RESET_TABLE_LENGTH_AND_WIDTH_SIZE());
  dispatch(DELETE_ALL_ROOFS());
  dispatch(DELETE_ALL_LEAD_EDGES());
  if(!is_aurora_project) dispatch(SET_DRAW_RUBBER_BAND());
  dispatch(SET_MAP_STATE(false));
  dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  dispatch(SET_LOCATION(''));
  dispatch(CLEAR_RAILS_LIST());
  dispatch(CLEAR_DATA_TO_LOAD());
  dispatch(BOM_RESET());
  dispatch(RESET_MODULE_SELECTOR());
  dispatch(CLEAR_OBSTRUCTIONS());
  clearLastZipCode();
  clearFoundZipCodeForEnvFactors();
  dispatch(PREVENT_AUTO_SAVE_FINISHED());
  dispatch(SET_MAP_ZOOM(18));
  setShouldSkipFetZipCode(false);
  dispatch(HIDE_CONFIRM_CLEAR_ARRAYS_MODAL());
  clearMoizeCache();
  
  if (!helioscope) {
    setDefaulPanelModel(productId);
  }

  const bingMap = getBingMap();
  const googleMap = getMap();

  const randomCenter = generateNewCenterForMap();

  if (bingMap) {
    bingMap.panTo(new Leaflet.LatLng(randomCenter.lat, randomCenter.lng));
  }

  if (googleMap) {
    googleMap.setCenter(new google.maps.LatLng(randomCenter.lat, randomCenter.lng));
  }
}