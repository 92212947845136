import nprogress from 'nprogress';
import { buildParam } from './utils/queryBuilder';
import { FETCH_STATIC_SUCCESSFUL } from 'actions';
import { FetchStaticsActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { FETCH_STATIC_ACTION } from './fetchStaticsActions';
import { Action } from '__common/store/action';
import { AnyAction } from 'redux';
import { ActionsObservable } from 'redux-observable';

export function fetchStatics(action$: ActionsObservable<AnyAction>) {
  return action$.ofType(FetchStaticsActionTypes.FETCH_STATIC)
    .switchMap((action: Action<FETCH_STATIC_ACTION>) => {
      const { filename, type, resource, pathVariables, stripSlash, queryString, layoutType } = action.payload;
      let q: string;
      let link: string;
      if (pathVariables) {
        q = buildParam(pathVariables);
      }

      if (q) {
        link = `api/v1/${resource}${q}/`;
      } else {
        link = `api/v1/${resource}/`;
      }


      if (stripSlash) {
        const lastChar = link.slice(-1);
        if (lastChar === '/') {
          link = link.slice(0, -1);
        }
      }
      if (queryString && queryString.length > 0) {
        link = `${link}${queryString}`;
      }

      if (layoutType && layoutType > 0) {
        link = `${link}${layoutType}`;
      }

      return ObservableAjax({
        takeUntil: action$.ofType(FetchStaticsActionTypes.FETCH_STATIC_SUCCESSFUL),
        onSuccess: FETCH_STATIC_SUCCESSFUL.bind(null, filename, type),
        onErrorMessage: 'Cannot fetch document',
        onErrorAction: () => nprogress.done(),
        link: hitApi('get', link, null, { responseType: 'arraybuffer' }),
      });
    });
}
