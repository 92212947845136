import React from 'react';
import { connect } from 'react-redux';
import { apiUrl } from 'local_config';
import EngineeringBalastActionButton from './engineeringBalastActionButton';

type Props = {
  engineeringBallastMaps: engineeringBallastMapsState,
  projectId: string,
  engineeringProjectDocuments: engineeringProjectDocumentsState
};

class OpenMapInNewTabButton extends React.Component<Props> {
  openInNewTab = () => {
    const { engineeringBallastMaps: { generatedArrayId, generatedLayoutType }, projectId, engineeringProjectDocuments: {units} } = this.props;
    window.open(`${location.protocol}//${apiUrl}api/v1/reports/${projectId}/layout/${generatedArrayId}/${generatedLayoutType}/${units}`);
  }

  render() {
    const { engineeringBallastMaps: { generated } } = this.props;

    if (generated) {
      return (
        <EngineeringBalastActionButton 
          tooltipLabel="Open image in new tab" 
          onClick={this.openInNewTab} 
          svgPath={require('assets/media/icons/icon_newtab.svg')}
        />
      );
    }

    return null;
  }
}

function mapStateToProps(appState: appState) {
  return {
    engineeringBallastMaps: appState.engineeringBallastMaps,
    engineeringProjectDocuments : appState.engineeringProjectDocuments
  };
}

export default connect(mapStateToProps)(OpenMapInNewTabButton);
