import config from 'local_config';

export function createLink(url: string): string {
  return `${location.protocol}//${config.apiUrl}${url}`;
}

export function getBomReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/bom/${projectId}/${query}`;
}

export function getEngineeringReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/engineering/${projectId}/${query}`;
}

export function getEngineeringSummaryReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/engineering_summary/${projectId}/${query}`;
}

export function getInternalEngineeringReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/internal_engineering/${projectId}/${query}`;
}

export function getDetailedEngineeringReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/detailed_engineering/${projectId}/${query}`;
}

export function getPeStampReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/pe_stamp/${projectId}/${query}`;
}

export function getLayoutReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/pdf/design_plan/${projectId}/${query}`;
}

export function getGenerateBomReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/bom/${projectId}/${query}`;
}

export function getGenerateEngineeringReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/engineering/${projectId}/${query}`;
}

export function getGenerateEngineeringSummaryReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/engineering_summary/${projectId}/${query}`;
}

export function getGenerateInternalEngineeringReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/internal_engineering/${projectId}/${query}`;
}

export function getGenerateDetailedEngineeringReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/detailed_engineering/${projectId}/${query}`;
}

export function getGeneratePeStampReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/pe_stamp/${projectId}/${query}`;
}

export function getGenerateLayoutReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/design_plan/${projectId}/${query}`;
}

export function getGenerateFullReportUrl(projectId: string, unitsType: unitsType): string {
  const query = getQueryParameters(unitsType);
  return `api/v1/reports/generate/pdf/full_report/${projectId}/${query}`;
}

export function getDXFUrl(projectId: string, arrayId:number): string {
  return `api/v1/reports/genrate/${projectId}/dxf/${arrayId}`;
}

export function getLayoutTypeDXFUrl(projectId: string, arrayId:number, layout_type:Number): string {
  return `api/v1/reports/genrate/${projectId}/dxf/${arrayId}/${layout_type}`;
}

export function getConsolidatedDXFUrl(projectId: string, roofId:number): string {
  return `api/v1/reports/genrate/${projectId}/consolidatedDxf/${roofId}`;
}

export function getConstructionDXFUrl(projectId: string, roofId:number): string {
  return `api/v1/reports/genrate/${projectId}/constructionDxf/${roofId}`;
}

export function getPlansetDXFurl(projectId: string): string {
  return `api/v1/reports/genrate/plansetzip/${projectId}`;
}

export function getDXFZIPAllUrl(projectId: string): string {
  return `api/v1/reports/genrate/dxfzip/${projectId}`;
}

export function getDownloadFullReportDownloadConfiguration(projectId: string, productId: string, unitsType: unitsType) {
  return {
    type: 'application/pdf',
    urlBase: 'reports/pdf/full_report/',
    pathVariables: { projectId },
    queryParameters: getQueryParameters(unitsType),
    fileNamePrefix: getFileNamePrefix(productId, projectId),
  };
}

export function getDownloadBomDownloadConfiguration(projectId: string, productId: string, unitsType: unitsType) {
  return {
    type: 'application/pdf',
    urlBase: 'reports/pdf/bom/',
    pathVariables: { projectId },
    queryParameters: getQueryParameters(unitsType),
    fileNamePrefix: getFileNamePrefix(productId,projectId),
  };
}

export function getDownloadLayoutDownloadConfiguration(projectId: string, unitsType: unitsType) {
  return {
    type: 'application/pdf',
    urlBase: 'reports/pdf/design_plan/',
    pathVariables: { projectId },
    queryParameters: getQueryParameters(unitsType),
  };
}

export function getEngineeringReportDownloadConfiguration(projectId: string, unitsType: unitsType) {
  return {
    type: 'application/pdf',
    urlBase: 'reports/pdf/engineering/',
    pathVariables: { projectId },
    queryParameters: getQueryParameters(unitsType),
  };
}

function getQueryParameters(unitsType: unitsType): string {
  return unitsType === 'metric' ? '?unitconversion=metric' : '';
}

export function getFileNamePrefix(productId: string, projectId: string): string {
  const filename_prefix = `${productId.toLocaleUpperCase()}_${projectId}_`
  return filename_prefix;
}
