import React from 'react';
import TotalListPrice from 'bom/components/TotalListPrice';
import DataTable from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHeader from 'react-md/lib/DataTables/TableHeader';
import TableRow from 'react-md/lib/DataTables/TableRow';
import { calculatePrices } from 'bom/bomHelpers';

type Props = {
  data: bomPartsList,
};

export default class CombinedProjectsTable extends React.Component<Props> {
  tableRows() {
    const { data } = this.props;
    return data.map((part, index) => (
        <TableRow key={index}>
          <TableColumn>{part.number}</TableColumn>
          <TableColumn className={part.category}>{part.type}</TableColumn>
          <TableColumn>{part.description}</TableColumn>
          <TableColumn>{part.quantity}</TableColumn>
          <TableColumn>{part.price}</TableColumn>
          <TableColumn>{(part.price * part.quantity).toFixed(2)}</TableColumn>
        </TableRow>
      ));
  }

  totalListPrice() {
    const { accessoriesPrice, baseSystemPrice } = calculatePrices(this.props.data);
    return (
      <TableRow key="total_row" className="part-row">
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} />
        <TableColumn style={{ position: 'relative' }} >
          <TotalListPrice totalPrice={Number((accessoriesPrice + baseSystemPrice).toFixed(2))} />
        </TableColumn>
    </TableRow>
    );
  }

  render() {
    
    return (
    <DataTable className="combined-projects-table" plain={true}>
      <TableHeader>
        <TableRow>
          <TableColumn>PART NUMBER</TableColumn>
          <TableColumn>PART TYPE</TableColumn>
          <TableColumn>DESCRIPTION</TableColumn>
          <TableColumn>QUANTITY</TableColumn>
          <TableColumn>UNIT PRICE (USD)</TableColumn>
          <TableColumn>TOTAL LIST PRICE (USD)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        {this.tableRows().concat(this.totalListPrice())}
      </TableBody>
    </DataTable>);
  }
}
