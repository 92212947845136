import * as PIXI from 'pixi.js';

const color = 0x00000;
const radius = 3;
const lineSize = 3;

export default (coords: { x: number, y: number }) => {
  const scaleTool = new PIXI.Graphics();
  scaleTool.lineStyle(1, color);
  scaleTool.beginFill(0xFFFFFF);
  scaleTool.drawCircle(coords.x, coords.y, radius);
  scaleTool.addChild(drawVerticalLine(coords.x - radius, coords.y, lineSize));
  scaleTool.addChild(drawVerticalLine(coords.x + radius, coords.y, lineSize));
  scaleTool.addChild(drawHorizontalLine(coords.x, coords.y - radius, lineSize));
  scaleTool.addChild(drawHorizontalLine(coords.x, coords.y + radius, lineSize));
  scaleTool.endFill();
  scaleTool.id = 'cursor';
  return scaleTool;
};

export function drawVerticalLine(x1, y1, size) {
  const line = new PIXI.Graphics();
  line.lineStyle(1, color);
  line.moveTo(x1, y1);
  line.pivot.x = size / 2;
  line.lineTo(x1 + size, y1);
  return line;
}

export function drawHorizontalLine(x1, y1, size) {
  const line = new PIXI.Graphics();
  line.lineStyle(1, color);
  line.pivot.y = size / 2;
  line.moveTo(x1, y1);
  line.lineTo(x1, y1 + size);
  return line;
}
