import { state } from "__common/store";

export enum PSEUDO_STAFF_USER_PERMISSIONS {
    engineering_layouts = "auth.engineering_layouts",
    source_code = 'auth.source_code',
    staggering_access = 'auth.staggering_access'
}

export const UBUILDER_SOFTWARE_GROUP = {
    solarmount_ascender_flush_permission: "auth.sm_ascender_flush_access"
} 

export const userHasSmAscenderFlushPermission = () => {
    const {user: {permissions}} = state();
    return permissions.indexOf(UBUILDER_SOFTWARE_GROUP.solarmount_ascender_flush_permission) > -1
}
export const PANEL_SPACINGS_UPDATOR_PERMISSION = 'auth.panel_spacings_updator';


export const PLANSET_DRAWING_GROUP = {
    planset_permission: 'tool.planset_drawing'
}
export const userPlansetPermission = () => {
    const {user: {permissions}} = state();
    return permissions.indexOf(PLANSET_DRAWING_GROUP.planset_permission) > -1
}