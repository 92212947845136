import { ModuleBits } from './baseTypes';

// (little-endian) - to make the bit data read the table right to left 
// e.g. on corner (0-1), on edge (0-1), orientation (0-1)

// ** old building code: 7-05, 7-10 **
// |----------------|---------|-----------|
// | orientation    | on edge | on corner |
// | (is landscape) |         |           |
// |----------------|---------|-----------|
// |       1        |    1    |     0     | <--- example: landscape panel on edge. 
// |                |         |           |      i.e. on corner, on edge, orientation gives 011 (bin) = 3 (dec)
// |----------------|---------|-----------| 

export class BasicModuleBits extends ModuleBits {
  fromBits(bitData: number, roofZoneId?: roofZoneNumber) {
    const isLandscape = bitData & 1;
    let roofZone = (bitData >> 1 & 0b11) as roofZoneNumber;
    if(roofZoneId) roofZone = roofZoneId;
    return {
      isLandscape: this.bitToBoolean(isLandscape),
      roofZone,
      nearObstruction: false,
    };
  }
  toBits(panel: panelInState) {
    const isLandscape = this.booleanToBit(panel.landscape); // 1 - landscape
    return panel.roofZone << 1 | isLandscape;
  }
}
