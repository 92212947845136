import * as React from 'react';
import Icon from '__common/components/Icon';
import { changeTileReplacementType } from 'bom/components/utils/partsSelectionFunctions';
import SelectionControlGroup from 'react-md/lib/SelectionControls/SelectionControlGroup';
import { SelectionControl } from 'react-md';
import { isMultipleValues, TILE_FLASHINGS, SUB_FLASHINGS } from 'projectDesign/components/projectConfiguration/utils/constants';

declare type tileReplacementAttachmentTypeComponentState = {
  dispatch: Function,
  group: string,
  groupItemTypes: string[],
  selectedGroupItem: string,
};

export default class TileReplacementAttachmentTypes extends React.Component<tileReplacementAttachmentTypeComponentState, {}> {

  changeTileReplacementType(group: string, selectedGroupItem: string, groupItemTypes: string[]) {
    changeTileReplacementType(group, selectedGroupItem, groupItemTypes);
  }

  parseTypes = (groupItemTypes: string[]) => {
    return groupItemTypes.map(type => Object({ label: type, value: type, key: type.toLowerCase().split(' ').join('-') }));
  }

  render() {
    const { group, groupItemTypes, selectedGroupItem } = this.props;

    const types = this.parseTypes(groupItemTypes);
    return (
      <div className="attachment-types-selector">
        <div className="control-panel-sublabel">
            {group.toUpperCase()}
        </div>

       {groupItemTypes.length > 1 && (groupItemTypes.includes("Flat Tile") || groupItemTypes.includes("Spanish Tile") || groupItemTypes.includes("W Tile"))? 
        <SelectionControlGroup
          id={`${group}-selection-control-group-radios`}
          name="tile-replacement-types"
          type="radio"
          value={selectedGroupItem}
          controls={types}
          onChange={(item) => this.changeTileReplacementType(group, item, groupItemTypes)}
          checkedRadioIcon={(<Icon>check</Icon>)}
          uncheckedRadioIcon={(<Icon>check</Icon>)}
          inline={true}
        />: null}
        {groupItemTypes && groupItemTypes.includes("Universal Sub Flashing") ?
        <SelectionControl
          id={`${group}-selection-control`}
          name="tile-replacement-types"
          label={`${groupItemTypes[0]}`}
          type="checkbox"
          onChange={() => this.changeTileReplacementType(group, selectedGroupItem === groupItemTypes[0]? '': groupItemTypes[0], groupItemTypes)}
          checked={selectedGroupItem === groupItemTypes[0]}
          checkedCheckboxIcon={(<Icon>check</Icon>)}
          uncheckedCheckboxIcon={(<Icon>check</Icon>)}
        /> : null
        }
        {
          groupItemTypes && isMultipleValues(TILE_FLASHINGS, groupItemTypes) && groupItemTypes.map((value, index)=>{
            return (
              <SelectionControl
                id={`${group}-selection-control-${index}`}
                name="tile-replacement-types"
                label={`${value}`}
                type="checkbox"
                onChange={() => this.changeTileReplacementType(group, selectedGroupItem === value? '': value, groupItemTypes)}
                checked={selectedGroupItem === value}
                checkedCheckboxIcon={(<Icon>check</Icon>)}
                uncheckedCheckboxIcon={(<Icon>check</Icon>)}
                style={{marginLeft : 10}}
              />
            )
          })
        }
        {
          groupItemTypes && isMultipleValues(SUB_FLASHINGS, groupItemTypes) && groupItemTypes.map((value, index)=>{
            return (
              <SelectionControl
                id={`${group}-selection-control-${index}`}
                name="tile-replacement-types"
                label={`${value}`}
                type="checkbox"
                onChange={() => this.changeTileReplacementType(group, selectedGroupItem === value? '': value, groupItemTypes)}
                checked={selectedGroupItem === value}
                checkedCheckboxIcon={(<Icon>check</Icon>)}
                uncheckedCheckboxIcon={(<Icon>check</Icon>)}
                style={{marginLeft : 10}}
              />
            )
          })
        }
      </div>
    );
  }
}

