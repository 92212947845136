export function getULAProjectConfigurationData(projectEnvConfig: projectEnvConfig) {
  const {
    zipcode,
    wind_speed,
    building_code,
    preferred_span,
    snow_load,
    elevation,
    wind_exposure,
    rail_finish,
    roof_type,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    clamp_type,
    risk_category,
    row_spacing,
    column_spacing,
    tilt,
    seismic_ss,
    seismic_s1,
    front_edge_height,
    rails_per_module,
    foundation_type,
    ground_screw_manufacturer,
    foundation_diameter,
    foundation_screw_length,
    rail_type,
    clamps_location,
    additional_load_combination,
    ns_diagnol_brace,
    is_sm_hd_rail,
    seismic_sds,
    seismic_sd1,
    tornado_speed,
    long_transition_periods_tl,
    longest_row_length,
    shortest_row_length,
    } = projectEnvConfig;

  return {
    zipcode,
    wind_speed,
    building_code,
    preferred_span,
    snow_load,
    elevation,
    wind_exposure,
    rail_finish,
    roof_type,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    clamp_type,
    risk_category,
    row_spacing,
    column_spacing,
    tilt,
    seismic_ss,
    seismic_s1,
    front_edge_height,
    rails_per_module,
    foundation_type,
    ground_screw_manufacturer,
    foundation_diameter,
    foundation_screw_length,
    additional_load_combination : additional_load_combination ? additional_load_combination : 1,
    rail_type,
    clamps_location,
    ns_diagnol_brace,
    is_sm_hd_rail,
    seismic_sds,
    seismic_sd1,
    tornado_speed,
    long_transition_periods_tl,
    longest_row_length,
    shortest_row_length,
  };
}
