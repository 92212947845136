import { state } from '__common/store';
import { showErrorAlert } from '__common/modules/alerts';


const time = 5000;

let timer;
let lastZipCode;
let locked = false;

export const windWarning = () => { 
  const { projectConfiguration: { projectEnvConfig: { zipcode } }, roofsSelector: { mapTypeSelected, mapType } } = state();

  if (!mapTypeSelected && mapType !== 'white') {
    return;
  }

  if (lastZipCode !== zipcode) {
    clearTimeout(timer);
    locked = false;
  }

  if (!locked) {
    showErrorAlert('Special wind conditions');

    locked = true;
    lastZipCode = zipcode;

    clearTimeout(timer);

    timer = setTimeout(() => {
      locked = false;
    },                 time);
  }
};
