import { nearestKdTreePoint } from '__editor/panelsEditor/components/cursor/utils/kdTreeStore';
import { isASCE716or722 } from '__common/constants/buildingCodes';
import { isBlankMap } from '__common/constants/map';
import { isGroundProduct, isRMIFIProduct } from '__common/constants/products';
import { applySkewFactorChanges } from '__common/utils/versionCompare/versionCompare';

export const canUseExposure = (buildingCode: number, productId: number): boolean => {
  return (!isGroundProduct(productId) && !isRMIFIProduct(productId) && isASCE716or722(buildingCode));
};

export const canShowSkewZone = (productId: number, mapType: string, version: string) => !isBlankMap(mapType) && applySkewFactorChanges(productId, version);

export const canShowRestrictedZone = (buildingCode: number): boolean => {
  return isASCE716or722(buildingCode);
};

export const shouldUseExposureZone = (mapType: string): boolean => {
  // for blank design we should be more conservative
  // and assume that all outermost panels are exposed. 
  return !isBlankMap(mapType);
};

export const getPanelCenter = (panel: panelInState) => ({ x: panel.x, y: panel.y });

export const getNearestPanelsIds = (panel: panelInState, bgXOffset: number, bgYOffset: number) => {
  const panelCenterRelativeToCenter = getPanelCenterRelativeToScreen(panel, bgXOffset, bgYOffset);

  const nearestPoints = nearestKdTreePoint(panelCenterRelativeToCenter, 1000, 100);

  const nearestPanelsIds = new Set(nearestPoints.map(panel => panel[0].panelId));

  return Array.from(nearestPanelsIds);
};

const getPanelCenterRelativeToScreen = (panel: panelInState, bgXOffset: number, bgYOffset: number) => {
  const panelCenter = getPanelCenter(panel);
  return { x: bgXOffset + panelCenter.x, y: bgYOffset + panelCenter.y };
};

