import keyMirror from 'keymirror';


export const HistoryActionTypes = keyMirror({
  UNDO_HISTORY: undefined,
  REDO_HISTORY: undefined,
  PUT_PANELS_IN_HISTORY: undefined,
  RESET_HISTORY: undefined,
  CLEAR_HISTORY: undefined,
});
