import Leaflet, { LatLngLiteral } from 'leaflet';
import { getBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import { getBingPolyRoofEdge } from '__editor/bingMapsRoofsSelector/models/leadEdge';
import { emptyRoofColor } from '../../models/selectedArea';

let leadEdgesOnMap: { [roofId: number]: Leaflet.Polyline } | null = null;

export function createBingLeadEdgesOnMap(roofId: number, leadEdgePath: LatLngLiteral[]) {
  const roofEdge = getBingPolyRoofEdge(leadEdgePath, roofId);
  addBingLeadEdgeObject(roofId, roofEdge);
  roofEdge.addTo(getBingMap());
}

export function addBingLeadEdgeObject(roofId: number, leadEdge: Leaflet.Polyline) {
  if (leadEdgesOnMap === null) {
    leadEdgesOnMap = {};
  }
  leadEdgesOnMap[roofId] = leadEdge;
}

export function removeBingLeadEdgeObject(roofId: number) {
  if (leadEdgesOnMap && leadEdgesOnMap[roofId] && Object.keys(leadEdgesOnMap[roofId]).length) {
    const map = getBingMap();
    leadEdgesOnMap[roofId].removeFrom(map);
    delete leadEdgesOnMap[roofId];
  }
}

export function getAllBingLeadEdges(): { [roofId: number]: Leaflet.Polyline } {
  if (leadEdgesOnMap) {
    return leadEdgesOnMap;
  }
}

export function clearAllBingLeadEdges() {
  const map = getBingMap();
  if (map && leadEdgesOnMap) {
    Object.keys(leadEdgesOnMap).map((roofId) => {
      leadEdgesOnMap[roofId].removeFrom(map);
    });
  }
  leadEdgesOnMap = null;
}

export function makeBingLeadEdgeEmpty(roofId: number) {
  leadEdgesOnMap[roofId].setStyle({ color: emptyRoofColor });
}
