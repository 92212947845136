import { ModuleSelectorActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';

export function FETCH_MFGS_AND_MODELS_REQUEST(productId, module, selectedModelId, customModel?,version?, inputUnit?) {
  return {
    type: ModuleSelectorActionTypes.FETCH_MFGS_AND_MODELS_REQUEST,
    payload: {
      productId,
      module,
      selectedModelId,
      customModel,
      version,
      inputUnit,
    },
  };
}

export function FETCH_MFGS_SUCCESS(mfgs) {
  return {
    type: ModuleSelectorActionTypes.FETCH_MFGS_SUCCESS,
    payload: {
      mfgs,
    },
  };
}
export function FETCH_MFG_MODELS_REQUEST(productId, module, selectedModelId, customModel?,version?, inputUnit?) {
  return {
    type: ModuleSelectorActionTypes.FETCH_MFG_MODELS_REQUEST,
    payload: {
      productId,
      module,
      selectedModelId,
      customModel,
      version,
      inputUnit,
    },
  };
}

export function FETCH_MFG_MODELS_REQUEST_ERROR(error) {
  return {
    type: ModuleSelectorActionTypes.FETCH_MFG_MODELS_REQUEST_ERROR,
    payload: {
      error,
    },
  };
}

export function FETCH_MFGS_MODELS_SUCCESS(model, models) {
  return {
    type: ModuleSelectorActionTypes.FETCH_MFGS_MODELS_SUCCESS,
    payload: {
      models,
      model,
    },
  };
}

export function SET_MFG(mfgId) {
  return {
    type: ModuleSelectorActionTypes.SET_MFG,
    payload: {
      mfgId,
    },
  };
}

export function SET_MODEL(modelId, customModel?, productId?, inputUnit?: number, toggled?: boolean) {
  return {
    type: ModuleSelectorActionTypes.SET_MODEL,
    payload: {
      modelId,
      customModel,
      productId,
      inputUnit,
      toggled,
    },
  };
}

export function RESET_MODULE_SELECTOR() {
  return {
    type: ModuleSelectorActionTypes.RESET_MODULE_SELECTOR,
  };
}

export function SET_CUSTOM_VALUE(field: string, value: number) {
  return {
    type: ModuleSelectorActionTypes.SET_CUSTOM_VALUE,
    payload: {
      field,
      value,
    },
  };
}

export function POPULATE_MODELS_BY_MODULE(productId: string, mfg: number, version?: string) {
  return {
    type: ModuleSelectorActionTypes.POPULATE_MODELS_BY_MODULE,
    payload: {
      productId,
      mfg,
      version,
    },
  };
}

export function SET_SAME_PANEL_DIMS(sameDims: { width?: number, length?: number }) {
  return {
    type: ModuleSelectorActionTypes.SET_SAME_PANEL_DIMS,
    payload: {
      sameDims,
    },
  };
}

export interface SET_MODEL_DATA_HAS_ERROR_ACTION {
  hasError: boolean;
}

export function SET_MODEL_DATA_HAS_ERROR(hasError: boolean): Action<SET_MODEL_DATA_HAS_ERROR_ACTION> {
  return {
    type: ModuleSelectorActionTypes.SET_MODEL_DATA_HAS_ERROR,
    payload: {
      hasError,
    },
  };
}
