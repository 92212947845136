import frostDepthGftTooltip from "../fieldsTooltips/frostDepthGftTooltip";

export const apiField = 'frost_depth';


const frostDepthGft = {
  type: 'Number',
  label: 'frost_depth',
  apiField,
  tooltip: frostDepthGftTooltip,
  min : 0,
};

export default frostDepthGft;
