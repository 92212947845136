interface BuildingType {
  value: 1 | 2 | 3;
  name: string;
}

export const flat: BuildingType = {
  value: 1,
  name: 'Flat',
};

export const gable: BuildingType = {
  value: 2,
  name: 'Gable',
};

export const hip: BuildingType = {
  value: 3,
  name: 'Hip',
};

export function isFlatBuildingType(type: number): boolean {
  return type === flat.value;
}

export function isGableBuildingType(type: number): boolean {
  return type === gable.value;
}

export function isHipBuildingType(type: number): boolean {
  return type === hip.value;
}
