import * as React from 'react';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import { getPanelsContainer } from '__editor/panelsEditor/components/panels/utils/panelsContainer';

export default class SetArrayTransparent extends React.Component {
  state = { transparentArray: false };

  setArrayTransparent = (value: boolean) => {
    const panelContainer = getPanelsContainer();

    if (value) {
      panelContainer.alpha = 0.3;
    } else {
      panelContainer.alpha = 1;
    }
  }

  render() {
    const panelContainer = getPanelsContainer();
    const { transparentArray } = this.state;
    let shouldBeChecked;

    if (panelContainer) {
      shouldBeChecked = panelContainer.alpha === 0.3 || transparentArray;
    }

    return (
    <SelectionControl
      id="set-array-transparent"
      type="checkbox"
      label="Set array transparent"
      name="transparent_array"
      defaultChecked={shouldBeChecked}
      onChange={this.setArrayTransparent}
    />
    );
  }
}
