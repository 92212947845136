import { BOM_GENERATE_START } from './components/engineeringProjectDocuments/engineeringProjectDocumentsActions';
import { EngineeringActionTypes } from 'actionsConstants';
import { hitApi, ObservableAjax } from '__common/utils/api';
// import { REFRESH_PDFS } from './engineeringActions';
import {
  GET_CODE_COMPILANT_LETTER,
  POPULATE_ENGINEERING_RAPORT,
  SEND_ENGINEERING_CLIENT_DETAILS_SUCCESS,
  SET_CODE_COMPILANT_LETTER,
  } from 'actions';

export function fetchEngineeringRaport(action$) {
  return action$.ofType(EngineeringActionTypes.FETCH_ENGINEERING_RAPORT)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringActionTypes.FETCH_ENGINEERING_RAPORT),
        onSuccess: [POPULATE_ENGINEERING_RAPORT, GET_CODE_COMPILANT_LETTER.bind(null, action.payload.projectId)],
        onErrorMessage: 'Cannot load engineering reports',
        link: hitApi('get', `api/v1/litereports/${action.payload.projectId}/`),
      }),
    );
}

export function updateEngineeringClientDetails(action$) {
  return action$.ofType(EngineeringActionTypes.SEND_ENGINEERING_CLIENT_DETAILS)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringActionTypes.SEND_ENGINEERING_CLIENT_DETAILS),
        onSuccess: SEND_ENGINEERING_CLIENT_DETAILS_SUCCESS,
        onErrorMessage: 'Cannot send engineering details',
        link: hitApi('patch', `api/v1/reports/${action.payload.projectId}/`, action.payload.clientDetails),
      }),
    );
}

export function getCompilantLetter(action$) {
  return action$.ofType(EngineeringActionTypes.GET_CODE_COMPILANT_LETTER)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringActionTypes.GET_CODE_COMPILANT_LETTER),
        onSuccess: SET_CODE_COMPILANT_LETTER,
        onErrorMessage: 'Cannot get compilant letter',
        link: hitApi('get', `api/v1/get_product_documents/?project_id=${action.payload.projectId}`),
      }),
    );
}

export function setDiscount(action$) {
  return action$.ofType(EngineeringActionTypes.SET_DISCOUNT)
    .switchMap(action =>
      ObservableAjax({
        takeUntil: action$.ofType(EngineeringActionTypes.SET_DISCOUNT),
        onSuccess: BOM_GENERATE_START.bind(null, action.payload.projectId),
        onErrorMessage: 'Cannot set discount',
        link: hitApi('put', `api/v1/project_discount/${action.payload.projectId}/`, { discount: action.payload.discount }),
      }),
    );
}
