import { DisableAccountActionTypes } from 'actionsConstants';

export function DISABLE_ACCOUNT() {
  return {
    type: DisableAccountActionTypes.DISABLE_ACCOUNT,
  };
}

export function DISABLE_ACCOUNT_SUCCESS() {
  return {
    type: DisableAccountActionTypes.DISABLE_ACCOUNT_SUCCESS,
  };
}

export function DISABLE_ACCOUNT_ERROR() {
  return {
    type: DisableAccountActionTypes.DISABLE_ACCOUNT_ERROR,
  };
}

export function DISABLE_ACCOUNT_CLEAR() {
  return {
    type: DisableAccountActionTypes.DISABLE_ACCOUNT_CLEAR,
  };
}
