export const apiField = 'aurora_racking_type';

function makeConfig(options: object[]) {
  return {
    type: 'Select',
    label: 'Racking Type',
    apiField,
    data: options,
  };
}

export default makeConfig([
  {
    value: 25,
    name: 'NXT U MOUNT',
  },
  {
    value: 9,
    name: 'SOLARMOUNT FLUSH',
  },
  
]);
