import nextUid from "__common/calculations/nextUid";
import { createArrayGrid } from "./dividePanelsintoSubArrays";
import { dispatch } from "__common/store";
import { ADD_PANEL_BAY_MAPPING, ADD_VISITED_BAYS } from "../panelsActions";

export const createPanelBayMapping = (array, panelsDict: {number: panelInState}, panelBaysMapping: panelBayMapping = new Map(), visitedBays, panels)=>{
    const panelBayMapping: panelBayMapping =  new Map([...Array.from(panelBaysMapping)]);
    let reOrderedMap: panelBayMapping = panelBayMapping
    if (Array.isArray(array) && array.length >= 1) {
      const grid = createArrayGrid(array);
      const arr = grid?.map(subarray => subarray.map(({ id }) => id));
      const rightEdgePanels = [];
      const leftEdgePanels = [];
      const northEdgePanels = [];
      const northRightEdgePanels = [];
      const northMostRow = []
      const arrayMapping: panelBayMapping = new Map()
      arr?.forEach((row, rowIndex, arr) => {
        row.forEach((panel, eleIndex) => {
          if(panel && panelBayMapping.has(panel)){
            arrayMapping.set(panel, panelBayMapping.get(panel))
          }
          if (eleIndex !== arr[0].length - 1 && rowIndex !== arr.length - 1 && arr[rowIndex][eleIndex+1] === undefined && (!arr[rowIndex+1][eleIndex+1])) {
            panel && rightEdgePanels.push(row[eleIndex]);
          } else if(panel && eleIndex !== arr[0].length - 1 && rowIndex === arr.length - 1 && arr[rowIndex][eleIndex+1] === undefined) {
            rightEdgePanels.push(panel)
          } else if (eleIndex === arr[0].length - 1 && panel) {
            rightEdgePanels.push(panel);
          }
          
          if (eleIndex !== 0 && rowIndex !== arr.length - 1 && arr[rowIndex][eleIndex-1] === undefined && !arr[rowIndex+1][eleIndex-1]) {
            panel && leftEdgePanels.push(panel);
          } else if (eleIndex !== 0 && rowIndex === arr.length - 1 && arr[rowIndex][eleIndex-1] === undefined) {
            panel && leftEdgePanels.push(panel)
          } else if (eleIndex === 0 && panel) {
            leftEdgePanels.push(panel);
          }
          
          if (rowIndex !==0 && arr[rowIndex - 1][eleIndex] === undefined && (!arr[rowIndex - 1][eleIndex + 1] || !arr[rowIndex - 1][eleIndex-1])) {
            panel && northEdgePanels.push(panel);
          }
  
          if (
            panel &&
            rowIndex !== 0 &&
            arr[rowIndex][eleIndex + 1] === undefined &&
            arr[rowIndex - 1][eleIndex + 1] === undefined
        ) {
            northRightEdgePanels.push(panel);
        }
        
  
          if (
            panel &&
            eleIndex === row.length - 1 &&
            rowIndex !== arr.length - 1 &&
            arr[rowIndex+1] &&
            eleIndex + 1 <= arr[rowIndex+1].length - 1
          ) {
              rightEdgePanels.push(panel);
          }
        
        }); 
      });
      leftEdgePanels.push(arr[arr.length-1].find(panel => panel !== undefined))
      rightEdgePanels.push(arr[arr.length-1].slice().reverse().find(panel => panel !== undefined))
      const reversedArr = arr[0].slice().reverse();
      arr[0].forEach((panel, i) => {
        if (panel !== undefined) {
          northEdgePanels.push(panel);
        }
        if (panel === undefined && i !==0 && arr[0][i-1]){
          northRightEdgePanels.push(arr[0][i-1])
          if(arr[1] && arr[1][i]===undefined){
            northMostRow.push(arr[i-1])
          }
        }        
      });
      const index = reversedArr.findIndex(panel => panel !== undefined);
      if (index !== -1 && arr[1]?.[arr[0].length - 1 - index +1] === undefined) {
        reversedArr.splice(index, 1); // Remove the first non-undefined element
      }
      // northMostRow.push(...reversedArr);
      
      if(array && array.length !== arrayMapping.size){
        const bayId = nextUid
        if (arr) {
          const currentBays = [];        
          for (let i = 0; i < arr.length; i++) {
            let row = arr[i];
            for (let j = 0; j < row.length; j++) {
              if (arr[i][j] && !panelBayMapping.has(arr[i][j])) {
                const temp = [];
                if (arr[i][j - 1]) {
                  temp.push({ ...panelBayMapping.get(arr[i][j - 1])[1], p: 1 });
                  temp.push({ id: bayId(), p: 2 });
                  temp.push({ id: bayId(), p: 3 });
                  temp.push({ ...panelBayMapping.get(arr[i][j - 1])[2], p: 4 });
                  // console.log('left', i, j, arr[i][j], temp);
                  if (arr[i][j + 1] && panelBayMapping.has(arr[i][j + 1])) {
                    temp[1] = { ...panelBayMapping.get(arr[i][j + 1])[0], p: 2 };
                    temp[2] = { ...panelBayMapping.get(arr[i][j + 1])[3], p: 3 };
                    // console.log('left & right', i, j, arr[i][j]);
                  } else if (i !== 0 && arr[i - 1][j] && panelBayMapping.has(arr[i - 1][j]) && arr[i + 1]?.[j] && panelBayMapping.has(arr[i + 1][j])) {
                    temp[0] = { ...panelBayMapping.get(arr[i][j - 1])[1], p: 1 };
                    temp[1] = { ...panelBayMapping.get(arr[i - 1][j])[2], p: 2 };
                    temp[2] = { ...panelBayMapping.get(arr[i + 1][j])[1], p: 3 };
                    temp[3] = { ...panelBayMapping.get(arr[i][j - 1])[2], p: 4 };
                    // console.log('left & top & bottom', i, j, arr[i][j]);
                  } else if (i !== 0 && arr[i - 1][j] && panelBayMapping.has(arr[i - 1][j])) {
                    temp[0] = { ...panelBayMapping.get(arr[i][j - 1])[1], p: 1 };
                    temp[1] = { ...panelBayMapping.get(arr[i - 1][j])[2], p: 2 };
                    temp[3] = { ...panelBayMapping.get(arr[i][j - 1])[2], p: 4 };
                    // console.log('left & top', i, j, arr[i][j], temp);
                  } else if (i !== 0 && arr[i - 1][j + 1] && panelBayMapping.has(arr[i - 1][j + 1])) {
                    temp[1] = { ...panelBayMapping.get(arr[i - 1][j + 1])[3], p: 2 };
                    // console.log('left & upper right corner', i, j, arr[i][j]);
                  } else if (arr[i + 1]?.[j] && panelBayMapping.has(arr[i + 1][j])) {
                    temp[2] = { ...panelBayMapping.get(arr[i + 1][j])[1], p: 3 };
                    // console.log('left & bottom', i, j, arr[i][j]);
                  }
                } else if (arr[i][j - 1] && !arr[i - 1][j]) {
                  if (arr[i - 1][j + 1]) {
                    temp.push({ ...panelBayMapping.get(arr[i][j - 1])[4], p: 4 });
                  } else {
                    temp.push({ ...panelBayMapping.get(arr[i][j - 1])[1], p: 1 });
                    temp.push({ id: bayId(), p: 2 });
                    temp.push({ id: bayId(), p: 3 });
                  }
                  // console.log('left and not top', i, j, arr[i][j]);
                } else if (i !== 0 && arr[i - 1][j]) {
                  temp.push({ ...panelBayMapping.get(arr[i - 1][j])[3], p: 1 });
                  temp.push({ ...panelBayMapping.get(arr[i - 1][j])[2], p: 2 });
                  temp.push({ id: bayId(), p: 3 });
                  temp.push({ id: bayId(), p: 4 });
                  // console.log('not first row and top', i, j, arr[i][j]);
                  if (i !== arr.length - 1 && arr[i+1][j] && panelBayMapping.has(arr[i+1][j])){
                    temp[2] = { ...panelBayMapping.get(arr[i+1]?.[j])[1], p: 3 };
                    temp[3] = { ...panelBayMapping.get(arr[i+1]?.[j])[0], p: 4 };
                    // console.log('not first row, top and bottom', i, j, arr[i][j]);
                  } else if (arr[i][j + 1] && panelBayMapping.has(arr[i]?.[j + 1])) {
                    temp[2] = { ...panelBayMapping.get(arr[i]?.[j + 1])[3], p: 3 };
                    // console.log('not first row and top and left', i, j, arr[i][j])
                  }
                } else if (!arr[i][j - 1] && i !== 0 && arr[i - 1][j - 1]) {
                  temp.push({ ...panelBayMapping.get(arr[i - 1][j - 1])[2], p: 1 });
                  temp.push({ id: bayId(), p: 2 });
                  temp.push({ id: bayId(), p: 3 });
                  temp.push({ id: bayId(), p: 4 });
                  // console.log('not left and not first row and left corner', i, j, arr[i][j]);
                } else if (arr[i][j + 1] && panelBayMapping.has(arr[i][j + 1])) {
                  temp.push({ id: bayId(), p: 1 });
                  temp.push({ ...panelBayMapping.get(arr[i][j + 1])[0], p: 2 });
                  temp.push({ ...panelBayMapping.get(arr[i][j + 1])[3], p: 3 });
                  temp.push({ id: bayId(), p: 4 });
                  if (arr[i + 1][j] && panelBayMapping.has(arr[i + 1][j])) {
                    temp[3] = { ...panelBayMapping.get(arr[i + 1][j])[0], p: 4 };
                    // console.log('not first row and right and bottom', i, j, arr[i][j]);
                  }
                  // console.log('not first row and right', i, j, arr[i][j]);
                } else if (i !== 0 && arr[i - 1][j + 1]) {
                  temp.push({ id: bayId(), p: 1 });
                  temp.push({ ...panelBayMapping.get(arr[i - 1][j + 1])[3], p: 2 });
                  temp.push({ id: bayId(), p: 3 });
                  temp.push({ id: bayId(), p: 4 });
                  // console.log('not first right top corner', i, j, arr[i][j]);
                } else if (i === 0 && i !== arr.length -1 && arr[i + 1][j] && panelBayMapping.has(arr[i + 1][j])) {
                  temp.push({ id: bayId(), p: 1 });
                  temp.push({ id: bayId(), p: 2 });
                  temp.push({ ...panelBayMapping.get(arr[i + 1][j])[1], p: 3 });
                  temp.push({ ...panelBayMapping.get(arr[i + 1][j])[0], p: 4 });
                  // console.log('first row and bottom', i, j, arr[i][j]);
                } else {
                  temp.push({ id: bayId(), p: 1 });
                  temp.push({ id: bayId(), p: 2 });
                  temp.push({ id: bayId(), p: 3 });
                  temp.push({ id: bayId(), p: 4 });
                  // console.log('new', i, j, arr[i][j]);
                }
  
                panelBayMapping.set(arr[i][j], temp);
                const selection = panelsDict[arr[i][j]];
                selection?.manualAttachments?.forEach((value, index) => {
                  temp[index].attached = value;
                  currentBays.push(temp[index].id);                         
                });
                
              }
            }
          }
          arr.forEach(row=>{
            row.forEach(panel=>{
              if(panel && panelBayMapping.has(panel)){
                reOrderedMap.set(panel, panelBayMapping.get(panel))
              }
            })
          })
          const filtered = visitedBays?.filter(vId => !currentBays.includes(vId))
          dispatch(ADD_VISITED_BAYS(filtered))
          dispatch(ADD_PANEL_BAY_MAPPING(reOrderedMap))
        }        
      }
      return [rightEdgePanels, leftEdgePanels, northEdgePanels, northRightEdgePanels, northMostRow]
      }
  }
  