import { BuildingTypeField } from 'projectDesign/components/projectConfiguration/fields/types/buildingType';
import BuildingTypeTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/buildingTypeTooltip';
import { gable } from '__common/constants/buildingTypes';

const config: BuildingTypeField = {
  type: 'Select',
  label: 'buildingType',
  apiField: 'building_type',
  missing: undefined,
  tooltip: BuildingTypeTooltip,
  data: [
    {
      value: gable.value,
      name: gable.name,
    },
  ],
};

export default config;
