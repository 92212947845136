import * as React from 'react';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import Loader from '__common/components/LoaderComponent';
import StatBox from 'yourProjects/components/StatBox';
import YourProjectsHeader from 'yourProjects/components/YourProjectsHeader';
import YourProjectsTable from 'yourProjects/components/YourProjectsTable';
import YourProjectsTableFilter from 'yourProjects/components/YourProjectsTableFilter';
import { CLEAR_YOUR_PROJECT } from 'yourProjects/yourProjectsActions';
import { connect } from 'react-redux';
import { FETCH_MFGS_AND_MODELS_REQUEST, INIT_USER_PROJECTS, YOUR_PROJECTS_LOAD_PREFERENCES } from 'actions';

type Props = {
  dispatch: Function,
  yourProjects: yourProjectsState,
  userPreferences: userPreferencesState,
};


class YourProjects extends React.Component<Props, {}> {
  COLUMNS = ['project_name', 'created', 'panel_mfg', 'panel_model', 'client_name', 'address', 'zipcode', 'total_price'];

  UNSAFE_componentWillMount() {
    const { dispatch, userPreferences: { projects_per_page }, yourProjects: { showOldProjects } } = this.props;
    this.loadUserPreferences();
    dispatch(INIT_USER_PROJECTS(projects_per_page, showOldProjects));
    dispatch(FETCH_MFGS_AND_MODELS_REQUEST('', '', '', ''));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CLEAR_YOUR_PROJECT()); 
  }

  loadUserPreferences() {
    const { dispatch, userPreferences: { project_sorting, projects_per_page, sort_method } } = this.props;
    dispatch(YOUR_PROJECTS_LOAD_PREFERENCES(this.COLUMNS[project_sorting - 1], sort_method === 1 ? 'desc' : 'asc', projects_per_page));
  }

  renderPage() {
    const { dispatch, yourProjects: { data, selectedProjects, rowsPerPage, totalProjects, kilowatts, trees, miles, paginationStart, sortedField, sortMethod, filterString, paginationTotal, paginationPage, showOldProjects, areLoading, filterSet, appliedFilters } } = this.props;
    return (
      <div className="your-projects">
        <YourProjectsHeader title="your projects"/>
        <div className="stats">
          <StatBox color="black" value={totalProjects} icon="event_note" description="PROJECTS CONFIGURED" />
          <StatBox color="orange" value={kilowatts} icon="lightbulb_outline" description="KILOWATTS CONFIGURED" />
          <StatBox color="blue" value={trees} icon="nature" description="TREES PLANTED" />
          <StatBox color="blue" value={miles} icon="directions_car" description="MILES DRIVEN" />
        </div>
        <div className="table">
          <YourProjectsTableFilter />
          <YourProjectsTable 
            paginationStart={paginationStart} 
            data={data} 
            rowsPerPage={rowsPerPage} 
            dispatch={dispatch} 
            totalProjects={totalProjects} 
            sortedField={sortedField} 
            sortMethod={sortMethod} 
            filterString={filterString}
            paginationTotal={paginationTotal}
            paginationPage={paginationPage}
            selectedProjects={selectedProjects}
            showOldProjects={showOldProjects}
            areLoading={areLoading}
            appliedFilters={appliedFilters}
          />
        </div>
      </div>
    );
  }

  render() {
    const { yourProjects: { data } } = this.props;
    return (
      <div className="app-content">
        <Header/>
        {data !== null ? this.renderPage() : <Loader />}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    yourProjects: appState.yourProjects,
    userPreferences: appState.userPreferences,
  };
}

export default connect(mapStateToProps)(YourProjects);
