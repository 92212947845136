
export const apiField = 'colour';

function makeConfig(data) {
  return{
    label: 'colour',
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data
}};


const colourMap = {
  "W": {value: 'W', name: 'WHITE',       },
  "B": {value: 'B', name: 'BLACK',       },
  "G": {value: 'G', name: 'GREY',        },
  "C": {value: 'C', name: 'COOL STAR',   },
  "O": {value: 'O', name: 'WEATHER WOOD',},
  "N": {value: 'N', name: 'N/A',         },
}

export default function colour(keys_array){ 
  const filteredList = keys_array.map(key => colourMap[key]);
      return makeConfig(filteredList);
    }