import rafterSpacing, { rafterSpacingMetric } from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import iceThickness, { apiField as iceThicknessApiField } from 'projectDesign/components/projectConfiguration/fields/iceThickness';
import { apiField as setbackDistanceApiField } from 'projectDesign/components/projectConfiguration/fields/setBackDistance';
import windOnIce, { apiField as windOnIceApiField } from 'projectDesign/components/projectConfiguration/fields/windOnIce';
import frontEdgeHeight, { apiField as frontEdgeHeightApiField } from 'projectDesign/components/projectConfiguration/fields/frontEdgeHeight';
import { apiField as allowMechanicalAttachmentsApiField } from '../fields/allowMechanicalAttachments';
import sfmSpans from 'projectDesign/components/projectConfiguration/fields/sfmSpans';
import span, { preferredSpanMetric } from 'projectDesign/components/projectConfiguration/fields/span';
import { apiField as spanFieldName } from 'projectDesign/components/projectConfiguration/fields/span';
import { apiField as railArrangementTypeFieldName } from 'projectDesign/components/projectConfiguration/fields/railArrangementType';
import { dispatch, state } from '__common/store';
import { isRMFamily, isSFMFamily, products, isULA, isEcoFoot2Plus, isRM10, isAscender, isSMTiltPR, isMetalX, isSunframeMicroRail} from '__common/constants/products';
import { SET_PROJECT_OPTION } from 'actions';
import {
  checkBuildingHeight,
  checkLongestBuildingLength,
  checkParapetHeight,
  checkRoofType,
  checkWindExposure,
} from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { SET_FIELD_INVALID, OPEN_DRAWER_PAGE, CLEAR_WARNING, SET_FIELD_WARNING, CLEAR_INVALID_FIELD } from '../projectConfigurationActions';
import { showErrorAlert } from '__common/modules/alerts';
import { RAIL_SYSTEM_PAGE } from './projectConfigurationDrawerPages';
import { RoofSubstrate, RoofType } from './constants';
import { AttachmentType } from '../fields/attachmentType';
import { isSMFlushMetric } from '__common/utils/products';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import metalXSpans from 'projectDesign/components/projectConfiguration/fields/metalXSpans';
import smTiltPrSpans from 'projectDesign/components/projectConfiguration/fields/span';
import { BUILDING_HEIGHT_UPPER_BOUND, buildingHeightCheck, riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import { RiskCategory } from '../fields/riskCategory';
import { getBuildingHeight } from '../projectConfiguration';
import { apiField as tornadoSpeedApiField } from '../fields/tornadoSpeed';
import { isASCE722 } from '__common/constants/buildingCodes';
import { FoundationType } from '../fields/foundationType';

export const changeRailArrangementTypeWarning = () => {
  showErrorAlert('change to a higher Rail Arrangement');
  dispatch(SET_PROJECT_OPTION(railArrangementTypeFieldName, null));
  dispatch(SET_FIELD_INVALID(railArrangementTypeFieldName, 'change to a higher Rail Arrangement'));
  dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
};
function getUpperLimitForTornadoEffectiveArea(result: number): string {
  const upperLimits: number[] = [1, 2000, 10000, 40000, 100000, 250000, 1000000, 4000000];

  for (let i = 0; i < upperLimits.length; i++) {
      if (result < upperLimits[i]) {
          return upperLimits[i].toString();
      }
  }
  return upperLimits[upperLimits.length - 1].toString();
}


export const showTornadoSpeedWarning = () => {
  const { projectConfiguration : { projectEnvConfig : { risk_category, building_code, building_length, shortest_building_length } } } = state();
  const area = shortest_building_length * building_length;
  if(riskCategoryCheckForTornadoSpeed(risk_category) && isASCE722(building_code)){
    if(area)
      dispatch(SET_FIELD_WARNING(tornadoSpeedApiField, `Verify the tornado speed for effective area ${getUpperLimitForTornadoEffectiveArea(area)} SQ.FT from the ASCE hazard tool.`));
    else
      dispatch(SET_FIELD_WARNING(tornadoSpeedApiField, `Please provide the tornado speed as per your effective roof area.`));
  } 
}

export const changeSetbackDistanceWarning = (seismic_setback, seismic_setback_satisfied, productId: number) => {
  const { projectConfiguration: { formState: { invalidFields, warningFields }, projectEnvConfig: { allow_mechanical_attachments } } } = state();
  const messageWithoutAttachment = `Required minimum seismic setback = ${seismic_setback}, revise setback `;
  const messageWithAttachment = messageWithoutAttachment + 'or use attachments';

  if (!seismic_setback_satisfied) {
    if (isEcoFoot2Plus(productId)) {
      dispatch(SET_FIELD_INVALID(setbackDistanceApiField, messageWithoutAttachment));
    }
    else {
      if (!allow_mechanical_attachments) {
        dispatch(SET_FIELD_INVALID(allowMechanicalAttachmentsApiField, messageWithAttachment));
        if (warningFields.some(({ fieldName }) => fieldName === setbackDistanceApiField)) {
          dispatch(CLEAR_WARNING(setbackDistanceApiField));
        }
      } else {
        dispatch(SET_FIELD_WARNING(setbackDistanceApiField, messageWithAttachment));
        if (invalidFields.some(({ fieldName }) => fieldName === allowMechanicalAttachmentsApiField)) {
          dispatch(CLEAR_INVALID_FIELD(allowMechanicalAttachmentsApiField));
        }
      }
    }
  }
  else {
    invalidFields.map(({ fieldName }) => {
      if (fieldName === setbackDistanceApiField || fieldName === allowMechanicalAttachmentsApiField) {
        dispatch(CLEAR_INVALID_FIELD(fieldName));
      }
    })

    if (warningFields.some(({ fieldName }) => fieldName === setbackDistanceApiField)) {
      dispatch(CLEAR_WARNING(setbackDistanceApiField));
    }
  }

};

export const invalidRailArrangementType = () => {
  const { projectConfiguration: { clampCheckFailed } } = state();
  return clampCheckFailed;
};

export const missingProductConfigurationPageValid = (productId: number) => {
  let {
    projectConfiguration: {
      projectEnvConfig: {
        building_height,
        roof_type,
        parapet_height,
        building_length,
      },
    },
  } = state();

  if (!isRMFamily(products[productId])) {
    return true;
  }

  return (
    checkBuildingHeight(building_height * 1) &&
    checkRoofType(roof_type * 1) &&
    checkParapetHeight(parapet_height * 1) &&
    checkLongestBuildingLength(building_length * 1)
  );
};

export const missingLocationAndLoadsPageValid = (productId: number) => {
  const {
    projectConfiguration: {
      projectEnvConfig: { wind_exposure },
    },
  } = state();

  if (!isRMFamily(products[productId])) {
    return true;
  }

  return checkWindExposure(wind_exposure);
};

export const projectConfigurationWarningConditionValid = (productId: number) => {
  const {
    moduleSelector: {
      modelData: { height }
    },
    projectConfiguration: {
      projectEnvConfig: {
          snow_load,
          wind_speed,
          zipcode
      } 
    },
  } = state();
  if (!isCanadianZipcode(zipcode) && (isRM10(productId) || isEcoFoot2Plus(productId))) {
    return ((height>=85 && (wind_speed >= 125 || snow_load >= 20)) || height>=90 || wind_speed >= 130 || snow_load >= 30);
  }
  return false;
};

export const isCanadianZipcode = (value: string) => {
  const ca = value && value.length == 3 ? new RegExp('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]$') : new RegExp('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$');
  return ca.test(value);
}

export const validateZipCode = zipcode => zipcode && (zipcode.trim().length===5 || isCanadianZipcode(zipcode.trim().toUpperCase()));

export const roofSlopeWarningCondition = () => {
  const { projectConfiguration: { projectEnvConfig: { allow_mechanical_attachments } }, drawingManager: { roofs } } = state();
  const roofPitchGreaterThan3Flag = Object.values(roofs ?? {}).some(({roofPitch}) => roofPitch > 3);
  return roofPitchGreaterThan3Flag && !allow_mechanical_attachments;
}

export const buildingStoreyWarningCondition = () => {
  const { projectConfiguration: { inputUnit, projectEnvConfig: { building_height, building_height_greater_than_6_storey, allow_mechanical_attachments } } } = state();
  return buildingHeightCheck(building_height, inputUnit) && building_height_greater_than_6_storey === 1 && !allow_mechanical_attachments;
}

export const attachmentWarningConditionForRiskCategoryIV = () => {
  const { projectConfiguration: { projectEnvConfig: { risk_category, allow_mechanical_attachments } } } = state();
  return risk_category === RiskCategory.IV && !allow_mechanical_attachments;
}

export const buildingHeightGreaterThan72WarningCondition = () => {
  const { projectConfiguration: { projectEnvConfig: { allow_mechanical_attachments } } } = state();
  const buildingHeightFt = getBuildingHeight();
  return buildingHeightFt > BUILDING_HEIGHT_UPPER_BOUND && !allow_mechanical_attachments;
}

export const validateRafterSpacing = (value: number | string) => {
  let {
    projectConfiguration: {
      projectEnvConfig: {
        preferred_span,
        roof_type,
        attachment_type,
        roof_substrate,
      },
      productId,
      inputUnit,
    },
  } = state();


  const rafterSpacingField = isMetricUnit(inputUnit) ? rafterSpacingMetric: rafterSpacing;
  dispatch(CLEAR_INVALID_FIELD(rafterSpacingField.apiField));
  
  if(isAscender(productId) || isSMTiltPR(productId) || isMetalX(productId) || isSunframeMicroRail(productId) || isOsbOrPlywood(roof_type, attachment_type,roof_substrate)) return;

  if (Number(value) < rafterSpacingField.min || Number(value) > rafterSpacingField.max) {
    dispatch(SET_FIELD_INVALID(rafterSpacingField.apiField,`rafter spacing should be ranging from ${rafterSpacingField.min} and ${rafterSpacingField.max}`));
  }

  if (!isSpanMultipleOfRafterSpacing(preferred_span, value)) {
    dispatch(SET_FIELD_INVALID(spanFieldName, 'Preferred span must be a multiple of rafter spacing'));
  }
  
};

export function validateSpans(productId: number, value: number | string) {
  const {
    projectConfiguration: {
      projectEnvConfig: {
        rafter_spacing_inches,
        roof_type,
        roof_substrate,
        attachment_type
      },
    },
  } = state();


  validateSpansMinMax(productId, Number(value));

  // zero modulo value is also zero so we have to exclude this special case.
  if (Number(value) === 0 || value === '') {
    dispatch(SET_PROJECT_OPTION(spanFieldName, ''));
    dispatch(SET_FIELD_INVALID(spanFieldName, 'preferred span cannot be empty or zero'));
    return;
  }

  if(isSMTiltPR(productId) || isMetalX(productId) || isSunframeMicroRail(productId) || isOsbOrPlywood(roof_type, attachment_type,roof_substrate)) {
    dispatch(CLEAR_INVALID_FIELD(spanFieldName));
    return;
  };

  // when preferred span is not a multiple of rafter span
  if (!isSpanMultipleOfRafterSpacing(value, rafter_spacing_inches)) {
    dispatch(SET_FIELD_INVALID(spanFieldName, 'Preferred span must be a multiple of rafter spacing'));
  }

}

export function isSpanMultipleOfRafterSpacing(spanValue: number | string, rafterSpacing: number | string): boolean {
  // multiplying by 1000 to avoid javascript rounding problem (we use modulo on integers e.g. 1560 % 260)
  // e.g. 15.6 % 2.6 = 2.599999999999999 even though 2.6 * 6 = 15.6; (but in javascript 2.6 * 6 = 15.600000000000001)
  const span = Number(spanValue) * 1000;
  const rafter = Number(rafterSpacing) * 1000;
  return span % rafter === 0;
}

export function isOsbOrPlywood(roof_type: number, attachment_type: number,roof_substrate:number) {
  if (roof_type === RoofType.SHINGLE && ([AttachmentType.FLASHLOC_DUO, AttachmentType.STRONGHOLD_ATT_BUTYL, AttachmentType.SFM_BUTYL_DTD, AttachmentType.SM_BUTYL_DTD].includes(attachment_type)) 
    && (roof_substrate == RoofSubstrate.OSB || roof_substrate == RoofSubstrate.PLYWOOD))
    return true;
  else {
    return false;
  }
}


export function validateSpansMinMax(productId: number, value: string | number) {
  let spans = span;

  switch (true) {
    case isSMFlushMetric():
      spans = preferredSpanMetric;
      break;
    case isSFMFamily(productId):
      spans =sfmSpans;  
      break;
    case isSMTiltPR(productId):
      spans = smTiltPrSpans;
      break;
    case isMetalX(productId):
      spans = metalXSpans;
      break; 
    default:
      break;
  }

  if (value === 0 || value === '') {
    dispatch(SET_PROJECT_OPTION(spanFieldName, ''));
    dispatch(SET_FIELD_INVALID(spanFieldName, 'preferred span cannot be empty or zero'));
  }

  if (!!value && (Number(value) < spans.min || Number(value) > spans.max)){
    dispatch(SET_FIELD_INVALID(spanFieldName,`Preferred span should be ranging from ${spans.min} to ${spans.max}`))
  }
}

export function validateIceThickness(value: number) {
  if (value > 2.5) {
    dispatch(SET_PROJECT_OPTION(iceThickness.apiField, 2.5));
  }

  if (value < 0) {
    dispatch(SET_PROJECT_OPTION(iceThickness.apiField, 0));
  }
}

export function validateWindOnIce(value: number) {
  if (value > 80) {
    dispatch(SET_PROJECT_OPTION(windOnIce.apiField, 80));
  }

  if (value < 0) {
    dispatch(SET_PROJECT_OPTION(windOnIce.apiField, 0));
  }
}


export function validateFrontEdgeHeight(value: number, tilt: number) {

  const { projectConfiguration: { productId, projectEnvConfig } } = state();
  const { frost_depth, foundation_type } = projectEnvConfig;

  if (isULA(productId)) {
    if (value < 1.5) {
      dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, 1.5));
    }
    if (value > 5) {
      dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, 5));
    }
    return;
  }

  if (!frontEdgeHeight.data[tilt]) return;
  const { min, max } = frontEdgeHeight.data[tilt];
  const minFrontedgeHeightForFrostDepthGftForTilt_20 = 2;
  const maxFrontedgeHeightForFrostDepthGftForTilt_20 = 4.5;
  const minFrontedgeHeightForFrostDepthGftForTilt_30 = 2.5;
  const maxFrontedgeHeightForFrostDepthGftForTilt_30 = 3;
  if (value < min) {
    dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, min));
  }

  if (value > max) {
    dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, max));
  }
  if (frost_depth > 3.5 && foundation_type === FoundationType.CONCRETE_PILE) {
    if (tilt === 20) {
      if (value < minFrontedgeHeightForFrostDepthGftForTilt_20) {
        dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, minFrontedgeHeightForFrostDepthGftForTilt_20));

      } else if (value > maxFrontedgeHeightForFrostDepthGftForTilt_20) {
        dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, maxFrontedgeHeightForFrostDepthGftForTilt_20));

      }

    } else if (tilt === 30) {
      if (value < minFrontedgeHeightForFrostDepthGftForTilt_30) {
        dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, minFrontedgeHeightForFrostDepthGftForTilt_30));

      } else if (value > maxFrontedgeHeightForFrostDepthGftForTilt_30) {
        dispatch(SET_PROJECT_OPTION(frontEdgeHeight.apiField, maxFrontedgeHeightForFrostDepthGftForTilt_30));

      }

    }

  }
}

export function validateProjectOptions(field: keyof projectEnvConfig) {
  const { projectConfiguration: { projectEnvConfig, } } = state();

  if (field === iceThicknessApiField) {
    const value = projectEnvConfig[field];
    validateIceThickness(value);
  }

  if (field === windOnIceApiField) {
    const value = projectEnvConfig[field];
    validateWindOnIce(value);
  }

  if (field === frontEdgeHeightApiField) {
    const value = projectEnvConfig[field];
    const { tilt } = projectEnvConfig;
    validateFrontEdgeHeight(value, tilt);
  }

}

export const moduleLengthCheckForGFT = module_length => module_length > 83 && module_length <= 97;