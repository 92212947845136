import React from 'react';
import { state } from '__common/store';
import { checkforBeforeRM5Revamp } from '__common/utils/versionCompare/versionCompare';

export const roofSlopeWarningOld = <div className="warning-soft-message">Roof slope greater than 3 deg requires attachments</div>;

export const buildingStoreyWarningOld = <div className="warning-soft-message">Building height greater than 6 storey requires attachments</div>;

export const buildingHeightGreaterThan72WarningOld = <div className="warning-soft-message">Building height greater than 72ft requires attachments</div>;


export const roofSlopeWarningNew = <div className="warning-message">Roof slope greater than 3 deg requires attachments</div>;

export const buildingStoreyWarningNew = <div className="warning-message">Building height greater than 6 storey requires attachments</div>;

export const buildingHeightGreaterThan72WarningNew = <div className="warning-message">Building height greater than 72ft requires attachments</div>;

export const riskCategoryWarning = <div className="warning-message">Risk Category IV requires attachments</div>;

export const roofSlopeWarning = () => {
   const {projectConfiguration: {projectVersion}} = state();
    return checkforBeforeRM5Revamp(projectVersion) ? roofSlopeWarningOld: roofSlopeWarningNew;
}

export const buildingStoreyWarning = () => {
    const {projectConfiguration: {projectVersion}} = state();
     return checkforBeforeRM5Revamp(projectVersion) ? buildingStoreyWarningOld: buildingStoreyWarningNew;
 }

 export const buildingHeightGreaterThan72Warning = () => {
    const {projectConfiguration: {projectVersion}} = state();
     return checkforBeforeRM5Revamp(projectVersion) ? buildingHeightGreaterThan72WarningOld: buildingHeightGreaterThan72WarningNew;
 }
