import * as PIXI from 'pixi.js';
import { getCoords } from '../components/roofEdges/roofEdgesCollisionsHelper';
import { ASCE_7_16_GREEN, ASCE_7_16_ORANGE, ASCE_7_16_SALMON, ASCE_7_16_VIOLET, ASCE_7_16_BROWN, ASCE_7_16_SECOND_EAVE, ASCE_7_16_SECOND_RIDGE, MIDDLE_ROOF_COLOR, NEAR_ONE_EDGE_COLOR, ON_THE_CORNER_COLOR, ASCE_7_16_BEIGE } from './utils/panelColor';
import { getZoneDirection } from '../components/roofZones/roofZonesHelper';
import { EDGES_TYPE } from "../components/roofZones/utils/edgesType";
import { RoofZonesCorner } from '../components/roofZones/roofZones';
import { edge, getEdgeType, getEdgePoints } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { DEBUG } from 'debug';
import { getCornerArea } from '__editor/panelsEditor/components/roofZones/utils/getCornerArea';
import { isResidentialProduct } from '__common/constants/products';
import { captureException } from '__common/utils/sentry';
// 1 - cover, 0 - transparent 
const alpha = 1;

export const roofZoneLine = (edge: edge, productId: number, color?: number, index?: number) => {
  const edgeType = getEdgeType(edge);
  const zone = getZoneDirection(getEdgePoints(edge));

  let col: number;
  let zIndex: number;

  if (color !== undefined) {
    col = color;
  } else  if (isResidentialProduct(productId) && edgeType !== undefined) {
    switch (edgeType) {
      case EDGES_TYPE.EAVE:
        col = ASCE_7_16_SECOND_EAVE;
        zIndex = 2;
        break;
      case EDGES_TYPE.RIDGE:
        col = ASCE_7_16_SECOND_RIDGE;
        zIndex = 2;
        break;
      case EDGES_TYPE.RAKEGABLE:
        col = ASCE_7_16_ORANGE;
        zIndex = 1;
        break;
      default:
        col = color;
    }
  } else {
    switch (zone) {
      case 'N':
        col = ASCE_7_16_GREEN;
        zIndex = 2;
        break;
      case 'S':
        col = ASCE_7_16_SALMON;
        zIndex = 2;
        break;
      case 'E':
        col = ASCE_7_16_ORANGE;
        zIndex = 1;
        break;
      case 'W':
        col = ASCE_7_16_ORANGE;
        zIndex = 1;
        break;
      default:
        col = color;
    }
  }

  const line = new PIXI.Graphics();
  const polygonCoords = getCoords(edge);
  polygonCoords.push(polygonCoords[0]);
  polygonCoords.push(polygonCoords[1]);
  line.beginFill(col, alpha);
  line.drawPolygon(polygonCoords);
  line.endFill();
  line.id = getLineId(index);
  line.zIndex = zIndex;

  if (DEBUG.showCornerAndEdgeIndex && index !== undefined) {
    _drawEdgeIdex(line, index);
  }

  return line;
};

const _drawEdgeIdex = (line: PIXI.Graphics, index: number) => {
  const { right, left, top, bottom } = line.getBounds();
  const indexText = new PIXI.Text(String(index), { fontSize: '14px' });
  indexText.width = 20;
  indexText.height = 20;
  indexText.x = (right + left) / 2;
  indexText.y = (bottom + top) / 2;
  indexText.zIndex = line.zIndex + 1;
  line.addChild(indexText);
};

const getLineId = (index?: number): string => {
  return `RoofZoneLine${index !== undefined ? `-${index}` : ''}`;
};

export const roofZoneCorner = (corner: RoofZonesCorner, productId: number, radius: number, zone: string, color?: number) => {
  let col: number;

  if (color !== undefined) {
    col = color;
  } else {
    col = getCommercialZoneColor(zone, color);
  }

  const cornerModel = new PIXI.Graphics();
  cornerModel.beginFill(col, alpha);
  cornerModel.lineStyle(0);
  cornerModel.zIndex = 3;
  cornerModel.id = getCornerId(corner.index);
  const cornerArea = getCornerArea(corner);
  if (cornerArea !== null && cornerArea.length > 0) {
    cornerModel.drawPolygon(cornerArea.map(makePixiPoint));
  }
  cornerModel.endFill();

  if (DEBUG.showCornerAndEdgeIndex && corner.index !== undefined) {
    _drawCornerIndex(corner, cornerModel);
  }
  return cornerModel;
};

const _drawCornerIndex = (corner: RoofZonesCorner, cornerModel: PIXI.Graphics) => {
  const { left, right, top, bottom } = cornerModel.getBounds();
  const width = right - left;
  const height = bottom - top;
  const indexText = new PIXI.Text(String(corner.index), { fontSize: '12px' });
  indexText.id = 'cornerIndexText';
  indexText.width = width;
  indexText.height = height;
  indexText.x = corner.pos.x - width / 2;
  indexText.y = corner.pos.y - height / 2;
  indexText.zIndex = cornerModel.zIndex + 1;
  cornerModel.addChild(indexText);
};

const getCornerId = (index?: number): string => {
  return `RoofZoneCorner${index !== undefined ? `-${index}` : ''}`;
};

const makePixiPoint = (point: pixelPoint): PIXI.Point => {
  return new PIXI.Point(point.x, point.y);
};

const getCommercialZoneColor = (zone: string, defaultColor: number): number => {
  switch (zone) {
    case 'NE':
    case 'NW':
    case 'EN':
    case 'WN':
      return ASCE_7_16_VIOLET;
    case 'SE':
    case 'SW':
    case 'ES':
    case 'WS':
      return ASCE_7_16_BROWN;
    case 'N':
      return ASCE_7_16_GREEN;
    case 'S':
      return ASCE_7_16_SALMON;
    case 'E':
      return ASCE_7_16_ORANGE;
    case 'W':
      return ASCE_7_16_ORANGE;
    default:
      return defaultColor;
  }
};

export const getResidentialZoneColor = (zone: roofZoneNumber): number => {
  switch (zone) {
    case 1:
      return MIDDLE_ROOF_COLOR;
    case 2:
      return NEAR_ONE_EDGE_COLOR;
    case 3:
      return ON_THE_CORNER_COLOR;
    case 5:
      return ASCE_7_16_BEIGE;      
    default:
      break;
  }
};
