import * as React from 'react';
import findKey from 'lodash/findKey';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import ModuleSelectorComponent from '__common/components/moduleSelector/moduleSelectorComponent';
import OtherUserSettings from 'userPreferences/components/otherUserSettings';
import UpdateButton from 'userPreferences/components/updateButton';
import { connect } from 'react-redux';
import { loadDefaultModule } from '__common/components/moduleSelector/moduleSelector';
import { getProductName, products } from '__common/constants/products';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import TextField from 'react-md/lib/TextFields/TextField';
import { SET_USER_ZIPCODE, SET_AREA, SET_USER_RAFTER_SPACING } from 'userPreferences/userPreferencesActions';
import {
  SET_USER_HELIOSCOPE,
  SET_USER_HELIOSCOPE_LOGIN,
  SET_USER_HELIOSCOPE_PASSWORD,
  SET_USER_SPANS,
  SET_STAGGER_ATTACHMENTS,
} from 'actions';
import rafterSpacing from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import { getAreaByZip } from '__editor/components/roofsSelector/components/roofsSelectorMap/utils/getAreaByZip';
import { ICITY_STATE } from '__editor/components/roofsSelector/components/roofsSelectorMap/utils/cityModel';

type Props = {
  dispatch: Function;
  moduleSelector: moduleSelectorState;
  userPreferences: userPreferencesState;
};

class UserPreferences extends React.Component<Props, {}> {
  validationTimoeout;

  UNSAFE_componentWillMount() {
    loadDefaultModule(getProductName(9));
  }

  setZipcode = (zipcode: string) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_ZIPCODE(zipcode));
    if (zipcode && zipcode.length === 5) {
      this.updateCityName(zipcode);
    }
  }

  updateCityName = (zipCode: string) => {
    const { dispatch } = this.props;
    getAreaByZip(zipCode, (data: ICITY_STATE) => dispatch(SET_AREA(`${data.city}, ${data.state}`)));
  }

  helioscopeCredentials = () => {
    const {
      userPreferences: {
        helioscope_username,
        helioscope_password,
        helioscope_user,
      },
    } = this.props;
    const visibility = helioscope_user ? 'visible' : 'hidden';
    return (
      <div className="helioscope-credentials" style={{ visibility }}>
        <TextField
          floatingLabel={false}
          id="heliscope-user-login"
          label="HELIOSCOPE USERNAME"
          placeholder="Type helioscope login"
          value={helioscope_username || ''}
          onChange={this.setHelioscopeLogin}
        />
        <TextField
          floatingLabel={false}
          id="helioscope-user-password"
          label="HELIOSCOPE PASSWORD"
          placeholder="Type helioscope password"
          type="password"
          value={helioscope_password || ''}
          onChange={this.setHelioscopePassword}
        />
      </div>
    );
  }

  enableHelioscopeUser = (enabled: boolean) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_HELIOSCOPE(enabled));
  }

  setHelioscopeLogin = (login: string) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_HELIOSCOPE_LOGIN(login));
  }

  setHelioscopePassword = (password: string) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_HELIOSCOPE_PASSWORD(password));
  }

  setSpans = (spans: number) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_SPANS(spans));
  }

  setRafterSpacing = (rafterSpacing: number) => {
    const { dispatch } = this.props;
    dispatch(SET_USER_RAFTER_SPACING(Number(rafterSpacing)));

    if (this.validationTimoeout) {
      clearTimeout(this.validationTimoeout);
    }

    this.validationTimoeout = setTimeout(() => {
      this.validateRafterSpacing(rafterSpacing);
      clearTimeout(this.validationTimoeout);
    },                                   2000);
  }

  validateRafterSpacing = (rafterSpacingVal) => {
    const { dispatch } = this.props;

    if (rafterSpacingVal < rafterSpacing.min) {
      dispatch(SET_USER_RAFTER_SPACING(1));
    } else if (rafterSpacingVal > rafterSpacing.max) {
      dispatch(SET_USER_RAFTER_SPACING(144));
    }
  }

  setStaggerAttachments = (stagger_attachments: number) => {
    const { dispatch } = this.props;
    if (stagger_attachments) {
      dispatch(SET_STAGGER_ATTACHMENTS(Boolean(true)));
    } else {
      dispatch(SET_STAGGER_ATTACHMENTS(Boolean(false)));
    }
  }

  renderError() {
    const {
      userPreferences: { error },
    } = this.props;
    let errorDiv;
    if (error !== null) {
      if (error['non_field_errors']) {
        errorDiv = (
          <div className="input-warning">{error['non_field_errors']}</div>
        );
      }
    }

    return errorDiv;
  }

  render() {
    const {
      moduleSelector: {
        modelData: { width, height, thickness },
      },
      userPreferences: {
        zipcode,
        product,
        helioscope_user,
        preferred_span,
        stagger_attachments,
        rafter_spacing_inches,
        area,
      },
    } = this.props;

    return (
      <div className="app-content">
        <Header />
        <div className="user-preferences">
          <div className="user-settings">
          <div className="main-title">
              Default Values for New Projects
          </div>
          <ModuleSelectorComponent
                  noTitle={true}
                  noCustomModule={true}
                  fullSelectWidth={true}
                  productId={findKey(products, val => val === product)}
                  hideSameDimsButton={true}
                  hideRefreshButton={true}
          />
            {this.renderError()}
            <div className="settings">
              <div className="panels-settings">
                <div>
                  <div className="input-label">ZIPCODE</div>
                  <TextField
                    id="zipcode"
                    lineDirection="center"
                    value={zipcode}
                    onChange={this.setZipcode}
                    fullWidth={true}
                  />
                </div>
                <div>
                  <div className="input-label">Stagger attachment</div>
                  <SelectField
                    id="stagger-attachment"
                    simplifiedMenu={true}
                    menuItems={[
                      { name: 'YES', value: 1 },
                      { name: 'NO', value: 0 },
                    ]}
                    value={stagger_attachments ? 1 : 0}
                    onChange={this.setStaggerAttachments}
                    itemLabel="name"
                    itemValue="value"
                    fullWidth={true}
                  />
                </div>
                                <div>
                  <div className="input-label">SPAN</div>
                  <TextField
                    id="spans"
                    value={preferred_span}
                    onChange={this.setSpans}
                    fullWidth={true}
                  />
                </div>
                <div>
                  <div className="input-label">Rafter spacing</div>
                  <TextField
                    id="rafter_spacing"
                    type="number"
                    min={1}
                    max={144}
                    value={rafter_spacing_inches}
                    onChange={this.setRafterSpacing}
                    fullWidth={true}
                  />
                </div>
              </div>
              <OtherUserSettings />
            </div>
            <div className="helioscope-user-checkbox">
              <div className="input-label">Helioscope user</div>
              <SelectionControl
                id="helioscope-user-checkbox"
                name="helioscope-checkbox"
                type="checkbox"
                aria-label="helioscope-checkbox"
                checked={helioscope_user}
                onChange={this.enableHelioscopeUser}
              />
            </div>
            {this.helioscopeCredentials()}
            <UpdateButton />
          </div>
          <div className="user-preview">
            <div className="main-title">Preferences status</div>
            <div className="details">
              <div className="detail">
                <div className="label">CITY, STATE</div>
                <div className="value">{area}</div>
              </div>
              <div className="detail">
                <div className="label">MODEL WIDTH</div>
                <div className="value">
                  {width}
                  ''
                </div>
              </div>
              <div className="detail">
                <div className="label">MODEL LENGTH</div>
                <div className="value">
                  {height}
                  ''
                </div>
              </div>
              <div className="detail">
                <div className="label">MODEL THICKNESS</div>
                <div className="value">
                  {thickness}
                  ''
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    userPreferences: appState.userPreferences,
    moduleSelector: appState.moduleSelector,
  };
}

export default connect(mapStateToProps)(UserPreferences);
