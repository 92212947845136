export function getMetalXProjectConfigurationData(projectEnvConfig: any) {
  const {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    elevation,
    building_height,
    roof_type,
    wind_exposure,
    stagger_attachments: staggered,
    preferred_span,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    shortest_building_length,
    pro_clamps,
    risk_category,
    material_thickness,
    topographical_factor_kzt,
    attachment_type,
  } = projectEnvConfig;

  return {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    elevation,
    building_height,
    roof_type,
    wind_exposure,
    stagger_attachments: staggered,
    preferred_span,
    rafter_spacing_inches,
    env_factors_for,
    client_address,
    client_name,
    created_user_time,
    building_type,
    shortest_building_length,
    pro_clamps: pro_clamps ? 1 : 0,
    risk_category,
    material_thickness,
    topographical_factor_kzt,
    attachment_type,
  };
}
