export default (config?: BuildingCodeTooltipConfig) => {
  const defaultConfig: BuildingCodeTooltipConfig = { 
    asce705enabled: true, 
    asce710enabled: true, 
    asce716enabled: true,
    asce722enabled: true,
  };

  const tooltipConfig = {
    ...defaultConfig,
    ...config,
  };

  const buildingCodes = [
    ...(tooltipConfig.asce705enabled ? ['ASCE 7-05 (IBC 2006)'] : []),
    ...(tooltipConfig.asce710enabled ? ['ASCE 7-10 (IBC 2009)'] : []),
    ...(tooltipConfig.asce716enabled ? ['ASCE 7-16 (IBC 2018)'] : []),
    ...(tooltipConfig.asce722enabled ? ['ASCE 7-22 (IBC 2018)'] : []),
  ].join(' or ');

  return `
    <div class="content">
      Please confirm with your local building authority whether your installation
      must comply with code ${buildingCodes}.
    </div>
  `;
};

export interface BuildingCodeTooltipConfig {
  asce705enabled?: boolean;
  asce710enabled?: boolean;
  asce716enabled?: boolean;
  asce722enabled?: boolean;
}
