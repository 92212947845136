import * as React from 'react';
import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import { connect } from 'react-redux';
import { inchesToMeters, metersToInches } from 'maths';
import { SET_ROOF_ROW_SPACING, SET_ROW_SPACING } from 'actions';
import { deleteAllPanels } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import { isRMGridFlex, isRmGridflex10 } from '__common/constants/products';

type Props = {
  productId: number,
  dispatch: Function,
  panels: panelInState[],
  rowSpacing: number,
  drawingManager: drawingManagerState,
  selectedRoofId: number,
};

class RowSpacing extends React.Component<Props> {
  row_spacings = [
    {
      name: '7.50 in',
      type: 7.5,
    },
    {
      name: '11 in',
      type: 11,
    },
  ];

  gridflex_row_spacing = [
    {
      name: '8 in',
      type: 8,
    },
    {
      name: '11 in',
      type: 11,
    },
  ];

  gridflex_10_row_spacing = [
    {
      name: '10 in',
      type: 10,
    },
    {
      name: '13 in',
      type: 13,
    },
    {
      name: '17 in',
      type: 17,
    },
  ];

  getRowSpacing = (productId) => {
    
    if (isRMGridFlex(productId)) {
      return this.gridflex_row_spacing;
    }
    else if(isRmGridflex10(productId)){
      return this.gridflex_10_row_spacing;
    }

    return this.row_spacings;
  }

  _hasPanels = () => {
    const { panels } = this.props;

    return panels && Array.isArray(panels) && panels.length > 0;
  }

  _getCurrentRowSpacing = () => {
    const { selectedRoofId, drawingManager: { roofs }, rowSpacing } = this.props;
    const spacing = roofs[selectedRoofId].rowSpacing || rowSpacing;
    return Number(metersToInches(spacing).toFixed(2));
  }

  getLabel = () => {
      return <div className="input-label">Select Row Spacing </div>;}
  render() {
    const { dispatch, selectedRoofId, productId } = this.props;

    const value = this._getCurrentRowSpacing();

    return (
      <ConfirmationSelectField
        items={this.getRowSpacing(productId)}
        value={value}
        label={this.getLabel()}
        onConfirm={value => {
          deleteAllPanels();
          dispatch(SET_ROW_SPACING(inchesToMeters(value)));
          dispatch(SET_ROOF_ROW_SPACING(selectedRoofId, inchesToMeters(value)));
        }}
        title="Are you sure you want to change the model"
        content="All array layouts will be lost."
        shouldConfirm={this._hasPanels()}
        selectFieldProps={{
          id: 'row-spacing',
          itemLabel: 'name',
          itemValue: 'type',
        }}
      />
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    panels: appState.panels.panels,
    rowSpacing: appState.settings.rowSpacing,
    drawingManager: appState.drawingManager,
    selectedRoofId: appState.background.selectedRoofId,
  };
}

export default connect(mapStateToProps)(RowSpacing);
