import { state, dispatch } from '__common/store';
import { isCursorInForbidenArea, isCursorSnapping, isCursorInZone } from '../cursor';
import { SET_ASCE_7_16_SNAPPING_CURSOR } from 'actions';
import { SET_ASCE_7_16_GREEN_CURSOR, SET_ASCE_7_16_YELLOW_CURSOR, SET_ASCE_7_16_VIOLET_CURSOR, SET_ASCE_7_16_ORANGE_CURSOR, SET_ASCE_7_16_BLACK_CURSOR } from '../cursorActions';
import { ASCE_716_ROOF_ZONES } from '../../roofZones/roofZoneNumbers';

export const asce716ResidentialsMapColors = (cursor: PIXI.Graphics, cursorColide: boolean, mapType: string, productId: string, roofZone: number) => {
  const { settings: { canvasCenter, panelWidth, panelHeight }, editorCursor: { colour }, background: { selectedRoofId, moveArrayMode}, panels: {panelsToBeMoved} } = state();
  const x = cursor.x - canvasCenter.x;
  const y = cursor.y - canvasCenter.y;

  const snappingId = 9;

  const isInForbbidenZone = isCursorInForbidenArea(cursor, cursorColide, colour, x, y, panelWidth, panelHeight, selectedRoofId, mapType);

  if (isInForbbidenZone && colour !== ASCE_716_ROOF_ZONES.EAST_WEST_EDGES) {
    return dispatch(SET_ASCE_7_16_ORANGE_CURSOR());
  }

  if (moveArrayMode && panelsToBeMoved.length > 0) {
    return dispatch(SET_ASCE_7_16_VIOLET_CURSOR());
  }

  if (isCursorSnapping(cursorColide, isInForbbidenZone, colour, productId, snappingId) && colour !== snappingId) {
    return dispatch(SET_ASCE_7_16_SNAPPING_CURSOR());
  }

  if (isCursorInZone(cursorColide, isInForbbidenZone, colour, roofZone, 1, snappingId)) {
    return dispatch(SET_ASCE_7_16_GREEN_CURSOR());
  }
  
  if (isCursorInZone(cursorColide, isInForbbidenZone, colour, roofZone, 2, snappingId)) {
    return dispatch(SET_ASCE_7_16_YELLOW_CURSOR());
  }

  if (isCursorInZone(cursorColide, isInForbbidenZone, colour, roofZone, 3, snappingId)) {
    return dispatch(SET_ASCE_7_16_BLACK_CURSOR());
  }

  if (isCursorInZone(cursorColide, isInForbbidenZone, colour, roofZone, 5, snappingId)) {
    return dispatch(SET_ASCE_7_16_VIOLET_CURSOR());
  }
};

