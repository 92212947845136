import productSettings from './productsSettingsData';
import { getProductTitle } from '__common/constants/products_titles';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { isSolarMount, products } from '__common/constants/products';
import { RESET_PROJECT_CONFIGURED, SET_INPUT_UNIT, SET_PROJECT_NAME, SET_PROJECT_OPTION } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import {
  SET_COLUMN_SPACING,
  SET_LANDSCAPE_CURSOR,
  SET_PORTRAIT_CURSOR,
  SET_PRODUCT_ID,
  SET_ROOF_ZONES_AUTOFILL,
  SET_ROW_SPACING,
  SET_SPAN,
  SET_RAILS_PRODUCT_ID,
  SET_TILTED_ROOF,
  SET_DEFAULT_MAP_ROOFS_SELECTOR,
  SET_TILTED_ROOF_STRUCTURE_TILT,
  SET_PRODUCT_TITLE,
} from 'actions';
import { SET_TABLE_ROW_SPACING } from '../settings/settingsActions';
import { SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH } from '../background/backgroundActions';
import { dispatch, state } from '__common/store';
import { greaterThanEqualToProjectVersion } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
import { userHasSmAscenderFlushPermission } from 'user/userGroups';
import { InputUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

const RM10EVO_CORRECT_ROW_SPACING = 13.5;
const RM10EVO_OLD_ROW_SPACING = 13;
const getRm10EvoRowSpacingToUse = (projectVersion) => greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['rm10evo_row_spacing_change'])? RM10EVO_CORRECT_ROW_SPACING: RM10EVO_OLD_ROW_SPACING;

export function setProductSettings(productId: number, mapType: string, isProjectLoading: boolean, projectVersion: string="") {
  switch (productId) {
    case products.solarmount_2:
      SM(productId, isProjectLoading);
      break;
    case products.solarmount_2_tilt:
    case products.nxt_tilt:
    // case products.adjustable_tilt_system:
      SMTilt(productId, isProjectLoading, projectVersion);
      break;
    case products.sunframe:
      SF(productId, isProjectLoading);
      break;
    case products.sfmEnhanced:
    case products.sfmInfinity:
      SfmEnhanced(productId, isProjectLoading);
      break;
    case products.metal_x:
      MetalX(productId, isProjectLoading);
      break;
    case products.rm:
    case products.rm_10_ultra:
      RM10(productId, isProjectLoading)
      break;
    case products.rm_10_evolution:
      RM10EVO(productId, isProjectLoading, projectVersion);
      break;
    case products.rm_gridflex:
      RMGridFlex(productId, isProjectLoading);
      break;
    case products.rm_gridflex_10:
      RMGridFlex10(productId, isProjectLoading);
      break;
    case products.rm_5:
      RM5(productId, isProjectLoading);
      break;
    case products.rm_dt:
      RMDT(productId, isProjectLoading);
      break;
    case products.gft:
      GFT(productId, isProjectLoading);
      break;
    case products.ula:
      ULA(productId, isProjectLoading);
      break;
    case products.ecofoot2_plus:
      ecoFoot2Plus(productId, isProjectLoading);
      break;
    case products.ascender:
      Ascender(productId, isProjectLoading);
      break;
    case products.nxt_umount:
      NxtHorizon(productId, isProjectLoading);
      break;
    case products.sm_tilt_pr:
      SMTiltPR(productId, isProjectLoading);
      break;
    default:
      break;
  }

  if (mapType === 'white') {
    dispatch(SET_ROOF_ZONES_AUTOFILL(false));
  }

  if (!isSolarMount(productId) && userHasSmAscenderFlushPermission()) {
    dispatch(SET_INPUT_UNIT(null));
  }
}


export function getProductSettings(productId: number, projectVersion: string = '') {
  switch (productId) {
    case products.solarmount_2:
    case products.solarmount_hd:
    case products.solarmount_lt:
    case products.solarmount_ascender_flush:
      return productSettings.SM;
    case products.solarmount_2_tilt:
    case products.nxt_tilt:  
      return productSettings.SMTilt;
    case products.sunframe:
      return productSettings.SF;
    case products.sfmEnhanced:
    case products.sfmInfinity:
      return productSettings.SfmEnhanced;
    case products.metal_x:
      return productSettings.MetalX;
    case products.rm:
    case products.rm_10_ultra:
      return productSettings.RM10;
    case products.rm_10_evolution:
      return { ...productSettings.RM10Evolution, rowSpacing: getRm10EvoRowSpacingToUse(projectVersion)};
    case products.rm_gridflex:
      return productSettings.RMGridFlex;
    case products.rm_gridflex_10:
      return productSettings.RMGridFlex10;  
    case products.rm_5:
      return productSettings.RM5;
    case products.rm_dt:
      return productSettings.RMDT;
    case products.gft:
      return productSettings.GFT;
    case products.ula:
      return productSettings.ULA;
    case products.ecofoot2_plus:
      return productSettings.ecoFoot2Plus;
    case products.ascender:
      return productSettings.Ascender
    case products.nxt_umount:
    case products.nxt_horizon_lt:
    case products.nxt_horizon_hd:
      return productSettings.NxtHorizon;
    case products.sm_tilt_pr:
    case products.sm_tilt_pr_lt:
      return productSettings.SMTILTPR
    default:
      break;
  }
}

function SM(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches }, } = state();
  const settings = productSettings.SM;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_RAILS_PRODUCT_ID(settings.spansProductId));
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(RESET_PROJECT_CONFIGURED());
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(SET_INPUT_UNIT(InputUnit.CUSTOMARY));

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }
  
  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function SMTiltPR(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.SMTILTPR;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_PORTRAIT_CURSOR());
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_RAILS_PRODUCT_ID(settings.spansProductId));
  dispatch(SET_TILTED_ROOF(null));
  dispatch(SET_TILTED_ROOF_STRUCTURE_TILT('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(RESET_PROJECT_CONFIGURED());
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(SET_PROJECT_OPTION('rail_direction', 'EW'))

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function NxtHorizon(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.NxtHorizon;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_RAILS_PRODUCT_ID(settings.spansProductId));
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(RESET_PROJECT_CONFIGURED());
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function SMTilt(productId: number, projectLoading: boolean, projectVersion: string) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.SMTilt;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_RAILS_PRODUCT_ID(settings.spansProductId));
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(99)));
  dispatch(SET_PROJECT_NAME(getProductTitle(99).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function Ascender(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.Ascender;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_SPAN(Math.round(inchesToMeters(preferred_span)) || settings.preferred_span));
  dispatch(SET_RAILS_PRODUCT_ID(settings.spansProductId));
  dispatch(SET_PORTRAIT_CURSOR());
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_TABLE_ROW_SPACING(inchesToMeters(settings.tableRowSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function SfmEnhanced(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.SfmEnhanced;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function MetalX(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.MetalX;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function SF(productId: number, projectLoading: boolean) {
  const { userPreferences: { preferred_span, rafter_spacing_inches } } = state();
  const settings = productSettings.SF;
  dispatch(SET_SPAN(preferred_span || settings.preferred_span));
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (rafter_spacing_inches) {
    dispatch(SET_PROJECT_OPTION('rafter_spacing_inches', rafter_spacing_inches));
  }

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function RM5(productId: number, projectLoading: boolean) {
  const settings = productSettings.RM5;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_TILTED_ROOF(null));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function RM10(productId: number, projectLoading: boolean) {
  const settings = productSettings.RM10;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF(null));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function RM10EVO(productId: number, projectLoading: boolean, projectVersion: string) {
  const settings = productSettings.RM10Evolution;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF(null));
  dispatch(SET_ROW_SPACING(inchesToMeters(getRm10EvoRowSpacingToUse(projectVersion))));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function ecoFoot2Plus(productId: number, projectLoading: boolean) {
  const settings = productSettings.ecoFoot2Plus;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF(settings.tiltedRoof));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function RMGridFlex(productId: number, projectLoading: boolean) {
  const settings = productSettings.RMGridFlex;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF(settings.tiltedRoof));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH(0, 0));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function RMGridFlex10(productId: number, projectLoading: boolean) {
  const settings = productSettings.RMGridFlex10;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_TILTED_ROOF('---'));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function RMDT(productId: number, projectLoading: boolean) {
  const settings = productSettings.RMDT;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_PORTRAIT_CURSOR());
  dispatch(SET_TILTED_ROOF(null));
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  dispatch(RESET_PROJECT_CONFIGURED());

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function GFT(productId: number, projectLoading: boolean) {
  const settings = productSettings.GFT;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_PORTRAIT_CURSOR());
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_TABLE_ROW_SPACING(inchesToMeters(settings.tableRowSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));

  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}

function ULA(productId: number, projectLoading: boolean) {
  const settings = productSettings.ULA;
  dispatch(SET_PRODUCT_ID(productId));
  dispatch(SET_PORTRAIT_CURSOR());
  dispatch(SET_ROW_SPACING(inchesToMeters(settings.rowSpacing)));
  dispatch(SET_COLUMN_SPACING(inchesToMeters(settings.columnSpacing)));
  dispatch(SET_TABLE_ROW_SPACING(inchesToMeters(settings.tableRowSpacing)));
  dispatch(SET_ROOF_ZONES_AUTOFILL(settings.autofillRoofZones));
  dispatch(SET_LANDSCAPE_CURSOR());
  dispatch(SET_PRODUCT_TITLE(getProductTitle(productId)));
  dispatch(SET_PROJECT_NAME(getProductTitle(productId).name.substring(2)));
  if (!projectLoading) {
    dispatch(SET_DEFAULT_MAP_ROOFS_SELECTOR());
  }
}
