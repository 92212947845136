import React from 'react';
import { connect } from 'react-redux';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import { GET_APP_REVISION_HISTORY } from 'app/appActions';

type Props = {
  revisionHistory: appConfigState['revision_history'];
  user: userState,
  dispatch: Function;
};


class ApplicationRevision extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(GET_APP_REVISION_HISTORY());
  }

  render() {
    const { revisionHistory, user } = this.props;
    return (
      <div>
        <Header />
        <div className="project-assumptions">
        <div className="enigneering-product-assumptions">
          {revisionHistory.length && !user.isStaff &&
          <div className="assumptions-main-header">Current Application Revision: {revisionHistory[0].application_revision}</div>}
          
          <table className='application-revision'>
            <thead>
              <tr>
              <th scope="col">Application Revision or Project version</th>
              <th scope="col">Date</th>
              <th scope="col">Products</th>
              <th scope="col">Changes</th>
              </tr>
            </thead>
            <tbody>
            {user.isStaff && revisionHistory.map(
            (data : revision_history_element)=> (
                <tr className={data.application_revision? 'application':'project'}>
                <td scope="row">{data.application_revision? ` Application Revision: ${data.application_revision}`:`Project Version: ${data.project_version}`}</td>
                <td>{data.date}&nbsp;</td>
                <td>{data.products_affected.join(", ")}</td>
                <td>
                  <ol>{data.change_log.map((changeLog, i) => <li className='change-log' key={i}> {changeLog}</li>)}</ol>
                </td>
                </tr>
                ))}
              </tbody>        
          </table>      
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    revisionHistory: state.app.revision_history,
    user : state.user,
  };
}

export default connect(mapStateToProps)(ApplicationRevision);
