import React from 'react';
import { DialogContainer } from 'react-md';
import { connect } from 'react-redux';

interface Props {
  exposureRecalculation: boolean;
}

class ExposureRecalculationModal extends React.Component<Props> {
  render() {
    return (
      <DialogContainer
        id="exposure-recalculation"
        visible={this.props.exposureRecalculation}
        title="Exposure recalculation"
        fullPage={false}
        onHide={() => {}}
        focusOnMount={false}
      >
        Recalculating panel exposure, please be patient...
      </DialogContainer>
    );
  }
}

const mapStateToProps = (appState: appState) => ({
  exposureRecalculation: appState.panels.exposureRecalculation,
});

export default connect(mapStateToProps)(ExposureRecalculationModal);


