import jQuery from 'jquery';
import { ADD_POINT_TO_ROOF, UPDATE_ONE_ROOF_CORNER } from 'actions';
import { checkOnRoofSizeOnEditor } from './roofSizeAlert';
import { dispatch, state } from '__common/store';
import { getRoofColor } from '../../../../components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import { redrawGoogleRoof, selectGoogleRoof } from '../drawingManager';
import { selectRoofLeadEdge } from '../../leadEdgeRoofSelector/utils/selectEdge';
import { findSelfIntersects } from './findGoogleMapsSelfIntersection';
import { newRoofOverlaping } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';

let markers = [];
let lastNonIntersectedMarkerPos;

export function setDraggableCorners(map: google.maps.Map, roofOverlay: google.maps.Polygon, roofMarker: google.maps.Marker, infoWindow: google.maps.InfoWindow, roofId: number) {
  markers = [];
  roofOverlay.getPath().forEach((element: google.maps.LatLng, index: number) => {
    const cornerMarker = setCornerPoint(map, roofOverlay, roofMarker, infoWindow, roofId, index);

    markers.push(cornerMarker);

    if (index !== 0) {
      const middleMarker = setMiddlePoint(roofOverlay, map, roofId, index);

      if (middleMarker) {
        markers.push(middleMarker);
      }

    }

    if (index === 0) {
      const lastMiddleMarker = setLastMiddlePoint(roofOverlay, map, roofId, index);

      if (lastMiddleMarker) {
        markers.push(lastMiddleMarker);
      }
    }
  });

  return markers;
}

export function unsetPolygons(roof) {
  const dragMarkers = roof.dragMarkers;
  if (dragMarkers && dragMarkers.length) {
    roof.dragMarkers.forEach((marker) => {
      marker.setMap(null);
    });
  }

  delete roof.dragMarkers;
  return roof;
}

function setCornerPoint(map: google.maps.Map, roofOverlay: google.maps.Polygon, roofMarker: google.maps.Marker, infoWindow: google.maps.InfoWindow, roofId: number, index: number) {
  const position = getMarkerOptions(roofId, map, roofOverlay.getPath().getAt(index));
  const marker = new google.maps.Marker(position);

  marker.addListener('dragstart', () => lastNonIntersectedMarkerPos = position);
  marker.addListener('drag', update_polygon_closure(roofOverlay, index, roofMarker, infoWindow, roofId));
  marker.addListener('dragend', (event) => {
    checkOnRoofSizeOnEditor(roofId);

    if (findSelfIntersects(roofOverlay) || newRoofOverlaping(polygon2JSON(roofOverlay), String(roofId))) {
      update_polygon_closure(roofOverlay, index, roofMarker, infoWindow, roofId, lastNonIntersectedMarkerPos.position)(event);
      lastNonIntersectedMarkerPos = undefined;
    }
    
  });

  return marker;
}

function setLastMiddlePoint(roofOverlay: google.maps.Polygon, map: google.maps.Map, roofId: number, index: number) {
  const lastIndex = roofOverlay.getPath().getLength();

  const middlePos = google.maps.geometry.spherical.interpolate(roofOverlay.getPath().getAt(index), roofOverlay.getPath().getAt(lastIndex - 1), 0.5);

  if (checkMinDistance(roofOverlay.getPath().getAt(index), middlePos)) {
    return;
  }

  const position = getMiddleMarkerOptions(roofId, map, middlePos);
  const middleMarker = new google.maps.Marker(position);

  middleMarker.id = 'middleMarker';
  middleMarker.index = index;

  middleMarker.addListener('mousedown', () => { 

    dispatch(ADD_POINT_TO_ROOF(index, getMiddleMarkerPos(index).toJSON(), roofId)); 
    
    redrawGoogleRoof(roofId);

    selectGoogleRoof(roofId, true);
    
    jQuery('.edit-array').addClass('active');


  });

  middleMarker.setDraggable(false);

  google.maps.event.trigger(middleMarker, 'drag');

  return middleMarker;
}

function setMiddlePoint(roofOverlay: google.maps.Polygon, map: google.maps.Map, roofId: number, index: number) {
  const middlePos = google.maps.geometry.spherical.interpolate(roofOverlay.getPath().getAt(index - 1),roofOverlay.getPath().getAt(index), 0.5);

  if (checkMinDistance(roofOverlay.getPath().getAt(index), middlePos)) {
    return;
  }

  const position = getMiddleMarkerOptions(roofId, map, middlePos);
  
  const middleMarker = new google.maps.Marker(position);
  
  middleMarker.id = 'middleMarker';
  middleMarker.index = index;

  middleMarker.addListener('mousedown', () => { 

    dispatch(ADD_POINT_TO_ROOF(index, getMiddleMarkerPos(index).toJSON(), roofId)); 

    redrawGoogleRoof(roofId);

    selectGoogleRoof(roofId);

    jQuery('.edit-array').addClass('active');

    setTimeout(() => {
      google.maps.event.trigger(markers[index], 'drag');
    });

  });

  middleMarker.setDraggable(false);

  return middleMarker;
}

function update_polygon_closure(roofOverlay: google.maps.Polygon, index: number, roofMarker: google.maps.Marker, infoWindow: google.maps.InfoWindow, roofId: number, position?: google.maps.LatLng) {

  return function (event) {
    if (event) {
      const { leadEdgeRoofSelector: { leadEdges } } = state();
      const edgeNumber = leadEdges[roofId];
      dispatch(UPDATE_ONE_ROOF_CORNER(roofId, index, { lat: position ? position.lat() : event.latLng.lat(), lng: position ? position.lng() : event.latLng.lng() }));
      
      if (position) {
        markers.filter(marker => !marker.id)[index].setPosition(position);
      }

      selectRoofLeadEdge({ edgeNumber, roofId });
      
      roofOverlay.getPath().setAt(index, position ? position : event.latLng);

      roofMarker.setPosition(roofOverlay.getRoofApproximateCenter());
      infoWindow.setPosition(roofOverlay.getRoofApproximateCenter());
  
      updateMiddleMarkersPosition(roofOverlay);
    }
  };
}

function updateMiddleMarkersPosition(roofOverlay: google.maps.Polygon) {
  markers = markers.map((marker: google.maps.Marker, newIndex: number) => {
    if (marker.id === 'middleMarker') {
      
      const index = marker.index;
      let middlePoint;

      if (index === 0) {
        const lastPoint = roofOverlay.getPath().getLength() - 1;
        middlePoint = google.maps.geometry.spherical.interpolate(roofOverlay.getPath().getAt(lastPoint),roofOverlay.getPath().getAt(index), 0.5);
      } else {
        middlePoint = google.maps.geometry.spherical.interpolate(roofOverlay.getPath().getAt(index - 1),roofOverlay.getPath().getAt(index), 0.5);
      }

      marker.setPosition(middlePoint);
    }

    return marker;
  });
}

function getMarkerOptions(roofId: number, map: google.maps.Map, position: google.maps.LatLng) {
  const icon = {
    path: google.maps.SymbolPath.CIRCLE,
    strokeColor: getRoofColor(roofId),
    strokeOpacity: 1,
    fillColor: '#FFFFFF',
    strokeWeight: 2,
    fillOpacity: 1,
    scale: 5,
  };

  const marker_options = {
    map,
    icon,
    flat: true,
    draggable: true,
    raiseOnDrag: false,
    position,
    zIndex: 10,
  };

  return marker_options;
}

function getMiddleMarkerOptions(roofId: number, map: google.maps.Map, position: google.maps.LatLng) {
  const icon = {
    path: google.maps.SymbolPath.CIRCLE,
    strokeColor: getRoofColor(roofId),
    strokeOpacity: 0.5,
    fillColor: '#FFFFFF',
    strokeWeight: 2,
    fillOpacity: 0.5,
    scale: 5,
  };

  const marker_options = {
    map,
    icon,
    flat: true,
    draggable: true,
    raiseOnDrag: false,
    position,
    zIndex: 10,
  };

  return marker_options;
}

function getMiddleMarkerPos(middleMarkerIndex) {
  return markers.filter(marker => marker.index === middleMarkerIndex)[0].getPosition();
}

function checkMinDistance(pos: google.maps.LatLng, pos2: google.maps.LatLng) {
  if (google.maps.geometry.spherical.computeDistanceBetween(pos, pos2) < 6) {
    return true;
  }
}

const polygon2JSON = function (boundaries) {
  const coordinates = [];
  const boundriesPaths = boundaries.getPath();
  for (let i = 0; i < boundriesPaths.getLength(); i++) {
    coordinates.push({ lat: boundriesPaths.getAt(i).lat(), lng: boundriesPaths.getAt(i).lng() });
  }
  coordinates.push(coordinates[0]);
  return coordinates;
};
