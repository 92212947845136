import { CLEAR_WARNING, SET_FIELD_INVALID, SET_FIELD_WARNING, SET_PROJECT_OPTION } from "actions";
import { SM_TILT_MODULE_LENGTH_ORIENTATION_LIMIT } from "__common/constants/modules";
import { isRM10, isRM10Evolution, isSMTilt, isRMGridFlex } from "__common/constants/products";
import { dispatch, state } from "__common/store";
import { smClampsOptions, apiField as  smClampsChoicesApiField} from "../fields/smClamps";
import { apiField as centralSupportApiField} from 'projectDesign/components/projectConfiguration/fields/centralSupport';
import { applyCentralSupportForRM5 } from "__common/utils/versionCompare/versionCompare";
import { apiField as mandatorymidsupportApiField } from '../fields/mandatorymidsupport';

export const updateProjectOptionsOnModuleDimsChange = () => {
    const {projectConfiguration : {productId, projectVersion, projectEnvConfig : {clamps_choices, snow_load}}, moduleSelector : {modelData} } = state();
    if(isSMTilt(productId)) {
      if(modelData.height > SM_TILT_MODULE_LENGTH_ORIENTATION_LIMIT) {
        if(!clamps_choices || clamps_choices == smClampsOptions.standardClamps.value) {
          dispatch(SET_PROJECT_OPTION(smClampsChoicesApiField, undefined));
          dispatch(SET_FIELD_INVALID(smClampsChoicesApiField,'Module Length above 84.42" can only have Pro and UAF Clamps'));
          dispatch(CLEAR_WARNING(smClampsChoicesApiField));
        }
        else {
          dispatch(SET_FIELD_WARNING(smClampsChoicesApiField,'Module Length above 84.42" can only have Pro and UAF Clamps'));
        }
     }
     else {
       dispatch(CLEAR_WARNING(smClampsChoicesApiField));
     }
    }
    if(checkCentralSupportConditionForSnowloadAndHeight(snow_load, modelData.height) && (isRM10(productId) || isRM10Evolution(productId) || applyCentralSupportForRM5(projectVersion))) {
      dispatch(SET_PROJECT_OPTION(centralSupportApiField,1));
    } else {    
      dispatch(SET_PROJECT_OPTION(centralSupportApiField,0));
    }
    if (isRMGridFlex(productId)) {
      dispatch(SET_PROJECT_OPTION(mandatorymidsupportApiField, disableMandatoryMidSupportCondition(modelData.height, snow_load)));
    }
  }
  
  
  
export const disableMandatoryMidSupportCondition = (height, snowload) => {
 if ((snowload>40 && height<=110 && height>=72) || (snowload>50 && height<72 && height>=50) || (snowload>20 && height>=87 || height>=90)){
  return 1;
 };
 return 0;
}

  export const checkCentralSupportConditionForSnowloadAndHeight = (snow_load, height) => {
    return (snow_load > 20 && height >= 87) || snow_load>60 || height >= 90;
  }  
