import PixiLayer from '__editor/googleMapsRoofsSelector/pixiLayer';
import { getMap } from '__editor/googleMapsRoofsSelector/components/map/map';
import { advanceRoofSelectingMove, advanceRoofSelectingClick } from '__common/components/advancedSelecting/advancedSelectingEvents';
import { measurementToolClick, measurementToolMove } from '__common/components/measurementTool/measurementToolEvents';
let pixiLayer;

export function initPixi() {
  const map = getMap();
  pixiLayer = new PixiLayer(map, {
    transparent: true,
  });
  pixiLayer.stage.on('mousemove', () => onMouseMove());
  pixiLayer.stage.on('touchmove', () => onMouseMove());
  pixiLayer.stage.on('mousedown', () => mapOnClickEvent());
  pixiLayer.stage.on('touchstart', () => mapOnClickEvent());
  pixiLayer.stage.on('touchend', () => mapOnClickEvent());
  return pixiLayer;
}

function onMouseMove() {
  if (pixiLayer) {
    advanceRoofSelectingMove(pixiLayer.renderer, pixiLayer.stage);
    measurementToolMove(pixiLayer.renderer, pixiLayer.stage);
  }
}

function mapOnClickEvent() {
  if (pixiLayer) {
    advanceRoofSelectingClick(pixiLayer.renderer, pixiLayer.stage);
    measurementToolClick(pixiLayer.renderer, pixiLayer.stage);
  }
}


export function getPixiLayer(): PixiLayer {
  if (pixiLayer) {
    return pixiLayer;
  }
}

export function clearPixiLayer() {
  if (pixiLayer) {
    if (pixiLayer.renderer.gl) {
      pixiLayer.renderer.gl.getExtension('WEBGL_lose_context').loseContext();
      pixiLayer.renderer.destroy();
    }
  }
}
