import * as React from 'react';
import { Button } from 'react-md';
import { connect } from 'react-redux';
import { launchRecheckPanelExposure } from '__editor/panelsEditor/components/panels/utils/exposureRecheck';
import { canUseExposure } from '__common/components/exposure/exposureHelper';

interface Props {
  panels: panelInState[];
  selectedRoofId: number;
  buildingCode: number;
  productId: number;
  recheckExposure: (panelsToRecheck: { recheck: number[] }) => void;
  startRecheck: () => void;
}

class RecheckExposureButton extends React.Component<Props> {
  recheckExposure = () => {
    const { selectedRoofId } = this.props;
    const panelsIdsToRecheck = this.props.panels.map(panel => panel.id);
    launchRecheckPanelExposure(panelsIdsToRecheck, selectedRoofId);
  }

  render() {
    const { buildingCode, productId } = this.props;
    if (!canUseExposure(buildingCode, productId)) {
      return null;
    }

    return (
      <Button raised={true} primary={true}  onClick={this.recheckExposure}>RECHECK EXPOSURE</Button>
    );
  }
}

const mapStateToProps = (appState: appState) => ({
  panels: appState.panels.panels,
  buildingCode: appState.projectConfiguration.projectEnvConfig.building_code,
  selectedRoofId: appState.background.selectedRoofId,
  productId: appState.projectConfiguration.productId,
});

export default connect(mapStateToProps)(RecheckExposureButton);
