export const apiField = 'rail_direction';

export default (removeUpSlope = false) => (
  {
    "label": "rail_direction",
    "id": "railDirection",
    "type": "Radio",
    apiField,
    "fields": [
      ...(removeUpSlope ? [] : [{
        "label": "UP-SLOPE",
        "value": "NS",
        "icon": "icon_direction_ns.svg"
      }]),
      {
        "label": "CROSS-SLOPE",
        "value": "EW",
        "icon": "icon_direction_ew.svg"
      }
    ]
  }
)