import { state, dispatch } from '__common/store';
import { isCursorInForbidenArea, isCursorInZone, isCursorSnapping } from '../cursor';
import { SET_BLACK_CURSOR, SET_ASCE_7_16_YELLOW_CURSOR, SET_ASCE_7_16_SNAPPING_CURSOR } from 'actions';
import { ASCE_716_ROOF_ZONES } from '../../roofZones/roofZoneNumbers';

export const asce716CommercialsBlankColors = (cursor: PIXI.Graphics, cursorColide: boolean, mapType: string, productId: string, roofZone: number) => {
  const { settings: { canvasCenter, panelWidth, panelHeight }, editorCursor: { colour }, background: { selectedRoofId, moveArrayMode}, panels: {panelsToBeMoved} } = state();
  const x = cursor.x - canvasCenter.x;
  const y = cursor.y - canvasCenter.y;
  const snappingId = 9;

  const isInForbbidenZone = isCursorInForbidenArea(cursor, cursorColide, colour, x, y, panelWidth, panelHeight, selectedRoofId, mapType);
  if (isInForbbidenZone && colour !== 3) {
    return dispatch(SET_BLACK_CURSOR());
  }

  if ((isCursorInZone(cursorColide, isInForbbidenZone, colour, roofZone, ASCE_716_ROOF_ZONES.MIDDLE, snappingId)) || (moveArrayMode && panelsToBeMoved.length > 0)) {
    return dispatch(SET_ASCE_7_16_YELLOW_CURSOR());
  }

  if (isCursorSnapping(cursorColide, isInForbbidenZone, colour, productId, snappingId) && colour !== snappingId) {
    return dispatch(SET_ASCE_7_16_SNAPPING_CURSOR());
  }
};

