import * as React from 'react';
import Autopopulate from 'projectDesign/components/Autopopulate';
import ChangeOrientationButton from '__editor/panelsEditor/components/panelsEditorControls/components/change_orientation';
import ClearArray from '__editor/panelsEditor/components/panelsEditorControls/components/ClearArray';
import Draw from '__editor/panelsEditor/components/panelsEditorControls/components/draw';
import MoveMap from '__editor/panelsEditor/components/panelsEditorControls/components/moveMap';
import MoveArray  from '__editor/panelsEditor/components/panelsEditorControls/components/moveArray';
import Redo from '__editor/panelsEditor/components/panelsEditorControls/components/redo';
import Remove from '__editor/panelsEditor/components/panelsEditorControls/components/remove';
import ScaleButton from '__editor/panelsEditor/components/panelsEditorControls/components/Scale';
import SVG from 'react-inlinesvg';
import Undo from '__editor/panelsEditor/components/panelsEditorControls/components/undo';
import Button from 'react-md/lib/Buttons/Button';
import DrawObstructions from '__editor/panelsEditor/components/panelsEditorControls/components/drawObstruction';
import { connect } from 'react-redux';
import { getRenderer } from '__editor/panelsEditor/components/renderer/renderer';
import { getStage } from '__editor/panelsEditor/components/stage/stage';
import { isRMFamily, isGFT, isEcoFoot2Plus, isSMTiltPR, isAscender, isRM10orRM10Evo, isRM10Evolution } from '__common/constants/products';
import { zoom } from '__editor/panelsEditor/components/stage/zoom';
import { MOVE_ARRAY_MODE_DISABLE, MOVE_MAP_MODE_DISABLE, TOGGLE_ATTACHMENTS_ENABLE, ENABLE_BAYS_CONTAINER } from '../background/backgroundActions';
import { TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { DISABLE_RUBBER_BAND, SET_DRAW_RUBBER_BAND } from '../rubberBand/rubberBandActions';
import { SHOW_CURSOR } from '../cursor/cursorActions';
import Compass from './components/compass';
import { isBlankMap } from '__common/constants/map';
import ToggleAttachments from './components/toggleAttachments';
import MeasuringTool from './components/measuringTool';
import ToggleRoofDimensions from './components/toggleRoofDimensions';
import { dispatch, state } from '__common/store';
import { Checkbox } from 'react-md';

type RoofsSelectorControlPanelState = {
  turnOnDrawing: () => void,
  advanceRoofSelectorEnabled: boolean;
  allowManualAttachments: boolean;
  dispatch: Function,
  mapType: string,
  isRoofSelected: boolean,
  background: backgroundState,
  productId: number,
  height : number,
  width : number,
  railsProductId?: number,
  toggleBaysContainer?: boolean,
};

class RoofsSelectorControlPanel extends React.Component<RoofsSelectorControlPanelState, {}> {
  componentDidMount() {
    this.props.turnOnDrawing();
  }
  
  /* User needs to rotate the cursor only based on these conditions but we are only using singgle orientation, hence commenting this function for now*/
  /*
  checkEcoFoot2PlusCondition(height : number, width : number, productId : number) {
    const areaInFeets = inchesToFeet(height) * inchesToFeet(width);
    return !(isEcoFoot2Plus(productId) && areaInFeets > 23.5 &&  width >= 37 && width <= 42);
  } */

  canRotatePanels() {
    const { productId } = this.props;
    return !isRMFamily(productId) && !isGFT(productId) && !(isEcoFoot2Plus(productId)) && !(isAscender(productId)) && !(isSMTiltPR(productId));
  }

  canAutoPopulate(){
    return (!isBlankMap(this.props.mapType));
  }

  renderDrawingTools() {
    const {projectConfiguration:{projectEnvConfig:{is_aurora_project}}} = state();
    return (
      <div className={is_aurora_project ? `drawing-tools buttons-containers aurora` : `drawing-tools buttons-containers`}>
        {!is_aurora_project && <Draw />}
        {!is_aurora_project && <Remove />}
        {!is_aurora_project && <DrawObstructions />}
        <MeasuringTool />
        <MoveMap />
        {!isAscender(this.props.productId) && !is_aurora_project ? <MoveArray /> : null}
        {this.props.allowManualAttachments && !is_aurora_project ? <ToggleAttachments /> : null}
        {this.canRotatePanels() && !is_aurora_project ? <ChangeOrientationButton /> : null}
        {this.canAutoPopulate() ? <Autopopulate /> : null}
        {!isBlankMap(this.props.mapType) ? <ToggleRoofDimensions /> : null}
      </div>
    );
  }

  renderScaleTool() {
    return (
      <div className="scale-tools buttons-containers">
        <ScaleButton />
      </div>
    );
  }

  historyTools() {
    return (
      <div className="history-tools buttons-containers">
        <Undo />
        <Redo />
      </div>
    );
  }

  clearArrayTool() {
    return (
      <div className="clear-array-tools buttons-containers">
        <ClearArray />
      </div>
    );
  }

  compass() {
    const { background: { rotationDegrees } } = this.props;
    
    return (
      <Compass rotation={rotationDegrees}/>
    );
  }

  renderZoomingTools() {
    const stage = getStage();
    const renderer = getRenderer();
    const { background: { bgXY } } = this.props;
    return (
      <div className="zooming-tools buttons-containers">
         <Button tooltipLabel="Zoom in" tooltipPosition="top" flat={true} onClick={() => zoom({ deltaY: -1 }, stage, renderer, bgXY)} className="roofs-selector-button"><SVG src={require('assets/media/icons/plus_icon.svg')} /></Button>
         <Button tooltipLabel="Zoom out" tooltipPosition="top" flat={true} onClick={() => zoom({ deltaY: 1 }, stage, renderer, bgXY)} className="roofs-selector-button"><SVG src={require('assets/media/icons/minus_icon.svg')} /></Button>
       </div>
    );
  }

  render() {
    const {projectConfiguration:{projectEnvConfig:{is_aurora_project}}} = state();
    return (
      <div id="roofs-selector-control-panel">
        {this.renderDrawingTools()}
        {this.historyTools()}
        {!is_aurora_project && this.clearArrayTool()}
        {this.compass()}
        {this.renderZoomingTools()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    turnOnDrawing: () => {
      //dispatch(SET_DRAW_RUBBER_BAND());
      dispatch(SHOW_CURSOR());
      dispatch(MOVE_MAP_MODE_DISABLE());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
      dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
      dispatch(TURN_OFF_MEASUREMENT_TOOL());
    },
  };
}

function mapStateToProps(state: appState) {
  return {
    advanceRoofSelectorEnabled: state.advanceRoofSelecting.enabled,
    mapType: state.roofsSelector.mapType,
    isRoofSelected: state.drawingManager.selectedIds.length > 0,
    background: state.background,
    toggleBaysContainer: state.background.toggleBaysContainer,
    productId: state.projectConfiguration.productId,
    height: state.moduleSelector.modelData.height,
    width: state.moduleSelector.modelData.width,
    allowManualAttachments: state.projectConfiguration.projectEnvConfig.allow_manual_attachments,
    railsProductId: state.projectConfiguration.railsProductId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoofsSelectorControlPanel);
