export const getRoofApproximateCenter = () => {
  google.maps.Polygon.prototype.getBoundingBox = function boundingBox() {
    const bounds = new google.maps.LatLngBounds();

    this.getPath().forEach((element) => {
      bounds.extend(element);
    });


    return (bounds);
  };

  google.maps.Polygon.prototype.getBounds = function getBounds() {
    const boundingBox = this.getBoundingBox();

    return [
      new google.maps.LatLng(boundingBox.getNorthEast().lat(), boundingBox.getNorthEast().lng()),
      new google.maps.LatLng(boundingBox.getSouthWest().lat(), boundingBox.getSouthWest().lng()),
    ];
  };

  google.maps.Polygon.prototype.getRoofApproximateCenter = function approxmiteCenter() {
    const polygonBounds = this.getBoundingBox();
    const centerPoint = polygonBounds.getCenter();

    // we want the roof area marker be the center of the polygon bounds,
    // so that the FogOfWar hole poly correctly aligns
    // with the roof area edges drawn using PIXI on the panels editor
    return centerPoint;
  };
};
