import React from 'react';
import SVG from 'react-inlinesvg';
import TextField from 'react-md/lib/TextFields/TextField';

type Props = {
  onChange: (val: string) => void,
  value: string,
  placeHolder?: string,
};

type State = {
  val: string,
};

export default class CustomName extends React.Component<Props, State> {
  state = {
    val: '',
  };

  UNSAFE_componentWillMount() {
    const { value } = this.props;
    this.setState({ val: value });
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.value !== this.props.value) {
      this.setState({ val: this.props.value });
    }
  }

  onType = (val: string) => {
    this.setState({ val });
  }

  setInStore = () => {
    const { val } = this.state;
    this.props.onChange(val);
  }

  render() {
    const { val } = this.state;
    const { placeHolder } = this.props;
    
    return (
      <span className="custom-name">
        <TextField
          id="array-custom-name"
          value={val}
          placeholder={placeHolder}
          className="name-field"
          fullWidth={true}
          inlineIndicator={
            <SVG src={require('assets/media/icons/edit_icon.svg')} />}
          style={{
            display: 'inline-block',
            fontFamily: 'PT Sans',
            fontSize: '1em',
            fontWeight: 400,
          }}
          onBlur={this.setInStore}
          onChange={this.onType}
        />
      </span>
    );
  }
}
