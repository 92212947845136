import { state } from '__common/store';
import railFinish from 'projectDesign/components/projectConfiguration/fields/railFinish';
import clampsChoice from './fields/clampsChoice';
import { isASCE716, isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import { isFlatBuildingType, isGableBuildingType, isHipBuildingType } from '__common/constants/buildingTypes';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { metersToFeets } from '__common/calculations/metersToFeets';

export const isASCE716Selected = () => {
  const { projectConfiguration: { projectEnvConfig } } = state();

  if (projectEnvConfig && Object.keys(projectEnvConfig).length && projectEnvConfig.building_code) {
    return isASCE716(projectEnvConfig.building_code) && !!projectEnvConfig.building_height;
  }
};

export const isASCE722Selected = () => {
  const { projectConfiguration: { projectEnvConfig } } = state();

  if (projectEnvConfig && Object.keys(projectEnvConfig).length && projectEnvConfig.building_code) {
    return isASCE722(projectEnvConfig.building_code) && !!projectEnvConfig.building_height;
  }
}

export const isASCE716or722Selected = () => {
  const { projectConfiguration: { projectEnvConfig } } = state();

  if (projectEnvConfig && Object.keys(projectEnvConfig).length && projectEnvConfig.building_code) {
    return isASCE716or722(projectEnvConfig.building_code) && !!projectEnvConfig.building_height;
  }
}

export const getBuildingHeight =  () => {
  const { projectConfiguration: { projectEnvConfig : { building_height }, inputUnit } } = state();

  if (building_height) {
    return isMetricUnit(inputUnit) ? metersToFeets(building_height) : building_height;
  }
};

export const getParapetHeight = () => {
  const { projectConfiguration: { projectEnvConfig } } = state();

  if (projectEnvConfig && projectEnvConfig.parapet_height) {
    return projectEnvConfig.parapet_height;
  }  
};

export const isASCE716BuildingCode = (buildingCode: number|string) => {
  return isASCE716(Number(buildingCode));
};

export const isASCE722BuildingCode = (buildingCode: number|string) => {
  return isASCE722(Number(buildingCode));
};

export const isASCE716or722BuildingCode = (buildingCode: number|string) => {
  return isASCE716(Number(buildingCode)) || isASCE722(Number(buildingCode));
};

export const isDarkRailFinish = (finish: string) => {
  return finish === railFinish.fields[2].value;
};

export const isFlatRoof = (buildingType: number) => isFlatBuildingType(buildingType);
export const isGableRoof = (buildingType: number) => isGableBuildingType(buildingType);
export const isHipRoof = (buildingType: number) => isHipBuildingType(buildingType);

export const areProClampsSelected = (clamps: number) => clamps === clampsChoice.data[0].value;
export const areStandardClampsSelected = (clamps: number) => clamps === clampsChoice.data[1].value;

export function getYesOrNo() {
  return [
    { value: 1, name: 'YES' },
    { value: 0, name: 'NO' },
  ]
}
