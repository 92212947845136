import * as React from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL, SET_COLUMN_SPACING, SET_RAILS_PRODUCT_ID, SET_ROW_SPACING, } from 'actions';
import { dispatch, state } from '__common/store';
import { isNxtHorizon, isSolarMount, products, isSMTiltPR, isAscender, isSMRegular, isSMAscenderFlush, isSMHD, isSMLT } from '__common/constants/products';
import { NxtHorizonRailType, AscenderRailType, SmFlushRailType } from '../projectConfiguration/utils/constants';
import { removeSMHDrailoptionSMFlush, adjustableTiltSystem, applyRemoveSMLightOption } from '__common/utils/versionCompare/versionCompare';
import { getStructuretype } from '../StructureTypeChange';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { userHasSmAscenderFlushPermission } from 'user/userGroups';
import { updateProjectOptionOnRailTypeChange } from '../projectConfiguration/utils/updateProjectOption';
import {OPEN_DRAWER_PAGE, SET_FLYOUT_VERIFIED, SET_PROJECT_OPTION } from '../projectConfiguration/projectConfigurationActions';
import { RAIL_SYSTEM_PAGE } from '../projectConfiguration/utils/projectConfigurationDrawerPages';
import { smClampsOptions } from '../projectConfiguration/fields/smClamps';
import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { inchesToMeters } from 'maths';

type Props = {
  dispatch: Function;
  projectConfiguration: projectConfigurationState;
  productId: number;
  railsTypeChanger: railsTypeChangerState,
  userPermissions?: string[],
};

class ProductChanger extends React.Component<Props, {}> {

  onProductChange(productId: number) {
    if(isSMAscenderFlush(productId)) {
      if (anyPanelsDrawn() && this.props.projectConfiguration.projectEnvConfig.mid_clamps_choices !== smClampsOptions.universalClamps.value) {
        dispatch(OPEN_DRAWER_PAGE(null));
        this.openConfirmationModal(this.setRailType, productId);
      }else if (!anyPanelsDrawn()) {
        // set spacings for uaf clamps
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
        this.setRailType(productId);
      }else {
        this.setRailType(productId);
      }
    }
    else {
      this.setRailType(productId);
    }
  }

  openConfirmationModal(cb, productId) {
    dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        'Are you sure you want to change the module specification?',
        'All array layouts will be lost.',
        () => {
          cb(productId, true);
          makeAllRoofsEmpty();
          dispatch(OPEN_DRAWER_PAGE('rail-system'));
          // set spacings for uaf clamps
          dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
          dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
        },
        () => {
          dispatch(OPEN_DRAWER_PAGE('rail-system'));
        },
      ),
    );
  }

  setRailType(productId: number, allPanelsDeleted: boolean = false) {
    const { projectConfiguration :{ projectEnvConfig: { tilt } } }=state();
    dispatch(SET_RAILS_PRODUCT_ID(Number(productId)));
    dispatch(SET_FLYOUT_VERIFIED(RAIL_SYSTEM_PAGE, false))
    if (isSolarMount(productId)&& tilt === null ) {
      if (isSMRegular(productId)) {
        dispatch(SET_PROJECT_OPTION('rail_type',SmFlushRailType.SM));
      }else if (isSMLT(productId)) {
        dispatch(SET_PROJECT_OPTION('rail_type',SmFlushRailType.SM_LT));
      }else if (isSMHD(productId)) {
        dispatch(SET_PROJECT_OPTION('rail_type',SmFlushRailType.SM_HD));
      }
      else if (isSMAscenderFlush(productId)) {
        dispatch(SET_PROJECT_OPTION('rail_type',SmFlushRailType.SM_ASCENDER_FLUSH));
      }

    } else if (isSMTiltPR(productId)) {
      if (productId === products.sm_tilt_pr) {
        dispatch(SET_PROJECT_OPTION('rail_type',SmFlushRailType.SM));
      }else if (productId === products.sm_tilt_pr_lt) {
        dispatch(SET_PROJECT_OPTION('rail_type',SmFlushRailType.SM_LT));
      }

    }else if(isNxtHorizon(productId)) {
      if (productId === 26) {
        dispatch(SET_PROJECT_OPTION('rail_type',NxtHorizonRailType.NH));
      }else if (productId === 27) {
        dispatch(SET_PROJECT_OPTION('rail_type',NxtHorizonRailType.NH_LT));
      }else if (productId === 28) {
        dispatch(SET_PROJECT_OPTION('rail_type',NxtHorizonRailType.NH_HD));
      }

    }else if(isAscender(productId)) {
      dispatch(SET_PROJECT_OPTION('rail_type',AscenderRailType.ASCENDER));
    }
    if (userHasSmAscenderFlushPermission()) {
      updateProjectOptionOnRailTypeChange(allPanelsDeleted);
    }
  }


  SpansValues(productId: number, spanValue: any) {

    if (isSMRegular(productId)) {
      return this.SMSpansValues(spanValue);
    }

    if (productId === 99) {
      return this.SMTiltSpansValues(spanValue);
    }
    
    if (productId === 25 || productId === 26 || productId === 27) {
      return this.NxtHorizonSpansValues(spanValue)
    }

    if (isSMTiltPR(productId)) {
      return this.SMTiltPRSpansValues(spanValue)
    }

    if (isAscender(productId)) {
      return this.AscenderSpansValues(spanValue);
    }
    
  }

  _SMTilt = () => {};

  SMSpansValues(spanValue: number) {
    const {
      projectConfiguration: { railsProductId, projectVersion, inputUnit },
    } = this.props;
    
      return (
        <div>
          <div className="products sm-tilt-products">
            <div
              className={`rail_product sm_light ${
                railsProductId === 11 ? 'active' : ''
              }`}
            >
              <div
                className="rail_type_icon"
                onClick={this.onProductChange.bind(this, 11)}
              >
                <SVG src={require('assets/media/icons/rail_light.svg')} />
                <div className="title">SM Light</div>
              </div>
              <div className="input-label">max cantilever</div>
              <div className="input-cantilever">{spanValue}{isMetricUnit(inputUnit) ?' cm': '"'}</div>
            </div>
            <div
              className={`rail_product sm_standard ${
                railsProductId === 9 ? 'active' : ''
              }`}
            >
              <div
                className="rail_type_icon"
                onClick={this.onProductChange.bind(this, 9)}
              >
                <SVG src={require('assets/media/icons/rail_standard.svg')} />
                \
                <div className="title">SM Standard</div>
              </div>
              <div className="input-label">max cantilever</div>
              <div className="input-cantilever">{spanValue}{isMetricUnit(inputUnit) ?' cm': '"'}</div>
            </div>

          {userHasSmAscenderFlushPermission() && <>
                <div
                  className={`rail_product sm_standard ${
                  railsProductId === 32 ? 'active' : ''
                  }`}
                  >
                  <div
                  className="rail_type_icon"
                  onClick={this.onProductChange.bind(this, 32)}
                  >
                  <SVG src={require('assets/media/icons/rail_standard_ascender.svg')} />
                  <div className="title">Ascender Rail</div>
                  </div>
                  <div className="input-label">max cantilever</div>
                  <div className="input-cantilever">{spanValue}{isMetricUnit(inputUnit) ?' cm': '"'}</div>
                  </div>
              </>
            }

            {
              !removeSMHDrailoptionSMFlush(projectVersion) && <>
                <div
                className={`rail_product sm_heavy ${
                  railsProductId === 2 ? 'active' : ''
                }`}
                >
                <div
                  className="rail_type_icon" 
                  onClick={this.onProductChange.bind(this, 2)}
                >
                  <SVG src={require('assets/media/icons/rail_heavy.svg')} />
                  <div className="title">SM Heavy</div>
                </div>
                <div className="input-label">max cantilever</div>
                <div className="input-cantilever">{spanValue}{isMetricUnit(inputUnit) ?' cm': '"'}</div>
              </div>
              </>
            }

          </div>
        </div>
      );  
  }

  SMTiltPRSpansValues(spanValue: number) {
    const {
      projectConfiguration: { railsProductId, projectVersion }
    } = this.props;
    
      return (
        <div>
          <div className="products sm-tilt-products">
            {applyRemoveSMLightOption(projectVersion) ?
            <div
              className={`rail_product sm_light ${
                railsProductId === 31 ? 'active' : ''
              }`}
            >
              <div
                className="rail_type_icon"
                onClick={this.onProductChange.bind(this, 31)}
              >
                <SVG src={require('assets/media/icons/rail_light.svg')} />
                <div className="title">SM Light</div>
              </div>
              <div className="input-label">max cantilever</div>
              <div className="input-cantilever">{spanValue}"</div>
            </div>
            : null}
            <div
              className={`rail_product sm_standard ${
                railsProductId === 30 ? 'active' : ''
              }`}
            >
              <div
                className="rail_type_icon"
                onClick={this.onProductChange.bind(this, 30)}
              >
                <SVG src={require('assets/media/icons/rail_standard.svg')} />
                \
                <div className="title">SM Standard</div>
              </div>
              <div className="input-label">max cantilever</div>
              <div className="input-cantilever">{spanValue}"</div>
            </div>

          </div>
        </div>
      );  
    
  }

  SMTiltSpansValues(spanValue: number) {
    const {
      projectConfiguration: { railsProductId,  projectVersion },
    } = this.props;

    return (
      <div>
      {!adjustableTiltSystem(projectVersion) && <>
        <div className="products sm-tilt-products">
          <div
            className={`rail_product sm_standard ${
              railsProductId === 9 ? 'active' : ''
            }`}
          >
            <div
              className="rail_type_icon"
              onClick={this.onProductChange.bind(this, 9)}
            >
              <SVG src={require('assets/media/icons/rail_standard.svg')} />
              <div className="title">SM Standard</div>
            </div>
            <div className="input-label">max cantilever</div>
            <div className="input-cantilever">{spanValue}"</div>
          </div>
          <div
            className={`rail_product sm_heavy ${
              railsProductId === 2 ? 'active' : ''
            }`}
          >
            <div
              className="rail_type_icon"
              onClick={this.onProductChange.bind(this, 2)}
            >
              <SVG src={require('assets/media/icons/rail_heavy.svg')} />
              <div className="title">SM Heavy</div>
            </div>
            <div className="input-label">max cantilever</div>
            <div className="input-cantilever">{spanValue}"</div>
          </div>
        </div>
        </>}
      </div>
    );
  }

  AscenderSpansValues(spanValue: any[]) {
    const {
      projectConfiguration: { railsProductId },
    } = this.props;

    return (
      <div>
        <div className="products sm-tilt-products">
          <div
            className={`rail_product sm_standard ${
              railsProductId === 29 ? 'active' : ''
            }`}
          >
            <div
              className="rail_type_icon"
              onClick={this.onProductChange.bind(this, 29)}
            >
              <SVG src={require('assets/media/icons/rail_standard_ascender.svg')} />
              <div className="title">Ascender Rail</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  NxtHorizonSpansValues(spanValue: number) {
    const {
      projectConfiguration: { railsProductId },
    } = this.props;

    return (
      <div>
        <div className="products">
          <div
            className={`rail_product sm_light ${
              railsProductId === 26 ? 'active' : ''
            }`}
          >
            <div
              className="rail_type_icon"
              onClick={this.onProductChange.bind(this, 26)}
            >
              <SVG src={require('assets/media/icons/rail_light.svg')} />
              <div className="title">NXT Light</div>
            </div>
            <div className="input-label">max cantilever</div>
            <div className="input-cantilever">{spanValue}"</div>
          </div>
          <div
            className={`rail_product sm_standard ${
              railsProductId === 25 ? 'active' : ''
            }`}
          >
            <div
              className="rail_type_icon"
              onClick={this.onProductChange.bind(this, 25)}
            >
              <SVG src={require('assets/media/icons/rail_standard.svg')} />
              \
              <div className="title">NXT Standard</div>
            </div>
            <div className="input-label">max cantilever</div>
            <div className="input-cantilever">{spanValue}"</div>
          </div>
          <div
            className={`rail_product sm_heavy ${
              railsProductId === 27 ? 'active' : ''
            }`}
          >
            <div
              className="rail_type_icon"
              onClick={this.onProductChange.bind(this, 27)}
            >
              <SVG src={require('assets/media/icons/rail_heavy.svg')} />
              <div className="title">NXT Heavy</div>
            </div>
            <div className="input-label">max cantilever</div>
            <div className="input-cantilever">{spanValue}"</div>
          </div>
        </div>
      </div>
    );
  }

  spanValue() {
    const {
      projectConfiguration: { projectEnvConfig, },
      productId,
    } = this.props;
    if(isAscender(productId)) {
      return projectEnvConfig.structure_type_info.map(structureType => {
        return {
          type: getStructuretype(structureType.structure_type),
          value: Math.ceil(Number(structureType.preferred_span) / 3)
        }
      })
    }
    else {
      return  Math.ceil(Number(projectEnvConfig.preferred_span) / 3);
    }
  }

  render() {
    const {
      productId,
    } = this.props;

    return (
      <div className="product-changer">
        {this.SpansValues(productId, this.spanValue())}
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    projectConfiguration: state.projectConfiguration,
    railsTypeChanger: state.railsTypeChanger,
    userPermissions: state.user.permissions,
  };
}

export default connect(mapStateToProps)(ProductChanger);
