import * as React from 'react';
import CombinedProjectsBackButton from 'combineProjects/components/CombinedProjectsBackButton';
import CombinedProjectsHeader from 'combineProjects/components/CombinedProjectsHeader';
import CombinedProjectsPartsTable from 'combineProjects/components/CombinedProjectsPartsTable';
import CombinedProjectsTable from 'combineProjects/components/CombinedProjectsTable';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import { connect } from 'react-redux';
import { POPULATE_COMBINED_PROJECTS, GET_COMBINED_PROJECT } from 'actions';
import { state } from '__common/store';

type Props = {
  match: any,
  dispatch: Function,
  yourProjects: yourProjectsState,
};

class CombineProjects extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch, match: { params: { combinedProjectId } } } = this.props;
    dispatch(GET_COMBINED_PROJECT(combinedProjectId));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(POPULATE_COMBINED_PROJECTS({ combined_project: '', parts_list: [], projects: {} }));
  }


findinputsWithUnequalValues(arr) {
  const keyValuesMap = {};
  const keysToConsider={'client_address': 'client address', 'zipcode': 'zip code', 'product_id': 'product id', 'panel_mfg': 'manufacturer', 'panel_model': 'model', 'risk_category': 'risk category'};
  arr.forEach(obj => {
    Object.keys(keysToConsider).forEach(key => {
          if (obj.hasOwnProperty(key)) {
              if (!keyValuesMap.hasOwnProperty(key)) {
                  keyValuesMap[key] = [];
              }
              keyValuesMap[key].push(obj[key]);
          }
      });
  });

  const keysWithUnequalValues = Object.keys(keysToConsider).filter(key => {
      const uniqueValues = new Set(keyValuesMap[key]);
      return uniqueValues.size > 1;
  }).map(key=>keysToConsider[key]);

  return keysWithUnequalValues;
}

  renderPage = () => {
    const { yourProjects: { combinedPartsList, combinedProjects }, match: { params: { combinedProjectId } } } = this.props;
    const { user:{ isStaff } } = state();
    const inputListWithUnequalValues = this.findinputsWithUnequalValues(combinedProjects);
    return (
      <div className="app-content">
        <Header />
        <div className="combined-projects">
          <CombinedProjectsHeader combinedId={combinedProjectId} combinedPartsList={combinedPartsList} inputListWithUnequalValues={inputListWithUnequalValues} isStaff={isStaff}/>
          <CombinedProjectsTable data={combinedProjects} isStaff={isStaff}/>
          <CombinedProjectsPartsTable data={combinedPartsList} />
        </div>
        <Footer ContainerFooter={CombinedProjectsBackButton} />
      </div>
    );
  }

  render() {
    const { yourProjects: { combinedProjectId } } = this.props;
    const projectId = this.props.match.params.combinedProjectId;

    if (combinedProjectId === projectId) {
      return this.renderPage();
    }

    return null;
  }
}

function mapStateToProps(appState: appState) {
  return {
    yourProjects: appState.yourProjects,
  };
}

export default connect(mapStateToProps)(CombineProjects);
