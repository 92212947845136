import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper'; 
// import { ULAClampLocationType } from '../utils/constants';
export const apiField = 'clamps_location';

export enum ULAClampLocationType {
  TOP_CLAMPS = 1,
  BOTTOM_CLAMPS = 2,
  BOTH_TOP_AND_BOTTOM_CLAMPS = 3,
};

export const clampLocation = {
  type: 'Select',
  apiField,
  label: 'clamps_location',
  onConfirm: () => {
    makeAllRoofsEmpty();
  },
  shouldConfirm: () => anyPanelsDrawn(),
  data: [
    { 
      value: ULAClampLocationType.TOP_CLAMPS,
      name: 'Top Clamps Only',
    },
    {
      value: ULAClampLocationType.BOTTOM_CLAMPS,
      name: 'Bottom Clamps Only',
    },
    {
        value: ULAClampLocationType.BOTH_TOP_AND_BOTTOM_CLAMPS,
        name: 'Both Top and Bottom Clamps',
    },
  ],
};