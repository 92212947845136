import { polygonOptions } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerConfigs';

export const drawingManagerSettings = {
  polygonOptions,
  drawingControl: false,
};

export const colorPalettes = () => [
  '#FF1902', '#FB5606', '#D92969', '#FFDA31',
  '#FA9A05', '#AC1912', '#9E542D', '#E3CC88',
  '#28C419', '#16C3C9', '#B3156C', '#6D248D',
  '#5F5C55'];
