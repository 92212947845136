import { ActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';
import { setRavenEmail } from '__common/utils/ravenConfig';

export const userState: userState = {
  isAuthenticated: false,
  isRunning: true,
  username: '',
  email: '',
  password: '',
  token: '',
  loginError: false,
  registrationError: '',
  isStaff:  false,
  isPrivilegedUser: false,
  products: [],
  permissions: [],
};

export default {
  user: createReducer(userState, {
    [ActionTypes.USER_LOGIN_REQUEST](state) {
      return {
        ...state,
        isRunning: true,
        loginError: false,
      };
    },
    [ActionTypes.USER_LOGIN_SUCCESS](state, action) {
      const { username, email } = action.payload;
      setRavenEmail(username, email);
      return {
        ...state,
        isAuthenticated: true,
        isRunning: false,
        username,
        email,
        token: action.payload.token,
        isStaff: action.payload.isStaff,
        isPrivilegedUser: action.payload.isPrivilegedUser,
        products: action.payload.products,
        permissions: action.payload.permissions
      };
    },
    [ActionTypes.USER_LOGOUT_SUCCESS](state) {
      setRavenEmail('Guest', 'Guest');
      return {
        ...state,
        isAuthenticated: false,
        isRunning: false,
        token: '',
        email: '',
        username: '',
        isStaff: false,
        isPrivilegedUser: false,
        products:[],
        permissions: []
      };
    },
    [ActionTypes.USER_LOGIN_FAILURE](state, action) {
      return {
        ...state,
        isRunning: false,
        loginError: action.payload.error,
      };
    },
    [ActionTypes.USER_CLEAR_LOGIN_ERROR](state) {
      return {
        ...state,
        loginError: false,
      };
    },
    [ActionTypes.USER_ALREADY_LOGED_IN](state) {
      return {
        ...state,
        isRunning: false,
      };
    },
    [ActionTypes.USER_REGISTRATION_FAILURE](state, action) {
      const { registrationError } = action.payload;
      return {
        ...state,
        isRunning: false,
        registrationError,
      };
    },
    [ActionTypes.USER_CLEAR_REGISTRATION_ERROR](state) {
      return {
        ...state,
        registrationError: '',
      };
    },
  }),
};
