import { isRM10, isRM10Evolution } from '__common/constants/products';

export default (productId: number) => {
  const helioscopeConfig = {
    building_code: 2,
    wind_speed: 115,
    snow_load: 5,
    seismic_ss: 0.46,
    elevation: 5499,
    mean_recurrence_interval: 25,
    building_height: 15,
    roof_type: 5,
    parapet_height: 1,
    block_weight: 32,
    wind_exposure: 'B',
    building_length: 100,
    helioscope_rack_type: 'rack',
    row_spacing: (isRM10(productId) ? 19.5 : isRM10Evolution(productId)? 13: 7.5),
  };
  return helioscopeConfig;
};
