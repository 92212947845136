import React from 'react';
import { Button, DialogContainer, List, ListItem } from 'react-md';
import { connect } from 'react-redux';
import { HIDE_INFO_MODAL } from './InfoModalActions';

type Props = {
  dispatch: Function,
  isOpen: boolean,
  onConfirmAction?: any,
  onConfirmCallback?: Function,
  mapType: string,
  title: string,
  content: string,
};

class InfoModal extends React.Component<Props> {

  shouldComponentUpdate(newProps: Props) {
    return newProps.isOpen !== this.props.isOpen;
  }

  hide = () => {
    const { dispatch, onConfirmAction, onConfirmCallback } = this.props;
    if (onConfirmAction) {
      dispatch(onConfirmAction);
    }
   
    if (onConfirmCallback) {
      onConfirmCallback();
    }
    dispatch(HIDE_INFO_MODAL());
  }

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      this.hide();
    }
  }

  render() {
    const { isOpen, title, content }  = this.props;

    return (
      <div>
        <DialogContainer
          id="info-modal"
          visible={isOpen}
          title={title}
          onHide={this.hide}
        >
          <List className="info-text" onClick={this.hide} onKeyDown={this.handleKeyDown}>
            <ListItem primaryText={content} />
          </List>
          <Button flat={true} className="info-text-button" visible={open} onClick={this.hide}>Close</Button>
        </DialogContainer>
      </div>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    isOpen: appState.productDisabledInfo.isOpen,
    title: appState.productDisabledInfo.title,
    content: appState.productDisabledInfo.content,
    onConfirmAction: appState.productDisabledInfo.onConfirmAction,
    onConfirmCallback: appState.productDisabledInfo.onConfirmCallback,
  };
}

export default connect(mapStateToProps)(InfoModal);
