import { state } from '__common/store';
import { gable } from '__common/constants/buildingTypes';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

export default () => {
  const { userPreferences: { preferred_span, rafter_spacing_inches }, projectConfiguration:{projectVersion} } = state();
  const smTiltConfig = {
    zipcode: '',
    building_code: 1,
    building_height: adjustableTiltSystem(projectVersion) ? '' : 30,
    wind_speed: 110,
    snow_load: 5,
    elevation: 5499,
    wind_exposure: 'B',
    rail_finish: 'AL',
    roof_type: 1,  
    seismic_ss: 1.20,
    topographical_factor_kzt: 1,
    adjustable_tilt_system: 9,
    mid_clamps_choices:4,
    end_clamps_choices:4,
    preferred_span: preferred_span || 72,
    tilt: adjustableTiltSystem(projectVersion) ? 10 : 1,
    rail_direction: 'EW',
    rafter_spacing_inches: rafter_spacing_inches || 24,
    client_name: '',
    building_type: gable.value,
    clamps_choices:1,
    shortest_building_length: 20,
  };
  return smTiltConfig;
};
