import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TextField from 'react-md/lib/TextFields/TextField';
import autobind from 'autobind-decorator';
import { CHANGE_DESIRED_TABLE_LENGTH } from 'actions';
import Tooltip from '__common/components/Tooltip';

interface props {
  length: number;
  dispatch: Function;
  changeLength: (length: number) => void;
}

interface state {
  length: number;
  isValid: boolean;
}

const tooltipText = `Select table length. Tables are made up of modules 2-high in portrait. Table length is the number of module columns in the east-west direction.`;

class DesiredTableSize extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isValid: true,
      length: props.length,
    };
  }
  static minSize = 4;
  static maxSize = 30;
  @autobind
  onLengthChange(value: string) {
    const parsedValue = parseInt(value, 10);

    if (
      parsedValue >= DesiredTableSize.minSize &&
      parsedValue <= DesiredTableSize.maxSize
    ) {
      this.setState({
        length: parsedValue,
        isValid: true,
      });
      this.props.changeLength(parsedValue);
    } else {
      this.setState({
        length: parsedValue,
        isValid: false,
      });
    }
  }

  getRangeHelper() {
    return `(${DesiredTableSize.minSize}-${DesiredTableSize.maxSize})`;
  }

  renderError() {
    if (this.state.isValid) {
      return null;
    }

    const errorMessage = `Desired table length must be in range ${DesiredTableSize.minSize} - ${DesiredTableSize.maxSize}`;

    return (
      <div className="input-warning">{errorMessage}</div>
    );
  }

  renderWarningMessage() {
    const { length } = this.props;
    if (length) {
      return null;
    }

    return (
      <div className="input-warning">You should enter length of the table </div>
    );
  }

  render() {
    const { length } = this.state;
    return (
      <div className="desired-table-size">
        {this.renderWarningMessage()}
        <div className="input-label">
          Desired table length {this.getRangeHelper()}:
          <Tooltip
            id={'desired-table-length'}
            content={tooltipText}
            position="right"
          />
        </div>
        <div className="input-row">
          <span className={classNames('input-prefix')}>2 x</span>
          <TextField
            id="desiredTableLength"
            className={classNames('input-value')}
            active={true}
            fullWidth={false}
            value={length ?? ""}
            onChange={this.onLengthChange}
            lineDirection="center"
            min={DesiredTableSize.minSize}
            max={DesiredTableSize.maxSize}
            step={1}
            type="number"
          />
        </div>
        {this.renderError()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changeLength: (length: number) => dispatch(CHANGE_DESIRED_TABLE_LENGTH(length)),
  };
};

const mapStateToProps = (state: appState) => {
  return {
    length: state.panels.desiredTableLength,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesiredTableSize);
