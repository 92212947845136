import * as React from 'react';
import { Provider } from 'react-redux';
import App from '_containers/App';
import ConnectedIntlProvider from '__common/providers/ConnectedIntlProvider';

const Root: React.StatelessComponent<onlyStoreComponentState> = ({ store }) => (
  <Provider store={store}>
    <ConnectedIntlProvider>
      <App />
    </ConnectedIntlProvider>
  </Provider>
);

export default Root;
