import { arrayHeightAboveRoofInch } from '__editor/panelsEditor/components/roofZones/utils/asce716ResidentialZonesDistances';
import { inchesToFeet } from '__common/calculations/inchesToFeet';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isRMFamily, isEcoFoot2Plus } from '__common/constants/products';

export const getSearchDistance = (productId: number, metersPerPixel: number) => {

  if (isRMFamily(productId) || isEcoFoot2Plus(productId)) {
    const arrayHeighFt = 10;
    const arrayHeightMeters = feetsToMeters(arrayHeighFt);
    return arrayHeightMeters / metersPerPixel;
  } 

  const arrayHeightInch = arrayHeightAboveRoofInch(productId);
  const searchDistance = arrayHeightInch * 2;
  const arrayHeighFt = inchesToFeet(searchDistance);
  const arrayHeightMeters = feetsToMeters(arrayHeighFt);

  return arrayHeightMeters / metersPerPixel;
};

