import { products } from '__common/constants/products';
import { state } from '__common/store';
import { apply96and185InchRail, applyAdd185InchRail, applyAdd185InchRailForSML, removeGFT166Rail } from '__common/utils/versionCompare/versionCompare';

export function getDefaultSelectedRails(productId: number, railFinish: string) {
  const {projectConfiguration:{projectVersion, railsProductId}} = state()
  switch (productId) {
    case products.sm_tilt_pr:
    case products.solarmount_2:
    case products.solarmount_2_tilt:
      return applyAdd185InchRail(projectVersion) && [products.solarmount_2, products.sm_tilt_pr].includes(railsProductId) ? [185] : [168 , 246];
    case products.solarmount_lt:
      return applyAdd185InchRailForSML(projectVersion) && [products.solarmount_lt].includes(railsProductId) ? [185] : [168, 246];
    case products.solarmount_hd:
    case products.sm_tilt_pr_lt:
      return [168, 246];
    case products.sunframe:
      return [192];
    case products.gft:
      return [168, 246];
    case products.ula:
      return [removeGFT166Rail(projectVersion) ? 168 : 166 , 246];
    case products.ascender:
    case products.solarmount_ascender_flush:
      return [applyAdd185InchRail(projectVersion) ? 185 : 181]
    case products.nxt_umount:
      return (apply96and185InchRail(projectVersion) ? [185] : [168])
    case products.nxt_tilt:
      return [168];
    default:
      return [168, 240];
  }
}
