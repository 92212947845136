import { getNewRoofId } from '../components/drawingManager/drawingManagerHelper';
import { getRoofColor } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import { polygonOptions } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerConfigs';
import { state } from '__common/store';

export default (coords: any, roofId?: number): googleRoofOnMap => {
  const { drawingManager: { roofs } } = state();
  const roofColor = getRoofColor(roofId);
  let newRoofId;
  const polygon = new google.maps.Polygon({
    paths: coords,
    ...polygonOptions,
    fillColor: roofColor,
    strokeColor: roofColor,
  });

  if (roofId) {
    newRoofId = roofId;
  } else {
    if (roofs && Object.keys(roofs).length) {
      newRoofId = getNewRoofId();
    } else {
      newRoofId = 1;
    }
  }
  
  const shape = {
    overlay: polygon,
    type: 'Polygon',
    id: newRoofId,
  };
  return shape;
};
