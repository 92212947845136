import differenceBy from 'lodash/differenceBy';
import store, { state } from '__common/store';
import { removeKdTreePoints } from '../cursor/utils/kdTreeStore';
import { removePanelFromRtree } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { removeChild } from '../stage/stage';
import { drawPanel } from './utils/panelsManagment';
import { removePanelCenterPoints } from './utils/panelsCenterStore';
import { _isULA } from '../cursor/utils/snapToGridHelper';
import { isRM10Evolution, isRM5 } from '__common/constants/products';
import { createPanelBayMapping } from './utils/createPanelBayMapping';
import { createBays } from './utils/createBays';
import { isPanelinSkewZone } from '../skewZones/drawSkewZonesOnStage';
import { canShowSkewZone } from '__common/components/exposure/exposureHelper';

export const drawPanelsOnStage = async(stage: PIXI.Container, panelsContainer, baysContainer, panelsDict) => {
  const { panels:{panels, bays: {panelBayMapping : panelBaysMapping, visitedBays}}, roofZones: { skewZone } } = store.getState();
  if (!panelsContainer) {
    return;
  }

  const toRemove = differenceBy<panel, panelInState>(panelsContainer.children, panels, 'id').map((panel) => {
    removeKdTreePoints(panel.stickPoints);
    removePanelCenterPoints(panel.centerPoint);
    removePanelFromRtree(panel.rTreeBounds);
    return panel.id;
  });
  
  panelsContainer.children = panelsContainer.children.filter(panel => !toRemove.includes(panel.id));

  if(skewZone) {
    panelsContainer.children = []
  }

  panelsContainer.children.map((panelGraphic: PIXI.Graphics) => {
    const panel = panels.find(panel => panel.id === panelGraphic.id);

    if (panel && panelGraphic.nearObstruction !== panel.nearObstruction) {
      panelsContainer.children = removeChild(panelsContainer.children, panel.id);
    }

    if (panel && panelGraphic.exposed !== panel.exposed) {
      panelsContainer.children = removeChild(panelsContainer.children, panel.id);
    }

    if (panel && panelGraphic.exposedNeighbour !== panel.exposedNeighbour) {
      panelsContainer.children = removeChild(panelsContainer.children, panel.id);
    }

    if (panel && panelGraphic.attached !== panel.attached) {
      panelsContainer.children = removeChild(panelsContainer.children, panel.id);
    }

    if (panel && panelGraphic.roofZone !== panel.roofZone) {
      panelsContainer.children = removeChild(panelsContainer.children, panel.id);
    }

    if (panel && panelGraphic.groupId !== panel.groupId) {
      panelsContainer.children = removeChild(panelsContainer.children, panel.id);
    }
    
  });


  const toAdd = differenceBy(panels, panelsContainer.children, 'id');
  const {projectConfiguration:{projectEnvConfig:{allow_manual_attachments}, productId, projectVersion}, background:{toggleAttachments}, roofsSelector: { mapType } } = state()
  
  toAdd.map((panel: any) => {
    if(canShowSkewZone(productId, mapType, projectVersion))
      panel.skewAffected = isPanelinSkewZone(panel);
    const pixiPanel: PIXI.Graphics = drawPanel(panel);
    pixiPanel.rTreeBounds = panel.rTreeBounds;
    panelsContainer.addChild(pixiPanel);
  });
  stage.addChild(panelsContainer);
  
  if(allow_manual_attachments && Object.keys(panelsDict).length >0 && toggleAttachments && (isRM10Evolution(productId) || isRM5(productId))){
    const arrayWise = panels.reduce((acc, item) => {
      const groupId = item.groupId;
      if (groupId !== undefined) {
        if (!acc[groupId]) {
          acc[groupId] = [];
        }
        acc[groupId].push(item);
      }
      return acc;
    }, {});
    
    const rightEdgePanels: Array<number> = [];
    const leftEdgePanels: Array<number> = [];
    const northEdgePanels: number[] = [];
    const northRightEdgePanels: Array<number> = [];
    const northMostRow: Array<number>= []

    await Promise.all(
      Object.values(arrayWise).map(async (array) => {
        const [right, left, north, northRight, firstRow] = await createPanelBayMapping(array, panelsDict, panelBaysMapping, visitedBays, panels);
        rightEdgePanels.push(...right);
        leftEdgePanels.push(...left);
        northEdgePanels.push(...north);
        northRightEdgePanels.push(...northRight)
        northMostRow.push(...firstRow)
      })
    )
    .then(()=>{
          baysContainer.children = baysContainer.children.filter(child=> panels.includes(child.id))
    })
    .finally(
      () => createBays(panelBaysMapping, panelsDict, baysContainer, visitedBays, rightEdgePanels, leftEdgePanels, northEdgePanels, northRightEdgePanels, northMostRow)
    )
    stage.addChild(baysContainer)
  } else {
    stage.removeChild(baysContainer)
  }
};


export const removeAllPanelsFromStageToRedraw = (panelsContainer: PIXI.Container, baysContainer: PIXI.Container) => {
  if (panelsContainer) {
    panelsContainer.children.map((panel: PIXI.Graphics) => panel.destroy());
    panelsContainer.children = [];
  }
  if (baysContainer) {
    baysContainer.children.map((panel: PIXI.Graphics) => panel.destroy());
    baysContainer.children = [];
  }
};



