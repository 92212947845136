export const options = [
  {
    value: 1,
    name: 'YES',
  },
  {
    value: 0,
    name: 'NO',
  },
];

const config = {
type: 'Select',
  apiField: 'allow_thermal_gap',
  label: 'allowThermalGap',
  tooltip:  () => `
  <div class="content">
  Allow Thermal Gap
  </div>
`,
  data: options,
};

export default config;
