import Polygon from 'polygon';
import { state } from '__common/store';
import { recheckExposureForNewPanelsNeighbours } from '__common/components/exposure/multiPanelsExposureStateOutsideOfEditor';
import { exposedDistance, getRoofZonesAreasMemoized as getRoofZonesAreas} from '__editor/panelsEditor/components/roofZones/roofZones';
import { isEdgePoint } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { getStage } from '../../../__editor/panelsEditor/components/stage/stage';
import { DEBUG } from 'debug';
export const reCheckPanelsExposure = (panelsInCurrentState: panelInState[], panelIds: number[], roofId: number) => {
  const {
    settings: {
      columnSpacing,
      rowSpacing,
    },
    background: {
      bgXY,
      metersPerPixel,
      roofEdges,
      zoom, cords,
      rotationDegrees },
    tiltedRoof: {
      roofPitch,
    },
    projectConfiguration: {
      productId,
    },
    roofsSelector: {
      mapType,
    },
  } = state();

  return recheckExposureForNewPanelsNeighbours(
    panelsInCurrentState,
    panelIds,
    roofEdges,
    roofId,
    cords,
    zoom,
    rotationDegrees,
    metersPerPixel,
    columnSpacing,
    rowSpacing,
    bgXY,
    roofPitch,
    productId,
    mapType,
  );
};

export const checkEdgeCollisionWithExposureZone = (
  edge: { x: number, y: number }[],
  roofEdges: google.maps.LatLngLiteral[],
  roofId: number,
  cords: cordPoint,
  zoom: number,
  bgRotationDegrees: number,
  bgOffset: pixelPoint,
  insideOfPolygon: boolean,
  roofPitch: string,
  metersPerPixel: number,
  side: 'N' | 'S' | 'E' | 'W',
  roofEdgesPixiCords,
  productId: number,
  mapType: string,
  tilt: number,
) => {

  const bgXOffset = bgOffset.x;
  const bgYOffset = bgOffset.y;
  const distance = exposedDistance(metersPerPixel);
  const rotatedRoofZone =  getRoofZonesAreas(
    {roofEdges, roofCenter: cords,  roofEdgesPixiCords, roofPitch, roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  let collision = false;

  if (Object.keys(rotatedRoofZone).length) {
    const restrictedAreaPolygons = rotatedRoofZone.edges
      .map(edge => edge.filter(corner => typeof corner === 'object'))
      .filter(edge => isEdgePoint(edge[2]) && edge[2].zone === side)
      .map(edge => new Polygon(edge));

    collision = restrictedAreaPolygons.some((area: PolygonInterface) => {
      drawPolygonArea(area.points);
      const firstPoint = { x: edge[0].x + bgXOffset, y: edge[0].y + bgYOffset };
      const secondPoint = { x: edge[1].x + bgXOffset, y: edge[1].y + bgYOffset };
      const firstPointContain = area.containsPoint(firstPoint);
      const secondPointContain = area.containsPoint(secondPoint);

      drawPolygonPoints(firstPoint);
      drawPolygonPoints(secondPoint);
      
      return firstPointContain && secondPointContain;
    });
  }

  return collision;
};

const drawPolygonPoints = (point: { x: number, y: number }) => {
  if (!DEBUG.showExposureZonePolygonCheckEdge) {
    return;
  }

  const stage = getStage();
  const p = new PIXI.Graphics();
  p.id = 'pointHelper';
  p.beginFill(0xFF00FF);
  p.drawCircle(point.x, point.y, 1);
  p.zIndex = 100000;
  p.endFill();
  stage.addChild(p);
};

const drawPolygonArea = (area: { x: number, y: number }[]) => {
  if (!DEBUG.showExposureZoneCollisionArea) {
    return;
  }
  
  const stage = getStage();
  const p = new PIXI.Graphics();
  
  const pArea = area.reduce((acc, corner) => {
    acc.push(corner.x);
    acc.push(corner.y);
    return acc;
  },                        []);
  
  p.id = 'pointHelper';
  p.beginFill(0xFF00FF);
  p.drawPolygon(pArea);
  p.zIndex = 100000;
  p.endFill();
  stage.addChild(p);
};
