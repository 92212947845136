import { createReducer } from '__common/utils/helpers';
import { HistoryActionTypes } from 'actionsConstants';


export const historyState: historyState = {
  panelsSnapshot: [[]],
  index: 0,
  maxHistory: 50,
  canUndo: false,
  canRedo: false,
};

export default {
  history: createReducer(historyState, {
    [HistoryActionTypes.UNDO_HISTORY](state) {
      const newIndex = state.index < state.maxHistory && state.index + 1 < state.panelsSnapshot.length ? state.index + 1 : state.index;
      let canUndo;
      if (newIndex === 0 || state.panelsSnapshot.length - 1 === newIndex) {
        canUndo = false;
      } else {
        canUndo = true;
      }
      return { ...state, index: newIndex, canUndo, canRedo: true };
    },
    [HistoryActionTypes.REDO_HISTORY](state) {
      const newIndex = state.index > 0 ? state.index - 1 : state.index;
      let canRedo;
      if (newIndex !== 0) {
        canRedo = true;
      } else {
        canRedo = false;
      }
      return { ...state, index: newIndex, canRedo, canUndo: true };
    },
    [HistoryActionTypes.PUT_PANELS_IN_HISTORY](state, action) {
      if (state.panelsSnapshot.length > state.maxHistory) {
        state.panelsSnapshot.pop();
      }

      state.panelsSnapshot.unshift(action.payload.panels);
      return { ...state, canUndo: true, canRedo: false };
    },
    [HistoryActionTypes.RESET_HISTORY](state) {
      let canRedo;
      if (state.panelsSnapshot.length) {
        canRedo = true;
      } else {
        canRedo = false;
      }
      return { ...state, index: 0, canRedo };
    },
    [HistoryActionTypes.CLEAR_HISTORY](state) {
      return { 
        panelsSnapshot: [[]],
        index: 0,
        maxHistory: 50,
        canUndo: false,
        canRedo: false,
      };
    },
  }),
};
