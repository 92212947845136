import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect } from 'react-redux';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';
import { SET_ROOF_ZONES_AUTOFILL } from 'actions';


type Props = {
  dispatch: Function,
  panels: any,
};

class RoofZonesAutofill extends React.Component<Props, {}> {
  @autobind
  onChange(status: boolean) {
    const { dispatch } = this.props;
    dispatch(SET_ROOF_ZONES_AUTOFILL(status));
  }

  render() {
    const { panels: { enabledRoofZones } } = this.props;
    return (
    <SelectionControl
      id="roof-edges-autofill"
      type="checkbox"
      label="Roof Zones Autofill"
      name="Roof zones autofill"
      defaultChecked={enabledRoofZones}
      onChange={this.onChange}
    />
    );
  }
}

function mapStateToProps(appState: appState): { panels: any } {
  return {
    panels: appState.panels,
  };
}

export default connect(mapStateToProps)(RoofZonesAutofill);
