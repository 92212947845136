import { deepClonedState, dispatch, state } from '__common/store';
import { getProductName, isAscender } from '__common/constants/products';
import { parseDataForBomSave } from '../bomHelpers';
import { SAVE_BOM, SAVE_BOM_AND_DOWNLOAD_BOM_PDF, SET_BOM_PK } from 'actions';
import { AUTO_SAVE_BOM } from 'bom/bomActions';

export function saveBom(dontRedirect = false) {
  const { bom: { partsList, extraParameters, productId, projectId, bomSelectionsLoading, stdClampsFetching, bomChanged, created, }, railsSelector: { railsList, railsFetching, selectOnlySMHDRails },} = deepClonedState();
  const bomSaveRequestBody = parseDataForBomSave(partsList, railsList, extraParameters, productId, selectOnlySMHDRails);

  if ((!partsList.length && !isAscender
    (productId)) || bomSelectionsLoading || stdClampsFetching || railsFetching || !validatePartListQty()) {
    return;
  }

  if (!dontRedirect && !bomChanged && !created) {
    return dispatch(SET_BOM_PK(projectId));
  }

  dispatch(SAVE_BOM(getProductName(productId), projectId, bomSaveRequestBody, dontRedirect));
}

// copy pasted from above, to do some day after release
export function autoSaveBom(dontRedirect = false) {
  const { bom: { partsList, extraParameters, productId, projectId, bomSelectionsLoading, stdClampsFetching, bomChanged, created,}, railsSelector: { railsList, railsFetching, selectOnlySMHDRails }, } = deepClonedState();
  const bomSaveRequestBody = parseDataForBomSave(partsList, railsList, extraParameters, productId, selectOnlySMHDRails);

  if ((!partsList.length && !isAscender
    (productId)) || bomSelectionsLoading || stdClampsFetching || railsFetching || !validatePartListQty()) {
    return;
  }

  if (!dontRedirect && !bomChanged && !created) {
    return dispatch(SET_BOM_PK(projectId));
  }

  dispatch(AUTO_SAVE_BOM(getProductName(productId), projectId, bomSaveRequestBody, dontRedirect));
}

export function saveBomAndDownloadPdf() {
  const { bom: { partsList, extraParameters, productId, projectId, bomSelectionsLoading, stdClampsFetching }, railsSelector: { railsList, railsFetching, selectOnlySMHDRails } } = deepClonedState();
  const bomSaveRequestBody = parseDataForBomSave(partsList, railsList, extraParameters, productId, selectOnlySMHDRails);
 
  if (!partsList.length || bomSelectionsLoading || stdClampsFetching || railsFetching || !validatePartListQty()) {
    return;
  }

  dispatch(SAVE_BOM_AND_DOWNLOAD_BOM_PDF(getProductName(productId), projectId, bomSaveRequestBody));
}

function validatePartListQty() {
  const { bom: { partsList } } = state();
  return partsList.every(part => {
    const qty: any = part.quantity; // qty can be string ;()
    if (qty.constructor === Number) {
      return qty > -1;
    }

    if (qty.constructor === String) {
      return qty.length;
    }
  });
}
