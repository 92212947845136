
import drawingManagerReducer from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerReducer';
import leadEdgeRoofSelectorReducer from '__editor/googleMapsRoofsSelector/components/leadEdgeRoofSelector/leadEdgeRoofSelectorReducer';
import saveLoadProject from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject//saveLoadProjectReducer';
import advanceRoofSelecting from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelectingReducer';
import measurementToolReducer from '__editor/googleMapsRoofsSelector/components/measurementTool/measurementToolReducer';

export default {
  ...drawingManagerReducer,
  ...leadEdgeRoofSelectorReducer,
  ...saveLoadProject,
  ...advanceRoofSelecting,
  ...measurementToolReducer,
};
