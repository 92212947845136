import { TOGGLE_ROOF_DIMENSIONS } from 'actions';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';

class ToggleRoofDimensions extends React.Component<toggleRoofDimensionsState, {}> {
  action = () => {
    const { dispatch } = this.props;
    dispatch(TOGGLE_ROOF_DIMENSIONS());
  }

  render() {
    const { showRoofDimensions } = this.props;
    return (
      <Button flat={true} onClick={this.action} className="roofs-selector-button" tooltipLabel="Show Roof Dimensions">
        <SVG className={ showRoofDimensions ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/area_dimensions.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    showRoofDimensions: state.background.showRoofDimensions,
  };
}

export default connect(mapStateToProps)(ToggleRoofDimensions);
