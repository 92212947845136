import { createReducer } from '__common/utils/helpers';
import { EngineeringBallastMapsActionsConstants } from 'actionsConstants';

export let engineeringBallastMaps: engineeringBallastMapsState = {
  isGenerating: false,
  generated: false,
  generatedArrayId: 0,
  generatedLayoutType: 0,
  generatingFailed: false,
};

export default {
  engineeringBallastMaps: createReducer(engineeringBallastMaps, {
    [EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_START](state, action) {
      const { generatedArrayId, generatedLayoutType } = action.payload; 
      return { ...state, isGenerating: true, generated: false, generatingFailed: false, generatedArrayId, generatedLayoutType };
    },
    [EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_SUCCESS](state) {
      return { ...state, isGenerating: false, generated: true, generatingFailed: false };
    }, 
    [EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_FAILED](state) {
      return { ...state, isGenerating: false, generated: true, generatingFailed: false, generatedArrayId: 0, generatedLayoutType: 0 };
    }, 
    [EngineeringBallastMapsActionsConstants.BALLAST_GENERATING_RESET]() {
      return {
        isGenerating: false,
        generated: false,
        generatedArrayId: 0,
        generatedLayoutType: 0,
        generatingFailed: false,
      };
    },   
  }),
};
