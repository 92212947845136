import keyMirror from 'keymirror';

export const RailsSelectorActionTypes = keyMirror({
  SELECT_RAIL: undefined,
  UNSELECT_RAIL: undefined,
  FETCH_RAILS: undefined,
  SELECT_ALL_RAILS: undefined,
  SELECT_DEAFULT_RAILS: undefined,
  LOAD_RAILS_LIST: undefined,
  SELECT_RAILS_FROM_API: undefined,
  CLEAR_RAILS_LIST: undefined,
  RAILS_LOADED: undefined,
  SELECT_ONLY_SMHD_RAILS: undefined,
  UNSELECT_ONLY_SMHD_RAILS_OPTION: undefined,
  RAIL_TYPE_LOADED: undefined,
  FETCHING_RAILS: undefined,
  SELECT_LOWEST_COST_RAILS_FOR_185: undefined,

});
