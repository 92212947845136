import { MINIMUM_SNOW_LOAD_THAT_REQUIRES_STAGGER_ATTACHMENTS } from 'projectDesign/components/projectConfiguration/constraints/constraints';

export default () => `
  <div>
    <div class="content">
      By staggering attachments, the load is more equally distributed across more of the rafters or trusses.
    </div>
    <div class="content">
      Staggered attachments are recommended for snow loads greater than ${MINIMUM_SNOW_LOAD_THAT_REQUIRES_STAGGER_ATTACHMENTS} psf.
    </div>
  </div>
`;
