
import { DrawPanelFunction } from './draw_on_area';
import { getSelectedRowByColumnPanelsWithGapsPopularingData } from './populating';
import nextUid from '__common/calculations/nextUid';
import { state } from '__common/store';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { PANEL_EW_POSITION, PANEL_NS_POSITION } from '../../cursor/utils/snapToGridStickyPoints';

export const gapLengthMeters = inchesToMeters(12);

export const drawUlaPanelsInTables = (
  direction: string, 
  points: { x: number, y: number }[], 
  drawFn: DrawPanelFunction, 
  groupId: number | null,
): panelInState[] => {
  switch (direction) {
    case 'SE':
      return draw_SE(points, drawFn);
    case 'NW':
      return draw_NW(points, drawFn);
    case 'NE':
      return draw_NE(points, drawFn);
    case 'SW':
      return draw_SW(points, drawFn);
    default:
      return draw_SE(points, drawFn);
  }
};

const draw_SE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      rowSpacing, 
      columnSpacing,
      tableRowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
      desiredTableWidth: tableWidth,
    },
  } = state();

  const { 
    topEdge, 
    leftEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getSelectedRowByColumnPanelsWithGapsPopularingData(points, tableLength, tableWidth);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelGroupColumns = Math.ceil(panelsInColumn / tableWidth);
  const initialX = leftEdge - panelWidth / 2;
  let x: number;
  let y: number;
  let panels: panelInState[] = []; 
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  
  for (let i = 0; i < panelGroupColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = topEdge + ((panelHeight + rowSpacingPx) * tableWidth * i) + ((tableRowSpacingPx) * i);
    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX + (panelWidth * j) + (columnSpacingPx * j) + (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth,tableWidth, panelHeight, groupId, panelEWPos, drawFn, rowSpacingPx));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_NW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      rowSpacing, 
      columnSpacing,
      tableRowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
      desiredTableWidth: tableWidth,
    },
  } = state();
  const { 
    bottomEdge, 
    rightEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getSelectedRowByColumnPanelsWithGapsPopularingData(points, tableLength, tableWidth);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelGroupColumns = Math.ceil(panelsInColumn / tableWidth);
  const initialX = rightEdge - panelWidth / 2;
  let x: number;
  let y: number;

  let panels: panelInState[] = [];
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  
  for (let i = 0; i < panelGroupColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = bottomEdge - ((panelHeight + rowSpacingPx) * tableWidth * i) - ((tableRowSpacingPx) * i);
    

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX - (panelWidth * j) - (columnSpacingPx * j) - (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, tableWidth, panelHeight, groupId, panelEWPos, drawFn, rowSpacingPx));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_NE = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      rowSpacing, 
      columnSpacing,
      tableRowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
      desiredTableWidth: tableWidth,
    },
  } = state();
  const { 
    bottomEdge, 
    leftEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getSelectedRowByColumnPanelsWithGapsPopularingData(points, tableLength, tableWidth);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelGroupColumns = Math.ceil(panelsInColumn / tableWidth);
  const initialX = leftEdge - panelWidth / 2;
  let x: number;
  let y: number;
  let panels: panelInState[] = [];
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;
  
  for (let i = 0; i < panelGroupColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = bottomEdge - ((panelHeight + rowSpacingPx) * tableWidth * i) - ((tableRowSpacingPx) * i);

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX + (panelWidth * j) + (columnSpacingPx * j) + (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, tableWidth, panelHeight, groupId, panelEWPos, drawFn, rowSpacingPx));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

const draw_SW = (points: { x: number, y: number }[], drawFn: DrawPanelFunction): panelInState[] => {
  const { 
    settings: { 
      rowSpacing, 
      columnSpacing,
      tableRowSpacing,
    }, 
    background: { 
      metersPerPixel,
    }, 
    panels: { 
      desiredTableLength: tableLength,
      desiredTableWidth: tableWidth,
    },
  } = state();
  const { 
    topEdge, 
    rightEdge, 
    panelWidth, 
    panelHeight, 
    panelsInRow, 
    panelsInColumn,
  } = getSelectedRowByColumnPanelsWithGapsPopularingData(points, tableLength, tableWidth);
  // readjust panelsInRow so that no incomplete tables are drawn
  const readjustedPanelsinRow = tableLength * Math.ceil(panelsInRow/tableLength);
  const panelGroupColumns = Math.ceil(panelsInColumn / tableWidth);
  const initialX = rightEdge - panelWidth / 2;
  let x: number;
  let y: number;
  let panels: panelInState[] = []; 
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const gapPx = gapLengthMeters / metersPerPixel;
  const tableRowSpacingPx = tableRowSpacing / metersPerPixel;

  
  for (let i = 0; i < panelGroupColumns; i++) {
    let groupId = nextUid();
    let gapIndex = 0;
    let tableIndex = 0;
    y = topEdge + ((panelHeight + rowSpacingPx) * tableWidth * i)  + ((tableRowSpacingPx) * i);

    for (let j = 0; j < readjustedPanelsinRow; j++) {
      x = initialX - (panelWidth * j) - (columnSpacingPx * j) - (gapIndex * gapPx);
      let panelEWPos = PANEL_EW_POSITION.MIDDLE;
      if (tableIndex === 0) {
        panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
      } else if (tableIndex === tableLength - 1){
        panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
      }
      panels = panels.concat(drawPanels(x, y, panelWidth, tableWidth, panelHeight, groupId, panelEWPos, drawFn, rowSpacingPx));
      tableIndex += 1;

      if (tableIndex === tableLength) {
        tableIndex = 0;
        gapIndex += 1;
        groupId = nextUid();
      }
    }
  }

  return panels;
};

function drawPanels(x: number, y: number, panelWidth: number, tableWidth:number, panelHeight: number, groupId: number, panelEWPos: number, drawFn: DrawPanelFunction, rowSpacing: number): panelInState[] {
  const panels: panelInState[] = [];
  const siblingId = nextUid();

  const initialY = y-panelHeight/2;

  for(let i = 0; i < tableWidth; i++){
    let panelNSPos = PANEL_NS_POSITION.MIDDLE;
    if(i===0){
      panelNSPos = PANEL_NS_POSITION.TOP_EDGE;
    } else if(i === tableWidth-1){
      panelNSPos = PANEL_NS_POSITION.BOTTOM_EDGE;
    }
    panels.push(drawFn(x + panelWidth / 2, initialY+(i*(panelHeight + rowSpacing)), groupId, siblingId, i, panelEWPos, panelNSPos, tableWidth));
  }

  return panels;
}
