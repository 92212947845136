import { EDGES_TYPE } from "__editor/panelsEditor/components/roofZones/utils/edgesType";

export interface adjacentEdge {
  index: number;
  edgeType: EDGES_TYPE;  
  points?: edgePoints;
}

export type edgePoint = {
  x: number,
  y: number,
  zone?: edgeZone,
  index?: number,
};

export type edgePoints = [edgePoint, edgePoint, edgePoint, edgePoint];

export type edge = [edgePoint, edgePoint, edgePoint, edgePoint, EDGES_TYPE, string?];

export function isEdgePoint(edgePoint: (edgePoint|EDGES_TYPE)): edgePoint is edgePoint {
  return typeof (edgePoint as edgePoint).x !== 'undefined';
}

export type edgeZone = string;

export const getEdgePoints = (edge: edge|pixelPoint[]): edgePoints => {
    if(edge && edge[0]){
        return [edge[0], edge[1], edge[2], edge[3]];
  }
};

export const getEdgeType = (edge: edge): EDGES_TYPE => {
  return edge[4];
};

