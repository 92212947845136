import * as PIXI from 'pixi.js';
import Polygon from 'polygon';
import roofEdgesModel from '__editor/panelsEditor/models/roofEdges';
import { removeChild } from '__editor/panelsEditor/components/stage/stage';
import { startCoords } from '../../../components/roofsSelector/components/roofsSelectorClassicDesigner/roofsSelectorClassicDesigner';
import { state } from '__common/store';
import {
  bingMapsBackgroundModel,
  googleMapsBackgroundModel,
  whiteBackgroundModel,
} from '__editor/panelsEditor/models/backgrounds';
import {
  lat_lng_to_pixels,
  pixel_to_lat_lng,
} from './utils/backgroundCordinatesConverter';
import { getEditorDims } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { getPanelsContainer } from '../panels/utils/panelsContainer';
import { getRoofEdgesPxPoints } from '../roofZones/roofZonesHelper';
import { getScaleFromPitchRoof } from '../tiltedRoof/tiltedRoof';
import { shouldUseVirtualRoofEdges } from '__editor/panelsEditor/panelsEditorHelper';
import roofEdgeDimensionsModel from '__editor/panelsEditor/models/roofEdgeDImensions';
import { getRotatedPolyPixelPoints } from '__editor/panelsEditor/models/fogOfWar';
import moize from 'moize';
import { isRmGridflex10 } from '__common/constants/products';

let roofEdgesObj: PIXI.Graphics;

export function getBackgroundContainer() {
  const backgroundContainer: PIXI.Container = new PIXI.Container();
  backgroundContainer.id = 'backgroundContainer';
  return backgroundContainer;
}

export function getBackground(
  x: number,
  y: number,
  rotation: number,
  backgroundType: string,
) {
  switch (backgroundType) {
    case 'google':
      return googleMapsBackgroundModel(x, y, rotation);
    case 'bing':
      return bingMapsBackgroundModel(x, y, rotation);
    case 'white':
      return whiteBackgroundModel(x, y, rotation);
  }
}

export function createRoofEdges() {
  const {
    background: { roofEdges, roofEdgesPixiCords, cords, zoom },
    roofsSelector: { mapType },
    projectConfiguration: { productId, projectVersion },
  } = state();

  const cordsInPx = shouldUseVirtualRoofEdges(mapType, productId, projectVersion) ? roofEdgesPixiCords : getRoofEdgesPointsInPx(roofEdges, cords, zoom);
  const polygon = new Polygon(cordsInPx);
  roofEdgesObj = roofEdgesModel(polygon.clean(true).dedupe(true).points);
  return roofEdgesObj;
}

export function createRoofDimensions(scale: number) {
  const {
    background: { roofEdges },
  } = state();

  const RotatedPolyPixelPoints = getRotatedPolyPixelPoints();
  const roofDimensionObj = roofEdgeDimensionsModel(RotatedPolyPixelPoints, roofEdges, scale);
  return roofDimensionObj;
}

export function getRoofEdges(): PIXI.Graphics {
  return roofEdgesObj;
}

export function removeRoofEdge(children: PIXI.Graphics[]) {
  return removeChild(children, 'roofEdges');
}

export const removeBackground = (children: PIXI.DisplayObject[]) => {
  let newChildrens = removeChild(children, 'background');
  newChildrens = removeChild(newChildrens, 'roofEdges');
  return newChildrens;
};

export const getCenterBgCords = (
  editorWidth: number,
  editorHeight: number,
): { x: number; y: number; rotationDegrees: number } => {
  const {
    background: { rotationDegrees, bgResized },
  } = state();
  const backgroundWidth = bgResized || false ? 1280 * 2 : 1280;
  const backgroundHeight = bgResized || false ? 1280 * 2 : 1280;
  const x = backgroundWidth / 2 - (backgroundWidth - editorWidth) / 2;
  const y = backgroundHeight / 2 - (backgroundHeight - editorHeight) / 2;
  return {
    x,
    y,
    rotationDegrees,
  };
};

export const getRoofEdgesPointsInPx = (
  roofEdges: google.maps.LatLngLiteral[],
  roofCenter: cordPoint,
  zoom: number,
): pixelPoint[] => {
  const central_point = lat_lng_to_pixels(roofCenter, zoom);

  return roofEdges.reduce<pixelPoint[]>(
    (acc: pixelPoint[], cord: cordPoint) => {
      const pixels = lat_lng_to_pixels(
        { lat: cord.lat, lng: cord.lng },
        zoom,
        central_point,
        true,
        true,
      );
      const imageWidth = 1280;
      const imageHeight = 1280;
      const x = pixels.x - imageWidth;
      const y = pixels.y - imageHeight;
      acc.push({ x, y });
      return acc;
    },
    [],
  );
};

export function getNewRoofEdgesForClassicDesigner(selectedRoofId?: number) {
  const {
    background: { cords },
    panels: { panels },
    drawingManager: { roofs },
    roofsSelector: { mapType },
    projectConfiguration: { productId, projectVersion }
  } = state();

  if (!panels || panels.length === 0) {
    return startCoords;
  }

  const panelsContainer = getPanelsContainer();
  const roof = roofs[selectedRoofId];
  const roofEdgesPixiCords = roof.roofEdgesPixiCords;

  const { width, height, x, y } = panelsContainer.getLocalBounds();
  const x2 = x + width;
  const y2 = y + height;
  const x3 = x + width;
  const y3 = y;
  const x4 = x;
  const y4 = y + height;
  const bgSize = 1280;

  let topLeft, topRight, bottomRight, bottomLeft;

  if (roofEdgesPixiCords?.length === 4 && (shouldUseVirtualRoofEdges(mapType, productId, projectVersion) ||  isRmGridflex10(productId))){
    topLeft = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      roofEdgesPixiCords[0].x,
      roofEdgesPixiCords[0].y,
    );
    topRight = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      roofEdgesPixiCords[1].x,
      roofEdgesPixiCords[1].y,
    );
    bottomRight = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      roofEdgesPixiCords[2].x,
      roofEdgesPixiCords[2].y,
    );
    bottomLeft = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      roofEdgesPixiCords[3].x,
      roofEdgesPixiCords[3].y,
    );
  } else {
    topLeft = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      x,
      y,
    );
    topRight = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      x3,
      y3,
    );
    bottomRight = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      x2,
      y2,
    );
    bottomLeft = pixel_to_lat_lng(
      cords.lat,
      cords.lng,
      20,
      bgSize,
      bgSize,
      x4,
      y4,
    );
  }

  return [
    {
      lat: topLeft.lat,
      lng: topLeft.lng,
    },
    {
      lat: topRight.lat,
      lng: topRight.lng,
    },
    {
      lat: bottomRight.lat,
      lng: bottomRight.lng,
    },
    {
      lat: bottomLeft.lat,
      lng: bottomLeft.lng,
    },
  ];
}

export const getEditorCenter = () => {
  const { width, height } = getEditorDims();

  return {
    x: width / 2,
    y: height / 2,
  };
};

export const getVirtualRoofEdgesPolygon = (roofEdges: pixelPoint[]): PolygonInterface => {
  const { settings: { canvasCenter }, background: { bgScale } } = state();
  if (!roofEdges) return null;
  return new Polygon(roofEdges.map((edge) => ({
    x: edge.x * bgScale.x + canvasCenter.x,
    y: edge.y * bgScale.y + canvasCenter.y,
  })));
};

export const getRealRoofEdgesBoundsFromPixiCoords = (
  roofEdgesPxPoints: pixelPoint[],
  rotationRadians: number,
  roofPitch: string,
  shouldScale: boolean,
  productId: number,
  tilt: number) => {

  let notResized = new Polygon(roofEdgesPxPoints);
  if (shouldScale) notResized = notResized.scale(2);
  notResized = notResized.rotate(rotationRadians);

  if (!roofPitch) {
    return notResized;
  }
  
  const scale = getScaleFromPitchRoof(roofPitch, productId);

  const resizScaled = new Polygon(roofEdgesPxPoints)
    .scale(2)
    .scale(scale)
    .rotate(rotationRadians);

  const keepXCooridnate = (point, index) => {
    point.x = notResized.points[index].x;

    return point;
  };

  const rescaledYOnlyPoints = resizScaled
    .points
    .map(keepXCooridnate);

  resizScaled.points = rescaledYOnlyPoints;

  return resizScaled;

};

export const getRealRoofEdgesBounds = (
  input: {
    roofEdges: { lat: number, lng: number }[],
    roofCenter: { lat: number, lng: number}, 
    bgOffSet: {x: number, y: number,}
    zoom: number,
    rotationRadians: number,
    roofPitch: string,
    productId: number,
    tilt: number,
    },
    relativeToCenter = true,
    shouldScale = true,
): PolygonInterface => {
  let roofEdgesPxPoints: pixelPoint[];
  const {roofEdges, roofCenter, bgOffSet, zoom, rotationRadians, roofPitch, productId, tilt} =  input;

  if (relativeToCenter) {
    roofEdgesPxPoints = getRoofEdgesPxPoints(roofEdges, roofCenter, zoom).map(point => ({ x: point.x + bgOffSet.x, y: point.y + bgOffSet.y }));
  } else {
    roofEdgesPxPoints = getRoofEdgesPxPoints(roofEdges, roofCenter, zoom);
  }

  return getRealRoofEdgesBoundsFromPixiCoords(roofEdgesPxPoints, rotationRadians, roofPitch, shouldScale, productId, tilt);
};

export const getRealRoofEdgesBoundsMemoized = moize(getRealRoofEdgesBounds);