import { isHipBuildingType } from '__common/constants/buildingTypes';
import { Feature, Position, Point } from 'geojson';
import turf from 'turf';
import { getIntersection } from '__common/calculations/linesIntersection';
import { edgePoints } from '__editor/panelsEditor/components/roofZones/utils/edges';
import { RoofZonesCorner } from '__editor/panelsEditor/components/roofZones/roofZones';
import { state } from '__common/store';

/** 
 * @see __editor/panelsEditor/components/roofZones/utils/docs/corner-areas.jpg 
 * It was too difficult to explain all the information in the comment so I attached an image.
 * There is also an XML file that can be imported into draw.io in case the change was needed.
 * 
 * The important thing to notice is that we use convex hull. 
 * The initial idea was to create a polygon made of intersection points (see image),
 * but it turned out that for acute angles the point are drawn in the opposite direction.
 * Also for reflex angle greater than 270° this approach caused the gaps between edges' area and the polygon
 * made of intersection points, so we needed also added a point from the edge.
 */
export function getCornerArea(corner: RoofZonesCorner): pixelPoint[] {
  const { projectConfiguration: { projectEnvConfig: { building_type } } } = state();
  if (isHipBuildingType(building_type)) {
    // TODO - reentrant angles are broken for hip roofs.
    return getHipRoofCornerArea(corner);
  }

  const [edge1, edge2] = corner.adjacentEdges;
  const line1 = getRoofEdgeLine(edge1.points);
  const line2 = getRoofEdgeLine(edge2.points);
  const line1prim = getParallelEdgeLine(edge1.points);
  const line2prim = getParallelEdgeLine(edge2.points);

  const intersectionPoints = turf.featureCollection([
    getIntersection(line1, line2),
    edge1.points[3],
    edge2.points[2],
    getIntersection(line1, line2prim),
    getIntersection(line1prim, line2prim),
    getIntersection(line1prim, line2),
    getIntersection(line1, line2),
  ].map(pixelPointToTurfPoint));
  const convexHull = turf.convex(intersectionPoints);
  if (convexHull === undefined) {
    return null;
  }
  return convexHull.geometry.coordinates[0].map(turfPointToPixelPoint);
}

function getHipRoofCornerArea(corner: RoofZonesCorner): pixelPoint[] {
  const { 
    pos: { x, y }, 
    distance, 
    siblingCorners: [previousCorner, nextCorner],
  } = corner;

  // Depending on which side of the roof the corner is, 
  // we have to determine whether we should add or substract to the corner's position
  // to achieve a square within the roof.
  // 
  //  --------------------> X
  //  |
  //  |  ⊙ - - - - ⊙
  //  |  |         | 
  //  |  |         |             
  //  |  |         |
  //  |  ⊙ = = = = ⊙ = = = = =    x multiplier +1
  //  V   south edge
  //  Y
  //  --------------------> X
  //  |
  //  |         ⊙ - - - - ⊙
  //  |         |         | 
  //  |         |         |             
  //  |         |         |
  //  |   = = = ⊙ = = = = ⊙ 0     x multiplier -1
  //  V   south edge
  //  Y
  // 
  const xMultiplier = nextCorner.pos.x - x > 0 ? 1 : -1;
  const yMultiplier = previousCorner.pos.y - y > 0 ? 1 : -1;

  const isSouthEdge = y === nextCorner.pos.y || y === previousCorner.pos.y;

  if (isSouthEdge) {
    // Hip roof has corners only on the south edge (eave).
    return turf.polygon([[
      [x, y],
      [x, y + (distance * yMultiplier)],
      [x + (distance * xMultiplier), y + (distance * yMultiplier)],
      [x + (distance * xMultiplier), y],
      [x, y],
    ]]).geometry.coordinates[0].map(turfPointToPixelPoint);
  }

  return [];
}

const getRoofEdgeLine = (edge: edgePoints): [pixelPoint, pixelPoint] => {
  return [edge[0], edge[1]];
};

const getParallelEdgeLine = (edge: edgePoints): [pixelPoint, pixelPoint] => {
  return [edge[2], edge[3]];
};

const turfPointToPixelPoint = ([x, y]: Position): pixelPoint => {
  return {
    x, y,
  };
};

const pixelPointToTurfPoint = (point: pixelPoint): Feature<Point> => {
  return turf.point([point.x, point.y]);
};
