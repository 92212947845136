import config from 'local_config';
import nprogress from 'nprogress';
import { ActionTypes, FetchStaticsActionTypes } from 'actionsConstants';
import { buildParam } from './utils/queryBuilder';
import { saveAs } from 'file-saver';
import { Action } from '__common/store/action';

export interface FETCH_STATIC_ACTION {
  resource: string;
  filename: string;
  type: string;
  pathVariables?: { [parameter: string]: string | number };
  queryString?: string;
  stripSlash?: boolean;
  layoutType?: number,
}

export function FETCH_STATIC(
  filename: string,
  type: string,
  resource: string,
  pathVariables?: { [parameter: string]: string | number },
  queryString?: string,
  stripSlash?: boolean,
  layoutType?: number,
): Action<FETCH_STATIC_ACTION> {
  nprogress.start();

  return {
    type: FetchStaticsActionTypes.FETCH_STATIC,
    payload: {
      resource,
      filename,
      type,
      pathVariables,
      queryString,
      stripSlash,
      layoutType,
    },
  };
}

export function OPEN_STATIC(filename: string, type: string, resource: string, pathVariables?: { [parameter: string]: string | number }, queryString?: string) {
  let q: string;
  if (pathVariables) {
    q = buildParam(pathVariables);
  }

  window.open(`${location.protocol}//${config.apiUrl}api/v1/${resource}${q}${queryString}`, 'download_window');

  return {
    type: ActionTypes.EMPTY_APP_ACTION,
  };
}

export function FETCH_STATIC_SUCCESSFUL(fileName: string, type: string, file: ArrayBuffer) {
  const blob = new Blob([file], { type });
  saveAs(blob, `${fileName}`);
  nprogress.done();
  return {
    type: FetchStaticsActionTypes.FETCH_STATIC_SUCCESSFUL,
  };
}
