import { AdjustableTiltSystemField, apiField } from "./types/adjustableTiltSystemType";

const config : AdjustableTiltSystemField = ({
    label: 'adjustableTiltSystem',
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data: [
      {
        value: 9,
        name: 'SM TILT',
      },
      {
        value: 34,
        name: 'NXT TILT',
      },
  ],
});
export default config;