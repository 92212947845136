import BigNumber from 'bignumber.js';
import GaussianElimination from 'na-gaussian-elimination';

export function findSlope(points) {
  const newPoints = points.map(point => parseFloat(point.toFixed(10)));
  const gaussianElimination = new GaussianElimination();
  const matrix = [
    [new BigNumber(newPoints[0]), new BigNumber(1)],
    [new BigNumber(newPoints[2]), new BigNumber(1)],
  ];
  const result = [new BigNumber(newPoints[1]), new BigNumber(newPoints[3])];
  try {
    const system = gaussianElimination.solve(matrix, result);
    return [system.solution[0].toNumber(), system.solution[1].toNumber()];
  } catch (e) {
    if (e instanceof GaussianElimination.SolutionError) {
      return [Infinity, null];
    } else {
      throw e;
    }
  }
}
