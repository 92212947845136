import * as React from 'react';
import AppHistory from '__common/modules/history';
import BackButton from 'app/components/FooterComponent/BackButton';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import Loader from '__common/components/LoaderComponent';
import ProjectConfiguration from 'projectDesign/components/projectConfiguration/projectConfigurationComponent';
import ProjectInfoHeaderMiddleBar from 'projectDesign/components/ProjectInfoHeaderMiddleBar';
import { connect } from 'react-redux';
import { loadProject, loadDefaultModule } from '__common/components/moduleSelector/moduleSelector';
import { getProductTitle } from '__common/constants/products_titles';
import { OPEN_DRAWER_PAGE, SET_GONE_THROUGH_THE_PANEL_EDITOR, SET_PRODUCT_ID, SET_PROJECT_OPTION } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import { products } from '__common/constants/products';
import {
  parseDataForImport,
  validateDataForImport,
} from 'helioscopeImport/components/helioscopeDesignsConfig/utils/parseDataForImport';
import {
  clearHelioscopeDesignsConfigInitData,
  fetchHelioscopeDesignsConfigInitDataRequest,
  IMPORT_HELIOSCOPE_PROJECT,
  IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT,
  SET_IMAGE_ROOFS_SELECTOR,
  SET_MAP_STATE,
  CLEAR_PROJECT_CONFIGURATION,
  RESET_MODULE_SELECTOR,
  SET_PRODUCT_TITLE,
  CLEAR_ROOFS_SELECTOR,
  fetchHelioscopeDesignsFullDesignConfigInitDataRequest,
} from 'actions';
import { roofsSelectorState } from '__editor/components/roofsSelector/roofsSelectorReducer';

type Props = {
  dispatch: Function;
  helioscopeDesignConfig: helioscopeDesignConfigState;
  match: any;
  moduleSelector: moduleSelectorState;
  projectConfiguration: projectConfigurationState,
  roofsSelector: roofsSelectorState,
};

export class HelioscopeDesignConfig extends React.Component<Props, {}> {
  UNSAFE_componentWillMount() {
    const { dispatch, match } = this.props;
    
    const projectId = match.params.projectId;
    const designId = match.params.designId;
    const fieldSegmentId = match.params.fieldSegmentId;
    const productId = match.params.productId;
    dispatch(CLEAR_PROJECT_CONFIGURATION());
    dispatch(SET_PRODUCT_ID(products[productId]));
    dispatch(OPEN_DRAWER_PAGE('helioscope-selection'));
    dispatch(
      fieldSegmentId?
      fetchHelioscopeDesignsConfigInitDataRequest(
        projectId,
        designId,
        fieldSegmentId,
        productId,
      ):
      fetchHelioscopeDesignsFullDesignConfigInitDataRequest(
        projectId,
        designId,
        productId,
      ) 
      ,
    );
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const productId = match.params.productId;
    dispatch(SET_PRODUCT_TITLE(getProductTitle(products[productId])));
  }

  componentDidUpdate(prevProps: Props) {
    const { dispatch, roofsSelector: { mapTypeSelected } } = prevProps;
    const { 
      match: {
        params: { productId },
      },
     } = this.props;
    if (Object.keys(this.props.projectConfiguration.projectEnvConfig).length > 0 && !mapTypeSelected) {
      dispatch(SET_IMAGE_ROOFS_SELECTOR(this.props.projectConfiguration.projectEnvConfig['helioscope_layout']));
      dispatch(SET_MAP_STATE(true));
      
      const { model, module } = this.props.projectConfiguration.projectEnvConfig;
      const { projectVersion } = this.props.projectConfiguration;
      if (module !== null && model !== null) {
        loadProject(productId, module, model, projectVersion);
      } else {
        loadDefaultModule(productId);
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearHelioscopeDesignsConfigInitData());
    dispatch(CLEAR_PROJECT_CONFIGURATION());
    dispatch(RESET_MODULE_SELECTOR());
    dispatch(CLEAR_ROOFS_SELECTOR());
  }

  updateInitData = (field, value) => {
    const { dispatch } = this.props;

    dispatch(SET_PROJECT_OPTION(field, value));
  }

  submitForm = () => {
    const {
      dispatch,
      projectConfiguration: { projectEnvConfig },
      helioscopeDesignConfig: { isImportingNow },
      match: {
        params: { productId, projectId, designId, fieldSegmentId },
      },
      moduleSelector,
    } = this.props;
    if (isImportingNow) {
      return;
    }
    const data = parseDataForImport(
      productId,
      projectId,
      designId,
      fieldSegmentId,
      projectEnvConfig,
      moduleSelector.modelData,
    );
    if (validateDataForImport(data)) {
      if (!!fieldSegmentId) dispatch(IMPORT_HELIOSCOPE_PROJECT(data));
      else dispatch(IMPORT_HELIOSCOPE_FULL_DESIGN_PROJECT(data));

      dispatch(SET_GONE_THROUGH_THE_PANEL_EDITOR(false));
    }
  }

  onZipCodeChange = (value) => {
    this.updateInitData('zipcode', value);
  }

  redirectToDesign(projectId) {
    const { productId } = this.props.match.params;
    AppHistory.push(`/project/design/${productId}/${projectId}`);
    return null;
  }

  renderLoader() {
    return <Loader />;
  }

  renderFooter = () => {
    const {
      dispatch,
      match: {
        params: { projectId },
      },
    } = this.props;

    return (
      <div className="footer-buttons">
        <BackButton
          url={`/hs/project/${projectId}`}
          dispatch={dispatch}
        />
      </div>
    );
  }

  renderConfigurator() {
    const { projectEnvConfig } = this.props.projectConfiguration;
    const {
      match: {
        params: { productId, projectId },
      },
    } = this.props;

    return (
      <div className="app-content">
        <Header navigation={true} Infobar={<ProjectInfoHeaderMiddleBar projectId={projectId} />} productId={productId} projectId={projectId} />
        <div className="helioscope-design-config">
        <div
            className="project-image"
            style={{
              background: `url(${
                projectEnvConfig['helioscope_layout']
              }) no-repeat 50% 60%`,
            }}
        />
          <ProjectConfiguration
            productId={productId}
            initData={projectEnvConfig}
            onChange={this.updateInitData}
            isHelioscope={true}
            onSave={this.submitForm}
          />
          <Footer ContainerFooter={this.renderFooter} />
        </div>
      </div>
    );
  }

  render() {
    const {
      helioscopeDesignConfig: { successfullyImportedProjectId, isImportingNow },
      projectConfiguration: { projectEnvConfig },
      roofsSelector: { mapTypeSelected },
      match: {
        params: { productId, projectId },
      },
    } = this.props;

    if (successfullyImportedProjectId.length) {
      return this.redirectToDesign(successfullyImportedProjectId);
    }

    if ((Object.keys(projectEnvConfig).length && mapTypeSelected) && !isImportingNow) {
      return this.renderConfigurator();
    }
    return (
      <div className="app-content helioscope-design-config">
        <Header navigation={true} Infobar={<ProjectInfoHeaderMiddleBar projectId={projectId} />} productId={productId} projectId={projectId} />
        {this.renderLoader()}
        <Footer ContainerFooter={this.renderFooter} />
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    projectConfiguration: state.projectConfiguration,
    helioscopeDesignConfig: state.helioscopeDesignConfig,
    moduleSelector: state.moduleSelector,
    roofsSelector: state.roofsSelector,
  };
}

export default connect(mapStateToProps)(HelioscopeDesignConfig);
