import { InputUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import parapetHeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/parapetHeightTooltip';
import { round } from 'lodash';
import { inchesToMeters } from 'maths';
export const apiField = 'parapet_height_input';

export default (inputUnit: number = InputUnit.CUSTOMARY) => {
  return {
    min: 0,
    max: isMetricUnit(inputUnit) ? round(inchesToMeters(100), 1) : 100,
    type: 'Number',
    label: isMetricUnit(inputUnit) ? 'parapetHeightMetric' : 'parapetHeight',
    missing: undefined,
    apiField,
    tooltip: parapetHeightTooltip,
    data: [],
  }
};


const PARAPET_DATA_METRIC = [
  {
    value: 1,
    name: '<= 1/2 Array Height (<=0.15 meters)',
  },
  {
    value: 2,
    name: '<= 1 Array Height (<= 0.30 meters)',
  },
  {
    value: 3,
    name: '> 1 Array Height (>0.30 meters)',
  },
]

const PARAPET_DATA = [
  {
    value: 1,
    name: '<= 1/2 Array Height (<=6 inches)',
  },
  {
    value: 2,
    name: '<= 1 Array Height (<= 12 inches)',
  },
  {
    value: 3,
    name: '> 1 Array Height (>12 inches)',
  },
]

