import React from 'react';
import EngineeringProductAssumptions from 'engineering/components/engineeringProductAssumptions';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';

export default class DesignAssumptions extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="project-assumptions">
          <EngineeringProductAssumptions />
        </div>
        <Footer />
      </>
    );
  }
}
