import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { clearBingSelection } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { clearGoogleMapsSelection } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManager';
import { deployAdvanceRoofSelecting } from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelecting';
import { deployBingAdvanceRoofSelecting } from '__editor/bingMapsRoofsSelector/components/bingMapsAdvanceRoofSelecting/bingMapsAdvanceRoofSelecting';
import { getMap, turnGoogleMapsOnMapMoveControls } from '__editor/googleMapsRoofsSelector/components/map/map';
import { TURN_OFF_EDIT, TURN_OFF_MEASUREMENT_TOOL, TURN_OFF_ROOF_SELECTING } from 'actions';
import { turnBingMapsOnMapMoveControls } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';

type MoveMapButtonState = {
  advanceRoofSelectorEnabled: boolean,
  measurementToolEnabled: boolean,
  editEnabled: boolean,
  dispatch: Function,
  mapType: string,
};

export default class MoveMapButton extends React.Component<MoveMapButtonState, {}> {
  turnOffOtherModes() {
    const { dispatch } = this.props;
    dispatch(TURN_OFF_EDIT());
    dispatch(TURN_OFF_ROOF_SELECTING());
    dispatch(TURN_OFF_MEASUREMENT_TOOL());
  }

  turnOnMapControls() {
    const { mapType } = this.props;

    if (mapType === 'google') {
      turnGoogleMapsOnMapMoveControls();
      clearGoogleMapsSelection();
    }

    if (mapType === 'bing') {
      turnBingMapsOnMapMoveControls();
      clearBingSelection();
    }

    const deployRubberFn = mapType === 'google' ? deployAdvanceRoofSelecting : deployBingAdvanceRoofSelecting;

    deployRubberFn();
  }

  changeCursor() {
    const { mapType } = this.props;

    if (mapType === 'google') {
      const map = getMap();
      map.setOptions({ draggableCursor: 'move' });
    }
  }

  @autobind
  turnOn() {
    this.turnOffOtherModes();
    this.turnOnMapControls();
    this.changeCursor();
  }

  moveMapEnabled() {
    const { advanceRoofSelectorEnabled, editEnabled, measurementToolEnabled } = this.props;
    return !advanceRoofSelectorEnabled && !editEnabled && !measurementToolEnabled;
  }

  render() {
    return (
    <Button tooltipLabel="Move map" flat={true} onClick={this.turnOn} className="roofs-selector-button">
      <SVG className={this.moveMapEnabled() ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_move.svg')} />
    </Button>
    );
  }
}
