import React from 'react';
import Button from 'react-md/lib/Buttons/Button';
import SVG from 'react-inlinesvg';
import { Positions } from 'react-md/lib/Tooltips';

type Props = {
  onClick: () => void;
  tooltipLabel: string;
  svgPath: string;
  tooltipPosition?: Positions;
};

export default (props: Props) => {
  const { 
    onClick, 
    tooltipLabel, 
    svgPath,
    tooltipPosition = 'left',
  } = props;
  return (
    <Button 
      tooltipPosition={tooltipPosition} 
      tooltipLabel={tooltipLabel} 
      onClick={onClick} 
      icon={true} 
      svg={true} 
      style={{ padding: '1px 7px 2px' }} 
      // this prevents from displaying too wide tooltips 
      // and rather wraps the text inside
      tooltipStyle={{
        width: '75px',
        whiteSpace: 'normal',
      }}
    >
      <SVG style={{ width: '24px', fill: 'black' }} className={'roofs-selector-control-panel-active'} src={svgPath} />
    </Button>
  );
};
